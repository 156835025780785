import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  ComposerCellComponent,
  ComposerContainerComponent, ComposerPlaceholderComponent,
  ComposerRowComponent
} from './components';
import {
  ComposerCellContentDirective,
  ComposerCellDirective
} from './directives';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    ComposerContainerComponent,
    ComposerRowComponent,
    ComposerCellComponent,
    ComposerCellContentDirective,
    ComposerCellDirective,
    ComposerPlaceholderComponent
  ],
  exports: [
    ComposerContainerComponent,
    ComposerRowComponent,
    ComposerCellComponent,
    ComposerCellContentDirective,
    ComposerCellDirective,
    ComposerPlaceholderComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    TranslateModule
  ]
})
export class ComposerModule {

}
