import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { FormControl } from '@angular/forms';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';

import { PaymentApiService } from 'projects/workspace/src/app/payment/services/payment-api.service';
import { DocumentTypesEnum, DocumentTypesUppercaseEnum } from './enums/ducument-types.enum';
import { DirectionDocumentEnum } from './enums/document-direction.enum';
import { SearchDocumentResponse } from './model/search-document-response';
import { PaymentModel } from 'projects/workspace/src/app/payment/models/payment.model';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { PaymentListTabsEnum } from 'projects/workspace/src/app/payment/enums';

// todo: check usage
@Component({
  selector: 'rnpl-link-document-modal',
  templateUrl: './link-document-modal.component.html',
  styleUrls: ['./link-document-modal.component.scss']
})
export class LinkDocumentModalComponent extends BaseModalComponent implements OnInit {

  readonly linkDocumentRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public documentType: DocumentTypesEnum;
  public documentTypesEnum = DocumentTypesEnum;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  public documentDirection: DirectionDocumentEnum;

  public documentsList: SearchDocumentResponse[] = [];

  public selectedDocument: SearchDocumentResponse = null;

  public documentTypeControl: FormControl;

  public customSearchFn = CustomSearchFn;

  constructor(
    public toasterService: ToasterService,
    public router: Router,
    public dialogRef: MatDialogRef<LinkDocumentModalComponent>,
    private readonly paymentApiService: PaymentApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      paymentId: number;
      direction: DirectionDocumentEnum;
      paymentStatus: PaymentListTabsEnum;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.documentDirection = this.data.direction;
    this.documentType = (this.documentDirection === DirectionDocumentEnum.INCOMING) ? DocumentTypesEnum.OIN : DocumentTypesEnum.IIN;
    this.documentTypeControl = new FormControl({value: this.documentType, disabled: true});
    this.getDocument();

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private getDocument(): void {
    this.showDropdownSpin$.next(true);
    const documentsRequest$: Observable<any> = this.data.paymentStatus === PaymentListTabsEnum.DRAFT
      ? this.paymentApiService.getAvailableLinks(this.documentType, this.documentDirection)
      : this.paymentApiService.getAvailableAssigns(this.data.paymentId);

    documentsRequest$
      .pipe(takeUntil(this._destroy))
      .subscribe((documents: SearchDocumentResponse[]) => {
        this.showDropdownSpin$.next(false);
        this.documentsList = this.prepareDocumentsList(documents);
      }, error => {
        this.showDropdownSpin$.next(false);
        this.displayMessage('error', error.error.message);
      });
  }

  public prepareDocumentsList(documents): SearchDocumentResponse[] {
    return documents.map(item => ({
      ...item,
      searchLabel: `${item.runpleId} ${get(item, 'partner.name')} ${item.amount} ${item.externalNumber || ''}`
    }));
  }

  public linkDocument(): void {
    if (this.linkDocumentRequest$.getValue()) { return; }
    this.linkDocumentRequest$.next(true);

    const assignDocument = {
      documentId: this.selectedDocument.id,
      documentType: this.selectedDocument.type,
    };

    // const linkRequest$: Observable<any> = this.data.paymentStatus === PaymentListTabsEnum.DRAFT
    //   ? this.paymentApiService.linkPayment(this.data.paymentId, this.selectedDocument.id, this.documentType)
    //   : this.paymentApiService.assignDocumentToPayment(this.data.paymentId, assignDocument);

    // linkRequest$
    this.paymentApiService.assignDocumentToPayment(this.data.paymentId, assignDocument)
      .pipe(
        finalize(() => { this.linkDocumentRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(this.subscriberHandler, this.handleError);
  }

  public subscriberHandler = (response: PaymentModel) => {
    this.dialogRef.close(response);
  }

  public handleError = error => {
    this.displayMessage('error', error.error.message || error.error.errors);
  }

}
