import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { TableColumnModelExtended } from '../../../../../models/table-column.model';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-column-with-currency',
  templateUrl: './column-with-currency.component.html',
  styleUrls: ['./column-with-currency.component.scss']
})
export class ColumnWithCurrencyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() set value(value: number) {
    this.valueInput = (value || value === 0) ? (value / 1e2).toFixed(2) : null;
  }
  @Input() row: any;
  @Input() rowIndex: number;
  @Input() disabled?: boolean = false;
  @Input() column: TableColumnModelExtended;

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  public inputControl = new FormControl(null, {validators: [Validators.required], updateOn: 'blur'});
  public valueInput: string = null;

  protected _$destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  @TrackInputChanges<string | number>('row', 'updateValueHandler', ChangesStrategy.Each)
  @TrackInputChanges<string | number>('value', 'updateValueHandler', ChangesStrategy.Each)
  @TrackInputChanges<boolean>('disabled', 'updateDisabledHandler', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        // debounceTime(500), Unused, updated by blur
        takeUntil(this._$destroy)
        )
      .subscribe((value) => {
        this.updateValueEmit(value);
      });

    // remove fields highlighting
    this.clearControlValidator();

  }

  private clearControlValidator() {
    if (get(this.column, 'clearValidator', false) || !!this.valueInput) {
      this.inputControl.clearValidators();
    }
  }


  public updateValueHandler(): void {
    // Fix bug when input isn't render yet and have no mask.
    setTimeout(() => { this.inputControl.setValue(this.valueInput, {emitEvent: false}); }, 0);
  }

  public updateDisabledHandler(): void {
    this.disabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  updateValueEmit(value: number): void {
    this.updateValue.emit({
      value: value
        ? +(value * 100).toFixed(0)  // example: convert from 1$ to 100¢
        : value,
      cell: this.column.prop,
      rowIndex: this.rowIndex
    });
  }

  ngOnDestroy(): void {
    this._$destroy.next(null);
    this._$destroy.complete();
  }
}
