import { ActionReducer, ActionReducerMap, createReducer, INIT, on } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import {
  MetaReducer
} from '@ngrx/store';

import { AppState } from '../state/app.state';
import { environment } from '../../../environments/environment';
import * as CollectionAppActions from '../actions/app.actions';
import { SharedStoreReducer } from './shared.reducer';
import { LinkReducer } from './link.reducer';
import { CompanyProfileReducer } from '../../administration/store/reducers';

export const appReducers: ActionReducerMap<AppState, any> = {
  router: routerReducer,
  linkQueue: LinkReducer,
  shared: SharedStoreReducer,
  companyProfileStore: CompanyProfileReducer,
};

// Meta-Reducer - clear store on logout
export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if ( action != null && action.type === CollectionAppActions.ClearStoreOnLogout.type) {
      return reducer( undefined, {type: INIT});
    }
    return reducer(state, action);
  };
}


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logout] : [logout];
