import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'rnpl-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {

  @Input() entityName: string;

  @Input() size: string;

  @Input() urlAvatar: string;

  @Input() colorAvatar: string;

  @Input() fullName: string;

  public firstPart = 'Runple';

  public secondPart = 'Entity';

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('entityName')) {
      if (!this.entityName) { return; }
      const value = this.entityName.split(' ');
      this.firstPart = value[0] || '';
      this.secondPart = value[1] || '';
    }
  }

}
