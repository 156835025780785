import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';


import { WizardStep } from '../../../../wizard/wizard-step';
import { RelationHelpers } from '../../helpers';
import { FormTooltipComponent } from '../../../../ui-components/form-tooltip/form-tooltip.component';
import { AttributeWizardComponent } from '../../attribute-wizard.component';


@Component({
  selector: 'rnpl-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent extends WizardStep implements OnInit {

  @Input()
  public entities: any;

  public entityLists: any = {};

  public onlyOneType: boolean = false;

  @ViewChild('entityHint', {static: false})
  private entityHint: FormTooltipComponent;

  constructor(@Optional() public wizard: AttributeWizardComponent) {
    super();
  }

  ngOnInit(): void {

    Object.keys(this.entities)
      .filter(entityType => this.form.get('entityType').value.includes(entityType))
      .forEach(entityType => {
        this.entityLists[entityType] = Object.keys(this.entities[entityType])
          .filter(entityKey => this.entities[entityType][entityKey].length)
          /**
           * Two level product filter
           * Needed for filter out general product and product unit
           */
          .filter(entityKey => {
            if (entityType !== 'product') { return true; }
            if (entityKey === 'product_products') { return true; }
          })
          /**
           * Two level product filter
           * @END
           */
          .map((entityKey) => {

            return {
              key: entityKey,
              label: RelationHelpers.entityKeyToLabel(entityKey),
              checked: !!this.form.get('entities').value.includes(entityKey)
            };
          });
      });

    this.onlyOneType = Object.keys(this.entityLists).length === 1;

    if (this.form.get('entities').value.length) {
      this.wizard.progress = this.progress.finish;
    }

    this.form.get('entities').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.wizard.progress = this.progress.finish;
        if (this.entityHint) {
          this.entityHint.hide();
        }
      });
  }

  public setEntity(event) {
    let entities = this.form.get('entities').value;
    if (event.checked) {
      if (!entities.includes(event.key)) {
        entities.push(event.key);
      }
    } else {
      entities = entities.filter(entity => entity !== event.key);
    }
    this.form.get('entities').setValue(entities);
  }

}
