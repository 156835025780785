import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MultipleControlComponent } from '../multiple-control.component';

@Component({
  selector: 'rnpl-radio-group',
  templateUrl: './radio-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioGroupComponent extends MultipleControlComponent implements OnInit {

  public colWidth: number = 6;

  constructor() {
    super();
  }

  ngOnInit(): void {
    switch (this.options.length) {

      case 2:
        this.colWidth = 6;
        break;

      case 3:
        this.colWidth = 4;
        break;

      default:
        this.colWidth = 12;
    }

    this.disabled = this.control.disabled || false;
  }

  public change(value) {
    this.control.setValue(value);
    this.control.markAsDirty();

    this.formUpdated.emit(true);
  }
}
