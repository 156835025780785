import { createReducer, on } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { UIStatesEnum, PaginationModel } from 'common/src/models';
import { CollectionPartnerActions } from '../actions/action-types';
import { PartnerModel } from '../../models/partner.model';
import { PartnersListTabsEnum } from '../../enums/partners-list-tabs.enum';
import { PartnersTypeEnum } from '../../enums/partner-types.enum';
import { FilterModel } from 'projects/workspace/src/app/warehouse/models/filter.model';
import { Validation } from 'projects/workspace/src/app/shared/models/response';
import { Title } from 'projects/workspace/src/app/administration/models/company-profile.model';

export const PartnersFeatureKey = 'corporatePartnersStore';

export interface PartnersListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: PartnerModel[];
    type: PartnersTypeEnum;
  }
}
export interface PartnersState {
  [PartnersListTabsEnum.ACTIVE]?: PartnersListState;
  [PartnersListTabsEnum.INACTIVE]?: PartnersListState;
  [PartnersListTabsEnum.DELETED]?: PartnersListState;
}

export interface PartnerStore {
  entity: PartnerState;
  list: PartnersState;
}

export interface PartnerState {
  partner: PartnerModel;
  currentState: UIStatesEnum;
  countries: CountryModel[];
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
  titles: Title[];
  validations: Validation[];
}

export const initialPartnerState: PartnerState = {
  partner: {} as PartnerModel,
  countries: [],
  currentState: UIStatesEnum.VIEW,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false,
  titles: [],
  validations: [],
};

export const initialPartnersState: PartnersState = {
  [PartnersListTabsEnum.ACTIVE]: {},
  [PartnersListTabsEnum.INACTIVE]: {},
  [PartnersListTabsEnum.DELETED]: {},
}

export function PartnersReducer(state, action) {
  return _PartnersReducer(state, action);
}

export function PartnersListReducer(state, action) {
  return _PartnersListReducer(state, action);
}

const _PartnersListReducer = createReducer(
  initialPartnersState,
  on(CollectionPartnerActions.LoadPartnersList, (state, { partnerListData, status }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...partnerListData,
    },
  })),
);

const _PartnersReducer = createReducer(
  initialPartnerState,
  on(CollectionPartnerActions.LoadPartner, (state, { partner }) => ({
    ...state,
    partner,
  })),
  on(CollectionPartnerActions.LoadCountries, (state, { countries }) => ({
    ...state,
    countries,
  })),
  on(CollectionPartnerActions.UpdatePartnerState, (state, { currentState }) => ({
    ...state,
    currentState,
  })),
  on(CollectionPartnerActions.UpdatePartnerUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt,
  })),
  on(CollectionPartnerActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionPartnerActions.ClearPartnerUpdatedAt, (state) => ({
    ...state,
    updatedAt: null,
  })),
  on(CollectionPartnerActions.LoadTitles, (state, {titles}) => ({
    ...state,
    titles
  })),
  on(CollectionPartnerActions.UpdateValidations, (state, {validations}) => ({
    ...state,
    validations
  })),
  on(CollectionPartnerActions.ResetValidations, (state) => ({
    ...state,
    validations: []
  })),
  on(CollectionPartnerActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1,
  })),
  on(CollectionPartnerActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1,
  })),
);
