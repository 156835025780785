import { OcrDocumentFieldTypeEnum, OcrPositionFieldTypeEnum } from '../components/ocr-preview/models';

export const OCR_SUMMARY_TARGET_LIST: {value: OcrDocumentFieldTypeEnum; label: string}[] = [
  {value: OcrDocumentFieldTypeEnum.EXTERNAL_NUMBER,  label: 'COLUMN.EXTERNAL_INVOICE_NUMBER'},
  {value: OcrDocumentFieldTypeEnum.ISSUE_DATE,       label: 'FORM.DATE_OF_ISSUE'},
  // {value: OcrDocumentFieldTypeEnum.CURRENCY,        label: 'FORM.INVOICE_CURRENCY'},
  {value: OcrDocumentFieldTypeEnum.DUE_DATE,         label: 'COMMON.DUE_DATE'},
  {value: OcrDocumentFieldTypeEnum.BIC,              label: 'FORM.BIC'},
  {value: OcrDocumentFieldTypeEnum.IBAN,             label: 'FORM.IBAN'},
  {value: OcrDocumentFieldTypeEnum.SCONTO_AMOUNT,    label: 'SCONTO.SCONTO_AMOUNT'},
  {value: OcrDocumentFieldTypeEnum.SCONTO_RATE,      label: 'SCONTO.SCONTO_RATE'},
  {value: OcrDocumentFieldTypeEnum.SCONTO_DUE_DATE,  label: 'SCONTO.SCONTO_PERIOD'},
  {value: OcrDocumentFieldTypeEnum.PROVIDED_FROM_TO, label: 'COLUMN.PERIOD'},
  {value: OcrDocumentFieldTypeEnum.NET_TOTAL,        label: 'TABLE_SUMMARY_BAR.TOTAL_AMOUNT_NET'},
  {value: OcrDocumentFieldTypeEnum.VAT_TOTAL,        label: 'TABLE_SUMMARY_BAR.TOTAL_VAT'},
  {value: OcrDocumentFieldTypeEnum.VAT_PERCENT,      label: 'GL_ACCOUNTS.VAT_RATE'},
  {value: OcrDocumentFieldTypeEnum.GROSS_TOTAL,      label: 'TABLE_SUMMARY_BAR.TOTAL_AMOUNT_GROSS'},
];

export const OCR_POSITIONS_FIELDS_LIST: {value: OcrPositionFieldTypeEnum; label: string}[] = [
  {value: OcrPositionFieldTypeEnum.NAME,              label: 'COLUMN.NAME'},
  {value: OcrPositionFieldTypeEnum.QUANTITY,          label: 'COLUMN.QUANTITY'},
  {value: OcrPositionFieldTypeEnum.VAT_VALUE,         label: 'OCR.VAT_AMOUNT'},
  {value: OcrPositionFieldTypeEnum.VAT_PERCENT,       label: 'GL_ACCOUNTS.VAT_RATE'},
  {value: OcrPositionFieldTypeEnum.NET_UNIT_PRICE,    label: 'COLUMN.UNIT_PRICE_NET'},
  {value: OcrPositionFieldTypeEnum.NET_TOTAL_PRICE,   label: 'COLUMN.AMOUNT_NET'},
  {value: OcrPositionFieldTypeEnum.GROSS_UNIT_PRICE,  label: 'COLUMN.UNIT_PRICE_GROSS'},
  {value: OcrPositionFieldTypeEnum.GROSS_TOTAL_PRICE, label: 'COLUMN.AMOUNT_GROSS'},
  {value: OcrPositionFieldTypeEnum.PROVIDED_FROM,     label: 'COLUMN.PROVIDED_FROM'},
  {value: OcrPositionFieldTypeEnum.PROVIDED_TO,       label: 'COLUMN.PROVIDED_TO'},
  {value: OcrPositionFieldTypeEnum.PROVIDED_FROM_TO,  label: 'COLUMN.PERIOD'},
  {value: OcrPositionFieldTypeEnum.DELIVERED,         label: 'COLUMN.DELIVERY_DATE'},
];
