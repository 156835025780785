import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { ProductModel } from '../../../products';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { ProductTypes } from '../../../products/product-types';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-add-product-for-linked-modal',
  templateUrl: './add-product-for-linked-modal.component.html',
})
export class AddProductForLinkedModalComponent implements OnInit, OnDestroy {
  public quantity: number;
  // public available: number;
  public selectedProductId: number;
  public selectedProductInvalid: boolean = false;
  public quantityInvalid: boolean = false;
  public allowFractionalValues: boolean = true;
  public productType = PRODUCTS_TYPE;

  public customSearchFn = CustomSearchFn;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly productsList$: BehaviorSubject<any> = new BehaviorSubject<any>([]); // todo model
  readonly productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private deliveryNoteApiService: DeliveryNoteApiService,
    private productUnitApiService: ProductUnitApiService,
    public dialogRef: MatDialogRef<AddProductForLinkedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dnId: number,
    }
  ) { }

  ngOnInit(): void {
    this.deliveryNoteApiService.getAvailableProductsForLinkedDeliveryNote(this.data.dnId)
      .pipe(
        finalize(() => this.productsLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((products) => this.productsList$.next(this.prepareProductsList(products)));
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => {
      return {
        ...item,
        searchLabel: `${item.name} ${item.type} ${item.netPrice || 'n/a'} ${item.available}`
      };
    });
  }

  public increaseQuantity(): void {
    this.quantity = (this.quantity || 0) + 1;
    this.quantityInvalid = false;
  }

  public decreaseQuantity(): void {
    if (!this.quantity) { return; }
    this.quantity = this.quantity - 1;
    this.quantityInvalid = false;
  }

  public submit(): void {
    if (!this.selectedProductId) {
      this.selectedProductInvalid = true;
    }

    if (!this.quantity) {
      this.quantityInvalid = true;
    }

    if (!this.selectedProductId || !this.quantity) {
      return;
    }

    this.deliveryNoteApiService.addProductUnitsToLinkedDeliveryNote(
      this.data.dnId,
      this.selectedProductId,
      this.quantity
    ).subscribe(() => this.dialogRef.close());
  }

  public selectProductHandler(product: any): void {
    if (!!product.open && product.open > 0) {
      this.quantity = product.open;
    }
    // this.available = product.available;

    this.productUnitApiService.getUnits$(product.type as ProductTypes)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        const allowFractionalValues = unitsList.find(u => u.name === product.unitType).allowFractionalValues;
        this.allowFractionalValues = allowFractionalValues && product.type !== ProductTypes.GOODS;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
