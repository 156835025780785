import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { QuantityCheckMethodEnum } from './enums/quantity-check-method.enum';
import { CommonModalDataModel } from '../../modals-common';

export function getPackUpListColumns(checkMethod: QuantityCheckMethodEnum): TableColumnModelExtended[] {
  const PackUpListColumns: TableColumnModelExtended[] = [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center',
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.BARCODE',
      prop: 'barcode',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.ENTERED_BARCODE',
      prop: 'barcode',
      sortable: true,
      hideWithMethod: QuantityCheckMethodEnum.SERIAL_NUMBER
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.SERIAL_NUMBER',
      prop: 'serialNumber',
      sortable: true,
      hideWithMethod: QuantityCheckMethodEnum.BARCODE
    },
    {
      cellTemplate: 'withStatusLabel',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.STATUS',
      prop: 'status',
      sortable: false,
      cellClass: 'center',
      width: 124,
      maxWidth: 124,
    },
    {
      cellTemplate: 'withClickableIcon',
      name: '',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.DeleteRow,
      icon: 'minus-circle',
      cellClass: 'remove-btn-grey',
      frozenRight: true,
    }
  ];

  return PackUpListColumns.filter(col => col.hideWithMethod !== checkMethod);
}

export const BarcodeMismatchProductModalData: CommonModalDataModel = {
  title: 'WAREHOUSE.MODAL.CHANGE_PRODUCT',
  titleIcon: 'question-mark-circle',
  message: 'WAREHOUSE.MODAL.CHANGE_PRODUCT_MESSAGE',
  confirmBtnText: 'BUTTON.CHANGE_PRODUCT',
  confirmBtnIcon: ''
};

export const ManualToBarcodeModalData: CommonModalDataModel = {
  title: 'WAREHOUSE.MODAL.MANUAL_TO_BARCODE_TITLE',
  titleIcon: 'question-mark-circle',
  message: `WAREHOUSE.MODAL.MANUAL_TO_BARCODE_MESSAGE`,
  confirmBtnText: 'BUTTON.CHANGE',
  confirmBtnIcon: ''
};

export const ManualToSerialNumberModalData: CommonModalDataModel = {
  title: 'WAREHOUSE.MODAL.MANUAL_TO_BARCODE_TITLE',
  titleIcon: 'question-mark-circle',
  message: `WAREHOUSE.MODAL.MANUAL_TO_SERIAL_MESSAGE`,
  confirmBtnText: 'BUTTON.CHANGE',
  confirmBtnIcon: ''
};

export const BarcodeToSerialNumberModalData: CommonModalDataModel = {
  title: 'WAREHOUSE.MODAL.MANUAL_TO_BARCODE_TITLE',
  titleIcon: 'question-mark-circle',
  message: `WAREHOUSE.MODAL.BARCODE_TO_SERIAL_MESSAGE`,
  confirmBtnText: 'BUTTON.CHANGE',
  confirmBtnIcon: ''
};

export const quantityMethodChangeInfo: any = {
  [QuantityCheckMethodEnum.MANUAL]: {
    [QuantityCheckMethodEnum.BARCODE]: {modal: ManualToBarcodeModalData},
    [QuantityCheckMethodEnum.SERIAL_NUMBER]: {modal: ManualToSerialNumberModalData}

  },
  [QuantityCheckMethodEnum.BARCODE]: {
    [QuantityCheckMethodEnum.SERIAL_NUMBER]: {modal: BarcodeToSerialNumberModalData}
  }
};
