import { AfterViewInit, Component, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ShepherdService } from 'angular-shepherd';
import { get } from 'lodash';

import { ProductsService } from 'common/src/modules/products/products.service';
import { FamilyModel, CategoryFamilySelectedEvent, CategoryFamilyModel } from 'common/src/modules/products/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { SoundService } from 'common/src/services/sound.service';
import { WizardService } from 'common/src/modules/wizard';
import { LinearTreeComponent } from '../linear-tree/linear-tree.component';
import { FamilyWizardContext } from '../../family-wizard';
import { AbstractAppComponent } from 'common/src/interfaces';
import { TreeEntityDefinitionModel } from '../linear-tree/tree-entity-definition.model';
import { DeleteFamilyModalComponent } from '../../modals';
import { CategoryModalComponent } from '../../../modals/modals-products/category-modal/category-modal.component';
import { selectCategories } from 'projects/workspace/src/app/products-two-level/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CategoriesState } from 'projects/workspace/src/app/products-two-level/store/reducers';
import { ProductTypes } from '../../product-types';
import { UserPermissionsService } from '../../../../services/user-permissions.service';
import { HintsButtonsModel, HintsModel, HintsWhenModel } from '../../../rnpl-common/models/hints.model';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../../modals/modals-common';
import { PartnersListTabsEnum } from '../../../../../../projects/workspace/src/app/partners/corporate/enums/partners-list-tabs.enum';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { HintsButtonCancelTemplate, HintsButtonGoItTemplate, HintsIndexTimerText } from '../../../rnpl-common/constants/hints-constans';

@Component({
  selector: 'rnpl-families-page',
  templateUrl: './families-page.component.html',
  styleUrls: ['./families-page.component.scss']
})
export class FamiliesPageComponent implements OnInit, OnDestroy {
  @Input() public showTabs: boolean;

  private pageRoute: string;

  public familiesTreeEntity: TreeEntityDefinitionModel = {
    type: 'category',
    subtype: 'subcategory',
    subMultitude: 'subcategories',
    multitude: 'categories'
  };

  public familyId: string;

  public familiesTree: Array<FamilyModel> = [];

  public family: FamilyModel;

  readonly productType$: BehaviorSubject<ProductTypes> = new BehaviorSubject<ProductTypes>(null);
  readonly familiesTreeByProductType$: BehaviorSubject<CategoriesState> = new BehaviorSubject<CategoriesState>(null);
  readonly updatedAt$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @ViewChild('famTree', {static: true})
  private linearTreeComp: LinearTreeComponent;

  private _requestParams = {} as HttpParams;
  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private router: Router,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              private productsService: ProductsService,
              private userPermissionsService: UserPermissionsService,
              private wizardService: WizardService,
              private toasterService: ToasterService,
              private soundService: SoundService,
              private location: Location,
              private readonly store: Store<AppState>,
              @Optional() private app: AbstractAppComponent) {

    this.router.events
      .pipe(takeUntil(this._destroy))
      .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.familyId = this.route.snapshot.params.categoryId;
        if (this.familyId) {
          this.linearTreeComp.openNodeById(this.familyId);
        }
      }
    });
  }

  ngOnInit(): void {
    this.store.select(selectCategories)
      .pipe(takeUntil(this._destroy))
      .subscribe((categories) => {
        this.familiesTreeByProductType$.next(categories);
      });

    this.route.paramMap
      .pipe(takeUntil(this._destroy))
      .subscribe(params => {
        this.productType$.next(params.get('productType') as ProductTypes);
        this._requestParams['products_type'] = params.get('productType');
        this.family = null;
        const familyTreeByProductType = this.familiesTreeByProductType$.getValue()[this._requestParams['products_type']];

        if (familyTreeByProductType && familyTreeByProductType.length) {
          this.familiesTree = familyTreeByProductType;
          this.openSelectedFamily();
          this.loadFamiliesTree(true);
        } else {
          this.loadFamiliesTree();
        }

      });

    if (this.app && this.app.projectName === 'workspace') {
      this.pageRoute = 'products/settings/categories';
    } else {
      this.pageRoute = 'products/categories';
    }
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
    // clearInterval(this.countdownTimer);
    // this.shepherdService.cancel();
  }

  public familySelected(event: CategoryFamilySelectedEvent): void {
    this.family = event.selectedNode;

    const url = `/${this.pageRoute}/${this._requestParams['products_type']}/${this.family.id}`;
    this.location.go(url);
  }

  public deleteFamily(family: FamilyModel): void {
    if (!this.userPermissionsService.canEditProducts()) {
      this.toasterService.notify({
        type: 'error',
        message: 'COMMON.NO_ACCESS_PRODUCTS'
      });
      return;
    }
    this.family = family;

    const dialog = this.dialog.open(DeleteFamilyModalComponent, {
      data: {family: this.family}
    });

    dialog.afterClosed()
      .subscribe( result => {
      if (result) {
        const ancestors = this.linearTreeComp.getParents(this.family);

        if (ancestors.length) {
          this.familyId = ancestors[0].id;
        } else {
          this.family = null;
          this.familyId = null;
        }

        this.loadFamiliesTree();
      }
    });
  }

  public createFamily(parentFamily: FamilyModel): void {
    if (!this.userPermissionsService.canEditProducts()) {
      this.toasterService.notify({
        type: 'error',
        message: 'COMMON.NO_ACCESS_PRODUCTS'
      });
      return;
    }
    const dialog = this.dialog.open(CategoryModalComponent, {
      data: {
        family: {
          parent_id: parentFamily.id,
          products_type: parentFamily.products_type,
        },
        isRoot: false,
      },
      disableClose: true,
    });
    dialog.afterClosed().subscribe((response) => {
      if (response) {
        this.loadFamiliesTree();
      }
    });
  }

  public createBasicFamily({parentId, productType}): void {
    if (!this.userPermissionsService.canEditProducts()) {
      this.toasterService.notify({
        type: 'error',
        message: 'COMMON.NO_ACCESS_PRODUCTS'
      });
      return;
    }
    const dialog = this.dialog.open(CategoryModalComponent, {
      data: {
        family: {
          parent_id: parentId,
          products_type: productType,
        },
        isRoot: true,
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((response) => {
      if (response) {
        this.loadFamiliesTree();
      }
    });
  }

  public editFamily(family: FamilyModel, step = 'products-layout'): void {
    if (!this.userPermissionsService.canEditProducts()) {
      this.toasterService.notify({
        type: 'error',
        message: 'COMMON.NO_ACCESS_PRODUCTS'
      });
      return;
    }
    this.wizardService.edit<FamilyWizardContext>('family',
      { family: family },
      this.location.path(),
      step);
  }

  public editFamilyForm(args: {node: CategoryFamilyModel, step: string}): void {
    this.editFamily(args.node, args.step);
  }

  public openSelectedFamily(): void {
    // open first family in familyTree if there is no active family
    if (!this.family && !this.familyId && this.familiesTree.length) {
      this.familyId = this.familiesTree[0].id;
    }

    if ((!this.family && this.familyId) || (this.family && this.family.id)) {
      setTimeout(() => {
        this.linearTreeComp.openNodeById(get(this, 'family.id', this.familyId));
      }, 50);
    }
  }

  public loadFamiliesTree(preventLoading = false): void {
    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    const params = new HttpParams().set('products_type', this._requestParams['products_type'])

    this.productsService.getFamiliesTree(params)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        this.familiesTree = response.data;

        this.openSelectedFamily();

        this.isLoading$.next(false);
        this.updatedAt$.next(new Date());

      }, () => {
        this.updatedAt$.next(null);
      });
  }
}
