import { Component, OnInit, TemplateRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { HrmService } from '../../hrm.service';
import { NavBarBtnModel, NavigationItemModel, UIStatesEnum } from 'common/src/models';
import { CommonModalsActionsEnum, DangerModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import {
  EMPLOYEE_NAV_ITEMS,
  getMyProfileNavBarButtons,
  getUserNavBarButtons,
  MY_PROFILE_NAV_ITEMS,
  STATUS_CLASSES
} from './employee-profile.config';
import { getLinkQueueState } from '../../../store/selectors/app.selectors';
import { AppState } from '../../../store/state/app.state';
import { isShouldRefresh, selectTeamsUser, selectTeamsUserCurrentState, selectTeamsUserUpdatedAt } from '../../store/selectors';
import { UserProfileModel } from '../../models';
import { UserListTabs } from '../user-list/enums/user-list-tabs.enum';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';

@Component({
  selector: 'rnpl-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit, OnDestroy {

  public dialogRef: MatDialogRef<TemplateRef<any>>;

  public userId: number;
  public userData: UserProfileModel;
  public navItems: NavigationItemModel[] = EMPLOYEE_NAV_ITEMS;
  public myProfileNavItems: NavigationItemModel[] = MY_PROFILE_NAV_ITEMS;
  public selectedCategory: string = 'Profile';
  public selectedFormType: string = 'Profile';
  public isMyProfile: boolean = false;
  public editingMode: boolean = false;

  readonly statusClasses: {[key: string]: string} = STATUS_CLASSES;
  public navBarButtons: NavBarBtnModel[] = getUserNavBarButtons(UIStatesEnum.VIEW);
  public myProfileNavBarButtons: NavBarBtnModel[] = getMyProfileNavBarButtons(UIStatesEnum.VIEW);
  public UIStates = UIStatesEnum;

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private previousLink$: BehaviorSubject<string> = new BehaviorSubject(null);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly userProfileUpdatedAt$: Observable<Date> = this.store.select(selectTeamsUserUpdatedAt).pipe(takeUntil(this.destroy$));
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private hrmService: HrmService,
    private titleService: Title,
    private translateService: TranslateService,
    private actionButtonsService: ActionButtonsService,
    private readonly store: Store<AppState>,
  ) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.userId = this.route.snapshot.params.userId;
          this.isMyProfile = this.route.snapshot.data.myProfile;
        }
    });
  }

  ngOnInit() {
    this.store.select(selectTeamsUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.userData = user;
        this.isLoading$.next(false);
        if (this.isMyProfile) {
          this.titleService.setTitle(this.translateService.instant('BUTTON.MY_PROFILE'));
        } else {
          this.titleService.setTitle(`${this.translateService.instant('APP.EMPLOYEES')}: ${get(user, 'profile.runpleId') || get(user, 'profile.id')}`);
        }
      });

    this.store.select(getLinkQueueState)
      .pipe(
        filter((linkQueue) => linkQueue.length >= 2),
        map((linkQueue) => linkQueue[linkQueue.length - 2].link),
        takeUntil(this.destroy$)
      )
      .subscribe((linkQueue) => {
        this.previousLink$.next(linkQueue);
      });

    this.store.select(selectTeamsUserCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
        this.navBarButtons = getUserNavBarButtons(state);
        this.myProfileNavBarButtons = getMyProfileNavBarButtons(state);
      });

    this.store.select(isShouldRefresh)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isShouldRefresh: boolean) => {
        if (isShouldRefresh) {
          this.loadUserProfile(true);
        }
      });
  }

  public loadUserProfile(preventLoading = false): void {
    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    this.hrmService.getUserProfileAndBlueprintsV2(this.userData.profile.id)
      .pipe(
        finalize(() =>  this.isLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public actionsEvent(e: string): void {
    if (this[e]) {
      this[e]();
    }
  }

  public onDeleteClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETE',
        message: this.translateService.instant(
          'TEAM.DELETE_USER',
          {
            firstName: this.userData.profile.firstName,
            lastName: this.userData.profile.lastName
          }
        ),
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeleteUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.changeUserStatus(UserListTabs.Deleted);
        }
      });
    this.btnToClearLoadingStatus$.next('onDeleteClick')
  }

  public onDeletePermanentlyClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETE',
        message: this.translateService.instant(
          'TEAM.DELETE_PERMANENTLY_USER',
          {
            firstName: this.userData.profile.firstName,
            lastName: this.userData.profile.lastName
          }
        ),
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeletePermanentlyClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.hrmService.deleteUser(this.userId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.goBack());
        }
      });

  }

  public onDeactivateClick(): void {
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.DEACTIVATE',
        message: this.translateService.instant(
          'TEAM.DEACTIVATE_USER',
          {
            firstName: this.userData.profile.firstName,
            lastName: this.userData.profile.lastName
          }
        ),
        confirmBtnIcon: 'checkmark',
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeactivateUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.changeUserStatus(UserListTabs.Inactive);
        }
      });
    this.btnToClearLoadingStatus$.next('onDeactivateClick')
  }

  public onActivateClick(): void {
    if (this.actionButtonsService.isActionDisabledByPlan()) { return; }

    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.ACTIVATE',
        message: this.translateService.instant(
          'TEAM.ACTIVATE_USER',
          {
            firstName: this.userData.profile.firstName,
            lastName: this.userData.profile.lastName
          }
        ),
        confirmBtnIcon: 'checkmark',
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onActivateUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.changeUserStatus(UserListTabs.Active);
        }
      });
    this.btnToClearLoadingStatus$.next('onActivateClick')
  }

  public onRestoreClick(): void {
    if (this.actionButtonsService.isActionDisabledByPlan()) { return; }

    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.RESTORE',
        message: this.translateService.instant(
          'TEAM.RESTORE_USER',
          {
            firstName: this.userData.profile.firstName,
            lastName: this.userData.profile.lastName
          }
        ),
        confirmBtnText: 'BUTTON.RESTORE',
        confirmBtnIcon: 'checkmark',
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onRestoreUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.changeUserStatus(UserListTabs.Inactive);
        }
      });
    this.btnToClearLoadingStatus$.next('onRestoreClick')
  }

  public onEditClick(): void {
    if (this.isInactive || this.isDeleted || this.editingMode) { return; }

    this.hrmService.userProfileSetEdit(this.userData.profile.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onEditClick')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public onFinishEditingClick() {
    this.hrmService.userProfileUnsetEdit(this.userData.profile.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onFinishEditingClick')),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public goBack(): void {
    if (this.previousLink) {
      this.router.navigateByUrl(this.previousLink);
    } else {
      this.router.navigate(['team/employee/list/active/1']);
    }
  }

  public changeUserStatus(status: UserListTabs, skipWarnings = false): void {
    this.hrmService.changeUserStatus(this.userId, status, skipWarnings)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {

      },error => {
        this.handlePopupErrors(error, status);
      });
  }

  private handlePopupErrors(error: HttpErrorResponse, status: UserListTabs): void {
    switch (error.error.message) {
      default:
        this.displayMessage(error.error.message, 'error');
        break;
    }
  }

  public displayMessage(message: string, type: string = 'success') {
    this.toasterService.notify({ type, message });
  }

  closeEditingOrGoBack() {
    this.goBack();
  }

  get previousLink(): string {
    return this.previousLink$.getValue();
  }

  get isEditingFlag(): boolean {
    return !!get(this, 'userData.profile.flags', []).includes('editing');
  }

  get isInactive(): boolean {
    return get(this, 'userData.profile.status') === UserListTabs.Inactive;
  }

  get isDeleted(): boolean {
    return get(this, 'userData.profile.status') === UserListTabs.Deleted;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
