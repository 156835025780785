import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'rnpl-hello-subscription-activation',
  templateUrl: './hello-subscription-activation.component.html',
  styleUrls: ['./hello-subscription-activation.component.scss']
})
export class HelloSubscriptionActivationComponent {

  @Output() startActivationSubscriptionEmit: EventEmitter<any> = new EventEmitter<any>();

  public firstName: string = this.authService.getUser().firstName;

  constructor(private authService: AuthService) {
  }

  public startActivationSubscription(): void {
    this.startActivationSubscriptionEmit.emit();
  }

}
