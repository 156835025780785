import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import { ColumnTable } from '../../models/column-model';

@Component({
  selector: 'rnpl-column-header-add-column-button',
  templateUrl: './column-header-add-column-button.component.html',
  styleUrls: ['./column-header-add-column-button.component.scss']
})
export class ColumnHeaderAddColumnButtonComponent implements OnChanges {

  @Input() column: ColumnTable;

  @Output() addColumnClick: EventEmitter<string> = new EventEmitter<string>();

  public disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('column')) {
      this.disabled = get(this.column, 'disabled', false);
    }
  }

  public onClickAddButton(): void {
    if (this.disabled) { return; }
    this.addColumnClick.emit();
  }

}
