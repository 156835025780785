export enum ChangeStatusOperationsEnum {
  DELETE = 'delete',
  CANCEL = 'cancel',
  RESTORE = 'restore',
  CREATE = 'create',
  PROCEED = 'proceed',
  CLONE = 'clone',
  UNLINK = 'unlink',
  LINK = 'link',
  APPROVE = 'approve',
  DISAPPROVE = 'disapprove',
  DECLINE = 'decline',
  CORRECTION = 'correction',
  BOOK = 'book',
}
