import { ColumnTypeEnum, DateColumnType, TableColumnModelExtended } from '../../../../models/table-column.model';
import { CommonModalDataModel } from '../../modals-common';

export function getReceivedGoodsColumns(confirmAllGoods = false): TableColumnModelExtended[]{
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 132,
      maxWidth: 132,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PRODUCT_NAME',
      preventTranslate: true,
      cellClass: 'link-primary inline',
      prop: 'productNameLink',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      width: 256,
      maxWidth: 256,
      sortable: true,
      type: ColumnTypeEnum.TEXT,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.RECEIVED',
      prop: 'delivered',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 98,
      maxWidth: 98,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      columnId: 'warehouseColumnModal',
      name: 'BUTTON.RETURN',
      prop: 'return',
      sortable: true,
      type: ColumnTypeEnum.STEPPER,
      decimal: 0,
      disabled: confirmAllGoods,
      width: 172,
      maxWidth: 172,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BATCH',
      prop: 'batchRunpleId',
      sortable: true,
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.EXPIRATION_DATE',
      prop: 'expirationDate',
      sortable: true,
      width: 288,
      maxWidth: 288,
      type: ColumnTypeEnum.DATE,
      dateType: DateColumnType.WITHOUT_TIME,
    },
  ];
}

export const MismatchedAmountModalData: CommonModalDataModel = {
  title: 'WAREHOUSE.INCOMING_DELIVERY.MODAL.PRODUCT_CANNOT_BE_RETURNED',
  message: 'WAREHOUSE.INCOMING_DELIVERY.MODAL.PRODUCT_CANNOT_BE_RETURNED_MESSAGE',
  hideCancelBtn: true,
  titleIcon: 'alert-triangle',
  titleColor: 'yellow-400',
  confirmBtnText: 'BUTTON.CLOSE',
  confirmBtnIcon: 'close'
};

