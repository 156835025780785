import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, startWith, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, interval, ReplaySubject } from 'rxjs';
import { isEmpty, isEqual } from 'lodash';
import { Store } from '@ngrx/store';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { StripePaymentService } from 'projects/workspace/src/app/shared/services';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { SubscriptionActivationTabEnum, SubscriptionPlanEnum } from '../subscription-activation.emum';
import { SubscriptionActivationPricesModel } from '../subscription-activation-prices.model';
import { ToasterService } from '../../ui-components/toaster';
import { BACKGROUND_CONTENT, TimeOfDay } from '../../modals/modals-sign-in/sign-in.config';

@Component({
  selector: 'rnpl-subscription-activation',
  templateUrl: './subscription-activation.component.html',
  styleUrls: ['./subscription-activation.component.scss']
})
export class SubscriptionActivationComponent implements OnInit, OnDestroy, AfterViewInit {
  public activationError: boolean = false;
  public today: Date = new Date();
  public companyProfile: CompanyProfile;
  public form: FormGroup;
  public subscriptionPrices: SubscriptionActivationPricesModel;
  public promoCodeControl: FormControl = new FormControl();

  public backGroundContent = BACKGROUND_CONTENT;
  public timeOfDay = TimeOfDay();

  public activationTabEnum = SubscriptionActivationTabEnum;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public activeTab$: BehaviorSubject<SubscriptionActivationTabEnum> =
    new BehaviorSubject<SubscriptionActivationTabEnum>(SubscriptionActivationTabEnum.HELLO);
  public activateSubscriptionRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public continueRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public tabsDataByActiveTab = {
    [SubscriptionActivationTabEnum.SUBSCRIPTION_OPTIONS]: {
      title: 'SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION',
      description: 'SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION_OPTIONS_DESC',
      tabName: SubscriptionActivationTabEnum.SUBSCRIPTION_OPTIONS,
    },
    [SubscriptionActivationTabEnum.CARD_DETAILS]: {
      title: 'Specify card details',
      description: 'SUBSCRIPTION_PLAN.BILLING_OPTIONS_DESC',
      tabName: SubscriptionActivationTabEnum.CARD_DETAILS,
    },
  };

  @ViewChild('subscriptionOptionsComponent', {static: false}) public subscriptionOptionsComponentRef;
  @ViewChild('perfectScrollbarSummary', {static: false}) public perfectScrollbarSummaryRef;
  @ViewChild('perfectScrollbarContent', {static: false}) public perfectScrollbarContentRef;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionActivationComponent>,
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public stripePaymentService: StripePaymentService,
    public store: Store<AppState>,
    private router: Router,
    public administrationsApiService: AdministrationsApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      activeTab: SubscriptionActivationTabEnum,
      initStripe: boolean,
    }
  ) {
    this.initForm();
    if (this.data) {
      if (this.data.activeTab) {
        this.activeTab$.next(this.data.activeTab);
      }
      if (this.data.initStripe) {
        this.initStripe();
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // fix scroll visibility issue
      this.perfectScrollbarSummaryRef.directiveRef.update();
      this.perfectScrollbarContentRef.directiveRef.update();
    }, 300);
  }

  ngOnInit() {
    this.store
      .select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        filter(profile => !isEmpty(profile)),
        takeUntil(this.destroy$)
      ).subscribe((profile: CompanyProfile) => {
        this.companyProfile = profile;
        this.form.patchValue(profile, {emitEvent: true});
      });
  }

  public initForm(): void {
    this.form = this.fb.group({
      subscriptionManagement: this.fb.group(({
        userChannelOptions: this.fb.group({
          wholesalesEnabled: [],
          ecommerceEnabled: [],
          servicesEnabled: [],
        }),
        subscriptionPlan: [],
      })),
      billingDetails: this.fb.group(({
        billingFrequency: [],
        paymentMethod: [],
      }))
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getPrices());

    this.subscriptionPlanControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: SubscriptionPlanEnum) => {
        if (value === SubscriptionPlanEnum.TEAM_MONTHLY && value === this.companyProfile.subscriptionManagement.subscriptionPlanActive) {
          this.form.get('subscriptionManagement').get('userChannelOptions')
            .patchValue(this.companyProfile.subscriptionManagement.userChannelOptions, {emitEvent: false});
        } else {

        }
        this.getPrices();
      });
  }

  public getPrices(): void {
    this.stripePaymentService.getCompanySubscriptionPrices(this.getUpdatedCompanyProfile())
      .pipe(takeUntil(this.destroy$))
      .subscribe((prices: SubscriptionActivationPricesModel) => this.subscriptionPrices = prices);
  }

  public getUpdatedCompanyProfile(forcePromoCode = false): CompanyProfile {
    if (!this.companyProfile) { return null; }
    const subscriptionManagementData = this.form.getRawValue();
    return {
      ...this.companyProfile,
      subscriptionManagement: {
        ...this.companyProfile.subscriptionManagement,
        promoCode: (
          !forcePromoCode
          && this.promoCodeControl.errors
          && (this.promoCodeControl.errors.validation || this.promoCodeControl.errors.notChecked)
        )
          ? null
          : this.promoCodeControl.value,
        subscriptionPlan: subscriptionManagementData.subscriptionManagement.subscriptionPlan,
        userChannelOptions: {
          ...this.companyProfile.subscriptionManagement.userChannelOptions,
          ...subscriptionManagementData.subscriptionManagement.userChannelOptions
        }
      },
      billingDetails: {
        ...this.companyProfile.billingDetails,
        ...subscriptionManagementData.billingDetails
      }
    };
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public selectTab(tabName: SubscriptionActivationTabEnum): void {
    if (!!tabName && this.activeTab$.getValue() !== tabName) {
      this.activeTab$.next(tabName);
    }
  }

  public openCardDetails(): void {
    if (this.salesChannelsValidation()) {
      return;
    }
    this.continueRequest$.next(true);

    this.administrationsApiService.updateCompanyProfile(this.getUpdatedCompanyProfile())
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initStripe(() => {
          this.selectTab(this.activationTabEnum.CARD_DETAILS);
          this.continueRequest$.next(false);
        });
      });
  }

  public initStripe(cbFunction?: Function): void {
    this.stripePaymentService.initStripe(this.promoCodeControl.value, cbFunction);
  }

  public cancelStripeRequest(): void {
    this.stripePaymentService.cancelPaymentIntent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectTab(this.activationTabEnum.SUBSCRIPTION_OPTIONS);
      });
  }

  public applyPromoCode(): void {
    this.stripePaymentService.getCompanySubscriptionPrices(this.getUpdatedCompanyProfile(true))
      .pipe(takeUntil(this.destroy$))
      .subscribe((prices: SubscriptionActivationPricesModel) => {
        this.subscriptionPrices = prices;
        this.promoCodeControl.setErrors({notChecked: false});
      }, (error) => {
        this.promoCodeControl.setErrors({validation: error.error.errors[0], notChecked: false});
      });
  }

  public resetPromoCodeErrors(): void {
    this.promoCodeControl.setErrors({notChecked: true, validation: false});
  }

  public activateSubscription(): void {
    if (this.salesChannelsValidation()) {
      return;
    }

    this.activateSubscriptionRequest$.next(true);
    this.toasterService.notifyRequestMessage(
      {key: 'subscriptionActivationRequest', message: 'SUBSCRIPTION_ACTIVATION.SUBSCRIPTION_IS_ACTIVATED'}
    );

    if (this.isFreePlan) {
      this.administrationsApiService.updateCompanyProfile(this.getUpdatedCompanyProfile(), '', true)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          interval(5000)
            .pipe(
              startWith(0),
              takeWhile(() => {
                return this.companyProfile.status === 'PAUSED'
                  || this.companyProfile.subscriptionManagement.subscriptionStatus !== 'ACTIVATED';
              }),
              switchMap(() => this.administrationsApiService.getCompanyProfile()),
              takeUntil(this.destroy$)
            )
            .subscribe((profile: CompanyProfile) => {
              if (profile.status !== 'PAUSED' && profile.subscriptionManagement.subscriptionStatus === 'ACTIVATED') {
                this.toasterService.hideRequestMessage('subscriptionActivationRequest');
                this.activateSubscriptionRequest$.next(false);
                this.closeOnSuccess();
              }
            });
        });
      return;
    }

    if (!this.isFreePlan) {
      const failedCallback = () => {
        this.toasterService.hideRequestMessage('subscriptionActivationRequest');
        this.activateSubscriptionRequest$.next(false);
        this.activationError = true;
      };
      this.stripePaymentService.confirmPayment(failedCallback);
    }
  }

  public salesChannelsValidation(): boolean {
    return this.subscriptionOptionsComponentRef.salesChannelsValidation();
  }

  public startActivationSubscription(): void {
    this.activeTab$.next(SubscriptionActivationTabEnum.SUBSCRIPTION_OPTIONS);
  }

  public closeOnSuccess(): void {
    this.dialogRef.close();
    this.router.navigate(['/']);
    this.toasterService.notify({
      type: 'activate-subscription',
      icon: 'checkmark-circle',
      title: 'SUBSCRIPTION_PLAN.YOUR_SUBSCRIPTION_IS_NOW_ACTIVE',
      message: 'SUBSCRIPTION_PLAN.YOUR_SUBSCRIPTION_IS_NOW_ACTIVE_DESCR',
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get isFreePlan(): boolean {
    return this.subscriptionPlanControl.value === SubscriptionPlanEnum.FREE;
  }

  get billingFrequencyControl(): FormControl { return this.form.get('billingDetails').get('billingFrequency') as FormControl; }
  get subscriptionPlanControl(): FormControl { return this.form.get('subscriptionManagement').get('subscriptionPlan') as FormControl; }

}
