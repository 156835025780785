import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { ProductModel, ProductsService } from '../../../products';
import { getProductSearchValue } from '../../../rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { ProductTypes } from '../../../products/product-types';

@Component({
  selector: 'rnpl-filters-control-product',
  templateUrl: './filters-control-product.component.html',
})
export class FiltersControlProductComponent extends FiltersControlBaseComponent implements OnInit {

  @Input() availableProductTypes: ProductTypes[];

  public defaultAvailableProductTypes = [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL];
  public productsList: ProductModel[] = [];
  public productType = PRODUCTS_TYPE;
  public productTypes: typeof ProductTypes = ProductTypes;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private productsService: ProductsService,
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.getProducts();
  }

  private getProducts(): void {
    this.showDropdownSpin$.next(true);
    this.productsService.getActiveProducts()
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((products: ProductModel[]) => {
        this.productsList = this.prepareProductsList(products);
      });
  }

  public prepareProductsList(products): ProductModel[] {
    return products
      .filter(item => (
        this.availableProductTypes
          ? !!this.availableProductTypes.includes(item.type)
          : !!this.defaultAvailableProductTypes.includes(item.type)
      ))
      .map(item => ({
        ...item,
        searchLabel: getProductSearchValue(item, this.translateService)
      }));
  }

}
