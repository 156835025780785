import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, } from '@angular/router';
import { Location } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProductsService } from '../../products.service';
import { AttributeModel } from '../../../system-settings';
import { ToasterService } from '../../../ui-components/toaster';
import { FamilyAttrsPageColumnsList } from './family-attrs-page-columns-list';

@Component({
  selector: 'rnpl-family-attrs-page',
  templateUrl: './family-attrs-page.component.html',
  styleUrls: ['./family-attrs-page.component.scss']
})
export class FamilyAttrsPageComponent implements OnInit, OnDestroy {

  public isLoading = false;
  public tableColumns = FamilyAttrsPageColumnsList;
  public familyId: string;
  public attributes: Array<AttributeModel> = [];

  private _destroy: ReplaySubject<any> = new ReplaySubject(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private productsService: ProductsService,
    private toasterService: ToasterService
  ) {

  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this._destroy))
      .subscribe(params => {
        if (params.get('familyId')) {
          this.familyId = params.get('familyId');
          this.getAllFamilyAttributes(this.familyId);
        }
      });
  }

  private getAllFamilyAttributes(familyId: string): void {
    this.isLoading = true;
    this.productsService.getAllFamilyAttributes(familyId)
      .pipe(takeUntil(this._destroy))
      .subscribe(attrs => {
        this.isLoading = false;
        this.attributes = this.getUniqueListBy(attrs.flat(), 'id');
      }, error => {
        this.isLoading = false;
        this.toasterService.notify({
          type: 'error', message: error.error.message
        });
      });
  }

  /**
   * Remove duplicates from array
   */
  private getUniqueListBy(arr: Array<any>, key: string): Array<any> {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  public goBack(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }

}
