import { AfterViewChecked, AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { ShepherdService } from 'angular-shepherd';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { POGoodsToConfirmType } from 'projects/workspace/src/app/purchase-order/models';
import { TradePositionModel } from 'projects/workspace/src/app/trade/models';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { getOpenGoodsColumns } from './incoming-delivery-products-receipt-modal.config';
import {
  IncomingDeliveryApiService
} from 'projects/workspace/src/app/warehouse/modules/incoming-delivery/services/incoming-delivery-api.service';
import { StockAreaEnum } from 'projects/workspace/src/app/warehouse/modules/transactions/enums';
import { PauseModalsHintsComponent } from '../../modals-subscription/pause-modals-hints/pause-modals-hints.component';

interface DataType {
  exchangeId?: number;
  poId?: number;
  fromPo?: boolean;
}

@Component({
  selector: 'rnpl-incoming-delivery-products-receipt-modal',
  templateUrl: './incoming-delivery-products-receipt-modal.component.html'
})
export class IncomingDeliveryProductsReceiptModalComponent extends BaseModalComponent implements OnInit, OnDestroy {

  public confirmAllGoods: boolean = false;
  public receiptAndWarehouse: boolean = true;

  public columnsResaleGoods: TableColumnModelExtended[] = getOpenGoodsColumns(
    this.confirmAllGoods,
    false,
    this.receiptAndWarehouse,
  );

  public columnsInternalUsageGoods: TableColumnModelExtended[] = getOpenGoodsColumns(
    this.confirmAllGoods,
    true,
    this.receiptAndWarehouse,
  );

  public openGoods: TradePositionModel[] = [];

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  readonly confirmRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @ViewChild('hint', { static: false }) hint: PauseModalsHintsComponent;

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public purchaseOrderApiService: PurchaseOrderApiService,
    public incomingDeliveryApiService: IncomingDeliveryApiService,
    public exchangeApiService: ExchangeApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IncomingDeliveryProductsReceiptModalComponent>,
    // public shepherdService: ShepherdService,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);
  }

  ngOnInit() {
    if (this.data.poId) {
      this.purchaseOrderApiService.getPOGoodsToConfirm(this.data.poId)
        .pipe(takeUntil(this._destroy))
        .subscribe((response) => {
          this.openGoods = this.prepareProducts(response.open);
          this.isLoading$.next(false);
        });
    }

    if (this.data.exchangeId) {
      this.exchangeApiService.getExchangeProductsToReceipt(this.data.exchangeId)
        .pipe(takeUntil(this._destroy))
        .subscribe((response) => {
          this.openGoods = this.prepareProducts(response.open);
          this.isLoading$.next(false);
        });
    }
  }

  // ngAfterViewChecked(): void {
  //   window.dispatchEvent(new Event('resize'));
  // }

  public onGoToProduct(service: TradePositionModel): void {
    window.open(window.location.origin + `/products/product-view/${service.productId}`, '_blank');
  }

  public prepareProducts(products): any {
    return [...products.map((item) => {
      return {
        ...item,
        productLink: {
          label: item.productRunpleId,
          routerLink: `/products/product-view/${item.productId}`
        },
        productNameLink: {
          label: item.description,
          routerLink: `/products/product-view/${item.productId}`
        }
      };
    })];
  }

  public actionsEvent(e: string): void {
    if (this[e]) {
      this[e]();
    }
  }

  public onEditTableCell(
    val: { value: string | number; cell: string; rowIndex: number, row: any }
  ): void {
    if (val.cell === 'batch') {
      if (!val.value) {
        this.setLocationsList(val.row, []);
        return;
      }

      const location = this.data.poId
        ? StockAreaEnum.GENERAL_STOCK
        : StockAreaEnum.RETURNS_STOCK;

      this.incomingDeliveryApiService.getAvailableStorageObjects(location, val.row.productId, val.row.batch)
        .pipe(takeUntil(this._destroy))
        .subscribe(locationsList => {
          this.setLocationsList(val.row, locationsList);
        });
    }
  }

  public setLocationsList(row, locationsList): void {
    const changedIndex = this.openGoods
      .findIndex((itm) => itm.id === row.id);
    this.openGoods[changedIndex].binLocations = locationsList;
    this.openGoods[changedIndex].binLocationId = null;
    this.openGoods = [...this.openGoods];
  }

  public confirmAllGoodsChanged(): void {
    this.openGoods = [...this.openGoods.map((good) => {
      return {
        ...good,
        delivered: good.ordered,
      };
    })];
    this.updateColumns();
  }

  public updateColumns(): void {
    this.columnsResaleGoods = getOpenGoodsColumns(this.confirmAllGoods, false, this.receiptAndWarehouse);
    this.columnsInternalUsageGoods = getOpenGoodsColumns(this.confirmAllGoods, true, this.receiptAndWarehouse);
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  public confirmReceipt(): void {
    if (this.confirmRequest$.getValue()) { return; }
    this.confirmRequest$.next(true);

    const goodsConfirm: POGoodsToConfirmType = {
      open: this.openGoods,
      receiptAndWarehouse: this.receiptAndWarehouse
    };

    setTimeout(() => { // wait for steppers to update
      const request$: Observable<any> = this.data.poId
        ? this.purchaseOrderApiService.confirmPOGoods(this.data.poId, goodsConfirm)
        : this.exchangeApiService.confirmExchangeProducts(this.data.exchangeId, goodsConfirm as any);

      request$
        .pipe(
          finalize(() => { this.confirmRequest$.next(false); }),
          takeUntil(this._destroy)
        )
        .subscribe(response => {
            this.dialogRef.close(response);
          // }
        });
    }, 1500);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get internalUsageGoods(): TradePositionModel[] {
    return this.openGoods.filter(g => g.internalUsage);
  }

  get resaleUsageGoods(): TradePositionModel[] {
    return this.openGoods.filter(g => !g.internalUsage);
  }

}
