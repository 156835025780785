export const TabWarehouse = [
  {
    label: 'TAB.ACTIVE',
    tabName: 'active',
    link: '../../active/1',
    icon: 'bulb',
    count: 0
  },
  {
    label: 'TAB.INACTIVE',
    tabName: 'inactive',
    link: '../../inactive/1',
    icon: 'bulb-off',
    count: 0
  },
  {
    label: 'TAB.BLOCKED',
    tabName: 'blocked',
    link: '../../blocked/1',
    icon: 'blocked',
    count: 0
  },
  {
    label: 'TAB.DELETED',
    tabName: 'deleted',
    link: '../../deleted/1',
    icon: 'trash-2',
    count: 0
  }
];
