import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { PurchaseOrderApiService } from '../../services/purchase-order-api.service';
import { AppState } from '../../../store/state/app.state';
import { PurchaseOrder, PurchaseOrderPropertiesModel } from '../../models';
import { FormInputChangedModel } from '../../../shared/models';
import { selectPoCurrentState } from '../../store/selectors';
import { UIStatesEnum } from 'common/src/models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { PartnersTypeEnum } from '../../../partners/corporate/enums';

@Component({
  selector: 'rnpl-business-partner',
  templateUrl: './business-partner.component.html',
  styleUrls: ['./business-partner.component.scss']
})
export class BusinessPartnerComponent implements OnInit {
  public form: FormGroup;
  public isReadonly: boolean = true;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() public purchaseOrder: PurchaseOrder;

  constructor(
    private poApi: PurchaseOrderApiService,
    private readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initForm(this.purchaseOrder.properties)
    this.trackPurchaseOrderStateChanges();
  }

  private trackPurchaseOrderStateChanges(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);

      if (this.hasLinkedIIN && this.partnerType.value === PartnersTypeEnum.GENERAL) {
        this.partnerType.disable(opts);
        this.companyId.disable(opts);
        this.generalPartnerName.disable(opts);
      }

      this.cdr.detectChanges();
    }
  }

  public initForm(properties: PurchaseOrderPropertiesModel = {} as PurchaseOrderPropertiesModel): void {
    this.form = this.fb.group({
      partnerType: [get(properties, 'partnerType'), [Validators.required]],
      generalPartnerName: [get(properties, 'generalPartnerName'), [Validators.required]],
      generalPartnerVat: [get(properties, 'generalPartnerVat')],
      companyId: [get(properties, 'company.id'), [Validators.required]],
    });
    this.cdr.detectChanges();
  }

  public updatePartnerField(partnerData): void {
    this.poApi.updatePO(
      this.purchaseOrder.id,
      {
        fieldValue: {
          type: partnerData.partnerType,
          id: partnerData.partnerId,
          updatePositions: false,
          updateDocument: false
        },
        fieldName: 'properties.' + partnerData.partnerIdFieldName
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  public updateProperties(field: FormInputChangedModel): void {
    field.fieldName = 'properties.' + field.fieldName;
    this.poApi.updatePO(this.purchaseOrder.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  get hasLinkedIIN(): boolean {
    if (!get(this.purchaseOrder, 'linkedDocuments')) { return false; }
    return !!get(this.purchaseOrder, 'linkedDocuments')
      .filter(doc => doc.type === DocumentTypesUppercaseEnum.INCOMING_INVOICE).length;
  }

  get partnerType(): FormControl { return this.form.get('partnerType') as FormControl; }
  get companyId(): FormControl { return this.form.get('companyId') as FormControl; }
  get generalPartnerName(): FormControl { return this.form.get('generalPartnerName') as FormControl; }
  get generalPartnerVat(): FormControl { return this.form.get('generalPartnerVat') as FormControl; }


}
