import { createSelector } from '@ngrx/store';

import { WarehouseFeatureKey, WarehousesStore, WarehouseState } from '../reducers';
import { AppState } from '../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[WarehouseFeatureKey]);

export const selectBinLocationsList = createSelector(
  selectFeature,
  (state: WarehousesStore) => state.binLocationsList.binLocations
);

export const selectStockStatusList = createSelector(
  selectFeature,
  (state: WarehousesStore) => state.list.stockStatus
);

export const selectReturnStockStatusList = createSelector(
  selectFeature,
  (state: WarehousesStore) => state.list.returnStockStatus
);

export const selectWarehouseEntity = createSelector(
  selectFeature,
  (state: WarehousesStore) => state.entity
);


export const selectWarehouseState = createSelector(
  selectWarehouseEntity,
  (state: WarehouseState) => state.currentState
);

export const selectCurrentWarehouse = createSelector(
  selectWarehouseEntity,
  (state: WarehouseState) => state.currentWarehouse
);

export const loadingRequestsCount = createSelector(
  selectWarehouseEntity,
  (state: WarehouseState) => state.loadingRequestsCount
);

export const warehouseUpdatedAt = createSelector(
  selectWarehouseEntity,
  (state: WarehouseState) => state.updatedAt
);
