import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';


import { Wizard } from '../../wizard/wizard';
import { FormWizardStep } from './steps';
import { SystemSettingsService } from '../system-settings.service';
import { AssignedAttributeModel, AttributeModel } from '../models';
import { ToasterService } from '../../ui-components/toaster';


@Component({
  selector: 'rnpl-form-wizard',
  templateUrl: './form-wizard.component.html',
  styleUrls: ['./form-wizard.component.scss']
})
export class FormWizardComponent extends Wizard implements OnInit, OnDestroy, OnChanges {

  @Input()
  formKey: string;

  @Input()
  formLabel: string;

  public availableAttributes: Array<AttributeModel> = [];
  public assignedAttributes: Array<AssignedAttributeModel> = [];

  step: FormWizardStep = 'form-layout';

  private stepSubscription: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private systemSettingsService: SystemSettingsService,
              private toasterService: ToasterService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('formKey')) {
      this.loadAttributes();
    }
  }

  ngOnInit(): void {

    this.stepSubscription = this.route.paramMap
      .subscribe(params => {
        this.step = params.get('step') as any;
      });
  }

  ngOnDestroy(): void {
    this.stepSubscription.unsubscribe();
  }

  goToStep = (step) => {
    this.router.navigate(['../', step], {relativeTo: this.route});
  }

  openStep(step): void {

  }

  stepCompleted(data): void {
    if (this.step === 'form-layout') {
      this.assignedAttributes = data;
    } else if (this.step === 'confirmation') {
      this.systemSettingsService.assignAttributes(this.formKey, this.assignedAttributes)
        .subscribe(response => {
          this.toasterService.notify({type: 'success', message: 'Form layout has been successfully saved'});
        }, (error) => {
          this.toasterService.notify({type: 'error', message: 'Form layout has not been saved'});
        });
    }
    this.completed.emit();
  }

  close(): void {
    this.closed.emit();
  }

  private loadAttributes(): void {
    this.systemSettingsService.availableAttributes(this.formKey)
      .subscribe(response => this.availableAttributes = response.data);

    this.systemSettingsService.assignedAttributes(this.formKey)
      .subscribe(response => this.assignedAttributes = response.data);
  }

}
