import { ProductTypes } from '../../products/product-types';

export interface NavItem {
  secondRoute?: string;
  items?: NavItem[];
  name?: string;
  iconName?: string;
  route?: string;
  show?: boolean;
  disabled?: boolean;
  children?: NavItem[];
  action?: any;
  title?: string;
  icon?: string;
  gradient?: string;
  minWidthClass?: string;
}

export function getHeadNavigationMenuItems(
  canViewAccounting: boolean,
  canViewAdministration: boolean,
  canViewAnalytics: boolean,
  canViewEcommerce: boolean,
  canViewPartners: boolean,
  canViewProducts: boolean,
  canViewRetail: boolean,
  canViewServices: boolean,
  canViewTeam: boolean,
  canViewWarehouse: boolean,
  canViewWholesale: boolean,
  wholesaleEnabled: boolean,
  ecommerceEnabled: boolean,
  servicesEnabled: boolean,
  goodsProductTypeEnabled: boolean,
  servicesProductTypeEnabled: boolean,
  digitalProductTypeEnabled: boolean,
  corporatePartnersEnabled: boolean,
  privatePartnersEnabled: boolean,
): NavItem[] {

  const productTypeRedirect = goodsProductTypeEnabled
    ? ProductTypes.GOODS
    : servicesProductTypeEnabled
      ? ProductTypes.SERVICES
      : ProductTypes.DIGITAL;

  const productTypeRedirectForPurchasePrice = goodsProductTypeEnabled
    ? ProductTypes.GOODS
    : digitalProductTypeEnabled
      ? ProductTypes.DIGITAL
      : null;

  const TRADE_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.TRADE',
    //   route: '/trade',
    //   // show: (canViewWholesale || canViewEcommerce || canViewServices) && (wholesaleEnabled || ecommerceEnabled || servicesEnabled),
    //   show: canViewWholesale || canViewEcommerce || canViewServices,
    //   minWidthClass: 'min-w-220',
    //   children: [
    //     {
    //       name: 'APP.OFFERS',
    //       show: canViewWholesale && wholesaleEnabled,
    //       iconName: 'offer',
    //       route: '/trade/offers',
    //       action: 'createNewOffer'
    //     },
    //     {
    //       name: 'APP.SALES_ORDERS',
    //       show: canViewWholesale && wholesaleEnabled,
    //       iconName: 'sales-order',
    //       route: '/trade/sales-order',
    //       action: 'createNewOrder'
    //     },
    //     {
    //       name: 'APP.PURCHASE_ORDERS',
    //       show: (canViewWholesale || canViewEcommerce) && (wholesaleEnabled),
    //       iconName: 'purchase-order',
    //       route: '/purchases/purchase-order',
    //       action: 'createNewPurchaseOrder'
    //     },
    //     {
    //       name: 'APP.PRICES',
    //       show: (canViewWholesale || canViewEcommerce || canViewServices) && (wholesaleEnabled || servicesEnabled),
    //       iconName: 'pricetag',
    //       route: '/trade/prices',
    //       children: [
    //         {
    //           name: 'PRICE.SALES_PRICES',
    //           route: `/trade/prices/sales/${productTypeRedirect}`,
    //         },
    //         {
    //           name: 'PRICE.PURCHASE_PRICES',
    //           show: goodsProductTypeEnabled || digitalProductTypeEnabled,
    //           route: `/trade/prices/purchase/${productTypeRedirectForPurchasePrice}`,
    //         },
    //       ]
    //     },
    //     {
    //       name: 'APP.ERA',
    //       show: (canViewWholesale || canViewEcommerce) && (wholesaleEnabled || ecommerceEnabled),
    //       iconName: 'exchange-return',
    //       route: '/trade/exchanges-and-returns',
    //       action: 'createNewERA',
    //     },
    //   ]
    // },
  ];

  const SERVICES_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.SERVICES',
    //   show: canViewServices && servicesEnabled,
    //   route: '/services',
    //   iconName: 'checklist',
    //   children: [
    //     {
    //       name: 'APP.TIME_TRACKING',
    //       iconName: 'timer',
    //       route: '/services/time-tracking',
    //       action: 'onLogTimeClick',
    //     },
    //     {
    //       name: 'APP.SUBSCRIPTIONS',
    //       iconName: 'subscriptions',
    //       route: '/services/subscription',
    //       action: 'createNewSubscription'
    //     },
    //     {
    //       name: 'APP.SETTINGS',
    //       iconName: 'options-2',
    //       disabled: true,
    //     },
    //   ]
    // },
  ];

  const ACCOUNTING_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.ACCOUNTING',
    //   show: canViewAccounting,
    //   route: '/accounting',
    //   iconName: 'calculator',
    //   children: [
    //     {
    //       name: 'APP.INVOICES',
    //       iconName: 'invoice',
    //       route: '/accounting/',
    //       children: [
    //         {
    //           name: 'APP.OUTGOING_INVOICES',
    //           iconName: 'outgoing-invoice',
    //           route: '/accounting/outgoing-invoice',
    //           action: 'createNewInvoice'
    //         },
    //         {
    //           name: 'APP.INCOMING_INVOICES',
    //           iconName: 'incoming-invoice',
    //           route: '/accounting/incoming-invoice',
    //           action: 'createNewIncomingInvoice'
    //         },
    //       ]
    //     },
    //     {
    //       name: 'APP.PAYMENTS',
    //       iconName: 'payment',
    //       route: '/accounting/payments'
    //     },
    //     {
    //       name: 'APP.CREDIT_NOTES',
    //       iconName: 'credit-note',
    //       route: '/accounting/credit-note',
    //       action: 'createNewCreditNote'
    //     },
    //     {
    //       name: 'APP.OPEN_ITEM_LIST',
    //       iconName: 'open-items-list',
    //       route: '/accounting/open-items'
    //     },
    //     {
    //       name: 'REPORTING_PERIODS.REPORTING_PERIODS',
    //       iconName: 'accountant-report',
    //       route: '/accounting/reporting-periods',
    //       children: [
    //         {
    //           name: 'REPORTING_PERIODS.REPORTS',
    //           route: '/accounting/reporting-periods/list',
    //         },
    //         {
    //           name: 'DOC_BLOCK.PREFERENCES',
    //           route: '/accounting/reporting-periods/preferences',
    //         },
    //       ]
    //     },
    //     {
    //       name: 'APP.SETTINGS',
    //       iconName: 'options-2',
    //       disabled: true,
    //     },
    //   ]
    // },
  ];

  const WAREHOUSE_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.WAREHOUSE',
    //   show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    //   route: '/warehouse',
    //   children: [
    //     {
    //       name: 'APP.STOCK_STATUS',
    //       iconName: 'cubes',
    //       route: '/warehouse/stock-status'
    //     },
    //     {
    //       name: 'APP.INCOMING_DELIVERIES',
    //       iconName: 'incoming-delivery',
    //       route: '/warehouse/incoming-delivery',
    //     },
    //     {
    //       name: 'APP.OUTGOING_DELIVERIES',
    //       iconName: 'outgoing-delivery',
    //       route: '/warehouse/outgoing-delivery',
    //       action: 'createNewDeliveryNote'
    //     },
    //     {
    //       name: 'APP.TRANSACTIONS',
    //       iconName: 'stocktransfer',
    //       route: '/warehouse/transactions',
    //     },
    //     {
    //       name: 'APP.MANAGEMENT',
    //       iconName: 'warehouse',
    //       route: '/warehouse/warehouse-profile',
    //     },
    //   ]
    // },
  ];

  const PARTNERS_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.PARTNERS',
    //   show: canViewPartners,
    //   route: '/partners-new',
    //   children: [
    //     {
    //       name: 'APP.CORPORATE_PARTNERS_1',
    //       iconName: 'factory',
    //       show: corporatePartnersEnabled,
    //       route: `/partners-new/corporate`,
    //       action: 'createNewPartner',
    //     },
    //     {
    //       name: 'APP.PRIVATE_PARTNERS',
    //       iconName: 'person',
    //       show: privatePartnersEnabled,
    //       route: `/partners-new/private`,
    //       action: 'createNewPrivatePartner',
    //     },
    //   ]
    // },
  ];

  const TEAM_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.TEAM',
    //   show: canViewTeam,
    //   route: '/team',
    // },
  ];

  const PRODUCTS_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'APP.PRODUCTS',
    //   show: canViewProducts,
    //   route: '/products',
    //   children: [
    //     {
    //       name: 'POSITIONS.PHYSICAL_GOODS',
    //       iconName: 'box',
    //       show: goodsProductTypeEnabled,
    //       route: `/products/${ProductTypes.GOODS}`,
    //       action: 'createNewProduct'
    //     },
    //     {
    //       name: 'APP.SERVICES',
    //       iconName: 'checklist',
    //       show: servicesProductTypeEnabled,
    //       route: `/products/${ProductTypes.SERVICES}`,
    //       action: 'createNewService'
    //     },
    //     {
    //       name: 'APP.DIGITAL_PRODUCTS',
    //       iconName: 'digital-product',
    //       show: digitalProductTypeEnabled,
    //       route: `/products/${ProductTypes.DIGITAL}`,
    //       action: 'createNewDigitalProduct'
    //     },
    //     {
    //       name: 'APP.CATEGORIES',
    //       iconName: 'folder-open',
    //       route: `/products/settings/categories/${productTypeRedirect}`,
    //     },
    //   ]
    // },
  ];

  const ANALYTICS_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'Analytics',
    //   show: canViewAnalytics,
    //   iconName: 'business-intelligence',
    //   disabled: true,
    // },
  ];

  const ADMINISTRATION_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.ADMINISTRATION',
    //   show: canViewAdministration,
    //   route: '/administration',
    // },
  ];

  const ECOMMERCE_MENU_ITEMS: NavItem[] = [
    // {
    //   name: 'MODULE.E_COMMERCE',
    //   // show: canViewEcommerce && ecommerceEnabled, // todo: ecommerce
    //   route: '/e-commerce',
    //   children: [
    //     {
    //       name: 'APP.ORDERS',
    //       iconName: 'sales-order',
    //       route: '/e-commerce/orders',
    //     },
    //     {
    //       name: 'APP.PRODUCTS',
    //       iconName: 'box',
    //       route: `/e-commerce/products/${ProductTypes.GOODS}`,
    //     },
    //     {
    //       name: 'APP.CUSTOMERS',
    //       iconName: 'person',
    //       children: [
    //         {
    //           name: 'APP.CORPORATE',
    //           iconName: 'factory',
    //           route: '/e-commerce/customers/corporate',
    //         },
    //         {
    //           name: 'APP.PRIVATE',
    //           iconName: 'person',
    //           route: '/e-commerce/customers/private',
    //         },
    //       ]
    //     },
    //     {
    //       name: 'Settings',
    //       iconName: 'settings',
    //       route: '/e-commerce/settings',
    //     },
    //   ]
    // },
  ];

  return [
    ...TRADE_MENU_ITEMS,
    ...ECOMMERCE_MENU_ITEMS,
    ...SERVICES_MENU_ITEMS,
    ...ACCOUNTING_MENU_ITEMS,
    ...WAREHOUSE_MENU_ITEMS,
    ...PRODUCTS_MENU_ITEMS,
    ...PARTNERS_MENU_ITEMS,
    ...TEAM_MENU_ITEMS,
    ...ADMINISTRATION_MENU_ITEMS,
  ];
}

