import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { ProductTypes } from '../../../products/product-types';
import { FamilyModel, ProductsService } from '../../../products';

@Component({
  selector: 'rnpl-filters-control-product-category',
  templateUrl: './filters-control-product-category.component.html',
})
export class FiltersControlProductCategoryComponent extends FiltersControlBaseComponent implements OnInit {

  @Input() productType: ProductTypes;

  public defaultProductType = ProductTypes.GOODS;
  public flatFamilies: FamilyModel[] = [];

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private productsService: ProductsService,
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnInit() {
    const requestParams = new HttpParams().set('products_type', this.productType || this.defaultProductType);

    this.productsService.getFamiliesTree(requestParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.flatFamilies =  this.convertTreeToFlat(response.data, 'id');
        this.showDropdownSpin$.next(false);
      });
  }

  private convertTreeToFlat(tree: FamilyModel[], idsPropName: string): FamilyModel[] {
    if (!tree) { return; }

    const flatArray = [];
    const getTreeItems = (branch) => {
      branch.map(node => {
        if (!node.product_locked_at) {
          flatArray.push(node);
        }

        if (node.children.length) {
          getTreeItems(node.children);
        }
      });
    };

    getTreeItems(tree);

    return flatArray;
  }

}
