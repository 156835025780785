import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { UIStatesEnum } from 'common/src/models';
import { HrmService } from '../../hrm.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { selectTeamsUser, selectTeamsUserCurrentState } from '../../store/selectors';
import { AppState } from '../../../store/state/app.state';
import { UserProfileModel } from '../../models';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'common/src/auth/auth.service';
import { AuthUserModel } from 'common/src/auth/auth-user.model';

@Component({
  selector: 'rnpl-preferences-system',
  templateUrl: './preferences-system.component.html',
  styleUrls: ['./preferences-system.component.scss']
})
export class PreferencesSystemComponent implements OnInit {

  public languagesList = [
    {label: 'COMMON.ENGLISH', value: 'en'},
    {label: 'COMMON.DEUTSCH', value: 'de'},
  ];

  public userData: UserProfileModel;
  public loggedUser: AuthUserModel;
  public editingMode: boolean = false;
  public isMyProfile: boolean = false;

  public form: FormGroup;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private hrmService: HrmService,
    private toasterService: ToasterService,
    private authService: AuthService,
    private store:  Store<AppState>,
  ) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isMyProfile = this.route.parent.snapshot.data.myProfile;
        }
      });

    this.loggedUser = this.authService.getUser()
  }

  ngOnInit() {
    this.initForm();

    this.store.select(selectTeamsUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.userData = user;
        this.authService.updateUser({
          ...this.loggedUser,
         lang: this.userData.preferences.system.language
        })
        this.form.patchValue(this.userData.preferences.system);
      });

    this.store.select(selectTeamsUserCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;

        if (!this.editingMode) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
  }

  public initForm(): void {
    this.form = this.fb.group({
      language: [],
      sounds: [],
    })
  }

  public updateUser(field: FormInputChangedModel): void {
    this.hrmService.updateUserProfile(this.userData.profile.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  get languageControl(): FormControl { return this.form.get('language') as FormControl; }

}
