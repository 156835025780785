import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { PickListEnum } from './enums/pick-list-type.model';
import { DeliveryNoteApiService } from '../../../../../../projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { get } from 'lodash';
import { FileSaverService } from 'ngx-filesaver';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rnpl-generate-pick-list-modal',
  templateUrl: './generate-pick-list-modal.component.html',
})
export class GeneratePickListModalComponent {

  readonly generatePickListRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public pickListEnum: typeof PickListEnum = PickListEnum;

  public pickListType: FormControl = new FormControl(PickListEnum.SINGLE);

  constructor(
    private fb: FormBuilder,
    private deliveryNoteApiService: DeliveryNoteApiService,
    public dialogRef: MatDialogRef<GeneratePickListModalComponent>,
    private readonly fileSaverService: FileSaverService,
    private readonly datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: {ids: number[]}
  ) {

  }

  public generatePickList(): void {
    this.deliveryNoteApiService.validateAndCreatePickList(this.data.ids, this.pickListTypeValue)
      .pipe(
        finalize(() => { this.generatePickListRequest$.next(false); }),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        const contentDispositionHeader = res.headers.get('Content-Disposition');
        this.fileSaverService.save(res.body, this.getFileName(contentDispositionHeader));
      });
  }

  public generalPIckListHandler() {
    if (this.generatePickListRequest$.getValue()) { return; }
    this.generatePickListRequest$.next(true);

    this.dialogRef.close(this.generatePickList());
  }

  public infoBlockDescr(): string {
    let descr: string;
    if (this.pickListType.value === this.pickListEnum.SINGLE) {
      descr = 'WAREHOUSE.DELIVERY_NOTE.MODAL.PICK_LIST_GENERATION_SINGLE_MESSAGE';
    } else {
      descr = 'WAREHOUSE.DELIVERY_NOTE.MODAL.PICK_LIST_GENERATION_MESSAGE';
    }
    return descr;
  }

  public get pickListTypeValue(): string {
    return get(this.pickListType, 'value', PickListEnum.SINGLE).toLowerCase();
  }

  public getFileName(str: string): string {
    const fileNameRegExp = /\"([^)]+)\"/;
    const currentDate = this.datePipe.transform(new Date(), 'yyMMdd');
    if (!!str) {
      const name = fileNameRegExp.exec(str);
      return name[1];
    }
    return `DN-${currentDate}.zip`;
  }

}

