import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { TableSummaryBarItemModel, TableSummaryBarItemTypeEnum } from '../table-summary-bar.model';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';


@Component({
  selector: 'rnpl-table-summary-bar-item',
  templateUrl: './table-summary-bar-item.component.html',
  styleUrls: ['./table-summary-bar-item.component.scss']
})
export class TableSummaryBarItemComponent {

  @Input() summaryBarItem: TableSummaryBarItemModel;

  public ITEM_TYPES = TableSummaryBarItemTypeEnum;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly store: Store<AppState>,
  ) { }

}
