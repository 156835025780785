import { createReducer, on } from '@ngrx/store';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { CollectionProductsActions } from '../actions/action-types';
import { CompositeProductModel, FamilyModel, ProductModel } from 'common/src/modules/products';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { FilterModel } from '../../../warehouse/models/filter.model';


export const ProductsFeatureKey = 'productsStore';

export interface ProductsStore {
  entity: ProductsState;
  list: ProductsListState;
  categories: CategoriesState;
}

export interface ProductsListPageState {
  pagination?: PaginationModel;
  sort?: FilterModel;
  data?: CompositeProductModel[];
}

export interface ProductsListState {
  [productType: string]: {
    bulk?: {
      [productStatus: string]: {
        [page: number]: ProductsListPageState
      }
    },
    [entityId: number]: {
      [productStatus: string]: {
        [page: number]: ProductsListPageState
      }
    }
  }
}

export interface CategoriesState {
  [productType: string]: FamilyModel[];
}

export interface ProductsState {
  product: ProductModel;
  currentState: UIStatesEnum;
  isEditingFinished: boolean;
  updatedAt: Date;
  loadingRequestsCount: number;
}

export const initialProductsState: ProductsState = {
  product: null,
  currentState: UIStatesEnum.CREATE,
  isEditingFinished: false,
  updatedAt: null,
  loadingRequestsCount: 0
};

export const initialProductsListsState: ProductsListState = {
  [ProductTypes.GOODS]: {},
  [ProductTypes.SERVICES]: {},
  [ProductTypes.DIGITAL]: {},
};

export const initialCategoriesState: ProductsListState = {
  [ProductTypes.GOODS]: [],
  [ProductTypes.SERVICES]: [],
  [ProductTypes.DIGITAL]: [],
};

export function ProductsListReducer(state, action) {
  return _ProductsListReducer(state, action);
}

const _ProductsListReducer = createReducer(
  initialProductsListsState,
  on(CollectionProductsActions.LoadProductsList, (
      state,
      data: {
        productType,
        entityKey,
        status,
        page ,
        productsListData,
      }
    ) => ({
      ...state,
      [data.productType]: {
        ...state[data.productType],
        [data.entityKey]: {
          ...state[data.productType][data.entityKey],
          [data.status]: {
            [data.page]: data.productsListData
          }
        }
      },
    })),
);

export function ProductsCategoriesReducer(state, action) {
  return _ProductsCategoriesReducer(state, action);
}

const _ProductsCategoriesReducer = createReducer(
  initialCategoriesState,
  on(CollectionProductsActions.LoadCategoriesList, (
    state,
    data: {
      productType,
      categories
    }
  ) => ({
    ...state,
    [data.productType]: data.categories
  })),
);

export function ProductsReducer(state, action) {
  return _ProductsReducer(state, action);
}

const _ProductsReducer = createReducer(
  initialProductsState,
  // Products
  on(CollectionProductsActions.LoadProduct, (state, { product }) => ({
    ...state,
    product
  })),
  on(CollectionProductsActions.UpdateProductsCurrentState, (state, { currentState }) => ({
    ...state,
    currentState
  })),
  on(CollectionProductsActions.UpdateFinishEditingState, (state, { isEditingFinished }) => ({
    ...state,
    isEditingFinished
  })),
  on(CollectionProductsActions.UpdateProductUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionProductsActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionProductsActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
);
