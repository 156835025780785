import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';
import { finalize, takeUntil } from 'rxjs/operators';

import { BaseModalComponent } from '../../../rnpl-common';
import { ToasterService } from '../../../ui-components/toaster';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { getContentDispositionFileName } from '../../../rnpl-common/helpers';
import { FileService } from '../../../../services/file.service';
import { FileUploadParams } from '../../../../models/file-upload-params.model';


@Component({
  selector: 'rnpl-time-tracking-export-list-modal',
  templateUrl: './time-tracking-export-list-modal.component.html',
})
export class TimeTrackingExportListModalComponent extends BaseModalComponent {

  @Input() period: FormControl = new FormControl('PERIOD', [Validators.required]);
  @Input() controlDateFrom: FormControl = new FormControl(null, [Validators.required]);
  @Input() controlDateTo: FormControl = new FormControl(null, [Validators.required]);

  public datePeriods = [
    {value: 'MONTH', label: 'FORM.PREVIOUS_MONTH'},
    {value: 'QUARTER', label: 'FORM.PREVIOUS_QUARTER'},
    {value: 'PERIOD', label: 'FORM.CUSTOM_PERIOD'}
  ];

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public datePipe : DatePipe,
    public timeTrackingApiService: TimeTrackingApiService,
    public fileSaverService: FileSaverService,
    public fileService: FileService,
    public dialogRef: MatDialogRef<TimeTrackingExportListModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {}
  ) {
    super(toasterService);

    this.trackPeriodChanges();
  }

  public trackPeriodChanges(): void {
    this.period.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value: 'MONTH'|'QUARTER'|'PERIOD') => {
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        switch (value) {
          case 'MONTH':
            const firstDayPrevMonth = new Date(currentYear, currentMonth - 1, 1);
            const lastDayPrevMonth = new Date(currentYear, currentMonth, 0);

            this.controlDateFrom.patchValue(firstDayPrevMonth);
            this.controlDateTo.patchValue(lastDayPrevMonth);
            break;
          case 'QUARTER':
            const currentQuarter = Math.floor((currentMonth / 3));
            const firstDayPrevQuarter = new Date(currentYear, currentQuarter * 3 - 3, 1);
            const lastDayPrevQuarter = new Date(firstDayPrevQuarter.getFullYear(), firstDayPrevQuarter.getMonth() + 3, 0);

            this.controlDateFrom.patchValue(firstDayPrevQuarter);
            this.controlDateTo.patchValue(lastDayPrevQuarter);
            break;
        }
      });
  }

  public dateUpdated(): void {
    this.period.patchValue('PERIOD', {emitEvent: false});
  }

  downloadFile(url) {
    this.fileService.downloadFile(url)
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy),
      )
      .subscribe((res: any) => {
        this.fileSaverService.save(
          res.body,
          getContentDispositionFileName(res.headers.get('Content-Disposition'))
        );
        this.dialogRef.close();
      });
  }

  public submit(): void {
    this.controlDateFrom.markAsDirty();
    this.controlDateTo.markAsDirty();
    this.controlDateFrom.updateValueAndValidity();
    this.controlDateTo.updateValueAndValidity();

    if (this.controlDateFrom.invalid || this.controlDateTo.invalid || this.submitRequest$.getValue()) {
      return;
    }

    if (this.controlDateFrom.value >= this.controlDateTo.value) {
      this.displayMessage('error', 'FORMS_VALIDATION.DATE_TO_GREATER_DATE_FROM');
      return;
    }

    this.submitRequest$.next(true);

    this.timeTrackingApiService.getRecordsListExport({
      periodFrom: this.datePipe.transform(this.controlDateFrom.value, 'yyyy-MM-dd'),
      periodTo: this.datePipe.transform(this.controlDateTo.value, 'yyyy-MM-dd'),
    })
      .subscribe((fileParams: FileUploadParams) => {
        this.downloadFile(fileParams.url);
      }, error => {
        this.displayMessage('error', error.error.message || error.error.errors);
      });
  }

}
