import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { StatusLineComponent } from './status-line.component';
import { RnplCommonModule } from '../../rnpl-common';
import { DateTimezonePipe } from '../../rnpl-common/pipes/date-timezone/date-timezone.pipe';
import { NumberFormatPipeModule } from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';

@NgModule({
  declarations: [
    StatusLineComponent
  ],
  exports: [
    StatusLineComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    TranslateModule,
    NumberFormatPipeModule
  ],
  providers: [
    DateTimezonePipe
  ]
})
export class StatusLineModule { }
