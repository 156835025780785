import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationGroupModel } from 'common/src/models';

@Component({
  selector: 'rnpl-settings-outlet',
  templateUrl: './settings-outlet.component.html',
  styleUrls: ['./settings-outlet.component.scss']
})
export class SettingsOutletComponent implements OnInit {

  public groups: NavigationGroupModel[];

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.data.subscribe( (data) => {
      this.groups = <NavigationGroupModel[]>data.groups;
    });
  }

}
