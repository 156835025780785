import {
  SubscriptionBillingFrequencyEnum,
  SubscriptionBillingTimeEnum,
  SubscriptionListTabsEnum,
  SubscriptionPeriodEnum
} from './enums';

export const STATUS_KEY = 'status';

export const STATUS_CLASSES: {[key: string]: string} = {
  [SubscriptionListTabsEnum.DRAFT]: 'rnpl-badge--grey',
  [SubscriptionListTabsEnum.PAUSED]: 'rnpl-badge--grey',
  [SubscriptionListTabsEnum.DELETED]: 'rnpl-badge--empty',
  [SubscriptionListTabsEnum.OPEN]: 'rnpl-badge--blue',
  [SubscriptionListTabsEnum.COMPLETED]: 'rnpl-badge--green',
  [SubscriptionListTabsEnum.CANCELED]: 'rnpl-badge--red',
  ['editing']: 'rnpl-badge--main-400',
  ['overdue']: 'rnpl-badge--yellow',
  ['corrupted']: 'rnpl-badge--yellow',
  ['active']: 'rnpl-badge--blue'
};

export const SUBS_DURATION_LIST: {value: SubscriptionPeriodEnum, label: string}[] = [
  // {value: SubscriptionPeriodEnum.DAY, label: 'Day'},
  // {value: SubscriptionPeriodEnum.WEEK, label: 'Week'},
  {value: SubscriptionPeriodEnum.MONTH, label: 'FORM.MONTH'},
  {value: SubscriptionPeriodEnum.QUARTER, label: 'FORM.QUARTER'},
  {value: SubscriptionPeriodEnum.HALF_YEAR, label: 'FORM.HALF_YEAR'},
  {value: SubscriptionPeriodEnum.YEAR, label: 'FORM.YEAR'},
  // {value: SubscriptionPeriodEnum.CUSTOM_RANGE, label: 'COMMON.CUSTOM_RANGE'},
  {value: SubscriptionPeriodEnum.UNLIMITED, label: 'FORM.UNLIMITED_DURATION'},
  {value: SubscriptionPeriodEnum.SPECIFY_END, label: 'FORM.SPECIFY_END_DATE'},
];

export const BILLING_FREQUENCY_LIST: {value: SubscriptionBillingFrequencyEnum; label: string}[] = [
  {value: SubscriptionBillingFrequencyEnum.MONTHLY, label: 'COMMON.MONTHLY'},
  {value: SubscriptionBillingFrequencyEnum.QUARTER, label: 'COMMON.QUARTERLY'},
  {value: SubscriptionBillingFrequencyEnum.HALF_YEARLY, label: 'COMMON.HALF_YEARLY'},
  {value: SubscriptionBillingFrequencyEnum.ANNUALLY, label: 'COMMON.ANNUALLY'},
];

export const BILLING_TIME_LIST: {value: SubscriptionBillingTimeEnum; label: string}[] = [
  {value: SubscriptionBillingTimeEnum.FIRST_DAY_BILLING_PERIOD, label: 'COMMON.FIRST_DAY_BILLING'},
  {value: SubscriptionBillingTimeEnum.LAST_DAY_BILLING_PERIOD, label: 'COMMON.LAST_DAY_BILLING'},
  {value: SubscriptionBillingTimeEnum.SUBSCRIPTION_START_DATE, label: 'FORM.START_DATE'},
];
