import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryServiceEnum } from 'projects/workspace/src/app/delivery-note/enums';

@Pipe({name: 'deliveryServiceImage'})
export class DeliveryServiceImagePipe implements PipeTransform {
  transform(deliveryService: DeliveryServiceEnum, ...args: any[]): string {
    let imageUrl: string;

    switch (deliveryService) {
      case DeliveryServiceEnum.AUSTRIAN_POST:
        imageUrl = 'assets/img/delivery-services/austrian-post.svg';
        break;
      case DeliveryServiceEnum.DHL:
        imageUrl = 'assets/img/delivery-services/dhl.svg';
        break;
      case DeliveryServiceEnum.UPS:
        imageUrl = 'assets/img/delivery-services/ups.svg';
        break;
      case DeliveryServiceEnum.DPD:
        imageUrl = 'assets/img/delivery-services/dpd.svg';
        break;
      case DeliveryServiceEnum.GLS:
        imageUrl = 'assets/img/delivery-services/gls.svg';
        break;
    }

    return imageUrl;
  }
}
