export interface HintsModel {
  hints: HintsListModel[],
  showRepeatHint: boolean


}

export enum HintTypeEnum {
  MEGA = 'mega',
  DEFAULT = 'default',
  SYSTEM = 'system'
}

export interface HintsListModel {
  id?: number;
  name?: string;
  viewed?: boolean;
  type?: HintTypeEnum;
  text?: string;
}
