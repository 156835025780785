export enum ExchangeRequiredSolutionEnum {
  REPLACE_PRODUCT = 'replace_product',
  MONEY_RETURN = 'money_return',
  SELL_DELIVERED_PRODUCT = 'sell_delivered_product',
  PRODUCT_RETURN = 'product_return',
  SELL_PRODUCT = 'sell_product',
  SHIP_PRODUCT = 'ship_product',
  GIVE_COMPENSATION = 'give_compensation',
  EXCHANGE = 'exchange',
  RETURN = 'return',
}
