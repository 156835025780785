import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ClearStoreOnLogout } from 'projects/workspace/src/app/store/actions/app.actions';
import { LoadCategories, LoadCountries } from 'projects/workspace/src/app/store/actions/shared.actions';
import { MatDialog } from '@angular/material/dialog';
import { AuthUserModel } from './auth-user.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static STORE_KEY_TOKEN = 'jwt_token';
  public static STORE_KEY_EXPIRES_AT = 'token_expires_at';
  public static STORE_USER = 'user';

  // public static EXPIRES_TIME = 60 * 60 * 10000;
  public static EXPIRES_TIME = 90 * 24 * 60 * 60 * 1000;

  public loggedUser$: BehaviorSubject<AuthUserModel> = new BehaviorSubject<AuthUserModel>(null);

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private readonly store: Store<AppState>
  ) {
    this.getUser();
  }

  /**
   * Autorize user by email and password
   *
   * @param email string
   * @param password string
   */
  public signIn(email: string, password: string): Observable<any> {
    const browserLang = this.translate.getBrowserLang();
    const params = new HttpParams().set('lang', browserLang.match(/en|de/) ? browserLang : 'en');
    const requestBody = {email, password};

    return this.http.post('/auth', requestBody, { params })
      .pipe(
        tap((response: any) => {
          if (response.data && !response.data.two_factor_code && !response.data.user) {
            this.storeToken(response.data, response.user);
            this.store.dispatch(LoadCountries());
            this.store.dispatch(LoadCategories());
          }
        }));
  }

  /**
   * Implements second step of two-factor authorization
   *
   * @param userId user id
   * @param authKey authorization key
   */
  public authorize(userId, authKey: number): Observable<any> {
    return this.http.post('/auth/second-step', {user_id: userId, code: authKey})
      .pipe(tap((response: any) => {
        if (response.data) {
          this.storeToken(response.data, response.user);
        }
      }));
  }

  /**
   * Reset user password by email
   *
   * @param email string
   */
  public resetPassword(email: string): Observable<any> {
    const requestBody = { email };
    return this.http.post('/users/reset-password', requestBody);
  }

  /**
   * Approve new email by token
   *
   * @param token string
   */
  public approveNewEmail(token: string): Observable<any> {
    return this.http.get(`/users/approve-email/${token}`);
  }

  /**
   * Restore user password by token
   *
   * @param token string
   * @param password string
   */
  public restorePassword(token: string, password: string): Observable<any> {
    const requestBody = { token, password };
    return this.http.post('/users/restore-password', requestBody)
      .pipe(
        tap((response: any) => {
          if (response.data && !response.data.two_factor_code && !response.data.user) {
            this.storeToken(response.data, response.user);
            this.store.dispatch(LoadCountries());
            this.store.dispatch(LoadCategories());
          }
        })
      );
  }

  /**
   * Removes token to sign out
   */
  public signOut(): void {
    localStorage.removeItem(AuthService.STORE_KEY_TOKEN);
    localStorage.removeItem(AuthService.STORE_USER);
    localStorage.removeItem(AuthService.STORE_KEY_EXPIRES_AT);
    this.dialog.closeAll();
    // const browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    this.router.navigate(['/sign-in'], { state: { authRedirectUrl: location.pathname }}).then(() => {
      this.store.dispatch(ClearStoreOnLogout());
    });
  }

  /**
   * Checks if user is authorized
   */
  public isUserAuthorized(): boolean {
    const expiresAt: string = localStorage.getItem(AuthService.STORE_KEY_EXPIRES_AT);
    const isAuthorized: boolean = expiresAt && parseInt(expiresAt, 10) > (new Date()).getTime();

    if (expiresAt && !isAuthorized) {
      this.signOut();
    }

    return isAuthorized;
  }

  public getToken(): string {
    return this.isUserAuthorized() ? localStorage.getItem(AuthService.STORE_KEY_TOKEN) : null;
  }

  public getUser(): AuthUserModel {
    const user = this.isUserAuthorized() ? JSON.parse(localStorage.getItem(AuthService.STORE_USER)) : null;
    this.loggedUser$.next(user);
    return user;
  }

  public updateUser(user: AuthUserModel): void {
    localStorage.setItem(AuthService.STORE_USER, JSON.stringify(user));
    this.loggedUser$.next(user);
  }

  public updatePermissions(permissions): void {
    const user = this.getUser();
    if (!user || !permissions) { return; }

    this.updateUser({...user, permissions});
  }

  /**
   * Stores token in local storage
   *
   * @param token string
   * @param user string
   */
  private storeToken(token: string, user: AuthUserModel): void {
    // if (user.lang) {
    //   this.translate.use(user.lang);
    // }
    this.loggedUser$.next(user);
    localStorage.setItem(AuthService.STORE_KEY_TOKEN, token);
    localStorage.setItem(AuthService.STORE_USER, JSON.stringify(user));
    localStorage.setItem(AuthService.STORE_KEY_EXPIRES_AT, ((new Date()).getTime() + AuthService.EXPIRES_TIME) + '');
    localStorage.setItem('userFirstName', user.firstName);
  }
}
