import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ShepherdService } from 'angular-shepherd';
import { Store } from '@ngrx/store';

import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { EmployeeCreateModel } from 'common/src/models/employee-create.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { TableColumnModel } from '../../../../models/table-column.model';
import { getUserPermissionsColumns } from 'projects/workspace/src/app/hrm/pages/role-and-permissions/role-and-permissions.component.config';
import { UIStatesEnum } from '../../../../models';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { getPermissions } from './create-new-employee-modal.config';

@Component({
  selector: 'rnpl-create-new-employee-modal',
  templateUrl: 'create-new-employee-modal.component.html',
  styleUrls: ['./create-new-employee-modal.component.scss']
})
export class CreateNewEmployeeModalComponent implements OnInit {

  public columns: TableColumnModel[] = getUserPermissionsColumns(UIStatesEnum.EDIT);
  public userData: EmployeeCreateModel = new EmployeeCreateModel();
  public setUpPermissions: boolean = false;
  public attributes: any[];
  public permissions: any[] = [
    {module: 'wholesale',      locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'ecommerce',      locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'services',       locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'accounting',     locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'warehouse',      locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'products',       locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'partners',       locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'team',           locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    {module: 'administration', locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    // {module: 'analytics',      locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
    // {module: 'retail',         locked: 'selected', view: 'availableForSelect', edit: 'availableForSelect', manage: 'availableForSelect'},
  ];

  @ViewChild('employeeForm', {static: false}) employeeForm;
  @ViewChild('departmentForm', {static: false}) departmentForm;

  readonly submitUserRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private hrmService: HrmService,
    private dialog: MatDialog,
    public store: Store<AppState>,
    private toasterService: ToasterService,
    public dialogRef: MatDialogRef<CreateNewEmployeeModalComponent>,
    public shepherdService: ShepherdService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.permissions = getPermissions(
          companyProfile.subscriptionManagement.userChannelOptions,
          companyProfile.subscriptionManagement.subscriptionPlanActive
        );
      });
  }

  ngOnInit() {
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }
    this.loadCreateUserBlueprint();
  }

  public loadCreateUserBlueprint(): void {
    this.hrmService.getCreateUserBlueprint().subscribe((response) => {
      this.attributes = response;
    });
  }

  public departmentFormUpdated(departmentFormValue: {department: string, position: string}): void {
    if (departmentFormValue.department && departmentFormValue.position) {
      this.hrmService.getUserPredefinedPermissions(
        departmentFormValue.department,
        departmentFormValue.position
      )
        .pipe(takeUntil(this.destroy$))
        .subscribe((permissions) => {
          this.permissions = [];

          for (const app in permissions) {
            if (permissions.hasOwnProperty(app)) {
              this.permissions.push(this.getPermissions(app, permissions[app]));
            }
          }

          this.permissions = [...this.permissions];
        });
    }
  }

  submitUser(
    employeeData: {generalInfo: EmployeeCreateModel, container: any[]},
    departmentFormValue: {department: string, position: string}
  ) {
    if (this.employeeForm.isFormInvalid() || this.departmentForm.isFormInvalid()) {
      return;
    }
    if (this.submitUserRequest$.getValue()) { return; }
    this.submitUserRequest$.next(true);

    const data = {
      container: employeeData.container || [],
      ...employeeData.generalInfo,
      ...departmentFormValue,
      permissions: this.getPermissionsValue()
    };

    this.createUser(data);
  }

  public createUser(data, skipWarnings = false): void {
    this.hrmService.createUser(data, skipWarnings)
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error, data);
          return throwError(error);
        }),
        finalize(() => this.submitUserRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((userData) => {
        this.displayMessage('SUCCESS_CREATED.USER', 'success');
        this.dialogRef.close(userData);
      });
  }

  private handlePopupErrors(error: HttpErrorResponse, data): void {
    switch (error.error.message) {
      // case 'Number of active users changes, the invoice amount will be automatically changed according to the tariffs':
      //   {
      //     const dialog = this.dialog.open(WarningModalComponent, {
      //       data: {
      //         title: 'TEAM.CREATE_EMPLOYEE?',
      //         message: 'TEAM.INVOICE_AMOUNT',
      //         confirmBtnText: 'BUTTON.CREATE'
      //       }
      //     });
      //
      //     dialog.afterClosed().subscribe(res => {
      //       if (res === CommonModalsActionsEnum.CONFIRM) {
      //         this.createUser(data, true);
      //       }
      //     });
      //   }
      //   break;
      default:
        this.displayMessage(error.error.message, 'error');
        break;
    }
  }

  public getPermissions(appName: string, permissions: string[]): any {
    const permission = {
      module: appName
    };

    if (!permissions.length || permissions.includes('locked')) {
      return {
        ...permission,
        locked: 'selected',
        view: 'availableForSelect',
        edit: 'availableForSelect',
        manage: 'availableForSelect',
      };
    }

    if (permissions.includes('admin')) {
      return {
        ...permission,
        locked: 'availableForSelect',
        view: 'activated',
        edit: 'activated',
        manage: 'selected',
      };
    }

    if (permissions.includes('write')) {
      return {
        ...permission,
        locked: 'availableForSelect',
        view: 'activated',
        edit: 'selected',
        manage: 'availableForSelect',
      };
    }

    if (permissions.includes('read')) {
      return {
        ...permission,
        locked: 'availableForSelect',
        view: 'selected',
        edit: 'availableForSelect',
        manage: 'availableForSelect',
      };
    }
  }

  public getPermissionsValue(): any {
    const permissions = {};

    this.permissions.map(itm => {
      permissions[itm.module] = [];

      if (itm.view === 'selected' || itm.view === 'activated') {
        permissions[itm.module].push('read');
      }

      if (itm.edit === 'selected' || itm.edit === 'activated') {
        permissions[itm.module].push('write');
      }

      if (itm.manage === 'selected') {
        permissions[itm.module].push('admin');
      }
    });

    return permissions;
  }

  public onTableIconClick(event): void {
    const permission = event.row;
    if (event.value === 'selected') { return; }

    if (event.column.prop === 'locked') {
      permission.locked = 'selected';
      permission.view = 'availableForSelect';
      permission.edit = 'availableForSelect';
      permission.manage = 'availableForSelect';
    }

    if (event.column.prop === 'view') {
      permission.locked = 'availableForSelect';
      permission.view = 'selected';
      permission.edit = 'availableForSelect';
      permission.manage = 'availableForSelect';
    }

    if (event.column.prop === 'edit') {
      permission.locked = 'availableForSelect';
      permission.view = 'activated';
      permission.edit = 'selected';
      permission.manage = 'availableForSelect';
    }

    if (event.column.prop === 'manage') {
      permission.locked = 'availableForSelect';
      permission.view = 'activated';
      permission.edit = 'activated';
      permission.manage = 'selected';
    }

    const foundIndex = this.permissions.findIndex(itm => itm.module === event.row.module);
    this.permissions[foundIndex] = permission;

    this.permissions = [...this.permissions];
  }

  public displayMessage(msg: string, type: string) {
    this.toasterService.notify({
      type,
      message: msg
    });
  }

}
