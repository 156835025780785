import { SendEmailStatusEnum } from 'common/src/models';

export const EMAIL_SENT_STATUS_TITLES: {[key: string]: string } = {
  [SendEmailStatusEnum.READ]: 'EMAIL_SENT_STATUS.EMAIL_RECEIVED',
  [SendEmailStatusEnum.SENT]: 'EMAIL_SENT_STATUS.EMAIL_SENT',
  [SendEmailStatusEnum.NOT_SENT]: 'EMAIL_SENT_STATUS.EMAIL_NOT_SENT',
  [SendEmailStatusEnum.PENDING]: 'EMAIL_SENT_STATUS.EMAIL_NOT_SENT',
  [SendEmailStatusEnum.FAILED]: 'EMAIL_SENT_STATUS.EMAIL_FAILED',
};

export const EMAIL_SENT_STATUS_TITLES_OVERDUE_NOTICE: {[key: string]: string } = {
  [SendEmailStatusEnum.READ]: 'OVERDUE_NOTICE.NOTICE_RECEIVED',
  [SendEmailStatusEnum.SENT]: 'OVERDUE_NOTICE.NOTICE_SENT',
  [SendEmailStatusEnum.NOT_SENT]: 'OVERDUE_NOTICE.NOTICE_PENDING',
  [SendEmailStatusEnum.PENDING]: 'OVERDUE_NOTICE.NOTICE_PENDING',
  [SendEmailStatusEnum.FAILED]: 'OVERDUE_NOTICE.NOTICE_PENDING',
};


