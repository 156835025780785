import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadPO,
  LoadPurchaseOrderPositions,
  UpdatePOCurrentState, UpdatePOSummary,
  UpdatePOUpdatedAt,
  UpdatePurchaseOrderPositionsCount,
  LoadPOList,
  UpdateShouldRefreshEntity,
} from '../store/actions/po.actions';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { FilterModel } from '../../warehouse/models/filter.model';
import {
  PurchaseOrder,
  PurchaseOrderCounters,
  ResponsePOCountersModel,
  Batch,
  BatchModel,
  POSummaryModel,
  POGoodsToConfirmType,
  POInvoice
} from '../models';
import { PoTabs } from '../enums';
import { AppState } from '../../store/state/app.state';
import { TradeDocumentPositionModel, TradePositionModel } from '../../trade/models';
import { UIStatesEnum } from 'common/src/models';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { TradeOfferPositionsByProductType } from '../../trade-offer/models';
import { PredefinedDocumentDataModel } from 'common/src/models/predefined-document-data.model';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { environment } from '../../../environments/environment';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { DocumentTemplateModel } from '../../shared/models';


@Injectable()
export class PurchaseOrderApiService {
  private readonly apiEndpoint: string = '/purchase-orders';

  private apiUrl(url: string = ''): string {
    return [this.apiEndpoint, url].join('');
  }

  constructor(
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly store: Store<AppState>) { }

  getPoList(
    status: PoTabs,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION},
    filters: any = {}
  ): Observable<ResponseList<PurchaseOrder>> {

    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<PurchaseOrder>>(this.apiUrl(), { params } )
      .pipe(tap((data: ResponseList<PurchaseOrder>) => {
        this.store.dispatch(LoadPOList({
          POListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getPurchaseOrderListPositions(poId: number): Observable<TradePositionModel[]> {
    return this.http.get<ResponseModel<TradePositionModel[]>>(this.apiUrl(`/${poId}/list/positions`))
      .pipe(map((data: ResponseModel<TradePositionModel[]>) => data.data));
  }

  getPurchaseOrdersRunpleIds(status: PoTabs): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl(`/runpleIds`), { params })
      .pipe(map((data: any) => data.data));
  }

  @DisplayToaster({showErrorMessage: true})
  getPOListCounters(): Observable<PurchaseOrderCounters> {
    return this.http.get<ResponseModel<ResponsePOCountersModel>>(this.apiUrl('/counters'))
      .pipe(map((data: ResponseModel<ResponsePOCountersModel>) => data.data.purchaseOrders));
  }

  updatePO(purchaseOrderId: number, field: FormInputChangedModel): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${purchaseOrderId}`), field)
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +purchaseOrderId);
          return throwError(error);
        })
      );
  }

  public trackingRefresh(purchaseOrderId: number): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PurchaseOrder>>('post', this.apiUrl(`/${purchaseOrderId}/tracking/refresh`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({ purchaseOrder: response.data }));
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +purchaseOrderId);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  updateDeliveryAddressByWarehouse(purchaseOrderId: number): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PurchaseOrder>>('put', this.apiUrl(`/${purchaseOrderId}/delivery-address/update-by-warehouse`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  updatePickUpAddressByVendor(purchaseOrderId: number): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PurchaseOrder>>('put', this.apiUrl(`/${purchaseOrderId}/pickup-address/update-by-vendor`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  createPurchasePosition(purchaseOrderId: number, position: Partial<TradePositionModel>): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<TradeOfferPositionsByProductType>>(this.apiUrl(`/${purchaseOrderId}/positions`), position)
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPurchaseOrderPositions({positions: response}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.totals.totalCount}));
          // this.store.dispatch(UpdateTradeOfferStatus({status: response.data.status}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +purchaseOrderId);
          return throwError(error);
        })
      );
  }

  createCorrectionPositions(
    purchaseOrderId: number,
    body: { targetAmountNet: number; targetVat: number; targetAmountGross: number; }
  ): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<TradeOfferPositionsByProductType>>(this.apiUrl(`/${purchaseOrderId}/positions/correction`), body)
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPurchaseOrderPositions({positions: response}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.totals.totalCount}));
          // this.store.dispatch(UpdateTradeOfferStatus({status: response.data.status}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +purchaseOrderId);
          return throwError(error);
        })
      );
  }

  updatePurchasePosition(positionId: number, field: FormInputChangedModel, poId: number): Observable<ResponseModel<TradePositionModel>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<TradePositionModel>>(this.apiUrl(`/positions/${positionId}`), field).pipe(
      tap((response: ResponseModel<TradePositionModel>) => {
        this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
        // this.store.dispatch(UpdateTradeOfferStatus({status: response.data.status}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, poId);
        return throwError(error);
      })
    );
  }

  @DisplayToaster({showErrorMessage: true})
  getPurchaseOrderPositions(purchaseOrderId: number): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    return this.http.get<ResponseModel<TradeOfferPositionsByProductType>>(this.apiUrl(`/${purchaseOrderId}/positions`))
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(LoadPurchaseOrderPositions({positions: response}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  clonePO(id: number): Observable<PurchaseOrder> {
    return this.http.request<ResponseModel<PurchaseOrder>>('post', this.apiUrl(`/${id}/clone`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.data.positionsCount }));
        }),
        map((data: ResponseModel<PurchaseOrder>) => data.data)
      );
  }

  getPurchaseOrderSummary(id: number | string): Observable<ResponseModel<POSummaryModel>> {
    return this.http.get<ResponseModel<POSummaryModel>>(this.apiUrl(`/${id}/summary`))
      .pipe(
        tap((data: ResponseModel<POSummaryModel>) => {
          this.store.dispatch(UpdatePOSummary({summary: data.data}));
        })
      );
  }

  public createNewBatch(purchaseOrderId, params: {batches: Batch[]}): Observable<any> {
    return this.http.put(`/purchase-orders/${purchaseOrderId}/batches`, params);
  }

  createAcceptPurchaseOrder(poId: string | number): Observable<PurchaseOrder> {
    return this.http.post<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${poId}/accept-create`), {})
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
        }),
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  deletePurchaseOrderPositions(purchaseOrderId: number, ids: number[]): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ids: ids };
    return this.http.request<ResponseModel<TradeOfferPositionsByProductType>>(
      'delete',
      this.apiUrl(`/${purchaseOrderId}/positions`),
      {body}
    )
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(LoadPurchaseOrderPositions({positions: response}));
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +purchaseOrderId);
          return throwError(error);
        })
      );
  }

  changePositionOrder(posId: number, moveToOrder: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(this.apiUrl(`/positions/${posId}/order/${moveToOrder}`), body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  createDefaultPurchaseOrder(data: PredefinedDocumentDataModel = {}): Observable<PurchaseOrder> {
    return this.http.post<ResponseModel<PurchaseOrder>>(this.apiUrl(`/draft`), {...data})
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public createPurchaseOrderBySalesOrderId(
    soId: number,
    positions: {positionId: number, quantity: number}[],
    proceedSo = false,
    finishSoEditing = false,
  ): Observable<PurchaseOrder> {
    const body = { proceedSo, finishSoEditing, positions };
    return this.http.request<ResponseModel<PurchaseOrder>>('post', this.apiUrl(`/by-sales-order-positions/${soId}`), {body})
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public unlinkPurchaseOrderWithSalesOrder(poId: number, soId: number): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${poId}/sales-order/${soId}`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.data.positionsCount}));
        }),
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public getPositionsFromPurchaseOrders(): Observable<TradeDocumentPositionModel[]> {
    return this.http.get<ResponseModel<TradeDocumentPositionModel[]>>(this.apiUrl('/positions/available-for-so'))
      .pipe(
        map((data: ResponseModel<TradeDocumentPositionModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public getPositionsAvailableForSO(poId: number): Observable<TradeDocumentPositionModel[]> {
    return this.http.get<ResponseModel<TradeDocumentPositionModel[]>>(this.apiUrl(`/${poId}/positions/available-for-so`))
      .pipe(
        map((data: ResponseModel<TradeDocumentPositionModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  getPO(id: number|string): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${id}`))
      .pipe(
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({purchaseOrder: response.data}));
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdatePurchaseOrderPositionsCount({positionsCount: response.data.positionsCount}));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data)
      );
  }

  getPurchaseOrderLinkedToIncomingInvoice(): Observable<POInvoice[]> {
    return this.http.get<ResponseModel<POInvoice[]>>(`${this.apiEndpoint}/invoice/filters`)
      .pipe(
        map((response: ResponseModel<POInvoice[]>) => response.data)
      );
  }

  changePurchaseOrdersStatus(status: PoTabs, poId: number): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status };
    return this.http
      .patch(this.apiUrl(`/${poId}/status`), body)
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPO({ purchaseOrder: response.data }));
        }),
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  deletePurchaseOrderPermanently(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(), {body});
  }

  getBatchById(id: string): Observable<BatchModel> {
    return this.http.get<ResponseModel<Batch>>(this.apiUrl(`/batches/${id}`))
      .pipe(map((data: ResponseModel<Batch>) => new BatchModel(data.data)));
  }

  deleteBatch(id: string): Observable<Object> {
    return this.http.delete<Object>(this.apiUrl(`/batches/${id}`));
  }

  confirmPositionsForGoodsReceipt(purchaseOrderId: string | number): Observable<PurchaseOrder> {
    return this.http.post<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${purchaseOrderId}/positions/confirm`), {})
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({purchaseOrder: response.data}))
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data)
      );
  }

  public purchaseOrderSetEdit(id: string | number, force = false): Observable<PurchaseOrder> {
    const body = { force };

    return this.http.put<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({ purchaseOrder: response.data }));
          this.store.dispatch(UpdatePOCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, +id);
          return throwError(error);
        })
      );
  }

  public purchaseOrderUnsetEdit(id: string | number): Observable<PurchaseOrder> {
    return this.http.request<ResponseModel<PurchaseOrder>>('put', this.apiUrl(`/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({ purchaseOrder: response.data }));
          this.store.dispatch(UpdatePOCurrentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, +id);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  changePOStatusBatch(status: PoTabs, order_ids: number[]): Observable<any> {
    const body = {status, order_ids};
    return this.http.patch(this.apiUrl('/multiple/statuses'), body);
  }

  @DisplayToaster({showErrorMessage: true})
  deletePOPermanentlyBatch(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(), {body});
  }

  @DisplayToaster({showErrorMessage: true})
  public getPOListExport(title: string, status: PoTabs): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  getPOGoodsToConfirm(poId: number): Observable<POGoodsToConfirmType> {
    return this.http.get<ResponseModel<POGoodsToConfirmType>>(this.apiUrl(`/${poId}/goods`))
      .pipe(map((data: ResponseModel<POGoodsToConfirmType>) => data.data));
  }

  confirmPOGoods(poId: number, body: POGoodsToConfirmType): Observable<any> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<any>(
      'post',
      this.apiUrl(`/${poId}/goods/confirm`),
      {body}
    ).pipe(

      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, +poId);
        return throwError(error);
      })
    );
  }

  returnPOGoods(poId: number, body: POGoodsToConfirmType): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<TradeOfferPositionsByProductType>>(
      'post',
      this.apiUrl(`/${poId}/positions/return`),
      {body}
    ).pipe(
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
    );
  }

  public getPurchaseOrderExportParams(id: number, title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `accounting/v1/pdf/purchase-order/${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  public getAvailableToIncomingInvoiceLinkPos(partnerId: number): Observable<Partial<PurchaseOrder>[]> {
    let params = {};

    if (partnerId) {
      params['partnerId'] = partnerId.toString();
    }
    return this.http.get<ResponseModel<Partial<PurchaseOrder>[]>>(this.apiUrl(`/invoice/available`), { params })
      .pipe(map((data: ResponseModel<Partial<PurchaseOrder>[]>) => data.data));
  }

  public getUploadPdfLink(poId: number): string {
    return `${environment.apiUrl}${environment.apiBase}/purchase-orders/${poId}/main/file`;
  }

  public getDownloadFileLink(fileId: number, title: string): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `/file/${fileId}/attachment`,
      title,
    };
    return of(fileParams);
  }

  public deleteFile(poId: number, fileId: string): Observable<PurchaseOrder> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<PurchaseOrder>>(this.apiUrl(`/${poId}/main/file/${fileId}`))
      .pipe(
        tap((response: ResponseModel<PurchaseOrder>) => {
          this.store.dispatch(LoadPO({purchaseOrder: response.data}))
          this.store.dispatch(UpdatePOUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<PurchaseOrder>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, poId);
          return throwError(error);
        })
      );
  }

  public downloadPurchaseOrderFile(poId: number): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: this.apiUrl(`/${poId}/file`)
    };
    return of(fileParams);
  }

  public getPurchaseOrdersFilterExternalNumbers(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/external-numbers'))
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data
            .filter(itm => !!itm)
            .sort((a, b) => a.localeCompare(b));
        })
      );
  }

  public getDocumentTemplates(): Observable<DocumentTemplateModel[]> {
    return this.http.get<ResponseModel<DocumentTemplateModel[]>>(this.apiUrl('/templates'))
      .pipe(map((response: ResponseModel<DocumentTemplateModel[]>) => response.data));
  }

  public getDocumentTemplatesNames(): Observable<{ id: number; name: string; }[]> {
    return this.http.get<ResponseModel<{ id: number; name: string; }[]>>(this.apiUrl('/templates/names'))
      .pipe(map((response: ResponseModel<{ id: number; name: string; }[]>) => response.data));
  }

  public saveDocumentTemplate(documentId: number, body: { name: string; overwrite: boolean; resetCounter: boolean; }): Observable<boolean> {
    return this.http.post<ResponseModel<boolean>>(this.apiUrl(`/${documentId}/template`), body)
      .pipe(map((response: ResponseModel<boolean>) => response.data));
  }

  public applyDocumentTemplate(documentId: number, templateId: number, force = false): Observable<any> {
    return this.http.patch<ResponseModel<any>>(this.apiUrl(`/${documentId}/template/${templateId}`), {force})
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDocumentFromTemplate(templateId: number): Observable<number> {
    return this.http.request<ResponseModel<number>>('post', this.apiUrl(`/template/${templateId}`))
      .pipe(map((response: ResponseModel<number>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, poId?: number): void {
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          ),
          backdropClass: 'backdrop-none',
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.purchaseOrderSetEdit(poId, true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('APP.PURCHASE_ORDER');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdatePOCurrentState({ currentState: UIStatesEnum.VIEW }));
        this.getPO(poId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message);
        break
    }
  }

}
