import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { EcoSettingsPaymentsModel } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import { BankAccountModel } from 'projects/workspace/src/app/bank-accounts/models';

@Component({
  selector: 'rnpl-update-purchase-on-invoice-modal',
  templateUrl: './update-purchase-on-invoice-modal.component.html',
})
export class UpdatePurchaseOnInvoiceModalComponent extends BaseModalComponent implements OnInit {

  public form: FormGroup;
  public bicControl: FormControl = new FormControl({value: null, disabled: true});
  public ibanControl: FormControl = new FormControl({value: null, disabled: true});

  public dueDays: {value: number, label: string}[] = [
    {value: 7, label: 'FORM.7_DAYS'},
    {value: 14, label: 'FORM.14_DAYS'},
    {value: 30, label: 'FORM.30_DAYS'},
    {value: 60, label: 'FORM.60_DAYS'},
    {value: 90, label: 'FORM.90_DAYS'},
  ];

  readonly updateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<UpdatePurchaseOnInvoiceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      paymentsSettings: EcoSettingsPaymentsModel
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.initForm();
    this.form.patchValue(get(this, 'data.paymentsSettings.purchaseOnInvoice'));
    this.bicControl.patchValue(get(this, 'data.paymentsSettings.purchaseOnInvoice.bankAccount.bic'));
    this.ibanControl.patchValue(get(this, 'data.paymentsSettings.purchaseOnInvoice.bankAccount.iban'));
  }

  public initForm(): void {
     this.form = this.fb.group({
       enabled: [],
       bankAccount: [],
       bankAccountId: [],
       dueWithinDays: [],
       type: [],
     });

     this.enabledControl.valueChanges
       .pipe(takeUntil(this._destroy))
       .subscribe((val: boolean) => {
         if (val) {
           this.bankAccountControl.setValidators(Validators.required);
         } else {
           this.bankAccountControl.clearValidators();
         }
         this.bankAccountControl.updateValueAndValidity();
       });
  }

  public bankChanged(bankAccount: BankAccountModel): void {
    this.data.paymentsSettings.purchaseOnInvoice.bankAccount = bankAccount;
    this.bicControl.patchValue(get(bankAccount, 'bic'));
    this.ibanControl.patchValue(get(bankAccount, 'iban'));
  }

  public submit(): void {
    if (this.updateRequest$.getValue()) { return; }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) {
      this.updateRequest$.next(false);
      return;
    }

    this.updateRequest$.next(true);

    this.ecoSettingsApiService.updateEcoSettingsPayments({
      fieldValue: this.form.getRawValue(),
      fieldName: 'purchaseOnInvoice'
    })
      .pipe(
        takeUntil(this._destroy),
        finalize(() => this.updateRequest$.next(false))
      )
      .subscribe((settings: EcoSettingsPaymentsModel) => this.dialogRef.close(settings));
  }

  public get enabledControl(): FormControl { return this.form.get('enabled') as FormControl; }
  public get typeControl(): FormControl { return this.form.get('type') as FormControl; }
  public get dueWithinDaysControl(): FormControl { return this.form.get('dueWithinDays') as FormControl; }
  public get bankAccountControl(): FormControl { return this.form.get('bankAccount') as FormControl; }

}
