import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'colorTranslatePipe'})
export class ColorTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(value: string, ...args: any[]): string {
    if (!value) { return value; }

    let translateKey: string;

    switch (value) {
      case 'Red':
      case 'Rot':
        translateKey = 'COLORS.RED';
        break;
      case 'Orange':
        translateKey = 'COLORS.ORANGE';
        break;
      case 'Yellow':
      case 'Gelb':
        translateKey = 'COLORS.YELLOW';
        break;
      case 'Light blue':
      case 'Hellblau':
        translateKey = 'COLORS.LIGHTBLUE';
        break;
      case 'Green':
      case 'Grün':
        translateKey = 'COLORS.GREEN';
        break;
      case 'Blue':
      case 'Blau':
        translateKey = 'COLORS.BLUE';
        break;
      case 'Purple':
      case 'Lila':
        translateKey = 'COLORS.PURPLE';
        break;
      case 'Pink':
      case 'Rosa':
        translateKey = 'COLORS.PINK';
        break;
      case 'Brown':
      case 'Braun':
        translateKey = 'COLORS.BROWN';
        break;
      case 'Black':
      case 'Schwarz':
        translateKey = 'COLORS.BLACK';
        break;
      case 'Grey':
      case 'Grau':
        translateKey = 'COLORS.GREY';
        break;
      case 'White':
      case 'Weiß':
        translateKey = 'COLORS.WHITE';
        break;
    }

    return translateKey
      ? this.translateService.instant(translateKey)
      : value;
  }
}
