import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';


import { WizardStep } from '../../../../wizard/wizard-step';
import { FormTooltipComponent } from '../../../../ui-components/form-tooltip/form-tooltip.component';
import { AttributeWizardComponent } from '../../attribute-wizard.component';


@Component({
  selector: 'rnpl-entity-type',
  templateUrl: './entity-type.component.html',
  styleUrls: ['./entity-type.component.scss']
})
export class EntityTypeComponent extends WizardStep implements OnInit {

  @ViewChild('entityTypeHint', {static: false})
  private entityTypeHint: FormTooltipComponent;

  constructor(@Optional() public wizard: AttributeWizardComponent) {
    super();
  }

  ngOnInit(): void {
    if (this.form.get('entityType').value.length) {
      this.wizard.progress = this.progress.finish;
    }

    this.form.get('entityType').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.wizard.progress = this.progress.finish;
        if (this.entityTypeHint) {
          this.entityTypeHint.hide();
        }
      });
  }

  public setEntityType(type, event): void {
    let entityTypes = this.form.get('entityType').value;

    if (event.target.checked) {
      if (!entityTypes.includes(type)) {
        entityTypes.push(type);
      }
    } else {
      entityTypes = entityTypes.filter(entityType => entityType !== type);
    }
    this.form.get('entityType').setValue(entityTypes);
  }

}
