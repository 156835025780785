import { createReducer, on } from '@ngrx/store';

import {PaginationModel, UIStatesEnum, UserModel} from 'common/src/models';
import { CollectionTeamsActions } from '../actions/action-types';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { UserListTabs } from '../../pages/user-list/enums/user-list-tabs.enum';
import { EmployeesListTotalsModel, UserProfileModel } from '../../models';

export const TeamsFeatureKey = 'teamsStore';

export interface TeamsListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: UserModel[];
    totals: EmployeesListTotalsModel;
  }
}

export interface TeamsState {
  [UserListTabs.Active]?: TeamsListState;
  [UserListTabs.Inactive]?: TeamsListState;
  [UserListTabs.Deleted]?: TeamsListState;
}

export interface EntityTeamsState {
  user: UserProfileModel;
  loggedUser: UserProfileModel;
  currentState: UIStatesEnum;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export interface TeamsStore {
  list: TeamsState;
  entity: EntityTeamsState;
}

export const initialTeamsState: TeamsState = {
  [UserListTabs.Active]: {},
  [UserListTabs.Inactive]: {},
  [UserListTabs.Deleted]: {},
}

export const initialEntityTeamsState: EntityTeamsState = {
  user: null,
  loggedUser: null,
  currentState: UIStatesEnum.VIEW,
  updatedAt: null,
  isShouldRefresh: false
}

export function TeamsListReducer(state, action) {
  return _TeamsListReducer(state, action);
}

const _TeamsListReducer = createReducer(
  initialTeamsState,
  on(CollectionTeamsActions.LoadTeamsList, (state, { teamsListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...teamsListData
    },
  })),
);

export function TeamsEntityReducer(state, action) {
  return _TeamsEntityReducer(state, action);
}

const _TeamsEntityReducer = createReducer(
  initialEntityTeamsState,
  on(CollectionTeamsActions.LoadUser, (state, { user }) => ({
    ...state,
    user,
    updatedAt: new Date(),
  })),
  on(CollectionTeamsActions.LoadLoggedUser, (state, { loggedUser }) => ({
    ...state,
    loggedUser,
  })),
  on(CollectionTeamsActions.UpdateTeamsUserUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionTeamsActions.UpdateUserCurrentState, (state, { currentState }) => ({
    ...state,
    currentState
  })),
  on(CollectionTeamsActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
);
