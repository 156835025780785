import { get } from 'lodash';

import { OutgoingInvoiceListTabsEnum } from './enums';
import { OutgoingInvoiceModel } from './models';
import { PaymentMethodsEnum, UIStatesEnum } from 'common/src/models';
import { ProductTypes } from '../../../../../common/src/modules/products/product-types';

export function getOutgoingInvoicesHints(
  currentState?: UIStatesEnum,
  hasInvoices?: boolean,
  hasPositions?: boolean,
  hasLinkedCreditNote?: boolean,
  showDropdownHints?: boolean,
  documentStatus?: OutgoingInvoiceListTabsEnum,
  outgoingInvoice?: OutgoingInvoiceModel,
  hasLinkedSo?: boolean,
  hasLinkedPayment?: boolean,
  displayDeliveredColumnHint?: boolean,
  isEqualized?: boolean,
  isTableView?: boolean,
) {

  const isReadOnly = currentState === UIStatesEnum.VIEW;
  const isCreateState = currentState === UIStatesEnum.CREATE;

  const isDeletedStatus = documentStatus === OutgoingInvoiceListTabsEnum.DELETED;
  const isCanceledStatus = documentStatus === OutgoingInvoiceListTabsEnum.CANCELED;
  const isPaidStatus = documentStatus === OutgoingInvoiceListTabsEnum.PAID;
  const isOpenStatus = documentStatus === OutgoingInvoiceListTabsEnum.OPEN;
  const isDraftStatus = documentStatus === OutgoingInvoiceListTabsEnum.DRAFT;

  const hintsList = {
    ['outgoing_invoices_view_draft_4']: {
      text: 'MEGA_HINT.OIN.HINT_8',
      attachTo: {
        element: '#sendEmailsFields',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
    ['create_entity_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_15',
      attachTo: {
        element: '#createInvoiceBtn',
        on: 'bottom'
      },
    },
    ['filter_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_19',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
    ['outgoing_invoices_add_position_1']: {
      text: 'MEGA_HINT.OIN.HINT_31',
      attachTo: {
        element: '#selectProduct',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    // ['reverse_charge_toggle_outgoing_invoices']: { // todo: move to billingInfo Modal
    //   text: 'MEGA_HINT.OIN.HINT_37',
    //   attachTo: {
    //     element: '#vatDisabled',
    //     on: 'bottom'
    //   },
    // },
  }

  const exportListBtnHint = {
    ['export_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_18',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  // const paymentReferenceHint = {
  //   ['outgoing_invoices_view_draft_3']: {  // todo: move to billing info modal
  //     text: 'MEGA_HINT.OIN.HINT_7',
  //     attachTo: {
  //       element: '#paymentReference',
  //       on: 'bottom'
  //     },
  //   },
  // }

  // const recipientBankDetails = {
  //   ['outgoing_invoices_view_draft_2']: { // todo: move to billing info modal
  //     text: 'MEGA_HINT.OIN.HINT_6',
  //     attachTo: {
  //       element: '#recipientBankDetails',
  //       on: 'bottom'
  //     },
  //     popperOptions : {
  //       modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
  //     },
  //   },
  // }

  const linkedBtnHint = {
    ['linked_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_28',
      attachTo: {
        element: '#linkBtn',
        on: 'bottom'
      },
    },
  }

  const emailSendCheckedFieldHint = {
    ['email_indicator_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_25',
      attachTo: {
        element: '#emailSendCheckedField',
        on: 'bottom'
      },
    },
  }

  // const remarkPositionsHints = {
  //   ['outgoing_invoices_view_draft_8']: {
  //     text: 'MEGA_HINT.OIN.HINT_12',
  //     attachTo: {
  //       element: '#remarkPositionsField',
  //       on: 'top'
  //     },
  //     popperOptions : {
  //       modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
  //     },
  //   },
  // }

  // const deliveredFieldPositionHint = {
  //   ['outgoing_invoices_view_draft_7']: {
  //     text: 'MEGA_HINT.OIN.HINT_11',
  //     attachTo: {
  //       element: '#deliveredPositionColumn',
  //       on: 'top'
  //     },
  //     popperOptions : {
  //       modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
  //     },
  //   },
  // }

  const exportBtnHint = {
    ['export_send_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_23',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const cloneBtnHint = {
    ['clone_entyty_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_22',
      attachTo: {
        element: '#cloneBtn',
        on: 'bottom'
      },
    },
  }

  const editBtnHint = {
    ['edit_entity_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_26',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
  }

  const openViewStateHints = {
    ['cancel_entity_button_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_21',
      attachTo: {
        element: '#canceledBtn',
        on: 'bottom'
      },
    },
    ['outgoing_invoices_view_open_1']: {
      text: 'MEGA_HINT.OIN.HINT_13',
      attachTo: {
        element: '#paidStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const creditNoteDocumentHint = {
    ['linked_document_cn_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_30',
      attachTo: {
        element: '#creditNoteStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [
          {
            name: 'offset',
            options: {
              offset : [0, !isEqualized && isPaidStatus ? -40 : 0]
            }
          }
        ]
      },
    },
  }

  const createCreditNoteBtn = {
    ['outgoing_invoices_view_paid_1']: {
      text: 'MEGA_HINT.OIN.HINT_34',
      attachTo: {
        element: '#creditNoteStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const dropdownHints = {
    ['outgoing_invoices_add_position_2']: {
      text: 'MEGA_HINT.OIN.HINT_32',
      attachTo: {
        element: '#createProductDropdown',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['outgoing_invoices_add_position_3']: {
      text: 'MEGA_HINT.OIN.HINT_33',
      attachTo: {
        element: '#userDefinedPositionDropdown',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const vatCheckedHint = {
    ['vat_indicator_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_24',
      attachTo: {
        element: '#vatCheckedField',
        on: 'bottom'
      },
    },
  }

  const linkedSoHint = {
    ['outgoing_invoices_view_open_2']: {
      text: 'MEGA_HINT.OIN.HINT_14',
      attachTo: {
        element: '#salesOrderStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -17] }}]
      },
    },
  }

  const linkedPaymentHint = {
    ['linked_document_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_27',
      attachTo: {
        element: '#paidStep',
        on: 'bottom'
      },
    },
    ['linked_document_payment_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_27',
      attachTo: {
        element: '#paidStep',
        on: 'bottom'
      },
    },
  }

  const draftStatusHints = {
    ['tab_draft_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_16',
      attachTo: {
        element: '#draftTab',
        on: 'bottom'
      },
    },
  }

  const sidebarHint = {
    ['sidebar_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_20',
      attachTo: {
        element: '#sidebarOin',
        on: 'left'
      },
    },
  }

  const draftCreateStateHints = {
    ['outgoing_invoices_view_draft_1']: {
      text: 'MEGA_HINT.OIN.HINT_5',
      attachTo: {
        element: '#createBtn',
        on: 'bottom'
      },
    },
  }

  const paidStatusHints = {
    ['outgoing_invoices_tab_paid_1']: {
      text: 'MEGA_HINT.OIN.HINT_4',
      attachTo: {
        element: '#paidTab',
        on: 'bottom'
      },
    },
  }

  const canceledStatusHints = {
    ['outgoing_invoices_tab_canceled_1']: {
      text: 'MEGA_HINT.OIN.HINT_17',
        attachTo: {
        element: '#canceledTab',
          on: 'bottom'
      },
    },
  }

  const openStatusHints = {
    ['outgoing_invoices_tab_open_1']: {
      text: 'MEGA_HINT.OIN.HINT_1',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
  }

  const draftAndOpenColumnHints = {
    ['outgoing_invoices_tab_open_2']: {
      text: 'MEGA_HINT.OIN.HINT_2',
      attachTo: {
        element: '#openAmountColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },

    ['outgoing_invoices_tab_open_3']: {
      text: 'MEGA_HINT.OIN.HINT_3',
      attachTo: {
        element: '#dueWithinColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const tableSummaryHint = {
    ['total_row_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_35',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'top'
      },
    },
  }
  const deleteTabHint = {
    ['tab_deleted_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_36',
      attachTo: {
        element: '#deletedTab',
        on: 'top'
      },
    },
  }

  const editStateHints = {
    // ['outgoing_invoices_view_draft_5']: { //todo: move to remark modal
    //   text: 'MEGA_HINT.OIN.HINT_9',
    //   attachTo: {
    //     element: '#printable_remark',
    //     on: 'top'
    //   },
    //   popperOptions : {
    //     modifiers : [{ name: 'offset', options: { offset : [17, 17] }}]
    //   },
    // },
    ['save_timer_outgoing_invoices']: {
      text: 'MEGA_HINT.OIN.HINT_39',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const createPositionBtnHint = {
    ['create_positions_button']: {
      text: 'MEGA_HINT.OIN.HINT_38',
      attachTo: {
        element: '#addPositionBtn',
        on: 'bottom'
      },
    },
  }

  if (isDraftStatus) {
    Object.assign(hintsList, draftStatusHints);

    if (!isReadOnly) {
      Object.assign(hintsList, sidebarHint);
    }
  }
  if (isPaidStatus) {
    Object.assign(hintsList, paidStatusHints);
  }
  if (isCanceledStatus) {
    Object.assign(hintsList, canceledStatusHints);
  }
  if (isOpenStatus) {
    Object.assign(hintsList, openStatusHints);
  }
  if (isDeletedStatus) {
    Object.assign(hintsList, deleteTabHint);
  }
  if ((isOpenStatus || isDraftStatus) && hasInvoices) {
    Object.assign(hintsList, draftAndOpenColumnHints);
  }
  if (!isDeletedStatus && !isCanceledStatus && hasInvoices) {
    Object.assign(hintsList, tableSummaryHint);
  }
  // if (hasPositions && !isReadOnly && !isPaidStatus) {
  //   Object.assign(hintsList, remarkPositionsHints);
  // }
  if (isReadOnly && !isDeletedStatus) {
    Object.assign(hintsList, exportBtnHint);
  }
  if (isReadOnly && !isCanceledStatus && !isDeletedStatus && isPaidStatus) {
    Object.assign(hintsList, editBtnHint);
  }
  if (hasLinkedCreditNote) {
    Object.assign(hintsList, creditNoteDocumentHint);
  }
  if (showDropdownHints) {
    Object.assign(hintsList, dropdownHints);
  }
  if (!get(outgoingInvoice, 'customer.noVat', true) && !isDeletedStatus && !isDraftStatus && !isCreateState) {
    Object.assign(hintsList, vatCheckedHint);
  }
  if (hasLinkedSo && !isDeletedStatus) {
    Object.assign(hintsList, linkedSoHint);
  }
  if (hasLinkedPayment) {
    Object.assign(hintsList, linkedPaymentHint);
  }
  if (!isDraftStatus && !isDeletedStatus) {
    Object.assign(hintsList, emailSendCheckedFieldHint);
  }
  // if (!isReadOnly && !isPaidStatus && displayDeliveredColumnHint) {
  //   Object.assign(hintsList, deliveredFieldPositionHint);
  // }
  if (!get(outgoingInvoice, 'linkedDocuments', []).length && !isDeletedStatus && !isCanceledStatus) {
    Object.assign(hintsList, linkedBtnHint);
  }
  if (!isReadOnly) {
    Object.assign(hintsList, editStateHints);
  }
  if (!isReadOnly && !isPaidStatus) {
    Object.assign(hintsList, createPositionBtnHint);
  }
  if (isReadOnly && isOpenStatus) {
    Object.assign(hintsList, openViewStateHints);
  }
  if (!isEqualized && isPaidStatus) {
    Object.assign(hintsList, createCreditNoteBtn);
  }
  if (isReadOnly && !isDraftStatus && !isDeletedStatus) {
    Object.assign(hintsList, cloneBtnHint);
  }
  if (get(outgoingInvoice, 'billingData.paymentMethod') === PaymentMethodsEnum.BANK_TRANSFER) {
    // Object.assign(hintsList, recipientBankDetails);
    // Object.assign(hintsList, paymentReferenceHint);
  }
  if (isDraftStatus && isCreateState) {
    Object.assign(hintsList, draftCreateStateHints);
  }
  if (hasInvoices) {
    Object.assign(hintsList, exportListBtnHint);
  }

  return hintsList;
}


export const BillingInfoHints = {
  ['outgoing_invoices_view_draft_2']: {
    text: 'MEGA_HINT.OIN.HINT_6',
    attachTo: {
      element: '#recipientBankDetails',
      on: 'bottom'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
    },
  },
  ['outgoing_invoices_view_draft_3']: {
    text: 'MEGA_HINT.OIN.HINT_7',
    attachTo: {
      element: '#paymentReference',
      on: 'bottom'
    },
  },
}

export const SelectCustomerHint = {
  ['outgoing_invoices_view_draft_6']: {
    text: 'MEGA_HINT.OIN.HINT_10',
    attachTo: {
      element: '#contactPersonFromModal',
      on: 'bottom'
    },
  }
}

export function getEditPositionsHints(productType: ProductTypes) {
  const hintsList = {
    ['outgoing_invoices_view_draft_8']: {
      text: 'MEGA_HINT.OIN.HINT_12',
      attachTo: {
        element: '#remarkPositionsField',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
      },
    },
  }

  const goodsHints = {
    ['outgoing_invoices_view_draft_7']: {
      text: 'MEGA_HINT.OIN.HINT_11',
      attachTo: {
        element: '#deliveredPositionColumn',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
      },
    },
  }

  if (productType === ProductTypes.GOODS) {
    Object.assign(hintsList, goodsHints);
  }

  return hintsList;

}
