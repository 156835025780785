import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DeliveryServiceEnum } from 'projects/workspace/src/app/shared/enums';

@Pipe({name: 'deliveryServiceTranslatePipe'})
export class DeliveryServiceTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(value: DeliveryServiceEnum|string, ...args: any[]): string {
    if (!value) { return value; }

    let translateKey: string;

    switch (value) {
      case DeliveryServiceEnum.AUSTRIAN_POST:
        translateKey = 'POST_SERVICES.AUSTRIAN_POST';
        break;
      case DeliveryServiceEnum.USER_DEFINED:
        translateKey = 'COMMON.USER_DEFINED';
        break;
    }

    return translateKey
      ? this.translateService.instant(translateKey)
      : value;
  }
}
