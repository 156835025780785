import { createReducer, on } from '@ngrx/store';

import { CollectionPOActions, CollectionBatchActions } from '../actions/action-types';
import { Batch } from '../../models/purchase-order/batch';
import { BatchStates } from '../../enums/batch-states';
import { PurchaseOrder } from '../../models';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { CorporatePartnerModel } from '../../../crm/models';
import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { ResponseModel } from '../../../shared/models/response';
import { TradeOfferPositionsByProductType } from '../../../trade-offer/models';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { PoTabs } from '../../enums';

export const POFeatureKey = 'purchaseOrderStore';

export interface POListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: PurchaseOrder[];
  }
}
export interface POsState {
  [PoTabs.Canceled]?: POListState;
  [PoTabs.Completed]?: POListState;
  [PoTabs.Deleted]?: POListState;
  [PoTabs.Draft]?: POListState;
  [PoTabs.Open]?: POListState;
  [PoTabs.Template]?: POListState;
}

export interface POStore {
  entity: POState;
  list: POListState;
  blocksValidations: POBlocksValidations;
}


export interface POState {
  // currentBatch: {batch: Batch, batchState: BatchStates};
  purchaseOrder: PurchaseOrder;
  currentState: UIStatesEnum;
  positions: ResponseModel<TradeOfferPositionsByProductType>;
  countries: CountryModel[];
  partners: CorporatePartnerModel[];
  positionsCount: number;
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export const initialPOState: POState = {
  // currentBatch: {batch: {}, batchState: BatchStates.VIEW},
  purchaseOrder: null,
  countries: [],
  partners: [],
  currentState: UIStatesEnum.CREATE,
  positions: null,
  positionsCount: 0,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false,
};

export const initialPOsState: POsState = {
  [PoTabs.Canceled]: {},
  [PoTabs.Completed]: {},
  [PoTabs.Deleted]: {},
  [PoTabs.Draft]: {},
  [PoTabs.Open]: {},
  [PoTabs.Template]: {},
}

export interface POBlocksValidations {
  propertiesBlockValid: boolean;
  deliveryInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialPOBlocksValidationsState: POBlocksValidations = {
  propertiesBlockValid: false,
  deliveryInfoBlockValid: false,
  positionsBlockValid: false,
};


export function POBlocksValidationsReducer(state, action) {
  return _POBlocksValidationsReducer(state, action);
}

const _POBlocksValidationsReducer = createReducer(
  initialPOBlocksValidationsState,
  on(CollectionPOActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
    ...state,
    propertiesBlockValid
  })),
  on(CollectionPOActions.UpdateDeliveryInfoBlockValid, (state, { deliveryInfoBlockValid }) => ({
    ...state,
    deliveryInfoBlockValid
  })),
  on(CollectionPOActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
);

export function POListReducer(state, action) {
  return _POListReducer(state, action);
}

const _POListReducer = createReducer(
  initialPOsState,
  on(CollectionPOActions.LoadPOList, (state, { POListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...POListData
    },
  })),
);


export function POReducer(state, action) {
  return _POReducer(state, action);
}

const _POReducer = createReducer(
  initialPOState,
  // Purchase Order
  on(CollectionPOActions.LoadPO, (state, { purchaseOrder }) => ({
    ...state,
    purchaseOrder
  })),
  on(CollectionPOActions.UpdatePO, (state, { purchaseOrder }) => ({
    ...state,
    purchaseOrder
  })),
  // Batch
  // on(CollectionBatchActions.ClearCurrentBatch, (state) => ({
  //   ...state,
  //   currentBatch: {}
  // })),
  // on(CollectionBatchActions.LoadCurrentBatch, (state, { batch }) => ({
  //   ...state,
  //   currentBatch: { ...state.currentBatch, batch }
  // })),
  // // Batch states
  // on(CollectionBatchActions.LoadBatchState, (state, { batchState }) => ({
  //   ...state,
  //   currentBatch: { ...state.currentBatch, batchState }
  // })),
  // on(CollectionBatchActions.ResetBatchState, (state) => ({
  //   ...state,
  //   currentBatch: { ...state.currentBatch, batchState: {} }
  // })),
  on(CollectionPOActions.LoadCountries, (state, {countries}) => ({
    ...state,
    countries
  })),
  on(CollectionPOActions.LoadPOPartners, (state, {partners}) => ({
    ...state,
    partners
  })),
  on(CollectionPOActions.UpdatePOCurrentState, (state, { currentState }) => ({
    ...state,
    currentState: currentState
  })),
  on(CollectionPOActions.UpdatePurchaseOrderPositionsCount, (state, { positionsCount }) => ({
    ...state,
    positionsCount
  })),
  on(CollectionPOActions.LoadPurchaseOrderPositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionPOActions.UpdatePurchaseOrderStatus, (state, { status}) => ({
    ...state,
    purchaseOrder: {
      ...state.purchaseOrder,
      status: status
    }
  })),
  on(CollectionPOActions.UpdatePOSummary, (state, { summary }) => ({
    ...state,
    purchaseOrder: {
      ...state.purchaseOrder,
      summary
    }
  })),
  on(CollectionPOActions.UpdatePOUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionPOActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
      ...state,
      isShouldRefresh,
  })),
  on(CollectionPOActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionPOActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
  on(CollectionPOActions.ClearPOState, (state) => ({
    ...state,
    purchaseOrder: null,
    currentState: UIStatesEnum.CREATE,
    isInvoiceExist: false,
    positions: null,
    positionsCount: 0,
    loadingRequestsCount: 0,
    updatedAt: null
  })),
  on(CollectionPOActions.ClearPOPositions, (state) => ({
    ...state,
    positions: null
  })),
);
