import {
  Component,
  ContentChild,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { debounceTime } from 'rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';

import { HeaderRowDefDirective, RowContext, RowDefDirective } from './row';

@Component({
  selector: 'rnpl-table',
  templateUrl: './table.html',
  styleUrls: ['./table.scss']
})
export class TableComponent implements OnInit, OnChanges {

  private static PAGE_CONTAINER_SELECTOR = 'mat-sidenav-content';
  private static PAGE_HEADER_SELECTOR = 'header';

  /**
   * Table data
   */
  @Input()
  public dataSource: Array<any>;

  @Input()
  public columns: Array<string>;

  @Output()
  public tableRendered: EventEmitter<any> = new EventEmitter();

  /**
   * Outlets for table rows
   */
  @ViewChild('headerRowOutlet', {read: ViewContainerRef, static: true})
  private headerRowOutlet: ViewContainerRef;
  @ViewChild('rowOutlet', {read: ViewContainerRef, static: true})
  private rowOutlet: ViewContainerRef;

  /**
   * Elements for pinned rows
   */
  @ViewChild('pinnedRowsWrap', {read: ElementRef, static: true})
  private pinnedRowsWrap: ElementRef;
  @ViewChild('fixedRowsWrap', {read: ElementRef, static: true})
  private fixedRowsWrap: ElementRef;
  @ViewChild('mainTableWrap', {read: ElementRef, static: true})
  private mainTableWrap: ElementRef;

  @ViewChild('pinnedHead', {read: ElementRef, static: true})
  private pinnedHead: ElementRef;
  @ViewChild('pinnedRows', {read: ViewContainerRef, static: true})
  private pinnedRows: ViewContainerRef;

  /**
   * Definitions of rows
   */
  @ContentChild(HeaderRowDefDirective, {static: true})
  private headerRowDef: HeaderRowDefDirective;
  @ContentChild(RowDefDirective, {static: true})
  private rowDef: RowDefDirective;

  private columnsWidth: Map<string, number> = new Map<string, number>();

  private rowVies: Map<any, EmbeddedViewRef<RowContext<any>>> = new Map<any, EmbeddedViewRef<RowContext<any>>>();
  private pinnedRowViews: Map<any, EmbeddedViewRef<RowContext<any>>> = new Map<any, EmbeddedViewRef<RowContext<any>>>();

  constructor(private hostElement: ElementRef) {
  }

  ngOnInit() {
    this.renderTableHeader();
    this.tableRendered.subscribe(() => {
      setTimeout(() => {
        this.readColumnsWidth();
        this.initPinnedRows();
      }, 200);
    });
  }

  /**
   * Re renders rows if data source is changed
   *
   * @param changes list of changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('dataSource')) {
      this.renderRows();
    }
  }

  /**
   * Pins row
   *
   * @param rawRow Row for pining
   */
  public pinRow(rawRow: any): void {
    const rowView = this.rowVies.get(rawRow);
    const viewIndex = this.rowOutlet.indexOf(rowView);
    if (viewIndex === -1) {
      return;
    }

    this.pinnedRows.insert(this.rowOutlet.detach(viewIndex));
    this.pinnedRowViews.set(rawRow, rowView);

    this.setPadding();
    this.setEvenOddClasses();
  }

  /**
   * Checks if row pinned
   *
   * @param row Row for checking
   */
  public isRowPinned(row: any): boolean {
    return !!this.pinnedRowViews.get(row);
  }

  /**
   * Unpins row
   *
   * @param rawRow Row for unpinning
   */
  public unpinRow(rawRow: any): void {
    const rowView = this.pinnedRowViews.get(rawRow);
    const viewIndex = this.pinnedRows.indexOf(rowView);
    if (viewIndex === -1) {
      return;
    }

    this.rowOutlet.insert(this.pinnedRows.detach(viewIndex), this.findInsertIndex(rowView));
    this.pinnedRowViews.delete(rawRow);

    this.setPadding();
    this.setEvenOddClasses();
  }

  /**
   * Updates even / odd classes for rows
   */
  private setEvenOddClasses(): void {
    let pinnedRowIndex = 0;
    for (; pinnedRowIndex < this.pinnedRows.length; pinnedRowIndex++) {
      const rowView = this.pinnedRows.get(pinnedRowIndex) as EmbeddedViewRef<RowContext<any>>;
      const rowEl = rowView.rootNodes[0] as HTMLTableRowElement;
      if (pinnedRowIndex % 2 === 0) {
        rowEl.classList.replace('odd', 'even');
      } else {
        rowEl.classList.replace('even', 'odd');
      }
    }

    for (let index = 0; index < this.rowOutlet.length; index++) {
      const rowView = this.rowOutlet.get(index) as EmbeddedViewRef<RowContext<any>>;
      const rowEl = rowView.rootNodes[0] as HTMLTableRowElement;
      if ((index + pinnedRowIndex) % 2 === 0) {
        rowEl.classList.replace('odd', 'even');
      } else {
        rowEl.classList.replace('even', 'odd');
      }
    }
  }

  /**
   * Finds index to insert unpinned row
   *
   * @param view The inserted row
   */
  private findInsertIndex(view: EmbeddedViewRef<RowContext<any>>): number {
    let result = 0;
    let rowView = this.rowOutlet.get(0) as EmbeddedViewRef<RowContext<any>>;
    while (rowView.context.index < view.context.index) {
      result++;
      rowView = this.rowOutlet.get(result) as EmbeddedViewRef<RowContext<any>>;
    }

    return result;
  }

  /**
   * Sets padding under pinned rows
   */
  private setPadding(): void {
    this.mainTableWrap.nativeElement.style.paddingTop =
      this.pinnedRowsWrap.nativeElement.offsetHeight - this.pinnedHead.nativeElement.offsetHeight + 'px';
  }

  /**
   * Renders table header
   */
  private renderTableHeader(): void {
    this.headerRowOutlet.createEmbeddedView(this.headerRowDef.template);
  }

  /**
   * Renders table rows
   */
  private renderRows(): void {
    if (!this.dataSource || !(this.dataSource instanceof Array)) {
      console.warn('Invalid data source.');
      return;
    }

    this.rowOutlet.clear();
    this.pinnedRows.clear();
    this.rowVies.clear();
    this.pinnedRowViews.clear();
    this.pinnedHead.nativeElement.innerHTML = '';

    this.dataSource.forEach((row: any, index) => {
      const rowView: EmbeddedViewRef<any> = this.rowOutlet.createEmbeddedView(
        this.rowDef.template,
        {
          $implicit: row,
          index: index,
          count: this.dataSource.length,
          first: index === 0,
          last: index === this.dataSource.length - 1,
          even: index % 2 === 0,
          odd: index % 2 !== 0
        }
      );
      this.rowVies.set(row, rowView);

      /**
       * To catch moment when table is fully rendered
       */
      if (index === this.dataSource.length - 1) {
        const rowElement = rowView.rootNodes[0] as HTMLElement;

        let cellIndex = 0;
        rowElement.addEventListener('DOMNodeInserted', (event) => {
          cellIndex++;
          if (cellIndex === this.columns.length) {
            this.tableRendered.emit();
          }
        });
      }
    });
  }

  /**
   * Saves original columns width
   */
  private readColumnsWidth(): void {
    const headerRow = (this.headerRowOutlet.get(0) as any).rootNodes[0] as HTMLTableRowElement;

    for (let index = 0; index < headerRow.cells.length; index++) {
      this.columnsWidth.set(this.columns[index], headerRow.cells.item(index).offsetWidth);
    }
  }

  /**
   * Updates columns width
   */
  private updateColumnsWidth(): void {
    const headerRow = (this.headerRowOutlet.get(0) as any).rootNodes[0] as HTMLTableRowElement;
    const pinnedRowsWrap = this.pinnedRowsWrap.nativeElement;
    const fixedRowsWrap = this.fixedRowsWrap.nativeElement;
    pinnedRowsWrap.style.width = headerRow.offsetWidth + 'px';
    fixedRowsWrap.style.width = headerRow.offsetWidth + 'px';

    this.readColumnsWidth();
    const pinnedHeaderRow = this.pinnedHead.nativeElement.rows[0];
    for (let index = 0; index < pinnedHeaderRow.cells.length; index++) {
      pinnedHeaderRow.cells.item(index).style.width = this.columnsWidth.get(this.columns[index]) + 'px';
    }
  }

  /**
   * Initiates table for fixed header and pinned rows
   */
  private initPinnedRows(): void {
    const headerRow = (this.headerRowOutlet.get(0) as any).rootNodes[0] as HTMLTableRowElement;
    this.pinnedHead.nativeElement.appendChild(headerRow.cloneNode(true));

    this.updateColumnsWidth();
    fromEvent(window, 'resize')
      .pipe(debounceTime(250))
      .subscribe((event) => {
        this.updateColumnsWidth();
      });

    const container = document.querySelector(TableComponent.PAGE_CONTAINER_SELECTOR);
    const pinnedRowsWrap = this.pinnedRowsWrap.nativeElement;
    const fixedRowsWrap = this.fixedRowsWrap.nativeElement;
    const containerRectTop = container.getBoundingClientRect().top;
    const headerRectHeight = document.querySelector(TableComponent.PAGE_HEADER_SELECTOR).getBoundingClientRect().height;

    fixedRowsWrap.style.top = containerRectTop + headerRectHeight + 'px';
    fixedRowsWrap.style.left = this.mainTableWrap.nativeElement.getBoundingClientRect().left + 'px';

    fromEvent(this.hostElement.nativeElement, 'scroll')
      .pipe(debounceTime(250))
      .subscribe((event) => {
        const tableRect = this.mainTableWrap.nativeElement.getBoundingClientRect();
        fixedRowsWrap.style.left = tableRect.left + 'px';
      });

    /**
     * Displays fixed header if the real one is gone out of viewport
     */
    let lastScrollTop = 0;
    fromEvent(container, 'scroll')
      .pipe(tap((event) => {
        const tableRect = this.mainTableWrap.nativeElement.getBoundingClientRect();
        if (container.scrollTop === lastScrollTop) {
          return;
        }

        if (lastScrollTop > container.scrollTop && tableRect.top >= 0) {
          fixedRowsWrap.style.display = 'none';
          fixedRowsWrap.innerHTML = '';
          pinnedRowsWrap.style.display = 'block';
          this.setPinnedRowsPos();
          return;
        }

        if (tableRect.top < containerRectTop) {
          fixedRowsWrap.innerHTML = pinnedRowsWrap.innerHTML;
          fixedRowsWrap.style.display = 'block';
          pinnedRowsWrap.style.display = 'none';
        }
        this.setPinnedRowsPos();

        lastScrollTop = container.scrollTop;
      }))
      .pipe(debounceTime(250))
      .subscribe((event) => {
        fixedRowsWrap.style.display = 'none';
        fixedRowsWrap.innerHTML = '';
        pinnedRowsWrap.style.display = 'block';
      });
  }

  /**
   * Sets absolute position of pinned rows container
   */
  private setPinnedRowsPos(): void {
    const tableRectTop = this.mainTableWrap.nativeElement.getBoundingClientRect().top;
    const containerRectTop = document.querySelector(TableComponent.PAGE_CONTAINER_SELECTOR).getBoundingClientRect().top;
    const pinnedRowsWrap = this.pinnedRowsWrap.nativeElement;
    const headerRectHeight = document.querySelector(TableComponent.PAGE_HEADER_SELECTOR).getBoundingClientRect().height;
    if (tableRectTop > containerRectTop + headerRectHeight) {
      pinnedRowsWrap.style.top = 0;
      return;
    }

    const pinnedRowsTop = tableRectTop < 0 ? Math.abs(tableRectTop) + containerRectTop : containerRectTop - tableRectTop;
    pinnedRowsWrap.style.top = pinnedRowsTop +  headerRectHeight + 'px';
  }
}
