import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {  ReplaySubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { PurchaseOrder, PurchaseOrderPropertiesModel } from '../../models';
import { STATUS_CLASSES } from '../../purchase-order.constants';
import { UIStatesEnum } from 'common/src/models';
import { AppState } from '../../../store/state/app.state';
import { selectPoCurrentState, selectPurchaseOrder } from '../../store/selectors';
import { PurchaseOrderApiService } from '../../services/purchase-order-api.service';
import { PoTabs } from '../../enums';
import { FormInputChangedModel } from '../../../shared/models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { CommonModalsActionsEnum, ConfirmModalComponent } from 'common/src/modules/modals/modals-common';
import { UPDATE_SMALL_BUSINESS_MODAL_CONFIG } from '../../../shared/constants';
import { selectAccountingSettings } from '../../../store/selectors/shared.selectors';
import { AccountingSettingsModel } from '../../../accounting/accounting-settings-module/models';


@Component({
  selector: 'rnpl-table-total-info',
  templateUrl: './table-total-info.component.html',
  styleUrls: ['./table-total-info.component.scss']
})
export class TableTotalInfoComponent implements OnInit, OnDestroy {
  readonly statusClasses: {[key: string]: string} = STATUS_CLASSES;
  public isReadonly: boolean = true;

  public sidebarStatus?: UIStatesEnum = UIStatesEnum.VIEW;
  public statuses: typeof PoTabs = PoTabs;

  public accountingSettings: AccountingSettingsModel;
  public form: FormGroup;
  public purchaseOrder: PurchaseOrder;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly fb: FormBuilder,
    private readonly poApi: PurchaseOrderApiService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    this.trackPurchaseOrderChanges();
    this.trackPurchaseOrderStateChanges();
  }

  private trackPurchaseOrderChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        this.initForm(this.purchaseOrder.properties);
        this.setFormsState();
      });
  }

  private trackPurchaseOrderStateChanges(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.sidebarStatus = state;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }

    if (this.hasLinkedIIN || this.purchaseOrder.properties.smallBusiness) {
      this.vatDisabled.disable(opts);
    }

    // if (!get(this.purchaseOrder, 'properties.company.id')) {
    //   this.form.get('companyContactId').disable(opts);
    //   this.form.get('email').disable(opts);
    //   this.form.get('phone').disable(opts);
    // }

    if (this.purchaseOrder.status === PoTabs.Template) {
      this.form.get('issueDate').disable(opts);
      this.form.get('externalNumber').disable(opts);
    }
  }

  public initForm(properties: PurchaseOrderPropertiesModel = {} as PurchaseOrderPropertiesModel): void {
    this.form = this.fb.group({
      // partnerType: [get(properties, 'partnerType'), [Validators.required]],
      // generalPartnerName: [get(properties, 'generalPartnerName'), [Validators.required]],
      // companyId: [get(properties, 'company.id'), [Validators.required]],
      // companyContactId: [get(properties, 'companyContact.id')],
      // email: [get(properties, 'email')],
      // phone: [get(properties, 'phone')],
      vatDisabled: [get(properties, 'vatDisabled')],
      smallBusiness: [get(properties, 'smallBusiness')],
      issueDate: [get(properties, 'issueDate')],
      externalNumber: [get(properties, 'externalNumber')],
    });
    this.cdr.detectChanges();

    // if (this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER) {
    //   this.companyContactId.setValidators(Validators.required);
    // }
  }

  public updateProperties(field: FormInputChangedModel): void {
    field.fieldName = 'properties.' + field.fieldName;
    this.poApi.updatePO(this.purchaseOrder.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  public updateSmallBusiness(fieldName: string, fieldValue): void {
    if (this.accountingSettings.smallBusiness.forward === fieldValue) {
      this.updateProperties({ fieldName, fieldValue });
      return;
    }

    const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_SMALL_BUSINESS_MODAL_CONFIG);

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.updateProperties({ fieldName, fieldValue });
      } else if (res === CommonModalsActionsEnum.REJECT) {
        this.router.navigate(['/accounting/settings/general-settings']);
      } else {
        this.smallBusiness.patchValue(this.purchaseOrder.properties.smallBusiness, {emitEvent: false});
      }
    });
  }

  get hasLinkedIIN(): boolean {
    if (!get(this, 'purchaseOrder.linkedDocuments')) { return false; }
    return get(this, 'purchaseOrder.linkedDocuments')
      .filter(doc => doc.type === DocumentTypesUppercaseEnum.INCOMING_INVOICE).length;
  }

  get vatDisabled(): FormControl { return this.form.get('vatDisabled') as FormControl; }
  get smallBusiness(): FormControl { return this.form.get('smallBusiness') as FormControl; }

  get externalNumber(): FormControl { return this.form.get('externalNumber') as FormControl; }
  get issueDate(): FormControl { return this.form.get('issueDate') as FormControl; }
  // get partnerType(): FormControl { return this.form.get('partnerType') as FormControl; }
  // get companyContactId(): FormControl { return this.form.get('companyContactId') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
