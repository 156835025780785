import { Component, Input } from '@angular/core';

import { TableColumnModelExtended } from '../../../../../models/table-column.model';

@Component({
  selector: 'rnpl-column-primary-with-number',
  templateUrl: './column-primary-with-number.component.html',
  styleUrls: ['./column-primary-with-number.component.scss'],
})
export class ColumnPrimaryWithNumberComponent {

  @Input() value: string | number;
  @Input() column: TableColumnModelExtended;
  @Input() row: any;

}

