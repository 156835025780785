import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { UIStatesEnum } from 'common/src/models';
import { selectPurchaseOrder } from '../../../store/selectors';
import { POLinkedDocumentModel, PurchaseOrder } from '../../../models';
import { AppState } from '../../../../store/state/app.state';
import { PurchaseOrderApiService } from '../../../services/purchase-order-api.service';
import { IncomingInvoiceApiService } from '../../../../incoming-invoice/services/invoice-api.service';
import { EmptyStateTypeEnum } from 'common/src/modules/ui-components/empty-state/empty-state.model';
import { getDocumentTypeLabel, getDocumentUrl } from '../../../../shared/helpers';
import { TypeViewEnum } from 'common/src/models/positions-view.enum';
import { generateCardsInfo } from '../../../../sales-order/pages/sales-order-documents/documents.config';
import { PositionInfoListModel } from 'common/src/modules/position-card/position-card-models.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { RowClick } from 'common/src/modules/ui-components/table/custom-table.interface';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';


@Component({
  selector: 'rnpl-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  public documents: POLinkedDocumentModel[] = [];
  // public columns = [];
  public purchaseOrder: PurchaseOrder;
  // public pagination: PaginationModel = {page: 1, per_page: 1000, count: 1000}; // 1000 - fix while pagination doesn exist

  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public emptyStateTypeEnum = EmptyStateTypeEnum;

  // public typeViewEnum = TypeViewEnum;
  public cards: PositionInfoListModel[] = [];

  // @Input()
  // public typeView = TypeViewEnum.CARD;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly purchaseOrderApiService: PurchaseOrderApiService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly invoiceApiService: IncomingInvoiceApiService,
  ) {}

  ngOnInit() {
    this.isLoading$.next(true);
    this.trackPOChanges();
  }

  private trackPOChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.documents = purchaseOrder.linkedDocuments.map(document => {
          if (document.type === 'sales_order') {
            document = {
              ...document,
              rowActions: {
                [TableActivateTypes.Unlink]: true
              }
            };
          }

          return {
            ...document,
            typeLabel: this.translateService.instant(getDocumentTypeLabel(document.type)),
            documentLink: {
              label: document.documentRunpleId,
              routerLink: getDocumentUrl(document.type, document.documentId)
            },
          }
        });
        this.isLoading$.next(false);
        this.purchaseOrder = purchaseOrder;
        // this.columns = generateTableColumns(this.translateService, purchaseOrder.status as PoTabs, this.hasLinkedSalesOrder());
        this.cards = generateCardsInfo();
      });
  }

  public createAction(actionName: string): void {
    if (this[actionName]) {
      this[actionName]();
    }
  }

  onCreateIncomingInvoiceClick(): void {
    this.invoiceApiService.createInvoiceFromPO(this.purchaseOrder.id)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('linkedDoc')),
        takeUntil(this.destroy$)
      )
      .subscribe((incomingInvoice) => {
        this.showMsg('success', 'IIN.CREATED_SUCCESS');
        this.router.navigate(
          [`/accounting/incoming-invoice/${incomingInvoice.invoiceId}`],
          {state: {uiState: UIStatesEnum.CREATE}}
          );
      }, (error: HttpErrorResponse) => {
        this.showMsg('error', error.error.message || error.error.errors);
      });
  }

  // public onTableIconClick(e: RowClick): void {
  //   if (e.column.clicktype === TableActivateTypes.Unlink &&
  //     e.row.rowActions &&
  //     e.row.rowActions[TableActivateTypes.Unlink]
  //   ) {
  //     if (e.row.type === 'sales_order') {
  //       this.unlinkSalesOrder(e.row.documentId);
  //     }
  //   }
  // }

  public rowAction(event: {position: any, actionType: TableActivateTypes}): void {
    if (event.actionType === TableActivateTypes.Unlink && event.position.type === 'sales_order') {
      this.unlinkSalesOrder(event.position.documentId);
    }
  }

  public unlinkSalesOrder(soId: number): void {
    if (!document) { return; }
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'SO_PO.PO_UNLINK_TITLE',
        message: 'SO_PO.PO_UNLINK_MSG',
        confirmBtnText: 'BUTTON.UNLINK',
        confirmBtnIcon: 'unlink'
      }
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$),)
      .subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.purchaseOrderApiService.unlinkPurchaseOrderWithSalesOrder(this.purchaseOrder.id, soId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.showMsg('success', 'PO.HAS_BEEN_UNLINKED');
            });
        }
      });
  }

  private showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public hasLinkedSalesOrder(): boolean {
    const documents = get(this.purchaseOrder, 'linkedDocuments');
    return documents.some(doc => doc.type === 'sales_order');
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
