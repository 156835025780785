import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ControlDefinitionModel } from '../control-definition.model';
import { DynamicFormService } from '../dynamic-form.service';

@Directive({
  selector: '[rnplControlDef]'
})
export class ControlDefinitionDirective implements OnInit {

  @Input('rnplControlDef')
  definition: ControlDefinitionModel;

  @Input()
  formGroup: FormGroup;

  constructor(private viewContainer: ViewContainerRef, private formService: DynamicFormService) {

  }

  ngOnInit(): void {
    this.formService.initControl(this.viewContainer, this.definition, this.formGroup);
  }

}
