import { createSelector } from '@ngrx/store';

import { DeliveryNoteBlocksValidationsState, DeliveryNoteFeatureKey, DeliveryNoteState, DeliveryNoteStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';

export const selectFeature = ((state: AppState) => state[DeliveryNoteFeatureKey]);

export const selectDeliveryNotesList = createSelector(
  selectFeature,
  (state: DeliveryNoteStore) => state.list
);

export const selectDeliveryNoteEntity = createSelector(
  selectFeature,
  (state: DeliveryNoteStore) => state.entity
);

export const selectDeliveryNoteBlocksValidations = createSelector(
  selectFeature,
  (state: DeliveryNoteStore) => state.blocksValidations
);

export const selectDeliveryNote = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.deliveryNote
);

export const selectDeliveryNoteCounters = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.counters
);

export const selectDeliveryNoteState = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.currentState
);


export const selectDeliveryNoteDeliveryInformation = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.deliveryNote.deliveryInformation
);

export const loadingRequestsCount = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.loadingRequestsCount
);

export const deliveryNoteUpdatedAt = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectDeliveryNoteEntity,
  (state: DeliveryNoteState) => state.isShouldRefresh
);

// export const selectDeliveryNotePropertiesBlockValid = createSelector(
//   selectDeliveryNoteBlocksValidations,
//   (state: DeliveryNoteBlocksValidationsState) => state.propertiesBlockValid
// );

export const selectDeliveryNoteDeliveryInfoBlockValid = createSelector(
  selectDeliveryNoteBlocksValidations,
  (state: DeliveryNoteBlocksValidationsState) => state.deliveryInfoBlockValid
);
