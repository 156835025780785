import { createSelector } from '@ngrx/store';

import { TeamsFeatureKey, TeamsStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';

export const selectFeature = ((state: AppState) => state[TeamsFeatureKey]);

export const selectTeamsList = createSelector(
  selectFeature,
  (state: TeamsStore) => state.list
);

export const selectTeamsUser = createSelector(
  selectFeature,
  (state: TeamsStore) => state.entity.user
);

export const selectLoggedUser = createSelector(
  selectFeature,
  (state: TeamsStore) => state.entity.loggedUser
);

export const selectTeamsUserUpdatedAt = createSelector(
  selectFeature,
  (state: TeamsStore) => state.entity.updatedAt
);

export const selectTeamsUserCurrentState = createSelector(
  selectFeature,
  (state: TeamsStore) => state.entity.currentState
);

export const isShouldRefresh = createSelector(
  selectFeature,
  (state: TeamsStore) => state.entity.isShouldRefresh
);

