import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { Title } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { PartnerContactModel } from 'projects/workspace/src/app/partners/corporate/models/partner-contact.model';
import { PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { selectTitles } from 'projects/workspace/src/app/partners/corporate/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rnpl-create-new-contact-modal',
  templateUrl: './create-new-contact-modal.component.html',
  styleUrls: ['./create-new-contact-modal.component.scss'],
})
export class CreateNewContactModalComponent implements OnInit {
  public form: FormGroup;

  public titleItems$: Observable<Title[]> = this.store.select(selectTitles);

  readonly createContactRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public positionItems = [
    {value: '-', label: '-'},
    {value: 'CEO', label: 'USER_POSITIONS.CEO'},
    {value: 'C-level manager', label: 'USER_POSITIONS.C_LEVEL'},
    {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
    // {value: 'Team Lead', label: 'Team Lead'},
    {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
    {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
    {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
  ];

  public departmentItems = [
    {value: '-', label: '-'},
    {value: 'Accounting', label: 'CATEGORIES.ACCOUNTING'},
    {value: 'Warehouse', label: 'MODULE.WAREHOUSE'},
    {value: 'Wholesale', label: 'MODULE.TRADE'},
    // {value: 'Retail', label: 'DEPARTMENTS.RETAIL'},
    // {value: 'Logistics', label: 'DEPARTMENTS.LOGISTICS'},
    {value: 'Management', label: 'APP.MANAGEMENT'},
    // {value: 'Legal', label: 'DEPARTMENTS.LEGAL'},
    // {value: 'IT', label: 'DEPARTMENTS.IT'},
    // {value: 'Marketing', label: 'DEPARTMENTS.MARKETING'},
    // {value: 'Customer care', label: 'DEPARTMENTS.CUSTOMER'},
    // {value: 'HR', label: 'DEPARTMENTS.HR'},
    {value: 'Services', label: 'CATEGORIES.SERVICES'},
    {value: 'E-commerce', label: 'MODULE.E_COMMERCE'},
  ];

  constructor(
    private readonly partnerApiService: PartnersApiService,
    private readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateNewContactModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { partner?: PartnerModel; contact?: PartnerContactModel }
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      firstName: [get(this.contact, 'firstName', ''), [Validators.required]],
      lastName: [get(this.contact, 'lastName', ''), [Validators.required]],
      phone: [get(this.contact, 'phone', ''), []],
      email: [get(this.contact, 'email', ''), [Validators.email]],
      title: [get(this.contact, 'title', null), []],
      // birthday: [get(this.contact, 'birthday', ''), []],
      // gender: [get(this.contact, 'gender', 'Unspecified'), [Validators.required]],
      department: [get(this.contact, 'department', null)],
      position: [get(this.contact, 'position', null)],
    });

    if (
      get(this.contact, 'isCorporateContact', false) ||
      get(this.contact, 'contactType') === 'accounting'
    ) {
      this.form.disable();
    }
  }

  public createContact() {
    if (this.createContactRequest$.getValue()) { return; }
    this.createContactRequest$.next(true);
    this.partnerApiService
      .createPartnerContact(this.partner.id, PartnersTypeEnum.CORPORATE, this.form.getRawValue())
      .pipe(
        finalize(() => { this.createContactRequest$.next(false); }),
        takeUntil(this.destroy$),
      )
      .subscribe((_) => {
        this.close(true);
      });
  }

  public saveContact() {
    this.partnerApiService
      .updatePartnerContact(this.partner.id, this.contact.id, PartnersTypeEnum.CORPORATE, this.form.getRawValue())
      .subscribe((_) => {
        this.close(true);
      });
  }

  public openRemoveContactModal() {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        message: `PARTNERS.YOU_ARE_ABOUT_TO_DELETE_CONTACT`,
      },
    });

    dialog.afterClosed().subscribe((response: CommonModalsActionsEnum) => {
      if (response === CommonModalsActionsEnum.CONFIRM) {
        this.partnerApiService.deletePartnerContact(this.partner.id, this.contact.id, PartnersTypeEnum.CORPORATE).subscribe((_) => {
          this.close(true);
        });
      }
    });
  }

  public close(success: boolean = false): void {
    this.dialogRef.close(success);
  }

  public get contact(): PartnerContactModel {
    return get(this.data, 'contact', {} as PartnerContactModel);
  }

  public get partner(): PartnerModel {
    return get(this.data, 'partner', {} as PartnerModel);
  }

  public get position(): FormControl {
    return this.form.get('position') as FormControl;
  }

  public get department(): FormControl {
    return this.form.get('department') as FormControl;
  }

  // public get gender(): FormControl {
  //   return this.form.get('gender') as FormControl;
  // }

  // public get birthday(): FormControl {
  //   return this.form.get('birthday') as FormControl;
  // }
}
