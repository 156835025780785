import { PurchaseOrder } from './purchase-order';
import { get } from 'lodash';

import { Batch } from './batch';

export class BatchModel {
  readonly id?: number;
  readonly spedition_type?: string;
  readonly spedition?: string;
  readonly tracking_number?: string;
  readonly expected_arrival_date?: Date;
  readonly actual_arrival_date?: Date;
  readonly comment?: string;
  readonly delivery_cost_transportation?: number;
  readonly delivery_cost_insurance?: number;
  readonly delivery_custom_costs?: number;
  readonly purchase_order?: PurchaseOrder;
  readonly created_at?: Date;

  constructor(data: Batch) {
    this.id = get(data, 'id');
    this.spedition_type = get(data, 'spedition_type');
    this.spedition = get(data, 'spedition');
    this.tracking_number = get(data, 'tracking_number');
    this.expected_arrival_date = get(data, 'expected_arrival_date');
    this.actual_arrival_date = get(data, 'actual_arrival_date');
    this.comment = get(data, 'comment');
    this.delivery_cost_transportation = get(data, 'delivery_cost_transportation');
    this.delivery_cost_insurance = get(data, 'delivery_cost_insurance');
    this.delivery_custom_costs = get(data, 'delivery_custom_costs');
    this.purchase_order = get(data, 'purchase_order');

    if (data.expected_arrival_date) {
      this.expected_arrival_date = new Date(data.expected_arrival_date);
    }

    if (data.actual_arrival_date) {
      this.actual_arrival_date = new Date(data.actual_arrival_date);
    }

    if (data.created_at) {
      this.created_at = new Date(data.created_at);
    }

  }

  updateBatch(data: Batch | BatchModel): BatchModel {
    const oldBatch: BatchModel = Object.create(this);
    return new BatchModel(Object.assign(
      oldBatch,
      this.shallowClone(data) as Batch
    ));
  }

  get positionsQuantity(): number {
    const positions = get(this, 'purchase_order.positions', []);

    return positions.reduce((acc, p: any) => p.quantity ? p.quantity : 0 + acc, 0); // todo: type
  }

  private shallowClone(obj) {
    return Object.keys(obj).reduce((clone, key) => {
      if (obj[key] !== null || obj[key] !== undefined) {
        clone[key] = obj[key];
      }
      return clone;
    }, {});
  }
}
