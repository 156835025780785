import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { SystemSettingsService } from '../../../system-settings/system-settings.service';
import { AssignedAttributeModel, AttributeModel } from '../../../system-settings/models';

@Component({
  selector: 'rnpl-form-setup-view',
  templateUrl: './form-setup-view.component.html',
  styleUrls: ['./form-setup-view.component.scss']
})
export class FormSetupViewComponent implements OnInit, OnDestroy, OnChanges {

  public formKey: string;
  public formLabel: string;

  private entityType: string;
  public entity: string;

  private paramsSubscription: Subscription;


  public availableAttributes: Array<AttributeModel> = [];
  public assignedAttributes: Array<AssignedAttributeModel> = [];

  grid: Array<Array<any>> = [[]];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private systemSettingsService: SystemSettingsService) {
  }

  ngOnInit(): void {

    this.paramsSubscription = this.route.paramMap
      .subscribe(params => {
        this.entityType = params.get('type');
        this.entity = params.get('entity');

        this.formKey = `${this.entityType}_${this.entity}_signup`;
        this.formLabel = `${this.entity.charAt(0).toUpperCase()}${this.entity.slice(1)} sign up`;

        this.loadAttributes();
      });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('formKey')) {
      this.grid = [[]];
      this.loadAttributes();
    }
  }

  private loadAttributes(): void {
    this.systemSettingsService.availableAttributes(this.formKey)
      .subscribe(response => {
        this.availableAttributes = response.data;
        this.makeGrid();
      });

    this.systemSettingsService.assignedAttributes(this.formKey)
      .subscribe(response => {
        this.assignedAttributes = response.data;
        this.makeGrid();
      });

  }

  private makeGrid(): void {
    this.grid = this.assignedAttributes.reduce((grid, attribute, index) => {

      const lastRow = grid.pop();

      let attrClass;
      switch (attribute.layout.size) {
        case 'small':
          attrClass = 'col-md-4';
          break;

        case 'wide':
          attrClass = 'col-md-12';
          break;

        default:
          attrClass = 'col-md-6';
      }

      lastRow.push({
        attribute: attribute.attribute,
        class: attrClass
      });

      grid.push(lastRow);

      if (attribute.layout.isLast && index < this.assignedAttributes.length - 1) {
        grid.push([]);
      }

      return grid;
    }, [[]]);
  }
}
