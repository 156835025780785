import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';

@Component({ // todo: check usage
  selector: 'rnpl-sandbox',
  templateUrl: './sandbox.component.html'
})
export class SandboxComponent extends InitialSetupBaseComponent implements OnInit {

  public form: FormGroup = this.fb.group({});

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public administrationsApiService: AdministrationsApiService,
    public readonly store: Store<AppState>,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.fb.group({
      startMode:  ['sandbox', {validators: []}],
    });
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company = {
      ...this.companyProfile,
      ...formValue
    };
    return this.updateCompanyInfo$(company);
  }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    return !!this.startModeControl.value;
  }

  get startModeControl(): FormControl { return this.form.get('startMode') as FormControl; }

}
