import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, distinctUntilChanged, pairwise, startWith, finalize, catchError } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { ProductSearchResponse } from './models/product-search-response.model';
import { QuantityCheckMethodEnum } from './enums/quantity-check-method.enum';
import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { RowClick } from 'common/src/modules/ui-components/table/custom-table.interface';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { AvailableProduct } from './models/available-product.model';
import { Unit } from './models/unit.model';
import { CommonModalDataModel, InfoModalComponent, CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import {
  getPackUpListColumns,
  quantityMethodChangeInfo,
  BarcodeMismatchProductModalData
} from './pack-up-modal.config';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';

@Component({
  selector: 'rnpl-pack-up-modal',
  templateUrl: './pack-up-modal.component.html',
  styleUrls: ['./pack-up-modal.component.scss'],
})
export class PackUpModalComponent extends BaseModalComponent implements OnInit {

  public productsList$: BehaviorSubject<AvailableProduct[]> = new BehaviorSubject<AvailableProduct[]>([]);

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly quantityCheckMethods: typeof QuantityCheckMethodEnum = QuantityCheckMethodEnum;

  // Table

  public columns: TableColumnModelExtended[] = getPackUpListColumns(QuantityCheckMethodEnum.BARCODE);
  readonly productTableList$: BehaviorSubject<Unit[]> = new BehaviorSubject([]);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public form: FormGroup;

  public selectedProductControl: FormControl = new FormControl({ value: null, disabled: false }, [Validators.required]);
  public isContinuousPackingControl: FormControl = new FormControl({ value: false, disabled: false });
  readonly puckUpProductRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  // public quantityMethodChangeInfo = quantityMethodChangeInfo;

  public emptyStateType: EmptyStateTypeEnum = EmptyStateTypeEnum.BARCODE;

  public counterAnimation: boolean = false;
  public allowFractionalValues: boolean = true;

  public customSearchFn = CustomSearchFn;

  public productType = PRODUCTS_TYPE;
  public productTypes: typeof ProductTypes = ProductTypes;

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<PackUpModalComponent>,
    private deliveryNoteApiService: DeliveryNoteApiService,
    private productsService: ProductsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly actionButtonService: ActionButtonsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveryNoteId: number,
      isLinkedSo: boolean,
      productId: number
    },
  ) {
    super(toasterService);

    setInterval(() => {
      this.displayHighlightingAnimation();
    }, 1200);
  }

  ngOnInit() {
    this.initForm();
    this.getAvailableProductsList();
    if (this.data.productId) {
      this.getProduct(this.data.productId);
      this.productSelected(this.data.productId);
    }
  }

  public displayHighlightingAnimation(): void {
    this.counterAnimation = !this.counterAnimation;
  }

  public initForm(): void {
    this.form = new FormGroup({
      barcode: new FormControl({ value: null, disabled: false }),
      serialNumber: new FormControl({ value: null, disabled: true }, [Validators.required]),
      isProductWithoutBarcode: new FormControl({ value: false, disabled: false }),
      quantity: new FormControl({ value: 0, disabled: true }),
      quantityCheckMethod: new FormControl({ value: QuantityCheckMethodEnum.MANUAL, disabled: false }),
    });
    this.bindFormControlsChanges();
  }

  public bindFormControlsChanges(): void {
    this.bindQuantityCheckMethodChanges();
    this.bindIsProductWithoutBarcodeChanges();
    this.bindSelectedProductChanges();
  }

  public bindQuantityCheckMethodChanges(): void {
    this.quantityCheckMethod.valueChanges
      .pipe(
        startWith(<QuantityCheckMethodEnum>this.quantityCheckMethod.value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this._destroy)
      ).subscribe(([prev, next]: [QuantityCheckMethodEnum, QuantityCheckMethodEnum]) => {
        this.columns = getPackUpListColumns(next);
        this.quantityCheckMethodHandleRelatedInput(next);

        if (prev === QuantityCheckMethodEnum.MANUAL) {
          this.productTableList$.next(null);
        } else {
          if (!this.selectedProductValue) { this.quantity.disable(); }
        }

        if (prev && next) {
          this.refreshProductRelatedInputs(next);
          const warningModal: CommonModalDataModel = get(quantityMethodChangeInfo, `[${prev}][${next}].modal`);

          if (warningModal && get(this, 'selectedProductValue.packedUp', false)) {
            this.openInfoModal(warningModal).afterClosed().subscribe((selectedAction: CommonModalsActionsEnum) => {

              if (selectedAction === CommonModalsActionsEnum.CLOSE) {
                this.revertQuantityCheckMethodToPreviousValue(prev);
                this.quantityCheckMethodHandleRelatedInput(prev);
              }

              if (selectedAction === CommonModalsActionsEnum.CONFIRM) {
                this.unpackCurrentProduct();
              }
            });
          }
        }
        this.cdr.detectChanges();
      });
  }

  private quantityCheckMethodHandleRelatedInput(quantityCheck: QuantityCheckMethodEnum) {
    if (quantityCheck === QuantityCheckMethodEnum.MANUAL) {
      this.quantity.enable({emitEvent: false});
      this.selectedProductControl.enable({emitEvent: false});
      this.barcode.enable({emitEvent: false});
      this.isProductWithoutBarcode.setValue(false);
      this.serialNumber.disable({emitEvent: false});
    }

    if (quantityCheck === QuantityCheckMethodEnum.SERIAL_NUMBER) {
      this.quantity.disable({emitEvent: false});
      this.serialNumber.enable({emitEvent: false});
    }

    if (quantityCheck === QuantityCheckMethodEnum.BARCODE) {
      this.isProductWithoutBarcode.setValue(false);
    }
  }

  // private handleChangeQuantity(inputsNameToClear: string[]): void {
  //   if (inputsNameToClear && inputsNameToClear.length) {
  //     this.unpackCurrentProduct();
  //     inputsNameToClear.map((inputName: string) => {
  //       const input = this.form.get(inputName);
  //       if (input) { input.reset('', {emitEvent: false}); }
  //     });
  //   }
  // }

  private revertQuantityCheckMethodToPreviousValue(previousCheckMethod: QuantityCheckMethodEnum): void {
    this.quantityCheckMethod.setValue(previousCheckMethod);
  }

  public bindIsProductWithoutBarcodeChanges(): void {
    this.isProductWithoutBarcode.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._destroy)
      ).subscribe((value: boolean) => {

        if (this.selectedProductValue && value) {
          if (this.quantityCheckMethodValue !== QuantityCheckMethodEnum.SERIAL_NUMBER) {
            this.quantityCheckMethod.setValue(QuantityCheckMethodEnum.MANUAL);
          } else if (this.selectedProductValue.barcode) {
            this.barcode.setValue(null, {emitEvent: false});
            this.barcode.disable();
          }
        } else if (!this.selectedProductValue && value) {
          if (this.quantityCheckMethodValue !== QuantityCheckMethodEnum.SERIAL_NUMBER) {
            this.quantityCheckMethod.setValue(QuantityCheckMethodEnum.MANUAL);
          }
          this.barcode.setValue(null, {emitEvent: false});
          this.barcode.disable();
        } else {
          this.barcode.enable();
        }
      });
  }

  public bindSelectedProductChanges(): void {
    this.selectedProductControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._destroy)
      ).subscribe((value: AvailableProduct) => {
        if (value) {
          this.barcode.setValue(null, {emitEvent: false});
          this.getProduct();
        }
      });
  }

  public onEnterBarcodeInput(): void {
    if (get(this.selectedProductValue, 'barcode') !== this.barcode.value && get(this.selectedProductValue, 'packedUp')) {
      this.openInfoModal(BarcodeMismatchProductModalData).afterClosed().subscribe((selectedAction: CommonModalsActionsEnum) => {
        if (selectedAction === CommonModalsActionsEnum.CONFIRM) {
          this.getProduct();
        }
        if (selectedAction === CommonModalsActionsEnum.CLOSE) {
          this.barcode.setValue(this.selectedProductValue.barcode);
        }
      });
    } else {
      this.getProduct();
    }
  }

  public getProduct(productId?: number): void {
    this.deliveryNoteApiService
      .getProduct(
        this.data.deliveryNoteId,
        get(this.barcode, 'value', null),
        productId || get(this.selectedProductValue, 'productId', null)
      )
      .pipe(takeUntil(this._destroy))
      .subscribe(
        (product: ProductSearchResponse) => {
          if (product.quantityCheck) {
            this.quantityCheckMethod.setValue(product.quantityCheck);
          }

          this.refreshProductRelatedInputs(this.quantityCheckMethodValue, product);
          this.handleProduct(product);
        },
        () => {
          this.dialog.open(WarningModalComponent, {
            data: {
              title: 'DELIVERY_NOTE.MISMATCHED_BARCODE_MODAL_TTL',
              message: this.data.isLinkedSo
                ? `DELIVERY_NOTE.MISMATCHED_BARCODE_MODAL_MSG_1`
                : `DELIVERY_NOTE.MISMATCHED_BARCODE_MODAL_MSG_2`,
            }
          });
        }
      );
  }

  public refreshProductRelatedInputs(quantityCheck: QuantityCheckMethodEnum, product?: ProductSearchResponse) {
    if (quantityCheck === QuantityCheckMethodEnum.MANUAL) {
      this.quantity.enable();
    }

    if (quantityCheck === QuantityCheckMethodEnum.BARCODE) {
      this.isProductWithoutBarcode.setValue(false);

      if (get(product, 'barcode') || get(this.selectedProductValue, 'barcode')) {
        this.isProductWithoutBarcode.disable();
      } else {
        if (this.selectedProductValue) {
          this.isProductWithoutBarcode.enable();
          this.barcode.disable();
        }
      }
    }

    if (quantityCheck === QuantityCheckMethodEnum.SERIAL_NUMBER) {
      if (get(product, 'barcode') || get(this.selectedProductValue, 'barcode')) {
        this.isProductWithoutBarcode.enable();
      } else {
        if (this.selectedProductValue) {
          this.isProductWithoutBarcode.setValue(true);
          this.isProductWithoutBarcode.disable();
          this.barcode.disable();
        }
      }
    }
  }

  public getAvailableProductsList(): void {
    this.showDropdownSpin$.next(true);
    this.deliveryNoteApiService
      .getAvailableProductsList(this.data.deliveryNoteId)
      .pipe(takeUntil(this._destroy))
      .subscribe((products: AvailableProduct[]) => {
        this.productsList$.next(this.prepareProductsList(products));
        // set imageUrl
        if (this.selectedProductControl.value) {
          const imageUrl = this.productsList$.getValue().find(p => p.id === this.selectedProductControl.value.productId).imageUrl;
          if (imageUrl) {
            this.selectedProductControl.patchValue({
              ...this.selectedProductControl.value,
              imageUrl
            });
          }
        }
        this.showDropdownSpin$.next(false);
      }, this.handleError);
  }

  public prepareProductsList(products): AvailableProduct[] {
    return products.map(item => {
      const { name, description, open, ordered, type, netPrice, available } = item;
      return {
        ...item,
        searchLabel: `${name} ${description} ${open} ${ordered} ${type} ${netPrice || 'n/a'} ${available}`
      };
    });
  }

  public productSelected(productID: number = this.selectedProductControl.value.productId): void {
    this.productsService.getProduct(productID)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.allowFractionalValues = product.unitTypeModel.allowFractionalValues && product.productType !== ProductTypes.GOODS;
      });
  }

  public packUpProduct(ignoreSerialNumberWarning = false): void {
    if (this.puckUpProductRequest$.getValue()) { return; }
    this.puckUpProductRequest$.next(true);

    if (this.isLoading$.getValue()) { return; }
    const data = {
      productId: get(this.selectedProductValue, 'productId', null),
      ...this.form.getRawValue(),
      ignoreSerialNumberWarning
    };
    this.isLoading$.next(true);

    this.deliveryNoteApiService.packUpProduct(this.data.deliveryNoteId, data)
      .pipe(
        finalize(() => {
          this.puckUpProductRequest$.next(false);
          this.isLoading$.next(false);
        }),
        catchError(error => {
          this.handleError(error);
          return throwError(error);
        }),
        takeUntil(this._destroy)
      )
      .subscribe((product: ProductSearchResponse) => {
        this.handleProduct(product);

        if (this.quantityCheckMethodValue === QuantityCheckMethodEnum.MANUAL) {
          this.quantity.reset(0, {emitEvent: false});
        } else {
          this.handleAfterBarcodeOrSerialPackUp();
        }
        this.deliveryNoteApiService.getDeliveryNoteById(this.data.deliveryNoteId).subscribe(() => {
          if (!this.isContinuousPackingControl.value) {this.closeModal(); }
        });
      });
  }

  rowClickReceiver(e: RowClick): void {
    if (e.column.clicktype === TableActivateTypes.DeleteRow) {
      this.deleteUnit(e.row.id);
    }
  }

  public deleteUnit(unitId: string) {
    this.deliveryNoteApiService
      .deleteUnit(this.data.deliveryNoteId, unitId)
      .pipe(takeUntil(this._destroy))
      .subscribe((product: ProductSearchResponse) => {
        this.handleProduct(product);
      });
  }

  public unpackCurrentProduct() {
    this.deliveryNoteApiService
      .unpackProduct(this.data.deliveryNoteId, this.selectedProductValue.id)
      .pipe(takeUntil(this._destroy))
      .subscribe((product: ProductSearchResponse) => {
        this.handleProduct(product);
      });
  }

  public handleProduct(product: ProductSearchResponse) {
    let  imageUrl = null;
    const {id, productId, productName: name, barcode, units, packedUp, open} = product;
    this.productTableList$.next(units);
    this.quantity.setValue(0, {emitEvent: false});
    // set imageUrl
    if (this.productsList$.getValue().length) {
      imageUrl = this.productsList$.getValue().find(p => p.id === productId).imageUrl;
    }
    this.selectedProductControl.setValue({id, productId, name, barcode, packedUp, open, imageUrl}, {emitEvent: false});
  }

  public handleAfterBarcodeOrSerialPackUp(): void {
    if (this.quantityCheckMethodValue === QuantityCheckMethodEnum.BARCODE && !this.isProductWithoutBarcode.value) {
      this.barcode.reset('', {emitEvent: false});
    }

    // if (this.quantityCheckMethodValue === QuantityCheckMethodEnum.SERIAL_NUMBER) {
    //   this.serialNumber.reset('', {emitEvent: false});
    // }
  }

  public handleError = (error) => {
    this.isLoading$.next(false);

      switch (error.error.message) {
        case 'serialNumberAlreadyUsed':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'WAREHOUSE.DELIVERY_NOTE.SERIAL_NUMBER_NOT_UNIQUE_TITLE',
                message: this.translateService.instant(
                  'WAREHOUSE.DELIVERY_NOTE.SERIAL_NUMBER_NOT_UNIQUE_MSG',
                  {runpleIds: error.error.data.deliveryNotes.join(',')}
                ),
                confirmBtnText: 'WAREHOUSE.DELIVERY_NOTE.PACK',
                confirmBtnIcon: 'open-box'
            }});

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.packUpProduct(true);
              }
            });
          }
          break;
        default:
          if (error.error.message) {
            this.displayMessage('error', error.error.message);
          }
          break;
      }
  }

  public increaseValue(control: FormControl, step: number = 1): void {
    const currentValue = control.value;
    control.setValue(currentValue + step);
  }

  public decreaseValue(control: FormControl, step: number = 1): void {
    const currentValue = control.value;
    if (currentValue) {
      control.setValue(currentValue - step);
    }
  }

  public closeModal(): void {
    if (this.isLoading$.getValue()) { return; }
    this.dialogRef.close();
  }

  public barcodeDialogToggle(disabled: boolean): void {
    if (!disabled) {
      this.actionButtonService.barcodeDialogToggle();
    }
  }

  private openInfoModal(data: CommonModalDataModel): MatDialogRef<InfoModalComponent> {
    return this.dialog.open(InfoModalComponent, { data });
  }

  get quantityCheckMethod(): FormControl { return this.form.get('quantityCheckMethod') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get isProductWithoutBarcode(): FormControl { return this.form.get('isProductWithoutBarcode') as FormControl; }
  get serialNumber(): FormControl { return this.form.get('serialNumber') as FormControl; }
  get barcode(): FormControl { return this.form.get('barcode') as FormControl; }

  get selectedProductValue(): AvailableProduct { return this.selectedProductControl.value as AvailableProduct; }

  get quantityCheckMethodValue(): QuantityCheckMethodEnum { return this.form.get('quantityCheckMethod').value as QuantityCheckMethodEnum; }

}
