import { Component, Input } from '@angular/core';
import { TableSummaryBarItemModel } from '../table-summary-bar.model';


@Component({
  selector: 'rnpl-table-summary-bar',
  templateUrl: './table-summary-bar.component.html',
  styleUrls: ['./table-summary-bar.component.scss']
})
export class TableSummaryBarComponent {

  @Input() tableSummaryBarItems: TableSummaryBarItemModel[] = [];
  @Input() hideSummary: boolean;

  public isDisplayed(item: TableSummaryBarItemModel): boolean {
    if (item.hasOwnProperty('displayed')) {
      return item.displayed;
    }
    return true;
  }

}
