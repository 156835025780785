import { get } from 'lodash';

import { InfoItemModel, InfoItemType } from '../../../ui-components/info-components/models/info-item.model';
import { ExchangeAuthorizationReasonEnum, ExchangeRequiredSolutionEnum } from 'projects/workspace/src/app/exchange/enums';
import { PricesInfoModel } from 'projects/workspace/src/app/trade-prices/models/prices-info.model';
import { AUTHORIZATION_REASON_LABELS, REQUIRED_SOLUTION_LABELS } from 'projects/workspace/src/app/exchange/exchange.constants';

export function getAuthorizationReasonList(
  unexpectedOrMissingProduct: boolean,
  unexpectedOrMissingProductType: 'unexpectedProduct'|'missingProduct'
): any[] {
  const list = [
    {
      value: ExchangeAuthorizationReasonEnum.WRONG_PRODUCT,
      label: AUTHORIZATION_REASON_LABELS[ExchangeAuthorizationReasonEnum.WRONG_PRODUCT],
      showCondition: unexpectedOrMissingProduct && unexpectedOrMissingProductType === 'unexpectedProduct'
    },
    {
      value: ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT,
      label: AUTHORIZATION_REASON_LABELS[ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT],
      showCondition: unexpectedOrMissingProduct && unexpectedOrMissingProductType === 'unexpectedProduct'
    },
    {
      value: ExchangeAuthorizationReasonEnum.MISSING_PRODUCT,
      label: AUTHORIZATION_REASON_LABELS[ExchangeAuthorizationReasonEnum.MISSING_PRODUCT],
      showCondition: unexpectedOrMissingProduct && unexpectedOrMissingProductType === 'missingProduct'
    },
    {
      value: ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT,
      label: AUTHORIZATION_REASON_LABELS[ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT],
      showCondition: !unexpectedOrMissingProduct
    },
    {
      value: ExchangeAuthorizationReasonEnum.PRODUCT_DOESNT_FIT,
      label: AUTHORIZATION_REASON_LABELS[ExchangeAuthorizationReasonEnum.PRODUCT_DOESNT_FIT],
      showCondition: !unexpectedOrMissingProduct
    },
  ];

  return list.filter(itm => itm.showCondition);
}

export function getRequiredSolutionList(authorizationReason: ExchangeAuthorizationReasonEnum): any[] {
  const list = [
    { value: ExchangeRequiredSolutionEnum.REPLACE_PRODUCT,
      label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.REPLACE_PRODUCT],
      showCondition:
        authorizationReason === ExchangeAuthorizationReasonEnum.WRONG_PRODUCT ||
        authorizationReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT
    },
    { value: ExchangeRequiredSolutionEnum.MONEY_RETURN,
      label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.MONEY_RETURN],
      showCondition:
        authorizationReason === ExchangeAuthorizationReasonEnum.WRONG_PRODUCT ||
        authorizationReason === ExchangeAuthorizationReasonEnum.MISSING_PRODUCT ||
        authorizationReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT ||
        authorizationReason === ExchangeAuthorizationReasonEnum.PRODUCT_DOESNT_FIT
    },
    // { value: ExchangeRequiredSolutionEnum.SELL_DELIVERED_PRODUCT,
    //   label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.SELL_DELIVERED_PRODUCT],
    //   showCondition: authorizationReason === ExchangeAuthorizationReasonEnum.WRONG_PRODUCT
    // },
    { value: ExchangeRequiredSolutionEnum.PRODUCT_RETURN,
      label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.PRODUCT_RETURN],
      showCondition: authorizationReason === ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT
    },
    { value: ExchangeRequiredSolutionEnum.SELL_PRODUCT,
      label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.SELL_PRODUCT],
      showCondition: authorizationReason === ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT
    },
    { value: ExchangeRequiredSolutionEnum.SHIP_PRODUCT,
      label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.SHIP_PRODUCT],
      showCondition: authorizationReason === ExchangeAuthorizationReasonEnum.MISSING_PRODUCT
    },
    { value: ExchangeRequiredSolutionEnum.GIVE_COMPENSATION,
      label: REQUIRED_SOLUTION_LABELS[ExchangeRequiredSolutionEnum.GIVE_COMPENSATION],
      showCondition: authorizationReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT
    },
  ];

  return list.filter(itm => itm.showCondition);
}


export function getSerialNumberInfoHelper(serialNumber: string, indexLabel: string): InfoItemModel[] {
  return [
    {
      label: indexLabel,
      value: serialNumber,
      statusIcon: 'passed',
      type: InfoItemType.WITH_STATUS,
      tableItem: true,
    },
  ];
}

export function getWADataInfoHelper(priceInfo: PricesInfoModel): InfoItemModel[] {
  return [
    {
      label: 'TABLE_SUMMARY_BAR.WA_PURCHASE_UNIT_PRICE_NET',
      value: get(priceInfo, 'purchase_data.wa_purchase_price', null),
      type: InfoItemType.WITH_CURRENCY,
      currencyConversion: true,
      tableItem: true,
    },
    // {
    //   label: 'WA sales unit price, net',
    //   // value: get(priceInfo, 'salesData.waSalesPrice', null), // todo
    //   value: 9.99,
    //   type: InfoItemType.WITH_CURRENCY,
    //   currencyConversion: true,
    //   tableItem: true,
    // },
    {
      label: 'TABLE_SUMMARY_BAR.WA_MARGIN',
      value: get(priceInfo, 'sales_data.wa_margin_net', null),
      percents: get(priceInfo, 'sales_data.wa_margin_rate', null),
      type: InfoItemType.WITH_CURRENCY_AND_PERCENTS,
      currencyConversion: true,
      tableItem: true,
      valueClasses: getValueColor(get(priceInfo, 'sales_data.wa_category_margin', null))
    },
  ];
}

export function getValueColor(val: number): string {
  if (!val) { return; }
  if (val === 0) { return 'color-yellow-600'; }
  if (val > 0) { return 'color-green-600'; }
  if (val < 0) { return 'color-danger-red'; }
}
