import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { selectCompanyProfile, selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  AvailableSalesOrderModel,
  SODeliveryNotes
} from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { isEqual } from 'lodash';

@Component({
  selector: 'rnpl-outgoing-invoice-link-sales-order-modal',
  templateUrl: './outgoing-invoice-link-sales-order-modal.component.html',
  styleUrls: ['./outgoing-invoice-link-sales-order-modal.component.scss']
})
export class OutgoingInvoiceLinkSalesOrderModalComponent extends BaseModalComponent implements OnInit {

  public companyProfile: CompanyProfile;
  public salesOrdersList:  AvailableSalesOrderModel[] = [];
  public selectedSalesOrder:  AvailableSalesOrderModel = null;
  public customSearchFn = CustomSearchFn;
  public selectSoError: boolean = false;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly linkRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public router: Router,
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<OutgoingInvoiceLinkSalesOrderModalComponent>,
    private salesOrderApiService: SalesOrderApiService,
    private invoiceApiService: InvoiceApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      outgoingInvoiceId: number;
      partnerId: number;
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this._destroy)
      ).subscribe((profile: CompanyProfile) => {
      this.companyProfile = profile;
    });
  }

  ngOnInit() {
    this.getAvailableSalesOrders();

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private getAvailableSalesOrders(): void {
    this.showDropdownSpin$.next(true);
    this.salesOrderApiService.getAvailableToLinkSalesOrders(this.data.partnerId)
      .pipe(takeUntil(this._destroy))
      .subscribe((salesOrders: AvailableSalesOrderModel[]) => {
        this.salesOrdersList = this.prepareSalesOrderList(salesOrders);
        this.showDropdownSpin$.next(false);
      });
  }

  public prepareSalesOrderList(salesOrders): AvailableSalesOrderModel[] {
    return salesOrders.map(item => {
      return {
        ...item,
        searchLabel: `${item.runpleId} ${item.customer.name} ${item.amountGross} ${item.positionsCount}`
      };
    });
  }

  public link(): void {
    if (!!this.selectedSalesOrder) {
      this.selectSoError = false;
      if (this.linkRequest$.getValue()) { return; }
      this.linkRequest$.next(true);

      this.invoiceApiService
        .linkOutgoingInvoiceWithSalesOrder(this.data.outgoingInvoiceId, this.selectedSalesOrder.id)
        .pipe(
          finalize(() => { this.linkRequest$.next(false); }),
          takeUntil(this._destroy))
        .subscribe(this.subscriberHandler);
    } else {
      this.selectSoError = true;
    }
  }

  public subscriberHandler = (response: OutgoingInvoiceModel) => {
    this.dialogRef.close(response);
  }

  public get partnerUrl(): string {
    if (this.partner.type === PartnersTypeEnum.CORPORATE || this.partner.type === PartnersTypeEnum.PRIVATE) {
      return `/partners-new/${this.partner.type}/${this.partner.id}`;
    }

    return null;
  }

  public selectSoChanges(): void {
    this.selectSoError = false;
  }

  public get partner() {
    return this.selectedSalesOrder.customer;
  }

}
