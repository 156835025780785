import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UIComponentsModule } from '../ui-components/ui-components.module';
import { DynamicFormsModule } from '../dynamic-forms/dynamic-forms.module';
import { StorageModule } from '../storage';
import { WizardService } from './wizard.service';
import {
  AttributeSettingsComponent,
  AvailableAttributesComponent,
  StepActionBarComponent,
  StepHeaderComponent,
  WizardComponent,
  WizardNavFooterComponent,
  WizardNavItemComponent,
  WizardNavSubItemComponent,
  WizardSideNavComponent,
  WizardSummaryComponent,
  WizardSummaryItemComponent,
  AttributeSettingsContentsComponent
} from './components';
import { RnplCommonModule } from '../rnpl-common';
import { TranslateModule } from "@ngx-translate/core";



@NgModule({
  declarations: [
    WizardComponent,
    WizardSideNavComponent,
    WizardNavItemComponent,
    WizardNavSubItemComponent,
    WizardNavFooterComponent,
    WizardSummaryComponent,
    WizardSummaryItemComponent,
    StepHeaderComponent,
    StepActionBarComponent,
    AvailableAttributesComponent,
    AttributeSettingsComponent,
    AttributeSettingsContentsComponent
  ],
  exports: [
    WizardComponent,
    WizardSummaryComponent,
    WizardSummaryItemComponent,
    StepHeaderComponent,
    StepActionBarComponent,
    WizardSideNavComponent,
    WizardNavItemComponent,
    WizardNavSubItemComponent,
    WizardNavFooterComponent,
    AvailableAttributesComponent,
    AttributeSettingsComponent,
    AttributeSettingsContentsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    UIComponentsModule,
    DynamicFormsModule,
    StorageModule,
    RnplCommonModule,
    TranslateModule
  ],
  providers: [WizardService]
})
export class WizardModule {
}
