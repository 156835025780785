import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

import { SubscriptionPlanEnum } from 'common/src/modules/subscription-activation/subscription-activation.emum';
import { SubscriptionPricesConfigModel } from '../../../administration/models/subscription-prices-config.model';
import { ChannelOptions } from '../../../administration/models/company-profile.model';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';

@Component({
  selector: 'rnpl-subscription-plan-form',
  templateUrl: './subscription-plan-form.component.html',
  styleUrls: ['./subscription-plan-form.component.scss'],
})
export class SubscriptionPlanFormComponent implements OnChanges {

  public planEnum: typeof SubscriptionPlanEnum = SubscriptionPlanEnum;

  @Input() idKey: string = '';
  @Input() form: FormGroup;
  @Input() billingDetailsForm: FormGroup;
  // @Input() showViewPlans = false;
  @Input() deactivateChannelValidation = false;
  @Input() subscriptionPricesConfig: SubscriptionPricesConfigModel;
  @Input() subscriptionPlanActive: SubscriptionPlanEnum;
  @Input() subscriptionPlan: SubscriptionPlanEnum;
  @Input() channelOptions: ChannelOptions;
  @Input() userChannelOptions: ChannelOptions;
  @Input() nextBillingAt: Date;
  @Input() displayPrice: boolean = false;
  @Input() isTrial: boolean = false;
  @Input() isInitialSetup: boolean = false;
  @Input() selectPlanTitle: string = 'SUBSCRIPTION_PLAN.SELECT_YOUR_PLAN';
  @Input() selectSalesChannelTitle: string = 'SUBSCRIPTION_PLAN.SELECT_SALES_CHANNELS';

  @Output() formChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() planUpgrade: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly datePipe: DatePipe,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('channelOptions') && this.channelOptions) {
      this.subscriptionPlanChanged(false);
    }
  }

  public wholesalesEnabledChanged(): void {
    if (this.isSoloPlan) {
      this.servicesEnabledControl.patchValue(false, {emitEvent: false});
      this.ecommerceEnabledControl.patchValue(false, {emitEvent: false});
    }
  }

  public servicesEnabledChanged(): void {
    if (this.isSoloPlan) {
      this.wholesalesEnabledControl.patchValue(false, {emitEvent: false});
      this.ecommerceEnabledControl.patchValue(false, {emitEvent: false});
    }
  }

  public ecommerceEnabledChanged(): void {
    if (this.isSoloPlan) {
      this.wholesalesEnabledControl.patchValue(false, {emitEvent: false});
      this.servicesEnabledControl.patchValue(false, {emitEvent: false});
    }
  }

  public channelChanged(userChannelValue: boolean, control: FormControl): void {
    if (this.isTeamPlan && this.deactivateChannelValidation && userChannelValue && !control.value) {
      const dialog = this.dialog.open(WarningModalComponent, {
        data: {
          title: 'SUBSCRIPTION_PLAN.SALES_CHANNEL_DEACTIVATION_WARNING_TITLE',
          message: this.translateService.instant(
            'SUBSCRIPTION_PLAN.SALES_CHANNEL_DEACTIVATION_WARNING_MSG',
            {
              nextBillingDate: this.datePipe.transform(this.nextBillingAt, 'dd.MM.yyyy')
            }
          ),
          confirmBtnText: 'BUTTON.CONTINUE',
          confirmBtnIcon: 'checkbox-on'
        }
      });

      dialog.afterClosed().subscribe(res => {
        if (!res || res !== CommonModalsActionsEnum.CONFIRM) {
          control.patchValue(true, {emitEvent: false});
        }
      });
    }
  }

  public subscriptionPlanChanged(manuallyChanged = true): void {
    const channelsControls: FormControl[] = [
      this.wholesalesEnabledControl,
      this.servicesEnabledControl,
      this.ecommerceEnabledControl,
    ];

    channelsControls.forEach(c => c.enable({emitEvent: false}));

    if (this.isFreePlan || this.isAccountingPlan) {
      channelsControls.forEach(c => {
        if (manuallyChanged) {
          c.patchValue(false, {emitEvent: false});
        }
        c.disable({ emitEvent: false });
      });
    }

    if (this.isSoloPlan && manuallyChanged) {
      const isSingleActivated: boolean = channelsControls.filter(c => c.value).length === 1;
      if (isSingleActivated) { return; } // if a single channel already activated, than do nothing
      channelsControls.forEach(c => c.patchValue(false, {emitEvent: false}));
    }
  }

  public salesChannelsValidation(): boolean {
    return (
        this.planValue !== SubscriptionPlanEnum.FREE &&
        this.planValue !== SubscriptionPlanEnum.ACCOUNTING_MONTHLY &&
        this.planValue !== SubscriptionPlanEnum.ACCOUNTING_ANNUALLY
      ) &&
      !this.wholesalesEnabledControl.value &&
      !this.ecommerceEnabledControl.value &&
      !this.servicesEnabledControl.value;
  }

  public upgradePlan(): void {
    this.planUpgrade.emit();
    this.subscriptionPlanChanged();
  }

  get isMonthly(): boolean {
    return this.billingFrequencyControl.value === 'MONTHLY';
  }

  get isAnnually(): boolean {
    return this.billingFrequencyControl.value === 'ANNUALLY';
  }

  get formRawValue(): any {
    return this.form.getRawValue();
  }

  get isFreePlan(): boolean {
    return this.planValue === SubscriptionPlanEnum.FREE;
  }

  get isAccountingPlan(): boolean {
    return this.planValue === SubscriptionPlanEnum.ACCOUNTING_MONTHLY ||
      this.planValue === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY;
  }

  get isSoloPlan(): boolean {
    return this.planValue === SubscriptionPlanEnum.SOLO_MONTHLY ||
      this.planValue === SubscriptionPlanEnum.SOLO_ANNUALLY;
  }

  get isTeamPlan(): boolean {
    return this.planValue === SubscriptionPlanEnum.TEAM_MONTHLY ||
      this.planValue === SubscriptionPlanEnum.TEAM_ANNUALLY;
  }

  get isTeamActivePlan(): boolean {
    return this.subscriptionPlanActive === SubscriptionPlanEnum.TEAM_MONTHLY ||
      this.subscriptionPlanActive === SubscriptionPlanEnum.TEAM_ANNUALLY;
  }

  get planValue(): SubscriptionPlanEnum{
    return this.subscriptionPlanControl.value;
  }

  get wholesalesEnabledControl(): FormControl { return this.form.get('userChannelOptions').get('wholesalesEnabled') as FormControl; }
  get ecommerceEnabledControl(): FormControl { return this.form.get('userChannelOptions').get('ecommerceEnabled') as FormControl; }
  get servicesEnabledControl(): FormControl { return this.form.get('userChannelOptions').get('servicesEnabled') as FormControl; }
  get subscriptionPlanControl(): FormControl { return this.form.get('subscriptionPlan') as FormControl; }
  get billingFrequencyControl(): FormControl { return this.billingDetailsForm.get('billingFrequency') as FormControl; }

}
