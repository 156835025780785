import { createSelector } from '@ngrx/store';

import { SalesOrderBlocksValidationsState, SalesOrderFeatureKey, SalesOrderState, SalesOrderStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[SalesOrderFeatureKey]);

export const selectSalesOrderEntity = createSelector(
  selectFeature,
  (state: SalesOrderStore) => state.entity
);

export const selectSalesOrderList = createSelector(
  selectFeature,
  (state: SalesOrderStore) => state.list
);

export const selectSalesOrderBlocksValidations = createSelector(
  selectFeature,
  (state: SalesOrderStore) => state.blocksValidations
);

export const selectSalesOrder = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.salesOrder
);

export const selectSalesOrderPositions = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.positions
);

export const selectSalesOrderPositionsCount = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.positionsCount
);

export const selectCountries = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.countries
);

export const selectSalesOrderCurrentState = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.currentState
);

export const selectSalesOrderInvoiceExisting = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.isInvoiceExist
);

export const loadingRequestsCount = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.loadingRequestsCount
);

export const salesOrderUpdatedAt = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectSalesOrderEntity,
  (state: SalesOrderState) => state.isShouldRefresh
);

export const selectSalesOrderPropertiesBlockValid = createSelector(
  selectSalesOrderBlocksValidations,
  (state: SalesOrderBlocksValidationsState) => state.propertiesBlockValid
);

export const selectSalesOrderDeliveryInfoBlockValid = createSelector(
  selectSalesOrderBlocksValidations,
  (state: SalesOrderBlocksValidationsState) => state.deliveryInfoBlockValid
);

export const selectSalesOrderBillingInfoBlockValid = createSelector(
  selectSalesOrderBlocksValidations,
  (state: SalesOrderBlocksValidationsState) => state.billingInfoBlockValid
);

export const selectSalesOrderPositionsBlockValid = createSelector(
  selectSalesOrderBlocksValidations,
  (state: SalesOrderBlocksValidationsState) => state.positionsBlockValid
);
