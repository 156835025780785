import { createAction, props } from '@ngrx/store';

import { UIStatesEnum, AddressModel } from 'common/src/models';
import { DeliveryNote, DeliveryInformation } from '../../models/delivery-note.model';
import { TabsCountersModel } from 'common/src/models/tabs-counters.model';
import { DeliveryNotesListState } from '../reducers';
import { DeliveryNoteListTabsEnum } from '../../enums';

export const LoadDeliveryNotesList = createAction(
  '[Delivery Note] Load Delivery Note List',
  props<{ deliveryNotesListData: DeliveryNotesListState, status: DeliveryNoteListTabsEnum, page: number }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[[Delivery Note] Update Delivery Note should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadDeliveryNote = createAction(
  '[Delivery Note] Load Delivery Note',
  props<{ deliveryNote: DeliveryNote }>()
);

export const LoadDeliveryNoteCounters = createAction(
  '[Delivery Note] Load Delivery Note counters',
  props<{ counters: TabsCountersModel }>()
);

export const ClearDeliveryNote = createAction(
  '[Delivery Note] Clear Delivery Note',
);

export const UpdateDeliveryNoteState = createAction(
  '[Delivery Note] Update Delivery Note State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateDeliveryNoteEstimatedDeliveryDate = createAction(
  '[Delivery Note] Update Delivery Note Estimated Delivery Date',
  props<{ estimatedDeliveryDate: Date }>()
);

export const UpdateDeliveryNoteDeliveryInformation = createAction(
  '[Delivery Note] Update Delivery Note delivery information',
  props<{ deliveryInformation: DeliveryInformation }>()
);

export const UpdateDeliveryNoteDeliveryInformationDeliveryAddress = createAction(
  '[Delivery Note] Load Delivery Note delivery information Delivery Address',
  props<{ deliveryToAddress: AddressModel }>()
);

export const UpdateDeliveryNoteDeliveryInformationPickupAddress = createAction(
  '[Delivery Note] Load Delivery Note delivery information Pickup Address',
  props<{ pickUpAddress: AddressModel }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[Delivery Note] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Delivery Note] Decrement Loading Requests Count'
);

export const UpdateDeliveryNoteUpdatedAt = createAction(
  '[Delivery Note] Update Delivery Note updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearDeliveryNoteUpdatedAt = createAction(
  '[Delivery Note] Clear Delivery Note updatedAt',
);

// export const UpdatePropertiesBlockValid = createAction(
//   '[Delivery Note] Update Delivery Note Properties Block Valid',
//   props<{ propertiesBlockValid: boolean }>()
// );

export const UpdateDeliveryInfoBlockValid = createAction(
  '[Delivery Note] Update Delivery Note Delivery Info Block Valid',
  props<{ deliveryInfoBlockValid: boolean }>()
);
