import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

export function getDocumentUrl(documentType: string | DocumentTypesUppercaseEnum, documentId: number): string {
  if (!documentType || !documentId) { return null; }

  let url: string = '';

  switch (documentType as string) {
    case 'delivery_note':
    case 'dn':
    case DocumentTypesUppercaseEnum.DN:
      url = '/warehouse/outgoing-delivery/';
      break;

    // TRADE
    case 'offer':
    case 'OFFER':
    case DocumentTypesUppercaseEnum.OFR:
      url = '/trade/offers/';
      break;

    case 'subscription':
    case DocumentTypesUppercaseEnum.SBC:
      url = '/services/subscription/';
      break;

    case DocumentTypesUppercaseEnum.SO:
    case 'so':
    case 'sales_order':
      url = '/trade/sales-order/';
      break;

    case 'po':
    case 'purchase_order':
    case DocumentTypesUppercaseEnum.PO:
      url = '/trade/purchase-order/';
      break;

    case 'era':
    case DocumentTypesUppercaseEnum.ERA:
      url = '/trade/exchanges-and-returns/';
      break;

    // ACCOUNTING
    case DocumentTypesUppercaseEnum.CRN:
    case DocumentTypesUppercaseEnum.ICN:
    case DocumentTypesUppercaseEnum.OCN:
    case 'credit_note':
      url = '/accounting/credit-note/';
      break;

    case 'oin':
    case 'outgoing_invoice':
    case DocumentTypesUppercaseEnum.OIN:
      url = '/accounting/outgoing-invoice/';
      break;

    case 'incoming_invoice':
    case DocumentTypesUppercaseEnum.IIN:
      url = '/accounting/incoming-invoice/';
      break;

    case DocumentTypesUppercaseEnum.IPB:
    case DocumentTypesUppercaseEnum.OPB:
    case DocumentTypesUppercaseEnum.PMNT:
    case 'payment':
      url = '/accounting/payments/';
      break;

    case DocumentTypesUppercaseEnum.SP:
      url = '/accounting/scheduled-payment/';
      break;

    // E-COMMERCE
    case DocumentTypesUppercaseEnum.ECO:
      url = '/e-commerce/orders/';
      break;

    default:
      return null;

  }

  return url + documentId;
}
