export enum SubscriptionActivationTabEnum {
  SUBSCRIPTION_OPTIONS = 'SUBSCRIPTION_OPTIONS',
  CARD_DETAILS = 'CARD_DETAILS',
  // BILLING_DETAILS = 'BILLING_DETAILS',
  HELLO = 'HELLO',
  // PAY_INVOICE = 'PAY_INVOICE',
}

export enum SubscriptionPlanEnum {
  FREE = 'FREE',
  ACCOUNTING_MONTHLY = 'ACCOUNTING_MONTHLY',
  ACCOUNTING_ANNUALLY = 'ACCOUNTING_ANNUALLY',
  SOLO_MONTHLY = 'SOLO_MONTHLY',
  SOLO_ANNUALLY = 'SOLO_ANNUALLY',
  TEAM_MONTHLY = 'TEAM_MONTHLY',
  TEAM_ANNUALLY = 'TEAM_ANNUALLY',
}
