import { Component, Input, OnInit } from '@angular/core';
import { NavigationItemModel } from 'common/src/models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../projects/workspace/src/app/store/state/app.state';
import { getRouterState } from 'projects/workspace/src/app/store/selectors/app.selectors';

@Component({
  selector: 'rnpl-view-nav',
  templateUrl: './view-nav.component.html',
  styleUrls: ['./view-nav.component.scss']
})

export class ViewNavComponent implements OnInit {

  @Input()
  viewNavItems: NavigationItemModel[];

  @Input()
  selectedItem: string;

  public activeRouter = {
    mainItem: '',
    subItem: ''
  };

  public activeRout: string;

  public isOpenMenu: boolean = false;

  constructor(private readonly store: Store<AppState>) {
  }

  ngOnInit(): void {
    this.bindRouterStateChanges();
  }

  public isActiveItemHandler(item): void {
    if (!item.children && !item.disabled) {
      this.activeRouter['mainItem'] = item.title.toLowerCase();
    }
    if (!item.disabled) {
      this.selectedItem = item.title.toLowerCase();
    }
  }

  public isActiveItem (title): boolean {
    const {mainItem} = this.activeRouter;
    return title.toLowerCase() === mainItem;
  }

  public setActiveState (router: string): boolean {
    return this.activeRout.search(router) > 0;
  }

  public isOpenSubMenuHandler(item): void {
    if (!item.disabled && item.title.toLowerCase() === this.selectedItem && item.children) {
      this.isOpenMenu = !this.isOpenMenu;
    } else if (!item.disabled) {
      this.isOpenMenu = true;
    }
  }

  public isOpenSubMenu (title): boolean {
    return this.isOpenMenu && title.toLowerCase() === this.selectedItem;
  }

  public isActiveSubItemHandler(item, subItem): void {
    this.activeRouter['mainItem'] = item.toLowerCase();
    this.activeRouter['subItem'] = subItem.toLowerCase();
  }

  public isActiveSubItem(itemTitle, subItemTitle): boolean {
    const {subItem, mainItem} = this.activeRouter;
    return subItemTitle.toLowerCase() === subItem && itemTitle.toLowerCase() === mainItem;
  }

  public bindRouterStateChanges(): void {
    this.store
      .select(getRouterState)
      .subscribe((router) => {
        this.activeRout = router.state.url;
      });
  }

}

