import { ChannelOptions } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SubscriptionPlanEnum } from '../../../subscription-activation/subscription-activation.emum';

export function getPermissions(channelsOptions: ChannelOptions, activePlan: SubscriptionPlanEnum): any[] {
  const isFreePlan = activePlan === SubscriptionPlanEnum.FREE;
  const isAccountingPlan = activePlan === SubscriptionPlanEnum.ACCOUNTING_MONTHLY || activePlan === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY;

  const permissions: any[] = [
    {
      module: 'wholesale',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect',
      enabled: channelsOptions.wholesalesEnabled ||
        (isFreePlan || isAccountingPlan) ||
        (
          channelsOptions.servicesEnabled &&
          !channelsOptions.wholesalesEnabled &&
          !channelsOptions.ecommerceEnabled
        )
    },
    {
      module: 'ecommerce',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect',
      enabled: channelsOptions.ecommerceEnabled
    },
    {
      module: 'services',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect',
      enabled: channelsOptions.servicesEnabled
    },
    {
      module: 'accounting',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect'
    },
    {
      module: 'warehouse',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect',
      enabled: !isFreePlan &&
        !isAccountingPlan &&
        !(channelsOptions.servicesEnabled &&
          !channelsOptions.wholesalesEnabled &&
          !channelsOptions.ecommerceEnabled
        )
    },
    {
      module: 'products',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect'
    },
    {
      module: 'partners',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect'
    },
    {
      module: 'team',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect'
    },
    {
      module: 'administration',
      locked: 'selected',
      view: 'availableForSelect',
      edit: 'availableForSelect',
      manage: 'availableForSelect'
    },
    // {
    //   module: 'analytics',
    //   locked: 'selected',
    //   view: 'availableForSelect',
    //   edit: 'availableForSelect',
    //   manage: 'availableForSelect'
    // },
    // {
    //   module: 'retail',
    //   locked: 'selected',
    //   view: 'availableForSelect',
    //   edit: 'availableForSelect',
    //   manage: 'availableForSelect'
    // },
  ];

  return permissions.filter(itm => {
    if (itm.hasOwnProperty('enabled')) {
      return itm.enabled;
    }
    return true;
  });
}
