import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { delay, finalize, switchMap, takeUntil } from 'rxjs/operators';

import { ToasterService } from '../../ui-components/toaster';
import { UserModel } from '../../../models';
import { TableColumnModelExtended } from '../../../models/table-column.model';
import { tableColumns } from './manage-users.config';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { UserListTabs } from 'projects/workspace/src/app/hrm/pages/user-list/enums/user-list-tabs.enum';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';

@Component({
  selector: 'rnpl-manage-users',
  templateUrl: './manage-users.component.html',
})
export class ManageUsersComponent implements OnDestroy {

  public activeUsers: UserModel[];
  public columns: TableColumnModelExtended[] = tableColumns;
  public selectedRows: UserModel[] = [];

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public dialogRef: MatDialogRef<ManageUsersComponent>,
    public toasterService: ToasterService,
    public administrationsApiService: AdministrationsApiService,
    public hrmService: HrmService,
  ) {
    this.hrmService.getSubscriptionActivationUsers()
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((users: UserModel[]) => {
        this.activeUsers = users.map(user => {
          return {
            ...user,
            employee_data: {
              user: user,
              label: `${user.firstName + ' ' + user.lastName}`,
              routerLink: `/team/employee-profile/${user.id}`
            },
          };
        });
      });
  }

  public manageUsers(): void {
    const usersToDeactivate = this.activeUsers
      .filter(activeUser => !this.selectedRows.some(selectedUser => selectedUser.id === activeUser.id))
      .map(user => user.id);

    this.hrmService.changeUsersStatus({ user_ids: usersToDeactivate, status: UserListTabs.Inactive, skipWarnings: false })
      .pipe(
        // delay before getCompanyProfile() call
        switchMap(() => of({}).pipe(delay(1000))), // todo: replace after companyProfile webSocket
        switchMap(() => this.administrationsApiService.getCompanyProfile()),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.dialogRef.close(res);
      }, error => {
        this.toasterService.notify({message: error.error.message, type: 'error'});
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
