export enum PaymentMethodsEnum {
  BANK_TRANSFER = 'bank_transfer',
  CREDIT_CARD = 'credit_card',
  PAYMENT_CARD = 'payment_card',
  CASH = 'cash',
  SEPA = 'sepa',
}

export enum PaymentCardTypeEnum {
  CREDIT_CARD = 'credit_card',
  DEBIT_CARD = 'debit_card',
}

export enum BankTransferMethodsEnum {
  MANUAL_TRANSFER = 'manual',
  SEPA = 'sepa',
  SEPA_DIRECT_DEBIT = 'sepa_direct_debit',
}
