import { createAction, props } from '@ngrx/store';

import { ReportingPeriodsListState } from '../reducers';
import { ReportingPeriodModel, ReportingPeriodsPreferencesModel } from '../../models';
import { UIStatesEnum } from 'common/src/models';
import { ReportingPeriodsListTabsEnum } from '../../enums';

export const LoadReportingPeriodsList = createAction(
  '[Reporting Periods] Load Reporting Periods List',
  props<{ reportingPeriods: ReportingPeriodsListState, status: ReportingPeriodsListTabsEnum, page: number }>()
);

export const LoadReportingPeriod = createAction(
  '[Reporting Periods] Load Reporting Periods',
  props<{ reportingPeriod: ReportingPeriodModel }>()
);

export const LoadReportingPeriodsPreferences = createAction(
  '[Reporting Periods] Load Reporting Periods Preferences',
  props<{ preferences: ReportingPeriodsPreferencesModel }>()
);

export const UpdateReportingPeriodUpdatedAt = createAction(
  '[Reporting Periods] Update Reporting Periods updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateReportingPeriodPreferencesCurrentState = createAction(
  '[Reporting Periods] Update Reporting Periods Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateReportingPeriodPreferencesUpdatedAt = createAction(
  '[Reporting Periods] Update Reporting Periods Preferences updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Reporting Periods] Update Reporting Periods should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const ClearReportingPeriodUpdatedAt = createAction('[Reporting Periods] Clear Reporting Periods updatedAt');

export const IncrementLoadingRequestsCount = createAction('[Reporting Periods] Increment Loading Requests Count');

export const DecrementLoadingRequestsCount = createAction('[Reporting Periods] Decrement Loading Requests Count');
