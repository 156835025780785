import { AfterViewChecked, AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ShepherdService } from 'angular-shepherd';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { TradePositionModel } from 'projects/workspace/src/app/trade/models';
import { getReceivedGoodsColumns } from './incoming-delivery-warehousing-modal.config';
import {
  IncomingDeliveryApiService
} from 'projects/workspace/src/app/warehouse/modules/incoming-delivery/services/incoming-delivery-api.service';
import { StockAreaEnum } from 'projects/workspace/src/app/warehouse/modules/transactions/enums';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { PauseModalsHintsComponent } from '../../modals-subscription/pause-modals-hints/pause-modals-hints.component';

interface DataType {
  documentId?: number;
  location?: StockAreaEnum;
  confirmed?: any;
  updateDocument?: boolean;
  fromPo?: boolean;
}

@Component({
  selector: 'rnpl-incoming-delivery-warehousing-modal',
  templateUrl: './incoming-delivery-warehousing-modal.component.html'
})
export class IncomingDeliveryWarehousingModalComponent extends BaseModalComponent implements
  OnInit,
  AfterViewInit,
  OnDestroy {

  public warehouseAllGoods: boolean = false;

  public columns: TableColumnModelExtended[] = getReceivedGoodsColumns();
  public products: any[] = [];

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('hint', { static: false }) hint: PauseModalsHintsComponent;

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public purchaseOrderApiService: PurchaseOrderApiService,
    public exchangeApiService: ExchangeApiService,
    public incomingDeliveryApiService: IncomingDeliveryApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IncomingDeliveryWarehousingModalComponent>,
    // public shepherdService: ShepherdService,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.incomingDeliveryApiService.getIncomingBufferProducts(this.data.location, this.data.documentId)
      .pipe(takeUntil(this._destroy))
      .subscribe((response) => {
        this.products = this.prepareProducts(response);
        this.isLoading$.next(false);
      });
  }

  // ngAfterViewChecked(): void {
  //   window.dispatchEvent(new Event('resize'));
  // }

  public onGoToProduct(service: TradePositionModel): void {
    window.open(window.location.origin + `/products/product-view/${service.productId}`, '_blank');
  }

  public prepareProducts(products): any {
    return [...products.map((item) => {
      return {
        ...item,
        productLink: {
          label: item.productRunpleId,
          routerLink: `/products/product-view/${item.productId}`
        },
        productNameLink: {
          label: item.productName,
          routerLink: `/products/product-view/${item.productId}`
        },
        warehouse: this.setQuantity(item)
      };
    })];
  }

  public setQuantity(item): number {
    if (!this.data || !this.data.confirmed) { return  item.warehouse; }

    const confirmedItem = this.data.confirmed.find(c => c.productId === item.productId && c.batchId === item.batchId);

    return confirmedItem
      ? confirmedItem.delivered
      : item.warehouse;
  }

  public warehouseAllGoodsChanged(): void {
    this.products = [...this.products.map((good) => {
      return {
        ...good,
        warehouse: good.received,
      };
    })];

    this.columns = getReceivedGoodsColumns(this.warehouseAllGoods);
  }

  public warehouseProducts(): void {
    const products = {
      products: this.products,
    };

    setTimeout(() => { // wait for steppers to update
      this.incomingDeliveryApiService.transferIncomingBufferProducts(this.data.location, this.data.documentId, products)
        .pipe(takeUntil(this._destroy))
        .subscribe(response => {
          if (this.data.updateDocument) {
            if (this.data.location === StockAreaEnum.GENERAL_STOCK) {
              this.purchaseOrderApiService.getPO(this.data.documentId).subscribe();
            } else if (this.data.location === StockAreaEnum.RETURNS_STOCK) {
              this.exchangeApiService.getExchangeById(this.data.documentId).subscribe();
            }
          }
          this.dialogRef.close(response);
        });
    }, 1500);
  }

  // public displayHints(): void {
  //   if (!this.shepherdService.isActive) {
  //     clearInterval(this.countdownTimer);
  //     this.shepherdService.start();
  //   }
  // }
  //
  // public startTimer(text: string): void {
  //   this.setVisitedPage();
  //   clearInterval(this.countdownTimer);
  //
  //   let hintsTimer: number = Math.ceil(Math.floor(text.length * HintsIndexTimerText) / 100);
  //
  //   this.hintsTimerCount = hintsTimer / 10;
  //
  //   const hintsBtnProgressElement = document.getElementsByClassName('hints-next-btn-progress');
  //
  //   const visibleBtnProgressElement = hintsBtnProgressElement[hintsBtnProgressElement.length - 1] as HTMLElement;
  //
  //   if (!!visibleBtnProgressElement) {
  //     visibleBtnProgressElement.style.animationDuration = `${this.hintsTimerCount}s`;
  //   }
  //
  //   this.countdownTimer = setInterval( () => {
  //     if (this.shepherdService.isActive) {
  //
  //       hintsTimer -= 1;
  //
  //       if (hintsTimer <= 0 && !this.stopTimer) {
  //         clearInterval(this.countdownTimer);
  //         this.shepherdService.next();
  //       }
  //     } else {
  //       clearInterval(this.countdownTimer);
  //       this.shepherdService.cancel();
  //     }
  //   }, 100);
  //
  // }
  //
  // private preparePartnerListHintsSteps(hintsList: HintsModel[]): HintsModel[] {
  //   return hintsList.map((hint, index) => {
  //     return {
  //       ...hint,
  //       buttons: this.getHintsButton(index),
  //       when: this.getHintsShowAndHideEvents(index),
  //       title: `
  //               <span class="hints-count">${index + 1}/${hintsList.length}</span>
  //               <span class="hints-img">👍</span>
  //              `,
  //     };
  //   });
  // }
  //
  // private cancelHintsTour(text?: string): void {
  //   this.stopTimer = true;
  //
  //   const hintsElements = document.getElementsByClassName('shepherd-element');
  //   const visibleHintsElement = hintsElements[hintsElements.length - 1] as HTMLElement;
  //   visibleHintsElement.style.zIndex = '999';
  //
  //   const hintsBtnProgressElement = document.getElementsByClassName('hints-next-btn-progress');
  //   const visibleBtnProgressElement = hintsBtnProgressElement[hintsBtnProgressElement.length - 1] as HTMLElement;
  //   visibleBtnProgressElement.classList.remove('show-animation');
  //
  //   const dialog = this.dialog.open(WarningModalComponent, {
  //     data: {
  //       title: 'Skip hints?',
  //       message: `Are you sure you want to skip hints?`,
  //       confirmBtnText: 'Skip',
  //       confirmBtnIcon: 'redo-outline'
  //     }
  //   });
  //
  //   dialog.afterClosed()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((response: CommonModalsActionsEnum) => {
  //       this.stopTimer = false;
  //       clearInterval(this.countdownTimer);
  //       visibleBtnProgressElement.classList.add('show-animation');
  //
  //       if (response === CommonModalsActionsEnum.CONFIRM) {
  //         this.shepherdService.cancel();
  //       }
  //
  //       if (!!text) {
  //         this.startTimer(text);
  //       }
  //
  //       visibleHintsElement.style.zIndex = '1001';
  //     });
  // }
  //
  // private getHintsShowAndHideEvents(index: number): HintsWhenModel {
  //   return {
  //     show: () => {
  //       clearInterval(this.countdownTimer);
  //       this.startTimer(this.hintsSteps[index].text);
  //     },
  //     hide: () => {
  //       clearInterval(this.countdownTimer);
  //     }
  //   };
  // }
  //
  // private getHintsButton(index: number): HintsButtonsModel[] {
  //   return [
  //     HintsButtonGoItTemplate,
  //     {
  //       text: 'Skip hints',
  //       classes: 'skip-hints',
  //       action: (() => this.cancelHintsTour(this.hintsSteps[index].text))
  //     },
  //   ];
  // }
  //
  // private getFinishHintsButtons(): HintsButtonsModel[] {
  //   return [
  //     HintsButtonCancelTemplate,
  //     {
  //       text: 'Repeat hints',
  //       classes: 'skip-hints',
  //       action: (() => {
  //         this.shepherdService.cancel();
  //         setTimeout(() => {
  //           this.shepherdService.start();
  //         }, 500);
  //       })
  //     },
  //   ];
  // }
  //
  // private setVisitedPage(): void {
  //   if (!this.isVisitedThePage) {
  //     localStorage.setItem('incomingDeliveryWarehousingModal', 'visited');
  //   }
  // }
  //
  // get isVisitedThePage(): boolean {
  //   return !!localStorage.getItem('incomingDeliveryWarehousingModal');
  // }

  ngAfterViewInit() {
    // this.shepherdService.confirmCancel = false;
    // this.shepherdService.modal = false;
    // this.shepherdService.addSteps([...this.preparePartnerListHintsSteps(this.hintsSteps), this.finishHintsConfig]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    // this.shepherdService.cancel();
    // clearInterval(this.countdownTimer);
  }

}
