import { UserListTabs } from "./enums/user-list-tabs.enum";
import { EmployeesListTotalsModel } from '../../models';
import {
  TableSummaryBarItemModel,
  TableSummaryBarItemTypeEnum
} from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { NavBarBtnModel } from 'common/src/models';
import { FiltersControlsNamesEnum, FiltersGroupModel } from 'common/src/modules/filters';
import { Observable } from 'rxjs';
import { OptionModel } from '../../../../../../../common/src/modules/dynamic-forms/controls/multiple-control.component';
import { UsersFiltersListModel } from '../../models/users-filters-list.model';


export const EMPTY_STATE_DATA = {
  [UserListTabs.Active]: {
    text: 'TEAM.EMPTY_ACTIVE'
  },
  [UserListTabs.Inactive]: {
    text: 'TEAM.EMPTY_INACTIVE'
  },
  [UserListTabs.Deleted]: {
    text: 'TEAM.EMPTY_DELETED'
  }
};

export const ActionBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.DELETE_PERMANENTLY',
    multipleText: 'BUTTON.DELETE_PERMANENTLY',
    badgeClass: 'rnpl-badge--red',
    iconName: 'fire',
    actionName: 'onDeletePermanentlyUserClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      UserListTabs.Inactive,
      UserListTabs.Active
    ],
  },
  {
    classes: 'btn-primary',
    text: 'BUTTON.RESTORE',
    multipleText: 'BUTTON.RESTORE',
    badgeClass: 'rnpl-badge--grey',
    iconName: 'undo',
    disabled: false,
    actionName: 'onRestoreUserClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      UserListTabs.Active,
      UserListTabs.Inactive
    ]
  },
  {
    classes: 'btn-white red',
    text: 'BUTTON.DELETE',
    multipleText: 'BUTTON.DELETE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'trash-2',
    actionName: 'onDeleteUserClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      UserListTabs.Deleted
    ]
  },
  {
    classes: 'btn-primary',
    text: 'BUTTON.ACTIVATE',
    multipleText: 'BUTTON.ACTIVATE',
    badgeClass: 'rnpl-badge--grey',
    iconName: 'bulb',
    disabled: false,
    actionName: 'onActivateUserClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      UserListTabs.Active,
      UserListTabs.Deleted
    ]
  },
  {
    classes: 'btn-white',
    text: 'BUTTON.DEACTIVATE',
    multipleText: 'BUTTON.DEACTIVATE',
    badgeClass: 'rnpl-badge--grey',
    iconName: 'bulb-off',
    disabled: false,
    actionName: 'onDeactivateUserClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      UserListTabs.Inactive,
      UserListTabs.Deleted
    ]
  },
];

export function getTableSummaryBarItems(
  totals: EmployeesListTotalsModel
): TableSummaryBarItemModel[] {
  const tableSummaryBarItems: TableSummaryBarItemModel[] = [
    {
      label: 'COLUMN.EMPLOYEE',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.employee,
      basisWidth: 167,
      displayed: !!totals.employee
    },
    {
      label: 'MODULE.ACCOUNTING',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.accounting,
      basisWidth: 167,
      displayed: !!totals.accounting
    },
    {
      label: 'MODULE.WAREHOUSE',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.warehouse,
      basisWidth: 167,
      displayed: !!totals.warehouse
    },
    {
      label: 'MODULE.TRADE',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.trade,
      basisWidth: 167,
      displayed: !!totals.trade
    },
    {
      label: 'MODULE.SERVICES',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.services,
      basisWidth: 167,
      displayed: !!totals.services
    },
    {
      label: 'DEPARTMENTS.RETAIL',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.retail,
      basisWidth: 167,
      displayed: !!totals.retail
    },
    {
      label: 'DEPARTMENTS.LOGISTICS',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.logistics,
      basisWidth: 167,
      displayed: !!totals.logistics
    },
    {
      label: 'APP.MANAGEMENT',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.management,
      basisWidth: 167,
      displayed: !!totals.management
    },
    {
      label: 'DEPARTMENTS.LEGAL',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.legal,
      basisWidth: 167,
      displayed: !!totals.legal
    },
    {
      label: 'DEPARTMENTS.IT',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.it,
      basisWidth: 167,
      displayed: !!totals.it
    },
    {
      label: 'DEPARTMENTS.MARKETING',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.marketing,
      basisWidth: 167,
      displayed: !!totals.marketing
    },
    {
      label: 'DEPARTMENTS.CUSTOMER_CARE',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.customerCare,
      basisWidth: 167,
      displayed: !!totals.customerCare
    },
    {
      label: 'DEPARTMENTS.HR',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.hr,
      basisWidth: 167,
      displayed: !!totals.hr
    },
    {
      label: 'ADMINISTRATION.ECOMMERCE',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.eCommerce,
      basisWidth: 167,
      displayed: !!totals.eCommerce
    },
  ];

  return tableSummaryBarItems;
}


export function getTeamsListFilters(
  runpleIdsList$: Observable<UsersFiltersListModel[]>,
  activeStatus: UserListTabs
): FiltersGroupModel[] {
  return [
    {
      disabled: true,
      filters: [
        {
          label: 'FORM.EMPLOYEE',
          controlName: 'id',
          controlType: FiltersControlsNamesEnum.EMPLOYEE,
          employeeStatus: activeStatus
        },
        {
          label: 'COLUMN.DEPARTMENT',
          controlName: 'department',
          bindLabel: 'label',
          bindValue: 'label',
          containerClass: 'col-6',
          placeholder: 'TEAM.SELECT_DEPARTMENT',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          baseDropdownItems: [
            {value: 'Accounting', label: 'CATEGORIES.ACCOUNTING'},
            {value: 'Warehouse', label: 'MODULE.WAREHOUSE'},
            {value: 'Wholesale', label: 'MODULE.TRADE'},
            {value: 'Services', label: 'MODULE.SERVICES'},
            {value: 'E-commerce', label: 'MODULE.E_COMMERCE'},
            {value: 'Management', label: 'APP.MANAGEMENT'},
          ]
        },
        {
          label: 'APP.POSITION',
          controlName: 'position',
          containerClass: 'col-6',
          bindLabel: 'label',
          bindValue: 'label',
          placeholder: 'TEAM.SELECT_POSITION',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          baseDropdownItems: [
            {value: 'CEO', label: 'USER_POSITIONS.CEO'},
            {value: 'C-level manager', label: 'USER_POSITIONS.C_LEVEL'},
            {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
            {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
            {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
            {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
            {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
            {value: 'Workspace administrator', label: 'USER_POSITIONS.ADMIN'},
          ]
        },
      ]
    },
    {
      title: 'DOC_BLOCK.PROPERTIES',
      filters: [
        {
          label: 'COLUMN.ID',
          placeholder: 'FORM.SELECT_ID',
          controlName: 'runpleId',
          bindValue: 'runpleId',
          bindLabel: 'runpleId',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          observableList: runpleIdsList$,
          allowSelectAll: true,
        },
        {
          label: 'COLUMN.LAST_ACTIVITY',
          controlName: 'lastActivity',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
        },
        {
          label: 'FORM.CREATION_DATE',
          controlName: 'created',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
        },
      ]
    }
  ];

}
