import { ProductTypes } from '../products/product-types';
import { UIStatesEnum } from '../../models';
import { DateColumnType } from '../../models/table-column.model';
import { PositionsActionsType } from '../ui-components/table/custom-table.enums';

export interface PositionInfoListModel {
  remarkBlockClass?: string;
  rightImg?: string;
  currencyIsOriginal?: boolean;
  label: string;
  prop: string;
  placeholder?: string;
  type: PositionItemTypeEnum;
  forcedType?: PositionItemTypeEnum;
  controlType?: ControlTypeEnum;
  itemClass?: string;
  hideWithProductType?: ProductTypes[];
  hideWithProductTypeGrouping?: ProductTypes[];
  showWithUIState?: UIStatesEnum[];
  unitType?: string | boolean;
  inputDropdownType?: InputDropdownTypeEnum;
  suffix?: string;
  listItems?: {value: any; label: any; enable?: boolean}[];
  bindLabel?: string;
  bindValue?: string;
  disabled?: boolean;
  vatBlocked?: boolean;
  valueClass?: string;
  controlClass?: string;
  suffixClass?: string;
  showWithDocumentStatus?: any[];
  currencyConversion?: boolean;
  showCondition?: boolean;
  currencyNegativeRow?: boolean;
  currencySourceFormat?: boolean;
  isOptional?: boolean;
  hintId?: string;
  dateType?: DateColumnType;
  displayConditions?: DisplayConditionsModel[];
  showUnitOnly?: boolean;
  colorState?: string;
}

export interface DisplayConditionsModel {
  values: any[];
  prop: string;
  productType?: ProductTypes;
}

export interface ActionsReorderingPositionModel {
  actionType: PositionsActionsType;
  rowIndex: number;
  moveTo: number;
  row: any;
  rows?: any;
  top?: any;
  left?: any;
  returnData?: any;
}

export interface PositionsIndexModel {
  index: number;
  title: string | number;
}

export enum PositionItemTypeEnum {
  WITH_CONTROL,
  PRIMARY,
  NUMBER,
  CURRENCY,
  STATUS_LINE,
  PROFIT,
  WITH_LINK,
  WITH_DATE
}

export enum ControlTypeEnum {
  TEXTAREA,
  INPUT,
  INPUT_DROPDOWN,
  DROPDOWN,
  DISCOUNT,
  REMARK,
  DATE,
  PROFIT,
  TOGGLE,
  UNIT_TYPE,
  ARBITRARY_POSITION_TYPE,
}

export enum InputDropdownTypeEnum {
  NUMBER,
  CURRENCY,
  DISCOUNT
}
