import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rnpl-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {

  public currentYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
  }

}
