import { ProductTypes } from 'common/src/modules/products/product-types';
import { DueWithinDateDaysEnum } from '../shared/enums';

export const CURRENCIES_SYMBOLS_BY_ISO_CODE = {
  EUR: ' €',
  BGN: ' лв',
  HRK: ' kn',
  CZK: ' Kč',
  DKK: ' kr',
  HUF: ' Ft',
  PLN: ' zł',
  RON: ' Leu',
  SEK: ' kr',
  CHF: ' Fr.',
  USD: ' $',
  GBP: ' £',
  ISK: ' Kr',
  NOK: ' Kr',
};

export const CURRENCIES_SYMBOLS_BY_ISO_CODE_FOR_TABLE = {
  EUR: ' eur',
  BGN: ' лв',
  HRK: ' kn',
  CZK: ' Kč',
  DKK: ' kr',
  HUF: ' Ft',
  PLN: ' zł',
  RON: ' Leu',
  SEK: ' kr',
  CHF: ' Fr.',
  USD: ' usd',
  GBP: ' gbp',
  ISK: ' Kr',
  NOK: ' Kr',
};

export const STATUS_KEY = 'status';

export enum FeeTypesEnum {
  BANK_FEE = 'BANK_FEE',
  PAYMENT_SERVICE_FEE = 'PAYMENT_SERVICE_FEE',
  EXCHANGE_RATE_DIFF = 'EXCHANGE_RATE_DIFF',
  USER_DEFINED = 'USER_DEFINED',
}

export const FEE_TYPES: {value: string, label: string}[] = [
  {value: FeeTypesEnum.BANK_FEE, label: 'FEES_TYPE.FEE_TYPE_BANK'},
  {value: FeeTypesEnum.PAYMENT_SERVICE_FEE, label: 'FEES_TYPE.FEE_TYPE_PAYMENT_SERVICE'},
  // {value: FeeTypesEnum.EXCHANGE_RATE_DIFF, label: 'FEES_TYPE.FEE_TYPE_EXCHANGE'},
  {value: FeeTypesEnum.USER_DEFINED, label: 'FEES_TYPE.FEE_TYPE_USER_DEFINED'},
];


export const DUE_WITHIN_DAYS: {value: number, label: string}[] = [
  {value: 7, label: 'FORM.7_DAYS'},
  {value: 14, label: 'FORM.14_DAYS'},
  {value: 30, label: 'FORM.30_DAYS'},
  {value: 60, label: 'FORM.60_DAYS'},
  {value: 90, label: 'FORM.90_DAYS'},
  {value: null, label: 'COMMON.USER_DEFINED'}
];

export const DUE_WITHIN_DATE_DAYS: {value: DueWithinDateDaysEnum, label: string}[] = [
  {value: DueWithinDateDaysEnum.DAYS_7, label: 'FORM.7_DAYS'},
  {value: DueWithinDateDaysEnum.DAYS_14, label: 'FORM.14_DAYS'},
  {value: DueWithinDateDaysEnum.DAYS_30, label: 'FORM.30_DAYS'},
  {value: DueWithinDateDaysEnum.DAYS_60, label: 'FORM.60_DAYS'},
  {value: DueWithinDateDaysEnum.DAYS_90, label: 'FORM.90_DAYS'},
  {value: DueWithinDateDaysEnum.DATE, label: 'COMMON.SPECIFY_DATE'},
  {value: DueWithinDateDaysEnum.USER_DEFINED, label: 'COMMON.USER_DEFINED'}
];

export const CORRECTION_POSITION_TYPES_LIST = [
  {value: 'NEGATIVE', label: '-', },
  {value: 'POSITIVE', label: '+'},
];

export const CORRECTION_CALCULATION_LIST = [
  {value: true, label: 'FORM.MANUALLY', },
  {value: false, label: 'FORM.AUTOMATICALLY'},
];

export const CORRECTION_TYPE_LABEL = {
  ['NEGATIVE']: '-',
  ['POSITIVE']: '+'
};

export const ARBITRARY_POSITION_TYPES_LIST = [
  { value: ProductTypes.GOODS, label: 'APP.PRODUCT' },
  { value: ProductTypes.SERVICES, label: 'APP.SERVICE' },
  { value: ProductTypes.DIGITAL, label: 'APP.DIGITAL_PRODUCT' },
];
