import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { AddressModel, ImageModel } from 'common/src/models';
import { CountryModel, RnplCommonService } from 'common/src/modules/rnpl-common';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { Title } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { GenderEnum } from 'projects/workspace/src/app/partners/corporate/enums/gender.enum';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';

@Component({
  selector: 'rnpl-create-new-private-partner-modal',
  templateUrl: './create-new-private-partner-modal.component.html',
  styleUrls: ['./create-new-private-partner-modal.component.scss']
})
export class CreateNewPrivatePartnerModalComponent implements OnInit {

  public form: FormGroup;

  public countries: CountryModel[];
  public partnersTypeEnum = PartnersTypeEnum;

  public readonly genderType = GenderEnum;

  readonly createNewPartnerRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly titleItems$: BehaviorSubject<Title[]> = new BehaviorSubject<Title[]>([]);

  constructor(
    private readonly partnerApiService: PartnersApiService,
    private readonly commonService: RnplCommonService,
    private readonly toasterService: ToasterService,
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateNewPrivatePartnerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.commonService.getCountries()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => this.countries = response.data);

    this.partnerApiService.getTitles()
      .pipe(takeUntil(this.destroy$))
      .subscribe(titles => this.titleItems$.next(titles));

    this.initForm();
  }

  initForm(): void {
    this.form = this.fb.group({
      title: [],
      birthday: [],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      gender: [GenderEnum.UNSPECIFIED],
      legalAddress: AddressFormFactory.getForm({} as AddressModel, false, true, ['country_iso3']),
      email: [null, [Validators.email]],
      phone: [null, []],
      image: [null, []],
      remark: [null, []],
    });
  }

  submitPartner(partnerData: Partial<PartnerModel>) {
    if (this.createNewPartnerRequest$.getValue()) { return; }
    this.createNewPartnerRequest$.next(true);

    this.partnerApiService.createPartner(PartnersTypeEnum.PRIVATE, partnerData)
      .pipe(
        finalize(() => this.createNewPartnerRequest$.next(false)),
        catchError(error => {
          this.displayMessage(error.error.message || error.error.errors, 'error');
          return throwError(error);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((partner: PartnerModel ) => {
        this.displayMessage('SUCCESS_CREATED.PARTNER', 'success');
        this.dialogRef.close(partner);
      });
  }

  public getFormData() {
    const formData: any = {
      ...this.form.getRawValue(),
    };
    formData.image = { id: get(formData, 'image.apiModel.id', null) };
    return formData;
  }

  public displayMessage(msg: string, type: string) {
    this.toasterService.notify({
      type,
      message: msg
    });
  }


  public setLogo(images: ImageModel[]): void {
    this.image.setValue(images.length ? images[0] : null);
  }

  get title(): FormControl { return this.form.get('title') as FormControl; }
  get birthday(): FormControl { return this.form.get('birthday') as FormControl; }
  get firstName(): FormControl { return this.form.get('firstName') as FormControl; }
  get lastName(): FormControl { return this.form.get('lastName') as FormControl; }
  get gender(): FormControl { return this.form.get('gender') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  public get legalAddress(): FormGroup { return this.form.get('legalAddress') as FormGroup; }
  public get legalAddressCountry() { return this.form.get('legalAddress').get('country_iso3') as FormControl; }
  public get email(): FormControl { return this.form.get('email') as FormControl; }
  public get phone(): FormControl { return this.form.get('phone') as FormControl; }
  public get image(): FormControl { return this.form.get('image') as FormControl; }

}
