import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FooterDocumentInfoModel } from '../../document-viewer.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
// import { selectGeneralSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
// import { GeneralSettingsModel } from 'projects/workspace/src/app/shared/models';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { BankAccountsApiService } from 'projects/workspace/src/app/bank-accounts/services/bank-accounts-api.service';
import { BankAccountModel, BankModel } from 'projects/workspace/src/app/bank-accounts/models';
import { PdfSettingsModel } from 'projects/workspace/src/app/pdf-settings/models';
import {
  PdfSettingsAlignEnum,
  PdfSettingsFooterBlocksEnum,
  PdfSettingsFooterInfoBlocksEnum
} from 'projects/workspace/src/app/pdf-settings/enums';

@Component({
  selector: 'rnpl-document-viewer-footer',
  templateUrl: './document-viewer-footer.component.html',
  styleUrls: ['./document-viewer-footer.component.scss']
})
export class DocumentViewerFooterComponent implements OnChanges, OnDestroy{

  public footerInfoBlocksEnum = PdfSettingsFooterInfoBlocksEnum;
  public blocksEnum = PdfSettingsFooterBlocksEnum;
  public alignEnum = PdfSettingsAlignEnum;
  public bankAccount: BankAccountModel;

  @Input() public footerInfo: FooterDocumentInfoModel;
  @Input() public pdfSettings: PdfSettingsModel;
  @Input() public bankInfo: { name: string; bic: string; iban: string } = null;

  // public generalSettings$: Observable<GeneralSettingsModel> = this.store.select(selectGeneralSettings);
  public companyProfile$: Observable<CompanyProfile> = this.store.select(selectCompanyProfile);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private store: Store<AppState>,
    private bankAccountsApiService: BankAccountsApiService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('pdfSettings') && this.pdfSettings && !this.bankAccount) {
      this.bankAccountsApiService.getBankAccountPrimary()
        .pipe(takeUntil(this.destroy$))
        .subscribe((bankAccount: BankAccountModel) => this.bankAccount = bankAccount);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
