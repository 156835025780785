import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { DeliveryInformation } from '../models/delivery-note.model';
import { AddressFormFactory } from '../../shared/forms/address-form.factory';
import { AddressModel, DeliveryTypesEnum } from 'common/src/models';
import { UpdateDeliveryInfoBlockValid } from '../store/actions/delivery-note.actions';
import { AppState } from '../../store/state/app.state';
import { AddressTypeEnum } from '../../sales-order/enums/address-type.enum';

@Injectable({
  providedIn: 'root'
})
export class DeliveryNoteFormService {

  public pickUpAddressForm: FormGroup;
  public form: FormGroup;
  public deliveryToAddressForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private store: Store<AppState>,
  ) { }

  public initForm(deliveryInformation = {} as DeliveryInformation, selectedDeliveryType): void {
    this.form = this.fb.group({
      deliveryType: [selectedDeliveryType, {updateOn: 'change', Validators: [Validators.required]}],
      isExpressDelivery: [deliveryInformation.isExpressDelivery || false, {updateOn: 'change'}],
      deliveryService: [deliveryInformation.deliveryService.id ? deliveryInformation.deliveryService : null],
      trackingCode: [deliveryInformation.trackingCode || null],
      partialShipment: [deliveryInformation.partialShipment || false, {updateOn: 'change'}],
      addressTemplate: [deliveryInformation.addressTemplate, {updateOn: 'change'}],
      name: [get(deliveryInformation, 'name'), [Validators.required]],
      estimatedDeliveryDate: [this.datePipe.transform(deliveryInformation.estimatedDeliveryDate, 'yyyy-MM-dd')],
    }, {updateOn: 'blur'});

    this.deliveryToAddressForm = AddressFormFactory.getForm(deliveryInformation.deliveryToAddress);
  }

  public initPickUpForm(pickUpAddress = {} as AddressModel): void {
    this.pickUpAddressForm = AddressFormFactory.getForm(pickUpAddress);
  }

  public updateDeliveryInfoBlockValid(hasLinkedEco: boolean): void {
    if (!this.form) { return; }

    let isFormsValid: boolean;
    if (hasLinkedEco) {
      this.form.get('deliveryService').setValidators(Validators.required);
      this.form.get('trackingCode').setValidators(Validators.required);
    } else {
      this.form.get('trackingCode').clearValidators();
      this.form.get('deliveryService').clearValidators();
    }
    this.form.get('deliveryService').updateValueAndValidity({emitEvent: false});
    this.form.get('trackingCode').updateValueAndValidity({emitEvent: false});

    if (this.form.get('deliveryType').value === DeliveryTypesEnum.DELIVERY) {
      if (this.form.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.deliveryToAddressForm);
      } else {
        this.deliveryToAddressForm.get('address_line').disable({emitEvent: false});
        this.deliveryToAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.form.valid && this.deliveryToAddressForm.valid;
    } else {
      if (this.form.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.pickUpAddressForm);
      } else {
        this.pickUpAddressForm.get('address_line').disable({emitEvent: false});
        this.pickUpAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.form.valid && this.pickUpAddressForm.valid;
    }

    this.store.dispatch(UpdateDeliveryInfoBlockValid({ deliveryInfoBlockValid: isFormsValid }));
  }

}
