import { Component, Input, OnInit } from '@angular/core';
import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import { CreateInvoiceHints } from 'projects/workspace/src/app/time-tracking/time-tracking-hints.config';
import { takeUntil } from 'rxjs/operators';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';

@Component({
  selector: 'rnpl-grouping-modal-hints',
  templateUrl: './grouping-modal-hints.component.html',
})
export class GroupingModalHintsComponent extends BaseMegaHintsComponent implements OnInit {
  @Input() public screenName: string;

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'MEGA_HINT.END_STEP.GROUPING';
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }

    this.initHints();
  }


  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = CreateInvoiceHints;

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length && !this.shepherdService.isActive) {
            this.shepherdService.start();
          }
        }
      });
  }

}
