import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { RnplCommonModule } from 'common/src/modules/rnpl-common';
import { DueWithinFormComponent } from './due-within-form.component';
import { UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    DueWithinFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    UIComponentsModule,
    RnplCommonModule,
    TranslateModule,
    NgxMaskModule
  ],
  exports: [DueWithinFormComponent],
  entryComponents: [DueWithinFormComponent],
  providers: []
})
export class DueWithinFormModule { }
