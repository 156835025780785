import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import { takeUntil } from 'rxjs/operators';
import { returnHintsList } from 'projects/workspace/src/app/exchange/returns-hints.config';
import { ExchangeRequiredSolutionEnum } from 'projects/workspace/src/app/exchange/enums';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';

@Component({
  selector: 'rnpl-add-product-hints',
  templateUrl: './add-product-hints.component.html',
  styleUrls: ['./add-product-hints.component.css']
})
export class AddProductHintsComponent extends BaseMegaHintsComponent implements OnInit, OnChanges {
  @Input() public selectPurpose: ExchangeRequiredSolutionEnum;
  @Input() public selectedReplacementProduct: boolean;
  @Input() public screenName;

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'BUTTON.EDIT_PRODUCT';
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('selectPurpose') ||
      changes.hasOwnProperty('selectedReplacementProduct')
    ) {
      if (!!this.selectPurpose && this.selectPurpose === ExchangeRequiredSolutionEnum.EXCHANGE) {
        this.screenName = 'returns_add_positions_to_exchange';
      }
      clearInterval(this.countdownTimer);
      if (this.shepherdService.isActive) {
        this.shepherdService.cancel();
      }

      if (!!this.screenName) {
        this.initHints();
      }
    }
  }

  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {

          this.hintListByName = returnHintsList(
            false,
            false,
            false,
            this.selectPurpose,
            this.selectedReplacementProduct
          );

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length && !this.shepherdService.isActive) {
            this.shepherdService.start();
          }
        }
      });
  }
}
