export const DASHBOARD_TICKS_OPTIONS = {
  beginAtZero: true,
  fontColor: '#5E6681',
  fontSize: 12,
  fontStyle: '500',
  fontFamily: 'Inter',
};

export const DASHBOARD_TICKS_OPTIONS_X_AXES = {
  padding: 8,
  autoSkipPadding: 12,
  maxRotation: 0
};


