import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { SalesOrderForceCompleteActionEnum } from 'projects/workspace/src/app/sales-order/enums';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { BaseModalComponent } from '../../../rnpl-common';
import { ToasterService } from '../../../ui-components/toaster';


@Component({
  selector: 'rnpl-force-complete-so-modal',
  templateUrl: './force-complete-so-modal.component.html'
})
export class ForceCompleteSoModalComponent extends BaseModalComponent {

  public fixDeliveryCosts = false;
  public salesOrderForceCompleteActionEnum = SalesOrderForceCompleteActionEnum;

  readonly cancelProductRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  // readonly removeProductRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public salesOrderApiService: SalesOrderApiService,
    public dialogRef: MatDialogRef<ForceCompleteSoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      salesOrderId: number,
      isDeliveryOrInsuranceCost: boolean
    }
  ) {
    super(toasterService);
  }

  public forceCompleteSalesOrder(action: SalesOrderForceCompleteActionEnum, loadingRequest$: BehaviorSubject<boolean>): void {
    if (loadingRequest$.getValue()) { return; }
    loadingRequest$.next(true);

    this.salesOrderApiService.salesOrderForceComplete(this.data.salesOrderId, action, this.fixDeliveryCosts)
      .pipe(
        finalize(() => loadingRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(() => this.dialogRef.close());
  }

}

