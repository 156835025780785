import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from 'common/src/models';

import { CountryModel } from '../models';
import { GeneralGoodModel } from '../../products';
import { DocumentTypesUppercaseEnum } from '../../modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { DisplayToaster } from 'projects/workspace/src/app/shared/decorators/toaster';
import { ToasterService } from '../../ui-components/toaster';
import { DEFAULT_SORT_DIRECTION } from 'projects/workspace/src/app/shared/constants';


@Injectable()
export class RnplCommonService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
  ) {
  }

  getCountries(): Observable<ApiResponse<CountryModel[]>> {
    return this.http.get<ApiResponse<CountryModel[]>>('/countries');
  }

  @DisplayToaster({showErrorMessage: true})
  sendEmail(emails: string[], documentType: DocumentTypesUppercaseEnum, documentId: number, setStatusToSent: boolean): Observable<any> {
    const body = { emails, setStatusToSent };
    return this.http.post( `accounting/v1/email/${documentType}/${documentId}`, body);
  }

  getDropdownGeneralGoods(field = 'runple_id', order_by: 'ASC' | 'DESC' = DEFAULT_SORT_DIRECTION): Observable<GeneralGoodModel[]> {
    return this.http.get<ApiResponse<GeneralGoodModel[]>>(
     '/dropdown/products/general-goods',
     {params: {field, order_by}}
    ).pipe(map((response: ApiResponse<GeneralGoodModel[]>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
