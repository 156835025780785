import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'rnpl-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss']
})
export class AttributesComponent implements OnInit, OnDestroy {

  public type: string;
  public entity: string;

  private paramsSubscription: Subscription;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.paramsSubscription = this.route.paramMap
      .subscribe(params => {
        this.type = params.get('type');
        this.entity = params.get('entity').replace('-', '_');
      });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

}
