import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmptyStateTypeEnum } from './empty-state.model';

@Component({
  selector: 'rnpl-empty-state',
  templateUrl: 'empty-state.component.html',
  styleUrls: ['empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  @Input() public type: EmptyStateTypeEnum|'documents-list';
  @Input() public icon: string = 'plus';
  @Input() public title: string = 'POSITIONS.NO_DATA_TO_DISPLAY';
  @Input() public positionAbsolute: boolean = true;
  @Input() public highlighting: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public isGrey: boolean;
  @Input() public wFull: boolean;

  @Output() clickAction: EventEmitter<any> = new EventEmitter<any>();

  public hasAction = false;

  ngOnInit(): void {
    this.hasAction = this.clickAction.observers.length > 0;
  }

  public emptyStateClick(disabled): void {
    if(!disabled) {
      this.clickAction.emit();
    }
  }
}
