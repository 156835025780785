import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { CustomerTypeEnum } from 'common/src/models';

@Component({
  selector: 'rnpl-company-name-control',
  templateUrl: './company-name-control.component.html',
})
export class CompanyNameControlComponent {

  @Input() control: FormControl;
  @Input() partnerType: CustomerTypeEnum|any;
  @Input() addressType: 'billing'|'delivery';
  // @Input() isOptional: boolean = false;
  // @Input() showValidation: boolean = false;

  @Output() fieldChanged: EventEmitter<any> = new EventEmitter<any>();

  public updateField(fieldValue: any): void {
    this.fieldChanged.emit(fieldValue);
  }

}
