import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { ScheduledPaymentBillingScheduleFormComponent } from './scheduled-payment-billing-schedule-form.component';

@NgModule({
  declarations: [
    ScheduledPaymentBillingScheduleFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    RnplCommonModule,
    UIComponentsModule
  ],
  exports: [
    ScheduledPaymentBillingScheduleFormComponent,
  ],
  entryComponents: [
    ScheduledPaymentBillingScheduleFormComponent
  ],
  providers: []
})
export class ScheduledPaymentBillingScheduleFormModule { }
