import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  EmployeeProfileComponent,
  HrmMainComponent,
  PersonalInfoComponent,
  PreferencesSecurityComponent,
  PreferencesSystemComponent,
  RoleAndPermissionsComponent,
  UserListComponent
} from './pages';
// import { AttributesComponent, FormSetupViewComponent, FormSetupComponent } from 'common/src/modules/entities-management/pages';
import { UserListTabs } from './pages/user-list/enums/user-list-tabs.enum';
import { IsUserGuard } from './guards/is-user.guard';
import { MyProfileGuard } from './guards/my-profile.guard';
import { AuthGuard } from 'common/src/auth/auth.guard';
import { InitialSetupGuard } from 'common/src/modules/initial-setup/initial-setup.guard';


const routes: Routes = [
  {
    path: '',
    component: HrmMainComponent,
    children: [
      {
        path: 'dashboard',
        canLoad: [AuthGuard, InitialSetupGuard],
        loadChildren: () => import('./team-dashboard/team-dashboard.module').then(m => m.TeamDashboardModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: `employee/list/${UserListTabs.Active}/1`,
      },
      {
        path: 'employee/list/:status/:page',
        component: UserListComponent,
        data: {
          title: 'Employees'
        }
      },
      {
        path: 'employee-profile/:userId',
        component: EmployeeProfileComponent,
        canActivate: [IsUserGuard],
        data: {
          title: 'Employee Profile'
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'profile',
          },
          {
            path: 'profile',
            component: PersonalInfoComponent,
          },
          {
            path: 'role-and-permissions',
            component: RoleAndPermissionsComponent,
          },
        ]
      },
      {
        path: 'my-profile',
        component: EmployeeProfileComponent,
        canActivate: [MyProfileGuard],
        data: {
          title: 'Employee Profile',
          myProfile: true
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'profile',
          },
          {
            path: 'profile',
            component: PersonalInfoComponent,
          },
          {
            path: 'role-and-permissions',
            component: RoleAndPermissionsComponent,
          },
          {
            path: 'preferences',
            pathMatch: 'full',
            // redirectTo: 'preferences/system',
            redirectTo: 'preferences/security',
          },
          {
            path: 'preferences/system',
            component: PreferencesSystemComponent,
          },
          {
            path: 'preferences/security',
            component: PreferencesSecurityComponent,
          },
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HrmRoutingModule {
}
