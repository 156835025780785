import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import {
  SalesOrderModel,
  SalesOrderPropertiesModel
} from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { selectSalesOrder } from 'projects/workspace/src/app/sales-order/store/selectors';
import { ModalNameEnum } from '../../../../models/modal-name.enum';

@Component({
  selector: 'rnpl-sales-order-add-remark-modal',
  templateUrl: './sales-order-add-remark-modal.component.html',
})
export class SalesOrderAddRemarkModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public salesOrder: SalesOrderModel;
  readonly destroy$: Subject<void> = new Subject();

  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<SalesOrderAddRemarkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private salesOrderApiService: SalesOrderApiService,
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.trackSalesOrderChanges();
  }

  private trackSalesOrderChanges(): void {
    this.store.select(selectSalesOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((salesOrder: SalesOrderModel) => {
        this.salesOrder = salesOrder;
        this.initForm(this.salesOrder.properties);
      });
  }

  public initForm(properties: SalesOrderPropertiesModel = {} as SalesOrderPropertiesModel): void {
    this.form = this.fb.group({
      printableRemarks: [get(properties, 'printableRemarks')],
    });
    this.isLoading$.next(false);
  }

  public updatePartnerField(value, closeModal = false): void {
    this.salesOrderApiService.updateSalesOrder(
      this.salesOrder.id,
      {
        fieldValue: value,
        fieldName: 'properties.printableRemarks'
      })
      .subscribe(() => {
        if (closeModal) {
          this.dialogRef.close();
        }
      }); // updated via store
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
