import { ProductTypes } from '../../products/product-types';
import { AppNameEnum } from '../../start-screen-page/launchpad.model';

export interface NavItem {
  salesChannel?: SalesChannelEnum;
  name?: string;
  iconName?: string;
  route?: string;
  show?: boolean;
  disabled?: boolean;
  children?: NavItem[];
  action?: any;
  title?: string;
  icon?: string;
  gradient?: string;
  minWidthClass?: string;
  secondRoute?: string;
  mainColor?: string;
  type?: AppNameEnum;
}
export enum SalesChannelEnum {
  TRADE = 'trade',
  SERVICES = 'services',
  E_COMMERCE = 'ecommerce',
  ALL_CHANNEL = 'allChannels'
}

export function getSalesChannelList (
  wholesaleEnabled: boolean,
  servicesEnabled: boolean,
  ecommerceEnabled: boolean,
): NavItem[] {
  return [
    {
      name: 'MODULE.TRADE',
      salesChannel: SalesChannelEnum.TRADE,
      iconName: 'cash',
      show: wholesaleEnabled,
    },
    {
      name: 'MODULE.SERVICES',
      salesChannel: SalesChannelEnum.SERVICES,
      iconName: 'checklist',
      show: servicesEnabled,
    },
    // {
    //   name: 'MODULE.E_COMMERCE',
    //   salesChannel: SalesChannelEnum.E_COMMERCE,
    //   iconName: 'shopping-cart',
    //   show: ecommerceEnabled,
    // },
    {
      name: 'MODULE.ALL_CHANNELS',
      salesChannel: SalesChannelEnum.ALL_CHANNEL,
      iconName: 'grid',
      show: servicesEnabled && wholesaleEnabled,
    },
  ];
}

// export const SALES_CHANNELS: NavItem[] = [
//   {
//     name: 'MODULE.TRADE',
//     salesChannel: 'trade',
//     iconName: 'cash'
//   },
//   {
//     name: 'MODULE.SERVICES',
//     salesChannel: 'services',
//     iconName: 'checklist'
//   },
//   // {
//   //   name: 'MODULE.E_COMMERCE',
//   //   salesChannel: 'ecommerce',
//   //   iconName: 'shopping-cart',
//   // },
// ];

export function getHeadNavigationMenuItems(
  salesChannel: SalesChannelEnum,
  canViewAccounting: boolean,
  canViewAdministration: boolean,
  canViewAnalytics: boolean,
  canViewEcommerce: boolean,
  canViewPartners: boolean,
  canViewProducts: boolean,
  canViewRetail: boolean,
  canViewServices: boolean,
  canViewTeam: boolean,
  canViewWarehouse: boolean,
  canViewWholesale: boolean,
  wholesaleEnabled: boolean,
  ecommerceEnabled: boolean,
  servicesEnabled: boolean,
  goodsProductTypeEnabled: boolean,
  servicesProductTypeEnabled: boolean,
  digitalProductTypeEnabled: boolean,
  corporatePartnersEnabled: boolean,
  privatePartnersEnabled: boolean,
): NavItem[] {

  const productTypeRedirect = goodsProductTypeEnabled
    ? ProductTypes.GOODS
    : servicesProductTypeEnabled
      ? ProductTypes.SERVICES
      : ProductTypes.DIGITAL;

  const productTypeRedirectForPurchasePrice = goodsProductTypeEnabled
    ? ProductTypes.GOODS
    : digitalProductTypeEnabled
      ? ProductTypes.DIGITAL
      : null;

  const SALES_MENU_ITEMS: NavItem = {
    name: 'MODULE.SALES',
    minWidthClass: 'min-w-220',
    show:
      salesChannel === SalesChannelEnum.TRADE
        ? canViewAccounting || canViewWholesale || canViewWarehouse
        : salesChannel === SalesChannelEnum.SERVICES
          ? canViewServices || canViewAccounting || canViewWholesale
          : canViewAccounting || canViewWholesale || canViewWarehouse || canViewServices,
    children: []
  };

  const PURCHASE_MENU_ITEMS: NavItem = {
    name: 'MODULE.PURCHASES',
    minWidthClass: 'min-w-220',
    show:
      salesChannel === SalesChannelEnum.SERVICES
        ? canViewServices || canViewAccounting || canViewWholesale
        : canViewAccounting || canViewWholesale || canViewWarehouse || canViewServices,
    children: []
  };

  const ACCOUNTING_MENU_ITEMS: NavItem = {
    name: 'MODULE.ACCOUNTING',
    minWidthClass: 'min-w-220',
    show: canViewAccounting,
    children: []
  };

  // const ANALYTICS_MENU_ITEMS: NavItem = {
  //   name: 'MODULE.ANALYTICS',
  //   minWidthClass: 'min-w-220',
  //   children: []
  // };

  const MANAGEMENT_MENU_ITEMS: NavItem = {
    name: 'APP.MANAGEMENT',
    minWidthClass: 'min-w-220',
    children: []
  };

  const OFFERS_ITEM: NavItem = {
    name: 'APP.OFFERS',
    show: canViewWholesale && wholesaleEnabled,
    iconName: 'offer',
    route: '/trade/offers',
    action: 'createNewOffer'
  };

  const SALES_ORDERS_ITEM: NavItem = {
    name: 'APP.SALES_ORDERS',
    show: canViewWholesale && wholesaleEnabled,
    iconName: 'sales-order',
    route: '/trade/sales-order',
    action: 'createNewOrder'
  };

  const PURCHASE_ORDERS_ITEM: NavItem = {
    name: 'APP.PURCHASE_ORDERS',
    show: canViewWholesale && wholesaleEnabled,
    iconName: 'purchase-order',
    route: '/trade/purchase-order',
    action: 'createNewPurchaseOrder'
  };

  const SALES_PRICES_ITEM: NavItem = {
    name: 'PRICE.SALES_PRICES',
    show: (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled),
    iconName: 'pricetag',
    route: `/trade/prices/sales`,
    secondRoute: salesChannel === SalesChannelEnum.SERVICES ? '/trade/prices/sales/services' : '',
  };

  const PURCHASE_PRICES_ITEM: NavItem = {
    name: 'PRICE.PURCHASE_PRICES',
    show: (canViewWholesale || canViewServices) &&
      (wholesaleEnabled || servicesEnabled) &&
      (goodsProductTypeEnabled || digitalProductTypeEnabled),
    iconName: 'pricetag',
    route: `/trade/prices/purchase`,
  };

  const ERA_ITEM: NavItem = {
    name: 'APP.RETURNS',
    show: (canViewWholesale || canViewServices) && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'exchange-return',
    route: '/trade/exchanges-and-returns',
    action: 'createNewERA',
  };

  const INCOMING_DELIVERIES_ITEM: NavItem = {
    name: 'APP.INCOMING_DELIVERIES',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'incoming-delivery',
    route: '/warehouse/incoming-delivery',
  };

  const OUTGOING_DELIVERIES_ITEM: NavItem = {
    name: 'APP.OUTGOING_DELIVERIES',
    show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'outgoing-delivery',
    route: '/warehouse/outgoing-delivery',
    action: 'createNewDeliveryNote'
  };

  const IIN_ITEM: NavItem = {
    name: 'APP.INCOMING_INVOICES',
    show: canViewAccounting,
    iconName: 'incoming-invoice',
    route: '/accounting/incoming-invoice',
    action: 'createNewIncomingInvoice'
  };

  const OIN_ITEM: NavItem = {
    name: 'APP.OUTGOING_INVOICES',
    show: canViewAccounting,
    iconName: 'outgoing-invoice',
    route: '/accounting/outgoing-invoice',
    action: 'createNewInvoice'
  };

  const STOCK_STATUS_ITEM: NavItem = {
    name: 'APP.STOCK_STATUS',
    show: goodsProductTypeEnabled && canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    iconName: 'cubes',
    route: '/warehouse/stock-status'
  };

  const OPEN_ITEMS_LIST_ITEM: NavItem = {
    name: 'APP.OPEN_ITEM_LIST',
    show: canViewAccounting,
    iconName: 'open-items-list',
    route: '/accounting/open-items'
  };

  const PMNT_ITEM: NavItem = {
    name: 'APP.PAYMENTS',
    show: canViewAccounting,
    iconName: 'payment',
    route: '/accounting/payments'
  };

  const CRN_ITEM: NavItem = {
    name: 'APP.CREDIT_NOTES',
    show: canViewAccounting,
    iconName: 'credit-note',
    route: '/accounting/credit-note',
    action: 'createNewCreditNote'
  };

  const REPORTING_PERIODS_ITEM: NavItem = {
    name: 'REPORTING_PERIODS.REPORTING_PERIODS',
    show: canViewAccounting,
    iconName: 'accountant-report',
    route: '/accounting/reporting-periods',
    children: [
      {
        name: 'REPORTING_PERIODS.REPORTS',
        route: '/accounting/reporting-periods/list',
        show: canViewAccounting,
      },
      {
        name: 'DOC_BLOCK.PREFERENCES',
        route: '/accounting/reporting-periods/preferences',
        show: canViewAccounting,
      },
    ]
  };

  const BANK_ACCOUNTS_ITEM: NavItem = {
    name: 'APP.BANK_ACCOUNTS',
    show: canViewAccounting,
    iconName: 'account',
    route: '/accounting/bank-accounts',
    action: 'createBankAccount'
  };

  const SCHEDULED_PAYMENTS_ITEM: NavItem = {
    name: 'SCHEDULED_PAYMENT.SCHEDULED_PAYMENTS',
    show: canViewAccounting,
    iconName: 'recurring-costs',
    route: '/accounting/scheduled-payment',
    action: 'createNewScheduledPayment'
  };

  const TIME_TRACKING_ITEM: NavItem = {
    name: 'APP.TIME_TRACKING',
    show: canViewServices && servicesEnabled,
    iconName: 'timer',
    route: '/services/time-tracking',
    action: 'onLogTimeClick',
  };

  const SUBSCRIPTIONS_ITEM: NavItem = {
    name: 'APP.SUBSCRIPTIONS',
    show: canViewServices && servicesEnabled,
    iconName: 'subscriptions',
    route: '/services/subscription',
    action: 'createNewSubscription'
  };

  // const WAREHOUSE_MENU_ITEMS: NavItem[] = [
  //   {
  //     name: 'MODULE.WAREHOUSE',
  //     show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
  //     route: '/warehouse',
  //     children: [
  //       {
  //         name: 'APP.TRANSACTIONS',
  //         show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
  //         iconName: 'stocktransfer',
  //         route: '/warehouse/transactions',
  //       },
  //       {
  //         name: 'APP.MANAGEMENT',
  //         show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
  //         iconName: 'warehouse',
  //         route: '/warehouse/warehouse-profile',
  //       },
  //     ]
  //   },
  // ];

  // const PARTNERS_MENU_ITEMS: NavItem[] = [
  //   {
  //     name: 'MODULE.PARTNERS',
  //     show: canViewPartners,
  //     route: '/partners-new',
  //     children: [
  //       {
  //         name: 'APP.CORPORATE_PARTNERS_1',
  //         iconName: 'factory',
  //         show: corporatePartnersEnabled,
  //         route: `/partners-new/corporate`,
  //         action: 'createNewPartner',
  //       },
  //       {
  //         name: 'APP.PRIVATE_PARTNERS',
  //         iconName: 'person',
  //         show: privatePartnersEnabled,
  //         route: `/partners-new/private`,
  //         action: 'createNewPrivatePartner',
  //       },
  //     ]
  //   },
  // ];

  // const TEAM_MENU_ITEMS: NavItem[] = [
  //   {
  //     name: 'MODULE.TEAM',
  //     show: canViewTeam,
  //     route: '/team',
  //   },
  // ];

  // const PRODUCTS_MENU_ITEMS: NavItem[] = [
  //   {
  //     name: 'APP.PRODUCTS',
  //     show: canViewProducts,
  //     route: '/products',
  //     children: [
  //       {
  //         name: 'APP.PRODUCTS',
  //         iconName: 'box',
  //         show: goodsProductTypeEnabled,
  //         route: `/products/${ProductTypes.GOODS}`,
  //         action: 'createNewProduct'
  //       },
  //       {
  //         name: 'APP.DIGITAL_PRODUCTS',
  //         iconName: 'digital-product',
  //         show: digitalProductTypeEnabled,
  //         route: `/products/${ProductTypes.DIGITAL}`,
  //         action: 'createNewDigitalProduct'
  //       },
  //       {
  //         name: 'APP.SERVICES',
  //         iconName: 'checklist',
  //         show: servicesProductTypeEnabled,
  //         route: `/products/${ProductTypes.SERVICES}`,
  //         action: 'createNewService'
  //       },
  //       {
  //         name: 'APP.CATEGORIES',
  //         iconName: 'folder-open',
  //         route: `/products/settings/categories/${productTypeRedirect}`,
  //       },
  //     ]
  //   },
  // ];

  // const ADMINISTRATION_MENU_ITEMS: NavItem[] = [
  //   {
  //     name: 'MODULE.ADMINISTRATION',
  //     show: canViewAdministration,
  //     route: '/administration',
  //   },
  // ];

  const ACCOUNTING_SETTINGS_ITEM: NavItem = {
    // name: 'APP.SETTINGS',
    // show: canViewAccounting,
    // iconName: 'options-2',
    // route: '/accounting/settings',
  };

  const MANAGEMENT_GROUPING = [
    // {
    //   title: 'APP.PRODUCTS',
    //   show: canViewProducts,
    //   items: [
    //     {
    //       name: 'COMMON.GOODS',
    //       iconName: 'box',
    //       show: goodsProductTypeEnabled,
    //       route: `/products/${ProductTypes.GOODS}`,
    //       action: 'createNewProduct'
    //     },
    //     {
    //       name: 'APP.DIGITAL_PRODUCTS',
    //       iconName: 'digital-product',
    //       show: digitalProductTypeEnabled,
    //       route: `/products/${ProductTypes.DIGITAL}`,
    //       action: 'createNewDigitalProduct'
    //     },
    //     {
    //       name: 'APP.SERVICES',
    //       iconName: 'checklist',
    //       show: servicesProductTypeEnabled,
    //       route: `/products/${ProductTypes.SERVICES}`,
    //       action: 'createNewService'
    //     },
    //     {
    //       name: 'APP.CATEGORIES',
    //       iconName: 'folder-open',
    //       show: true,
    //       route: `/products/settings/categories/${productTypeRedirect}`,
    //     },
    //     {
    //       name: 'APP.SETTINGS',
    //       show: canViewProducts,
    //       iconName: 'options-2',
    //       route: `/products/products-settings`,
    //     },
    //   ]
    // },
    // {
    //   title: 'MODULE.PARTNERS',
    //   show: canViewPartners,
    //   items: [
    //     {
    //       name: 'APP.CORPORATE_PARTNERS_1',
    //       iconName: 'factory',
    //       show: corporatePartnersEnabled,
    //       route: `/partners-new/corporate`,
    //       action: 'createNewPartner',
    //     },
    //     {
    //       name: 'APP.PRIVATE_PARTNERS',
    //       iconName: 'person',
    //       show: privatePartnersEnabled,
    //       route: `/partners-new/private`,
    //       action: 'createNewPrivatePartner',
    //     },
    //     {
    //       name: 'APP.SETTINGS',
    //       show: canViewPartners,
    //       iconName: 'options-2',
    //       route: `/partners-new/settings`,
    //     },
    //   ]
    // },
    // {
    //   title: 'MODULE.TEAM',
    //   show: canViewTeam,
    //   items: [
    //     {
    //       name: 'APP.EMPLOYEES',
    //       show: canViewTeam,
    //       iconName: 'people',
    //       route: '/team',
    //       action: 'createNewEmployee'
    //     },
    //   ]
    // },
    // {
    //   title: 'MODULE.WAREHOUSE',
    //   show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled) && salesChannel !== SalesChannelEnum.SERVICES,
    //   items: [
    //     {
    //       name: 'APP.TRANSACTIONS',
    //       show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    //       iconName: 'stocktransfer',
    //       route: '/warehouse/transactions',
    //       action: 'createTransaction'
    //     },
    //     {
    //       name: 'APP.MANAGEMENT',
    //       show: canViewWarehouse && (wholesaleEnabled || ecommerceEnabled),
    //       iconName: 'warehouse',
    //       route: '/warehouse/warehouse-profile',
    //     },
    //     {
    //       name: 'APP.SETTINGS',
    //       show: canViewWarehouse && (wholesaleEnabled),
    //       iconName: 'options-2',
    //       route: '/warehouse/settings',
    //     },
    //   ]
    // },
    // {
    //   title: 'MODULE.ADMINISTRATION',
    //   show: canViewAdministration,
    //   items: [
    //     {
    //       name: 'ADMINISTRATION.COMPANY_PROFILE',
    //       show: canViewAdministration,
    //       route: '/administration/company-profile',
    //       iconName: 'factory'
    //     },
    //   ]
    // }
  ];

  // const ANALYTICS_GROUPING = [
  //   {
  //     title: 'Dashboards',
  //     show: (wholesaleEnabled && canViewWholesale) || canViewWarehouse || canViewAccounting,
  //     items: []
  //   },
  // ];

  // const TRADE_DASHBOARD_ITEM: NavItem = {
  //   name: 'DASHBOARDS.TRADE_DATA',
  //   iconName: 'cash',
  //   show: canViewWholesale && wholesaleEnabled,
  //   route: '/analytics/trade-dashboard'
  // };

  // const ACCOUNTING_DASHBOARD_ITEM: NavItem = {
  //   name: 'DASHBOARDS.ACCOUNTING_DATA',
  //   iconName: 'calculator',
  //   show: canViewAccounting,
  //   route: '/analytics/accounting-dashboard'
  // };

  // const WAREHOUSE_DASHBOARD_ITEM: NavItem = {
  //   name: 'DASHBOARDS.WAREHOUSE_DATA',
  //   iconName: 'warehouse',
  //   show: canViewWarehouse,
  //   route: '/analytics/warehouse-dashboard'
  // };

  const SALES_GROUPING = [
    // {
    //   title: 'MODULE.TRADE',
    //   show: canViewWholesale && wholesaleEnabled,
    //   items: [
    //     OFFERS_ITEM,
    //     SALES_ORDERS_ITEM,
    //     ERA_ITEM
    //   ]
    // },
    // {
    //   title: 'MODULE.SERVICES',
    //   show: canViewServices && servicesEnabled,
    //   items: [
    //     TIME_TRACKING_ITEM,
    //     SUBSCRIPTIONS_ITEM,
    //   ]
    // },
    // {
    //   title: 'MODULE.BACKOFFICE',
    //   show: true,
    //   items: [
    //     OUTGOING_DELIVERIES_ITEM,
    //     OIN_ITEM,
    //     SALES_PRICES_ITEM,
    //     STOCK_STATUS_ITEM,
    //   ]
    // },
  ];

  const SALES_GROUPING_TRADE = [
    // {
    //   title: 'MODULE.SALES',
    //   show: canViewWholesale && wholesaleEnabled,
    //   items: [
    //     OFFERS_ITEM,
    //     SALES_ORDERS_ITEM,
    //     ERA_ITEM
    //   ]
    // },
    // {
    //   title: 'MODULE.BACKOFFICE',
    //   show: true,
    //   items: [
    //     OUTGOING_DELIVERIES_ITEM,
    //     OIN_ITEM,
    //     SALES_PRICES_ITEM,
    //     STOCK_STATUS_ITEM,
    //   ]
    // },
  ];

  const SALES_GROUPING_SERVICES = [
    // {
    //   title: 'MODULE.SALES',
    //   show: canViewWholesale && wholesaleEnabled,
    //   items: [
    //     TIME_TRACKING_ITEM,
    //     SUBSCRIPTIONS_ITEM,
    //   ]
    // },
    // {
    //   title: 'MODULE.BACKOFFICE',
    //   show: true,
    //   items: [
    //     OIN_ITEM,
    //     SALES_PRICES_ITEM,
    //   ]
    // },
  ];

  const PURCHASES_GROUPING = [
    // {
    //   title: 'MODULE.PURCHASES',
    //   show: true,
    //   items: [
    //     PURCHASE_ORDERS_ITEM
    //   ]
    // },
    // {
    //   title: 'MODULE.BACKOFFICE',
    //   show: true,
    //   items: [
    //     INCOMING_DELIVERIES_ITEM,
    //     IIN_ITEM,
    //     PURCHASE_PRICES_ITEM,
    //     STOCK_STATUS_ITEM
    //   ]
    // },
  ];

  const PURCHASES_GROUPING_SERVICES = [
    // {
    //   title: 'MODULE.PURCHASES',
    //   show: true,
    //   items: [
    //     PURCHASE_ORDERS_ITEM
    //   ]
    // },
    // {
    //   title: 'MODULE.BACKOFFICE',
    //   show: true,
    //   items: [
    //     IIN_ITEM,
    //   ]
    // },
  ];

  if (salesChannel === SalesChannelEnum.TRADE) {
    SALES_MENU_ITEMS.children = SALES_GROUPING_TRADE;

    PURCHASE_MENU_ITEMS.children = PURCHASES_GROUPING;

    // ANALYTICS_GROUPING[0].items = [
      // TRADE_DASHBOARD_ITEM,
      // ACCOUNTING_DASHBOARD_ITEM,
      // WAREHOUSE_DASHBOARD_ITEM
    // ];

    ACCOUNTING_MENU_ITEMS.children = [
      OPEN_ITEMS_LIST_ITEM,
      OIN_ITEM,
      IIN_ITEM,
      PMNT_ITEM,
      CRN_ITEM,
      REPORTING_PERIODS_ITEM,
      SCHEDULED_PAYMENTS_ITEM,
      BANK_ACCOUNTS_ITEM,
      ACCOUNTING_SETTINGS_ITEM
    ];
  }

  if (salesChannel === SalesChannelEnum.SERVICES) {
    SALES_MENU_ITEMS.children = SALES_GROUPING_SERVICES;

    PURCHASE_MENU_ITEMS.children = PURCHASES_GROUPING_SERVICES;

    // ANALYTICS_GROUPING[0].items = [
    //   ACCOUNTING_DASHBOARD_ITEM,
    // ];

    ACCOUNTING_MENU_ITEMS.children = [
      OPEN_ITEMS_LIST_ITEM,
      OIN_ITEM,
      IIN_ITEM,
      PMNT_ITEM,
      CRN_ITEM,
      REPORTING_PERIODS_ITEM,
      SCHEDULED_PAYMENTS_ITEM,
      BANK_ACCOUNTS_ITEM,
      ACCOUNTING_SETTINGS_ITEM
    ];
  }

  if (salesChannel === SalesChannelEnum.E_COMMERCE) {
    SALES_MENU_ITEMS.children = [
      SALES_ORDERS_ITEM,
      OUTGOING_DELIVERIES_ITEM,
      OIN_ITEM,
      SALES_PRICES_ITEM,
      STOCK_STATUS_ITEM,
      ERA_ITEM
    ];

    PURCHASE_MENU_ITEMS.children = [
      PURCHASE_ORDERS_ITEM,
      INCOMING_DELIVERIES_ITEM,
      IIN_ITEM,
      PURCHASE_PRICES_ITEM,
      STOCK_STATUS_ITEM
    ];

    ACCOUNTING_MENU_ITEMS.children = [
      OPEN_ITEMS_LIST_ITEM,
      OIN_ITEM,
      IIN_ITEM,
      PMNT_ITEM,
      CRN_ITEM,
      REPORTING_PERIODS_ITEM,
      SCHEDULED_PAYMENTS_ITEM,
      BANK_ACCOUNTS_ITEM,
      ACCOUNTING_SETTINGS_ITEM
    ];
  }

  if (salesChannel === SalesChannelEnum.ALL_CHANNEL) {
    SALES_MENU_ITEMS.children = SALES_GROUPING;

    PURCHASE_MENU_ITEMS.children = PURCHASES_GROUPING;

    // ANALYTICS_GROUPING[0].items = [
      // TRADE_DASHBOARD_ITEM,
      // ACCOUNTING_DASHBOARD_ITEM,
      // WAREHOUSE_DASHBOARD_ITEM
    // ];

    ACCOUNTING_MENU_ITEMS.children = [
      OPEN_ITEMS_LIST_ITEM,
      OIN_ITEM,
      IIN_ITEM,
      PMNT_ITEM,
      CRN_ITEM,
      REPORTING_PERIODS_ITEM,
      SCHEDULED_PAYMENTS_ITEM,
      BANK_ACCOUNTS_ITEM,
      ACCOUNTING_SETTINGS_ITEM
    ];
  }

  // ANALYTICS_MENU_ITEMS.children = ANALYTICS_GROUPING;
  MANAGEMENT_MENU_ITEMS.children = MANAGEMENT_GROUPING;

  return [
    SALES_MENU_ITEMS,
    PURCHASE_MENU_ITEMS,
    ACCOUNTING_MENU_ITEMS,
    // ANALYTICS_MENU_ITEMS,
    MANAGEMENT_MENU_ITEMS
  ];
}

