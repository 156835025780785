import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PositionAccountingSettings } from '../../../outgoing-invoice/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { FundsWriteOffEnum } from '../../../fixed-assets/enums';

@Component({
  selector: 'rnpl-position-accounting-settings',
  templateUrl: './position-accounting-settings.component.html',
})
export class PositionAccountingSettingsComponent implements OnChanges, OnDestroy {

  public form: FormGroup;
  public fundsWriteOffEnum = FundsWriteOffEnum;

  @Input() idKey: string = 'position';
  @Input() displayRevenue: boolean = false;
  @Input() optionalTaxCode: boolean = false;
  @Input() optionalGlDebit: boolean = false;
  @Input() isReadonly: boolean = false;
  @Input() displayAssets: boolean = true;
  @Input() disableAssets: boolean = false;
  @Input() displayProductTypeId: boolean = false;
  @Input() displayLongTermPeriodOfUsed: boolean = false;
  @Input() isInternalUsage: boolean = true;
  @Input() openedPanel: boolean = false;
  @Input() privateUsage: boolean = false;
  @Input() addValidations: boolean = false;
  @Input() accountingSettings: PositionAccountingSettings;
  @Input() productType: ProductTypes;
  @Input() updateAccountingSettingsOnProductTypeChange: boolean = false;

  @Output() fieldChanged: EventEmitter<{ fieldName: string, fieldValue: any }> = new EventEmitter<{fieldName: string; fieldValue: any}>();
  @Output() togglePanelEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(private readonly fb: FormBuilder) {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.hasOwnProperty('accountingSettings') && this.accountingSettings) {
      this.form.patchValue(this.accountingSettings);

      if (this.accountingSettings.hasOwnProperty('usedUp')) {
        this.usedUpControl.patchValue(this.accountingSettings.usedUp);
      } else if (this.accountingSettings.hasOwnProperty('usedUpByDefault')) {
        this.usedUpControl.patchValue(this.accountingSettings.usedUpByDefault);
      }

      if (this.disableAssets) {
        this.usedUpControl.disable();
        this.lowValueAssetControl.disable();
        // this.periodOfUseControl.disable();
      }

      this.setFormState();
    }

    if (changes && changes.hasOwnProperty('isReadonly')) {
      this.setFormState();
    }

    if (changes && changes.hasOwnProperty('addValidations') && this.form) {
      this.fundsWriteOffMethodControl.setValidators(Validators.required);
      this.periodOfUseControl.setValidators([Validators.required, Validators.min(1.01)]);
      this.fundsWriteOffRateControl.setValidators([Validators.required, Validators.min(0.01), Validators.max(99.99)]);
    }
  }

  public setFormState(): void {
    if (this.isReadonly) {
      this.form.disable();
    } else {
      this.form.enable();

      if (this.disableFormDueToProductTypeId) {
        this.form.disable();
        this.productTypeIdControl.enable();
      }
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      taxCode: [],
      glAccountIdPurchaseDebit: [],
      glAccountIdPurchaseCredit: [],
      glAccountRevenue: [],
      usedUp: [],
      lowValueAsset: [],
      // privateUsage: [],
      productTypeId: [],
      longTermPeriodOfUse: [],
      fundsWriteOffMethod: ['LINEAR'],
      periodOfUse: [2],
      fundsWriteOffRate: [null],
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (!this.isReadonly && !this.disableAssets) {
          this.longTermPeriodOfUseControl.enable({emitEvent: false});
          if (!this.disableFormDueToProductTypeId) {
            this.usedUpControl.enable({emitEvent: false});
          }
          // this.periodOfUseControl.enable({emitEvent: false});
        }

        if (value.longTermPeriodOfUse) {
          this.usedUpControl.disable({emitEvent: false});
        }
        if (value.usedUp || (value.lowValueAsset && this.productType === ProductTypes.GOODS)) {
          // this.periodOfUseControl.disable({emitEvent: false});
          this.longTermPeriodOfUseControl.disable({emitEvent: false});
        }
      });
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldValue, fieldName});
  }

  public productTypeUpdated(product): void {
    if (this.updateAccountingSettingsOnProductTypeChange) {
      this.accountingSettings.productTypeCategoryName = product.category;
      this.accountingSettings.productTypeName = product.name;
    }
  }

  public togglePanel(isOpen: boolean): void {
    this.togglePanelEmit.emit(isOpen);
  }

  // public increaseQuantity(): void {
  //   this.periodOfUseControl.patchValue((this.periodOfUseControl.value || 0) + 1);
  //   this.updateField(this.periodOfUseControl.value, 'accountingSettings.periodOfUse');
  // }
  //
  // public decreaseQuantity(): void {
  //   if (!this.periodOfUseControl.value) { return; }
  //   this.periodOfUseControl.patchValue(this.periodOfUseControl.value - 1);
  //   this.updateField(this.periodOfUseControl.value, 'accountingSettings.periodOfUse');
  // }

  public getFormValue(): PositionAccountingSettings {
    return this.form.getRawValue();
    // return {
    //   ...this.form.getRawValue(),
    //   periodOfUse: this.periodOfUseControl.value ? 2 : 1
    // };
  }

  public resetForm(): void {
    this.form.reset();
    this.fundsWriteOffMethodControl.patchValue('LINEAR');
    this.periodOfUseControl.patchValue(2);
  }

  get showUsedUp(): boolean {
    if (!this.accountingSettings || !this.accountingSettings.hasOwnProperty('usedUpAllowed')) {
      return true;
    }

    return this.accountingSettings.usedUpAllowed;
  }

  get disableFormDueToProductTypeId(): boolean {
    return this.displayProductTypeId
      && !this.accountingSettings.productTypeId
      && !this.productTypeIdControl.value
  }

  get taxCodeControl(): FormControl { return this.form.get('taxCode') as FormControl; }
  get glAccountIdPurchaseDebitControl(): FormControl { return this.form.get('glAccountIdPurchaseDebit') as FormControl; }
  get glAccountIdPurchaseCreditControl(): FormControl { return this.form.get('glAccountIdPurchaseCredit') as FormControl; }
  get glAccountRevenueControl(): FormControl { return this.form.get('glAccountRevenue') as FormControl; }
  get usedUpControl(): FormControl { return this.form.get('usedUp') as FormControl; }
  // get privateUsageControl(): FormControl { return this.form.get('privateUsage') as FormControl; }
  get lowValueAssetControl(): FormControl { return this.form.get('lowValueAsset') as FormControl; }
  get productTypeIdControl(): FormControl { return this.form.get('productTypeId') as FormControl; }
  get longTermPeriodOfUseControl(): FormControl { return this.form.get('longTermPeriodOfUse') as FormControl; }
  get fundsWriteOffMethodControl(): FormControl { return this.form.get('fundsWriteOffMethod') as FormControl; }
  get fundsWriteOffRateControl(): FormControl { return this.form.get('fundsWriteOffRate') as FormControl; }
  get periodOfUseControl(): FormControl { return this.form.get('periodOfUse') as FormControl; }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
