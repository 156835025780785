import { createSelector } from '@ngrx/store';

import { TradeOfferBlocksValidationsState, TradeOfferFeatureKey, TradeOfferState, TradeOfferStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[TradeOfferFeatureKey]);

export const selectTradeOfferList = createSelector(
  selectFeature,
  (state: TradeOfferStore) => state.list
);

export const selectTradeOfferEntity = createSelector(
  selectFeature,
  (state: TradeOfferStore) => state.entity
);

export const selectTradeOfferBlocksValidations = createSelector(
  selectFeature,
  (state: TradeOfferStore) => state.blocksValidations
);

export const selectTradeOffer = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.tradeOffer
);

export const selectCountries = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.countries
);

export const selectTradeOfferState = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.currentState
);

export const selectTradeOfferPositions = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.positions
);

export const selectTradeOfferPositionsCount = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.positionsCount
);

export const loadingRequestsCount = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.loadingRequestsCount
);

export const tradeOfferUpdatedAt = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectTradeOfferEntity,
  (state: TradeOfferState) => state.isShouldRefresh
);

export const selectTradeOfferPropertiesBlockValid = createSelector(
  selectTradeOfferBlocksValidations,
  (state: TradeOfferBlocksValidationsState) => state.propertiesBlockValid
);

export const selectTradeOfferDeliveryInfoBlockValid = createSelector(
  selectTradeOfferBlocksValidations,
  (state: TradeOfferBlocksValidationsState) => state.deliveryInfoBlockValid
);

export const selectTradeOfferBillingInfoBlockValid = createSelector(
  selectTradeOfferBlocksValidations,
  (state: TradeOfferBlocksValidationsState) => state.billingInfoBlockValid
);

export const selectTradeOfferPositionsBlockValid = createSelector(
  selectTradeOfferBlocksValidations,
  (state: TradeOfferBlocksValidationsState) => state.positionsBlockValid
);
