import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from '../../store/state/app.state';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { ProductUnitModel, ResponseModel } from '../models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { DisplayToaster } from '../decorators/toaster';

@Injectable({providedIn: 'root'})
export class ProductUnitApiService {

  public unitsList: {[key in ProductTypes]?: BehaviorSubject<ProductUnitModel[]>} = {
    [ProductTypes.GOODS]: new BehaviorSubject<ProductUnitModel[]>([]),
    [ProductTypes.SERVICES]: new BehaviorSubject<ProductUnitModel[]>([]),
    [ProductTypes.DIGITAL]: new BehaviorSubject<ProductUnitModel[]>([]),
  };

  private wid: number;
  private apiEndpoint: string = '/products/settings';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => this.wid = profile.workspaceId);
  }

  public getUnits$(productType: ProductTypes): Observable<ProductUnitModel[]> {
    if (!this.unitsList[productType]) { return of([]); }

    if (this.unitsList[productType].getValue().length) {
      return this.unitsList[productType];
    } else {
      return this.getProductUnitsList(productType)
        .pipe(map((list: ProductUnitModel[]) => list.filter((itm: ProductUnitModel) => !itm.hidden)));
    }
  }

  public getProductUnitsList(productType: ProductTypes): Observable<ProductUnitModel[]> {
    return this.http.get<ResponseModel<ProductUnitModel[]>>(this.apiUrl(`/${productType}/product-unit/list`))
      .pipe(
        tap((response: ResponseModel<ProductUnitModel[]>) => {
          this.unitsList[productType].next(response.data.filter((itm: ProductUnitModel) => !itm.hidden));
        }),
        map((response: ResponseModel<ProductUnitModel[]>) => response.data)
      );
  }

  // public getProductUnit(productType: ProductTypes, name: string): Observable<ProductUnitModel> {
  //   const params = {
  //     product_type: productType as string,
  //     workspace_id: this.wid as any,
  //     name,
  //   };
  //   return this.http.get<ResponseModel<ProductUnitModel>>(this.apiUrl(`/${productType}/product-unit`), { params })
  //     .pipe(map((response: ResponseModel<ProductUnitModel>) => response.data));
  // }

  @DisplayToaster({showErrorMessage: true})
  public createProductUnit(productType: ProductTypes, name: string, allowFractionalValues: boolean): Observable<ProductUnitModel> {
    const body = { name, allowFractionalValues };
    return this.http.post<ResponseModel<ProductUnitModel>>(this.apiUrl(`/${productType}/product-unit`), body)
      .pipe(map((response: ResponseModel<ProductUnitModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public reorderProductUnits(productType: ProductTypes, ids: number[]): Observable<any> {
    const body = { ids };
    return this.http.post(this.apiUrl(`/${productType}/product-unit/reorder`), body);
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteProductUnit(productType: ProductTypes, unitId: number): Observable<any> {
    return this.http.delete<ResponseModel<any>>(this.apiUrl(`/${productType}/product-unit/${unitId}`))
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateProductUnit(
    productType: ProductTypes,
    unitId: number,
    name: string,
    allowFractionalValues: boolean,
  ): Observable<ProductUnitModel> {
    const body = { name, allowFractionalValues };
    return this.http.patch<ResponseModel<ProductUnitModel>>(this.apiUrl(`/${productType}/product-unit/${unitId}`), body)
      .pipe(map((response: ResponseModel<ProductUnitModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateProductUnitVisibility(
    productType: ProductTypes,
    unitId: number,
    hidden: boolean,
  ): Observable<ProductUnitModel> {
    const params = {
      hidden: hidden as any
    };
    return this.http.request<ResponseModel<ProductUnitModel>>(
      'post',
      this.apiUrl(`/${productType}/product-unit/${unitId}/visibility`), { params }
    )
      .pipe(map((response: ResponseModel<ProductUnitModel>) => response.data));
  }

}
