import { createSelector } from '@ngrx/store';

import { EcoOrderFeatureKey, EcoOrderState, EcoOrderStore } from '../reducers';
import { AppState } from '../../../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[EcoOrderFeatureKey]);

export const selectEcoOrderEntity = createSelector(
  selectFeature,
  (state: EcoOrderStore) => state.entity
);

export const selectEcoOrderList = createSelector(
  selectFeature,
  (state: EcoOrderStore) => state.list
);

// export const selectSalesOrderBlocksValidations = createSelector(
//   selectFeature,
//   (state: EcoOrderStore) => state.blocksValidations
// );

export const selectEcoOrder = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.ecoOrder
);

export const selectEcoOrderPositions = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.positions
);

export const selectSalesOrderPositionsCount = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.positionsCount
);

export const selectPartners = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.partners
);

export const selectEcoOrderCurrentState = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.currentState
);

export const loadingRequestsCount = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.loadingRequestsCount
);

export const salesOrderUpdatedAt = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectEcoOrderEntity,
  (state: EcoOrderState) => state.isShouldRefresh
);

// export const selectSalesOrderPropertiesBlockValid = createSelector(
//   selectSalesOrderBlocksValidations,
//   (state: SalesOrderBlocksValidationsState) => state.propertiesBlockValid
// );
//
// export const selectSalesOrderDeliveryInfoBlockValid = createSelector(
//   selectSalesOrderBlocksValidations,
//   (state: SalesOrderBlocksValidationsState) => state.deliveryInfoBlockValid
// );
//
// export const selectSalesOrderBillingInfoBlockValid = createSelector(
//   selectSalesOrderBlocksValidations,
//   (state: SalesOrderBlocksValidationsState) => state.billingInfoBlockValid
// );
