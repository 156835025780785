import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { WarehouseMainDropdownItem } from 'projects/workspace/src/app/warehouse/interfaces/warehouse-main-dropdown-item';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { NavigationItemModel } from 'common/src/models';

@Component({
  selector: 'rnpl-warehouse-menu',
  templateUrl: './warehouse-menu.component.html',
  styleUrls: ['./warehouse-menu.component.scss'],
})
export class WarehouseMenuComponent implements OnChanges, OnInit, OnDestroy {

  @Input() readonly selected: WarehouseMainDropdownItem;

  public displayWarehouseName: string;
  public displayWarehouseNumber: string | number;
  public warehouseNavItems: NavigationItemModel[];

  private activeLink: string;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private readonly router: Router) {}

  ngOnInit(): void {
    this.activeLink = this.router.routerState.snapshot.url;
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((data: NavigationEnd) => (this.activeLink = data.url));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selected') && changes.selected.currentValue) {
      this.selectWarehouse(changes.selected.currentValue);
    }
  }

  public selectWarehouse(item): void {
    if (item.runple_id !== this.displayWarehouseNumber) {
      this.displayWarehouseName = item.label;
      this.displayWarehouseNumber = item.runple_id;
      this.warehouseNavItems = this.getWarehouseNavItems(item.id);
    }
  }

  public routNavigate(item): void {
    if (!item.disabled) {
      this.router.navigate([item.routerLink]);
    }
  }

  public isActiveClass(item): boolean {
    if (!item.routerLink) {
      return;
    }
    const baseLink = item.routerLink.split('/', 3).join('/');
    return this.activeLink.includes(baseLink);
  }

  private getWarehouseNavItems(id: number): NavigationItemModel[] {
    return [
      {
        title: 'Stock status',
        icon: 'cubes',
        routerLink: '/warehouse/stock-status/general-stock/1',
      },
      {
        title: 'Management',
        icon: 'warehouse',
        routerLink: `/warehouse/warehouse-profile/${id}/general-info`,
      },
    ];
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
