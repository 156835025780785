import { PoTabs } from './enums';
import { UIStatesEnum } from 'common/src/models';

export function purchasesOrderHints(
  currentState?: UIStatesEnum,
  activeStatus?: PoTabs,
  hasPo?: boolean,
  displayFieldValidHint?: boolean,
  displayBatchesHint?: boolean,
  displayInvoiceHint?: boolean,
  displayProductReceiptBtnHint?: boolean,
  hideDeliveryAction?: boolean,
  hideBillingAction?: boolean,
  displayVatIndicator?: boolean,
  receiptAndWarehouse?: boolean,
) {
  const isReadOnly = currentState === UIStatesEnum.VIEW;
  const isEditState = currentState === UIStatesEnum.EDIT;
  const isCreateState = currentState === UIStatesEnum.CREATE;

  const isDraftStatus = activeStatus === PoTabs.Draft;
  const isOpenStatus = activeStatus === PoTabs.Open;
  const isCompletedStatus = activeStatus === PoTabs.Completed;
  const isCanceledStatus = activeStatus === PoTabs.Canceled;
  const isDeletedStatus = activeStatus === PoTabs.Deleted;

  const hintsList = {
    ['purchase_orders_view_draft_3']: {
      text: 'MEGA_HINT.PO.HINT_2',
      attachTo: {
        element: '#is_delivery_cost',
        on: 'bottom'
      },
    },
    ['purchase_orders_warehousing_1']: {
      text: 'MEGA_HINT.PO.HINT_5',
      attachTo: {
        element: '#orderedColumnModal',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_2']: {
      text: 'MEGA_HINT.PO.HINT_6',
      attachTo: {
        element: '#confirmAllGoods',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_3']: {
      text: 'MEGA_HINT.PO.HINT_7',
      attachTo: {
        element: '#batchColumnModal',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_4']: {
      text: 'MEGA_HINT.PO.HINT_8',
      attachTo: {
        element: '#expirationDateColumnModal',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_5']: {
      text: 'MEGA_HINT.PO.HINT_9',
      attachTo: {
        element: '#deliveredColumnModal',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_6']: {
      text: 'MEGA_HINT.PO.HINT_10',
      attachTo: {
        element: '#receiptWarehouseToggle',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_return_2']: {
      text: 'MEGA_HINT.PO.HINT_14',
      attachTo: {
        element: '#warehouseColumnModal',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['purchase_orders_warehousing_return_1']: {
      text: 'MEGA_HINT.PO.HINT_15',
      attachTo: {
        element: '#confirmAllGoods',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['create_entity_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_19',
      attachTo: {
        element: '#createPoBtn',
        on: 'bottom'
      },
    },
    ['filter_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_20',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
    ['reverse_charge_toggle_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_21',
      attachTo: {
        element: '#vatDisabled',
        on: 'bottom'
      },
    },
    ['linked_document_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_25',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
    },
  }

  const binLocationColumnHint = {
    ['purchase_orders_warehousing_7']: {
      text: 'MEGA_HINT.PO.HINT_11',
      attachTo: {
        element: '#binLocationModalsColumn',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const editBtnHint = {
    ['edit_entity_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_24',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
  }

  const billingActionHint = {
    ['purchase_orders_view_open_3']: {
      text: 'MEGA_HINT.PO.HINT_4',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const cloneBtn = {
    ['clone_entity_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_23',
      attachTo: {
        element: '#cloneBtn',
        on: 'bottom'
      },
    },
  }

  const productReceiptBtnHint = {
    ['purchase_orders_view_open_2']: {
      text: 'MEGA_HINT.PO.HINT_3',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const openViewStateHints = {
    ['cancel_entity_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_22',
      attachTo: {
        element: '#cancelBtn',
        on: 'bottom'
      },
    },
  }

  const invoiceHInt = {
    ['linked_document_invoice_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_30',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, !hideBillingAction ? -50 : 0] }}]
      },
    },
  }

  const batchesHInt = {
    ['purchase_orders_view_open_4']: {
      text: 'MEGA_HINT.PO.HINT_12',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{
            name: 'offset',
            options: {
              offset : [0, (!hideDeliveryAction) ? -50 : 0]
            }
          }]
      },
    },
  }

  const exportListBtnHInt = {
    ['export_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_18',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const stepDocPoHint = {
    ['purchase_orders_view_open_1']: {
      text: 'MEGA_HINT.PO.HINT_33',
      attachTo: {
        element: '#currentStep',
        on: 'left'
      },
    },
  }

  const sideBarHints = {
    ['sidebar_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_36',
      attachTo: {
        element: '#sidebarPo',
        on: 'left'
      },
    },
  }

  const editStateHints = {
    ['create_positions_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_29',
      attachTo: {
        element: '#addPositionBtn',
        on: 'bottom'
      },
    },
    ['save_timer_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_28',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const doneBtn = {
    ['done_button_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_34',
      attachTo: {
        element: '#doneBtn',
        on: 'bottom'
      },
    },
  }

  const vatIndicatorHint = {
    ['vat_indicator_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_35',
      attachTo: {
        element: '#vatCheckedField',
        on: 'bottom'
      },
    },
  }

  const fieldValidHint = {
    ['field_validation_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_27',
      attachTo: {
        element: '#selectCustomer',
        on: 'bottom'
      },
    },
  }

  const openTabHint = {
    ['purchase_orders_tab_open_1']: {
      text: 'MEGA_HINT.PO.HINT_31',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
  }

  const completedTabHint = {
    ['purchase_orders_tab_completed_1']: {
      text: 'MEGA_HINT.PO.HINT_32',
      attachTo: {
        element: '#completedTab',
        on: 'bottom'
      },
    },
  }

  const canceledTab = {
    ['purchase_orders_tab_canceled_1']: {
      text: 'MEGA_HINT.PO.HINT_1',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
    },
  }

  const deletedTabHint = {
    ['tab_deleted_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_17',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  const draftTabHint = {
    ['tab_draft_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_16',
      attachTo: {
        element: '#draftTab',
        on: 'bottom'
      },
      classes: 'ml-8'
    },
  }

  const tableSummaryHint = {
    ['total_row_purchase_orders']: {
      text: 'MEGA_HINT.PO.HINT_26',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }
  const returnProductBtnHint = {
    ['purchase_orders_view_open_5']: {
      text: 'MEGA_HINT.PO.HINT_13',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  if (isOpenStatus) { Object.assign(hintsList, openTabHint); }
  if (isDraftStatus) { Object.assign(hintsList, draftTabHint); }
  if (isCanceledStatus) { Object.assign(hintsList, canceledTab);}
  if (isDeletedStatus) { Object.assign(hintsList, deletedTabHint); }
  if (isCompletedStatus) { Object.assign(hintsList, completedTabHint); }

  if (isEditState) { Object.assign(hintsList, doneBtn); }
  if (hasPo) { Object.assign(hintsList, exportListBtnHInt); }
  if (!isReadOnly) { Object.assign(hintsList, editStateHints); }
  if (isCreateState || isDraftStatus) { Object.assign(hintsList, sideBarHints); }
  if (isOpenStatus && isReadOnly) { Object.assign(hintsList, openViewStateHints); }
  if (displayFieldValidHint && !isReadOnly) { Object.assign(hintsList, fieldValidHint); }
  if ((isOpenStatus || isDraftStatus) && isReadOnly) { Object.assign(hintsList, editBtnHint); }
  if (displayVatIndicator && !isCreateState && !isDraftStatus && !isDeletedStatus) { Object.assign(hintsList, vatIndicatorHint); }

  if (!isCanceledStatus && !isDeletedStatus) {
    if (isReadOnly) { Object.assign(hintsList, cloneBtn); }
    if (hasPo) { Object.assign(hintsList, tableSummaryHint); }
    if (displayBatchesHint) { Object.assign(hintsList, batchesHInt); }
    if (displayInvoiceHint) { Object.assign(hintsList, invoiceHInt); }
    if (!isCreateState && !isDraftStatus) { Object.assign(hintsList, stepDocPoHint); }
    if (!hideBillingAction && !isCreateState && !isDraftStatus) { Object.assign(hintsList, billingActionHint); }
    if (displayProductReceiptBtnHint && !hideDeliveryAction) { Object.assign(hintsList, productReceiptBtnHint); }
    if (!displayProductReceiptBtnHint && !hideDeliveryAction) { Object.assign(hintsList, returnProductBtnHint); }
  }

  if (receiptAndWarehouse) { Object.assign(hintsList, binLocationColumnHint); }

  return hintsList;
}
