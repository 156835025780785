import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { get } from 'lodash';

import { DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';

type dataType = {
  deliveryNote: DeliveryNote
};

@Component({
  selector: 'rnpl-puck-up-modal',
  templateUrl: './puck-up-modal.component.html'
})
export class PuckUpModalComponent implements OnInit {

  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PuckUpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataType
  ) {

  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      totalPackageWeight: [get(this, 'data.deliveryNote.totalPackageWeight', null)],
      totalPackageVolume: [get(this, 'data.deliveryNote.totalPackageVolume', null)],
      totalPackageWeightUnit: [get(this, 'data.deliveryNote.totalPackageWeightUnit', 'kg')],
      totalPackageVolumeUnit: [get(this, 'data.deliveryNote.totalPackageVolumeUnit', 'm3')],
    });
  }

  public puckUp(): void {
    this.dialogRef.close(this.form.getRawValue());
  }

}

