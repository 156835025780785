import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ResponseModel } from '../../shared/models/response';
import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import { RecentBankModel } from '../models/recent-bank.model';
import {
  ConnectionAccountsModel,
  InstitutionConnectionBodyModel,
  InstitutionConnectionModel,
  InstitutionModel
} from '../../bank-accounts/models';
import { PaymentModel } from '../models/payment.model';
import { AdministrationsApiService } from '../../administration/services/administrations-api.service';

@Injectable({
  providedIn: 'root',
})
export class OpenBankingApiService {
  public companyProfile: CompanyProfile;
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/open-banking`;

  constructor(
    private readonly store: Store<AppState>,
    private readonly toasterService: ToasterService,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly http: HttpClient,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  private getApiEndpointWid(): string {
    return `${environment.javaApiVersion}/workspaces/${this.companyProfile.workspaceId}/open-banking`;
  }

  @DisplayToaster({showErrorMessage: true})
  public getOpenBankingEnabled(): Observable<boolean> {
    return this.http.get<ResponseModel<boolean>>(`${this.apiEndpoint}/enabled`)
      .pipe(
        map((response: ResponseModel<boolean>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public syncRecentBanks(wid = this.companyProfile.workspaceId): Observable<RecentBankModel[]> {
    const params = {
      wid: wid.toString()
    };

    return this.http.get<ResponseModel<RecentBankModel[]>>(`${this.apiEndpoint}/recent-banks`, {params})
      .pipe(
        map((response: ResponseModel<RecentBankModel[]>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getTransactions(
    bankCode?: string,
    bankIso2?: string,
    uuid?: string,
    bankAccountId?: number,
    latest = true,
    wid = this.companyProfile.workspaceId
  ): Observable<any> {
    const params = {
      wid: wid.toString(),
      latest: latest.toString(),
    };

    if (bankCode) { params['bankCode'] = bankCode; }
    if (bankIso2) { params['bankIso2']= bankIso2; }
    if (bankAccountId) { params['bankAccountId']= bankAccountId; }
    if (uuid) { params['uuid']= uuid; }

    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/transactions`, {params})
      .pipe(
        map((response: ResponseModel<any>) => response.data),
      );
  }

  // public revokeBankConnection(code: string, wid = this.companyProfile.workspaceId): Observable<any> {
  //   const params = {
  //     wid: wid.toString(),
  //     code
  //   };
  //   return this.http.delete(`${this.apiEndpoint}/revoke`, {params});
  // }

  @DisplayToaster({showErrorMessage: true})
  public getInstitutions(): Observable<InstitutionModel[]> {
    const params = {
      countryIso3: this.companyProfile.legalAddress.country_iso3,
    };
    return this.http.get<ResponseModel<InstitutionModel[]>>(`${this.apiEndpoint}/institutions`, {params})
      .pipe(
        map((response: ResponseModel<InstitutionModel[]>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getInstitutionConnections(): Observable<Partial<InstitutionConnectionModel>[]> {
    return this.http.get<ResponseModel<Partial<InstitutionConnectionModel>[]>>(`${this.getApiEndpointWid()}/connection`)
      .pipe(
        map((response: ResponseModel<Partial<InstitutionConnectionModel>[]>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getInstitutionConnectionDetails(reference: string): Observable<InstitutionConnectionModel> {
    return this.http.request<ResponseModel<InstitutionConnectionModel>>('post', `${this.getApiEndpointWid()}/connection/${reference}`)
      .pipe(
        map((response: ResponseModel<InstitutionConnectionModel>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public postOpenBankingConnectionComplete(body: InstitutionConnectionBodyModel, reference: string): Observable<any> {
    return this.http.post<ResponseModel<any>>(`${this.getApiEndpointWid()}/connection/${reference}/complete`, body)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((response: ResponseModel<any>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public postOpenBankingConnection(body: {institutionId: string, redirectURL: string, maxHistoricalDays?: number}): Observable<ConnectionAccountsModel> {
    body.maxHistoricalDays = body.maxHistoricalDays || 90;
    return this.http.post<ResponseModel<ConnectionAccountsModel>>(`${this.getApiEndpointWid()}/connection`, body)
      .pipe(
        map((response: ResponseModel<ConnectionAccountsModel>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public revokeOpenBankingConnection(
    queryParams: { institutionId?: string; reference?: string; requisitionId?: string }
  ): Observable<any> {
    let params = new HttpParams();

    if (queryParams.institutionId) { params = params.set('institutionId', queryParams.institutionId); }
    if (queryParams.requisitionId) { params = params.set('requisitionId', queryParams.requisitionId); }
    if (queryParams.reference) { params = params.set('reference', queryParams.reference); }

    return this.http.request<ResponseModel<any>>('delete', `${this.getApiEndpointWid()}/connection`, { params })
      .pipe(
        map((response: ResponseModel<any>) => response.data),
      );
  }

  public logOpenBankingConnectionError(
    body: { ref?: string; error?: string, redirectUrl?: string }
  ): Observable<any> {
    return this.http.post<ResponseModel<any>>(`${this.getApiEndpointWid()}/connection/log-error`, body);
  }

  @DisplayToaster({showErrorMessage: true})
  public getOpenBankingTransactions(
    queryParams: { institutionId?: string; dateFrom?: string; dateTo?: string }
  ): Observable<PaymentModel[]> {
    let params = new HttpParams();

    if (queryParams.institutionId) { params = params.set('institutionId', queryParams.institutionId); }
    if (queryParams.dateFrom) { params = params.set('dateFrom', queryParams.dateFrom); }
    if (queryParams.dateTo) { params = params.set('dateTo', queryParams.dateTo); }

    return this.http.post<ResponseModel<PaymentModel[]>>(`${this.getApiEndpointWid()}/transactions`, {}, {params})
      .pipe(
        map((response: ResponseModel<PaymentModel[]>) => response.data),
      );
  }

}
