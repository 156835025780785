import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../models/table-column.model';
import { TranslateService } from '@ngx-translate/core';

export function getOpenGoodsColumns(
  confirmAllGoods = false,
  internalUsage = false,
  warehousing: boolean,
): TableColumnModelExtended[] {

  const cols: TableColumnModelExtended[] = [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 132,
      maxWidth: 132,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PRODUCT_NAME',
      preventTranslate: true,
      cellClass: 'link-primary inline',
      prop: 'productNameLink',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      sortable: true,
      type: ColumnTypeEnum.TEXT,
      width: 250,
      maxWidth: 250
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      columnId: 'orderedColumnModal',
      name: 'COLUMN.ORDERED',
      prop: 'ordered',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 98,
      maxWidth: 98,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      columnId: 'deliveredColumnModal',
      name: 'COLUMN.DELIVERED',
      prop: 'delivered',
      sortable: true,
      type: ColumnTypeEnum.STEPPER,
      decimal: 0,
      disabled: confirmAllGoods,
      width: 172,
      maxWidth: 172,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      columnId: 'batchColumnModal',
      name: 'COLUMN.BATCH',
      prop: 'batch',
      sortable: true,
      resizeable: false,
      // disabled: confirmAllGoods,
      type: ColumnTypeEnum.TEXT,
      width: 160,
      maxWidth: 160,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      columnId: 'expirationDateColumnModal',
      name: 'COLUMN.EXPIRATION_DATE',
      prop: 'expirationDate',
      sortable: true,
      resizeable: false,
      // disabled: confirmAllGoods,
      width: 288,
      maxWidth: 288,
      type: ColumnTypeEnum.DATE,
    },
  ];

  if (warehousing && !internalUsage) {
    cols.push({
      cellTemplate: 'withDropdown',
      headerTemplate: 'headerPrimary',
      columnId: 'binLocationModalsColumn',
      name: 'COLUMN.BIN_LOCATION',
      prop: 'binLocationId',
      rowListPropertyName: 'binLocations',
      sortable: true,
      resizeable: false,
      bindLabel: 'binLocation',
      bindValue: 'id',
      // disabled: confirmAllGoods,
      width: 140,
      maxWidth: 140,
    });
  }

  return cols;
}
