import {
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostBinding, Input, OnDestroy,
  OnInit,
  Optional,
  QueryList
} from '@angular/core';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';


import { ComposerContainerComponent } from '../composer-container/composer-container.component';
import { CellDataModel, CellSize, ComposerCell, ComposerPlaceholder } from '../../interfaces';
import { digitalSize } from '../../helpers';


@Component({
  selector: 'rnpl-composer-row',
  templateUrl: './composer-row.component.html',
  styleUrls: ['./composer-row.component.scss']
})
export class ComposerRowComponent implements OnInit, OnDestroy {

  @Input()
  dataRow: Array<CellDataModel<any>> = [];

  @Input()
  index: number;

  @ContentChildren(forwardRef(() => ComposerCell))
  set cells(cells: QueryList<ComposerCell>) {
    this._cells = cells;

    this.isEmpty = !this._cells || !this._cells.length;
  }

  @ContentChildren(ComposerPlaceholder)
  placeholders: QueryList<ComposerPlaceholder>;

  @HostBinding('class.empty')
  public isEmpty: boolean = false;

  @HostBinding('class.target')
  private isTarget: boolean = false;

  private _cells: QueryList<ComposerCell>;

  private rect: DOMRect;

  private subscriptions: Array<Subscription> = [];

  constructor(@Optional() private container: ComposerContainerComponent,
              private hostElement: ElementRef) {

  }

  ngOnInit(): void {
    if (!this.container) {
      return;
    }

    this.subscriptions.push(
      this.container.dragStarted
        .subscribe(event => {
          this.prepareRow(event.dragItemSize);
        })
    );

    this.subscriptions.push(
      this.container.dragEnded
        .subscribe(() => this.reset())
    );

    this.subscriptions.push(
      this.container.dragMoved
        .subscribe(event => this.handleMove(event))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Prepares row when user starts dragging
   *
   * @param dragSize size of current dragging item
   */
  private prepareRow(dragSize: CellSize): void {
    this.rect = this.hostElement.nativeElement.getBoundingClientRect();

    if (this.isEmpty) {
      return;
    }

    const busyWidth = this._cells.reduce((filledWidth, cell) => {
      return !cell.isEmpty ? filledWidth + cell.digitalSize : filledWidth;
    }, 0);

    if (busyWidth + digitalSize(dragSize) > 1) {
      this.placeholders
        .forEach(placeholder => placeholder.disabled = true);
    } else {
      const emptyIndex = this._cells.toArray().findIndex(cell => cell.isEmpty);
      if (emptyIndex > -1) {
        this.placeholders.toArray()[emptyIndex].disabled = true;
        this.placeholders.toArray()[emptyIndex + 1].disabled = true;
      }
    }
  }

  /**
   * Handles drag item moving inside row
   *
   * @param event Cdk drag move event
   */
  private handleMove(event: CdkDragMove): void {
    if (event.pointerPosition.y < this.rect.top || event.pointerPosition.y > this.rect.bottom) {
      this.isTarget = false;
      this.placeholders.forEach(placeholder => this.container.removePlaceholder(placeholder));
      return;
    }
    this.isTarget = true;

    const availablePlaceholders = this.placeholders
      .filter(placeholder => !placeholder.disabled);

    if (event.pointerPosition.y < this.rect.top + 8 || event.pointerPosition.y > this.rect.bottom - 8) {
      this.placeholders.forEach(placeholder => this.container.removePlaceholder(placeholder));
      return;
    }

    const distances = availablePlaceholders
      .map(placeholder => {
        this.container.removePlaceholder(placeholder);
        return Math.abs(placeholder.rect.left - event.pointerPosition.x);
      });

    const minDistance = Math.min(...distances);
    const nearestIndex = distances.indexOf(minDistance);
    if (nearestIndex > -1 && minDistance < 128) {
      this.container.setPlaceholder(availablePlaceholders[nearestIndex]);
    }
  }

  /**
   * Resets row when dragging is ended
   */
  private reset(): void {
    this.isTarget = false;

    this.placeholders
      .forEach(placeholder => {
        placeholder.disabled = false;
        this.container.removePlaceholder(placeholder);
      });
  }
}
