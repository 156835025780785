import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'rnpl-input',
  templateUrl: './collectivefield.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectivefieldComponent extends BaseControlComponent implements OnInit {
  placeholder: string = null;

  @Input() private attributes: string[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.placeholder = this.attributes.length
      ? this.attributes.join(' ')
      : `Enter ${this.label}`;

    this.disabled = this.control.disabled || false;
  }

  public change(event): void {
    this.control.setValue(event.target.value);
    this.control.markAsDirty();
  }
}
