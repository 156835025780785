import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProductTypes } from 'common/src/modules/products/product-types';

@Component({
  selector: 'rnpl-positions-navigate-buttons',
  templateUrl: './positions-navigate-buttons.component.html',
  styleUrls: ['./positions-navigate-buttons.component.css']
})
export class PositionsNavigateButtonsComponent implements OnChanges {

  public contactPositionsArray: any[] = [];
  public positionsNavigateIndex: number;

  @Input() public position: any;
  @Input() public positions: any[];
  @Input() public positionsByProductType: {[key in ProductTypes]?: any[]} = {};
  @Input() isSinglePositionsType: boolean = false;

  @Output() prevPositionEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() nextPositionEmit: EventEmitter<any> = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.hasOwnProperty('positionsByProductType') || changes.hasOwnProperty('positions'))) {
      this.getConcatPositionsArray(this.positionsByProductType);
      this.getPositionNavigateIndex();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // default behaviour if focus on input/textarea
    if ((event.target as HTMLElement).classList.contains('rnpl-form-control')) { return; }

    if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.nextPosition();
    }

    if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.prevPosition();
    }
  }

  public prevPosition(): void {
    if (this.positionsNavigateIndex) {
      this.position = this.contactPositionsArray[this.positionsNavigateIndex - 1];
      this.positions = this.isSinglePositionsType
        ? this.contactPositionsArray
        : this.positionsByProductType[this.position.productTypeForGrouping || this.position.productType];
      this.positionsNavigateIndex--;
      this.prevPositionEmit.next({
        position: this.position,
        positions: this.positions,
      });
    }

  }
  public nextPosition(): void {
    if (this.contactPositionsArray.length > this.positionsNavigateIndex + 1) {
      this.position = this.contactPositionsArray[this.positionsNavigateIndex + 1];
      this.positions = this.isSinglePositionsType
        ? this.contactPositionsArray
        : this.positionsByProductType[this.position.productTypeForGrouping || this.position.productType];
      this.positionsNavigateIndex++;
      this.nextPositionEmit.next({
        position: this.position,
        positions: this.positions,
      });
    }
  }

  public getPositionNavigateIndex(): void {
    this.contactPositionsArray.map((pos, index) => {
      if (pos.order === this.position.order && (pos.positionId === this.position.positionId || pos.productId === this.position.productId)) {
        this.positionsNavigateIndex = index;
      }
    });
  }

  public getConcatPositionsArray(positionsByProductType: {[key in ProductTypes]?: any[]} = {}): void {
    if (this.isSinglePositionsType) {
      this.contactPositionsArray = this.positions;
    } else {
      this.contactPositionsArray = [
        ...positionsByProductType[ProductTypes.GOODS] || [],
        ...positionsByProductType[ProductTypes.SERVICES] || [],
        ...positionsByProductType[ProductTypes.DIGITAL] || [],
        ...positionsByProductType[ProductTypes.ARBITRARY] || [],
        ...positionsByProductType[ProductTypes.CORRECTION] || [],
        ...positionsByProductType[ProductTypes.OVERDUE_FEE] || [],
      ];
    }
  }
}
