export enum OcrPositionFieldTypeEnum {
  NAME = 'NAME',
  QUANTITY = 'QUANTITY',
  VAT_VALUE = 'VAT_VALUE',
  VAT_PERCENT = 'VAT_PERCENT',
  NET_UNIT_PRICE = 'NET_UNIT_PRICE',
  GROSS_UNIT_PRICE = 'GROSS_UNIT_PRICE',
  NET_TOTAL_PRICE = 'NET_TOTAL_PRICE',
  GROSS_TOTAL_PRICE = 'GROSS_TOTAL_PRICE',
  PROVIDED_FROM = 'PROVIDED_FROM',
  PROVIDED_TO = 'PROVIDED_TO',
  PROVIDED_FROM_TO = 'PROVIDED_FROM_TO',
  DELIVERED = 'DELIVERED',
}
