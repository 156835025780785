import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { HighlightTypeEnum } from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { PoPositionModalComponent } from 'common/src/modules/modals/modals-purchase-order';
import { EmptyStateTypeEnum } from 'common/src/modules/ui-components/empty-state/empty-state.model';
import { PositionsActionsType } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TableColumnModel } from 'common/src/models/table-column.model';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { PurchaseOrder } from '../../../models';
import { PurchaseOrderApiService } from '../../../services/purchase-order-api.service';
import { generatePositionsCardsInfo, PositionsButtons } from './positions.config';
import { selectPoCurrentState, selectPoPositions, selectPurchaseOrder } from '../../../store/selectors';
import { TradeOfferPositionsByProductType } from '../../../../trade-offer/models';
import { ResponseModel } from '../../../../shared/models';
import { PoTabs, PurposeTypeEnum } from '../../../enums';
import { UpdatePositionsBlockValid } from '../../../store/actions/po.actions';
import { AppState } from '../../../../store/state/app.state';
import { TradePositionModel } from '../../../../trade/models';
import { CORRECTION_TYPE_LABEL } from '../../../../accounting/accounting.constants';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from 'common/src/modules/modals/modals.contsans';

@Component({
  selector: 'rnpl-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionsComponent implements OnInit, OnDestroy {

  public positionsCardInfoByTypes = {
    [ProductTypes.GOODS]: [] as TableColumnModel[],
    [ProductTypes.DIGITAL]: [] as TableColumnModel[],
    [ProductTypes.CORRECTION]: [] as TableColumnModel[],
  };

  public positionsByProductType = {
    [ProductTypes.GOODS]: [],
    [ProductTypes.DIGITAL]: [],
    [ProductTypes.CORRECTION]: [],
  };

  public selectedRows = {
    [ProductTypes.GOODS]: [],
    [ProductTypes.DIGITAL]: [],
    [ProductTypes.CORRECTION]: [],
  };

  public currentState: UIStatesEnum;
  public UIStates: typeof UIStatesEnum = UIStatesEnum;
  public productTypes: typeof ProductTypes = ProductTypes;
  public purchaseOrder: PurchaseOrder;
  public actionBarBtns: NavBarBtnModel[] = PositionsButtons;
  public showUnitOnly: boolean = false;

  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly sidebarState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly sidebarData$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public emptyStateTypeEnum = EmptyStateTypeEnum;

  constructor(
    private readonly poApi: PurchaseOrderApiService,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly store: Store<AppState>,
    private readonly datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.isLoading$.next(true);

    this.trackPOChanges();
    this.trackStateChanges();
    this.trackPositionsChanges();
  }

  private trackPositionsChanges(): void {
    this.store.select(selectPoPositions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((positions: ResponseModel<TradeOfferPositionsByProductType>) => {
        if (positions) {
          for (const key in positions.data) {
            if (positions.data.hasOwnProperty(key)) {
              this.positionsByProductType[key] = positions.data[key].map(pos => {

                const negativeCorrection = key === ProductTypes.CORRECTION && pos.correctionType && pos.correctionType === 'NEGATIVE';

                pos = {
                  ...pos,
                  productLink: {
                    label: pos.productRunpleId,
                    routerLink: `/products/product-view/${pos.productId}`
                  },
                  productNameLink: {
                    label: pos.description,
                    routerLink: `/products/product-view/${pos.productId}`
                  },
                  purposeTranslate: pos.purpose === PurposeTypeEnum.INTERNAL_USAGE ?
                    this.translate.instant('CATEGORIES.INTERNAL') :
                    this.translate.instant('CATEGORIES.RESALE'),
                  currencyNegativeRow: negativeCorrection,
                  ['customHighlight']: {
                    [HighlightTypeEnum.NEGATIVE_TO_RED]: {
                      netUnitPrice: negativeCorrection,
                      vatTotal: negativeCorrection,
                      grossUnitPrice: negativeCorrection,
                    }
                  },
                  description: pos.correctionType
                    ? pos.positionName
                    : pos.description,
                  correctionTypeLabel: pos.correctionType
                    ? CORRECTION_TYPE_LABEL[pos.correctionType]
                    : null,
                };

                if (key === this.productTypes.GOODS) {
                  return {
                    ...pos,
                    openData: {
                      value: pos.open,
                      maxValue: pos.quantity,
                      inverse: true,
                      postfix: this.translate.instant('FORM.PCS'),
                      hidden: (pos.purpose === PurposeTypeEnum.INTERNAL_USAGE) || (pos.purpose === PurposeTypeEnum.RESALE && !pos.open)
                    },

                  };
                } else if (key === this.productTypes.DIGITAL) {
                  return this.addProvidedWithinToPosition(pos);
                } else {
                  return {
                    ...pos
                  };
                }
              });
            }
          }
          this.isLoading$.next(false);
          this.checkPositionsValid(positions.data);
        }
      });
  }

  private trackPOChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        this.loadPositions();
        this.getPositionsCards();
      });
  }

  private addProvidedWithinToPosition(position: TradePositionModel): TradePositionModel {
    return {
      ...position,
      providedWithin: this.getProvidedWithinValue(position),
      // openData: {
      //   value: position.open,
      //   maxValue: position.quantity,
      //   inverse: true,
      //   postfix: this.translate.instant('FORM.PCS'),
      //   hidden: this.currentState === UIStatesEnum.VIEW && (position.purpose === PurposeTypeEnum.INTERNAL_USAGE)
      // }
    };
  }

  private getProvidedWithinValue(pos: TradePositionModel): string {
    if (!pos.providedFrom && !pos.providedTo) {
      return '-'
    }

    const providedFrom: string = pos.providedFrom ? this.datePipe.transform(pos.providedFrom, 'dd.MM.yyyy') : '-';
    const providedTo: string = pos.providedTo ? this.datePipe.transform(pos.providedTo, 'dd.MM.yyyy') : '-';

    return `${providedFrom}-${providedTo}`;
  }

  private trackStateChanges(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
        this.clearSelected();
        this.getPositionsCards();
      });
  }

  private getPositionsCards(): void {
    if (this.purchaseOrder && this.currentState) {
      for (const key in this.positionsByProductType) {
        if (this.positionsByProductType.hasOwnProperty(key)) {
          this.positionsCardInfoByTypes[key] = generatePositionsCardsInfo(
            this.translate,
            key as ProductTypes,
            this.currentState,
            this.purchaseOrder.status as PoTabs,
            this.purchaseOrder.properties.vatDisabled
          );
        }
      }
    }
  }

  public onActionReceived(btnAction: string): void {
    if (this[btnAction]) {
      this[btnAction]();
    }
  }

  public onDeletePositionsClick(): void {
    const ids: number[] = [];
    for (const key in this.selectedRows) {
      if (this.selectedRows.hasOwnProperty(key)) {
        this.selectedRows[key].map((position: TradePositionModel) => ids.push(position.id));
      }
    }
    this.deletePositions(ids);
  }

  private deletePositions(ids: number[]): void {
    this.poApi.deletePurchaseOrderPositions(this.purchaseOrder.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearSelected();
        this.refreshPO();
      });
  }

  private movePosition(posId: number, moveTo: number): void {
    this.poApi.changePositionOrder(posId, moveTo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearSelected();
        this.refreshPO();
      });
  }

  public loadPositions(): void {
    this.poApi.getPurchaseOrderPositions(this.purchaseOrder.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // will be updated via module store
  }

  public createNewPosition(): void {
    const dialog = this.dialog.open(PoPositionModalComponent, {
      data: {
        purchaseOrderId: this.purchaseOrder.id,
        purchaseOrderStatus: this.purchaseOrder.status,
        vatBlocked: this.purchaseOrder.properties.vatDisabled
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.refreshPO();
      }
    });
  }

  public positionActionHandler(e): void {
    if (e.actionType === PositionsActionsType.DELETE) {
      this.deletePositions([e.row.id]);
    } else if (e.actionType === PositionsActionsType.INFO) {
      this.onOpenSidebar(e.row);
    } else {
      this.movePosition(e.row.id, e.moveTo);
    }
  }

  public refreshPO(): void {
    this.poApi.getPO(this.purchaseOrder.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.poApi.getPurchaseOrderSummary(this.purchaseOrder.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public onEditPositionTableCell(
    val: { value: string | number; cell: string; rowIndex: number, row: TradePositionModel },
    force = false
  ): void {
    // correction position soft validation
    if (val.cell === 'specifyAmountsSeparately' && !val.value && !force) {
      if (
        (val.row.netUnitPrice && (val.row.netUnitPrice !== 0)) ||
        (val.row.grossUnitPrice && (val.row.grossUnitPrice !== 0)) ||
        (val.row.vatTotal && (val.row.vatTotal !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.onEditPositionTableCell(val, true);
          } else {
            this.refreshPO();
          }
        });
        return;
      }
    }

    this.poApi
      .updatePurchasePosition(
        val.row.id,
        {
          fieldName: val.cell,
          fieldValue: val.value
        },
        this.purchaseOrder.id
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.refreshPO();

        const productType = val.row.productType;

        // find index of changed position
        const changedPositionIndex = this.positionsByProductType[productType]
          .findIndex((position: TradePositionModel) => position.id === response.data.id);
        // update changed position
        this.positionsByProductType[productType][changedPositionIndex] = response.data = {
          ...response.data,
          productLink: {
            label: response.data.productRunpleId,
            routerLink: `/products/product-view/${response.data.productId}`
          },
        };
        // reinit list
        this.positionsByProductType[productType] = [...this.positionsByProductType[productType]];
      }, () => {
        if (val.cell === 'unitType') {
          this.redrawCardsUnitTypeControl()
        }
      });
  }

  public redrawCardsUnitTypeControl(): void {
    this.showUnitOnly = true;
    setTimeout(() => this.showUnitOnly = false);
  }

  public hideSidebar() {
    this.sidebarState$.next(null);
    this.sidebarData$.next(null);
  }

  public onOpenSidebar(e: TradePositionModel): void {
    if (e.productId) {
      if (!this.sidebarState$.value) {
        this.sidebarState$.next(true);
        this.sidebarData$.next(e.productId);
      } else {
        if (e.productId !== this.sidebarData$.value) {
          this.sidebarData$.next(e.productId);
        } else {
          this.sidebarState$.next(null);
          this.sidebarData$.next(null);
        }
      }
    }
  }

  public clearSelected(): void {
    this.selectedRows = {
      [ProductTypes.GOODS]: [],
      [ProductTypes.DIGITAL]: [],
      [ProductTypes.CORRECTION]: [],
    };
  }

  public selectedPosition(positions) {
    this.selectedRows = positions;
  }

  private checkPositionsValid(positions): void {
    let validStates: boolean[] = [];

    for (const key in positions) {
      if (positions.hasOwnProperty(key)) {
        if (!!positions[key].length) {
          validStates.push(positions[key].every((pos) => pos.validPosition))
        }
      }
    }

    if (validStates.every((state) => state) && this.positionsCount > 0) {
      this.store.dispatch(UpdatePositionsBlockValid( { positionsBlockValid: true }));
    } else {
      this.store.dispatch(UpdatePositionsBlockValid( { positionsBlockValid: false }));
    }
  }

  get selectedRowsLength(): number {
    let length = 0;
    for (const key in this.selectedRows) {
      if (this.selectedRows.hasOwnProperty(key)) {
        length = length + this.selectedRows[key].length;
      }
    }
    return length;
  }

  get positionsCount(): number {
    let length = 0;
    for (const key in this.positionsByProductType) {
      if (this.positionsByProductType.hasOwnProperty(key)) {
        length = length + this.positionsByProductType[key].length;
      }
    }
    return length;
  }

  get isStatusOpen(): boolean {
    return this.purchaseOrder.status === PoTabs.Open;
  }
  get isStatusCompleted(): boolean {
    return this.purchaseOrder.status === PoTabs.Completed;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
