import { Component, EventEmitter, Input, Output } from '@angular/core';


import { CellSize } from '../../../dynamic-forms/composer/interfaces';


@Component({
  selector: 'rnpl-attribute-settings',
  templateUrl: './attribute-settings.component.html',
  styleUrls: ['./attribute-settings.component.scss']
})
export class AttributeSettingsComponent {

  @Input() currentSize: CellSize;
  @Output() sizeChanged: EventEmitter<CellSize> = new EventEmitter<CellSize>();
  @Output() removeAttrClick: EventEmitter<null> = new EventEmitter<null>();
  @Output() closed: EventEmitter<null> = new EventEmitter<null>();

  changeSize(size: CellSize): void {
    this.sizeChanged.emit(size);
  }

  close(): void {
    this.closed.emit();
  }

  removeAttr(): void {
    this.removeAttrClick.emit();
  }
}
