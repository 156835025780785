import { TableColumnModelExtended } from '../../../models/table-column.model';

export const tableColumns: TableColumnModelExtended[] = [
  {
    cellTemplate: 'numberRows',
    cellClass: 'center',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    headerClass: 'center',
  },
  {
    cellTemplate: 'withAvatarArrowRight',
    headerTemplate: 'headerPrimary',
    cellClass: 'cursor-pointer p-0',
    name: 'COLUMN.NAME',
    prop: 'employee_data',
    withoutLink: true,
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.LAST_ACTIVITY',
    prop: 'lastActivity',
    sortable: true,
    resizeable: false,
    width: 144,
    maxWidth: 144,
  },
  {
    cellTemplate: 'withSelectionToggle',
    headerTemplate: 'headerSelectionsToggle',
    cellClass: 'center',
    name: 'selection',
    width: 60,
    maxWidth: 60,
    sortable: false,
    resizeable: false,
  }
];
