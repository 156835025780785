import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { debounceTime } from 'rxjs/internal/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AffectSubscriptionEnum, SubscriptionListTabsEnum, SubscriptionPeriodEnum } from 'projects/workspace/src/app/subscription/enums';
import { SUBS_DURATION_LIST } from 'projects/workspace/src/app/subscription/subscription.constants';
import { PauseModalsHintsComponent } from '../pause-modals-hints/pause-modals-hints.component';

@Component({
  selector: 'rnpl-subscription-pause-modal',
  templateUrl: './subscription-pause-modal.component.html',
})
export class SubscriptionPauseModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public nextBillingDate: any;
  public readonly subsDurationList: { value: SubscriptionPeriodEnum; label: string }[] = SUBS_DURATION_LIST;

  public readonly affectSubsEnum = AffectSubscriptionEnum;

  public subscriptionPeriodEnum = SubscriptionPeriodEnum;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('hint', { static: false }) hint: PauseModalsHintsComponent;

  constructor(
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private subscriptionApiService: SubscriptionApiService,
    public dialogRef: MatDialogRef<SubscriptionPauseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      subscriptionsIds: number[],
      isSubscriptionsDurationUnlimited: boolean,
      status: SubscriptionListTabsEnum,
      isSingleSubscription: boolean,
    }
  ) {
    super(toasterService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      startDate: [this.getDateWithoutTimezoneOffset(new Date()), [Validators.required]],
      duration: [SubscriptionPeriodEnum.MONTH],
      endDate: [{value: null, disabled: true}, [Validators.required]],
      affectOnSubscription: [
        {
          value: AffectSubscriptionEnum.PAUSE_WITHIN_DURATION,
          disabled: this.data.isSubscriptionsDurationUnlimited || this.data.subscriptionsIds.length > 1
        },
        [Validators.required]
      ],
    });

    this.duration.valueChanges.subscribe((value: SubscriptionPeriodEnum) => {
      if (value !== SubscriptionPeriodEnum.UNLIMITED && value !== SubscriptionPeriodEnum.SPECIFY_END) {
        this.endDate.disable({emitEvent: false});
      } else {
        this.endDate.enable({emitEvent: false});
      }
    });

    this.getPauseBillingDate();

    this.form.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.data.status) {
          this.getPauseBillingDate();
        }
      });

    this.cdr.detectChanges();
  }

  public getPauseBillingDate(): void {
    if (this.data.subscriptionsIds.length !== 1 || this.durationUnlimited) { return; }

    this.subscriptionApiService.getSubscriptionPauseBillingDate(
      this.data.subscriptionsIds[0],
      {
        ...this.form.getRawValue(),
        status: this.data.status
      }
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.nextBillingDate = res;
      });
  }

  public getDateWithoutTimezoneOffset(date: Date) {
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  }

  public pauseSubscriptions() {
    const requestData = {
      ids: this.data.subscriptionsIds,
      status: SubscriptionListTabsEnum.PAUSED,
      ...this.form.getRawValue(),
    };

    if (this.data.isSingleSubscription) {
      this.subscriptionApiService.changeSubscriptionStatus(
        SubscriptionListTabsEnum.PAUSED,
        this.data.subscriptionsIds[0],
        this.form.getRawValue()
      )
        .pipe(
          catchError((error: HttpErrorResponse) => {
            this.showMsg('error', error.error.message || error.error.errors);
            return throwError(error);
          }))
        .subscribe((response) => {
        this.dialogRef.close(true);
      });
    } else {
      this.subscriptionApiService.changeSubscriptionsStatus(requestData).subscribe((response) => {
        this.dialogRef.close(true);
      });
    }
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  get showEndDate(): boolean {
    return this.duration.value === SubscriptionPeriodEnum.SPECIFY_END;
  }
  get durationUnlimited(): boolean {
    return this.duration.value === SubscriptionPeriodEnum.UNLIMITED;
  }
  get endDate(): FormControl {
    return this.form.get('endDate') as FormControl;
  }
  get duration(): FormControl {
    return this.form.get('duration') as FormControl;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
