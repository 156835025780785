import { AfterViewInit, Injectable, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { ShepherdService } from 'angular-shepherd';

import { HintsButtonsModel, HintsWhenModel } from '../../models/hints.model';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { HintsListModel } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import { repeatHintsHint } from 'projects/workspace/src/app/products-two-level/products-hints.config';
import { SkipHintModalComponent } from '../../../modals/modals-common';
import { HintsIndexTimerText } from '../../constants/hints-constans';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { environment } from 'projects/workspace/src/environments/environment';
import { ResultDataModel } from '../../../modals/modals-common/skip-hint-modal/skip-hints-modal.model';
import { AuthService } from '../../../../auth/auth.service';

@Injectable()
export class BaseMegaHintComponentDependences {
  constructor(
    public hrmService: HrmService,
    public translate: TranslateService,
    public shepherdService: ShepherdService,
    public dialog: MatDialog,
    public actionButtonService: ActionButtonsService,
    public authService: AuthService,
  ) {}
}

export abstract class BaseMegaHintsComponent implements OnDestroy, AfterViewInit {
  @Input() public screenName: string;
  @Input() public endFinishText: string;

  public shepherdService = this.deps.shepherdService;
  public hrmService = this.deps.hrmService;
  public translate = this.deps.translate;
  public dialog = this.deps.dialog;
  public actionButtonService = this.deps.actionButtonService;
  public authService = this.deps.authService;

  public megaHintTimer: number;
  public stopTimer: boolean = false;
  public hintsTimerCount: number = 0;
  public megaHintId: number;
  public notViewedHintsList: HintsListModel[] = [];
  public hintsList: HintsListModel[] = [];
  public profileLangIsDe: boolean;

  public hintListByName;
  public activeHintIndex = 0;
  public currentTimer: number = 0;
  public countdownTimer;
  public megaHintConfig = [];
  public isCloseHint$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public showHintProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public animationStateIsPaused$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  public finishText = [
    'MEGA_HINT.END_STEP.END_STEP_TEXT_1',
    'MEGA_HINT.END_STEP.END_STEP_TEXT_2',
    'MEGA_HINT.END_STEP.END_STEP_TEXT_3',
    'MEGA_HINT.END_STEP.END_STEP_TEXT_4',
  ];

  public finishHintsConfig = {
    classes: 'finish-step from-modal',
    buttons: this.getFinishHintsButtons(),
    when: {
      hide: () => {
        clearInterval(this.countdownTimer);
      }
    }
  };

  public displayAllHintsHint = {
    ...repeatHintsHint,
    text: this.translate.instant('MEGA_HINT.REPEAT_ALL_HINTS'),
    buttons: this.getFinishHintsButtons(true),
    when: {
      hide: () => {
        clearInterval(this.countdownTimer);
      }
    }
  };

  protected constructor(
    public deps: BaseMegaHintComponentDependences
  ) { }

  public startTimerMegaHint(): void {
    this.profileLangIsDe = this.authService.getUser().lang === 'de';

    clearInterval(this.countdownTimer);

    if (!this.showHintProgress$.value) {
      setTimeout(() => {
        this.showHintProgress$.next(true);
      }, 0);
    }

    let hintsTimer: number = this.megaHintTimer;

    this.countdownTimer = setInterval( () => {
      if (!this.isCloseHint$.value) {
        if (!this.animationStateIsPaused$.value) {
          hintsTimer -= 1;
        }

        this.currentTimer = hintsTimer;

        if (hintsTimer <= 0) {
          clearInterval(this.countdownTimer);
          this.nextMegaHint();
          this.showHintProgress$.next(false);
        }
      }
    }, 10);
  }

  public startHintsTimer(text: string, id?: number): void {
    clearInterval(this.countdownTimer);

    let hintsTimer: number = Math.ceil(Math.floor(this.translate.instant(text).length * HintsIndexTimerText) / 100);

    this.hintsTimerCount = hintsTimer / 10;

    const hintsBtnProgressElement = document.getElementsByClassName('hints-next-btn-progress');
    const visibleBtnProgressElement = hintsBtnProgressElement[hintsBtnProgressElement.length - 1] as HTMLElement;

    if (!!visibleBtnProgressElement) {
      visibleBtnProgressElement.style.animationDuration = `${this.hintsTimerCount}s`;
    }

    this.countdownTimer = setInterval(() => {
      if (this.shepherdService.isActive) {

        if (!this.animationStateIsPaused$.value) {
          visibleBtnProgressElement.style.animationPlayState = 'running';
          hintsTimer -= 1;
        } else {
          visibleBtnProgressElement.style.animationPlayState = 'paused';
        }

        this.currentTimer = hintsTimer;

        if (hintsTimer <= 0 && !this.stopTimer) {
          clearInterval(this.countdownTimer);
          this.nextHint(id);
        }
      } else {
        clearInterval(this.countdownTimer);
        this.shepherdService.cancel();
      }
    }, 100);

  }

  public nextMegaHint(): void {
    if (!!this.megaHintConfig.length && this.activeHintIndex + 1 < this.megaHintConfig.length) {
      this.showHintProgress$.next(false);
      this.setDefaultStates();
      this.activeHintIndex ++;
      this.startTimerMegaHint();
    } else {
      this.closeMegaHint(false);
      if (!JSON.parse(localStorage.getItem('hideHintsTour'))) {
        this.shepherdService.start();
      }
    }
  }

  public prevMegaHint(): void {
    this.activeHintIndex --;
    this.setDefaultStates();
    this.startTimerMegaHint();
  }

  public closeMegaHint(withModal: boolean): void {
    if (withModal) {
      if (!this.animationStateIsPaused$.value) {
        this.pause();
      }

      const dialog = this.dialog.open(SkipHintModalComponent);

      dialog.afterClosed()
        .pipe(takeUntil(this._destroy))
        .subscribe((response: ResultDataModel) => {
          if (response) {
            this.isCloseHint$.next(true);
            this.setDefaultStates();
            this.skipHints(response.skipAllHints);
          } else {
            this.pause();
          }
        });
    } else {
      this.hrmService.setViewedHint(this.megaHintId, this.screenName)
        .pipe(takeUntil(this._destroy))
        .subscribe();

      this.isCloseHint$.next(true);
      this.setDefaultStates();
    }
  }

  public pause(): void {
    if (!this.animationStateIsPaused$.value) {
      this.animationStateIsPaused$.next(true);
      clearInterval(this.currentTimer);
    } else {
      this.animationStateIsPaused$.next(false);
    }
  }

  public skipHints(skipAllHints: boolean): void {
    if (skipAllHints) {
      this.hrmService.skipAllHints().pipe(takeUntil(this._destroy)).subscribe();
    } else {
      this.hrmService.skipHints(this.screenName, this.hintsIds())
        .pipe(takeUntil(this._destroy))
        .subscribe((hints) => {
          if (hints.data.showRepeatHint) {
            this.shepherdService.addSteps([this.displayAllHintsHint]);
            this.shepherdService.start();
          }
        });
    }
  }

  public replacePauseBtnIcon(): void {
    const hintsBtnPauseList = document.getElementsByClassName('pause-hint-icon');
    const hintsBtnPause = hintsBtnPauseList[hintsBtnPauseList.length - 1] as HTMLElement;

    const hintsBtnPlayList = document.getElementsByClassName('play-hint-icon');
    const hintsBtnPlay = hintsBtnPlayList[hintsBtnPlayList.length - 1] as HTMLElement;

    if (this.animationStateIsPaused$.value) {
      if (hintsBtnPause && hintsBtnPlay) {
        hintsBtnPause.style.display = 'none';
        hintsBtnPlay.style.display = 'block';
      }
    } else {
      if (hintsBtnPause && hintsBtnPlay) {
        hintsBtnPause.style.display = 'block';
        hintsBtnPlay.style.display = 'none';
      }
    }
  }

  public prepareHintsList(hintsList: HintsListModel[]): HintsListModel[] {
    if (!!hintsList && hintsList.length) {
      return hintsList.map(hint => {
        return {
          ...hint,
          ...this.hintListByName[hint.name],
        };
      });
    }
  }

  public getHintsButton(hint, index: number): HintsButtonsModel[] {
    return [
      {
        text: `<i class="icon icon-arrow-left"></i>`,
        classes: `btn btn-white yellow center prev ${index === 0 ? 'd-none' : '' }`,
        action: (() => this.prevHint())
      },
      {
        text: `
            <i id="playHintIcon" class="icon icon-play-circle play-hint-icon"></i>
            <i id="pauseHintIcon" class="icon icon-pause-circle pause-hint-icon"></i>
         `,
        classes: 'btn pause center',
        action: (() => {
          this.pause();
          this.replacePauseBtnIcon();
        })
      },
      {
        text: `<span class="hints-next-btn-progress show-animation"></span><i class="icon icon-arrow-right"></i>`,
        classes: 'btn btn-white yellow center',
        action: (() => this.nextHint(hint.id))
      },
      {
        text: this.translate.instant('MEGA_HINT.SCIP_HINTS_BTN'),
        classes: 'skip-hints',
        action: (() => this.cancelHintsTour())
      },
    ];
  }

  public getFinishHintsButtons(isSkipDisplayHints?): HintsButtonsModel[] {
    if (isSkipDisplayHints) {
      return [
        {
          text: `${this.translate.instant('BUTTON.CLOSE')} <i class="icon icon-checkmark ml-40"></i>`,
          classes: 'btn close-hints',
          action: (() => {
            this.hrmService.skipRepeatHints(this.screenName)
              .pipe(takeUntil(this._destroy))
              .subscribe();

            this.setDefaultStates();
            this.shepherdService.cancel();
            clearInterval(this.countdownTimer);
          })
        },
      ];
    }
    return [
      {
        text: `${this.translate.instant('BUTTON.CLOSE')} <i class="icon icon-checkmark ml-40"></i>`,
        classes: 'btn close-hints',
        action: (() => {
          this.setDefaultStates();
          this.shepherdService.cancel();
          clearInterval(this.countdownTimer);
        })
      },
      {
        text: this.translate.instant('MEGA_HINT.REPEAT_HINTS'),
        classes: 'skip-hints',
        action: (() => {
          this.shepherdService.cancel();
          setTimeout(() => {
            if (!JSON.parse(localStorage.getItem('hideHintsTour'))) {
              this.addHintsSteps(this.hintsList);
              this.isCloseHint$.next(false);
              this.activeHintIndex = 0;
              this.startTimerMegaHint();
            }
          }, 500);
        })
      },
    ];
  }

  public getHintsShowAndHideEvents(hint): HintsWhenModel {
    return {
      show: () => {
        this.startHintsTimer(hint.text, hint.id);
      },
      hide: () => {
        clearInterval(this.countdownTimer);
      }
    };
  }

  public prepareHintsSteps(hintsList) {
    const hintsListIsMoreTheOne = hintsList.length > 1;

    return hintsList.map((hint, index) => {
      if (!!hint.text) {
        hint.text = this.translate.instant(hint.text);
      }

      const text = environment.production ? hint.text : hint.text + ' ' + hint.name; //  add hint key for dev

      const hintsTitle = hintsListIsMoreTheOne ?
        `<span class="hints-count">${index + 1}/${hintsList.length}</span><img class="hints-img" src="assets/img/mega-hints/emoji/${this.randomNumber(1, 7)}.png" alt="">` :
        `<img class="hints-img" src="assets/img/mega-hints/emoji/${this.randomNumber(1, 7)}.png" alt="">`;

      return {
        ...hint,
        text: text,
        buttons: hintsListIsMoreTheOne ?
          this.getHintsButton(hint, index) :
          [
            {
              text: this.translate.instant('MEGA_HINT.SCIP_HINTS_BTN'),
              classes: 'skip-hints',
              action: (() => this.cancelHintsTour())
            },
            {
              text: `${this.translate.instant('BUTTON.CLOSE')} <i class="icon icon-checkmark ml-40"></i>`,
              classes: 'btn close-hints',
              action: (() => {
                this.hrmService.setViewedHint(hint.id, this.screenName)
                  .pipe(takeUntil(this._destroy))
                  .subscribe();
                this.setDefaultStates();
                this.shepherdService.cancel();
                clearInterval(this.countdownTimer);
              })
            }
          ],
        when: hintsListIsMoreTheOne ? this.getHintsShowAndHideEvents(hint) : false,
        title: hintsTitle,
      };
    });
  }

  public nextHint(id: number): void {
    this.setDefaultStates();
    this.hrmService.setViewedHint(id, this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe();

    this.shepherdService.next();
  }

  public prevHint(): void {
    this.setDefaultStates();
    this.shepherdService.back();
  }

  public cancelHintsTour(): void {
    if (!this.animationStateIsPaused$.value) {
      this.pause();
    }

    const hintsElements = document.getElementsByClassName('shepherd-element');
    const visibleHintsElement = hintsElements[hintsElements.length - 1] as HTMLElement;
    visibleHintsElement.style.zIndex = '999';

    const dialog = this.dialog.open(SkipHintModalComponent);

    dialog.afterClosed()
      .pipe(takeUntil(this._destroy))
      .subscribe((response: ResultDataModel) => {
        visibleHintsElement.style.zIndex = '1052';

        if (response) {
          this.setDefaultStates();
          this.shepherdService.cancel();
          clearInterval(this.countdownTimer);
          this.pause();
          this.skipHints(response.skipAllHints);
        }

        this.pause();
        this.replacePauseBtnIcon();
      });
  }

  public displayHints(): void {
    this.actionButtonService.displayHints
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        if (!this.shepherdService.isActive) {
          clearInterval(this.countdownTimer);

          if (!!this.hintsList && !!this.hintsList.length) {
            this.addHintsSteps(this.hintsList);
            this.isCloseHint$.next(false);
            this.activeHintIndex = 0;
            this.startTimerMegaHint();
          }
        }
      });
  }
  public repeatHints(withMegaHint?: boolean): void {
    if (!this.shepherdService.isActive) {
      clearInterval(this.countdownTimer);
      this.activeHintIndex = 0;
      this.addHintsSteps(this.hintsList);

      if (withMegaHint) {
        this.isCloseHint$.next(false);
        this.startTimerMegaHint();
      } else {
        this.shepherdService.start();
      }
    }
  }

  public addHintsSteps(hintList): void {
    this.shepherdService.addSteps(
      [
        ...this.prepareHintsSteps(hintList),
        {
          ...this.finishHintsConfig,
          text: `${this.translate.instant(this.finishText[this.randomNumber(0, 3)], {moduleName: this.translate.instant(this.endFinishText)})}`,
          title: `<img class="hints-img" src="assets/img/mega-hints/emoji/final/${this.randomNumber(1, 4)}.png" alt="">`
        }
      ]
    );
  }

  public setDefaultStates(): void {
    this.animationStateIsPaused$.next(false);
    this.showHintProgress$.next(false);
    this.currentTimer = 0;
  }

  public randomNumber(min, max): number {
    const rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }

  private hintsIds(): number[] {
    return [...this.hintsList.map(hint => hint.id), this.megaHintId];
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
    clearInterval(this.countdownTimer);

    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
    }
  }

  ngAfterViewInit() {
    this.shepherdService.confirmCancel = false;
    this.shepherdService.modal = false;
  }

}
