import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  OnDestroy, OnInit
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../../models/table-column.model';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';

@Component({
  selector: 'rnpl-column-with-form-input',
  templateUrl: './column-with-form-input.component.html',
  styleUrls: ['./column-with-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnWithFormInputComponent implements OnInit, OnChanges, OnDestroy {

  @Input() value: string | number;
  @Input() rowIndex: number;
  @Input() row: any;
  @Input() column: TableColumnModelExtended;
  @Input() type: ColumnTypeEnum = ColumnTypeEnum.TEXT;
  @Input() decimal?: number = 0;
  @Input() disabled?: boolean;
  @Input() required?: boolean;
  @Input() replaceEmpty?: boolean = true;

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  public inputControl = new FormControl(null, {validators: [], updateOn: 'blur'});
  readonly displayedValueWasChanged$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public unitTypeShortcut = { // todo move to common file
    unit: 'pcs',
    hours: 'h'
  };

  readonly columnTypes: typeof ColumnTypeEnum = ColumnTypeEnum;

  protected _$destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  @TrackInputChanges<string | number>('row', 'updateValueHandler', ChangesStrategy.Each)
  @TrackInputChanges<string | number>('value', 'updateValueHandler', ChangesStrategy.Each)
  @TrackInputChanges<boolean>('disabled', 'updateDisabledHandler', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.inputControl.valueChanges
      .pipe(takeUntil(this._$destroy))
      .subscribe((value) => {
        this.updateValueEmit(value);
        this.displayedValueWasChanged$.next(this.displayedValueWasChanged + 1);
      });

    if (get(this.column, 'required', false) && !this.value && this.value !== 0) {
      this.inputControl.setValidators([Validators.required]);
    }

  }

  public updateValueHandler(): void {
    const updatedValue = (this.type === ColumnTypeEnum.NUMBER && this.value === '') ? null : this.value;
    // Fix bug when input isn't render yet and have no mask.
    setTimeout(() => {
      this.inputControl.setValue(updatedValue, {emitEvent: false});
      this.displayedValueWasChanged$.next(this.displayedValueWasChanged + 1);
    }, 0);
  }

  public updateDisabledHandler(): void {
    this.disabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  public updateValueEmit(value: string | number): void {
    this.updateValue.emit({
      value: this.getValueByColumnType(value),
      cell: this.column.prop,
      rowIndex: this.rowIndex
    });
  }

  public getValueByColumnType(value: string | number): any {
    if (value === null || value === '') { return null; }

    switch (this.type) {
      case ColumnTypeEnum.NUMBER:
      case ColumnTypeEnum.STEPPER:
      case ColumnTypeEnum.MASK_NUMBERS:
        return Number(value);

      case ColumnTypeEnum.INT_OR_FLOAT_ONLY:
        if (!this.replaceEmpty && !value && value !== 0) { return null; }
        return +String(value).replace(',', '.');

      default:
        return value;
    }
  }

  public updateDatePicker(event) {
    this.inputControl.setValue(event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null);
  }

  public onAddStepperClick(): void {
    this.stepperAction('add');
  }

  public onSubtractStepperClick(): void {
    this.stepperAction('subtract');
  }

  private stepperAction(action: string): void {
    const value = this.inputControl.value || 0;
    const number = parseInt(value, 10);

    (action === 'add')
      ? this.inputControl.setValue(number + 1)
      : this.inputControl.setValue(number > 0 ? number - 1 : 0);
  }

  public get displayedValueWasChanged(): number {
    return this.displayedValueWasChanged$.getValue();
  }

  ngOnDestroy(): void {
    this._$destroy.next(null);
    this._$destroy.complete();
  }
}
