import { Directive, HostBinding, HostListener, Input } from '@angular/core';


import { SoundService } from 'common/src/services/sound.service';


@Directive({
  selector: '[rnplDisabledItem]'
})
export class DisabledItemDirective {

  @Input('rnplDisabledItem')
  @HostBinding('class.disabled')
  disabled: boolean = true;

  constructor(private soundService: SoundService) {
  }

  @HostListener('click', ['$event'])
  clicked(event): void {
    if (!this.disabled) {
      return;
    }

    event.preventDefault();
    this.soundService.playSound('miscellaneous_disabled_button');
  }
}
