import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({name: 'translatedDatePipe'})
export class TranslatedDatePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {
  }

  transform(value: Date, dateFormat?: string, ...args: any[]): string {
    if (!value) { return value as any; }

    return this.datePipe.transform(value, dateFormat)
      .replace('January', this.translateService.instant('MONTHS.JAN'))
      .replace('February', this.translateService.instant('MONTHS.FEB'))
      .replace('March', this.translateService.instant('MONTHS.MAR'))
      .replace('April', this.translateService.instant('MONTHS.APR'))
      .replace('May', this.translateService.instant('MONTHS.MAY'))
      .replace('June', this.translateService.instant('MONTHS.JUN'))
      .replace('July', this.translateService.instant('MONTHS.JUL'))
      .replace('August', this.translateService.instant('MONTHS.AUG'))
      .replace('September', this.translateService.instant('MONTHS.SEP'))
      .replace('October', this.translateService.instant('MONTHS.OCT'))
      .replace('November', this.translateService.instant('MONTHS.NOV'))
      .replace('December', this.translateService.instant('MONTHS.DEC'));
  }

}
