import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material';
import { ReplaySubject, Subscription } from 'rxjs';
import { get, isEqual } from 'lodash';
import { Store } from '@ngrx/store';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { UIStatesEnum } from '../../../../models';
import { selectDeliveryNote, selectDeliveryNoteState } from 'projects/workspace/src/app/delivery-note/store/selectors';
// import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/delivery-note/store/actions/delivery-note.actions';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';

@Component({
  selector: 'rnpl-add-remark-modal',
  templateUrl: './add-remark-modal.component.html',
  styleUrls: ['./add-remark-modal.component.css']
})
export class AddRemarkModalComponent implements OnInit {
  public form: FormGroup;
  public formSubscription: Subscription = null;
  public sidebarStatus: UIStatesEnum = UIStatesEnum.VIEW;
  public deliveryNote: DeliveryNote;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public dialogRef: MatDialogRef<AddRemarkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private readonly deliveryNoteApiService: DeliveryNoteApiService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.trackDeliveryNoteChanges();
    this.trackDeliveryNoteStateChanges();
  }

  private trackDeliveryNoteStateChanges(): void {
    this.store.select(selectDeliveryNoteState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.sidebarStatus = state;
        this.setControlsState();
      });
  }

  private trackDeliveryNoteChanges(): void {
    this.store.select(selectDeliveryNote)
      .subscribe((deliveryNote: DeliveryNote) => {
        this.deliveryNote = deliveryNote;
        this.formSubscription.unsubscribe();
        this.initForm(deliveryNote);
        // this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));
      });
  }

  private initForm(deliveryNote: DeliveryNote = {} as DeliveryNote): void {
    this.form = this.fb.group({
      printableRemarks: [get(deliveryNote, 'printableRemarks'), {updateOn: 'blur'}],
    });

    this.cdr.detectChanges();

    this.formSubscription = this.form.valueChanges
      .pipe(
        debounceTime(50),
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const deliveryNote = {
          ...this.deliveryNote,
          ...this.form.getRawValue()
        };
        this.deliveryNoteApiService.updateDeliveryNote(this.deliveryNote.id, deliveryNote)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      });
  }

  private setControlsState(): void {
    const opts = {emitEvent: false};

    if (this.isView() || this.isPacking()) {
      this.form.disable(opts);
      return;
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }
  }

  public isView(): boolean {
    return (this.sidebarStatus === UIStatesEnum.VIEW);
  }

  public isPacking(): boolean {
    return (this.sidebarStatus === UIStatesEnum.PACKING);
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
