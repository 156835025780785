import { CdkDrag } from '@angular/cdk/drag-drop';


import { ComposerCellComponent } from './components';


export type CellSize = 'small' | 'medium' | 'wide';

export interface ComposerDragStartedEvent {
  dragItem: CdkDrag;
  dragItemSize: CellSize;
  sourceCell: ComposerCellComponent;
}

export interface CellDataModel<T> {
  size: CellSize;
  data?: T;
}

export abstract class ComposerGridItem {
  rect: DOMRect;
  rowIndex: number;
  index: number;
}

export abstract class ComposerCell extends ComposerGridItem {
  isEmpty: boolean;
  digitalSize: number;
  data: CellDataModel<any>;
}

export abstract class ComposerPlaceholder extends ComposerGridItem {
  disabled: boolean;
  isTarget: boolean;
  direction: string;
}
