import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { takeUntil } from 'rxjs/operators';
import { BatchDataModel } from '../../models/batch-data.model';
import { AutoUnsubscribe } from 'common/src/decorators/auto-unsubscribe';
import { CostsType } from '../enums/costs.enum';
import { BatchStates } from 'projects/workspace/src/app/purchase-order/enums/batch-states';
import { get } from 'lodash';
import { Store, select } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { currentBatchStateSelector, currentBatchSelector } from 'projects/workspace/src/app/purchase-order/store/selectors/po.selectors';
import { BatchModel } from 'projects/workspace/src/app/purchase-order/models/purchase-order/batch.model';
import { LoadCurrentBatch } from 'projects/workspace/src/app/purchase-order/store/actions/batch.actions';

@AutoUnsubscribe()
@Component({
  selector: 'rnpl-batch-view-costs',
  templateUrl: './batch-view-costs.component.html',
  styleUrls: ['./batch-view-costs.component.scss']
})
export class BatchViewCostsComponent extends BaseFormComponent implements OnInit, OnDestroy {

  @Input() batchData: BatchDataModel;

  public unitValue: string = 'EUR';

  public metricConversion = 'EUR-EUR';

  readonly batch$: BehaviorSubject<BatchModel> = new BehaviorSubject({} as BatchModel);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private metricPipe: MetricConversionPipe,
    private store: Store<AppState>
  ) {
    super();
    this.storeSubscribes();
  }

  public storeSubscribes(): void {
    this.store
      .pipe(
        select(currentBatchStateSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(activeState => {
        (activeState === BatchStates.VIEW)
          ? this.readonly = true
          : this.readonly = false;
        this.updateFormEditingStatus();
      });
  }

  ngOnInit(): void {
    this.initForm();
    this.subscribeCosts();
    this.subscribeNettoAndBrutto();
    this.subscribeBatch();
  }

  public updateFormEditingStatus(): void {
    if (!this.form) { return; }
    if (this.readonly) {
      this.form.disable({ onlySelf: false });
    } else {
      this.form.enable({ onlySelf: false });
      this.fieldsAction('disable', this.vat);
      this.changeStatusForPay(this.costs.value);
    }
  }

  public initForm(): void {
    const batch = this.batch$.getValue();
    const netto_price = this.getNettoPrice(batch);
    this.form = this.fb.group({
      freight_cost: this.fb.group({
        costs: [netto_price ? CostsType.PAYED_BY_US : CostsType.PAYED_BY_VENDOR, [Validators.required]],
        netto_price: [netto_price, { validators: [Validators.required], updateOn: 'blur' }],
        vat: [{ value: '', disabled: true }, [Validators.required]],
        brutto_price: ['', { validators: [Validators.required], updateOn: 'blur' }],
      }),
    });

    this.updateFormEditingStatus();
  }

  public subscribeBatch(): void {
    this.store
      .pipe(
        select(currentBatchSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(batch => {
        this.batch$.next(batch);
        this.updateForm(batch);
      });
  }

  public updateForm(batch: BatchModel): void {
    if (this.form) {
      const netto = this.getNettoPrice(batch);
      this.costs.setValue(netto ? CostsType.PAYED_BY_US : CostsType.PAYED_BY_VENDOR, { emitEvent: false });
      this.netto_price.setValue(netto, { emitEvent: false });
      this.handleVatAndBrutto(netto);
    }
  }

  public subscribeNettoAndBrutto() {
    this.netto_price.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((netto) => {
        this.handleVatAndBrutto(netto);

        this.store.dispatch(LoadCurrentBatch({
          batch: {
            ...this.batch$.getValue(),
            delivery_cost_transportation: Number((netto * 100).toFixed(2))
          }
        }));
      });

    this.brutto_price.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((brutto) => {
        this.handleVatAndNetto(brutto);
      });
  }

  public handleVatAndBrutto(netto) {
    const vat = (Number(netto) * 0.2).toFixed(2);
    const brutto = (Number(netto) + Number(vat)).toFixed(2);

    this.vat.setValue(vat);
    this.brutto_price.setValue(brutto, { emitEvent: false });
  }

  public handleVatAndNetto(brutto) {
    const vat = (Number(brutto) / 6).toFixed(2);
    const netto = (Number(brutto) / 1.2).toFixed(2);

    this.vat.setValue(vat);
    this.netto_price.setValue(netto, { emitEvent: false });
  }

  public subscribeCosts() {
    this.costs.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((costs: CostsType) => {
        this.changeStatusForPay(costs);
      });
  }

  public getNettoPrice(batch: BatchModel): number {
    const nettoCent = get(batch, 'delivery_cost_transportation', 0);
    return Number((nettoCent / 100).toFixed(2));
  }

  public changeStatusForPay(costs: CostsType) {
    if (costs === CostsType.PAYED_BY_VENDOR) {
      this.fieldsAction('reset', this.netto_price, this.brutto_price, this.vat);
      this.fieldsAction('disable', this.netto_price, this.brutto_price, this.vat);
    } else {
      this.fieldsAction('enable', this.netto_price, this.brutto_price);
    }
  }

  public fieldsAction(type: string, ...fields: FormControl[]): void {
    switch (type) {
      case 'enable':
        fields.forEach(field => field.enable({ emitEvent: false }));
        break;
      case 'disable':
        fields.forEach(field => field.disable({ emitEvent: false }));
        break;
      case 'reset':
        fields.forEach(field => field.setValue(0, { emitEvent: false }));
        break;
    }
  }

  public valueHandler(value: string): void {
    if (this.unitValue !== value) {
      this.metricConversion = `${this.unitValue}-${value}`;
      this.unitValue = value;
      this.updateFormMetric(this.metricConversion);
    }
  }

  public updateFormMetric(metricConversion): void {
    const toUpdateList = [
      'netto_price',
      'vat',
      'brutto_price'
    ];
    toUpdateList.forEach((item) => {
      this[item].setValue(
        this.metricPipe.transform(Number(this[item].value), metricConversion)
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get costs() { return this.form.get('freight_cost').get('costs') as FormControl; }

  get netto_price() { return this.form.get('freight_cost').get('netto_price') as FormControl; }

  get vat() { return this.form.get('freight_cost').get('vat') as FormControl; }

  get brutto_price() { return this.form.get('freight_cost').get('brutto_price') as FormControl; }
}
