import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'rnpl-change-email-modal',
  templateUrl: 'change-email-modal.component.html',
})
export class ChangeEmailModalComponent {

  public form: FormGroup;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private hrmService: HrmService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangeEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentEmail: string
    }) {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  submit(): void {
    if (this.submitRequest$.getValue()) { return; }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.submitRequest$.next(true);

    this.hrmService.changMyProfileEmail(this.form.getRawValue())
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.dialogRef.close(res);
      });
  }

  get emailControl(): FormControl { return this.form.get('email') as FormControl; }
  get passwordControl(): FormControl { return this.form.get('password') as FormControl; }

}
