export const BACKGROUND_CONTENT = {
  night: {
    background: 'night',
    title: 'SIGN_IN.GOOD_EVENING'
  },
  morning: {
    background: 'morning',
    title: 'SIGN_IN.GOOD_MORNING'
  },
  day: {
    background: 'day',
    title: 'SIGN_IN.HELLO'
  },
  afternoon: {
    background: 'afternoon',
    title: 'SIGN_IN.GOOD_AFTERNOON'
  },
  evening: {
    background: 'night',
    title: 'SIGN_IN.GOOD_EVENING'
  },
};

export function TimeOfDay(): string {
  const hours = new Date().getHours();
  if (hours >= 5 && hours <= 9) {
    return 'morning';
  }
  if (hours >= 10 && hours <= 15) {
    return 'day';
  }
  if (hours >= 16 && hours <= 19) {
    return 'afternoon';
  }
  if (hours >= 20 && hours <= 23) {
    return 'evening';
  }
  return 'night';
}
