import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'rnpl-delivery-note-units-form',
  templateUrl: './delivery-note-units-form.component.html'
})
export class DeliveryNoteUnitsFormComponent {

  @Input() readonly form: FormGroup;
  @Input() readonly isView: boolean;
  @Input() readonly controlsWidth: number = 12;

  constructor() { }

  public valueHandlerWeight(value: string): void {
    this.totalPackageWeightUnit.setValue(value);
  }

  public valueHandlerVolume(value: string): void {
    this.totalPackageVolumeUnit.setValue(value);
  }

  get totalPackageWeight(): FormControl { return this.form.get('totalPackageWeight') as FormControl; }
  get totalPackageVolume(): FormControl { return this.form.get('totalPackageVolume') as FormControl; }
  get totalPackageWeightUnit(): FormControl { return this.form.get('totalPackageWeightUnit') as FormControl; }
  get totalPackageVolumeUnit(): FormControl { return this.form.get('totalPackageVolumeUnit') as FormControl; }

}
