import { MatDialogRef } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { MetricConversionPipe } from '../../../rnpl-common';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectSubscription, selectSubscriptionCurrentState } from 'projects/workspace/src/app/subscription/store/selectors';
import { SubscriptionModel } from 'projects/workspace/src/app/subscription/models/subscription.model';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { UIStatesEnum } from '../../../../models';

@Component({
  selector: 'rnpl-subscription-add-discount-modal',
  templateUrl: './subscription-add-discount-modal.component.html',
  styleUrls: ['./subscription-add-discount-modal.component.scss']
})
export class SubscriptionAddDiscountModalComponent implements OnInit {
  public subscription: SubscriptionModel;
  public discountControl: FormControl = new FormControl({value: null});
  public discountAmountNetControl: FormControl = new FormControl({value: null});
  public discountAmountGrossControl: FormControl = new FormControl({value: null});

  readonly destroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<SubscriptionAddDiscountModalComponent>,
    private readonly metricPipe: MetricConversionPipe,
    private store: Store<AppState>,
    private readonly subscriptionApiService: SubscriptionApiService,
  ) { }

  ngOnInit() {
    this.trackSubscriptionChanges();

    this.store.select(selectSubscriptionCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        if (response === UIStatesEnum.VIEW) {
          this.discountControl.disable({emitEvent: false});
          this.discountAmountNetControl.disable({emitEvent: false});
          this.discountAmountGrossControl.disable({emitEvent: false});
        } else {
          this.discountControl.enable({emitEvent: false});
          this.discountAmountNetControl.enable({emitEvent: false});
          this.discountAmountGrossControl.enable({emitEvent: false});
        }
      });
  }

  private trackSubscriptionChanges(): void {
    this.store.select(selectSubscription)
      .pipe(takeUntil(this.destroy$))
      .subscribe((subscription: SubscriptionModel) => {
        this.subscription = subscription;
        this.discountControl.patchValue(this.subscription.discount);
        this.discountAmountNetControl.patchValue(this.getCurrencyConvertedValue(this.subscription.discountAmountNet));
        this.discountAmountGrossControl.patchValue(this.getCurrencyConvertedValue(this.subscription.discountAmountGross));
      });
  }

  public updateDiscount(field: FormInputChangedModel): void {
    this.subscriptionApiService.updateSubscription(this.subscription.id, field)
      .subscribe(); // updated via store
  }

  private getCurrencyConvertedValue(value: number|string): number|string {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
