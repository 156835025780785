import { Component, OnInit, Input } from '@angular/core';
import { TradeOfferModel } from 'projects/workspace/src/app/trade-offer/models';
import { Observable } from 'rxjs';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';

@Component({
  selector: 'rnpl-view-summary-offer',
  templateUrl: './view-summary-offer.component.html',
  styleUrls: ['./view-summary-offer.component.scss'],
})
export class ViewSummaryOfferComponent implements OnInit {

  @Input() offer: TradeOfferModel = null;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit() {}

  public get partnerUrl(): string {
    return `/partners-new/${this.partner.type || PartnersTypeEnum.CORPORATE}/${this.partner.id}`;
  }

  public get partner() {
    return this.offer.properties.company;
  }
}
