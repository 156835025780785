import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input, OnChanges,
  Output, SimpleChanges, TemplateRef,
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'rnpl-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sidePanelAnimation', [
      state('visible', style({
        transform: 'translateX(0)',
      })),
      state('hidden', style({
        transform: 'translateX(120%)',
      })),
      transition('visible => hidden', animate('400ms ease-in-out')),
      transition('hidden => visible', animate('400ms ease-in-out'))
    ])
  ]
})
export class SidebarComponent implements OnChanges {
  @ContentChild(TemplateRef, { static: false }) view: TemplateRef<any>;

  @Input() listBottomIndent: boolean;
  @Input() opened: boolean;
  @Input() bottomIndent?: number = 0;
  @Input() hiddenCross: boolean;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly sidebarState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly sidebarOpened$: Observable<boolean> = this.sidebarState$
    .pipe(
      mergeMap((value: boolean) => iif(() => value, of(value), of(value).pipe(delay(300))))
    );

  hide(): void {
    this.sidebarState$.next(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('opened')) {
      if (changes.opened.currentValue) {
        this.sidebarState$.next(true);
      } else {
        this.sidebarState$.next(false);
      }
    }
  }

  animationHandler(e) {
    if (e.fromState === 'visible' && e.toState === 'hidden') {
      this.close.emit();
    }
  }
}
