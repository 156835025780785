import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable, Subject } from 'rxjs';


import { MAX_OPTIONS_COUNT_RADIO, MAX_OPTIONS_COUNT_SELECT } from '../../constants';
import { TabLabelDirective } from '../../../../tabs/tabs';
import { SoundService } from 'common/src/services/sound.service';

@Component({
  selector: 'rnpl-control-changing-confirmation',
  templateUrl: './control-changing-confirmation.component.html',
  styleUrls: ['./control-changing-confirmation.component.scss']
})
export class ControlChangingConfirmationComponent {

  @Input()
  public controls: any;

  public newControl: string;

  public dialogRef: MatDialogRef<TemplateRef<any>>;

  @ViewChild('popup', {static: true})
  private popupTemplate: TemplateRef<any>;

  private excessOptions$: Subject<number>;

  private excessCount: number;

  constructor(private dialog: MatDialog, public soundService: SoundService) {
  }

  public verify(control: string, newControl: string, optionsCount: number): Observable<number> {
    this.newControl = newControl;

    this.excessCount = this.getExcessOptionsCount(newControl, optionsCount);
    this.excessOptions$ = new Subject();
    if (!this.excessCount) {
      setTimeout(() => {
        this.confirm(true);
      }, 0);
    } else {
      this.dialogRef = this.dialog.open(this.popupTemplate, {
        width: 'auto',
        position: {
          top: '64px'
        }
      });

      this.soundService.playSound('modal_dangerous');

    }

    return this.excessOptions$;
  }

  public confirm(confirmed: boolean): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    if (confirmed) {
      this.excessOptions$.next(this.excessCount);
    } else {
      this.excessOptions$.error(null);
    }
    this.excessOptions$.complete();
    this.excessOptions$ = null;
  }

  private getExcessOptionsCount(control: string, optionsCount: number): number {

    let excess = 0;
    switch (control) {

      case 'radio':
        if (optionsCount > MAX_OPTIONS_COUNT_RADIO) {
          excess = optionsCount - MAX_OPTIONS_COUNT_RADIO;
        }
        break;

      case 'select':
        if (optionsCount > MAX_OPTIONS_COUNT_SELECT) {
          excess = optionsCount - MAX_OPTIONS_COUNT_SELECT;
        }
        break;

      case 'tokenfield':
      case 'combobox':
        return 0;
        break;

      default:
        excess = optionsCount;
    }

    return excess;
  }
}
