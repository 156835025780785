import { WarehouseResponse } from '../../../models/warehouse-response.model';

export function wahrehouseResponseHepler(response: WarehouseResponse[]) {
  return response.map((el: any) =>  ({
    ...el,
    storage_area: {
      value: el.storage_area.available_storage_area,
      maxValue: el.storage_area.max_storage_area,
    },
    storage_volume: {
      value: el.storage_volume.available_storage_volume,
      maxValue: el.storage_volume.max_storage_volume,
    },
    runple_id_data: {
      label: el.runple_id,
      routerLink: `/warehouse/warehouse-profile/${el.id}`
    }
  }));
}
