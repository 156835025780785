import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';

export type StepState = 'locked' | 'ready' | 'active' | 'completed';

@Component({
  selector: 'rnpl-wizard-nav-sub-item',
  templateUrl: './wizard-nav-sub-item.component.html'
})
export class WizardNavSubItemComponent {

  @Input()
  label: string;

  @Input()
  public set state(state: StepState) {
    this._state = state;
    this.stateChanged.emit(this._state);
  }

  public get state(): StepState {
    return this._state;
  }

  @Output()
  public click: EventEmitter<any> = new EventEmitter();

  @Output()
  public stateChanged: EventEmitter<StepState> = new EventEmitter<StepState>();

  protected _state: StepState = 'locked';

  public clicked(): void {
    if (this.state !== 'locked') {
      this.click.emit();
    }
  }
}

@Component({
  selector: 'rnpl-wizard-nav-item',
  templateUrl: './wizard-nav-item.component.html'
})
export class WizardNavItemComponent extends WizardNavSubItemComponent implements AfterContentInit {

  @Input()
  public icon: string;

  @ContentChildren(WizardNavSubItemComponent)
  public subItems: QueryList<WizardNavSubItemComponent>;

  ngAfterContentInit(): void {
  }

  public clicked(): void {
    if (this._state === 'locked') {
      return;
    }

    if (!this.subItems.length) {
      super.clicked();
    } else {
      this.subItems.first.clicked();
    }
  }
}


@Component({
  selector: 'rnpl-wizard-side-nav',
  templateUrl: './wizard-side-nav.component.html',
  styleUrls: ['./wizard-side-nav.component.scss']
})
export class WizardSideNavComponent {

}
