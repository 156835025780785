import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';

import { LaunchpadModalComponent } from '../../modals/launchpad-modal/launchpad-modal.component';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SandboxHintService } from '../../quick-onboarding/sandbox-hint.service';
import { AuthService } from '../../../auth/auth.service';
import { CompanyProfileModeEnum } from 'projects/workspace/src/app/administration/enums';
import { AuthUserPermissionsEnum } from '../../../auth/auth-user-permissions.enum';
import { AuthUserModel } from '../../../auth/auth-user.model';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { selectUserShortcuts } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { ShortcutsConfig, userAvailableShortcutsHelper } from '../../modals/modals-control-center/shortcuts-modal/user-shortcuts.helper';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { ExitSandboxModalComponent } from '../../quick-onboarding/components/exit-sandbox-modal/exit-sandbox-modal.component';
import { ShortcutsModalComponent } from '../../modals/modals-control-center';
import { UserShortcutEnum } from 'projects/workspace/src/app/hrm/enums';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { ActionButtonsService } from '../../../services/action-buttons.service';
import { UpdatePaymentCardModalComponent } from '../../modals/modals-subscription-activation';

@Component({
  selector: 'rnpl-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('sidePanelAnimation', [
      state('open', style({
        transform: 'translateX(0)',
      })),
      state('close', style({
        transform: 'translateX(312px)',
      })),
      transition('open => close', animate('300ms ease-in-out')),
      transition('close => open', animate('300ms ease-in-out'))
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  public companyProfileMode = CompanyProfileModeEnum;
  public authUserPermissions = AuthUserPermissionsEnum;

  public user: AuthUserModel = null;
  public onboardingState: string = 'open';
  public userId: number;
  public hideHover: boolean;

  public activatedShortcuts = []; // todo model
  public isMobile: boolean = false;
  public visibleShortcuts = []; // todo model
  public otherShortcuts = []; // todo model
  readonly companyProfile$: BehaviorSubject<CompanyProfile> = new BehaviorSubject<CompanyProfile>(null);
  public showSandboxHint$: Observable<boolean> = this.sandboxHintService.showSandboxHint$;
  public showOverduePaymentHint$: Observable<boolean> = this.sandboxHintService.showOverduePaymentHint$;
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly shortCutWidthWithIndent: number = 56;

  public resizeObservable$: Observable<Event>;
  public resizeSubscription$: Subscription;

  private subscriptions: Subscription[] = [];

  public addedShortcutsList = []; // todo model
  public availableShortcutsList = []; // todo model

  // private readonly startDaysToTheBillingDate = 6;

  @ViewChild('headerElement', {static: true}) public headerElement: ElementRef;
  @ViewChild('controlsListElement', {static: true}) public controlsListElement: ElementRef;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    public router: Router,
    private sandboxHintService: SandboxHintService,
    // private quickOnboardingToggleService: QuickOnboardingToggleService,
    private readonly store: Store<AppState>,
    private deviceService: DeviceDetectorService,
    private hrmService: HrmService,
    private actionButtonService: ActionButtonsService,
  ) {
    this.userId = this.authService.getUser().id;
    // this.quickOnboardingToggleService.toggleState$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(stateValue => this.onboardingState = stateValue);

    this.store.select(selectUserShortcuts)
      .pipe(takeUntil(this.destroy$))
      .subscribe(shortcuts => {
        this.activatedShortcuts = shortcuts.activated
          .filter((itm, index) => index < 10) // get only 10 first shortcuts
          .map(itm => ShortcutsConfig()[itm]);
        this.availableShortcutsList = userAvailableShortcutsHelper(shortcuts.available);
        this.addedShortcutsList = shortcuts.activated.map(itm => ShortcutsConfig()[itm]);
      });

    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.authService.loggedUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this.user = user);
    this.selectCompanyProfileFromStore();

    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$
      .pipe(takeUntil(this.destroy$))
      .subscribe( evt => {
        this.getShortcutsFromStore();
      });
  }

  ngAfterViewInit() {
    this.getShortcutsFromStore();
  }

  public getMaxActiveShortcutsToTheScreen(): number {
    const headerWidth = this.headerElement.nativeElement.getBoundingClientRect().width;
    const controlsContainerWidth = this.controlsListElement.nativeElement.getBoundingClientRect().width;

    let shortcutsContainerWidth: number = 0;
    shortcutsContainerWidth = headerWidth - (controlsContainerWidth * 2);

    return Math.floor(shortcutsContainerWidth / this.shortCutWidthWithIndent) - 1;
  }

  public getShortcutsFromStore():void {
    const maxItems = this.getMaxActiveShortcutsToTheScreen();

    this.subscriptions.forEach(s => s.unsubscribe());

    const subscription = this.store.select(selectUserShortcuts)
      .pipe(takeUntil(this.destroy$))
      .subscribe(shortcuts => {
        this.visibleShortcuts = shortcuts.activated
          .filter((itm, index) => index < maxItems) // get only max first shortcuts
          .map(itm => ShortcutsConfig()[itm]);

        this.otherShortcuts = shortcuts.activated
          .filter((itm, index) => index > maxItems - 1) // get other shortcuts
          .map(itm => ShortcutsConfig()[itm]);
      });

    this.subscriptions.push(subscription);
  }

  public openLaunchpadModal(): void {
    this.dialog.open(LaunchpadModalComponent, {
      panelClass: 'launchpad-modal',
      width: '100%',
      maxWidth: '100%',
      height: '100%'
    });
  }

  public selectCompanyProfileFromStore() {
    this.store
      .select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile: CompanyProfile) => {
        this.companyProfile$.next(profile);
      });
  }

  // public activateSubscription(): void {
  //   this.router.navigate(['/administration/subscription-management'], {state: {isActivationSubscriptionForm: true}});
  // }

  public showSandboxHint(): void {
    this.sandboxHintService.setShowHint = true;
  }

  public showOverduePaymentHint(): void {
    this.sandboxHintService.setOverduePaymentShowHint = true;
  }

  // public toggleOnboardingMenu(): void {
  //   this.quickOnboardingToggleService.toggle();
  // }

  public updateOverduePaymentCard(e): void {
    e.stopPropagation();
    this.sandboxHintService.setOverduePaymentShowHint = false;
    this.dialog.open(UpdatePaymentCardModalComponent, {
      maxWidth: '480px',
      panelClass: 'mat-dialog-container-min-width-auto',
      disableClose: true
    });
  }

  public exitSandbox(e): void {
    e.stopPropagation();
    this.sandboxHintService.setShowHint = false;
    this.dialog.open(ExitSandboxModalComponent, {
      data: {
        companyProfile: this.companyProfile$.getValue()
      },
      disableClose: true
    });
  }

  public toggleSandboxHint(e, value: boolean): void {
    e.stopPropagation();
    this.sandboxHintService.setShowHint = value;
  }

  public toggleOverduePaymentHint(e, value: boolean): void {
    e.stopPropagation();
    this.sandboxHintService.setOverduePaymentShowHint = value;
  }

  public openShortcutsSettingsModal(): void {
    this.dialog.open(ShortcutsModalComponent, {
      minWidth: '680px',
      maxWidth: '680px',
      disableClose: true
    });
  }

  public drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.visibleShortcuts, event.previousIndex, event.currentIndex);
    this.submitShortcuts();
  }

  public submitShortcuts(): void {
    const shortcuts: UserShortcutEnum[] = [
      ...this.visibleShortcuts.map(i => i.key),
      ...this.otherShortcuts.map(i => i.key)
    ];

    this.hrmService.updateUserModulesShortcuts(this.userId, shortcuts)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

//   public displaySbcActivationButton(): boolean {
//     if (!this.companyProfile$.value.tracking.nextBillingAt) {return false;}
// // if to the next billing date <= 6 days
//     let todayDate: any = new Date();
//     let nextBillingDate: any =  new Date(this.companyProfile$.value.tracking.nextBillingAt);
//     let oneDay: any = 3600 * 24 * 1000;  // milliseconds per day
//
//     todayDate = Math.floor(todayDate.getTime() / oneDay);
//     nextBillingDate = Math.floor(nextBillingDate.getTime() / oneDay);
//
//     return ((nextBillingDate - todayDate) <= this.startDaysToTheBillingDate);
//   }

  public contextMenuActionsHandler(action: string): void {
    if (this[action]) {
      this[action]();
    } else {
      this.actionButtonService.callAction(action);
    }
  }

  public removeShortcut = (shortcutKey): void => { // need to use arrow func because of context
    const previousIndex = this.addedShortcutsList.findIndex(shortcut => shortcut.key === shortcutKey);
    const nextGroup = this.availableShortcutsList.find(shortcutGroup => shortcutGroup.group.includes(shortcutKey));
    const nextIndex = nextGroup.group.findIndex((key => key === shortcutKey));

    transferArrayItem(
      this.addedShortcutsList,
      nextGroup.shortcuts,
      previousIndex,
      nextIndex,
    );

    const shortcuts: UserShortcutEnum[] = this.addedShortcutsList.map(i => i.key);
    this.hrmService.updateUserModulesShortcuts(this.userId, shortcuts)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.resizeSubscription$.unsubscribe();
  }

}
