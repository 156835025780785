import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-product-basic-color-form',
  templateUrl: './product-basic-color-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductBasicColorFormComponent implements OnChanges {

  public basicColorsList: {value: string, label: string}[] = [
    {value: 'Rot', label: 'COLORS.RED'},
    {value: 'Orange', label: 'COLORS.ORANGE'},
    {value: 'Gelb', label: 'COLORS.YELLOW'},
    {value: 'Grün', label: 'COLORS.GREEN'},
    {value: 'Hellblau', label: 'COLORS.LIGHTBLUE'},
    {value: 'Blau', label: 'COLORS.BLUE'},
    {value: 'Lila', label: 'COLORS.PURPLE'},
    {value: 'Rosa', label: 'COLORS.PINK'},
    {value: 'Braun', label: 'COLORS.BROWN'},
    {value: 'Schwarz', label: 'COLORS.BLACK'},
    {value: 'Grau', label: 'COLORS.GREY'},
    {value: 'Weiß', label: 'COLORS.WHITE'},
    {value: null, label: 'COMMON.USER_DEFINED'}
  ];

  public currentValue: string;
  public dontUpdateBasicColorField: boolean;

  public defaultControl: FormControl = new FormControl();

  @Input() basicColorControl: FormControl;
  @Input() currentBasicColor: string;
  @Input() readOnly: boolean;

  @Output() fieldChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('currentBasicColor')) {
      this.currentValue = this.currentBasicColor;

      if (this.dontUpdateBasicColorField) {
        this.defaultControl.setValue('COMMON.USER_DEFINED');
      }
    }

    if (changes.hasOwnProperty('readOnly')) {
      if (this.dontUpdateBasicColorField) {
        this.defaultControl.setValue('COMMON.USER_DEFINED');
      }
    }

    if (this.readOnly) {
      this.defaultControl.disable();
      this.basicColorControl.disable();
    } else {
      this.defaultControl.enable();
      this.basicColorControl.enable();
    }
  }

  public updateField(fieldValue: any): void {
    this.fieldChanged.emit(fieldValue);
    if (!this.isBasicColorPredefined) {
      this.dontUpdateBasicColorField = false;
    }
  }

  public updateBasicColorField(fieldValue: any): void {
    if (!!fieldValue) {
      this.dontUpdateBasicColorField = false;
      this.fieldChanged.emit(fieldValue);
      this.currentValue = fieldValue;
      this.basicColorControl.setValue(fieldValue);
    } else {
      this.dontUpdateBasicColorField = true;
      // if (this.isBasicColorPredefined) {
      //   this.basicColorControl.setValue(this.currentValue);
      // }
      this.defaultControl.setValue('COMMON.USER_DEFINED');
    }
  }

  public updateValidity(): void {
    this.basicColorControl.markAsUntouched();
    this.basicColorControl.updateValueAndValidity();
  }

  public getBasicColorPredefined(basicColor: string = this.currentBasicColor): boolean {
    return [
      'Rot',
      'Orange',
      'Gelb',
      'Grün',
      'Hellblau',
      'Blau',
      'Lila',
      'Rosa',
      'Braun',
      'Schwarz',
      'Grau',
      'Weiß',
      '',
    ].includes(basicColor);
  }

  get isBasicColorPredefined(): boolean {
    return this.getBasicColorPredefined();
  }

  get basicColor() { return get(this, 'basicColorControl'); }

}
