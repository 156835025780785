import { CellSize } from './interfaces';

export const digitalSize = (size: CellSize) => {
  switch (size) {
    case 'small':
      return 0.25;
      break;

    case 'wide':
      return 1;
      break;

    default:
      return 0.5;
  }
};
