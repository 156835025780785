import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import {
  getConfirmedServicesColumns,
  getOpenServicesColumns,
} from './sales-order-confirm-services-modal.config';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { SalesOrderServicesConfirmModel, SalesPosition } from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { RowClick } from '../../../ui-components/table/custom-table.interface';
import { TableActivateTypes } from '../../../ui-components/table/custom-table.enums';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';
import { TranslateService } from '@ngx-translate/core';

type dataType = {
  salesOrderId: number;
};

@Component({
  selector: 'rnpl-sales-order-confirm-services-modal',
  templateUrl: './sales-order-confirm-services-modal.component.html'
})
export class SalesOrderConfirmServicesModalComponent extends BaseModalComponent implements OnInit {

  public isUpdateQuantity: boolean = false;
  public columnsForOpenServices: TableColumnModelExtended[] = getOpenServicesColumns(this.translateService);
  public columnsForConfirmedServices: TableColumnModelExtended[] = getConfirmedServicesColumns(this.translateService);
  public openServices: SalesPosition[] = [];
  public confirmedServices: SalesPosition[] = [];

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public salesOrderApiService: SalesOrderApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SalesOrderConfirmServicesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dataType
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.salesOrderApiService.getSalesOrderServicesToConfirm(this.data.salesOrderId)
      .pipe(takeUntil(this._destroy))
      .subscribe((response) => {
        this.openServices = response.open;
        this.confirmedServices = response.confirmed;
        this.isLoading$.next(false);
      });
  }

  public onGoToProduct(service: SalesPosition): void {
    window.open(window.location.origin + `/products/product-view/${service.productId}`, '_blank');
  }

  public onTableIconClick(e: RowClick): void {
    if (e.column.clicktype === TableActivateTypes.Rollback) {
      const dialog = this.dialog.open(DangerModalComponent, {
        data: {
          title: 'MODAL.RETURN_PROVIDED_SERVICES',
          message: 'MODAL.RETURN_PROVIDED_SERVICES_MESSAGE',
          confirmBtnText: 'BUTTON.RETURN',
          confirmBtnIcon: 'undo'
        }
      });

      dialog.afterClosed().subscribe(res => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.returnService(e.row.id);
        }
      });
    }
  }

  private returnService(positionId: number): void {
    this.salesOrderApiService.returnSalesOrderService(positionId)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        // update returned service for openServices
        const returnedOpenServiceIndex = this.openServices
          .findIndex((service: SalesPosition) => service.id === response.id);
        if (returnedOpenServiceIndex < 0) {
          this.openServices.push(response); // if not exist - add
        } else {
          this.openServices[returnedOpenServiceIndex] = response; // if exist - update
        }

        // update returned service for confirmedServices
        const returnedConfirmedServiceIndex = this.confirmedServices
          .findIndex((service: SalesPosition) => service.id === response.id);
        if (returnedConfirmedServiceIndex >= 0) {
          this.confirmedServices.splice(returnedConfirmedServiceIndex, 1);
        }

        // reinit lists
        this.openServices = [...this.openServices];
        this.confirmedServices = [...this.confirmedServices];
      });
  }

  public confirmServices(): void {
    const servicesConfirm: SalesOrderServicesConfirmModel = {
      open: this.openServices,
      updateQuantity: this.isUpdateQuantity
    };

    this.salesOrderApiService.confirmSalesOrderServices(this.data.salesOrderId, servicesConfirm)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        this.dialogRef.close(response);
      });
  }

}
