import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InfoItemModel, InfoItemType } from '../models/info-item.model';
import { SendEmailStatusEnum, VatStatusEnum } from '../../../../models';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { Observable } from 'rxjs';
import { EMAIL_SENT_STATUS_CLASSES } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent {

  @Input() public infoItem: InfoItemModel = null;

  @Output() action: EventEmitter<string> = new EventEmitter<string>();

  readonly ITEM_TYPES: typeof InfoItemType = InfoItemType;
  readonly sendEmailStatusEnum = SendEmailStatusEnum;
  readonly currenciesSymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);


  public vatStatusTitles: {[key: string]: string } = {
    [VatStatusEnum.FAILED]: 'VAT.FAILED',
    [VatStatusEnum.PASSED]: 'VAT.PASSED',
    [VatStatusEnum.PENDING]: 'VAT.NOT_VERIFIED',
    [VatStatusEnum.NA]: 'VAT.NOT_VERIFIED',
    [VatStatusEnum.NOT_VERIFIED]: 'VAT.NOT_VERIFIED',
  };

  public vatStatusClasses: {[key: string]: string } = {
    [VatStatusEnum.FAILED]: 'icon--alert icon-alert-circle',
    [VatStatusEnum.PASSED]: 'icon--success icon-checkmark-circle',
    [VatStatusEnum.PENDING]: 'icon--pending-grey icon-question-mark-circle',
    [VatStatusEnum.NA]: 'icon--pending-grey icon-question-mark-circle',
    [VatStatusEnum.NOT_VERIFIED]: 'icon--pending-grey icon-question-mark-circle',
  };

  public emailSentStatusClasses: {[key: string]: string } = EMAIL_SENT_STATUS_CLASSES;

  constructor(
    private readonly store: Store<AppState>,
  ) {}

  public outputEvent(itemLabel: string): void {
    this.action.emit(itemLabel);
  }

}
