import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { SalesOrderModel } from '../../models/sales-order.model';
import { SalesOrderListTabsEnum } from '../../enums';
import { CorporatePartnerModel } from '../../../crm/models';
import { SalesPositionsByProductType } from '../../models/sales-order-position.model';
import { ResponseModel } from '../../../shared/models/response';
import { SummaryInfoModel } from '../../models/summary-info-model';
import { SalesOrdersListState } from '../reducers';

export const LoadSalesOrdersList = createAction(
  '[Sales order] Load Sales orders List',
  props<{ salesOrderListData: SalesOrdersListState, status: SalesOrderListTabsEnum, page: number }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Sales order] Update Sales order should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadSalesOrder = createAction(
  '[Sales order] Load Sales order',
  props<{ salesOrder: SalesOrderModel }>()
);

export const LoadSalesOrderPositions = createAction(
  '[Sales order Positions] Load Sales order Positions',
  props<{ positions: ResponseModel<SalesPositionsByProductType> }>()
);

export const UpdateSalesOrder = createAction(
  '[Sales order] Update Sales order',
  props<{ salesOrder: SalesOrderModel }>()
);

export const UpdateSalesOrderPositionsCount = createAction(
  '[Sales order] Update Sales order Positions Count',
  props<{ positionsCount: number }>()
);

export const ClearSalesOrder = createAction(
  '[Sales order] Clear Sales order'
);

export const ClearSalesOrderState = createAction(
  '[Sales order] Clear Sales order state'
);

export const ClearSalesOrderPositions = createAction(
  '[Sales order] Clear Sales order positions'
);

export const UpdateSalesOrderCurrentState = createAction(
  '[Sales order] Update Sales order Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateSalesOrderSummary = createAction(
  '[Sales order] Update Sales order Summary',
  props<{ summary: SummaryInfoModel }>()
);

export const UpdateSalesOrderExistingInvoice = createAction(
  '[Sales order] Update Sales order existing invoice',
  props<{ isInvoiceExist: boolean }>()
);

export const UpdateSalesOrderStatus = createAction(
  '[Sales order] Update Sales order Status',
  props<{ status: SalesOrderListTabsEnum }>()
);

export const UpdateSalesOrderUpdatedAt = createAction(
  '[Sales order] Update Sales order updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearSalesOrderUpdatedAt = createAction(
  '[Sales order] Clear Sales order updatedAt',
);

export const LoadSalesOrderCountries = createAction(
  '[Sales order] Load Sales order Countries',
  props<{ countries: CountryModel[] }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[Sales order] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Sales order] Decrement Loading Requests Count'
);

export const UpdatePropertiesBlockValid = createAction(
  '[Sales order] Update Sales order Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateDeliveryInfoBlockValid = createAction(
  '[Sales order] Update Sales order Delivery Info Block Valid',
  props<{ deliveryInfoBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Sales order] Update Sales order Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Sales order] Update Sales order Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
