import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';

import { CategoryFamilyModel, FamilyModel, CategoryFamilySelectedEvent } from '../../models';
import { NavBarBtnActionsEnum } from '../../../../models';
import { ProductTypes } from '../../product-types';

@Component({
  selector: 'rnpl-vertical-tree',
  templateUrl: './vertical-tree.component.html',
  styleUrls: ['./vertical-tree.component.scss']
})
export class VerticalTreeComponent implements OnInit, OnChanges {

  @Input()
  public tree: Array<CategoryFamilyModel> = [];

  @Input()
  public entity: 'category'|'family'|'generalCategory'; //'category' or 'family' or 'generalCategory'

  @Input()
  public editable: boolean = false;

  @Input()
  public isLoading: boolean = false;

  @Input()
  public extendable: boolean = false;

  @Output()
  public selected: EventEmitter<CategoryFamilySelectedEvent> = new EventEmitter<CategoryFamilySelectedEvent>();

  /**
   * Emits with parent node
   */
  @Output()
  public createNodeBtnClicked: EventEmitter<CategoryFamilyModel> = new EventEmitter<CategoryFamilyModel>();

  @Output()
  public createProductBtnClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public editNodeBtnClicked: EventEmitter<CategoryFamilyModel> = new EventEmitter<CategoryFamilyModel>();

  @Output()
  public deleteBtnClicked: EventEmitter<CategoryFamilyModel> = new EventEmitter<CategoryFamilyModel>();

  @Output()
  public applyActionClicked: EventEmitter<{action: NavBarBtnActionsEnum, item: any}> = new EventEmitter<{action: NavBarBtnActionsEnum, item: any}>();


  public treeControl: NestedTreeControl<CategoryFamilyModel> = new NestedTreeControl<CategoryFamilyModel>(node => node.children);
  public dataSource = new MatTreeNestedDataSource();
  public actionsEnum = NavBarBtnActionsEnum;

  /**
   * Lets detect that nodes are parent of current node
   */
  public selectedNodes: SelectionModel<CategoryFamilyModel> = new SelectionModel(true);

  /**
   * Links to parents of nodes
   */
  private parentsMap: Map<CategoryFamilyModel, CategoryFamilyModel> = new Map<CategoryFamilyModel, CategoryFamilyModel>();

  public modalTitle: {[key: string]: string} = {
    [ProductTypes.GOODS]: 'BUTTON.CREATE_PRODUCT',
    [ProductTypes.SERVICES]: 'BUTTON.CREATE_SERVICE',
    [ProductTypes.DIGITAL]: 'BUTTON.CREATE_DGT_PRODUCT',
  };

  ngOnInit(): void {
    this.initTree();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('tree')) {
      this.initTree();
    }
  }

  public collapseAll(): void {
    this.selectedNodes.clear();
    this.treeControl.collapseAll();
  }

  public chooseNode(node: CategoryFamilyModel, dropPagination?: boolean): void {
    const ancestryNodes = this.getParents(node);
    this.selectedNodes.clear();
    this.selectedNodes.select(node, ...ancestryNodes);
    this.selectedNodes.selected.forEach(selectedNode => {
      if (node !== selectedNode && !this.treeControl.isExpanded(selectedNode)) {
        this.treeControl.expand(selectedNode);
      }
    });
    this.selected.emit({selectedNode: node, ancestors: ancestryNodes, dropPagination: dropPagination});
  }

  public openNodeById(id): void {
    this.parentsMap.forEach((parent, node) => {
      if (node.id === id) {
        this.chooseNode(node);
        this.treeControl.expand(node);
      }
    });
  }

  /**
   * Create product
   *
   * @param family CategoryFamilyModel - required parameter for product creation
   */
  public createProduct(family: FamilyModel): void {
    this.createProductBtnClicked.emit(family);
  }

  public createNode(node: CategoryFamilyModel): void {
    this.createNodeBtnClicked.emit(node);
  }

  public applyAction(action: NavBarBtnActionsEnum, item: any): void {
    this.applyActionClicked.emit({action, item});
  }

  public editNode(node: CategoryFamilyModel): void {
    this.editNodeBtnClicked.emit(node);
  }

  public deleteNode(node: CategoryFamilyModel): void {
    this.deleteBtnClicked.emit(node);
  }

  public hasChild = (_: number, node: CategoryFamilyModel) => !!node.children && node.children.length;

  /**
   * Gets flat list of parents by the node
   *
   * @param node CategoryFamilyModel
   */
  public getParents(node: CategoryFamilyModel): Array<CategoryFamilyModel> {
    const parent = this.parentsMap.get(node);
    if (!parent) {
      return [];
    }
    return [parent, ...this.getParents(parent)];
  }

  /**
   * Keeps parent nodes at parentsMap
   *
   * @param node current node
   * @param parent parent node of current
   */
  private setParent(node: CategoryFamilyModel, parent?: CategoryFamilyModel): void {
    if (parent) {
      this.parentsMap.set(node, parent);
    } else {
      this.parentsMap.set(node, null);
    }

    if (node.children && node.children.length) {
      node.children.forEach(childNode => {
        this.setParent(childNode, node);
      });
    }
  }

  private initTree(): void {
    this.dataSource.data = this.tree;
    this.parentsMap.clear();

    this.tree.forEach(node => {
      this.setParent(node);
    });
  }

}
