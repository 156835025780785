import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { StockAddressesService } from '../../services';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FilterModel } from '../../models/filter.model';
import { PaginationModel } from 'common/src/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { TabWarehouse } from './warehouse-tab';
import { WarehouseListColumns } from './warehouse-list-columns';
import { MatDialog } from '@angular/material';
import { takeUntil, filter, map, switchMap } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
// tslint:disable-next-line: max-line-length
import {CreateNewWarehouseModalComponent} from 'common/src/modules/modals/modals-warehouse/create-new-warehouse-modal/create-new-warehouse-modal.component';
import { WarehouseTabs } from './enums/wh-tabs.enum';
import { WarehouseSidebarStatus } from './enums/wh-sidebar.enum';
import { TableColumnModel } from 'common/src/models/table-column.model';
import { STATUS_KEY, TABS_CAN_CREATE_WH } from './warehouse-list.constants';
import { get } from 'lodash';
import { wahrehouseResponseHepler } from './heplers/response.helper';
import { DEFAULT_PAGINATION } from '../../../shared/constants';

@Component({
  selector: 'rnpl-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('sidePanelAnimation', [
      state('visible', style({
        width: '304px',
        marginLeft: '16px'
      })),
      state('hidden', style({
        width: '0px',
        marginLeft: '0px'
      })),
      transition('visible => hidden', animate('400ms ease-in-out')),
      transition('hidden => visible', animate('400ms ease-in-out'))
    ])
  ]
})
export class WarehouseListComponent implements OnInit, OnDestroy {
  readonly sidebarState$: BehaviorSubject<WarehouseSidebarStatus>
    = new BehaviorSubject<WarehouseSidebarStatus>(WarehouseSidebarStatus.Hidden);

  readonly activeStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(WarehouseTabs.Active);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly canCreateWarehouse$: Observable<boolean>;
  readonly warehousesList$: BehaviorSubject<WarehouseResponse[]> = new BehaviorSubject([]);

  public columns: TableColumnModel[]  = WarehouseListColumns;
  public tabs: Array<TabDefinitionModel> = TabWarehouse;
  public pagination: PaginationModel = DEFAULT_PAGINATION;

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly stockAddressService: StockAddressesService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toasterService: ToasterService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.canCreateWarehouse$ = this.activatedRoute.paramMap
      .pipe(
        filter((params: ParamMap) => params.has(STATUS_KEY)),
        map((params: ParamMap) => TABS_CAN_CREATE_WH.includes(params.get(STATUS_KEY) as WarehouseTabs))
      );
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(
        switchMap((params: ParamMap) => {
          this.isLoading$.next(true);
          this.activeStatus$.next(params.get(STATUS_KEY));
          this.pagination.page = +params.get('page');
          return this.getWarehouseList({nameColumn: 'status', value: this.activeStatus$.getValue()},
            this.pagination.page.toString());
        }),
        takeUntil(this._destroy)
      )
      .subscribe(
        (data) => {
          this.isLoading$.next(false);
          this.sidebarState$.next(null);
          this.warehousesList$.next(wahrehouseResponseHepler(data.data));
          this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
          this.getWarehouseCounters();
        },
        (errorObject) => {
          this.isLoading$.next(false);
          this.displayMessage(errorObject.error.message || errorObject.message, 'error');
        }
      );
  }

  public getWarehouseList(filter: FilterModel, page: string) {
    return this.stockAddressService.getWarehouseList(filter, page);
  }

  public getWarehouseCounters() {
    this.stockAddressService.getWarehouseCounters()
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(response => {
        this.tabs = this.tabs.map((t: TabDefinitionModel) => ({...t, count: response[t.tabName]}));
        this.cdr.detectChanges();
      },
      errorObject => {
        this.displayMessage(errorObject.error.message || errorObject.message, 'error');
      });
  }

  public createNewWarehouse() {
    const dialogRef = this.dialog.open(CreateNewWarehouseModalComponent, {
      data: {}
    });

    dialogRef.afterClosed()
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(result => {
      if (result) {
        this.displayMessage('New employee created successfully!', 'success');
      }
      this.updateWarehousesList();
    });
  }

  public updateWarehousesList() {
    this.isLoading$.next(true);
    this.stockAddressService.getWarehouseList({nameColumn: 'status', value: this.activeStatus$.getValue()}, this.pagination.page.toString())
      .subscribe(
        (data) => {
          this.isLoading$.next(false);
          this.sidebarState$.next(null);
          this.warehousesList$.next(wahrehouseResponseHepler(data.data));
          this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
          this.getWarehouseCounters();
        },
        (errorObject) => {
          this.isLoading$.next(false);
          this.displayMessage(errorObject.error.message || errorObject.message, 'error');
        }
      );
  }

  public displayMessage(msg: string, type: string): void {
    this.toasterService.notify({
      type,
      message: msg
    });
  }

  public goToPage(page: number): void {
    const url = this.getNewPaginationUrl(this.router.url);

    this.pagination = { ...this.pagination, page: page + 1 };
    this.router.navigate([url, this.pagination.page]);
  }

  public getNewPaginationUrl(oldUrl: string): string {
    return oldUrl.split('/').slice(0, -1).join('/');
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }
}
