import { Component, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UserStatusEnum } from 'common/src/models/user-status.enum';
import { NavBarActionModel } from 'projects/workspace/src/app/hrm/models/nav-bar-action.model';

@Component({
  selector: 'rnpl-nav-bar-actions',
  templateUrl: 'nav-bar-actions.component.html',
  styleUrls: ['./nav-bar-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarActionsComponent { // todo delete

  @Input() status: string = UserStatusEnum.STATUS_ACTIVE;

  @Input() mode: string = 'single';

  @Input() selected: number = 0;

  @Input() showPermanentDelete: boolean = true;

  @Input() deletePermanentlyBtnIsDisabled: boolean = true;

  @Output() action = new EventEmitter<NavBarActionModel>();

  // TODO - Get statuses from Input params
  public statuses = UserStatusEnum;

  changeStatus(status: string) {
    this.action.emit({type: 'change', value: status});
  }

  delete() {
    this.action.emit({type: 'delete'});
  }

}
