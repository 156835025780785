import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DUE_WITHIN_DAYS } from '../../../accounting.constants';

@Component({
  selector: 'rnpl-overdue-notice-due-days-form',
  templateUrl: './overdue-notice-due-days-form.component.html',
})
export class OverdueNoticeDueDaysFormComponent {
  public daysAfterDueDate = DUE_WITHIN_DAYS;

  public daysAfterDueDateDefaultControl: FormControl = new FormControl(0);

  @Input() public showDueDays = false;
  @Input() public extendableColumnSize = true;
  @Input() public controlTitle: string;
  @Input() public dueDaysControl: FormControl;

  public dueDaysChanged(dueDays: number): void {
    if (dueDays) {
      this.dueDaysControl.patchValue(dueDays, {emitEvent: false});
    }
  }

  public getIsDueWithinPredefined(): boolean {
    return [7,14,30,60,90].includes(this.dueDaysControl.value);
  }

  get isDueWithinPredefined(): boolean {
    return this.getIsDueWithinPredefined();
  }

}
