import {
  ExchangeAuthorizationReasonEnum,
  ExchangeListTabsEnum,
  ExchangeRequiredSolutionEnum,
} from './enums';

export const STATUS_KEY = 'status';

export const AUTHORIZATION_REASON_LABELS = {
  [ExchangeAuthorizationReasonEnum.WRONG_PRODUCT]: 'FORM.WRONG_PRODUCT',
  [ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT]: 'FORM.UNEXPECTED_PRODUCT',
  [ExchangeAuthorizationReasonEnum.MISSING_PRODUCT]: 'FORM.MISSING_PRODUCT',
  [ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT]: 'FORM.DAMAGED_PRODUCT',
  [ExchangeAuthorizationReasonEnum.PRODUCT_DOESNT_FIT]: 'FORM.PRODUCT_DOES_NOT_FIT',
};

export const REQUIRED_SOLUTION_LABELS = {
  [ExchangeRequiredSolutionEnum.REPLACE_PRODUCT]: 'FORM.REPLACE_PRODUCT',
  [ExchangeRequiredSolutionEnum.MONEY_RETURN]: 'FORM.RETURN',
  [ExchangeRequiredSolutionEnum.SELL_DELIVERED_PRODUCT]: 'FORM.SELL_DERIVED_PRODUCT',
  [ExchangeRequiredSolutionEnum.RETURN]: 'FORM.RETURN',
  [ExchangeRequiredSolutionEnum.EXCHANGE]: 'FORM.EXCHANGE',
  [ExchangeRequiredSolutionEnum.SELL_PRODUCT]: 'FORM.SELL_PRODUCT',
  [ExchangeRequiredSolutionEnum.SHIP_PRODUCT]: 'FORM.SHIP_PRODUCT',
  [ExchangeRequiredSolutionEnum.GIVE_COMPENSATION]: 'FORM.GIVE_COMPENSATION',
};

export const STATUS_CLASSES: {[key: string]: string} = {
  // [ExchangeListTabsEnum.PENDING]: 'rnpl-badge--yellow',
  [ExchangeListTabsEnum.DRAFT]: 'rnpl-badge--grey',
  [ExchangeListTabsEnum.DELETED]: 'rnpl-badge--empty',
  [ExchangeListTabsEnum.OPEN]: 'rnpl-badge--blue',
  [ExchangeListTabsEnum.COMPLETED]: 'rnpl-badge--green',
  [ExchangeListTabsEnum.CANCELED]: 'rnpl-badge--red',
  ['editing']: 'rnpl-badge--main-400',
};
