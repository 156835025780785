export interface AttributeModel {
  id?: number;
  name: string;
  type: string;
  required: boolean;
  printable: boolean;
  control: string;
  options: Array<any>;
  localizations: Array<any>;
  translations: AttributeTranslationModel[];
  business_form_relations: Array<any>;
  family_relations: Array<any>;
  attributes?: string[]
}

export interface AttributeTranslationModel {
  value: string;
  language?: any;
}

export class AttributeLayoutModel {
  size: string;
  isLast?: boolean;
}

export interface AssignedAttributeModel {
  attribute?: AttributeModel;
  layout?: AttributeLayoutModel;
  properties?: number[]
}
