import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BACKGROUND_CONTENT, TimeOfDay } from '../modals-sign-in/sign-in.config';
import { LaunchpadApiService } from '../../start-screen-page/launchpad-api.service';

@Component({
  selector: 'rnpl-launchpad-modal',
  templateUrl: './launchpad-modal.component.html',
  styleUrls: ['./launchpad-modal.component.scss'],
})
export class LaunchpadModalComponent implements OnInit, OnDestroy {

  public backGroundContent = BACKGROUND_CONTENT;
  public timeOfDay = TimeOfDay();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<LaunchpadModalComponent>,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
