import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TableColumnModelExtended } from '../../../../../models/table-column.model';
import { getLinkByType } from './helpers/link-by-type';

@Component({
  selector: 'rnpl-column-link',
  templateUrl: './column-link.component.html',
  styleUrls: ['./column-link.component.scss']
})
export class ColumnLinkComponent {

  @Input()
  value: string | number;

  @Input()
  column: TableColumnModelExtended;

  @Input() set row(row: any) {
    this.routerLink$.next(getLinkByType(row.id || row.documentId, row.documentType));
  }

  readonly routerLink$: BehaviorSubject<string> = new BehaviorSubject('');

}
