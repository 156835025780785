import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from './auth.service';

/**
 * Adds token to request headers and redirects to sign in page on 401 http error
 */
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {
  }

  /**
   * Adds token to request headers
   *
   * @param request HttpRequest
   * @param next HttpHandler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('https:') && request.url.includes('s3')) {
      return next.handle(request);
    }

    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {Authorization: `Bearer ${token}`}
      });
    }

    return next.handle(request)
      .pipe(tap(() => {}, this.handleHttpError.bind(this)));
  }

  /**
   * Redirects to sign in page on 401 http errors
   *
   * @param error HttpErrorResponse|any
   */
  handleHttpError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.authService.signOut();
      }
    }
  }

}
