import { Pipe, PipeTransform } from '@angular/core';
import { ProductTypes } from '../../products/product-types';

@Pipe({name: 'productTypeIcon'})
export class ProductTypeIconPipe implements PipeTransform {
  transform(productType: ProductTypes, ...args: any[]): string {
    let iconName: string;

    switch (productType) {
      case ProductTypes.GOODS:
        iconName = 'box';
        break;
      case ProductTypes.SERVICES:
        iconName = 'checklist';
        break;
      case ProductTypes.DIGITAL:
        iconName = 'digital-product';
        break;
      case ProductTypes.ARBITRARY:
        iconName = 'user-defined';
        break;
      case ProductTypes.CORRECTION:
        iconName = 'summary';
        break;
      case ProductTypes.CREDIT:
        iconName = 'checkbox-off';
        break;
      case ProductTypes.OVERDUE_FEE:
        iconName = 'overdue';
        break;
      case ProductTypes.INVOICE:
      case ProductTypes.PREPAYMENT:
        iconName = 'outgoing-invoice';
        break;
      default:
        iconName = 'box';
        break;
    }

    return iconName;
  }
}
