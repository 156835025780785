export enum PdfSettingsFontEnum {
  INTER = 'INTER',
  COMFORTAA = 'COMFORTAA',
  COMFORTAA_REGULAR = 'COMFORTAA_REGULAR',
  COMFORTAA_LIGHT = 'COMFORTAA_LIGHT',
  COMFORTAA_BOLD = 'COMFORTAA_BOLD',
  SANS = 'SANS',
  SANS_SERIF = 'SANS_SERIF',
  CRIMSON_PRO = 'CRIMSON_PRO',
  CRIMSON_PRO_REGULAR = 'CRIMSON_PRO_REGULAR',
  CRIMSON_PRO_LIGHT = 'CRIMSON_PRO_LIGHT',
  CRIMSON_PRO_BOLD = 'CRIMSON_PRO_BOLD',
  POPPINS = 'POPPINS',
  POPPINS_BOLD = 'POPPINS_BOLD',
  POPPINS_LIGHT = 'POPPINS_LIGHT',
  POPPINS_REGULAR = 'POPPINS_REGULAR',
  GLUTEN = 'GLUTEN',
  GLUTEN_LIGHT = 'GLUTEN_LIGHT',
  GLUTEN_REGULAR = 'GLUTEN_REGULAR',
  GLUTEN_BOLD = 'GLUTEN_BOLD',
  JET_BRAINS_MONO = 'JET_BRAINS_MONO',
  JET_BRAINS_MONO_BOLD = 'JET_BRAINS_MONO_BOLD',
  JET_BRAINS_MONO_LIGHT = 'JET_BRAINS_MONO_LIGHT',
  JET_BRAINS_MONO_REGULAR = 'JET_BRAINS_MONO_REGULAR',
  JOSEFIN_SANS = 'JOSEFIN_SANS',
  JOSEFIN_SANS_BOLD = 'JOSEFIN_SANS_BOLD',
  JOSEFIN_SANS_LIGHT = 'JOSEFIN_SANS_LIGHT',
  JOSEFIN_SANS_REGULAR = 'JOSEFIN_SANS_REGULAR',
  OXANIUM = 'OXANIUM',
  OXANIUM_BOLD = 'OXANIUM_BOLD',
  OXANIUM_LIGHT = 'OXANIUM_LIGHT',
  OXANIUM_REGULAR = 'OXANIUM_REGULAR',
  TEXTURIA = 'TEXTURIA',
  TEXTURIA_BOLD = 'TEXTURIA_BOLD',
  TEXTURIA_LIGHT = 'TEXTURIA_LIGHT',
  TEXTURIA_REGULAR = 'TEXTURIA_REGULAR',
  BIO_RHYME_EXPANDED_BOLD = 'BIO_RHYME_EXPANDED_BOLD',
  BIO_RHYME_EXPANDED_LIGHT = 'BIO_RHYME_EXPANDED_LIGHT',
  BIO_RHYME_EXPANDED_REGULAR = 'BIO_RHYME_EXPANDED_REGULAR',
}
