import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';

import { TradeOfferModel } from 'projects/workspace/src/app/trade-offer/models';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';

@Component({
  selector: 'rnpl-link-offer-modal',
  templateUrl: './link-offer-modal.component.html',
  styleUrls: ['./link-offer-modal.component.scss']
})
export class LinkOfferModalComponent extends BaseModalComponent implements OnInit {

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public offersList: TradeOfferModel[] = [];

  public selectedOffer: TradeOfferModel = null;

  constructor(
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<LinkOfferModalComponent>,
    private tradeOfferApiService: TradeOfferApiService,
    private salesOrderApi: SalesOrderApiService,
    @Inject(MAT_DIALOG_DATA) public data: {salesOrderId: number; }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.getOffer();
  }

  private getOffer(): void {
    this.showDropdownSpin$.next(true);
    this.tradeOfferApiService.getActiveOffers()
      .pipe(takeUntil(this._destroy))
      .subscribe((offers: TradeOfferModel[]) => {
        this.offersList = offers;
      });
  }

  public linkOffer(): void {
    this.salesOrderApi
      .linkOffer(this.data.salesOrderId, this.selectedOffer.id)
      .pipe(takeUntil(this._destroy))
      .subscribe(this.subscriberHandler, this.handleError);
  }

  public subscriberHandler = (response: SalesOrderModel) => {
    this.dialogRef.close(response);
  }

  public handleError = error => {
    this.displayMessage('error', error.error.message);
  }

}
