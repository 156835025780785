import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { PurposeFormComponent } from './purpose-form.component';

@NgModule({
  declarations: [
    PurposeFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [PurposeFormComponent],
  entryComponents: [PurposeFormComponent],
})
export class PurposeFormModule { }
