import {TableColumnModelExtended } from '../../../../models/table-column.model';
import { TranslateService } from '@ngx-translate/core';

export function getTableColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center',
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.SERVICE_ID',
      prop: 'productLink',
      cellClass: 'link-primary',
      sortable: true,
      width: 172,
      maxWidth: 172,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.SERVICE_NAME',
      prop: 'product.name',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PROVIDED_SERVICES',
      prop: 'product.providedServices',
      sourceTextFormat: true,
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.LOGGED_TIME',
      prop: 'loggedDuration',
      // additionalText: translateService.instant('UNITS.H'),
      sortable: true,
      currencyConversion: true,
      currencySourceFormat: true,
      unitTypeShortcut: true,
      decimal: 0,
      cellClass: 'right font-weight-bold',
      headerClass: 'right',
      width: 136,
      maxWidth: 136,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PERIOD',
      prop: 'rawTrackedTime.periodString',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      sourceTextFormat: true,
      sortable: true,
    },
    {
      cellTemplate: 'withSelectionToggle',
      headerTemplate: 'headerSelectionsToggle',
      cellClass: 'center',
      headerClass: 'center px-0',
      name: 'selection',
      width: 60,
      maxWidth: 60,
      sortable: false,
      resizeable: false,
    }
  ];
}
