import { CreditNotePurposeEnum, CreditNotesListTabsEnum } from './enums';
import { PaymentDirectionEnum } from '../payment/models/payment.model';
import { UIStatesEnum } from 'common/src/models';

export function creditNotesHintsList(
  currentState?: UIStatesEnum,
  purpose?: CreditNotePurposeEnum,
  direction?: PaymentDirectionEnum,
  hasLinkedInvoice?: boolean,
  hasLinkedPayment?: boolean,
  activeTab?: CreditNotesListTabsEnum | string,
  hasCreditNotes?: boolean,
  displayUploadBtnHint?: boolean,
  displayIbanHint?: boolean,
  displayFieldValidationHint?: boolean,
  hasLinkedEra?: boolean,
  showDocumentBar?: boolean,
  displayVatCheckHint?: boolean
) {
  const isReadOnly = currentState === UIStatesEnum.VIEW;
  const isEditState = currentState === UIStatesEnum.EDIT;

  const isCommonCorrection = purpose === CreditNotePurposeEnum.COMMON_CORRECTION;
  const isIinCorrection = purpose === CreditNotePurposeEnum.IIN_CORRECTION;

  const directionIsIncoming = direction === PaymentDirectionEnum.INCOMING;
  const directionIsOutgoing = direction === PaymentDirectionEnum.OUTGOING;

  const isDraftStatus = activeTab === CreditNotesListTabsEnum.DRAFT;
  const isOpenStatus = activeTab === CreditNotesListTabsEnum.OPEN;
  const isPaidStatus = activeTab === CreditNotesListTabsEnum.PAID;
  const isCanceledStatus = activeTab === CreditNotesListTabsEnum.CANCELED;
  const isDeletedStatus = activeTab === CreditNotesListTabsEnum.DELETED;

  const hintsList = {
    ['credit_notes_add_position_1']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_7',
      attachTo: {
        element: '#positionsCreditNoteModal',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -5] }}]
      },
      classes: 'from-modal',
    },
    ['credit_notes_add_position_2']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_8',
      attachTo: {
        element: '#toggle_selection_head',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 70] }}]
      },
      classes: 'from-modal ml--15',
    },
    ['create_entity_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_22',
      attachTo: {
        element: '#createCreditNoteBtn',
        on: 'bottom'
      },
    },
    ['filter_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_27',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
    // ['clone_entyty_button_credit_notes']: {
    //   text: 'MEGA_HINT.CREDIT_NOTE.HINT_31',
    //   attachTo: {
    //     element: '#cloneBtn',
    //     on: 'bottom'
    //   },
    // },
  }

  const doneBtnHint = {
    ['done_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_33',
      attachTo: {
        element: '#doneBtn',
        on: 'bottom'
      },
    },
  }

  const editBtnHint = {
    ['edit_entity_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_32',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
  }

  const checkVatHInt = {
    ['vat_indicator_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_34',
      attachTo: {
        element: '#vatCheckedField',
        on: 'bottom'
      },
    },
  }

  const openViewStateHints = {
    ['cancel_entity_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_30',
      attachTo: {
        element: '#cancelBtn',
        on: 'bottom'
      },
    },
  }

  const ibanHint = {
    ['credit_notes_view_draft_4']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_6',
      attachTo: {
        element: '#bankAccountIban',
        on: 'bottom'
      },
    },
  }

  // const ibanIncomingCorrectionHint = {
  //   ['credit_notes_view_draft_6']: {
  //     text: 'MEGA_HINT.CREDIT_NOTE.HINT_10',
  //     attachTo: {
  //       element: '#iban',
  //       on: 'bottom'
  //     },
  //   },
  // }

  const bookPaymentBtnHint = {
    ['credit_notes_view_open_1']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_14',
      attachTo: {
        element: '#paymentStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -17] }}]
      },
    },
  }

  const emailCheckHint = {
    ['email_indicator_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_20',
      attachTo: {
        element: '#emailSendCheckedField',
        on: 'bottom'
      },
    },
  }

  const exportBtnHint = {
    ['export_send_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_25',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const exportListBtnHint = {
    ['export_button_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_16',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const totalRowHint = {
    ['total_row_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_23',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }

  const editState = {
    ['save_timer_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_31',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const commonPurposeEditStateHints = {
    ['credit_notes_view_draft_1']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_3',
      attachTo: {
        element: '#propertiesBlock',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -30] }}]
      },
    },
  }
  const incomingPurposeEditStateHints = {
    ['credit_notes_view_draft_5']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_9',
      attachTo: {
        element: '#propertiesBlock',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -30] }}]
      },
    },
  }
  const outgoingPurposeEditStateHints = { //todo: fix hide element
    ['credit_notes_view_draft_2']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_4',
      attachTo: {
        element: '#propertiesBlock',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -30] }}]
      },
    },
  }

  const uploadFileBtnHint = {
    ['credit_notes_view_draft_7']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_11',
      attachTo: {
        element: '#uploadFileBtn',
        on: 'bottom'
      },
    },
  }

  const sourceSelectHint = {
    ['credit_notes_view_draft_3']: { //todo: fix hide element
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_5',
      attachTo: {
        element: '#propertiesBlock',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -120] }}]
      },
    },
  }

  const fieldValidationHInt = {
    ['field_validation_credit_notes']: { // todo: fix hide element
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_24',
      attachTo: {
        element: '#propertiesBlock',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -120] }}]
      },
    },
  }

  const directionSelectHint = {
    ['credit_notes_view_draft_8']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_12',
      attachTo: {
        element: '#propertiesBlock',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -120] }}]
      },
    },
  }
  const incomingDirectionSelectHint = {
    ['credit_notes_view_draft_9']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_13',
      attachTo: {
        element: '#directionSelect',
        on: 'bottom'
      },
    },
  }

  const invoiceStepHint = {
    ['linked_based_document_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_19',
      attachTo: {
        element: '#invoiceStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -17] }}]
      },
    },
  }

  const linkedPaymentHint = {
    ['linked_document_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_21',
      attachTo: {
        element: '#paymentStep',
        on: 'bottom'
      },
    },
    ['linked_document_payment_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_21',
      attachTo: {
        element: '#paymentStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, (!isPaidStatus && isReadOnly) ? - 60 : -17] }}]
      },
    },
  }

  const openTabColumnHints = {
    ['credit_notes_tab_open_2']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_1',
      attachTo: {
        element: '#openAmountColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const openTabHint = {
    ['credit_notes_tab_open_1']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_28',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
  }

  const paidTabHint = {
    ['credit_notes_tab_paid_1']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_2',
      attachTo: {
        element: '#paidTab',
        on: 'bottom'
      },
    },
  }

  const canceledTab = {
    ['tab_cancel_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_17',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
    },
  }

  const draftTabHint = {
    ['tab_draft_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_15',
      attachTo: {
        element: '#draftTab',
        on: 'bottom'
      },
    },
  }

  const deletedTabHint = {
    ['tab_deleted_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_29',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  const sidebarHint = {
    ['sidebar_credit_notes']: {
      text: 'MEGA_HINT.CREDIT_NOTE.HINT_18',
      attachTo: {
        element: '#sidebarCreditNote',
        on: 'left'
      },
    },
  }

  if (hasCreditNotes) {
    Object.assign(hintsList, exportListBtnHint);
  }

  if (!isCommonCorrection && !isReadOnly) {
    Object.assign(hintsList, sourceSelectHint);

    if (displayFieldValidationHint) {
      Object.assign(hintsList, fieldValidationHInt);
    }
  }

  if (!isReadOnly) {
    Object.assign(hintsList, editState);
  }

  if (hasLinkedInvoice && !hasLinkedEra && !isDraftStatus && showDocumentBar) {
    Object.assign(hintsList, invoiceStepHint);
  }

  if (directionIsIncoming && displayUploadBtnHint && !isReadOnly) {
    Object.assign(hintsList, uploadFileBtnHint);
  }

  if (isCommonCorrection && !isReadOnly && directionIsOutgoing) {
    Object.assign(hintsList, directionSelectHint);
  }

  if (isCommonCorrection && !isReadOnly && directionIsIncoming) {
    Object.assign(hintsList, incomingDirectionSelectHint);
  }

  if (hasLinkedPayment) {
    Object.assign(hintsList, linkedPaymentHint);
  }

  if (isOpenStatus && hasCreditNotes) {
    Object.assign(hintsList, openTabColumnHints);
  }
  if (isPaidStatus) {
    Object.assign(hintsList, paidTabHint);
  }
  if (isCanceledStatus) {
    Object.assign(hintsList, canceledTab);
  }
  if (isDraftStatus) {
    Object.assign(hintsList, draftTabHint);

    if (!isReadOnly) {
      Object.assign(hintsList, sidebarHint);
    }
  }
  if (isOpenStatus) {
    Object.assign(hintsList, openTabHint);
  }
  if (isDeletedStatus) {
    Object.assign(hintsList, deletedTabHint);
  }
  if (
    !isDeletedStatus && isReadOnly && !isIinCorrection && directionIsOutgoing
  ) {
    Object.assign(hintsList, exportBtnHint);
  }
  if (!isPaidStatus && !isCanceledStatus && !isDeletedStatus && isReadOnly && showDocumentBar) {
    Object.assign(hintsList, bookPaymentBtnHint);
  }
  if (isOpenStatus && isReadOnly) {
    Object.assign(hintsList, openViewStateHints);
  }
  if (!isCanceledStatus && !isDeletedStatus && hasCreditNotes) {
    Object.assign(hintsList, totalRowHint);
  }
  if (directionIsOutgoing && !isDraftStatus && showDocumentBar ) {
    Object.assign(hintsList, emailCheckHint);
  }
  if (displayIbanHint && ((purpose === CreditNotePurposeEnum.COMMON_CORRECTION && directionIsIncoming) || purpose === CreditNotePurposeEnum.IIN_CORRECTION)) {
    Object.assign(hintsList, ibanHint);
  }
  // if (displayIbanHint && directionIsIncoming) {
  //   Object.assign(hintsList, ibanIncomingCorrectionHint);
  // }
  if ((isDraftStatus || isOpenStatus) && isReadOnly) {
    Object.assign(hintsList, editBtnHint);
  }
  if (purpose === CreditNotePurposeEnum.IIN_CORRECTION && !isReadOnly) {
    Object.assign(hintsList, incomingPurposeEditStateHints);
  }
  if (purpose === CreditNotePurposeEnum.COMMON_CORRECTION && !isReadOnly) {
    Object.assign(hintsList, commonPurposeEditStateHints);
  }
  if (purpose === CreditNotePurposeEnum.OIN_CORRECTION  && !isReadOnly) {
    Object.assign(hintsList, outgoingPurposeEditStateHints);
  }
  if ((isOpenStatus || isDraftStatus) && isEditState) {
    Object.assign(hintsList, doneBtnHint);
  }
  if (displayVatCheckHint && !isDraftStatus && !isDeletedStatus) {
    Object.assign(hintsList, checkVatHInt);
  }

  return hintsList;
}

