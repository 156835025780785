export const DOUGHNUT_BACKGROUNDS = [
  '#202431',
  '#30364A',
  '#5E6681',
  '#B5BBCE',
  '#CDD1DF',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
  '#E0E2EB',
];
