import { AfterViewChecked, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { TimeTrackingCreateOinPreferenceEnum } from 'projects/workspace/src/app/time-tracking/enums';
import { TimeTrackingBillingScheduleModel } from 'projects/workspace/src/app/time-tracking/models';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { PaymentMethodsEnum, SendEmailMethodEnum } from '../../../../models';
import { BILLING_FREQUENCY_LIST, BILLING_TIME_LIST, getTableColumns } from './time-tracking-billing-schedule-modal.config';
import { TableActivateTypes } from '../../../ui-components/table/custom-table.enums';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { TableColumnModelExtended } from '../../../../models/table-column.model';



@Component({
  selector: 'rnpl-time-tracking-billing-schedule-modal',
  templateUrl: './time-tracking-billing-schedule-modal.component.html'
})
export class TimeTrackingBillingScheduleModalComponent extends BaseModalComponent implements OnInit, AfterViewChecked {

  public form: FormGroup;
  public selectedCustomer: any;
  public excludedCustomers: any[] = [];
  public columns: TableColumnModelExtended[] = getTableColumns(this.translateService);

  public sendEmailMethod: typeof SendEmailMethodEnum = SendEmailMethodEnum;
  public paymentMethods: typeof PaymentMethodsEnum = PaymentMethodsEnum;
  public timeTrackingCreateOinPreference: typeof TimeTrackingCreateOinPreferenceEnum = TimeTrackingCreateOinPreferenceEnum;
  public emptyStateTypeEnum = EmptyStateTypeEnum;

  public billingFrequencyList = BILLING_FREQUENCY_LIST;
  public readonly billingTimeList = BILLING_TIME_LIST;

  @ViewChild('groupingForm', {static: false}) groupingFormRef;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly customers$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    private timeTrackingApiService: TimeTrackingApiService,
    public dialogRef: MatDialogRef<TimeTrackingBillingScheduleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      schedule: TimeTrackingBillingScheduleModel
    }
  ) {
    super(toasterService);
  }

  ngOnInit(): void {
    this.initForm();
    this.form.patchValue(this.data.schedule);
    const excludedPartners = get(this, 'data.schedule.excludedPartners') || [];
    this.excludedCustomers = [...excludedPartners.sort((a, b) => b.id - a.id)];

    this.timeTrackingApiService.getScheduleBillingPartnersList()
      .pipe(takeUntil(this._destroy))
      .subscribe(res => {
        const partners = res.map(partner => ({
            ...partner,
            documentLink: {
              label: partner.partner.runpleId,
              routerLink: `/partners-new/corporate/${partner.partner.id}`,
            },
          }));

        this.customers$.next(partners);
      });
  }

  ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'));
  }

  public initForm(): void {
    this.form = this.fb.group({
      enabled: [],
      billingFrequency: [],
      billingTime: [],
      enableWeekendManagement: [],
      createOinPreference: [],
      paymentMethod: [],
      emailPreference: [],
      excludeListEnabled: [],
    });
  }

  public rowClickReceiver(event): void {
    if (event.type === TableActivateTypes.DeleteRow) {
      const customer = event.row;

      const indexCustomerToRemove = this.excludedCustomers
        .findIndex(itm => itm.partner.id === customer.partner.id && itm.partner.type === customer.partner.type);
      this.excludedCustomers.splice(indexCustomerToRemove, 1);
      this.excludedCustomers = [...this.excludedCustomers];

      const newCustomers = [...this.customers$.getValue(), customer];
      this.customers$.next(newCustomers);
    }
  }

  public excludePartner(): void {
    if (this.selectedCustomer) {
      if (
        this.excludedCustomers.find(customer => {
          return +customer.partner.id === +this.selectedCustomer.partner.id &&
            customer.partner.runpleId === this.selectedCustomer.partner.runpleId;
        })
      ) {
        return;
      }

      this.excludedCustomers.unshift(this.selectedCustomer);
      this.excludedCustomers = [...this.excludedCustomers];

      const availableCustomers = this.customers$.getValue();

      const indexCustomerToRemove = availableCustomers
        .findIndex(itm => itm.partner.id === this.selectedCustomer.partner.id && itm.partner.type === this.selectedCustomer.partner.type);

      availableCustomers.splice(indexCustomerToRemove, 1);
      this.customers$.next([...availableCustomers]);
      this.selectedCustomer = null;
    }
  }

  public submitScheduleBilling(): void {
    const schedule: Partial<TimeTrackingBillingScheduleModel> = {
      ...this.data.schedule,
      ...this.form.getRawValue(),
      ...(this.groupingFormRef && this.groupingFormRef.getFormData()),
      excludedPartners: this.excludedCustomers
    };

    this.timeTrackingApiService.updateScheduleBilling(schedule)
      .pipe(takeUntil(this._destroy))
      .subscribe(res => this.dialogRef.close(res));
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.partner.name.toLocaleLowerCase().indexOf(term) > -1 ||
      item.partner.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  get enabledControl(): FormControl { return this.form.get('enabled') as FormControl; }
  get enableWeekendManagementControl(): FormControl { return this.form.get('enableWeekendManagement') as FormControl; }
  get excludeListEnabledControl(): FormControl { return this.form.get('excludeListEnabled') as FormControl; }
  get emailPreferenceControl(): FormControl { return this.form.get('emailPreference') as FormControl; }
  get paymentMethodControl(): FormControl { return this.form.get('paymentMethod') as FormControl; }
  get createOinPreferenceControl(): FormControl { return this.form.get('createOinPreference') as FormControl; }
  get billingTimeControl(): FormControl { return this.form.get('billingTime') as FormControl; }
  get billingFrequencyControl(): FormControl { return this.form.get('billingFrequency') as FormControl; }

}
