import {TableColumnModelExtended } from '../../../../models/table-column.model';
import {
  TimeTrackingBillingFrequencyEnum,
  TimeTrackingBillingTimeEnum,
} from 'projects/workspace/src/app/time-tracking/enums';
import { TableActivateTypes } from '../../../ui-components/table/custom-table.enums';
import { TranslateService } from '@ngx-translate/core';

export const BILLING_FREQUENCY_LIST: {value: TimeTrackingBillingFrequencyEnum; label: string}[] = [
  {value: TimeTrackingBillingFrequencyEnum.MONTH, label: 'COMMON.MONTHLY'},
  {value: TimeTrackingBillingFrequencyEnum.QUARTER, label:  'FORM.QUARTER'},
  {value: TimeTrackingBillingFrequencyEnum.SIX_MONTH, label: 'COMMON.HALF_YEAR'},
  {value: TimeTrackingBillingFrequencyEnum.YEAR, label: 'FORM.YEAR'},
];

export const BILLING_TIME_LIST: {value: TimeTrackingBillingTimeEnum; label: string}[] = [
  {value: TimeTrackingBillingTimeEnum.FIRST_DAY, label: 'COMMON.FIRST_DAY_BILLING'},
  {value: TimeTrackingBillingTimeEnum.LAST_DAY, label: 'COMMON.LAST_DAY_BILLING'},
];

export function getTableColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center',
    },
    {
      cellTemplate: 'withCorporatePartner',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary cursor-pointer',
      name: 'COLUMN.CUSTOMER_NAME',
      prop: 'partner',
      sortable: true,
      clicktype: TableActivateTypes.Link,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.OPEN_TIME',
      prop: 'openTIme',
      sortable: true,
      currencyConversion: true,
      currencySourceFormat: true,
      additionalText: translateService.instant('UNITS.H'),
      headerClass: 'right',
      width: 136,
      maxWidth: 136,
    },
    {
      cellTemplate: 'withClickableIcon',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.DeleteRow,
      frozenRight: true,
      icon: 'minus-circle',
      cellClass: 'remove-btn-grey'
    },
  ];
}
