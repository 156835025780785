import { FlagsEnum } from 'common/src/models/flags.enum';
import { ScheduledPaymentBillingFrequencyEnum, ScheduledPaymentDurationEnum, ScheduledPaymentListTabsEnum } from './enums';

export const STATUS_KEY = 'status';

export const STATUS_CLASSES: {[key: string]: string} = {
  [ScheduledPaymentListTabsEnum.DRAFT]: 'rnpl-badge--grey',
  [ScheduledPaymentListTabsEnum.OPEN]: 'rnpl-badge--blue',
  [ScheduledPaymentListTabsEnum.COMPLETED]: 'rnpl-badge--green',
  [ScheduledPaymentListTabsEnum.CANCELED]: 'rnpl-badge--red',
  [ScheduledPaymentListTabsEnum.DELETED]: 'rnpl-badge--empty',
  [FlagsEnum.EDITING]: 'rnpl-badge--main-400',
  [FlagsEnum.OVERDUE]: 'rnpl-badge--yellow',
};

export const BILLING_FREQUENCY_LIST: {value: ScheduledPaymentBillingFrequencyEnum; label: string}[] = [
  {value: ScheduledPaymentBillingFrequencyEnum.MONTHLY, label: 'COMMON.MONTHLY'},
  {value: ScheduledPaymentBillingFrequencyEnum.EVERY_3MONTH, label:  'COMMON.QUARTERLY'},
  {value: ScheduledPaymentBillingFrequencyEnum.EVERY_6MONTH, label: 'COMMON.HALF_YEARLY'},
  {value: ScheduledPaymentBillingFrequencyEnum.ANNUALLY, label: 'COMMON.ANNUALLY'},
  {value: ScheduledPaymentBillingFrequencyEnum.SINGLE, label: 'SCHEDULED_PAYMENT.SINGLE_PAYMENT'},
];

export const DURATION_LIST: {value: ScheduledPaymentDurationEnum; label: string}[] = [
  // {value: ScheduledPaymentDurationEnum.MONTH, label: 'FORM.MONTH'},
  // {value: ScheduledPaymentDurationEnum.QUARTER, label:  'FORM.QUARTER'},
  // {value: ScheduledPaymentDurationEnum.HALF_YEAR, label: 'FORM.HALF_YEAR'},
  // {value: ScheduledPaymentDurationEnum.YEAR, label: 'FORM.YEAR'},
  {value: ScheduledPaymentDurationEnum.UNLIMITED, label: 'FORM.UNLIMITED'},
  // {value: ScheduledPaymentDurationEnum.SINGLE, label: 'SCHEDULED_PAYMENT.SINGLE_PAYMENT'},
  {value: ScheduledPaymentDurationEnum.CUSTOM, label: 'FORM.SPECIFY_END_DATE'},
];
