import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectUserQuickActions } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { quickActionsConfig } from '../../modals/modals-control-center/shortcuts-modal/user-shortcuts.helper';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ActionButtonsService } from '../../../services/action-buttons.service';
import { QuickActionsModalComponent } from '../../modals/quick-actions-modal/quick-actions-modal.component';

@Component({
  selector: 'rnpl-quick-actions',
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.scss']
})
export class QuickActionsComponent implements OnDestroy {

  @Input() public btnClass: string;

  public activatedQuickActions = []; // todo model

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);


  constructor(
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly actionButtonService: ActionButtonsService,
    private readonly router: Router,
  ) {
    this.store.select(selectUserQuickActions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(shortcuts => {
        this.activatedQuickActions = shortcuts.activated.map(itm => quickActionsConfig[itm]);
      });
  }

  public openShortcutsSettingsModal(): void {
    this.dialog.open(QuickActionsModalComponent, {
      minWidth: '700px',
      maxWidth: '700px',
      disableClose: true
    });
  }

  public actionHandler(action: string, routerLink: string = '', event: Event): void {
    event.stopPropagation();
    if (!!routerLink) {
      this.navigateToShortcutLink(routerLink);
      return;
    }

    this.actionButtonService.callAction(action);
  }

  public navigateToShortcutLink(routerLink: string): void {
    this.router.navigate([routerLink]);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
