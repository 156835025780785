import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';

import { NavBarBtnModel } from '../../../models';

@Component({
  selector: 'rnpl-nav-bar-actions-new',
  templateUrl: './nav-bar-actions-new.component.html',
  styleUrls: ['./nav-bar-actions-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavBarActionsNewComponent implements AfterViewInit, OnChanges {

  public initReady = false;
  public _buttons: NavBarBtnModel[] = [];

  @Input() status: string = null; // required to pass (with null all buttons will be hidden)

  @Input() mode: string = 'single';

  @Input() selected: number = 0;

  @Input() set buttons(buttons: NavBarBtnModel[]) {
    this._buttons = [...buttons.map(btn => (
      {
        ...btn,
        actions: btn.actions && btn.actions.length
          ? btn.actions.filter(action => !action.hasOwnProperty('showCondition') || action.showCondition)
          : null
      }
    ))];
  }

  @Input() btnClearLoadingStatus: string;

  @Output() action = new EventEmitter<string>();
  @Output() clickActionWithValue = new EventEmitter<{ actionName: string, returnValue: any }>();
  @Output() loadingStatusCleared = new EventEmitter<null>();

  constructor(private readonly cdr: ChangeDetectorRef) { }

  @TrackInputChanges<string>('btnClearLoadingStatus', 'handleBtnClearLoadingStatus', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit(): void {
    this.initReady = true;
    this.cdr.detectChanges();
  }

  public handleBtnClearLoadingStatus(): void {
    if (!this._buttons || !this.btnClearLoadingStatus) { return; }
    const selectedButtons = this._buttons.filter((btn) => btn.actionName === this.btnClearLoadingStatus);
    if (selectedButtons.length) {
      selectedButtons.forEach(btn => {
        this.changeBtnLoadingStatus(btn, false);
      });
      this.loadingStatusCleared.emit();
    }
  }

  public clickAction(btn: NavBarBtnModel): void {
    if (!btn.disabled && !btn.isLoading) {
      this.action.emit(btn.actionName);
      if (btn.allowLoadingSpinner) {
        this.changeBtnLoadingStatus(btn, true);
      }
    }
  }

  public clickButtonDropdownAction(actionName: string, btn: NavBarBtnModel): void {
    if (!btn.disabled && !btn.isLoading) {
      this.action.emit(actionName);
      if (btn.allowLoadingSpinner) {
        this.changeBtnLoadingStatus(btn, true);
      }
    }
  }

  public clickButtonDropdownWithAction(action: { actionName: string, returnValue: any }, btn: NavBarBtnModel): void {
    if (!btn.disabled && !btn.isLoading) {
      this.clickActionWithValue.emit(action);
      if (btn.allowLoadingSpinner) {
        this.changeBtnLoadingStatus(btn, true);
      }
    }
  }

  public changeBtnLoadingStatus(btn: NavBarBtnModel, status: boolean): void {
    btn.isLoading = status;
  }

  public trackByTemplate(index: number, item: NavBarBtnModel) {
    return item.actionName;
  }

}
