import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActionType } from './models/action-type.enum';
import { interval, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { UIStatesEnum } from '../../../models';

@Component({
  selector: 'rnpl-document-saved-info',
  templateUrl: './document-saved-info.component.html',
  styleUrls: ['./document-saved-info.component.scss'],
})
export class DocumentSavedInfoComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isLoading: boolean = false;
  @Input() actionType: ActionType|'Saved'|'Synced' = ActionType.Save;
  @Input() iconName: string = 'icon-save';
  @Input() savedDate: Date;
  @Input() refreshTime: number;
  @Input() updatedBy: string;
  @Input() uiState: UIStatesEnum = UIStatesEnum.VIEW;

  @Output() clickAction = new EventEmitter<string>();
  @Output() refreshAction = new EventEmitter<boolean>(null);

  public showTooltip: boolean = false;
  public syncNow: boolean = false;
  public interval: Observable<number> = interval(1000);
  public refreshInterval: Subscription;
  public checkViewSubscription: Subscription = null;
  public defaultInterval = 300 * 10e2;
  public actionTypes: typeof ActionType = ActionType;
  public UIStates: typeof UIStatesEnum = UIStatesEnum;

  private $_destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.interval
      .pipe(
        takeUntil(this.$_destroy)
      )
      .subscribe(() => {
        if (this.savedDate) {
          this.savedDate = new Date(this.savedDate);
        }
      });
  }

  @TrackInputChanges<number>('refreshTime', 'setRefreshInterval', ChangesStrategy.Each)
  @TrackInputChanges<number>('savedDate', 'checkViewChanges', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void {}

  public actionClick(actionName: string): void {
    if (this.uiState !== UIStatesEnum.VIEW) { return; }
    this.clickAction.emit(actionName);
  }

  public checkViewChanges() {
    if (this.checkViewSubscription) {
      this.checkViewSubscription.unsubscribe();
    }

    const intervalBreakpoints = [10, 60, 3600]; // 10, 60, 3600 seconds (order is matter)
    let passedTime = 0;

    this.checkViewSubscription = interval(10 * 10e2)
      .pipe(
        tap(() => { passedTime += 10; }),
        // check changes if {intervalBreakpoints} has {passedTime} item OR each minute
        filter(() => passedTime === intervalBreakpoints[0] || passedTime % 60 === 0),
        tap(() => {
          if (passedTime === intervalBreakpoints[0]) {
            intervalBreakpoints.shift();
          }
          this.cdr.detectChanges();
        }),
        takeWhile(() => !!intervalBreakpoints.length, true),
        takeUntil(this.$_destroy)
      )
      .subscribe();
  }

  public setRefreshInterval() {
    this.refreshInterval = interval(this.refreshTime || this.defaultInterval)
      .pipe(
        tap(() => { this.refreshEvent(); }),
        takeUntil(this.$_destroy)
      )
      .subscribe();
  }

  public refreshEvent(): void {
    if (this.uiState !== UIStatesEnum.VIEW) { return; }
    this.refreshAction.emit(true);
  }

  ngOnDestroy(): void {
    this.$_destroy.next(null);
    this.$_destroy.complete();
  }

}
