import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { TradeDocumentPositionModel } from '../../trade/models';
import { ResponseModel } from '../../shared/models/response';
import { ToasterService } from 'common/src/modules/ui-components/toaster';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderApiHelperService {

  private readonly apiEndpoint: string = '/sales-orders';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly toasterService:ToasterService,
  ) {}

  public getPositionsAvailableForPO(soId: number): Observable<TradeDocumentPositionModel[]> {
    return this.http.get<ResponseModel<TradeDocumentPositionModel[]>>(this.apiUrl(`/${soId}/positions/available-for-po`))
      .pipe(
        map((data: ResponseModel<TradeDocumentPositionModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
