import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { BaseFormComponent } from '../../../crm/partner-forms/components/base-form.component';
import { AddressFormFactory } from '../../../crm/partner-forms/components/address-form.factory';
import { CountryModel } from 'common/src/modules/rnpl-common';
import { StockAddressesService } from '../../services';
import { AppState } from '../../../store/state/app.state';
import { UpdateWarehouseFormStock, ClearWarehouseFormStock } from '../../store/actions/warehouse.actions';
import { convertAddressToStringHelper } from 'common/src/modules/rnpl-common/helpers';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { selectCountries } from '../../../store/selectors/shared.selectors';

@Component({
  selector: 'rnpl-warehouse-form-stocks',
  templateUrl: './warehouse-form-stocks.component.html',
  styleUrls: ['./warehouse-form-stocks.component.scss']
})
export class WarehouseFormStocksComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() warehouseData: WarehouseResponse;

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public daysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  public countries: CountryModel[] = [];

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private stockAddressesService: StockAddressesService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly') || changes.hasOwnProperty('warehouseData')) {
      this.initForm(this.warehouseData);
      this.updateFormEditingStatus();
      this.updateIsLoading();
    }
  }

  ngOnInit(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this._destroy))
      .subscribe(countries => {
        this.countries = countries;
        this.updateIsLoading();
      });

    this.initForm(this.warehouseData);
    this.updateFormEditingStatus();
  }

  public updateIsLoading(): void {
    if (this.countries && this.warehouseData) {
      this.isLoading$.next(false);
    }
  }

  initForm(warehouseData: any = {} as any): void {
    this.form = this.fb.group({
      // name: [get(warehouseData, 'name', ''), Validators.required],
      // description: [get(warehouseData, 'description', ''), []],
      address: AddressFormFactory.getForm(get(warehouseData, 'addressAndContact.address') || {}),
      contact: this.fb.group({
        phone: [get(warehouseData, 'addressAndContact.phone', '')],
        email: [get(warehouseData, 'addressAndContact.email', ''), [Validators.email]]
      })
    });
    this.cdr.detectChanges();
    this.trackFormChanges();
  }

  public trackFormChanges(): void {
    if (this.form) {
      this.form.valueChanges
        .pipe(
          distinctUntilChanged(),
          takeUntil(this._destroy)
        )
        .subscribe(value => this.store.dispatch(UpdateWarehouseFormStock({ warehouseForm: value })));
    }
  }

  public updateWarehouse(fieldValue: any, fieldName: string) {
    this.stockAddressesService.patchWarehouseProfile({fieldValue, fieldName}).subscribe();
  }

  public displayMessage(message: string, type: string) {
    this.toasterService.notify({ type, message });
  }

  public fillOwnWarehouseLegalAddress(): void {
    this.stockAddressesService.setWarehouseLegalAddress(this.warehouseData.id).subscribe();
  }

  updateFormEditingStatus() {
    if (!this.form) { return; }

    if (this.readonly) {
      this.form.disable({ onlySelf: false });
    } else {
      this.form.enable({ onlySelf: false });
    }
  }

  public get warehouseAddressAsText(): string {
    if (!this.form) { return ''; }

    return convertAddressToStringHelper(this.address.value, this.countries);
  }

  getFormData() {
    return this.form.getRawValue();
  }

  ngOnDestroy() {
    this.store.dispatch(ClearWarehouseFormStock());
    this._destroy.next(null);
    this._destroy.complete();
  }

  // get name() { return this.form.get('name'); }

  // get description() { return this.form.get('description'); }

  get address() { return this.form.get('address'); }

  get phone() { return this.form.get('contact.phone'); }

  get email() { return this.form.get('contact.email'); }

}
