import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { selectSubscription } from 'projects/workspace/src/app/subscription/store/selectors';
import {
  SubscriptionModel,
  SubscriptionPropertiesModel
} from 'projects/workspace/src/app/subscription/models/subscription.model';

@Component({
  selector: 'rnpl-subscription-add-remark-modal',
  templateUrl: './subscription-add-remark-modal.component.html'
})
export class SubscriptionAddRemarkModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  readonly destroy$: Subject<void> = new Subject();
  public subscription: SubscriptionModel;

  constructor(
    public dialogRef: MatDialogRef<SubscriptionAddRemarkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private subscriptionApiService: SubscriptionApiService
  ) { }

  ngOnInit() {
    this.trackSubscriptionChanges();
  }

  private trackSubscriptionChanges(): void {
    this.store.select(selectSubscription)
      .pipe(takeUntil(this.destroy$))
      .subscribe((subscription: SubscriptionModel) => {
        this.subscription = subscription;
        this.initForm(subscription.properties);
      });
  }

  public initForm(properties: SubscriptionPropertiesModel = {} as SubscriptionPropertiesModel): void {
    this.form = this.fb.group({
      printableRemarks: [get(properties, 'printableRemarks')],
    });
    this.cdr.detectChanges();
  }

  public updatePartnerField(value: string, closeModal = false): void {
    const field: FormInputChangedModel = {
      fieldValue: value,
      fieldName: 'properties.printableRemarks',
    };

    this.subscriptionApiService.updateSubscription(this.subscription.id, field)
      .subscribe(() => {
        if (closeModal) {
          this.dialogRef.close();
        }
      });
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
