import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { WarehouseStatusEnum } from '../../enums/warehouse.status.enum';

export function getButtonsByState(state: UIStatesEnum): NavBarBtnModel[] {
  return NavBarButtons.filter((btn: NavBarBtnModel) => btn.other.includes(state));
}

export const NavBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white',
    text: 'BUTTON.EDIT',
    hintsId: 'editBinLocationBtn',
    iconName: 'edit',
    disabled: false,
    actionName: 'onEditClick',
    allowLoadingSpinner: true,
    greyIcon: true,
    multiple: false,
    other: [UIStatesEnum.VIEW],
  },
  {
    classes: 'btn-primary',
    text: 'BUTTON.DONE',
    hintsId: 'doneBinLocationBtn',
    iconName: 'checkmark',
    disabled: false,
    actionName: 'onFinishEditingClick',
    multiple: false,
    allowLoadingSpinner: true,
    other: [UIStatesEnum.EDIT],
  }
];

export const STATUS_CLASSES: {[key: string]: string} = {
  [WarehouseStatusEnum.ACTIVE]: 'rnpl-badge--blue',
  [WarehouseStatusEnum.INACTIVE]: 'rnpl-badge--red',
  [WarehouseStatusEnum.DELETED]: 'rnpl-badge--red',
  'editing': 'rnpl-badge--main-400',
};
