import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { ProductTypes } from '../../../products/product-types';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';

@Component({
  selector: 'rnpl-filters-control-product-type',
  templateUrl: './filters-control-product-type.component.html',
})
export class FiltersControlProductTypeComponent extends FiltersControlBaseComponent implements OnChanges {

  @Input() observableList: Observable<any[]>;

  public productType = PRODUCTS_TYPE;

  public transformedList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnChanges(changes:SimpleChanges): void {
    if (changes && changes.hasOwnProperty('observableList')) {
      this.observableList
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.transformedList$.next(res.map(itm => ({
            ...itm,
            searchLabel: this.getProductSearchValue(itm)
          })));
        });
    }
  }

  public getProductSearchValue(product: any): string {
    const productTypes = {
      [ProductTypes.GOODS]: this.translateService.instant('POSITIONS.PRODUCTS'),
      [ProductTypes.SERVICES]: this.translateService.instant('POSITIONS.SERVICES'),
      [ProductTypes.DIGITAL]: this.translateService.instant('POSITIONS.DIGITAL_PRODUCTS'),
    };
    return `${product.name} ${productTypes[product.entityForm]} ${this.translateService.instant('GL_CATEGORY.' + product.categoryPath)}`;
  }

}
