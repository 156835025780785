import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { TranslateModule } from '@ngx-translate/core';

import { UIComponentsModule, RnplCommonModule } from 'common/src/modules';
import { CorporatePartnerFormComponent } from './corporate-partner-form.component';
import { AddressFormModule } from '../address-form/address-form.module';
import { InputValidationErrorsModule } from '../../../../shared/components/input-validation-errors/input-validation-errors.module';

@NgModule({
  declarations: [
    CorporatePartnerFormComponent,
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RnplCommonModule,
    AddressFormModule,
    NgxMaskModule.forRoot(),
    InputValidationErrorsModule,
    TranslateModule
  ],
  exports: [
    CorporatePartnerFormComponent,
  ],
  providers: [
  ]
})

export class CorporatePartnerFormModule {
}
