import { MatDialogConfig } from '@angular/material/dialog/typings/dialog-config';

export const UPDATE_SMALL_BUSINESS_MODAL_CONFIG: MatDialogConfig = {
  data: {
    title: 'SMALL_BUSINESS.DOCUMENTS_MODAL_TITLE',
    message: 'SMALL_BUSINESS.DOCUMENTS_MODAL_MSG',
    isRejectButton: true,
    rejectBtnText: 'SMALL_BUSINESS.GO_TO_ACCOUNTING_SETTINGS',
    rejectBtnIcon: 'external-link',
    rejectBtnClass: 'btn-white',
    confirmBtnText: 'BUTTON.CONTINUE',
    confirmBtnIcon: 'arrow-right',
  }
};
