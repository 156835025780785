import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { AppState } from '../../../../../store/state/app.state';
import { CountryModel } from 'common/src/modules/rnpl-common';
import { selectCountries } from '../../../../../store/selectors/shared.selectors';
import { VAT_LIST, WEEK_DAYS_LIST } from '../../../../../shared/constants';
import { EcoSettingsDeliveryCountryModel } from '../../models';
import { AddressModel, PayerEnum } from 'common/src/models';
import { AddressFormFactory } from '../../../../../shared/forms/address-form.factory';
import { EcoSettingsApiService } from '../../services/eco-settings-api.service';
import { StockAddressesService } from '../../../../../warehouse/services';
import { DeliveryServiceModel } from '../../../../../warehouse/warehouse-settings-module/models';

@Component({
  selector: 'rnpl-eco-delivery-form',
  templateUrl: './eco-delivery-form.component.html',
  styleUrls: ['./eco-delivery-form.component.scss'],
})
export class EcoDeliveryFormComponent implements OnChanges, OnDestroy {

  @Input() deliveryCountrySettings: EcoSettingsDeliveryCountryModel;
  @Input() alreadyAddedCountriesISO3: string[] = [];

  public warehouseData: any;
  public ShowUsePickUpBtn = false;
  public workingDayFrom: Date = null;
  public workingDayTo: Date = null;
  public form: FormGroup;
  public pickUpAddressForm: FormGroup;
  public payerEnum = PayerEnum;
  public readonly vatList = VAT_LIST;
  public readonly weekDaysList = WEEK_DAYS_LIST;

  public countries$: Observable<CountryModel[]> = this.store.select(selectCountries)
    .pipe(
      map(countries => {
        return countries.filter(c => !this.alreadyAddedCountriesISO3 || !this.alreadyAddedCountriesISO3.includes(c.code_iso3.toUpperCase()));
      })
    );

  public allCountries$: Observable<CountryModel[]> = this.store.select(selectCountries);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private ecoSettingsApiService: EcoSettingsApiService,
    private stockService: StockAddressesService,
    private translateService: TranslateService,
    private readonly store: Store<AppState>
  ) {
    this.stockService.getWarehouseInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((warehouseData: any) => {
        this.warehouseData = warehouseData;
        this.canShowUsePickUpBtn();
      });

    this.initForm();
    this.initPickUpForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('deliveryCountrySettings') && this.deliveryCountrySettings) {
      this.form.patchValue(this.deliveryCountrySettings);
      this.initPickUpForm();

      if (get(this.deliveryCountrySettings, 'workingDayFrom')) {
        const fromArr = this.deliveryCountrySettings.workingDayFrom.split(':');
        this.workingDayFrom = new Date( new Date().setHours((fromArr[0] || '00') as any, (fromArr[1] || '00') as any, 0) );
      }

      if (get(this.deliveryCountrySettings, 'workingDayTo')) {
        const toArr = this.deliveryCountrySettings.workingDayTo.split(':');
        this.workingDayTo = new Date( new Date().setHours((toArr[0] || '00') as any, (toArr[1] || '00') as any, 0) );
      }
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      country: [null, [Validators.required]],
      amountToGetFreeDelivery: [null, [Validators.required]],
      inlandFreeDelivery: [false],
      standartDelivery: [false],
      expressDelivery: [false],
      pickUp: [false],
      pickUpFrom: [null, [Validators.required]],
      pickUpTo: [null, [Validators.required]],
      workingDayFrom: [null, [Validators.required]],
      workingDayTo: [null, [Validators.required]],
      workingAllDay: [],
      workingAllWeek: [],
      standartDeliveryEntity: this.fb.group({
        deliveryCostsGross: [null, [Validators.required]],
        deliveryCostsNet: [null, [Validators.required]],
        deliveryCostsVat: [null, [Validators.required]],
        deliveryDaysFrom: [null, [Validators.required]],
        deliveryDaysTo: [null, [Validators.required]],
        payer: [null, [Validators.required]],
        deliveryServiceId: [null, [Validators.required]],
      }),
      expressDeliveryEntity: this.fb.group({
        deliveryCostsGross: [null, [Validators.required]],
        deliveryCostsNet: [null, [Validators.required]],
        deliveryCostsVat: [null, [Validators.required]],
        deliveryDaysFrom: [null, [Validators.required]],
        deliveryDaysTo: [null, [Validators.required]],
        payer: [null, [Validators.required]],
        deliveryServiceId: [null, [Validators.required]],
      }),
    });

    this.workingAllDayControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value) {
          this.workingDayFrom = new Date( new Date().setHours('00' as any, '00' as any, 0) );
          this.workingDayTo = new Date( new Date().setHours('23' as any, '59' as any, 59) );
          this.workingDayFromControl.disable();
          this.workingDayToControl.disable();
        } else {
          this.workingDayFromControl.enable();
          this.workingDayToControl.enable();
        }
      });

    this.workingAllWeekControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value) {
          this.pickUpFromControl.patchValue('MONDAY');
          this.pickUpToControl.patchValue('SUNDAY');
          this.pickUpFromControl.disable();
          this.pickUpToControl.disable();
        } else {
          this.pickUpFromControl.enable();
          this.pickUpToControl.enable();
        }
      });
  }

  public initPickUpForm(pickUpAddress = get(this, 'deliveryCountrySettings.pickUpAddress')): void {
    const iso2: string = get(pickUpAddress, 'country.iso2');
    const address: AddressModel = {
      street: get(pickUpAddress, 'street'),
      zip_code: get(pickUpAddress, 'postalCode'),
      city: get(pickUpAddress, 'city'),
      state: get(pickUpAddress, 'state'),
      house_number: get(pickUpAddress, 'house'),
      block: get(pickUpAddress, 'block'),
      floor: get(pickUpAddress, 'floor'),
      apartments: get(pickUpAddress, 'room'),
      country_iso3: iso2 ? iso2.toLowerCase() : null,
      additional_information: get(pickUpAddress, 'additionalInformation'),
    };
    this.pickUpAddressForm = AddressFormFactory.getForm(address, false);
    this.pickUpAddressForm.get('country_iso3').disable();
    this.canShowUsePickUpBtn();
  }

  private canShowUsePickUpBtn(): void {
    const warehouseCountryISO2 = (get(this, 'warehouseData.data.addressAndContact.address.country.country_iso2') || '').toLowerCase();
    const deliveryCountryISO2 = (get(this, 'deliveryCountrySettings.country.iso2') || '').toLowerCase();

    if (warehouseCountryISO2 && deliveryCountryISO2) {
      this.ShowUsePickUpBtn = warehouseCountryISO2 === deliveryCountryISO2;
    }
  }

  public countryControlChanged(country: CountryModel): void {
    if (country) {
      this.pickUpAddressForm.get('country_iso3').patchValue(country.code_iso2.toLowerCase())
    }
  }

  public useCorporateAddress(): void {
    this.ecoSettingsApiService.getEcoSettingsDeliveryCorporateAddress()
      .pipe(takeUntil(this.destroy$))
      .subscribe((address: AddressModel) => this.initPickUpForm(address));
  }

  public expressDeliveryServiceChanged(ds: DeliveryServiceModel): void {
    this.deliveryCountrySettings.expressDeliveryEntity.deliveryService = ds;
  }

  public standartDeliveryServiceChanged(ds: DeliveryServiceModel): void {
    this.deliveryCountrySettings.standartDeliveryEntity.deliveryService = ds;
  }

  public recalculateStandartDeliveryPriceControls(sourceValue: number, sourceControlType: 'net'|'gross'|'vat'): void {
    this.recalculatePriceControls(
      sourceValue,
      sourceControlType,
      this.standartEntityDeliveryCostsNetControl,
      this.standartEntityDeliveryCostsGrossControl,
      this.standartEntityDeliveryCostsVatControl,
    );
  }

  public recalculateExpressDeliveryPriceControls(sourceValue: number, sourceControlType: 'net'|'gross'|'vat'): void {
    this.recalculatePriceControls(
      sourceValue,
      sourceControlType,
      this.expressEntityDeliveryCostsNetControl,
      this.expressEntityDeliveryCostsGrossControl,
      this.expressEntityDeliveryCostsVatControl,
    );
  }

  public recalculatePriceControls(
    sourceValue: number,
    sourceControlType: 'net'|'gross'|'vat',
    netControl: FormControl,
    grossControl: FormControl,
    vatControl: FormControl
  ): void {
    if (!vatControl.value && vatControl.value !== 0) { return; }

    const vat = this.getVat(vatControl.value);

    if (sourceControlType === 'vat') {
      if (netControl.value) {
        grossControl.patchValue(+(netControl.value * vat).toFixed(2));
        return;
      }

      if (grossControl.value) {
        netControl.patchValue(+(grossControl.value / vat).toFixed(2));
        return;
      }
    }

    if (!sourceValue) { return; }

    if (sourceControlType === 'net') {
      if (vat) {
        grossControl.patchValue(+(sourceValue * vat).toFixed(2));
      }
    }

    if (sourceControlType === 'gross') {
      if (vat) {
        netControl.patchValue(+(sourceValue / vat).toFixed(2));
      }
    }
  }

  private getVat(vat: number): number {
    return vat / 100 + 1; // example: 13 => 1.13
  }

  public getFormValue(): any {
    const formValue = this.form.getRawValue();
    const country = this.deliveryCountrySettings
      ? formValue.country
      : {iso2: formValue.country && formValue.country.country_iso2 as any};

    return {
      ...formValue,
      country,
      pickUpAddress: this.getPickUpAddressValue(),
    };
  }

  public getPickUpAddressValue(): any {
    const pickUpAddressValue = this.pickUpAddressForm.getRawValue();
    const countryCode: string = get(pickUpAddressValue, 'country_iso3');
    return {
      house: pickUpAddressValue.house_number,
      additionalInformation: pickUpAddressValue.additional_information,
      postalCode: pickUpAddressValue.zip_code,
      room: pickUpAddressValue.apartments,
      country: {
        iso2: countryCode ? countryCode.toUpperCase() : null,
      },
      floor: pickUpAddressValue.floor,
      block: pickUpAddressValue.block,
      street: pickUpAddressValue.street,
      city: pickUpAddressValue.city,
      state: pickUpAddressValue.state,
    }
  }

  public isFormValid(): boolean {
    const standardDeliveryControls: FormControl[] = [
      this.standartEntityDeliveryDaysFromControl,
      this.standartEntityDeliveryDaysToControl,
      this.standartEntityPayerControl,
      this.standartEntityDeliveryCostsGrossControl,
      this.standartEntityDeliveryCostsNetControl,
      this.standartEntityDeliveryCostsVatControl,
      this.standartEntityDeliveryServiceIdControl,
    ];
    const expressDeliveryControls: FormControl[] = [
      this.expressEntityDeliveryDaysFromControl,
      this.expressEntityDeliveryDaysToControl,
      this.expressEntityPayerControl,
      this.expressEntityDeliveryCostsGrossControl,
      this.expressEntityDeliveryCostsNetControl,
      this.expressEntityDeliveryCostsVatControl,
      this.expressEntityDeliveryServiceIdControl,
    ];
    const pickUpControls: FormControl[] = [
      this.pickUpFromControl,
      this.pickUpToControl,
      this.workingDayFromControl,
      this.workingDayToControl,
    ];

    let controlsToSetValidation: FormControl[] = [];
    let controlsToRemoveValidation: FormControl[] = [];

    if (this.inlandFreeDeliveryControl.value) {
      controlsToSetValidation.push(this.amountToGetFreeDeliveryControl);
    } else {
      controlsToRemoveValidation.push(this.amountToGetFreeDeliveryControl);
    }

    if (this.standartDeliveryControl.value) {
      controlsToSetValidation = [...controlsToSetValidation, ...standardDeliveryControls];
    } else {
      controlsToRemoveValidation = [...controlsToRemoveValidation, ...standardDeliveryControls];
    }

    if (this.expressDeliveryControl.value) {
      controlsToSetValidation = [...controlsToSetValidation, ...expressDeliveryControls];
    } else {
      controlsToRemoveValidation = [...controlsToRemoveValidation, ...expressDeliveryControls];
    }

    if (this.pickUpControl.value) {
      controlsToSetValidation = [...controlsToSetValidation, ...pickUpControls];
    } else {
      controlsToRemoveValidation = [...controlsToRemoveValidation, ...pickUpControls];
    }

    controlsToSetValidation.forEach(control => control.setValidators(Validators.required));
    controlsToRemoveValidation.forEach(control => control.clearValidators());

    [...controlsToSetValidation, ...controlsToRemoveValidation].forEach(control => {
      control.markAsTouched();
      control.updateValueAndValidity();
    });

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    this.pickUpAddressForm.markAllAsTouched();
    this.pickUpAddressForm.updateValueAndValidity();

    return this.pickUpControl.value
      ? this.form.valid && this.pickUpAddressForm.valid
      : this.form.valid;
  }

  public get countryControl(): FormControl { return this.form.get('country') as FormControl; }
  public get inlandFreeDeliveryControl(): FormControl { return this.form.get('inlandFreeDelivery') as FormControl; }
  public get expressDeliveryControl(): FormControl { return this.form.get('expressDelivery') as FormControl; }
  public get standartDeliveryControl(): FormControl { return this.form.get('standartDelivery') as FormControl; }
  public get pickUpControl(): FormControl { return this.form.get('pickUp') as FormControl; }
  public get amountToGetFreeDeliveryControl(): FormControl { return this.form.get('amountToGetFreeDelivery') as FormControl; }
  public get pickUpFromControl(): FormControl { return this.form.get('pickUpFrom') as FormControl; }
  public get pickUpToControl(): FormControl { return this.form.get('pickUpTo') as FormControl; }
  public get workingDayFromControl(): FormControl { return this.form.get('workingDayFrom') as FormControl; }
  public get workingDayToControl(): FormControl { return this.form.get('workingDayTo') as FormControl; }
  public get workingAllDayControl(): FormControl { return this.form.get('workingAllDay') as FormControl; }
  public get workingAllWeekControl(): FormControl { return this.form.get('workingAllWeek') as FormControl; }

  public get standartEntityGroup(): FormGroup { return this.form.get('standartDeliveryEntity') as FormGroup; }
  public get expressEntityGroup(): FormGroup { return this.form.get('expressDeliveryEntity') as FormGroup; }

  public get standartEntityDeliveryDaysFromControl(): FormControl { return this.standartEntityGroup.get('deliveryDaysFrom') as FormControl; }
  public get standartEntityDeliveryDaysToControl(): FormControl { return this.standartEntityGroup.get('deliveryDaysTo') as FormControl; }
  public get standartEntityPayerControl(): FormControl { return this.standartEntityGroup.get('payer') as FormControl; }
  public get standartEntityDeliveryCostsGrossControl(): FormControl { return this.standartEntityGroup.get('deliveryCostsGross') as FormControl; }
  public get standartEntityDeliveryCostsNetControl(): FormControl { return this.standartEntityGroup.get('deliveryCostsNet') as FormControl; }
  public get standartEntityDeliveryCostsVatControl(): FormControl { return this.standartEntityGroup.get('deliveryCostsVat') as FormControl; }
  public get standartEntityDeliveryServiceIdControl(): FormControl { return this.standartEntityGroup.get('deliveryServiceId') as FormControl; }

  public get expressEntityDeliveryDaysFromControl(): FormControl { return this.expressEntityGroup.get('deliveryDaysFrom') as FormControl; }
  public get expressEntityDeliveryDaysToControl(): FormControl { return this.expressEntityGroup.get('deliveryDaysTo') as FormControl; }
  public get expressEntityPayerControl(): FormControl { return this.expressEntityGroup.get('payer') as FormControl; }
  public get expressEntityDeliveryCostsGrossControl(): FormControl { return this.expressEntityGroup.get('deliveryCostsGross') as FormControl; }
  public get expressEntityDeliveryCostsNetControl(): FormControl { return this.expressEntityGroup.get('deliveryCostsNet') as FormControl; }
  public get expressEntityDeliveryCostsVatControl(): FormControl { return this.expressEntityGroup.get('deliveryCostsVat') as FormControl; }
  public get expressEntityDeliveryServiceIdControl(): FormControl { return this.expressEntityGroup.get('deliveryServiceId') as FormControl; }


  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
