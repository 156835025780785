import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { TableColumnModel } from 'common/src/models/table-column.model';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import {
  OrderedProduct,
  SalesOrderModel,
  SalesOrderSearchByIdModel,
  SODeliveryNotes
} from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { getSalesOrderOrderedProductsTableColumns } from './link-sales-order.config';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rnpl-link-sales-order',
  templateUrl: './link-sales-order.component.html',
  styleUrls: ['./link-sales-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkSalesOrderComponent extends BaseModalComponent implements OnInit, OnDestroy {

  readonly linkDocumentRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  public columns: TableColumnModel[] = getSalesOrderOrderedProductsTableColumns();

  public salesOrdersList: SODeliveryNotes[] = [];

  public selectedSalesOrder: SalesOrderModel = null;

  public customSearchFn = CustomSearchFn;

  readonly productList$: BehaviorSubject<OrderedProduct[]> = new BehaviorSubject([]);
  readonly currentSalesOrderInfo$: BehaviorSubject<SalesOrderSearchByIdModel> = new BehaviorSubject(null);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<LinkSalesOrderComponent>,
    private deliveryNoteApiService: DeliveryNoteApiService,
    private salesOrderApiService: SalesOrderApiService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveryNoteId: number;
      partnerId: number;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.getSalesOrders();
  }

  private getSalesOrders(): void {
    this.showDropdownSpin$.next(true);
    this.salesOrderApiService.getActiveSalesOrders(this.data.partnerId)
      .pipe(takeUntil(this._destroy))
      .subscribe((salesOrders: SODeliveryNotes[]) => {
        this.showDropdownSpin$.next(false);
        this.salesOrdersList = this.prepareSalesOrderList(salesOrders);
      }, error => {
        this.showDropdownSpin$.next(false);
        this.displayMessage('error', error.error.message);
      });
  }

  public prepareSalesOrderList(salesOrders): SODeliveryNotes[] {
    return salesOrders.map(item => {
      return {
        ...item,
        searchLabel: `${item.runpleId} ${item.partner.name} ${item.openUnitsQuantity} ${item.amountGross}`
      };
    });
  }

  public getProductInfo(data): void {
    this.showDropdownSpin$.next(true);

    this.salesOrderApiService.getActiveSalesOrderById(data.id)
      .pipe(takeUntil(this._destroy))
      .subscribe((salesOrder: SalesOrderSearchByIdModel) => {
        this.showDropdownSpin$.next(false);
        this.currentSalesOrderInfo$.next(salesOrder);
        this.productList$.next(salesOrder.orderedProducts);
      }, error => {
        this.showDropdownSpin$.next(false);
        this.displayMessage('error', error.error.message);
      });
  }

  public linkSalesOrder(): void {
    if (this.linkDocumentRequest$.getValue()) { return; }
    this.linkDocumentRequest$.next(true);

    this.deliveryNoteApiService
      .linkOrUnlinkSalesOrder(this.data.deliveryNoteId, this.selectedSalesOrder.id)
      .pipe(
        finalize(() => { this.linkDocumentRequest$.next(false); }),
        takeUntil(this._destroy)
      )
      .subscribe(this.subscriberHandler, this.handleError);
  }

  public subscriberHandler = (response: DeliveryNote) => {
    this.dialogRef.close(response);
  }

  public handleError = error => {
    this.displayMessage('error', error.error.message);
  }

  public rowClickReceiver(event): void {

    if (event.type === TableActivateTypes.Link) {
      if (event.column.prop === 'productName' ) {
        this.router.navigate([`/products/product-view/${event.row.productId}`]);
        this.dialogRef.close(null);
      }
    }
  }

  public goToPartner() {
    this.router.navigate([`/partners-new/${this.partner.type || PartnersTypeEnum.CORPORATE}/${this.partner.id}`]);
    this.dialogRef.close(null);
  }

  public get partner() {
    return this.currentSalesOrderInfo$.getValue().partner;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
