import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'rnpl-boards-page',
  templateUrl: 'boards-page.component.html',
  styleUrls: ['boards-page.component.scss']
})
export class BoardsPageComponent implements OnInit {

  @Input()
  scrollStep: number = 320;

  @Input()
  controlsBottom: boolean = false;

  @Input()
  hideControls: boolean = false;


  public position: number = 0;
  private containerWidth: number;
  private itemListWidth: number;
  private maxRightShift: number;
  private rightMargin = 16;

  ngOnInit() {
    setTimeout((() => {
      this.itemListWidth = document.querySelector('.boards-page-body').clientWidth;
      this.containerWidth = this.boardsPageWidth;
    }), 200);
  }

  nextPosition(): void {
    if (this.containerWidth <= (this.itemListWidth + this.rightMargin)) {
      this.containerWidth = this.boardsPageWidth;
      this.maxRightShift = this.containerWidth - (this.itemListWidth + this.rightMargin);
      this.position -= this.scrollStep;
      this.position = Math.max(this.position, this.maxRightShift);
    }
  }

  prevPosition(): void {
    this.position += this.scrollStep;
    this.position = Math.min(this.position, 0);
  }

  isActiveRightShift(): boolean {
    return this.maxRightShift === this.position || this.containerWidth > this.itemListWidth;
  }

  isActiveLeftShift (): boolean {
    return this.position === 0;
  }

  get boardsPageWidth(): number {
    return document.querySelector('.boards-page').clientWidth;
  }
}
