import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MatSidenavModule,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig
} from '@angular/material';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SafePipeModule } from 'safe-pipe';
import { StoreModule } from '@ngrx/store';
import { LottieModule } from 'ngx-lottie';
import { EffectsModule } from '@ngrx/effects';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileSaverModule } from 'ngx-filesaver';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ShepherdService } from 'angular-shepherd';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ColorPickerModule } from 'ngx-color-picker';

import { SystemPreferencesPermissionsService } from 'common/src/services/system-preferences-permissions.service';
import { UserPermissionsService } from 'common/src/services/user-permissions.service';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';
import { BreadcrumbsService } from 'common/src/modules/ui-components/breadcrumbs';
import { InitialSetupModule } from 'common/src/modules/initial-setup/initial-setup.module';
import { CommonPagesModule } from 'common/src/pages/common-pages.module';
import { AuthInterceptor } from 'common/src/auth/auth.interceptor';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BarcodeModule } from "common/src/modules/barcode/barcode.module";
import { SoundService } from 'common/src/services/sound.service';
import { ModalsModule } from 'common/src/modules/modals/modals.module';
import { AuthService } from 'common/src/auth/auth.service';
import {
  RnplCommonModule,
  UIComponentsModule,
  WizardModule,
} from 'common/src/modules';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './api.interceptor';
import { AppService } from './app.service';
import { environment } from '../environments/environment';
import { appReducers, metaReducers } from './store/reducers/app.reducers';
import { ValidationService } from './shared/services/validation.service';
import { InputValidationErrorsModule } from './shared/components/input-validation-errors/input-validation-errors.module';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { AdministrationsApiService } from './administration/services/administrations-api.service';
import { NgxCurrencyModule, CurrencyMaskInputMode, CurrencyMaskConfig } from "ngx-currency-custom";
import { CustomSerializer } from './store/custom-route-serializer';
import { SharedEffects } from './store/effects/shared.effects';
import { SandboxHintService } from 'common/src/modules/quick-onboarding/sandbox-hint.service';
import { SubscriptionActivationModule } from 'common/src/modules/subscription-activation/subscription-activation.module';
import { QuickOnboardingModule } from 'common/src/modules/quick-onboarding/quick-onboarding.module';
import { BaseMegaHintComponentDependences } from 'common/src/modules/rnpl-common/components/base-mega-hints/base-mega-hints.component';
import { MAT_DIALOG_OPTIONS } from './shared/constants/documents-modals-basic-config';
import { LimitedFunctionalityModalModule } from './shared/components/limited-functionality-modal/limited-functionality-modal.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function playerFactory() {
  return import('lottie-web');
}

export const mainCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  decimalAlternative: '.',
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // LayoutModule,
    // MatToolbarModule,
    MatSidenavModule,
    // MatListModule,
    MatDialogModule,
    // MatExpansionModule,
    RnplCommonModule,
    CommonPagesModule,
    UIComponentsModule,
    WizardModule,
    LottieModule.forRoot({player: playerFactory, useCache: true}),
    ModalsModule,
    FileSaverModule,
    StoreModule.forRoot(appReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    EffectsModule.forRoot([SharedEffects]),
    SafePipeModule,
    InputValidationErrorsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    NgxCurrencyModule.forRoot(mainCurrencyMaskConfig),
    NgSelectModule,
    FormsModule,
    BarcodeModule,
    InitialSetupModule,
    SubscriptionActivationModule,
    QuickOnboardingModule,
    BrowserAnimationsModule,
    ContextMenuModule.forRoot(),
    PerfectScrollbarModule,
    LimitedFunctionalityModalModule,
    ColorPickerModule,
  ],
  providers: [
    AppService,
    AdministrationsApiService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: (router, authService) => new AuthInterceptor(router, authService),
      deps: [Router, AuthService],
      multi: true
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: MAT_DIALOG_OPTIONS
    },
    {provide: SwRegistrationOptions, useFactory: () => ({enabled: environment.production})},
    ToasterService,
    BreadcrumbsService,
    SoundService,
    UserPermissionsService,
    SystemPreferencesPermissionsService,
    ValidationService,
    ActionButtonsService,
    SandboxHintService,
    ShepherdService,
    BaseMegaHintComponentDependences,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
