import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseModel, ColumnsSettings } from 'projects/workspace/src/app/shared/models/response';
import { DisplayToaster } from 'projects/workspace/src/app/shared/decorators/toaster';
import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AuthService } from 'common/src/auth/auth.service';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

@Injectable()
export class UserColumnsSettingsService {
  private readonly apiEndpoint: string = `${environment.javaCommonsApiVersion}/tables/fields`;
  private readonly userId: number;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly authService: AuthService,
  ) {
    this.userId = this.authService.getUser().id;
  }

  @DisplayToaster({showErrorMessage: true})
  public getColumnsSettings(status: string, docType: DocumentTypesUppercaseEnum|string): Observable<ColumnsSettings> {
    const params = {
      userId: this.userId as any,
      type: docType,
      status,
    };
    return this.http.get<ResponseModel<ColumnsSettings>>(this.apiUrl(), { params })
      .pipe(map((response: ResponseModel<ColumnsSettings>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public saveColumnsSettings(columnsSettings: ColumnsSettings): Observable<ColumnsSettings> {
    return this.http.post<ResponseModel<ColumnsSettings>>(this.apiUrl(), { ...columnsSettings, userId: this.userId })
      .pipe(map((response: ResponseModel<ColumnsSettings>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
