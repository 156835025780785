import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { BankAccountsListTabsEnum } from '../../enums';
import { BankAccountsListState } from '../reducers';
import { BankAccountModel } from '../../models';

export const LoadBankAccountsList = createAction(
  '[Bank Accounts] Load Bank Accounts List',
  props<{ bankAccountsListData: BankAccountsListState, status: BankAccountsListTabsEnum, page: number }>()
);

export const LoadBankAccount = createAction(
  '[Bank Accounts] Load Bank Account',
  props<{ bankAccount: BankAccountModel }>()
);

export const UpdateBankAccountUIState = createAction(
  '[Bank Accounts] Update Bank Account Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateBankAccountUpdatedAt = createAction(
  '[Bank Accounts] Update Bank Account updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Bank Accounts] Update Bank Account should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const IncrementLoadingRequestsCount = createAction('[Bank Accounts] Increment Loading Requests Count');

export const DecrementLoadingRequestsCount = createAction('[Bank Accounts] Decrement Loading Requests Count');
