import { PurposeTypeEnum } from '../purchase-order/enums';

// possible payment deadlines of trade offer
// @value represents hours
export const PAYMENT_DEADLINE_LIST: {value: number, label: string}[] = [
  {value: 7, label: 'FORM.7_DAYS'},
  {value: 14, label: 'FORM.14_DAYS'},
  {value: 30, label: 'FORM.30_DAYS'},
  {value: 60, label: 'FORM.60_DAYS'},
  {value: 90, label: 'FORM.90_DAYS'},
  {value: null, label: 'COMMON.USER_DEFINED'}
];

export const PURPOSES_LIST: {value: string, label: string}[] = [
  {value: PurposeTypeEnum.RESALE, label: 'CATEGORIES.RESALE'},
  {value: PurposeTypeEnum.INTERNAL_USAGE, label: 'CATEGORIES.INTERNAL'},
];
