import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from '../../../store/state/app.state';
import { selectPurchaseOrder } from '../../store/selectors';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { PurchaseOrder } from '../../models';
import { PoTabs } from '../../enums';

@Component({
  selector: 'rnpl-purchase-order-attachments',
  templateUrl: './purchase-order-attachments.component.html',
  styleUrls: ['./purchase-order-attachments.component.scss']
})
export class PurchaseOrderAttachmentsComponent implements OnInit {
  public purchaseOrder: PurchaseOrder;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.trackPurchaseOrderChanges();
  }

  private trackPurchaseOrderChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
      });
  }

  get isReadonly(): boolean {
    return this.purchaseOrder.status === PoTabs.Canceled
      || this.purchaseOrder.status === PoTabs.Deleted
      || this.purchaseOrder.status === PoTabs.Template;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
