import { PoTabs } from './enums';

export const STATUS_KEY = 'status';

export const STATUS_CLASSES: {[key: string]: string} = {
  [PoTabs.Draft]: 'rnpl-badge--grey',
  [PoTabs.Open]: 'rnpl-badge--blue',
  [PoTabs.Deleted]: 'rnpl-badge--empty',
  [PoTabs.Completed]: 'rnpl-badge--green',
  [PoTabs.Canceled]: 'rnpl-badge--red',
  ['editing']: 'rnpl-badge--main-400',
  ['corrupted']: 'rnpl-badge--yellow',
  ['active']: 'rnpl-badge--blue'
};
