import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { UIStatesEnum } from 'common/src/models';
import { takeUntil } from 'rxjs/operators';
import { HrmService } from '../../hrm.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { selectTeamsUser, selectTeamsUserCurrentState } from '../../store/selectors';
import { AppState } from '../../../store/state/app.state';
import { TableColumnModel } from 'common/src/models/table-column.model';
import { getUserPermissionsColumns } from './role-and-permissions.component.config';
import { UserProfileModel } from '../../models';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';

@Component({
  selector: 'rnpl-role-and-permissions',
  templateUrl: './role-and-permissions.component.html',
  styleUrls: ['./role-and-permissions.component.scss']
})
export class RoleAndPermissionsComponent implements OnInit {

  public userData: UserProfileModel;
  public permissions: any[] = [];
  public editingMode: boolean = false;
  public isMyProfile: boolean = false;

  public columns: TableColumnModel[] = getUserPermissionsColumns(UIStatesEnum.VIEW);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hrmService: HrmService,
    private toasterService: ToasterService,
    private store:  Store<AppState>,
  ) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isMyProfile = this.route.parent.snapshot.data.myProfile;
        }
      });
  }

  ngOnInit() {
    this.isLoading$.next(true);

    this.store.select(selectTeamsUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.userData = user;
        this.permissions = [];

        for (let app in user.roleAndPermissions.permissions) {
          if (user.roleAndPermissions.permissions.hasOwnProperty(app)) {
            this.permissions.push(this.getPermissions(app, user.roleAndPermissions.permissions[app]));
          }
        }
        this.permissions = [...this.permissions];

        this.isLoading$.next(false);
      });

    this.store.select(selectTeamsUserCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
        this.columns = getUserPermissionsColumns(this.isMyProfile ? UIStatesEnum.VIEW : state);
      });
  }

  public updateUser(field: FormInputChangedModel): void {
    this.hrmService.updateUserProfile(this.userData.profile.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public getPermissions(appName: string, permissions: string[]): any {
    const permission = {
      module: appName
    };

    if (!permissions.length) {
      return {
        ...permission,
        locked: 'selected',
        view: 'availableForSelect',
        edit: 'availableForSelect',
        manage: 'availableForSelect',
      };
    }

    if (permissions.includes('admin')) {
      return {
        ...permission,
        locked: 'availableForSelect',
        view: 'activated',
        edit: 'activated',
        manage: 'selected',
      };
    }

    if (permissions.includes('write')) {
      return {
        ...permission,
        locked: 'availableForSelect',
        view: 'activated',
        edit: 'selected',
        manage: 'availableForSelect',
      };
    }

    if (permissions.includes('read')) {
      return {
        ...permission,
        locked: 'availableForSelect',
        view: 'selected',
        edit: 'availableForSelect',
        manage: 'availableForSelect',
      };
    }
  }

  public onTableIconClick(event): void {
    const permissions = {
      manage: 'admin',
      edit: 'write',
      view: 'read',
      locked: 'locked',
    };

    this.hrmService.updateUserPermissionsV2(
      this.userData.profile.id,
      event.row.module,
      permissions[event.column.prop],
      event.value === 'availableForSelect' || event.value === 'activated'
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }


}
