import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { LoadTransactionsList } from '../store/actions/transactions.actions';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from '../../../../store/state/app.state';
import { ResponseList, ResponseModel } from '../../../../shared/models/response';
import { FilterModel } from '../../../models/filter.model';
import { BinLocationProductModel, TransactionModel } from '../models';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ProductModel } from 'common/src/modules/products';
import { StockAreaEnum, TransactionTypeEnum } from '../enums';
import { DisplayToaster } from '../../../../shared/decorators/toaster';
import { IncomingDeliveryListTabsEnum } from '../../incoming-delivery/enums';
import { DEFAULT_SORT_DIRECTION } from '../../../../shared/constants';
import { selectCompanyProfile } from '../../../../administration/store/selectors';
import { CompanyProfile } from '../../../../administration/models/company-profile.model';
import { AdministrationsApiService } from '../../../../administration/services/administrations-api.service';


@Injectable({
  providedIn: 'root'
})
export class TransactionsApiService {
  public companyProfile: CompanyProfile;
  private readonly apiEndpoint: string = '/warehouse/transactions';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  getTransactionsList(
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = { nameColumn: 'createdAt', value: DEFAULT_SORT_DIRECTION },
    filters: any = {}
  ): Observable<ResponseList<TransactionModel>> {
    const params = {
      page,
      per_page,
      [`sort[${sort.nameColumn}]`]: sort.value,
    };

    for (const [key, value] of Object.entries(filters)) {
      const val: string = Array.isArray(value) ? value.join(',') : value.toString();
      const replacedVal: string = val
        .replace(this.translateService.instant('COLUMN.VENDOR'), 'vendor')
        .replace(this.translateService.instant('COLUMN.CUSTOMER'), 'Customer');

      params[`filters[${key}]`] = replacedVal;
    }

    return this.http.get<ResponseList<TransactionModel>>(this.apiUrl(), { params })
      .pipe(tap((data: ResponseList<TransactionModel>) => {
        this.store.dispatch(LoadTransactionsList({
          transactionsListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          page: data.pagination.page
        }));
      }));
  }

  public getTransactionSBinLocationsFrom(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/from'))
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data.map(str => {
            return str
              .replace('vendor', this.translateService.instant('COLUMN.VENDOR'))
              .replace('Customer', this.translateService.instant('COLUMN.CUSTOMER'))
          });
        })
      );
  }

  public getTransactionSBinLocationsTo(): Observable<string[]> {
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/to'))
      .pipe(
        map((response: ResponseModel<string[]>) => {
          return response.data.map(str => {
            return str
              .replace('vendor', this.translateService.instant('COLUMN.VENDOR'))
              .replace('Customer', this.translateService.instant('COLUMN.CUSTOMER'))
          });
        })
      );
  }

  public getAvailableForTransactionProducts(transactionType: TransactionTypeEnum): Observable<Partial<ProductModel>[]> {
    return this.http.get<ResponseModel<Partial<ProductModel>[]>>(this.apiUrl(`/${transactionType.replace('_', '-')}/products`))
      .pipe(
        map((response: ResponseModel<Partial<ProductModel>[]>) => response.data)
      );
  }

  public getTransactionBinLocations(productId?: number, stockArea?: StockAreaEnum, excludeBinId?: number): Observable<any> {
    const params = { };

    if (productId) {
      params['productId'] = productId;
    }

    if (stockArea) {
      params['stockArea'] = stockArea;
    }

    if (excludeBinId) {
      params['excludeBinId'] = excludeBinId;
    }

    return this.http.get(this.apiUrl('/bin-locations'), { params });
  }

  public getBatches(status?: IncomingDeliveryListTabsEnum): Observable<any> {
    const params = { };

    if (status) {
      params['status'] = status;
    }
    return this.http.get<ResponseModel<any>>('/warehouse/batches', { params })
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  public getBinLocationProductData(productId: number, binLocationId: number): Observable<BinLocationProductModel[]> {
    return this.http.get<ResponseModel<BinLocationProductModel[]>>(this.apiUrl(`/bin-locations/${binLocationId}/product/${productId}`))
      .pipe(
        map((response: ResponseModel<BinLocationProductModel[]>) => response.data)
      );
  }

  public createTransaction(body, transactionType: TransactionTypeEnum): Observable<any> {
    return this.http.request('post', this.apiUrl(`/${transactionType.replace('_', '-')}`), { body })
      .pipe(
        tap((response: ResponseModel<any>) => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
      )
  }

  public getTransactionsListExport(): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/csv`),
      type: 'zip'
    };
    return of(fileParams);
  }

  public getLinkedDocument(): Observable<any> {
    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/linked-documents`)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

}
