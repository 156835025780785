import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { OinTypeEnum } from '../../outgoing-invoice/enums';

export function getDocumentTypeLabel(docType: string | DocumentTypesUppercaseEnum, document?: any): string {
  let label = '';
  switch (docType) {

    case DocumentTypesUppercaseEnum.OIN:
    case 'outgoing_invoice':
      label = 'APP.OIN';
      if (document && document.oinType === OinTypeEnum.PREPAYMENT) {
        label = 'FINAL_INVOICE.DOWN_PAYMENT_INVOICE';
      }
      if (document && document.oinType === OinTypeEnum.FINAL) {
        label = 'FINAL_INVOICE.FINAL_INVOICE';
      }
      break;

    case DocumentTypesUppercaseEnum.IIN:
      label = 'APP.INCOMING_INVOICE';
      break;

    case DocumentTypesUppercaseEnum.OPB:
      label = 'PAYMENT.OUTGOING_PAYMENT';
      break;

    case DocumentTypesUppercaseEnum.IPB:
      label = 'PAYMENT.INCOMING_PAYMENT';
      break;

    case DocumentTypesUppercaseEnum.ICN:
      label = 'COMMON.INCOMING_CREDIT_NOTE';
      break;

    case DocumentTypesUppercaseEnum.CRN:
    case 'credit_note':
      label = 'APP.CREDIT_NOTE';
      break;

    case DocumentTypesUppercaseEnum.OCN:
      label = 'COMMON.OUTGOING_CREDIT_NOTE';
      break;

    case DocumentTypesUppercaseEnum.SO:
    case 'sales_order':
    case 'so':
      label = 'APP.SALES_ORDER';
      break;

    case 'po':
    case 'purchase_order':
    case DocumentTypesUppercaseEnum.PO:
      label = 'APP.PURCHASE_ORDER';
      break;

    case 'era':
    case DocumentTypesUppercaseEnum.ERA:
      label = 'APP.EXCHANGE_AND_RETURN';
      break;

    case 'delivery_note':
    case 'dn':
    case DocumentTypesUppercaseEnum.DN:
      label = 'APP.OUTGOING_DELIVERY';
      break;

    case 'offer':
    case 'OFFER':
    case DocumentTypesUppercaseEnum.OFR:
      label = 'APP.OFFER';
      break;

    case 'subscription':
    case DocumentTypesUppercaseEnum.SBC:
      label = 'APP.SUBSCRIPTION';
      break;

    case DocumentTypesUppercaseEnum.TTR:
      label = 'COLUMN.LOGGED_TIME';
      break;

    default:
      label = 'Document';
      break;

  }

  return label;
}
