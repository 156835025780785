import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddressFormFactory } from '../../shared/forms/address-form.factory';
import { get } from 'lodash';
import { BillingInformation, SubscriptionModel } from '../models/subscription.model';
import { AddressTypeEnum } from '../../sales-order/enums/address-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionFormsService {

  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
  ) { }

  public initForm(subscription: SubscriptionModel): void {
    const billingInfo = subscription.billingInformation;

    this.form = this.fb.group({
      dueWithinDays: [get(billingInfo, 'dueWithinDays'), {validators: [Validators.required]}],
      billingFrequency: [get(billingInfo, 'billingFrequency')],
      specifyBillingDate: [get(billingInfo, 'specifyBillingDate')],
      billingTime: [get(billingInfo, 'billingTime')],
      // dayOfMonth: [],
      // createOutgoingInvoiceAt: [],
      // billingPeriod: [get(billingInfo, 'billingPeriod')],
      // billingFor: [get(billingInfo, 'billingFor')],
      paymentMethod: [get(billingInfo, 'paymentMethod')],
      transferMethod: [get(billingInfo, 'transferMethod')],
      // sameAsDeliveryAddress: [],
      billingAddress: AddressFormFactory.getForm(get(billingInfo, 'billingAddress')),
      bankAccountId: [+get(billingInfo, 'bankAccountId')],
      iban: [get(billingInfo, 'iban')],
      bic: [get(billingInfo, 'bic')],
      vatDisabled: [get(subscription, 'properties.vatDisabled')],
      smallBusiness: [get(subscription, 'properties.smallBusiness')],
      addressTemplate: [get(billingInfo, 'addressTemplate')],
      name: [get(billingInfo, 'name')],
    });

    if (this.form.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.form.get('billingAddress') as FormGroup);
      this.form.get('name').disable({emitEvent: false});
      this.form.get('name').updateValueAndValidity({emitEvent: false});
    } else {
      this.form.get('billingAddress').get('address_line').disable({emitEvent: false});
      this.form.get('billingAddress').get('address_line').updateValueAndValidity({emitEvent: false});
    }
  }
}
