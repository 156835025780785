import { FormControl, FormGroup, Validators } from '@angular/forms';


import { AddressModel } from 'common/src/models';

export class AddressFormFactory {

  static getForm(address: AddressModel = {} as any, readonly: boolean = false): FormGroup {
    return new FormGroup({
      country_iso3: new FormControl({
        value: address.country ? (address.country.code_iso3 || address.country.codeISO3) : address.country_iso3,
        disabled: readonly
      }, [Validators.required]),

      city: new FormControl(
        {
          value: address.city || null,
          disabled: readonly,
        }, [
          Validators.required,
          Validators.maxLength(100),
          // Validators.pattern('^[a-zA-Z0-9-\\s\u0080-\u00FF\w]{0,100}$')
        ]
      ),

      state: new FormControl(
        {
          value: address.state || null,
          disabled: readonly
        }, [
          Validators.maxLength(255),
          // Validators.pattern('^[a-zA-Z0-9-\\s\u0080-\u00FF\w]{0,255}$')
        ]
      ),

      // region: new FormControl({
      //   value: address.region || null,
      //   disabled: readonly
      // }, []),

      street: new FormControl(
        {
          value: address.street || null,
          disabled: readonly
        }, [
          Validators.required,
          Validators.maxLength(255),
          // Validators.pattern('^[a-zA-Z0-9-\\s\u0080-\u00FF\w]{0,255}$')
        ]
      ),

      floor: new FormControl(
        {
          value: address.floor || null,
          disabled: readonly
        }, [
          Validators.maxLength(10),
          // Validators.pattern('^[a-zA-Z0-9\u0080-\u00FF\w]{0,10}$')
        ]
      ),

      block: new FormControl(
        {
          value: address.block || null,
          disabled: readonly
        },
        [
          Validators.maxLength(10),
          // Validators.pattern('^[a-zA-Z0-9\u0080-\u00FF\w]{0,10}$')
        ]
      ),

      house_number: new FormControl(
        {
          value: address.house_number || null,
          disabled: readonly
        }, [
          Validators.maxLength(20),
          // Validators.required,
          // Validators.pattern('^[0-9-\\s\\/\u0080-\u00FF\w]{0,20}$')
        ]
      ),

      apartments: new FormControl(
        {
          value: address.apartments || null,
          disabled: readonly
        }, [
          Validators.maxLength(10),
          // Validators.pattern('^[a-zA-Z0-9-\u0080-\u00FF\w]{0,10}$')
        ]
      ),

      zip_code: new FormControl(
        {
          value: address.zip_code || null,
          disabled: readonly
        }, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(8),
          // Validators.pattern('^[a-zA-Z0-9-\\s\\/\u0080-\u00FF\w]{0,8}$')
        ],
      ),
      additional_information: new FormControl(
        {
          value: address.additional_information || null,
          disabled: readonly
        }, [
        ],
      ),
    },
    { updateOn: 'blur' });
  }

}
