import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'rnpl-auth-key-page',
  templateUrl: './auth-key-page.component.html',
  styleUrls: ['./auth-key-page.component.scss']
})
export class AuthKeyPageComponent implements OnInit {

  public showHeader: boolean = false;

  public userData;

  @HostListener('scroll', ['$event'])
  onScrollEvent($event) {
    this.showHeader = $event.target.scrollTop > 100;
  }

  constructor(private route: ActivatedRoute, private router: Router, private titleService: Title) {
    this.userData = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Runple - auth key');
  }
}
