import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { WarehouseSettingsModel } from 'projects/workspace/src/app/warehouse/warehouse-settings-module/models';

@Component({
  selector: 'rnpl-ups-settings-modal',
  templateUrl: './ups-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpsSettingsModalComponent {

  public form: FormGroup;

  constructor(
    public fb: FormBuilder,
    public deliveryNoteApiService: DeliveryNoteApiService,
    public dialogRef: MatDialogRef<UpsSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      warehouseSettings: WarehouseSettingsModel
    }
  ) {
    this.initForm();
    this.form.patchValue(this.data.warehouseSettings);
  }

  initForm(): void {
    this.form = this.fb.group({
      upsAccessKey: [null, [Validators.required]],
      upsAccountNumber: [null, [Validators.required]],
      upsPassword: [null, [Validators.required]],
      upsUserId: [null, [Validators.required]],
    });
  }

  public onNoAccountClick(): void {
    // window.open('https://ec.europa.eu/commission/presscorner/detail/de/QANDA_19_5555', '_blank');
  }

  public submit(): void {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.dialogRef.close(this.form.getRawValue());
  }

  get upsAccessKeyControl(): FormControl { return this.form.get('upsAccessKey') as FormControl; }
  get upsAccountNumberControl(): FormControl { return this.form.get('upsAccountNumber') as FormControl; }
  get upsPasswordControl(): FormControl { return this.form.get('upsPassword') as FormControl; }
  get upsUserIdControl(): FormControl { return this.form.get('upsUserId') as FormControl; }

}

