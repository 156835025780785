import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { TradeOfferPositionsByProductType } from 'projects/workspace/src/app/trade-offer/models';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { TradeDocumentPositionModel } from 'projects/workspace/src/app/trade/models';
import { TradeApiService } from 'projects/workspace/src/app/trade';
import { ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { CompositeProductModalComponent } from '../../modals-products';
import { CustomSearchFn, getProductSearchValue } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { SalesPosition } from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { PoTabs, PurposeTypeEnum } from 'projects/workspace/src/app/purchase-order/enums';
import { CORRECTION_POSITION_TYPES_LIST } from 'projects/workspace/src/app/accounting/accounting.constants';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from '../../modals.contsans';

interface DataType {
  purchaseOrderId: number;
  purchaseOrderStatus: PoTabs;
  vatBlocked: boolean;
}

@Component({
  selector: 'rnpl-po-position-modal',
  templateUrl: './po-position-modal.component.html',
  styleUrls: ['./po-position-modal.component.scss']
})
export class PoPositionModalComponent extends BaseModalComponent implements OnInit {
  public companyProfile: CompanyProfile;

  public productType = PRODUCTS_TYPE;

  public productsList: ProductModel[] = [];
  public positionsListFromSO: TradeDocumentPositionModel[] = [];

  public isProductFromSO: boolean = false;
  public readonly productTypes = ProductTypes;
  public selectedProductInvalid: boolean = false;
  public selectedPositionInvalid: boolean = false;
  public productNameInvalid: boolean = false;
  public allowFractionalValues: boolean = true;
  public currentCurrency: string = 'EUR';
  public selectedPosition: TradeDocumentPositionModel = null;
  public selectedProduct: ProductModel;
  public selectedProductType: ProductTypes|string = ProductTypes.GOODS;

  public customSearchFn = CustomSearchFn;

  readonly addPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public showDropdownSpinSo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public form: FormGroup;
  public readonly vatList = VAT_LIST;
  // public purposeList = PURPOSES_LIST;
  public correctionPositionTypesList = CORRECTION_POSITION_TYPES_LIST;

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<PoPositionModalComponent>,
    public dialog: MatDialog,
    private productsService: ProductsService,
    private tradeApi: TradeApiService,
    private poApi: PurchaseOrderApiService,
    private salesOrderApiService: SalesOrderApiService,
    private fb: FormBuilder,
    private recalculatePositionApiService: RecalculatePositionApiService,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.initForm();
    this.getProducts();
  }

  private initForm() {
    this.form = this.fb.group({
      grossTotalPrice: [null, Validators.required],
      grossUnitPrice: [null, Validators.required],
      netTotalPrice: [null, Validators.required],
      productDescription: [],
      description: [],
      providedServices: [],
      netUnitPrice: [null, Validators.required],
      vat: [20],
      discount: [],
      discountAmountNet: [],
      discountAmountGross: [],
      addDiscount: [],
      quantity: [1],
      addRemark: [false],
      remark: [''],
      purpose: [(this.form && this.purpose && this.purpose.value) || PurposeTypeEnum.RESALE],
      specifyAmountsSeparately: [],
      correctionType: [],
      vatTotal: [],
      unitType: ['psc'],
    });
  }

  public selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;
    this.initForm();
    this.unitTypeControl.patchValue(selectedProduct.unitType || selectedProduct.unit_type);
    this.productDescription.patchValue(selectedProduct.description);
    this.providedServices.patchValue(selectedProduct.providedServices || selectedProduct.description);

    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
      this.vat.setValue(0);
    }
    this.getProductById(+selectedProduct.id);
  }

  public getProductById(productId) {
    this.productsService.getProduct(productId)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.unitTypeControl.patchValue(product.unitType);
        this.allowFractionalValues = product.unitTypeModel.allowFractionalValues && product.productType !== ProductTypes.GOODS;
      });
  }

  public selectPositionFromSoChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;
    this.selectedProduct = {
      ...selectedProduct,
      name: selectedProduct.description,
      id: selectedProduct.productId
    };

    this.initForm();

    this.quantity.setValue(selectedProduct.quantity);

    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
      this.vat.setValue(0);
    }
  }

  private getProducts(): void {
    this.productsService.getActiveProducts()
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = products
          .map(item => ({
            ...item,
            searchLabel: getProductSearchValue(item, this.translateService)
          }))
          .filter((product: ProductModel) => product.type !== ProductTypes.SERVICES);
        this.showDropdownSpin$.next(false);
      });

    this.salesOrderApiService.getPositionsFromSalesOrders()
      .pipe(takeUntil(this._destroy))
      .subscribe((products) => {
        this.positionsListFromSO = products.map(item => ({
          ...item,
          searchLabel: `${item.description} ${item.orderRunpleId} ${this.translatedProductType(item.productType)} ${item.netUnitPrice} ${item.quantity} ${item.productRunpleId} ${item.productDescription}`
        }));

        this.showDropdownSpinSo$.next(false);
      });
  }

  public translatedProductType(productType: ProductTypes): string {
    return this.translateService.instant(this.productType[productType]);
  }

  public isProductFromSOChanged(): void {
    this.selectedPosition = null;
    this.selectedProduct = null;
    this.selectedPositionInvalid = false;
    this.selectedPositionInvalid = false;
  }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.selectPositionChanges(product);
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
      }
    });
  }

  public createPosition(): void {
    if (this.selectedProductType === ProductTypes.CORRECTION) {
      if (!this.description.valid) {
        this.productNameInvalid = true;
        return;
      }
    } else {
      if (!this.selectedProduct && !this.selectedPosition) {
        this.selectedProductInvalid = true;
        this.selectedPositionInvalid = true;
        return;
      }
    }

    if (this.addPositionRequest$.getValue()) { return; }
    this.addPositionRequest$.next(true);

    const position: SalesPosition = {} as SalesPosition;

    if (this.isProductFromSO) {
      position.productId = this.selectedPosition.productId;
      position.soPositionId = this.selectedPosition.positionId;
    } else {
      position.productId = +this.selectedProduct.id;
    }

    this.poApi.createPurchasePosition(
      this.data.purchaseOrderId,
      {
        ...position,
        ...this.form.getRawValue(),
        grossTotalPrice: this.getCreationCurrency(this.grossTotalPrice),
        grossUnitPrice: this.getCreationCurrency(this.grossUnitPrice),
        netTotalPrice: this.getCreationCurrency(this.netTotalPrice),
        netUnitPrice: this.getCreationCurrency(this.netUnitPrice),
        discountAmountNet: this.getCreationCurrency(this.discountAmountNet),
        discountAmountGross: this.getCreationCurrency(this.discountAmountGross),
        vatTotal: this.getCreationCurrency(this.vatTotal),
        productId: this.selectedProduct.id,
        productType: this.selectedProductType,
        positionName: this.selectedProductType === ProductTypes.CORRECTION
          ? this.description.value
          : null,
        positionType: this.selectedProductType === ProductTypes.CORRECTION
          ? ProductTypes.CORRECTION
          : null,
        productTypeForGrouping: this.selectedProductType,
      })
      .pipe(
        finalize(() => { this.addPositionRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe((response: ResponseModel<TradeOfferPositionsByProductType>) => {
        this.poApi.getPurchaseOrderSummary(this.data.purchaseOrderId).subscribe();
        this.dialogRef.close(response);
      });
  }

  public setCurrencyControl(val): number {
    if (!val) { return val; }
    return +(val * 100).toFixed(0);
  }

  public getCreationCurrency(control: FormControl): number {
    return this.isCorrectionProductType
      ? this.setCurrencyControl(control.value) || 0
      : this.setCurrencyControl(control.value);
  }

  public updatePosition(value, fieldName, force: boolean = false): void {
    if (fieldName === 'specifyAmountsSeparately' && !value && !force) {
      if (
        (this.netTotalPrice.value && (this.netTotalPrice.value !== '0.00' && this.netTotalPrice.value !== 0)) ||
        (this.grossTotalPrice.value && (this.grossTotalPrice.value !== '0.00' && this.grossTotalPrice.value !== 0)) ||
        (this.vatTotal.value && (this.vatTotal.value !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(value, fieldName, true);
          } else {
            this.specifyAmountsSeparately.setValue(true);
          }
        });
        return;
      }
    }

    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.PO, {
      protoPosition: {
        ...this.form.getRawValue(),
        productId: this.selectedProduct.id,
        productTypeForGrouping: this.selectedProductType,
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(product => {
        this.form.patchValue(product);
      });
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.selectedProductType === ProductTypes.SERVICES || this.selectedProductType === ProductTypes.ARBITRARY) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.productDescription.value, 'productDescription');
    }
  }

  public typeServicesOrArbitrary(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES || this.selectedProductType === ProductTypes.ARBITRARY;
  }

  public displayCorrectionPosition(): void {
    this.selectedProductType = ProductTypes.CORRECTION;
    this.selectedProduct = { name: this.translateService.instant('FORM.CORRECTION') };
    this.initForm();
    this.correctionType.setValue('NEGATIVE');
    if (this.data.vatBlocked) {
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
      this.vat.setValue(0);
    }
  }

  get isCorrectionProductType(): boolean {
    return this.selectedProductType === ProductTypes.CORRECTION;
  }
  get isGeneralProductType(): boolean {
    return this.selectedProductType === ProductTypes.GENERAL;
  }
  get isTemplate(): boolean {
    return this.data && this.data.purchaseOrderStatus === PoTabs.Template;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get addRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get discountAmountGross(): FormControl { return this.form.get('discountAmountGross') as FormControl; }
  get addDiscount(): FormControl { return this.form.get('addDiscount') as FormControl; }
  get purpose(): FormControl { return this.form.get('purpose') as FormControl; }
  get specifyAmountsSeparately(): FormControl { return this.form.get('specifyAmountsSeparately') as FormControl; }
  get description(): FormControl { return this.form.get('description') as FormControl; }
  get correctionType(): FormControl { return this.form.get('correctionType') as FormControl; }
  get vatTotal(): FormControl { return this.form.get('vatTotal') as FormControl; }
  get unitTypeControl(): FormControl { return this.form.get('unitType') as FormControl; }
}
