import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { getColumns } from './create-po-modal.config';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { TradeDocumentPositionModel } from 'projects/workspace/src/app/trade/models';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { PurchaseOrder } from 'projects/workspace/src/app/purchase-order/models';
import { SalesOrderApiHelperService } from 'projects/workspace/src/app/sales-order/services/sales-order-api-helper.service';

@Component({
  selector: 'rnpl-create-po-modal',
  templateUrl: './create-po-modal.component.html',
})
export class CreatePoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public columns: TableColumnModelExtended[] = getColumns();
  public emptyStateTypeEnum = EmptyStateTypeEnum;
  public addAllToggle = true;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly productsList$: BehaviorSubject<TradeDocumentPositionModel[]> = new BehaviorSubject<TradeDocumentPositionModel[]>([]);
  readonly productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    public salesOrderApiHelperService: SalesOrderApiHelperService,
    public purchaseOrderApiService: PurchaseOrderApiService,
    public dialogRef: MatDialogRef<CreatePoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      soId: number,
      proceedSo: boolean,
      finishSoEditing: boolean
    }
  ) {}

  ngOnInit(): void {
    this.salesOrderApiHelperService.getPositionsAvailableForPO(this.data.soId)
      .pipe(
        finalize(() => this.productsLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((products) => this.productsList$.next(this.prepareProductsList(products)));
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  public prepareProductsList(products): any {
    return products.map(item => {
      return {
        ...item,
        productLink: {
          label: item.productRunpleId,
          routerLink: `/products/product-view/${item.productId}`
        },
        productNameLink: {
          label: item.description,
          routerLink: `/products/product-view/${item.productId}`
        },
        confirmQuantity: item.quantity
      };
    });
  }

  public addAllProducts(e: boolean): void {
    if (!e) { return; }
    const products = this.productsList$.getValue().map(p => ({
      ...p,
      confirmQuantity: p.quantity
    }));
    this.productsList$.next(products);
  }

  public submit(): void {
    const positions = this.productsList$.getValue().map(p => ({
      ...p,
      quantity: p.confirmQuantity
    }));

    this.purchaseOrderApiService.createPurchaseOrderBySalesOrderId(
      this.data.soId,
      positions,
      this.data.proceedSo,
      this.data.finishSoEditing
    )
      .pipe(
        // finalize(() => this.btnToClearLoadingStatus$.next('onCreatePurchaseOrderClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((purchaseOrder: PurchaseOrder) => this.dialogRef.close(purchaseOrder));
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
