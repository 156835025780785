import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DeliveryServiceEnum } from '../enums';
import { InfoModalComponent } from 'common/src/modules/modals/modals-common/info-modal/info-modal.component';
import { TrackShipmentHelper } from '../helpers';

@Injectable()
export class TrackShipmentService {

  public readonly allowedDeliveryServices: DeliveryServiceEnum[] = [
    DeliveryServiceEnum.AUSTRIAN_POST,
    DeliveryServiceEnum.DHL,
    DeliveryServiceEnum.UPS,
    DeliveryServiceEnum.DPD,
  ];

  constructor(private readonly dialog: MatDialog) { }

  public canTrackDelivery(deliveryService: DeliveryServiceEnum): boolean {
    return this.allowedDeliveryServices.includes(deliveryService);
  }

  public trackShipment(trackingCode: string, deliveryService: DeliveryServiceEnum): void {
    if (!deliveryService || !this.canTrackDelivery(deliveryService)) {
      this.openInfoModal('WAREHOUSE.DELIVERY_NOTE.TRACK_DELIVERY_WRONG_SERVICE');
      return;
    }

    if (!trackingCode) {
      this.openInfoModal('WAREHOUSE.DELIVERY_NOTE.TRACK_DELIVERY_NO_CODE');
      return;
    }

    TrackShipmentHelper(deliveryService, trackingCode);
  }

  private openInfoModal(message: string): void {
    this.dialog.open(InfoModalComponent, {
      data: {
        title: 'WAREHOUSE.DELIVERY_NOTE.TRACK_DELIVERY',
        message,
        confirmBtnText: 'BUTTON.CLOSE',
        hideCancelBtn: true,
        confirmBtnIcon: 'close'
      }
    });
  }

}
