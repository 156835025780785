import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rnpl-empty-positions',
  templateUrl: './empty-positions.component.html',
  styleUrls: ['./empty-positions.component.scss']
})
export class EmptyPositionsComponent {
  @Input() isActionType: boolean = false;
  @Input() isReadOnly: boolean = true;
  @Input() icon: string = 'list';
  @Input() description: string = 'BUTTON.ADD_POSITION';

  @Output()
  public displayAddPositionModalEmit: EventEmitter<any> = new EventEmitter();

  public goToAnchor() {
    try {
      document.getElementById('positions').scrollIntoView();
    } catch (e) { }
  }

  public displayAddPositionModal():void {
    if (this.isReadOnly) { return; }
    this.displayAddPositionModalEmit.emit();
  }
}
