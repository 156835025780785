import { SalesOrderListTabsEnum } from '../../enums';
import { UIStatesEnum } from 'common/src/models';

export function HintsListByName(
  hasSalesOrders?: boolean,
  currentState?: UIStatesEnum,
  hasLinkedDeliveryNote?: boolean,
  hasDocuments?: boolean,
  hasPosition?: boolean,
  hasLinkedInvoice?: boolean,
  hasVat?: boolean,
  activeStatus?: SalesOrderListTabsEnum,
  displayFieldValidationHint?: boolean,
  displayContactPersonFieldHint?: boolean,
  isShowDocumentBar?: boolean,
  hasGoods?: boolean,
) {

  const isReadOnly = currentState === UIStatesEnum.VIEW;
  const isCreateState = currentState === UIStatesEnum.CREATE;
  const isEditState = currentState === UIStatesEnum.EDIT;

  const isDraftStatus = activeStatus === SalesOrderListTabsEnum.DRAFT;
  const isOpenStatus = activeStatus === SalesOrderListTabsEnum.OPEN;
  const isCompletedStatus = activeStatus === SalesOrderListTabsEnum.COMPLETED;
  const isCanceledStatus = activeStatus === SalesOrderListTabsEnum.CANCELED;
  const isDeletedStatus = activeStatus === SalesOrderListTabsEnum.DELETED;

  const hintsList = {
    ['sales_orders_view_draft_3']: {
      text: 'MEGA_HINT.SO.HINT_14',
      attachTo: {
        element: '#emailSendControls',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -20] }}]
      },
    },
    // ['reverse_charge_toggle_sales_orders']: { // todo: move to billingInfo Modal
    //   text: 'MEGA_HINT.SO.HINT_24',
    //   attachTo: {
    //     element: '#vatDisabled',
    //     on: 'bottom'
    //   },
    // },
    ['create_entity_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_29',
      attachTo: {
        element: '#createSoBtn',
        on: 'bottom'
      },
    },
    ['filter_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_30',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
  }

  const sidebarSoHint = {
    ['sidebar_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_27',
      attachTo: {
        element: '#sidebarSoHint',
        on: 'left'
      },
    },
    ['sales_orders_view_draft_2']: {
      text: 'MEGA_HINT.SO.HINT_13',
      attachTo: {
        element: '#endStep',
        on: 'bottom'
      },
    },
  }

  const editBtnHint = {
    ['edit_entity_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_36',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
  }

  const cloneBtnHint = {
    ['clone_entyty_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_35',
      attachTo: {
        element: '#cloneBtn',
        on: 'bottom'
      },
    },
  }
  const exportBtnHint = {
    ['export_send_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_25',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const openViewStateHints = {
    ['cancel_entity_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_34',
      attachTo: {
        element: '#canceledBtn',
        on: 'bottom'
      },
    },
  }

  const soProcessStepHint = {
    ['sales_orders_view_open_1']: {
      text: 'MEGA_HINT.SO.HINT_18',
      attachTo: {
        element: '#currentStep',
        on: 'left'
      },
    },
  }

  const billingStepHint = {
    ['sales_orders_view_open_4']: {
      text: 'MEGA_HINT.SO.HINT_17',
      attachTo: {
        element: '#billingStep',
        on: 'left'
      },
    },
  }

  const columnsHints = {
    ['sales_orders_tab_draft_3']: {
      text: 'MEGA_HINT.SO.HINT_3',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'top'
      },
    },
    ['export_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_22',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const editStateHints = {
    // ['sales_orders_view_draft_4']: { //todo: move to remark modal
    //   text: 'MEGA_HINT.SO.HINT_15',
    //   attachTo: {
    //     element: '#printable_remark',
    //     on: 'bottom'
    //   },
    // },
    ['save_timer_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_32',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
    ['create_positions_button_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_39',
      attachTo: {
        element: '#addPositionBtn',
        on: 'bottom'
      },
    },
  }

  // const contactPersonFieldHint = {
  //   ['sales_orders_view_draft_1']: { // todo: move to select partner modal
  //     text: 'MEGA_HINT.SO.HINT_12',
  //     attachTo: {
  //       element: '#contactPerson',
  //       on: 'bottom'
  //     },
  //   },
  // }
  const emailIndicatorHint = {
    ['email_indicator_sales_order']: {
      text: 'MEGA_HINT.SO.HINT_41',
      attachTo: {
        element: '#emailSendCheckedField',
        on: 'bottom'
      },
    },
  }

  const fieldValidationHint = {
    ['field_validation_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_33',
      attachTo: {
        element: '#selectCustomer',
        on: 'bottom'
      },
    },
  }

  // const positionColumnGoodsOpenStatus = {
  //   ['sales_orders_view_open_2']: {
  //     text: 'MEGA_HINT.SO.HINT_19',
  //     attachTo: {
  //       element: '#openColumn',
  //       on: 'bottom'
  //     },
  //     scrollTo: {
  //       block: 'center',
  //       inline: 'center',
  //     },
  //   },
  // }

  const documentsHints = {
    ['sales_orders_view_open_3']: {
      text: 'MEGA_HINT.SO.HINT_20',
      attachTo: {
        element: '#Documents',
        on: 'top'
      },
    },
  }

  const linkedDeliveryNoteHint = {
    ['linked_document_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_28',
      attachTo: {
        element: '#deliveryNoteStep',
        on: 'bottom'
      },
    },
    ['linked_document_dn_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_28',
      attachTo: {
        element: '#deliveryNoteStep',
        on: 'bottom'
      },
    },
  }

  const linkedInvoiceHint = {
    ['linked_document_invoice_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_38',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
    },
  }

  const createSoBtn = {
    ['sales_orders_view_draft_5']: {
      text: 'MEGA_HINT.SO.HINT_16',
      attachTo: {
        element: '#createSoBtn',
        on: 'top'
      },
    },
  }

  const vatCheckedHint = {
    ['vat_indicator_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_26',
        attachTo: {
        element: '#vatCheckedField',
          on: 'bottom'
      },
    },
  }

  const openTabHint = {
    ['sales_orders_tab_open_1']: {
      text: 'MEGA_HINT.SO.HINT_4',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
  }

  const competedTabHint = {
    ['sales_orders_tab_completed_1']: {
      text: 'MEGA_HINT.SO.HINT_11',
      attachTo: {
        element: '#completedTab',
        on: 'bottom'
      },
    },
  }

  const deletedTab = {
    ['tab_deleted_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_23',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  const openTabColumnHints = {
    ['sales_orders_tab_open_2']: {
      text: 'MEGA_HINT.SO.HINT_5',
      attachTo: {
        element: '#deliveryColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['sales_orders_tab_open_3']: {
      text: 'MEGA_HINT.SO.HINT_6',
      attachTo: {
        element: '#billingColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['sales_orders_tab_open_5']: {
      text: 'MEGA_HINT.SO.HINT_8',
      attachTo: {
        element: '#openPositionsColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['sales_orders_tab_open_7']: {
      text: 'MEGA_HINT.SO.HINT_10',
      attachTo: {
        element: '#delayedSummaryBarItem',
        on: 'bottom'
      },
    },
  }

  const draftTabHint = {
    ['tab_draft_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_21',
      attachTo: {
        element: '#draftTab',
        on: 'bottom'
      },
      classes: 'ml-8'
    },
  }
  const canceledTabHint = {
    ['sales_orders_tab_canceled_1']: {
      text: 'MEGA_HINT.SO.HINT_40',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
    },
  }

  const positionColumnHint = {
    ['sales_orders_tab_draft_1']: {
      text: 'MEGA_HINT.SO.HINT_1',
      attachTo: {
        element: '#positionsColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const openAndDraftColumnsHints = {
    ['sales_orders_tab_draft_2']: {
      text: 'MEGA_HINT.SO.HINT_2',
      attachTo: {
        element: '#estimateProfitColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['sales_orders_tab_open_4']: {
      text: 'MEGA_HINT.SO.HINT_7',
      attachTo: {
        element: '#deliveryDateColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['sales_orders_tab_open_6']: {
      text: 'MEGA_HINT.SO.HINT_9',
      attachTo: {
        element: '#updateColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const tableSummaryHint = {
    ['total_row_sales_orders']: {
      text: 'MEGA_HINT.SO.HINT_31',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }

  if (hasSalesOrders) { Object.assign(hintsList, columnsHints); }
  if (!isReadOnly) { Object.assign(hintsList, editStateHints); }
  if (hasLinkedDeliveryNote && !isDraftStatus && !isCanceledStatus && !isCreateState && hasGoods) { Object.assign(hintsList, linkedDeliveryNoteHint); }
  if (hasDocuments) { Object.assign(hintsList, documentsHints); }
  // if (hasGoods && isOpenStatus) { Object.assign(hintsList, positionColumnGoodsOpenStatus); }
  if (hasLinkedInvoice && !isDraftStatus && !isCanceledStatus && !isCreateState && !isDeletedStatus) { Object.assign(hintsList, linkedInvoiceHint); }
  if (isCreateState && isDraftStatus) { Object.assign(hintsList, createSoBtn); }
  if (hasVat && !isCreateState && isShowDocumentBar) { Object.assign(hintsList, vatCheckedHint); }

  if (isOpenStatus) {
    Object.assign(hintsList, openTabHint);

    if (hasSalesOrders) { Object.assign(hintsList, openTabColumnHints); }
  }

  if (isCompletedStatus) { Object.assign(hintsList, competedTabHint); }

  if (isDeletedStatus) { Object.assign(hintsList, deletedTab); }

  if (isDraftStatus) { Object.assign(hintsList, draftTabHint); }

  if (isCanceledStatus) { Object.assign(hintsList, canceledTabHint); }

  if (!isOpenStatus && hasSalesOrders) { Object.assign(hintsList, positionColumnHint); }

  if ((isOpenStatus || isDraftStatus) && hasSalesOrders ) { Object.assign(hintsList, openAndDraftColumnsHints); }

  if (!isCanceledStatus && !isDeletedStatus && hasSalesOrders) { Object.assign(hintsList, tableSummaryHint);}

  if (!isDraftStatus && !isCreateState && isShowDocumentBar) { Object.assign(hintsList, soProcessStepHint); }

  if (isOpenStatus && isReadOnly) {
    Object.assign(hintsList, openViewStateHints);
    Object.assign(hintsList, billingStepHint);
  }

  if (!isReadOnly && displayFieldValidationHint) { Object.assign(hintsList, fieldValidationHint); }

  if (!isCanceledStatus && !isDeletedStatus && isReadOnly) {
    Object.assign(hintsList, cloneBtnHint);
    Object.assign(hintsList, exportBtnHint);
  }

  if ((isDraftStatus || isOpenStatus) && isReadOnly) { Object.assign(hintsList, editBtnHint); }
  // if (displayContactPersonFieldHint && !isReadOnly) { Object.assign(hintsList, contactPersonFieldHint); }
  if (isDraftStatus && !isReadOnly) { Object.assign(hintsList, sidebarSoHint); }
  if (!isDraftStatus && !isCreateState && isShowDocumentBar) { Object.assign(hintsList, emailIndicatorHint); }

  return hintsList;
}

export const SelectPartnersHints = {
  ['sales_orders_view_draft_1']: {
    text: 'MEGA_HINT.SO.HINT_12',
    attachTo: {
      element: '#contactPersonFromModal',
      on: 'bottom'
    },
  },
}

export const RemarkHints = {
  ['sales_orders_view_draft_4']: {
    text: 'MEGA_HINT.SO.HINT_15',
    attachTo: {
      element: '#printable_remark',
      on: 'bottom'
    },
  },
}

export const BillingInfoHints = {
  ['reverse_charge_toggle_sales_orders']: {
    text: 'MEGA_HINT.SO.HINT_24',
    attachTo: {
      element: '#vatDisabled',
      on: 'bottom'
    },
  },
}
