import { Component, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'rnpl-wizard-nav-footer',
  templateUrl: './wizard-nav-footer.component.html',
  styleUrls: ['./wizard-nav-footer.component.scss']
})
export class WizardNavFooterComponent {

  @Output()
  public hintsEnabled: EventEmitter<boolean> = new EventEmitter();

  public enableHints(event) {
    this.hintsEnabled.emit(event.target.checked);
  }
}
