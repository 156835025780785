import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { ScheduledPaymentModel } from '../../models';
import { FormInputChangedModel } from '../../../shared/models';
import {
  ScheduledPaymentBillingFrequencyEnum,
  ScheduledPaymentDurationEnum,
  ScheduledPaymentListTabsEnum,
} from '../../enums';
import { BILLING_FREQUENCY_LIST, DURATION_LIST } from '../../scheduled-payment.constants';


@Component({
  selector: 'rnpl-scheduled-payment-billing-schedule-form',
  templateUrl: './scheduled-payment-billing-schedule-form.component.html',
})
export class ScheduledPaymentBillingScheduleFormComponent implements OnChanges {

  @Input() form: FormGroup;
  @Input() scheduledPayment: ScheduledPaymentModel;
  @Input() modalView = false;
  @Input() hasPeriods = false;

  public durationList = DURATION_LIST;
  public billingFrequencyList = BILLING_FREQUENCY_LIST;

  public readonly scheduledPaymentDurationEnum = ScheduledPaymentDurationEnum;
  public readonly scheduledPaymentBillingFrequencyEnum = ScheduledPaymentBillingFrequencyEnum;

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  readonly destroy$: Subject<void> = new Subject();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('hasPeriods') && this.hasPeriods) {
      if (this.hasPeriods && this.scheduledPayment.billing.schedule.frequency !== ScheduledPaymentBillingFrequencyEnum.SINGLE) {
        const billingFrequencyListCopy = [...this.billingFrequencyList];
        billingFrequencyListCopy.pop();
        this.billingFrequencyList = billingFrequencyListCopy;
      }
    }
  }

  public updateField(fieldName: string, fieldValue: any): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  public updateDateField(fieldValue: any, fieldName: string): void {
    this.updateField(
      fieldName,
      fieldValue ? new Date(fieldValue).toLocaleDateString('fr-CA') : null
    );
  }

  public isFormValid(): boolean {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  get isStatusOpen(): boolean { return this.scheduledPayment.status === ScheduledPaymentListTabsEnum.OPEN; }
  get isStatusCompleted(): boolean { return this.scheduledPayment.status === ScheduledPaymentListTabsEnum.COMPLETED; }

  public get ibanControl(): FormControl { return this.form.get('iban') as FormControl; }
  public get bicControl(): FormControl { return this.form.get('bic') as FormControl; }
  public get frequencyControl(): FormControl { return this.form.get('frequency') as FormControl; }
  public get periodFirstDateControl(): FormControl { return this.form.get('periodFirstDate') as FormControl; }
  public get periodLastDateControl(): FormControl { return this.form.get('periodLastDate') as FormControl; }
  public get billingDateControl(): FormControl { return this.form.get('billingDate') as FormControl; }

  public get durationControl(): FormControl { return this.form.get('duration') as FormControl; }
  public get durationEndDateControl(): FormControl { return this.form.get('durationEndDate') as FormControl; }

}
