import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-filters-group',
  templateUrl: './filters-group.component.html',
  styleUrls: ['./filters-group.component.scss']
})
export class FiltersGroupComponent {

  public expanded: boolean = true;

  @Input() public title: string;
  @Input() public disabled: boolean = false;

  public toggleAccordion(): void {
    this.expanded = !this.expanded;
  }

}
