import { AfterViewChecked, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { TradePositionModel } from 'projects/workspace/src/app/trade/models';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { getReceivedGoodsColumns, MismatchedAmountModalData } from './incoming-delivery-products-return-modal.config';
import { InfoModalComponent } from '../../modals-common';
import { TranslateService } from '@ngx-translate/core';
import { PauseModalsHintsComponent } from '../../modals-subscription/pause-modals-hints/pause-modals-hints.component';

interface DataType {
  exchangeId?: number;
  poId?: number;
  fromPo?: boolean;
  hideHints?: boolean;
}

@Component({
  selector: 'rnpl-incoming-delivery-products-return-modal',
  templateUrl: './incoming-delivery-products-return-modal.component.html'
})
export class IncomingDeliveryProductsReturnModalComponent extends BaseModalComponent implements OnInit {

  public returnAllGoods: boolean = false;
  public hideHints: boolean = false;

  public columns: TableColumnModelExtended[] = getReceivedGoodsColumns();
  public confirmedGoods: TradePositionModel[] = [];

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly returnRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @ViewChild('hint', { static: false }) hint: PauseModalsHintsComponent;

  constructor(
    public translateService: TranslateService,
    public toasterService: ToasterService,
    public purchaseOrderApiService: PurchaseOrderApiService,
    public exchangeApiService: ExchangeApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IncomingDeliveryProductsReturnModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.hideHints = this.data.hideHints || false;
    if (this.data.poId) {
      this.purchaseOrderApiService.getPOGoodsToConfirm(this.data.poId)
        .pipe(takeUntil(this._destroy))
        .subscribe((response) => {
          this.confirmedGoods = this.prepareProducts(response.confirmed);
          this.isLoading$.next(false);
        });
    }

    if (this.data.exchangeId) {
      this.exchangeApiService.getExchangeProductsToReceipt(this.data.exchangeId)
        .pipe(takeUntil(this._destroy))
        .subscribe((response) => {
          this.confirmedGoods = this.prepareProducts(response.confirmed);
          this.isLoading$.next(false);
        });
    }
  }

  public prepareProducts(products): any {
    return [...products.map((item) => {
      return {
        ...item,
        productLink: {
          label: item.productRunpleId,
          routerLink: `/products/product-view/${item.productId}`
        },
        productNameLink: {
          label: item.description,
          routerLink: `/products/product-view/${item.productId}`
        },
        return: 0
      };
    })];
  }

  // ngAfterViewChecked(): void {
  //   window.dispatchEvent(new Event('resize'));
  // }

  public onGoToProduct(service: TradePositionModel): void {
    window.open(window.location.origin + `/products/product-view/${service.productId}`, '_blank');
  }

  public returnAllGoodsChanged(): void {
    this.confirmedGoods = [...this.confirmedGoods.map((good) => {
      return {
        ...good,
        return: good.delivered,
      };
    })];
    this.columns = getReceivedGoodsColumns(this.returnAllGoods);
  }

  public confirmReturn(): void {
    if (this.returnRequest$.getValue()) { return; }
    this.returnRequest$.next(true);

    const productsToReturn = {
      products: this.confirmedGoods,
    };

    setTimeout(() => { // wait for steppers to update
      const request$: Observable<any> = this.data.poId
        ? this.purchaseOrderApiService.returnPOGoods(this.data.poId, productsToReturn)
        : this.exchangeApiService.returnExchangeProducts(this.data.exchangeId, productsToReturn as any);

      request$
        .pipe(
          finalize(() => { this.returnRequest$.next(false); }),
          catchError(error => {
            this.handlePopupErrors(error);
            return throwError(error);
          }),
          takeUntil(this._destroy)
        )
        .subscribe(response => {
          this.dialogRef.close(response);
        });
    }, 1500);
  }

  private handlePopupErrors(error: HttpErrorResponse): void {
    switch (error.error.message) {
      case 'The quantity of the product in stock in the status Available is less than you want to return':
        this.dialog.open(InfoModalComponent, {
          data: MismatchedAmountModalData
        });
        break;
      default:
        this.showMsg('error', error.error.message);
        break;
    }
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  get internalUsageGoods(): TradePositionModel[] {
    return this.confirmedGoods.filter(g => g.internalUsage);
  }

  get resaleUsageGoods(): TradePositionModel[] {
    return this.confirmedGoods.filter(g => !g.internalUsage);
  }

}
