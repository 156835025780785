import { createSelector } from '@ngrx/store';

import { OutgoingInvoiceBlocksValidationsState, OutgoingInvoiceFeatureKey, OutgoingInvoiceState, OutgoingInvoiceStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';

export const selectFeature = ((state: AppState) => state[OutgoingInvoiceFeatureKey]);

export const selectOutgoingInvoiceEntity = createSelector(
  selectFeature,
  (state: OutgoingInvoiceStore) => state.entity
);

export const selectOutgoingInvoiceList = createSelector(
  selectFeature,
  (state: OutgoingInvoiceStore) => state.list
);

export const selectOutgoingInvoiceValidations = createSelector(
  selectFeature,
  (state: OutgoingInvoiceStore) => state.blocksValidations
);

export const selectOutgoingInvoice = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.outgoingInvoice
);

export const selectOutgoingInvoiceState = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.currentState
);

export const selectCountries = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.countries
);

export const selectOutgoingInvoiceCurrentState = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.currentState
);

export const selectOutgoingInvoicePositions = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.positions
);

export const selectOutgoingInvoicePositionsCount = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.positionsCount
);

export const selectOutgoingInvoiceSummary = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.outgoingInvoiceSummary
);

export const selectOutgoingInvoicePositionsTotals = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.outgoingPositionsTotals
);

export const isShouldRefresh = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.isShouldRefresh
);

export const outgoingInvoiceUpdatedAt = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.updatedAt
);

export const loadingRequestsCount = createSelector(
  selectOutgoingInvoiceEntity,
  (state: OutgoingInvoiceState) => state.loadingRequestsCount
);

export const selectOutgoingInvoicePropertiesBlockValid = createSelector(
  selectOutgoingInvoiceValidations,
  (state: OutgoingInvoiceBlocksValidationsState) => state.propertiesBlockValid
);

export const selectOutgoingInvoiceBillingInfoBlockValid = createSelector(
  selectOutgoingInvoiceValidations,
  (state: OutgoingInvoiceBlocksValidationsState) => state.billingInfoBlockValid
);

export const selectOutgoingInvoicePositionsBlockValid = createSelector(
  selectOutgoingInvoiceValidations,
  (state: OutgoingInvoiceBlocksValidationsState) => state.positionsBlockValid
);
