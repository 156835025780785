import { Component, ElementRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { filter, take } from 'rxjs/operators';


import { WizardStep } from '../../../../wizard/wizard-step';
import { CONTROLS } from '../../../../dynamic-forms/controls';
import { SystemSettingsService } from '../../../system-settings.service';
import {
  MIN_OPTIONS_COUNT_RADIO,
  MIN_OPTIONS_COUNT_SELECT,
  MIN_OPTIONS_COUNT_COMBOBOX,
  MIN_OPTIONS_COUNT_TOKENFIELD,
  MAX_OPTIONS_COUNT_RADIO,
  MAX_OPTIONS_COUNT_SELECT
} from '../../constants';
import { ControlChangingConfirmationComponent } from '../../modals';
import { FormTooltipComponent } from '../../../../ui-components/form-tooltip/form-tooltip.component';
import { AttributeWizardComponent } from '../../attribute-wizard.component';


@Component({
  selector: 'rnpl-control-setup',
  templateUrl: './control-setup.component.html',
  styleUrls: ['./control-setup.component.scss']
})
export class ControlSetupComponent extends WizardStep implements OnInit {

  public static KEY_CODE_ENTER = 13;
  public static KEY_CODE_TAB = 9;
  public static KEY_CODE_BACKSPACE = 8;

  @Input()
  public attributeName: string;

  public controls: any;
  public controlList: Array<any> = [];

  public controlOptions: Array<any> = [];

  public control: any = null;
  public oldControl: any;

  public optionsIsAvail: boolean = false;
  public newOptionIsAvail: boolean = false;

  public minOptionsCount: number = 0;

  private defaultLang: string = 'eng';

  private lastOptKey: number = 0;

  @ViewChild('controlHint', {static: false})
  private controlHint: FormTooltipComponent;

  @ViewChild('optionsHint', {static: false})
  private optionsHint: FormTooltipComponent;

  @ViewChild('ctrlConfirm', {static: true})
  private ctrlConfirm: ControlChangingConfirmationComponent;

  constructor(@Optional() public wizard: AttributeWizardComponent,
              private systemSettingsService: SystemSettingsService,
              private hostElement: ElementRef) {
    super();
  }

  ngOnInit(): void {

    this.initProgress();

    this.controlOptions = this.form.get('options').value;
    this.lastOptKey = this.controlOptions.reduce((lastKey, option) => {
      if (option.key && option.key > lastKey) {
        return option.key;
      } else {
        return lastKey;
      }
    }, 0);

    this.systemSettingsService.getControls()
      .subscribe((response) => {
        this.controls = response.data.controls;
        this.controlList = Object.keys(this.controls)
          .filter(key => !!CONTROLS[key])
          .map(key => {
            return {label: this.controls[key], value: key};
          });

        const control = this.form.get('control').value;
        if (control) {
          this.control = this.controlList.find(controlDef => controlDef.value === control);
          this.oldControl = this.control;
          this.updateMinOptionsCount(control);
          this.optionsIsAvail = this.optionsAvailable(control);
          this.newOptionIsAvail = this.newOptionAvailable();
        }
      });

    this.form.get('control').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        let progress = this.progress.start;
        if (!this.optionsAvailable()) {
          progress = this.progress.finish;
        } else {
          progress += Math.floor((this.progress.finish - this.progress.start) / 2);
        }
        if (progress <= this.progress.finish) {
          this.wizard.progress = progress;
        }
        if (this.controlHint) {
          this.controlHint.hide();
        }
      });

    this.form.get('options').statusChanges
      .pipe(filter(status => status === 'VALID'))
      .pipe(take(1))
      .subscribe(() => {
        if (this.wizard.progress < this.progress.finish) {
          this.wizard.progress = this.progress.finish;
        }
        if (this.optionsHint) {
          this.optionsHint.hide();
        }
      });
  }

  public complete(): void {
    this.controlOptions = this.controlOptions.filter(option => option.eng.length);
    this.form.get('options').setValue(this.controlOptions);

    super.complete();
  }

  /**
   * Asks user for confirmation before change
   *
   * @param control The object with key and label of control
   */
  public changeControl(control): void {
    if (!this.oldControl) {
      this.setControl(control);
      this.oldControl = control;

      this.updateMinOptionsCount(control.value);
      return;
    }

    const optionsCount = this.controlOptions.filter(option => !!option[this.defaultLang]).length;
    this.ctrlConfirm.verify(this.oldControl.value, control.value, optionsCount)
      .subscribe(excessCount => {

        this.controlOptions = this.controlOptions.filter(option => !!option[this.defaultLang]);
        this.controlOptions.splice(this.controlOptions.length - excessCount, excessCount);
        this.form.get('options').setValue(this.controlOptions);

        this.setControl(control);

        this.oldControl = this.control;
        this.updateMinOptionsCount(control.value);
      }, () => {
        this.control = this.oldControl;
      });
  }

  public setControl(control): void {
    this.form.get('control').setValue(control.value);
    this.form.get('control').markAsDirty();
    this.optionsIsAvail = this.optionsAvailable();
    this.newOptionIsAvail = this.newOptionAvailable();

    if (this.newOptionIsAvail && !this.controlOptions.length) {
      this.addOption();
    }
    this.form.get('options').updateValueAndValidity();
  }

  /**
   * Checks if options are available for current control
   *
   * @param control Name of control
   */
  public optionsAvailable(control?: string): boolean {
    if (!this.form || !this.form.get('control')) {
      return false;
    }
    control = control || this.form.get('control').value;
    return ['tokenfield', 'radio', 'select', 'combobox'].includes(control);
  }

  /**
   * Checks if new option is available
   */
  public newOptionAvailable(): boolean {
    const control = this.form.get('control').value;
    const optionsCount = this.form.get('options').value.length;

    switch (control) {

      case 'radio':
        return optionsCount < MAX_OPTIONS_COUNT_RADIO;
        break;

      case 'select':
        return optionsCount < MAX_OPTIONS_COUNT_SELECT;
        break;

      case 'tokenfield':
      case 'combobox':
        return true;
        break;

      default:
        return false;
    }
  }

  /**
   * Changes order of options
   *
   * @param event The drag'n'drop event object
   */
  public reorderOptions(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.controlOptions, event.previousIndex, event.currentIndex);
    this.form.get('options').setValue(this.controlOptions);
  }

  /**
   * Adds new option if current isn't empty
   */
  public addOption(force: boolean = false): void {
    const current = document.activeElement as HTMLInputElement;
    const optionElement = current.closest('.drag-option')
      || this.hostElement.nativeElement.querySelector('.drag-option:last-child');
    if (!force && optionElement && !optionElement.querySelector('input').value) {
      optionElement.classList.add('has-error');
      optionElement.querySelector('input').focus();
      return;
    }

    const option = {key: ++this.lastOptKey};
    option[this.defaultLang] = '';
    this.controlOptions.push(option);
    this.form.get('options').setValue(this.controlOptions);
    this.newOptionIsAvail = this.newOptionAvailable();
    this.focusOnOption(this.controlOptions.length - 1);
  }

  /**
   * Removes options from option list by option index
   *
   * @param index The index of option
   */
  public removeOption(index: number): void {
    this.controlOptions.splice(index, 1);
    this.form.get('options').setValue(this.controlOptions);
    this.newOptionIsAvail = this.newOptionAvailable();
  }

  /**
   * Renames options
   *
   * @param event The keyup event object
   * @param index The index of options
   */
  public renameOption(event, index: number): void {
    event.target.closest('.drag-option').classList.remove('has-error');

    if (event.target.value) {
      const sameOption = this.controlOptions
        .find(option => option.eng.toLowerCase() === event.target.value);
      if (sameOption) {
        event.target.closest('.drag-option').classList.add('has-error');
      }
    }

    this.controlOptions[index][this.defaultLang] = event.target.value;
    this.form.get('options').setValue(this.controlOptions);
  }

  /**
   * Handle clicks on 'Enter' and 'Tab' buttons
   *
   * @param event The keydown event object
   * @param index The index of options
   */
  public handleKeyboardOptionsEvents(event, index: number): void {
    if (event.which === ControlSetupComponent.KEY_CODE_ENTER
      && this.controlOptions.length === index + 1
      && this.newOptionIsAvail) {

      this.addOption();
      return;
    }

    if (event.which === ControlSetupComponent.KEY_CODE_TAB
      || (event.which === ControlSetupComponent.KEY_CODE_ENTER
        && (this.controlOptions.length > index + 1 || !this.newOptionIsAvail))) {

      const focusedIndex = index + 1 < this.controlOptions.length
        ? index + 1
        : 0;
      this.focusOnOption(focusedIndex);
      return;
    }

    if (event.which === ControlSetupComponent.KEY_CODE_BACKSPACE
      && !this.controlOptions[index][this.defaultLang].length
      && this.controlOptions.length > this.minOptionsCount) {

      this.removeOption(index);
      if (index > 0) {
        this.focusOnOption(index - 1);
      }
    }
  }

  /**
   * Makes focus at field of option by index
   *
   * @param index The index of option
   */
  private focusOnOption(index): void {
    setTimeout(() => {
      const inputs = this.hostElement.nativeElement.querySelectorAll('.drag-option input');
      inputs.item(index).focus();
    }, 50);
  }

  private updateMinOptionsCount(control: string): void {
    switch (control) {
      case 'radio':
        this.minOptionsCount = MIN_OPTIONS_COUNT_RADIO;
        break;

      case 'select':
        this.minOptionsCount = MIN_OPTIONS_COUNT_SELECT;
        break;

      case 'combobox':
        this.minOptionsCount = MIN_OPTIONS_COUNT_COMBOBOX;
        break;

      case 'tokenfield':
        this.minOptionsCount = MIN_OPTIONS_COUNT_TOKENFIELD;
        break;

      default:
        this.minOptionsCount = 0;
    }

    while (this.minOptionsCount > this.controlOptions.length) {
      this.addOption(true);
    }
    const firstEmptyIndex = this.controlOptions.findIndex(option => !option[this.defaultLang]);
    if (firstEmptyIndex > -1) {
      this.focusOnOption(firstEmptyIndex);
    }
  }

  private initProgress(): void {
    let progress = this.progress.start;
    if (this.form.get('control').value) {
      if (!this.optionsAvailable()) {
        progress = this.progress.finish;
      } else {
        progress += Math.floor((this.progress.finish - this.progress.start) / 2);

        if (this.form.get('options').valid) {
          progress += Math.floor((this.progress.finish - this.progress.start) / 2);
        }
      }
    }
    if (progress <= this.progress.finish) {
      this.wizard.progress = progress;
    }
  }
}
