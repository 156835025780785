import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActionType } from 'common/src/modules/ui-components/document-saved-info/models/action-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'savedAtPipe'})
export class SavedAtPipe implements PipeTransform {

  constructor(
      public datePipe: DatePipe,
      private translate: TranslateService
  ) {
  }

  transform(value: Date, action: ActionType = ActionType.Save, ...args: any[]): string {
    if (value) {

      let translatedAction: string;
      switch (action) {
        case ActionType.Save:
          translatedAction = 'SYNC.SAVED';
          break;
        case ActionType.Sync:
          translatedAction = 'SYNC.SYNCED';
          break;
        default:
          translatedAction = action;
          break;
      }

      translatedAction = this.translate.instant(translatedAction);

      const nowSeconds = +new Date() / 1000;
      const savedDateSeconds =  +new Date(value) / 1000;
      // const timezoneOffsetSeconds = (+new Date(value).getTimezoneOffset() * 60);

      // const secondsFromLastUpdate = Math.floor(nowSeconds - savedDateSeconds + timezoneOffsetSeconds);
      const secondsFromLastUpdate = Math.floor(nowSeconds - savedDateSeconds);

      if (secondsFromLastUpdate < 9) {
        // less than 10 seconds ago will show as 'Just saved'
        return `${translatedAction} ${this.translate.instant('SYNC.JUST_NOW')}`;
      }
      if (secondsFromLastUpdate < 59) {
        // less than 60 seconds ago will show as `${action} a minute ago`
        return `${translatedAction} ${this.translate.instant('SYNC.A_MINUTE_AGO')}`;
      }
      if (secondsFromLastUpdate < 3599) {
        // less than 60 minutes ago will show as `${action} (n) minutes ago`
        if (secondsFromLastUpdate < 119) {
          return `${translatedAction} ${this.translate.instant('SYNC.ONE_MINUTE_AGO')}`;
        } else {
          return `${translatedAction} ${Math.floor(secondsFromLastUpdate / 60)} ${this.translate.instant('SYNC.MINUTES_AGO')}`;
        }
      }

      return this.datePipe.transform(value, 'dd.MM.yyyy, HH:mm');
    }

    return value as any;
  }
}
