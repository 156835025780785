import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StripePaymentService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-update-payment-card-modal',
  templateUrl: './update-payment-card-modal.component.html',
})
export class UpdatePaymentCardModalComponent implements OnDestroy {

  public activationError: boolean = false;
  public stripePaymentUpdateElementId: string;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<UpdatePaymentCardModalComponent>,
    public stripePaymentService: StripePaymentService,
  ) {
    this.stripePaymentService.initStripeUpdate();
    this.stripePaymentUpdateElementId = StripePaymentService.PAYMENT_UPDATE_ELEMENT_ID;
  }

  public cancelStripeRequestAndClose(): void {
    this.stripePaymentService.cancelUpdateIntent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close());
  }

  public submit(): void {
    this.submitRequest$.next(true);

    this.stripePaymentService.confirmUpdatePayment(
      () => {
        setTimeout(() => {
          this.dialogRef.close();
          this.submitRequest$.next(false);
        }, 1000);
      },
      () => {
        this.activationError = true;
        this.submitRequest$.next(false);
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
