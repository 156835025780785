import { Directive, HostListener, OnInit, Optional } from '@angular/core';


import { ComposerCellComponent } from '../components';


@Directive({
  selector: '[rnplComposerCellContent]'
})
export class ComposerCellContentDirective implements OnInit {

  @HostListener('cdkDragStarted', ['$event'])
  dragStarted(event) {
    if (this.cell) {
      this.cell.dragStarted(event.source);
    }
  }

  constructor(@Optional() private cell: ComposerCellComponent) {
  }

  ngOnInit(): void {
  }

}
