import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'processAttributeValue'})
export class ProcessAttributeValuePipe implements PipeTransform {
  transform(value: any): string {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (value === 0) {
      return value;
    }

    return value || '-';
  }
}
