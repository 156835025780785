import { get } from 'lodash';

import { CreditNotePurposeEnum, CreditNotesListTabsEnum } from '../../enums';
import { BankTransferMethodsEnum, GeneralPartnerTypeEnum, NavBarBtnModel, PaymentMethodsEnum, UIStatesEnum } from 'common/src/models';
import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';
import { CreditNoteModel } from '../../models';
import {
  CreateDocumentStepsModel,
  DocumentToDoListModel,
  LinkedDocumentsModel,
  ViewDocumentListModel
} from 'common/src/models/view-document-list.model';
import { PaymentDirectionEnum } from '../../../payment/models/payment.model';
import { DocumentPreviewTabsEnum } from 'common/src/models/document-preview-tabs.enum';
import { DocumentTypeEnum } from 'common/src/modules/ui-components/document-steps/document-step.model';
import { PartnersTypeEnum } from '../../../partners/corporate/enums';

export function getButtonsByStatus(state: UIStatesEnum, creditNote: CreditNoteModel): NavBarBtnModel[] {
  let buttons: NavBarBtnModel[];
  const purpose: CreditNotePurposeEnum = get(creditNote, 'properties.purpose')

  if (
    purpose === CreditNotePurposeEnum.OIN_CORRECTION ||
    (purpose === CreditNotePurposeEnum.COMMON_CORRECTION && get(creditNote, 'properties.direction') === PaymentDirectionEnum.OUTGOING)
  ) {
    buttons = [...NavBarButtons(get(creditNote, 'properties.direction'), !!creditNote.runpleId)];
    buttons.splice(-2, 0, exportButton);
  } else {
    buttons = NavBarButtons(get(creditNote, 'properties.direction'), !!creditNote.runpleId)
  }

  if (
    creditNote.status === CreditNotesListTabsEnum.PAID &&
    creditNote.billingInformation.subtract
  ) {
    buttons.push(getCancelButton());
  }

  return buttons.filter((btn: NavBarBtnModel) => btn.other.includes(state));
}

export function getViewDocumentList(
  positionsCount: number,
  creditNote: CreditNoteModel,
  // showLinkedDoc: boolean,
  isNarrowScreen: boolean,
  isReadOnly: boolean,
  displayBillingBlock: boolean
): ViewDocumentListModel[] {
  const viewDocumentItems: ViewDocumentListModel[] = [
    {
      // icon: 'person',
      title: 'APP.BUSINESS_PARTNER',
      template: 'PropertiesPartner',
      anchor: 'propertiesPartner',
      // hideButton: crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION && !creditNote.linkedDocumentsTotals.documents,
    },
    {
      // icon: 'file-text',
      title: 'DOC_BLOCK.PROPERTIES',
      template: 'Properties',
      anchor: 'properties',
      // hideButton: crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION && !creditNote.linkedDocumentsTotals.documents,
    },
    {
      // icon: 'plus-circle',
      title: 'DOC_BLOCK.POSITIONS',
      template: 'Position',
      count: positionsCount,
      anchor: 'positions',
      hintId: 'sidebarCreditNote',
    },
  ];

  const billingInfoBlock = {
    // icon: 'euro',
    title: 'DOC_BLOCK.BILLING',
    template: 'BillingInfo',
    anchor: 'billing-information',
  }

  const fileTab: ViewDocumentListModel = {
    title: 'DOC_BLOCK.CREDIT_NOTE_FILE',
    anchor: 'credit-note-file',
    template: 'Files',
    // icon: 'attach',
    count: 0,
  };

  // const documentPreview: ViewDocumentListModel[] = [
  //   {
  //     // icon: 'credit-note',
  //     title: 'DOC_BLOCK.DOCUMENT_PREVIEW',
  //     template: 'DocumentPreview',
  //     anchor: 'document-preview',
  //   },
  // ];

  if (displayBillingBlock) {
    viewDocumentItems.splice(3, 0, billingInfoBlock);
  }

  // if (
  //   crnPurpose === CreditNotePurposeEnum.OIN_CORRECTION ||
  //   (crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION && crnDirection === PaymentDirectionEnum.OUTGOING)
  // ) {
  //   if (isReadOnly) {
  //     return documentPreview;
  //   }
  //   return viewDocumentItems;
  // }

  // if (
  //   crnPurpose === CreditNotePurposeEnum.IIN_CORRECTION ||
  //   (crnPurpose === CreditNotePurposeEnum.COMMON_CORRECTION && crnDirection === PaymentDirectionEnum.INCOMING)
  // ) {
    if (isNarrowScreen && (!isReadOnly || get(creditNote, 'files.length'))) {
      viewDocumentItems.splice(3, 0, fileTab);
    }
    return viewDocumentItems;
  // }
}

// export function getViewDocumentListNew(
//   creditNote: CreditNoteModel,
//   showLinkedDoc: boolean,
// ): ViewDocumentListModel[] {
//   const linkDocuments: ViewDocumentListModel = {
//     icon: 'link',
//     title: 'TABLE_SUMMARY_BAR.DOCUMENTS',
//     template: 'Documents',
//     count: creditNote.linkedDocuments.length,
//     anchor: 'Documents',
//     // showIndicator: creditNote.linkedDocuments.length === 0,
//   };
//
//   const documentPreview: ViewDocumentListModel[] = [
//     {
//       icon: 'outgoing-invoice',
//       title: 'DOC_BLOCK.DOCUMENT_PREVIEW',
//       template: 'DocumentPreview',
//       anchor: 'document-preview',
//     },
//   ];
//
//   if (showLinkedDoc) {
//     if (
//       creditNote.status === CreditNotesListTabsEnum.DELETED && !!creditNote.linkedDocuments.length ||
//       creditNote.status === CreditNotesListTabsEnum.CANCELED && !!creditNote.linkedDocuments.length ||
//       creditNote.status !== CreditNotesListTabsEnum.DELETED && creditNote.status !== CreditNotesListTabsEnum.CANCELED
//     ) {
//       documentPreview.splice(1, 0, linkDocuments);
//     }
//   }
//   return documentPreview;
// }

export function getViewDocumentTabs(
  creditNote: CreditNoteModel,
  showLinkedDoc: boolean,
  linkedDocumentsCount: number
): any[] {
  const showLinkedDocuments: boolean = showLinkedDoc && (
    creditNote.status === CreditNotesListTabsEnum.DELETED && !!creditNote.linkedDocuments.length ||
    creditNote.status === CreditNotesListTabsEnum.CANCELED && !!creditNote.linkedDocuments.length ||
    creditNote.status !== CreditNotesListTabsEnum.DELETED && creditNote.status !== CreditNotesListTabsEnum.CANCELED
  );

  const viewDocumentsTabs = [
    {
      label: 'APP.CREDIT_NOTE',
      tabName: DocumentPreviewTabsEnum.DOCUMENT,
      link: `${DocumentPreviewTabsEnum.DOCUMENT}`,
      icon: 'file-text',
    },
    {
      label: 'DOC_BLOCK.PROPERTIES',
      tabName: DocumentPreviewTabsEnum.PROPERTIES,
      link: `${DocumentPreviewTabsEnum.PROPERTIES}`,
      icon: 'options-2',
      showCondition: creditNote.properties.direction === 'OUTGOING'
    },
    {
      label: 'TAB.LOG',
      tabName: DocumentPreviewTabsEnum.LOG,
      link: `${DocumentPreviewTabsEnum.LOG}`,
      icon: 'clipboard',
    },
    {
      label: 'TABLE_SUMMARY_BAR.DOCUMENTS',
      tabName: DocumentPreviewTabsEnum.DOCUMENTS,
      link: `${DocumentPreviewTabsEnum.DOCUMENTS}`,
      icon: 'link',
      count: linkedDocumentsCount,
      showCondition: showLinkedDocuments
    },
    {
      label: 'MODAL.REMARK',
      tabName: DocumentPreviewTabsEnum.REMARK,
      link: `${DocumentPreviewTabsEnum.REMARK}`,
      icon: 'message-square',
    },
  ];

  return viewDocumentsTabs.filter(t => !t.hasOwnProperty('showCondition') || t.showCondition);
}

const exportButton: NavBarBtnModel = {
  classes: 'btn-white',
  text: 'BUTTON.EXPORT',
  iconName: 'share',
  disabled: false,
  multiple: false,
  allowLoadingSpinner: true,
  actionName: 'onExportCreditNoteClick',
  spinnerColor: '#5E6681',
  dropdownClass: 'inner-blue-400',
  // width: 141,
  hintsId: 'exportBtn',
  actions: [
    {
      actionTitle: 'BUTTON.EXPORT_PDF',
      actionName: 'onExportCreditNoteClick',
      actionIcon: 'pdf'
    },
    {
      actionTitle: 'BUTTON.SEND_EMAIL',
      actionName: 'onSendEmailClick',
      actionIcon: 'email',
      // hideWithStatus: [
      //   CreditNotesListTabsEnum.DRAFT,
      // ],
    }
  ],
  hideWithStatus: [
    CreditNotesListTabsEnum.DELETED
  ],
  other: [UIStatesEnum.VIEW]
};

export function getCancelButton(): NavBarBtnModel {
  return {
    classes: 'btn-white red',
    text: 'BUTTON.CANCEL_ENTITY',
    iconName: 'close-square',
    disabled: false,
    actionName: 'onCancelCreditNoteClick',
    hintsId: 'cancelBtn',
    allowLoadingSpinner: true,
    greyIcon: true,
    multiple: false,
    other: [UIStatesEnum.VIEW]
  };
}

export function NavBarButtons(direction: PaymentDirectionEnum, hasRID = false): NavBarBtnModel[] {
  return [
    {
      classes: 'btn-white',
      text: 'BUTTON.MORE_ACTIONS',
      iconName: 'menu',
      disabled: false,
      // allowLoadingSpinner: true,
      disabledMainAction: true,
      withActions: true,
      spinnerColor: '#5E6681',
      multiple: false,
      dropdownClass: 'inner-blue-400',
      width: 141,
      isMoreAction: true,
      actions: [
        {
          actionTitle: 'BUTTON.DELETE',
          actionName: 'onDeleteCreditNoteClick',
          classes: 'btn-white red',
          actionIcon: 'trash-2',
          showCondition: direction === PaymentDirectionEnum.INCOMING || (direction === PaymentDirectionEnum.OUTGOING && !hasRID),
          hideWithStatus: [
            CreditNotesListTabsEnum.DELETED,
            CreditNotesListTabsEnum.CANCELED,
            CreditNotesListTabsEnum.OPEN,
            CreditNotesListTabsEnum.PAID,
          ],
        },
        {
          actionTitle: 'BUTTON.CANCEL_ENTITY',
          classes: 'btn-white red',
          actionName: 'onCancelCreditNoteClick',
          actionIcon: 'close-square',
          showCondition: direction === PaymentDirectionEnum.OUTGOING && hasRID,
          hideWithStatus: [
            CreditNotesListTabsEnum.DELETED,
            CreditNotesListTabsEnum.PAID,
            CreditNotesListTabsEnum.CANCELED,
            CreditNotesListTabsEnum.OPEN,
          ],
        },
        {
          actionTitle: 'BUTTON.DELETE',
          classes: 'btn-white red',
          greyIcon: true,
          actionName: 'onDeleteCreditNoteClick',
          actionIcon: 'trash-2',
          hideWithStatus: [
            CreditNotesListTabsEnum.DELETED,
            CreditNotesListTabsEnum.OPEN,
            CreditNotesListTabsEnum.PAID,
            CreditNotesListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'BUTTON.CANCEL_ENTITY',
          classes: 'btn-white red',
          greyIcon: true,
          actionName: 'onCancelCreditNoteClick',
          actionIcon: 'close-square',
          hideWithStatus: [
            CreditNotesListTabsEnum.DELETED,
            CreditNotesListTabsEnum.PAID,
            CreditNotesListTabsEnum.CANCELED,
            CreditNotesListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'BUTTON.DELETE_PERMANENTLY',
          classes: 'btn-white red',
          greyIcon: true,
          actionName: 'onDeletePermanentlyCreditNoteClick',
          actionIcon: 'fire',
          hideWithStatus: [
            CreditNotesListTabsEnum.OPEN,
            CreditNotesListTabsEnum.PAID,
            CreditNotesListTabsEnum.CANCELED,
            CreditNotesListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'BUTTON.REOPEN',
          classes: 'btn-white',
          greyIcon: true,
          actionName: 'onRestoreCreditNoteClick',
          actionIcon: 'undo',
          hideWithStatus: [
            CreditNotesListTabsEnum.PAID,
            CreditNotesListTabsEnum.OPEN,
            CreditNotesListTabsEnum.DRAFT,
          ],
        },
      ],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white',
      multipleClasses: 'btn-white',
      text: 'BUTTON.TRANSFER_FILE',
      multipleText: 'BUTTON.TRANSFER_FILE_BULK',
      iconName: 'euro',
      actionName: 'onExportXMLClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: true,
      hideWithStatus: [
        CreditNotesListTabsEnum.DRAFT,
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.CANCELED,
        CreditNotesListTabsEnum.DELETED,
      ],
      other: [],
    },
    {
      classes: 'btn-white red d-none',
      multipleClasses: 'btn-white red',
      text: 'BUTTON.DELETE',
      multipleText: 'BUTTON.DELETE',
      badgeClass: 'rnpl-badge--red',
      iconName: 'trash-2',
      actionName: 'onDeleteCreditNoteClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        CreditNotesListTabsEnum.DELETED,
        CreditNotesListTabsEnum.OPEN,
        CreditNotesListTabsEnum.PAID,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE',
      iconName: 'plus-square',
      disabled: false,
      multiple: false,
      allowLoadingSpinner: true,
      actionName: 'onProceedCreditNoteClick',
      dropdownClass: 'inner-blue-400',
      width: 141,
      actions: [
        {
          actionTitle: 'BUTTON.CREATE',
          actionName: 'onProceedCreditNoteClick',
          actionIcon: 'plus-square'
        },
        {
          actionTitle: 'BUTTON.SAVE_AS_DRAFT',
          actionName: 'onCreateCreditNoteClick',
          actionIcon: 'save'
        },
      ],
      hideWithStatus: [
        CreditNotesListTabsEnum.DELETED,
        CreditNotesListTabsEnum.OPEN,
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.CANCELED,
      ],
      other: [UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.EDIT',
      iconName: 'edit',
      disabled: false,
      actionName: 'onEditCreditNoteClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hintsId: 'editBtn',
      hideWithStatus: [
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.CANCELED,
        CreditNotesListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-danger',
      multipleClasses: 'btn-white red',
      text: 'BUTTON.DELETE_PERMANENTLY',
      multipleText: 'BUTTON.DELETE_PERMANENTLY',
      badgeClass: 'rnpl-badge--red',
      iconName: 'fire',
      actionName: 'onDeletePermanentlyCreditNoteClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        CreditNotesListTabsEnum.OPEN,
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.CANCELED,
        CreditNotesListTabsEnum.DRAFT,
      ],
      other: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-dark',
      multipleClasses: 'btn-primary',
      multipleText: 'BUTTON.REOPEN',
      iconName: 'undo',
      disabled: false,
      actionName: 'onRestoreCreditNoteClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.OPEN,
        CreditNotesListTabsEnum.DRAFT,
        CreditNotesListTabsEnum.CANCELED,
        CreditNotesListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE',
      iconName: 'plus-square',
      disabled: false,
      actionName: 'onProceedCreditNoteClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        CreditNotesListTabsEnum.DELETED,
        CreditNotesListTabsEnum.OPEN,
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.CANCELED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.DONE',
      iconName: 'checkmark',
      disabled: false,
      actionName: 'onCloseEditCreditNoteClick',
      allowLoadingSpinner: true,
      multiple: false,
      hintsId: 'doneBtn',
      hideWithStatus: [
        CreditNotesListTabsEnum.PAID,
        CreditNotesListTabsEnum.CANCELED,
        CreditNotesListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.EDIT]
    }
  ];
}

export function getCreateDocumentSteps(
  creditNote: CreditNoteModel,
  currentState: UIStatesEnum,
  positionsBlockValid: boolean,
  billingInfoBlockValid: boolean,
): CreateDocumentStepsModel[] {
  const crnDirection = creditNote.properties.direction;
  return [
    {
      title: 'DOC_SUMMARY.UPLOAD_FILE',
      action: null,
      anchor: 'credit-note-file',
      valid: !!creditNote.files.length,
      hide: crnDirection === PaymentDirectionEnum.OUTGOING
    },
    {
      title: 'FORM.SELECT_BUSINESS_PARTNER',
      action: crnDirection === PaymentDirectionEnum.OUTGOING
        ? 'selectCustomer'
        : null,
      anchor: crnDirection === PaymentDirectionEnum.OUTGOING
        ? null
        : 'propertiesPartner',
      valid: creditNote.properties.partnerType === PartnersTypeEnum.GENERAL
        ? !!get(creditNote, 'properties.partner.id') && !!get(creditNote, 'properties.partnerName')
        : !!get(creditNote, 'properties.partner.id')
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_PROPERTIES',
      link: null,
      anchor: 'properties',
      valid: !!(get(creditNote, 'properties.issueDate') && get(creditNote, 'properties.externalNumber')),
      hide: crnDirection === PaymentDirectionEnum.OUTGOING
    },
    {
      title: 'DOC_SUMMARY.ADD_POSITIONS',
      action: crnDirection === PaymentDirectionEnum.OUTGOING && currentState !== UIStatesEnum.VIEW
        ? 'displayAddPositionModal'
        : null,
      anchor: crnDirection === PaymentDirectionEnum.OUTGOING
        ? null
        : 'positions',
      valid: positionsBlockValid
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_BILLING_DETAILS',
      action: crnDirection === PaymentDirectionEnum.OUTGOING
        ? 'displayBillingInfoModal'
        : null,
      anchor: crnDirection === PaymentDirectionEnum.OUTGOING
        ? null
        : 'billing-information',
      valid: billingInfoBlockValid
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_PROPERTIES',
      link: DocumentPreviewTabsEnum.PROPERTIES,
      anchor: null,
      valid: !!get(creditNote, 'properties.issueDate'),
      hide: crnDirection === PaymentDirectionEnum.INCOMING
    },
  ];
}

export function getDocumentToDoList(
  isReadonly: boolean,
  checkVat: boolean,
  creditNote: CreditNoteModel,
  linkedIncomingInvoice: LinkedDocumentsModel[],
  linkedOutgoingInvoice: LinkedDocumentsModel[],
  linkedOpb: LinkedDocumentsModel[],
  linkedIpb: LinkedDocumentsModel[],
  linkedEra: LinkedDocumentsModel[],
  sendEmail: boolean,
  isFreePlan: boolean
): DocumentToDoListModel[] {
  const canTransferFile: boolean = creditNote.properties.direction === PaymentDirectionEnum.OUTGOING
    && creditNote.billingInformation.paymentMethod === PaymentMethodsEnum.BANK_TRANSFER
    && creditNote.billingInformation.bankTransferMethod === BankTransferMethodsEnum.MANUAL_TRANSFER;

  return [
    {
      title: creditNote.properties.direction === PaymentDirectionEnum.INCOMING ? 'DOC_SUMMARY.RECEIVE_PAYMENT' : 'DOC_SUMMARY.MAKE_PAYMENT',
      valid: creditNote.status === CreditNotesListTabsEnum.PAID,
      hideActionList: !isReadonly || creditNote.status === CreditNotesListTabsEnum.PAID,
      hideAdditionalInfoList: false,
      linkedDocuments: [...linkedOpb, ...linkedIpb],
      hide: creditNote.status === CreditNotesListTabsEnum.PAID,
      actions: [
        {
          text: 'DOC_SUMMARY.ALLOCATE_PAYMENT',
          iconName: 'payment',
          actionName: 'allocatePayment',
          btnClass: 'primary',
          hide: (!canTransferFile && isFreePlan) || creditNote.status === CreditNotesListTabsEnum.PAID,
          disabledMainAction: false,
          classes: 'btn-primary',
          multiple: true,
          actions: [
            {
              actionTitle: 'DOC_SUMMARY.ALLOCATE_PAYMENT',
              actionIcon: 'payment',
              actionName: 'allocatePayment',
              hideAction: isFreePlan,
            },
            {
              actionTitle: 'BUTTON.BOOK_PAYMENT',
              actionName: 'bookPayment',
              actionIcon: 'payment',
            },
            {
              actionTitle: 'BUTTON.TRANSFER_FILE',
              actionName: 'onExportXMLClick',
              actionIcon: 'euro',
              hideAction: !canTransferFile
            }
          ]
        },
        {
          title: 'BUTTON.BOOK_PAYMENT',
          icon: 'payment',
          action: 'bookPayment',
          btnClass: 'primary',
          hide: !(!canTransferFile && isFreePlan) || creditNote.status === CreditNotesListTabsEnum.PAID
        },
      ],
      additionalInfoList: [
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'COLUMN.OPEN_AMOUNT',
          statusLineType: 'payments-euro-val-only',
          currency: get(creditNote, 'amountDue.currency'),
          value: get(creditNote, 'summary.openAmount', null),
          maxValue: get(creditNote, 'summary.crnAmount.gross', null),
          colorState: 'main-600',
        },
      ],
    },
    {
      title: 'DOC_SUMMARY.SEND_CREDIT_NOTE_TO_CUSTOMER',
      hide: creditNote.properties.direction !== PaymentDirectionEnum.OUTGOING || creditNote.status === CreditNotesListTabsEnum.PAID,
      valid: sendEmail,
      hideActionList: !isReadonly || sendEmail,
      actions: [
        {
          title: 'DOC_SUMMARY.SEND_CREDIT_NOTE',
          icon: 'email',
          action: 'onSendEmailClick',
          btnClass: 'primary'
        },
      ],
    },
    {
      vatCheckType: true,
      vatStatus: get(creditNote, 'properties.vatStatus'),
      hide: ((get(creditNote, 'properties.partner.noVat') || !get(creditNote, 'properties.partner.euMember'))
        && (get(creditNote, 'properties.partnerType') !== PartnersTypeEnum.GENERAL || !get(creditNote, 'properties.partnerVat'))
        || (
          get(creditNote, 'properties.partnerType') === PartnersTypeEnum.GENERAL &&
          get(creditNote, 'partnerProfile.generalType') === GeneralPartnerTypeEnum.NON_EU
        )) || creditNote.status === CreditNotesListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_PAYMENTS',
      linkedDocumentsType: true,
      linkedDocuments: [...linkedOpb, ...linkedIpb],
      icon: 'payment',
      hide: (!linkedOpb.length && !linkedIpb.length) || creditNote.status !== CreditNotesListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_IIN',
      linkedDocumentsType: true,
      linkedDocuments: linkedIncomingInvoice,
      hide: !linkedIncomingInvoice.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_OIN',
      linkedDocumentsType: true,
      linkedDocuments: linkedOutgoingInvoice,
      hide: !linkedOutgoingInvoice.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_ERA',
      linkedDocumentsType: true,
      linkedDocuments: linkedEra,
      hide: !linkedEra.length
    },
  ];
}

export const DeletePermanentlyModalData: CommonModalDataModel = {
  title: 'MODAL.DELETE_PERMANENTLY',
  message: 'CREDIT_NOTE.MODAL.DELETE_PERMANENTLY_MESSAGE',
  confirmBtnText: 'BUTTON.DELETE',
  confirmBtnIcon: 'trash-2'
};

export const DeleteModalData: CommonModalDataModel = {
  title: 'MODAL.DELETE',
  message: 'CREDIT_NOTE.MODAL.DELETE_MESSAGE',
  confirmBtnText: 'BUTTON.DELETE',
  confirmBtnIcon: 'trash-2'
};

export const CloseOrCreateModalData: CommonModalDataModel = {
  title: 'CREDIT_NOTE.MODAL.CREATE_TITLE',
  message: 'CREDIT_NOTE.MODAL.CREATE_MESSAGE',
  isRejectButton: true,
  rejectBtnText: 'BUTTON.DELETE',
  confirmBtnText: 'BUTTON.CREATE',
  confirmBtnIcon: 'plus-square',
  titleIcon: 'question-mark-circle',
  titleColor: 'main-100',
};

export function getInfoErrorModalData(title: string, message: string): CommonModalDataModel {
  return {
    title,
    message,
    hideCancelBtn: true,
    titleIcon: 'alert-triangle',
    titleColor: 'yellow-400',
    confirmBtnText: 'BUTTON.CLOSE',
    confirmBtnIcon: 'close'
  }
}

export const EraQuantityMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_QUANTITY',
  'CREDIT_NOTE.MODAL.ERA_QUANTITY_MISMATCH_MESSAGE'
);

export const EraCostMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_COST',
  'CREDIT_NOTE.MODAL.ERA_COST_MISMATCH'
);
