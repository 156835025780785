import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material';
import { FileUploadModule } from 'ng2-file-upload';
import { LottieModule } from 'ngx-lottie';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';

import { StorageModule } from '../storage';
import {
  AppContextService,
  PreloaderService,
  RnplCommonService,
  RnplModuleService
} from './services';
import {
  DisabledItemDirective,
  RequestButtonDirective,
  CopyClipboardDirective,
  ScrollSpyDirective,
  DropFileDirective,
  ClickOutsideDirective,
  DropdownHeightDirective,
  ButtonListPositionsDirective
} from './directives';
import {
  ExportButtonComponent,
  IconComponent,
  IconContainerDirective,
  ImageUploadComponent,
  SvgCheckmarkComponent,
  CopyComponent,
} from './components';
import {
  CountryImagePipe,
  ProcessAttributeValuePipe,
  LangImagePipe,
  CurrencyImagePipe,
  MetricConversionPipe,
  TypeOfPipe,
  SavedAtPipe,
  TimePickerFocusedValuePipe,
  TimePickerDisplayedValuePipe,
  StatusTranslatePipe,
  UnitTypeTranslatePipe,
  AttrNameTranslatePipe,
  ModuleNameTranslatePipe,
  ProductTypeIconPipe,
  PartnerTypeIconPipe,
  CountryImageByIso3Pipe,
  ColorTranslatePipe,
  CountryTranslateByIso3Pipe,
  DeliveryServiceImagePipe,
  DeliveryServiceTranslatePipe,
  DocumentIconPipe,
  EcoCategoryNameTranslatePipe,
  FileSizePipe,
  CountryTransformFromIso3ToIso2Pipe,
  CountryTransformFromIso2ToIso3Pipe,
  GlCategoryIconPipe,
  GeneralPartnerTranslatePipe
} from './pipes';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ChangeStatusConfirmationModalComponent } from './modals/change-status-confirmation-modal/change-status-confirmation-modal.component';
import { NgxResizeWatcherDirective } from '../ui-components/table/directives/ngx-resize-watcher';
import { CurrencyFormat } from './pipes/currency-format/currency-format.pipe';
import { DateTimezonePipe } from './pipes/date-timezone/date-timezone.pipe';
import { HighlightColorDirective } from './directives/highlight-number/highlight-number.dirrective';
import { NumbersOnlyDirective } from '../ui-components/table/directives/number-only';
import { RnplVatValidatorDirective } from './validators/vat-validator-async';
import { LoaderWhiteModule } from '../ui-components/loader-white/loader-white.module';
import { CompanyProfileLoadedGuard } from './guards/company-profile-loaded.guard';
import { TranslatedDatePipe } from './pipes/translated-date.pipe';
import { ImageCropperModalComponent } from 'projects/workspace/src/app/shared/components/image-cropper-modal/image-cropper-modal.component';
import { ImageCropperModalModule } from 'projects/workspace/src/app/shared/components/image-cropper-modal/image-cropper-modal.module';

export function playerFactory() {
  return import('lottie-web');
}

const DIRECTIVES = [
  DisabledItemDirective,
  IconContainerDirective,
  DropFileDirective,
  NgxResizeWatcherDirective,
  NumbersOnlyDirective,
  CopyClipboardDirective,
  HighlightColorDirective,
  RnplVatValidatorDirective,
  DropdownHeightDirective,
  ScrollSpyDirective,
  ClickOutsideDirective,
  RequestButtonDirective,
  ButtonListPositionsDirective
];


const DECLARATIONS = [
  ...DIRECTIVES,
  IconComponent,
  ExportButtonComponent,
  ImageUploadComponent,
  ProcessAttributeValuePipe,
  CountryImagePipe,
  LangImagePipe,
  CurrencyImagePipe,
  SvgCheckmarkComponent,
  PreloaderComponent,
  MetricConversionPipe,
  TypeOfPipe,
  CurrencyFormat,
  AvatarComponent,
  ChangeStatusConfirmationModalComponent,
  CopyComponent,
  SavedAtPipe,
  DateTimezonePipe,
  TimePickerFocusedValuePipe,
  TimePickerDisplayedValuePipe,
  StatusTranslatePipe,
  UnitTypeTranslatePipe,
  AttrNameTranslatePipe,
  ModuleNameTranslatePipe,
  ProductTypeIconPipe,
  PartnerTypeIconPipe,
  CountryImageByIso3Pipe,
  ColorTranslatePipe,
  CountryTranslateByIso3Pipe,
  DeliveryServiceImagePipe,
  DeliveryServiceTranslatePipe,
  DocumentIconPipe,
  EcoCategoryNameTranslatePipe,
  TranslatedDatePipe,
  FileSizePipe,
  CountryTransformFromIso3ToIso2Pipe,
  CountryTransformFromIso2ToIso3Pipe,
  GlCategoryIconPipe,
  GeneralPartnerTranslatePipe
];


@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  imports: [
    CommonModule,
    DragDropModule,
    MatMenuModule,
    FileUploadModule,
    LottieModule.forRoot({player: playerFactory, useCache: true}),
    StorageModule,
    LoaderWhiteModule,
    TranslateModule,
    ImageCropperModule,
    ImageCropperModalModule
  ],
  providers: [
    AppContextService,
    PreloaderService,
    RnplModuleService,
    RnplCommonService,
    TimePickerFocusedValuePipe,
    TimePickerDisplayedValuePipe,
    StatusTranslatePipe,
    UnitTypeTranslatePipe,
    AttrNameTranslatePipe,
    ModuleNameTranslatePipe,
    CompanyProfileLoadedGuard,
    CountryImagePipe,
    CurrencyFormat,
    FileSizePipe,
    CurrencyFormat,
    CountryTransformFromIso3ToIso2Pipe,
    CountryTransformFromIso2ToIso3Pipe,
  ],
  entryComponents: [
    ChangeStatusConfirmationModalComponent,
    ImageCropperModalComponent
  ]
})
export class RnplCommonModule {

}
