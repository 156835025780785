import { Batch } from '../../../models/purchase-order/batch';
import { BatchStatus } from '../../../enums/batch-status';
import { BatchesComponent } from './batches.component';

export interface TemplateBatch {
  readonly processTitle: BatchStatus;
  readonly inAction?: boolean;
  readonly actionTitle?: string;
  count: number;
  batches?: Batch[];
  action?: () => void;
}

export const getBatches = (component: BatchesComponent, batches: Batch[]): TemplateBatch[] => {
  return [
    {
      processTitle: BatchStatus.ARRIVALS,
      count: 0,
      batches: [],
      inAction: true,
      actionTitle: 'BUTTON.CREATE_NEW_BATCH',
      action: component.createNewBatch.bind(component)
    },
    {
      processTitle: BatchStatus.BUFFER,
      count: 0,
      batches: [],
    },
    {
      processTitle: BatchStatus.INSPECTION,
      count: 0,
      batches: [],
    },
    {
      processTitle: BatchStatus.ON_STOCK,
      count: 0,
      batches: [],
    }
  ]
    .map((b: TemplateBatch) => {
      /*let filteredBatches = [];

      if (b.processTitle === BatchStatus.ARRIVALS) {
        filteredBatches = batches.filter(batch => !batch.actual_arrival_date);
      }

      if (b.processTitle === BatchStatus.BUFFER) {
        filteredBatches = batches.filter(batch => batch.actual_arrival_date);
      }*/
      return setBatchInfo(b, batches.filter(batch => batch.status === b.processTitle)) ;
    });
};

const setBatchInfo = (batch: TemplateBatch, filteredBatches: Batch[]) => {
  batch.count = filteredBatches.length;
  batch.batches = [].concat(filteredBatches);

  return batch;
};
