import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';

import { TableColumnModelExtended } from '../../../../../models/table-column.model';

@Component({
  selector: 'rnpl-column-with-dropdown',
  templateUrl: './column-with-dropdown.component.html',
  styleUrls: ['./column-with-dropdown.component.scss']
})
export class ColumnWithDropdownComponent implements OnChanges {

  public disabled = false;
  public invalid = false;
  public validatable = true;
  public listItems: any[];

  @Input() value: string | number | boolean = null;
  @Input() rowIndex: number;
  @Input() column: TableColumnModelExtended;
  @Input() row: any;
  @Input() rowItems: any[];
  @Input() disabledCell?: boolean;

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('row')) {
      this.disabled = false;
      this.invalid = !!this.row.invalid;
      this.updateDisabledStatus();
    }

    if (changes.hasOwnProperty('column')) {
      if (this.column.validatable === false) {
        this.validatable = false;
      }
      this.filterUnitTypeList();
    }

    if (changes.hasOwnProperty('value')) {
      this.value = this.value === 0 || this.value === false
        ? this.value
        : this.value || null;
      this.filterUnitTypeList();
    }
  }

  public updateDisabledStatus(): void {
    if (get(this.row, 'disabled.fully')) {
      this.disabled = true;
      return;
    }

    if (get(this.row, 'disabled.fields.length')) {
      const disabledFieldsLength = this.row.disabled.fields.filter((el) => el === this.column.prop).length;
      if (disabledFieldsLength) {
        this.disabled = true;
      }
    }
  }

  public filterUnitTypeList(): void {
    // check if it is column for 'unitType'
    if (this.row.hasOwnProperty('unitType') && this.column.prop === 'unitType') {
      // check should we filter list
      if (this.column.listItems.find(i => i.hasOwnProperty('typeGroupList')) && this.value) {
        const newList = this.column.listItems.filter(item => item.typeGroupList.includes(this.value));
        this.listItems = [...newList];
      }
    }
  }

  public updateValueEmit(): void {
    this.updateValue.emit({
      value: this.value,
      cell: this.column.prop,
      rowIndex: this.rowIndex
    });
  }

}
