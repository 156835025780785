import {Component, Input, OnInit} from '@angular/core';
import { SummaryItemModel } from '../../../models/summary-item.model';

@Component({
  selector: 'rnpl-view-summary',
  templateUrl: './view-summary.component.html'
})
export class ViewSummaryComponent implements OnInit {

  @Input()
  title: string = 'Summary';

  @Input()
  summaryData: Array<SummaryItemModel>;

  public isOpen = false;
  public totalBalanceNetto: number = 0;
  public totalBalanceBrutto: number = 0;
  // default vat percent
  private vatPercent = 0.2;

  constructor() {
  }

  ngOnInit() {
    // get total balance
    const summaryData = this.summaryData;
    summaryData.map(item => {
      this.totalBalanceNetto += (item.balance ? item.balance : 0);
      this.totalBalanceBrutto += (item.balance ? ((item.balance * this.vatPercent) + item.balance) : 0);
    });
  }

  getBalanceNetto(val: number) {
    if (val) {
      const netto = val.toFixed(2);
      return `${netto} €`;
    } else {
      return 'N/A';
    }
  }

  getValueAddedTax(val: number) {
    if (val) {
      const vat = val * this.vatPercent;
      return `${vat.toFixed(2)} €`;
    } else {
      return 'N/A';
    }
  }

  getBalanceBrutto(val: number) {
    if (val) {
      const brutto = ((val * this.vatPercent) + val);
      return `${brutto.toFixed(2)} €`;
    } else {
      return 'N/A';
    }
  }

}
