import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { RnplCommonModule, UIComponentsModule } from '../modules';
import {
  AuthConfirmFormComponent,
  SignInFormComponent
} from '../forms';
import { AuthKeyPageComponent } from './auth-key-page/auth-key-page.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';
import { ChangePasswordPageComponent } from './change-password-page/change-password-page.component';
import { ChangePasswordComponent } from '../modules/modals/modals-sign-in/change-password/change-password.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { InfoHintsCardModule } from '../modules/info-hints-card/info-hints-card.module';
import { LogoModule } from '../modules/ui-components/logo/logo.module';


@NgModule({
  declarations: [
    SignInPageComponent,
    SignInFormComponent,
    AuthKeyPageComponent,
    AuthConfirmFormComponent,
    ChangePasswordPageComponent,
  ],
  exports: [
    SignInPageComponent,
    AuthKeyPageComponent,
    SignInFormComponent,
    ChangePasswordPageComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RnplCommonModule,
    UIComponentsModule,
    TranslateModule,
    NgOtpInputModule,
    InfoHintsCardModule,
    LogoModule,
    PerfectScrollbarModule
  ],
  entryComponents: [ChangePasswordComponent]
})
export class CommonPagesModule {
}
