import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { CountryModel } from '../../../rnpl-common';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-filters-control-country',
  templateUrl: './filters-control-country.component.html',
})
export class FiltersControlCountryComponent extends FiltersControlBaseComponent implements OnInit {

  @Input() observableList: Observable<any>;
  @Input() bindValue: string;
  @Input() bindLabel: string;

  readonly countries$: BehaviorSubject<CountryModel[]> = new BehaviorSubject<CountryModel[]>([]);
  public defaultBindValue = 'code_iso3';
  public defaultBindLabel = 'title';

  constructor(
    private readonly store: Store<AppState>,
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  public ngOnInit(): void {
    this.selectCountries();
  }

  private selectCountries(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries: CountryModel[]) => {
        this.countries$.next(countries);
      });
  }

  public getTitle(item): string {
    return get(item, this.bindLabel || this.defaultBindLabel);
  }

}
