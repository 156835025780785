import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

import { RnplCommonModule } from '../rnpl-common';
import { InfoHintsCardComponent } from './info-hints-card.component';


@NgModule({
  declarations: [InfoHintsCardComponent],
  exports: [InfoHintsCardComponent],
  imports: [
    TranslateModule,
    RnplCommonModule,
    CommonModule,
  ]
})
export class InfoHintsCardModule { }
