import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { ProductTypes } from 'common/src/modules/products/product-types';

@Component({
  selector: 'rnpl-arbitrary-position-type-control',
  templateUrl: './arbitrary-position-type-control.component.html',
})
export class ArbitraryPositionTypeControlComponent implements OnChanges {

  @Input() control: FormControl = new FormControl();
  @Input() controlValue: ProductTypes;
  @Input() idKey: string;
  @Input() disabled: boolean;

  @Output() fieldChanged: EventEmitter<ProductTypes> = new EventEmitter<ProductTypes>();

  public productTypes = ProductTypes;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.hasOwnProperty('controlValue')) {
      this.control.patchValue(this.controlValue);
    }

    if (changes && changes.hasOwnProperty('disabled')) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  public updateField(fieldValue: ProductTypes): void {
    this.fieldChanged.emit(fieldValue);
  }

}
