import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

export function getLinkByType(id: number | string, type: DocumentTypesUppercaseEnum) {
  if (!id) {
    return '';
  }

  switch (type) {
    case DocumentTypesUppercaseEnum.IIN:
      return `/accounting/incoming-invoice/${id}`;
    case DocumentTypesUppercaseEnum.OIN:
      return `/accounting/outgoing-invoice/${id}`;
    case DocumentTypesUppercaseEnum.OCN:
    case DocumentTypesUppercaseEnum.CRN:
    case DocumentTypesUppercaseEnum.ICN:
      return `/accounting/credit-note/${id}`;

    default:
      return '';
  }
}
