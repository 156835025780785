import { Component, Input } from '@angular/core';
import { DocumentTypesUppercaseEnum } from '../../modals/modals-common/link-document-modal/enums/ducument-types.enum';

@Component({
  selector: 'rnpl-document-status',
  templateUrl: './document-status.component.html',
  styleUrls: ['./document-status.component.scss']
})
export class DocumentStatusComponent {

  @Input() status: string;
  @Input() documentType?: DocumentTypesUppercaseEnum;
  @Input() statusLblClass: string = null;
  @Input() outlineNone = false;

}
