import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IgxDatePickerComponent } from 'igniteui-angular/lib/date-picker/date-picker.component';

@Component({
  selector: 'rnpl-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnChanges, OnDestroy {

  public isOpenCalendar: boolean = false;
  public displayDate: Date;
  public isActiveInput: boolean;
  public topDropdown: boolean;
  private dateControlSubs: Subscription;
  public currentLang = 'en';

  @Input() public date: string;
  @Input() public controlDateFormat: string;
  @Input() public label: string;
  @Input() public errorMessage: string;
  @Input() public isOptional: boolean = false;
  @Input() public disabled: boolean;
  @Input() public invalid: boolean = false;
  @Input() public forTable: boolean = false; /* set height 32px*/
  @Input() public control: FormControl;
  @Input() public required: boolean = false;
  @Input() public isDark: boolean = false;
  @Input() public isReadonly: boolean = false;
  @Input() public hintsId: string;

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropDownTarget', {static: false}) private input: ElementRef<any>;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly translateService: TranslateService,
  ) {
    this.currentLang = translateService.currentLang;
  }

  updateValueEmit(value: Date): void {
    this.updateValue.emit({
      value: value,
    });
    if (this.control) {
      const date = this.controlDateFormat
        ? this.datePipe.transform(value, this.controlDateFormat)
        : value;
      this.control.setValue(date);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('control')) {
      this.setDisplayedDate(this.control.value);
      this.bindDateControl();
    }

    if (changes.hasOwnProperty('date')) {
      this.setDisplayedDate(this.date);
    }
  }

  public setDisplayedDate(value: string | Date): void {
    if (value) {
      this.displayDate = new Date(value);
    } else {
      this.displayDate = null;
    }
  }

  public bindDateControl(): void {
    this.dateControlSubs = this.control.valueChanges.subscribe(value => {
      this.setDisplayedDate(value);
    });
  }

  public openCalendar(): void {
    if (this.disabled || get(this.control, 'disabled')) { return; }
    this.isOpenCalendar = true;

    const dropdownDom: HTMLElement = document.querySelector('.igx-date-picker--dropdown');
    const inputPosition = this.input.nativeElement.getBoundingClientRect().bottom;
    const calendarPosition = dropdownDom.getBoundingClientRect().bottom;

    const overlayContent = document.querySelector<HTMLElement>('.igx-overlay__content');

    /*todo: refactoring.
    *  */
    /* Its for adaptiv dropdown(css no working for this) , only this method how i think. */
    this.topDropdown = inputPosition > calendarPosition;
    if (this.topDropdown) {
      dropdownDom.classList.add('top');
    }

    dropdownDom.style.width = this.input.nativeElement.offsetWidth + 'px';
    overlayContent.style.opacity = '1';

    setTimeout(() => {
      const end = this.input.nativeElement.value.length
      this.input.nativeElement.setSelectionRange(end, end);
      this.input.nativeElement.focus();
      }, 500); // fix focus on input
  }

  public closeCalendarHandler(datePicker: IgxDatePickerComponent): void {
    const formattedDate: Date = this.formatDateStringToDate(this.input.nativeElement.value);
    if (formattedDate !== this.displayDate || (!!this.control && !!this.control.value && !formattedDate)) {
      this.updateValueEmit(formattedDate)
    }
    datePicker.closeCalendar();
    this.isOpenCalendar = false;
    this.isActiveInput = false;
  }

  public formatter = (date: Date) => {
    try {
      return this.datePipe.transform(date, 'dd.MM.yyyy');
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  public formatDateStringToDate(value: string): Date {
    const date = new Date(value.split('.').reverse().join('/'));

    if (value && value.length === 10 && this.isValidDate(date)) { // Check that date is exist, full d0.M0.0000 and valid
        return this.getDateWithoutTimezoneOffset(date);
    }

    this.input.nativeElement.value = ''; // force reset input view due to unchanged ngModel
    return null;
  }

  public getDateWithoutTimezoneOffset(date: Date) {
    if (!date.getHours()) {
      const userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + Math.abs(userTimezoneOffset));
    } else {
      date.setUTCHours(0, 0, 0, 0);
    }
    return date;
  }

  public activateInput(): void {
    this.isActiveInput = true;
  }

  public isValidDate(date: any): boolean {
    return !isNaN(date) && date instanceof Date;
  }

  ngOnDestroy(): void {
    if (this.dateControlSubs) {
      this.dateControlSubs.unsubscribe();
    }
  }

}
