import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PositionsContextMenuComponent } from './positions-context-menu.component';
import { ContextMenuModule } from 'ngx-contextmenu';

@NgModule({
  declarations: [PositionsContextMenuComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ContextMenuModule,
  ],
  exports: [PositionsContextMenuComponent]
})
export class PositionsContextMenuModule { }
