import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


import { AppContextService } from '../rnpl-common/services';
import { StorageService } from '../storage';
import {
  WizardContext,
  WizardContextItem,
  WizardEntity
} from './models';


@Injectable()
export class WizardService {

  private static STORAGE_KEY = 'rnpl_wizard_context';

  private _context: WizardContext = [];

  constructor(private storage: StorageService, private appContext: AppContextService, private router: Router) {
    this.loadContext();
  }

  /**
   * Opens entity wizard
   *
   * @param entity Wizard entity
   * @param data Wizard data
   * @param backwardRoute Backward link
   */
  public create<T>(entity: WizardEntity, data: T, backwardRoute: string | Array<string>): void {
    this.createContext(entity, data, backwardRoute);

    this.router.navigate([`/${this.appContext.getFromContext('currentModule')}/${entity}/create/general-settings`]);
  }

  /**
   * Opens entity wizard
   *
   * @param entity Wizard entity
   * @param data Wizard data
   * @param backwardRoute Backward link
   */
  public createContext<T>(entity: WizardEntity, data: T, backwardRoute: string | Array<string>): void {
    const context: WizardContextItem<T> = {
      entity, data, backwardRoute,
      step: 'general-settings'
    };
    this.setContext<T>(context);
  }

  /**
   * Opens entity wizard for editing
   *
   * @param entity Wizard entity
   * @param data Wizard data
   * @param backwardRoute Backward link
   * @param step wizard step name
   */
  public edit<T>(entity: WizardEntity, data: T, backwardRoute: string | Array<string>, step = 'products-layout'): void {
    const context: WizardContextItem<T> = {
      entity, data, backwardRoute,
      step: step,
      edited: true
    };
    this.setContext<T>(context);

    this.router.navigate([`/${this.appContext.getFromContext('currentModule')}/${entity}/edit/${step}`]);
  }

  /**
   * Returns wizard context data
   *
   * @param entity Wizard entity
   */
  public getContext<T>(entity: WizardEntity): WizardContextItem<T> {
    const context = this.loadContext()
      .find(item => {
        return item.entity === entity;
      });

    if (!context) {
      return null;
    }
    return context;
  }

  /**
   * Sets step to context
   *
   * @param entity Wizard entity
   * @param step Wizard step
   */
  public setWizardStep(entity: WizardEntity, step: string): void {
    const contextIndex = this.loadContext()
      .findIndex(item => item.entity === entity);

    if (contextIndex < 0) {
      return;
    }

    this._context[contextIndex].step = step;
    this.saveContext();
  }

  /**
   * Updates context data
   *
   * @param entity Wizard Entity
   * @param data Some data to patch in context
   */
  public patchContextData(entity: WizardEntity, data: any): void {
    const contextIndex = this.loadContext()
      .findIndex(item => item.entity === entity);

    if (contextIndex < 0) {
      return;
    }

    this._context[contextIndex].data = {...this._context[contextIndex].data, ...data};
    this.saveContext();
  }

  /**
   * Closes wizard by entity
   *
   * @param entity Wizard entity
   */
  public close(entity: WizardEntity): void {
    const contextIndex = this.loadContext()
      .findIndex(item => item.entity === entity);

    if (contextIndex < 0) {
      return;
    }
    const context = this._context.splice(contextIndex, 1)[0];
    this.saveContext();
    this.router.navigate(
      typeof context.backwardRoute === 'string'
        ? [context.backwardRoute]
        : context.backwardRoute
    );
  }

  private loadContext(): WizardContext {
    const context = this.storage.load<WizardContext>(WizardService.STORAGE_KEY, StorageService.TYPE_SESSION);
    if (context) {
      this._context = context;
    }
    return this._context;
  }

  private saveContext(): void {
    this.storage.store(WizardService.STORAGE_KEY, this._context, StorageService.TYPE_SESSION);
  }

  private setContext<T>(context: WizardContextItem<T>): void {
    const contextIndex = this._context.findIndex(item => item.entity === context.entity);
    if (contextIndex < 0) {
      this._context.push(context);
    } else {
      this._context[contextIndex] = context;
    }
    this.saveContext();
  }
}
