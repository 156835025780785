export enum PoTabs {
  Draft = 'draft',
  Open = 'open',
  Completed = 'completed',
  Canceled = 'canceled',
  Deleted = 'deleted',
  Template = 'template'
}

export enum PoTabsManagement {
  POSITIONS = 'positions',
  LOGISTICS = 'logistics',
  DOCUMENTS = 'documents',
  ORDER_FILE = 'order-file',
  ACCOUNTING = 'accounting',
  BATCHES = 'batches'
}
