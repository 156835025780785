import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

import { MetricConversionPipe, RnplCommonModule } from 'common/src/modules/rnpl-common';
import { DeliveryCostFormComponent } from './delivery-cost-form.component';
import { UIComponentsModule } from 'common/src/modules';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DeliveryCostFormComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgSelectModule,
        UIComponentsModule,
        NgxMaskModule,
        NgxCurrencyModule,
        RnplCommonModule,
        TranslateModule,
    ],
  exports: [DeliveryCostFormComponent],
  entryComponents: [DeliveryCostFormComponent],
  providers: [MetricConversionPipe]
})
export class DeliveryCostFormModule { }
