import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AddressModel } from 'common/src/models';

@Component({
  selector: 'rnpl-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit {

  @Input() public addressData: AddressModel;
  @Input() public companyName: string;
  @Input() public isReadonly: boolean;
  @Input() public addressTitle: string;

  @Output() isEditAddress: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  public editAddress(): void {
    this.isEditAddress.emit();
  }


}
