import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { get } from 'lodash';

import { CustomerTypeEnum } from 'common/src/models';
import { CreateNewPartnerModalComponent, CreateNewPrivatePartnerModalComponent } from 'common/src/modules/modals/modals-crm';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';
import { Contact, GeneralPartnerModel, PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';
import { PrivatePartnerModel } from 'projects/workspace/src/app/partners/private/models/partner.model';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { PartnersFieldsChangedModel } from './update-partner-fields.model';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { SystemPreferencesPermissionsService } from '../../../services/system-preferences-permissions.service';


// todo: after GL accounts release need to remove code for user defined partner
@Component({
  selector: 'rnpl-select-partner',
  templateUrl: './select-partner.component.html',
  styleUrls: ['./select-partner.component.scss']
})
export class SelectPartnerComponent implements OnInit, OnChanges, OnDestroy {

  public corporatePartnersEnabled: boolean = false;
  public privatePartnersEnabled: boolean = false;

  @Input() readonly partnerLabel: string = 'FORM.CUSTOMER';

  @Input() readonly partnerType: FormControl;
  @Input() readonly companyId: FormControl;
  @Input() readonly companyIdFieldName: string = 'company.id';
  @Input() readonly generalPartnerNameFieldName: string = 'generalPartnerName';
  @Input() readonly generalPartnerVatNumberFieldName: string = 'vatNumber';
  @Input() readonly userDefinedPartnerName: boolean = false;
  @Input() readonly generalPartnerAllowed: boolean = false;
  @Input() readonly companyContactId: FormControl;
  @Input() readonly email: FormControl;
  @Input() readonly phone: FormControl;
  @Input() readonly userDefinedCustomerControl: FormControl;
  @Input() readonly generalPartnerNameControl: FormControl;
  @Input() readonly generalPartnerVatNumberControl: FormControl;
  // @Input() readonly countryControl: FormControl;
  @Input() readonly companyIdBindValue: string = 'id';
  @Input() readonly partnersFilers: { [key: string]: any } = null;
  @Input() readonly userDefinedPartnerAction: boolean;
  @Input() readonly userDefinedPartnerType: CustomerTypeEnum = CustomerTypeEnum.NOT_SAVED_PARTNER;
  @Input() readonly placeholder: string = 'FORM.SELECT_CUSTOMER';
  @Input() readonly hintsId: string = '';
  @Input() readonly documentSummaryType: boolean;
  @Input() readonly contactPersonIsOptional: boolean;
  @Input() readonly fromModal: boolean;
  @Input() readonly showInfoBlock: boolean;
  @Input() readonly infoBlockTitle: string;
  @Input() readonly infoBlockMsg: string;
  @Input() readonly showValidationErrors: string;
  // @Input() countries: CountryModel[];

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();
  @Output() partnerChanged: EventEmitter<PartnersFieldsChangedModel> = new EventEmitter<PartnersFieldsChangedModel>();
  @Output() selectedCustomerChanged: EventEmitter<PartnerModel> = new EventEmitter<PartnerModel>();
  // @Output() countryChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  @ViewChild('userDefinedPartner', {static: false}) userDefinedPartnerRef: ElementRef;

  public displayUserDefinedPartnerName$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly combinedPartnersList$: BehaviorSubject<PartnerModel[]> = new BehaviorSubject<PartnerModel[]>([]);
  readonly generalPartnersList$: BehaviorSubject<GeneralPartnerModel[]> = new BehaviorSubject<GeneralPartnerModel[]>([]);
  readonly contactsList$: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>([]);
  readonly destroy$: Subject<void> = new Subject();

  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private partnersApiService: PartnersApiService,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
  ) { }

  ngOnInit(): void {
    this.showDropdownSpin$.next(true);
    this.getCombinedPartners();
    if (this.generalPartnerAllowed) {
      this.getGeneralPartners();
    }
    this.corporatePartnersEnabled = this.systemPreferencesPermissionsService.corporatePartnersEnabled();
    this.privatePartnersEnabled = this.systemPreferencesPermissionsService.privatePartnersEnabled();
  }

  @TrackInputChanges<string>('companyId', 'updateContactsList', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges) {}


  private getCombinedPartners(): void {
    this.partnersApiService.getCombinedPartnersList(this.partnersFilers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(partners => {
        this.showDropdownSpin$.next(false);
        this.combinedPartnersList$.next(partners);
        this.updateContactsList();
        if (this.generalPartnerAllowed && !this.generalPartnersList$.getValue().length) {
          this.getGeneralPartners();
        }
      });
  }

  private getGeneralPartners(): void {
    this.partnersApiService.getGeneralPartners()
      .pipe(takeUntil(this.destroy$))
      .subscribe(partners => {
        this.generalPartnersList$.next(partners);
      });
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  // public updateCountryField(fieldValue: any, fieldName: string): void {
  //   this.countryChanged.emit({fieldName, fieldValue});
  // }

  public updatePartner(
    partnerType: CustomerTypeEnum,
    partnerTypeFieldName: string,
    partnerId: number,
    partnerIdFieldName: string,
    salesPriceList: string
  ): void {
    this.partnerChanged.emit({partnerType, partnerTypeFieldName, partnerId, partnerIdFieldName, salesPriceList});
  }

  public selectedCustomer(customer): void {
    this.selectedCustomerChanged.emit(customer);
  }

  public createNewPartner() {
    const dialogRef = this.dialog.open(CreateNewPartnerModalComponent, {disableClose: true});

    dialogRef.afterClosed()
      .subscribe((partner: PartnerModel) => {
        if (partner) {
          this.getCombinedPartners();
          this.updatePartnerFields(partner);
          this.companyIdBindValue
            ? this.companyId.setValue(partner.id)
            : this.companyId.setValue(partner);
        }
      });
  }

  public createNewPrivatePartner() {
    const dialogRef = this.dialog.open(CreateNewPrivatePartnerModalComponent, {disableClose: true});

    dialogRef.afterClosed()
      .subscribe((partner: PrivatePartnerModel) => {
        if (partner) {
          this.getCombinedPartners();
          this.updatePartnerFields(partner);
          this.companyIdBindValue
            ? this.companyId.setValue(partner.id)
            : this.companyId.setValue(partner);
        }
      });
  }

  public updateContactsList() {
    this.contactsList$.next([]);

    if (this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER) {

      const selectedPartner = this.combinedPartnersList$
        .getValue()
        .find(partner => partner.id === get(this.companyId, this.companyIdBindValue  === '' ? 'value.id' : 'value'));
      if (selectedPartner && selectedPartner.contacts.length) {
        this.contactsList$.next(this.prepareContactsList(selectedPartner.contacts));
      }
    }
  }

  public prepareContactsList(contacts): Contact[] {
    return contacts.map(contact => {
      let contactName: string;
      switch (contact.name) {
        case 'Corporate Contact':
          contactName = 'COMMON.CORPORATE_CONTACT';
          break;
        case 'User-defined Contact':
          contactName = 'COMMON.USER_DEFINED_CONTACT';
          break;
        case 'Accounting Contact':
          contactName = 'COMMON.ACCOUNTING_CONTACT';
          break;
      }

      return {
        ...contact,
        name: contactName || contact.name
      };
    });
  }

  public displayUserDefinedPartner(): void {
    this.updatePartner(this.userDefinedPartnerType, 'partnerType', 0, this.companyIdFieldName, '');
    this.partnerType.setValue(this.userDefinedPartnerType);
    this.displayUserDefinedPartnerName$.next(true);
    this.cdr.detectChanges();
    if (this.userDefinedPartnerRef && this.userDefinedPartnerRef.nativeElement) {
      this.userDefinedPartnerRef.nativeElement.focus();
    }
  }

  get isPartnerTypeCorporate(): boolean {
    return this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER;
  }

  get isPartnerTypeGeneral(): boolean {
    return this.partnerType.value === CustomerTypeEnum.GENERAL;
  }

  get isPartnerTypeNotSavedPartner(): boolean {
    return this.partnerType.value === CustomerTypeEnum.NOT_SAVED_PARTNER || this.partnerType.value === CustomerTypeEnum.NOT_SAVED;
  }

  public updatePartnerFields(partner): void {
    this.selectedCustomer(partner);
    this.updatePartner(partner.type, 'partnerType', partner.id, this.companyIdFieldName, partner.salesPriceList);
    this.displayUserDefinedPartnerName$.next(false);
    this.partnerType.setValue(partner.type);
  }

  public selectGeneralPartner(partner: GeneralPartnerModel): void {
    if (!this.companyIdBindValue) {
      this.companyId.patchValue(partner);
    } else {
      this.companyId.patchValue(partner.id);
    }

    this.selectedCustomer(partner);
    this.updatePartner(partner.type as CustomerTypeEnum, 'partnerType', partner.id, this.companyIdFieldName, '');
    this.displayUserDefinedPartnerName$.next(false);
    this.partnerType.setValue(partner.type);
  }

  get bottomIndent(): number {
    if (this.generalPartnerAllowed && this.generalPartnersList$.getValue().length) {
      return this.generalPartnersList$.getValue().length * 44 + (this.userDefinedPartnerAction ? 124 : 80);
    } else {
      return this.userDefinedPartnerAction ? 124 : 80;
    }
  }

  get getSelectedGeneralPartner(): GeneralPartnerModel {
    if (!this.companyId.value || !this.generalPartnersList$.getValue().length) { return null; }
    return this.generalPartnersList$.getValue().find(gp => (gp.id === this.companyId.value) || (gp.id === this.companyId.value.id));
  }

  // get availableCountries(): CountryModel[] {
  //   if (!this.countries || !this.getSelectedGeneralPartner) { return []; }
  //
  //   return this.countries.filter(c => {
  //     if (this.getSelectedGeneralPartner.generalType === 'eu') { return c.isEu; }
  //     if (this.getSelectedGeneralPartner.generalType === 'non_eu') { return !c.isEu; }
  //     return this.countries;
  //   })
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
