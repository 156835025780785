import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rnpl-settings-table',
  templateUrl: './settings-table.component.html',
  styleUrls: ['./settings-table.component.scss']
})
export class SettingsTableComponent implements OnInit {

  @Input() disabled = true;
  @Input() count: number = 0;
  @Output() toggleSidePanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  toggleSaidPanelAction(): void {
    this.toggleSidePanel.emit();
  }

  ngOnInit() {}
}
