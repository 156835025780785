import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { finalize, takeUntil } from 'rxjs/operators';

import { DocumentTypesUppercaseEnum } from '../link-document-modal/enums/ducument-types.enum';
import { DocumentTextTemplatesApiService } from 'projects/workspace/src/app/shared/services';
import { DocumentTextTemplateModel } from 'projects/workspace/src/app/shared/models';
import { DocumentTextPositionEnum } from 'projects/workspace/src/app/shared/enums';
import { SavePdfTextTemplateModalComponent } from '../save-pdf-text-template-modal/save-pdf-text-template-modal.component';
import { DangerModalComponent } from '../danger-modal/danger-modal.component';
import { CommonModalsActionsEnum } from '../common-modals-actions.enum';
import { DeleteTemplateModalData } from 'projects/workspace/src/app/shared/constants';

type UpdateRequestFunction = (fieldValue: string, fieldName: string) => Observable<any>;

@Component({
  selector: 'rnpl-add-pdf-text-modal',
  templateUrl: './add-pdf-text-modal.component.html',
})
export class AddPdfTextModalComponent implements OnInit, OnDestroy {

  public templatesList: DocumentTextTemplateModel[] = [];
  public introText: string = '';
  public outroText: string = '';
  public selectedMode: DocumentTextPositionEnum = DocumentTextPositionEnum.INTRO;
  public selectedTemplate: DocumentTextTemplateModel = null;
  public documentTextPositionEnum = DocumentTextPositionEnum;

  readonly destroy$: Subject<void> = new Subject();
  readonly templatesListLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private dialog: MatDialog,
    private documentTextTemplatesApiService: DocumentTextTemplatesApiService,
    public dialogRef: MatDialogRef<AddPdfTextModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      mode: DocumentTextPositionEnum;
      documentType: DocumentTypesUppercaseEnum;
      introText: string;
      outroText: string;
      textExist: boolean;
      updateRequest$: UpdateRequestFunction;
    }
  ) {
  }

  ngOnInit(): void {
    this.selectedMode = this.data.mode;
    this.introText = this.data.introText;
    this.outroText = this.data.outroText;
    this.getTemplates();
  }

  private getTemplates(): void {
    this.documentTextTemplatesApiService.getTextTemplates(this.data.documentType, this.selectedMode)
      .pipe(
        finalize(() => this.templatesListLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((templates: DocumentTextTemplateModel[]) => {
        this.templatesList = templates;
        this.checkSelectedTemplate();
      });
  }

  public selectedModeChanged(): void {
    this.selectedTemplate = null;
    this.getTemplates();
  }

  public templateSelected(): void {
    if (this.selectedTemplate.position === DocumentTextPositionEnum.INTRO) {
      this.introText = this.selectedTemplate.text;
    }
    if (this.selectedTemplate.position === DocumentTextPositionEnum.OUTRO) {
      this.outroText = this.selectedTemplate.text;
    }
  }

  public saveTemplate(): void {
    this.dialog.open(SavePdfTextTemplateModalComponent, {
      data: {
        templates: this.templatesList,
        documentType: this.data.documentType,
        mode: this.selectedMode,
        text: this.getFieldValue()
      },
      disableClose: true
    }).afterClosed().subscribe((res: boolean) => {
        if (res) {
          this.getTemplates();
        }
      });
  }

  public checkSelectedTemplate(): void {
    const fieldValue = this.getFieldValue();
    if (this.templatesList.length && fieldValue) {
      const selectedTemplate = this.templatesList.find(tpl => tpl.text === fieldValue);
      if (selectedTemplate) {
        this.selectedTemplate = selectedTemplate;
      } else {
        this.selectedTemplate = null;
      }
    }
  }

  public deleteTemplate(): void {
    if (!this.selectedTemplate) { return; }

    this.dialog.open(DangerModalComponent, { data: DeleteTemplateModalData, disableClose: true })
      .afterClosed()
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.documentTextTemplatesApiService.deleteTextTemplate(this.selectedTemplate.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.selectedTemplate = null;
              this.getTemplates();
            });
        }
      });
  }

  public deleteText(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'PDF_SETTINGS.DELETE_TEXT',
        message: 'PDF_SETTINGS.DELETE_TEXT_MSG',
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      },
      disableClose: true
    });

    dialog.afterClosed().subscribe((response: CommonModalsActionsEnum) => {
      if (response === CommonModalsActionsEnum.CONFIRM) {
        if (this.selectedMode === DocumentTextPositionEnum.INTRO) {
          this.introText = '';
        }
        if (this.selectedMode === DocumentTextPositionEnum.OUTRO) {
          this.outroText = '';
        }

        this.submit();
      }
    });
  }

  public submit(): void {
    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    const fieldName = {
      [DocumentTextPositionEnum.INTRO]: 'introText',
      [DocumentTextPositionEnum.OUTRO]: 'outroText',
    };

    this.data.updateRequest$(this.getFieldValue(), fieldName[this.selectedMode])
      .pipe(finalize(() => this.submitRequest$.next(false)))
      .subscribe(() => this.dialogRef.close());
  }

  public getFieldValue(): string {
    const fieldValue = {
      [DocumentTextPositionEnum.INTRO]: this.introText,
      [DocumentTextPositionEnum.OUTRO]: this.outroText,
    };

    return fieldValue[this.selectedMode];
  }

  get editMode(): boolean {
    return this.data.textExist
      || this.selectedMode === DocumentTextPositionEnum.INTRO && !!this.data.introText
      || this.selectedMode === DocumentTextPositionEnum.OUTRO && !!this.data.outroText;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

