import { TranslateService } from '@ngx-translate/core';
import { CommonModalDataModel } from './common-modals.model';

// todo rework to class
export function getAnotherUserEditErrorModalData(
  {document, userName}: {[key: string]: string},
  translateService: TranslateService
): CommonModalDataModel {

  const documentTitle = translateService.instant(setDocumentNameTranslate(document));

  return  {
    title: translateService.instant('COMMON.DOCUMENT_EDITED_TITLE', {document: documentTitle}),
    message: translateService.instant('COMMON.DOCUMENT_EDITED_TEXT', {document: documentTitle, userName}),
    confirmBtnText: translateService.instant('COMMON.FORCE_EDIT'),
    confirmBtnIcon: 'edit',
  };
}

function setDocumentNameTranslate(doc: string): string {
  let documentNameTranslate: string;

  switch (doc.toLowerCase()) {
    case 'sales order':
      documentNameTranslate = 'APP.SALES_ORDER';
      break;
    case 'offer':
      documentNameTranslate = 'APP.OFFER';
      break;
    case 'purchase order':
      documentNameTranslate = 'APP.PURCHASE_ORDER';
      break;
    case 'outgoing invoice':
      documentNameTranslate = 'APP.OUTGOING_INVOICES';
      break;
    case 'incoming invoice':
      documentNameTranslate = 'APP.INCOMING_INVOICES';
      break;
    case 'payment':
      documentNameTranslate = 'DOC_SUMMARY.PAYMENT';
      break;
    case 'credit note':
      documentNameTranslate = 'APP.CREDIT_NOTE';
      break;
    case 'delivery note':
      documentNameTranslate = 'COLUMN.DELIVERY_NOTE';
      break;
    case 'product':
      documentNameTranslate = 'APP.PRODUCT';
      break;
    case 'service':
      documentNameTranslate = 'APP.SERVICE';
      break;
    case 'digital product':
      documentNameTranslate = 'APP.DIGITAL_PRODUCT';
      break;
    case 'partner':
      documentNameTranslate = 'COLUMN.PARTNER';
      break;
    case 'entity':
      documentNameTranslate = 'APP.EMPLOYEES';
      break;
    case 'reporting period preferences':
      documentNameTranslate = 'REPORTING_PERIODS.REPORTING_PERIODS_PREFERENCES';
      break;
    case 'Bank Account':
      documentNameTranslate = 'BANK_ACCOUNTS.BANK_ACCOUNT';
      break;
    default:
      documentNameTranslate = doc;
      break;
  }

  return documentNameTranslate;
}
