import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, CanLoad, Route, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';

@Injectable({
  providedIn: 'root'
})
export class InitialSetupCompletedGuard implements CanActivate, CanLoad {

  constructor(
    private readonly router: Router,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly store: Store<AppState>
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectCompanyProfile).pipe(
      first(),
      switchMap((companyProfile: CompanyProfile) => {
        return isEmpty(companyProfile) ? this.administrationsApiService.getCompanyProfile() : of(companyProfile);
      }),
      catchError(() => {
        return of(false);
      }),
      switchMap((companyProfile: CompanyProfile) => {
        if (companyProfile.tracking.completedAt) {
          this.router.navigate(['/']);
          return of(false);
        } else {
          return of(true);
        }
      }),
    );
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectCompanyProfile).pipe(
      first(),
      switchMap((companyProfile: CompanyProfile) => {
        return isEmpty(companyProfile) ? this.administrationsApiService.getCompanyProfile() : of(companyProfile);
      }),
      catchError(() => {
        this.router.navigate(['/']);
        return of(false);
      }),
      switchMap((companyProfile: CompanyProfile) => {
        if (companyProfile.tracking.completedAt) {
          this.router.navigate(['/']);
          return of(false);
        } else {
          return of(true);
        }
      }),
    );
  }
}
