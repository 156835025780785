import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'glCategoryIcon'})
export class GlCategoryIconPipe implements PipeTransform {
  transform(glCategory: string, ...args: any[]): string {
    let iconName: string = 'checkbox-off';

    switch (glCategory) {
      case 'ACCOUNTING_SERVICES':
        iconName = 'accountant-report';
        break;
      case 'AUTO':
        iconName = 'car';
        break;
      case 'BANK':
        iconName = 'payment';
        break;
      case 'COMMUNICATION':
        iconName = 'phone';
        break;
      case 'ELECTRONICS':
        iconName = 'printer';
        break;
      case 'IT_SOFTWARE':
        iconName = 'it';
        break;
      case 'LEGAL_SERVICES':
        iconName = 'bank';
        break;
      case 'LOGISTICS':
        iconName = 'cubes';
        break;
      case 'MARKETING':
        iconName = 'star';
        break;
      case 'MEAL':
        iconName = 'food';
        break;
      case 'OFFICE':
        iconName = 'home';
        break;
      case 'PENALTIES':
        iconName = 'recurring-costs';
        break;
      case 'STAFF_EXPENSES':
        iconName = 'people';
        break;
      case 'TAXES_FEES':
        iconName = 'sale';
        break;
      case 'TRAVEL':
        iconName = 'plane';
        break;
      case 'UTILITIES':
        iconName = 'flash';
        break;
      case 'BUSINESS_CONSULTING':
        iconName = 'factory';
        break;
      case 'AGRICULTURE_GOODS_EQUIPMENT':
        iconName = 'agriculture';
        break;
      case 'ART_ANTIQUES':
        iconName = 'art-and-antiques';
        break;
      case 'BATHROOM_SANITARY':
        iconName = 'bathroom-and-sanitary';
        break;
      case 'BUILDING_MATERIALS_EQUIPMENTS':
        iconName = 'building-materials-and-equipment';
        break;
      case 'CHEMICAL_GOODS_EQUIPMENTS':
        iconName = 'chemical-goods-and-equipment';
        break;
      case 'CLOTHES_SHOES_ACCESSORIES':
        iconName = 'clothes-shoes-and-accessories';
        break;
      case 'FLOWERS_PLANTS':
        iconName = 'flowers-and-plants';
        break;
      case 'HEALTH_DRUGSTORE':
        iconName = 'health-and-drugstore';
        break;
      case 'HOBBY_CRAFTING':
        iconName = 'hobby-and-crafting';
        break;
      case 'JEWELRY':
        iconName = 'jewelry';
        break;
      case 'OPTICS':
        iconName = 'optics';
        break;
      case 'ANIMAL_SUPPLIES_AND_FEED':
        iconName = 'pets';
        break;
      case 'SPORT_FITNESS_OUTDOORS':
        iconName = 'sport-fitness-and-outdoors';
        break;
      case 'TOYS_KIDS_BABIES':
        iconName = 'toys-kids-and-babies';
        break;
      case 'WEAPONS_HUNTING_FISHING':
        iconName = 'weapons-hunting-and-fishing';
        break;
      case 'INSURANCE':
        iconName = 'insurance';
        break;
      case 'PRIVATE_PAYMENT_POSITION':
        iconName = 'private-payment';
        break;
      case 'CASH_WITHDRAWAL':
      case 'CASH_DEPOSIT':
        iconName = 'cash';
        break;
      case 'RESALE':
      case 'PURCHASE_OF_GOODS':
        iconName = 'purchase-order';
        break;
      case 'SALES_OF_GOODS':
        iconName = 'sales-order';
        break;
    }

    return iconName;
  }
}
