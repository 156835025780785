import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { CustomerTypeEnum, UIStatesEnum } from '../../../../models';
import { selectSubscription, selectSubscriptionCurrentState,  } from 'projects/workspace/src/app/subscription/store/selectors';
import {
  SubscriptionModel,
  SubscriptionPropertiesModel
} from 'projects/workspace/src/app/subscription/models/subscription.model';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';
// import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/subscription/store/actions/subscription.actions';

@Component({
  selector: 'rnpl-sbc-select-partner-modal',
  templateUrl: './sbc-select-partner-modal.component.html',
})
export class SbcSelectPartnerModalComponent implements OnInit {
  public form: FormGroup;
  public subscription: SubscriptionModel;
  public isReadonly: boolean = true;
  public sidebarStatus: UIStatesEnum = UIStatesEnum.VIEW;
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly fb: FormBuilder,
    private subscriptionApiService: SubscriptionApiService,
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<SbcSelectPartnerModalComponent>,
    private readonly cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.trackSubscriptionChanges();
    this.trackSubscriptionStateChanges();
  }

  private trackSubscriptionChanges(): void {
    this.store.select(selectSubscription)
      .pipe(takeUntil(this.destroy$))
      .subscribe((subscription: SubscriptionModel) => {
        this.subscription = subscription;
        this.initForm(this.subscription.properties);
        this.setFormsState();
      });
  }

  private trackSubscriptionStateChanges(): void {
    this.store.select(selectSubscriptionCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.sidebarStatus = state;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);
    }

    if (!get(this, 'subscription.properties.company.id')) {
      this.companyContactId.disable(opts);
      this.email.disable(opts);
      this.phone.disable(opts);
    }
    if (this.hasLinkedOINOpenPaidStatus) {
      this.partnerType.disable(opts);
      this.companyId.disable(opts);
    }
  }

  public initForm(properties: SubscriptionPropertiesModel = {} as SubscriptionPropertiesModel): void {
    this.form = this.fb.group({
      partnerType: [get(properties, 'partnerType'), [Validators.required]],
      companyId: [get(properties, 'company.id'), [Validators.required]],
      companyContactId: [get(properties, 'companyContact.id')],
      email: [get(properties, 'email')],
      phone: [get(properties, 'phone')],
    });

    this.isLoading$.next(false);
    this.cdr.detectChanges();

    if (this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER) {
      this.companyContactId.setValidators(Validators.required);
    }

    // this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.updateSubscriptionProperties({fieldName, fieldValue});
  }

  public updateSubscriptionProperties(field: FormInputChangedModel): void {
    field.fieldName = 'properties.' + field.fieldName;
    this.subscriptionApiService.updateSubscription(this.subscription.id, field)
      .subscribe();
  }

  public updatePartnerField(partnerData): void {
    if (partnerData.salesPriceList && partnerData.salesPriceList !== this.subscription.salesPriceList) {
      const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG);

      dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.updatePartnerFieldRequest(partnerData, true, true);
        } else if (res === CommonModalsActionsEnum.REJECT) {
          this.updatePartnerFieldRequest(partnerData, false, false);
        } else {
          this.initForm(this.subscription.properties);
        }
      });
      return;
    }

    this.updatePartnerFieldRequest(partnerData);
  }

  public updatePartnerFieldRequest(partnerData, updatePositions = false, updateDocument = false): void {
    this.subscriptionApiService.updateSubscription(
      this.subscription.id,
      {
        fieldValue: {
          type: partnerData.partnerType,
          id: partnerData.partnerId,
          updatePositions,
          updateDocument
        },
        fieldName: 'properties.' + partnerData.partnerIdFieldName
      })
      .subscribe();
  }

  get hasLinkedOINOpenPaidStatus(): boolean {
    if (!get(this, 'subscription.linkedDocuments')) { return false; }
    return get(this, 'subscription.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.OUTGOING_INVOICE &&
          (doc.status === OutgoingInvoiceListTabsEnum.OPEN || doc.status === OutgoingInvoiceListTabsEnum.PAID));
  }

  get email(): FormControl { return this.form.get('email') as FormControl; }
  get phone(): FormControl { return this.form.get('phone') as FormControl; }
  get companyId(): FormControl { return this.form.get('companyId') as FormControl; }
  get partnerType(): FormControl { return this.form.get('partnerType') as FormControl; }
  get companyContactId(): FormControl { return this.form.get('companyContactId') as FormControl; }

}
