export * from './general-settings/general-settings.component';
export * from './control-setup/control-setup.component';
export * from './localization/localization.component';
export * from './translations/translations.component';
export * from './entity-type/entity-type.component';
export * from './entity/entity.component';
export * from './forms/forms.component';
export * from './family/family.component';
export * from './confirmation/confirmation.component';

export type AttributeStep =
  'general-settings'
  | 'control-setup'
  | 'localization'
  | 'translations'
  | 'entity-type'
  | 'entity'
  | 'form'
  | 'family'
  | 'confirmation';
