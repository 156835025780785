export interface TradeProductInfoModel {
  sales_data: TradeProductSalesInfoModel,
  purchase_data: TradeProductPurchaseInfoModel
}

export interface TradeProductSalesInfoModel {
  last_sales_price: number,
  last_sales_date: Date,
  average_sales_price: number,
  price_increase: PriceChangesEnum
}

export interface TradeProductPurchaseInfoModel {
  last_purchase_price: number,
  last_purchase_date: Date,
  average_purchase_price: number,
  price_increase: PriceChangesEnum
}

export enum PriceChangesEnum {
  UP = 'up',
  DOWN = 'down'
}
