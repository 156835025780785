import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-column-with-country',
  templateUrl: './column-with-country.component.html',
  styleUrls: ['./column-with-country.component.scss']
})
export class ColumnWithCountryComponent {

  @Input() value: any;

  constructor() {
  }
}
