import { Component, Input, OnInit, Optional } from '@angular/core';


import { AttributeStep } from '../../steps';
import { AttributeForm } from '../../attribute-form.factory';
import { AttributeModel } from '../../../models';
import { mapFormToAttribute, RelationHelpers } from '../../helpers';
import { AttributeWizardComponent } from '../../attribute-wizard.component';
import { AbstractAppComponent } from '../../../../../interfaces';


@Component({
  selector: 'rnpl-attribute-summary',
  templateUrl: './attribute-summary.component.html',
  styleUrls: ['./attribute-summary.component.scss']
})
export class AttributeSummaryComponent implements OnInit {

  @Input()
  public currentStep: AttributeStep | string;

  @Input()
  public wizardForm: AttributeForm;

  public attribute: AttributeModel;

  public languages: Array<any> = [];

  public entityKeyToLabel = RelationHelpers.entityKeyToLabel;
  public formKeyToLabel = RelationHelpers.formKeyToLabel;

  constructor(@Optional() public wizard: AttributeWizardComponent,
              @Optional() public app: AbstractAppComponent) {
  }

  ngOnInit(): void {
    this.wizardForm.controlSetup.valueChanges
      .subscribe(() => {
        this.attribute = mapFormToAttribute(this.wizardForm);
      });
    if (this.wizardForm.controlSetup.dirty) {
      this.attribute = mapFormToAttribute(this.wizardForm);
    }

    if (this.wizard.availableLangs && this.wizard.availableLangs.length) {
      this.languages = this.wizard.availableLangs
        .filter(lang => {
          return this.wizardForm.translations.get('translations').value
            .find(translation => translation.code_iso3 === lang.code_iso3);
        });
    }
  }
}
