import { Pipe, PipeTransform } from '@angular/core';
import { EcoSettingsBlockTypeEnum } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/enums';

@Pipe({
  name: 'ecoCategoryNameTranslate',
})
export class EcoCategoryNameTranslatePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string, type?: EcoSettingsBlockTypeEnum): any {
    switch (type) {
      case 'BESTSELLER':
        return 'ECOMMERCE.BESTSELLERS';
      case 'NEW_ITEM':
        return 'ECOMMERCE.NEW_ITEMS';
    }

    return value;
  }
}
