import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rnpl-progress-line',
  templateUrl: 'progress-line.component.html',
  styleUrls: ['progress-line.component.scss']
})
export class ProgressLineComponent implements OnInit {

  @Input()
  public progress: number = 0;

  @Input()
  public status: string = '';

  @Input()
  public title: string = '';

  @Input()
  public color: string = 'green';

  @Input()
  public timeOver: boolean = false;

  @Input()
  public inverse: boolean = false;

  @Input()
  public icon: string;

  @Input()
  public label: string;

  constructor() { }

  ngOnInit() {
  }

}
