import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { selectCompanyProfile, selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { IncomingInvoiceApiService } from 'projects/workspace/src/app/incoming-invoice/services/invoice-api.service';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { IncomingInvoiceModel } from 'projects/workspace/src/app/incoming-invoice/models/incoming-invoice.model';
import { PurchaseOrder } from 'projects/workspace/src/app/purchase-order/models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { isEqual } from 'lodash';

@Component({
  selector: 'rnpl-link-purchase-order-modal',
  templateUrl: './link-purchase-order-modal.component.html',
  styleUrls: ['./link-purchase-order-modal.component.scss']
})
export class LinkPurchaseOrderModalComponent extends BaseModalComponent implements OnInit {

  public companyProfile: CompanyProfile;
  public purchaseOrdersList:  Partial<PurchaseOrder>[] = [];
  public selectedPurchaseOrder:  Partial<PurchaseOrder> = null;

  public customSearchFn = CustomSearchFn;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly linkDocumentRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public router: Router,
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<LinkPurchaseOrderModalComponent>,
    private purchaseOrderApiService: PurchaseOrderApiService,
    private incomingInvoiceApiService: IncomingInvoiceApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      incomingInvoiceId: number;
      partnerId: number;
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this._destroy)
      ).subscribe((profile: CompanyProfile) => {
      this.companyProfile = profile;
    });
  }

  ngOnInit() {
    this.getAvailablePurchaseOrders();

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef),
        takeUntil(this._destroy)
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  private getAvailablePurchaseOrders(): void {
    this.showDropdownSpin$.next(true);
    this.purchaseOrderApiService.getAvailableToIncomingInvoiceLinkPos(this.data.partnerId)
      .pipe(takeUntil(this._destroy))
      .subscribe((purchaseOrders: Partial<PurchaseOrder>[]) => {
        this.purchaseOrdersList = this.preparePurchaseOrderList(purchaseOrders);
        this.showDropdownSpin$.next(false);
      });
  }

  public preparePurchaseOrderList(purchaseOrders): PurchaseOrder[] {
    return purchaseOrders.map(item => {
      return {
        ...item,
        searchLabel: `${item.runpleId} ${item.vendor.name} ${item.amountGross}`
      };
    });
  }

  public link(): void {
    if (this.linkDocumentRequest$.getValue()) { return; }
    this.linkDocumentRequest$.next(true);

    this.incomingInvoiceApiService
      .linkIncomingInvoiceWithPurchaseOrder(this.data.incomingInvoiceId, this.selectedPurchaseOrder.id)
      .pipe(
        finalize(() => { this.linkDocumentRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(this.subscriberHandler);
  }

  public subscriberHandler = (response: IncomingInvoiceModel) => {
    this.dialogRef.close(response);
  };

  public get partnerUrl(): string {
    if (this.partner.type === PartnersTypeEnum.CORPORATE || this.partner.type === PartnersTypeEnum.PRIVATE) {
      return `/partners-new/${this.partner.type}/${this.partner.id}`;
    }

    return null;
  }

  public get partner() {
    return this.selectedPurchaseOrder.vendor;
  }

}
