import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../../auth/auth.service';
import { ToasterService } from '../../../ui-components/toaster';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ImageModel } from '../../../../models';
import { SignInFormNameEnum } from '../sign-in.enum';
import { BACKGROUND_CONTENT, TimeOfDay } from '../sign-in.config';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { CURRENT_VERSION, CURRENT_VERSION_NAME } from 'projects/workspace/src/app/version.constant';

@Component({
  selector: 'rnpl-sign-in-modal',
  templateUrl: 'sign-in-modal.component.html',
  styleUrls: ['./sign-in-modal.component.scss']
})
export class SignInModalComponent implements OnInit, OnDestroy {

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showPassword: boolean = false;
  public emailErrMessage: string;
  public passwordErrMessage: string;
  public otpFormErrMessage: string;
  public otpPassword: number;
  public signInForm: FormGroup;
  public otpForm: FormGroup;
  public resetPasswordForm: FormGroup;
  public btnIsClicked: boolean = false;
  public otpFormInvalid: boolean = false;
  public isTimeOver: boolean = false;
  public twoFactor: boolean = false;
  public countdown = {
    minutes: 2,
    seconds: 0,
    percents: 100
  };
  public countdownTimer;
  public userData;

  public fullSizeLogoImages: Array<ImageModel> = [];

  public activeForm: SignInFormNameEnum = SignInFormNameEnum.SignIn;

  public signInFormNameEnum = SignInFormNameEnum;

  readonly companyProfile$: BehaviorSubject<CompanyProfile> = new BehaviorSubject<CompanyProfile>(null);
  readonly checkOriginRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public backGroundContent = BACKGROUND_CONTENT;

  public timeOfDay = TimeOfDay();

  public currentVersion: string = CURRENT_VERSION;
  public currentVersionName: string = CURRENT_VERSION_NAME;

  @ViewChild('ngOtpInput', {static: true}) ngForm: any;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    containerClass: 'otp-password',
    inputClass: 'rnpl-form-control otp-password-item',
    placeholder: '',
    inputStyles: {
      'width': '40px',
      'height': '56px'
    }
  };

  constructor(
    public dialogRef: MatDialogRef<SignInModalComponent>,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private administrationsApiService: AdministrationsApiService,
    private titleService: Title,
    private toasterService: ToasterService,
    private readonly store: Store<AppState>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {authRedirectUrl: string}
  ) {}

  onOtpChange(otp) {
    this.otpPassword = otp;
    this.otpForm.get('authKey').setValue(otp);
  }

  ngOnInit() {
    this.checkOrigin();
    this.initFormSignIn();
    this.initResetPasswordForm();
    if (this.isUserAuthorized()) {
      setTimeout(() => {
        this.dialogRef.close();
      }, 0);
    }

    this.selectCompanyProfileFromStore();
  }

  public selectCompanyProfileFromStore() {
    this.store
      .select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile: CompanyProfile) => {
        this.companyProfile$.next(profile);
        this.getFullSizeLogo();
      });
  }

  public checkOrigin(): void {
    this.administrationsApiService.checkOrigin()
      .pipe(
        finalize(() => this.checkOriginRequest$.next(true)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.activeForm = SignInFormNameEnum.SignIn;
      }, (error) => {
        if (error.error.errors.includes('workspaceNotReady')) {
          this.activeForm = SignInFormNameEnum.WorkspaceNotActivated;
          return;
        }
        if (window.location.origin.includes('localhost:4200')) {
          this.activeForm = SignInFormNameEnum.SignIn;
          return;
        }
      });
  }

  public resendAuthorizationEmail(): void {
    this.administrationsApiService.resendConfirmationEmail()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.activeForm = SignInFormNameEnum.CheckInboxActivation;
      });
  }

  public isUserAuthorized() {
    return this.authService.isUserAuthorized();
  }

  /**
   * Displays/hides password symbols
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Sets error message for invalid field
   *
   * @param controlName string
   * @param event MouseEvent
   */
  public setError(controlName: string, event?: MouseEvent): void {
    if (event && !(event.target as HTMLInputElement).value) {
      return;
    }

    const control = this.signInForm.get(controlName);

    if (control.invalid) {
      switch (controlName) {
        case 'email':
          if (this.btnIsClicked && !control.value) {
            this.emailErrMessage = 'SIGN_IN.EMAIL_CANNOT_BE_BLANK';
          } else {
            this.emailErrMessage = 'SIGN_IN.ENTER_VALID_EMAIL';
          }
          break;
        case 'password':
          if (this.btnIsClicked && !control.value) {
            this.passwordErrMessage = 'SIGN_IN.PASSWORD_CANNOT_BE_BLANK';
          } else {
            this.passwordErrMessage = 'Please enter a valid password.';
          }
          break;
        default:
      }
    }
  }

  /**
   * Invokes authorization method of AuthService
   */
  public signIn(): void {
    this.btnIsClicked = true;

    if (this.signInForm.invalid) {
      this.setError('email');
      this.setError('password');

      if (this.signInForm.invalid && this.signInForm.get('email').value && this.signInForm.get('password').value) {
        this.toasterService.notify({
          type: 'error',
          message: 'TOASTER.WRONG_EMAIL_OR_PASSWORD',
          soundOff: true,
        });
      }

      return;
    }

    this.authService.signIn(
      this.signInForm.get('email').value,
      this.signInForm.get('password').value
    )
      .subscribe((response: any) => {
        if (response.data.two_factor_code && response.data.user) {
          this.userData = {
            ...this.signInForm.getRawValue(),
            data: response.data
          };

          this.activeForm = SignInFormNameEnum.TwoFactor;

          this.initTwoFactorForm();
          this.startTimer();

        } else {
          this.isLoading$.next(true);
          setTimeout(() => { // show loading animation then redirect
            this.router.navigate([this.data.authRedirectUrl ? this.data.authRedirectUrl : '/']);
            this.dialogRef.close();
          }, 1200);
        }
      }, () => {
        this.isLoading$.next(false);
        this.passwordErrMessage = 'TOASTER.WRONG_EMAIL_OR_PASSWORD';
      });
  }

  /**
   * Initializes sign in form
   */
  private initFormSignIn(): void {
    this.signInForm = this.fb.group({
      'email': [null, [Validators.required, Validators.email]],
      'password': [null, [Validators.required, /* todo: add password validators */]]
    });

    this.signInForm.get('email').valueChanges
      .subscribe(() => {
        this.emailErrMessage = '';
      });

    this.signInForm.get('password').valueChanges
      .subscribe(() => {
        this.passwordErrMessage = '';
      });
  }

  /**
   * Initializes reset password form
   */
  public initResetPasswordForm(): void {
    this.resetPasswordForm = this.fb.group({
      'email': [this.signInForm.get('email').value, { validators: [Validators.required, Validators.email], updateOn: 'blur' }],
    });
  }

  public resetPassword(): void {
    this.resetPasswordForm.markAllAsTouched();
    this.resetPasswordForm.updateValueAndValidity();

    if (!this.resetPasswordForm.valid) {
      return;
    }

    this.authService.resetPassword(this.resetPasswordEmailControl.value)
      .subscribe(() => {
        this.activeForm = SignInFormNameEnum.CheckInbox;
      });
  }

  /**
   * Initializes two factor form
   */
  private initTwoFactorForm(): void {
    setTimeout(() => {
      this.twoFactor = true;
    }, 300);

    this.otpForm = this.fb.group({
      userId: [this.userData.data.user, []],
      authKey: [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern(/^\d+$/)
      ]]
    });

    this.otpForm.get('authKey').valueChanges
      .subscribe(() => {
        this.otpFormErrMessage = '';
      });
  }

  public signInTwoFactor(): void {
    if (this.otpForm.invalid) {
      this.otpFormInvalid = true;
      this.setErrorTwoFactor();
      return;
    } else {
      this.otpFormInvalid = false;
    }

    this.authService.authorize(
      this.userData.data.user,
      this.otpPassword
    )
      .subscribe(() => {
        this.router.navigate(['/']);
        this.dialogRef.close();
      }, () => {
        this.otpFormInvalid = true;
        this.toasterService.notify({
          type: 'warning',
          message: 'Wrong authorization key.',
          soundOff: true,
        });
      });
  }

  private setErrorTwoFactor(): void {
    if (this.ngForm) {
      const control = this.ngForm;
      if (control.valid) {
        return;
      }
    }

    if (!this.otpPassword) {
      this.otpFormErrMessage = 'Authorization key cannot be blank.';
    } else {
      this.otpFormErrMessage = 'Authorization key should contains 6 digits.';
    }
  }

  public startTimer(): void {

    this.countdownTimer = setInterval(() => {
      this.countdown.minutes = Math.floor((this.userData.data.ttl / 1000 / 60) << 0);
      this.countdown.seconds = Math.floor((this.userData.data.ttl / 1000) % 60);
      this.countdown.percents = (this.userData.data.ttl / 120000 ) * 100;

      this.userData.data.ttl -= 1000;

      if (this.userData.data.ttl < 0) {
        clearInterval(this.countdownTimer);
        this.isTimeOver = true;
      }
    }, 1000);
  }

  public resendCode(): void {
    this.activeForm = SignInFormNameEnum.CheckInbox;

    this.authService.signIn(
      this.userData.email,
      this.userData.password
    ).subscribe((response: any) => {
      this.userData.data = response.data;
      clearInterval(this.countdownTimer);
      this.startTimer();
      this.isTimeOver = false;
    });
  }

  /**
   * shows forms
   */

  public hideTwoFactorForm(): void {
    this.activeForm = SignInFormNameEnum.SignIn;

    clearInterval(this.countdownTimer);
    this.isTimeOver = false;
    this.otpFormInvalid = false;
    setTimeout(() => {
      this.twoFactor = false;
    }, 300);
  }

  public getFullSizeLogo() {
    this.fullSizeLogoImages = [this.companyProfile.printLogo];
  }

  public displayResetPasswordForm(): void {
    this.activeForm = SignInFormNameEnum.ResetPassword;
    this.titleService.setTitle(`Runple. ${this.translate.instant('SIGN_IN.RESET_PASSWORD')}`);
    if (this.signInForm.get('email').value) {
      this.resetPasswordEmailControl.patchValue(this.signInForm.get('email').value, { emitEvent: false });
    }
  }

  public backToSignIn(): void {
    this.titleService.setTitle(`Runple. ${this.translate.instant('SIGN_IN.SIGN_IN')}`);
    this.activeForm = this.signInFormNameEnum.SignIn;
    this.emailErrMessage = null;
  }

  public getUserFirstName(): string {
    const firstName = localStorage.getItem('userFirstName');
    return firstName ? ` ${firstName}` : '';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get logoName(): string {
    return window.location.host.includes('it-work.runple.app')
      ? 'it-work_logo_2020'
      : 'runple-logo-dark';
  }

  get companyProfile() { return this.companyProfile$.getValue(); }

  get resetPasswordEmailControl(): FormControl { return this.resetPasswordForm.get('email') as FormControl; }

}
