import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { getHeadNavigationMenuItems, getSalesChannelList, NavItem, SalesChannelEnum } from './nav-items.config';
import { UserPermissionsService } from '../../../services/user-permissions.service';
import { SystemPreferencesPermissionsService } from '../../../services/system-preferences-permissions.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesChannelService } from '../../../services/sales-channel.service';
import { HrmService } from '../../../../../projects/workspace/src/app/hrm/hrm.service';
import { AuthService } from '../../../auth/auth.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';

// todo: check usage, remove
@Component({
  selector: 'rnpl-head-navigation-process-based',
  templateUrl: './head-navigation-process-based.component.html',
  styleUrls: [
    '../head-navigation/head-navigation.component.scss',
    '../nav-menu-items/nav-menu-items.component.scss'
  ]
})
export class HeadNavigationProcessBasedComponent implements OnChanges, OnInit, OnDestroy {

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public menuData$: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);

  public activeSalesChannel: SalesChannelEnum = SalesChannelEnum.TRADE;

  public userId: number;

  public menuTitleBySalesChannel = {
    [SalesChannelEnum.TRADE]: {
      title: 'MODULE.TRADE',
      iconName: 'cash'
    },
    [SalesChannelEnum.SERVICES]: {
      title: 'MODULE.SERVICES',
      iconName: 'checklist'
    },
    [SalesChannelEnum.E_COMMERCE]: {
      title: 'MODULE.E_COMMERCE',
      iconName: 'shopping-cart'
    },
    [SalesChannelEnum.ALL_CHANNEL]: {
      title: 'MODULE.ALL_CHANNELS',
      iconName: 'grid'
    },
  };

  @Input() public salesChannels: NavItem[] = [];
  @Input() public appNav: boolean;

  @Output()
  openMenu = new EventEmitter();

  constructor(
    public router: Router,
    public store: Store<AppState>,
    private userPermissionsService: UserPermissionsService,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
    private salesChannelService: SalesChannelService,
    private hrmService: HrmService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.userId = this.authService.getUser().id;
    this.hrmService.getUserProfileAndBlueprintsV2(this.userId, false, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.activeSalesChannel = user.preferences.navigation.salesChannel;
        this.salesChannelService.setSalesChannel(user.preferences.navigation.salesChannel);
        // if (
        //   this.systemPreferencesPermissionsService.wholesaleEnabled() &&
        //   this.systemPreferencesPermissionsService.servicesEnabled()
        // ) {
        //   this.activeSalesChannel = user.preferences.navigation.salesChannel;
        //   this.salesChannelService.setSalesChannel(user.preferences.navigation.salesChannel);
        // } else if (this.systemPreferencesPermissionsService.wholesaleEnabled()) {
        //   this.changeSalesChannel(SalesChannelEnum.TRADE);
        // } else if (this.systemPreferencesPermissionsService.servicesEnabled()) {
        //   this.changeSalesChannel(SalesChannelEnum.SERVICES);
        // }

        this.getMenuData();
      });

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.appNav) {
          this.getMenuData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('appNav')) {
      if (!this.appNav) {
        this.getMenuData();
      }
    }
  }

  public getMenuData(): void {
    if (this.activeSalesChannel === SalesChannelEnum.SERVICES && !this.systemPreferencesPermissionsService.servicesEnabled()) {
      this.activeSalesChannel = SalesChannelEnum.TRADE;
    }
    this.menuData$.next(getHeadNavigationMenuItems(
      this.activeSalesChannel,
      this.userPermissionsService.canViewAccounting(),
      this.userPermissionsService.canViewAdministration(),
      this.userPermissionsService.canViewAnalytics(),
      this.userPermissionsService.canViewEcommerce(),
      this.userPermissionsService.canViewPartners(),
      this.userPermissionsService.canViewProducts(),
      this.userPermissionsService.canViewRetail(),
      this.userPermissionsService.canViewServices(),
      this.userPermissionsService.canViewTeam(),
      this.userPermissionsService.canViewWarehouse(),
      this.userPermissionsService.canViewWholesale(),
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
      this.systemPreferencesPermissionsService.servicesProductTypeEnabled(),
      this.systemPreferencesPermissionsService.digitalProductTypeEnabled(),
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
    ));

    this.salesChannels = getSalesChannelList(
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      );
  }

  public changeSalesChannel(channel): void {
    if (!!channel) {
      this.hrmService.updateUserProfile(
        this.userId,
        {
          fieldName: 'preferences.navigation.salesChannel',
          fieldValue: channel
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe((user) => {
          this.activeSalesChannel = user.preferences.navigation.salesChannel;
          this.salesChannelService.setSalesChannel(user.preferences.navigation.salesChannel);
          this.getMenuData();
        });
    }
  }

  public openNavBar() {
    this.openMenu.emit(true);

    const overlay = document.querySelector<HTMLElement>('.cdk-overlay-container');
    overlay.style.zIndex = '1200';
  }

  public showItem(item: NavItem): boolean {
    if (item.hasOwnProperty('show')) {
      return item.show;
    }

    return true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
