import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ResponseList, ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { DisplayToaster } from 'projects/workspace/src/app/shared/decorators/toaster';
import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BarcodeStatusEnum } from './barcode-status.enum';
import { BarcodeModel } from './barcode.model';

@Injectable({
  providedIn: 'root'
})
export class BarcodeApiService {
  private readonly apiEndpoint: string = `${environment.javaCommonsApiVersion}/barcodes`;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
  ) { }

  @DisplayToaster({showErrorMessage: true})
  public getBarcodesList(
    status: BarcodeStatusEnum,
    page: string = '1',
    per_page: string = '1000',
  ): Observable<ResponseList<BarcodeModel>> {
    const params = {
      status,
      page,
      size: per_page
    };
    return this.http.get<ResponseList<BarcodeModel>>(this.apiUrl(), { params });
  }

  @DisplayToaster({showErrorMessage: true})
  public copyBarcodeItem(barcodeId: number, copied: boolean): Observable<BarcodeModel> {
    const params = {
      copied: copied as any
    };
    return this.http.put<ResponseModel<BarcodeModel>>(this.apiUrl(`/${barcodeId}/copied`), null, { params })
      .pipe(map((response: ResponseModel<BarcodeModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public changeBarcodeItemStatus(barcodeId: number, status: BarcodeStatusEnum): Observable<BarcodeModel> {
    const body = { status };
    return this.http.patch<ResponseModel<BarcodeModel>>(this.apiUrl(`/${barcodeId}/status`), body)
      .pipe(map((response: ResponseModel<BarcodeModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public changeBarcodesBatchStatus(status: BarcodeStatusEnum, ids: number[]): Observable<BarcodeModel> {
    const body = { ids, status };
    return this.http.patch<ResponseModel<BarcodeModel>>(this.apiUrl(`/status/multiple`), body)
      .pipe(map((response: ResponseModel<BarcodeModel>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteBarcodeItemPermanently(barcodeId: number): Observable<any> {
    return this.http.delete(this.apiUrl(`/${barcodeId}`));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteAllBarcodesPermanently(): Observable<any> {
    return this.http.delete(this.apiUrl('/all'));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
