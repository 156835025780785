import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';


import { WizardStep } from '../../../../wizard/wizard-step';
import { AssignedAttributeModel } from '../../../models';


@Component({
  selector: 'rnpl-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends WizardStep implements OnChanges {

  @Input()
  formLabel: string;

  @Input()
  assignedAttributes: Array<AssignedAttributeModel> = [];

  grid: Array<Array<any>> = [[]];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('assignedAttributes')) {
      this.grid = this.assignedAttributes.reduce((grid, attribute, index) => {

        const lastRow = grid.pop();

        let attrClass;
        switch (attribute.layout.size) {
          case 'small':
            attrClass = 'col-md-4';
            break;

          case 'wide':
            attrClass = 'col-md-12';
            break;

          default:
            attrClass = 'col-md-6';
        }

        lastRow.push({
          attribute: attribute.attribute,
          class: attrClass
        });

        grid.push(lastRow);

        if (attribute.layout.isLast && index < this.assignedAttributes.length - 1) {
          grid.push([]);
        }

        return grid;
      }, [[]]);
    }
  }

}
