import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ShepherdService } from 'angular-shepherd';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { get } from 'lodash';
import { Store } from '@ngrx/store';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { IncomingInvoiceApiService } from 'projects/workspace/src/app/incoming-invoice/services/invoice-api.service';
import {
  IINPositionsModel,
  IncomingInvoicePositionModel
} from 'projects/workspace/src/app/incoming-invoice/models/incoming-invoice-position.model';
import { CompositeProductModalComponent } from '../../modals-products';
import { CustomSearchFn, getProductSearchValue, getProductSearchValueCamel } from 'common/src/modules/rnpl-common/helpers';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import {
  CORRECTION_POSITION_TYPES_LIST,
  CURRENCIES_SYMBOLS_BY_ISO_CODE
} from 'projects/workspace/src/app/accounting/accounting.constants';
import { PurposeTypeEnum } from 'projects/workspace/src/app/purchase-order/enums';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA } from '../../modals.contsans';
import {
  PositionAccountingSettingsComponent
} from 'projects/workspace/src/app/shared/components/position-accounting-settings/position-accounting-settings.component';
import { PositionAccountingSettings } from 'projects/workspace/src/app/outgoing-invoice/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';

@Component({
  selector: 'rnpl-incoming-invoice-position-modal',
  templateUrl: './incoming-invoice-position-modal.component.html',
  styleUrls: ['./incoming-invoice-position-modal.component.scss']
})
export class IncomingInvoicePositionModalComponent extends BaseModalComponent implements OnInit {

  public form: FormGroup;
  public companyProfile: CompanyProfile;
  public accountingSettings: PositionAccountingSettings;

  public vatList = VAT_LIST;
  public productType = PRODUCTS_TYPE;
  public correctionPositionTypesList = CORRECTION_POSITION_TYPES_LIST;
  public currencySymbolByIsoCode = CURRENCIES_SYMBOLS_BY_ISO_CODE;
  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public previousUnitType: string;

  public currentCurrency: string = 'EUR';
  public productTypeRadio: 'common'|'general' = 'common';
  public previousDropdownPosition: 'bottom'|'top'|'auto' = null;

  public productTypes = ProductTypes;
  public purposeType = PurposeTypeEnum;

  public addProductsNotFromPurchaseOrder: boolean = false;

  public selectedProductInvalid: boolean = false;
  public productNameInvalid: boolean = false;

  public productsList: ProductModel[] = [];
  public productsListAllTypes: ProductModel[] = [];

  public selectedProduct: ProductModel = null;
  public positionName: string = null;
  public selectedProductType: ProductTypes|string = ProductTypes.GOODS;
  public selectedGeneralCategory: any = null;

  public customSearchFn = CustomSearchFn;

  readonly generalCategoriesList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly generalProductsList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly addPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @ViewChild('positionAccountingSettings', { static: false }) positionAccountingSettingsRef: PositionAccountingSettingsComponent;


  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    private productsService: ProductsService,
    private incomingInvoiceApiService: IncomingInvoiceApiService,
    public dialogRef: MatDialogRef<IncomingInvoicePositionModalComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public shepherdService: ShepherdService,
    private recalculatePositionApiService: RecalculatePositionApiService,
    private productUnitApiService: ProductUnitApiService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {
      invoiceId: number,
      hasLinkedPO: boolean,
      invoiceMayContainProductsNotFromOrder: boolean,
      vatBlocked: boolean,
      disabledAccountingSettings: boolean,
      currentCurrency: string,
    }
  ) {
    super(toasterService);
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.initForm(this.selectedProduct);
    this.getProducts();
    this.getGeneralProductsListDropdown();

    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
    }
  }

  private getGeneralProductsListDropdown(): void {
    this.productsService.getGeneralProductsListDropdown()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        const filteredCategories = response
          .map(category => ({
            ...category,
            children: category.children.filter(c => c.standaloneAllowed)
          }))
          .filter(c => c.children && c.children.length)
          .filter(c => c.isVisibleForList);
        this.generalCategoriesList$.next(filteredCategories);
      });
  }

  public clearProductsList(): void {
    this.generalProductsList$.next([]);
  }

  public selectCategory(category): void {
    this.selectedGeneralCategory = category;
    this.generalProductsList$.next(category.children);
  }

  public fixDropdownPosition(productType: 'common'|'general', dropdownPosition: 'bottom'|'top'|'auto'): void {
    if (productType === 'general') {
      this.previousDropdownPosition = dropdownPosition;
    } else {
      this.previousDropdownPosition = null;
    }
  }

  private initForm(selectProduct: ProductModel = {} as ProductModel, fromDocument: boolean = false) {
    this.form = this.fb.group({
      grossTotalPrice: [fromDocument ? this.setCurrencyValue(get(selectProduct, 'grossTotalPrice')) : null, Validators.required],
      grossUnitPrice: [fromDocument ? this.setCurrencyValue(get(selectProduct, 'grossUnitPrice')) : null, Validators.required],
      netTotalPrice: [fromDocument ? this.setCurrencyValue(get(selectProduct, 'netTotalPrice')) : null, Validators.required],
      netUnitPrice: [fromDocument ? this.setCurrencyValue(get(selectProduct, 'netUnitPrice')) : null, Validators.required],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [get(selectProduct, 'description')],
      description: [get(selectProduct, 'name')],
      vat: [fromDocument ? get(selectProduct, 'vat') : 20],
      delivered: [get(selectProduct, 'delivered')],
      providedFrom: [get(selectProduct, 'providedFrom')],
      providedTo: [get(selectProduct, 'providedTo')],
      quantity: [fromDocument ? get(selectProduct, 'available') : 1],
      remark: [],
      addRemark: [false],
      productType: [get(selectProduct, 'productType')],
      unitType: [get(selectProduct, 'unitType', 'pcs')],
      discountAmountNet: [fromDocument ? get(selectProduct, 'discountAmountNet') : null],
      discountAmountGross: [fromDocument ? get(selectProduct, 'discountAmountGross') : null],
      discount: [fromDocument ? get(selectProduct, 'discount') : null],
      addDiscount: [fromDocument ? get(selectProduct, 'addDiscount') : false],
      purpose: [(this.form && this.purpose && this.purpose.value) || PurposeTypeEnum.INTERNAL_USAGE, {updateOn: 'change'}],
      correctionType: [],
      vatTotal: [],
      specifyAmountsSeparately: [],
      productDescriptionUpdated: [false]
    });

    this.patchPurposeControl();
  }

  private patchPurposeControl(): void {
    // we cant select purpose for services or correction positions
    if (this.form && this.selectedProduct && (this.isServicesProductType || this.isCorrectionProductType || this.isGeneralServiceType)) {
      this.purpose.setValue(null);
      this.purpose.disable();
    } else {
      this.cdr.detectChanges();
      this.purpose.enable();
    }
  }

  selectPositionChanges(selectedProduct: ProductModel): void {
    this.selectedProductType = selectedProduct.type || selectedProduct.productType;
    if (this.productTypeRadio === 'general') {
      this.selectedProductType = ProductTypes.GENERAL;
    }
    this.selectedProduct = selectedProduct;
    this.currentCurrency = this.data.currentCurrency;
    this.productTypeControl.setValue(selectedProduct.type);
    this.accountingSettings = null;
    if (this.positionAccountingSettingsRef) {
      this.positionAccountingSettingsRef.resetForm();
    }

    if (selectedProduct.accountingSettings) {
      this.accountingSettings = selectedProduct.accountingSettings;
      this.accountingSettings.productTypeId = selectedProduct.accountingSettings.glProductTypeId;
    }

    if (this.data.vatBlocked) {
      this.vat.disable({onlySelf: true, emitEvent: false});
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
    }

    if (this.data.hasLinkedPO && !this.addProductsNotFromPurchaseOrder) {
      this.initForm(this.selectedProduct, true);
      // init position predefined data
      // wait for this.positionAccountingSettingsRef to render
      setTimeout(() => this.updatePosition(this.purpose.value, 'purpose'), 200);
    } else {
      this.getProductById(+selectedProduct.id);
    }

    this.getProductUnits();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.selectedProduct.type || this.selectedProduct.entityForm)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        this.unitsList = unitsList.map(u => u.name);
      });
  }

  public getProductById(productId) {
    this.productsService.getProduct(productId)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.initForm(product);
        this.accountingSettings = product.accountingSettings;
        if (this.data.vatBlocked) {
          this.vat.setValue(0);
        }

        // init position predefined data
        // wait for this.positionAccountingSettingsRef to render
        setTimeout(() => this.updatePosition(this.purpose.value, 'purpose'), 200);
      });
  }

  private getProducts(): void {
    const activeProductsRequest$ = this.productsService
      .getActiveProducts()
      .pipe(tap((products: ProductModel[]) => this.productsListAllTypes = this.prepareProductsList(products)));

    const availableProductsRequest$ = this.incomingInvoiceApiService
      .getAvailablePositionsFromPO(this.data.invoiceId, this.companyProfile.workspaceId)
      .pipe(tap((products: ProductModel[]) => this.productsList = this.prepareProductsList(products)));

    forkJoin([
      activeProductsRequest$,
      availableProductsRequest$
    ])
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this.showDropdownSpin$.next(false));
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService),
      searchLabelCamel: getProductSearchValueCamel(item, this.translateService)
    }));
  }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((product: ProductModel) => {
      if (product) {
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
        this.selectPositionChanges(product);
      }
    });
  }

  public addPosition(): void {
    let description: string = null;

    if (this.selectedProductType === ProductTypes.CORRECTION) {
        if (!this.description.valid) {
        this.productNameInvalid = true;
        return;
      }
    } else {
      if (!this.selectedProduct) {
        this.selectedProductInvalid = true;
        return;
      }
    }

    if (this.addPositionRequest$.getValue()) { return; }
    this.addPositionRequest$.next(true);

    const selectedNotCorrection = this.selectedProduct && this.selectedProductType !== ProductTypes.CORRECTION;

    if (get(this.selectedProduct, 'logistics')) {
      description = this.selectedProduct.description;
    }

    const position: Partial<IncomingInvoicePositionModel> = {
      ...this.form.getRawValue(),
      productTypeForGrouping: this.selectedProductType,
      manufacturerCode: get(this.selectedProduct, 'manufacturer_code') || get(this.selectedProduct, 'manufacturerCode'),
      productId: selectedNotCorrection
        ? +this.selectedProduct.id
        : null,
      runpleId: selectedNotCorrection
        ? (this.selectedProduct.runpleId || this.selectedProduct.runple_id)
        : null,
      unitType: selectedNotCorrection
        ? this.unitType.value
        : 'pcs',
      type: this.selectedProductType,
      notFromBaseDocument: !this.data.hasLinkedPO || this.addProductsNotFromPurchaseOrder,
      logistics: !!this.selectedProduct.logistics,
      productDescription: !this.isServicesProductType && !this.isGeneralServiceType
        ? this.productDescription.value
        : description,
      description: !this.isGeneralServiceType && !this.isCorrectionProductType
        ? get(this.selectedProduct, 'name')
        : this.description.value,
      providedServices: this.isServicesProductType || this.isGeneralServiceType
        ? this.providedServices.value
        : null,
      documentId: this.data.invoiceId,
      accountingSettings: {
        ...this.accountingSettings,
        ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue())
      }
    };

    this.incomingInvoiceApiService.createPositionByPredefinedForm(this.data.invoiceId, position)
      .pipe(finalize(() => this.addPositionRequest$.next(false)))
      .subscribe((response: IINPositionsModel) => this.dialogRef.close(response));
  }

  public displayCorrectionPosition(): void {
    this.selectedProductType = ProductTypes.CORRECTION;
    this.selectedProduct = { name: this.translateService.instant('FORM.CORRECTION') };
    this.positionName = null;
    this.currentCurrency = this.data.currentCurrency;
    this.initForm();
    this.productTypeControl.setValue(ProductTypes.CORRECTION);
    this.correctionType.setValue('NEGATIVE');
    if (this.data.vatBlocked) {
      this.vatTotal.disable({onlySelf: true, emitEvent: false});
      this.vat.setValue(0);
    }

    // init position predefined data
    // wait for this.positionAccountingSettingsRef to render
    setTimeout(() => this.updatePosition(this.purpose.value, 'purpose'), 200);
  }

  public purposeChanged(): void {
    if (this.purpose.value === PurposeTypeEnum.RESALE) {
      // set to 'common' to hide general products if this.productTypeRadio === 'general'
      this.productTypeRadio = 'common';
    }

    // if (this.selectedProduct) {
      // // reset form if product already selected
      // this.selectedProductType = ProductTypes.GOODS;
      // this.selectedProduct = null;
      // this.positionName = null;
      // this.currentCurrency = this.data.currentCurrency;
      // this.initForm();
    // }

    // updatePositionData
    this.updatePosition(this.purpose.value || PurposeTypeEnum.INTERNAL_USAGE, 'purpose');
  }

  public updatePosition(value, fieldName, force: boolean = false): void {
    if (!this.selectedProduct) { return; }

    this.addPositionRequest$.next(true);

    let description: string = null;

    switch (fieldName) {
      case 'delivered':
      case 'providedFrom':
      case 'providedTo':
        value = this.datePipe.transform(value, 'yyyy-MM-dd');
        break;
    }

    if (get(this.selectedProduct, 'logistics')) {
      description = this.selectedProduct.description;
    }

    if (fieldName === 'specifyAmountsSeparately' && !value && !force) {
      if (
        (this.netTotalPrice.value && (this.netTotalPrice.value !== '0.00')) ||
        (this.grossTotalPrice.value && (this.grossTotalPrice.value !== '0.00')) ||
        (this.vatTotal.value && (this.vatTotal.value !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(value, fieldName, true);
          } else {
            this.specifyAmountsSeparately.setValue(true);
          }
        });
        return;
      }
    }

    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.IIN, {
      protoPosition: {
        ...this.form.getRawValue(),
        purpose: this.purpose.value || PurposeTypeEnum.INTERNAL_USAGE,
        productId: this.selectedProduct.id,
        productTypeForGrouping: this.selectedProductType,
        productDescription: !this.isServicesProductType && !this.isGeneralServiceType
          ? this.productDescription.value
          : description,
        providedServices: this.isServicesProductType || this.isGeneralServiceType
          ? this.providedServices.value
          : null,
        description: this.isCorrectionProductType
          ? this.form.get('description').value
          : get(this.selectedProduct, 'name'),
        notFromBaseDocument: !this.data.hasLinkedPO || this.addProductsNotFromPurchaseOrder,
        logistics: !!this.selectedProduct.logistics,
        accountingSettings: {
          ...this.accountingSettings,
          ...(this.positionAccountingSettingsRef && this.positionAccountingSettingsRef.getFormValue())
        }
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType,
      documentId: this.data.invoiceId,
    })
      .pipe(finalize(() => this.addPositionRequest$.next(false)),)
      .subscribe(product => {
        this.form.patchValue(product);
        this.previousUnitType = product.unitType;
        this.patchPurposeControl();
        if (product.accountingSettings) {
          this.accountingSettings = product.accountingSettings;
        }
      }, () => {
        if (fieldName === 'unitType') {
          // set previous value of unit type in case of error
          this.unitType.patchValue(this.previousUnitType);
          // redraw quantity control view
          setTimeout(() => this.quantity.patchValue(this.quantity.value), 10);
        }
      });
  }

  public setCurrencyValue(value): number {
    if (!value) { return value; }
    return +(value / 1e2).toFixed(2);
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.isServicesProductType) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.productDescription.value, 'productDescription');
    }
  }

  get isServicesProductType(): boolean {
   return this.selectedProductType === ProductTypes.SERVICES;
  }
  get isCorrectionProductType(): boolean {
   return this.selectedProductType === ProductTypes.CORRECTION;
  }
  get isGoodsProductType(): boolean {
   return this.selectedProductType === ProductTypes.GOODS;
  }
  get isGeneralProductType(): boolean {
    return this.selectedProductType === ProductTypes.GENERAL;
  }

  get isDigitalProductType(): boolean {
    return this.selectedProductType === ProductTypes.DIGITAL;
  }
  get isGeneralServiceType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.isGeneralProductType &&  this.selectedProduct.entityForm === ProductTypes.SERVICES;
  }

  get isGeneralDigitalType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.isGeneralProductType && this.selectedProduct.entityForm === ProductTypes.DIGITAL;
  }
  get isGeneralGoodsType(): boolean {
    if (!this.selectedProduct) { return false; }
    return this.isGeneralProductType &&  this.selectedProduct.entityForm === ProductTypes.GOODS;
  }
  get isResale(): boolean {
    return this.purpose.value === PurposeTypeEnum.RESALE;
  }
  get isInternalUsage(): boolean {
    return this.purpose.value === PurposeTypeEnum.INTERNAL_USAGE;
  }
  get isLogistics(): boolean {
    return this.selectedProduct.logistics;
  }

  get allowFractionalValues(): boolean {
    if (!this.unitType.value || !this.unitsListSource.length) { return true; }
    return this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.unitType.value)
      .allowFractionalValues;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get specifyRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get providedFrom(): FormControl { return this.form.get('providedFrom') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get description(): FormControl { return this.form.get('description') as FormControl; }
  get productDescription(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get providedTo(): FormControl { return this.form.get('providedTo') as FormControl; }
  get delivered(): FormControl { return this.form.get('delivered') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get addDiscount(): FormControl { return this.form.get('addDiscount') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }
  get discountAmountGross(): FormControl { return this.form.get('discountAmountGross') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get productTypeControl(): FormControl { return this.form.get('productType') as FormControl; }
  get unitType(): FormControl { return this.form.get('unitType') as FormControl; }
  get purpose(): FormControl { return this.form.get('purpose') as FormControl; }
  get vatTotal(): FormControl { return this.form.get('vatTotal') as FormControl; }
  get specifyAmountsSeparately(): FormControl { return this.form.get('specifyAmountsSeparately') as FormControl; }
  get correctionType(): FormControl { return this.form.get('correctionType') as FormControl; }

}
