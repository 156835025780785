import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function DateMoreThanOrEqualsValidator(compareDate = new Date()): ValidatorFn {
  return function DateLessThanOrEqualsValidate(control: AbstractControl): ValidationErrors {
    if (!control.parent) {
      return null;
    }

    const date1 = control.value;
    if (date1 !== null && compareDate !== null && date1 > compareDate) {
      return {
        'date_less_than_or_equal': true
      };
    }
    return null;
  };
}
