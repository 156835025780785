import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyModule } from 'ngx-currency-custom';

import { RnplCommonModule } from 'common/src/modules/rnpl-common';
import { UIComponentsModule } from 'common/src/modules';
import { OverdueNoticeFormComponent } from './overdue-notice-form.component';
import { OverdueNoticeDueDaysFormModule } from '../overdue-notice-due-days-form/overdue-notice-due-days-form.module';

@NgModule({
  declarations: [
    OverdueNoticeFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    UIComponentsModule,
    RnplCommonModule,
    TranslateModule,
    OverdueNoticeDueDaysFormModule,
    NgxCurrencyModule
  ],
  exports: [OverdueNoticeFormComponent],
  entryComponents: [OverdueNoticeFormComponent],
  providers: []
})
export class OverdueNoticeFormModule { }
