import { Component, Input, OnInit } from '@angular/core';


import { WizardStep } from '../../../../wizard/wizard-step';
import { SystemSettingsService } from '../../../system-settings.service';


@Component({
  selector: 'rnpl-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent extends WizardStep implements OnInit {

  @Input()
  public availableLangs: Array<any> = [];

  public currentLang: any;
  public currentTranslation: any;

  public defaultDisplayingLang: any = {
    country: 'United Kingdom',
    lang: 'English'
  };

  public options: Array<any> = [];

  private defaultLang: string = 'eng';

  public languages: Array<any> = [];

  constructor(private systemSettingsService: SystemSettingsService) {
    super();
  }

  ngOnInit(): void {

    this.options = this.wizardForm.controlSetup.get('options').value;

    const availLangs = this.availableLangs.map(language => language.code_iso3);

    this.languages = this.wizardForm.localization.get('localizations').value.reduce((langList, country) => {

      const countryLangs = country.related_languages
        .filter(lang => lang.language.code_iso3 !== this.defaultLang)
        .map(lang => {
          return {
            ...lang.language,
            icon: lang.language.code_iso3
          };
        })
        .filter(lang => !langList.find(listItem => availLangs.includes(listItem.code_iso3) && listItem.code_iso3 === lang.code_iso3));

      return [...langList, ...countryLangs];
    }, []);

    if (this.languages.length) {
      this.currentLang = this.languages[0];
      this.setCurrentTranslation();
    }

    this.preFillForm();
  }

  public getIndexOfCurrent(): number {
    return this.languages.findIndex(lang => lang === this.currentLang) + 1;
  }

  public setCurrentTranslation(): void {
    this.currentTranslation = this.form.get('translations').value
      .find(translation => translation.code_iso3 === this.currentLang.code_iso3);
  }

  public setTranslation(event): void {
    const translations = this.form.get('translations').value;

    const currentTranslation = translations.find(translation => translation.code_iso3 === this.currentLang.code_iso3);
    currentTranslation.value = event.target.value;

    this.form.get('translations').setValue(translations);
  }

  public saveOptions(): void {
    this.wizardForm.controlSetup.get('options').setValue(this.options);
    this.form.get('translations').updateValueAndValidity();
  }

  private preFillForm(): void {
    const translations = this.form.get('translations').value
      .reduce((resultTranslations, currTranslation) => {
        resultTranslations[currTranslation.code_iso3] = currTranslation.value;
        return resultTranslations;
      }, {});

    if (!translations[this.defaultLang]) {
      translations[this.defaultLang] = this.wizardForm.generalSettings.get('name').value;
    }

    const langKeys = this.languages.map(lang => lang.code_iso3);
    langKeys.forEach(lang => {
      if (!translations[lang]) {
        translations[lang] = '';
      }
    });

    this.form.get('translations').setValue(
      Object.keys(translations).map(lang => {
        return {code_iso3: lang, value: translations[lang]};
      })
    );
    this.form.get('translations').markAsDirty();

    const options = this.wizardForm.controlSetup.get('options').value;
    options.forEach(option => {
      langKeys.forEach(key => {
        if (!option.hasOwnProperty(key)) {
          option[key] = '';
        }
      });
    });
    this.wizardForm.controlSetup.get('options').setValue(options);

    this.form.updateValueAndValidity();
  }
}
