import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AppState } from '../../store/state/app.state';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { ResponseModel } from '../models';
import { DisplayToaster } from '../decorators/toaster';

@Injectable()
export class DocumentsTemplatesApiService {
  private wid: number;
  private apiEndpoint: string;

  public appliedPreviewTemplateDocumentId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public applyPreviewTemplateDocument(documentId: number): void {
    this.appliedPreviewTemplateDocumentId$.next(documentId);
  }

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
        this.apiEndpoint =  `${environment.javaApiVersion}/${this.wid}/templates`;
      });
  }

  public getDocumentTemplates(documentType: DocumentTypesUppercaseEnum): Observable<any> {
    const params = {
      // userId: this.userId as any,
      // type: docType,
      // status,
    };
    // return this.http.get<ResponseModel<any>>(this.apiUrl(`/${documentType}`), { params })
    return this.http.get<ResponseModel<any>>(this.apiUrl(`/${documentType}`))
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  public getDocumentTemplatesNames(documentType: DocumentTypesUppercaseEnum): Observable<{ key: number; value: string; }[]> {
    return this.http.get<ResponseModel<{ key: number; value: string; }[]>>(this.apiUrl(`/${documentType}/filter/template-name`))
      .pipe(map((response: ResponseModel<{ key: number; value: string; }[]>) => response.data));
  }

  public saveDocumentTemplate(
    documentType: DocumentTypesUppercaseEnum,
    documentId: number,
    body: { name: string; rewrite: boolean; resetCounter: boolean; }
  ): Observable<any> {
    return this.http.post(this.apiUrl(`/${documentType}/from/${documentId}`), body);
  }

  public applyDocumentTemplate(
    documentType: DocumentTypesUppercaseEnum,
    documentId: number,
    templateId: number,
    rewrite: boolean = false
  ): Observable<any> {
    const params = {
      rewrite,
    };
    return this.http.post<ResponseModel<any>>(this.apiUrl(`/${documentType}/${templateId}/apply-on/${documentId}`), params)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDocumentFromTemplate(documentType: DocumentTypesUppercaseEnum, templateId: number,): Observable<number> {
    return this.http.request<ResponseModel<number>>('post', this.apiUrl(`/${documentType}/${templateId}/apply`))
      .pipe(map((response: ResponseModel<number>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteDocumentTemplates(documentType: DocumentTypesUppercaseEnum, ids: number[]): Observable<any> {
    return this.http.request('delete', this.apiUrl(`/${documentType}`), {body: {ids}});
  }

}
