import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { RnplCommonModule } from 'common/src/modules/rnpl-common';
import { CommonAddressFormComponent } from './common-address-form.component';
import { UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    CommonAddressFormComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    UIComponentsModule,
    TranslateModule,
    GooglePlaceModule
  ],
  exports: [CommonAddressFormComponent],
  entryComponents: [CommonAddressFormComponent]
})
export class CommonAddressFormModule { }
