import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';

import { AppState } from '../../store/state/app.state';
import { selectGeneralSettings } from '../../store/selectors/shared.selectors';
import { GeneralSettingsService } from '../general-settings.service';
import { GeneralSettingsModel } from '../../shared/models';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AdministrationsApiService } from '../../administration/services/administrations-api.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralSettingsGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly generalSettingsService: GeneralSettingsService,
    private readonly store: Store<AppState>
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.select(selectCompanyProfile)
      .pipe(
        first(),
        switchMap((companyProfile: CompanyProfile) => {
          return isEmpty(companyProfile)
            ? this.administrationsApiService.getCompanyProfile()
            : of(companyProfile);
        }),
        switchMap(() => this.store.select(selectGeneralSettings)),
        switchMap((settings: GeneralSettingsModel) => {
          return settings && settings.id ? of(true) : this.generalSettingsService.getGeneralSettings();
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return of(false);
        }),
        switchMap(() => of(true))
      );
  }

}
