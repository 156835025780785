import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../models/table-column.model';

// export const STOCK_AREA_LIST = [
//   {
//     label: 'TAB.GENERAL_STOCK',
//     value: StockAreaEnum.GENERAL_STOCK
//   },
//   {
//     label: 'TAB.RETURNS_STOCK',
//     value: StockAreaEnum.RETURNS_STOCK
//   },
// ];

export const COLUMNS: TableColumnModelExtended[] = [
  {
    cellTemplate: 'numberRows',
    cellClass: 'center',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    headerClass: 'center'
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.BIN_LOCATION',
    prop: 'bin',
    sortable: true,
    width: 104,
    maxWidth: 104,
    // resizeable: false,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.BATCH',
    prop: 'batch',
    sortable: true,
    width: 164,
    maxWidth: 164,
    // resizeable: false,
  },
  {
    cellTemplate: 'primaryWithNumber',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.STORED',
    prop: 'stored',
    sortable: true,
    additionalText: 'pcs',
    cellClass: 'right font-weight-500',
    width: 98,
    maxWidth: 98,
  },
  {
    cellTemplate: 'primaryWithNumber',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.AVAILABLE',
    prop: 'available',
    sortable: true,
    additionalText: 'pcs',
    cellClass: 'right font-weight-500',
    width: 98,
    maxWidth: 98,
  },
  {
    cellTemplate: 'withInput',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.QUANTITY',
    prop: 'quantity',
    placeholder: '0',
    sortable: true,
    type: ColumnTypeEnum.STEPPER,
    decimal: 0,
    additionalText: 'pcs',
    width: 150,
  },
];
