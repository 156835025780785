import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { HintTypeEnum } from '../../../hrm/models/hints-list.model';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from 'common/src/modules/rnpl-common/components/base-mega-hints/base-mega-hints.component';
import { stockStatusHintsList } from '../../configs/hints.config';

@Component({
  selector: 'rnpl-stock-status-mega-hint',
  templateUrl: './stock-status-mega-hint.component.html',
  styleUrls: ['./stock-status-mega-hint.component.scss']
})
export class StockStatusMegaHintComponent extends BaseMegaHintsComponent implements OnInit {
  @Input() screenName;
  @Input() activeTabIsReturnedStock: boolean;
  @Input() hasStockStatusList: boolean;

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'APP.STOCK_STATUS';
    this.megaHintTimer = 800;
    this.initHints();
  }

  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = stockStatusHintsList(
            this.activeTabIsReturnedStock,
            this.hasStockStatusList
          );

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => !!this.hintListByName[hint.name])
            .filter(hint => hint.type !== HintTypeEnum.MEGA);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name])
            .filter(hint => !hint.viewed);

          this.megaHintId = +hints.data.hints[0].id;

          this.displayHints();

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!hints.data.hints && !!hints.data.hints.length && !hints.data.hints[0].viewed && hints.data.hints[0].type === HintTypeEnum.MEGA) {
            this.isCloseHint$.next(false);
            this.startTimerMegaHint();
          } else {
            if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
              this.shepherdService.start();
            }
          }
        }
      })
  }

}
