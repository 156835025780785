import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FormInputChangedModel } from '../../models/form-input-value.model';
import { PayerEnum } from 'common/src/models';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';
import { VAT_LIST } from '../../constants/vat-list';

@Component({
  selector: 'rnpl-delivery-cost-form',
  templateUrl: './delivery-cost-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryCostFormComponent {

  @Input() form: FormGroup;
  @Input() currencyConversion: boolean = false;
  @Input() isDeliveryCostEnabled: boolean = false;
  @Input() showToggle: boolean = true;
  @Input() vatBlocked: boolean = false;
  @Input() localCurrency: string = 'EUR';
  @Input() recipientLabel: string = 'FORM.RECIPIENT';
  @Input() senderLabel: string = 'FORM.SENDER';

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public readonly payer = PayerEnum;
  public readonly vatList = VAT_LIST;

  constructor(private metricPipe: MetricConversionPipe) {

  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  public updateFieldWithConversion(fieldValue: any, fieldName: string): void {
    if (this.currencyConversion && fieldValue) {
      fieldValue = this.toInteger(fieldValue);
    }

    if (!fieldValue && fieldValue !== 0) {
      fieldValue = null;
    }

    this.updateField(fieldValue, fieldName);
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  get isDeliveryCosts(): FormControl { return this.form.get('isDeliveryCosts') as FormControl; }
  get deliveryCosts(): FormControl { return this.form.get('deliveryCosts') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.form.get('deliveryCostsPayer') as FormControl; }
  get deliveryCostsVat(): FormControl { return this.form.get('deliveryCostsVat') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.form.get('deliveryCostsGross') as FormControl; }

}
