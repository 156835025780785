import { Component, Output, EventEmitter, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { filter, finalize, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { FormInputChangedModel } from '../../../shared/models';
// import { selectCountries } from '../../../store/selectors/shared.selectors';
// import { CountryModel } from 'common/src/modules/rnpl-common';
// import { AppState } from '../../../store/state/app.state';
import { BankAccountsApiService } from '../../services/bank-accounts-api.service';
import { BankAccountModel, BankModel, InstitutionModel } from '../../models';
import { CurrencyModel } from '../../../payment/models/currency.model';
import { PaymentCardTypeEnum } from 'common/src/models';
import { OpenBankingApiService } from '../../../payment/services/open-banking-api.service';


@Component({
  selector: 'rnpl-bank-account-form',
  templateUrl: './bank-account-form.component.html',
})
export class BankAccountFormComponent implements OnInit, OnChanges {
  public paymentCardTypeEnum = PaymentCardTypeEnum;
  // private availableCountriesIso3Codes: string[] = [
  //   'aut', 'deu', 'bel', 'bgr', 'hrv', 'est', 'cyp', 'cze', 'dnk', 'fin', 'fra', 'grc', 'hun', 'isl', 'irl', 'ita', 'lva',
  //   'lie', 'ltu', 'lux', 'mlt', 'mco', 'nld', 'nor', 'pol', 'prt', 'rou', 'svk', 'svn', 'esp',  'swe', 'che', 'gbr', 'usa',
  // ];
  public currencies: CurrencyModel[] = [];
  // public countries: CountryModel[] = [];
  public banks: InstitutionModel[] = [];
  private subscriptions: Subscription[] = [];

  public form: FormGroup;

  @Input() isReadonly = false;
  @Input() checkPrimaryBankAccountExists = false;
  @Input() primaryBankAccountExists = false;
  @Input() bankAccount: BankAccountModel;

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    // private store: Store<AppState>,
    private fb: FormBuilder,
    private bankAccountsApiService: BankAccountsApiService,
    private openBankingApiService: OpenBankingApiService,
  ) {

  }

  ngOnInit() {
    // this.store.select(selectCountries)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((countries: CountryModel[]) => {
    //     this.countries = countries
    //       .filter(country => this.availableCountriesIso3Codes.includes(country.code_iso3));
    //   });

    this.getCurrencies();
    this.getInstitutions();
    this.initForm();
    this.setFormState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('isReadonly')) {
      this.setFormState();
    }
    if (changes.hasOwnProperty('bankAccount')) {
      this.initForm();
      this.setFormState();
    }
  }

  public setFormState(): void {
    if (!this.form) { return; }

    if (this.isReadonly) {
      this.form.disable({emitEvent: false});
    } else {
      this.form.enable({emitEvent: false});
    }

    // this.currencyCodeControl.disable({emitEvent: false});
  }

  public initForm(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    // const country = (get(this, 'bankAccount.country') || 'aut').toLowerCase();

    this.form = this.fb.group({
      accountName: [get(this, 'bankAccount.accountName'), [Validators.required]],
      institution: [get(this, 'bankAccount.institution'), [Validators.required]],
      bic: [get(this, 'bankAccount.bic'), [Validators.required]],
      // country: [country],
      currencyCode: [get(this, 'bankAccount.currencyCode', 'EUR'), {disabled: true}],
      iban: [get(this, 'bankAccount.iban'), [Validators.required]],
      primaryAccount: [get(this, 'bankAccount.primaryAccountInEditMode', false)],
      // userDefinedBank: [get(this, 'bankAccount.userDefinedBank', false)],
      // bankDescription: [get(this, 'bankAccount.bankDescription')],
      card: this.fb.group({
        type: [get(this, 'bankAccount.card.type', PaymentCardTypeEnum.CREDIT_CARD)],
      })
    });

    // if (get(this, 'bankAccount.userDefinedBank')) {
    //   this.bankControl.patchValue({}, {emitEvent: false});
    // }

    // this.getBanksByCountry(country);

    // const countrySubscription$ = this.countryControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((iso3: string) => {
    //     if (!this.bankAccount) {
    //       this.bankControl.reset();
    //       // this.userDefinedBankControl.patchValue(false);
    //       // this.bankDescriptionControl.patchValue(null);
    //       this.bicControl.patchValue(null);
    //       this.getBanksByCountry(iso3);
    //     }
    //   });

    // const bankSubscription$ = this.bankControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((bank: BankModel) => {
    //     if (bank && !this.bankAccount) {
    //       this.userDefinedBankControl.patchValue(false);
    //       this.bankDescriptionControl.patchValue(null);
    //       this.bankDescriptionControl.clearValidators();
    //       this.bankDescriptionControl.updateValueAndValidity();
    //       // this.bicControl.patchValue(bank.bic);
    //     }
    //   });

    const ibanControlSubscription = this.ibanControl.valueChanges
      .pipe(
        filter(() => !this.bankAccount),
        startWith(this.ibanControl.value),
        pairwise(),
        takeUntil(this.destroy$)
      )
      .subscribe(([prevIban, nextIban]: [any, any]) => {
        if (!this.accountNameControl.value || this.accountNameControl.value === prevIban) {
          this.accountNameControl.patchValue(nextIban);
        }
      });

    // this.subscriptions.push(countrySubscription$);
    // this.subscriptions.push(bankSubscription$);
    this.subscriptions.push(ibanControlSubscription);
  }

  public getFormData(): Partial<BankAccountModel> {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    return {
      ...this.form.getRawValue(),
      // primary: this.checkPrimaryBankAccountExists && !this.primaryBankAccountExists
      //   ? true
      //   : this.form.getRawValue().primary,
      // bank: this.userDefinedBankControl.value
      //   ? null
      //   : this.bankControl.value
    };
  }

  public updateField(fieldValue: any, fieldName: string): void {
    fieldValue = fieldValue === '' ? null : fieldValue;
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  public updateBank(bic: string): void {
    if (!this.bankAccount) {
      this.bicControl.patchValue(bic);
    }
  }

  public getInstitutions(): void {
    this.openBankingApiService.getInstitutions()
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((institutions: InstitutionModel[]) => {
        this.banks = institutions;
        if (this.banks.length === 1 && !this.bankAccount) {
          this.institutionControl.patchValue(this.banks[0]);
        }
      });
  }

  public getCurrencies(): void {
    this.bankAccountsApiService.getCurrencies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((currencies: CurrencyModel[]) => this.currencies = currencies);
  }

  // public setUserDefinedBank(): void {
  //   if (!this.bankAccount) {
  //     this.bankControl.patchValue({});
  //     this.userDefinedBankControl.patchValue(true);
  //   }
  //   this.bankDescriptionControl.setValidators(Validators.required);
  //   this.bankDescriptionControl.updateValueAndValidity();
  //   this.updateField(true, 'userDefinedBank');
  // }

  get accountNameControl(): FormControl { return this.form.get('accountName') as FormControl; }
  get institutionControl(): FormControl { return this.form.get('institution') as FormControl; }
  get bicControl(): FormControl { return this.form.get('bic') as FormControl; }
  // get countryControl(): FormControl { return this.form.get('country') as FormControl; }
  get currencyCodeControl(): FormControl { return this.form.get('currencyCode') as FormControl; }
  get ibanControl(): FormControl { return this.form.get('iban') as FormControl; }
  get primaryAccountControl(): FormControl { return this.form.get('primaryAccount') as FormControl; }
  // get userDefinedBankControl(): FormControl { return this.form.get('userDefinedBank') as FormControl; }
  // get bankDescriptionControl(): FormControl { return this.form.get('bankDescription') as FormControl; }
  get cardTypeControl(): FormControl { return this.form.get('card').get('type') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
