import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { SandboxModel } from '../../sandbox.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AuthService } from '../../../../auth/auth.service';


@Component({
  selector: 'rnpl-exit-sandbox-modal',
  templateUrl: './exit-sandbox.component.html',
  styleUrls: ['./exit-sandbox.component.scss'],
})
export class ExitSandboxModalComponent implements OnDestroy {
  public keepAllData = true;
  public keepDataRadioValue: 'sandboxDataKeep'|'sandboxDataDelete' = 'sandboxDataKeep';
  public sandboxConfig = {
    saveProducts: true,
    saveSalesPrices: true,
    saveStockStatus: true,
    savePartners: true,
    saveLedgerAccounts: true,
    saveGeneralProducts: true,
    saveAssetsDirectory: true,
    saveEmployees: true,
  };

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly exitSandboxRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public administrationsApiService: AdministrationsApiService,
    public authService: AuthService,
    public dialogRef: MatDialogRef<ExitSandboxModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      companyProfile: CompanyProfile
    }
  ) {
  }

  public keepDataRadioValueChanged(value: 'sandboxDataKeep'|'sandboxDataDelete'): void {
    const keepData = value === 'sandboxDataKeep';

    for (let sandboxConfigKey in this.sandboxConfig) {
      // IMPORTANT - do not change to for (CONST sandboxConfigKey in this.sandboxConfig)
      // IMPORTANT - do not add if (this.sandboxConfig[sandboxConfigKey])
      this.sandboxConfig[sandboxConfigKey] = keepData;
    }
    if (keepData) {
      this.keepAllData = true;
    }
  }

  public keepAllDataChanged(): void {
    for (let sandboxConfigKey in this.sandboxConfig) {
      // IMPORTANT - do not change to for (CONST sandboxConfigKey in this.sandboxConfig)
      // IMPORTANT - do not add if (this.sandboxConfig[sandboxConfigKey])
      this.sandboxConfig[sandboxConfigKey] = true;
    }
  }

  public saveProductsChanged(): void {
    if (this.sandboxConfig.saveProducts) {
      this.sandboxConfig.saveSalesPrices = true;
      this.sandboxConfig.saveStockStatus = true;
    } else {
      this.sandboxConfig.saveSalesPrices = false;
      this.sandboxConfig.saveStockStatus = false;
    }
  }

  public saveLegerAccountsChanged(): void {
    if (this.sandboxConfig.saveLedgerAccounts) {
      this.sandboxConfig.saveGeneralProducts = true;
      this.sandboxConfig.saveAssetsDirectory = true;
    } else {
      this.sandboxConfig.saveGeneralProducts = false;
      this.sandboxConfig.saveAssetsDirectory = false;
    }
  }

  public exitSandbox(): void {
    if (this.exitSandboxRequest$.getValue()) { return; }
    this.exitSandboxRequest$.next(true);

    const requestBody: Partial<SandboxModel> = {
      workspaceId: this.data.companyProfile.workspaceId,
      saveProducts: this.sandboxConfig.saveProducts,
      saveSalesPrices: this.sandboxConfig.saveSalesPrices,
      saveStockStatus: this.sandboxConfig.saveStockStatus,
      savePartners: this.sandboxConfig.savePartners,
      saveLedgerAccounts: this.sandboxConfig.saveLedgerAccounts,
      saveGeneralProducts: this.sandboxConfig.saveGeneralProducts,
      saveAssetsDirectory: this.sandboxConfig.saveAssetsDirectory,
      saveEmployees: this.sandboxConfig.saveEmployees,
      saveTradeDocs: this.keepDataRadioValue === 'sandboxDataKeep' && this.keepAllData,
      saveAccountingDocs: this.keepDataRadioValue === 'sandboxDataKeep' && this.keepAllData,
      saveServicesDocs: this.keepDataRadioValue === 'sandboxDataKeep' && this.keepAllData,
      saveWarehouseDocs: this.keepDataRadioValue === 'sandboxDataKeep' && this.keepAllData,
    };

    this.administrationsApiService.exitSandbox(requestBody as SandboxModel)
      .pipe(
        finalize(() => this.exitSandboxRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.authService.signOut());
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
