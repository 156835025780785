import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  SystemSettingsCommonModule,
  UIComponentsModule,
  DynamicFormsModule, RnplCommonModule
} from 'common/src/modules/index';
import { AttributeWizardModule } from '../system-settings/attribute-wizard/attribute-wizard.module';
import { FormWizardModule } from '../system-settings/form-wizard/form-wizard.module';
import { EntitiesManagementRoutingModule } from './entities-management-routing.module';
import {
  AttributesComponent,
  EntitiesManagementComponent,
  FormSetupComponent,
  FormSetupViewComponent,
} from './pages';
import { FamilyWizardModule } from '../products/family-wizard';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    EntitiesManagementComponent,
    AttributesComponent,
    FormSetupComponent,
    FormSetupViewComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    RouterModule,
    EntitiesManagementRoutingModule,
    UIComponentsModule,
    SystemSettingsCommonModule,
    AttributeWizardModule,
    FamilyWizardModule,
    FormWizardModule,
    DynamicFormsModule,
    NgSelectModule,
    RnplCommonModule,
    TranslateModule
  ],
  providers: [],
  bootstrap: [EntitiesManagementComponent]
})
export class EntitiesManagementModule {

}
