import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rnpl-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  @Input('height')
  public height: string = '56';

  @Input()
  public isGrey: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  get logoName(): string {
    return window.location.host.includes('it-work.runple.app')
      ? 'it-work_logo_2020'
      : 'runple-logo';
  }

}
