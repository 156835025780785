import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { WarehouseSettingsService } from '../../../warehouse/warehouse-settings-module/services/warehouse-settings.service';
import { DeliveryServiceModel } from '../../../warehouse/warehouse-settings-module/models';
import { DeliveryServiceEnum } from '../../enums';

@Component({
  selector: 'rnpl-delivery-service-control',
  templateUrl: './delivery-service-control.component.html',
})
export class DeliveryServiceControlComponent {

  @Input() control: FormControl;
  @Input() label: string = 'FORM.DELIVERY_SERVICE';
  @Input() bindValue: string = 'id';
  @Input() isOptional: boolean = false;
  @Input() showValidation: boolean = false;
  @Input() showUserDefined: boolean = true;
  @Input() controlKey: string = '';

  public translatedName = {
    [DeliveryServiceEnum.AUSTRIAN_POST]: 'POST_SERVICES.AUSTRIAN_POST',
    [DeliveryServiceEnum.USER_DEFINED]: 'COMMON.USER_DEFINED'
  };

  public deliveryServiceEnum: typeof DeliveryServiceEnum = DeliveryServiceEnum;

  public loadingList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public deliveryServicesList$: Observable<DeliveryServiceModel[]> = this.warehouseSettingsService.getDeliveryServices()
    .pipe(
      map((deliveryServices: DeliveryServiceModel[]) => {
        return this.showUserDefined
          ? deliveryServices
          : deliveryServices.filter(s => s.name !== DeliveryServiceEnum.USER_DEFINED);
      }),
      finalize(() => this.loadingList$.next(false))
    );

  @Output() fieldChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private warehouseSettingsService: WarehouseSettingsService
  ) {

  }

  public updateField(fieldValue: any): void {
    this.fieldChanged.emit(fieldValue);
  }


}
