import { createAction, props } from '@ngrx/store';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { ListStateInterface } from '../../../shared/models/list-state.model';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { PurchasePriceListModel, SalesPriceListModel } from '../../models';

export const LoadPricesSalesList = createAction(
  '[Prices] Load Prices Sales List',
  props<{ pricesSalesListData: ListStateInterface<SalesPriceListModel, FilterModel>, productType: ProductTypes }>()
);
export const LoadPricesPurchaseList = createAction(
  '[Prices] Load Prices Purchase List',
  props<{ pricesPurchaseListData: ListStateInterface<PurchasePriceListModel, FilterModel>,  productType: ProductTypes }>()
);
