import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import {
  BillingInfoHints,
  getEditPositionsHints,
  SelectCustomerHint
} from 'projects/workspace/src/app/outgoing-invoice/outgoing-invoices-hints.config';
import { ProductTypes } from '../../../products/product-types';

@Component({
  selector: 'rnpl-outgoing-invoice-modal-hints',
  templateUrl: './outgoing-invoice-modal-hints.component.html'
})
export class OutgoingInvoiceModalHintsComponent extends BaseMegaHintsComponent implements OnInit {
@Input() modalName: ModalNameEnum;
@Input() productType: ProductTypes = ProductTypes.GOODS;

public hintsListByModalName = {};

  constructor(
    public deps: BaseMegaHintComponentDependences
) {
    super(deps);
  }

  ngOnInit() {
    this.hintsListByModalName = {
    [ModalNameEnum.BILLING_INFO]: BillingInfoHints,
    [ModalNameEnum.SELECT_PARTNER]: SelectCustomerHint,
    [ModalNameEnum.EDIT_POSITION]: getEditPositionsHints(this.productType),
  }
    this.endFinishText = 'APP.OUTGOING_INVOICES';
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }

    this.initHints();
  }

  private initHints(): void {
    this.screenName = 'outgoing_invoices_view_draft';
    this.hintListByName = this.hintsListByModalName[this.modalName];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          this.displayHints();

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.shepherdService.start();
          }
        }
      });
  }
}
