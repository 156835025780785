import { ColumnTypeEnum, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { BinLocationsTabsEnum } from '../../enums';
import { NavBarBtnModel } from 'common/src/models';
import { TranslateService } from '@ngx-translate/core';
import { HintsModel } from 'common/src/modules/rnpl-common/models/hints.model';
import { TimeTrackingListTabsEnum } from '../../../time-tracking/enums';

export const BinLocationsTabs: TabDefinitionModel[] = [
  {
    label: 'TAB.GENERAL_STOCK',
    tabName: 'General stock',
    routeParam: BinLocationsTabsEnum.GENERAL_STOCK,
    link: `../../${BinLocationsTabsEnum.GENERAL_STOCK}/1`,
    icon: 'box',
    hintsId: 'generalStockTab',
    count: 0
  },
  {
    label: 'TAB.RETURNS_STOCK',
    tabName: 'Returns stock',
    routeParam: BinLocationsTabsEnum.RETURNS_STOCK,
    link: `../../${BinLocationsTabsEnum.RETURNS_STOCK}/1`,
    icon: 'returns',
    hintsId: 'returnsStockTab',
    count: 0
  },
  {
    label: 'TAB.DELETED',
    tabName: BinLocationsTabsEnum.DELETED,
    routeParam: BinLocationsTabsEnum.DELETED,
    link: `../../${BinLocationsTabsEnum.DELETED}/1`,
    hintsId: 'deleteTab',
    icon: 'trash-2',
    hideCount: true
  }
];

export const NavBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    multipleClasses: 'btn-white red',
    text: 'BUTTON.DELETE',
    multipleText: 'BUTTON.DELETE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'trash-2',
    actionName: 'onDeleteClick',
    allowLoadingSpinner: true,
    greyIcon: true,
    multiple: true,
    hideWithStatus: [
      BinLocationsTabsEnum.DELETED,
    ],
  },
  {
    classes: 'btn-white red',
    multipleClasses: 'btn-white red',
    text: 'BUTTON.DELETE_PERMANENTLY',
    multipleText: 'BUTTON.DELETE_PERMANENTLY',
    badgeClass: 'rnpl-badge--red',
    iconName: 'fire',
    actionName: 'onDeletePermanentlyClick',
    allowLoadingSpinner: true,
    greyIcon: true,
    multiple: false,
    hideWithStatus: [
      BinLocationsTabsEnum.GENERAL_STOCK,
      BinLocationsTabsEnum.RETURNS_STOCK,
    ],
  },
  {
    classes: 'btn-white',
    multipleClasses: 'btn-white',
    text: 'BUTTON.DEACTIVATE',
    multipleText: 'BUTTON.DEACTIVATE',
    iconName: 'bulb-off',
    allowLoadingSpinner: true,
    multiple: false,
    actionName: 'onDeactivateClick',
    greyIcon: true,
    hideWithStatus: [
      BinLocationsTabsEnum.DELETED,
    ],
    backdropClass: 'mb-48',
    actions: [
      {
        actionTitle: 'BUTTON.DEACTIVATE',
        actionName: 'onDeactivateClick',
        actionIcon: 'bulb-off'
      },
      {
        actionTitle: 'BUTTON.ACTIVATE',
        actionName: 'onActivateClick',
        actionIcon: 'bulb'
      }
    ]
  },
  // {
  //   classes: 'btn-dark',
  //   multipleClasses: 'btn-white',
  //   text: 'BUTTON.DEACTIVATE',
  //   multipleText: 'BUTTON.DEACTIVATE',
  //   iconName: 'bulb-off',
  //   allowLoadingSpinner: true,
  //   multiple: false,
  //   actionName: 'onDeactivateClick',
  //   greyIcon: true,
  //   hideWithStatus: [
  //     BinLocationsTabsEnum.DELETED,
  //   ],
  // },
  {
    classes: 'btn-dark',
    multipleClasses: 'btn-primary',
    text: 'BUTTON.RESTORE',
    multipleText: 'BUTTON.RESTORE',
    iconName: 'undo',
    allowLoadingSpinner: true,
    multiple: false,
    actionName: 'onRestoreClick',
    greyIcon: true,
    hideWithStatus: [
      BinLocationsTabsEnum.GENERAL_STOCK,
      BinLocationsTabsEnum.RETURNS_STOCK,
    ],
  },
  // {
  //   classes: 'btn-dark',
  //   multipleClasses: 'btn-primary',
  //   text: 'BUTTON.ACTIVATE',
  //   multipleText: 'BUTTON.ACTIVATE',
  //   iconName: 'bulb',
  //   allowLoadingSpinner: true,
  //   multiple: false,
  //   actionName: 'onActivateClick',
  //   greyIcon: true,
  //   hideWithStatus: [
  //     BinLocationsTabsEnum.DELETED,
  //   ],
  // },
];

export const BinLocationsListColumns: TableColumnModelExtended[] = [
  {
    cellTemplate: 'withSelection',
    headerTemplate: 'headerSelections',
    cellClass: 'center',
    name: 'selection',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    frozenLeft: true,
  },
  {
    cellTemplate: 'numberRows',
    cellClass: 'center cursor-pointer',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    customizable: true,
    headerClass: 'center',
    clicktype: TableActivateTypes.RowDetail,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    cellClass: 'cursor-pointer',
    name: 'COLUMN.BIN_LOCATION',
    prop: 'binLocation',
    sortable: true,
    width: 160,
    maxWidth: 160,
    clicktype: TableActivateTypes.RowDetail,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    cellClass: 'cursor-pointer',
    name: 'COLUMN.TYPE',
    prop: 'typeLabel',
    sortable: true,
    customizable: true,
    clicktype: TableActivateTypes.RowDetail,
  },
  {
    cellTemplate: 'withStatusLabel',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.STATUS',
    prop: 'status',
    sortable: true,
    customizable: true,
    cellClass: 'center cursor-pointer',
    width: 124,
    maxWidth: 124,
    clicktype: TableActivateTypes.RowDetail,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'POSITIONS.PRODUCTS',
    prop: 'productsCount',
    sortable: true,
    customizable: true,
    cellClass: 'right cursor-pointer',
    width: 160,
    maxWidth: 160,
    clicktype: TableActivateTypes.RowDetail,
    showWithStatuses: [
      BinLocationsTabsEnum.GENERAL_STOCK,
      BinLocationsTabsEnum.RETURNS_STOCK,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    cellClass: 'cursor-pointer',
    name: 'COLUMN.CREATED',
    prop: 'createdAt',
    width: 147,
    sortable: true,
    customizable: true,
    clicktype: TableActivateTypes.RowDetail,
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    cellClass: 'cursor-pointer',
    name: 'COLUMN.LAST_ACTIVITY',
    prop: 'lastActivity',
    width: 147,
    maxWidth: 147,
    sortable: true,
    customizable: true,
    clicktype: TableActivateTypes.RowDetail,
    showWithStatuses: [
      BinLocationsTabsEnum.GENERAL_STOCK,
      BinLocationsTabsEnum.RETURNS_STOCK,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    cellClass: 'cursor-pointer',
    name: 'COLUMN.DELETED',
    prop: 'deletedAt',
    width: 147,
    sortable: true,
    customizable: true,
    clicktype: TableActivateTypes.RowDetail,
    showWithStatuses: [
      BinLocationsTabsEnum.DELETED,
    ]
  },
  {
    cellTemplate: 'withClickableIcon',
    name: '',
    width: 42,
    maxWidth: 42,
    sortable: false,
    resizeable: false,
    clicktype: TableActivateTypes.RowDetail,
    frozenRight: true,
    icon: 'arrow-micro-down',
    cellClass: 'px-4 cursor-pointer',
    showWithStatuses: [
      BinLocationsTabsEnum.GENERAL_STOCK,
      BinLocationsTabsEnum.RETURNS_STOCK,
    ]
  }
];

export function getBinLocationsListInnerTableColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 172,
      maxWidth: 172,
      clicktype: TableActivateTypes.Link,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary inline',
      name: 'COLUMN.PRODUCT_NAME',
      preventTranslate: true,
      prop: 'productNameLink',
      sortable: true
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      additionalText: translateService.instant('FORM.PCS'),
      cellClass: 'right font-weight-500',
      width: 112,
      maxWidth: 112,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BATCH',
      prop: 'batch',
      sortable: true,
    },
    {
      cellTemplate: 'withStatusLine',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.EXPIRATION_DATE',
      prop: 'expirationDateValue',
      sortable: true,
      type: ColumnTypeEnum.DATE,
      width: 180,
      maxWidth: 180,
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.RECEIVED',
      prop: 'receivedAt',
      width: 147,
      sortable: true,
    },
  ];
}

export const BIN_LOCATIONS_TYPE_LABELS = {
  ['rma_buffer']: 'FORM.RMA_BUFFER',
  ['bin_location']: 'FORM.BIN',
  ['inbound_buffer']: 'FORM.INBOUND_BUFFER',
  ['outbound_processing_station']: 'FORM.OUTBOUND_STATION',
  ['outbound_shipments_deck']: 'FORM.OUTBOUND_DECK',
};

export const EMPTY_STATE_DATA = {
  [BinLocationsTabsEnum.GENERAL_STOCK]: {
    icon: 'box',
    text: 'WAREHOUSE.BIN_LOCATION.EMPTY'
  },
  [BinLocationsTabsEnum.RETURNS_STOCK]: {
    icon: 'returns',
    text: 'WAREHOUSE.BIN_LOCATION.EMPTY'
  },
  [BinLocationsTabsEnum.DELETED]: {
    icon: 'trash-2',
    text: 'WAREHOUSE.BIN_LOCATION.EMPTY_DELETED'
  },
};
