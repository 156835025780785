import { Component, Input, OnInit, Optional } from '@angular/core';


import { WizardStep } from '../../../../wizard/wizard-step';
import { AttributeModel } from '../../../models';
import { mapFormToAttribute, RelationHelpers } from '../../helpers';
import { AttributeWizardComponent } from '../../attribute-wizard.component';
import { AbstractAppComponent } from '../../../../../interfaces';


@Component({
  selector: 'rnpl-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends WizardStep implements OnInit {

  @Input()
  public availableLangs: Array<any> = [];

  public languages: Array<any> = [];

  public attribute: AttributeModel;

  public entityKeyToLabel = RelationHelpers.entityKeyToLabel;
  public formKeyToLabel = RelationHelpers.formKeyToLabel;

  constructor(@Optional() public wizard: AttributeWizardComponent,
              @Optional() public app: AbstractAppComponent) {
    super();
  }

  ngOnInit(): void {

    this.wizard.progress = 100;

    this.attribute = mapFormToAttribute(this.wizardForm);
    this.attribute.name = '';

    this.languages = this.availableLangs
      .filter(lang => {
        return this.wizardForm.translations.get('translations').value
          .find(translation => translation.code_iso3 === lang.code_iso3);
      });
  }

  public toggleOnHover(event, classAction: string): void {
    const cardParamEl = event.target.closest('.card-param') as Element;

    if (cardParamEl && cardParamEl.querySelector('.icon-edit')) {
      cardParamEl.querySelector('.icon-edit').classList[classAction]('d-none');
    }
  }

}
