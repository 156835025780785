import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { MetricConversionPipe } from '../../../rnpl-common';
import { selectEcoOrder, selectEcoOrderCurrentState } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/store/selectors';
import { EcoOrderModel } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/models';
import { UIStatesEnum } from '../../../../models';

@Component({
  selector: 'rnpl-eco-add-discount-modal',
  templateUrl: './eco-add-discount-modal.component.html',
  styleUrls: ['./eco-add-discount-modal.component.css']
})
export class EcoAddDiscountModalComponent implements OnInit {
  public ecoOrder: EcoOrderModel;
  // public discountControl: FormControl = new FormControl({value: null});
  // public discountAmountNetControl: FormControl = new FormControl({value: null});

  readonly destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private readonly salesOrderApiService: SalesOrderApiService,
    private readonly metricPipe: MetricConversionPipe,
    public dialogRef: MatDialogRef<EcoAddDiscountModalComponent>
  ) { }

  ngOnInit(): void {
    this.trackSalesOfferChanges();

    // this.store.select(selectEcoOrderCurrentState)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((response: UIStatesEnum) => {
    //     if (response === UIStatesEnum.VIEW) {
    //       this.discountControl.disable({emitEvent: false});
    //       this.discountAmountNetControl.disable({emitEvent: false});
    //     } else {
    //       this.discountControl.enable({emitEvent: false});
    //       this.discountAmountNetControl.enable({emitEvent: false});
    //     }
    //   });
  }

  private trackSalesOfferChanges(): void {
    this.store.select(selectEcoOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((ecoOrder: EcoOrderModel) => {
        this.ecoOrder = ecoOrder;
        // this.discountControl.patchValue(this.ecoOrder.discount);
        // this.discountAmountNetControl.patchValue(this.getCurrencyConvertedValue(this.ecoOrder.discountAmountNet));
      });
  }

  // public updateDiscount(field: FormInputChangedModel): void {
  //   this.salesOrderApiService.updateSalesOrder(this.salesOrder.id, field)
  //     .subscribe(); // updated via store
  // }

  private getCurrencyConvertedValue(value: number|string): number|string {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
