import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { SubscriptionModel } from '../../models/subscription.model';
import { SubscriptionListTabsEnum } from '../../enums';
import { CorporatePartnerModel } from '../../../crm/models';
import { SalesPositionsByProductType } from '../../models/subscription-position.model';
import { ResponseModel } from '../../../shared/models/response';
import { SummaryInfoModel } from '../../models/summary-info-model';
import { SubscriptionListState } from '../reducers';

export const LoadSubscriptionsList = createAction(
  '[Subscription] Load Subscriptions List',
  props<{ subscriptionsListData: SubscriptionListState, status: SubscriptionListTabsEnum, page: number }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Subscription] Update Subscription should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);


export const LoadSubscription = createAction(
  '[Subscription] Load Subscription',
  props<{ subscription: SubscriptionModel }>()
);

export const LoadSubscriptionPositions = createAction(
  '[Subscription Positions] Load Subscription Positions',
  props<{ positions: ResponseModel<SalesPositionsByProductType> }>()
);

export const UpdateSubscription = createAction(
  '[Subscription] Update Subscription',
  props<{ subscription: SubscriptionModel }>()
);

export const UpdateSubscriptionPositionsCount = createAction(
  '[Subscription] Update Subscription Positions Count',
  props<{ positionsCount: number }>()
);

export const ClearSubscription = createAction(
  '[Subscription] Clear Subscription'
);

export const ClearSubscriptionState = createAction(
  '[Subscription] Clear Subscription state'
);

export const ClearSubscriptionPositions = createAction(
  '[Subscription] Clear Subscription positions'
);

export const UpdateSubscriptionCurrentState = createAction(
  '[Subscription] Update Subscription Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateSubscriptionSummary = createAction(
  '[Subscription] Update Subscription Summary',
  props<{ summary: SummaryInfoModel }>()
);

export const UpdateSubscriptionStatus = createAction(
  '[Subscription] Update Subscription Status',
  props<{ status: SubscriptionListTabsEnum }>()
);

export const UpdateSubscriptionUpdatedAt = createAction(
  '[Subscription] Update Subscription updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearSubscriptionUpdatedAt = createAction(
  '[Subscription] Clear Subscription updatedAt',
);

export const LoadSubscriptionCountries = createAction(
  '[Subscription] Load Subscription Countries',
  props<{ countries: CountryModel[] }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[Subscription] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Subscription] Decrement Loading Requests Count'
);

// export const UpdatePropertiesBlockValid = createAction(
//   '[Subscription] Update Subscription Properties Block Valid',
//   props<{ propertiesBlockValid: boolean }>()
// );

export const UpdateDurationBlockValid = createAction(
  '[Subscription] Update Subscription Durationo Block Valid',
  props<{ durationBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Subscription] Update Subscription Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Subscription] Update Subscription Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
