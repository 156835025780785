import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { FileAttachmentModel, ResponseModel } from '../../models';
import { DisplayToaster } from '../../decorators/toaster';

@Injectable()
export class FilesAttachmentApiService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) {
  }

  private baseApiUrl(documentType: DocumentTypesUppercaseEnum): string {
    switch (documentType) {
      case DocumentTypesUppercaseEnum.PRODUCT:
        return 'products';
      case DocumentTypesUppercaseEnum.PARTNER:
        return 'partners';
      case DocumentTypesUppercaseEnum.OFR:
        return 'trade-offers';
      case DocumentTypesUppercaseEnum.SO:
        return 'sales-orders';
      case DocumentTypesUppercaseEnum.PO:
        return 'purchase-orders';
      case DocumentTypesUppercaseEnum.SBC:
        return 'subscriptions';
      case DocumentTypesUppercaseEnum.DN:
        return 'delivery-notes';
    }

  }

  public getAttachments(documentType: DocumentTypesUppercaseEnum, documentId: number|string): Observable<FileAttachmentModel[]> {
    return this.http.get<ResponseModel<FileAttachmentModel[]>>(`/${this.baseApiUrl(documentType)}/${documentId}/file`)
      .pipe(
        map((response: ResponseModel<FileAttachmentModel[]>) => response.data)
      );
  }

  public uploadAttachment(documentType: DocumentTypesUppercaseEnum, documentId: number|string, file: File): Observable<any> {
    const formData = new FormData();
    formData.set('file', file);

    return this.http.post<ResponseModel<any>>(`/${this.baseApiUrl(documentType)}/${documentId}/file`, formData)
      .pipe(
        map((response: ResponseModel<any>) => response.data),
        catchError((error) => {
          if (error.status === 413) {
            this.toasterService.notify({type: 'error', message: 'ATTACHMENTS.FILE_SIZE_ERROR'});
            return throwError(error);
          }
          this.toasterService.notify({type: 'error', message: error.error.message});
          return throwError(error);
        }),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public deleteAttachment(documentType: DocumentTypesUppercaseEnum, documentId: number|string, fileId: number): Observable<any> {
    return this.http.delete<ResponseModel<any>>(`/${this.baseApiUrl(documentType)}/${documentId}/file/${fileId}`);
  }

  public getDownloadAttachment(fileId: number): Observable<any> {
    return this.http.get<ResponseModel<any>>(`/file/${fileId}/attachment`)
      .pipe(
        map((response: ResponseModel<any>) => response.data),
      );
  }

}
