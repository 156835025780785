import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import {
  CountryTransformFromIso2ToIso3Pipe,
  CountryTransformFromIso3ToIso2Pipe
} from 'common/src/modules/rnpl-common';
import { PartnersTypeEnum } from '../../../partners/corporate/enums';
import { CustomerTypeEnum, GeneralPartnerTypeEnum } from 'common/src/models';
import { AddressTypeEnum } from '../../../sales-order/enums/address-type.enum';

@Component({
  selector: 'rnpl-common-address-form',
  templateUrl: './common-address-form.component.html'
})
export class CommonAddressFormComponent implements OnInit, OnDestroy, AfterViewInit {

  public addressTypeEnum = AddressTypeEnum

  public streetAutocompleteOptions: Options = {
    fields: ['address_components'],
    types: ['route']
  } as Options;

  public postalCodeAutocompleteOptions: Options = {
    fields: ['address_components'],
    types: ['postal_code']
  } as Options;

  public cityAutocompleteOptions: Options = {
    fields: ['address_components'],
    types: ['locality'],
  } as Options;

  public availableCountries: CountryModel[] = [];

  @Input() form: FormGroup;
  @Input() countries: CountryModel[];
  @Input() suffixId: string;
  @Input() allOptional = false;
  @Input() showErrors = false;
  @Input() countryBindValue = 'code_iso3';
  @Input() partnerType: PartnersTypeEnum|CustomerTypeEnum;
  @Input() generalType: GeneralPartnerTypeEnum;
  @Input() addressType: AddressTypeEnum = AddressTypeEnum.USER_DEFINED;

  @Output()
  inputChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  @ViewChild(NgSelectComponent, {static: false}) private select: NgSelectComponent;

  @ViewChild('placesRefStreet', {static: false}) private placesRefStreet: GooglePlaceDirective;
  @ViewChild('placesRefPostalCode', {static: false}) private placesRefPostalCode: GooglePlaceDirective;
  @ViewChild('placesRefCity', {static: false}) private placesRefCity: GooglePlaceDirective;

  constructor(
    private countryTransformFromIso3ToIso2Pipe: CountryTransformFromIso3ToIso2Pipe,
    private countryTransformFromIso2ToIso3Pipe: CountryTransformFromIso2ToIso3Pipe
  ) {
  }

  ngOnInit() {
    window.addEventListener('scroll', this.onScroll, true);
    this.getAvailableCountries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      (changes.hasOwnProperty('countries') || changes.hasOwnProperty('partnerType') || changes.hasOwnProperty('generalType'))
    ) {
      this.getAvailableCountries();
    }
  }

  ngAfterViewInit() {
    if (!!this.countryControl.value) {
      this.setCurrentCountry(this.countryControl.value);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
    CommonAddressFormComponent.removeAutocompleteElement();
  }

  public updateInput(fieldName: string, fieldValue: any): void {
    if (fieldName === 'country_iso3' && !!fieldValue) {
      this.setCurrentCountry(fieldValue);
    }

    this.inputChanged.emit({fieldName, fieldValue});
  }

  // Close dropdown with scroll form
  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.select.close();
    }
  }

  public addressChange(address: any, fieldName: string) {
    if (fieldName !== 'street') {
      const value = address.address_components[0].long_name;

      if (fieldName === 'city') {
        this.cityControl.setValue(value)
      } else {
        this.zipCodeControl.setValue(value)
      }

      this.inputChanged.emit({fieldName: fieldName, fieldValue: address.address_components[0].long_name});
      return;
    }

    let postalCode = '';
    let city = '';
    let street = '';
    let countryIso = '';
    // let state = '';
    let house = '';

    address.address_components.map(component => {
      switch (component.types[0]) {
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'country':
          countryIso = this.countryTransformFromIso2ToIso3Pipe.transform(component.short_name).toLowerCase();
          break;
        case 'route':
          street = component.long_name;
          break;
        // case 'administrative_area_level_1':
        //   state = component.long_name;
        //   break;
        case 'street_number':
          house = component.long_name;
          break;
      }
    });

    this.blockControl.setValue('');
    this.apartmentsControl.setValue('');

    // if (!this.zipCodeControl.value || fieldName === 'zip_code') {
      this.zipCodeControl.setValue(postalCode);
      this.inputChanged.emit({fieldName: 'zip_code', fieldValue: postalCode});
    // }

    // if (!this.cityControl.value || fieldName === 'city') {
      this.cityControl.setValue(city);
      this.inputChanged.emit({fieldName: 'city', fieldValue: city});
    // }

    // if (!this.stateControl.value) {
    //   this.stateControl.setValue(state);
    //   this.inputChanged.emit({fieldName: 'state', fieldValue: state});
    // }

    // if (!this.houseNumberControl.value) {
      this.houseNumberControl.setValue(house);
      this.inputChanged.emit({fieldName: 'house_number', fieldValue: house});
    // }

    // if (!this.streetControl.value || fieldName === 'street') {
      this.streetControl.setValue(street);
      this.inputChanged.emit({fieldName: 'street', fieldValue: street});
    // }

    this.countryControl.setValue(countryIso);
    this.inputChanged.emit({fieldName: 'country_iso3', fieldValue: countryIso});
  }

  private setCurrentCountry(country): void {
    if (this.placesRefStreet) {
      this.placesRefStreet.options.componentRestrictions = { country: this.countryTransformFromIso3ToIso2Pipe.transform(country) || '' };
      this.placesRefStreet.reset();
    }

    if (this.placesRefPostalCode) {
      this.placesRefPostalCode.options.componentRestrictions = { country: this.countryTransformFromIso3ToIso2Pipe.transform(country) || '' };
      this.placesRefPostalCode.reset();
    }

    if (this.placesRefCity) {
      this.placesRefCity.options.componentRestrictions = { country: this.countryTransformFromIso3ToIso2Pipe.transform(country) || '' };
      this.placesRefCity.reset();
    }
  }

  private static removeAutocompleteElement(): void {
    document.querySelectorAll('.pac-container').forEach(el => el.remove());
  }

  private getAvailableCountries(): void {
    if (!this.countries) {
      this.availableCountries = [];
      return;
    }

    if (!this.generalType || !this.partnerType || this.partnerType !== PartnersTypeEnum.GENERAL)  {
      this.availableCountries = this.countries;
      return;
    }

    this.availableCountries = this.countries.filter(c => {
      if (this.generalType && this.generalType === 'eu') { return c.isEu; }
      if (this.generalType && this.generalType === 'non_eu') { return !c.isEu; }
      return true;
    })
  }

  get streetControl(): FormControl { return this.form.get('street') as FormControl; }
  get zipCodeControl(): FormControl { return this.form.get('zip_code') as FormControl; }
  get cityControl(): FormControl { return this.form.get('city') as FormControl; }
  get countryControl(): FormControl { return this.form.get('country_iso3') as FormControl; }
  // get stateControl(): FormControl { return this.form.get('state') as FormControl; }
  get houseNumberControl(): FormControl { return this.form.get('house_number') as FormControl; }
  get blockControl(): FormControl { return this.form.get('block') as FormControl; }
  get apartmentsControl(): FormControl { return this.form.get('apartments') as FormControl; }

}
