import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { finalize, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { ProductTypes } from '../../../products/product-types';
import { TableColumnModel } from '../../../../models/table-column.model';
import {
  generatePositionsCardsInfo,
  ProductTypeTranslateKey
} from 'projects/workspace/src/app/outgoing-invoice/pages/positions/positions.config';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { UIStatesEnum } from '../../../../models';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { OutgoingInvoiceModel, OutgoingInvoicePositionModel, PositionsModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CommonModalsActionsEnum, DangerModalComponent, WarningModalComponent } from '../../modals-common';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { HighlightTypeEnum } from '../../../ui-components/table-summary-bar/table-summary-bar.model';
import { CORRECTION_TYPE_LABEL } from 'projects/workspace/src/app/accounting/accounting.constants';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { CHANGE_CORRECTION_CALCULATION_MODAL_DATA, REMOVE_POSITION_MODAL_DATA } from '../../modals.contsans';
import {
  selectOutgoingInvoiceCurrentState,
  selectOutgoingInvoicePositions
} from 'projects/workspace/src/app/outgoing-invoice/store/selectors';
import { OinTypeEnum, OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-outgoing-invoice-edit-position-modal',
  templateUrl: './outgoing-invoice-edit-position-modal.component.html',
  styleUrls: ['./outgoing-invoice-edit-position-modal.component.scss']
})
export class OutgoingInvoiceEditPositionModalComponent implements OnInit, OnDestroy {

  public positionsCardInfoByTypes: {[key in ProductTypes]?: any[]} = {
    [ProductTypes.GOODS]: [] as TableColumnModel[],
    [ProductTypes.SERVICES]: [] as TableColumnModel[],
    [ProductTypes.DIGITAL]: [] as TableColumnModel[],
    [ProductTypes.ARBITRARY]: [] as TableColumnModel[],
    [ProductTypes.CORRECTION]: [] as TableColumnModel[],
    [ProductTypes.CREDIT]: [] as TableColumnModel[],
    [ProductTypes.OVERDUE_FEE]: [] as TableColumnModel[],
  };

  public currentState: UIStatesEnum;
  public UIStates: typeof UIStatesEnum = UIStatesEnum;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public position: OutgoingInvoicePositionModel;
  public positions: OutgoingInvoicePositionModel[];
  public rowIndex: number;
  public positionsByProductType: {[key in ProductTypes]?: any[]};

  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];
  public isReadonly: boolean = true;
  public showControls: boolean = true;

  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;

  public positionsUpdateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<OutgoingInvoiceEditPositionModalComponent>,
    private readonly dialog: MatDialog,
    private readonly invoiceApiService: InvoiceApiService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>,
    private readonly datePipe: DatePipe,
    private productUnitApiService: ProductUnitApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentInvoice: OutgoingInvoiceModel;
      position: OutgoingInvoicePositionModel,
      positions: OutgoingInvoicePositionModel[],
      positionsByProductType: {[key in ProductTypes]?: any[]},
      hasLinkedOFR: boolean,
    }
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.position = this.preparePosition(this.data.position);
    this.positions = this.data.positions;
    this.positionsByProductType = this.data.positionsByProductType;
    this.rowIndex = this.getRowIndex(this.position);

    this.trackInvoiceStateChanges();
    this.trackPositionsChanges();
    this.getPositionsCards();
    this.getProductUnits();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
        this.redrawControls();
      });
  }

  private trackPositionsChanges(): void {
    this.positionsUpdateRequest$.next(true);

    this.store.select(selectOutgoingInvoicePositions)
      .pipe(
        finalize(() => this.positionsUpdateRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((response: PositionsModel) => {
        if (response) {
          this.positions = response[this.position.productType];
          this.positionsByProductType = response;
        }

        this.rowIndex = this.getRowIndex(this.position);
      });
  }

  private trackInvoiceStateChanges(): void {
    this.store.select(selectOutgoingInvoiceCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        if (
          this.data.currentInvoice.status === OutgoingInvoiceListTabsEnum.PAID ||
          this.data.currentInvoice.status === OutgoingInvoiceListTabsEnum.DELETED ||
          this.data.currentInvoice.status === OutgoingInvoiceListTabsEnum.CANCELED
        ) {
          this.isReadonly = true;
        }
      });

  }

  private getCurrentInvoice(): void {
    this.invoiceApiService.getOutgoingInvoiceById(this.data.currentInvoice.invoiceId)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private getPositionsCards(): void {
    this.isLoading$.next(false);
    if (this.data.currentInvoice) {
      this.positionsCardInfoByTypes[this.position.productTypeForGrouping] = generatePositionsCardsInfo(
        // this.translateService,
        this.position.productType || this.position.productTypeForGrouping,
        this.position.productTypeForGrouping,
        UIStatesEnum.CREATE,
        this.data.currentInvoice.billingData.vatDisabled || this.data.currentInvoice.billingData.smallBusiness,
        this.isFinalInvoice,
        this.isRegularInvoice,
      );
    }
  }

  private movePosition(posId: number, moveTo: number): void {
    this.invoiceApiService.changePositionOrder(posId, moveTo, +this.data.currentInvoice.invoiceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        data[this.position.productType].map(pos => {
          if (pos.productId === this.position.productId) {
            this.position = pos;
          }
        });

        this.getCurrentInvoice();
      });
  }

  private deletePositions(ids: number[]): void {
    this.invoiceApiService.deleteOutgoingInvoicePositions(this.data.currentInvoice.invoiceId, ids, this.data.hasLinkedOFR)
      .pipe(takeUntil(this.destroy$))
      .subscribe(()  => {
        this.getCurrentInvoice();
        this.dialogRef.close();
      });
  }

  public actionListHandler({row, moveTo}) {
    this.movePosition(row.positionId, moveTo);
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    this.positions.map((pos, index) => {
      if (pos.positionId === position.positionId) {
        rowIndex = index;
      }
    });
    return rowIndex;
  }

  public deletePositionDialog(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: REMOVE_POSITION_MODAL_DATA,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deletePositions([this.position.positionId]);
      }
    });
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public updatePosition(cell: string, value: string | boolean, force: boolean = false): void {
    // correction position soft validation
    if (cell === 'specifyAmountsSeparately' && !value && !force) {
      if (
        (this.position.netTotalPrice && (this.position.netTotalPrice !== '0.00')) ||
        (this.position.grossTotalPrice && (this.position.grossTotalPrice !== '0.00')) ||
        (this.position.vatTotal && (this.position.vatTotal !== 0))
      ) {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: CHANGE_CORRECTION_CALCULATION_MODAL_DATA
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.updatePosition(cell, value, true);
          } else {
            this.getCurrentInvoice();
          }
        });
        return;
      }
    }

    this.patchPosition(cell, value);
  }

  public patchPosition(fieldName, fieldValue): void {
    this.invoiceApiService.updateOutgoingInvoicePosition(
      this.data.currentInvoice.invoiceId,
      {
        fieldName,
        fieldValue,
        positionId: this.position.positionId
      }
    )
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response: OutgoingInvoicePositionModel) => {
        this.position = this.preparePosition(response);
        this.getPositionsCards();
        this.getCurrentInvoice();
      }, () => {
        if (fieldName === 'unitType') {
          this.redrawControls();
        }
      });
  }

  public redrawControls(): void {
    this.showControls = false;
    setTimeout(() => this.showControls = true);
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(fieldName, event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null);
  }

  private preparePosition(position: OutgoingInvoicePositionModel): OutgoingInvoicePositionModel {
    const negativeCorrection = position.productType === ProductTypes.CORRECTION &&
      position.productTypeForGrouping === ProductTypes.CORRECTION &&
      position.correctionType === 'NEGATIVE';

    return {
      ...position,
      salesIndicator: position.soPosition,
      productLink: {
        label: position.runpleId,
        routerLink: position.positionType === 'REGULAR'
          ? `/products/product-view/${position.productId}`
          : null
      },
      productNameLink: {
        label: position.description,
        routerLink: position.positionType === 'REGULAR'
          ? `/products/product-view/${position.productId}`
          : null
      },
      productTypeTranslate: position.productType
        ? (ProductTypeTranslateKey[position.productType] && this.translateService.instant(ProductTypeTranslateKey[position.productType]))
        : null,
      providedWithin: this.getProvidedWithinValue(position),
      currencyNegativeRow: negativeCorrection,
      ['customHighlight']: {
        [HighlightTypeEnum.NEGATIVE_TO_RED]: {
          netTotalPrice: negativeCorrection,
          vatTotal: negativeCorrection,
          grossUnitPrice: negativeCorrection,
        }
      },
      correctionTypeLabel: position.correctionType
        ? CORRECTION_TYPE_LABEL[position.correctionType]
        : null,
      calculationTypeLabel: position.specifyAmountsSeparately
        ? this.translateService.instant('FORM.MANUALLY')
        : this.translateService.instant('FORM.AUTOMATICALLY'),
    };
  }

  private getProvidedWithinValue(pos: OutgoingInvoicePositionModel): string {
    if (!pos.providedFrom && !pos.providedTo) {
      return '-';
    }

    const providedFrom: string = pos.providedFrom ? this.datePipe.transform(pos.providedFrom, 'dd.MM.yyyy') : '-';
    const providedTo: string = pos.providedTo ? this.datePipe.transform(pos.providedTo, 'dd.MM.yyyy') : '-';

    return `${providedFrom}-${providedTo}`;
  }

  public changePosition({position, positions}): void {
    this.position = this.preparePosition(position);
    this.positions = positions;
    this.getCurrentRowIndex();
    this.getPositionsCards();
    this.getProductUnits();
    this.redrawControls();
  }

  public getCurrentRowIndex(): void {
    this.positionsByProductType[this.position.productTypeForGrouping].map((pos, index) => {
      if (pos.order === this.position.order && pos.positionId === this.position.positionId) {
        this.rowIndex = index;
      }
    });
  }

  get hasLinkedSO(): boolean {
    return !!get(this, 'currentInvoice.linkedDocuments', [])
      .filter(doc => doc.type === DocumentTypesUppercaseEnum.SO).length;
  }

  get isCorrectionType(): boolean {
    return this.position.positionType.toLowerCase() === ProductTypes.CORRECTION;
  }

  get isCreditType(): boolean {
    return this.position.positionType.toLowerCase() === ProductTypes.CREDIT;
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

  get isPrepaymentPosition(): boolean {
    return this.position.productType === ProductTypes.PREPAYMENT;
  }

  get isFinalInvoice(): boolean {
    return this.data.currentInvoice.type === OinTypeEnum.FINAL;
  }

  get isRegularInvoice(): boolean {
    return this.data.currentInvoice.type === OinTypeEnum.REGULAR;
  }

  get isInvoicePosition(): boolean {
    return this.position.productType === ProductTypes.INVOICE;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
