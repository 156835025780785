import { Component } from '@angular/core';

@Component({
  selector: 'rnpl-svg-checkmark',
  templateUrl: './svg-checkmark.component.html',
  styleUrls: ['./svg-checkmark.component.scss']
})

export class SvgCheckmarkComponent {

  constructor() {

  }

}
