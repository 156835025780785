export enum SubscriptionPeriodEnum {
  // DAY = 'day',
  // WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  HALF_YEAR = 'half_year',
  YEAR = 'year',
  CUSTOM_RANGE = 'custom_range',
  UNLIMITED = 'unlimited_duration',
  SPECIFY_END = 'specify_end_date',
}
