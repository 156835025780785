import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { MetricConversionPipe } from '../../rnpl-common';
import { PositionItemTypeEnum } from '../position-card-models.model';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';

@Component({
  selector: 'rnpl-positions-discount',
  templateUrl: './positions-discount.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionsDiscountComponent implements OnChanges {

  public addDiscount = new FormControl();
  public discount = new FormControl(null, {updateOn: 'blur'});
  public discountNet = new FormControl(null, {updateOn: 'blur'});
  public discountGross = new FormControl(null, {updateOn: 'blur'});

  public currencySymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;

  @Input() currency: string = 'EUR';
  @Input() colorState?: string;
  @Input() position: any;
  @Input() isReadOnly: boolean = false;
  @Input() showOnlyControl: boolean = false; // skip info list and always show discount control
  @Input() currencyIsOriginal: boolean;

  @Output() fieldChanged: EventEmitter<{ fieldName: string, fieldValue: any }> = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private metricPipe: MetricConversionPipe,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('position')) {
      this.addDiscount.patchValue(this.position.addDiscount)
      this.setValue(this.position.discount, this.discount, true)
      this.setValue(this.position.discountAmountNet, this.discountNet)
      this.setValue(this.position.discountAmountGross, this.discountGross);
    }
    if (changes.hasOwnProperty('isReadOnly') && this.isReadOnly) {
      this.addDiscount.disable();
      this.discount.disable();
      this.discountNet.disable();
      this.discountGross.disable();
    }

    if (changes.hasOwnProperty('isReadOnly') && !this.isReadOnly) {
      this.addDiscount.enable();
      this.discount.enable();
      this.discountNet.enable();
      this.discountGross.enable();
    }
  }

  private setValue(value: any, control: FormControl, alwaysOriginal = false): void {
    if (!!value || value === 0) {
      const valueModified = this.currencyIsOriginal || alwaysOriginal ? +value : (+value / 1e2).toFixed(2);
      control.setValue(valueModified);
    } else {
      control.setValue(null);
    }
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return this.currencyIsOriginal ? +value : +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public updateInputWithConversion(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({ fieldValue: this.toInteger(fieldValue), fieldName });
  }

  public updateInput(fieldValue, fieldName: string): void {
    this.fieldChanged.emit({ fieldValue, fieldName });
  }

  get discountInfo(): any {
    return [
      {
        label: this.translateService.instant('COMMON.DISCOUNT_WITH_POSTFIX', {postfix: '%'}),
        prop: 'discount',
        suffix: '%',
        type: PositionItemTypeEnum.PRIMARY,
        valueClass: `font-weight-500 ${this.colorState || 'color-danger-red'}`,
      },
      {
        label: 'COMMON.DISCOUNT_NET',
        prop: 'discountAmountNet',
        suffix: this.currencySymbols[this.currency],
        type: PositionItemTypeEnum.CURRENCY,
        currencySourceFormat: this.currencyIsOriginal,
        valueClass: `font-weight-500 ${this.colorState || 'color-danger-red'}`,
        suffixClass: this.colorState || 'color-danger-red',
      },
      {
        label: 'COMMON.DISCOUNT_GROSS',
        prop: 'discountAmountGross',
        suffix: this.currencySymbols[this.currency],
        type: PositionItemTypeEnum.CURRENCY,
        currencySourceFormat: this.currencyIsOriginal,
        valueClass: `font-weight-500 ${this.colorState || 'color-danger-red'}`,
        suffixClass: this.colorState || 'color-danger-red',
      },
    ];
  }

}
