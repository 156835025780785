import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { CustomerTypeEnum, UIStatesEnum } from '../../../../models';
import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/sales-order/store/actions/sales-order.actions';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { EcoOrderModel, EcoOrderPropertiesModel } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/models';
import {
  selectEcoOrder,
  selectEcoOrderCurrentState
} from 'projects/workspace/src/app/e-commerce/modules/eco-orders/store/selectors';
import { EcoOrderListTabsEnum } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/enums';

@Component({
  selector: 'rnpl-eco-add-partner-modal',
  templateUrl: './eco-add-partner-modal.component.html',
  styleUrls: ['./eco-add-partner-modal.component.css']
})
export class EcoAddPartnerModalComponent implements OnInit {

  public ecoOrder: EcoOrderModel;
  public form: FormGroup;
  public isReadonly: boolean = false;
  readonly destroy$: Subject<void> = new Subject();

  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<EcoAddPartnerModalComponent>,
    private store: Store<AppState>,
    private salesOrderApiService: SalesOrderApiService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.trackSalesOrderChanges();
    this.trackSalesOrderStateChanges();
  }

  private trackSalesOrderChanges(): void {
    this.store.select(selectEcoOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((ecoOrder: EcoOrderModel) => {
        this.ecoOrder = ecoOrder;
        this.initForm(ecoOrder.properties);
      });
  }
  public initForm(
    properties: EcoOrderPropertiesModel = {} as EcoOrderPropertiesModel
  ): void {
    this.form = this.fb.group({
      partnerType: [get(properties, 'partnerType'), [Validators.required]],
      companyId: [get(properties, 'company.id'), [Validators.required]],
      companyContactId: [get(properties, 'companyContact.id')],
      email: [get(properties, 'email')],
      phone: [get(properties, 'phone')],
    });

    if (this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER) {
      this.companyContactId.setValidators(Validators.required);
    }

    this.cdr.detectChanges();
    this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));
  }

  public updatePartnerField(partnerData): void {
    this.salesOrderApiService.updateSalesOrder(
      this.ecoOrder.id,
      {
        fieldValue: partnerData.partnerType,
        fieldName: 'properties.' + partnerData.partnerTypeFieldName
      }).subscribe(); // updated via store
  }

  public updateSalesProperties(field: FormInputChangedModel): void {
    field.fieldName = 'properties.' + field.fieldName;
    this.salesOrderApiService.updateSalesOrder(this.ecoOrder.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  private trackSalesOrderStateChanges(): void {
    this.store.select(selectEcoOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }

    if (this.ecoOrder.status === EcoOrderListTabsEnum.OPEN) {
      this.form.get('partnerType').disable(opts);
      this.form.get('companyId').disable(opts);
    }
  }

  get email(): FormControl { return this.form.get('email') as FormControl; }
  get phone(): FormControl { return this.form.get('phone') as FormControl; }
  get companyId(): FormControl { return this.form.get('companyId') as FormControl; }
  get partnerType(): FormControl { return this.form.get('partnerType') as FormControl; }
  get companyContactId(): FormControl { return this.form.get('companyContactId') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
