import { SetupStepsModel, StepsSidebarModel } from './setup-steps.model';
import { InitialSetupStepIdEnum } from './initial-setup.enum';

export const InitialSetupsSteps: SetupStepsModel[] = [
  {
    id: InitialSetupStepIdEnum.LEGAL_INFO,
    step: 0,
    status: 'active',
    title: 'SUBSCRIPTION_PLAN.PROVIDE_DETAILS',
  },
  {
    id: InitialSetupStepIdEnum.SALES_CHANNELS,
    step: 1,
    status: 'active',
    title: 'SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION_OPTIONS',
  },
  {
    id: InitialSetupStepIdEnum.BUSINESS_SPECIFICS,
    step: 2,
    status: 'active',
    title: 'SUBSCRIPTION_PLAN.PRODUCTS_PARTNERS',
  },
  {
    id: InitialSetupStepIdEnum.BANK_ACCOUNT,
    step: 3,
    status: 'active',
    title: 'SUBSCRIPTION_PLAN.BANK_ACCOUNT',
  },
  {
    id: InitialSetupStepIdEnum.CORPORATE_LOGO,
    step: 4,
    status: 'active',
    title: 'SUBSCRIPTION_PLAN.UPLOAD_LOGO',
  },
  // {
  //   id: InitialSetupStepIdEnum.SANDBOX,
  //   step: 5,
  //   status: 'active',
  //   title: 'Start',
  // },
];

export const SidebarContentByActiveStep: {[key: string]: StepsSidebarModel} = {
  [InitialSetupStepIdEnum.LEGAL_INFO]: {
    title: 'SUBSCRIPTION_PLAN.PROVIDE_DETAILS',
    description: 'SUBSCRIPTION_PLAN.PROVIDE_DETAILS_DESC_NEW',
    step: 1,
  },
  [InitialSetupStepIdEnum.SALES_CHANNELS]: {
    title: 'SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION_OPTIONS',
    description: 'SUBSCRIPTION_PLAN.SELECT_SUBSCRIPTION_OPTIONS_DESCR',
    step: 2,
  },
  [InitialSetupStepIdEnum.BUSINESS_SPECIFICS]: {
    title: 'SUBSCRIPTION_PLAN.PRODUCTS_PARTNERS',
    description: 'SUBSCRIPTION_PLAN.PRODUCTS_PARTNERS_DESC_NEW',
    step: 3,
  },
  [InitialSetupStepIdEnum.BANK_ACCOUNT]: {
    title: 'SUBSCRIPTION_PLAN.BANK_ACCOUNT',
    description: 'SUBSCRIPTION_PLAN.BANK_ACCOUNT_DESC_NEW',
    step: 4,
  },
  [InitialSetupStepIdEnum.CORPORATE_LOGO]: {
    title: 'SUBSCRIPTION_PLAN.UPLOAD_LOGO',
    description: 'SUBSCRIPTION_PLAN.UPLOAD_LOGO_DESC_NEW',
    step: 5,
  },
  // [InitialSetupStepIdEnum.SANDBOX]: {
  //   title: 'INITIAL_SETUP.START_MODE_CHOOSE',
  //   description: '',
  //   step: 6,
  // },
};
