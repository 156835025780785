import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { WarehouseSettingsModel } from 'projects/workspace/src/app/warehouse/warehouse-settings-module/models';

@Component({
  selector: 'rnpl-austrian-post-settings-modal',
  templateUrl: './austrian-post-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AustrianPostSettingsModalComponent {

  public form: FormGroup;

  constructor(
    public fb: FormBuilder,
    public deliveryNoteApiService: DeliveryNoteApiService,
    public dialogRef: MatDialogRef<AustrianPostSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      warehouseSettings: WarehouseSettingsModel
    }
  ) {
    this.initForm();
    this.form.patchValue(this.data.warehouseSettings);
  }

  initForm(): void {
    this.form = this.fb.group({
      postClientId: [null, [Validators.required]],
      postOrgUnitGuid: [null, [Validators.required]],
      postOrgUnitId: [null, [Validators.required]],
    });
  }

  public onNoAccountClick(): void {
    // window.open('https://ec.europa.eu/commission/presscorner/detail/de/QANDA_19_5555', '_blank');
  }

  public submit(): void {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.dialogRef.close(this.form.getRawValue());
  }

  get postClientIdControl(): FormControl { return this.form.get('postClientId') as FormControl; }
  get postOrgUnitGuidControl(): FormControl { return this.form.get('postOrgUnitGuid') as FormControl; }
  get postOrgUnitIdControl(): FormControl { return this.form.get('postOrgUnitId') as FormControl; }

}

