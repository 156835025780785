import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { WarehouseSettingsModel } from 'projects/workspace/src/app/warehouse/warehouse-settings-module/models';

@Component({
  selector: 'rnpl-dhl-settings-modal',
  templateUrl: './dhl-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DhlSettingsModalComponent {

  public form: FormGroup;

  constructor(
    public fb: FormBuilder,
    public deliveryNoteApiService: DeliveryNoteApiService,
    public dialogRef: MatDialogRef<DhlSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      warehouseSettings: WarehouseSettingsModel
    }
  ) {
    this.initForm();
    this.form.patchValue(this.data.warehouseSettings);
  }

  initForm(): void {
    this.form = this.fb.group({
      dhlApiToken: [null, [Validators.required]],
      dhlAppId: [null, [Validators.required]],
      dhlUserId: [null, [Validators.required]],
      dhlPassword: [null, [Validators.required]],
    });
  }

  public onNoAccountClick(): void {
    // window.open('https://ec.europa.eu/commission/presscorner/detail/de/QANDA_19_5555', '_blank');
  }

  public submit(): void {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.dialogRef.close(this.form.getRawValue());
  }

  get dhlApiTokenControl(): FormControl { return this.form.get('dhlApiToken') as FormControl; }
  get dhlAppIdControl(): FormControl { return this.form.get('dhlAppId') as FormControl; }
  get dhlUserIdControl(): FormControl { return this.form.get('dhlUserId') as FormControl; }
  get dhlPasswordControl(): FormControl { return this.form.get('dhlPassword') as FormControl; }

}

