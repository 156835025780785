import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { get } from 'lodash';

import { DUE_WITHIN_DAYS } from '../../../accounting/accounting.constants';

@Component({
  selector: 'rnpl-due-within-form',
  templateUrl: './due-within-form.component.html'
})
export class DueWithinFormComponent implements OnChanges {

  public currentValue: number;
  public dontUpdateDueWithinField: boolean;
  public dueUponReceiptList = [
    {value: 1, label: 'FORM.DUE_UPON_RECEIPT'},
    ...DUE_WITHIN_DAYS
  ];

  public defaultControl: FormControl = new FormControl();

  @Input() dueDays = DUE_WITHIN_DAYS;
  @Input() predefinedValues = [7,14,30,60,90];
  @Input() selectLabel = 'COLUMN.DUE_WITHIN';
  @Input() selectPlaceholder = 'FORM.SELECT_DUE_WITHIN';
  @Input() dueWithinControl: FormControl;
  @Input() currentDueWithin: number;
  @Input() readOnly: boolean;
  @Input() hasDueUponReceipt: boolean = false;

  @Output() fieldChanged: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('currentDueWithin')) {
      this.currentValue = this.currentDueWithin;

      if (this.dontUpdateDueWithinField) {
        this.defaultControl.setValue('COMMON.USER_DEFINED');
      }
    }

    // add due upon receipt option
    if (changes.hasOwnProperty('hasDueUponReceipt')) {
      this.dueDays = this.dueUponReceiptList;
    }

    if (changes.hasOwnProperty('readOnly')) {
      if (this.dontUpdateDueWithinField) {
        this.defaultControl.setValue('COMMON.USER_DEFINED');
      }

      if (this.readOnly) {
        this.defaultControl.disable()
      } else {
        this.defaultControl.enable()
      }
    }
  }

  public updateField(fieldValue: any): void {
    this.fieldChanged.emit(fieldValue);
    if (!this.getIsDueWithinPredefined()) {
      this.dontUpdateDueWithinField = false;
    }
  }

  public updateDueWithinField(fieldValue: any): void {
    if (!!fieldValue) {
      this.dontUpdateDueWithinField = false;
      this.fieldChanged.emit(fieldValue);
      this.currentValue = fieldValue;
      this.dueWithinControl.setValue(fieldValue)
    } else {
      this.dontUpdateDueWithinField = true;
      this.dueWithinControl.setValue(this.currentValue);
      this.defaultControl.setValue('COMMON.USER_DEFINED');
    }
  }

  public updateValidity(): void {
    this.dueWithinControl.markAsUntouched();
    this.dueWithinControl.updateValueAndValidity();
  }

  public getIsDueWithinPredefined(): boolean {
    return this.predefinedValues.includes(this.currentDueWithin);
  }

  get isDueWithinPredefined(): boolean {
    return this.getIsDueWithinPredefined();
  }

  get dueWithin() { return get(this, 'dueWithinControl'); }

}
