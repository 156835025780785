import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, finalize, startWith, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { SetupStepsModel, StepsSidebarModel } from '../../setup-steps.model';
import { InitialSetupStepIdEnum } from '../../initial-setup.enum';
import { InitialSetupsSteps, SidebarContentByActiveStep } from '../../initial-setup.config';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { loadingRequestsCount } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { BACKGROUND_CONTENT, TimeOfDay } from '../../../modals/modals-sign-in/sign-in.config';
import { ToasterService } from '../../../ui-components/toaster';

@Component({
  selector: 'rnpl-initial-setup',
  templateUrl: './initial-setup.component.html',
  styleUrls: ['./initial-setup.component.scss']
})
export class InitialSetupComponent extends InitialSetupBaseComponent implements OnInit {

  public backGroundContent = BACKGROUND_CONTENT;
  public timeOfDay = TimeOfDay();
  public initialSetupStepIdEnum = InitialSetupStepIdEnum;
  public initialSetupSteps: SetupStepsModel[] = InitialSetupsSteps;
  public sidebarContentByActiveStep: {[key: string]: StepsSidebarModel} = SidebarContentByActiveStep;
  public setupErrors: HttpErrorResponse = null;
  public isMobile: boolean;
  public showScrollButton: boolean = false;

  public currentStep$: BehaviorSubject<string> = new BehaviorSubject<string>(InitialSetupStepIdEnum.HELLO);
  readonly loadingRequestsCount$: Observable<number> = this.store.select(loadingRequestsCount);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly updateRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @ViewChild('stepLegalInfo', {static: false}) stepLegalInfo;
  @ViewChild('stepSalesChannels', {static: false}) stepSalesChannels;
  @ViewChild('stepBusinessSpecific', {static: false}) stepBusinessSpecific;
  @ViewChild('stepCorporateLogo', {static: false}) stepCorporateLogo;
  @ViewChild('stepBankAccount', {static: false}) stepBankAccount;
  @ViewChild('stepSandbox', {static: false}) stepSandbox; // todo: sandbox temporary
  @ViewChild('scrollbar', {static: false}) scrollbar: PerfectScrollbarComponent;

  constructor(
    public administrationsApiService: AdministrationsApiService,
    public router: Router,
    public toasterService: ToasterService,
    public readonly store: Store<AppState>,
    private deviceService: DeviceDetectorService,
  ) {
    super(store, router, administrationsApiService);
    this.isMobile = this.deviceService.isMobile();
  }

  ngOnInit() {
    this.administrationsApiService.getCompanyProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    this.currentStep$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => {
          if (!!this.scrollbar) {
            const scrollContainerElement = this.scrollbar.directiveRef.elementRef.nativeElement;
            this.showScrollButton = scrollContainerElement.scrollHeight > scrollContainerElement.clientHeight;
          }
        });
      });
  }

  public scrollToBottom(): void {
    if (!!this.scrollbar) {
      this.scrollbar.directiveRef.scrollToBottom(0, 200);
    }
  }

  public startInitialSetup(): void {
    this.currentStep$.next(InitialSetupStepIdEnum.LEGAL_INFO);
  }

  public backToSetup(): void {
    this.currentStep$.next(InitialSetupStepIdEnum.CORPORATE_LOGO);
  }

  public changeStep(direction?: 'prev' | 'next'): void {
    if (this.updateRequest$.getValue()) { return; }

    let nextStepIndex: number;

    this.initialSetupSteps.map((step, index) => {
      if (this.currentStep$.getValue() === step.id && !!direction) {

        if (direction) {
          switch (direction) {

            case 'next':
              if (index !== this.initialSetupSteps.length - 1) {
                nextStepIndex = index + 1;
              } else {
                nextStepIndex = index;
              }
              break;

            case 'prev':
              if (index > 0) {
                nextStepIndex = index - 1;
              } else {
                nextStepIndex = 0;
              }
              break;
          }
        }
      }
    });

    if (direction === 'next') {
      if (!this.validateSteps()) {
        return;
      }
      this.updateRequest$.next(true);
      this.updateStep$()
        .pipe(finalize(() => this.updateRequest$.next(false)))
        .subscribe(() => {
          if (this.validateSteps()) { // additional check after response
            this.initialSetupSteps[nextStepIndex - 1].status = 'complete';
            this.initialSetupSteps = [...this.initialSetupSteps];
            this.currentStep$.next(this.initialSetupSteps[nextStepIndex].id);
          }
        });
    }

    if (direction === 'prev') {
      this.currentStep$.next(this.initialSetupSteps[nextStepIndex].id);
    }
  }

  public goToStep(clickedStep: SetupStepsModel, prevStep: SetupStepsModel): void {
    const currentStep: SetupStepsModel = this.initialSetupSteps.find(step => step.id === this.currentStep$.getValue());

    // change step is not allowed
    if (clickedStep.id === currentStep.id) { return; }
    if (this.updateRequest$.getValue()) { return; }

    // go back
    if (clickedStep.step < currentStep.step) {
      this.currentStep$.next(clickedStep.id);
      return;
    }

    // go forward
    if (clickedStep.status === 'complete' || prevStep.status === 'complete') {
      if (!this.validateSteps()) {
        return;
      }
      this.updateRequest$.next(true);
      this.updateStep$()
        .pipe(finalize(() => this.updateRequest$.next(false)))
        .subscribe(() => {
          if (this.validateSteps()) { // additional check after response
            this.currentStep$.next(clickedStep.id);
          }
        });
    }
  }

  public validateSteps(): boolean {
    let valid = false;

    switch (this.currentStep$.getValue()) {
      case InitialSetupStepIdEnum.LEGAL_INFO:
        valid = this.stepLegalInfo.getStepValidation();
        break;
      case InitialSetupStepIdEnum.SALES_CHANNELS:
        valid = this.stepSalesChannels.getStepValidation();
        break;
      case InitialSetupStepIdEnum.BUSINESS_SPECIFICS:
        valid = this.stepBusinessSpecific.getStepValidation();
        break;
      case InitialSetupStepIdEnum.BANK_ACCOUNT:
        valid = this.stepBankAccount.getStepValidation();
        break;
      case InitialSetupStepIdEnum.CORPORATE_LOGO:
        // valid = false;
        // todo: sandbox temporary
        valid = true;
        break;
      case InitialSetupStepIdEnum.SANDBOX:
        valid = false;
        break;
      // todo: sandbox temporary
    }

    if (!valid) {
      this.toasterService.notify({type: 'error', message: 'INITIAL_SETUP.PROVIDE_REQUIRED_INFO'});
    }

    return valid;
  }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    let request$;

    switch (this.currentStep$.getValue()) {
      case InitialSetupStepIdEnum.LEGAL_INFO:
        request$ = this.stepLegalInfo.updateStep$();
        break;
      case InitialSetupStepIdEnum.SALES_CHANNELS:
        request$ = this.stepSalesChannels.updateStep$();
        break;
      case InitialSetupStepIdEnum.BUSINESS_SPECIFICS:
        request$ = this.stepBusinessSpecific.updateStep$();
        break;
      case InitialSetupStepIdEnum.BANK_ACCOUNT:
        request$ = this.stepBankAccount.updateStep$();
        break;
        // todo: sandbox temporary
      case InitialSetupStepIdEnum.CORPORATE_LOGO:
        request$ = of(true);
        break;
      // todo: sandbox temporary
    }

    return request$;
  }

  public onStartClick(): void {
    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);
    this.setupErrors = null;
    this.currentStep$.next(InitialSetupStepIdEnum.FINISHING);

    this.administrationsApiService.finishCompanyProfileSetup(
      this.companyProfile.workspaceId,
      false
      // this.stepSandbox.startModeControl.value !== 'sandbox'
    )
      .pipe(
        catchError((error) => {
          this.setupErrors = error;
          this.currentStep$.next(InitialSetupStepIdEnum.ERROR);
          this.submitRequest$.next(false);
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        interval(2000)
          .pipe(
            startWith(0),
            takeWhile(() => !this.companyProfile.tracking.completedAt),
            takeUntil(this.destroy$),
          )
          .subscribe(() => {
            this.administrationsApiService.getCompanyProfile()
              .pipe(
                catchError((error) => {
                  this.submitRequest$.next(false);
                  return throwError(error);
                }),
                tap((companyProfile: CompanyProfile) => {
                  if (companyProfile.tracking.completedAt && companyProfile.entityState === 'EXISTS') {
                    this.submitRequest$.next(false);
                  }
                })
              )
              .subscribe();
          });
      });
  }

  public get showSetupFlow(): boolean {
    const currentStep: string = this.currentStep$.getValue();
    return currentStep === InitialSetupStepIdEnum.LEGAL_INFO
      || currentStep === InitialSetupStepIdEnum.SALES_CHANNELS
      || currentStep === InitialSetupStepIdEnum.BUSINESS_SPECIFICS
      || currentStep === InitialSetupStepIdEnum.BANK_ACCOUNT
      || currentStep === InitialSetupStepIdEnum.CORPORATE_LOGO
      || currentStep === InitialSetupStepIdEnum.SANDBOX;
  }

}
