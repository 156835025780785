import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { get } from 'lodash';

import { TimeTrackingRecordGroupingEnum } from 'projects/workspace/src/app/time-tracking/enums';


@Component({
  selector: 'rnpl-time-tracking-grouping-form',
  templateUrl: './time-tracking-grouping-form.component.html'
})
export class TimeTrackingGroupingFormComponent implements OnInit, OnChanges {

  @Input() public formData: any;
  @Input() public hideGroupingForm: boolean;

  public form: FormGroup;
  public recordGroupingEnum = TimeTrackingRecordGroupingEnum;

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('formData')) {
      this.initForm();
    }
  }

  public initForm(): void {
    this.form = this.fb.group({
      grouping: [get(this, 'formData.grouping', TimeTrackingRecordGroupingEnum.COMBINED)],
      providedServicesEnabled: [get(this, 'formData.providedServicesEnabled', false)],
    });
  }

  public getFormData(): any {
    const formData = this.form.getRawValue();
    return {
      ...formData,
      providedServicesEnabled: !formData.providedServicesEnabled
    };
  }

  get groupingControl(): FormControl { return this.form.get('grouping') as FormControl; }
  get providedServicesEnabledControl(): FormControl { return this.form.get('providedServicesEnabled') as FormControl; }

}
