import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserModel } from 'common/src/models';
import { TableColumnModelExtended } from '../../../../../models/table-column.model';

@Component({
  selector: 'rnpl-column-with-avatar-arrow-right',
  templateUrl: './column-with-avatar-arrow-right.component.html',
  styleUrls: ['./column-with-avatar-arrow-right.component.scss']
})
export class ColumnWithAvatarArrowRightComponent {

  @Output() textClick: EventEmitter<string> = new EventEmitter();

  @Input() value: {label: string, routerLink: string, user: UserModel};
  @Input() column: TableColumnModelExtended;

  onTextClick(): void {
    this.textClick.emit(this.value.label);
  }
}
