import { Component, Input, OnInit } from '@angular/core';

import { PriceChangesEnum, TradeProductPurchaseInfoModel } from 'projects/workspace/src/app/trade';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { Observable } from 'rxjs';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';

@Component({
  selector: 'rnpl-view-summary-purchase-data',
  templateUrl: './view-summary-purchase-data.component.html'
})
export class ViewSummaryPurchaseDataComponent implements OnInit {

  @Input() purchaseData: TradeProductPurchaseInfoModel = null;

  public priceChangesEnum = PriceChangesEnum;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
  }

}
