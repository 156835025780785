import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';
import { Store } from '@ngrx/store';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ReportingPeriodsApiService } from 'projects/workspace/src/app/reporting-periods/services/reporting-periods-api.service';
import {
  ReportingPeriodModel,
  ReportingPeriodsPreferencesModel
} from 'projects/workspace/src/app/reporting-periods/models';
import { CustomSearchFn, getContentDispositionFileName } from '../../../rnpl-common/helpers';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FileService } from '../../../../services/file.service';
import { FileUploadParams } from '../../../../models/file-upload-params.model';



@Component({
  selector: 'rnpl-reporting-periods-report-period-modal',
  templateUrl: './reporting-periods-report-period-modal.component.html'
})
export class ReportingPeriodsReportPeriodModalComponent extends BaseModalComponent implements OnInit {

  public reportingPeriodPreferences: ReportingPeriodsPreferencesModel;
  public selectedPeriod: ReportingPeriodModel = null;
  public selectedTaxAdvisor: any = null;
  public hiddenTo: string = null;
  public sendTo: string = null;
  public isHiddenCopy = false;
  public wid: number;

  readonly reportingPeriods$: BehaviorSubject<ReportingPeriodModel[]> = new BehaviorSubject<ReportingPeriodModel[]>([]);
  readonly customers$: Observable<any[]>;
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly confirmRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly downloadFileRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public customSearchFn = CustomSearchFn;

  constructor(
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private reportingPeriodsApiService: ReportingPeriodsApiService,
    private partnersApiService: PartnersApiService,
    private fileService: FileService,
    private fileSaverService: FileSaverService,
    public dialogRef: MatDialogRef<ReportingPeriodsReportPeriodModalComponent>,
    private readonly store: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) public data: {
      reportingPeriod: ReportingPeriodModel
    }
  ) {
    super(toasterService);
    this.customers$ = this.partnersApiService.getCombinedPartnersList();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
        this.reportingPeriodsApiService.getReportingPeriodsPreferences(this.wid)
          .pipe(takeUntil(this.destroy$))
          .subscribe(preferences => {
            this.reportingPeriodPreferences = preferences;
            this.selectedTaxAdvisor = preferences.taxAdvisor;
            this.sendTo = preferences.taxAdvisorEmailTo;
            this.setActiveReportingPeriod();
          });
      });

    this.reportingPeriodsApiService
      .getReportingPeriodsListByStatuses(['OPEN', 'PENDING', 'PARTIALLY_REPORTED', 'UNREPORTED', 'OVERDUE'], 'period.view')
      .pipe(takeUntil(this.destroy$))
      .subscribe((periods: ReportingPeriodModel[]) => {
        this.reportingPeriods$.next(periods);
        this.setActiveReportingPeriod();
      });
  }

  ngOnInit(): void {
    if (this.data && this.data.reportingPeriod) {
      this.selectedPeriod = this.data.reportingPeriod;
      this.selectedTaxAdvisor = this.data.reportingPeriod.advisor;
      this.sendTo = this.data.reportingPeriod.advisorEmail;
    }
  }

  public setActiveReportingPeriod(): void {
    const periods = this.reportingPeriods$.getValue();
    if (this.selectedPeriod || !periods.length || !this.reportingPeriodPreferences) {
      return;
    }

    const today = new Date();
    const activePeriods = periods
      .filter(period => period.period.type === this.reportingPeriodPreferences.period.period)
      .filter(period => (new Date(period.period.firstDay) < today) && (new Date(period.period.lastDay) > today));

    this.selectedPeriod = activePeriods.length ? activePeriods[0] : null;
  }

  public onDownloadReportPeriod(): void {
    if (!this.selectedPeriod) { return; }
    if (this.downloadFileRequest$.getValue()) { return; }
    this.toasterService.notifyRequestMessage(
      {key: 'generateReportingPeriod', message: 'REPORTING_PERIODS.REPORT_IS_EXPORTING'}
    );
    this.downloadFileRequest$.next(true);

    this.reportingPeriodsApiService.getDownloadReportingPeriodsExportParams(this.wid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileParams: FileUploadParams) => {
        this.downloadFile(fileParams.url, [this.selectedPeriod.id]);
      });
  }

  downloadFile(url, ids: number[]) {
    this.fileService.downloadFilePostRequest(url, {ids})
      .pipe(
        finalize(() => {
          this.toasterService.hideRequestMessage('generateReportingPeriod');
          this.downloadFileRequest$.next(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((res: any) => {
        this.fileSaverService.save(
          res.body,
          getContentDispositionFileName(res.headers.get('Content-Disposition'))
        );
      });
  }

  public onReportPeriod(): void {
    if (this.confirmRequest$.getValue()) { return; }
    this.confirmRequest$.next(true);

    this.reportingPeriodsApiService.reportSinglePeriod(
      this.wid,
      this.selectedTaxAdvisor,
      this.selectedPeriod
        ? this.selectedPeriod.id
        : null,
      this.sendTo ? this.sendTo.split(',') : [],
      this.hiddenTo ? this.hiddenTo.split(',') : [],
    )
      .pipe(
        finalize(() => this.confirmRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        if (res) {
          this.dialogRef.close(res);
        }
      });
  }

}
