import {
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CdkDrag, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import {
  CellDataModel,
  CellSize,
  ComposerCell,
  ComposerDragStartedEvent,
  ComposerPlaceholder
} from '../../interfaces';
import { ComposerCellDirective } from '../../directives/composer-cell.directive';


@Component({
  selector: 'rnpl-composer-container',
  templateUrl: './composer-container.component.html',
  styleUrls: ['./composer-container.component.scss']
})
export class ComposerContainerComponent implements AfterContentInit {

  @Input()
  dataGrid: Array<Array<CellDataModel<any>>>;

  @Output()
  layoutChanged: EventEmitter<Array<Array<CellDataModel<any>>>> = new EventEmitter<Array<Array<CellDataModel<any>>>>();

  @Output()
  dragStarted: EventEmitter<ComposerDragStartedEvent> = new EventEmitter<ComposerDragStartedEvent>();

  @Output()
  dragEnded: EventEmitter<CdkDrag> = new EventEmitter<CdkDrag>();

  @Output()
  dragMoved: EventEmitter<CdkDragMove<any>> = new EventEmitter<CdkDragMove<any>>();

  @Output()
  cellSelected: EventEmitter<CellDataModel<any>> = new EventEmitter<CellDataModel<any>>();


  @HostBinding('class.drag-small')
  get isDragSmall() {
    return this.dragSize === 'small';
  }

  @HostBinding('class.drag-medium')
  get isDragMedium() {
    return this.dragSize === 'medium';
  }

  @HostBinding('class.drag-wide')
  get isDragWide() {
    return this.dragSize === 'wide';
  }

  @ContentChild(ComposerCellDirective, {static: true})
  public cellDirective: ComposerCellDirective;

  public dragItem: CdkDrag;
  public dragSize: CellSize;

  get placeholder(): ComposerPlaceholder {
    return this._placeholder;
  }

  get sourceCell(): ComposerCell {
    return this._sourceCell;
  }

  @HostBinding('class.active')
  private isActive: boolean = false;

  private rect: DOMRect;

  private itemMoveSub: Subscription;

  private _placeholder: ComposerPlaceholder;

  private _sourceCell: ComposerCell;

  constructor(private hostElement: ElementRef) {
  }

  ngAfterContentInit(): void {
    this.updateRect();
  }

  selectCell(cell: CellDataModel<any>): void {
    this.cellSelected.emit(cell);
  }

  /**
   * Stores current placeholder
   *
   * @param placeholder An placeholder from grid
   */
  setPlaceholder(placeholder: ComposerPlaceholder): void {
    this._placeholder = placeholder;
    this._placeholder.isTarget = true;
  }

  /**
   * Resets current placeholder
   *
   * @param placeholder An placeholder from grid
   */
  removePlaceholder(placeholder: ComposerPlaceholder): void {
    placeholder.isTarget = false;
    if (this._placeholder === placeholder) {
      this._placeholder = null;
    }
  }

  /**
   * Removes cell from grid row
   *
   * @param rowIndex An index of row
   * @param cellIndex An index of cell
   */
  removeCell(rowIndex: number, cellIndex: number): void {
    this.dataGrid[rowIndex].splice(cellIndex, 1);
    if (!this.dataGrid[rowIndex].length) {
      this.dataGrid.splice(rowIndex, 1);
    }
  }

  /**
   * Inserts row to grid
   *
   * @param rowIndex An index of row
   * @param cell A cell data model
   */
  insertRow(rowIndex: number, cell: CellDataModel<any>): void {
    this.dataGrid.splice(rowIndex, 0, []);
    this.insertCell(rowIndex, 0, cell);
  }

  /**
   * Inserts cell to grid row
   *
   * @param rowIndex An index of row
   * @param cellIndex An index of cell
   * @param cell A cell data model
   */
  insertCell(rowIndex: number, cellIndex: number, cell: CellDataModel<any>): void {
    this.dataGrid[rowIndex].splice(cellIndex, 0, cell);
  }

  reload(): void {
    this.dataGrid = [...this.dataGrid];
    this.layoutChanged.emit(this.dataGrid);
  }

  private moveCell(): void {
    if (this._placeholder.direction === 'y') {

      let removeRowIndex = this._sourceCell.rowIndex;
      if (this._placeholder.index <= removeRowIndex) {
        removeRowIndex++;
      }

      this.insertRow(this._placeholder.index, this._sourceCell.data);
      this.removeCell(removeRowIndex, this._sourceCell.index);
      this.layoutChanged.emit(this.dataGrid);
      return;
    }

    if (this._placeholder.rowIndex !== this._sourceCell.rowIndex) {
      this.insertCell(this._placeholder.rowIndex, this._placeholder.index, this._sourceCell.data);
      this.removeCell(this._sourceCell.rowIndex, this._sourceCell.index);
    } else {
      moveItemInArray(this.dataGrid[this._sourceCell.rowIndex], this._sourceCell.index, this._placeholder.index);
    }
    this.reload();
  }

  public activate(event: ComposerDragStartedEvent): void {
    this.updateRect();
    this.dragItem = event.dragItem;
    this.dragSize = event.dragItemSize;
    this._sourceCell = event.sourceCell;


    this.itemMoveSub = this.dragItem.moved
      .pipe(auditTime(100))
      .subscribe(movedEvent => this.handleMove(movedEvent));

    this.dragItem.released
      .subscribe(endedEvent => this.deactivate(endedEvent.source));

    this.isActive = true;
    this.dragStarted.emit(event);
  }

  public deactivate(dragItem: CdkDrag): void {
    if (this.placeholder) {
      this.moveCell();
    }
    dragItem.reset();
    if (this.sourceCell) {
      this.sourceCell.isEmpty = false;
    }

    this.dragEnded.emit(dragItem);
    this.dragItem = null;
    this.dragSize = null;
    this._placeholder = null;
    this._sourceCell = null;
    this.itemMoveSub.unsubscribe();
    this.isActive = false;
  }

  private updateRect(): void {
    this.rect = this.hostElement.nativeElement.getBoundingClientRect();
  }

  private handleMove(event: CdkDragMove): void {
    if (event.pointerPosition.y < this.rect.top || event.pointerPosition.y > this.rect.bottom) {
      return;
    }
    if (event.pointerPosition.x < this.rect.left || event.pointerPosition.x > this.rect.right) {
      return;
    }
    this.dragMoved.emit(event);
  }
}
