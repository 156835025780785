import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency-custom';


import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { SafePipeModule } from 'safe-pipe';
import { EcoDeliveryFormComponent } from './eco-delivery-form.component';
import { CommonAddressFormModule } from '../../../../../shared/forms/common-address-form/common-address-form.module';
import { DeliveryServiceControlModule } from '../../../../../shared/forms/delivery-service-control/delivery-service-control.module';


@NgModule({
  declarations: [
    EcoDeliveryFormComponent,
  ],
  exports: [
    EcoDeliveryFormComponent
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    RnplCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    CommonAddressFormModule,
    TranslateModule,
    NgxCurrencyModule,
    SafePipeModule,
    DeliveryServiceControlModule
  ],
})
export class EcoDeliveryFormModule { }
