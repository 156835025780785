import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { ModalNameEnum } from '../../../../models/modal-name.enum';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';
import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import {
  BillingInfoHints,
  RemarkHints,
  SelectPartnersHints
} from 'projects/workspace/src/app/sales-order/components/sales-order-mega-hint/hints.config';

@Component({
  selector: 'rnpl-sales-order-modal-hints',
  templateUrl: './sales-order-modal-hints.component.html'
})
export class SalesOrderModalHintsComponent extends BaseMegaHintsComponent implements OnInit {
  @Input() modalName: ModalNameEnum;

  public hintsListByModalName = {
    [ModalNameEnum.SELECT_PARTNER]: SelectPartnersHints,
    [ModalNameEnum.REMARK]: RemarkHints,
    [ModalNameEnum.BILLING_INFO]: BillingInfoHints,
    // [ModalNameEnum.DELIVERY_INFO]: DeliveryInfoHints,
  }

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'APP.SALES_ORDER';
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }

    this.initHints();
  }

  private initHints(): void {
    this.screenName = 'sales_orders_view_draft';
    this.hintListByName = this.hintsListByModalName[this.modalName];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          this.displayHints();

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.shepherdService.start();
          }
        }
      });
  }
}
