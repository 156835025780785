import {
  trigger,
  transition,
  style,
  query,
  group,
  animate,
  keyframes,
} from '@angular/animations';

const animationTiming: number = 325;

export const routeScaleAnimation =
  trigger('routeAnimations', [
    transition('signInRouteAnimation <=> StartScreenRouteAnimation', [
      query(':enter, :leave',  [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
        }),
      ], { optional: true }),
      group([
        query(':enter', [
          animate(`${animationTiming}ms ease`, keyframes([
            style({ opacity: 0.5, offset: 0 }),
            style({ opacity: 0.5, offset: 0.5 }),
            style({ opacity: 1, offset: 1 }),
          ])),
        ], { optional: true }),
        query(':leave', [
          animate(`${animationTiming}ms ease`, keyframes([
            style({ transform: 'scale(1)', offset: 0 }),
            style({ opacity: 0.5, transform: 'scale(2)', offset: 0.5 }),
            style({ opacity: 0, transform: 'scale(3)', offset: 1 }),
          ])),
        ], { optional: true })
      ]),
    ])
  ]);
