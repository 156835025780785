import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'rnpl-select',
  templateUrl: './rnpl-select.component.html',
  styleUrls: ['./rnpl-select.component.scss']
})
export class RnplSelectComponent {

  @Input()
  public label: string;

  @Input()
  public optional: boolean;

  @Input()
  public control: FormControl;

  @Input()
  public searchable: boolean;

  @Input()
  public items: Array<any>;

  @Input()
  public placeholder: string;

  @Input()
  public appendTo: string;

  @Input()
  public dropdownPosition: string = 'auto';

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  updateValueEmit(value): void {
    this.updateValue.emit({
      value: value
    })
    if (this.control) {
      this.control.setValue(value);
    }
  }
}
