import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';


@Injectable()
export class CompanyProfileLoadedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly administrationsApiService: AdministrationsApiService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.administrationsApiService.getCompanyProfile().pipe(
      first(),
      switchMap(() => of(true)),
      catchError(() => {
        this.router.navigate(['/']);
        return of(false);
      }),
    );
  }
}


