import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-table-total-info-skeleton',
  templateUrl: './table-total-info-skeleton.component.html',
  styleUrls: ['./table-total-info-skeleton.component.scss']
})
export class TableTotalInfoSkeletonComponent {

  @Input() dark: boolean = false;

  constructor() { }
}
