import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import { createUserHints } from 'projects/workspace/src/app/hrm/team-hints.config';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';

@Component({
  selector: 'rnpl-create-new-user-hints',
  templateUrl: './create-new-user-hints.component.html',
})
export class CreateNewUserHintsComponent extends BaseMegaHintsComponent implements OnInit, OnChanges {
  @Input() public setUpPermission: boolean;
  @Input() public screenName: string;

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'TEAM.CREATE_EMPLOYEE';
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('setUpPermission')) {
      clearInterval(this.countdownTimer);
      if (this.shepherdService.isActive) {
        this.shepherdService.cancel();
        this.animationStateIsPaused$.next(false);
      }

      this.initHints();
    }
  }

  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = createUserHints(this.setUpPermission);

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length && !this.shepherdService.isActive) {
            this.shepherdService.start();
          }
        }
      });
  }
}
