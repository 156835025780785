import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';

@Component({
  selector: 'rnpl-sync-payments-modal',
  templateUrl: './sync-payments-modal.component.html'
})
export class SyncPaymentsModalComponent extends BaseModalComponent implements OnDestroy {
  public toControl = new FormControl(null, [Validators.required]);
  public fromControl = new FormControl(null, [Validators.required]);

  constructor(
    public toasterService: ToasterService,
    public datePipe: DatePipe,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<SyncPaymentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      minDates: string[]
    }
  ) {
    super(toasterService);
  }

  public submit(): void {
    this.toControl.markAllAsTouched();
    this.fromControl.markAllAsTouched();
    this.toControl.updateValueAndValidity();
    this.fromControl.updateValueAndValidity();

    if (this.toControl.invalid || this.fromControl.invalid) {
      return;
    }
    let returnFlag = false;
    if (this.toControl.value > new Date()) {
      this.toasterService.notify({type: 'error', message: 'CARDLESS_BA.TO_MORE_TODAY_VALIDATION'});
      returnFlag = true;
    }
    if (this.fromControl.value > new Date()) {
      this.toasterService.notify({type: 'error', message: 'CARDLESS_BA.FROM_MORE_TODAY_VALIDATION'});
      returnFlag = true;
    }
    let minDate;
    const hasSomeMinDate = this.data.minDates.some(md => {
      if (this.fromControl.value < new Date(md)) {
        minDate = md;
      }
      return this.fromControl.value < new Date(md);
    })

    if (hasSomeMinDate && minDate) {
      this.toasterService.notify(
        {
          type: 'error',
          message: this.translateService.instant(
            'CARDLESS_BA.FROM_LESS_MIN_DATE_VALIDATION',
            {minDate: this.datePipe.transform(new Date(minDate), 'dd.MM.yyyy')}
          )
        }
      );
      returnFlag = true;
    }
    if (returnFlag) {
      return;
    }

    this.dialogRef.close({
      dateFrom: this.datePipe.transform(this.fromControl.value, 'yyyy-MM-dd'),
      dateTo: this.datePipe.transform(this.toControl.value, 'yyyy-MM-dd'),
    });
  }

}
