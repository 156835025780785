import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { FamilyModel } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { NavBarBtnModel } from '../../../../models';
import { FamilyWizardContext } from '../../../products/family-wizard';
import { WizardService } from '../../../wizard';
import { ProductsService } from '../../../products/products.service';
import { NavBarButtons } from './category-modal.config';

type DataType = {
  family: Partial<FamilyModel>;
  isRoot: boolean;
}

@Component({
  selector: 'rnpl-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent extends BaseModalComponent {

  public form: FormGroup;
  public actionButtons: NavBarBtnModel[] = NavBarButtons;
  public categoryName: string = null;
  public categoryDescription: string = null;

  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    public fb: FormBuilder,
    public location: Location,
    public wizardService: WizardService,
    public productsService: ProductsService,
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<CategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      // gl_account_id_purchase_credit: [],
      // gl_account_id_purchase_debit: [],
      // gl_account_write_off_debit: [],
      // gl_product_type_id: [],
      product_creation_forbidden: [false],
      long_term_period_of_use: [false],
      period_of_use: [2, [Validators.required, Validators.min(1.01)]],
      funds_write_off_method: [null, Validators.required],
      funds_write_off_rate: [null, [Validators.required, Validators.min(0.01), Validators.max(99.99)]],
    });

    // this.productCreationForbiddenControl.valueChanges
    //   .pipe(takeUntil(this._destroy))
    //   .subscribe((value: boolean) => {
    //      if (value) {
    //        this.glAccountIdPurchaseDebitControl.disable();
    //        this.glAccountIdPurchaseCreditControl.disable();
    //      } {
    //       this.glAccountIdPurchaseDebitControl.enable();
    //       this.glAccountIdPurchaseCreditControl.enable();
    //     }
    //   });
  }

  public actionsEvent(e: string): void {
    this[e]();
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onCreateClick(): void {
    this.submitCategory(false);
  }

  onCreateAndSetupClick(): void {
    this.submitCategory(true);
  }

  public submitCategory(isSetup: boolean): void {
    const category: FamilyModel = {
      type: 'custom', // hardcoded value for workspace app
      ...this.form.getRawValue(),
      translations: [
        {
          language: {code_iso3: 'eng'},
          title: this.categoryName,
          description: this.categoryDescription
        }
      ]
    };

    if (this.data.family.parent_id) {
      category.parent_id = this.data.family.parent_id as any;
    }

    const request$ = this.data.isRoot
      ? this.productsService.createRootFamily(category, this.data.family.products_type as ProductTypes)
      : this.productsService.createFamily(category);

    request$
      .pipe(
        finalize(() => {
          this.btnToClearLoadingStatus$.next('onCreateClick');
        }),
        takeUntil(this._destroy)
      )
      .subscribe(response => {
        if (isSetup) {
          this.wizardService.edit<FamilyWizardContext>(
            'family',
            { family: response.data },
            this.location.path(),
          );
          this.onCloseClick();
        } else {
          this.dialogRef.close(response.data);
        }
      }, error => {
        this.toasterService.notify({type: 'error', message: error.error.message});
      });
  }

  // public get glProductTypeIdControl(): FormControl { return this.form.get('gl_product_type_id') as FormControl; }
  // public get glAccountIdPurchaseDebitControl(): FormControl { return this.form.get('gl_account_id_purchase_debit') as FormControl; }
  // public get glAccountIdPurchaseCreditControl(): FormControl { return this.form.get('gl_account_id_purchase_credit') as FormControl; }
  // public get glAccountWriteOffDebitControl(): FormControl { return this.form.get('gl_account_write_off_debit') as FormControl; }
  public get productCreationForbiddenControl(): FormControl { return this.form.get('product_creation_forbidden') as FormControl; }
  public get longTermPeriodOfUseControl(): FormControl { return this.form.get('long_term_period_of_use') as FormControl; }
  public get periodOfUseControl(): FormControl { return this.form.get('period_of_use') as FormControl; }
  public get fundsWriteOffMethodControl(): FormControl { return this.form.get('funds_write_off_method') as FormControl; }
  public get fundsWriteOffRateControl(): FormControl { return this.form.get('funds_write_off_rate') as FormControl; }

}
