import { BarcodeScannerComponent } from './barcode-scanner/barcode-scanner.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { DropdownComponent, TokenfieldComponent, ComboboxComponent } from './dropdown/dropdown.component';
import { InputComponent } from './input/input.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { TextareaComponent } from './textarea/textarea.component';
import { ToggleComponent } from './toggle/toggle.component';
import { CollectivefieldComponent } from './collectivefield/collectivefield.component';

export * from './barcode-scanner/barcode-scanner.component';
export * from './checkbox/checkbox.component';
export * from './checkbox-group/checkbox-group.component';
export * from './dropdown/dropdown.component';
export * from './input/input.component';
export * from './radio-group/radio-group.component';
export * from './textarea/textarea.component';
export * from './toggle/toggle.component';
export * from './collectivefield/collectivefield.component';

export const CONTROLS: {[k: string]: any} = {
  barcode: BarcodeScannerComponent,
  checkbox: CheckboxComponent,
  checkboxGroup: CheckboxGroupComponent,
  select: DropdownComponent,
  tokenfield: TokenfieldComponent,
  combobox: ComboboxComponent,
  text: InputComponent,
  radio: RadioGroupComponent,
  textarea: TextareaComponent,
  toggle: ToggleComponent,
  collective_field: CollectivefieldComponent
};
