export enum TableActivateTypes {
  RowDetail = 1,
  Sidebar,
  EditRow,
  DeleteRow,
  Link,
  Unlink,
  Rollback,
  Action,
  Bill,
  Return,
  Receipt,
  Cancel,
  Download,
  Email,
}

export enum PositionsActionsType {
  TO_TOP,
  TO_BOTTOM,
  UP,
  DOWN,
  TO,
  DELETE,
  RESTORE,
  EDIT,
  INFO,
  LINK,
  CREATE,
  ACTIVATE,
  IGNORE,
}
