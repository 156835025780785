import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TableSummaryBarComponent } from './table-summary-bar/table-summary-bar.component';
import { TableSummaryBarItemComponent } from './table-summary-bar-item/table-summary-bar-item.component';
import { RnplCommonModule } from '../../rnpl-common';
import { StatusLineModule } from '../status-line/status.line.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import {
  NumberFormatPipeModule
} from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';

@NgModule({
  declarations: [
    TableSummaryBarComponent,
    TableSummaryBarItemComponent
  ],
  exports: [
    TableSummaryBarComponent,
    TableSummaryBarItemComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    StatusLineModule,
    TranslateModule,
    FormsModule,
    NumberFormatPipeModule
  ]
})
export class TableSummaryBarModule { }
