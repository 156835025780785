import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent {

  public isScrolled: boolean;

  @Input()
  public hideViewer: boolean;
  @Input()
  public hasFile: boolean;


  public showGradient(e): void {
    this.isScrolled = e;
  }
}
