import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { ProductTypes } from 'common/src/modules/products/product-types';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';
import { ActionButtonsService } from '../../../services/action-buttons.service';


@Component({
  selector: 'rnpl-product-form-barcode',
  templateUrl: './product-form-barcode.component.html',
  styleUrls: ['./form-hardcode.scss']
})
export class ProductFormBarcodeComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() productData: any;
  @Input() productType: ProductTypes;
  // @Input() readonly: boolean = false;
  @Input() counterAnimation: boolean = false;

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public barcodePlaceholder = {
    false: 'FORM.ENTER_BARCODE',
    true: 'FORM.DONT_SPECIFY_BARCODE',
  };

  public controlId = Math.floor(Math.random() * 1000);

  public readonly productTypes = ProductTypes;

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder, private cdr: ChangeDetectorRef,
    private readonly actionButtonService: ActionButtonsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.updateFormEditingStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly')) {
      this.updateFormEditingStatus();
      if (!this.readonly && get(this.productData, 'barcodeDisabled')) {
        this.barcode.disable({emitEvent: false});
      }
    }
    if (changes.hasOwnProperty('productData') || changes.hasOwnProperty('productType')) {
      this.initForm();
    }
  }

  public initForm(): void {
    this.form = new FormGroup({});

    switch (this.productType) {
      case ProductTypes.GOODS:
        this.form = this.fb.group({
          barcode: [get(this.productData, 'barcode') || null, Validators.required],
          barcodeDisabled: [get(this.productData, 'barcodeDisabled') || false, Validators.required],
        });

        this.barcodeDisabledChanged(get(this.productData, 'barcodeDisabled', false), false);
        break;
      case ProductTypes.SERVICES:
        this.form = this.fb.group({});
        break;
    }
  }

  public getFormData(): any {
    const formData = this.form.getRawValue();
    return {...formData};
  }

  public barcodeDisabledChanged(value: boolean, isUpdateField: boolean): void {
    if (value) {
      this.barcode.setValue(null);
      this.cdr.detectChanges();
      this.barcode.disable();
    } else if (!value && !this.readonly) {
      this.cdr.detectChanges();
      this.barcode.enable();
    }

    if (isUpdateField) {
      this.updateField(value, 'barcodeDisabled');
    }
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get barcode(): FormControl { return this.form.get('barcode') as FormControl; }
  get barcodeDisabled(): FormControl { return this.form.get('barcodeDisabled') as FormControl; }

  get barcodeDisabledVal(): boolean {
    if (!this.barcodeDisabled) { return null; }

    return this.barcodeDisabled.value;
  }

  public barcodeDialogToggle(disabled: boolean): void {
    if (!disabled) {
      this.actionButtonService.barcodeDialogToggle();
    }
  }

}
