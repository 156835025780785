import {TableColumnModelExtended} from 'common/src/models/table-column.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { UIStatesEnum } from 'common/src/models';

export function getUserPermissionsColumns(uiState: UIStatesEnum): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'withModuleName',
      headerTemplate: 'headerWithHints',
      hintsId: 'appColumn',
      name: 'FILTERS.SALES_CHANNEL',
      prop: 'module',
    },
    {
      cellTemplate: uiState === UIStatesEnum.VIEW ? 'withPermissionView' : 'withPermissionEdit',
      headerTemplate: 'headerWithHints',
      headerClass: 'text-center center',
      hintsId: 'lockedColumn',
      clicktype: TableActivateTypes.Action,
      sortable: false,
      name: 'TEAM.LOCKED',
      prop: 'locked',
      width: 80,
      maxWidth: 80,
      blockedModules: [
        'products',
        'partners',
        'team'
      ]
    },
    {
      cellTemplate: uiState === UIStatesEnum.VIEW ? 'withPermissionView' : 'withPermissionEdit',
      headerTemplate: 'headerWithHints',
      headerClass: 'text-center center',
      clicktype: TableActivateTypes.Action,
      sortable: false,
      hintsId: 'viewColumn',
      name: 'TEAM.VIEW',
      prop: 'view',
      width: 80,
      maxWidth: 80,
    },
    {
      cellTemplate: uiState === UIStatesEnum.VIEW ? 'withPermissionView' : 'withPermissionEdit',
      headerTemplate: 'headerWithHints',
      hintsId: 'editColumn',
      headerClass: 'text-center center',
      clicktype: TableActivateTypes.Action,
      sortable: false,
      name: 'BUTTON.EDIT',
      prop: 'edit',
      width: 80,
      maxWidth: 80,
    },
    {
      cellTemplate: uiState === UIStatesEnum.VIEW ? 'withPermissionView' : 'withPermissionEdit',
      headerTemplate: 'headerWithHints',
      hintsId: 'manageColumn',
      headerClass: 'text-center center',
      clicktype: TableActivateTypes.Action,
      sortable: false,
      name: 'BUTTON.MANAGE',
      prop: 'manage',
      width: 80,
      maxWidth: 80,
      blockedModules: [
        // 'ecommerce',
        // 'wholesale',
      ]
    },
  ]
}
