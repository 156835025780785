import { SalesOrderListTabsEnum } from './enums';
import { FlagsEnum } from '../../../../../common/src/models/flags.enum';

export const STATUS_KEY = 'status';

export const STATUS_CLASSES: {[key: string]: string} = {
  [SalesOrderListTabsEnum.DRAFT]: 'rnpl-badge--grey',
  [SalesOrderListTabsEnum.OPEN]: 'rnpl-badge--blue',
  [SalesOrderListTabsEnum.DELETED]: 'rnpl-badge--empty',
  [SalesOrderListTabsEnum.COMPLETED]: 'rnpl-badge--green',
  [SalesOrderListTabsEnum.CANCELED]: 'rnpl-badge--red',
  ['editing']: 'rnpl-badge--main-400',
  ['paid']: 'rnpl-badge--green',
  ['shipped']: 'rnpl-badge--green',
  ['packed_up']: 'rnpl-badge--blue',
  [FlagsEnum.DELAYED.toLowerCase()]: 'rnpl-badge--yellow',
  ['corrupted']: 'rnpl-badge--yellow',
  ['active']: 'rnpl-badge--blue'
};
