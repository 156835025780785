import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { BankAccountDocumentFormComponent } from './bank-account-document-form.component';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { SafePipeModule } from 'safe-pipe';

@NgModule({
  declarations: [
    BankAccountDocumentFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UIComponentsModule,
    TranslateModule,
    NgSelectModule,
    RnplCommonModule,
    SafePipeModule
  ],
  exports: [
    BankAccountDocumentFormComponent,
  ],
  entryComponents: [
    BankAccountDocumentFormComponent
  ],
  providers: []
})
export class BankAccountDocumentFormModule { }
