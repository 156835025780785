import { Component, Input } from '@angular/core';

import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';

@Component({
  selector: 'rnpl-document-list-container',
  templateUrl: './document-list-container.component.html',
  styleUrls: ['./document-list-container.component.scss']
})
export class DocumentListContainerComponent {

  @Input() public tabs: TabDefinitionModel[] = [];
  @Input() public showSummaryBar: boolean;
  @Input() public activeStatus: string;
  @Input() public isLoading: boolean;
  @Input() public displayTable: boolean;
  @Input() public mainColor: string;
  @Input() public title: string;
  @Input() public icon: string;
  @Input() public selectedRows: [];

}
