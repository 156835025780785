import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { BaseFormComponent } from '../../../crm/partner-forms/components/base-form.component';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';
import { UserModel } from 'common/src/models';
import { SystemPreferencesPermissionsService } from 'common/src/services/system-preferences-permissions.service';
import { ChannelOptions, CompanyProfile } from '../../../administration/models/company-profile.model';
import { SubscriptionPlanEnum } from 'common/src/modules/subscription-activation/subscription-activation.emum';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { AppState } from '../../../store/state/app.state';

@Component({
  selector: 'rnpl-department-form',
  templateUrl: 'department-form.component.html',
  styleUrls: ['./department-form.component.scss']
})
export class DepartmentFormComponent extends BaseFormComponent implements OnChanges {

  @Input() userData: UserModel;
  @Input() appendTo: string;
  @Input() isLoading: boolean = true;

  public companyProfile: CompanyProfile;

  @Output()
  inputChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public filteredDepartmentItems = [];

  public positionItems = {
    ['Management']: [
      {value: 'CEO', label: 'USER_POSITIONS.CEO'},
      {value: 'C-level manager', label: 'USER_POSITIONS.C_LEVEL'},
      {value: 'Workspace administrator', label: 'USER_POSITIONS.ADMIN'},
    ],
    ['Accounting']: [
      {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
      {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
      {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
      {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
      {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
    ],
    ['Wholesale']: [
      {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
      {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
      {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
      {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
      {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
    ],
    ['E-commerce']: [
      {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
      {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
      {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
      {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
      {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
    ],
    ['Services']: [
      {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
      {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
      {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
      {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
      {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
    ],
    ['Retail']: [
      {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
      {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
      {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
      {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
      {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
    ],
    ['Warehouse']: [
      {value: 'Head of Department', label: 'USER_POSITIONS.HEAD_OF_DEPARTMENT'},
      {value: 'Trusted employee', label: 'USER_POSITIONS.TRUSTED_EMPLOYEE'},
      {value: 'Employee', label: 'USER_POSITIONS.EMPLOYEE'},
      {value: 'Intern', label: 'USER_POSITIONS.INTERN'},
      {value: 'Contractor', label: 'USER_POSITIONS.CONTRACTOR'},
    ],
  };

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private fb: FormBuilder,
    public store: Store<AppState>,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
  ) {
    super();
    this.initForm(this.userData);
    this.updateFormEditingStatus();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
        this.filteredDepartmentItems = this.getDepartmentItems()
          .filter(itm => !itm.hasOwnProperty('available') || itm.available);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly') || changes.hasOwnProperty('userData')) {
      this.form.patchValue(this.userData, { emitEvent: false, onlySelf: false });
      this.updateFormEditingStatus();
    }

    if (changes.hasOwnProperty('isLoading')) {
      this.isLoading$.next(this.isLoading);
    }
  }

  updateFormEditingStatus() {
    if (!this.form) { return; }

    if (this.readonly) {
      this.form.disable({ emitEvent: false, onlySelf: false });
    } else {
      this.form.enable({ emitEvent: false, onlySelf: false });
    }

    if (this.userData && this.userData.id) {
      this.isLoading$.next(false);
    }
  }

  initForm(userData: UserModel = {} as any): void {
    this.form = this.fb.group({
      department: [userData.department || null, [Validators.required]],
      position: [userData.position || null, [Validators.required]]
    });
    super.initForm();

    this.department.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        if (value) {
          this.position.patchValue(value === 'Management' ? 'C-level manager' : 'Employee');
        }
      });
  }

  public updateField(fieldValue: any, fieldName: string): void {
    fieldName = 'profile.' + fieldName;
    this.inputChanged.emit({fieldName, fieldValue});
  }

  private getDepartmentItems(): any[] {
    return [
      {value: 'Accounting', label: 'CATEGORIES.ACCOUNTING'},
      {
        value: 'Warehouse',
        label: 'MODULE.WAREHOUSE',
        available: !this.isFreePlan() &&
          !this.isAccountingPlan() &&
          !(
            this.userChannelOptions() &&
            this.userChannelOptions().servicesEnabled &&
            !this.userChannelOptions().wholesalesEnabled &&
            !this.userChannelOptions().ecommerceEnabled
          )
      },
      {value: 'Wholesale', label: 'MODULE.TRADE', available: this.systemPreferencesPermissionsService.wholesaleEnabled()},
      {value: 'Services', label: 'MODULE.SERVICES', available: this.systemPreferencesPermissionsService.servicesEnabled()},
      {value: 'E-commerce', label: 'MODULE.E_COMMERCE', available: this.systemPreferencesPermissionsService.ecommerceEnabled()},
      {value: 'Management', label: 'APP.MANAGEMENT'},
    ];
  }

  getFormData() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    return this.form.getRawValue();
  }

  isFormInvalid(): boolean {
    if (this.form) {
      return this.form.invalid;
    }
    return false;
  }

  userChannelOptions(): ChannelOptions {
    return this.companyProfile &&
      this.companyProfile.subscriptionManagement &&
      this.companyProfile.subscriptionManagement.userChannelOptions;
  }

  isFreePlan(): boolean {
    return this.companyProfile &&
      this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.FREE;
  }

  isAccountingPlan(): boolean {
    return this.companyProfile &&
      (this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.ACCOUNTING_MONTHLY ||
      this.companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY);
  }

  get department(): FormControl { return this.form.get('department') as FormControl; }
  get position(): FormControl {   return this.form.get('position') as FormControl; }


}
