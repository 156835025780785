import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { AuthService } from '../../../auth/auth.service';
import { selectUserQuickActions } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import {
  quickActionsConfig,
  userAvailableShortcutsHelper
} from '../modals-control-center/shortcuts-modal/user-shortcuts.helper';
import { UserShortcutEnum } from 'projects/workspace/src/app/hrm/enums';

@Component({
  selector: 'rnpl-quick-actions-modal',
  templateUrl: './quick-actions-modal.component.html',
  styleUrls: ['./quick-actions-modal.component.scss']
})
export class QuickActionsModalComponent implements OnDestroy {

  public userId: number;

  public addedQuickActionsList = []; // todo model
  public availableQuickActionsList = []; // todo model

  public scrolledIsBottom: boolean = true;
  public isScrolled: boolean;
  public scrolledIsBottomAddedList: boolean;
  public isScrolledAddedList: boolean;
  public hasAvailableQuickActions: boolean;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private store: Store<AppState>,
    private hrmService: HrmService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<QuickActionsModalComponent>,
  ) {
    this.userId = this.authService.getUser().id;
    this.store.select(selectUserQuickActions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(quickActions => {
        this.availableQuickActionsList = userAvailableShortcutsHelper(quickActions.available, quickActionsConfig);
        this.addedQuickActionsList = quickActions.activated.map(itm => quickActionsConfig[itm]);
        this.checkEmptyAvailableQuickActions();
      });
  }

  public drop(event: CdkDragDrop<any[]>, blockReorder = false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (blockReorder) { // block possibility to reorder items between groups at Available shortcuts
        if (event.previousContainer.id === 'addedShortcutsDropList') { // add exception for items moved from Added to Available lists
          this.removeQuickAction(event.previousContainer.data[event.previousIndex]); // move manually to prevent reordering
        }
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  public addQuickAction = (item, group): void => { // need to use arrow func because of context
    const previousIndex = group.shortcuts.findIndex(shortcut => shortcut.key === item.key);

    transferArrayItem(
      group.shortcuts,
      this.addedQuickActionsList,
      previousIndex,
      this.addedQuickActionsList.length,
    );
  }

  public removeQuickAction = (item): void => { // need to use arrow func because of context
    const previousIndex = this.addedQuickActionsList.findIndex(shortcut => shortcut.key === item.key);
    const nextGroup = this.availableQuickActionsList.find(shortcutGroup => shortcutGroup.group.includes(item.key));
    const nextIndex = nextGroup.group.findIndex((key => key === item.key));

    transferArrayItem(
      this.addedQuickActionsList,
      nextGroup.shortcuts,
      previousIndex,
      nextIndex,
    );

    this.checkEmptyAvailableQuickActions();
  }

  public addAllQuickActions = (): void => { // need to use arrow func because of context
    this.availableQuickActionsList.forEach(group => {
      [...group.shortcuts].forEach(shortcut => this.addQuickAction(shortcut, group));
    });

    this.checkEmptyAvailableQuickActions();
  }

  public removeAllQuickActions = (): void => { // need to use arrow func because of context
    [...this.addedQuickActionsList].forEach(shortcut => {
      this.removeQuickAction(shortcut);
    });

    this.checkEmptyAvailableQuickActions();
  }

  public submitQuickActions(): void {
    const quickActions: UserShortcutEnum[] = this.addedQuickActionsList.map(i => i.key);
    this.hrmService.updateUserModulesQuickActions(this.userId, quickActions)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close());
  }

  private checkEmptyAvailableQuickActions(): void {
    this.hasAvailableQuickActions = this.availableQuickActionsList.some(itm => !!itm.shortcuts.length);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
