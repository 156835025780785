import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { SalesOrderListTabsEnum } from '../../enums';
import { VAT_LIST } from '../../../shared/constants';
import { TranslateService } from '@ngx-translate/core';
import {
  ControlTypeEnum,
  InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';

export const PositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.REMOVE',
    multipleText: 'BUTTON.REMOVE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'minus-circle',
    actionName: 'onDeletePositionsClick',
    multiple: false
  }
];

export const CanceledPositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-dark',
    multipleClasses: 'btn-primary',
    text: 'BUTTON.RESTORE',
    multipleText: 'BUTTON.RESTORE',
    badgeClass: 'rnpl-badge--grey',
    iconName: 'undo',
    actionName: 'onRestorePositionsClick',
    multiple: false
  },
  // {
  //   classes: 'btn-dark',
  //   multipleClasses: 'btn-white red',
  //   text: 'BUTTON.REMOVE',
  //   multipleText: 'BUTTON.REMOVE',
  //   badgeClass: 'rnpl-badge--red',
  //   iconName: 'minus-circle',
  //   actionName: 'onDeletePositionsClick',
  //   multiple: false
  // }
];

export function generatePositionsCardsInfo(
  translateService: TranslateService,
  productType: ProductTypes,
  state: UIStatesEnum,
  documentStatus: SalesOrderListTabsEnum,
  vatBlocked: boolean,
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.SERVICES],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.QUANTITY',
      hintId: 'quantityColumn',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.PRIMARY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
    },
    {
      label: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.PRIMARY,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: vatBlocked,
      vatBlocked: vatBlocked,
    },
    {
      label: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      forcedType: PositionItemTypeEnum.CURRENCY,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      valueClass: 'font-weight-500',
      showUnitOnly: true,
    },
    {
      label: '',
      prop: '',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DISCOUNT,
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
    },
    {
      label: 'COLUMN.OPEN',
      suffix: '€',
      type: PositionItemTypeEnum.STATUS_LINE,
      colorState: 'main-600',
      prop: 'openData',
      hintId: 'openColumn',
      showWithDocumentStatus: [SalesOrderListTabsEnum.OPEN],
      showWithUIState: [UIStatesEnum.VIEW],
      hideWithProductType: [ProductTypes.DIGITAL, ProductTypes.SERVICES],
    },
    {
      label: 'COLUMN.MARGIN',
      prop: 'marginData',
      suffix: '€',
      type: PositionItemTypeEnum.PROFIT,
      currencyConversion: true,
      hideWithProductType: [ProductTypes.DIGITAL],
      showWithDocumentStatus: [
        SalesOrderListTabsEnum.DRAFT,
        SalesOrderListTabsEnum.OPEN,
        SalesOrderListTabsEnum.COMPLETED,
      ],
      showWithUIState: [UIStatesEnum.VIEW]
    },
    {
      label: 'COLUMN.MARGIN',
      prop: 'marginData',
      suffix: '€',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.PROFIT,
      currencyConversion: true,
      hideWithProductType: [ProductTypes.DIGITAL],
      showWithDocumentStatus: [
        SalesOrderListTabsEnum.DRAFT,
        SalesOrderListTabsEnum.OPEN,
        SalesOrderListTabsEnum.COMPLETED,
      ],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
  ];

  positionInfoList = positionInfoList
    .filter((item) => {
      if (item.hideWithProductType) {
        return !item.hideWithProductType.includes(productType);
      }
      return true;
    }).filter((item) => {
      if (item.showWithUIState) {
        return item.showWithUIState.includes(state);
      }
      return true;
    }).filter((item) => {
      if (item.showWithDocumentStatus) {
        return item.showWithDocumentStatus.includes(documentStatus);
      }
      return true;
    });

  return positionInfoList;
}
