import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../store/state/app.state';
import { FilterModel } from '../../warehouse/models/filter.model';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { SubscriptionListModel, SubscriptionModel } from '../models/subscription.model';
import { AffectSubscriptionEnum, SubscriptionListTabsEnum, SubscriptionPeriodEnum } from '../enums';
import { LoadSubscription, LoadSubscriptionPositions, UpdateSubscription, UpdateSubscriptionCurrentState, UpdateSubscriptionPositionsCount, IncrementLoadingRequestsCount, DecrementLoadingRequestsCount, UpdateSubscriptionSummary, UpdateSubscriptionUpdatedAt, LoadSubscriptionsList, UpdateShouldRefreshEntity } from '../store/actions/subscription.actions';
import { SalesPosition, SalesPositionsByProductType } from '../models/subscription-position.model';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { UIStatesEnum } from 'common/src/models';
import { SummaryInfoModel } from '../models/summary-info-model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ResponseSubscriptionCountersModel, SubscriptionCountersModel } from '../models/subscription-counters.model';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { PurchaseOrder } from '../../purchase-order/models';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { TradePositionModel } from '../../trade/models';
import { DocumentTemplateModel } from '../../shared/models';

@Injectable()
export class SubscriptionApiService {
  private readonly apiEndpoint: string = '/subscriptions';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  createSubscriptionDraft(): Observable<SubscriptionModel> {
    return this.http
      .post<ResponseModel<SubscriptionModel>>(this.apiUrl('/draft'), {})
      .pipe(
        map((data: ResponseModel<SubscriptionModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  getSubscriptionList(
    status: SubscriptionListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = { nameColumn: 'updated_at', value: DEFAULT_SORT_DIRECTION },
    filters: any = {}
  ): Observable<ResponseList<SubscriptionListModel>> {
    const params = {
      page,
      per_page,
      ['filters[status]']: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http.get<ResponseList<SubscriptionListModel>>(this.apiUrl(), { params })
      .pipe(tap((data: ResponseList<SubscriptionListModel>) => {
        this.store.dispatch(LoadSubscriptionsList({
          subscriptionsListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status,
          page: data.pagination.page
        }));
      }));
  }

  getSubscriptionListPositions(subscriptionId: number): Observable<TradePositionModel[]> {
    return this.http.get<ResponseModel<TradePositionModel[]>>(this.apiUrl(`/${subscriptionId}/list/positions`))
      .pipe(map((data: ResponseModel<TradePositionModel[]>) => data.data));
  }

  getSubscriptionsRunpleIds(status: SubscriptionListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl(`/runpleIds`), { params })
      .pipe(map((data: any) => data.data));
  }

  getSubscriptionCounters(): Observable<SubscriptionCountersModel> {
    return this.http
      .get<ResponseModel<ResponseSubscriptionCountersModel>>(
        this.apiUrl('/counters')
      )
      .pipe(
        map(
          (data: ResponseModel<ResponseSubscriptionCountersModel>) =>
            data.data.subscriptions
        )
      );
  }

  getSubscriptionSummary(
    id: number | string
  ): Observable<ResponseModel<SummaryInfoModel>> {
    return this.http.get<ResponseModel<SummaryInfoModel>>(this.apiUrl(`/${id}/summary`))
      .pipe(
        tap((data: ResponseModel<SummaryInfoModel>) => {this.store.dispatch(UpdateSubscriptionSummary({summary: data.data}))})
      );
  }

  public getSOListExport(title: string, status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  @DisplayToaster({showErrorMessage: true})
  changeSubscriptionStatusBatch(status: SubscriptionListTabsEnum,ids: number[]): Observable<any> {
    const body = { status, ids };
    return this.http.patch(this.apiUrl('/status'), body);
  }

  @DisplayToaster({showErrorMessage: true})
  changeSubscriptionsStatus(formData: any): Observable<any> {
    return this.http.patch(this.apiUrl('/status'), formData);
  }

  @DisplayToaster({showErrorMessage: true})
  getSubscriptionPauseBillingDate(
    subscriptionId: number,
    pauseData: {
      status: SubscriptionListTabsEnum,
      startDate: Date,
      endDate: Date,
      duration: SubscriptionPeriodEnum,
      affectOnSubscription: AffectSubscriptionEnum,
    }
  ): Observable<any> {
    return this.http.post(this.apiUrl(`/${subscriptionId}/pause/billing-date`), pauseData)
      .pipe(
        map((data: ResponseModel<any>) => data.data),
      );
  }

  changeSubscriptionStatus(status: SubscriptionListTabsEnum, subscriptionId: number, formData?: any): Observable<SubscriptionModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status, ...formData };
    return this.http
      .patch(this.apiUrl(`/${subscriptionId}/status`), body)
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((data: ResponseModel<SubscriptionModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  getSubscriptionById(id: string | number): Observable<SubscriptionModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http
      .get<ResponseModel<SubscriptionModel>>(this.apiUrl(`/${id}`))
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateSubscriptionPositionsCount({ positionsCount: response.data.positionsCount }));
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<SubscriptionModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updateSubscription(subscriptionId: number, field: FormInputChangedModel, force = false): Observable<SubscriptionModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<SubscriptionModel>>(this.apiUrl(`/${subscriptionId}`), {...field, force})
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
        }),
        map((response: ResponseModel<SubscriptionModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, subscriptionId, field);
          return throwError(error);
        })
      );
  }

  updateBillingInfoAddressByCustomer(subscriptionId: number): Observable<SubscriptionModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<SubscriptionModel>>('put', this.apiUrl(`/${subscriptionId}/billing-address/update-by-customer`))
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
        }),
        map((response: ResponseModel<SubscriptionModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, subscriptionId);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  createSubscription(id: number): Observable<SubscriptionModel> {
    return this.http.request<ResponseModel<SubscriptionModel>>('post', this.apiUrl(`/${id}/accept-create`))
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
        }),
        map((data: ResponseModel<SubscriptionModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  createSubscriptionsInvoice(subscriptionId: number, invoiceId: number): Observable<SubscriptionModel> {
    return this.http.post<ResponseModel<SubscriptionModel>>(this.apiUrl(`/${subscriptionId}/invoice`), {id: invoiceId})
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
        }),
        map((data: ResponseModel<SubscriptionModel>) => data.data)
      );
  }

  getSubscriptionPositions(subscriptionId: number): Observable<ResponseModel<SalesPositionsByProductType>> {
    return this.http.get<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/${subscriptionId}/positions`))
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadSubscriptionPositions({positions: response}));
        }),
      );
  }

  createSubscriptionPosition(subscriptionId: number, position: SalesPosition): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/${subscriptionId}/positions`), position)
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSubscriptionPositions({positions: response}));
          this.store.dispatch(UpdateSubscriptionPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, subscriptionId);
          return throwError(error);
        })
      );
  }

  updateSubscriptionPosition(positionId: number, field: FormInputChangedModel, subscriptionId): Observable<ResponseModel<SalesPosition>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<SalesPosition>>(this.apiUrl(`/positions/${positionId}`), field).pipe(
      tap((response: ResponseModel<SalesPosition>) => {
        this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, subscriptionId);
        return throwError(error);
      })
    );
  }

  deleteSubscriptionPositions(subscriptionId: number, ids: number[]): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ids: ids };
    return this.http.request<ResponseModel<SalesPositionsByProductType>>(
      'delete',
      this.apiUrl(`/${subscriptionId}/positions`),
      {body}
    )
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadSubscriptionPositions({positions: response}));
          this.store.dispatch(UpdateSubscriptionUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateSubscriptionPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, subscriptionId);
          return throwError(error);
        })
      );
  }

  changePositionOrder(posId: number, moveToOrder: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(this.apiUrl(`/positions/${posId}/order/${moveToOrder}`), body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  deleteSubscriptionsPermanently(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(), {body});
  }

  @DisplayToaster({showErrorMessage: true})
  deleteSubscriptionPermanently(subscriptionId: number): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${subscriptionId}`));
  }

  public getSubscriptionExportParams(id: number, title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `accounting/v1/pdf/subscription/${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  public cloneSubscription(subscriptionId: number): Observable<SubscriptionModel> {
    return this.http.post<ResponseModel<SubscriptionModel>>(this.apiUrl(`/${subscriptionId}/clone`), {})
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(LoadSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateSubscriptionPositionsCount({ positionsCount: response.data.positionsCount }));
        }),
        map((response: ResponseModel<SubscriptionModel>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public sendSubscriptionEmail(subscriptionId: number): Observable<any> {
    return this.http.post(this.apiUrl(`/${subscriptionId}/email`), {});
  }

  public subscriptionSetEdit(id: string | number, force = false): Observable<SubscriptionModel> {
    const body = { force };

    return this.http.put<ResponseModel<SubscriptionModel>>( this.apiUrl(`/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(UpdateSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateSubscriptionCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<SubscriptionModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public subscriptionUnsetEdit(id: string | number): Observable<SubscriptionModel> {
    return this.http.request<ResponseModel<SubscriptionModel>>('put', this.apiUrl(`/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<SubscriptionModel>) => {
          this.store.dispatch(UpdateSubscription({ subscription: response.data }));
          this.store.dispatch(UpdateSubscriptionCurrentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<SubscriptionModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public getDocumentTemplates(): Observable<DocumentTemplateModel[]> {
    return this.http.get<ResponseModel<DocumentTemplateModel[]>>(this.apiUrl('/templates'))
      .pipe(map((response: ResponseModel<DocumentTemplateModel[]>) => response.data));
  }

  public getDocumentTemplatesNames(): Observable<{ id: number; name: string; }[]> {
    return this.http.get<ResponseModel<{ id: number; name: string; }[]>>(this.apiUrl('/templates/names'))
      .pipe(map((response: ResponseModel<{ id: number; name: string; }[]>) => response.data));
  }

  public saveDocumentTemplate(documentId: number, body: { name: string; overwrite: boolean; resetCounter: boolean; }): Observable<boolean> {
    return this.http.post<ResponseModel<boolean>>(this.apiUrl(`/${documentId}/template`), body)
      .pipe(map((response: ResponseModel<boolean>) => response.data));
  }

  public applyDocumentTemplate(documentId: number, templateId: number, force = false): Observable<any> {
    return this.http.patch<ResponseModel<any>>(this.apiUrl(`/${documentId}/template/${templateId}`), {force})
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDocumentFromTemplate(templateId: number): Observable<number> {
    return this.http.request<ResponseModel<number>>('post', this.apiUrl(`/template/${templateId}`))
      .pipe(map((response: ResponseModel<number>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, subscriptionId?: string | number, field?: FormInputChangedModel): void {
    if (!!error.error.message) {
      switch (error.error.message) {
        case 'anotherUserEditError':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: getAnotherUserEditErrorModalData(
              {
                document: error.error.data.entityName,
                userName: error.error.data.userName,
              },
              this.translateService
            ),
            backdropClass: 'backdrop-none',
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.subscriptionSetEdit(subscriptionId, true).subscribe();
            }
          });
        }
          break;
        case 'billingFrequencyUpdateError':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: {
              title: 'SUBSCRIPTIONS.CHANGE_BILLING_FREQUENCY',
              message: 'SUBSCRIPTIONS.CHANGE_BILLING_FREQUENCY_MSG',
              confirmBtnText: 'BUTTON.CHANGE',
              confirmBtnIcon: 'checkbox-on',
            }
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.updateSubscription(+subscriptionId, field, true).subscribe();
            } else {
              this.getSubscriptionById(subscriptionId).subscribe();
            }
          });
        }
          break;
        case 'billingDateUpdateError':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: {
              title: 'SUBSCRIPTIONS.CHANGE_BILLING_DATE',
              message: 'SUBSCRIPTIONS.CHANGE_BILLING_DATE_MSG',
              confirmBtnText: 'BUTTON.CHANGE',
              confirmBtnIcon: 'checkbox-on',
            }
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.updateSubscription(+subscriptionId, field, true).subscribe();
            } else {
              this.getSubscriptionById(subscriptionId).subscribe();
            }
          });
        }
          break;
        case 'billingFrequencyUpdateHardError':
        {
          const dialog = this.dialog.open(InfoModalComponent, {
            data: {
              title: 'SUBSCRIPTIONS.UNABLE_CHANGE_BILLING_FREQUENCY',
              message: 'SUBSCRIPTIONS.UNABLE_CHANGE_BILLING_FREQUENCY_MSG',
              hideCancelBtn: true,
              titleIcon: 'alert-triangle',
              titleColor: 'yellow-400',
              confirmBtnText: 'BUTTON.CLOSE',
              confirmBtnIcon: 'close'
            }
          });

          dialog.afterClosed().subscribe(res => {
            this.getSubscriptionById(subscriptionId).subscribe();
          });
        }
          break;
        case 'billingDateUpdateHardError':
        {
          const dialog = this.dialog.open(InfoModalComponent, {
            data: {
              title: 'SUBSCRIPTIONS.UNABLE_CHANGE_BILLING_DATE',
              message: 'SUBSCRIPTIONS.UNABLE_CHANGE_BILLING_DATE_MSG',
              hideCancelBtn: true,
              titleIcon: 'alert-triangle',
              titleColor: 'yellow-400',
              confirmBtnText: 'BUTTON.CLOSE',
              confirmBtnIcon: 'close'
            }
          });

          dialog.afterClosed().subscribe(res => {
            this.getSubscriptionById(subscriptionId).subscribe();
          });
        }
          break;
        case 'notEditModeError':
          const documentName = this.translateService.instant('APP.SUBSCRIPTION');
          this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
          this.store.dispatch(UpdateSubscriptionCurrentState({ currentState: UIStatesEnum.VIEW }));
          this.getSubscriptionById(subscriptionId).subscribe();
          break;
        default:
          this.showMsg('error', error.error.message);
          break
      }
    }
  }

}
