import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'rnpl-change-password-modal',
  templateUrl: 'change-password-modal.component.html',
})
export class ChangePasswordModalComponent {

  public form: FormGroup;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private hrmService: HrmService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentEmail: string
    }) {
    this.initForm();
  }

  public initForm(): void {
    this.form = this.fb.group({
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}')]],
      newPasswordConfirm: ['', [Validators.required]],
    });
  }

  submit(): void {
    if (this.submitRequest$.getValue()) { return; }

    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.newPasswordControl.value && this.newPasswordConfirmControl.value) {
      if (this.newPasswordControl.value === this.newPasswordConfirmControl.value) {
        this.newPasswordConfirmControl.setErrors(null);
      } else {
        this.newPasswordConfirmControl.setErrors({ mismatch: true });
      }
    }

    if (this.form.invalid) { return; }

    this.submitRequest$.next(true);


    this.hrmService.changMyProfilePassword(this.form.getRawValue())
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.dialogRef.close(res);
      }, error => {
        if (error.error.message === 'Password is not correct') {
          this.passwordControl.setErrors({ passwordNotCorrect: true });
          return;
        }
        this.toasterService.notify({type: 'error', message: error.error.message});
      });
  }

  get passwordControl(): FormControl { return this.form.get('password') as FormControl; }
  get newPasswordControl(): FormControl { return this.form.get('newPassword') as FormControl; }
  get newPasswordConfirmControl(): FormControl { return this.form.get('newPasswordConfirm') as FormControl; }

}
