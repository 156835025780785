import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  selectOutgoingInvoice,
  selectOutgoingInvoiceState,
  selectOutgoingInvoiceSummary
} from 'projects/workspace/src/app/outgoing-invoice/store/selectors';
import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { SummaryInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models/summary-invoice.model';
import { UIStatesEnum } from '../../../../models';
import { OinTypeEnum, OutgoingInvoiceListTabsEnum } from '../../../../../../projects/workspace/src/app/outgoing-invoice/enums';

@Component({
  selector: 'rnpl-outgoing-invoice-add-discount-modal',
  templateUrl: './outgoing-invoice-add-discount-modal.component.html',
  styleUrls: ['./outgoing-invoice-add-discount-modal.component.scss']
})
export class OutgoingInvoiceAddDiscountModalComponent implements OnInit, OnDestroy {
  public invoice: OutgoingInvoiceModel;
  public discountControl: FormControl = new FormControl({value: null});
  public discountAmountNetControl: FormControl = new FormControl({value: null});
  public discountAmountGrossControl: FormControl = new FormControl({value: null});

  readonly destroy$: Subject<void> = new Subject();

  public summary: SummaryInvoiceModel;

  constructor(
    public dialogRef: MatDialogRef<OutgoingInvoiceAddDiscountModalComponent>,
    private invoiceApiService: InvoiceApiService,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.trackInvoiceChanges();
    this.invoiceApiService.getSummaryInfo(this.invoice.invoiceId).subscribe();
    this.trackOutgoingInvoiceSummaryChanges();

    this.store.select(selectOutgoingInvoiceState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        const isPaidStatus = this.invoice && this.invoice.status === OutgoingInvoiceListTabsEnum.PAID;
        const isPrepayment = this.invoice && this.invoice.type === OinTypeEnum.PREPAYMENT;
        const isFinal = this.invoice && this.invoice.type === OinTypeEnum.FINAL;
        if ((response === UIStatesEnum.VIEW) || isPaidStatus || isPrepayment || isFinal) {
          this.discountControl.disable({emitEvent: false});
          this.discountAmountNetControl.disable({emitEvent: false});
          this.discountAmountGrossControl.disable({emitEvent: false});
        } else {
          this.discountControl.enable({emitEvent: false});
          this.discountAmountNetControl.enable({emitEvent: false});
          this.discountAmountGrossControl.enable({emitEvent: false});
        }
      });
  }

  private trackOutgoingInvoiceSummaryChanges(): void {
    this.store.select(selectOutgoingInvoiceSummary)
      .pipe(takeUntil(this.destroy$))
      .subscribe((summary: SummaryInvoiceModel) => this.summary = summary);
  }


  private trackInvoiceChanges(): void {
    this.store.select(selectOutgoingInvoice)
      .pipe(takeUntil(this.destroy$))
      .subscribe((invoice: OutgoingInvoiceModel) => {
        this.invoice = invoice;
        this.discountControl.patchValue(this.invoice.billingData.discount);
        this.discountAmountNetControl.patchValue(this.invoice.billingData.discountAmountNet);
        this.discountAmountGrossControl.patchValue(this.invoice.billingData.discountAmountGross);
       });
  }

  public updateDiscount(field: FormInputChangedModel): void {
    field.fieldName = 'billingData.' + field.fieldName;
    this.invoiceApiService.updateOutgoingInvoiceField(this.invoice.invoiceId, field.fieldName, field.fieldValue)
      .subscribe(() => {
        this.invoiceApiService.getSummaryInfo(this.invoice.invoiceId).subscribe();
      }); // updated via store
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
