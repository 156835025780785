import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { StockAddressesService } from '../../services';
import { ReplaySubject } from 'rxjs';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { takeUntil, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';
import { selectCurrentWarehouse, selectWarehouseState } from '../../store/selectors';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { UIStatesEnum } from 'common/src/models';

@Component({
  selector: 'rnpl-warehouse-general-info',
  templateUrl: './warehouse-general-info.component.html',
  styleUrls: ['./warehouse-general-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WarehouseGeneralInfoComponent implements OnInit, OnDestroy {

  public warehouseData: any;
  public warehouseId: string;
  public editingMode = false;

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private stockAddressesService: StockAddressesService,
    private toasterService: ToasterService,
    private readonly store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.store.select(selectCurrentWarehouse)
      .pipe(
        filter((currentWarehouse: WarehouseResponse) => !!currentWarehouse),
        takeUntil(this._destroy),
      )
      .subscribe(currentWarehouse => this.warehouseData = currentWarehouse);

    this.store.select(selectWarehouseState)
      .pipe(takeUntil(this._destroy))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
      });
  }

  // TODO - rewrite on update by 1 field
  public saveWarehouse(warehouseDataRequest) {
    this.stockAddressesService.patchWarehouseProfile(warehouseDataRequest).subscribe();
  }

  public displayMessage(msg: string, type: string) {
    this.toasterService.notify({
      type,
      message: msg
    });
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }

}
