import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


import { BaseFormLayoutStep } from 'common/src/modules/wizard';
import { CollectiveFieldControl, CollectiveFields, FamilyModel } from '../../../models';
import { ProductsService } from '../../../products.service';
import { FamilyAssignedAttrModalComponent } from '../../../../modals/modals-products';
import {CommonModalsActionsEnum} from '../../../../modals/modals-common';
import { FamilyWizardComponent } from '../../family-wizard.component';
import { AttributeModel } from '../../../../system-settings';


@Component({
  selector: 'rnpl-product-layout',
  templateUrl: './product-layout.component.html',
  styleUrls: ['./product-layout.component.scss']
})
export class ProductLayoutComponent extends BaseFormLayoutStep implements OnInit, OnDestroy, AfterViewInit {

  private formKey: string;

  public fg = new FormGroup({});

  public collectiveFields: CollectiveFields = {} as CollectiveFields;

  public locale: any = {title: 'Default (English)', icon: 'eng'};

  @Input() family: FamilyModel;
  @Input() parentId;
  @Input() control: FormControl;
  @Input() saveChangeRequest: boolean;
  @Input() public parentControls: Array<any> = [];

  @Output() public completed: EventEmitter<any> = new EventEmitter();
  @Output() public closed: EventEmitter<any> = new EventEmitter();
  @Output() public back: EventEmitter<any> = new EventEmitter();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  // public countdownTimer;
  // public stopTimer: boolean = false;
  // private hintsTimerCount: number = 0;
  // private hintsSteps: HintsModel[] = [];

  // private finishHintsConfig: HintsModel = {
  //   title: '<span class="hints-img">👍</span>',
  //   text: `Wham! Now you know the basics about Form layout`,
  //   classes: 'finish-step',
  //   buttons: this.getFinishHintsButtons(),
  //   when: {
  //     hide: () => {
  //       clearInterval(this.countdownTimer);
  //     }
  //   }
  // };

  constructor(
    private productsService: ProductsService,
    private router: Router,
    private dialog: MatDialog,
    // private readonly shepherdService: ShepherdService,
    // private actionButtonService: ActionButtonsService,
    @Optional() public wizard: FamilyWizardComponent
  ) {
    super();
  }

  ngOnInit(): void {
    this.formKey = `product_${this.family.products_type}_create`;
    this.getAvailableAttrs();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    // clearInterval(this.countdownTimer);
    //
    // if (this.shepherdService.isActive) {
    //   this.shepherdService.cancel();
    // }
  }

  public getAvailableAttrs(): void {
    this.productsService.getAvailableFamilyAttributes(this.formKey, this.family.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {

        if (response.data instanceof Array) {
          this._availableAttributes = response.data;
        } else {
          this._availableAttributes = Object.keys(response.data).map(key => response.data[key]);
        }

        // clearInterval(this.countdownTimer);

        // setTimeout(() => {
        //   if (
        //     !localStorage.getItem('dragAndDropAttrContainer') ||
        //     !localStorage.getItem('addAttrToTheFormBtn') ||
        //     !localStorage.getItem('createAttrBtn')
        //   ) {
        //     this.hintsSteps = this.getHintsSteps;
        //     this.addHintsSteps();
        //     this.shepherdService.start();
        //   }
        // }, 1000);
      });
  }

  // get available and not replace removed before attributes
  public getAvailableAttrsSafety(changedAttr?: AttributeModel): void {
    this.productsService.getAvailableFamilyAttributes(this.formKey, this.family.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        let availableList = [];
        if (response.data instanceof Array) {
          availableList = response.data;
        } else {
          availableList = Object.keys(response.data).map(key => response.data[key]);
        }
        availableList.forEach(attr => {
          const foundIndex = this._availableAttributes.findIndex(available => available.id == attr.id);
          if (foundIndex === -1) {
            this._availableAttributes.push(attr);
          } else {
            this._availableAttributes[foundIndex] = attr;
          }
        });

        if (changedAttr) {
          const foundIndex = this._availableAttributes.findIndex(available => available.id == changedAttr.id);
          this._availableAttributes[foundIndex] = changedAttr;
        }
      });
  }

  public updateAssignedAttributes(attributes) {
    this.control.setValue(attributes);
    this.control.markAsDirty();
    this.control.markAsTouched();
    super.updateAssignedAttributes(attributes);
  }

  public assignAttribute(attribute): void {
    super.assignAttribute(attribute);
    this.control.setValue(this.assignedAttributes);
    this.control.markAsDirty();
    this.control.markAsTouched();

    // clearInterval(this.countdownTimer);
    // if (this.shepherdService.isActive) {
    //   this.shepherdService.cancel();
    // }

    // if (!localStorage.getItem('dragAndDropAttrContainer')) {
    //   this.hintsSteps = ProductFormLayoutDragAndDropAttrHint;
    //   this.addHintsSteps();
    //   setTimeout(() => {
    //     this.shepherdService.start();
    //   }, 500);
    // }
  }

  public complete(): void {
    this.completed.emit({
      assignedAttributes: this.assignedAttributes,
      collectiveFields: this.collectiveFields
    });
  }

  public close(): void {
    this.closed.emit();
  }

  public goBack(): void {
    this.back.emit();
  }

  public createAttribute(): void {
    // clearInterval(this.countdownTimer);
    // if (this.shepherdService.isActive) {
    //   this.shepherdService.cancel();
    // }

    const dialog = this.dialog.open(FamilyAssignedAttrModalComponent, {
      data: {
        family: this.family,
        productType: this.family.products_type,
        entityType: 'product', // will be selected as [entity type]
        entities: 'product_products', // will be selected as [entity]
        formKey: this.formKey // will be selected as [form]
      },
      disableClose: true
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.SUCCESS) {
        this.getAvailableAttrsSafety();

        // if (!localStorage.getItem('addAttrToTheFormBtn')) {
        //   this.hintsSteps = ProductFormLayoutAddAttrToTheFormHint;
        //   this.addHintsSteps();
        //   setTimeout(() => {
        //     this.shepherdService.start();
        //   }, 500);
        // }
      }
    });
  }

  public assignCollectiveAttrs(collectiveControls: CollectiveFieldControl[]): void {
    // set array of placeholder strings
    this.selectedAttribute.attribute.attributes = collectiveControls.map(control => control.name);
    // set id's array of attached attributes
    this.selectedAttribute.properties = collectiveControls.map(control => Number(control.id));
    // add current field to object when this field id will be a [key] and attached attributes will be an array [value]
    this.collectiveFields[this.selectedAttribute.attribute.id] = collectiveControls;
    // refresh form-composer
    this.assignedAttributes = [...this.assignedAttributes];
  }

  // public startTimer(text: string, hintId?: string): void {
  //   this.setVisitedPage(hintId);
  //
  //   let hintsTimer: number = Math.ceil(Math.floor(text.length * HintsIndexTimerText) / 100);
  //
  //   this.hintsTimerCount = hintsTimer / 10;
  //
  //   const hintsBtnProgressElement = document.getElementsByClassName('hints-next-btn-progress');
  //   const visibleBtnProgressElement = hintsBtnProgressElement[hintsBtnProgressElement.length - 1] as HTMLElement;
  //
  //   if (!!visibleBtnProgressElement) {
  //     visibleBtnProgressElement.style.animationDuration = `${this.hintsTimerCount}s`
  //   }
  //
  //   this.countdownTimer = setInterval( () => {
  //     if (this.shepherdService.isActive) {
  //
  //       hintsTimer -= 1;
  //
  //       if (hintsTimer <= 0 && !this.stopTimer) {
  //         clearInterval(this.countdownTimer);
  //         this.shepherdService.next();
  //       }
  //     } else {
  //       clearInterval(this.countdownTimer);
  //       this.shepherdService.cancel();
  //     }
  //   }, 100);
  //
  // }
  //
  // private prepareProductListHintsSteps(hintsList: HintsModel[]): HintsModel[] {
  //   return hintsList.map((hint, index) => {
  //     return {
  //       ...hint,
  //       buttons: this.getHintsButton(index),
  //       when: this.getHintsShowAndHideEvents(index),
  //       title: `
  //               <span class="hints-count">${index + 1}/${hintsList.length}</span>
  //               <span class="hints-img">✍</span>
  //              `,
  //     };
  //   });
  // }
  //
  // private cancelHintsTour(text?: string): void {
  //   this.stopTimer = true;
  //   const hintsBtnProgressElement = document.getElementsByClassName('hints-next-btn-progress');
  //   const visibleBtnProgressElement = hintsBtnProgressElement[hintsBtnProgressElement.length - 1] as HTMLElement;
  //   visibleBtnProgressElement.classList.remove('show-animation');
  //
  //   const dialog = this.dialog.open(WarningModalComponent, {
  //     data: {
  //       title: 'Skip hints?',
  //       message: `Are you sure you want to skip hints?`,
  //       confirmBtnText: 'Skip',
  //       confirmBtnIcon: 'redo-outline'
  //     }
  //   });
  //
  //   dialog.afterClosed()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((response: CommonModalsActionsEnum) => {
  //       this.stopTimer = false;
  //       clearInterval(this.countdownTimer);
  //       visibleBtnProgressElement.classList.add('show-animation');
  //
  //       if (response === CommonModalsActionsEnum.CONFIRM) {
  //         this.shepherdService.cancel();
  //         clearInterval(this.countdownTimer);
  //       }
  //
  //       if (!!text) {
  //         this.startTimer(text);
  //       }
  //     });
  // }
  //
  // private displayHintsSteps(): void {
  //   this.actionButtonService.displayHints
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(() => {
  //       if (!this.shepherdService.isActive) {
  //         clearInterval(this.countdownTimer);
  //         this.hintsSteps = this.getHintsSteps;
  //         this.addHintsSteps();
  //         this.shepherdService.start();
  //       }
  //     });
  // }
  //
  // private getHintsShowAndHideEvents(index: number): HintsWhenModel {
  //   return {
  //     show: () => {
  //       this.startTimer(this.hintsSteps[index].text, this.hintsSteps[index].id);
  //     },
  //     hide: () => {
  //       clearInterval(this.countdownTimer);
  //     }
  //   };
  // }
  //
  // private getHintsButton(index: number): HintsButtonsModel[] {
  //   return [
  //     HintsButtonGoItTemplate,
  //     {
  //       text: 'Skip hints',
  //       classes: 'skip-hints',
  //       action: (() => this.cancelHintsTour(this.hintsSteps[index].text))
  //     },
  //   ];
  // }
  //
  // private getFinishHintsButtons(): HintsButtonsModel[] {
  //   return [
  //     HintsButtonCancelTemplate,
  //     {
  //       text: 'Repeat hints',
  //       classes: 'skip-hints',
  //       action: (() => {
  //         this.shepherdService.cancel();
  //         setTimeout(() => {
  //           this.shepherdService.start();
  //         }, 500);
  //       })
  //     },
  //   ];
  // }
  //
  // private addHintsSteps(): void {
  //   this.shepherdService.addSteps([...this.prepareProductListHintsSteps(this.hintsSteps), this.finishHintsConfig]);
  // }
  //
  // private setVisitedPage(hintId): void {
  //   if (hintId) {
  //     if (!localStorage.getItem(hintId)) { localStorage.setItem(hintId, 'visited'); }
  //   }
  // }
  //
  // get isVisitedThePageCreateDeliveryView(): boolean {
  //   return !!localStorage.getItem('deliveryNoteCreateView');
  // }
  //
  // get getHintsSteps() {
  //   return ProductFormLayoutHints(!!this.availableAttributes.length, !!this.assignedAttributes.length);
  // }

  ngAfterViewInit() {
    // this.shepherdService.confirmCancel = false;
    // this.shepherdService.modal = false;
    // this.displayHintsSteps();
  }

}
