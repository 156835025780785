import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Table cell definition should contains template of cell
 */
export interface CellDef {
  template: TemplateRef<any>;
}

/**
 * Header cell definition
 */
@Directive({selector: '[rnplHeaderCellDef]'})
export class HeaderCellDefDirective implements CellDef {

  /**
   * Name of column
   */
  @Input('rnplHeaderCellDef')
  public column: string;

  constructor(public template: TemplateRef<any>) {
  }
}

/**
 * Table cell definition
 */
@Directive({selector: '[rnplCellDef]'})
export class CellDefDirective implements CellDef {

  @Input('rnplCellDefColumn')
  public column: string;

  constructor(public template: TemplateRef<any>) {
  }
}

/**
 * Table header cell
 */
@Directive({
  selector: 'rnpl-header-cell, td[rnpl-header-cell]'
})
export class HeaderCellDirective {
}

/**
 * Table cell
 */
@Directive({
  selector: 'rnpl-cell, td[rnpl-cell]'
})
export class CellDirective {
}
