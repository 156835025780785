import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DUE_WITHIN_DATE_DAYS } from '../../../accounting/accounting.constants';
import { DueWithinDateDaysEnum } from '../../enums';

@Component({
  selector: 'rnpl-due-within-date-form',
  templateUrl: './due-within-date-form.component.html'
})
export class DueWithinDateFormComponent implements OnChanges {

  public dueWithinDateDaysEnum = DueWithinDateDaysEnum;
  public dueUponReceiptList: {value: DueWithinDateDaysEnum, label: string}[] = [
    {value: DueWithinDateDaysEnum.DAYS_1, label: 'FORM.DUE_UPON_RECEIPT'},
    ...DUE_WITHIN_DATE_DAYS
  ];

  @Input() dueDays = DUE_WITHIN_DATE_DAYS;
  @Input() selectLabel = 'COLUMN.DUE_WITHIN';
  @Input() selectPlaceholder = 'FORM.SELECT_DUE_WITHIN';
  @Input() dueWithinFieldName = 'paymentDueWithinType';
  @Input() dueWithinUserDefinedFieldName = 'paymentDueWithin';
  @Input() dueWithinDateFieldName = 'paymentDueWithinDate';
  @Input() dueWithinControl: FormControl;
  @Input() dueWithinUserDefinedControl: FormControl;
  @Input() dueWithinDateControl: FormControl;
  @Input() readOnly: boolean;
  @Input() hasDueUponReceipt: boolean = false;

  @Output() fieldChanged: EventEmitter<{ fieldValue: any, fieldName: string }> = new EventEmitter<{ fieldValue: any, fieldName: string }>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // add due upon receipt option
    if (changes.hasOwnProperty('hasDueUponReceipt')) {
      this.dueDays = this.dueUponReceiptList;
    }

    if (changes.hasOwnProperty('readOnly')) {
      if (this.readOnly) {
        this.dueWithinControl.disable();
        this.dueWithinUserDefinedControl.disable();
        this.dueWithinDateControl.disable();
      } else {
        this.dueWithinControl.enable();
        this.dueWithinUserDefinedControl.enable();
        this.dueWithinDateControl.enable();
      }
    }
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({ fieldValue, fieldName });
  }

  public updateDatePicker(event): void {
    this.updateField(event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null, this.dueWithinDateFieldName);
  }

  public updateValidity(): void {
    this.dueWithinControl.markAsUntouched();
    this.dueWithinControl.updateValueAndValidity();
  }

}
