import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { ProductTypes } from 'common/src/modules/products/product-types';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';


@Component({
  selector: 'rnpl-product-form-preferences',
  templateUrl: './product-form-preferences.component.html'
})
export class ProductFormPreferencesComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() productData: any;
  @Input() productType: ProductTypes;
  // @Input() readonly: boolean = false;

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public readonly productTypes = ProductTypes;

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.updateFormEditingStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly')) {
      this.updateFormEditingStatus();
    }
    if (changes.hasOwnProperty('productData') || changes.hasOwnProperty('productType')) {
      this.initForm();
    }
  }

  public initForm(): void {
    switch (this.productType) {
      case ProductTypes.SERVICES:
        this.form = this.fb.group({
          availableForTimeTracking: [get(this.productData, 'preferences.availableForTimeTracking') || null],
        });
        break;
      default:
        this.form = new FormGroup({});
        break;
    }
  }

  public getFormData(): any {
    const formData = this.form.getRawValue();
    return {...formData};
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  get availableForTimeTrackingControl(): FormControl { return this.form.get('availableForTimeTracking') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
