import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TradeDocumentsNumbersSettingsModel, TradeSettingsModel } from './models/trade-settings.model';
import { DocumentNumberSettingsModel, ResponseModel } from '../shared/models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

@Injectable({
  providedIn: 'root'
})
export class TradeSettingsService {

  constructor(
    private readonly http: HttpClient,
  ) {
  }

  public getTradeSettings(): Observable<TradeDocumentsNumbersSettingsModel> {
    return this.http.get<ResponseModel<TradeDocumentsNumbersSettingsModel>>('/settings')
      .pipe(
        map((data: ResponseModel<TradeDocumentsNumbersSettingsModel>) => data.data)
      );
  }

  public updateTradeSettings(documentIdSettings: TradeDocumentsNumbersSettingsModel): Observable<TradeDocumentsNumbersSettingsModel> {
    return this.http.patch<ResponseModel<TradeDocumentsNumbersSettingsModel>>('/settings', documentIdSettings)
      .pipe(
        map((data: ResponseModel<TradeDocumentsNumbersSettingsModel>) => data.data)
      );
  }

  public resetDocumentNumberSettings(documentType: DocumentTypesUppercaseEnum): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/settings/document-id/${documentType}/reset-default`)
      .pipe(
        map((data: ResponseModel<any>) => data.data)
      );
  }

  public getSettingsMail(): Observable<TradeSettingsModel> {
    return this.http.get<ResponseModel<TradeSettingsModel>>('/trade/settings')
      .pipe(
        map((data: ResponseModel<TradeSettingsModel>) => data.data)
      );
  }

  public getNextRunpleIdDocumentNumberSettings(
    documentType: DocumentTypesUppercaseEnum,
    runpleIdSettings: DocumentNumberSettingsModel
  ): Observable<string> {

    return this.http.post<ResponseModel<string>>(`/settings/document-id/${documentType}/next-runple-id`, {...runpleIdSettings})
      .pipe(map((response: ResponseModel<string>) => response.data));
  }

}
