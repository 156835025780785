export const WEEK_DAYS_LIST: {value: string; label: string}[] = [
  {
    value: 'MONDAY',
    label: 'WEEK_DAYS.MONDAY'
  },
  {
    value: 'TUESDAY',
    label: 'WEEK_DAYS.TUESDAY'
  },
  {
    value: 'WEDNESDAY',
    label: 'WEEK_DAYS.WEDNESDAY'
  },
  {
    value: 'THURSDAY',
    label: 'WEEK_DAYS.THURSDAY'
  },
  {
    value: 'FRIDAY',
    label: 'WEEK_DAYS.FRIDAY'
  },
  {
    value: 'SATURDAY',
    label: 'WEEK_DAYS.SATURDAY'
  },
  {
    value: 'SUNDAY',
    label: 'WEEK_DAYS.SUNDAY'
  },
];
