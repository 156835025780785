import { Component, Input } from '@angular/core';


import { BaseFormLayoutStep } from 'common/src/modules/wizard/base-form-layout-step';


@Component({
  selector: 'rnpl-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.scss']
})
export class FormLayoutComponent extends BaseFormLayoutStep {

  @Input()
  formLabel: string;

  constructor() {
    super();
  }

  public complete(): void {
    this.completed.emit(this.assignedAttributes);
    this.navigate(this.nextStep);
  }
}
