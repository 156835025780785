import { Component, Input } from '@angular/core';

import { DateColumnType, TableColumnModelExtended } from '../../../../../models/table-column.model';
import { get } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { Observable } from 'rxjs';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';

@Component({
  selector: 'rnpl-column-with-date',
  templateUrl: './column-with-date.component.html',
  styleUrls: ['./column-with-date.component.scss']
})
export class ColumnWithDateComponent {

  @Input()
  value: string;

  @Input()
  column: TableColumnModelExtended;

  public get dateType(): DateColumnType {
    return get(this.column, 'dateType', DateColumnType.WITH_TIME);
  }
  public dateTypes: typeof DateColumnType = DateColumnType;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly store: Store<AppState>,
  ) {
  }
}
