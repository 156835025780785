import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ApiResponse, PaginationModel, UIStatesEnum } from 'common/src/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { GeneralPartnerModel, PartnerModel } from '../models/partner.model';
import { DecrementLoadingRequestsCount, IncrementLoadingRequestsCount, LoadPartner, LoadPartnersList, LoadTitles, UpdatePartnerState, UpdatePartnerUpdatedAt, UpdateShouldRefreshEntity, UpdateValidations } from '../store/actions/partner.actions';
import { AppState } from '../../../store/state/app.state';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { ResponseList, ResponseModel } from '../../../shared/models/response';
import { DisplayToaster } from '../../../shared/decorators/toaster';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';
import { CompanyProfile, Title } from '../../../administration/models/company-profile.model';
import { environment } from 'projects/workspace/src/environments/environment';
import { PartnerContactModel } from '../models/partner-contact.model';
import { PartnerVatCheckModel } from '../models/vat-check.model';
import { PartnersCountersModel } from '../models/partner-counters.model';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { AdministrationsApiService } from '../../../administration/services/administrations-api.service';
import { DEFAULT_SORT_DIRECTION, SECONDARY_SORT_DIRECTION } from '../../../shared/constants';
import { PartnerBankAliasTypeEnum, PartnersTypeEnum, PartnersListTabsEnum } from '../enums';

@Injectable()
export class PartnersApiService {
  private readonly apiEndpoint: string = '/partners';

  public companyProfile: CompanyProfile;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService, // Required for @DisplayToaster decorator.
    private administrationsApiService: AdministrationsApiService,
    private translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  @DisplayToaster({showErrorMessage: true})
  getCombinedPartnersList(filters?: any, sort: FilterModel = { nameColumn: 'name', value: SECONDARY_SORT_DIRECTION }, status: PartnersListTabsEnum = PartnersListTabsEnum.ACTIVE): Observable<PartnerModel[]> {
    const params = {};

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value
      }
    }

    params[`sort[${sort.nameColumn}]`] = sort.value;
    params[`filters[status]`] = status;

    return this.http.get<ResponseModel<PartnerModel[]>>(this.apiUrl(), { params})
      .pipe(
        map((response: ResponseModel<PartnerModel[]>) => {
          return  response.data
            .filter((partner: PartnerModel) => {
              return (partner.type === PartnersTypeEnum.CORPORATE && this.companyProfile.partnerOptions.corporateEnabled) ||
                (partner.type === PartnersTypeEnum.PRIVATE && this.companyProfile.partnerOptions.privateEnabled);
            });
        }),
        map((response:PartnerModel[]) => response.map(item => ({
          ...item,
          searchLabel: `${item.name} ${item.city} ${item.countryTitle} ${item.alias}`
        })))
      );
  }

  getGeneralPartners(): Observable<GeneralPartnerModel[]> {
    return this.http.get<ResponseModel<GeneralPartnerModel[]>>(this.apiUrl('/general'))
      .pipe(
        map((response: ResponseModel<GeneralPartnerModel[]>) => response.data),
        // map((response: GeneralPartnerModel[]) => response.map(item => ({
        //   ...item,
        //   searchLabel: `${item.name} ${item.type}`
        // })))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getPartnersList(
    status: PartnersListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModel = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION },
    type: PartnersTypeEnum,
    filters: any = {},
  ): Observable<ResponseList<PartnerModel>> {
    const params = {
      page: pagination.page,
      per_page: pagination.per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http.get<ResponseList<PartnerModel>>(`${this.apiUrl()}/${type}`, { params })
      .pipe(tap((data: ResponseList<PartnerModel>) => {
        this.store.dispatch(LoadPartnersList({
          partnerListData: {
            [data.pagination.page]: {
              pagination: data.pagination, sort,
              data: data.data,
              type
            }
          },
          status,
        }));
      }));
  }

  @DisplayToaster({showErrorMessage: true})
  addPartnerBankAlias(partnerId: number, aliasType: PartnerBankAliasTypeEnum): Observable<any> {
    const body = { type: aliasType };
    return this.http.post(this.apiUrl(`/${partnerId}/bank-alias`), body);
  }

  @DisplayToaster({showErrorMessage: true})
  updatePartnerBankAlias(partnerId: number, aliasId: string, alias: string): Observable<any> {
    const body = { alias };
    return this.http.patch(this.apiUrl(`/${partnerId}/bank-alias/${aliasId}`), body);
  }

  @DisplayToaster({showErrorMessage: true})
  removePartnerBankAlias(partnerId: number, aliasId: string): Observable<any> {
    return this.http.delete(this.apiUrl(`/${partnerId}/bank-alias/${aliasId}`));
  }

  getCorporatePartnersRunpleIds(status: PartnersListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl('/corporate/runpleIds'), { params })
      .pipe(map((data: any) => data.data));
  }

  getCorporatePartnersFilterCountries(status: PartnersListTabsEnum): Observable<string[]> {
    const params = { status };
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/corporate/countries'), { params })
      .pipe(
        map((data: ResponseModel<string[]>) => data.data)
      );
  }

  getCorporatePartnersFilterCities(status: PartnersListTabsEnum): Observable<string[]> {
    const params = { status };
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/corporate/cities'), { params })
      .pipe(
        map((data: ResponseModel<string[]>) => data.data.filter(itm => !!itm))
      );
  }

  getCorporatePartnersFilterNames(status: PartnersListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<ResponseModel<any>>(this.apiUrl('/corporate/names'), { params })
      .pipe(
        map((data: ResponseModel<any>) => data.data.filter(itm => !!itm))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getPartnerContactsList(
    partnerId: number,
    type: PartnersTypeEnum,
    pagination: PaginationModel,
    sort: FilterModel = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION }
  ): Observable<ResponseList<PartnerContactModel>> {
    return this.http.get<ResponseList<PartnerContactModel>>(`${this.apiEndpoint}/${type}/${partnerId}/contacts`, {
      params: {
        page: pagination.page,
        per_page: pagination.per_page,
        [`sort[${sort.nameColumn}]`]: sort.value,
      }
    });
  }

  @DisplayToaster({showErrorMessage: true})
  public getPartnerById(id: string | number, type: PartnersTypeEnum = PartnersTypeEnum.CORPORATE): Observable<PartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<PartnerModel>>(`${this.apiEndpoint}/${type}/${id}`).pipe(
      tap((response: ResponseModel<PartnerModel>) => {
        this.store.dispatch(LoadPartner({partner: response.data}));
        this.store.dispatch(UpdatePartnerUpdatedAt({ updatedAt: new Date() }));
        this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
      }),
      map((response: ResponseModel<PartnerModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }


  public getPartnerListExport(title: string, status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  @DisplayToaster({showErrorMessage: true})
  getPartnersCounters(type: PartnersTypeEnum): Observable<PartnersCountersModel> {
    return this.http
      .get<ResponseModel<PartnersCountersModel>>(
        this.apiUrl(`/${type}/counters`)
      )
      .pipe(
        map(
          (data: ResponseModel<PartnersCountersModel>) =>
            data.data
        )
      );
  }

  @DisplayToaster({showErrorMessage: true})
  changePartnerStatusBatch(status: PartnersListTabsEnum, ids: number[], type: PartnersTypeEnum): Observable<any> {
    const body = { status, ids };
    return this.http.patch(this.apiUrl(`/${type}/status`), body);
  }

  @DisplayToaster({showErrorMessage: true})
  changePartnerStatus(status: PartnersListTabsEnum, partnerId: number, type: PartnersTypeEnum): Observable<PartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status };
    return this.http
      .patch(this.apiUrl(`/${type}/${partnerId}/status`), body)
      .pipe(
        tap((response: ResponseModel<PartnerModel>) => {
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPartner({ partner: response.data }));
        }),
        map((data: ResponseModel<PartnerModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updatePartner(partnerId: number, type: PartnersTypeEnum, field: FormInputChangedModel): Observable<PartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PartnerModel>>(this.apiUrl(`/${type}/${partnerId}`), field)
      .pipe(
        tap((response: ResponseModel<PartnerModel>) => {
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPartner({ partner: response.data }));
          this.store.dispatch(UpdateValidations({ validations: response.validations }));
        }),
        map((response: ResponseModel<PartnerModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          if (
            error.error.message &&
            error.error.message.includes('The entered VAT number belongs to') &&
            error.error.message.includes('Do you want to create another partner with the same VAT number')
          ) {
            const companiesNames = error.error.message
              .replace('VAT number is already taken! The entered VAT number belongs to ', '')
              .replace('. Do you want to create another partner with the same VAT number?', '');

            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'MESSAGES.VAT_TAKEN_TITLE',
                message: this.translateService.instant('MESSAGES.VAT_TAKEN_MSG', {partnerName:companiesNames }),
                confirmBtnText: 'BUTTON.CONTINUE',
                confirmBtnIcon: 'checkbox-on'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                field = {
                  ...field,
                  force: true
                };
                this.updatePartner(partnerId, type, field).subscribe();
              }
            });
            return throwError(error);
          }
          this.handlePopupErrors(error, partnerId);
          return throwError(error);
        })
      );
  }

  createPartner(type:PartnersTypeEnum, partnerData: Partial<PartnerModel> | {companyProfile: Partial<PartnerModel>}): Observable<PartnerModel> {
    return this.http.post<ResponseModel<PartnerModel>>(this.apiUrl(`/${type}/signup`), partnerData)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((data: ResponseModel<PartnerModel>) => data.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  createPartnerContact(partnerId: number, type: PartnersTypeEnum, contact: PartnerContactModel): Observable<PartnerContactModel> {
    return this.http.post<ResponseModel<PartnerContactModel>>(this.apiUrl(`/${type}/${partnerId}/contacts`), {contact})
      .pipe(
        map((data: ResponseModel<PartnerContactModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  updatePartnerContact(partnerId: number, contactId: number, type: PartnersTypeEnum, contact: PartnerContactModel): Observable<PartnerContactModel> {
    return this.http.put<ResponseModel<PartnerContactModel>>(this.apiUrl(`/${type}/${partnerId}/contacts/${contactId}`), {contact})
      .pipe(
        map((data: ResponseModel<PartnerContactModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  deletePartnerPermanently(partnerId: number, type: PartnersTypeEnum): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${type}/${partnerId}`));
  }

  @DisplayToaster({showErrorMessage: true})
  deletePartnerContact(partnerId: number, contactId: number, type: PartnersTypeEnum): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${type}/${partnerId}/contacts/${contactId}`));
  }

  public getListExportFile(status: PartnersListTabsEnum, type: PartnersTypeEnum): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${this.apiEndpoint}/${type}/${status}/csv`,
      {
        observe: 'response',
        responseType: 'blob'
      }
    );
  }

  public partnerSetEdit(id: string | number, type: PartnersTypeEnum, force = false): Observable<PartnerModel> {
    const body = { force };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<PartnerModel>>(this.apiUrl(`/${type}/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<PartnerModel>) => {
          this.store.dispatch(LoadPartner({ partner: response.data }));
          this.store.dispatch(UpdatePartnerState({ currentState: UIStatesEnum.EDIT }));
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<PartnerModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public partnerUnsetEdit(id: string | number, type: PartnersTypeEnum): Observable<PartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PartnerModel>>('put', this.apiUrl(`/${type}/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<PartnerModel>) => {
          this.store.dispatch(LoadPartner({ partner: response.data }));
          this.store.dispatch(UpdatePartnerState({ currentState: UIStatesEnum.VIEW }));
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<PartnerModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getTitles(): Observable<Title[]> {
    return this.http
      .get<Title[]>(`${environment.javaApiVersion}/utils/profile/titles`)
      .pipe(
        tap((response: Title[]) => {
          this.store.dispatch(LoadTitles({ titles: response }));
        }),
        map((response: Title[]) => response)
      );
  }

  validateVatNumber(vat: string, country_code?: string, code_iso3?: string): Observable<PartnerVatCheckModel> {
    const params = new HttpParams({fromObject: {vat, country_code, code_iso3}});

    return this.http.get<ApiResponse<PartnerVatCheckModel>>(`/integrations/taxud/company-info/by-vat-number`, {params: params})
      .pipe(
        map((response: ResponseModel<PartnerVatCheckModel>) => response.data),
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, partnerId?: number | string): void {
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          )
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.partnerSetEdit(partnerId, PartnersTypeEnum.CORPORATE, true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('FORM.PARTNER');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdatePartnerState({ currentState: UIStatesEnum.VIEW }));
        this.getPartnerById(partnerId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message || error.error.errors);
        break
    }
  }

}
