import { createSelector } from '@ngrx/store';

import { CreditNoteBlocksValidationsState, CreditNoteFeatureKey, CreditNoteState, CreditNoteStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';

export const selectFeature = ((state: AppState) => state[CreditNoteFeatureKey]);

export const selectCreditNoteEntity = createSelector(
  selectFeature,
  (state: CreditNoteStore) => state.entity
);

export const selectCreditNotesList = createSelector(
  selectFeature,
  (state: CreditNoteStore) => state.list
);

export const selectCreditNoteBlocksValidations = createSelector(
  selectFeature,
  (state: CreditNoteStore) => state.blocksValidations
);

export const selectCreditNote = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.creditNote
);

export const selectCreditNoteState = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.currentState
);

export const selectCountries = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.countries
);

export const selectCreditNoteCurrentState = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.currentState
);

export const selectCreditNotesFiles = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.creditNote.files
);

export const selectCreditNotePositions = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.positions
);

export const selectCreditNotePositionsCount = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.positionsCount
);

export const isShouldRefresh = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.isShouldRefresh
);

export const creditNoteUpdatedAt = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.updatedAt
);

export const loadingRequestsCount = createSelector(
  selectCreditNoteEntity,
  (state: CreditNoteState) => state.loadingRequestsCount
);

export const selectCreditNotePropertiesBlockValid = createSelector(
  selectCreditNoteBlocksValidations,
  (state: CreditNoteBlocksValidationsState) => state.propertiesBlockValid
);

export const selectCreditNoteBillingInfoBlockValid = createSelector(
  selectCreditNoteBlocksValidations,
  (state: CreditNoteBlocksValidationsState) => state.billingInfoBlockValid
);

export const selectCreditNotePositionsBlockValid = createSelector(
  selectCreditNoteBlocksValidations,
  (state: CreditNoteBlocksValidationsState) => state.positionsBlockValid
);
