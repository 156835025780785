import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentStepModel, DocumentTypeEnum, TitleTypeEnum } from './document-step.model';
import { NavBarBtnModel, SendEmailStatusEnum, VatStatusEnum } from '../../../models';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';
import { EMAIL_SENT_STATUS_CLASSES, EMAIL_SENT_STATUS_TITLES } from 'projects/workspace/src/app/shared/constants';
import { PositionsActionsType } from '../table/custom-table.enums';

@Component({
  selector: 'rnpl-document-steps',
  templateUrl: './document-steps.component.html',
  styleUrls: ['./document-steps.component.scss']
})
export class DocumentStepsComponent implements OnInit {

  readonly currenciesSymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;

  public titleType = TitleTypeEnum;
  public documentTypeEnum = DocumentTypeEnum;
  public sendEmailStatusEnum = SendEmailStatusEnum;

  public vatStatusClasses: {[key: string]: string } = {
    [VatStatusEnum.FAILED]: 'icon--alert icon-alert-circle',
    [VatStatusEnum.PASSED]: 'icon--success icon-checkmark-circle',
    [VatStatusEnum.PENDING]: 'icon--pending-grey icon-question-mark-circle',
    [VatStatusEnum.NOT_VERIFIED]: 'icon--pending-grey icon-question-mark-circle',
    [VatStatusEnum.NA]: 'icon--pending-grey icon-question-mark-circle',
  };

  public emailSentStatusClasses: {[key: string]: string } = EMAIL_SENT_STATUS_CLASSES;
  public emailSentTitleByStatus: {[key: string]: string } = EMAIL_SENT_STATUS_TITLES;

  public vatTitleByStatus: {[key: string]: string } = {
    [VatStatusEnum.FAILED]: 'VAT.VAT_NUMBER_CHECKED',
    [VatStatusEnum.PASSED]: 'VAT.VAT_NUMBER_CHECKED',
    [VatStatusEnum.PENDING]: 'VAT.VAT_NUMBER_CHECKED',
    [VatStatusEnum.NA]: 'VAT.VAT_NOT_VERIFIED',
    [VatStatusEnum.NOT_VERIFIED]: 'VAT.VAT_NOT_VERIFIED',
  };


  @Input()
  public documentSteps: DocumentStepModel[];

  @Output() clickAction = new EventEmitter<string>();
  @Output() clickActionWithValue = new EventEmitter<{actionName: string|PositionsActionsType, returnValue: any}>();

  constructor() { }

  public actionsEvent(actionName: string, disabled: boolean = false) {
    if (actionName && !disabled) {
      this.clickAction.emit(actionName);
    }
  }

  public actionsEventWithValue(event: {actionName: string|PositionsActionsType, returnValue: any}, disabled: boolean = false) {
    if (event.actionName && !disabled) {
      this.clickActionWithValue.emit(event);
    }
  }

  ngOnInit() {
  }

  public convertedValue(value): string {
    return typeof value === 'string'
      ? value.replace('_', ' ')
      : null;
  }

  public toggleLinkedDocuments(step): void {
    step.hideDocuments = !step.hideDocuments;
  }

  public displayToggle(step): boolean {
    return step.documents && step.documents.length
      ? step.documents.some(item => item.type !== DocumentTypeEnum.StatusLine)
      : false;
  }

  public getMultipleButton(multiButton: NavBarBtnModel): NavBarBtnModel {
    return {
      ...multiButton,
      actions: multiButton.actions.filter(btn => !btn.hideAction)
    };
  }
}
