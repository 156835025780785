import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FileSaverService } from 'ngx-filesaver';
import { TranslateService } from "@ngx-translate/core";
import { get, isEqual } from 'lodash';

import { TableColumnModel, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { NavBarBtnModel, PaginationModel, UIStatesEnum } from 'common/src/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FilterModel } from 'projects/workspace/src/app/warehouse/models/filter.model';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  BIN_LOCATIONS_TYPE_LABELS,
  BinLocationsListColumns,
  getBinLocationsListInnerTableColumns,
  BinLocationsTabs, EMPTY_STATE_DATA,
  NavBarButtons
} from "./warehouse-bin-locations.config";
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { BinLocationsTabsEnum } from '../../enums';
import { DEFAULT_PAGINATION, DEFAULT_SORT_DIRECTION } from '../../../shared/constants';
import { ResponseList, RowNumberSettings, UserSorting } from '../../../shared/models/response';
import { BinLocationService } from '../../services';
import { CreateNewBinLocationModalComponent } from 'common/src/modules/modals/modals-warehouse';
import { selectBinLocationsList, selectCurrentWarehouse, selectWarehouseState } from '../../store/selectors';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { CommonModalsActionsEnum, DangerModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { FileService } from 'common/src/services/file.service';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { getContentDispositionFileName } from 'common/src/modules/rnpl-common/helpers';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { UserTableSettingsService } from '../../../shared/services';


@Component({
  selector: 'rnpl-warehouse-bin-locations',
  templateUrl: './warehouse-bin-locations.component.html',
  styleUrls: ['./warehouse-bin-locations.component.scss'],
})
export class WarehouseBinLocationsComponent implements OnInit, OnDestroy {
  public warehouseData: any; // todo type
  public emptyStateData = EMPTY_STATE_DATA; // todo
  public tabs:  TabDefinitionModel[] = BinLocationsTabs;
  public columns: TableColumnModel[] = [];
  public customizedColumns: TableColumnModel[] = [];
  public innerTableColumns: TableColumnModel[] = getBinLocationsListInnerTableColumns(this.translate);
  public pagination: PaginationModel = DEFAULT_PAGINATION;
  public actionBarButtons: NavBarBtnModel[] = NavBarButtons;
  public selectedRows = [];
  public sort: FilterModel = { nameColumn: 'binLocation', value: DEFAULT_SORT_DIRECTION };
  public binLocationsTabsEnum = BinLocationsTabsEnum;
  public editingMode: boolean = false;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  private listData$: BehaviorSubject<any> = new BehaviorSubject(null); // todo type
  readonly binLocationsList$: BehaviorSubject<any[]> = new BehaviorSubject([]); // todo type
  readonly selectedInnerTableData$: BehaviorSubject<any[]> = new BehaviorSubject([]); // todo type
  readonly activeStatus$: BehaviorSubject<BinLocationsTabsEnum> =
    new BehaviorSubject<BinLocationsTabsEnum>(BinLocationsTabsEnum.GENERAL_STOCK);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly exportCsvRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly createBinRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild('tableComponent', {static: false}) tableComponentRef;

  constructor(
    private readonly toasterService: ToasterService,
    private readonly binLocationService: BinLocationService,
    private readonly location: Location,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private translate: TranslateService,
    private userTableSettingsService: UserTableSettingsService
  ) {}

  ngOnInit() {
    this.bindBinLocationsList();

    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: ParamMap) => {
        const pageFromRouting = Number(params.get('page'));

        if (this.activeStatus !== params.get('location')) {
          this.sort = { nameColumn: 'binLocation', value: DEFAULT_SORT_DIRECTION };
        }
        this.activeStatus$.next(params.get('location') as BinLocationsTabsEnum);
        this.selectedRows = [];
        this.columns = this.filterColumns(BinLocationsListColumns);
        this.pagination = { ...this.pagination, page: pageFromRouting };

        // const binLocationsByCurrentPage = this.listData$.getValue()[this.activeStatus][pageFromRouting];
        //
        // if (binLocationsByCurrentPage && binLocationsByCurrentPage.data && isEqual(this.sort, binLocationsByCurrentPage.sort)) {
        //   this.isLoading$.next(false);
        //   this.pagination = binLocationsByCurrentPage.pagination;
        //   this.sort = binLocationsByCurrentPage.sort;
        //   this.binLocationsList$.next(this.prepareListData(binLocationsByCurrentPage.data));
        //   this.getBinLocationsList(true);
        //   // this.cdr.detectChanges();
        // } else {
        //   this.pagination = { ...this.pagination, page: pageFromRouting };
          this.getBinLocationsList();
        // }

        this.getBinLocationsTabsCounters();

      });

    this.selectWarehouse();

    this.store.select(selectWarehouseState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
      });
  }

  public bindBinLocationsList() {
    this.store.select(selectBinLocationsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((listItems) => {
        this.listData$.next(listItems);
      });
  }

  public getBinLocationsList(preventLoading = false, page = this.pagination.page): void {
    this.selectedRows = [];

    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    if (this.tableComponentRef) {
      this.tableComponentRef.collapseAll();
    }

    this.userTableSettingsService.getRowNumberSettings(DocumentTypesUppercaseEnum.BIN_LOCATION)
      .pipe(
        tap((rowNumberSettings: RowNumberSettings) => {
          this.pagination = {...this.pagination , per_page: rowNumberSettings.number};
        }),
        switchMap(() => this.userTableSettingsService.getUserSorting(this.activeStatus, DocumentTypesUppercaseEnum.BIN_LOCATION)),
        tap((sorting: UserSorting) => {
          this.sort = {
            nameColumn: sorting.sortBy || this.sort.nameColumn,
            value: sorting.direction || this.sort.value
          };
          const binLocationsByCurrentPage = this.listData$.getValue()[this.activeStatus][page];
          if (
            binLocationsByCurrentPage &&
            isEqual(this.sort, binLocationsByCurrentPage.sort) &&
            binLocationsByCurrentPage.pagination.per_page === this.pagination.per_page
          ) {
            this.pagination = binLocationsByCurrentPage.pagination;
            this.sort = binLocationsByCurrentPage.sort;
            this.binLocationsList$.next(this.prepareListData(binLocationsByCurrentPage.data));
            this.isLoading$.next(false);
          }
        }),
        switchMap(() => this.binLocationService.getBinLocations(
          this.activeStatus,
          // this.filters,
          page,
          this.pagination.per_page,
          this.sort
        )),
        takeUntil(this.destroy$)
      )
      .subscribe((data: ResponseList<any>) => { // todo type
        this.binLocationsList$.next(this.prepareListData(data.data));
        this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
        this.isLoading$.next(false);

      }, error => {
        this.isLoading$.next(false);
        this.showMsg('error', error.error.message);
      });
  }

  public prepareListData(listData: any): any[] {
    return listData.map(itm => {
      return {
        ...itm,
        typeLabel: this.translate.instant(BIN_LOCATIONS_TYPE_LABELS[itm.type])
      }
    });
  }

  private getBinLocationsTabsCounters(): void {
    this.binLocationService.getBinLocationsCounters()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: any) => {
        this.tabs = this.tabs.map((t: TabDefinitionModel) => ({...t, count: response[t.routeParam]}));
      }, error => {
        this.showMsg('error', error.error.message);
      });
  }

  private filterColumns(cols: TableColumnModelExtended[]): TableColumnModelExtended[] {
    const columns = [...cols];

    return columns.filter((col: TableColumnModelExtended) => {
      if (!col.showWithStatuses) { return true; } // show for all statuses
      return col.showWithStatuses.includes(this.activeStatus); // check for showing
    });
  }

  public onActionReceived(btnAction: string) {
    if (this[btnAction]) {
      this[btnAction]();
    }
  }

  public selectWarehouse(): void {
    this.store
      .select(selectCurrentWarehouse)
      .pipe(
        filter((currentWarehouse: WarehouseResponse) => !!currentWarehouse),
        takeUntil(this.destroy$)
      )
      .subscribe((currentWarehouse) => {
        this.warehouseData = currentWarehouse;
        this.getBinLocationsList(true);
        this.getBinLocationsTabsCounters();
      });
  }

  onDeleteClick(): void {
    this.changeStatus('deleted', 'onDeleteClick');
  }

  onActivateClick(): void {
    this.changeStatus('active', 'onActivateClick');
  }

  onDeactivateClick(): void {
    this.changeStatus('inactive', 'onDeactivateClick');
  }

  onRestoreClick(): void {
    this.changeStatus('active', 'onRestoreClick');
  }

  onDeletePermanentlyClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETED_SELECTED_PERMANENTLY',
        message: `${this.translate.instant('MODAL.YOU_ARE_ABOUT_TO_DELETE')} ${this.selectedRows.length} ${this.translate.instant('WAREHOUSE.BIN_LOCATION.PERMANENTLY_DELETE_MESSAGE')}`,
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onDeletePermanentlyClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((response: CommonModalsActionsEnum) => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          const binsIds = this.selectedRows.map((bin) => bin.id);
          this.binLocationService.deleteBinLocations(binsIds)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              this.getBinLocationsList(true);
              this.getBinLocationsTabsCounters();
            });
        }
      });
  }

  public changeStatus(status: string, action: string): void {
    const binsIds = this.selectedRows.map((bin) => bin.id);
    this.binLocationService.changeBinLocationStatus(status, binsIds)
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next(action)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getBinLocationsList(true);
        this.getBinLocationsTabsCounters();
      });
  }

  public rowClickReceiver(event): void {
    if (event.type === TableActivateTypes.RowDetail) {
      this.selectedInnerTableData$.next(this.prepareInnerTableColumns(event.row.products));
    }
  }

  private prepareInnerTableColumns(products: any[]): any[] { // todo: type
    return products.map((product: any) => ({
      ...product,
      productLink: {
        label: product.productRunpleId,
        routerLink: `/products/product-view/${product.productId}`
      },
      productNameLink: {
        label: product.productName,
        routerLink: `/products/product-view/${product.productId}`
      },
      expirationDateValue: {expectedDate: product.expirationDate, date: product.receivedAt},
    }));
  }

  private showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public goToPage(page: number): void {
    const url = this.getNewPaginationUrl(this.router.url);

    this.pagination = { ...this.pagination, page: page + 1 };
    this.router.navigate([url, this.pagination.page]);
  }

  public createBinLocation(): void {
    const dialog = this.dialog.open(CreateNewBinLocationModalComponent, {
      data: {
        rackFormat: this.warehouseData.preferences.rackFormat,
        binLocationFormat: this.warehouseData.preferences.binLocationFormat,
        location: this.activeStatus,
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe(() => this.getBinLocationsList(true));
  }

  public sortTable(e): void {
    let sortedProp = e.column.prop;

    if (sortedProp === 'typeLabel') {
      sortedProp = 'type';
    }

    if (sortedProp === 'expirationDateValue') {
      sortedProp = 'expirationDate';
    }

    this.sort = { nameColumn: sortedProp, value: e.newValue.toUpperCase() };
    this.saveUserSorting(sortedProp, e.newValue.toUpperCase());
  }

  public saveUserSorting(sortBy: string, direction: 'ASC'|'DESC'): void {
    const sorting = {
      sortBy,
      direction,
      type: DocumentTypesUppercaseEnum.BIN_LOCATION,
      status: this.activeStatus
    }
    this.userTableSettingsService.saveUserSorting(sorting)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getBinLocationsList(true, this.pagination.page));
  }

  public onExportCsvClick(): void {
    if (this.exportCsvRequest$.getValue()) { return; }
    this.exportCsvRequest$.next(true);

    const status = this.activeStatus;

    this.binLocationService.getListExport(status)
      .subscribe((fileParams: FileUploadParams) => {
        const { url } = fileParams;
        this.downloadFile(url);
      }, error => {
        this.showMsg('error', error.error.message || error.error.errors);
      });
  }

  public downloadFile(url) {
    this.fileService.downloadFile(url)
      .pipe(
        finalize(() => this.exportCsvRequest$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe((res: any) => {
        this.fileSaverService.save(res.body, getContentDispositionFileName(res.headers.get('Content-Disposition')));
      });
  }

  public getNewPaginationUrl(oldUrl: string): string {
    return oldUrl.split('/').slice(0, -1).join('/');
  }

  public get activeStatus(): BinLocationsTabsEnum { return this.activeStatus$.getValue(); }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
