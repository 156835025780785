import { get } from 'lodash';

import { PoTabs } from '../../enums';
import { CustomerTypeEnum, GeneralPartnerTypeEnum, NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { PurchaseOrder } from '../../models';
import {
  CreateDocumentStepsModel,
  DocumentToDoListModel,
  LinkedDocumentsModel,
  ViewDocumentListModel
} from 'common/src/models/view-document-list.model';
import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { DocumentPreviewTabsEnum } from 'common/src/models/document-preview-tabs.enum';
import { DocumentTypeEnum, StepStatusEnum } from 'common/src/modules/ui-components/document-steps/document-step.model';
import { PartnersTypeEnum } from '../../../partners/corporate/enums';
import { DeliveryNoteListTabsEnum } from '../../../delivery-note/enums';
import { BehaviorSubject } from 'rxjs';

export function getButtonsByStatus(state: UIStatesEnum, allowedSoCreation: boolean): NavBarBtnModel[] {
  return getNavBarButtonWithState(allowedSoCreation)
    .filter((btn: NavBarBtnModel) => btn.other.includes(state));
}

export function getViewDocumentList(
  positionsCount: number,
  purchaseOrder: PurchaseOrder,
  deliveryInfoBlockValid: boolean,
  showLinkedDoc: boolean,
  positionsBlockValid: boolean,
  isNarrowScreen: boolean,
  currentState: UIStatesEnum,
  isTemplate
): ViewDocumentListModel[] {
  // const showLinkedDocuments: boolean = showLinkedDoc && (
  //   purchaseOrder.status === PoTabs.Deleted && !!purchaseOrder.linkedDocuments.length ||
  //   purchaseOrder.status === PoTabs.Canceled && !!purchaseOrder.linkedDocuments.length ||
  //   purchaseOrder.status !== PoTabs.Canceled && purchaseOrder.status !== PoTabs.Deleted
  // );
  const viewDocumentItems: ViewDocumentListModel[] = [
    {
      title: 'COLUMN.VENDOR',
      template: 'BusinessPartner',
      anchor: 'businessPartner',
    },
    {
      icon: 'file-text',
      title: 'DOC_BLOCK.PROPERTIES',
      template: 'Properties',
      anchor: 'properties',
      haveIndicator: true,
      // showIndicator: !propertiesValid
    },
    {
      icon: 'list',
      title: 'DOC_BLOCK.POSITIONS',
      descr: positionsBlockValid ? 'DOC_SUMMARY.GREAT_JOB' : 'DOC_SUMMARY.ADD_POSITIONS',
      template: 'Position',
      count: positionsCount,
      anchor: 'positions',
      showIndicator: !positionsBlockValid,
      hintId: 'sidebarPo',
      haveIndicator: true,
    },
    {
      icon: 'car',
      title: 'DOC_BLOCK.DELIVERY',
      descr: deliveryInfoBlockValid ? 'DOC_SUMMARY.GREAT_JOB' : 'DOC_SUMMARY.SPECIFY_DELIVERY_INFO',
      template: 'DeliveryInfo',
      anchor: 'delivery',
      haveIndicator: true,
      showIndicator: !deliveryInfoBlockValid
    },
    {
      icon: 'attach',
      title: 'COMMON.FILE',
      descr: 'DOC_SUMMARY.LOOKS_GOOD',
      template: 'PoFile',
      count: purchaseOrder.files.length,
      anchor: 'order-file',
      // showIndicator: !purchaseOrder.files.length,
      haveIndicator: true,
      showCondition: !isTemplate && isNarrowScreen && (currentState !== UIStatesEnum.VIEW || get(purchaseOrder, 'files.length'))
    },
    // {
    //   icon: 'link',
    //   title: 'TABLE_SUMMARY_BAR.DOCUMENTS',
    //   template: 'Documents',
    //   count: purchaseOrder.linkedDocuments.length,
    //   anchor: 'Documents',
    //   // showIndicator: purchaseOrder.linkedDocuments.length === 0,
    //   showCondition: showLinkedDocuments
    // },
    // {
    //   icon: 'info',
    //   title: 'DOC_BLOCK.REMARKS',
    //   template: 'Remarks',
    //   anchor: 'remarks',
    // },
  ];

  return viewDocumentItems.filter(t => !t.hasOwnProperty('showCondition') || t.showCondition);
}

export function getNavBarButtonWithState(allowedSoCreation = false): NavBarBtnModel[] {
  return [
    {
      classes: 'btn-primary-grey red',
      text: 'BUTTON.DELETE',
      iconName: 'trash-2',
      actionName: 'onDeleteClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        PoTabs.Draft,
        PoTabs.Open,
        PoTabs.Completed,
        PoTabs.Canceled,
        PoTabs.Deleted,
      ],
      other: [UIStatesEnum.VIEW, UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.MORE_ACTIONS',
      iconName: 'menu',
      disabled: false,
      // allowLoadingSpinner: true,
      disabledMainAction: true,
      withActions: true,
      spinnerColor: '#5E6681',
      multiple: false,
      dropdownClass: 'inner-blue-400',
      width: 141,
      isMoreAction: true,
      hideWithStatus: [
        PoTabs.Template
      ],
      actions: [
        {
          actionTitle: 'BUTTON.DELETE',
          actionName: 'onDeleteClick',
          actionIcon: 'trash-2',
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            PoTabs.Open,
            PoTabs.Completed,
            PoTabs.Deleted,
          ],
        },
        {
          actionTitle: 'BUTTON.DELETE_PERMANENTLY',
          actionName: 'onDeletePermClick',
          actionIcon: 'fire',
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            PoTabs.Draft,
            PoTabs.Open,
            PoTabs.Canceled,
            PoTabs.Completed,
          ],
        },
        {
          actionTitle: 'BUTTON.CANCEL_ENTITY',
          actionName: 'onCancelPOClick',
          actionIcon: 'close-square',
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            PoTabs.Draft,
            PoTabs.Completed,
            PoTabs.Canceled,
            PoTabs.Deleted,
          ],
        },
        {
          actionTitle: 'BUTTON.CLONE',
          actionName: 'onClonePOClick',
          actionIcon: 'copy',
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            PoTabs.Canceled,
            PoTabs.Deleted,
          ],
        },
        {
          actionTitle: 'SO_PO.CREATE_SO',
          actionName: 'onCreateSalesOrderClick',
          actionIcon: 'sales-order',
          classes: 'btn-white',
          greyIcon: true,
          hideAction: !allowedSoCreation,
          hideWithStatus: [
            PoTabs.Draft,
            PoTabs.Canceled,
            PoTabs.Deleted,
          ],
        },
        {
          actionTitle: 'BUTTON.REOPEN',
          actionName: 'onRestoreClick',
          actionIcon: 'undo',
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            PoTabs.Draft,
            PoTabs.Open,
            PoTabs.Completed,
          ],
        },
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
     classes: 'btn-white',
     text: 'BUTTON.EDIT',
     iconName: 'edit',
     disabled: false,
     actionName: 'onEditPOClick',
     allowLoadingSpinner: true,
     greyIcon: true,
     multiple: false,
     hintsId: 'editBtn',
     hideWithStatus: [
       PoTabs.Completed,
       PoTabs.Canceled,
       PoTabs.Deleted,
     ],
     other: [UIStatesEnum.VIEW]
    },
    {
     classes: 'btn-primary',
     text: 'BUTTON.CREATE',
     iconName: 'plus-square',
     disabled: false,
     multiple: false,
     allowLoadingSpinner: true,
     actionName: 'onProceedClick',
     dropdownClass: 'inner-blue-400',
     width: 141,
     actions: [
       {
         actionTitle: 'BUTTON.CREATE',
         actionName: 'onProceedClick',
         actionIcon: 'plus-square'
       },
       {
         actionTitle: 'BUTTON.SAVE_AS_DRAFT',
         actionName: 'onCreateClick',
         actionIcon: 'save'
       },
     ],
     hideWithStatus: [
       PoTabs.Deleted,
       PoTabs.Open,
       PoTabs.Canceled,
       PoTabs.Completed,
       PoTabs.Template,
     ],
     other: [UIStatesEnum.CREATE]
    },
    {
     classes: 'btn-primary',
     text: 'BUTTON.CREATE',
     iconName: 'plus-square',
     disabled: false,
     actionName: 'onProceedClick',
     allowLoadingSpinner: true,
     multiple: false,
     hideWithStatus: [
       PoTabs.Open,
       PoTabs.Completed,
       PoTabs.Canceled,
       PoTabs.Deleted,
       PoTabs.Template,
     ],
     other: [UIStatesEnum.VIEW]
    },
    {
     classes: 'btn-primary',
     text: 'BUTTON.DONE',
     iconName: 'checkmark',
     actionName: 'onFinishEditingClick',
     allowLoadingSpinner: true,
     multiple: false,
     hintsId: 'doneBtn',
     other: [UIStatesEnum.EDIT]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE_PO',
      iconName: 'plus-square',
      actionName: 'createDocumentFromTemplate',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        PoTabs.Draft,
        PoTabs.Open,
        PoTabs.Completed,
        PoTabs.Canceled,
        PoTabs.Deleted,
      ],
      other: [UIStatesEnum.VIEW]
    },
  ];
}

export function getPoTabs(purchaseOrder: PurchaseOrder): TabDefinitionModel[] {
  const linkedDocumentsLength: number = get(purchaseOrder, 'linkedDocuments.length', 0);
  const poTabs: TabDefinitionModel[] = [
    {
      label: 'APP.PURCHASE_ORDER',
      tabName: DocumentPreviewTabsEnum.DOCUMENT,
      link: `${DocumentPreviewTabsEnum.DOCUMENT}`,
      // icon: 'file-text',
    },
    {
      label: 'ATTACHMENTS.FILES',
      tabName: DocumentPreviewTabsEnum.ATTACHMENTS,
      link: `${DocumentPreviewTabsEnum.ATTACHMENTS}`,
      showCondition: purchaseOrder.status !== PoTabs.Template
      // icon: 'message-square',
    },
    {
      label: 'MODAL.REMARK',
      tabName: DocumentPreviewTabsEnum.REMARK,
      link: `${DocumentPreviewTabsEnum.REMARK}`,
      // icon: 'message-square',
    },
  ]

  const documentsTab: TabDefinitionModel = {
      label: 'TABLE_SUMMARY_BAR.DOCUMENTS',
      tabName: DocumentPreviewTabsEnum.DOCUMENTS,
      link: `${DocumentPreviewTabsEnum.DOCUMENTS}`,
      count: linkedDocumentsLength,
      // icon: 'link',
    };

  if (!!linkedDocumentsLength) {
    poTabs.splice(1, 0, documentsTab);
  }

  return poTabs;
}

export function getCreateDocumentSteps(
  purchaseOrder: PurchaseOrder,
  deliveryInfoBlockValid: boolean,
  positionsBlockValid: boolean,
): CreateDocumentStepsModel[] {
  return [
    {
      title: 'FORM.SELECT_VENDOR',
      valid: purchaseOrder.properties.partnerType === CustomerTypeEnum.GENERAL
        ? get(purchaseOrder, 'properties.company.id') && get(purchaseOrder, 'properties.generalPartnerName')
        : get(purchaseOrder, 'properties.company.id'),
      anchorFragment: 'businessPartner'
    },
    {
      title: 'DOC_SUMMARY.ADD_POSITIONS',
      valid: positionsBlockValid,
      anchorFragment: 'positions'
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_DELIVERY_INFO',
      valid: deliveryInfoBlockValid,
      anchorFragment: 'delivery'
    },
  ];
}

export function getDocumentToDoList(
  checkVat: boolean,
  purchaseOrder: PurchaseOrder,
  showDeliveryDate: boolean,
  canTrackDelivery: boolean,
  deliveryServiceLoading$: BehaviorSubject<boolean>,
  hideProductReceiptAction: boolean,
  hideProductWarehousingAction: boolean,
  hideCreateIncomingInvoice: boolean,
  linkedSo: LinkedDocumentsModel[],
  linkedBatches: LinkedDocumentsModel[],
  linkedInvoice: LinkedDocumentsModel[],
  positionsTotals: any
): DocumentToDoListModel[] {
  return [
    {
      title: 'DOC_SUMMARY.RECEIVE_GOODS',
      hide: !purchaseOrder.hasResaleGoods || purchaseOrder.status === PoTabs.Completed,
      valid: purchaseOrder.delivery !== 'partially_shipped'
        && purchaseOrder.delivery !== 'pending'
        && !positionsTotals.openProductsPcs,
      disabled: false,
      hideActionList: hideProductReceiptAction
        && hideProductWarehousingAction
        && purchaseOrder.status !== PoTabs.Open
        && !canTrackDelivery,
      linkedDocuments: linkedBatches,
      actions: [
        {
          title: 'WAREHOUSE.DELIVERY_NOTE.TRACK_DELIVERY',
          icon: 'external-link',
          action: 'trackShipment',
          btnClass: 'grey',
          hide: !canTrackDelivery || purchaseOrder.status !== PoTabs.Open,
        },
        {
          title: 'DOC_SUMMARY.RECEIVE_GOODS',
          icon: 'goods-receipt',
          action: 'productReceipt',
          btnClass: 'primary',
          hide: get(purchaseOrder, 'properties.allowedProductWarehousing') || hideProductReceiptAction,
        },
        {
          text: 'DOC_SUMMARY.RECEIVE_GOODS',
          iconName: 'goods-receipt',
          actionName: 'productReceipt',
          disabledMainAction: false,
          classes: 'btn-primary',
          hide: hideProductWarehousingAction || hideProductReceiptAction,
          multiple: true,
          actions: [
            {
              actionTitle: 'DOC_SUMMARY.RECEIVE_GOODS',
              actionIcon: 'goods-receipt',
              actionName: 'productReceipt',
            },
            {
              actionTitle: 'COLUMN.WAREHOUSE',
              actionIcon: 'warehousing',
              actionName: 'productWarehousing',
            },
          ]
        },
        {
          title: 'COLUMN.WAREHOUSE',
          icon: 'warehousing',
          action: 'productWarehousing',
          btnClass: 'primary',
          hide: hideProductWarehousingAction
            || (!hideProductWarehousingAction && !hideProductReceiptAction),
        },
        // {
        //   title: 'BUTTON.PRODUCT_RETURN',
        //   icon: 'undo',
        //   action: 'productReturn',
        //   btnClass: 'grey',
        //   hide: hideProductReturnAction,
        // },
        // {
        //   title: 'INCOMING_DELIVERY.CANCEL_WAREHOUSING',
        //   icon: 'undo',
        //   action: 'productCancelWarehousing',
        //   btnClass: 'grey',
        //   hide: hideProductCancelWarehousingAction,
        // },
      ],
      additionalInfoList: [
        {
          type: DocumentTypeEnum.StatusLine,
          statusLineType: 'date',
          label: 'DOC_SUMMARY.DELIVERY_DATE',
          icon: 'calendar',
          value: purchaseOrder.createdAt,
          expectedDate: purchaseOrder.deliveryInformation.estimatedDeliveryDate,
          hide: !showDeliveryDate
        },
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'DOC_SUMMARY.NOT_RECEIVED_UNITS',
          value: positionsTotals.openProductsPcs,
          maxValue: positionsTotals.resaleProductsPcs,
          valueOnly: true,
          colorState: 'main-600',
          hide: purchaseOrder.status !== PoTabs.Open || !positionsTotals.openProductsPcs
        },
        {
          type: DocumentTypeEnum.Steps,
          hide: !canTrackDelivery || purchaseOrder.status !== PoTabs.Open || !get(purchaseOrder, 'deliveryInformation.deliveryServiceDetails.isAuthorized'),
          loading$: deliveryServiceLoading$,
          steps: [
            {
              icon: 'goods-receipt',
              title: 'STATUS.DELIVERED',
              status: get(purchaseOrder, 'deliveryInformation.deliveryServiceDetails.deliveredAt') ? StepStatusEnum.Success : StepStatusEnum.Pending,
            },
            {
              icon: 'picking',
              title: 'DELIVERY_SERVICES.IN_TRANSIT',
              status: get(purchaseOrder, 'deliveryInformation.deliveryServiceDetails.inDeliveryAt') ? StepStatusEnum.Success : StepStatusEnum.Pending,
            },
            {
              icon: 'car',
              title: 'DELIVERY_SERVICES.ON_THE_WAY',
              status: get(purchaseOrder, 'deliveryInformation.deliveryServiceDetails.onTheWayAt') ? StepStatusEnum.Success : StepStatusEnum.Pending,
            },
            {
              icon: 'clock',
              title: 'DELIVERY_SERVICES.DATA_TRANSFERRED',
              status: get(purchaseOrder, 'deliveryInformation.deliveryServiceDetails.transferredAt') ? StepStatusEnum.Success : StepStatusEnum.Pending,
            },
          ]
        },
      ]
    },
    {
      title: 'BUTTON.CAPTURE_INCOMING_INVOICE',
      valid: !!linkedInvoice.length && !positionsTotals.unbilledProductsPcs,
      hideActionList: hideCreateIncomingInvoice,
      hideAdditionalInfoList: purchaseOrder.status !== PoTabs.Open || !positionsTotals.unbilledProductsPcs,
      linkedDocuments: linkedInvoice,
      hide: purchaseOrder.status === PoTabs.Completed,
      actions: [
        {
          title: 'BUTTON.CREATE_INVOICE',
          icon: 'incoming-invoice',
          action: 'linkIncomingInvoice',
          btnClass: 'primary',
          hide: hideCreateIncomingInvoice
        },
      ],
      additionalInfoList: [
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'DOC_SUMMARY.UNBILLED_UNITS',
          value: positionsTotals.unbilledProductsPcs,
          maxValue: positionsTotals.productsPcs,
          valueOnly: true,
          colorState: 'main-600',
          hide: purchaseOrder.status !== PoTabs.Open || !positionsTotals.unbilledProductsPcs
        },
      ]
    },
    {
      vatStatus: get(purchaseOrder, 'properties.vatStatus'),
      vatCheckType: true,
      hide: ((get(purchaseOrder, 'properties.company.noVat') || !get(purchaseOrder, 'properties.company.euMember'))
        && (get(purchaseOrder, 'properties.company.type') !== PartnersTypeEnum.GENERAL || !get(purchaseOrder, 'properties.generalPartnerVat'))
        || (
          get(purchaseOrder, 'properties.company.type') === PartnersTypeEnum.GENERAL &&
          get(purchaseOrder, 'properties.company.generalType') === GeneralPartnerTypeEnum.NON_EU
        )) || purchaseOrder.status === PoTabs.Completed,
    },
    {
      title: 'COMMON.GOODS_RECEIPT',
      linkedDocumentsType: true,
      linkedDocuments: linkedBatches,
      icon: 'car',
      hide: !linkedBatches.length || purchaseOrder.status !== PoTabs.Completed
    },
    {
      title: 'DOC_SUMMARY.LINKED_INVOICES',
      linkedDocumentsType: true,
      linkedDocuments: linkedInvoice,
      icon: 'euro',
      hide: !linkedInvoice.length || purchaseOrder.status !== PoTabs.Completed
    },
    {
      title: 'DOC_SUMMARY.LINKED_SALES_ORDER',
      linkedDocumentsType: true,
      linkedDocuments: linkedSo,
      hide: !linkedSo.length
    }
  ];
}

export const CannotProceedErrModalData: CommonModalDataModel = {
  title: 'SO_PO.ALL_PRODUCTS_ORDERED_TITLE',
  message: 'SO_PO.ALL_PRODUCTS_ORDERED_MSG',
  hideCancelBtn: true,
  titleIcon: 'alert-triangle',
  confirmBtnText: 'BUTTON.CLOSE',
  confirmBtnIcon: 'close'
};
