import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class PreloaderService {

  get events(): Observable<string> {
    return this._initializedEvents$;
  }

  get stop(): Observable<string> {
    return this._stoppedEvents$;
  }

  private _events: Set<string> = new Set();

  private _initializedEvents$: Subject<string> = new Subject<string>();
  private _stoppedEvents$: Subject<string> = new Subject();

  public initPreloader(event: string = 'all'): void {
    if (this._events.has(event)) {
      return;
    }
    this._events.add(event);
    this._initializedEvents$.next(event);
  }

  public stopPreloader(event: string = 'all'): void {
    if (!this._events.has(event)) {
      return;
    }
    this._events.delete(event);
    this._stoppedEvents$.next(event);
  }
}
