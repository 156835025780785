export enum DocumentPreviewTabsEnum {
  DOCUMENT = 'document',
  SCHEDULE = 'schedule',
  PROPERTIES = 'properties',
  PREFERENCES = 'preferences',
  LOG = 'log',
  PACKING = 'packing',
  REMARK = 'remark',
  DOCUMENTS = 'documents',
  PAYMENTS = 'payments',
  CANCELED_POSITIONS = 'canceled-positions',
  ATTACHMENTS = 'attachments',
}
