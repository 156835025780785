import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

export const ICON_BY_DOCUMENT_TYPE: {[key: string]: string} = {
  [DocumentTypesUppercaseEnum.SO]: 'sales-order',
  [DocumentTypesUppercaseEnum.ECO]: 'sales-order',
  [DocumentTypesUppercaseEnum.SALES_ORDER]: 'sales-order',
  [DocumentTypesUppercaseEnum.OFFER]: 'offer',
  [DocumentTypesUppercaseEnum.OFR]: 'offer',
  [DocumentTypesUppercaseEnum.PO]: 'purchase-order',
  [DocumentTypesUppercaseEnum.PURCHASE_ORDER]: 'purchase-order',
  [DocumentTypesUppercaseEnum.PRICE_PURCHASE]: 'pricetag',
  [DocumentTypesUppercaseEnum.PRICE_SALES]: 'pricetag',
  [DocumentTypesUppercaseEnum.ERA]: 'exchange-return',
  [DocumentTypesUppercaseEnum.SBC]: 'subscriptions',
  [DocumentTypesUppercaseEnum.OUTGOING_INVOICE]: 'outgoing-invoice',
  [DocumentTypesUppercaseEnum.OIN]: 'outgoing-invoice',
  [DocumentTypesUppercaseEnum.IIN]: 'incoming-invoice',
  [DocumentTypesUppercaseEnum.INCOMING_INVOICE]: 'incoming-invoice',
  [DocumentTypesUppercaseEnum.OPB]: 'payment',
  [DocumentTypesUppercaseEnum.OUTGOING_PAYMENT]: 'payment',
  [DocumentTypesUppercaseEnum.IPB]: 'payment',
  [DocumentTypesUppercaseEnum.INCOMING_PAYMENT]: 'payment',
  [DocumentTypesUppercaseEnum.SP]: 'recurring-costs',
  [DocumentTypesUppercaseEnum.CREDIT_NOTE]: 'credit-note',
  [DocumentTypesUppercaseEnum.ICN]: 'credit-note',
  [DocumentTypesUppercaseEnum.OCN]: 'credit-note',
  [DocumentTypesUppercaseEnum.CRN]: 'credit-note',
  [DocumentTypesUppercaseEnum.DN]: 'outgoing-delivery',
  [DocumentTypesUppercaseEnum.DELIVERY_NOTE]: 'outgoing-delivery',
}
