import { Component, Input, Optional } from '@angular/core';

import { AccordionItemDefinitionModel } from './accordion-item-definition.model';
import { AccordionTreeComponent } from './accordion-tree.component';

@Component({
  selector: 'rnpl-accordion-tree-level',
  templateUrl: 'accordion-tree-level.component.html',
  styleUrls: ['accordion-tree.component.scss']
})
export class AccordionTreeLevelComponent {

  @Input()
  public items: Array<AccordionItemDefinitionModel>;

  @Input()
  public isRoot;

  constructor(@Optional() private accordionTree: AccordionTreeComponent) { }

  public toggle(item, event) {
    if (this.accordionTree) {
      this.accordionTree.itemToggled.emit({...item, checked: event.target.checked});
    }
  }
}
