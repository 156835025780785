export enum UserShortcutEnum {
  OFFERS = 'offers',
  SALES_ORDERS = 'sales_orders',
  PURCHASE_ORDERS = 'purchase_orders',
  PRICES = 'prices',
  RETURNS = 'returns',
  TIME_TRACKING = 'time_tracking',
  SUBSCRIPTIONS = 'subscriptions',
  OUTGOING_INVOICES = 'outgoing_invoices',
  INCOMING_INVOICES = 'incoming_invoices',
  PAYMENTS = 'payments',
  CREDIT_NOTES = 'credit_notes',
  OPEN_ITEM_LIST = 'open_item_list',
  REPORTING_PERIODS = 'reporting_periods',
  BANK_ACCOUNT = 'bank_account',
  STOCK_STATUS = 'stock_status',
  INCOMING_DELIVERIES = 'incoming_deliveries',
  OUTGOING_DELIVERIES = 'outgoing_deliveries',
  TRANSACTIONS = 'transactions',
  MANAGEMENT = 'management',
  GOODS = 'goods',
  SERVICES = 'services',
  DIGITAL_PRODUCTS = 'digital_products',
  GENERAL_PRODUCTS = 'general_products',
  CATEGORIES = 'categories',
  PRODUCT_SETTINGS = 'product_settings',
  CORPORATE_PARTNERS = 'corporate_partners',
  PRIVATE_PARTNERS = 'private_partners',
  PARTNER_SETTINGS = 'partner_settings',
  EMPLOYEES = 'employees',
  TRADE_DATA = 'trade_data',
  ACCOUNTING_DATA = 'accounting_data',
  WAREHOUSE_DATA = 'warehouse_data',
  COMPANY_PROFILE = 'company_profile',
  ECOMMERCE_ORDERS = 'ecommerce_orders',
  ECOMMERCE_PRODUCTS = 'ecommerce_products',
  ECOMMERCE_PRICES = 'ecommerce_prices',
  ECOMMERCE_CORPORATE_PARTNERS = 'ecommerce_corporate_partners',
  ECOMMERCE_PRIVATE_PARTNERS = 'ecommerce_private_partners',
  SCHEDULED_PAYMENT = 'scheduled_payment',
  PAYMENT_CALENDAR = 'payment_calendar',
  SCHEDULE_PAYMENTS = 'schedule_payments',
  GL_ACCOUNT = 'gl_account',
  FIXED_ASSETS = 'fixed_assets',
  SCANNED_BARCODES = 'scanned_barcodes',
  CASHFLOW = 'cashflow',
}
