import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { RnplCommonModule } from 'common/src/modules/rnpl-common';
import { UIComponentsModule } from 'common/src/modules';
import { TranslateModule } from '@ngx-translate/core';
import { OverdueNoticeDueDaysFormComponent } from './overdue-notice-due-days-form.component';

@NgModule({
  declarations: [
    OverdueNoticeDueDaysFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    UIComponentsModule,
    RnplCommonModule,
    TranslateModule,
  ],
  exports: [OverdueNoticeDueDaysFormComponent],
  entryComponents: [OverdueNoticeDueDaysFormComponent],
  providers: []
})
export class OverdueNoticeDueDaysFormModule { }
