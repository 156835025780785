import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ApiResponse, PaginationModel, UIStatesEnum } from 'common/src/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { PrivatePartnerModel } from '../models/partner.model';
import { PartnersListTabsEnum } from '../enums/partners-list-tabs.enum';
import { DecrementLoadingRequestsCount, IncrementLoadingRequestsCount, LoadPartner, LoadPartnersList, LoadTitles, UpdatePartnerState, UpdatePartnerUpdatedAt, UpdateShouldRefreshEntity, UpdateValidations } from '../store/actions/partner.actions';
import { AppState } from '../../../store/state/app.state';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { ResponseList, ResponseModel } from '../../../shared/models/response';
import { DisplayToaster } from '../../../shared/decorators/toaster';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';
import { Title } from '../../../administration/models/company-profile.model';
import { environment } from 'projects/workspace/src/environments/environment';
import { PartnersCountersModel } from '../models/partner-counters.model';
import { PartnersTypeEnum } from '../../corporate/enums/partner-types.enum';
import { PartnerVatCheckModel } from '../../corporate/models/vat-check.model';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { DEFAULT_SORT_DIRECTION } from '../../../shared/constants';

@Injectable()
export class PrivatePartnersApiService {
  private readonly apiEndpoint: string = '/partners';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService, // Required for @DisplayToaster decorator.
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>,
  ) {}

  @DisplayToaster({showErrorMessage: true})
  getPartnersList(
    status: PartnersListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModel = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION },
    type: PartnersTypeEnum,
    filters: any = {},
  ): Observable<ResponseList<PrivatePartnerModel>> {
    const params = {
      page: pagination.page,
      per_page: pagination.per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http.get<ResponseList<PrivatePartnerModel>>(`${this.apiUrl()}/${type}`, { params })
      .pipe(tap((data: ResponseList<PrivatePartnerModel>) => {
        this.store.dispatch(LoadPartnersList({
          partnerListData: {
            [data.pagination.page]: {
              pagination: data.pagination, sort,
              data: data.data,
              type
            }
          },
          status,
        }));
      }));
  }

  getPrivatePartnersFilterCountries(status: PartnersListTabsEnum): Observable<string[]> {
    const params = { status };
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/private/countries'), { params })
      .pipe(
        map((data: ResponseModel<string[]>) => data.data)
      );
  }

  getPrivatePartnersFilterCities(status: PartnersListTabsEnum): Observable<string[]> {
    const params = { status };
    return this.http.get<ResponseModel<string[]>>(this.apiUrl('/private/cities'), { params })
      .pipe(
        map((data: ResponseModel<string[]>) => data.data.filter(itm => !!itm))
      );
  }

  getPrivatePartnersRunpleIds(status: PartnersListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl('/private/runpleIds'), { params })
      .pipe(map((data: any) => data.data));
  }

  getPrivatePartnersFilterNames(status: PartnersListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<ResponseModel<any>>(this.apiUrl('/private/names'), { params })
      .pipe(
        map((data: ResponseModel<any>) => data.data.filter(itm => !!itm))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getPartnerById(id: string | number, type: PartnersTypeEnum = PartnersTypeEnum.PRIVATE): Observable<PrivatePartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<PrivatePartnerModel>>(`${this.apiEndpoint}/${type}/${id}`).pipe(
      tap((response: ResponseModel<PrivatePartnerModel>) => {
        this.store.dispatch(LoadPartner({partner: response.data}));
        this.store.dispatch(UpdatePartnerUpdatedAt({ updatedAt: new Date() }));
        this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
      }),
      map((response: ResponseModel<PrivatePartnerModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }


  public getPartnerListExport(title: string, status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  @DisplayToaster({showErrorMessage: true})
  getPartnersCounters(type: PartnersTypeEnum): Observable<PartnersCountersModel> {
    return this.http
      .get<ResponseModel<PartnersCountersModel>>(
        this.apiUrl(`/${type}/counters`)
      )
      .pipe(
        map(
          (data: ResponseModel<PartnersCountersModel>) =>
            data.data
        )
      );
  }

  @DisplayToaster({showErrorMessage: true})
  changePartnerStatusBatch(status: PartnersListTabsEnum, ids: number[], type: PartnersTypeEnum): Observable<any> {
    const body = { status, ids };
    return this.http.patch(this.apiUrl(`/${type}/status`), body);
  }

  @DisplayToaster({showErrorMessage: true})
  changePartnerStatus(status: PartnersListTabsEnum, partnerId: number, type: PartnersTypeEnum): Observable<PrivatePartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status };
    return this.http
      .patch(this.apiUrl(`/${type}/${partnerId}/status`), body)
      .pipe(
        tap((response: ResponseModel<PrivatePartnerModel>) => {
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPartner({ partner: response.data }));
        }),
        map((data: ResponseModel<PrivatePartnerModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updatePartner(partnerId: number, type: PartnersTypeEnum, field: FormInputChangedModel): Observable<PrivatePartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<PrivatePartnerModel>>(this.apiUrl(`/${type}/${partnerId}`), field)
      .pipe(
        tap((response: ResponseModel<PrivatePartnerModel>) => {
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadPartner({ partner: response.data }));
          this.store.dispatch(UpdateValidations({ validations: response.validations }));
        }),
        map((response: ResponseModel<PrivatePartnerModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, partnerId);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  deletePartnerPermanently(partnerId: number, type: PartnersTypeEnum): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${type}/${partnerId}`));
  }

  @DisplayToaster({showErrorMessage: true})
  deletePrivatePartnerPermanently(partnerId: number): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete',`/private-partners/${partnerId}`);
  }

  @DisplayToaster({showErrorMessage: true})
  deletePrivatePartnerPermanentlyBatch(ids: number[]): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete','/private-partners', {body: { ids }});
  }

  @DisplayToaster({showErrorMessage: true})
  public getPartnerExportParams(id: number, title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `accounting/v1/pdf/sales-order/${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  public partnerSetEdit(id: string | number, type: PartnersTypeEnum, force = false): Observable<PrivatePartnerModel> {
    const body = { force };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<PrivatePartnerModel>>(this.apiUrl(`/${type}/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<PrivatePartnerModel>) => {
          this.store.dispatch(LoadPartner({ partner: response.data }));
          this.store.dispatch(UpdatePartnerState({ currentState: UIStatesEnum.EDIT }));
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<PrivatePartnerModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public partnerUnsetEdit(id: string | number, type: PartnersTypeEnum): Observable<PrivatePartnerModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<PrivatePartnerModel>>('put', this.apiUrl(`/${type}/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<PrivatePartnerModel>) => {
          this.store.dispatch(LoadPartner({ partner: response.data }));
          this.store.dispatch(UpdatePartnerState({ currentState: UIStatesEnum.VIEW }));
          this.store.dispatch(UpdatePartnerUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<PrivatePartnerModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getTitles(): Observable<Title[]> {
    return this.http
      .get<Title[]>(`${environment.javaApiVersion}/utils/profile/titles`)
      .pipe(
        tap((response: Title[]) => {
          this.store.dispatch(LoadTitles({ titles: response }));
        }),
        map((response: Title[]) => response)
      );
  }

  public getListExportFile(status: PartnersListTabsEnum, type: PartnersTypeEnum): Observable<HttpResponse<Blob>> {
    const body = {};
    return this.http.get(
      `${this.apiEndpoint}/${type}/${status}/csv`,
      {
        observe: 'response',
        responseType: 'blob'
      }
    );
  }

  validateVatNumber(vat: string, country_code: string): Observable<ApiResponse<PartnerVatCheckModel>> {
    const params = new HttpParams({fromObject: {vat: vat, country_code: country_code}});

    return this.http.get<ApiResponse<PartnerVatCheckModel>>(`/integrations/taxud/company-info/by-vat-number`, {params: params});
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, partnerId?: number | string): void {

    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          )
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.partnerSetEdit(partnerId, PartnersTypeEnum.PRIVATE, true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('FORM.PARTNER');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdatePartnerState({ currentState: UIStatesEnum.VIEW }));
        this.getPartnerById(partnerId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message || error.error.errors);
        break
    }
  }

}
