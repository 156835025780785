import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DeliveryTypesEnum } from '../../../../models';
import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-delivery-type',
  templateUrl: './filters-control-delivery-type.component.html',
})
export class FiltersControlDeliveryTypeComponent extends FiltersControlBaseComponent {

  public deliveryTypesList = [
    {value: DeliveryTypesEnum.PICK_UP, label: 'FORM.PICK_UP', searchLabel: `${this.translateService.instant('FORM.PICK_UP')} ${DeliveryTypesEnum.PICK_UP}`},
    {value: DeliveryTypesEnum.STANDARD_DELIVERY, label: 'FORM.STANDARD_DELIVERY', searchLabel: `${this.translateService.instant('FORM.STANDARD_DELIVERY')} ${DeliveryTypesEnum.STANDARD_DELIVERY}`},
    {value: DeliveryTypesEnum.EXPRESS_DELIVERY, label: 'FORM.EXPRESS_DELIVERY', searchLabel: `${this.translateService.instant('FORM.EXPRESS_DELIVERY')} ${DeliveryTypesEnum.EXPRESS_DELIVERY}`},
  ];

  constructor(public translateService: TranslateService) {
    super(translateService);
  }

}
