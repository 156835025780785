import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { get } from 'lodash';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ReportingPeriodEnum } from '../../initial-setup.enum';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { ChannelOptions, CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
// import { CategoryModel } from 'projects/workspace/src/app/shared/models/category-model';
// import { BranchModel } from 'projects/workspace/src/app/administration/models/branch.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { SubscriptionPlanEnum } from '../../../subscription-activation/subscription-activation.emum';

@Component({
  selector: 'rnpl-business-specifics',
  templateUrl: './business-specifics.component.html',
  styleUrls: ['./business-specifics.component.scss']
})
export class BusinessSpecificsComponent extends InitialSetupBaseComponent implements OnInit {

  public form: FormGroup = this.fb.group({});
  public userChannelOptions: ChannelOptions;

  public reportingPeriodEnum: typeof ReportingPeriodEnum = ReportingPeriodEnum;
  // public branchesList: BranchModel[] = [];

  // readonly branchItems$: BehaviorSubject<CategoryModel[]> = new BehaviorSubject<CategoryModel[]>(null);

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public administrationsApiService: AdministrationsApiService,
    public readonly store: Store<AppState>,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.initForm();
    // this.getAvailableBranches();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.initForm(companyProfile);
        this.userChannelOptions = get(companyProfile, 'subscriptionManagement.userChannelOptions');
        // this.branchesList = companyProfile.branches.map(el => ({...el}));
        if (this.userChannelOptions && this.userChannelOptions.servicesEnabled) {
          this.servicesControl.disable();
        }
        if (this.userChannelOptions && (this.userChannelOptions.wholesalesEnabled || this.userChannelOptions.ecommerceEnabled)) {
          this.goodsControl.disable();
        }
      });
  }

  public initForm(companyProfile: CompanyProfile = {} as any): void {
    const goodsEnabled = get(companyProfile, 'subscriptionManagement.userChannelOptions.wholesalesEnabled') ||
      get(companyProfile, 'subscriptionManagement.userChannelOptions.ecommerceEnabled')
        ? true
        : get(companyProfile, 'userProductOptions.goodsEnabled', false);
    const servicesEnabled = get(companyProfile, 'subscriptionManagement.userChannelOptions.servicesEnabled')
      ? true
      : get(companyProfile, 'userProductOptions.servicesEnabled', false);

    this.form = this.fb.group({
      // accountingOptions: this.fb.group({
      //   reportingPeriodOption:  [get(companyProfile, 'accountingOptions.reportingPeriodOption', null), {validators: []}],
      // }),
      partnerOptions: this.fb.group({
        corporateEnabled:  [get(companyProfile, 'partnerOptions.corporateEnabled', true), {validators: []}],
        privateEnabled:  [get(companyProfile, 'partnerOptions.privateEnabled', false), {validators: []}],
      }),
      userProductOptions: this.fb.group({
        goodsEnabled:  [goodsEnabled, {validators: []}],
        digitalEnabled:  [get(companyProfile, 'userProductOptions.digitalEnabled', false), {validators: []}],
        servicesEnabled:  [servicesEnabled, {validators: []}],
      }),
    });
  }

  // private getAvailableBranches(): void {
  //   this.administrationsApiService.getAvailableBranches()
  //     .subscribe(response => {
  //       this.branchItems$.next(response as any);
  //     });
  // }

  // public addBranch(): void {
  //   this.administrationsApiService.addBranch().subscribe((companyProfile: CompanyProfile) => {
  //     this.companyProfile = companyProfile;
  //     this.branchesList = companyProfile.branches.map(el => ({...el}));
  //   });
  // }
  //
  // public editBranch({branchId}, currentBranchId: number): void {
  //   this.administrationsApiService.updateBranch(currentBranchId, branchId).subscribe((companyProfile: CompanyProfile) => {
  //      this.companyProfile = companyProfile;
  //     this.branchesList = companyProfile.branches.map(el => ({...el}));
  //   });
  // }
  //
  // public removeBranch(branchId: number): void {
  //   this.administrationsApiService.deleteBranch(branchId).subscribe((companyProfile: CompanyProfile) => {
  //      this.companyProfile = companyProfile;
  //     this.branchesList = companyProfile.branches.map(el => ({...el}));
  //   });
  // }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company = {
      ...this.companyProfile,
      ...formValue
    };
    return this.updateCompanyInfo$(company);
  }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    return (this.goodsControl.value || this.digitalProductsControl.value || this.servicesControl.value) &&
      (this.corporatePartnersControl.value || this.privatePartnersControl.value);
      //&&  this.reportingPeriodControl.value &&
      // get(this.companyProfile, 'branches.length');
  }

  get isFreePlan(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.FREE;
  }

  get isAccountingPlan(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.ACCOUNTING_MONTHLY ||
      get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY;
  }

  get goodsControl() { return this.form.get('userProductOptions').get('goodsEnabled') as FormControl; }
  get digitalProductsControl() { return this.form.get('userProductOptions').get('digitalEnabled') as FormControl; }
  get servicesControl() { return this.form.get('userProductOptions').get('servicesEnabled') as FormControl; }

  get corporatePartnersControl() { return this.form.get('partnerOptions').get('corporateEnabled') as FormControl; }
  get privatePartnersControl() { return this.form.get('partnerOptions').get('privateEnabled') as FormControl; }

  // get reportingPeriodControl(): FormControl { return this.form.get('accountingOptions').get('reportingPeriodOption') as FormControl; }
  //
  // get branches() { return get(this.companyProfile, 'branches', []); }

}
