import { TranslateService } from '@ngx-translate/core';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { PoTabs } from '../../../enums';
import { VAT_LIST } from '../../../../shared/constants';
import {
  ControlTypeEnum,
  InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from 'common/src/modules/position-card/position-card-models.model';
import { CORRECTION_POSITION_TYPES_LIST } from '../../../../accounting/accounting.constants';

export const PositionsButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.REMOVE',
    multipleText: 'BUTTON.REMOVE',
    badgeClass: 'rnpl-badge--grey',
    iconName: 'minus-circle',
    actionName: 'onDeletePositionsClick',
    multiple: false
  }
];

export function generatePositionsCardsInfo(
  translateService: TranslateService,
  productType: ProductTypes,
  state: UIStatesEnum,
  documentStatus: PoTabs,
  vatBlocked: boolean,
): PositionInfoListModel[] {
  const cantEditColumn = (state === UIStatesEnum.VIEW);

  let positionInfoList: PositionInfoListModel[] = [
    {
      label: 'FORM.DESCRIPTION',
      prop: 'positionName',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.ARBITRARY, ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.ARBITRARY, ProductTypes.SERVICES, ProductTypes.CORRECTION],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.SPECIFY_AMOUNTS_SEPARATELY',
      prop: 'specifyAmountsSeparately',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TOGGLE,
      itemClass: 'col-12',
      isOptional: true,
      hideWithProductType: [ProductTypes.ARBITRARY, ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.TYPE',
      prop: cantEditColumn ? 'correctionTypeLabel' : 'correctionType',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: CORRECTION_POSITION_TYPES_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      hideWithProductType: [ProductTypes.ARBITRARY, ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL],
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      isOptional: true,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      hideWithProductType: [ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.CORRECTION],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'FORM.DESCRIPTION',
      prop: 'description',
      isOptional: true,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.TEXTAREA,
      itemClass: 'col-12',
      hideWithProductType: [ProductTypes.SERVICES, ProductTypes.GOODS, ProductTypes.DIGITAL, ProductTypes.CORRECTION],
      showWithUIState: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'quantity',
      unitType: 'unitType',
      type: cantEditColumn ? PositionItemTypeEnum.NUMBER : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.NUMBER,
      hideWithProductType: [ProductTypes.CORRECTION]
    },
    {
      label: 'COLUMN.UNIT_PRICE_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      hideWithProductType: [ProductTypes.CORRECTION],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      hideWithProductType: [ProductTypes.CORRECTION],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_NET',
      prop: 'netUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      currencyNegativeRow: true,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      showUnitOnly: true,
      hideWithProductType: [ProductTypes.ARBITRARY, ProductTypes.SERVICES, ProductTypes.GOODS, ProductTypes.DIGITAL],
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vat',
      suffix: '%',
      type: cantEditColumn ? PositionItemTypeEnum.PRIMARY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.DROPDOWN,
      listItems: VAT_LIST,
      bindLabel: 'label',
      bindValue: 'value',
      disabled: vatBlocked,
      vatBlocked: vatBlocked,
      hideWithProductType: [ProductTypes.CORRECTION]
    },
    {
      label: 'COLUMN.VAT',
      prop: 'vatTotal',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      disabled: vatBlocked,
      vatBlocked: vatBlocked,
      showUnitOnly: true,
      hideWithProductType: [ProductTypes.ARBITRARY, ProductTypes.SERVICES, ProductTypes.GOODS, ProductTypes.DIGITAL],
    },
    {
      label: 'COLUMN.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      hideWithProductType: [ProductTypes.CORRECTION],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      valueClass: 'font-weight-500',
      hideWithProductType: [ProductTypes.CORRECTION],
      showUnitOnly: true,
    },
    {
      label: 'COLUMN.AMOUNT_GROSS',
      prop: 'grossUnitPrice',
      suffix: '€',
      type: cantEditColumn ? PositionItemTypeEnum.CURRENCY : PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
      currencyNegativeRow: true,
      hideWithProductType: [ProductTypes.SERVICES, ProductTypes.GOODS, ProductTypes.DIGITAL],
    },
    {
      label: '',
      prop: '',
      type: PositionItemTypeEnum.WITH_CONTROL,
      colorState: 'color-green-600',
      controlType: ControlTypeEnum.DISCOUNT,
      hideWithProductType: [ProductTypes.CORRECTION]
    },
    {
      label: 'COMMON.INTERNAL_REMARK_1',
      prop: 'remark',
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.REMARK,
      bindValue: 'value',
    },
    {
      label: 'COLUMN.OPEN',
      suffix: '€',
      type: PositionItemTypeEnum.STATUS_LINE,
      colorState: 'main-600',
      prop: 'openData',
      showWithDocumentStatus: [PoTabs.Open],
      showWithUIState: [UIStatesEnum.VIEW],
      hideWithProductType: [ProductTypes.DIGITAL, ProductTypes.SERVICES, ProductTypes.CORRECTION],
    }
  ];

  positionInfoList = positionInfoList
    .filter((item) => {
      if (item.hideWithProductType) {
        return !item.hideWithProductType.includes(productType);
      }
      return true;
    }).filter((item) => {
      if (item.showWithUIState) {
        return item.showWithUIState.includes(state);
      }
      return true;
    }).filter((item) => {
      if (item.showWithDocumentStatus) {
        return item.showWithDocumentStatus.includes(documentStatus);
      }
      return true;
    });

  return positionInfoList;

}
