import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PurchaseOrder } from '../../models';
import { BatchModel } from '../../models/purchase-order/batch.model';

@Component({
  selector: 'rnpl-batches-sidebar-content',
  templateUrl: './batches-sidebar-content.component.html',
  styleUrls: ['./batches-sidebar-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchesSidebarContentComponent {
  @Input() order: any;
  @Input() hiddenCross?: boolean = false;
  @Input() batch: BatchModel;
}
