import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AuthUserPermissionsEnum } from '../auth/auth-user-permissions.enum';

@Injectable({ providedIn: 'root' })
export class UserPermissionsService {

  constructor(private authService: AuthService) {
  }

  public canViewAccounting(): boolean {
    return this.canViewModule('accounting');
  }

  public canViewWholesale(): boolean {
    return this.canViewModule('wholesale');
  }

  public canEditWholesale(): boolean {
    return this.canEditModule('wholesale');
  }

  public canManageWholesale(): boolean {
    return this.canManageModule('wholesale');
  }

  public canManageServices(): boolean {
    return this.canManageModule('services');
  }

  public canViewAdministration(): boolean {
    return this.canViewModule('administration');
  }

  public canEditAdministration(): boolean {
    return this.canEditModule('administration');
  }

  public canViewAnalytics(): boolean {
    return this.canViewModule('analytics');
  }

  public canViewEcommerce(): boolean {
    return this.canViewModule('ecommerce');
  }

  public canManageEcommerce(): boolean {
    return this.canManageModule('ecommerce');
  }

  public canViewPartners(): boolean {
    return this.canViewModule('partners');
  }

  public canViewProducts(): boolean {
    return this.canViewModule('products');
  }

  public canEditProducts(): boolean {
    return this.canEditModule('products');
  }

  public canViewRetail(): boolean {
    return this.canViewModule('retail');
  }

  public canViewServices(): boolean {
    return this.canViewModule('services');
  }

  public canViewTeam(): boolean {
    return this.canViewModule('team');
  }

  public canViewWarehouse(): boolean {
    return this.canViewModule('warehouse');
  }

  public canEditWarehouse(): boolean {
    return this.canEditModule('warehouse');
  }

  public canManageWarehouse(): boolean {
    return this.canManageModule('warehouse');
  }

  public canManageProducts(): boolean {
    return this.canManageModule('products');
  }

  public canManagePartners(): boolean {
    return this.canManageModule('partners');
  }

  public canEditAccounting(): boolean {
    return this.canEditModule('accounting');
  }

  public canManageAccounting(): boolean {
    return this.canManageModule('accounting');
  }

  public canViewModule(moduleName: string): boolean {
    const loggedUser = this.authService.getUser();
    if (!loggedUser) { return false; }

    return loggedUser.permissions[moduleName] &&
      (
        loggedUser.permissions[moduleName].includes(AuthUserPermissionsEnum.ADMIN) ||
        loggedUser.permissions[moduleName].includes(AuthUserPermissionsEnum.WRITE) ||
        loggedUser.permissions[moduleName].includes(AuthUserPermissionsEnum.READ)
      );
  }

  public canEditModule(moduleName: string): boolean {
    const loggedUser = this.authService.getUser();
    if (!loggedUser) { return false; }

    return loggedUser.permissions[moduleName] &&
      (
        loggedUser.permissions[moduleName].includes(AuthUserPermissionsEnum.ADMIN) ||
        loggedUser.permissions[moduleName].includes(AuthUserPermissionsEnum.WRITE)
      );
  }

  public canManageModule(moduleName: string): boolean {
    const loggedUser = this.authService.getUser();
    if (!loggedUser) { return false; }

    return loggedUser.permissions[moduleName] && loggedUser.permissions[moduleName].includes(AuthUserPermissionsEnum.ADMIN);
  }

}
