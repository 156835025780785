import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TradeProductInfoModel } from './trade-product-info.model';
import { ResponseModel } from '../shared/models/response';

@Injectable()
export class TradeApiService {
  private readonly apiEndpoint: string = '/trade';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(private readonly http: HttpClient) {}

  getTradeProductInfo(productId: number): Observable<TradeProductInfoModel> {
    return this.http.get<ResponseModel<TradeProductInfoModel>>(this.apiUrl(`/${productId}/info`))
      .pipe(
        map((data: ResponseModel<TradeProductInfoModel>) => data.data)
      );
  }

}
