import { EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';


import { AttributeForm } from '../system-settings/attribute-wizard/attribute-form.factory';


export class WizardStep {

  @Input()
  public form: FormGroup;

  @Input()
  public wizardForm: AttributeForm | any;

  @Input()
  public navigate: (step: string) => void;

  @Input()
  public prevStep: string;

  @Input()
  public nextStep: string;

  @Input()
  public displayHints: boolean = true;

  @Input()
  public progress: any;

  @Output()
  public completed: EventEmitter<any> = new EventEmitter();

  @Output()
  public closed: EventEmitter<any> = new EventEmitter();

  public complete(): void {
    this.completed.emit(this.form);
    if (this.nextStep) {
      this.navigate(this.nextStep);
    }
  }

  public close(): void {
    this.closed.emit(this.form);
  }
}
