import { createReducer, on } from '@ngrx/store';
import * as CollectionAppActions from '../actions/router.actions';

export interface RootLinkState {
  link: string;
  status: string;
}

export const initialLinksQueueState: RootLinkState[]  = [];

export function LinkReducer(state, action) {
  return _LinkReducer(state, action);
}

const _LinkReducer = createReducer(
  initialLinksQueueState,
  on(CollectionAppActions.SaveLink, (state, { linkState }) => ([
    ...state.concat(linkState)
  ])),
  on(CollectionAppActions.RemoveLastLink, (state) => ([
    ...state.slice(0, state.length - 1)
  ])),
  on(CollectionAppActions.ClearLinkQueue, (state) => ([])),
);