import { createReducer, on } from '@ngrx/store';

import {
  CollectionWarehouseActions,
  CollectionStockStatusActions,
  CollectionReturnStockStatusActions, CollectionBinLocationsActions
} from '../actions/action-types';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { FilterModel } from '../../models/filter.model';
import { StockStatusModel, StockStatusTotalsModel } from '../../models/stock-address.model';
import { ReturnStockStatusModel, ReturnStockStatusTotalsModel } from '../../models';
import { BinLocationsTabsEnum } from '../../enums';

export const WarehouseFeatureKey = 'warehouseStore';

export interface BinLocationsListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: any[];
  }
}

export interface StockStatusListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: StockStatusModel[];
    totals: StockStatusTotalsModel;
  }
}

export interface ReturnStockStatusListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: ReturnStockStatusModel[];
    totals: ReturnStockStatusTotalsModel;
  }
}

export interface WarehousesStore {
  list: {
    stockStatus: StockStatusListState,
    returnStockStatus: ReturnStockStatusListState,
  };
  binLocationsList: {
    binLocations: {
      [BinLocationsTabsEnum.GENERAL_STOCK]: BinLocationsListState,
      [BinLocationsTabsEnum.RETURNS_STOCK]: BinLocationsListState,
      [BinLocationsTabsEnum.DELETED]: BinLocationsListState,
    },
  }
  entity: WarehouseState;
}

export interface WarehouseState {
  warehouses: WarehouseResponse[];
  currentWarehouse: WarehouseResponse;
  currentState: UIStatesEnum;
  warehouseForm: any;
  loadingRequestsCount: number;
  updatedAt: Date;
}

export const initialWarehouseState: WarehouseState = {
  warehouses: null,
  currentWarehouse: null,
  currentState: UIStatesEnum.VIEW,
  warehouseForm: null,
  loadingRequestsCount: 0,
  updatedAt: null
};

export const initialStockStatusState: any = { // TODO - add type
  stockStatus: {},
  returnStockStatus: {}
};

export const initialBinLocationsState: any = { // TODO - add type
  binLocations: {
    [BinLocationsTabsEnum.GENERAL_STOCK]: [],
    [BinLocationsTabsEnum.RETURNS_STOCK]: [],
    [BinLocationsTabsEnum.DELETED]: [],
  },
};

export function StockStatusListReducer(state, action) {
  return _StockStatusListReducer(state, action);
}

const _StockStatusListReducer = createReducer(
  initialStockStatusState,
  on(CollectionStockStatusActions.LoadStockStatusList, (state, { stockStatusListData }) => ({
    ...state,
    stockStatus: {
      ...state.stockStatus,
      ...stockStatusListData
    }
  })),
  on(CollectionReturnStockStatusActions.LoadReturnStockStatusList, (state, { returnStockStatusListData }) => ({
    ...state,
    returnStockStatus: {
      ...state.returnStockStatus,
      ...returnStockStatusListData
    }
  })),
);


export function BinLocationsListReducer(state, action) {
  return _BinLocationsListReducer(state, action);
}

const _BinLocationsListReducer = createReducer(
  initialBinLocationsState,
  on(CollectionBinLocationsActions.LoadBinLocationsList, (state, { binLocationsListData, location }) => ({
    ...state,
    binLocations: {
      ...state.binLocations,
      [location]: binLocationsListData
    }
  })),
);


export function WarehouseReducer(state, action) {
  return _WarehouseReducer(state, action);
}

const _WarehouseReducer = createReducer(
  initialWarehouseState,
  // Current Warehouse
  on(CollectionWarehouseActions.UpdateCurrentWarehouse, (state, { currentWarehouse }) => ({
    ...state,
    currentWarehouse
  })),
  on(CollectionWarehouseActions.UpdateWarehouseCurrentState, (state, { currentState }) => ({
    ...state,
    currentState
  })),
  // Warehouse form
  on(CollectionWarehouseActions.ClearWarehouseFormStock, (state) => ({
    ...state,
    warehouseForm: null
  })),
  on(CollectionWarehouseActions.UpdateWarehouseFormStock, (state, { warehouseForm }) => ({
    ...state,
    warehouseForm
  })),
  // Warehouses list
  on(CollectionWarehouseActions.ClearWarehousesList, (state) => ({
    ...state,
    warehouses: null
  })),
  on(CollectionWarehouseActions.UpdateWarehousesList, (state, { warehouses }) => ({
    ...state,
    warehouses
  })),
  on(CollectionWarehouseActions.UpdateWarehouseUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionWarehouseActions.ClearWarehouseUpdatedAt, (state) => ({
    ...state,
    updatedAt: null
  })),
  on(CollectionWarehouseActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionWarehouseActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
);
