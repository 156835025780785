import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { AppState } from '../../store/state/app.state';
import { environment } from 'projects/workspace/src/environments/environment';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { EcoCategoryModel, EcoProductModel } from '../modules/e-commerce-products/models';
import { FilterModel } from '../../warehouse/models/filter.model';
import { EcoOrderListTabsEnum } from '../modules/eco-orders/enums';
import { EcoOrderModel, EcoOrdersListCountersModel, ResponseEcoOrdersCountersModel } from '../modules/eco-orders/models';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadEcoOrder,
  LoadEcoOrderPositions,
  LoadEcoOrdersList,
  UpdateEcoOrder,
  UpdateEcoOrderCurrentState,
  UpdateEcoOrderPositionsCount,
  UpdateEcoOrderUpdatedAt
} from '../modules/eco-orders/store/actions/eco-order.actions';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { SalesPosition, SalesPositionsByProductType } from '../modules/eco-orders/models/sales-order-position.model';
import { LoadEcommerceProductsList } from '../modules/e-commerce-products/store/actions/products.actions';
import { UIStatesEnum } from 'common/src/models';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ECommerceApiService {
  private readonly apiEndpoint: string = environment.javaEcommerceApiVersion;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    // private readonly dialog: MatDialog,
    private readonly store: Store<AppState>
  ) { }

  public getEcommerceCategoriesTree(params?: HttpParams): Observable<EcoCategoryModel[]> {
    return this.http.get<ResponseModel<EcoCategoryModel[]>>(this.apiUrl('/admin/category/tree'), {params})
      .pipe(
        // tap((response: ResponseModel<FamilyModel[]>) => {
        //   this.store.dispatch(LoadCategoriesList({
        //     productType: params['products_type'],
        //     categories: response.data
        //   }));
        // })
        map((response: ResponseModel<EcoCategoryModel[]>) => response.data)
      );
  }

  public getEcommerceProductsByCategory(
    categoryId: number,
    filter: FilterModel = {nameColumn: 'status', value: 'active'},
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'runpleId', value: DEFAULT_SORT_DIRECTION},
    productType
    ): Observable<ResponseList<EcoProductModel>> {
    return this.http.get<ResponseList<EcoProductModel>>(
      this.apiUrl(`/admin/products/family/${categoryId}`),
      {
        params: {
          page,
          per_page,
          [`filters[${filter.nameColumn}]`]: filter.value,
          [`sort[${sort.nameColumn}]`]: sort.value
        }
      })
      .pipe(
        tap((response: ResponseList<EcoProductModel>) => {
          this.store.dispatch(LoadEcommerceProductsList({
            productType,
            entityKey: categoryId,
            page: +page,
            productsListData: response
          }));
        })
      );
  }

  public getBulkEcoProductsList(
    productType: ProductTypes,
    filter: FilterModel = {nameColumn: 'status', value: 'active'},
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'runpleId', value: DEFAULT_SORT_DIRECTION},
  ): Observable<ResponseList<EcoProductModel>> {
    return this.http.get<ResponseList<EcoProductModel>>(
      `/products/${productType}/list`,
      {
        params: {
          page,
          per_page,
          [`filters[${filter.nameColumn}]`]: filter.value,
          [`sort[${sort.nameColumn}]`]: sort.value
        }
      }
    ).pipe(
      tap((products: ResponseList<EcoProductModel>) => {
        this.store.dispatch(LoadEcommerceProductsList({
          productType,
          entityKey: 'bulk',
          page: +page,
          productsListData: products
        }));
      })
    );
  }

  public getActiveEcommerceProducts(): Observable<EcoProductModel[]> {
    return this.http.get(this.apiUrl('/admin/products')).pipe(map((response: ResponseModel<EcoProductModel[]>) => response.data));
  }

  public setEcommerceProductsToShop(ids: number[]): Observable<any> {
    const body = { ids };
    return this.http.post(this.apiUrl('/admin/products/shop'), body);
  }

  public removeEcommerceProductsFromShop(ids: number[]): Observable<any> {
    const body = { ids };
    return this.http.request('delete', this.apiUrl('/admin/products/shop'), { body } );
  }

  public setEcommerceProductPrices(productId: number, priceData: any): Observable<any> {
    const body = { ...priceData };
    return this.http.request('patch', this.apiUrl(`/admin/products/price-list/${productId}`), { body } );
  }

  getECOList(
    status: EcoOrderListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = { nameColumn: 'updated_at', value: DEFAULT_SORT_DIRECTION },
    filters: any = {}
  ): Observable<ResponseList<any>> {
    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<any>>(this.apiUrl('/admin/orders'), { params })
      .pipe(tap((data: ResponseList<any>) => {
        this.store.dispatch(LoadEcoOrdersList({
          ecoOrderListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getEcoOrdersRunpleIds(status: EcoOrderListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(`/sales-orders/ecommerce/runpleIds`, { params })
      .pipe(map((data: any) => data.data));
  }

  getEcoOrderListPositions(ecoId: number): Observable<SalesPosition[]> {
    return this.http.get<ResponseModel<SalesPosition[]>>(this.apiUrl(`/admin/orders/${ecoId}/list/positions`))
      .pipe(map((data: ResponseModel<SalesPosition[]>) => data.data));
  }

  getEcoOrdersCounters(): Observable<EcoOrdersListCountersModel> {
    return this.http
      .get<ResponseModel<ResponseEcoOrdersCountersModel>>(
        this.apiUrl('/admin/orders/counters')
      )
      .pipe(
        map((data: ResponseModel<ResponseEcoOrdersCountersModel>) => data.data.orders)
      );
  }

  changeEcoOrderStatusBatch(status: EcoOrderListTabsEnum, order_ids: number[]): Observable<any> {
    const body = { status, order_ids };
    return this.http.patch(this.apiUrl('/admin/orders/multiple/statuses'), body)
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  deleteEcoOrderPermanently(ecoOrderId: number): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/admin/orders/${ecoOrderId}`))
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  deleteEcoOrdersPermanently(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl('/admin/orders'), {body})
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  getECOById(id: string | number): Observable<EcoOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http
      .get<ResponseModel<EcoOrderModel>>(this.apiUrl(`/admin/orders/${id}`))
      .pipe(
        tap((response: ResponseModel<EcoOrderModel>) => {
          this.store.dispatch(LoadEcoOrder({ ecoOrder: response.data }));
          // this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
          // this.store.dispatch(UpdateSalesOrderPositionsCount({ positionsCount: response.data.positionsCount }));
          this.store.dispatch(UpdateEcoOrderUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<EcoOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updateEcoOrder(ecoOrderId: number, field: FormInputChangedModel): Observable<EcoOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<EcoOrderModel>>(this.apiUrl(`/admin/orders/${ecoOrderId}`), field)
      .pipe(
        tap((response: ResponseModel<EcoOrderModel>) => {
          this.store.dispatch(UpdateEcoOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadEcoOrder({ ecoOrder: response.data }));
        }),
        map((response: ResponseModel<EcoOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        // catchError(error => {
        //   this.handlePopupErrors(error, salesOrderId);
        //   return throwError(error);
        // })
      );
  }

  public ecoOrderSetEdit(id: string | number, force = false): Observable<EcoOrderModel> {
    const body = { force };

    return this.http.put<ResponseModel<EcoOrderModel>>(this.apiUrl(`/admin/orders/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<EcoOrderModel>) => {
          this.store.dispatch(UpdateEcoOrderCurrentState({ currentState: UIStatesEnum.EDIT }));
          this.store.dispatch(UpdateEcoOrder({ ecoOrder: response.data }));
        }),
        map((response: ResponseModel<EcoOrderModel>) => response.data),
        // catchError(error => {
        //   this.handlePopupErrors(error, +id);
        //   return throwError(error);
        // })
      );
  }

  public ecoOrderUnsetEdit(id: string | number): Observable<EcoOrderModel> {
    return this.http.request<ResponseModel<EcoOrderModel>>('put', this.apiUrl(`/admin/orders/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<EcoOrderModel>) => {
          this.store.dispatch(UpdateEcoOrderCurrentState({ currentState: UIStatesEnum.VIEW }));
          this.store.dispatch(UpdateEcoOrder({ ecoOrder: response.data }));
        }),
        map((response: ResponseModel<EcoOrderModel>) => response.data),
        // catchError(error => {
        //   this.handlePopupErrors(error, +id);
        //   return throwError(error);
        // })
      );
  }

  changeEcoOrderStatus(status: EcoOrderListTabsEnum, ecoOrderId: number): Observable<EcoOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status };
    return this.http
      .patch(this.apiUrl(`/admin/orders/${ecoOrderId}/status`), body)
      .pipe(
        tap((response: ResponseModel<EcoOrderModel>) => {
          this.store.dispatch(UpdateEcoOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadEcoOrder({ ecoOrder: response.data }));
        }),
        map((data: ResponseModel<EcoOrderModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  getEcoOrderPositions(ecoOrderId: number): Observable<ResponseModel<SalesPositionsByProductType>> {
    return this.http.get<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/admin/orders/${ecoOrderId}/positions`))
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadEcoOrderPositions({positions: response}));
        }),
      );
  }

  createEcoOrderPosition(toId: number, position: SalesPosition): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/admin/orders/${toId}/positions`), position)
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(UpdateEcoOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadEcoOrderPositions({positions: response}));
          this.store.dispatch(UpdateEcoOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        // catchError(error => {
        //   this.handlePopupErrors(error, toId);
        //   return throwError(error);
        // })
      );
  }

  updateEcoOrderPosition(ecoOrderId: number, positionId: number, field: FormInputChangedModel, toId: number): Observable<ResponseModel<SalesPosition>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<SalesPosition>>(this.apiUrl(`/admin/orders/${ecoOrderId}/positions/${positionId}`), field)
      .pipe(
        tap((response: ResponseModel<SalesPosition>) => {
          this.store.dispatch(UpdateEcoOrderUpdatedAt({updatedAt: new Date()}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        // catchError(error => {
        //   this.handlePopupErrors(error, toId);
        //   return throwError(error);
        // })
      );
  }

  deleteEcoOrderPositions(salesOrderId: number, ids: number[]): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ids: ids };
    return this.http.request<ResponseModel<SalesPositionsByProductType>>(
      'delete',
      this.apiUrl(`/admin/orders/${salesOrderId}/positions`),
      {body}
    )
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadEcoOrderPositions({positions: response}));
          this.store.dispatch(UpdateEcoOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateEcoOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        // catchError(error => {
        //   this.handlePopupErrors(error, +salesOrderId);
        //   return throwError(error);
        // })
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, crnId?: string | number): void {
    error.error.errors.forEach(errorText => {
      switch (errorText) {
        // case 'anotherUserEditError':
        // {
        //   const dialog = this.dialog.open(WarningModalComponent, {
        //     data: getAnotherUserEditErrorModalData({
        //       document: error.error.data.entityName,
        //       userName: error.error.data.userName,
        //     })
        //   });
        //
        //   dialog.afterClosed().subscribe(res => {
        //     if (res === CommonModalsActionsEnum.CONFIRM) {
        //       this.startCreditNoteEditing(+crnId, true).subscribe();
        //       this.getCreditNoteById(+crnId).subscribe();
        //     }
        //   });
        // }
        //   break;
        default:
          this.showMsg('error', errorText);
          break
      }
    });
  }

}
