import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import {
  DocumentNumberDateFormatEnum,
  DocumentNumberModeEnum,
  DocumentNumberModel,
  DocumentNumberPrefixSeparatorEnum,
  DocumentNumberPrefixTypeEnum,
  DocumentNumberResetEnum
} from '../../models';
import { AccountingSettingsService } from '../../../accounting/accounting-settings-module/services/accounting-settings.service';
import { TradeSettingsService } from '../../../trade/trade-settings.service';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';

export interface ErrorMessage {
  documentType: DocumentTypesUppercaseEnum;
  itemId: number;
  level: 'ERROR';
  message: string;
  modal: boolean;
  name: string;
  priority: number;
}

@Component({
  selector: 'rnpl-document-number-settings',
  templateUrl: './document-number-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentNumberSettingsComponent implements OnChanges, OnDestroy {

  @Input() documentType: DocumentTypesUppercaseEnum;
  @Input() documentNumberSettings: DocumentNumberModel;
  @Input() serverErrors: ErrorMessage[];
  @Input() allowedContinueSequence: boolean = false;

  public form: FormGroup;
  public numberPreview: string;
  public showIsLengthUp: boolean = false;
  public documentNumberModeEnum = DocumentNumberModeEnum;
  public documentNumberPrefixTypeEnum = DocumentNumberPrefixTypeEnum;

  public title: {[key in DocumentTypesUppercaseEnum]?: string} = {
    [DocumentTypesUppercaseEnum.OIN]: 'APP.OUTGOING_INVOICES',
    [DocumentTypesUppercaseEnum.OCN]: 'APP.OUTGOING_CREDIT_NOTES',
    [DocumentTypesUppercaseEnum.SBC]: 'APP.SUBSCRIPTIONS',
    [DocumentTypesUppercaseEnum.OFR]: 'APP.OFFERS',
    [DocumentTypesUppercaseEnum.SO]:  'APP.SALES_ORDERS',
    [DocumentTypesUppercaseEnum.RMA]: 'APP.ERA',
    [DocumentTypesUppercaseEnum.DN]:  'COMMON.DELIVERY_NOTES',
  };

  public previewTitle: {[key in DocumentTypesUppercaseEnum]?: string} = {
    [DocumentTypesUppercaseEnum.OIN]: 'DOCUMENTS_NUMBERS.OIN_NUMBER',
    [DocumentTypesUppercaseEnum.OCN]: 'DOCUMENTS_NUMBERS.OCN_NUMBER',
    [DocumentTypesUppercaseEnum.SBC]: 'DOCUMENTS_NUMBERS.SBC_NUMBER',
    [DocumentTypesUppercaseEnum.OFR]: 'DOCUMENTS_NUMBERS.OFR_NUMBER',
    [DocumentTypesUppercaseEnum.SO]:  'DOCUMENTS_NUMBERS.SO_NUMBER',
    [DocumentTypesUppercaseEnum.RMA]: 'DOCUMENTS_NUMBERS.RMA_NUMBER',
    [DocumentTypesUppercaseEnum.DN]:  'DOCUMENTS_NUMBERS.DN_NUMBER',
  };

  public prefixTypeList: { value: DocumentNumberModeEnum, label: string }[] = [
    {value: DocumentNumberModeEnum.SINGLE, label: 'DOCUMENTS_NUMBERS.SINGLE'},
    {value: DocumentNumberModeEnum.DOUBLE, label: 'DOCUMENTS_NUMBERS.DOUBLE'},
    {value: DocumentNumberModeEnum.NONE, label:   'DOCUMENTS_NUMBERS.NONE'},
  ];

  public prefixList: { value: DocumentNumberPrefixTypeEnum, label: string }[] = [
    {value: DocumentNumberPrefixTypeEnum.DATE, label: 'FORM.DATE'},
    {value: DocumentNumberPrefixTypeEnum.YEAR, label: 'FORM.YEAR'},
    {value: DocumentNumberPrefixTypeEnum.USER_DEFINED, label: 'COMMON.USER_DEFINED'},
  ];

  public dateFormatList: { value: DocumentNumberDateFormatEnum, label: string }[] = [
    {value: DocumentNumberDateFormatEnum.DDMMYYYY, label: 'DOCUMENTS_NUMBERS.DDMMYYYY'},
    {value: DocumentNumberDateFormatEnum.DDMMYY,   label: 'DOCUMENTS_NUMBERS.DDMMYYY'},
    {value: DocumentNumberDateFormatEnum.YYYMMDD,  label: 'DOCUMENTS_NUMBERS.YYYYMMDD'},
    {value: DocumentNumberDateFormatEnum.YYMMDD,   label: 'DOCUMENTS_NUMBERS.YYMMDD'},
    {value: DocumentNumberDateFormatEnum.YY,       label: 'DOCUMENTS_NUMBERS.YY'},
    {value: DocumentNumberDateFormatEnum.YYYY,     label: 'UNITS.YYYY'},
  ];

  public separatorList: { value: DocumentNumberPrefixSeparatorEnum, label: string }[] = [
    {value: DocumentNumberPrefixSeparatorEnum.SLASH, label: '/'},
    {value: DocumentNumberPrefixSeparatorEnum.DOT, label: '.'},
    {value: DocumentNumberPrefixSeparatorEnum.COLON, label: ':'},
    {value: DocumentNumberPrefixSeparatorEnum.DASH, label: '-'},
    {value: DocumentNumberPrefixSeparatorEnum.NONE, label: 'DOCUMENTS_NUMBERS.WITHOUT_SEPARATOR'},
  ];

  public numberLengthList: { value: number, label: string }[] = [
    { value: 3, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 3}) },
    { value: 4, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 4}) },
    { value: 5, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 5}) },
    { value: 6, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 6}) },
    { value: 7, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 7}) },
    { value: 8, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 8}) },
    { value: 9, label: this.translateService.instant('DOCUMENTS_NUMBERS.N_SYMBOLS', {n: 9}) },
  ];

  public resetPeriodsList: { value: DocumentNumberResetEnum, label: string }[] = [
    {value: DocumentNumberResetEnum.DAILY, label: 'DOCUMENTS_NUMBERS.DAY'},
    {value: DocumentNumberResetEnum.ANNUALLY, label: 'FORM.YEAR'},
    {value: DocumentNumberResetEnum.NEVER, label: 'DOCUMENTS_NUMBERS.NONE'},
  ];

  @Output() resetEmit: EventEmitter<DocumentTypesUppercaseEnum> = new EventEmitter<DocumentTypesUppercaseEnum>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private tradeSettingsService: TradeSettingsService,
    private accountingSettings: AccountingSettingsService,
  ) {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.hasOwnProperty('documentNumberSettings') && this.documentNumberSettings) {
        this.form.patchValue(this.documentNumberSettings.forwardSettings);
      }
      if (changes.hasOwnProperty('serverErrors') && this.serverErrors.length) {
        this.setServerErrors();
      }
    }
  }

  private getValueValidators(maxLength: number = 3): ValidatorFn[] {
    return [Validators.required, Validators.maxLength(maxLength), Validators.pattern('[a-zA-Z]*')];
  }

  public initForm(): void  {
    this.form = this.fb.group({
      firstPrefix: this.fb.group({
        separator: [],
        type: [],
        value: [null, this.getValueValidators()],
      }),
      secondPrefix: this.fb.group({
        separator: [],
        type: [],
        value: [null, this.getValueValidators()],
      }),
      length: [],
      mode: [],
      reset: [],
      continueExistingNumberSequence: [],
      number: [],
    });

    this.modeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((mode: DocumentNumberModeEnum) => {
        if (mode === DocumentNumberModeEnum.NONE) {
          this.firstPrefixGroup.patchValue(this.documentNumberSettings.currentSettings.firstPrefix, {emitEvent: false});
          this.secondPrefixGroup.patchValue(this.documentNumberSettings.currentSettings.secondPrefix, {emitEvent: false});
        }
        if (mode === DocumentNumberModeEnum.SINGLE) {
          this.secondPrefixGroup.patchValue(this.documentNumberSettings.currentSettings.secondPrefix, {emitEvent: false});
        }
      });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getRunpleIdPreview();
        this.resetControl.setErrors(null, {emitEvent: false});

        if (this.allowedContinueSequence && this.continueExistingNumberSequenceControl.value) {
          this.numberControl.setValidators(Validators.required);
        } else {
          this.numberControl.clearValidators();
        }
        this.numberControl.updateValueAndValidity({emitEvent: false})
      });

    this.firstPrefixTypeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((prefixType: DocumentNumberPrefixTypeEnum) => {
        if (prefixType === DocumentNumberPrefixTypeEnum.YEAR) {
          this.firstPrefixValueControl.clearValidators();
        } else if ((prefixType === DocumentNumberPrefixTypeEnum.DATE)) {
          this.firstPrefixValueControl.setValidators(this.getValueValidators(8));
        } else {
          this.firstPrefixValueControl.setValidators(this.getValueValidators());
        }
        this.firstPrefixValueControl.patchValue(null, { emitEvent: false });
      });

    this.secondPrefixTypeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((prefixType: DocumentNumberPrefixTypeEnum) => {
        if (prefixType === DocumentNumberPrefixTypeEnum.YEAR) {
          this.secondPrefixValueControl.clearValidators();
        } else if ((prefixType === DocumentNumberPrefixTypeEnum.DATE)) {
          this.secondPrefixValueControl.setValidators(this.getValueValidators(8));
        } else {
          this.secondPrefixValueControl.setValidators(this.getValueValidators());
        }
        this.secondPrefixValueControl.patchValue(null, { emitEvent: false });
      });
  }

  private setServerErrors(): void {
    this.serverErrors
      .filter(err => err.documentType === this.documentType)
      .forEach(error => {
        const control: AbstractControl = this.form.get(error.name);
        if (control) {
          control.setErrors({backEndValidation: error.message});
        }
      });
  }

  public isValid(): boolean {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity({emitEvent: false});

    return this.form.valid;
  }

  public getUpdated(): DocumentNumberModel {
    return {
      ...this.documentNumberSettings,
      forwardSettings: {
        ...this.documentNumberSettings.forwardSettings,
        ...this.form.getRawValue()
      }
    };
  }

  public reset(): void {
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'DOCUMENTS_NUMBERS.RESET_TO_DEFAULTS_MODAL_TITLE',
        message: 'DOCUMENTS_NUMBERS.RESET_TO_DEFAULTS_MODAL_DESCR',
        confirmBtnText: 'BUTTON.CONTINUE',
        confirmBtnIcon: 'arrow-right'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.resetEmit.emit(this.documentType);
      }
    })
  }

  public getRunpleIdPreview(): void {
    if (!this.documentType || !this.documentNumberSettings) { return; }

    let request$: Observable<string>;

    switch (this.documentType) {
      case DocumentTypesUppercaseEnum.OIN:
      case DocumentTypesUppercaseEnum.OCN:
        request$ = this.accountingSettings.getNextRunpleIdDocumentNumberSettings(this.documentType, this.form.getRawValue());
        break;
      case DocumentTypesUppercaseEnum.SBC:
      case DocumentTypesUppercaseEnum.OFR:
      case DocumentTypesUppercaseEnum.SO:
      case DocumentTypesUppercaseEnum.RMA:
      case DocumentTypesUppercaseEnum.DN:
        request$ = this.tradeSettingsService.getNextRunpleIdDocumentNumberSettings(this.documentType, this.form.getRawValue());
        break;
    }

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe((numberPreview: string) => {
        this.numberPreview = numberPreview;
        this.cdr.detectChanges();
      });
  }

  get isLengthUp(): boolean {
    if (!this.documentNumberSettings || !this.numberPreview || this.form.dirty) { return false; }
    let isLengthUp = false;

    if (this.documentNumberSettings.currentSettings.mode === DocumentNumberModeEnum.NONE) {
      isLengthUp = this.numberPreview.length > this.documentNumberSettings.currentSettings.length;
      if (isLengthUp) {
        this.showIsLengthUp = true;
      }
      return isLengthUp;
    }

    let maxLength = this.documentNumberSettings.currentSettings.length;
    if (this.documentNumberSettings.currentSettings.firstPrefix.type !== DocumentNumberPrefixTypeEnum.YEAR) {
      maxLength = maxLength + (this.documentNumberSettings.currentSettings.firstPrefix.value.length || 0);
    } else {
      maxLength = 2;
    }
    if (this.documentNumberSettings.currentSettings.firstPrefix.separator !== DocumentNumberPrefixSeparatorEnum.NONE) {
      maxLength = maxLength + 1;
    }

    if (this.documentNumberSettings.currentSettings.mode === DocumentNumberModeEnum.DOUBLE) {
      if (this.documentNumberSettings.currentSettings.secondPrefix.type !== DocumentNumberPrefixTypeEnum.YEAR) {
        maxLength = maxLength + (this.documentNumberSettings.currentSettings.secondPrefix.value.length || 0);
      } else {
        maxLength = maxLength + 2;
      }
      if (this.documentNumberSettings.currentSettings.secondPrefix.separator !== DocumentNumberPrefixSeparatorEnum.NONE) {
        maxLength = maxLength + 1;
      }
    }

    isLengthUp = this.numberPreview.length > maxLength;

    if (isLengthUp) {
      this.showIsLengthUp = true;
    }

    return isLengthUp;
  }

  get lengthControl(): FormControl { return this.form.get('length') as FormControl }
  get modeControl(): FormControl { return this.form.get('mode') as FormControl }
  get resetControl(): FormControl { return this.form.get('reset') as FormControl }
  get continueExistingNumberSequenceControl(): FormControl { return this.form.get('continueExistingNumberSequence') as FormControl }
  get numberControl(): FormControl { return this.form.get('number') as FormControl }

  get firstPrefixGroup(): FormGroup { return this.form.get('firstPrefix') as FormGroup; }
  get secondPrefixGroup(): FormGroup { return this.form.get('secondPrefix') as FormGroup; }

  get firstPrefixSeparatorControl(): FormControl { return this.firstPrefixGroup.get('separator') as FormControl }
  get firstPrefixTypeControl(): FormControl { return this.firstPrefixGroup.get('type') as FormControl }
  get firstPrefixValueControl(): FormControl { return this.firstPrefixGroup.get('value') as FormControl }

  get secondPrefixSeparatorControl(): FormControl { return this.secondPrefixGroup.get('separator') as FormControl }
  get secondPrefixTypeControl(): FormControl { return this.secondPrefixGroup.get('type') as FormControl }
  get secondPrefixValueControl(): FormControl { return this.secondPrefixGroup.get('value') as FormControl }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
