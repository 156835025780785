import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { TradeOfferDeliveryInfoModel, TradeOfferModel } from '../models';
import { AddressFormFactory } from '../../shared/forms/address-form.factory';
import { UpdateBillingInfoBlockValid, UpdateDeliveryInfoBlockValid } from '../store/actions/trade-offer.actions';
import { AppState } from '../../store/state/app.state';
import { DeliveryTypesEnum, ScontoRateTypeEnum } from 'common/src/models';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { AddressTypeEnum } from '../../sales-order/enums/address-type.enum';

@Injectable({
  providedIn: 'root'
})
export class OfferFormService {

  public currentTradeOffer: TradeOfferModel;
  public billingInfoForm: FormGroup;
  public deliveryInfoForm: FormGroup;
  public pickUpAddressForm: FormGroup;
  public deliveryToAddressForm: FormGroup;

  constructor(
    private readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    private metricPipe: MetricConversionPipe,
    private datePipe: DatePipe,
  ) { }

  public initBillingInfoForm(tradeOffer: TradeOfferModel = {} as TradeOfferModel): void {
    this.currentTradeOffer = tradeOffer;
    const billingInfo = tradeOffer.billingInformation;

    this.billingInfoForm = this.fb.group({
      paymentMethod: [get(billingInfo, 'paymentMethod')],
      paymentTerms: [get(billingInfo, 'paymentTerms')],
      paymentDueWithin: [get(billingInfo, 'paymentDueWithin'), {validators: [Validators.required]}],
      sameAsDeliveryAddress: [get(billingInfo, 'sameAsDeliveryAddress')],
      billingAddress: AddressFormFactory.getForm(get(billingInfo, 'billingAddress')),
      vatDisabled: [get(tradeOffer, 'properties.vatDisabled')],
      smallBusiness: [get(tradeOffer, 'properties.smallBusiness')],
      addressTemplate: [get(billingInfo, 'addressTemplate')],
      name: [get(billingInfo, 'name')],
      sconto: this.fb.group({
        enabled: [get(billingInfo, 'sconto.enabled')],
        rate: [this.fillRateField(get(billingInfo, 'sconto.rate')), {validators: [Validators.required]}],
        type: [get(billingInfo, 'sconto.type'), {validators: [Validators.required]}],
        period: [get(billingInfo, 'sconto.period'), {validators: [Validators.required]}],
      })
    });

    if (get(billingInfo, 'sconto.enabled')) {
      this.scontoRateControl.setValidators(Validators.required);
      this.scontoPeriodControl.setValidators(Validators.required)
    } else {
      this.scontoRateControl.clearValidators();
      this.scontoPeriodControl.clearValidators();
    }
    this.scontoRateControl.updateValueAndValidity();
    this.scontoPeriodControl.updateValueAndValidity();

    if (this.billingInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.billingInfoForm.get('billingAddress') as FormGroup);
      this.billingInfoForm.get('name').disable({emitEvent: false});
      this.billingInfoForm.get('name').updateValueAndValidity({emitEvent: false});
    } else {
      this.billingInfoForm.get('billingAddress').get('address_line').disable({emitEvent: false});
      this.billingInfoForm.get('billingAddress').get('address_line').updateValueAndValidity({emitEvent: false});
    }

    if (get(billingInfo, 'sameAsDeliveryAddress')) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.billingInfoForm.get('billingAddress') as FormGroup);
      this.billingInfoForm.get('billingAddress').get('address_line').disable({emitEvent: false});
      this.billingInfoForm.get('billingAddress').get('address_line').updateValueAndValidity({emitEvent: false});
    }

    this.store.dispatch(UpdateBillingInfoBlockValid({ billingInfoBlockValid: this.billingInfoForm.valid }));
  }

  public initDeliveryInfoForm(deliveryInfo: TradeOfferDeliveryInfoModel = {} as TradeOfferDeliveryInfoModel): void {
    const deliveryDate = get(deliveryInfo, 'estimatedDeliveryDate');

    this.deliveryInfoForm = this.fb.group({
      deliveryType: [get(deliveryInfo, 'deliveryType')],
      isExpressDelivery: [get(deliveryInfo, 'isExpressDelivery')],
      isInsuredDelivery: [get(deliveryInfo, 'isInsuredDelivery')],
      isDeliveryCosts: [get(deliveryInfo, 'isDeliveryCosts')],
      estimatedDeliveryDate: [deliveryDate ? this.datePipe.transform(deliveryDate, 'yyyy-MM-dd') : null],
      partialShipment: [get(deliveryInfo, 'partialShipment')],
      addressTemplate: [get(deliveryInfo, 'addressTemplate')],

      deliveryCosts: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryCosts'))],
      deliveryCostsGross: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryCostsGross'))],
      deliveryCostsVat: [get(deliveryInfo, 'deliveryCostsVat')],
      deliveryCostsPayer: [get(deliveryInfo, 'deliveryCostsPayer')],

      // deliveryInsuranceCosts: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryInsuranceCosts'))],
      // deliveryInsuranceCostsGross: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryInsuranceCostsGross'))],
      // deliveryInsuranceVat: [get(deliveryInfo, 'deliveryInsuranceVat')],
      // deliveryInsurancePayer: [get(deliveryInfo, 'deliveryInsurancePayer')],
      name: [get(deliveryInfo, 'name')],
    });

    this.pickUpAddressForm = AddressFormFactory.getForm(get(deliveryInfo, 'pickUpAddress'));
    this.deliveryToAddressForm = AddressFormFactory.getForm(get(deliveryInfo, 'deliveryToAddress'));

    this.updateDeliveryInfoBlockValid();
  }

  private fillRateField(value: number|string): number|string {
    if (get(this, 'tradeOffer.billingInformation.sconto.type') === ScontoRateTypeEnum.ABSOLUTE) {
      if (value === 0) { return value; }
      if (!value) { return null; }
      return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
    }
    return value;
  }

  private fillControlValueWithConversion(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  private updateDeliveryInfoBlockValid(): void {
    const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
    // const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];

    let isFormsValid: boolean;

    if (this.deliveryInfoForm.get('isDeliveryCosts').value) {
      deliveryCostControls.forEach(control => control.setValidators(Validators.required));
    } else {
      deliveryCostControls.forEach(control => control.clearValidators());
    }

    // if (this.deliveryInfoForm.get('isInsuredDelivery').value) {
    //   insuranceCostControls.forEach(control => control.setValidators(Validators.required));
    // } else {
    //   insuranceCostControls.forEach(control => control.clearValidators());
    // }

    // [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());

    [...deliveryCostControls].forEach(control => control.updateValueAndValidity());

    if (this.deliveryTypeControl.value === DeliveryTypesEnum.DELIVERY) {
      if (this.deliveryInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.deliveryToAddressForm);
      } else {
        this.deliveryToAddressForm.get('address_line').disable({emitEvent: false});
        this.deliveryToAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.deliveryInfoForm.valid && this.deliveryToAddressForm.valid;
    } else {
      if (this.deliveryInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.pickUpAddressForm);
      } else {
        this.pickUpAddressForm.get('address_line').disable({emitEvent: false});
        this.pickUpAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.deliveryInfoForm.valid && this.pickUpAddressForm.valid;
    }

    this.store.dispatch(UpdateDeliveryInfoBlockValid({ deliveryInfoBlockValid: isFormsValid }));
  }

  get scontoRateControl(): FormGroup { return this.billingInfoForm.get('sconto').get('rate') as FormGroup; }
  get scontoPeriodControl(): FormGroup { return this.billingInfoForm.get('sconto').get('period') as FormGroup; }

  get deliveryCostsVat(): FormControl { return this.deliveryInfoForm.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.deliveryInfoForm.get('deliveryCosts') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.deliveryInfoForm.get('deliveryCostsGross') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.deliveryInfoForm.get('deliveryCostsPayer') as FormControl; }
  // get deliveryInsuranceVat(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceVat') as FormControl; }
  // get deliveryInsuranceCosts(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceCosts') as FormControl; }
  // get deliveryInsuranceCostsGross(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceCostsGross') as FormControl; }
  // get deliveryInsurancePayer(): FormControl { return this.deliveryInfoForm.get('deliveryInsurancePayer') as FormControl; }
  get deliveryTypeControl(): FormControl { return this.deliveryInfoForm.get('deliveryType') as FormControl; }

}
