import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import {
  EcommerceSettingsBlockContainerModel,
  EcoSettingsBlockModel,
  EcoSettingsBlockUpdateModel
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { AddBannerModalComponent } from '../add-banner-modal/add-banner-modal.component';
import { EcoSettingsBlockTypeEnum } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/enums';

@Component({
  selector: 'rnpl-slider-modal',
  templateUrl: './slider-modal.component.html',
  styleUrls: ['./slider-modal.component.scss']
})
export class SliderModalComponent extends BaseModalComponent implements OnInit {

  public banners: EcommerceSettingsBlockContainerModel[] = [];
  public selectedSliderSpeed: number = 4;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public dialog: MatDialog,
    public ecoSettingsApiService: EcoSettingsApiService,
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<SliderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      block: EcoSettingsBlockModel;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    if (this.isBlockExist) {
      this.selectedSliderSpeed = this.data.block.container.slideChangeSpeed;
      this.banners = [...this.data.block.container.banners];
    }
  }

  public addBanner(): void {
    const dialog = this.dialog.open(AddBannerModalComponent, {
      data: {
        addOnBlock: true
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.banners.push(res.container);
      }
    });
  }

  public blockDropped(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.banners, event.previousIndex, event.currentIndex);
  }

  public deleteBanner(banner: EcommerceSettingsBlockContainerModel): void {
    const foundIndex = this.banners.findIndex(x => x.id === banner.id);
    this.banners.splice(foundIndex, 1);
  }

  public editBanner(banner: EcommerceSettingsBlockContainerModel): void {
    const dialog = this.dialog.open(AddBannerModalComponent, {
      data: {
        block: {
          container: banner,
          id: banner.id
        },
        addOnBlock: true
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        const foundIndex = this.banners.findIndex(x => x.id === res.id);
        this.banners[foundIndex] = res.container;
      }
    });
  }

  public submitSlider(): void {
    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    let submitRequest$: Observable<any>;

    const data: EcoSettingsBlockUpdateModel = {
      slideChangeSpeed: this.selectedSliderSpeed,
      blockType: EcoSettingsBlockTypeEnum.SLIDER,
      addOnBlock: false,
      bannerIds: this.banners.map(banner => banner.id)
    };

    if (this.isBlockExist) {
      submitRequest$ = this.ecoSettingsApiService.ecoSettingsUpdateBlock(this.data.block.id, data);
    } else {
      submitRequest$ = this.ecoSettingsApiService.ecoSettingsCreateBlock(data);
    }

    submitRequest$
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((block: EcoSettingsBlockModel) => this.dialogRef.close(block));
  }

  public get isBlockExist(): boolean {
    return !!(this.data && this.data.block);
  }

}
