import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';


import {
  DynamicFormsModule,
  ProductsCommonModule,
  RnplCommonModule,
  UIComponentsModule,
  WizardModule
} from 'common/src/modules';
import { AttributeWizardComponent } from './attribute-wizard.component';
import { ClosingPopupComponent, ControlChangingConfirmationComponent } from './modals';
import { SystemSettingsService } from '../system-settings.service';
import { AttributeSummaryComponent } from './components';
import {
  FamilyComponent,
  ConfirmationComponent,
  ControlSetupComponent,
  EntityComponent,
  EntityTypeComponent,
  FormsComponent,
  GeneralSettingsComponent,
  LocalizationComponent,
  TranslationsComponent
} from './steps';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    AttributeWizardComponent,
    ControlChangingConfirmationComponent,
    ClosingPopupComponent,
    AttributeSummaryComponent,
    GeneralSettingsComponent,
    ControlSetupComponent,
    LocalizationComponent,
    TranslationsComponent,
    EntityTypeComponent,
    EntityComponent,
    FormsComponent,
    FamilyComponent,
    ConfirmationComponent
  ],
  exports: [
    AttributeWizardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    NgSelectModule,
    RnplCommonModule,
    WizardModule,
    ProductsCommonModule,
    DynamicFormsModule,
    UIComponentsModule,
    TranslateModule
  ],
  providers: [
    SystemSettingsService
  ]
})
export class AttributeWizardModule {

}
