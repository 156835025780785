import { createSelector } from '@ngrx/store';

import { ProductsFeatureKey, ProductsState, ProductsStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[ProductsFeatureKey]);

export const selectProductsList = createSelector(
  selectFeature,
  (state: ProductsStore) => state.list
);

export const selectProductsEntity = createSelector(
  selectFeature,
  (state: ProductsStore) => state.entity
)

export const selectCategories = createSelector(
  selectFeature,
  (state: ProductsStore) => state.categories
)

export const selectProduct = createSelector(
  selectProductsEntity,
  (state: ProductsState) => state.product
);

export const selectProductCurrentState = createSelector(
  selectProductsEntity,
  (state: ProductsState) => state.currentState
);

export const selectFinishEditingState = createSelector(
  selectProductsEntity,
  (state: ProductsState) => state.isEditingFinished
);

export const productUpdatedAt = createSelector(
  selectProductsEntity,
  (state: ProductsState) => state.updatedAt
);

export const loadingRequestsCount = createSelector(
  selectProductsEntity,
  (state: ProductsState) => state.loadingRequestsCount
);
