import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

import { UIComponentsModule, RnplCommonModule } from 'common/src/modules';
import { AddressFormComponent } from './address-form.component';
import { InputValidationErrorsModule } from 'projects/workspace/src/app/shared/components/input-validation-errors/input-validation-errors.module';

@NgModule({
  declarations: [
    AddressFormComponent,
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RnplCommonModule,
    NgxMaskModule.forRoot(),
    InputValidationErrorsModule,
    TranslateModule,
    GooglePlaceModule
  ],
  exports: [
    AddressFormComponent,
  ],
  providers: [
  ]
})

export class AddressFormModule {
}
