import { createAction, props } from '@ngrx/store';
import { FamilyModel, ProductModel } from 'common/src/modules/products';
import { UIStatesEnum } from 'common/src/models';
import { ProductsListPageState } from '../reducers';
import { ProductStatusEnum } from '../../enums/product-status.enum';
import { ProductTypes } from 'common/src/modules/products/product-types';


export const LoadProductsList = createAction(
  '[Products] Load Products List',
  props<{
    productType: ProductTypes,
    entityKey: 'bulk'|number,
    status: ProductStatusEnum,
    page: number,
    productsListData: ProductsListPageState,
  }>()
);

export const LoadCategoriesList = createAction(
  '[Products] Load Categories',
  props<{
    productType: ProductTypes,
    categories: FamilyModel[],
  }>()
);

export const LoadProduct = createAction(
  '[Products] Load Product',
  props<{ product: ProductModel }>()
);

export const UpdateProductsCurrentState = createAction(
  '[Products] Update Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateFinishEditingState = createAction(
  '[Products] Update Finish Editing State',
  props<{ isEditingFinished: boolean }>()
);

export const UpdateProductUpdatedAt = createAction(
  '[Products] Update Product updatedAt',
  props<{ updatedAt: Date }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[Products] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Products] Decrement Loading Requests Count'
);
