import { createAction, props } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { UIStatesEnum } from 'common/src/models';
import { CreditNoteModel } from '../../models';
import { CreditNotesListTabsEnum } from '../../enums';
import { CreditNotesListState } from '../reducers';
import { CRNPositionsModel } from '../../models/credit-note-position.model';


export const LoadCreditNoteList = createAction(
  '[Credit Note] Load Credit Notes List',
  props<{ creditNoteListData: CreditNotesListState, status: CreditNotesListTabsEnum, page: number }>()
);

export const LoadCreditNote = createAction(
  '[Credit Note] Load Credit Note',
  props<{ creditNote: CreditNoteModel }>()
);

export const ResetCreditNote = createAction(
  '[Credit Note] Reset Credit Note'
);

export const LoadCreditNoteCountries = createAction(
  '[Credit Note] Load Countries',
  props<{ countries: CountryModel[] }>()
);

export const UpdateCreditNoteState = createAction(
  '[Credit Note] Update Credit Note Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const ResetCreditNoteState = createAction(
  '[Credit Note] Reset Credit Note Current State'
);

export const LoadCreditNotePositions = createAction(
  '[Credit Note] Load Credit Note Positions',
  props<{ positions: CRNPositionsModel }>()
);

export const UpdateCreditNotePositionsCount = createAction(
  '[Credit Note] Update Credit Note Positions Count',
  props<{ positionsCount: number }>()
);

export const ResetCreditNotePositionsCount = createAction(
  '[Credit Note] Reset Credit Note Positions Count'
);

export const UpdateShouldRefreshEntity = createAction(
  '[Credit Note] Update Credit Note should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const UpdateCreditNoteUpdatedAt = createAction(
  '[Credit Note] Update Credit Note updatedAt',
  props<{ updatedAt: Date }>()
);

export const CreditNoteSendEmail = createAction(
  '[Credit Note] Send Email'
);

export const ClearCreditNoteUpdatedAt = createAction(
  '[Credit Note] Clear Credit Note updatedAt'
);

export const IncrementLoadingRequestsCount = createAction(
  '[Credit Note] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Credit Note] Decrement Loading Requests Count'
);

export const UpdatePropertiesBlockValid = createAction(
  '[Credit Note] Update Credit Note Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Credit Note] Update Credit Note Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Credit Note] Update Credit Note Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
