import { createAction, props } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { CategoryModel } from '../../shared/models/category-model';
import { AccountingSettingsModel } from '../../accounting/accounting-settings-module/models';
import { GeneralSettingsModel, ProductUnitModel } from '../../shared/models';
import { UserShortcutsModel } from '../../hrm/models';
import { StartScreenModel } from 'common/src/modules/start-screen-page/launchpad.model';
import { PdfSettingsModel } from '../../pdf-settings/models';
import { ProductTypes } from 'common/src/modules/products/product-types';

export const LoadCountries = createAction(
  '[Shared] Load Countries'
);

export const LoadCountriesSuccess = createAction(
  '[Shared] Load Countries success',
  props<{ countries: CountryModel[] }>()
);

export const LoadCategories = createAction(
  '[Shared] Load Categories'
);

export const LoadCategoriesSuccess = createAction(
  '[Shared] Load Categories success',
  props<{ categories: CategoryModel[] }>()
);

export const LoadProductsUnitsSuccess = createAction(
  '[Shared] Load Products Units success',
  props<{ productType: ProductTypes, units: ProductUnitModel[]}>()
);

export const LoadAccountingSettingsSuccess = createAction(
  '[Shared] Load Accounting Settings success',
  props<{ accountingSettings: AccountingSettingsModel }>()
);

export const LoadGeneralSettingsSuccess = createAction(
  '[Shared] Load General Settings success',
  props<{ generalSettings: GeneralSettingsModel }>()
);

export const LoadPdfSettingsSuccess = createAction(
  '[Shared] Load PDF Settings success',
  props<{ pdfSettings: PdfSettingsModel }>()
);

export const LoadUserShortcutsSuccess = createAction(
  '[Shared] Load User Shortcuts success',
  props<{ userShortcuts: UserShortcutsModel }>()
);

export const LoadUserQuickActionsSuccess = createAction(
  '[Shared] Load User Quick Actions success',
  props<{ userQuickActions: UserShortcutsModel }>()
);

export const LoadUserStartScreenListSuccess = createAction(
  '[Shared] Load User Start Screen Items success',
  props<{ userStartScreenItems: StartScreenModel[] }>()
);

export const IncrementStartScreenListLoadingRequestsCount = createAction(
  '[Shared] Increment Start Screen List Loading Request',
);

export const DecrementStartScreenListLoadingRequestsCount = createAction(
  '[Shared] Decrement Start Screen List Loading Request',
);

export const LoadAppsCountersSuccess = createAction(
  '[Shared] Load Apps Counters Success',
  props<{ appsCounters: {[key: string]: number} }>()
);
