import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { DocumentListContainerComponent } from './document-list-container.component';
import { UIComponentsModule } from 'common/src/modules';


@NgModule({
  declarations: [
    DocumentListContainerComponent
  ],
  exports: [
    DocumentListContainerComponent
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    TranslateModule,
    PerfectScrollbarModule
  ]
})
export class DocumentListContainerModule { }
