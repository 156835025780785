import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';

import { AvailableSourcesModel } from 'projects/workspace/src/app/credit-note/models/available-sources.model';
import { CreditNotePurposeEnum } from 'projects/workspace/src/app/credit-note/enums';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import { PaymentDirectionEnum } from 'projects/workspace/src/app/payment/models/payment.model';
import { CreditNoteModel } from 'projects/workspace/src/app/credit-note/models';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import {
  // CRN_COMMON_CORRECTION_DIRECTIONS,
  // CRN_PURPOSES,
  getCRNInvoiceTitle
} from 'projects/workspace/src/app/credit-note/credit-note.constants';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';

@Component({
  selector: 'rnpl-credit-note-create-modal',
  templateUrl: './credit-note-create-modal.component.html',
  styleUrls: ['./credit-note-create-modal.component.scss'],
})
export class CreditNoteCreateModalComponent implements OnDestroy {

  public companyProfile: CompanyProfile;
  public form: FormGroup;
  public creditNotePurposeEnum: typeof CreditNotePurposeEnum = CreditNotePurposeEnum;
  public paymentDirectionEnum: typeof PaymentDirectionEnum = PaymentDirectionEnum;
  // public crnPurposes: {value: CreditNotePurposeEnum; label: string}[] = CRN_PURPOSES;
  // public commonCorrectionDirections: {value: PaymentDirectionEnum; label: string}[] = CRN_COMMON_CORRECTION_DIRECTIONS;
  public invoiceTitle = getCRNInvoiceTitle;
  public customSearchFn = CustomSearchFn;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly sourcesList$: BehaviorSubject<AvailableSourcesModel[]> = new BehaviorSubject<AvailableSourcesModel[]>([]);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    public readonly dialogRef: MatDialogRef<CreditNoteCreateModalComponent>,
    public readonly creditNoteApiService: CreditNoteApiService,
  ) {
    this.initForm();

    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      ).subscribe((profile: CompanyProfile) => {
      this.companyProfile = profile;
    });
  }

  public purposeChanged(): void {
    this.direction.patchValue(null, {emitEvent: false});
    this.sourceDocumentId.patchValue(null, {emitEvent: false});
    this.getSources();
  }

  private getSources(): void {
    const purpose = this.purpose.value;
    if (purpose !== CreditNotePurposeEnum.IIN_CORRECTION && purpose !== CreditNotePurposeEnum.OIN_CORRECTION) {
      return;
    }
    this.sourcesList$.next([]);
    this.showDropdownSpin$.next(true);

    this.creditNoteApiService.getCreditNoteModalAvailableSources(this.purpose.value)
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((sources: AvailableSourcesModel[]) => {
        this.sourcesList$.next(this.prepareSourcesList(sources));
      });
  }

  private prepareSourcesList(sources): AvailableSourcesModel[] {
    return sources.map(item => {
      return {
        ...item,
        searchLabel: `${item.runpleId} ${item.partner.name} ${item.amount}`
      };
    })
  }

  private initForm(): void {
    this.form = this.fb.group({
      purpose: [null, [Validators.required]],
      direction: [null, [Validators.required]],
      sourceDocument: this.fb.group({
        documentId: [null, [Validators.required]]
      }),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value.purpose === CreditNotePurposeEnum.COMMON_CORRECTION) {
          this.direction.setValidators([Validators.required]);
          this.sourceDocumentId.clearValidators();
        } else {
          this.sourceDocumentId.setValidators([Validators.required]);
          this.direction.clearValidators();
        }
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.invalid || this.submitRequest$.getValue()) { return; }

    this.submitRequest$.next(true);

    this.creditNoteApiService.createCreditNoteForInvoice(
      this.sourceDocumentId.value,
      this.purpose.value,
      this.direction.value
    )
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        tap((data: CreditNoteModel) => {
          this.dialogRef.close();
          this.dialogRef.afterClosed()
            .subscribe(() => this.router.navigate([`/accounting/credit-note/${data.id}`]));
        }),
        takeUntil(this.destroy$),
      ).subscribe();
  }

  get sourceDocumentId(): FormControl { return this.form.get('sourceDocument').get('documentId') as FormControl; }
  get purpose(): FormControl { return this.form.get('purpose') as FormControl; }
  get direction(): FormControl { return this.form.get('direction') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

