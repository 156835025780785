import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';


import { WizardStep } from '../../../../wizard/wizard-step';

@Component({
  selector: 'rnpl-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent extends WizardStep implements OnInit, OnChanges {

  @Input()
  public countries: Array<any> = [];

  public localizationList: Array<any> = [];

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('countries')) {
      this.localizationList = this.countries
        .map(country => {
          return {
            country,
            lang: country.related_languages.map(lang => lang.language.title).join(', '),
            checked: !!this.form.get('localizations').value.find(locale => locale.id === country.id)
          };
        });
    }
  }

  public setLocalization(country, event): void {
    const localizations = this.form.get('localizations').value;
    if (event.target.checked) {
      localizations.push(country);
      this.form.get('localizations').setValue(localizations);
    } else {
      this.form.get('localizations').setValue(localizations.filter(locale => locale !== country));
    }
  }

}
