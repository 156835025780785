import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get, isEqual } from 'lodash';

import {
  selectCountries,
  selectOutgoingInvoice,
  selectOutgoingInvoiceState
} from 'projects/workspace/src/app/outgoing-invoice/store/selectors';
import { CountryModel } from '../../../rnpl-common';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import {
  AddressModel,
  BankTransferMethodsEnum,
  CustomerTypeEnum,
  PaymentMethodsEnum,
  ScontoModel,
  UIStatesEnum
} from '../../../../models';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { UpdateBillingInfoBlockValid } from 'projects/workspace/src/app/outgoing-invoice/store/actions/outgoing-invoice.actions';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { UPDATE_SMALL_BUSINESS_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';
import { selectAccountingSettings } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AccountingSettingsModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';

@Component({
  selector: 'rnpl-outgoing-invoice-billing-info-modal',
  templateUrl: './outgoing-invoice-billing-info-modal.component.html',
})
export class OutgoingInvoiceBillingInfoModalComponent implements OnInit, OnDestroy {

  public accountingSettings: AccountingSettingsModel;
  public hasPayments: boolean = false;
  public paymentMethods: typeof PaymentMethodsEnum = PaymentMethodsEnum;
  public paymentMethod: PaymentMethodsEnum;
  public bankTransferMethodsEnum = BankTransferMethodsEnum;
  public currentState: UIStatesEnum;
  public currentInvoice: OutgoingInvoiceModel;
  public form: FormGroup;
  public formUpdatedByField: FormGroup;
  public scontoForm: FormGroup;
  public countries: CountryModel[] = [];
  public isReadonly: boolean = true;
  public vatDisabledControlDisabled: boolean = false;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeList = [];
  public addressTypeForGeneralPartner = [
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];
  public controlAddressTemplate: FormControl = new FormControl(null);
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;

  private convertAddressToString = convertAddressToStringHelper;
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public dialogRef: MatDialogRef<OutgoingInvoiceBillingInfoModalComponent>,
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly invoiceApiService: InvoiceApiService,
    private readonly cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.store.select(selectAccountingSettings)
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountingSettings: AccountingSettingsModel) => this.accountingSettings = accountingSettings);

    this.initScontoForm();
    this.initForm();

    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries: CountryModel[]) => {
        this.countries = countries;
        this.cdr.detectChanges();
      });

    this.store.select(selectOutgoingInvoice)
      .pipe(takeUntil(this.destroy$))
      .subscribe((invoice: OutgoingInvoiceModel) => {
        this.currentInvoice = invoice;
        this.paymentMethod = get(invoice, 'billingData.paymentMethod') || this.paymentMethods.BANK_TRANSFER;
        this.scontoForm.patchValue(invoice.billingData.sconto, {emitEvent: false});
        this.formUpdatedByField.patchValue(invoice.billingData, {emitEvent: false});
        this.initForm(this.currentInvoice.billingAddress || {} as AddressModel);
        this.initScontoForm(this.currentInvoice.billingData.sconto || {} as ScontoModel);
        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);

        if (invoice.billingData.sconto.enabled) {
          this.store.dispatch(UpdateBillingInfoBlockValid({
            billingInfoBlockValid: this.form.valid && this.formUpdatedByField.valid && this.scontoForm.valid
          }));
        } else {
          this.store.dispatch(UpdateBillingInfoBlockValid({
            billingInfoBlockValid: this.form.valid && this.formUpdatedByField.valid
          }));
        }
        this.getPaymentsCount();
        this.setFormsState();
      });

    this.store.select(selectOutgoingInvoiceState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private getPaymentsCount(): void {
    this.invoiceApiService.getPaymentsCount(this.currentInvoice.invoiceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: number) => {
        this.hasPayments = !!response;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.form.disable(opts);
      this.formUpdatedByField.disable(opts);
      this.scontoForm.disable(opts);
      this.addressTemplateControl.disable(opts);
      this.billingAddress.disable(opts);
      this.cdr.detectChanges();
    } else {
      this.form.enable(opts);
      this.formUpdatedByField.enable(opts);
      this.scontoForm.enable(opts);
      this.addressTemplateControl.enable(opts);
      this.billingAddress.enable(opts);
      this.cdr.detectChanges();

      if (!get(this, 'currentInvoice.customer') && !get(this, 'currentInvoice.notSavedCustomer.enabled')) {
        this.billingAddress.disable(opts);
      } else {
        this.billingAddress.enable(opts);
        this.cdr.detectChanges();
      }

      if (this.hasPayments) {
        this.form.get('paymentMethod').disable(opts);
      }

      if (this.currentInvoice.status === OutgoingInvoiceListTabsEnum.PAID) {
        this.form.disable(opts);
        this.formUpdatedByField.disable(opts);
        this.scontoForm.disable(opts);
      }

      if (this.currentInvoice.billingData.bankAccount) {
        this.ibanControl.disable(opts);
        this.bicControl.disable(opts);
      }

      if (
        get(this.currentInvoice, 'customer.type') === PartnersTypeEnum.CORPORATE ||
        get(this.currentInvoice, 'customer.type') === PartnersTypeEnum.GENERAL
      ) {
        this.nameControl.disable(opts);
      }

      if (this.isTemplate) {
        this.paymentReferenceControl.disable(opts);
      }

      if (get(this.currentInvoice, 'customer.generalType') === 'inland') {
        this.billingAddress.get('country_iso3').disable(opts);
      }

      this.vatDisabledControlDisabled = this.hasLinkedSo
        || this.hasLinkedSBC
        || this.hasLinkedECO
        || this.hasLinkedCRN
        || this.hasLinkedOFR
        || this.currentInvoice.status === OutgoingInvoiceListTabsEnum.PAID
        || this.currentInvoice.billingData.smallBusiness;
    }
  }

  private initScontoForm(sconto = {}): void {
    this.scontoForm = this.fb.group({
      enabled: [(get(sconto, 'enabled'))],
      rate: [(get(sconto, 'rate')), [Validators.required]],
      type: [(get(sconto, 'type')), [Validators.required]],
      period: [(get(sconto, 'period')), [Validators.required]],
    });
  }

  private initForm(billingAddress = {} as AddressModel): void {
    this.form = this.fb.group({
      paymentMethod: [(get(this, 'currentInvoice.billingData.paymentMethod') || PaymentMethodsEnum.BANK_TRANSFER), [Validators.required]],
      bankTransferMethod: [(get(this, 'currentInvoice.billingData.bankTransferMethod') || PaymentMethodsEnum.BANK_TRANSFER), [Validators.required]],
      iban: [get(this, 'currentInvoice.billingData.iban', ''), {validators: Validators.required, updateOn: 'blur'}],
      bic: [get(this, 'currentInvoice.billingData.bic', ''), {validators: Validators.required, updateOn: 'blur'}],
      paymentReference: [get(this, 'currentInvoice.billingData.paymentReference', ''), {updateOn: 'blur'}],
      // addressTemplate: [get(this, 'currentInvoice.billingAddress.addressTemplate', ''), {updateOn: 'blur'}],
      dueWithinDays: [get(this, 'currentInvoice.billingData.dueWithinDays', 14), {validators: Validators.required, updateOn: 'blur'}],
      billingAddress: AddressFormFactory.getForm(billingAddress),
    });

    this.addressTemplateControl.setValue(billingAddress.addressTemplate);

    if (this.addressTemplateControl.value === AddressTypeEnum.SIMPLIFIED) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.billingAddress);
    } else {
      this.billingAddress.get('address_line').disable({emitEvent: false});
      this.billingAddress.get('address_line').updateValueAndValidity({emitEvent: false});
    }

    this.formUpdatedByField = this.fb.group({
      bankAccount: [(get(this, 'currentInvoice.billingData.bankAccount')), [Validators.required]],
      name: [(get(this, 'currentInvoice.billingData.name')), [Validators.required]],
      smallBusiness: [(get(this, 'currentInvoice.billingData.smallBusiness'))],
      dueWithinDays: [get(this, 'currentInvoice.billingData.dueWithinDays'), {validators: Validators.required, updateOn: 'blur'}],
      dueWithinDate: [get(this, 'currentInvoice.billingData.dueWithinDate'), {validators: Validators.required, updateOn: 'blur'}],
      dueWithinType: [get(this, 'currentInvoice.billingData.dueWithinType'), {validators: Validators.required, updateOn: 'blur'}],
    });

    this.cdr.detectChanges();
    this.bindFormChanges();
  }

  public bindFormChanges() {
    this.form.valueChanges
      .pipe(
        // debounceTime(300), Unused, updated by blur
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.updateBillingData());
  }

  public getNewInvoice() {
    const formData = this.form.getRawValue();
    return {
      ...this.currentInvoice,
      billingAddress: {
        ...this.currentInvoice.billingAddress,
        ...formData.billingAddress,
        addressTemplate: this.addressTemplateControl.value
      },
      billingData: {
        ...this.currentInvoice.billingData,
        bankTransferMethod: get(formData, 'bankTransferMethod', ''),
        dueWithinDays: get(formData, 'dueWithinDays'),
        paymentMethod: get(formData, 'paymentMethod', ''),
        paymentReference: get(formData, 'paymentReference', '')
      }
    };
  }

  public updateBillingData(): void {
    this.invoiceApiService.updateInvoice(this.getNewInvoice())
      .subscribe();
  }

  public scontoFieldUpdated(field: FormInputChangedModel): void {
    // in case we cant use (change) event with currencyMask, and each (blur) event returns value even if it not has been changed
    // send data to back-end only if it changed
    // will be nice to remove it in future updates
    if (this.currentInvoice.billingData.sconto[field.fieldName] === field.fieldValue) {
      return;
    }

    const fieldName = 'billingData.sconto.' + field.fieldName;
    this.updateField(fieldName, field.fieldValue);
  }

  public updateField(fieldName: string, fieldValue: any): void {
    this.invoiceApiService.updateOutgoingInvoiceField(this.currentInvoice.invoiceId, fieldName, fieldValue)
      .subscribe(); // updated via store
  }

  public updateSmallBusiness(fieldName: string, fieldValue: any): void {
    if (this.accountingSettings.smallBusiness.forward === fieldValue) {
      this.updateField(fieldName, fieldValue);
      return;
    }

    const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_SMALL_BUSINESS_MODAL_CONFIG);

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.updateField(fieldName, fieldValue);
      } else if (res === CommonModalsActionsEnum.REJECT) {
        this.router.navigate(['/accounting/settings/general-settings']);
      } else {
        this.smallBusiness.setValue(this.currentInvoice.billingData.smallBusiness);
      }
    });
  }

  // public fillCustomerBillingAddress(): void {
  //   const customerId = get(this, 'currentInvoice.customer.id', null);
  //   if (customerId) {
  //     this.invoiceApiService.assignCustomerBillingAddress(this.currentInvoice.invoiceId, customerId)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe();
  //   }
  // }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get deliveryAddressAsText(): string {
    if (!this.form) { return ''; }

    const billingAddressVal = this.billingAddress.value;
    return this.convertAddressToString(billingAddressVal, this.countries);
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'currentInvoice.customer.runpleId');
  }

  get hasLinkedSo(): boolean {
    return !!get(this, 'currentInvoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.SO).length;
  }

  get hasLinkedSBC(): boolean {
    return !!get(this, 'currentInvoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.SBC).length;
  }

  get hasLinkedECO(): boolean {
    return !!get(this, 'currentInvoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.ECO).length;
  }

  get hasLinkedCRN(): boolean {
    return !!get(this, 'currentInvoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.CRN).length;
  }

  get hasLinkedOFR(): boolean {
    return !!get(this, 'currentInvoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.OFR)
      .length;
  }

  get isTemplate(): boolean {
    return this.currentInvoice && this.currentInvoice.status === OutgoingInvoiceListTabsEnum.TEMPLATE;
  }

  get isGeneralPartner(): boolean {
    return this.currentInvoice && this.currentInvoice.partnerType === CustomerTypeEnum.GENERAL;
  }

  get billingAddress(): FormGroup { return this.form.get('billingAddress') as FormGroup; }
  get ibanControl(): FormControl { return this.form.get('iban') as FormControl; }
  get bicControl(): FormControl { return this.form.get('bic') as FormControl; }
  get paymentReferenceControl(): FormControl { return this.form.get('paymentReference') as FormControl; }
  get addressTemplateControl(): FormControl { return this.controlAddressTemplate as FormControl; }
  get dueWithinDays(): FormControl { return this.formUpdatedByField.get('dueWithinDays') as FormControl; }
  get dueWithinDate(): FormControl { return this.formUpdatedByField.get('dueWithinDate') as FormControl; }
  get dueWithinType(): FormControl { return this.formUpdatedByField.get('dueWithinType') as FormControl; }
  get bankAccountControl(): FormControl { return this.formUpdatedByField.get('bankAccount') as FormControl; }
  get nameControl(): FormControl { return this.formUpdatedByField.get('name') as FormControl; }
  get smallBusiness(): FormControl { return this.formUpdatedByField.get('smallBusiness') as FormControl; }
}
