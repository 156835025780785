import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import { takeUntil } from 'rxjs/operators';
import { getLodTimeRecordHints } from 'projects/workspace/src/app/time-tracking/time-tracking-hints.config';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';

@Component({
  selector: 'rnpl-log-time-modals-hints',
  templateUrl: './log-time-modals-hints.component.html',
})
export class LogTimeModalsHintsComponent extends BaseMegaHintsComponent implements OnInit, OnChanges {
  @Input() public billNeed: boolean;
  @Input() public displayDurationSummaryItem: boolean;
  @Input() public displayDurationHint: boolean;
  @Input() public displayDurationEnableHint: boolean;
  @Input() public selectedServices: boolean;
  @Input() public screenName;

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'COLUMN.LOGGED_TIME';
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('billNeed') ||
      changes.hasOwnProperty('displayDurationSummaryItem') ||
      changes.hasOwnProperty('displayDurationHint') ||
      changes.hasOwnProperty('displayDurationEnableHint') ||
      changes.hasOwnProperty('selectedServices')
    ) {
      clearInterval(this.countdownTimer);
      if (this.shepherdService.isActive) {
        this.shepherdService.cancel();
        this.animationStateIsPaused$.next(false);
      }

      this.initHints();
    }
  }

  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = getLodTimeRecordHints(
            this.billNeed,
            this.displayDurationSummaryItem,
            this.displayDurationHint,
            this.displayDurationEnableHint,
            this.selectedServices,
          );

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length && !this.shepherdService.isActive) {
            this.shepherdService.start();
          }
        }
      });
  }
}
