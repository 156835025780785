import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';


@Component({
  selector: 'rnpl-step-header',
  templateUrl: './step-header.component.html',
  styleUrls: ['./step-header.component.scss']
})
export class StepHeaderComponent {

  @HostBinding('class') class = 'wizard-header';

  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Output()
  public closeBtnClicked: EventEmitter<any> = new EventEmitter();

  public close(): void {
    this.closeBtnClicked.emit();
  }
}
