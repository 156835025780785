import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { PaymentModel } from 'projects/workspace/src/app/payment/models/payment.model';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { ScheduledPaymentBillingPeriodModel } from 'projects/workspace/src/app/scheduled-payment/models';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { ScheduledPaymentApiService } from 'projects/workspace/src/app/scheduled-payment/services/scheduled-payment-api.service';
import { SearchDocumentResponse } from '../../modals-common/link-document-modal/model/search-document-response';

@Component({
  selector: 'rnpl-scheduled-payments-link-modal',
  templateUrl: './scheduled-payments-link-modal.component.html',
})
export class ScheduledPaymentsLinkModalComponent extends BaseModalComponent implements OnInit {

  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  public modalTitle = {
    [DocumentTypesUppercaseEnum.OPB]: 'SCHEDULED_PAYMENT.LINK_PAYMENT'
  };

  public selectTitle = {
    [DocumentTypesUppercaseEnum.OPB]: 'DOC_SUMMARY.PAYMENT'
  };

  readonly linkDocumentRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public documentsList: SearchDocumentResponse[] = [];
  public selectedDocument: SearchDocumentResponse = null;

  public customSearchFn = CustomSearchFn;

  constructor(
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<ScheduledPaymentsLinkModalComponent>,
    private readonly scheduledPaymentApiService: ScheduledPaymentApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      scheduledPaymentId: number;
      billingPeriod: ScheduledPaymentBillingPeriodModel;
      documentType: DocumentTypesUppercaseEnum
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.getDocument();
    // this.router.events
    //   .pipe(
    //     filter((event: RouterEvent) => event instanceof NavigationStart),
    //     filter(() => !!this.dialogRef),
    //     takeUntil(this._destroy)
    //   )
    //   .subscribe(() => {
    //     this.dialogRef.close();
    //   });
  }

  private getDocument(): void {
    this.showDropdownSpin$.next(true);

    this.scheduledPaymentApiService.getAvailableForLinkDocuments(this.data.scheduledPaymentId)
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((documents: SearchDocumentResponse[]) => {
        this.documentsList = this.prepareDocumentsList(documents);
      }, error => {
        this.displayMessage('error', error.error.message);
      });
  }

  public prepareDocumentsList(documents): SearchDocumentResponse[] {
    return documents.map(item => ({
      ...item,
      searchLabel: `${item.runpleId} ${get(item, 'partner.name')} ${item.amount} ${item.externalNumber || ''}`
    }));
  }

  public linkDocument(): void {
    if (this.linkDocumentRequest$.getValue()) { return; }
    this.linkDocumentRequest$.next(true);

    this.scheduledPaymentApiService.scheduledPaymentLinkPeriod(
      this.data.scheduledPaymentId,
      this.data.billingPeriod.periodId,
      this.selectedDocument.id,
      this.data.documentType as any
    )
      .pipe(
        finalize(() => this.linkDocumentRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(this.subscriberHandler);
  }

  public subscriberHandler = (response: PaymentModel) => {
    this.dialogRef.close(response);
  }

}
