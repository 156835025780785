import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { InfoItemModel } from 'common/src/modules/ui-components/info-components/models/info-item.model';
import {
  getPrimeCostDataInfoHelper,
  getPurchaseDataInfoHelper,
  getSalesDataInfoHelper,
  getStockStatusDataInfoHelper
} from './get-prices-info-helpers';
import { PricesInfoModel } from '../../models/prices-info.model';
import { TradePricesApiService } from '../../services/trade-prices-api.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';

@Component({
  selector: 'rnpl-prices-table-total-info',
  templateUrl: './prices-table-total-info.component.html',
  styleUrls: ['./prices-table-total-info.component.scss'],
})
export class PricesTableTotalInfoComponent implements OnChanges {
  @Input() public productId: number;
  @Input() salesPriceListName: string;

  public purchaseDataInfo: InfoItemModel[];
  public salesDataInfo: InfoItemModel[];
  public stockStatusDataInfo: InfoItemModel[];
  public primeConstDataInfo: InfoItemModel[];

  public readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly productInfo$: BehaviorSubject<PricesInfoModel> = new BehaviorSubject<PricesInfoModel>(null);

  constructor(
    private pricesApiService: TradePricesApiService,
    private toasterService: ToasterService,
  ) {}

  ngOnChanges(changes:SimpleChanges) {
    if (changes.hasOwnProperty('productId') || changes.hasOwnProperty('salesPriceListName')) {
      this.getProductSalesInfo();
    }
  }

  public getProductSalesInfo(): void {
    if (!this.productId) { return; }
    this.isLoading$.next(true);

    this.pricesApiService.getProductSalesInfo(this.productId, this.salesPriceListName)
      .subscribe(response => {
        this.productInfo$.next(response);
        this.updateInfoItems();
        this.isLoading$.next(false);
      }, error => {
        this.updateInfoItems();
        this.showMsg('error', error.error.message);
        this.isLoading$.next(false);
      });
  }

  private showMsg(type: string, message: string): void {
    this.toasterService.notify({type, message});
  }

  public updateInfoItems(): void {
    if (!!this.productInfo) {
      this.purchaseDataInfo = getPurchaseDataInfoHelper(this.productInfo);
      this.salesDataInfo = getSalesDataInfoHelper(this.productInfo);
      this.stockStatusDataInfo = getStockStatusDataInfoHelper(this.productInfo);
      this.primeConstDataInfo = getPrimeCostDataInfoHelper(this.productInfo);
    }
  }

  public get productInfo() {
    return this.productInfo$.getValue();
  }

  public get isGoodsProductInfo(): boolean {
    if (!this.productInfo) { return  false ;}
    return this.productInfo.runple_id.includes('GDS');
  }

  public get isServicesProductInfo(): boolean {
    if (!this.productInfo) { return  false ;}
    return this.productInfo.runple_id.includes('SRV');
  }

  public get isDigitalProductInfo(): boolean {
    if (!this.productInfo) { return  false ;}
    return this.productInfo.runple_id.includes('DGT');
  }
}
