import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import { AddressModel, ApiResponse } from 'common/src/models';

import {
  CompanyInfoModel,
  CorporatePartnerModel,
  PartnerContactModel,
  PartnerVATDataModel,
  PaymentProfileModel,
  CEOInfoModel, } from './models';
import { ResponseList, ResponseModel } from '../shared/models/response';
import { FilterModel } from '../warehouse/models/filter.model';
import { map } from 'rxjs/operators';
import { DEFAULT_SORT_DIRECTION } from '../shared/constants';


@Injectable()
export class CrmService {

  constructor(private http: HttpClient) {
  }

  public getPartners(
    filters: FilterModel = {nameColumn: 'status', value: 'active'},
    page = '1',
    per_page = '1000',
    sort: FilterModel = {nameColumn: 'updated_at', value: DEFAULT_SORT_DIRECTION},
    ): Observable<ResponseList<CorporatePartnerModel>> {
    return this.http.get<ResponseList<CorporatePartnerModel>>(
      '/companies?type=corporation',
      {
        params: {
          page,
          per_page,
          [`filters[${filters.nameColumn}]`]: filters.value,
          [`sort[${sort.nameColumn}]`]: sort.value
        },
      }
    );
  }

  public getPartnersCounters(): Observable<any> {
    return this.http.get('/companies/counters').pipe(map((response: any) => response.data));
  }

  public changePartnersStatus(params: {ids: number[], status: string}): Observable<any> {
    return this.http.request('patch', `/companies/status`, {body: params});
  }

  getPartner(id: string|number): Observable<ApiResponse<CorporatePartnerModel>> {
    return this.http.get<ApiResponse<CorporatePartnerModel>>(`/companies/${id}`);
  }

  createPartner(partner: CorporatePartnerModel): Observable<CorporatePartnerModel> {
    return this.http.post<ApiResponse<CorporatePartnerModel>>('/companies/corporation/signup', partner)
      .pipe(map((response: ApiResponse<CorporatePartnerModel>) => response.data));
  }

  updatePartner(partner: CorporatePartnerModel): Observable<ApiResponse<CompanyInfoModel>> {
    return this.http.put<ApiResponse<CompanyInfoModel>>(`/companies/${partner.company_profile.id}`, partner);
  }

  deletePartner(partnerId: number): Observable<ApiResponse<null>> {
    return this.http.request<ApiResponse<any>>('delete', `/companies/${partnerId}`);
  }

  deletePartners(ids: (string|number)[]): Observable<ApiResponse<null>> {
    return this.http.request<ApiResponse<any>>('delete', `/companies`, {body: { ids }});
  }

  updateCEO(companyId, ceo: CEOInfoModel): Observable<ApiResponse<CompanyInfoModel>> {
    return this.http.put<ApiResponse<CompanyInfoModel>>(`/companies/${companyId}/ceo`, {ceo});
  }

  getPartnerContacts(companyId: string): Observable<ApiResponse<PartnerContactModel[]>> {
    return this.http.get<ApiResponse<any[]>>(`/companies/${companyId}/contacts`);
  }

  createPartnerContact(companyId: string, contact: PartnerContactModel): Observable<ApiResponse<PartnerContactModel>> {
    return this.http.post<ApiResponse<any>>(`/companies/${companyId}/contacts`, {contact});
  }

  updatePartnerContact(companyId: string, contact: PartnerContactModel): Observable<ApiResponse<PartnerContactModel>> {
    return this.http.put<ApiResponse<any>>(`/companies/${companyId}/contacts/${contact.id}`, {contact});
  }

  deletePartnerContact(companyId: string, contactId: string): Observable<ApiResponse<PartnerContactModel>> {
    return this.http.delete<ApiResponse<any>>(`/companies/${companyId}/contacts/${contactId}`);
  }

  updatePartnerWarehouse(companyId: string, warehouse_profiles: any): Observable<ApiResponse<CompanyInfoModel>> {
    return this.http.put<ApiResponse<any>>(`/companies/${companyId}/warehouse-profiles`, {warehouse_profiles});
  }

  corporatePartnerSetEdit(id: string): Observable<ApiResponse<CompanyInfoModel>> {
    return this.http.request<ApiResponse<CompanyInfoModel>>('put', `/companies/${id}/locking`);
  }

  corporatePartnerUnsetEdit(id: string): Observable<ApiResponse<CompanyInfoModel>> {
    return this.http.request<ApiResponse<CompanyInfoModel>>('put', `/companies/${id}/unlocking`);
  }

  updatePartnerAccounting(
    companyId: string,
    accounting: PaymentProfileModel
  ): Observable<ApiResponse<PaymentProfileModel>> {
    return this.http.put<ApiResponse<any>>(`/companies/${companyId}/payment-profile`, accounting);
  }

  validateVatNumber(vat: string, country_code: string): Observable<ApiResponse<PartnerVATDataModel>> {
    const params = new HttpParams({fromObject: {vat: vat, country_code: country_code}});

    return this.http.get<ApiResponse<PartnerVATDataModel>>(`/integrations/taxud/company-info/by-vat-number`, {params: params});
  }

  getPartnerWarehouseAddress(partnerId: number|string): Observable<AddressModel> {
    return this.http.get<ResponseModel<AddressModel>>(`/companies/${partnerId}/warehouse-address`)
      .pipe(map((response: any) => response.data));
  }
}
