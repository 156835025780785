import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { AvailableAssignsModel, AvailableAssignsPartnerModel } from '../link-document-modal/model/available-assigns.model';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';

@Component({
  selector: 'rnpl-add-document-modal',
  templateUrl: './add-document-modal.component.html',
  styleUrls: ['./add-document-modal.component.scss']
})
export class AddDocumentModalComponent extends BaseModalComponent implements OnInit {

  public companyProfile: CompanyProfile;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;
  public selectedDocument: AvailableAssignsModel = null;
  public availableDocumentsList: AvailableAssignsModel[] = [];
  readonly currenciesSymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;

  public customSearchFn = CustomSearchFn;

  constructor(
    private readonly store: Store<AppState>,
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<AddDocumentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      availableDocumentsList: AvailableAssignsModel[];
      bulkPartnersLinking: boolean;
      alreadySelectedPartner: AvailableAssignsPartnerModel;
      paymentCurrency: string;
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this._destroy)
      ).subscribe((profile: CompanyProfile) => {
        this.companyProfile = profile;
      });
  }

  ngOnInit() {
    this.availableDocumentsList = this.data.availableDocumentsList.filter(itm => itm.currency === this.data.paymentCurrency);
  }

  public addDocument(): void {
    if (!this.selectedDocument) {
      this.toasterService.notify({type: 'error', message: 'Select document'});
      return;
    }

    if (!this.data.bulkPartnersLinking && this.data.alreadySelectedPartner) { // validate different partners
      if (
        this.selectedDocument.partner.id === this.data.alreadySelectedPartner.id &&
        this.selectedDocument.partner.type === this.data.alreadySelectedPartner.type
      ) {
        if ( this.data.alreadySelectedPartner.type === 'general') {
          if (this.data.alreadySelectedPartner.name.toLowerCase() === this.selectedDocument.partner.name.toLowerCase()) {
            this.dialogRef.close(this.selectedDocument); // if partners equals - add document
          } else {
            this.toasterService.notify({type: 'error', message: 'GL_ACCOUNTS.DIFF_PARTNERS_NAMES_ERROR'});
          }
          return;
        }
        this.dialogRef.close(this.selectedDocument); // if partners equals - add document
        return;
      }
      // if partners not equals - show error toast
      this.toasterService.notify({type: 'error', message: 'GL_ACCOUNTS.DIFF_PARTNERS_ERROR'});
      return;
    }

    this.dialogRef.close(this.selectedDocument);
  }

}
