import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { OinTypeEnum, OutgoingInvoiceListTabsEnum } from '../../enums';
import { ButtonDropdownActionsModel, CustomerTypeEnum, GeneralPartnerTypeEnum, NavBarBtnModel, UIStatesEnum } from 'common/src/models';
import { OutgoingInvoiceModel } from '../../models';
import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';
import { getInfoErrorModalData } from '../../../incoming-invoice/pages/incoming-invoice/incoming-invoice.config';
import { CreateDocumentStepsModel, DocumentToDoListModel, LinkedDocumentsModel } from 'common/src/models/view-document-list.model';
import { DocumentPreviewTabsEnum } from 'common/src/models/document-preview-tabs.enum';
import { DocumentTypeEnum } from 'common/src/modules/ui-components/document-steps/document-step.model';
import { PartnersTypeEnum } from '../../../partners/corporate/enums';
import { CurrencyFormat } from 'common/src/modules/rnpl-common/pipes/currency-format/currency-format.pipe';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

export const ICON_BY_STATUS = {
  'OPEN': 'inbox',
  'open': 'inbox',
  'COMPLETED': 'checkbox-on',
  'ACCEPTED': 'checkbox-on',
  'completed': 'checkbox-on',
  'PAID': 'checkbox-on',
  'paid': 'checkbox-on',
  'BOOKED': 'checkbox-on',
  'booked': 'checkbox-on',
  'accepted': 'checkbox-on',
  'shipped': 'car',
  'CANCELED': 'close-square',
  'DELETED': 'trash-2',
  'draft': 'file',
  'DRAFT': 'file',
  'packing': 'open-box',
  'pending': 'clock',
  'PENDING': 'change',
  'sent': 'email',
  'packed_up': 'box',
  'canceled': 'close-square',
  'received': 'goods-receipt'
};

export const ICON_COLOR_BY_STATUS = {
  'OPEN': 'blue-400',
  'open': 'blue-400',
  'sent': 'blue-400',
  'packed_up': 'blue-400',
  'COMPLETED': 'green-500',
  'ACCEPTED': 'green-500',
  'completed': 'green-500',
  'PAID': 'green-500',
  'paid': 'green-500',
  'BOOKED': 'green-500',
  'booked': 'green-500',
  'accepted': 'green-500',
  'shipped': 'green-500',
  'CANCELED': 'red-600',
  'canceled': 'red-600',
  'DELETED': 'main-200',
  'pending': 'main-200',
  'PENDING': 'main-200',
  'draft': 'main-200',
  'DRAFT': 'main-200',
  'packing': 'blue-400',
  'received': 'blue-400',
  'warehoused': 'green-500'
};


export function getButtonsByStateAndStatus(
  state: UIStatesEnum,
  invoice: OutgoingInvoiceModel,
  hasDeal: boolean = false,
  hasPayments: boolean = false,
  hasLinkedSoSbcEcoEra: boolean = true,
  wholesalesEnabled = false,
  isEqualized: boolean = false,
): NavBarBtnModel[] {
  const prepareNavBurButtons = getNavBarButtons(
    invoice,
    hasDeal,
    hasPayments,
    invoice.forced,
    wholesalesEnabled,
    !!invoice.runpleId,
    isEqualized,
  );

  prepareNavBurButtons[0].actions = prepareNavBurButtons[0].actions.filter((btn: ButtonDropdownActionsModel) => {
    if (btn.hasOwnProperty('showWithLinkedDocument')) {
      return (btn.showWithLinkedDocument && hasLinkedSoSbcEcoEra) ||
        (!btn.showWithLinkedDocument && !hasLinkedSoSbcEcoEra);
    }
    return true;
  });

  return prepareNavBurButtons
    .filter((btn: NavBarBtnModel) => {
      return btn.other.includes(state);
    })
    .filter((column: NavBarBtnModel) => {
      return !column.hideWithStatus || !column.hideWithStatus.includes(invoice.status);
    })
    .filter((btn: NavBarBtnModel) => {
      if (btn.hasOwnProperty('showWithLinkedDocument')) {
        return (btn.showWithLinkedDocument && hasLinkedSoSbcEcoEra) ||
          (!btn.showWithLinkedDocument && !hasLinkedSoSbcEcoEra);
      }
      return true;
    })
    .filter((btn: NavBarBtnModel) => !btn.hasOwnProperty('showCondition') || btn.showCondition);
}

export function getViewDocumentTabs(
  invoice: OutgoingInvoiceModel,
  showLinkedDoc: boolean,
  linkedDocumentsCount: number
): any[] {
  const showLinkedDocuments: boolean = showLinkedDoc && (
    invoice.status === OutgoingInvoiceListTabsEnum.DELETED && !!invoice.linkedDocuments.length ||
    invoice.status === OutgoingInvoiceListTabsEnum.CANCELED && !!invoice.linkedDocuments.length ||
    (
      invoice.status !== OutgoingInvoiceListTabsEnum.CANCELED &&
      invoice.status !== OutgoingInvoiceListTabsEnum.DELETED &&
      invoice.status !== OutgoingInvoiceListTabsEnum.TEMPLATE
    )
  );
  const viewDocumentsTabs = [
    {
      label: 'APP.OUTGOING_INVOICE',
      tabName: DocumentPreviewTabsEnum.DOCUMENT,
      link: `${DocumentPreviewTabsEnum.DOCUMENT}`,
      icon: 'file-text',
    },
    {
      label: 'DOC_BLOCK.PROPERTIES',
      tabName: DocumentPreviewTabsEnum.PROPERTIES,
      link: `${DocumentPreviewTabsEnum.PROPERTIES}`,
      icon: 'options-2',
    },
    {
      label: 'TAB.LOG',
      tabName: DocumentPreviewTabsEnum.LOG,
      link: `${DocumentPreviewTabsEnum.LOG}`,
      icon: 'clipboard',
    },
    {
      label: 'TABLE_SUMMARY_BAR.DOCUMENTS',
      tabName: DocumentPreviewTabsEnum.DOCUMENTS,
      link: `${DocumentPreviewTabsEnum.DOCUMENTS}`,
      icon: 'link',
      count: linkedDocumentsCount,
      showCondition: showLinkedDocuments
    },
    {
      label: 'MODAL.REMARK',
      tabName: DocumentPreviewTabsEnum.REMARK,
      link: `${DocumentPreviewTabsEnum.REMARK}`,
      icon: 'message-square',
    },
  ];

  return viewDocumentsTabs.filter(t => !t.hasOwnProperty('showCondition') || t.showCondition);
}

export function getNavBarButtons(
  invoice: OutgoingInvoiceModel = null,
  hasDeal: boolean = false,
  hasPayments: boolean = false,
  isForced: boolean = false,
  wholesalesEnabled: boolean = false,
  hasRID: boolean = false,
  isEqualized: boolean = false,
): NavBarBtnModel[] {
  const isFinalInvoice = invoice && invoice.type === OinTypeEnum.FINAL;
  const isPrepaymentInvoice = invoice && invoice.type === OinTypeEnum.PREPAYMENT;
  const hideCreateCRNButton = invoice
    && (
      (isFinalInvoice && invoice.status === OutgoingInvoiceListTabsEnum.OPEN)
      || (isPrepaymentInvoice && invoice.status === OutgoingInvoiceListTabsEnum.OPEN)
      || (isFinalInvoice && !(invoice.linkedDocuments.filter(doc => doc.printableType === DocumentTypesUppercaseEnum.IPB).length))
    );

  const buttons: NavBarBtnModel[] = [
    {
      classes: 'btn-white',
      text: 'BUTTON.MORE_ACTIONS',
      iconName: 'menu',
      disabled: false,
      // allowLoadingSpinner: true,
      disabledMainAction: true,
      withActions: true,
      spinnerColor: '#5E6681',
      multiple: false,
      dropdownClass: 'inner-blue-400',
      width: 141,
      isMoreAction: true,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.TEMPLATE
      ],
      actions: [
        {
          actionTitle: 'BUTTON.DELETE',
          actionName: 'onDeleteInvoiceClick',
          actionIcon: 'trash-2',
          disabled: hasDeal,
          classes: 'btn-white red',
          greyIcon: true,
          showCondition: !hasRID,
          hideWithStatus: [
            // OutgoingInvoiceListTabsEnum.DRAFT,
            OutgoingInvoiceListTabsEnum.DELETED,
            OutgoingInvoiceListTabsEnum.CANCELED,
            OutgoingInvoiceListTabsEnum.OPEN,
            OutgoingInvoiceListTabsEnum.PAID,
          ],
        },
        {
          actionTitle: 'BUTTON.CANCEL_ENTITY',
          actionName: 'onCancelInvoiceClick',
          actionIcon: 'close-square',
          showCondition: hasRID,
          disabled: hasDeal,
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.DELETED,
            OutgoingInvoiceListTabsEnum.PAID,
            OutgoingInvoiceListTabsEnum.CANCELED,
            // OutgoingInvoiceListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'BUTTON.LINK_SALES_ORDER',
          actionName: 'linkDocument',
          actionIcon: 'link',
          disabled: hasDeal,
          showWithLinkedDocument: false,
          showCondition: wholesalesEnabled,
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.DELETED,
            OutgoingInvoiceListTabsEnum.CANCELED,
            OutgoingInvoiceListTabsEnum.PAID,
          ],
        },
        {
          actionTitle: 'BUTTON.CLONE',
          actionName: 'onCloneOutgoingInvoiceClick',
          actionIcon: 'copy',
          disabled: hasDeal,
          showCondition: !isFinalInvoice && !isPrepaymentInvoice,
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.DELETED,
            OutgoingInvoiceListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'BUTTON.DELETE_PERMANENTLY',
          actionName: 'onDeletePermanentlyInvoiceClick',
          actionIcon: 'fire',
          disabled: hasDeal,
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.OPEN,
            OutgoingInvoiceListTabsEnum.PAID,
            OutgoingInvoiceListTabsEnum.CANCELED,
            OutgoingInvoiceListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'BUTTON.REOPEN',
          actionName: 'onRestoreInvoiceClick',
          actionIcon: 'undo',
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.PAID,
            OutgoingInvoiceListTabsEnum.OPEN,
            OutgoingInvoiceListTabsEnum.DRAFT,
          ],
        },
        {
          actionTitle: 'DOCUMENTS_TEMPLATES.SAVE_AS_TEMPLATE',
          actionName: 'saveAsTemplate',
          showCondition: !isFinalInvoice && !isPrepaymentInvoice,
          actionIcon: 'file',
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.CANCELED,
            OutgoingInvoiceListTabsEnum.DELETED,
          ],
        },
        {
          actionTitle: 'BUTTON.CREATE_CREDIT_NOTE',
          actionName: 'createCreditNote',
          actionIcon: 'credit-note',
          classes: 'btn-white',
          hideWithStatus: [
            OutgoingInvoiceListTabsEnum.CANCELED,
            OutgoingInvoiceListTabsEnum.DELETED,
            OutgoingInvoiceListTabsEnum.DRAFT,
          ],
          hideAction: isEqualized || hideCreateCRNButton
        },
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-white',
      text: 'DOCUMENTS_TEMPLATES.SAVE_AS_TEMPLATE',
      iconName: 'file',
      disabled: false,
      actionName: 'saveAsTemplate',
      showCondition: !isFinalInvoice && !isPrepaymentInvoice,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      other: [UIStatesEnum.EDIT]
    },
    {
      classes: 'btn-white',
      text: 'DOCUMENTS_TEMPLATES.USE_TEMPLATE',
      iconName: 'copy-from',
      disabled: false,
      disabledMainAction: false,
      withActions: true,
      multiple: false,
      actionName: 'useTemplate',
      dropdownClass: 'inner-blue-400',
      spinnerColor: '#5E6681',
      width: 141,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      actions: [
        {
          actionTitle: 'DOCUMENTS_TEMPLATES.USE_TEMPLATE',
          actionName: 'useTemplate',
          actionIcon: 'copy-from',
        },
        {
          actionTitle: 'DOCUMENTS_TEMPLATES.SAVE_AS_TEMPLATE',
          actionName: 'saveAsTemplate',
          actionIcon: 'file',
          showCondition: !isFinalInvoice && !isPrepaymentInvoice,
        },
      ],
      other: [UIStatesEnum.CREATE, UIStatesEnum.EDIT]
    },
    {
      classes: 'btn-white red',
      multipleClasses: 'btn-white red',
      text: 'BUTTON.DELETE',
      multipleText: 'BUTTON.DELETE',
      badgeClass: 'rnpl-badge--red',
      iconName: 'trash-2',
      actionName: 'onDeleteTemplateClick',
      allowLoadingSpinner: true,
      multiple: true,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'DOC_SUMMARY.CREATE',
      iconName: 'plus-square',
      hintsId: 'createBtn',
      disabled: false,
      multiple: false,
      allowLoadingSpinner: true,
      actionName: 'onProceedInvoiceClick',
      dropdownClass: 'inner-blue-400',
      width: 144,
      actions: [
        {
          actionTitle: 'DOC_SUMMARY.CREATE',
          actionName: 'onProceedInvoiceClick',
          actionIcon: 'plus-square'
        },
        {
          actionTitle: 'BUTTON.SAVE_AS_DRAFT',
          actionName: 'onCreateInvoiceClick',
          actionIcon: 'save'
        },

      ],
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
      ],
      other: [UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.EDIT',
      hintsId: 'editBtn',
      iconName: 'edit',
      disabled: hasDeal,
      actionName: 'onEditInvoiceClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-white red',
      text: 'BUTTON.DELETE_PERMANENTLY',
      multipleText: 'BUTTON.DELETE_PERMANENTLY',
      badgeClass: 'rnpl-badge--red',
      iconName: 'fire',
      actionName: 'onDeletePermanentlyInvoiceClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      disabled: hasDeal,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      other: [UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-dark',
      multipleClasses: 'btn-white',
      multipleText: 'BUTTON.CANCEL_ENTITY',
      iconName: 'bulb-off',
      disabled: hasDeal,
      actionName: 'onCancelInvoiceClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      other: [UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-dark',
      multipleClasses: 'btn-primary',
      multipleText: 'BUTTON.REOPEN',
      iconName: 'undo',
      disabled: false,
      actionName: 'onRestoreInvoiceClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      other: [UIStatesEnum.EDIT, UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.EXPORT',
      iconName: 'share',
      hintsId: 'exportBtn',
      disabled: false,
      multiple: false,
      allowLoadingSpinner: true,
      actionName: 'onExportPdfClick',
      spinnerColor: '#5E6681',
      dropdownClass: 'inner-blue-400',
      width: 144,
      actions: [
        {
          actionTitle: 'BUTTON.EXPORT_PDF',
          actionName: 'onExportPdfClick',
          actionIcon: 'pdf'
        },
        {
          actionTitle: 'BUTTON.SEND_EMAIL',
          actionName: 'onSendEmailClick',
          actionIcon: 'email',
        }
      ],
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE',
      iconName: 'plus-square',
      disabled: false,
      actionName: 'onProceedInvoiceClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DELETED,
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.TEMPLATE,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.DONE',
      iconName: 'checkmark',
      disabled: hasDeal,
      actionName: 'onCloseEditInvoiceClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.EDIT]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE_INVOICE',
      iconName: 'plus-square',
      actionName: 'createDocumentFromTemplate',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
  ];

  if (hasPayments) {
    const forceCompleteBtn: ButtonDropdownActionsModel = {
      actionTitle: 'BUTTON.FORCE_COMPLETE',
      actionName: 'onForceCompleteClick',
      actionIcon: 'checkbox-on',
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.PAID,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
      ],
    };

    buttons[0].actions.splice(5, 0, forceCompleteBtn);
  }

  if ((isForced && !isFinalInvoice) || (isForced && isFinalInvoice && (Number(invoice.totals.finalInvoiceAmount) !== 0))) {
    const forceCompleteBtn: ButtonDropdownActionsModel = {
      actionTitle: 'BUTTON.WITHDRAW_COMPLETION',
      actionIcon: 'close-square',
      actionName: 'onWithdrawCompletionInvoiceClick',
      classes: 'btn-white',
      hideWithStatus: [
        OutgoingInvoiceListTabsEnum.DRAFT,
        OutgoingInvoiceListTabsEnum.OPEN,
        OutgoingInvoiceListTabsEnum.CANCELED,
        OutgoingInvoiceListTabsEnum.DELETED,
      ],
    };

    buttons[0].actions.splice(5, 0, forceCompleteBtn);
  }

  return buttons;
}

export function getCreateDocumentSteps(
  outgoingInvoice: OutgoingInvoiceModel,
  positionsBlockValid: boolean,
  billingInfoBlockValid: boolean,
  isReadOnly: boolean,
  isFinalInvoice: boolean,
): CreateDocumentStepsModel[] {
  return [
    {
      title: 'FORM.SELECT_CUSTOMER',
      action: 'selectCustomer',
      valid: outgoingInvoice.partnerType === CustomerTypeEnum.GENERAL
        ? (!!outgoingInvoice.customerId && !!outgoingInvoice.partnerName)
        : !!outgoingInvoice.customerId
    },
    {
      title: 'DOC_SUMMARY.ADD_POSITIONS',
      action: !isReadOnly && !isFinalInvoice
        ? 'displayAddPositionModal'
        : null,
      valid: positionsBlockValid,
      disabled: isReadOnly || isFinalInvoice,
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_BILLING_DETAILS',
      action: 'displayBillingInfoModal',
      valid: billingInfoBlockValid
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_PROPERTIES',
      link: DocumentPreviewTabsEnum.PROPERTIES,
      valid: !!outgoingInvoice.issueDate,
      hide: outgoingInvoice.status === OutgoingInvoiceListTabsEnum.DRAFT || outgoingInvoice.status === OutgoingInvoiceListTabsEnum.TEMPLATE
    },
  ];
}

export function getDocumentToDoList(
  sendEmail: boolean,
  checkVat: boolean,
  invoice: OutgoingInvoiceModel,
  linkedSo: LinkedDocumentsModel[],
  linkedSbc: LinkedDocumentsModel[],
  linkedPayments: LinkedDocumentsModel[],
  linkedEco: LinkedDocumentsModel[],
  linkedOfr: LinkedDocumentsModel[],
  linkedEra: LinkedDocumentsModel[],
  linkedInvoices: LinkedDocumentsModel[],
  overdueNotice: LinkedDocumentsModel[],
  isReadOnly: boolean,
  hideSendNoticeAction: boolean,
  hideOverdueNoticeList: boolean,
  isEqualized: boolean,
  linkedCrn: LinkedDocumentsModel[],
  isFreePlan: boolean,
): DocumentToDoListModel[] {
  return [
    {
      title: 'DOC_SUMMARY.RECEIVE_PAYMENT',
      valid: invoice.status === OutgoingInvoiceListTabsEnum.PAID,
      hide: invoice.status === OutgoingInvoiceListTabsEnum.DRAFT || invoice.status === OutgoingInvoiceListTabsEnum.PAID,
      hideAdditionalInfoList: invoice.status !== OutgoingInvoiceListTabsEnum.OPEN,
      hideAdditionalLinkedDocumentsList: hideOverdueNoticeList,
      hideActionList: !isReadOnly && (invoice.status !== OutgoingInvoiceListTabsEnum.PAID || isEqualized),
      isOverdue: !hideSendNoticeAction,
      linkedDocuments: [
        ...linkedPayments,
      ],
      actions: [
        {
          text: 'DOC_SUMMARY.ALLOCATE_PAYMENT',
          iconName: 'payment',
          actionName: 'allocatePayment',
          classes: 'btn-primary',
          multiple: true,
          hide: isFreePlan,
          actions: [
            {
              actionTitle: 'DOC_SUMMARY.ALLOCATE_PAYMENT',
              actionIcon: 'payment',
              actionName: 'allocatePayment',
            },
            {
              actionTitle: 'BUTTON.BOOK_PAYMENT',
              actionIcon: 'payment',
              actionName: 'bookPayment',
            }
          ]
        },
        {
          title: 'BUTTON.BOOK_PAYMENT',
          icon: 'payment',
          action: 'bookPayment',
          btnClass: 'primary',
          hide: invoice.status !== OutgoingInvoiceListTabsEnum.OPEN || !isReadOnly || !isFreePlan
        },
      ],
      additionalInfoList: [
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'COMMON.DUE_DATE',
          statusLineType: 'date',
          value: get(invoice, 'issueDate', null),
          expectedDate: get(invoice, 'billingData.dueDate', null),
          colorState: !hideSendNoticeAction ? 'grey-400' : null,
        },
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'COLUMN.OPEN_AMOUNT',
          statusLineType: 'payments-euro-val-only',
          value: get(invoice, 'totals.openAmount', null),
          maxValue: get(invoice, 'totals.finalInvoiceAmount', null),
          colorState: 'main-600'
        },
      ],
      additionalActions: [
        {
          title: 'OVERDUE_NOTICE.SEND_NOTICE',
          icon: 'overdue',
          action: 'onSendNoticeClick',
          btnClass: 'grey',
          hide: hideSendNoticeAction || !isReadOnly,
        },
      ],
      additionalLinkedDocuments: [
        ...overdueNotice
      ]
    },
    {
      title: 'DOC_SUMMARY.SEND_INVOICE_TO_CUSTOMER',
      valid: sendEmail,
      hideActionList: sendEmail || !isReadOnly,
      hide: invoice.status === OutgoingInvoiceListTabsEnum.PAID,
      actions: [
        {
          title: 'DOC_SUMMARY.SEND_INVOICE',
          icon: 'email',
          action: 'onSendEmailClick',
          btnClass: 'primary'
        },
      ],
    },
    {
      vatCheckType: true,
      vatStatus: get(invoice, 'vatStatus'),
      hide: ((get(invoice, 'customer.noVat') || !get(invoice, 'customer.euMember'))
        && (get(invoice, 'customer.type') !== PartnersTypeEnum.GENERAL || !get(invoice, 'partnerVat'))
        || (
          get(invoice, 'customer.type') === PartnersTypeEnum.GENERAL &&
          get(invoice, 'customer.generalType') === GeneralPartnerTypeEnum.NON_EU
        )) || invoice.status === OutgoingInvoiceListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_PAYMENTS',
      linkedDocumentsType: true,
      linkedDocuments: linkedPayments,
      icon: 'payment',
      hide: !linkedPayments.length || invoice.status !== OutgoingInvoiceListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_CRN',
      linkedDocumentsType: true,
      linkedDocuments: linkedCrn,
      icon: 'credit-note',
      hide: !linkedCrn.length || invoice.status !== OutgoingInvoiceListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_SALES_ORDER',
      linkedDocumentsType: true,
      linkedDocuments: linkedSo,
      hide: !linkedSo.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_SUBSCRIPTION',
      linkedDocumentsType: true,
      linkedDocuments: linkedSbc,
      hide: !linkedSbc.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_ECO',
      linkedDocumentsType: true,
      linkedDocuments: linkedEco,
      hide: !linkedEco.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_OFFER',
      linkedDocumentsType: true,
      linkedDocuments: linkedOfr,
      hide: !linkedOfr.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_ERA',
      linkedDocumentsType: true,
      linkedDocuments: linkedEra,
      hide: !linkedEra.length
    },
    {
      title: 'DOC_SUMMARY.LINKED_INVOICES',
      linkedDocumentsType: true,
      linkedDocuments: linkedInvoices,
      hide: !linkedInvoices.length
    },
  ];
}

export const CreateOrConfirmModalData: CommonModalDataModel = {
  title: 'OIN.CREATE_INVOICE',
  message: 'OIN.CREATE_INVOICE_MESSAGE',
  isRejectButton: true,
  rejectBtnText: 'BUTTON.DELETE',
  confirmBtnText: 'BUTTON.SAVE',
  confirmBtnIcon: 'plus-square',
  titleIcon: 'question-mark-circle',
  titleColor: 'main-100',

};

export function getForceCompleteModalData(invoice: OutgoingInvoiceModel, translateService: TranslateService, currencyFormat: CurrencyFormat) {
  const openAmount = +invoice.totals.openAmount;
  const totalAmount = +invoice.totals.totalAmount;
  const onePercentOfTotalAmount = totalAmount / 100;
  // limit is 50€ or 1%
  const isOpenAmountMoreThanLimit: boolean = openAmount > 50 ? true : openAmount > onePercentOfTotalAmount;
  const openAmountFormatted = currencyFormat.transform(openAmount, '',2,'.', ',', 3, true);

  let amountMsg = isOpenAmountMoreThanLimit
    ? translateService.instant('FORCE_PAID.MESSAGE_1', { openAmount: openAmountFormatted, currency: '€' })
    : translateService.instant('FORCE_PAID.TITLE', { openAmount: openAmountFormatted, currency: '€' });

  return {
    title: 'BUTTON.FORCE_COMPLETE',
    message: `${amountMsg} ${translateService.instant('FORCE_PAID.MESSAGE_2')}`,
    confirmBtnText: 'BUTTON.FORCE_COMPLETE',
    confirmBtnIcon: 'checkbox-on'
  };
}

export function getScontoDate(issueDate: Date, period: number): Date {
  let scontoDate: Date = new Date(issueDate);

  if (!issueDate || !period) { return null; }

  return new Date(scontoDate.setDate(scontoDate.getDate() + period));
}

export const DeliveryServiceMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_COST',
  'MODAL.MISMATCHED_COST_INVOICE_MESSAGE'
);

export const PositionQuantityMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_QUANTITY',
  'OIN.MODAL.MISMATCH_POSITION_QUANTITY_MESSAGE'
);

export const EraQuantityMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_QUANTITY',
  'OIN.MODAL.MISMATCH_ERA_QUANTITY_MESSAGE'
);
