import { Pipe, PipeTransform } from '@angular/core';
import { CustomerTypeEnum, GeneralPartnerTypeEnum } from '../../../models';

@Pipe({name: 'partnerTypeIcon'})
export class PartnerTypeIconPipe implements PipeTransform {
  transform(partnerType: CustomerTypeEnum, generalPartnerType?: GeneralPartnerTypeEnum,  ...args: any[]): string {
    let iconName: string;

    if (generalPartnerType) {
      switch (generalPartnerType) {
        case GeneralPartnerTypeEnum.EU:
          iconName = 'eu';
          break;
        case GeneralPartnerTypeEnum.NON_EU:
          iconName = 'non-eu';
          break;
        default:
          iconName = 'factory';
          break;
      }

      return iconName;
    }

    switch (partnerType) {
      case CustomerTypeEnum.CORPORATE_PARTNER:
        iconName = 'factory';
        break;
      case CustomerTypeEnum.PRIVATE_PARTNER:
        iconName = 'person';
        break;
      case CustomerTypeEnum.NOT_SAVED:
      case CustomerTypeEnum.NOT_SAVED_PARTNER:
        iconName = 'question-mark-circle';
        break;
      default:
        iconName = 'factory';
        break;
    }

    return iconName;
  }
}
