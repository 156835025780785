export class WarehouseCreateModel {
  description: string;
  email: string;
  name: string;
  phone: string;
  address: {
    apartments: string;
    block: string;
    city: string;
    country_iso3: string
    floor: string;
    house_number: string;
    region: string;
    state: string;
    street: string;
    zip_code: string;
  };
  contact: {
    phone: string,
    email: string,
    fax?: string,
    day_and_night?: boolean,
    working_hours_start?: string,
    working_hours_end?: string,
    working_days_start?: string,
    working_days_end?: string
  };

  constructor(ext: any = {}) {
    Object.assign(this, {
      description: '',
      email: '',
      name: '',
      phone: '',
      address: {
        apartments: '',
        block: '',
        city: '',
        country_iso3: 'aut',
        floor: '',
        house_number: '',
        region: '',
        state: '',
        street: '',
        zip_code: '',
      },
      contact: {
        phone: '',
        email: ''
      }
    }, ext);
  }
}
