import { Component, OnDestroy, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { selectUserShortcuts } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { UserShortcutEnum } from 'projects/workspace/src/app/hrm/enums';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ShortcutsConfig, userAvailableShortcutsHelper } from './user-shortcuts.helper';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'rnpl-shortcuts-modal',
  templateUrl: './shortcuts-modal.component.html',
  styleUrls: ['./shortcuts-modal.component.scss']
})
export class ShortcutsModalComponent implements OnInit, OnDestroy {

  public userId: number;

  public addedShortcutsList = []; // todo model
  public availableShortcutsList = []; // todo model

  public scrolledIsBottom: boolean;
  public isScrolled: boolean;
  public scrolledIsBottomAddedList: boolean;
  public isScrolledAddedList: boolean;
  public hasAvailableShortcuts: boolean;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private store: Store<AppState>,
    private hrmService: HrmService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<ShortcutsModalComponent>,
  ) {
    this.userId = this.authService.getUser().id;
    this.store.select(selectUserShortcuts)
      .pipe(takeUntil(this.destroy$))
      .subscribe(shortcuts => {
        this.availableShortcutsList = userAvailableShortcutsHelper(shortcuts.available);
        this.addedShortcutsList = shortcuts.activated.map(itm => ShortcutsConfig()[itm]);
        this.checkEmptyAvailableShortcuts();
      });
  }

  ngOnInit() {}

  public drop(event: CdkDragDrop<any[]>, blockReorder = false) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (blockReorder) { // block possibility to reorder items between groups at Available shortcuts
        if (event.previousContainer.id === 'addedShortcutsDropList') { // add exception for items moved from Added to Available lists
          this.removeShortcut(event.previousContainer.data[event.previousIndex]); // move manually to prevent reordering
        }
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  public addShortcut = (item, group): void => { // need to use arrow func because of context
    const previousIndex = group.shortcuts.findIndex(shortcut => shortcut.key === item.key);

    transferArrayItem(
      group.shortcuts,
      this.addedShortcutsList,
      previousIndex,
      this.addedShortcutsList.length,
    );
  }

  public removeShortcut = (item): void => { // need to use arrow func because of context
    const previousIndex = this.addedShortcutsList.findIndex(shortcut => shortcut.key === item.key);
    const nextGroup = this.availableShortcutsList.find(shortcutGroup => shortcutGroup.group.includes(item.key));
    const nextIndex = nextGroup.group.findIndex((key => key === item.key));

    transferArrayItem(
      this.addedShortcutsList,
      nextGroup.shortcuts,
      previousIndex,
      nextIndex,
    );

    this.checkEmptyAvailableShortcuts();
  }

  public addAllShortcuts = (): void => { // need to use arrow func because of context
    this.availableShortcutsList.forEach(group => {
      [...group.shortcuts].forEach(shortcut => this.addShortcut(shortcut, group));
    });

    this.checkEmptyAvailableShortcuts();
  }

  public removeAllShortcuts = (): void => { // need to use arrow func because of context
    [...this.addedShortcutsList].forEach(shortcut => {
      this.removeShortcut(shortcut);
    });

    this.checkEmptyAvailableShortcuts();
  }

  public submitShortcuts(): void {
    const shortcuts: UserShortcutEnum[] = this.addedShortcutsList.map(i => i.key);
    this.hrmService.updateUserModulesShortcuts(this.userId, shortcuts)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close());
  }

  private checkEmptyAvailableShortcuts(): void {
    this.hasAvailableShortcuts = this.availableShortcutsList.some(itm => !!itm.shortcuts.length);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
