import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency-custom';

import { PositionAccountingSettingsComponent } from './position-accounting-settings.component';
import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';
import { GlAccountDropdownModule, GlProductTypeDropdownModule } from '../../../gl-accounts/components';


@NgModule({
  declarations: [
    PositionAccountingSettingsComponent
  ],
  exports: [
    PositionAccountingSettingsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ExpansionPanelModule,
    GlAccountDropdownModule,
    GlProductTypeDropdownModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgxCurrencyModule
  ],
})
export class PositionAccountingSettingsModule { }
