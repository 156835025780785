export enum DueWithinDateDaysEnum {
  USER_DEFINED = 'USER_DEFINED',
  DATE = 'DATE',
  DAYS_7 = 'DAYS_7',
  DAYS_14 = 'DAYS_14',
  DAYS_30 = 'DAYS_30',
  DAYS_60 = 'DAYS_60',
  DAYS_90 = 'DAYS_90',
  DAYS_1 = 'DAYS_1',
}
