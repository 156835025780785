import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { PaginationModel } from '../../../models';
import { DEFAULT_PAGINATION } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent implements OnInit, OnDestroy {

  public pageCountControl: FormControl = new FormControl();

  @Input() tablePagination: PaginationModel = DEFAULT_PAGINATION;
  @Input() disabled: boolean;
  @Input() darkGreyButton: boolean;
  @Output() public pageCountEmitter: EventEmitter<number> = new EventEmitter<number>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.pageCountControl.setValue(this.tablePagination.page);

    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((paramMap: ParamMap) => {
        const pageCount = +get(paramMap, 'params.page');
        this.pageCountControl.setValue(pageCount);
      })
  }

  public getPagesCount(): number {
    return Math.ceil(this.tablePagination.count / this.tablePagination.per_page);
  }

  public changePageCount(val): void {
    if (val <= 0) {
      this.pageCountControl.setValue(1);
      this.pageCountEmitter.emit(this.pageCountControl.value - 1);
      return;
    }

    if (val > this.getPagesCount()) {
      this.pageCountControl.setValue(this.getPagesCount());
      this.pageCountEmitter.emit(this.pageCountControl.value - 1);
      return;
    }

    this.pageCountControl.setValue(Math.round(val));
    this.pageCountEmitter.emit(this.pageCountControl.value - 1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
