import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-date-periods',
  templateUrl: './filters-control-date-periods.component.html',
})
export class FiltersControlDatePeriodsComponent extends FiltersControlBaseComponent implements OnChanges, OnDestroy  {

  @Input() controlDateFrom: AbstractControl;
  @Input() controlDateTo: AbstractControl;
  @Input() nextDates: boolean;
  @Input() lastDates: boolean;
  @Input() baseDropdownItems: {value: string, label: string}[];

  public prepareItemsList: {label: string, value: any, searchLabel?: string}[] = [];

  public filtersDatePeriods = [
    this.getFiltersDatePeriodItem('today', 'FORM.TODAY'),
    this.getFiltersDatePeriodItem('week', 'FORM.7_DAYS'),
    this.getFiltersDatePeriodItem('month', 'FORM.30_DAYS'),
    this.getFiltersDatePeriodItem('quarter', 'FORM.90_DAYS'),
    this.getFiltersDatePeriodItem('period', 'FORM.CUSTOM_PERIOD'),
  ];

  public filtersNextDatePeriods = [
    this.getFiltersDatePeriodItem('today', 'FORM.TODAY'),
    this.getFiltersDatePeriodItem('week', 'FORM.7_DAYS'),
    this.getFiltersDatePeriodItem('month', 'FORM.30_DAYS'),
    this.getFiltersDatePeriodItem('quarter', 'FORM.90_DAYS'),
    this.getFiltersDatePeriodItem('period', 'FORM.CUSTOM_PERIOD'),
  ];

  public filtersLastDatePeriods = [
    this.getFiltersDatePeriodItem('today', 'FORM.TODAY'),
    this.getFiltersDatePeriodItem('week', 'FORM.7_DAYS'),
    this.getFiltersDatePeriodItem('month', 'FORM.30_DAYS'),
    this.getFiltersDatePeriodItem('quarter', 'FORM.90_DAYS'),
    this.getFiltersDatePeriodItem('period', 'FORM.CUSTOM_PERIOD'),
  ];

  constructor(public translateService: TranslateService) {
    super(translateService);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('control') &&
      changes.hasOwnProperty('controlDateFrom') &&
      changes.hasOwnProperty('controlDateTo')
    ) {
      this.control.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => {
          if (!value || value !== 'period') {
           this.controlDateFrom.reset();
           this.controlDateTo.reset();
          }
      });
    }
  }

  get listItems(): {label: string, value: any, searchLabel?: string}[] {
    if (this.baseDropdownItems && this.baseDropdownItems.length) {
      return this.baseDropdownItems;
    }

    if (this.nextDates) {
      return this.filtersNextDatePeriods;
    }

    if (this.lastDates) {
      return this.filtersLastDatePeriods;
    }

    return this.filtersDatePeriods;
  }

  private getFiltersDatePeriodItem(value: string, label: string): {label: string, value: any, searchLabel?: string} {
    return {value: value, label: label, searchLabel: `${value} ${this.translateService.instant(label)}`};
  }

}
