import {HintsButtonsModel} from '../models/hints.model';

export const HintsIndexTimerText = 65;

export const HintsButtonGoItTemplate: HintsButtonsModel = {
  text: `<span class="hints-next-btn-progress show-animation"></span><span class="title">Got it</span><i class="icon icon-checkmark ml-40"></i>`,
  classes: 'btn btn-white yellow center',
  action: function() {
    return this.next();
  }
};

export const HintsButtonCancelTemplate: HintsButtonsModel = {
  text: 'Close <i class="icon icon-checkmark ml-40"></i>',
  classes: 'btn close-hints',
  action: function() {
    return this.cancel();
  }
};
