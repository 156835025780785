import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { get, isEqual } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { CustomerTypeEnum, DeliveryTypesEnum, UIStatesEnum } from '../../../../models';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { CountryModel } from '../../../rnpl-common';
import { selectSalesOrder, selectSalesOrderCurrentState } from 'projects/workspace/src/app/sales-order/store/selectors';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { ToasterService } from '../../../ui-components/toaster';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { SalesOrderFormsService } from 'projects/workspace/src/app/sales-order/services/sales-order-forms.service';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';
import { SalesOrderListTabsEnum } from 'projects/workspace/src/app/sales-order/enums';

@Component({
  selector: 'rnpl-sales-order-delivery-info-modal',
  templateUrl: './sales-order-delivery-info-modal.component.html',
})
export class SalesOrderDeliveryInfoModalComponent implements OnInit, OnDestroy {
  public countries: CountryModel[];
  public salesOrder: SalesOrderModel;
  public addressTypeList = [];
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public isReadonly: boolean = true;
  public readonly deliveryTypes = DeliveryTypesEnum;
  private convertAddressToString = convertAddressToStringHelper;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private salesOrderApiService: SalesOrderApiService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<SalesOrderDeliveryInfoModalComponent>,
    private salesOrderFormsService: SalesOrderFormsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup,
      pickUpAddressForm: FormGroup,
      deliveryToAddressForm: FormGroup
    }
  ) { }

  ngOnInit(): void {
    this.selectCountries();
    this.trackSalesOrderChanges();
    this.selectAndHandleOrderState();
  }

  private selectCountries() {
    this.store.select(selectCountries)
      .pipe(distinctUntilChanged(isEqual), takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
        this.cdr.detectChanges();
      });
  }

  private trackSalesOrderChanges(): void {
    this.store.select(selectSalesOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: SalesOrderModel) => {
        this.salesOrder = response;
        this.salesOrderFormsService.initDeliveryInfoForm(response.deliveryInformation);
        this.data.form = this.salesOrderFormsService.deliveryInfoForm;

        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: !this.isPickUpTypeSelected && this.isCustomerSelected && !this.isGeneralPartner},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: !this.isPickUpTypeSelected && this.isCustomerSelected && !this.isGeneralPartner},
          {value: AddressTypeEnum.WAREHOUSE, label: 'FORM.WAREHOUSE_ADDRESS', enabled: this.isPickUpTypeSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);

        this.updateDeliveryInfoBlockValid();
        this.setFormsState();
      });
  }

  private selectAndHandleOrderState() {
    this.store.select(selectSalesOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private updateDeliveryInfoBlockValid(): void {
    const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
    // const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];

    if (this.data.form.get('isDeliveryCosts').value) {
      deliveryCostControls.forEach(control => control.setValidators(Validators.required));
    } else {
      deliveryCostControls.forEach(control => control.clearValidators());
    }

    // if (this.data.form.get('isInsuredDelivery').value) {
    //   insuranceCostControls.forEach(control => control.setValidators(Validators.required));
    // } else {
    //   insuranceCostControls.forEach(control => control.clearValidators());
    // }

    // [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());
    [...deliveryCostControls].forEach(control => control.updateValueAndValidity());
  }

  public updateSalesOrderDeliveryInfo(fieldValue, fieldName: string): void {
    fieldName = 'deliveryInformation.' + fieldName;
    this.salesOrderApiService.updateSalesOrder(this.salesOrder.id, {fieldValue, fieldName})
      .subscribe((salesOrder: SalesOrderModel) => {
        this.salesOrderFormsService.initDeliveryInfoForm(salesOrder.deliveryInformation);
        this.data.form = this.salesOrderFormsService.deliveryInfoForm;

        if (fieldName === 'deliveryInformation.addressTemplate') {
          this.data.deliveryToAddressForm = AddressFormFactory.getForm(get(salesOrder, 'deliveryInformation.deliveryToAddress'));
          this.data.pickUpAddressForm = AddressFormFactory.getForm(get(salesOrder, 'deliveryInformation.pickUpAddress'));
        }
      }); // updated via store
  }

  public updateAddressField(field: FormInputChangedModel, addressGroupName: string): void {
    const fieldName = addressGroupName + '.' + field.fieldName;
    this.updateSalesOrderDeliveryInfo(field.fieldValue, fieldName);
  }

  // public fillCustomerDeliveryAddress(): void {
  //   this.salesOrderApiService.updateDeliveryAddressByCustomer(this.salesOrder.id).subscribe();
  // }

  public fillOwnWarehouseDeliveryAddress(): void {
    this.salesOrderApiService.updatePickUpAddressByWarehouse(this.salesOrder.id).subscribe();
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public get isPickUpTypeSelected(): boolean {
    return get(this, 'salesOrder.deliveryInformation.deliveryType') === this.deliveryTypes.PICK_UP;
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'salesOrder.properties.company.runpleId');
  }

  public get pickUpAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const pickUpAddressVal = this.data.pickUpAddressForm.value;
    return this.convertAddressToString(pickUpAddressVal, this.countries);
  }

  public get deliveryAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const deliveryAddressVal = this.data.deliveryToAddressForm.value;
    return this.convertAddressToString(deliveryAddressVal, this.countries);
  }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.data.form.disable(opts);
      this.data.deliveryToAddressForm.disable(opts);
      this.data.pickUpAddressForm.disable(opts);
    } else {
      this.data.form.enable(opts);
      this.data.deliveryToAddressForm.enable(opts);
      this.data.pickUpAddressForm.enable(opts);
      this.cdr.detectChanges();

      if (this.salesOrder.status === SalesOrderListTabsEnum.TEMPLATE) {
        this.estimatedDeliveryDate.disable(opts);
      }

      if (this.isGeneralPartner) {
        this.nameControl.disable(opts);
      }

      if (!get(this.salesOrder, 'properties.company.id')) {
        this.data.deliveryToAddressForm.disable(opts);
        this.data.pickUpAddressForm.disable(opts);
      }

      if (this.salesOrder.properties.smallBusiness) {
        this.deliveryCostsVat.disable(opts);
        // this.deliveryInsuranceVat.disable(opts);
      }
    }
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateSalesOrderDeliveryInfo(AddressTypeEnum.USER_DEFINED, 'addressTemplate');
  }

  get isGeneralPartner(): boolean {
    return get(this, 'salesOrder.properties.partnerType') === CustomerTypeEnum.GENERAL;
  }

  get deliveryTypeControl(): FormControl { return this.data.form.get('deliveryType') as FormControl; }
  get estimatedDeliveryDate(): FormControl { return this.data.form.get('estimatedDeliveryDate') as FormControl; }
  get deliveryCostsVat(): FormControl { return this.data.form.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.data.form.get('deliveryCosts') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.data.form.get('deliveryCostsPayer') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.data.form.get('deliveryCostsGross') as FormControl; }
  // get deliveryInsuranceVat(): FormControl { return this.data.form.get('deliveryInsuranceVat') as FormControl; }
  // get deliveryInsuranceCosts(): FormControl { return this.data.form.get('deliveryInsuranceCosts') as FormControl; }
  // get deliveryInsuranceCostsGross(): FormControl { return this.data.form.get('deliveryInsuranceCostsGross') as FormControl; }
  // get deliveryInsurancePayer(): FormControl { return this.data.form.get('deliveryInsurancePayer') as FormControl; }
  get isDeliveryCosts(): FormControl { return this.data.form.get('isDeliveryCosts') as FormControl; }
  get addressTemplateControl(): FormControl { return this.data.form.get('addressTemplate') as FormControl; }
  get nameControl(): FormControl { return this.data.form.get('name') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
