export enum FlagsEnum {
  EDITING = 'EDITING',
  PENDING = 'PENDING',
  ENDED = 'ENDED',
  OVERPAID = 'OVERPAID',
  ACTIVE = 'ACTIVE',
  OVERDUE = 'OVERDUE',
  CORRUPTED = 'CORRUPTED',
  DELAYED = 'DELAYED',
  VAT_BLOCKED = 'VAT_BLOCKED',
  EQUALIZED = 'EQUALIZED',
  PACKING = 'PACKING',
  REPORTED = 'REPORTED',
  PAUSED = 'PAUSED',
  PRIMARY = 'PRIMARY',
  SHORTAGE = 'SHORTAGE',
  EXPRESS = 'EXPRESS',
  ALLOCATED = 'ALLOCATED',
  PARTIALLY = 'PARTIALLY',
  READY = 'READY',
  BLOCKED = 'BLOCKED',
}
