import {TableColumnModelExtended } from '../../../../models/table-column.model';

export const tableColumns: TableColumnModelExtended[] = [
  {
    cellTemplate: 'numberRows',
    cellClass: 'center',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    headerClass: 'center',
  },
  {
    cellTemplate: 'withLinkPrimary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.SERVICE_ID',
    prop: 'productLink',
    cellClass: 'link-primary',
    sortable: true,
    width: 172,
    maxWidth: 172,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.SERVICE_NAME',
    prop: 'product.name',
    sortable: true,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.PROVIDED_SERVICES',
    prop: 'product.providedServices',
    sourceTextFormat: true,
    sortable: true,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.LOGGED_TIME',
    prop: 'loggedTime',
    unitTypeShortcut: true,
    currencyConversion: true,
    currencySourceFormat: true,
    sortable: true,
    decimal: 0,
    cellClass: 'right font-weight-bold',
    headerClass: 'right',
    width: 98,
    maxWidth: 98,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.PERIOD',
    prop: 'rawTrackedTime.periodString',
    sortable: true,
  },
  {
    cellTemplate: 'withSelectionToggle',
    headerTemplate: 'headerSelectionsToggle',
    cellClass: 'center',
    name: 'selection',
    width: 60,
    maxWidth: 60,
    sortable: false,
    resizeable: false,
  }
];
