import { EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export class BaseControlComponent {

  @Input()
  public control: AbstractControl = null;

  @Input()
  public key: string = null;

  @Input()
  public label: string = null;

  @Input()
  public optional: boolean = true;

  @Input()
  public disabled: boolean = null;

  @Output() formUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

}
