import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ImageModel } from '../../../../models';
import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-corporate-logo',
  templateUrl: './corporate-logo.component.html',
  styleUrls: ['./corporate-logo.component.scss']
})
export class CorporateLogoComponent extends InitialSetupBaseComponent implements OnInit {

  public corporateLogo: Array<ImageModel> = [];

  constructor(
    public readonly store: Store<AppState>,
    public router: Router,
    public readonly administrationsApiService: AdministrationsApiService,
  ) {
    super(store, router, administrationsApiService);
  }

  ngOnInit() {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        if (companyProfile) {
          this.corporateLogo = [this.companyProfile.printLogo];
        }
      });
  }

  public changeFullsizeLogo(images: ImageModel[]): void {
    if (!images.length) {
      this.deleteFullSizeLogo();
      return;
    }

    this.administrationsApiService.setFullsizeLogo(images[0].apiModel)
      .pipe(
        finalize(() => this.loadCompanyProfile()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
          this.corporateLogo = [images[0].apiModel];
        }, () => {
          this.corporateLogo = [];
        });
  }

  public deleteFullSizeLogo(): void {
    this.administrationsApiService.deleteFullsizeLogo(this.corporateLogo[0])
      .pipe(
        finalize(() => this.loadCompanyProfile()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
          this.corporateLogo = [];
        });
  }

  public loadCompanyProfile(): void {
    this.administrationsApiService.getCompanyProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public getUploadImageLink(): string {
    return this.administrationsApiService.getUploadImageLink();
  }

}
