import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { Store } from '@ngrx/store';

import { AppState } from '../../store/state/app.state';
import { AccountingSettingsService } from '../accounting-settings-module/services/accounting-settings.service';
import { selectAccountingSettings } from '../../store/selectors/shared.selectors';
import { AccountingSettingsModel } from '../accounting-settings-module/models';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AdministrationsApiService } from '../../administration/services/administrations-api.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingSettingsGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly accountingSettingsService: AccountingSettingsService,
    private readonly store: Store<AppState>
  ) {}

  canActivate(): Observable<boolean> | boolean {
    return this.store.select(selectCompanyProfile)
      .pipe(
        first(),
        switchMap((companyProfile: CompanyProfile) => {
          return isEmpty(companyProfile)
            ? this.administrationsApiService.getCompanyProfile()
            : of(companyProfile);
        }),
        switchMap(() => this.store.select(selectAccountingSettings)),
        switchMap((settings: AccountingSettingsModel) => {
          return settings && settings.id
            ? of(true)
            : this.accountingSettingsService.getAccountingSettings();
        }),
        catchError(() => {
          this.router.navigate(['/']);
          return of(false);
        }),
        switchMap(() => of(true))
      );
  }

}
