import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ColumnTypeEnum, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { NavBarBtnModel } from 'common/src/models';
import { PurchaseOrderListTotalsModel } from '../../models';
import { PoTabs } from '../../enums';
import {
  HighlightTypeEnum,
  TableSummaryBarItemModel,
  TableSummaryBarItemTypeEnum
} from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { FiltersControlsNamesEnum, FiltersGroupModel } from 'common/src/modules/filters';
import { FlagsEnum } from 'common/src/models/flags.enum';

export const PurchaseOrderTabs: TabDefinitionModel[] = [
  // {
  //   label: 'DOCUMENTS_TEMPLATES.TEMPLATES',
  //   tabName: PoTabs.Template,
  //   link: `../../${PoTabs.Template}/1`,
  //   icon: 'file-text',
  //   hideCount: true
  // },
  {
    label: 'TAB.DRAFT',
    tabName: PoTabs.Draft,
    link: `../../${PoTabs.Draft}/1`,
    icon: 'file',
    hintsId: 'draftTab',
    count: 0
  },
  {
    label: 'TAB.OPEN',
    tabName: PoTabs.Open,
    link: `../../${PoTabs.Open}/1`,
    icon: 'inbox',
    hintsId: 'openTab',
    count: 0
  },
  {
    label: 'TAB.COMPLETED',
    tabName: PoTabs.Completed,
    link: `../../${PoTabs.Completed}/1`,
    icon: 'checkbox-on',
    hintsId: 'completedTab',
    count: 0,
    hideCount: true
  },
  {
    label: 'TAB.CANCELED',
    tabName: PoTabs.Canceled,
    link: `../../${PoTabs.Canceled}/1`,
    icon: 'close-square',
    count: 0,
    hintsId: 'canceledTab',
    hideCount: true
  },
  {
    label: 'TAB.DELETED',
    tabName: PoTabs.Deleted,
    link: `../../${PoTabs.Deleted}/1`,
    icon: 'trash-2',
    count: 0,
    hintsId: 'deletedTab',
    hideCount: true
  },
];

export function getPurchaseOrderListFiltersConfig(
  status: PoTabs,
  externalNumbers$: Observable<string[]>,
  runpleIdsList$: Observable<any[]>,
  templatesNames$: Observable<{ id: number; name: string; }[]>
): FiltersGroupModel[] {
  const filtersConfig: FiltersGroupModel[] = [
    {
      disabled: true,
      filters: [
        {
          label: 'COLUMN.NAME',
          controlName: 'id',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          observableList: templatesNames$,
          bindValue: 'id',
          bindLabel: 'name',
          placeholder: 'FORM.SELECT_NAME',
          showWithStatus: [
            PoTabs.Template
          ]
        },
        {
          label: 'FORM.VENDOR',
          controlName: 'partnerId',
          placeholder: 'FORM.SELECT_VENDOR',
          controlType: FiltersControlsNamesEnum.PARTNER,
        },
        {
          label: 'POSITIONS.PRODUCT',
          controlName: 'productId',
          controlType: FiltersControlsNamesEnum.PRODUCT,
        },
      ]
    },
    {
      title: 'DOC_BLOCK.DELIVERY',
      filters: [
        {
          label: 'FORM.DELIVERY_STATUS',
          controlName: 'deliveryStatus',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          placeholder: 'FORM.SELECT_DELIVERY_STATUS',
          controlClass: 'col-6 mw-272',
          baseDropdownItems: [
            {label: 'STATUS.PENDING', value: 'pending'},
            // {label: 'STATUS.PARTIAL', value: 'partially_prepared'},
            {label: 'STATUS.DELIVERED', value: 'delivered'},
            {label: 'STATUS.PARTIALLY_WAREHOUSED', value: 'partially_warehoused'},
            {label: 'STATUS.PARTIAL_PROCESSING', value: 'partially_shipped'},
            {label: 'STATUS.WAREHOUSED', value: 'warehoused'},
            {label: 'STATUS.NOT_REQUIRED', value: 'not_required'},
          ],
          showWithStatus: [
            PoTabs.Open,
          ]
        },
        {
          label: 'FORM.DELIVERY_STATUS',
          controlName: 'deliveryStatus',
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_DELIVERY_STATUS',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          baseDropdownItems: [
            {label: 'STATUS.DELIVERED', value: 'delivered'},
            {label: 'STATUS.PARTIALLY_WAREHOUSED', value: 'partially_warehoused'},
            {label: 'STATUS.PARTIAL_PROCESSING', value: 'partially_shipped'},
            {label: 'STATUS.WAREHOUSED', value: 'warehoused'},
          ],
          showWithStatus: [
            PoTabs.Completed,
          ]
        },
        {
          label: 'FORM.ESTIMATED_DELIVERY_DATE',
          controlName: 'estimatedDeliveryDate',
          nextDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Draft,
            PoTabs.Open,
          ]
        },
        {
          label: 'FORM.DELIVERY_TYPE',
          controlName: 'deliveryType',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.DELIVERY_TYPE,
          showWithStatus: [
            PoTabs.Draft,
            PoTabs.Open,
            PoTabs.Completed,
            PoTabs.Template,
          ]
        },
      ]
    },
    {
      title: 'DOC_BLOCK.BILLING',
      filters: [
        {
          label: 'FORM.BILLING_STATUS',
          controlName: 'billingStatus',
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_BILLING_STATUS',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          baseDropdownItems: [
            {label: 'STATUS.PENDING', value: 'pending'},
            {label: 'STATUS.PARTIAL_BILLED', value: 'partially_billed'},
            {label: 'STATUS.BILLED', value: 'billed'},
            {label: 'STATUS.PARTIALLY_PAID', value: 'partially_paid'},
            {label: 'STATUS.PAID', value: 'paid'},
          ],
          showWithStatus: [
            PoTabs.Open,
          ]
        },
      ]
    },
    {
      title: 'DOC_BLOCK.PROPERTIES',
      filters: [
        {
          label: 'FORM.EXTERNAL_NUMBER',
          controlName: 'externalNumber',
          placeholder: 'FORM.SELECT_EXTERNAL_NUMBER',
          containerClass: 'col-6',
          controlType: FiltersControlsNamesEnum.DROPDOWN_OBSERVABLE_STRING,
          observableList: externalNumbers$,
          allowSelectAll: true,
          showWithStatus: [
            PoTabs.Draft,
            PoTabs.Open,
            PoTabs.Completed,
            PoTabs.Canceled,
            PoTabs.Deleted,
          ]
        },
        {
          label: 'COLUMN.ID',
          controlName: 'runpleId',
          bindValue: 'runpleId',
          containerClass: 'col-6',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          placeholder: 'FORM.SELECT_ID',
          allowSelectAll: true,
          observableList: runpleIdsList$,
          showWithStatus: [
            PoTabs.Open,
            PoTabs.Completed,
            PoTabs.Canceled,
          ]
        },
        {
          label: 'COLUMN.STATUS',
          controlName: 'flag',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          placeholder: 'FORM.SELECT_STATUS',
          baseDropdownItems: [
            {label: 'STATUS.EDITING', value: FlagsEnum.EDITING.toLowerCase()},
          ],
          showWithStatus: [
            PoTabs.Draft,
          ]
        },
        {
          label: 'COLUMN.STATUS',
          controlName: 'flag',
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_STATUS',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          baseDropdownItems: [
            {label: 'STATUS.EDITING', value: FlagsEnum.EDITING.toLowerCase()},
          ],
          showWithStatus: [
            PoTabs.Open,
          ]
        },
        {
          label: 'FORM.CANCELED',
          controlName: 'canceled',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Canceled
          ]
        },
        {
          label: 'FORM.DELETED',
          controlName: 'deleted',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Deleted
          ]
        },
        {
          label: 'FORM.COMPLETED',
          controlName: 'completed',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Completed
          ]
        },
        {
          label: 'FORM.OPENED',
          controlName: 'opened',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Open,
          ]
        },
        {
          label: 'FORM.UPDATED',
          controlName: 'updated',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Template,
          ]
        },
        {
          label: 'DOCUMENTS_TEMPLATES.LAST_USED',
          controlName: 'lastTemplateUsed',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Template,
          ]
        },
        {
          label: 'FORM.CREATION_DATE',
          controlName: 'created',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            PoTabs.Open,
            PoTabs.Completed,
            PoTabs.Canceled,
            PoTabs.Template,
          ]
        },
        {
          label: 'COLUMN.EMPLOYEE',
          controlName: 'managerId',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.EMPLOYEE,
        },
      ]
    }
  ];

  return filtersConfig.map(itm => {
    return {
      ...itm,
      filters: itm.filters.filter(itm => itm.hasOwnProperty('showWithStatus') ? itm.showWithStatus.includes(status) : true)
    }
  });
}

export const ActionBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.DELETE_PERMANENTLY',
    multipleText: 'BUTTON.DELETE_PERMANENTLY',
    badgeClass: 'rnpl-badge--red',
    iconName: 'fire',
    actionName: 'onDeletePermanentlyClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      PoTabs.Draft,
      PoTabs.Open,
      PoTabs.Canceled,
      PoTabs.Completed,
      PoTabs.Template,
    ],
  },
  {
    classes: 'btn-white red',
    text: 'BUTTON.DELETE',
    multipleText: 'BUTTON.DELETE',
    badgeClass: 'rnpl-badge--red',
    iconName: 'trash-2',
    actionName: 'onDeleteClick',
    allowLoadingSpinner: true,
    multiple: true,
    hideWithStatus: [
      PoTabs.Deleted,
      PoTabs.Completed,
      PoTabs.Open,
    ]
  },
];

export const PurchaseOrderListColumns: TableColumnModelExtended[] = [
  {
    cellTemplate: 'withSelection',
    headerTemplate: 'headerSelections',
    cellClass: 'center',
    name: 'selection',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    frozenLeft: true,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Canceled,
      PoTabs.Deleted,
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'numberRows',
    cellClass: 'center cursor-pointer',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    customizable: true,
    headerClass: 'center',
    clicktype: TableActivateTypes.Link,
  },
  {
    cellTemplate: 'withLinkPrimary',
    cellClass: 'link-primary cursor-pointer',
    headerTemplate: 'headerPrimary',
    name: 'COMMON.TEMPLATE',
    prop: 'templateNameLink',
    preventTranslate: true,
    // width: 152,
    // maxWidth: 152,
    sortable: true,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'withLinkPrimary',
    headerTemplate: 'headerPrimary',
    cellClass: 'link-primary cursor-pointer',
    name: 'COLUMN.PO_ID',
    prop: 'documentLink',
    width: 172,
    maxWidth: 172,
    sortable: true,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Open,
      PoTabs.Completed,
      PoTabs.Canceled,
      PoTabs.Deleted,
    ]
  },
  {
    cellTemplate: 'withStatusLabel',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.STATUS',
    prop: 'statusValue',
    sortable: true,
    customizable: true,
    cellClass: 'center cursor-pointer',
    width: 124,
    maxWidth: 124,
    clicktype: TableActivateTypes.Link,
  },
  {
    cellTemplate: 'withStatusLabel',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.DELIVERY',
    prop: 'delivery',
    sortable: true,
    customizable: true,
    cellClass: 'center cursor-pointer',
    width: 185,
    maxWidth: 185,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Open,
      PoTabs.Completed,
    ]
  },
  {
    cellTemplate: 'withStatusLabel',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.BILLING',
    prop: 'billing',
    sortable: true,
    customizable: true,
    cellClass: 'center cursor-pointer',
    // width: 88,
    maxWidth: 165,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Open
    ]
  },
  {
    cellTemplate: 'withCorporatePartner',
    headerTemplate: 'headerPrimary',
    cellClass: 'link-primary cursor-pointer',
    name: 'COLUMN.VENDOR',
    prop: 'vendor',
    sortable: true,
    customizable: true,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Open,
      PoTabs.Completed,
      PoTabs.Canceled,
      PoTabs.Deleted,
    ]
  },
  {
    cellTemplate: 'withCorporatePartner',
    headerTemplate: 'headerPrimary',
    cellClass: 'link-primary cursor-pointer',
    name: 'COLUMN.VENDOR',
    prop: 'customer',
    sortable: true,
    customizable: true,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'withStatusLine',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.DELIVERY_DATE',
    prop: 'deliveryDateValue',
    sortable: true,
    customizable: true,
    type: ColumnTypeEnum.DATE,
    width: 180,
    maxWidth: 180,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    colorState: 'main-600',
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Open,
    ],
  },
  {
    cellTemplate: 'primaryWithNumber',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.POSITIONS',
    prop: 'positionsQuantity',
    postFixText: 'FORM.PCS',
    sortable: true,
    customizable: true,
    cellClass: 'right font-weight-bold cursor-pointer',
    headerClass: 'right',
    width: 128,
    maxWidth: 128,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Completed,
      PoTabs.Canceled,
      PoTabs.Deleted,
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'primaryWithNumber',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.OPEN_POSITIONS',
    prop: 'positionsQuantity',
    postFixText: 'FORM.PCS',
    sortable: true,
    customizable: true,
    cellClass: 'right font-weight-bold cursor-pointer',
    headerClass: 'right',
    width: 128,
    maxWidth: 128,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Open,
    ]
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'FORM.AMOUNT_GROSS',
    prop: 'amountGross',
    sortable: true,
    additionalText: '€', //todo: add from workspace currency
    currencyConversion: true,
    customizable: true,
    cellClass: 'right font-weight-bold cursor-pointer',
    headerClass: 'right',
    width: 136,
    maxWidth: 136,
    clicktype: TableActivateTypes.Link,
  },
  {
    cellTemplate: 'primaryWithNumber',
    headerTemplate: 'headerPrimary',
    name: 'DOCUMENTS_TEMPLATES.USED',
    prop: 'used',
    sortable: true,
    customizable: true,
    cellClass: 'right font-weight-bold cursor-pointer',
    headerClass: 'right',
    width: 100,
    maxWidth: 100,
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Template,
    ],
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'FORM.EXTERNAL_NUMBER',
    prop: 'externalNumber',
    sortable: true,
    customizable: true,
    width: 200,
    maxWidth: 200,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Open,
      PoTabs.Completed,
      PoTabs.Canceled,
      PoTabs.Deleted,
    ],
  },
  {
    cellTemplate: 'withLinksList',
    headerTemplate: 'headerPrimary',
    cellClass: 'link-primary',
    name: 'COLUMN.SALES_ORDER_ID',
    prop: 'linkedDocumentsLinks',
    width: 196,
    maxWidth: 196,
    sortable: true,
    customizable: true,
    showWithStatuses: [
      PoTabs.Completed,
      PoTabs.Open,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'DOCUMENTS_TEMPLATES.LAST_USED',
    prop: 'lastUsedAt',
    sortable: true,
    customizable: true,
    width: 144,
    maxWidth: 144,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.UPDATED',
    prop: 'updatedAt',
    sortable: true,
    customizable: true,
    width: 144,
    maxWidth: 144,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Open,
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.CREATED',
    prop: 'createdAt',
    sortable: true,
    customizable: true,
    width: 144,
    maxWidth: 144,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Template,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.COMPLETED',
    prop: 'completedAt',
    sortable: true,
    customizable: true,
    width: 144,
    maxWidth: 144,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Completed,
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.CANCELED',
    prop: 'canceledAt',
    sortable: true,
    customizable: true,
    width: 144,
    maxWidth: 144,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Canceled
    ]
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'FORM.DELETED',
    prop: 'deletedAt',
    sortable: true,
    customizable: true,
    width: 144,
    maxWidth: 144,
    cellClass: 'cursor-pointer',
    clicktype: TableActivateTypes.Link,
    showWithStatuses: [
      PoTabs.Deleted,
    ]
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COMMON.INTERNAL_REMARK',
    prop: 'remark',
    sortable: true,
    customizable: true,
    // sourceTextFormat: true,
    showWithStatuses: [
      PoTabs.Draft,
      PoTabs.Open,
      PoTabs.Completed,
      PoTabs.Canceled,
      PoTabs.Deleted,
    ],
  },
  {
    cellTemplate: 'withClickableIcon',
    name: '',
    width: 42,
    maxWidth: 42,
    sortable: false,
    resizeable: false,
    clicktype: TableActivateTypes.RowDetail,
    frozenRight: true,
    icon: 'arrow-micro-down',
    cellClass: 'px-4 cursor-pointer'
  }
];

export function getPurchaseOrderListInnerTableColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 160,
      maxWidth: 160,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary inline',
      name: 'COLUMN.PRODUCT_NAME',
      prop: 'productNameLink',
      sortable: true
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      unitTypeShortcut: true,
      width: 112,
      maxWidth: 112,
      cellClass: 'right font-weight-bold',
      headerClass: 'right',
    },
    {
      cellTemplate: 'withStatusLine',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.OPEN',
      prop: 'openData',
      sortable: true,
      unitTypeShortcut: true,
      type: ColumnTypeEnum.STATUS_LINE_OPEN_AMOUNT,
      width: 164,
      maxWidth: 164,
      cellClass: 'font-weight-500',
      showWithStatuses: [
        PoTabs.Open,
      ]
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.UNIT_PRICE_GROSS',
      prop: 'grossUnitPrice',
      sortable: true,
      additionalText: '€', //todo: add from workspace currency
      currencyConversion: true,
      width: 140,
      maxWidth: 140,
      cellClass: 'right font-weight-bold',
      headerClass: 'right',
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.AMOUNT_GROSS',
      prop: 'grossTotalPrice',
      sortable: true,
      additionalText: '€', //todo: add from workspace currency
      currencyConversion: true,
      width: 140,
      maxWidth: 140,
      cellClass: 'right font-weight-bold',
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: translateService.instant('COMMON.DISCOUNT_WITH_POSTFIX', {postfix: '%'}),
      prop: 'discount',
      sortable: true,
      additionalText: '%',
      // highlightTypes: [HighlightTypeEnum.POSITIVE_TO_YELLOW],
      cellClass: 'right font-weight-500 color-green-600',
      headerClass: 'right',
      width: 100,
      // maxWidth: 100,
    },
    // {
    //   cellTemplate: 'primary',
    //   headerTemplate: 'headerPrimary',
    //   name: translateService.instant('COMMON.DISCOUNT_WITH_POSTFIX', {postfix: '€'}),
    //   prop: 'discountAmount',
    //   sortable: true,
    //   additionalText: '€', //todo: add from workspace currency
    //   currencyConversion: true,
    //   highlightTypes: [HighlightTypeEnum.POSITIVE_TO_YELLOW],
    //   cellClass: 'right font-weight-500 color-green-600',
    //   headerClass: 'right',
    //   width: 100,
    //   maxWidth: 100
    //   // maxWidth: 100,
    // },
  ]
}

export const EMPTY_STATE_DATA = {
  [PoTabs.Draft]: {
    icon: 'file',
    text: 'PO.EMPTY_LIST_DRAFT'
  },
  [PoTabs.Open]: {
    icon: 'inbox',
    text: 'PO.EMPTY_LIST_OPEN'
  },
  [PoTabs.Completed]: {
    icon: 'checkbox-on',
    text: 'PO.EMPTY_LIST_COMPLETED'
  },
  [PoTabs.Canceled]: {
    icon: 'close-square',
    text: 'PO.EMPTY_LIST_CANCELED'
  },
  [PoTabs.Deleted]: {
    icon: 'trash-2',
    text: 'PO.EMPTY_LIST_DELETED'
  },
  [PoTabs.Template]: {
    icon: 'file-text',
    text: 'DOCUMENTS_TEMPLATES.EMPTY_STATE'
  },
};

export function getTableSummaryBarItems(
  totals: PurchaseOrderListTotalsModel,
  status: PoTabs
): TableSummaryBarItemModel[] {
  const tableSummaryBarItems: TableSummaryBarItemModel[] = [
    {
      label: STATUS_LABEL[status],
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.totalCount,
      titleIcon: TOTALS_ICON[status],
      basisWidth: 167,
    },
    {
      label: 'TABLE_SUMMARY_BAR.TOTAL_AMOUNT_NET',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      value: totals.totalNet,
      currencySymbol: '€',
      currencyConversion: true,
      basisWidth: 172,
    },
    {
      label: 'TABLE_SUMMARY_BAR.TOTAL_VAT',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      value: totals.totalVat,
      currencySymbol: '€',
      currencyConversion: true,
      basisWidth: 172,
    },
    {
      label: 'TABLE_SUMMARY_BAR.TOTAL_AMOUNT_GROSS',
      type: TableSummaryBarItemTypeEnum.WITH_CURRENCY,
      value: totals.totalGross,
      currencySymbol: '€',
      currencyConversion: true,
      basisWidth: 172,
    },
  ];

  return tableSummaryBarItems;
}

export const TOTALS_ICON = {
  [PoTabs.Draft]: 'file',
  [PoTabs.Open]: 'inbox',
  [PoTabs.Completed]: 'checkbox-on',
  [PoTabs.Canceled]: 'close-square',
  [PoTabs.Deleted]: 'trash-2',
};

export const STATUS_LABEL = {
  [PoTabs.Draft]: 'TAB.DRAFT',
  [PoTabs.Open]: 'TAB.OPEN',
  [PoTabs.Completed]: 'TAB.COMPLETED',
  [PoTabs.Canceled]: 'TAB.CANCELED',
  [PoTabs.Deleted]: 'TAB.DELETED',
}

export function getDeleteModalData(translateService: TranslateService): CommonModalDataModel {
  return {
    title: translateService.instant('MODAL.DELETE_SELECTED', {documentName: translateService.instant('APP.PURCHASE_ORDERS')}),
    message: translateService.instant('COMMON.ABOUT_TO_DELETE', {documentName: translateService.instant('APP.PURCHASE_ORDERS')}),
    confirmBtnText: 'BUTTON.DELETE',
    confirmBtnIcon: 'trash-2'
  }
}

export function getDeletePermanentlyModalData(translateService: TranslateService): CommonModalDataModel {
  return {
    title: 'MODAL.DELETED_SELECTED_PERMANENTLY',
    message: translateService.instant('COMMON.ABOUT_TO_DELETE', {documentName: translateService.instant('APP.PURCHASE_ORDERS')}),
    confirmBtnText: 'BUTTON.DELETE_PERMANENTLY',
    confirmBtnIcon: 'trash-2'
  }
}
