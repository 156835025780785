import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { catchError, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, throwError } from 'rxjs';

import { OverdueNoticeFeeTypeEnum, OverdueNoticeTypeEnum } from 'projects/workspace/src/app/accounting/accounting-settings-module/enums';
import { OverdueNoticeItemModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';
import {
  AccountingSettingsService
} from 'projects/workspace/src/app/accounting/accounting-settings-module/services/accounting-settings.service';
import { CommonModalsActionsEnum } from '../../modals-common';

@Component({
  selector: 'rnpl-oin-send-notice-email-modal',
  templateUrl: './oin-send-notice-email-modal.component.html'
})
export class OinSendNoticeEmailModalComponent implements OnDestroy {

  public overdueNoticeTypeEnum = OverdueNoticeTypeEnum;
  public form: FormGroup = this.fb.group({
    overdueNotice: this.fb.group({
      addFee: [false],
      daysAfterDueDate: [7, [Validators.required]],
      feeValue: [null, [Validators.required]],
      message: [null, [Validators.required]],
      overdueFeeType: [OverdueNoticeFeeTypeEnum.PERCENT],
    }),
    emails: [null, [Validators.required]],
  });

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<OinSendNoticeEmailModalComponent>,
    public accountingSettingsService: AccountingSettingsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      emails: string,
      oinId: number,
      manualNotice: OverdueNoticeItemModel
    }
  ) {
    this.emailsControl.patchValue(this.data.emails);
    this.overdueNoticeForm.patchValue(this.data.manualNotice);
  }

  public sendEmail(): void {
    const emails = this.emailsControl.value
      ? this.emailsControl.value.replace(' ', '').split(',')
      : null;

    this.form.markAllAsTouched();
    this.accountingSettingsService.sendManualNoticeOINEmail(
      this.data.oinId,
      emails,
      this.overdueNoticeForm.getRawValue()
    )
      .pipe(
        catchError(error => {
          if (error.error.messages) {
            error.error.messages.forEach(err => {
              this.form.get(err.name).setErrors({backEndValidation: err.message});
            });
          }
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
  }

  public get emailsControl(): FormControl { return this.form.get('emails') as FormControl; }
  public get overdueNoticeForm(): FormGroup { return this.form.get('overdueNotice') as FormGroup; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

