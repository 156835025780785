import { Component, Input, OnInit, OnDestroy, TemplateRef, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef, MatMenuTrigger } from '@angular/material';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, Subscription } from 'rxjs';


import { SoundService } from 'common/src/services/sound.service';
import { WizardService } from 'common/src/modules/wizard';
import { SystemSettingsService } from '../../system-settings.service';
import { ToasterService } from '../../../ui-components/toaster';
import { AttributeModel } from '../../models';
import { AttributeWizardContext } from '../../attribute-wizard/attribute-wizard.component';
import { CONTROLS } from '../../../dynamic-forms/controls';


@Component({
  selector: 'rnpl-attr-list',
  templateUrl: './attr-list.component.html',
  styleUrls: ['./attr-list.component.scss'],
  animations: [
    trigger('sidePanelAnimation', [
      state('visible', style({
        width: '304px',
        marginLeft: '16px'
      })),
      state('hidden', style({
        width: '0px',
        marginLeft: '0px'
      })),
      transition('visible => hidden', animate('400ms ease-in-out')),
      transition('hidden => visible', animate('400ms ease-in-out'))
    ])
  ]

})
export class AttrListComponent implements OnInit, OnDestroy, OnChanges {

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  public entityType;

  @Input()
  public entity;

  public selectedAttrs = new SelectionModel<AttributeModel>(true, []);

  public attributesList: Array<AttributeModel> = [];
  public pagination: any = {};

  public pages: Array<any> = [];

  public controls: any = {};
  public controlList: Array<any> = [];

  public sidePanelState = 'hidden';

  public dialogRef: MatDialogRef<TemplateRef<any>>;

  public get requestParams() {
    return this._requestParams;
  }

  public get filters() {
    return this._filters;
  }

  public filtersCount: number = 0;

  @ViewChild(MatMenuTrigger, {static: false})
  private menuTrigger: MatMenuTrigger;

  @ViewChild('deletePopup', {static: true})
  private deletePopup: TemplateRef<any>;

  private _requestParams: any = {};

  private _filters: any = {};

  @ViewChild('controlFilter', {static: false})
  private controlFilter: NgSelectComponent;

  private subscriptions: Array<Subscription> = [];

  constructor(private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private wizardService: WizardService,
              private systemSettingsService: SystemSettingsService,
              private toasterService: ToasterService,
              public soundService: SoundService) {
  }

  ngOnInit() {

    const subscription = this.route.paramMap
      .subscribe(params => {
        this.pagination.page = params.get('page');
        this._requestParams.page = this.pagination.page;

        this.goToPage(this.pagination.page);

        if (!params.get('type') || !params.get('entity')) {
          this.loadAttributes();
          return;
        }

        const paramsEntity = `${params.get('type')}_${params.get('entity').replace('-', '_')}`;
        const requestParamsEntity = this._requestParams.business_entity;
        // load attrs on page change if entity type still same as on previous page
        if (paramsEntity === requestParamsEntity) {
          this.loadAttributes();
        }
      });

    this.subscriptions.push(subscription);

    this.systemSettingsService.getControls()
      .subscribe(response => {
        this.controls = response.data.controls;
        this.controlList = Object.keys(this.controls)
          .filter(key => !!CONTROLS[key])
          .map(key => {
            return {label: this.controls[key], value: key};
          });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._requestParams.business_entity = `${this.entityType}_${this.entity}`;
    if (this.pagination.page) {
      this.loadAttributes();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public goToPage(page: number): void {
    this.router.navigate(['../', page], {relativeTo: this.route});
  }

  /**
   * Whether the number of selected elements matches the total number of rows.
   */
  public isAllSelected() {
    const numSelected = this.selectedAttrs.selected.length;
    const numRows = this.attributesList.length;
    return numSelected === numRows;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selectedProducts.
   */
  public masterToggle(): void {
    this.isAllSelected() ?
      this.selectedAttrs.clear() :
      this.attributesList.forEach(row => this.selectedAttrs.select(row));
  }

  public displayDeletePopup(): void {
    this.dialogRef = this.dialog.open(this.deletePopup, {
      width: 'auto',
      position: {
        top: '64px'
      }
    });

    this.soundService.playSound('modal_dangerous');
  }

  public deleteAttributes(): void {

    const itemsToDel = this.selectedAttrs.selected.map(attribute => attribute.id);

    this.systemSettingsService.deleteAttributes({ids: itemsToDel})
      .subscribe(() => {
        if (this.dialogRef) {
          this.dialogRef.close();
        }

        let message = '';
        if (this.selectedAttrs.selected.length === 1) {
          const deletedAttribute = this.selectedAttrs.selected[0];
          message = `${deletedAttribute.name} has been deleted`;
        } else {
          message = `${this.selectedAttrs.selected.length} attributes have been deleted`;
        }
        this.selectedAttrs.clear();

        this.toasterService.notify({type: 'success', message});

        this.systemSettingsService.getAttributes(this._requestParams)
          .subscribe(response => this.loadAttributes());
      });
  }

  public matMenuShow(): void {
    this.menuTrigger.openMenu();
  }

  public matMenuHide(): void {
    this.menuTrigger.closeMenu();
  }

  public showSidePanel(): void {
    this.sidePanelState = 'visible';
  }

  public hideSidePanel(): void {
    this.sidePanelState = 'hidden';
  }

  /**
   * Sets controls to filtering
   *
   * @param controls List of selected controls
   */
  public updateControlFilter(controls: Array<any>): void {
    if (!controls.length) {
      delete this._filters['control[]'];
    } else {
      this._filters['control[]'] = controls.map(control => control.value);
    }
  }

  /**
   * Sets types to filtering
   *
   * @param type An attribute type
   * @param checked Is type checked
   */
  public updateTypeFilter(type, checked): void {
    if (!checked) {
      this._filters['type[]'] = this._filters['type[]'].filter(attrType => attrType !== type);
    } else {
      this._filters['type[]'] = this._filters['type[]'] || [];
      if (!this._filters['type[]'].includes(type)) {
        this._filters['type[]'].push(type);
      }
    }
  }

  public clearFilters(): void {
    this.resetFilter('control');
    this.resetFilter('type');
    this.hideSidePanel();
    this.applyFilters();
  }

  /**
   * Resets filter
   *
   * @param field 'control' or 'type'
   */
  public resetFilter(field: string): void {
    if (field === 'control') {
      this.controlFilter.clearModel();
    }
    delete this._requestParams[`${field}[]`];
    delete this._filters[`${field}[]`];
  }

  /**
   * Updates list of attributes by set filters
   */
  public applyFilters(): void {
    this.filtersCount = Object.keys(this._filters).length;
    delete this._requestParams['control[]'];
    delete this._requestParams['type[]'];
    this._requestParams = {...this.requestParams, ...this._filters};
    this.loadAttributes();
  }

  /**
   * Sorts attributes by column
   *
   * @param column A column name
   */
  public sort(column: string): void {
    if (!this._requestParams[`sort[${column}]`]) {
      Object.keys(this._requestParams)
        .filter(key => /^sort/.test(key))
        .forEach(key => {
          delete this._requestParams[key];
        });
      this._requestParams[`sort[${column}]`] = 'asc';
    } else {
      this._requestParams[`sort[${column}]`] = this._requestParams[`sort[${column}]`] === 'asc' ? 'desc' : 'asc';
    }
    this.loadAttributes();
  }

  /**
   * Loads attributes with params
   */
  public loadAttributes(): void {
    this.isLoading$.next(true);
    this.systemSettingsService.getAttributes(this._requestParams)
      .subscribe(response => {
        this.isLoading$.next(false);
        this.hideSidePanel();
        this.initList(response.data, response.pagination);
      });
  }

  public createAttribute(): void {
    this.wizardService.create<AttributeWizardContext>(
      'attribute',
      {
        entityTypes: [this.entityType],
        entities: [`${this.entityType}_${this.entity}`]
      },
      this.router.url
    );
  }

  /**
   * Inits attributes list and pagination values
   *
   * @param list A list of attributes
   * @param pagination A pagination object
   */
  private initList(list, pagination): void {
    this.attributesList = list;
    this.pagination = pagination;

    let pagesCount = Math.floor(this.pagination.count / this.pagination.per_page);
    if (this.pagination.count % this.pagination.per_page) {
      pagesCount++;
    }
    this.pages = Array.from({length: pagesCount}, (val, index) => index + 1);
  }
}
