import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { AppActionModel } from '../../launchpad.model';

@Component({
  selector: 'rnpl-page-context-menu',
  templateUrl: './page-context-menu.component.html',
  styleUrls: ['./page-context-menu.component.scss']
})
export class PageContextMenuComponent {
  @Input() public actions: AppActionModel[];

  @Input() public contextMenuPositions: {[key: string]: number} = {
    x: 0,
    y: 0
  };

  @Output() actionListEmit: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatMenuTrigger, {static: false}) menuTrigger: MatMenuTrigger;

  public actionListHandler(actionName: string): void {
    if (actionName) {
      this.actionListEmit.next(actionName);
    }
  }

}
