import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { selectPoCurrentState, selectPurchaseOrder } from '../../../store/selectors';
import { PurchaseOrder, PurchaseOrderPropertiesModel } from '../../../models';
import { UIStatesEnum } from 'common/src/models';
import { AppState } from '../../../../store/state/app.state';
import { FormInputChangedModel } from '../../../../shared/models';
import { PurchaseOrderApiService } from '../../../services/purchase-order-api.service';
import { getToRemarksHelper } from '../../../helpers';
import { InfoItemModel } from 'common/src/modules/ui-components/info-components/models/info-item.model';

@Component({
  selector: 'rnpl-purchase-order-remark',
  templateUrl: './purchase-order-remark.component.html',
  styleUrls: ['./purchase-order-remark.component.scss']
})
export class PurchaseOrderRemarkComponent implements OnInit {
  public form: FormGroup;
  public purchaseOrder: PurchaseOrder;
  public isReadonly: boolean = true;
  public viewInfoRemarksList: InfoItemModel[] = null;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private readonly poApi: PurchaseOrderApiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.trackPurchaseOrderChanges();
    this.trackPurchaseOrderStateChanges();
  }

  private trackPurchaseOrderChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        this.initForm(purchaseOrder.properties);
        this.updateInfoList();
        this.setFormsState();
      });
  }

  private trackPurchaseOrderStateChanges(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }
  }

  public initForm(properties: PurchaseOrderPropertiesModel = {} as PurchaseOrderPropertiesModel): void {
    this.form = this.fb.group({
      remark: [get(properties, 'remark')],
    });
    this.cdr.detectChanges();
  }

  public updateProperties(field: FormInputChangedModel): void {
    this.poApi.updatePO(this.purchaseOrder.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public updateInfoList(): void {
    this.viewInfoRemarksList = getToRemarksHelper(this.purchaseOrder);
  }

  get remark(): FormControl { return this.form.get('remark') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
