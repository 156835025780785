import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { ReplaySubject } from 'rxjs';

import { InitialSetupComponent } from '../initial-setup/initial-setup.component';
import { TranslateService } from '@ngx-translate/core';
import { HotjarService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-initial-setup-page',
  templateUrl: './initial-setup-page.component.html',
  styleUrls: ['./initial-setup-page.component.scss']
})
export class InitialSetupPageComponent implements OnInit, OnDestroy {

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private dialog: MatDialog,
    private titleService: Title,
    private translateService: TranslateService,
    private router: Router,
    private hotjarService: HotjarService,
  ) {
    this.hotjarService.addScript();
  }

  ngOnInit() {
    setTimeout(() => {
      this.titleService.setTitle(`Runple. ${this.translateService.instant('INITIAL_SETUP.INITIAL_SETUP')}`);
    }, 200);

    const dialog = this.dialog.open(InitialSetupComponent, {
      disableClose: true,
      hasBackdrop: false,
      backdropClass: 'drop-sign-in',
      closeOnNavigation: false,
      panelClass: 'sign-in-modal'
    });

    dialog.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate(['/']));
  }

  ngOnDestroy() {
    // this.hotjarService.removeScript();

    this.destroy$.next(true);
    this.destroy$.complete();
    this.dialog.closeAll();
  }
}
