import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { get } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { AddressFormFactory } from 'projects/workspace/src/app/crm/partner-forms/components/address-form.factory';
import { CountryModel, RnplCommonService } from 'common/src/modules/rnpl-common';
import { BatchDataModel } from '../../models/batch-data.model';
import { AutoUnsubscribe } from 'common/src/decorators/auto-unsubscribe';
import { BatchStates } from 'projects/workspace/src/app/purchase-order/enums/batch-states';
import { BatchModel } from '../../../../../models/purchase-order/batch.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  currentBatchStateSelector,
  currentBatchSelector,
} from 'projects/workspace/src/app/purchase-order/store/selectors/po.selectors';
import { LoadCurrentBatch } from 'projects/workspace/src/app/purchase-order/store/actions/batch.actions';
import { AddressModel } from 'common/src/models';

@AutoUnsubscribe()
@Component({
  selector: 'rnpl-batch-view-logistics',
  templateUrl: './batch-view-logistics.component.html',
  styleUrls: ['./batch-view-logistics.component.scss']
})
export class BatchViewLogisticsComponent extends BaseFormComponent implements OnInit, OnDestroy {

  @Input() batchData: BatchDataModel;

  readonly activeState$: BehaviorSubject<BatchStates> = new BehaviorSubject(BatchStates.VIEW);
  readonly batch$: BehaviorSubject<BatchModel> = new BehaviorSubject({} as BatchModel);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public countries: CountryModel[] = [];
  public delivery_address: AddressModel;

  constructor(
    private fb: FormBuilder,
    private rnplCommonService: RnplCommonService,
    private store: Store<AppState>
  ) {
    super();
    this.storeSubscribes();
  }

  public storeSubscribes(): void {
    this.store
      .pipe(
        select(currentBatchStateSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(activeState => {
        this.activeState$.next(activeState);
      });
  }

  ngOnInit(): void {
    this.updateFormEditingStatus();

    this.initForm();

    this.subCountry();

    this.subDeliveryAdress();

    this.subBatch();

    this.watchBatchState();

  }

  public initForm(): void {
    const batch = this.batch$.getValue();

    this.form = this.fb.group({
      spedition_type: this.fb.group({
        type: [get(batch, 'spedition_type', 'internal'), { validators: [Validators.required], updateOn: 'blur' }],
        shipment_service: [get(batch, 'spedition', ''), { validators: [Validators.required], updateOn: 'blur' }],
        tracking_number: [get(batch, 'tracking_number', ''), { updateOn: 'blur' }],
      }),
      delivery_to_address: AddressFormFactory.getForm(this.delivery_address || {} as AddressModel),
    });

    this.watchFormEditing();
  }

  public updateFormEditingStatus(): void {
    if (!this.form) { return; }

    if (this.readonly) {
      this.form.disable({ onlySelf: false });
    } else {
      this.form.enable({ onlySelf: false });
      this.form.get('delivery_to_address').disable();
    }
  }

  public subDeliveryAdress(): void {
    // this.store
    //   .pipe(
    //     select(poDeliveryToAddressSelector),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe(address => {
    //     this.delivery_address = address;
    //     this.initForm();
    //   });
  }

  public subBatch(): void {
    this.store
      .pipe(
        select(currentBatchSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(batch => {
        this.batch$.next(batch);
        this.initForm();
      });
  }

  public subCountry(): void {
    this.rnplCommonService.getCountries().subscribe((response: { data: CountryModel[] }) => this.countries = response.data);
  }

  public watchBatchState(): void {
    this.activeState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BatchStates) => {
        switch (state) {
          case BatchStates.EDIT:
            this.readonly = false;
            break;
          case BatchStates.VIEW:
            this.readonly = true;
            break;
        }
        this.updateFormEditingStatus();
      });
  }

  public watchFormEditing() {
    this.spedition_type.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.store.dispatch(LoadCurrentBatch({
          batch: {
            ...this.batch$.getValue(),
            spedition_type: value.type,
            spedition: value.shipment_service,
            tracking_number: value.tracking_number
          }
        }));
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get spedition_type() { return this.form.get('spedition_type'); }

  get delivery_to_address() { return this.form.get('delivery_to_address'); }

  get shipment_service() { return this.form.get('spedition_type').get('shipment_service'); }

}
