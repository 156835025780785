import { createAction, props } from '@ngrx/store';

import { POSummaryModel, PurchaseOrder } from '../../models';
import { UIStatesEnum } from 'common/src/models';
import { PoTabs } from '../../enums';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { CorporatePartnerModel } from '../../../crm/models';
import { TradeOfferPositionsByProductType } from '../../../trade-offer/models';
import { ResponseModel } from '../../../shared/models/response';
import { POListState } from '../reducers';

export const LoadPOList = createAction(
  '[PO] Load PO List',
  props<{ POListData: POListState, status: PoTabs, page: number }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[PO] Update PO should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadPO = createAction(
  '[PO] Load PO',
  props<{ purchaseOrder: PurchaseOrder }>()
);

export const UpdatePO = createAction(
  '[PO] Update PO',
  props<{ purchaseOrder: PurchaseOrder }>()
);

export const LoadCountries = createAction(
  '[PO] Load PO COUNTRIES',
  props<{ countries: CountryModel[] }>()
);

export const LoadPOPartners = createAction(
  '[PO] Load PO Partners',
  props<{ partners: CorporatePartnerModel[] }>()
);

export const UpdatePOUpdatedAt = createAction(
  '[PO] Update PO updatedAt',
  props<{ updatedAt: Date }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[PO] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[PO] Decrement Loading Requests Count'
);

export const UpdatePOSummary = createAction(
  '[PO] Update PO Summary',
  props<{ summary: POSummaryModel }>()
);

export const ClearPOState = createAction(
  '[PO] Clear PO state'
);

export const ClearPOPositions = createAction(
  '[PO] Clear PO positions'
);

export const UpdatePOCurrentState = createAction(
  '[PO] Update PO Active State',
  props<{ currentState: UIStatesEnum }>()
);

export const LoadPurchaseOrderPositions = createAction(
  '[PO] Load Purchase Order Positions',
  props<{ positions: ResponseModel<TradeOfferPositionsByProductType> }>()
);

export const UpdatePurchaseOrderPositionsCount = createAction(
  '[PO] Update Purchase Order Positions Count',
  props<{ positionsCount: number }>()
);

export const UpdatePurchaseOrderStatus = createAction(
  '[PO] Update Purchase Order Status',
  props<{ status: PoTabs }>()
);

export const UpdatePropertiesBlockValid = createAction(
  '[PO] Update Purchase Order Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateDeliveryInfoBlockValid = createAction(
  '[PO] Update Purchase Order Delivery Info Block Valid',
  props<{ deliveryInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[PO] Update Purchase Order Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
