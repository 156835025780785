import { NavBarBtnModel } from '../../../../models';

export const NavBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'BUTTON.CANCEL',
    iconName: 'close',
    disabled: false,
    actionName: 'onCloseClick',
    multiple: false,
  },
  {
    classes: 'btn-primary btn-create-category',
    text: 'BUTTON.CREATE',
    iconName: 'plus-square',
    allowLoadingSpinner: true,
    actionName: 'onCreateClick',
    disabled: false,
    multiple: false,
    dropdownClass: 'inner-blue-400 btn-drop-menu--inner-fluid icon-close-right',
    width: 160,
    actions: [
      {
        actionTitle: 'BUTTON.CREATE',
        actionName: 'onCreateClick',
        actionIcon: 'plus-square'
      },
      {
        actionTitle: 'BUTTON.CREATE_AND_SET_UP',
        actionName: 'onCreateAndSetupClick',
        actionIcon: 'grid'
      }
    ]
  },
];
