import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  Input,
  QueryList,
  TemplateRef
} from '@angular/core';

@Directive({
  selector: '[rnpl-tab-label], [rnplTabLabel]'
})
export class TabLabelDirective {

  constructor(public template: TemplateRef<any>) {
  }
}


@Component({
  selector: 'rnpl-tab',
  templateUrl: './tab.html',
  styleUrls: ['./tab.scss']
})
export class TabComponent {

  @Input()
  public label: string;

  @Input()
  public isActive: boolean = false;

  @ContentChild(TabLabelDirective, {static: true})
  public labelTemplate: TabLabelDirective;

}


@Component({
  selector: 'rnpl-tab-group',
  templateUrl: './tab-group.html',
  styleUrls: ['./tab-group.scss']
})
export class TabGroupComponent implements AfterContentInit {

  @ContentChildren(TabComponent)
  public tabs: QueryList<TabComponent>;

  ngAfterContentInit(): void {
    const activeTab = this.tabs.find(tab => tab.isActive);
    if (!activeTab && this.tabs.first) {
      this.activateTab(this.tabs.first);
    }
  }

  public activateTab(tab: TabComponent) {
    if (tab.isActive) {
      return;
    }

    this.tabs.forEach(currTab => {
      currTab.isActive = currTab === tab;
    });
  }
}
