import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';


import { AttributeModel } from '../../../system-settings/models';
import { FamilyAssignedAttrModalComponent } from '../../../modals/modals-products/family-assigned-attr-modal/family-assigned-attr-modal.component';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../../modals/modals-common';
import { SystemSettingsService } from '../../../system-settings/system-settings.service';
import { ToasterService } from '../../../ui-components/toaster';
import { FamilyModel } from '../../../products';


@Component({
  selector: 'rnpl-available-attributes',
  templateUrl: './available-attributes.component.html',
  styleUrls: ['./available-attributes.component.scss']
})
export class AvailableAttributesComponent {

  @Input()
  set attributes(attributes: Array<AttributeModel>) {
    this._attributes = this.getUniqueListBy(attributes, 'id');
  }

  @Input()
  family: FamilyModel;

  @Input()
  creationAvailable: boolean = false;

  @Output()
  selected: EventEmitter<AttributeModel> = new EventEmitter<AttributeModel>();

  @Output()
  attributesChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  attributeEdited: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  createBtnClicked: EventEmitter<any> = new EventEmitter<any>();

  get attributes(): Array<AttributeModel> {
    return this._attributes
      .filter(attribute => attribute.name.toLowerCase().includes(this.attributeQuery.toLowerCase()));
  }

  public attributeQuery: string = '';

  private _attributes: Array<AttributeModel> = [];

  constructor(
    private dialog: MatDialog,
    private systemSettingsService: SystemSettingsService,
    private toasterService: ToasterService,
  ) { }

  /**
   * Remove duplicates from array
   */
  private getUniqueListBy(arr: Array<any>, key: string): Array<any> {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  public select(attribute: AttributeModel): void {
    this.selected.emit(attribute);
  }

  public edit(event: Event, attribute: AttributeModel): void {
    event.stopPropagation();
    const dialog = this.dialog.open(FamilyAssignedAttrModalComponent, {
      data: {
        attribute,
        family: this.family
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum | AttributeModel) => {
      if (res === CommonModalsActionsEnum.DELETE) {
        this.attributesChanged.emit();
      }

      if (res.hasOwnProperty('control')) {
        this.attributeEdited.emit(res);
      }
    });
  }

  public delete(event: Event, attribute: AttributeModel): void {
    event.stopPropagation();
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'PRODUCTS.DELETE_ATTR',
        message: 'PRODUCTS.DELETE_ATTR_PERMANENTLY'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.systemSettingsService.deleteAttributes([attribute.id])
          .subscribe(() => {
            this.attributesChanged.emit();
          }, error => {
            this.toasterService.notify({type: 'error', message: error.error.message});
          });
      }
    });
  }

  // public expandAttribute(event): void {
  //   const attributeElement = (event.currentTarget as HTMLElement).closest('.drag-item');
  //   if (attributeElement) {
  //     attributeElement.classList.toggle('expanded');
  //   }
  // }

  public create(): void {
    this.createBtnClicked.emit();
  }
}
