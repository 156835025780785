export enum DeliveryNoteListTabsEnum {
  DRAFT = 'draft',
  OPEN = 'open',
  PACKING = 'packing',
  PACKED_UP = 'packed_up',
  SHIPPED = 'shipped',
  CANCELED = 'canceled',
  DELETED = 'deleted',
  TEMPLATE = 'template',
  EDITING = 'editing'
}
