import { createSelector } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

import { PartnersFeatureKey, PartnerState, PartnerStore } from '../reducers';

export const selectFeature = ((state: AppState) => state[PartnersFeatureKey]);

export const selectPartnerEntity = createSelector(
  selectFeature,
  (state: PartnerStore) => state.entity
);

export const selectPartnersList = createSelector(
  selectFeature,
  (state: PartnerStore) => state.list
);

export const selectPartner = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.partner
);

export const selectPartnerState = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.currentState
);

export const selectTitles = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.titles
);

export const selectCountries = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.countries
);

export const selectValidations = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.validations
);

export const partnerUpdatedAt = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.isShouldRefresh
);

export const loadingRequestsCount = createSelector(
  selectPartnerEntity,
  (state: PartnerState) => state.loadingRequestsCount
);
