import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { TabLabelDirective } from '../../../../tabs/tabs';
import { SoundService } from 'common/src/services/sound.service';

export type WizardClosingAction = 'close' | 'cancel' | 'save' | 'delete';

@Component({
  selector: 'rnpl-closing-popup',
  templateUrl: './closing-popup.component.html',
  styleUrls: ['./closing-popup.component.scss']
})
export class ClosingPopupComponent {

  public dialogRef: MatDialogRef<TemplateRef<any>>;

  public isDraft: boolean = false;

  @ViewChild('popup', {static: true})
  private popupTemplate: TemplateRef<any>;

  private closingAction$: Subject<WizardClosingAction>;

  constructor(private dialog: MatDialog, public soundService: SoundService) {
  }

  public close(isDraft: boolean = false): Observable<WizardClosingAction> {
    this.closingAction$ = new Subject();
    this.isDraft = isDraft;

    this.dialogRef = this.dialog.open(this.popupTemplate, {
      width: 'auto',
      position: {
        top: '64px'
      }
    });

    this.soundService.playSound('modal_warning');
    return this.closingAction$;
  }

  public doAction(action: WizardClosingAction): void {
    this.closingAction$.next(action);

    if (this.dialogRef) {
      this.dialogRef.close();
    }

    this.closingAction$.complete();
    this.closingAction$ = null;
  }

}
