import { ProductTypes } from '../../products/product-types';

export const UNITS_LIST_BY_PRODUCT_TYPE = {
  [ProductTypes.GOODS]: [
    {value: 'pcs',  label: 'FORM.PCS', typeGroupList: ['pcs']},
    {value: 'g',    label: 'g',  typeGroupList: ['g', 'kg', 't']},
    {value: 'kg',   label: 'kg', typeGroupList: ['g', 'kg', 't']},
    {value: 't',    label: 't',  typeGroupList: ['g', 'kg', 't']},
    {value: 'mm',   label: 'mm', typeGroupList: ['mm', 'cm', 'm']},
    {value: 'cm',   label: 'cm', typeGroupList: ['mm', 'cm', 'm']},
    {value: 'm',    label: 'm',  typeGroupList: ['mm', 'cm', 'm']},
    {value: 'ml',   label: 'ml', typeGroupList: ['ml', 'cl', 'l']},
    {value: 'cl',   label: 'cl', typeGroupList: ['ml', 'cl', 'l']},
    {value: 'l',    label: 'l',  typeGroupList: ['ml', 'cl', 'l']},
  ],
  [ProductTypes.SERVICES]: [
    {value: 'pcs',   label: 'FORM.PCS',   typeGroupList: ['pcs']},
    {value: 'h',     label: 'UNITS.H',   typeGroupList: ['h', 'min', 'pd', 'l.s.']},
    {value: 'min',   label: 'UNITS.MIN', typeGroupList: ['h', 'min', 'pd', 'l.s.']},
    {value: 'pd',    label: 'UNITS.PD',  typeGroupList: ['h', 'min', 'pd', 'l.s.']},
    {value: 'l.s.',  label: 'UNITS.LS',  typeGroupList: ['h', 'min', 'pd', 'l.s.']},
  ],
  [ProductTypes.DIGITAL]: [
    {value: 'pcs',  label: 'FORM.PCS', typeGroupList: ['pcs']},
    // {value: 'h', label: 'UNITS.H', typeGroupList: ['h']},
  ],
  [ProductTypes.ARBITRARY]: [
    {value: 'pcs',   label: 'FORM.PCS'},
    {value: 'h',     label: 'UNITS.H'},
    {value: 'pd',    label: 'UNITS.PD'},
    {value: 'min',   label: 'UNITS.MIN'},
    {value: 'l.s.',  label: 'UNITS.LS'},
    {value: 'g',     label: 'g'},
    {value: 'kg',    label: 'kg'},
    {value: 't',     label: 't'},
    {value: 'mm',    label: 'mm'},
    {value: 'cm',    label: 'cm'},
    {value: 'm',     label: 'm'},
    {value: 'ml',    label: 'ml'},
    {value: 'cl',    label: 'cl'},
    {value: 'l',     label: 'l'},
  ],
};
