import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { CommonModalDataModel } from '../common-modals.model';
import { CommonModalsActionsEnum } from '../common-modals-actions.enum';
import { SoundService } from '../../../../services/sound.service';

@Component({
  selector: 'rnpl-warning-modal',
  templateUrl: './warning-modal.component.html'
})
export class WarningModalComponent implements OnInit {

  constructor(
    private soundService: SoundService,
    public dialogRef: MatDialogRef<WarningModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonModalDataModel
  ) {

  }

  ngOnInit(): void {
    this.soundService.playSound('modal_warning');

    const overlay = document.querySelector<HTMLElement>('.cdk-overlay-container');
    overlay.style.zIndex = '1200';

    this.dialogRef.afterClosed().subscribe(() => {
      overlay.style.zIndex = '1000';
    });
  }

  public confirmAction(): void {
    this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
  }

}

