import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { TranslateModule } from '@ngx-translate/core';

import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { DiscountFormComponent } from './discount-form.component';
import { UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    DiscountFormComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UIComponentsModule,
    NgxCurrencyModule,
    TranslateModule
  ],
  exports: [
    DiscountFormComponent,
  ],
  entryComponents: [DiscountFormComponent],
  providers: [MetricConversionPipe]
})
export class DiscountFormModule { }
