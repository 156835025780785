import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FiltersControlsNamesEnum } from '../../enums';

@Component({
  selector: 'rnpl-filters-control-head',
  templateUrl: './filters-control-head.component.html',
  styleUrls: ['./filters-control-head.component.scss']
})
export class FiltersControlHeadComponent implements OnInit {

  @Input() public control: AbstractControl;
  @Input() public controlDateFrom: AbstractControl;
  @Input() public controlDateTo: AbstractControl;

  @Input()
  public title: string;

  @Input()
  public controlType: FiltersControlsNamesEnum;

  @Output() public controlReset: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  resetFilter(): void {
    if (this.controlType === FiltersControlsNamesEnum.DATES_RANGE) {
      this.controlDateFrom.reset();
      this.controlDateTo.reset();
    } else {
      this.control.reset();
    }
    this.controlReset.emit();
  }

}
