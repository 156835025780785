import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { AddressFormFactory } from '../../shared/forms/address-form.factory';
import { AddressModel, PaymentMethodsEnum } from 'common/src/models';
import { UpdateBillingInfoBlockValid } from '../store/actions/credit-note.actions';
import { AppState } from '../../store/state/app.state';
import { AddressTypeEnum } from '../../sales-order/enums/address-type.enum';

@Injectable({
  providedIn: 'root'
})
export class CreditNoteFormsService {

  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
  ) { }

  public initForm(creditNote): void {
    this.form = this.fb.group({
      partnerBic: [get(creditNote, 'billingInformation.partnerBic', null)],
      partnerIban: [get(creditNote, 'billingInformation.partnerIban', null)],
      bankAccount: [get(creditNote, 'billingInformation.bankAccount', null)],
      recipientName: [get(creditNote, 'billingInformation.recipientName', null)],
      paymentMethod: [get(creditNote, 'billingInformation.paymentMethod', null), {updateOn: 'change'}],
      bankTransferMethod: [get(creditNote, 'billingInformation.bankTransferMethod', null), {updateOn: 'change'}],
      subtract:[get(creditNote, 'billingInformation.subtract', false), {updateOn: 'change'}],
      reference: [get(creditNote, 'billingInformation.reference', '')],
      vatDisabled: [get(creditNote, 'properties.vatDisabled', ''), {updateOn: 'change'}],
      smallBusiness: [get(creditNote, 'properties.smallBusiness', ''), {updateOn: 'change'}],
      addressTemplate: [get(creditNote, 'billingInformation.addressTemplate')],
      billingAddress: AddressFormFactory.getForm(get(creditNote, 'billingInformation.billingAddress', {} as AddressModel))
    }, {updateOn: 'blur'});

    if (creditNote.billingInformation.paymentMethod === PaymentMethodsEnum.BANK_TRANSFER && !creditNote.billingInformation.subtract) {
      this.partnerIban.setValidators(Validators.required);
    } else {
      this.partnerIban.clearValidators();
    }

    this.partnerIban.updateValueAndValidity({emitEvent: false});

    if (this.form.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.form.get('billingAddress') as FormGroup);
      this.form.get('recipientName').disable({emitEvent: false});
      this.form.get('recipientName').updateValueAndValidity({emitEvent: false});
    } else {
      this.form.get('billingAddress').get('address_line').disable({emitEvent: false});
      this.form.get('billingAddress').get('address_line').updateValueAndValidity({emitEvent: false});
    }

    this.store.dispatch(UpdateBillingInfoBlockValid({ billingInfoBlockValid: this.form.valid }));
  }

  get recipientName(): FormControl { return this.form.get('recipientName') as FormControl; }
  get partnerIban(): FormControl { return this.form.get('partnerIban') as FormControl; }
  get partnerBic(): FormControl { return this.form.get('partnerBic') as FormControl; }
  get reference(): FormControl { return this.form.get('reference') as FormControl; }
  get billingAddress(): FormGroup { return this.form.get('billingAddress') as FormGroup; }
  get subtractControl(): FormControl { return this.form.get('subtract') as FormControl; }
  get bankAccountControl(): FormControl { return this.form.get('bankAccount') as FormControl; }
}
