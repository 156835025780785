export enum QuartersEnum {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export enum MonthEnum {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}

export enum HalfYearEnum {
  H1 = 'H1',
  H2 = 'H2',
}

export enum RoundingEnum {
  MIN15 = 'MIN15',
  MIN30 = 'MIN30',
  HOUR = 'HOUR',
}

export enum ReportsPeriodEnum {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  RANGE = 'RANGE',
  HALF_YEAR = 'HALF_YEAR',
}

export const MONTHS_LIST:  {[key: string]: string}[] = [
  {value: 'MONTHS.JAN', key: MonthEnum.JANUARY},
  {value: 'MONTHS.FEB', key: MonthEnum.FEBRUARY},
  {value: 'MONTHS.MAR', key: MonthEnum.MARCH},
  {value: 'MONTHS.APR', key: MonthEnum.APRIL},
  {value: 'MONTHS.MAY', key: MonthEnum.MAY},
  {value: 'MONTHS.JUN', key: MonthEnum.JUNE},
  {value: 'MONTHS.JUL', key: MonthEnum.JULY},
  {value: 'MONTHS.AUG', key: MonthEnum.AUGUST},
  {value: 'MONTHS.SEP', key: MonthEnum.SEPTEMBER},
  {value: 'MONTHS.OCT', key: MonthEnum.OCTOBER},
  {value: 'MONTHS.NOV', key: MonthEnum.NOVEMBER},
  {value: 'MONTHS.DEC', key: MonthEnum.DECEMBER},
];

export const PERIODS_LIST: {[key: string]: string}[] = [
  {key: ReportsPeriodEnum.MONTH, value: 'FORM.MONTH'},
  {key: ReportsPeriodEnum.QUARTER, value: 'FORM.QUARTER'},
  {key: ReportsPeriodEnum.HALF_YEAR, value: 'FORM.6_MONTH'},
  {key: ReportsPeriodEnum.YEAR, value: 'FORM.YEAR'},
  {key: ReportsPeriodEnum.RANGE, value: 'COMMON.USER_DEFINED'},
];

export const QUARTERS_LIST: {[key: string]: string}[] = [
  {key: QuartersEnum.Q1, value: 'RANGES.JAN_MAR'},
  {key: QuartersEnum.Q2, value: 'RANGES.APR_JUN'},
  {key: QuartersEnum.Q3, value: 'RANGES.JUL_SEP'},
  {key: QuartersEnum.Q4, value: 'RANGES.OCT_DEC'},
];

export const HALF_YEAR_LIST: {[key: string]: string}[] = [
  {key: HalfYearEnum.H1, value: 'RANGES.HALF_FIRST'},
  {key: HalfYearEnum.H2, value: 'RANGES.HALF_SECOND'},
];

export const ROUND_UP_LIST: {[key: string]: string}[] = [
  {key: RoundingEnum.MIN15, value: 'FORM.15_MIN'},
  {key: RoundingEnum.MIN30, value: 'FORM.30_MIN'},
  {key: RoundingEnum.HOUR, value: 'FORM.HOUR'},
];
