import { createAction, props } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { UIStatesEnum } from 'common/src/models';
import { PartnersListState } from '../reducers';
import { PartnerModel } from '../../models/partner.model';
import { PartnersListTabsEnum } from '../../enums/partners-list-tabs.enum';
import { Validation } from 'projects/workspace/src/app/shared/models/response';
import { Title } from 'projects/workspace/src/app/administration/models/company-profile.model';

export const LoadPartnersList = createAction(
  '[Corporate Partner] Load Partners List',
  props<{ partnerListData: PartnersListState, status: PartnersListTabsEnum }>()
);

export const LoadPartner = createAction(
  '[Corporate Partner] Load Partner',
  props<{ partner: PartnerModel }>()
);

export const LoadCountries = createAction(
  '[Corporate Partner] Load Countries',
  props<{ countries: CountryModel[] }>()
);

export const UpdatePartnerState = createAction(
  '[Corporate Partner] Update Partner Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdatePartnerUpdatedAt = createAction(
  '[Corporate Partner] Update Partner updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Corporate Partner] Update Partner should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const ClearPartnerUpdatedAt = createAction(
  '[Corporate Partner] Clear Partner updatedAt'
);

export const LoadTitles = createAction(
  '[Corporate Partner] Load Titles',
  props<{ titles: Title[] }>()
);

export const UpdateValidations = createAction(
  '[Corporate Partner] Update Validations',
  props<{ validations: Validation[] }>()
);

export const ResetValidations = createAction(
  '[Corporate Partner] Reset Validations'
);

export const IncrementLoadingRequestsCount = createAction(
  '[Corporate Partner] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Corporate Partner] Decrement Loading Requests Count'
);
