import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { DocumentTypesUppercaseEnum } from '../link-document-modal/enums/ducument-types.enum';
import { ScheduledPaymentApiService } from 'projects/workspace/src/app/scheduled-payment/services/scheduled-payment-api.service';
import { IncomingInvoiceApiService } from 'projects/workspace/src/app/incoming-invoice/services/invoice-api.service';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';
import { CommonModalsActionsEnum } from '../common-modals-actions.enum';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';


@Component({
  selector: 'rnpl-autocorrection-modal',
  templateUrl: './autocorrection-modal.component.html',
  styleUrls: ['./autocorrection-modal.component.scss'],
})
export class AutocorrectionModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public currency = '€';

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public fb: FormBuilder,
    public incomingInvoiceApiService: IncomingInvoiceApiService,
    public creditNoteApiService: CreditNoteApiService,
    public scheduledPaymentApiService: ScheduledPaymentApiService,
    public purchaseOrderApiService: PurchaseOrderApiService,
    public dialogRef: MatDialogRef<AutocorrectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentId: number,
      currency: string;
      // vatBlocked: boolean;
      documentAmountNet: number;
      documentAmountGross: number;
      documentVat: number;
      // targetAmountNet?: number;
      // targetAmountGross?: number;
      // targetVat?: number;
      documentType: DocumentTypesUppercaseEnum,
    }
  ) {

  }

  ngOnInit(): void {
    this.initForm();
    if (this.data.currency) { this.currency = CURRENCIES_SYMBOLS_BY_ISO_CODE[this.data.currency]; }

    // if (!!+this.data.targetAmountNet || !!+this.data.targetAmountGross) {
    //   this.targetAmountNetControl
    //     .patchValue(this.convertCurrency ? this.setCurrencyValue(this.data.targetAmountNet) : this.data.targetAmountNet);
    //
    //   this.targetAmountGrossControl
    //     .patchValue(this.convertCurrency ? this.setCurrencyValue(this.data.targetAmountGross) : this.data.targetAmountGross);
    //
    //   this.targetVatControl
    //     .patchValue(this.convertCurrency ? this.setCurrencyValue(this.data.targetVat) : this.data.targetVat);
    // }
  }

  private initForm(): void {
    this.form = this.fb.group({
      targetAmountNet: [],
      targetAmountGross: [],
      targetVat: [],
    });
  }

  public submit(): void {
    let request$: Observable<any>;

    switch (this.data.documentType) {
      case DocumentTypesUppercaseEnum.IIN:
        request$ = this.incomingInvoiceApiService.createCorrectionPositions(this.data.documentId, this.form.getRawValue());
        break;
      case DocumentTypesUppercaseEnum.ICN:
        request$ = this.creditNoteApiService.createCorrectionPositions(this.data.documentId, this.form.getRawValue());
        break;
      case DocumentTypesUppercaseEnum.SP:
        request$ = this.scheduledPaymentApiService.createCorrectionPositions(this.data.documentId, this.form.getRawValue());
        break;
      case DocumentTypesUppercaseEnum.PO:
        request$ = this.purchaseOrderApiService.createCorrectionPositions(
          this.data.documentId,
          {
            targetAmountNet: this.getCurrencyValue(this.form.getRawValue().targetAmountNet),
            targetAmountGross: this.getCurrencyValue(this.form.getRawValue().targetAmountGross),
            targetVat: this.getCurrencyValue(this.form.getRawValue().targetVat),
          }
        );
        break;
    }

    if (this.submitRequest$.getValue() || !request$) { return; }
    this.submitRequest$.next(true);

    request$
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
  }

  // public setCurrencyValue(value): number {
  //   if (!value) { return value;}
  //   return +(value / 1e2).toFixed(2);
  // }

  public getCurrencyValue(value): number {
    if (!value) { return value; }
    return +(value * 100).toFixed(0);
  }

  get convertCurrency(): boolean {
    return this.data.documentType === DocumentTypesUppercaseEnum.PO;
  }

  get targetAmountNetControl(): FormControl { return this.form.get('targetAmountNet') as FormControl; }
  get targetAmountGrossControl(): FormControl { return this.form.get('targetAmountGross') as FormControl; }
  get targetVatControl(): FormControl { return this.form.get('targetVat') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

