import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-column-with-btn-add-remove',
  templateUrl: './column-with-btn-add-remove.component.html',
  styleUrls: ['./column-with-btn-add-remove.component.scss']
})
export class ColumnWithBtnAddRemoveComponent implements OnChanges {

  @Input() value: 'add' | 'remove';

  @Input() row: any;

  @Input() column: any;

  @Output() addOrRemoveClick: EventEmitter<string> = new EventEmitter<string>();

  public disabled = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('row')) {
      this.disabled = false;
      this.updateDisabledStatus();
    }
  }

  public updateDisabledStatus(): void {
    if (this.row.disabled.fully) {
      this.disabled = true;
      return;
    }

    if (get(this.row, 'disabled.fields.length')) {

      if (this.row.disabled.fields.filter((el) => el === this.column.prop).length) {

        this.disabled = true;
      }
    }
  }

  public onClickButton(type: 'add' | 'remove'): void {
    if (this.disabled) { return; }
    this.addOrRemoveClick.emit(type);
  }
}
