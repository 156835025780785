import { AttributeModel } from '../models';
import { AttributeForm } from './attribute-form.factory';

export class AttributeRelations {
  public entityTypes: Array<string> = [];
  public entities: Array<string> = [];
  public forms: Array<string> = [];
  public families: Array<string> = [];
}

export class RelationHelpers {

  public static splitEntitiesByAttribute(attribute: AttributeModel, entities: any): AttributeRelations {
    const relations = new AttributeRelations();

    Object.keys(entities).forEach(type => {
      Object.keys(entities[type]).forEach(entity => {

        entities[type][entity].forEach(entityForm => {
          const relatedForms = attribute.business_form_relations
            .filter(relation => relation.business_form === entityForm)
            .map(relation => relation.business_form);

          if (relatedForms.length) {
            if (!relations.entityTypes.includes(type)) {
              relations.entityTypes.push(type);
            }

            if (!relations.entities.includes(entity)) {
              relations.entities.push(entity);
            }

            relations.forms = [...relations.forms, ...relatedForms];
          }
        });
      });
    });

    return relations;
  }

  public static entityKeyToLabel(key: string): string {
    const label = key
      .replace(new RegExp(`^[a-z]+_`), '')
      .replace(/_/g, ' ');

    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  public static formKeyToLabel(key: string): string {
    const label = key.split('_').slice(1).join(' ');

    return label.charAt(0).toUpperCase() + label.slice(1) + ' form';
  }
}

export const mapFormToAttribute = (form: AttributeForm) => {
  const attribute: AttributeModel = {
    ...form.generalSettings.getRawValue(),
    ...form.controlSetup.getRawValue(),
    ...form.translations.getRawValue()
  };

  attribute.localizations = form.localization.get('localizations').value
    .map(country => {
      return {country_id: country.id};
    });

  attribute.business_form_relations = form.relations.get('forms').value
    .map(currForm => {
      return {business_form: currForm};
    });

  return attribute;
};
