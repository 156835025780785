import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';

import { ControlDefinitionModel } from './control-definition.model';
import { DynamicFormService } from './dynamic-form.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'form[rnpl-form], rnpl-form',
  template: `
<div *ngFor="let formRow of formGrid" class="row">
  <div *ngFor="let controlDef of formRow" [class]="controlDef.class">
    <ng-container [rnplControlDef]="controlDef" [formGroup]="formGroup"></ng-container>
  </div>
</div>
<ng-content></ng-content>
`,
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges, OnDestroy {

  private formUpdatedSubscription: Subscription;

  @Input()
  public formGroup: FormGroup = new FormGroup({});

  @Input()
  public displayErrors: boolean = false;

  @Input()
  public set controls(controls: Array<ControlDefinitionModel>) {
    this._controls = controls;
    this.initGrid(this._controls);
  }

  @Input()
  public data: any = {};

  @Output() formUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.has-errors')
  get hasErrors() {
    return this.formGroup.invalid && this.displayErrors;
  }

  public formGrid: Array<any>;

  private _controls: Array<ControlDefinitionModel> = [];

  constructor(
    private formService: DynamicFormService,
    private translateService: TranslateService,
  ) {

  }

  ngOnInit(): void {
    this.formUpdatedSubscription = this.formService.formUpdated$
      .pipe(skip(1))
      .subscribe(value => {
      this.formUpdated.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      this.initGrid(this._controls);
    }
  }

  ngOnDestroy(): void {
    this.formUpdatedSubscription.unsubscribe();
  }

  private initGrid(controls: Array<ControlDefinitionModel>): void {
    if (!this.formGroup) {
      this.formGroup = new FormGroup({});
    }

    this.formGrid = [[]];
    this.formGrid = controls.reduce((grid, control, index) => {

      if (this.data && this.data[control.key]) {
        control.value = this.data[control.key];
      } else {
        control.value = null;
      }

      const lastRow = grid.pop();

      let attrClass;
      switch (control.layout.size) {
        case 'small':
          attrClass = 'col-md-4';
          break;

        case 'wide':
          attrClass = 'col-md-12';
          break;

        default:
          attrClass = 'col-md-6';
      }
      control.class = attrClass;

      lastRow.push(control);

      grid.push(lastRow);

      if (control.layout.isLast && index < controls.length - 1) {
        grid.push([]);
      }

      return grid;
    }, [[]]);
  }

  public getCollectiveValue(): string {
    let collectiveValue = '';

    this._controls.forEach(control => {
      const controlValue = this.formGroup.get(control.key).value;
      if (this.formGroup && this.formGroup.get(control.key) && controlValue) {
        if (collectiveValue) {
          collectiveValue += ' ';
        }
        if (controlValue === true) {
          collectiveValue += `${control.label} - ${this.translateService.instant('FORM.ENABLED')}`;
          return;
        }
        collectiveValue += `${control.label} - ${controlValue}`;
      }
    });

    return collectiveValue;
  }
}
