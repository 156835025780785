import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { MatSidenav } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { get, isEmpty, isEqual } from 'lodash';
import { BehaviorSubject, interval, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { ShepherdService } from 'angular-shepherd';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AbstractAppComponent } from 'common/src/interfaces';
import { AuthService } from 'common/src/auth/auth.service';
import { AppService } from './app.service';
import { AppContextService } from 'common/src/modules/rnpl-common/services';
import { AdministrationsApiService } from './administration/services/administrations-api.service';
import { AppState } from './store/state/app.state';
import { CompanyProfile } from './administration/models/company-profile.model';
import { selectCompanyProfile } from './administration/store/selectors';
import { LoadCountries } from './store/actions/shared.actions';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';
import { RootLinkState } from './store/reducers/link.reducer';
import { getLinkQueueState } from './store/selectors/app.selectors';
import { ClearLinkQueue, RemoveLastLink, SaveLink } from './store/actions/router.actions';
import { LoadCategories } from './store/actions/shared.actions';
import { AuthUserModel } from 'common/src/auth/auth-user.model';
import { HrmService } from './hrm/hrm.service';
import { routeScaleAnimation } from './route-animation';
import { LaunchpadApiService } from 'common/src/modules/start-screen-page/launchpad-api.service';

@Component({
  selector: 'rnpl-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeScaleAnimation],
  providers: [{provide: AbstractAppComponent, useExisting: AppComponent}]
})
export class AppComponent extends AbstractAppComponent implements OnInit, AfterViewInit {

  public projectName = 'workspace';

  public isUserAuthorized: boolean = false;
  public companyProfileLoaded: boolean = false;
  public userShortcutsLoaded: boolean = false;
  public userQuickActionsLoaded: boolean = false;
  public screenItemsLoaded: boolean = false;
  public isHideHeader: boolean = false;
  public isInitialSetup: boolean = false;
  public isDarkBackground: boolean = false;
  public loggedUser: AuthUserModel;
  public isMobile: boolean;

  private linkQueue$: BehaviorSubject<RootLinkState[]> = new BehaviorSubject(null);

  readonly companyProfile$: BehaviorSubject<CompanyProfile> = new BehaviorSubject<CompanyProfile>(null);

  private updateTimerInterval = 300 * 10e2 // 5 minutes

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  @ViewChild(MatSidenav, {static: false}) public sideNav: MatSidenav;
  @ViewChild('barcode', {static: false}) public barcodeRef;

  private defaultStepOptions: object = {
    classes: 'system-hints-container',
    scrollTo: {
      block: 'center',
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 17] }}]
    },
    exitOnEsc: false,
    keyboardNavigation: false,
    useModalOverlay: false,
    buttons: [
      {
        text: 'Got it <i class="icon icon-checkmark ml-40"></i>',
        classes: 'btn btn-white yellow center',
        action: function() {
          return this.next();
        }
      },
      {
        text: this.translate.instant('MEGA_HINT.SCIP_HINTS_BTN'),
        classes: 'skip-hints',
        type: 'cancel',
        action: function() {
          return this.cancel();
        }
      },
    ],
  };

  constructor(private router: Router,
              private appService: AppService,
              private administrationsApiService: AdministrationsApiService,
              private hrmService: HrmService,
              private appContext: AppContextService,
              public authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private readonly store: Store<AppState>,
              private actionButtonService: ActionButtonsService,
              private translate: TranslateService,
              private shepherdService: ShepherdService,
              private launchpadApiService: LaunchpadApiService,
              private deviceService: DeviceDetectorService,
  ) {
    super();

    this.isMobile = this.deviceService.isMobile();
    this.loggedUser = this.authService.getUser();
    // if (loggedUser && loggedUser.lang) {
    //   translate.use(loggedUser.lang);
    // } else {
    //   const browserLang = translate.getBrowserLang();
    //   translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    // }
    //
    // window.addEventListener('storage', function(event) {
    //   const newStorage = JSON.parse(event.newValue);
    //   if (newStorage && newStorage.lang) {
    //     translate.use(newStorage.lang);
    //   }
    // });

    if (this.loggedUser && this.loggedUser.lang) {
      translate.use(this.loggedUser.lang);
    } else {
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    }
    // translate.use('de');
  }

  ngAfterViewInit() {
    this.shepherdService.defaultStepOptions = this.defaultStepOptions;
  }

  ngOnInit(): void {
    this.appContext.setToContext('application', this.projectName);

    this.selectCompanyProfileFromStore();

    this.store.select(getLinkQueueState)
       .pipe(takeUntil(this.destroy$))
       .subscribe((linkQueue) => {
        this.linkQueue$.next(linkQueue);
    });

    this.router.events
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {

          this._handleRoutingLinkWithStore(this.router.url);

          this.isUserAuthorized = this.authService.isUserAuthorized();

          // update logged user after sign in
          if (!this.loggedUser) {
            this.loggedUser = this.authService.getUser();
          }

          this.isHideHeader = get(this.activatedRoute, 'snapshot.firstChild.data.hideHeader', false);
          this.isInitialSetup = get(this.activatedRoute, 'snapshot.firstChild.data.isInitialSetup', false);
          this.isDarkBackground = get(this.activatedRoute, 'snapshot.firstChild.data.darkBg', false);

          // get user shortcuts
          if (this.isUserAuthorized && this.loggedUser) {
            if (!this.userQuickActionsLoaded) { this.getUserModulesQuickActions(); }
            if (!this.userShortcutsLoaded) { this.getUserModulesShortcuts(); }
            if (!this.screenItemsLoaded) { this.getScreenItems(); }
          }

          if (this.isUserAuthorized && !this.companyProfileLoaded) {
            this.getCompanyProfile();
          }
        }
      });

      this.startUpdateInterval();
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  public getScreenItems(): void {
    this.launchpadApiService.getStartScreenApps()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.screenItemsLoaded = true);
  }

  public startUpdateInterval() {
    interval(this.updateTimerInterval)
      .pipe(
        startWith(0),
        filter(() => this.authService.isUserAuthorized()),
        tap(() => {
          this.store.dispatch(LoadCountries());
          this.store.dispatch(LoadCategories());
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public selectCompanyProfileFromStore() {
    this.store
      .select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        filter(profile => !isEmpty(profile)),
        takeUntil(this.destroy$)
      )
      .subscribe((profile: CompanyProfile) => {
        this.companyProfile$.next(profile);
        if (get(profile, 'printLogo.urlOriginal')) {
          localStorage.setItem('companyLogo', profile.printLogo.urlOriginal);
        }
      });
  }

  private getCompanyProfile(): void {
    if (!this.companyProfile) { return; }

    this.administrationsApiService.getLegalCountryTranslate(this.companyProfile.legalAddress.country_iso3)
      .pipe(takeUntil(this.destroy$))
      .subscribe();

    if (this.companyProfile) {
      this.companyProfileLoaded = true;
    } else {
      this.administrationsApiService.getCompanyProfile()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.companyProfileLoaded = true);
    }
  }

  private getUserModulesShortcuts() : void {
    this.hrmService.getUserModulesShortcuts(this.loggedUser.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.userShortcutsLoaded = true);
  }

  private getUserModulesQuickActions() : void {
    this.hrmService.getUserModulesQuickActions(this.loggedUser.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.userQuickActionsLoaded = true);
  }

  private _handleRoutingLinkWithStore(currentUrl) {
    const linksFromStore = this.linkQueue$.getValue();
    const previousUrlFromStore = linksFromStore.length && linksFromStore[linksFromStore.length-1].link;
    const splittedCurrentUrl = currentUrl.split('/');
    const splittedPreviousUrl = previousUrlFromStore && previousUrlFromStore.split('/');

    if (this._isCurrentLinkInStore(linksFromStore, this.router.url)) {
      if (linksFromStore.length > 1) {
        this.store.dispatch(RemoveLastLink());
      }
      return;
    }

    if (splittedCurrentUrl.some(link => link === 'list')) {
      if (this._hasListLinkInStore(linksFromStore)) {
        this.store.dispatch(ClearLinkQueue());
      }
      this.store.dispatch(SaveLink({ linkState: { link: this.router.url, status: 'list'}}));
    } else {
      if (splittedPreviousUrl && splittedPreviousUrl.some(link => link === 'list')) {
        this.store.dispatch(SaveLink({ linkState: { link: this.router.url, status: 'view'}}));
      } else {
        if (splittedPreviousUrl && this._isTheSameModuleLink(splittedCurrentUrl, splittedPreviousUrl) && this._hasListLinkInStore(linksFromStore)) {
          this.store.dispatch(RemoveLastLink());
        }
        this.store.dispatch(SaveLink({ linkState: { link: this.router.url, status: 'view'}}));
      }
    }
  }

  private _hasListLinkInStore(linksQueue) {
    if (!linksQueue.length) {
      return false;
    }

    return linksQueue.filter(link => link.status === 'list');
  }

  private _isTheSameModuleLink(splittedCurrentLink, splittedPreviousLink) {
    let isTheSame = false;
    for (let i = 0; i < splittedCurrentLink.length-2; i++) {
      isTheSame = (splittedCurrentLink[i] === splittedPreviousLink[i]);
    }
    return isTheSame;
  }

  private _isCurrentLinkInStore(linksQueue, currentLink) {
    return linksQueue.some(link => {
      return link.link === currentLink
    });
  }

  public actionHandler(action: string) {
    if (action && this.actionButtonService[action]) {
      this.actionButtonService[action]();
    } else {
      console.warn(`Can not call quick action with ${action}, it doesn't exist`)
    }
  }

  get companyProfile() { return this.companyProfile$.getValue(); }

  get isClosedLimitedModal(): boolean {
    return !!localStorage.getItem('isClosedLimitedModal');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
