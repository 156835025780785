import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { CurrencyModel } from '../../../payment/models/currency.model';
import { SchedulesPaymentsListState } from '../reducers';
import { ScheduledPaymentListTabsEnum } from '../../enums';
import { ScheduledPaymentBillingPeriodModel, ScheduledPaymentModel } from '../../models';

export const LoadScheduledPaymentsList = createAction(
  '[Scheduled Payment] Load Scheduled Payments List',
  props<{ scheduledPaymentListData: SchedulesPaymentsListState, status: ScheduledPaymentListTabsEnum, page: number }>()
);

export const LoadScheduledPayment = createAction(
  '[Scheduled Payment] Load Scheduled Payment',
  props<{ scheduledPayment: ScheduledPaymentModel }>()
);

export const UpdateScheduledPaymentState = createAction(
  '[Scheduled Payment] Update Scheduled Payment Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateScheduledPaymentAttachmentsCount = createAction(
  '[Scheduled Payment] Update Scheduled Payment Attachments Count',
  props<{ attachmentsCount: number }>()
);

export const LoadCurrencies = createAction('[Scheduled Payment] Load Currencies', props<{ currencies: CurrencyModel[] }>());

export const UpdateScheduledPaymentUpdatedAt = createAction(
  '[Scheduled Payment] Update Scheduled Payment updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Scheduled Payment] Update Scheduled Payment should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadScheduledPaymentPeriods = createAction(
  '[Scheduled Payment] Load Scheduled Payment periods',
  props<{ periods: ScheduledPaymentBillingPeriodModel[] }>()
);

export const UpdateScheduledPaymentSyncPeriods = createAction(
  '[Scheduled Payment] Update Scheduled Payment sync periods',
  props<{ syncPeriods: { sync: boolean } }>()
);

export const ClearScheduledPaymentUpdatedAt = createAction('[Scheduled Payment] Clear Scheduled Payment updatedAt');

export const IncrementLoadingRequestsCount = createAction('[Scheduled Payment] Increment Loading Requests Count');

export const DecrementLoadingRequestsCount = createAction('[Scheduled Payment] Decrement Loading Requests Count');


export const UpdatePropertiesBlockValid = createAction(
  '[Scheduled Payment] Update Scheduled Payment Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Scheduled Payment] Update Scheduled Payment Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Scheduled Payment] Update Scheduled Payment Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
