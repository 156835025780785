import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
// import { MatMenuModule } from '@angular/material';

import { FilesAttachmentComponent } from './files-attachment.component';
import { RnplCommonModule } from 'common/src/modules';
import { FilesAttachmentApiService } from './files-attachment-api.service';
// import { UserColumnsSettingsService } from '../../services/user-columns-settings.service';


@NgModule({
  declarations: [
    FilesAttachmentComponent
  ],
  exports: [
    FilesAttachmentComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    RouterModule,
    // MatMenuModule,
    TranslateModule
  ],
  providers: [
    FilesAttachmentApiService
    // UserColumnsSettingsService
  ]
})
export class FilesAttachmentModule { }
