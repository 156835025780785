import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TradePricesApiService } from 'projects/workspace/src/app/trade-prices/services/trade-prices-api.service';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { MetricConversionPipe } from '../../../rnpl-common';
import { ProductTypes } from '../../../products/product-types';
import { InfoItemModel, InfoItemType } from '../../../ui-components/info-components/models/info-item.model';
import { CommonModalsActionsEnum } from '../../modals-common';
import { ProductModel } from '../../../products';

@Component({
  selector: 'rnpl-product-price-modal',
  templateUrl: './product-price-modal.component.html'
})
export class ProductPriceModalComponent extends BaseModalComponent implements OnInit {

  public form: FormGroup = this.fb.group({
    netPrice: [],
    vat: [],
    grossPrice: [],
    marginRate: [],
    marginNet: [],
  });

  public localCurrency: string = 'EUR';
  public readonly vatList = VAT_LIST;
  public readonly productTypes = ProductTypes;

  public purchaseDataInfo: InfoItemModel[];

  constructor(
    public toasterService: ToasterService,
    public dialog: MatDialog,
    public metricPipe: MetricConversionPipe,
    public tradePricesApiService: TradePricesApiService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<ProductPriceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      product: ProductModel,
      productType: ProductTypes,
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    if (!this.waPurchasePrice) {
      this.marginNetControl.disable({emitEvent: false});
      this.marginRateControl.disable({emitEvent: false});
    }

    this.getWaPurchaseData();
    this.form.patchValue(this.data.product.prices.price, {emitEvent: false});

    this.netPriceControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => this.netPriceFilled(value));

    this.vatControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => this.vatFilled(value));

    this.grossPriceControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => this.grossPriceFilled(value));

    this.marginNetControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => this.marginNetFilled(value));

    this.marginRateControl.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe(value => this.marginRateFilled(value));
  }

  public netPriceFilled(netUnitPrice: number): void {
    if (!netUnitPrice && netUnitPrice !== 0) {
      this.grossPriceControl.setValue(null, {emitEvent: false});
      if (!this.waPurchasePrice) {
        this.marginNetControl.setValue(null, {emitEvent: false});
        this.marginRateControl.setValue(null, {emitEvent: false});
      }
      return;
    }

    if (this.vatMultiplier || this.vatMultiplier === 0) {
      this.grossPriceControl.setValue(+(netUnitPrice * this.vatMultiplier).toFixed(2), {emitEvent: false});
    }

    if (this.waPurchasePrice) {
      this.marginNetControl.setValue(+(this.netPriceControl.value - (this.waPurchasePrice / 100)).toFixed(2), {emitEvent: false});
      this.marginRateControl.setValue(+(this.marginNetControl.value * 100 / (this.waPurchasePrice / 100)).toFixed(2), {emitEvent: false});
    }
  }

  public grossPriceFilled(gross: number): void {
    if (!gross && gross !== 0) { return; }

    if (this.vatMultiplier || this.vatMultiplier === 0) {
      this.netPriceControl.setValue(+(gross / this.vatMultiplier).toFixed(2), {emitEvent: false});
    }

    if (this.waPurchasePrice) {
      this.marginNetControl.setValue(+(this.netPriceControl.value - (this.waPurchasePrice / 100)).toFixed(2), {emitEvent: false});
      this.marginRateControl.setValue(+(this.marginNetControl.value * 100 / (this.waPurchasePrice / 100)).toFixed(2), {emitEvent: false});
    }
  }

  public vatFilled(vat: number): void {
    if (!vat && vat !== 0) { return; }

    if (this.vatMultiplier && this.netPriceControl.value) {
      this.grossPriceControl.setValue(+(this.netPriceControl.value * this.vatMultiplier).toFixed(2), {emitEvent: false});
    }
  }

  public marginNetFilled(marginNet: number): void {
    if (!marginNet && marginNet !== 0) { return; }

    this.netPriceControl.setValue(+((this.waPurchasePrice / 100) +  marginNet).toFixed(2), {emitEvent: false});
    this.grossPriceControl.setValue(+(this.netPriceControl.value * this.vatMultiplier).toFixed(2), {emitEvent: false});
    this.marginRateControl.setValue(+(this.marginNetControl.value * 100 / (this.waPurchasePrice / 100)).toFixed(2), {emitEvent: false});
  }

  public marginRateFilled(marginRate: number): void {
    if (!marginRate && marginRate !== 0) { return; }

    this.marginNetControl.setValue(+((this.waPurchasePrice / 100) * marginRate / 100).toFixed(2), {emitEvent: false});
    this.netPriceControl.setValue(+((this.waPurchasePrice / 100) +  this.marginNetControl.value).toFixed(2), {emitEvent: false});
    this.grossPriceControl.setValue(+(this.netPriceControl.value * this.vatMultiplier).toFixed(2), {emitEvent: false});
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public getWaPurchaseData(): void {
    this.purchaseDataInfo = [{
      label: 'COLUMN.WA_PURCHASE_PRICE',
      value: this.waPurchasePrice,
      type: InfoItemType.WITH_CURRENCY,
      tableItem: true,
      currencyConversion: true
    }];
  }

  public submit(): void {
    const formValue = this.form.getRawValue();
    const convertedFormValue = {
      net_price: formValue.netPrice ? this.toInteger(formValue.netPrice) : null,
      gross_price: formValue.grossPrice ? this.toInteger(formValue.grossPrice) : null,
      margin_net: (formValue.marginNet || formValue.marginNet === 0) ? this.toInteger(formValue.marginNet) : null,
      margin_rate: formValue.marginRate,
      vat: formValue.vat,
    };

    this.tradePricesApiService.updateProductPrices(+this.data.product.id, convertedFormValue)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
  }

  get vatMultiplier(): number {
    if (this.vatControl.value === 0) { return 1; }
    return this.vatControl.value ? this.vatControl.value / 100 + 1 : null;
  }

  get waPurchasePrice(): number {
    return get(this, 'data.product.prices.price.waPurchasePriceNet', null);
  }

  get vatControl(): FormControl { return this.form.get('vat') as FormControl; }
  get netPriceControl(): FormControl { return this.form.get('netPrice') as FormControl; }
  get grossPriceControl(): FormControl { return this.form.get('grossPrice') as FormControl; }
  get marginNetControl(): FormControl { return this.form.get('marginNet') as FormControl; }
  get marginRateControl(): FormControl { return this.form.get('marginRate') as FormControl; }

}
