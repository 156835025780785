import { createAction, props } from '@ngrx/store';
import { CompanyProfile, LegalType, Title } from '../../models/company-profile.model';
import { CountryModel } from 'common/src/modules/rnpl-common';
import { Validation } from '../../../shared/models/response';
import { UIStatesEnum } from 'common/src/models';
import { OnboardingModel } from '../../models/onboarding.model';


export const LoadCompanyProfile = createAction(
  '[Company Profile] Load Company Profile',
  props<{ companyProfile: CompanyProfile }>()
);

export const LoadOnboarding = createAction(
  '[Company Profile] Load Onboarding',
  props<{ onboarding: OnboardingModel }>()
);

export const UpdateCompanyProfile = createAction(
  '[Company Profile] Update Company Profile',
  props<{ companyProfile: CompanyProfile }>()
);

export const LoadCompanyProfileCountries = createAction(
  '[Company Profile] Load Company Profile Countries',
  props<{ countries: CountryModel[] }>()
);

export const LoadLegalTypes = createAction(
  '[Company Profile] Load Legal Types',
  props<{ legalTypes: LegalType[] }>()
);

export const LoadTitles = createAction(
  '[Company Profile] Load Titles',
  props<{ titles: Title[] }>()
);

export const UpdateValidations = createAction(
  '[Company Profile] Update Validations',
  props<{ validations: Validation[] }>()
);

export const ResetValidations = createAction(
  '[Company Profile] Reset Validations'
);

export const IncrementLoadingRequestsCount = createAction(
  '[Company Profile] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Company Profile] Decrement Loading Requests Count'
);

export const UpdateSaveAction = createAction(
  '[Company Profile] Update Save Action',
  props<{ saveAction: boolean }>()
);

export const UpdateBtnToClearLoadingStatus = createAction(
  '[Company Profile] Update Btn To Clear Loading Status',
  props<{ actionName: string }>()
);

// export const UpdateSaveActionDisabled = createAction(
//   '[Company Profile] Update Save Action Disabled',
//   props<{ saveActionDisabled: boolean }>()
// );
//
// export const UpdateSaveActionDisabledAllowed = createAction(
//   '[Company Profile] Update Save Action Disabled Allowed',
//   props<{ saveActionDisabledAllowed: boolean }>()
// );

export const UpdateProfileUpdatedAt = createAction(
  '[Company Profile] Update Company Profile updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearProfileUpdatedAt = createAction(
  '[Company Profile] Clear Company Profile updatedAt',
);

export const LoadLegalCountryTranslate = createAction(
  '[Company Profile] Load Legal Country Translate',
  props<{ legalCountryTranslate: string }>()
);

// export const UpdateProfileCurrentState = createAction(
//   '[Company Profile] Update Company Profile Current State',
//   props<{ currentState: UIStatesEnum }>()
// );
