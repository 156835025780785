import { Component, OnInit } from '@angular/core';


import { NavigationItemModel } from 'common/src/models/navigation-item.model';
import { ModuleOutlet } from 'common/src/modules/rnpl-common/components';
import { MODULE_MENU_ITEMS } from '../../module-menu-items';
import { RnplModuleService } from '../../../rnpl-common/services';


@Component({
  selector: 'rnpl-entities-management',
  templateUrl: './entities-management.component.html',
  styleUrls: ['./entities-management.component.scss']
})
export class EntitiesManagementComponent extends ModuleOutlet implements OnInit{

  appName = 'entities-management';

  public navItems: NavigationItemModel = MODULE_MENU_ITEMS;

  constructor(public moduleService: RnplModuleService) {
    super();
  }
}
