import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { catchError, debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { ProductTypes } from '../../../products/product-types';
import {
  SalesPosition,
  SalesPositionsByProductType
} from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { CommonModalsActionsEnum, DangerModalComponent, WarningModalComponent } from '../../modals-common';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { ProductUnitModel, ResponseModel } from 'projects/workspace/src/app/shared/models';
import { MetricConversionPipe } from '../../../rnpl-common';
import { selectSalesOrderCurrentState, selectSalesOrderPositions } from 'projects/workspace/src/app/sales-order/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { generatePositionsCardsInfo } from 'projects/workspace/src/app/sales-order/pages/sales-order-positions/positions.config';
import { TableColumnModel } from '../../../../models/table-column.model';
import { UIStatesEnum } from '../../../../models';
import { PositionsActionsType } from '../../../ui-components/table/custom-table.enums';
import { REMOVE_POSITION_MODAL_DATA } from '../../modals.contsans';
import { SalesOrderListTabsEnum } from 'projects/workspace/src/app/sales-order/enums';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-sales-order-edit-position-modal',
  templateUrl: './sales-order-edit-position-modal.component.html',
  styleUrls: ['./sales-order-edit-position-modal.component.scss']
})
export class SalesOrderEditPositionModalComponent implements OnInit, OnDestroy {

  public productTypes: typeof ProductTypes = ProductTypes;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public position: SalesPosition;
  public positions: SalesPosition[];
  public rowIndex: number;
  public isReadonly: boolean = false;
  public showControls: boolean = true;
  public positionsByProductType: {[key in ProductTypes]?: any[]};

  public positionsCardInfoByTypes: {[key in ProductTypes]?: any[]} = {
    [ProductTypes.GOODS]: [] as TableColumnModel[],
    [ProductTypes.SERVICES]: [] as TableColumnModel[],
    [ProductTypes.DIGITAL]: [] as TableColumnModel[],
  };
  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];

  public positionsUpdateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public store: Store<AppState>,
    public dialogRef: MatDialogRef<SalesOrderEditPositionModalComponent>,
    private readonly salesOrderApi: SalesOrderApiService,
    private metricPipe: MetricConversionPipe,
    private readonly dialog: MatDialog,
    public actionButtonsService: ActionButtonsService,
    private productUnitApiService: ProductUnitApiService,
    private readonly translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentSalesOrder: SalesOrderModel;
      position: SalesPosition,
      positions: SalesPosition[],
      rowIndex: number,
      positionsByProductType: {[key in ProductTypes]?: any[]}
    }
  ) { }

  ngOnInit() {
    this.position = {
      ...this.data.position,
      marginData: {
        profit: this.data.position.marginNet,
        percent: this.data.position.marginRate,
        currency: '€'
      },
    };
    this.positions = this.data.positions;
    this.positionsByProductType = this.data.positionsByProductType;
    this.rowIndex = this.getRowIndex(this.position);

    this.getProductUnits();
    this.getPositionsCards();
    this.getCurrentSalesOrder();
    this.selectAndHandleOrderState();
    this.trackPositionsChanges();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
        this.redrawControls();
      });
  }

  private selectAndHandleOrderState() {
    this.store.select(selectSalesOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
      });
  }

  private getPositionsCards(): void {
    if (this.data.currentSalesOrder) {
      this.positionsCardInfoByTypes[this.position.productType] = generatePositionsCardsInfo(
        this.translate,
        this.position.productType,
        UIStatesEnum.CREATE,
        this.data.currentSalesOrder.status,
        this.data.currentSalesOrder.properties.vatDisabled || this.data.currentSalesOrder.properties.smallBusiness
      );
    }
  }

  private trackPositionsChanges(): void {
    this.positionsUpdateRequest$.next(true);
    this.store.select(selectSalesOrderPositions)
      .pipe(
        finalize(() => this.positionsUpdateRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((positions: ResponseModel<SalesPositionsByProductType>) => {
        if (positions) {
          this.positions = positions.data[this.position.productType];
          this.positionsByProductType = positions.data;
        }
        this.rowIndex = this.getRowIndex(this.position);
      });
  }

  public updatePosition(fieldName, fieldValue): void {
    this.salesOrderApi
      .updateSalesOrderPosition(
        +this.position.id,
        {
          fieldName: fieldName,
          fieldValue: fieldValue
        },
        this.data.currentSalesOrder.id
      )
      // .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.position = {
          ...response.data,
          marginData: {
            profit: response.data.marginNet,
            percent: response.data.marginRate,
            currency: '€'
          },
        };
        this.getCurrentSalesOrder();
      }, () => {
        if (fieldName === 'unitType') {
          this.redrawControls();
        }
      });
  }

  public redrawControls(): void {
    this.showControls = false;
    setTimeout(() => this.showControls = true);
  }

  public onEditPositionTableCell(
    fieldName, fieldValue
  ): void {

    if (fieldName === 'quantity' && this.position.timeTrackingIndicator) {
      if (this.hasLinkedOIN) {
        this.salesOrderApi.getTimeTrackingRecordQuantityValidation(+this.position.id, fieldValue)
          .pipe(
            catchError(error => {
              this.loadPositions();
              return throwError(error);
            })
          )
          .subscribe(() => this.updateTimeTrackingRecordQuantity(fieldName, fieldValue));
      } else {
        this.updateTimeTrackingRecordQuantity(fieldName, fieldValue);
      }
    } else {
      this.updatePosition(fieldName, fieldValue);
    }
  }

  public updateTimeTrackingRecordQuantity(fieldName, fieldValue): void {
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.CHANGE_THE_QUANTITY_OF_PRODUCTS',
        message: 'MODAL.CHANGE_QUANTITY_OF_PRODUCTS_IN_DOCUMENT',
        confirmBtnText: 'BUTTON.CHANGE',
        confirmBtnIcon: 'checkbox-on',
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.updatePosition(fieldName, fieldValue);
      } else {
        this.loadPositions();
      }
    });
  }

  public loadPositions(): void {
    this.salesOrderApi.getSalesOrderPositions(this.data.currentSalesOrder.id)
      .pipe(
        debounceTime(1000),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private getCurrentSalesOrder(): void {
    this.salesOrderApi.getSalesOrderById(this.data.currentSalesOrder.id)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  public toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return  +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public actionListHandler({actionType, row, moveTo}): void {
    if (actionType === PositionsActionsType.INFO) {
      this.actionButtonsService.displaySidebarHandler(row.productId);
      return;
    }
    this.movePosition(row.id, moveTo);
  }

  public displayProductInfo(): void {
    this.actionButtonsService.displaySidebarHandler(this.position.productId);
  }

  public deletePositions(ids: number[]): void {
    this.salesOrderApi.deleteSalesOrderPositions(this.data.currentSalesOrder.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getCurrentSalesOrder();
        this.dialogRef.close();
      });
  }

  public deletePositionDialog(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: REMOVE_POSITION_MODAL_DATA,
    });

    dialog.afterClosed().subscribe((res) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.deletePositions([this.position.id]);
        }
    });
  }

  private movePosition(posId: number, moveTo: number): void {
    this.salesOrderApi.changePositionOrder(posId, moveTo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.position = {
          ...data,
          marginData: {
            profit: data.marginNet,
            percent: data.marginRate,
            currency: '€'
          },
        };
        this.getCurrentSalesOrder();
      });
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    this.positions.map((pos, index) => {
      if (pos.id === position.id) {
       rowIndex = index;
      }
    });
    return rowIndex;
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(fieldName, event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null);
  }

  public changePosition({position, positions}): void {
    this.position = {
      ...position,
      marginData: {
        profit: position.marginNet,
        percent: position.marginRate,
        currency: '€'
      },
    };
    this.positions = positions;
    this.getPositionsCards();
    this.getCurrentRowIndex();
    this.getProductUnits();
    this.redrawControls();
  }

  public getCurrentRowIndex(): void {
    this.positionsByProductType[this.position.productType].map((pos, index) => {
      if (pos.order === this.position.order) {
        this.rowIndex = index;
      }
    });
  }

  get hasLinkedOIN(): boolean {
    const documents = get(this.data.currentSalesOrder, 'linkedDocuments');
    return documents.some(doc => doc.type === 'outgoing_invoice');
  }

  get isStatusOpen(): boolean {
    return this.data.currentSalesOrder.status === SalesOrderListTabsEnum.OPEN;
  }

  get isStatusCompleted(): boolean {
    return this.data.currentSalesOrder.status === SalesOrderListTabsEnum.COMPLETED;
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
