import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectEcoOrder } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/store/selectors';
import { EcoOrderModel, EcoOrderPropertiesModel } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/models';
import { ECommerceApiService } from 'projects/workspace/src/app/e-commerce/services';

@Component({
  selector: 'rnpl-eco-add-remark-modal',
  templateUrl: './eco-add-remark-modal.component.html',
  styleUrls: ['./eco-add-remark-modal.component.css']
})
export class EcoAddRemarkModalComponent implements OnInit {

  public form: FormGroup;
  public ecoOrder: EcoOrderModel;
  readonly destroy$: Subject<void> = new Subject();

  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<EcoAddRemarkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private readonly eCommerceApiService: ECommerceApiService,
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.trackEcoOrderChanges();
  }

  private trackEcoOrderChanges(): void {
    this.store.select(selectEcoOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((ecoOrder: EcoOrderModel) => {
        this.ecoOrder = ecoOrder;
        this.initForm(ecoOrder.properties);
      });
  }

  public initForm(properties: EcoOrderPropertiesModel = {} as EcoOrderPropertiesModel): void {
    this.form = this.fb.group({
      printableRemarks: [get(properties, 'printableRemarks')],
    });
    this.isLoading$.next(false);
  }

  public updateEcoOrder(value, closeModal = false): void {
    this.eCommerceApiService.updateEcoOrder(this.ecoOrder.id, {
      fieldValue: value,
      fieldName: 'properties.printableRemarks'
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (closeModal) {
          this.dialogRef.close();
        }
      }); // updated via store
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
