import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';
import { StockAddressesService } from '../../../warehouse/services';
import { selectCurrentWarehouse, selectWarehouseState } from '../../../warehouse/store/selectors';
import { WarehouseResponse } from '../../../warehouse/models/warehouse-response.model';
import { UIStatesEnum } from 'common/src/models';

@Component({
  selector: 'rnpl-warehouse-information',
  templateUrl: './warehouse-information.component.html',
  styleUrls: ['./warehouse-information.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class WarehouseInformationComponent implements OnInit, OnDestroy {
  public warehouseData: any;
  public editingMode = false;

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private stockAddressesService: StockAddressesService,
    private readonly store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store
      .select(selectCurrentWarehouse)
      .pipe(
        filter((currentWarehouse: WarehouseResponse) => !!currentWarehouse),
        takeUntil(this._destroy)
      )
      .subscribe((currentWarehouse) => (this.warehouseData = currentWarehouse));

    this.store.select(selectWarehouseState)
      .pipe(takeUntil(this._destroy))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
      });
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }
}
