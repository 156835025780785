import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StockAddressesService } from '../services';
import { WarehouseResponse } from '../models/warehouse-response.model';
import { ResponseList } from '../../shared/models/response';

@Injectable()
export class WarehouseActiveItemsResolver
  implements Resolve<ResponseList<WarehouseResponse>> {
  constructor(
    private stockService: StockAddressesService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResponseList<WarehouseResponse>> {
    return this.stockService.getWarehouseInfo().pipe(
      tap(() => {
        if (state.url === '/warehouse') {
          this.router.navigate([`/warehouse/warehouse-profile`,]);
        }
      })
    );
  }
}
