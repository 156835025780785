import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-dropdown-observable-string',
  templateUrl: './filters-control-dropdown-observable-string.component.html',
})
export class FiltersControlDropdownObservableStringComponent extends FiltersControlBaseComponent {

  @Input() observableList: Observable<string[] | number[]>;
  @Input() placeholder: string; // todo remove

}
