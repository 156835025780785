import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SandboxHintService {
  public static HINT_STORAGE_KEY = 'showSandboxHint';
  public static OVERDUE_PAYMENT_STORAGE_KEY = 'showOverduePaymentHint';

  showSandboxHint$ = new BehaviorSubject<boolean>(this.getShowHint);
  showOverduePaymentHint$ = new BehaviorSubject<boolean>(this.getOverduePaymentShowHint);

  set setShowHint(value: boolean) {
    this.showSandboxHint$.next(value);
    localStorage.setItem(SandboxHintService.HINT_STORAGE_KEY, JSON.stringify(value));
  }

  get getShowHint() {
    const hint = localStorage.getItem(SandboxHintService.HINT_STORAGE_KEY);
    return hint ? JSON.parse(hint) : true;
  }

  set setOverduePaymentShowHint(value: boolean) {
    this.showOverduePaymentHint$.next(value);
    localStorage.setItem(SandboxHintService.OVERDUE_PAYMENT_STORAGE_KEY, JSON.stringify(value));
  }

  get getOverduePaymentShowHint() {
    const hint = localStorage.getItem(SandboxHintService.OVERDUE_PAYMENT_STORAGE_KEY);
    return hint ? JSON.parse(hint) : true;
  }
}
