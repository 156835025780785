import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


import {
  DynamicFormsModule,
  SystemSettingsCommonModule,
  UIComponentsModule,
  WizardModule
} from '../..';
import { FormWizardComponent } from './form-wizard.component';
import { ConfirmationComponent, FormLayoutComponent } from './steps';


@NgModule({
  declarations: [
    FormWizardComponent,
    FormLayoutComponent,
    ConfirmationComponent
  ],
  exports: [FormWizardComponent],
  imports: [
    CommonModule,
    FormsModule,
    UIComponentsModule,
    DynamicFormsModule,
    WizardModule,
    SystemSettingsCommonModule,
    TranslateModule
  ]
})
export class FormWizardModule {

  @Input()
  formKey: string;


}
