import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { ToasterService } from '../../modules/ui-components/toaster';

@Component({
  selector: 'rnpl-auth-confirm-form',
  templateUrl: './auth-confirm-form.component.html',
  styleUrls: ['./auth-confirm-form.component.scss']
})
export class AuthConfirmFormComponent implements OnInit {

  @Input()
  public userData;
  public authConfirmForm: FormGroup;
  public authKeyErrMessage: string;

  public countdown = {
    minutes: 2,
    seconds: 0,
    percents: 100
  };
  public countdownTimer;


  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToasterService) {
  }

  ngOnInit() {
    this.initForm();
    this.startTimer();
  }

  /**
   * Sets error message for invalid field
   *
   * @param controlName string
   * @param event MouseEvent
   */
  public setError(controlName: string, event?: MouseEvent): void {
    const control = this.authConfirmForm.get(controlName);
    if (control.valid) {
      return;
    }

    if (!control.value) {
      this.authKeyErrMessage = 'Authorization key cannot be blank.';
    } else {
      this.authKeyErrMessage = 'Authorization key should contains 6 digits.';
    }
  }

  /**
   * Invokes authorization method of AuthService
   */
  public signIn(): void {
    if (this.authConfirmForm.invalid) {
      this.setError('authKey');
      return;
    }

    this.authService.authorize(
      this.userData.data.user,
      this.authConfirmForm.get('authKey').value
    )
      .subscribe((status: boolean) => {
        this.router.navigate(['/']);
      }, error => {
        this.toasterService.notify({
          type: 'warning',
          message: 'Wrong authorization key.'
        });
      });
  }

  /**
   * Initializes sign in form
   */
  private initForm(): void {
    this.authConfirmForm = this.fb.group({
      userId: [this.userData.data.user, []],
      authKey: [null, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern(/^\d+$/)
      ]]
    });

    this.authConfirmForm.get('authKey').valueChanges
      .subscribe(() => {
        this.authKeyErrMessage = '';
      });
  }

  resendCode(): void {
    this.authService.signIn(
      this.userData.email,
      this.userData.password
    ).subscribe((response: any) => {
      this.userData.data = response.data;
    });

    clearInterval(this.countdownTimer);
    this.startTimer();
  }

  public startTimer(): void {
    this.countdownTimer = setInterval(() => {
      this.countdown.minutes = Math.floor((this.userData.data.ttl / 1000 / 60) << 0);
      this.countdown.seconds = Math.floor((this.userData.data.ttl / 1000) % 60);
      this.countdown.percents = (this.userData.data.ttl / 120000 ) * 100;

      this.userData.data.ttl = this.userData.data.ttl - 1000;

      if (this.userData.data.ttl < 0) {
        clearInterval(this.countdownTimer);
      }
    }, 1000);
  }

  public goBack(): void {
    this.router.navigate(['/sign-in']);
  }

}
