import { InfoItemModel, InfoItemType } from 'common/src/modules/ui-components/info-components/models/info-item.model';
import { PurchaseOrder } from '../models';
import { PoTabs } from '../enums';

export function getToRemarksHelper(purchaseOrder: PurchaseOrder): InfoItemModel[] {
  const infoItems = [
    {
      label: 'FORM.PURCHASE_MANAGER',
      value: purchaseOrder.properties.manager.name,
      type: InfoItemType.WITH_USER,
      routerLink: [`/team/employee-profile/${purchaseOrder.properties.manager.id}`],
      tableItem: true,
      user: purchaseOrder.properties.manager as any
    },
    {
      label: 'FORM.CANCELED',
      value: purchaseOrder.properties.canceledAt,
      type: InfoItemType.WITH_DATE_PRIMARY,
      tableItem: true,
      showWithStatuses: [
        PoTabs.Canceled,
      ]
    },
    {
      label: 'FORM.DELETED',
      value: purchaseOrder.properties.deletedAt,
      type: InfoItemType.WITH_DATE_PRIMARY,
      tableItem: true,
      showWithStatuses: [
        PoTabs.Deleted,
      ]
    },
    {
      label: 'FORM.COMPLETED',
      value: purchaseOrder.properties.completedAt,
      type: InfoItemType.WITH_DATE_PRIMARY,
      tableItem: true,
      showWithStatuses: [
        PoTabs.Completed,
      ]
    },
    {
      label: 'FORM.OPENED',
      value: purchaseOrder.properties.openedAt,
      type: InfoItemType.WITH_DATE_PRIMARY,
      tableItem: true,
      showWithStatuses: [
        PoTabs.Completed,
        PoTabs.Open,
      ]
    },
    {
      label: 'FORM.UPDATED',
      value: purchaseOrder.updatedAt,
      type: InfoItemType.WITH_DATE_PRIMARY,
      tableItem: true,
      showWithStatuses: [
        PoTabs.Draft,
        PoTabs.Open,
      ]
    },
    {
      label: 'FORM.CREATED',
      value: purchaseOrder.createdAt,
      type: InfoItemType.WITH_DATE_PRIMARY,
      tableItem: true,
    }
  ];

  return infoItems.filter((item: InfoItemModel) => {
    if (item.hasOwnProperty('showWithStatuses')) {
      return !!item.showWithStatuses.find(status => status === purchaseOrder.status);
    }
    return true;
  });
}
