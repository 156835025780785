import { Injectable } from '@angular/core';



@Injectable()
export class SoundService {
  private sounds: Map<string, HTMLAudioElement> = new Map<string, HTMLAudioElement>();

  public playSound(name: string): void {
    return;
    if (!name) { return; }

    if (!this.sounds.has(name)) {
      const audio = new Audio();
      audio.src = `../../assets/audio/${name}.ogg`;
      audio.load();
      this.sounds.set(name, audio);
    }

    this.sounds.get(name).play();
  }
}
