import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../models/table-column.model';
import { TableActivateTypes } from '../../../ui-components/table/custom-table.enums';
import { TranslateService } from '@ngx-translate/core';

export const COMMON_COLUMNS: TableColumnModelExtended[] = [
  {
    cellTemplate: 'numberRows',
    cellClass: 'center',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    headerClass: 'center'
  },
  {
    cellTemplate: 'withArrowRight',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.SERVICE_ID',
    prop: 'productRunpleId',
    sortable: true
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.SERVICE_NAME',
    prop: 'description',
    sortable: true,
  },
];

export const ACTION_COLUMN: TableColumnModelExtended = {
  cellTemplate: 'withClickableIcon',
  name: '',
  width: 40,
  maxWidth: 40,
  sortable: false,
  resizeable: false,
  clicktype: TableActivateTypes.Rollback,
  icon: 'undo',
  cellClass: 'undo-btn',
  frozenRight: true,
};

export function getOpenServicesColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    ...COMMON_COLUMNS,
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.OPEN',
      prop: 'open',
      sortable: true,
      postFixText: 'UNITS.H',
      cellClass: 'right font-weight-500'
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PROVIDED',
      prop: 'provided',
      sortable: true,
      unitTypeShortcut: true,
      type: ColumnTypeEnum.INT_OR_FLOAT_ONLY,
      decimal: 0,
      additionalText: translateService.instant('UNITS.H'),
    },
    {
      cellTemplate: 'withTextarea',
      name: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      sortable: true,
      sourceTextFormat: true,
      type: ColumnTypeEnum.TEXT
    },
    // {
    //   cellTemplate: 'withInput',
    //   headerTemplate: 'headerPrimary',
    //   name: 'COLUMN.PROVIDED_FROM',
    //   prop: 'providedFrom',
    //   sortable: true,
    //   resizeable: false,
    //   width: 272,
    //   type: ColumnTypeEnum.DATE,
    // },
    // {
    //   cellTemplate: 'withInput',
    //   headerTemplate: 'headerPrimary',
    //   name: 'COLUMN.PROVIDED_TO',
    //   prop: 'providedTo',
    //   sortable: true,
    //   resizeable: false,
    //   width: 272,
    //   type: ColumnTypeEnum.DATE,
    // },
    {
      ...ACTION_COLUMN
    }
  ];
}

export function getConfirmedServicesColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    ...COMMON_COLUMNS,
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PROVIDED',
      prop: 'provided',
      sortable: true,
      decimal: 0,
      postFixText: 'FORM.PCS',
    },
    {
      cellTemplate: 'primary',
      name: 'FORM.DESCRIPTION',
      prop: 'providedServices',
      sourceTextFormat: true,
      sortable: true,
      type: ColumnTypeEnum.TEXT
    },
    // {
    //   cellTemplate: 'primary',
    //   headerTemplate: 'headerPrimary',
    //   name: 'COLUMN.PROVIDED_FROM',
    //   prop: 'providedFrom',
    //   sortable: true,
    //   resizeable: false,
    //   width: 272,
    //   type: ColumnTypeEnum.DATE,
    // },
    // {
    //   cellTemplate: 'primary',
    //   headerTemplate: 'headerPrimary',
    //   name: 'COLUMN.PROVIDED_TO',
    //   prop: 'providedTo',
    //   sortable: true,
    //   resizeable: false,
    //   width: 272,
    //   type: ColumnTypeEnum.DATE,
    // },
    {
      ...ACTION_COLUMN
    }
  ];
}
