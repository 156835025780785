import { BatchStates } from '../../../../enums/batch-states';
import { NavBarBtnModel } from 'common/src/models';
import { BatchModel } from '../../../../models/purchase-order/batch.model';

const buttons: NavBarBtnModel[] = [
  {
    classes: 'btn-white red',
    text: 'BUTTON.DELETE',
    iconName: 'trash-2',
    disabled: false,
    actionName: 'onDeleteClick',
    multiple: false,
    other: [BatchStates.VIEW, BatchStates.EDIT]
  },
  {
    classes: 'btn-white',
    text: 'BUTTON.EDIT',
    iconName: 'bulb-off',
    disabled: false,
    actionName: 'onEditClick',
    multiple: false,
    hideWithStatus: [typeof BatchStates.EDIT],
    other: [BatchStates.VIEW]
  },
  {
    classes: 'btn-white',
    text: 'Save',
    iconName: 'save',
    disabled: false,
    actionName: 'onSaveClick',
    multiple: false,
    hideWithStatus: [typeof BatchStates.VIEW],
    other: [BatchStates.EDIT]
  },
];

export function getBatchButtons(state: BatchStates, batch: BatchModel) {
  return batch.actual_arrival_date
        ? []
        : buttons.filter((b: NavBarBtnModel) => b.other.includes(state));
}
