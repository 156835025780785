export class EmployeeCreateModel {
  avatar?: any;
  email: string;
  first_name: string;
  last_name: string;
  gender: string;
  container: { [key: string]: any };
  constructor(ext: any = {}) {
    Object.assign(this, {
      email: '',
      first_name: '',
      last_name: '',
      avatar: { 'id': 2 },
      container: [],
    }, ext);
  }
}
