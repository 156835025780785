import { createReducer, on } from '@ngrx/store';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { CollectionDeliveryNoteActions } from '../actions/action-types';
import { DeliveryNote } from '../../models/delivery-note.model';
import { TabsCountersModel } from 'common/src/models/tabs-counters.model';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { DeliveryNoteListTabsEnum } from '../../enums';

export const DeliveryNoteFeatureKey = 'deliveryNoteStore';

export interface DeliveryNotesListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: DeliveryNote[];
  }
}
export interface DeliveryNotesState {
  [DeliveryNoteListTabsEnum.CANCELED]?: DeliveryNotesListState;
  [DeliveryNoteListTabsEnum.DELETED]?: DeliveryNotesListState;
  [DeliveryNoteListTabsEnum.OPEN]?: DeliveryNotesListState;
  [DeliveryNoteListTabsEnum.PACKED_UP]?: DeliveryNotesListState;
  [DeliveryNoteListTabsEnum.PACKING]?: DeliveryNotesListState;
  [DeliveryNoteListTabsEnum.SHIPPED]?: DeliveryNotesListState;
  [DeliveryNoteListTabsEnum.TEMPLATE]?: DeliveryNotesListState;
}
export interface DeliveryNoteStore {
  entity: DeliveryNoteState;
  list: DeliveryNotesListState;
  blocksValidations: DeliveryNoteBlocksValidationsState;
}
export interface DeliveryNoteState {
  deliveryNote: DeliveryNote;
  counters: TabsCountersModel;
  currentState: UIStatesEnum;
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export const initialDeliveryNotesState: DeliveryNotesState = {
  [DeliveryNoteListTabsEnum.CANCELED]: {},
  [DeliveryNoteListTabsEnum.DELETED]: {},
  [DeliveryNoteListTabsEnum.OPEN]: {},
  [DeliveryNoteListTabsEnum.PACKED_UP]: {},
  [DeliveryNoteListTabsEnum.PACKING]: {},
  [DeliveryNoteListTabsEnum.SHIPPED]: {},
  [DeliveryNoteListTabsEnum.TEMPLATE]: {},
}


export const initialDeliveryNoteState: DeliveryNoteState = {
  deliveryNote: {} as DeliveryNote,
  counters: {} as TabsCountersModel,
  currentState: UIStatesEnum.VIEW,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false,
};

export interface DeliveryNoteBlocksValidationsState {
  // propertiesBlockValid: boolean;
  deliveryInfoBlockValid: boolean;
}

export const initialDeliveryNoteBlocksValidationsState: DeliveryNoteBlocksValidationsState = {
  // propertiesBlockValid: false,
  deliveryInfoBlockValid: false,
};

export function DeliveryNoteBlocksValidationsReducer(state, action) {
  return _DeliveryNoteBlocksValidationsReducer(state, action);
}

const _DeliveryNoteBlocksValidationsReducer = createReducer(
  initialDeliveryNoteBlocksValidationsState,
  // on(CollectionDeliveryNoteActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
  //   ...state,
  //   propertiesBlockValid
  // })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryInfoBlockValid, (state, { deliveryInfoBlockValid }) => ({
    ...state,
    deliveryInfoBlockValid
  })),
);

export function DeliveryNotesListReducer(state, action) {
  return _DeliveryNotesListReducer(state, action);
}

const _DeliveryNotesListReducer = createReducer(
  initialDeliveryNotesState,
  on(CollectionDeliveryNoteActions.LoadDeliveryNotesList, (state, { deliveryNotesListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...deliveryNotesListData
    },
  })),
);


export function DeliveryNoteReducer(state, action) {
  return _DeliveryNoteReducer(state, action);
}

const _DeliveryNoteReducer = createReducer(
  initialDeliveryNoteState,
  on(CollectionDeliveryNoteActions.LoadDeliveryNote, (state, {deliveryNote}) => ({
    ...state,
    deliveryNote
  })),
  on(CollectionDeliveryNoteActions.LoadDeliveryNoteCounters, (state, {counters}) => ({
    ...state,
    counters
  })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryNoteState, (state, {currentState}) => ({
    ...state,
    currentState
  })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryNoteDeliveryInformation, (state, { deliveryInformation}) => ({
    ...state,
    deliveryNote: {
      ...state.deliveryNote,
      deliveryInformation: {
        ...state.deliveryNote.deliveryInformation,
        ...deliveryInformation
      }
    }
  })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryNoteEstimatedDeliveryDate, (state, { estimatedDeliveryDate}) => ({
    ...state,
    deliveryNote: {
      ...state.deliveryNote,
      deliveryInformation: {
        ...state.deliveryNote.deliveryInformation,
        estimatedDeliveryDate
      }
    }
  })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryNoteDeliveryInformationDeliveryAddress, (state, { deliveryToAddress}) => ({
    ...state,
    deliveryNote: {
      ...state.deliveryNote,
      deliveryInformation: {
        ...state.deliveryNote.deliveryInformation,
        deliveryToAddress: deliveryToAddress
      }
    }
  })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryNoteDeliveryInformationPickupAddress, (state, { pickUpAddress}) => ({
    ...state,
    deliveryNote: {
      ...state.deliveryNote,
      deliveryInformation: {
        ...state.deliveryNote.deliveryInformation,
        pickUpAddress: pickUpAddress
      }
    }
  })),
  on(CollectionDeliveryNoteActions.UpdateDeliveryNoteUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionDeliveryNoteActions.ClearDeliveryNoteUpdatedAt, (state) => ({
    ...state,
    updatedAt: null
  })),
  on(CollectionDeliveryNoteActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionDeliveryNoteActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
  on(CollectionDeliveryNoteActions.ClearDeliveryNote, (state) => ({
    ...state,
    deliveryNote: {} as DeliveryNote,
    counters: {} as TabsCountersModel,
    currentState: UIStatesEnum.VIEW,
    loadingRequestsCount: 0,
    updatedAt: null,
  })),
  on(CollectionDeliveryNoteActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
);
