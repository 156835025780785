import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  TabComponent,
  TabGroupComponent,
  TabLabelDirective
} from './tabs';

@NgModule({
  declarations: [
    TabComponent,
    TabGroupComponent,
    TabLabelDirective
  ],
  exports: [
    TabComponent,
    TabGroupComponent,
    TabLabelDirective
  ],
  imports: [
    CommonModule
  ]
})
export class TabsModule {

}
