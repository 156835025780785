import { Component, OnInit, ViewChild } from '@angular/core';
import { CountryModel, RnplCommonService } from 'common/src/modules/rnpl-common';
import { CorporatePartnerFormComponent } from '../../partner-forms';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-partner-form',
  templateUrl: './partner-form.component.html',
  styleUrls: ['./partner-form.component.scss']
})
export class PartnerFormComponent implements OnInit {

  @ViewChild('formProfile', {static: true}) partnerInfoForm: CorporatePartnerFormComponent;
  // @ViewChild('formCeo', {static: true}) seoInfoForm: CeoInfoFormComponent;

  /**
   * Countries for forms
   */
  countries: CountryModel[];

  constructor(
    private commonService: RnplCommonService,
  ) { }

  ngOnInit() {
    this.commonService.getCountries()
      .subscribe(response => this.countries = response.data);
  }

  public getFormData() {
    const formData: any = {
      company_profile: this.partnerInfoForm.form.getRawValue(),
      // owner: this.seoInfoForm.form.getRawValue()
    };
    const img = get(formData, 'company_profile.image.apiModel.id', null);
    formData.company_profile.image = img ? { id: img } : img;
    return formData;
  }

  isFormInvalid(): boolean {
    // if (this.partnerInfoForm.form && this.seoInfoForm.form) {
    //   return this.partnerInfoForm.form.invalid || this.seoInfoForm.form.invalid;
    // }
    if (this.partnerInfoForm.form) {
      return this.partnerInfoForm.form.invalid;
    }
    return false;
  }

}
