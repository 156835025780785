import { Component, EventEmitter, Input, OnInit, OnChanges, Output } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';

import { CategoryFamilyModel, CategoryFamilySelectedEvent} from '../../models';
import { TreeEntityDefinitionModel } from './tree-entity-definition.model';
import { ProductTypes } from '../../product-types';

@Component({
  selector: 'rnpl-linear-tree',
  templateUrl: './linear-tree.component.html',
  styleUrls: ['./linear-tree.component.scss']
})
export class LinearTreeComponent implements OnInit, OnChanges {

  @Input()
  public isLoading = false;

  @Input()
  public tree: Array<CategoryFamilyModel> = [];

  @Input()
  public entity: TreeEntityDefinitionModel;

  @Input()
  public productType: ProductTypes;

  @Output()
  public selected: EventEmitter<CategoryFamilySelectedEvent> =
    new EventEmitter<CategoryFamilySelectedEvent>();

  @Output()
  public createBtnClicked: EventEmitter<CategoryFamilyModel> = new EventEmitter<CategoryFamilyModel>();

  @Output()
  public createBasicNodeBtnClicked: EventEmitter<{parentId: number, productType: string}> =
    new EventEmitter<{parentId: number, productType: string}>();

  @Output()
  public editBtnClicked: EventEmitter<CategoryFamilyModel> = new EventEmitter<CategoryFamilyModel>();

  @Output()
  public editFamilyFormClicked: EventEmitter<{node: CategoryFamilyModel, step: string}> =
    new EventEmitter<{node: CategoryFamilyModel, step: string}>();

  @Output()
  public deleteBtnClicked: EventEmitter<CategoryFamilyModel> = new EventEmitter<CategoryFamilyModel>();

  /**
   * Lets detect that nodes are parent of current node
   */
  public selectedNodes: SelectionModel<CategoryFamilyModel> = new SelectionModel(true);

  /**
   * Links to parents of nodes
   */
  private parentsMap: Map<CategoryFamilyModel, CategoryFamilyModel> = new Map<CategoryFamilyModel, CategoryFamilyModel>();

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    this.initTree();
  }

  ngOnChanges(): void {
    this.initTree();
  }

  public chooseNode(node: CategoryFamilyModel): void {
    const ancestryNodes = this.getParents(node);
    this.selectedNodes.clear();
    this.selectedNodes.select(node, ...ancestryNodes);
    this.selected.emit({selectedNode: node, ancestors: ancestryNodes});
  }

  public createNode(node: CategoryFamilyModel): void {
    this.createBtnClicked.emit(node);
  }

  public createBasicNode(): void {
    if (this.tree.length) {
      this.createBasicNodeBtnClicked.emit({
        parentId: +this.tree[0].parent_id,
        productType: this.tree[0].products_type
      });
    } else {
      this.createBasicNodeBtnClicked.emit({
        parentId: null,
        productType: this.productType
      });
    }
  }

  public editNode(node: CategoryFamilyModel): void {
    this.editBtnClicked.emit(node);
  }

  public editFamilyFormLayout(node: CategoryFamilyModel, step: string): void {
    this.editFamilyFormClicked.emit({node, step});
  }

  public deleteNode(node: CategoryFamilyModel): void {
    this.deleteBtnClicked.emit(node);
  }

  public openNodeById(id): void {
    for (const [node, parent] of this.parentsMap) {
      if (node.id === id) {
        this.chooseNode(node);
        break;
      }
    }
  }

  /**
   * Gets flat list of parents by the node
   *
   * @param node CategoryFamilyModel
   */
  public getParents(node: CategoryFamilyModel): Array<CategoryFamilyModel> {
    const parent = this.parentsMap.get(node);
    if (!parent) {
      return [];
    }
    return [parent, ...this.getParents(parent)];
  }

  /**
   * Keeps parent nodes at parentsMap
   *
   * @param node current node
   * @param parent parent node of current
   */
  private setParent(node: CategoryFamilyModel, parent?: CategoryFamilyModel): void {
    if (parent) {
      this.parentsMap.set(node, parent);
    } else {
      this.parentsMap.set(node, null);
    }

    if (node.children && node.children.length) {
      node.children.forEach(childNode => {
        this.setParent(childNode, node);
      });
    }
  }

  private initTree(): void {
    this.parentsMap.clear();
    this.tree.forEach(node => {
      this.setParent(node);
    });
  }

  public viewFamilyAttributes(family: CategoryFamilyModel): void {
    this.router.navigate([`/products/settings/attributes/${family.id}`]);
  }

}
