import { Injectable } from '@angular/core';


@Injectable()
export class StorageService {

  public static TYPE_LOCAL = 'local';
  public static TYPE_SESSION = 'session';

  public store(key: string, data: any, type = StorageService.TYPE_LOCAL, expires = null): void {
    let storedData = data;
    if (expires) {
      storedData = {
        timestamp: (new Date()).getTime(),
        expires,
        data
      };
    }

    this.getStorage(type).setItem(key, JSON.stringify(storedData));
  }

  public load<T>(key: string, type = StorageService.TYPE_LOCAL): T {
    const data = this.getStorage(type).getItem(key);
    if (!data) {
      return null;
    }
    const result = JSON.parse(data);
    if (result.expires && result.timestamp) {
      if (parseInt(result.expires + result.timestamp, 10) <= (new Date()).getTime()) {
        this.getStorage(type).removeItem(key);
        return null;
      } else {
        this.store(key, result.data, type, result.expires);
        return result.data;
      }
    }

    return result;
  }

  public remove(key: string, type = StorageService.TYPE_LOCAL): void {
    this.getStorage(type).removeItem(key);
  }

  private getStorage(type): Storage {
    return type === StorageService.TYPE_SESSION
      ? sessionStorage
      : localStorage;
  }
}
