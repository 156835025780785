import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { SalesOrderDeliveryInfoModel, SalesOrderModel } from '../models/sales-order.model';
import { AddressFormFactory } from '../../shared/forms/address-form.factory';
import { UpdateBillingInfoBlockValid, UpdateDeliveryInfoBlockValid } from '../store/actions/sales-order.actions';
import { DeliveryTypesEnum, ScontoRateTypeEnum } from 'common/src/models';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { AppState } from '../../store/state/app.state';
import { DueWithinDateDaysEnum } from '../../shared/enums';
import { AddressTypeEnum } from '../enums/address-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderFormsService {

  public billingInfoForm: FormGroup;
  public deliveryInfoForm: FormGroup;
  public pickUpAddressForm: FormGroup;
  public deliveryToAddressForm: FormGroup;
  public currentSalesOrder: SalesOrderModel;

  constructor(
    private readonly fb: FormBuilder,
    private metricPipe: MetricConversionPipe,
    private datePipe: DatePipe,
    private readonly store: Store<AppState>,
  ) { }

  public initBillingInfoForm(salesOrder: SalesOrderModel = {} as SalesOrderModel): void {
    this.currentSalesOrder = salesOrder;
    const billingInfo = salesOrder.billingInformation;

    this.billingInfoForm = this.fb.group({
      bankAccount: this.fb.group({
        id: [get(billingInfo, 'bankAccount.id')]
      }),
      iban: [get(billingInfo, 'iban'), {validators: [Validators.required]}],
      bic: [get(billingInfo, 'bic'), {validators: [Validators.required]}],
      paymentMethod: [get(billingInfo, 'paymentMethod')],
      paymentTerms: [get(billingInfo, 'paymentTerms')],
      paymentDueWithin: [get(billingInfo, 'paymentDueWithin'), {validators: [Validators.required]}],
      paymentDueWithinDate: [get(billingInfo, 'paymentDueWithinDate'), {validators: [Validators.required]}],
      paymentDueWithinType: [get(billingInfo, 'paymentDueWithinType'), {validators: [Validators.required]}],
      sameAsDeliveryAddress: [get(billingInfo, 'sameAsDeliveryAddress')],
      vatDisabled: [get(salesOrder, 'properties.vatDisabled')],
      smallBusiness: [get(salesOrder, 'properties.smallBusiness')],
      addressTemplate: [get(billingInfo, 'addressTemplate')],
      name: [get(billingInfo, 'name')],
      billingAddress: AddressFormFactory.getForm(get(billingInfo, 'billingAddress')),
      sconto: this.fb.group({
        enabled: [get(billingInfo, 'sconto.enabled')],
        rate: [this.fillRateField(get(billingInfo, 'sconto.rate')), {validators: [Validators.required]}],
        type: [get(billingInfo, 'sconto.type'), {validators: [Validators.required]}],
        period: [get(billingInfo, 'sconto.period'), {validators: [Validators.required]}],
      })
    });

    if (get(billingInfo, 'sconto.enabled')) {
      this.scontoRateControl.setValidators(Validators.required);
      this.scontoPeriodControl.setValidators(Validators.required);
    } else {
      this.scontoRateControl.clearValidators();
      this.scontoPeriodControl.clearValidators();
    }
    if (get(salesOrder, 'properties.instantSale')) {
      this.ibanControl.setValidators(Validators.required);
      this.bicControl.setValidators(Validators.required);
    } else {
      this.ibanControl.clearValidators();
      this.bicControl.clearValidators();
    }

    if (get(billingInfo, 'paymentDueWithinType') === DueWithinDateDaysEnum.USER_DEFINED) {
      this.paymentDueWithinControl.setValidators(Validators.required);
    } else {
      this.paymentDueWithinControl.clearValidators();
    }

    if (get(billingInfo, 'paymentDueWithinType') === DueWithinDateDaysEnum.DATE) {
      this.paymentDueWithinDateControl.setValidators(Validators.required);
    } else {
      this.paymentDueWithinDateControl.clearValidators();
    }

    this.ibanControl.updateValueAndValidity();
    this.bicControl.updateValueAndValidity();
    this.scontoRateControl.updateValueAndValidity();
    this.scontoPeriodControl.updateValueAndValidity();
    this.paymentDueWithinControl.updateValueAndValidity();
    this.paymentDueWithinDateControl.updateValueAndValidity();

    if (this.billingInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.billingInfoForm.get('billingAddress') as FormGroup);
      this.billingInfoForm.get('name').disable({emitEvent: false});
      this.billingInfoForm.get('name').updateValueAndValidity({emitEvent: false});
    } else {
      this.billingInfoForm.get('billingAddress').get('address_line').disable({emitEvent: false});
      this.billingInfoForm.get('billingAddress').get('address_line').updateValueAndValidity({emitEvent: false});
    }

    if (get(billingInfo, 'sameAsDeliveryAddress')) {
      AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.billingInfoForm.get('billingAddress') as FormGroup);
      this.billingInfoForm.get('billingAddress').get('address_line').disable({emitEvent: false});
      this.billingInfoForm.get('billingAddress').get('address_line').updateValueAndValidity({emitEvent: false});
    }

    this.store.dispatch(UpdateBillingInfoBlockValid({ billingInfoBlockValid: this.billingInfoForm.valid }));
  }

  public initDeliveryInfoForm(deliveryInformation: SalesOrderDeliveryInfoModel = {} as SalesOrderDeliveryInfoModel): void {
    const deliveryDate = get(deliveryInformation, 'estimatedDeliveryDate');

    this.deliveryInfoForm = this.fb.group({
      deliveryType: [get(deliveryInformation, 'deliveryType'), [Validators.required]],
      isExpressDelivery: [get(deliveryInformation, 'isExpressDelivery')],
      isInsuredDelivery: [get(deliveryInformation, 'isInsuredDelivery')],
      isDeliveryCosts: [get(deliveryInformation, 'isDeliveryCosts')],
      estimatedDeliveryDate: [deliveryDate ? this.datePipe.transform(deliveryDate, 'yyyy-MM-dd') : null],
      partialShipment: [get(deliveryInformation, 'partialShipment', false)],
      //deliveryCosts
      deliveryCosts: [this.fillControlValueWithConversion(get(deliveryInformation, 'deliveryCosts'))],
      deliveryCostsGross: [this.fillControlValueWithConversion(get(deliveryInformation, 'deliveryCostsGross'))],
      deliveryCostsVat: [get(deliveryInformation, 'deliveryCostsVat')],
      deliveryCostsPayer: [get(deliveryInformation, 'deliveryCostsPayer')],
      //deliveryInsurance
      // deliveryInsuranceCosts: [this.fillControlValueWithConversion(get(deliveryInformation, 'deliveryInsuranceCosts'))],
      // deliveryInsuranceCostsGross: [this.fillControlValueWithConversion(get(deliveryInformation, 'deliveryInsuranceCostsGross'))],
      // deliveryInsuranceVat: [get(deliveryInformation, 'deliveryInsuranceVat')],
      // deliveryInsurancePayer: [get(deliveryInformation, 'deliveryInsurancePayer')],
      addressTemplate: [get(deliveryInformation, 'addressTemplate')],
      name: [get(deliveryInformation, 'name')],
    });

    this.pickUpAddressForm = AddressFormFactory.getForm(get(deliveryInformation, 'pickUpAddress'));
    this.deliveryToAddressForm = AddressFormFactory.getForm(get(deliveryInformation, 'deliveryToAddress'));

    this.updateDeliveryInfoBlockValid();
  }

  private updateDeliveryInfoBlockValid(): void {
    const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
    // const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];

    let isFormsValid: boolean;

    if (this.deliveryInfoForm.get('isDeliveryCosts').value) {
      deliveryCostControls.forEach(control => control.setValidators(Validators.required));
    } else {
      deliveryCostControls.forEach(control => control.clearValidators());
    }

    // if (this.deliveryInfoForm.get('isInsuredDelivery').value) {
    //   insuranceCostControls.forEach(control => control.setValidators(Validators.required));
    // } else {
    //   insuranceCostControls.forEach(control => control.clearValidators());
    // }

    // [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());
    [...deliveryCostControls].forEach(control => control.updateValueAndValidity());

    if (this.deliveryTypeControl.value === DeliveryTypesEnum.DELIVERY) {
      if (this.deliveryInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.deliveryToAddressForm);
      } else {
        this.deliveryToAddressForm.get('address_line').disable({emitEvent: false});
        this.deliveryToAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.deliveryInfoForm.valid && this.deliveryToAddressForm.valid;
    } else {
      if (this.deliveryInfoForm.get('addressTemplate').value === AddressTypeEnum.SIMPLIFIED) {
        AddressFormFactory.setStateForSimplifiedFAddressTemplate(this.pickUpAddressForm);
      } else {
        this.pickUpAddressForm.get('address_line').disable({emitEvent: false});
        this.pickUpAddressForm.get('address_line').updateValueAndValidity({emitEvent: false});
      }
      isFormsValid = this.deliveryInfoForm.valid && this.pickUpAddressForm.valid;
    }

    this.store.dispatch(UpdateDeliveryInfoBlockValid({ deliveryInfoBlockValid: isFormsValid }));
  }

  private fillRateField(value: number|string): number|string {
    if (get(this, 'currentSalesOrder.billingInformation.sconto.type') === ScontoRateTypeEnum.ABSOLUTE) {
      if (value === 0) { return value; }
      if (!value) { return null; }
      return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
    }
    return value;
  }

  private fillControlValueWithConversion(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }


  get bankAccountIdControl(): FormControl { return this.billingInfoForm.get('bankAccount').get('id') as FormControl; }
  get ibanControl(): FormControl { return this.billingInfoForm.get('iban') as FormControl; }
  get bicControl(): FormControl { return this.billingInfoForm.get('bic') as FormControl; }
  get paymentDueWithinControl(): FormControl { return this.billingInfoForm.get('paymentDueWithin') as FormControl; }
  get paymentDueWithinDateControl(): FormControl { return this.billingInfoForm.get('paymentDueWithinDate') as FormControl; }
  get scontoRateControl(): FormGroup { return this.billingInfoForm.get('sconto').get('rate') as FormGroup; }
  get scontoPeriodControl(): FormGroup { return this.billingInfoForm.get('sconto').get('period') as FormGroup; }

  get deliveryCostsVat(): FormControl { return this.deliveryInfoForm.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.deliveryInfoForm.get('deliveryCosts') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.deliveryInfoForm.get('deliveryCostsGross') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.deliveryInfoForm.get('deliveryCostsPayer') as FormControl; }
  // get deliveryInsuranceVat(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceVat') as FormControl; }
  // get deliveryInsuranceCosts(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceCosts') as FormControl; }
  // get deliveryInsuranceCostsGross(): FormControl { return this.deliveryInfoForm.get('deliveryInsuranceCostsGross') as FormControl; }
  // get deliveryInsurancePayer(): FormControl { return this.deliveryInfoForm.get('deliveryInsurancePayer') as FormControl; }
  get deliveryTypeControl(): FormControl { return this.deliveryInfoForm.get('deliveryType') as FormControl; }
}
