import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BaseControlComponent } from '../base-control.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rnpl-input',
  templateUrl: './input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends BaseControlComponent implements OnInit {

  @Input() public placeholder: string = null;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.placeholder = this.translateService.instant('FORM.ENTER_ATTR_NAME', {attr: this.label});
    this.disabled = this.control.disabled || false;
  }

  public change(event) {
    // this.control.setValue(event.target.value);
    // this.control.markAsDirty();

    this.formUpdated.emit(true);
  }
}
