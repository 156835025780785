import { CountryModel } from '../rnpl-common';
import { AddressTypeEnum } from '../../../../projects/workspace/src/app/sales-order/enums/address-type.enum';

export interface DocumentViewerModel {
  hasSconto?: boolean;
  positionTotalData: PositionTotalDataModel[];
  headAddressInfo: HeadAddressInfoModel;
  headDocumentInfo: HeadDocumentInfoModel[];
  infoBlockData: InfoBlockDataModel[];
  additionalInfoSummary?: AditionalInfoModel[];
  documentRemark?: string;
  outroText?: string;
  introText?: string;
  bankInfo?: {
    name?: string;
    iban?: string;
    bic?: string;
  };
}

export interface HeadAddressInfoModel {
  companyName: string;
  sublineName?: string;
  street?: string;
  additionalInfo: string;
  addressTemplate: AddressTypeEnum|string;
  addressLine: string;
  streetLine: string[];
  postalCodeCity: string;
  country?: CountryModel;
  countryTitle?: string;
  addressLocationForLetter?: boolean;
}

export interface HeadDocumentInfoModel {
  title: string;
  value: any;
  skipTimezoneOffset?: boolean;
  type?: DocumentInfoTypeEnum;
  routerLink?: string[];
  hide?: boolean;
}

export interface FooterDocumentInfoModel {
  legalAddress: CompanyLegalAddressModel;
  companyContact: CompanyContactInfoModel;
  companyInfo: CompanyInfoModel[];
}

export enum DocumentInfoTypeEnum {
  DATE,
  LINK,
  MAIL,
  CURRENCY,
  ADDRESS,
  SCONTO,
  DISCOUNT,
  COMPANY_NAME_FROM_ADMIN,
  DATE_WITH_HOURS,
  ITEMS_LIST,
  FROM_ADMIN,
  SERIAL_NUM_LIST,
  CURRENCY_WITH_HIGHLIGHT,
  CONTACT_PERSON,
  CUSTOMER_NUMBER,
  LINKED_DOCUMENT,
  PRODUCT,
  QUANTITY,
  UNIT_PRICE,
  AMOUNT_PRICE,
  VAT,
  DELIVERY_DATE,
}

export interface DocumentTitleModel {
  documentName: string;
  documentId: string;
}

export interface CompanyLegalAddressModel {
  companyName: string;
  companyNameWithType: string;
  companyNameForDeliveryAddress: string;
  streetLine: string[];
  additionalInfo: string;
  postalCodeCityCountry: string;
  street: string;
}

export interface CompanyContactInfoModel {
  email: string;
  phone: string;
  webSite: string;
}

export interface CompanyInfoModel {
  title?: string;
  titleDescription?: string;
  value: any;
  hide?: boolean;
  hideTitle?: boolean;
  type?: DocumentInfoTypeEnum;
  additionalInfo?: string;
  valueClass?: string;
  rate?: any;
}

export interface PositionsColumnModel {
  saveFormattingText?: boolean;
  itemProp?: any;
  columnName: string;
  columnClass?: string;
  prop: string;
  propNet?: string;
  propGross?: string;
  propDiscountPercent?: string;
  type?: DocumentInfoTypeEnum;
  width?: number;
  additionalText?: string;
  textRight?: boolean;
  currencySourceFormat?: boolean;
  showWithCondition?: any;
  showCondition?: boolean;
  currencyNegativeRow?: boolean;
  positionDescription?: string;
  withLink?: boolean;
  hintId?: string;
  // highlightColorHex?: string;
}

export interface PositionTotalDataModel {
  noVat?: boolean;
  isOriginal?: boolean;
  title: string;
  value?: any;
  type?: DocumentInfoTypeEnum;
  isTotal?: boolean;
  additionalInfo?: string;
  hide?: boolean;
  itemClass?: string;
  valueClass?: string;
  reverseVatSign?: boolean;
  displayEmptyDiscount?: boolean;
  detailedVatList?: DetailedVatModel[];
  vatTotal?: number;
  // highlightCorrectionValue?: boolean;
}

export interface InfoBlockDataModel {
  descr?: string;
  type?: InfoBlockTypeEnum;
  title?: string;
  infoData?: CompanyInfoModel[];
  hide?: boolean;
  anchor?: string;
  action?: string;
  valid?: boolean;
}

export interface AditionalInfoModel {
  show: boolean;
  text: string;
  action?: string;
  isImgEU?: boolean;
}

export enum InfoBlockTypeEnum {
  PRIMARY,
  SCONTO,
  WARNING,
  QR_CODE
}

export interface DetailedVatModel {
  vat: number;
  vatAmount: number;
}

export enum PositionTypeEnum {
  DELIVERY_STANDARD = 'DELIVERY_STANDARD',
  DELIVERY_EXPRESS = 'DELIVERY_EXPRESS',
  INSURANCE = 'INSURANCE',
  PREPAYMENT = 'PREPAYMENT',
  INVOICE = 'INVOICE',
}
