import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { CdkDragMove } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';

import { ComposerContainerComponent } from '../composer-container/composer-container.component';
import { ComposerRowComponent } from '../composer-row/composer-row.component';
import { ComposerPlaceholder } from '../../interfaces';


@Component({
  selector: 'rnpl-composer-placeholder',
  templateUrl: './composer-placeholder.component.html',
  styleUrls: ['./composer-placeholder.component.scss'],
  providers: [{provide: ComposerPlaceholder, useExisting: ComposerPlaceholderComponent}]
})
export class ComposerPlaceholderComponent implements OnInit, OnDestroy {

  @Input()
  public direction = 'x';

  @Input()
  index: number = 0;

  @Input()
  @HostBinding('class.disabled')
  public disabled: boolean = false;

  @Input()
  @HostBinding('class.target')
  public isTarget: boolean = false;

  public get rowIndex() {
    return this.row.index;
  }

  public get rect() {
    return this.hostElement.nativeElement.getBoundingClientRect();
  }

  @HostBinding('class.composer-direction-y')
  get isDirectionY() {
    return this.direction === 'y';
  }

  @HostBinding('class.composer-direction-x')
  get isDirectionX() {
    return this.direction !== 'y';
  }

  private subscriptions: Array<Subscription> = [];

  constructor(@Optional() private container: ComposerContainerComponent,
              @Optional() public row: ComposerRowComponent,
              private hostElement: ElementRef) {
  }

  ngOnInit(): void {

    if (this.direction === 'y') {
      this.subscriptions.push(
        this.container.dragMoved
          .subscribe(event => this.handleMove(event))
      );

      this.subscriptions.push(
        this.container.dragEnded
          .subscribe(() => this.isTarget = false)
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private handleMove(event: CdkDragMove): void {
    if (this.container.dataGrid[this.container.sourceCell.rowIndex].length === 1
      && (this.index === this.container.sourceCell.rowIndex
      || this.index === this.container.sourceCell.rowIndex + 1)) {
      return;
    }
    if ((event.pointerPosition.y < this.rect.top - 8 || event.pointerPosition.y > this.rect.top + 8)) {
      this.container.removePlaceholder(this);
      return;
    }
    this.container.setPlaceholder(this);
  }
}
