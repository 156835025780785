import { WizardStep } from './wizard-step';
import { AssignedAttributeModel, AttributeModel } from '../system-settings/models';
import { Input } from '@angular/core';

export class BaseFormLayoutStep extends WizardStep {

  @Input()
  assignedAttributes: Array<AssignedAttributeModel> = [];

  @Input()
  set availableAttributes(attributes: Array<AttributeModel>) {
    this._availableAttributes = attributes;
  }


  selectedAttribute: AssignedAttributeModel;

  get availableAttributes(): Array<AttributeModel> {
    return this._availableAttributes
      .filter(attribute => {
        return !this.assignedAttributes.find(assignedAttribute => assignedAttribute.attribute.id === attribute.id);
      });
  }

  _availableAttributes: Array<AttributeModel> = [];

  /**
   * Updates attributes when form layout is changed
   *
   * @param attributes List of assigned attributes
   */
  public updateAssignedAttributes(attributes): void {
    const deletedAttributes = this.assignedAttributes
      .filter(attribute => {
        return !attributes.find(assignedAttribute => assignedAttribute.attribute === attribute.attribute);
      })
      .map(attribute => attribute.attribute);

    this.availableAttributes = [...this._availableAttributes, ...deletedAttributes];
    this.assignedAttributes = [...attributes];
    this.selectedAttribute = null;
  }

  public assignAttribute(attribute: AttributeModel): void {
    const lastAttribute = this.assignedAttributes.pop();
    if (lastAttribute) {
      lastAttribute.layout.isLast = true;
      this.assignedAttributes.push(lastAttribute);
    }

    this.assignedAttributes.push({
      attribute,
      layout: {size: 'medium'}
    });
    this.assignedAttributes = [...this.assignedAttributes];
  }

  public selectAttribute(attribute: AttributeModel): void {
    this.selectedAttribute = this.assignedAttributes.find(assignedAttr => assignedAttr.attribute === attribute);
  }

  public changeSize(size): void {
    this.selectedAttribute.layout.size = size;
    this.assignedAttributes = [...this.assignedAttributes];
    this.updateAssignedAttributes(this.assignedAttributes);
  }

  public removeAttrSelection(): void {
    this.selectedAttribute = null;
    this.assignedAttributes = [...this.assignedAttributes];
    this.updateAssignedAttributes(this.assignedAttributes);
  }

  public removeSelectedAttr(): void {
    if (!this.selectedAttribute) { return; }

    const assignedAttributes = this.assignedAttributes
      .filter((attr: AssignedAttributeModel) => attr.attribute.id !== this.selectedAttribute.attribute.id);
    this.availableAttributes = [...this._availableAttributes, this.selectedAttribute.attribute];
    this.assignedAttributes = [...assignedAttributes];
    this.updateAssignedAttributes(this.assignedAttributes);
    this.selectedAttribute = null;
  }

}
