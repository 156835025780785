import { NavigationItemModel } from 'common/src/models';

export const WAREHOUSE_NAV_ITEMS: NavigationItemModel[] = [
  {
    icon: 'pin',
    disabled: false,
    title: 'WAREHOUSE.ADDRESS_AND_CONTACTS',
    routerLink: './address-and-contacts'
  },
  {
    icon: 'stock-status',
    disabled: false,
    title: 'WAREHOUSE.BIN_LOCATIONS',
    routerLink: './bin-locations'
  },
  {
    icon: 'options-2',
    disabled: false,
    title: 'DOC_BLOCK.PREFERENCES',
    routerLink: './preferences'
  },
];
