import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { get } from 'lodash';


import { AddressModel } from 'common/src/models';

import { CountryModel } from 'common/src/modules/rnpl-common';
import { BaseFormComponent } from '../base-form.component';
import { AddressFormFactory } from '../address-form.factory';
import { WarehouseInfoModel } from '../../../models';
import { convertAddressToStringHelper } from 'common/src/modules/rnpl-common/helpers';

@Component({
  selector: 'rnpl-warehouse-form',
  templateUrl: './warehouse-form.component.html'
})
export class WarehouseFormComponent extends BaseFormComponent implements OnChanges {

  @Input()
  warehouseInfo: WarehouseInfoModel;

  @Input()
  legalAddress: AddressModel;

  @Input()
  countries: CountryModel[];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('warehouseInfo')) {
      this.initForm(this.warehouseInfo);
    }

    if (changes.hasOwnProperty('readonly')) {
      this.swapEditMode();
    }
  }

  swapEditMode() {
    if (this.readonly) {
      this.form.disable({onlySelf: false});
    } else {
      this.form.enable({onlySelf: false});
    }
  }

  setAddress(sameAsLegal: boolean): void {
    if (sameAsLegal && this.legalAddress) {
      this.address.updateValueAndValidity();
    }
  }

  initForm(warehouseInfo: WarehouseInfoModel = {} as any): void {
    this.form = this.fb.group({
      address: AddressFormFactory.getForm(warehouseInfo.address)
    });

    this.cdr.detectChanges();

    super.initForm();
  }

  public get addressAsText(): string {
    if (!this.form) { return ''; }

    return convertAddressToStringHelper(this.address.value, this.countries);
  }

  get address() {
    return this.form.get('address');
  }

}
