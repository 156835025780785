import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { FamilyModel } from '../../../products';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import {
  EcoSettingsBlockModel,
  EcoSettingsBlockUpdateModel
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import { EcoSettingsBlockTypeEnum } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/enums';

@Component({
  selector: 'rnpl-add-category-modal',
  templateUrl: './add-category-modal.component.html'
})
export class AddCategoryModalComponent extends BaseModalComponent implements OnInit {

  public flatCategories: FamilyModel[] = [];
  public selectedCategory: FamilyModel;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly addRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<AddCategoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      block?: EcoSettingsBlockModel;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.ecoSettingsApiService.getEcoSettingsCategoriesList()
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((categoryList: FamilyModel[]) => {
        // this.flatCategories = this.convertTreeToFlat(categoryTree);
        this.flatCategories = categoryList;
        if (this.data.block.container) {
          this.selectedCategory = this.flatCategories.find(category => +category.id === this.data.block.container.categoryId);
        }
      });
  }

  private convertTreeToFlat(tree: FamilyModel[]): FamilyModel[] {
    if (!tree) { return; }

    const flatArray = [];
    const getTreeItems = (branch) => {
      branch.map(node => {
        if (!node.product_locked_at) {
          flatArray.push(node);
        }

        if (node.children.length) {
          getTreeItems(node.children);
        }
      });
    };

    getTreeItems(tree);

    return flatArray;
  }

  public addCategory(): void {
    if (!this.selectedCategory) { return; }
    if (this.addRequest$.getValue()) { return; }

    this.addRequest$.next(true);

    const data: EcoSettingsBlockUpdateModel = {
      title: this.selectedCategory.title,
      categoryId: +this.selectedCategory.id,
      url: `/products?familyId=${this.selectedCategory.id}`,
      blockType: EcoSettingsBlockTypeEnum.PRODUCTS
    };

    if (this.data && this.data.block && this.data.block.id) {
      this.ecoSettingsApiService.ecoSettingsUpdateBlock(this.data.block.id, data)
        .pipe(takeUntil(this._destroy))
        .subscribe((res) => this.dialogRef.close(res));
      return;
    }

    this.ecoSettingsApiService.ecoSettingsCreateBlock(data)
      .pipe(
        finalize(() => this.addRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((block: EcoSettingsBlockModel) => this.dialogRef.close(block));
  }

}
