import { createAction, props } from '@ngrx/store';
import { Batch } from '../../models/purchase-order/batch';
import { BatchStates } from '../../enums/batch-states';

// Batches array.

export const LoadBatches = createAction(
  '[Batch] Load Batches',
  props<{ batches: Batch[] }>()
);

export const SaveBatches = createAction(
  '[Batch] Save Batch',
  props<{ batches: Batch[] }>()
);

export const UpdateBatches = createAction(
  '[Batch] Update Batch',
  props<{ batches: Batch[] }>()
);

export const ClearBatches = createAction(
  '[Batch] Clear Batch',
);

// Current batch

export const LoadCurrentBatch = createAction(
  '[Batch] Load Current Batch',
  props<{ batch: Batch }>()
);

export const SaveCurrentBatch = createAction(
  '[Batch] Save Current Batch',
  props<{ batch: Batch }>()
);

export const UpdateCurrentBatch = createAction(
  '[Batch] Update Current Batch',
  props<{ batch: Batch }>()
);

export const ClearCurrentBatch = createAction(
  '[Batch] Clear Current Batch',
  props<{ batch: Batch }>()
);

// Batch state

export const LoadBatchState = createAction(
  '[Batch state] Load batch state',
  props<{ batchState: BatchStates }>()
);

export const ResetBatchState = createAction(
  '[Batch state] Reset batch state'
);
