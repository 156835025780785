import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { CurrencyModel } from '../../../payment/models/currency.model';
import { IncomingInvoiceModel, Summary } from '../../models/incoming-invoice.model';
import { IncomingInvoicesListState } from '../reducers';
import { IncomingInvoiceListTabsEnum } from '../../enums';
import { IINPositionsModel } from '../../models/incoming-invoice-position.model';

export const LoadIncomingInvoicesList = createAction(
  '[Incoming Invoice] Load Incoming Invoices List',
  props<{ incomingInvoiceListData: IncomingInvoicesListState, status: IncomingInvoiceListTabsEnum, page: number }>()
);

export const LoadIncomingInvoice = createAction(
  '[Incoming Invoice] Load Incoming Invoice',
  props<{ incomingInvoice: IncomingInvoiceModel }>()
);

export const UpdateIncomingInvoiceState = createAction(
  '[Incoming Invoice] Update Incoming Invoice Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateIncomingInvoicePositionsCount = createAction(
  '[Incoming Invoice] Update Incoming Invoice Positions Count',
  props<{ positionsCount: number }>()
);

export const UpdateIncomingInvoicePaymentsCount = createAction(
  '[Incoming Invoice] Update Incoming Invoice Payments Count',
  props<{ paymentsCount: number }>()
);

export const UpdateIncomingInvoiceSummary = createAction(
  '[Incoming Invoice] Update Incoming Invoice Summary',
  props<{ summary: Summary }>()
);

export const LoadCurrencies = createAction('[Incoming Invoice] Load Currencies', props<{ currencies: CurrencyModel[] }>());

export const UpdateIncomingInvoiceUpdatedAt = createAction(
  '[Incoming invoice] Update Incoming invoice updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Incoming invoice] Update Incoming invoice should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const UpdateOcrProcessing = createAction(
  '[Incoming invoice] Update Incoming invoice ocr processing',
  props<{ isOcrProcessing: boolean }>()
);

export const LoadIncomingInvoicePositions = createAction(
  '[Incoming Invoice] Load Incoming Invoice Positions',
  props<{ positions: IINPositionsModel }>()
);

export const ClearIncomingInvoiceUpdatedAt = createAction('[Incoming invoice] Clear Incoming invoice updatedAt');

export const IncrementLoadingRequestsCount = createAction('[Incoming invoice] Increment Loading Requests Count');

export const DecrementLoadingRequestsCount = createAction('[Incoming invoice] Decrement Loading Requests Count');


export const UpdatePropertiesBlockValid = createAction(
  '[Incoming invoice] Update Incoming invoice Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Incoming invoice] Update Incoming invoice Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Incoming invoice] Update Incoming invoice Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
