import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { startWith, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { interval, Observable, ReplaySubject } from 'rxjs';
import { get, isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import {
  CONNECT_BANK_ACTIONS,
  DELIVERY_SERVICES_ACTIONS,
  IMPORT_DATA_ACTIONS,
  SET_UP_PDF_ACTIONS,
  WAREHOUSE_ACTIONS
} from '../../quick-onboarding.config';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { selectCompanyProfile, selectOnboarding } from 'projects/workspace/src/app/administration/store/selectors';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { OnboardingModel } from 'projects/workspace/src/app/administration/models/onboarding.model';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from '../../../modals/modals-common';
import { NavBarBtnModel } from '../../../../models';
import { SubscriptionPlanEnum } from '../../../subscription-activation/subscription-activation.emum';
import { ExitSandboxModalComponent } from '../exit-sandbox-modal/exit-sandbox-modal.component';

@Component({
  selector: 'rnpl-quick-onboarding',
  templateUrl: './quick-onboarding.component.html',
  styleUrls: ['./quick-onboarding.component.scss'],
})
export class QuickOnboardingComponent implements OnInit, OnDestroy {
  public companyProfile: CompanyProfile;
  public subscriptionPlanEnum = SubscriptionPlanEnum;
  public onboarding: OnboardingModel;

  public connectBankActions: NavBarBtnModel[] = CONNECT_BANK_ACTIONS;
  public warehouseActions: NavBarBtnModel[] = WAREHOUSE_ACTIONS;
  public setUpPdfActions: NavBarBtnModel[] = SET_UP_PDF_ACTIONS;
  public deliveryServicesActions: NavBarBtnModel[] = DELIVERY_SERVICES_ACTIONS;
  public importDataActions: NavBarBtnModel[] = IMPORT_DATA_ACTIONS;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public readonly store: Store<AppState>,
    private actionButtonService: ActionButtonsService,
    private dialog: MatDialog,
    private router: Router,
    private administrationsApiService: AdministrationsApiService,
  ) {
  }

  ngOnInit() {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });

    interval(30000)
      .pipe(
        startWith(0),
        switchMap(() => this.administrationsApiService.getOnboardingProcess()),
        takeWhile(() => !get(this, 'companyProfile.onboardingCompleted')),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.store.select(selectOnboarding)
      .pipe(takeUntil(this.destroy$))
      .subscribe((onboarding: OnboardingModel) => {
        this.onboarding = onboarding;
      });
  }

  public completeOnboarding(): void {
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'ONBOARDING.COMPLETE_MODAL_TTL',
        message: 'ONBOARDING.COMPLETE_MODAL_MSG',
        confirmBtnText: 'BUTTON.CONTINUE',
        confirmBtnIcon: 'arrow-right'
      }
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.administrationsApiService.completeOnboardingProcess()
          .pipe(
            switchMap(() => this.administrationsApiService.getOnboardingProcess()),
            switchMap(() => this.administrationsApiService.getCompanyProfile()),
            takeUntil(this.destroy$)
          )
          .subscribe();
      }
    });
  }

  public onActionReceived(btnAction: string) {
    if (this[btnAction]) {
      this[btnAction]();
    }
  }

  public connectService(): void {
    this.router.navigate(['/warehouse/settings/delivery-services']);
  }

  public connectBank(): void {
    this.router.navigate(['/accounting/bank-accounts/create-banks-list']);
  }

  public setUpPdf(): void {
    this.router.navigate(['/pdf-designer']);
  }

  public importData(): void {
    this.router.navigate(['/data-import']);
  }

  public warehouseGoods(): void {
    this.actionButtonService.createTransaction();
  }

  public skipBankStep(): void {
    this.skipStepModal(this.administrationsApiService.skipBankAccountProcess());
  }

  public skipWarehouseStep(): void {
    this.skipStepModal(this.administrationsApiService.skipWarehouseProcess());
  }

  public skipPdfStep(): void {
    this.skipStepModal(this.administrationsApiService.skipPdfDesignerProcess());
  }

  public skipDeliveryServiceStep(): void {
    this.skipStepModal(this.administrationsApiService.skipDeliveryServiceProcess());
  }

  public skipImportDataStep(): void {
    this.skipStepModal(this.administrationsApiService.skipImportProcess());
  }

  public skipStepModal(skipRequest$: Observable<any>): void {
    const dialog = this.dialog.open(InfoModalComponent, {
      data: {
        title: 'ONBOARDING.SKIP_MODAL_TTL',
        message: 'ONBOARDING.SKIP_MODAL_MSG',
        confirmBtnText: 'BUTTON.CONTINUE',
        confirmBtnIcon: 'arrow-right'
      }
    });

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        skipRequest$
          .pipe(
            switchMap(() => this.administrationsApiService.getOnboardingProcess()),
            takeUntil(this.destroy$)
          )
          .subscribe();
      }
    });
  }

  public exitSandboxMode(): void {
    this.dialog.open(ExitSandboxModalComponent, {
      data: {
        companyProfile: this.companyProfile
      },
      disableClose: true
    });
  }

  get progress(): number {
    if (!this.onboarding || !this.onboarding.progress || isEmpty(this.onboarding)) { return 0; }
    return (this.onboarding.progress.completed * 100) / this.onboarding.progress.total;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
