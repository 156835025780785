import { Component, OnDestroy } from '@angular/core';
import { NavigationItemModel } from 'common/src/models';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { WarehouseResponse } from '../../models/warehouse-response.model';
import { WarehouseMainDropdownItem } from '../../interfaces/warehouse-main-dropdown-item';
import { WAREHOUSE_DEFAULT_NAVIGATION } from '../../module-menu-items';
import { StockAddressesService } from '../../services';

@Component({
  selector: 'rnpl-warehouse-main',
  templateUrl: './warehouse-main.component.html',
  styleUrls: ['./warehouse-main.component.scss']
})
export class WarehouseMainComponent implements OnDestroy {
  // navItems: NavigationItemModel;

  dropdownItems: WarehouseMainDropdownItem[];
  selectedMenuDropdownItem: WarehouseMainDropdownItem;

  readonly unsubscriber$: Subject<void> = new Subject();

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly stockAddressesService: StockAddressesService,
              private readonly router: Router) {
    // this.navItems = WAREHOUSE_DEFAULT_NAVIGATION;
    // this.initDropdown(this.activatedRoute.snapshot.data.warehouses.data);

    // this.router.events
    //   .pipe(
    //     filter((e) => e instanceof NavigationEnd && e.url.includes('warehouse')),
    //     map((e: NavigationEnd) => this.getUrlSegments(e.url)),
    //     filter((segments: UrlSegment[]) => (
    //       this.checkUrlSegments(segments, 'warehouse') && this.checkUrlSegments(segments, 'warehouse-profile')
    //     )),
    //     map((segments: UrlSegment[]) => segments.slice(2).shift()),
    //     filter((segment: UrlSegment) => !!segment),
    //     map((segment: UrlSegment) => parseInt(segment.path, 10)),
    //     takeUntil(this.unsubscriber$)
    //   )
    //   .subscribe((id) => {
    //     const foundItem = this.findMenuItem(id);
    //
    //     if (this.selectedMenuDropdownItem.id !== id && foundItem) {
    //       this.selectedMenuDropdownItem = foundItem;
    //     }
    //   });
  }

  // private initDropdown(items: any): void {
  //   this.mapDropdownItems(items);
  //
  //   this.selectedMenuDropdownItem = {... this.dropdownItems[0] };
  // }
  //
  // private mapDropdownItems(items: any): void {
  //   this.dropdownItems = [
  //     {
  //       runple_id: items.runpleId.split('-').pop(),
  //       id: items.id,
  //       label: items.name
  //     }
  //   ];
  // }
  //
  // private getUrlSegments(url: string): UrlSegment[] {
  //   const urlTree = this.router.parseUrl(url);
  //   return urlTree.root.children.primary.segments;
  // }

  // private checkUrlSegments(segments: UrlSegment[], segmentName: string): boolean {
  //   return !!segments.find((s: UrlSegment) => s.path === segmentName);
  // }
  //
  // private findMenuItem(id: number): WarehouseMainDropdownItem {
  //   return this.dropdownItems.find((i) => i.id === id);
  // }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
