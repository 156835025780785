import { createReducer, on } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { CollectionCreditNoteActions } from '../actions/action-types';
import { PaginationModel, SendEmailStatusEnum, UIStatesEnum } from 'common/src/models';
import { CreditNoteModel } from '../../models';
import { FilterModelNew } from '../../../outgoing-invoice/models/filter-model-new';
import { CreditNotesListTabsEnum } from '../../enums';
import { CRNPositionsModel } from '../../models/credit-note-position.model';

export const CreditNoteFeatureKey = 'creditNoteStore';

export interface CreditNotesListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModelNew;
    data: CreditNoteModel[];
  }
}
export interface CreditNotesState {
  [CreditNotesListTabsEnum.DRAFT]?: CreditNotesListState;
  [CreditNotesListTabsEnum.OPEN]?: CreditNotesListState;
  [CreditNotesListTabsEnum.PAID]?: CreditNotesListState;
  [CreditNotesListTabsEnum.CANCELED]?: CreditNotesListState;
  [CreditNotesListTabsEnum.DELETED]?: CreditNotesListState;
}
export interface CreditNoteStore {
  entity: CreditNoteState;
  list: CreditNotesListState;
  blocksValidations: CreditNoteBlocksValidationsState;
}

export const initialCreditNotesListState: CreditNotesState = {
  [CreditNotesListTabsEnum.DRAFT]: {},
  [CreditNotesListTabsEnum.OPEN]: {},
  [CreditNotesListTabsEnum.PAID]: {},
  [CreditNotesListTabsEnum.CANCELED]: {},
  [CreditNotesListTabsEnum.DELETED]: {},
}

export interface CreditNoteState {
  creditNote: CreditNoteModel;
  currentState: UIStatesEnum;
  countries: CountryModel[];
  positionsCount: number;
  isShouldRefresh: boolean;
  loadingRequestsCount: number;
  updatedAt: Date;
  positions: CRNPositionsModel;
}

export const initialCreditNoteState: CreditNoteState = {
  creditNote: {} as CreditNoteModel,
  countries: [],
  currentState: UIStatesEnum.VIEW,
  positionsCount: 0,
  isShouldRefresh: false,
  loadingRequestsCount: 0,
  updatedAt: null,
  positions: null,
};

export interface CreditNoteBlocksValidationsState {
  propertiesBlockValid: boolean;
  billingInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialCreditNoteBlocksValidationsState: CreditNoteBlocksValidationsState = {
  propertiesBlockValid: false,
  billingInfoBlockValid: false,
  positionsBlockValid: false,
};

export function CreditNoteReducer(state, action) {
  return _CreditNoteReducer(state, action);
}

export function CreditNotesListReducer(state, action) {
  return _CreditNotesListReducer(state, action);
}

export function CreditNoteBlocksValidationsReducer(state, action) {
  return _CreditNoteBlocksValidationsReducer(state, action);
}

const _CreditNoteBlocksValidationsReducer = createReducer(
  initialCreditNoteBlocksValidationsState,
  on(CollectionCreditNoteActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
    ...state,
    propertiesBlockValid
  })),
  on(CollectionCreditNoteActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionCreditNoteActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
);

const _CreditNotesListReducer = createReducer(
  initialCreditNotesListState,
  on(CollectionCreditNoteActions.LoadCreditNoteList, (state, { creditNoteListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...creditNoteListData
    },
  })),
);

const _CreditNoteReducer = createReducer(
  initialCreditNoteState,
  on(CollectionCreditNoteActions.LoadCreditNote, (state, { creditNote }) => ({
    ...state,
    creditNote
  })),
  on(CollectionCreditNoteActions.LoadCreditNoteCountries, (state, {countries}) => ({
    ...state,
    countries
  })),
  on(CollectionCreditNoteActions.UpdateCreditNoteState, (state, {currentState}) => ({
    ...state,
    currentState
  })),
  on(CollectionCreditNoteActions.LoadCreditNotePositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionCreditNoteActions.UpdateCreditNotePositionsCount, (state, {positionsCount}) => ({
    ...state,
    positionsCount
  })),
  on(CollectionCreditNoteActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionCreditNoteActions.UpdateCreditNoteUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt,
  })),
  on(CollectionCreditNoteActions.CreditNoteSendEmail, (state) => ({
    ...state,
    creditNote: {
      ...state.creditNote,
      properties: {
        ...state.creditNote.properties,
        sendEmailStatus: {
          ...state.creditNote.properties.sendEmailStatus,
          emailStatus: SendEmailStatusEnum.PENDING
        }
      }
    }
  })),
  on(CollectionCreditNoteActions.ResetCreditNote, (state) => ({
    ...state,
    creditNote: null,
    updatedAt: null,
    positionsCount: 0
  })),
  on(CollectionCreditNoteActions.ResetCreditNoteState, (state) => ({
    ...state,
    currentState: UIStatesEnum.VIEW
  })),
  on(CollectionCreditNoteActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionCreditNoteActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),

  on(CollectionCreditNoteActions.ResetCreditNotePositionsCount, (state) => ({
    ...state,
    positionsCount: 0
  })),
);
