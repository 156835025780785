import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { ColorPickerControlComponent } from './color-picker-control.component';

@NgModule({
  declarations: [
    ColorPickerControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ColorPickerModule
  ],
  exports: [
    ColorPickerControlComponent,
  ],
  // entryComponents: [
  //   CompanyNameControlComponent
  // ],
})
export class ColorPickerControlModule { }
