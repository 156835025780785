import { Component, OnInit, OnChanges, SimpleChanges, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { BaseFormComponent } from '../../../crm/partner-forms/components/base-form.component';
import { UserModel, ImageModel } from 'common/src/models';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';

@Component({
  selector: 'rnpl-employee-form',
  templateUrl: 'employee-form.component.html',
  styleUrls: ['./employee-form.component.scss']
})
export class EmployeeFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() userData: UserModel;
  @Input() attributes: any[] = [];
  @Input() appendTo: string;
  @Input() isLoading: boolean = true;
  @Input() hideAvatarUpload: boolean;

  @Output()
  inputChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public controls: any = [];
  public formBlueprint: FormGroup = new FormGroup({});

  public titleItems: Array<string> = [
    '-',
    'Dr.',
    'Prof.',
    'Prof. Dr.',
    'Ing.',
    'Dipl. -ing',
    'Mag.'
  ];

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('readonly') ||
      changes.hasOwnProperty('userData') ||
      changes.hasOwnProperty('attributes')
    ) {
      this.initForm(this.userData);
      this.initFormDataForBlueprint(this.attributes);
      this.updateFormEditingStatus();
    }

    if (changes.hasOwnProperty('isLoading')) {
      this.isLoading$.next(this.isLoading);
    }
  }

  ngOnInit(): void {
    this.initForm(this.userData);
    this.updateFormEditingStatus();
  }

  updateFormEditingStatus() {
    if (!this.form) { return; }

    if (this.readonly) {
      this.form.disable({ onlySelf: false });
    } else {
      this.form.enable({ onlySelf: false });
    }

    if (this.userData && this.userData.id) {
      this.form.get('email').disable();
      this.isLoading$.next(false);
    }
  }

  initForm(userData: UserModel = {} as any): void {
    this.form = this.fb.group({
      title: [userData.title || null],
      firstName: [`${userData.firstName || ''}`, Validators.required],
      lastName: [`${userData.lastName || ''}`, Validators.required],
      email: [userData.email || '', [Validators.required, Validators.email]],
      phone: [userData.phone || ''],
      birthday: [userData.birthday || ''],
      avatar: [null, []],
      gender: [userData.gender || 'Unspecified', Validators.required],
    });
    super.initForm();
    this.cdr.detectChanges();
  }

  initFormDataForBlueprint(attributes) {
    if (!attributes) { return; }
    this.formBlueprint = new FormGroup({});
    this.controls = attributes.map(control => {
      control.properties = {label: control.label};
      const optionsRule = control.rules.find(rule => rule.type === 'options');
      if (optionsRule && optionsRule.list) {
        optionsRule.options = optionsRule.list.map(option => {
          return {label: option.eng, value: option.eng};
        });
      }
      control.disabled = this.readonly || this.isLoading$.value;
      return control;
    });
  }

  public updateField(fieldValue: any, fieldName: string): void {
    fieldName = 'profile.' + fieldName;
    this.inputChanged.emit({fieldName, fieldValue});
  }

  setLogo(images: ImageModel[]): void {
    this.avatar.setValue(images.length ? images[0] : null);
  }

  getFormData() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    const generalInfo = this.form.getRawValue();
    if (get(generalInfo, 'avatar.apiModel.id', null)) {
      generalInfo.avatar = {id: generalInfo.avatar.apiModel.id};
    }

    return {generalInfo, container: this.formBlueprint.getRawValue()};
  }

  isFormInvalid(): boolean {
    if (this.form &&  this.formBlueprint) {
      return this.form.invalid || this.formBlueprint.invalid;
    }
    return false;
  }

  get firstName() {  return this.form.get('firstName'); }
  get lastName() {   return this.form.get('lastName'); }
  get email() {      return this.form.get('email'); }
  get phone() {      return this.form.get('phone'); }
  get birthday() {   return this.form.get('birthday'); }
  get avatar() {     return this.form.get('avatar'); }

}
