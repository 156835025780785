import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/internal/operators/tap';

import { ResponseModel } from 'projects/workspace/src/app/shared/models';
import { AppNameEnum, StartScreenModel } from './launchpad.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  DecrementStartScreenListLoadingRequestsCount,
  IncrementStartScreenListLoadingRequestsCount, LoadAppsCountersSuccess,
  LoadUserStartScreenListSuccess
} from 'projects/workspace/src/app/store/actions/shared.actions';
import { ToasterService } from '../ui-components/toaster';

@Injectable({
  providedIn: 'root'
})

export class LaunchpadApiService {

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private readonly toasterService: ToasterService,
  ) { }

  public getStartScreenApps(): Observable<StartScreenModel[]> {
    this.store.dispatch(IncrementStartScreenListLoadingRequestsCount());
    return this.http.get<ResponseModel<StartScreenModel[]>>('/launch-pad')
      .pipe(
        tap((res) => {
          this.store.dispatch(LoadUserStartScreenListSuccess({userStartScreenItems: res.data}));
        }),
        map((res:ResponseModel<StartScreenModel[]>) => res.data),
        finalize(() => this.store.dispatch(DecrementStartScreenListLoadingRequestsCount()))
      );
  }

  public updateStartScreenApps(startScreenList: StartScreenModel[]): Observable<StartScreenModel[]> {
    return this.http.patch<ResponseModel<StartScreenModel[]>>('/launch-pad', startScreenList)
      .pipe(
        tap((res) => {
          this.store.dispatch(LoadUserStartScreenListSuccess({userStartScreenItems: res.data}));
        }),
        map((res:ResponseModel<StartScreenModel[]>) => res.data),
        catchError(error => {
          if (!error.error.message.includes('Duplicate item')) {
            this.showMsg('error', error.error.message);
          }

          return throwError(error);
        }),
      );
  }

  public removeAppFromStartScreen(name: AppNameEnum | string): Observable<StartScreenModel[]> {
    return this.http.request<ResponseModel<null>>('delete','/launch-pad/remove', {body: { item: name }})
      .pipe(
        tap((res) => {
          this.store.dispatch(LoadUserStartScreenListSuccess({userStartScreenItems: res.data}));
        }),
        map((res:ResponseModel<StartScreenModel[]>) => res.data)
      );
  }

  public addAppToStartScreen(item: AppNameEnum, screen: number): Observable<StartScreenModel[]> {
    return this.http.post<ResponseModel<StartScreenModel[]>>('/launch-pad/add', {item, screen})
      .pipe(
        tap((res) => {
          this.store.dispatch(LoadUserStartScreenListSuccess({userStartScreenItems: res.data}));
        }),
        map((res:ResponseModel<StartScreenModel[]>) => res.data)
      );
  }

  public getAvailableStartScreenApps(): Observable<StartScreenModel[]> {
    return this.http.get<ResponseModel<StartScreenModel[]>>('/launch-pad/available')
      .pipe(
        map((res:ResponseModel<StartScreenModel[]>) => res.data)
      );
  }

  public getAppsCounters(): Observable<{[key: string]: number}> {
    return this.http.get<ResponseModel<{[key: string]: number}>>('/launch-pad/counters')
      .pipe(
        tap((res) => {
          this.store.dispatch(LoadAppsCountersSuccess({appsCounters: res.data}));
        }),
        map((res:ResponseModel<{[key: string]: number}>) => res.data)
      );
  }

  private showMsg(type: string, message: string): void {
    this.toasterService.notify({type, message});
  }

}
