import { Component, Input } from '@angular/core';

import { CorporatePartnerModel } from 'projects/workspace/src/app/crm/models';
import { PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { CustomerTypeEnum } from '../../../../../models';

@Component({
  selector: 'rnpl-column-with-corporate-partner',
  templateUrl: './column-with-corporate-partner.component.html',
  styleUrls: ['./column-with-corporate-partner.component.scss']
})
export class ColumnWithCorporatePartnerComponent {

  @Input() partner: CorporatePartnerModel | PartnerModel | any; // ONLY PartnerModel in future
  @Input() legalAddressCountryISO3: string;

  public get partnerUrl(): string {
    if (
      (this.partner.type !== PartnersTypeEnum.CORPORATE && this.partner.type !== PartnersTypeEnum.PRIVATE) ||
      !this.partner.id
    ) { return null; }
    return `/partners-new/${this.partner.type}/${this.partner.id}`;
  }

  get isGeneralPartner(): boolean {
    return this.partner && this.partner.type && this.partner.type === CustomerTypeEnum.GENERAL;
  }

}
