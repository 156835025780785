import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { NavBarBtnModel, NavigationItemModel, UIStatesEnum } from 'common/src/models';
import { AppState } from '../../../store/state/app.state';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { StockAddressesService } from '../../services';
import {
  UpdateCurrentWarehouse,
  UpdateWarehouseCurrentState,
  UpdateWarehouseUpdatedAt
} from '../../store/actions/warehouse.actions';
import {
  selectCurrentWarehouse,
  selectWarehouseState,
  warehouseUpdatedAt
} from '../../store/selectors';
import { WAREHOUSE_NAV_ITEMS } from './warehouse-nav-items';
import { getButtonsByState, STATUS_CLASSES } from './warehouse-profile.config';
import { TranslateService } from "@ngx-translate/core";
import { getLinkQueueState } from '../../../store/selectors/app.selectors';

@Component({
  selector: 'rnpl-warehouse-profile',
  templateUrl: './warehouse-profile.component.html',
  styleUrls: ['./warehouse-profile.component.scss']
})

export class WarehouseProfileComponent implements OnInit, OnDestroy {

  public warehouseData: any;

  public navItems: NavigationItemModel[] = WAREHOUSE_NAV_ITEMS;
  public navBarButtons: NavBarBtnModel[] = getButtonsByState(UIStatesEnum.VIEW);

  public selectedCategory: string = 'Warehouse information';
  public editingMode: boolean = false;
  public UIStates: typeof UIStatesEnum = UIStatesEnum;

  readonly statusClasses: {[key: string]: string} = STATUS_CLASSES;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private previousLink$: BehaviorSubject<string> = new BehaviorSubject(null);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly warehouseUpdatedAt$: Observable<Date> = this.store.select(warehouseUpdatedAt);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stockAddressesService: StockAddressesService,
    private readonly store: Store<AppState>,
    private titleService: Title,
    private translate: TranslateService
  ) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        filter((event: RouterEvent) => event.url.includes('warehouse/warehouse-profile')),
        takeUntil(this.destroy$)
      )
      .subscribe(_ => {
        this.selectedCategory = this.route.snapshot.firstChild.data.title;
        this.titleService.setTitle(`${this.translate.instant('WAREHOUSE.WAREHOUSE_MANAGEMENT')}: ${this.translate.instant(this.selectedCategory)}`);
    });
  }

  ngOnInit() {
    this.store.select(selectCurrentWarehouse)
      .pipe(
        takeUntil(this.destroy$),
        filter((currentWarehouse: WarehouseResponse) => !!currentWarehouse),
      )
      .subscribe(currentWarehouse => this.warehouseData = currentWarehouse);

    this.store.select(selectWarehouseState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
        this.navBarButtons = getButtonsByState(state);
      });

    this.store.select(getLinkQueueState)
      .pipe(
        filter((linkQueue) => linkQueue.length >= 2),
        map((linkQueue) => linkQueue[linkQueue.length - 2].link),
        takeUntil(this.destroy$)
      )
      .subscribe((linkQueue) => {
        this.previousLink$.next(linkQueue);
      });

    this.loadWarehouseInfo();
  }

  public loadWarehouseInfo(preventLoading = false): void {
    if (!this.warehouseData || !preventLoading) { this.isLoading$.next(true); }

    this.stockAddressesService.getWarehouseInfo()
      .pipe(
        finalize(() => this.isLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.warehouseData = response.data;
        this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
        this.store.dispatch(UpdateWarehouseUpdatedAt({updatedAt: new Date()}));
        // this.titleService.setTitle(`Warehouse management: ${this.selectedCategory}`);
      });

    // this.stockAddressesService.getBasicWarehouse()
    //   .pipe(
    //     finalize(() => this.isLoading$.next(false)),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((response) => {
    //     this.warehouseData = response.data;
    //     this.store.dispatch(UpdateCurrentWarehouse({ currentWarehouse: response.data }));
    //     this.store.dispatch(UpdateWarehouseUpdatedAt({updatedAt: new Date()}));
    //     // this.titleService.setTitle(`Warehouse management: ${this.selectedCategory}`);
    //   });
  }

  public actionsEvent(e: string): void {
    if (this[e]) {
      this[e]();
    }
  }

  public onEditClick(): void {
    this.stockAddressesService.warehouseSetEdit()
      .pipe(
        finalize(() => {
         this.btnToClearLoadingStatus$.next('onEditClick');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public onFinishEditingClick() {
    this.stockAddressesService.warehouseUnsetEdit()
      .pipe(
        finalize(() => {
          this.btnToClearLoadingStatus$.next('onFinishEditingClick');
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  public close(): void {
    if (this.previousLink) {
      this.router.navigateByUrl(this.previousLink);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  public get previousLink(): string {
    return this.previousLink$.getValue();
  }

  get isEditingFlag(): boolean {
    return !!get(this, 'warehouseData.flags', []).includes('editing');
  }

  ngOnDestroy(): void {
    this.store.dispatch(UpdateWarehouseCurrentState({ currentState: UIStatesEnum.VIEW }));
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
