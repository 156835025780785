import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { isEqual } from 'lodash';

import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { PaymentModel } from 'projects/workspace/src/app/payment/models/payment.model';
import { CommonModalsActionsEnum } from '../../modals-common';
import { selectCompanyProfile, selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CustomerTypeEnum } from '../../../../models';

type SubmitRequestFn = (documentId: number, paymentId: number) => Observable<any>;
type GetPaymentsRequestFn = (documentId: number) => Observable<any>;

@Component({
  selector: 'rnpl-allocate-payment-modal',
  templateUrl: './allocate-payment-modal.component.html',
})
export class AllocatePaymentModalComponent implements OnInit, OnDestroy {
  public selectedPayment: PaymentModel;
  public paymentsList: PaymentModel[] = [];
  public customSearchFn = CustomSearchFn;
  public companyProfile: CompanyProfile;
  public displayError: boolean = false;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly linkDocumentRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public router: Router,
    public store: Store<AppState>,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<AllocatePaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentId: number;
      getPaymentsRequest$: GetPaymentsRequestFn;
      submitRequest$: SubmitRequestFn;
    }
  ) {
    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      ).subscribe((profile: CompanyProfile) => this.companyProfile = profile);
  }

  ngOnInit() {
    this.getDocument();

    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationStart),
        filter(() => !!this.dialogRef),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialogRef.close());
  }

  private getDocument(): void {
    this.data.getPaymentsRequest$(this.data.documentId)
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((payments: PaymentModel[]) => {
        this.paymentsList = payments.map((payment: PaymentModel) => {
          const name = payment.partnerInfo.name || payment.lastPartnerName
            ? payment.lastPartnerName || payment.partnerInfo.name
              .replace('General partner inland', this.translateService.instant('GENERAL_PARTNER.INLAND'))
              .replace('General partner EU', this.translateService.instant('GENERAL_PARTNER.EU'))
              .replace('General partner Non EU', this.translateService.instant('GENERAL_PARTNER.NON_EU'))
            :  this.translateService.instant('GL_ACCOUNTS.NOT_ALLOCATED');

          return {
            ...payment,
            partnerInfo: {
              ...payment.partnerInfo,
              name,
            },
            searchLabel: `${name} ${payment.runpleId || this.translateService.instant('STATUS.PENDING')}`
          };
        });
      });
  }

  public linkDocument(): void {
    if (!this.selectedPayment) {
      this.displayError = true;
      return;
    }

    this.linkDocumentRequest$.next(true);
    this.data.submitRequest$(this.data.documentId, this.selectedPayment.paymentId)
      .pipe(
        finalize(() => this.linkDocumentRequest$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
  }

  public get partnerUrl(): string {
    const { type  } = this.selectedPayment.partnerInfo;
    if (type === CustomerTypeEnum.CORPORATE_PARTNER || type === CustomerTypeEnum.PRIVATE_PARTNER) {
      return `/partners-new/${type}/${this.selectedPayment.partnerInfo.id}`;
    }

    return null;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
