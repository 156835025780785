import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderWhiteComponent } from './loader-white.component';

@NgModule({
  declarations: [
    LoaderWhiteComponent
  ],
  exports: [
    LoaderWhiteComponent
  ],
  imports: [
    CommonModule
  ]
})
export class LoaderWhiteModule { }
