import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';


import { PreloaderService } from '../../services/preloader/preloader.service';


@Directive({
  selector: 'button[rnplRequestBtn]'
})
export class RequestButtonDirective implements OnInit, OnDestroy {

  private static LOADER_HTML = '<i class="icon icon-loader-white loader"></i>';

  @Input('rnplRequestBtn')
  event: string = 'all';

  private btnText: string;
  private btnHTML: string;

  private loaderEventsSub: Subscription = new Subscription();
  private stopEventsSub: Subscription = new Subscription();

  constructor(private host: ElementRef, private preloader: PreloaderService) {
  }

  ngOnInit(): void {
    this.btnText = this.host.nativeElement.innerText;
    this.btnHTML = this.host.nativeElement.innerHTML;

    this.loaderEventsSub = this.preloader.events
      .pipe(filter(event => event === this.event))
      .subscribe(() => this.initPreloader());

    this.stopEventsSub = this.preloader.stop
      .pipe(filter(event => event === this.event))
      .subscribe(() => this.hidePreloader());
  }

  ngOnDestroy(): void {
    this.loaderEventsSub.unsubscribe();
    this.stopEventsSub.unsubscribe();
  }

  private initPreloader(): void {
    this.host.nativeElement.setAttribute('disabled', true);
    this.host.nativeElement.classList.add('loading');
    this.host.nativeElement.innerHTML = `${this.btnText} ${RequestButtonDirective.LOADER_HTML}`;
  }

  private hidePreloader(): void {
    this.host.nativeElement.classList.remove('loading');
    this.host.nativeElement.innerHTML = this.btnHTML;
    this.host.nativeElement.removeAttribute('disabled');
  }
}
