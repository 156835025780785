import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'rnpl-form-setup',
  templateUrl: './form-setup.component.html',
  styleUrls: ['./form-setup.component.scss']
})
export class FormSetupComponent implements OnInit, OnDestroy {

  public formKey: string = '';
  public formLabel: string = '';

  private entityType: string;
  private entity: string;

  private paramsSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) {
  }


  ngOnInit(): void {
    this.paramsSubscription = this.route.paramMap
      .subscribe(params => {
        this.entityType = params.get('type');
        this.entity = params.get('entity');

        this.formKey = `${this.entityType}_${this.entity}_signup`;
        this.formLabel = `${this.entity.charAt(0).toUpperCase()}${this.entity.slice(1)} sign up`;
      });
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
  }

  public back(): void {
    this.router.navigate(['/entities-management/user', this.entity, 'form-composer-view']);
  }

}
