import {
  AfterContentInit,
  Component,
  ContentChildren,
  Directive,
  Input,
  QueryList,
  TemplateRef, ViewChild,
  ViewContainerRef
} from '@angular/core';

import { CellDefDirective, HeaderCellDefDirective } from './cell';


/**
 * Row definition should contains template for row
 */
export interface RowDef {
  template: TemplateRef<any>;
}

/**
 * Header row definition.
 */
@Directive({selector: '[rnplHeaderRowDef]'})
export class HeaderRowDefDirective implements RowDef {

  constructor(public template: TemplateRef<any>) {
  }
}

/**
 * Row definition
 */
@Directive({selector: '[rnplRowDef]'})
export class RowDefDirective implements RowDef {

  constructor(public template: TemplateRef<any>) {
  }
}

export interface RowContext<T> {
  /** Data for the row that this cell is located within. */
  $implicit?: T;

  /** Index of the data object in the provided data array. */
  index?: number;

  /** Length of the number of total rows. */
  count?: number;

  /** True if this cell is contained in the first row. */
  first?: boolean;

  /** True if this cell is contained in the last row. */
  last?: boolean;

  /** True if this cell is contained in a row with an even-numbered index. */
  even?: boolean;

  /** True if this cell is contained in a row with an odd-numbered index. */
  odd?: boolean;
}

const ROW_TEMPLATE = `<ng-container #cellOutlet></ng-container>`;

export class RowBase {

  /**
   * List of columns to displaying
   */
  @Input()
  columns: Array<string>;

  /**
   * Outlet for table cell
   */
  @ViewChild('cellOutlet', {read: ViewContainerRef, static: true})
  cellOutlet: ViewContainerRef;

  /**
   * Templates of table cell storing by column name
   */
  cellTemplates: Map<string, TemplateRef<any>>;

  constructor() {
    this.columns = [];
    this.cellTemplates = new Map<string, TemplateRef<any>>();
  }
}

@Component({
  selector: 'rnpl-header-row, tr[rnpl-header-row]',
  template: ROW_TEMPLATE
})
export class HeaderRowComponent extends RowBase implements AfterContentInit {

  /**
   * Sets templates of header cells
   *
   * @param headerCellDefs QueryList<HeaderCellDefDirective>
   */
  @ContentChildren(HeaderCellDefDirective)
  set headerCellDefs(headerCellDefs: QueryList<HeaderCellDefDirective>) {
    this.cellTemplates.clear();
    headerCellDefs.forEach((headerCell: HeaderCellDefDirective) => {
      this.cellTemplates.set(headerCell.column, headerCell.template);
    });
  }

  constructor() {
    super();
  }

  ngAfterContentInit(): void {
    this.renderHeaderCells();
  }

  private renderHeaderCells(): void {
    this.columns.forEach((column: string) => {
      const cellTemplate = this.cellTemplates.get(column);

      if (cellTemplate) {
        this.cellOutlet.createEmbeddedView(cellTemplate);
      } else {
        console.warn(`Can not find definition of ${column} column.`);
      }
    });
  }
}


@Component({
  selector: 'rnpl-row, tr[rnpl-row]',
  template: ROW_TEMPLATE
})
export class RowComponent extends RowBase implements AfterContentInit {

  /**
   * Data for current row
   */
  @Input()
  rowData: any;

  /**
   * Sets templates of table cells
   *
   * @param cellDefs QueryList<HeaderCellDefDirective>
   */
  @ContentChildren(CellDefDirective)
  set cellDefs(cellDefs: QueryList<HeaderCellDefDirective>) {
    this.cellTemplates.clear();
    cellDefs.forEach((cellDef: HeaderCellDefDirective) => {
      this.cellTemplates.set(cellDef.column, cellDef.template);
    });
  }

  constructor() {
    super();
  }

  ngAfterContentInit(): void {
    this.renderColumns();
  }

  private renderColumns(): void {

    this.cellOutlet.clear();

    this.columns.forEach((column: string) => {
      const cellTemplate = this.cellTemplates.get(column);

      if (cellTemplate) {
        this.cellOutlet.createEmbeddedView(cellTemplate, {$implicit: this.rowData});
      } else {
        console.warn(`Can not find definition of ${column} column.`);
      }
    });
  }
}
