export enum NavBarBtnActionsEnum {
  // default actions
  DELETE = 'deleted',
  DELETE_PERMANENTLY = 'deletePermanently',
  ACTIVATE = 'active',
  DEACTIVATE = 'inactive',
  SAVE = 'save',
  CANCEL = 'cancel',
  CLONE = 'clone',
  HIDE = 'hide',
  SHOW = 'show',
  // Purchase order actions
  PROCEED_PO = 'proceedPO',
  // Incoming delivery position actions
  REMOVE = 'remove',
  REJECT = 'reject',
  UNVERIFY = 'unverify',
  // Incoming delivery attachment actions
  DOWNLOAD = 'download',
  MOVE = 'move',
}
