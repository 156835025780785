import { PartnersListTabsEnum } from './corporate/enums/partners-list-tabs.enum';
import { PartnersTypeEnum } from './corporate/enums/partner-types.enum';

export function partnersHints(
  isReadOnly?: boolean,
  status?: PartnersListTabsEnum | string,
  hasContacts?: boolean,
  screenName?: string,
  partnerType?: PartnersTypeEnum,
  displayValidationHint?: boolean,
  legalInfoTab?: boolean,
  billingInfoTab?: boolean,
  deliveryInfoTab?: boolean,
  contactListTab?: boolean,
  preferencesTab?: boolean,
  hasPartners?: boolean,
) {
  const hintsList = {
    ['partners_create_5']: {
      text: 'MEGA_HINT.PARTNERS.HINT_6',
      classes: 'from-modal',
      attachTo: {
        element: '#imgDropZone',
        on: 'top'
      },
    },

    ['partners_create_4']: {
      text: 'MEGA_HINT.PARTNERS.HINT_10',
      classes: 'from-modal',
      attachTo: {
        element: '#phoneNumberCPModal',
        on: 'bottom'
      },
    },
    ['partners_profile_legal_information_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_11',
      attachTo: {
        element: '#sidebarActionCP',
        on: 'bottom'
      },
    },
  };

  const exportBtn = {
    ['export_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_28',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const partnersDetailsHints = {
    ['create_entity_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_24',
      attachTo: {
        element: '#createContactCP',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const validFieldHint = {
    ['field_validation_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_8',
      attachTo: {
        element: '#iban',
        on: 'bottom'
      },
    },
  }

  const partnersListHints = {
    ['create_entity_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_7',
      attachTo: {
        element: '#createBtn',
        on: 'bottom'
      },
    },
    ['field_validation_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_8',
      classes: 'from-modal',
      attachTo: {
        element: '#streetModalPartner',
        on: 'bottom'
      },
    },
    ['filter_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_25',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
  }

  const contactsColumnHints = {
    ['partners_profile_list_contacts_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_16',
      attachTo: {
          element: '#departmentCPColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['partners_profile_list_contacts_2']: {
      text: 'MEGA_HINT.PARTNERS.HINT_17',
      attachTo: {
        element: '#nameCPColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const activeTabHints = {
    ['partners_list_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_1',
      attachTo: {
        element: '#activeTab',
        on: 'bottom'
      },
    },
  }

  const activeStatusColumnsHints = {
    ['partners_list_2']: {
      text: 'MEGA_HINT.PARTNERS.HINT_2',
      attachTo: {
        element: '#lastActivityCPColumn',
        on: 'bottom'
      },
    },
    ['total_row_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_27',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'top'
      },
    },
  }

  const deletedTabHints  = {
    ['tab_deleted_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_9',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  const editStateHints = {
    ['done_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_23',
      attachTo: {
        element: '#doneBtn',
        on: 'bottom'
      },
    },
    ['save_timer_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_22',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const editBtnHint = {
    ['edit_entity_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_21',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
  }

  const deactivatedBtnHint = {
    ['deactivate_entity_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_20',
      attachTo: {
        element: '#deactivateBtn',
        on: 'bottom'
      },
    },
  }

  const deletedBtnHint = {
    ['delete_entity_button_partners']: {
      text: 'MEGA_HINT.PARTNERS.HINT_19',
      attachTo: {
        element: '#deleteBtn',
        on: 'bottom'
      },
    },
  }

  const corporatePartnerHints = {
    ['partners_create_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_3',
      classes: 'from-modal',
      attachTo: {
        element: '#regCountryField',
        on: 'bottom'
      },
    },
    ['partners_create_2']: {
      text: 'MEGA_HINT.PARTNERS.HINT_4',
      classes: 'from-modal',
      attachTo: {
        element: '#vatNumberCPModal',
        on: 'bottom'
      },
    },
    ['partners_create_3']: {
      text: 'MEGA_HINT.PARTNERS.HINT_5',
      classes: 'from-modal',
      attachTo: {
        element: '#notHaveVatCPModal',
        on: 'bottom'
      },
    },
  }

  const deliveryInfoTabHints = {
    ['partners_profile_delivery_information_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_15',
      attachTo: {
        element: '#streetFieldCP',
        on: 'bottom'
      },
    },
  }

  const preferencesTabHints = {
    ['partners_profile_preferences_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_18',
      attachTo: {
        element: '#categoryFieldCP',
        on: 'bottom'
      },
    },
  }

  const billingInfoTabHint = {
    ['partners_profile_billing_information_1']: {
      text: 'MEGA_HINT.PARTNERS.HINT_12',
      attachTo: {
        element: '#streetFieldCP',
        on: 'bottom'
      },
    },
    ['partners_profile_billing_information_2']: {
      text: 'MEGA_HINT.PARTNERS.HINT_13',
      attachTo: {
        element: '#accountantEmailCP',
        on: 'bottom'
      },
    },
    ['partners_profile_billing_information_3']: {
      text: 'MEGA_HINT.PARTNERS.HINT_14',
      attachTo: {
        element: '#bicFieldCP',
        on: 'bottom'
      },
    },
  }

  if (!isReadOnly) {
    Object.assign(hintsList, editStateHints);
  } else {

    if (status !== PartnersListTabsEnum.DELETED) {
      Object.assign(hintsList, deletedBtnHint);
    }

    if (status === PartnersListTabsEnum.ACTIVE) {
      Object.assign(hintsList, deactivatedBtnHint);
      Object.assign(hintsList, editBtnHint);
    }
  }

  if (contactListTab) {
    Object.assign(hintsList, partnersDetailsHints);
  }
  if (preferencesTab) {
    Object.assign(hintsList, preferencesTabHints);
  }
  if (billingInfoTab) {
    Object.assign(hintsList, billingInfoTabHint);
  }

  if (!contactListTab && !deliveryInfoTab && !legalInfoTab && !billingInfoTab && !preferencesTab) {
    Object.assign(hintsList, partnersListHints)
  }

  if (status === PartnersListTabsEnum.ACTIVE) {
    Object.assign(hintsList, activeTabHints);
  }

  if (status === PartnersListTabsEnum.ACTIVE && hasPartners) {
    Object.assign(hintsList, activeStatusColumnsHints);
  }

  if (status === PartnersListTabsEnum.DELETED) { Object.assign(hintsList, deletedTabHints); }
  if (hasContacts) { Object.assign(hintsList, contactsColumnHints); }

  if (partnerType !== PartnersTypeEnum.PRIVATE) {
    Object.assign(hintsList, corporatePartnerHints);
  }

  if (displayValidationHint && !isReadOnly) {
    Object.assign(hintsList, validFieldHint);
  }

  if (deliveryInfoTab) {
    Object.assign(hintsList, deliveryInfoTabHints);
  }

  if (hasPartners) {
    Object.assign(hintsList, exportBtn);
  }

  return hintsList;
}
