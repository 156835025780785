import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from 'projects/workspace/src/environments/environment';
import { AppState } from '../store/state/app.state';
import { GeneralSettingsModel, ResponseModel } from '../shared/models';
import { LoadGeneralSettingsSuccess } from '../store/actions/shared.actions';
import { selectCompanyProfile } from '../administration/store/selectors';
import { CompanyProfile } from '../administration/models/company-profile.model';

@Injectable({
  providedIn: 'root',
})
export class GeneralSettingsService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/settings`;

  private wid: number;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.wid = profile.workspaceId;
      });
  }

  public getGeneralSettings(wid: number = this.wid): Observable<GeneralSettingsModel> {
    const params = {
      wid: (wid).toString(),
    };

    return this.http.get<ResponseModel<GeneralSettingsModel>>(this.apiUrl('/general-settings'), { params })
      .pipe(
        tap((response: ResponseModel<GeneralSettingsModel>) => {
          this.store.dispatch(LoadGeneralSettingsSuccess({ generalSettings: response.data }));
        }),
        map((response: ResponseModel<GeneralSettingsModel>) => response.data)
      );
  }

}
