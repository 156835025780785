import { Component, ContentChild, HostListener, Input, OnInit } from '@angular/core';
import { NavigationItemModel } from 'common/src/models';
import { ActivatedRoute, Router } from '@angular/router';

import { WarehouseSelectionComponent } from '../warehouse-selection/warehouse-selection.component';
import { get } from 'lodash';
import { NavItem } from '../head-navigation/nav-items.config';

// todo: check usage, remove
@Component({
  selector: 'rnpl-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit { // todo: rewrite all logic
  @ContentChild(WarehouseSelectionComponent, { static: true })
  dropdown?: WarehouseSelectionComponent;

  @Input()
  navItems: NavItem;

  public selectedItem: string;
  public selectedSubItem: string;
  public openMenu: boolean = false;
  public isOpenMenuSub: boolean = false;
  public componentName = this.route.component['name'];
  public isOpen: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    // const menuState = sessionStorage.getItem(this.componentName);

    // if (menuState === 'true') {
    //   this.isOpen = true;
    // }
  }

  // @HostListener('document:click', ['$event'])
  // // hides context-menu when clicking outside
  // clickedOutside($event: MouseEvent) {
  //   if ((<HTMLInputElement>$event.target).classList.contains('view-nav')) {
  //     this.isOpen = false;
  //   }
  // }

  // public isOpenMenuHandler(item): void {
  //   this.selectedItem = item.title.toLowerCase();
  //   if (!item.disabled && item.title.toLowerCase() === this.selectedItem && item.children) {
  //     this.isOpen = true;
  //     this.openMenu = !this.openMenu;
  //   } else {
  //     this.openMenu = false;
  //   }
  // }
  //
  // public isOpenSubMenuHandler(item): void {
  //   if (item.children) {
  //     if (item.title !== this.selectedSubItem) {
  //       this.selectedSubItem = item.title;
  //       this.isOpenMenuSub = true;
  //     } else {
  //       this.isOpenMenuSub = !this.isOpenMenuSub;
  //     }
  //   } else {
  //     this.isOpenMenuSub = false;
  //   }
  // }
  /**
   * Open/close full nav bar
   */
  public navBarToggle(event?): void {
    if (get(event, 'target.id', '') === 'nav-bar') {
      this.isOpen = !this.isOpen;
    }
  }

  public openMenuWithNav(isOpen: boolean) {
    if (isOpen) {
      this.isOpen = true;
    }
  }

  // saveMenuState (state): void {
  //   sessionStorage.setItem(this.componentName, state);
  // }
}
