import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';

import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import {
  selectOutgoingInvoice,
  selectOutgoingInvoiceCurrentState
} from 'projects/workspace/src/app/outgoing-invoice/store/selectors';
import { CustomerTypeEnum, UIStatesEnum } from '../../../../models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { PaymentListTabsEnum } from 'projects/workspace/src/app/payment/enums';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { PartnersFieldsChangedModel } from '../../../ui-components/select-partner/update-partner-fields.model';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-outgoing-invoice-select-partner-modal',
  templateUrl: './outgoing-invoice-select-partner-modal.component.html',
})
export class OutgoingInvoiceSelectPartnerModalComponent implements OnInit {

  // public countries: CountryModel[] = [];
  // public billingAddressForm: FormGroup;
  public formUpdatedByField: FormGroup;
  public invoice: OutgoingInvoiceModel;
  public isReadonly: boolean = true;
  public sidebarStatus: UIStatesEnum = UIStatesEnum.VIEW;
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  // public billingAddressSubscription: Subscription;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<OutgoingInvoiceSelectPartnerModalComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private invoiceApiService: InvoiceApiService
  ) {
    // this.initForm();
    this.initFormUpdatedByField();
    // this.initBillingAddressForm();
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this.trackInvoiceChanges();
    this.trackInvoiceStateChanges();

    // this.store.select(selectCountries)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((countries: CountryModel[]) => {
    //     this.countries = countries;
    //     this.cdr.detectChanges();
    //   });
  }

  private trackInvoiceChanges(): void {
    this.store.select(selectOutgoingInvoice)
      .pipe(takeUntil(this.destroy$))
      .subscribe((invoice: OutgoingInvoiceModel) => {
        this.invoice = invoice;
        this.formUpdatedByField.patchValue(invoice, {emitEvent: false});
        // this.initBillingAddressForm(invoice.billingAddress);
        this.formUpdatedByField.get('contactId').patchValue(get(invoice, 'properties.contact.id'), {emitEvent: false});
        this.setControlsState();
        this.isLoading$.next(false);
      });
  }

  private trackInvoiceStateChanges(): void {
    this.store.select(selectOutgoingInvoiceCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.sidebarStatus = state;
        this.setControlsState();
      });
  }

  private initFormUpdatedByField(): void {
    this.formUpdatedByField = this.fb.group({
      properties: this.fb.group({
        phone: []
      }),
      contactId: [],
      customerId: ['', [Validators.required]],
      partnerType: [null, [Validators.required]],
      partnerName: [null, [Validators.required]],
      partnerVat: [],
      sendEmailStatus: this.fb.group({
        emailRecipient: [],
      })
    });
  }

  // private initBillingAddressForm(billingAddress = {} as AddressModel): void {
  //   this.billingAddressForm = AddressFormFactory.getForm(billingAddress);
  //
  //   if (this.billingAddressSubscription) {
  //     this.billingAddressSubscription.unsubscribe();
  //   }
  //
  //   this.billingAddressSubscription = this.billingAddressForm.valueChanges
  //     .pipe(
  //       // debounceTime(300), Unused, updated by blur
  //       distinctUntilChanged(isEqual),
  //       takeUntil(this.destroy$),
  //     )
  //     .subscribe(() => this.updateBillingAddress(this.getNewInvoice()));
  // }

  // public getNewInvoice() {
  //   return {
  //     ...this.invoice,
  //     billingAddress: {
  //       ...this.invoice.billingAddress,
  //       ...this.billingAddressForm.getRawValue()
  //     },
  //   };
  // }

  // public updateBillingAddress(invoice: OutgoingInvoiceModel): void {
  //   this.invoiceApiService.updateInvoice(invoice)
  //     .subscribe();
  // }

  private setControlsState(): void {
    const opts = {emitEvent: false};

    if (this.isView()) {
      this.formUpdatedByField.disable(opts);
      // this.billingAddressForm.disable(opts);
      return;
    } else {
      this.formUpdatedByField.enable(opts);
      // this.billingAddressForm.enable(opts);

      this.cdr.detectChanges();

      // if (!get(this, 'invoice.customer') && !get(this, 'invoice.notSavedCustomer.enabled')) {
      //   this.billingAddressForm.disable(opts);
      // } else {
      //   this.billingAddressForm.enable(opts);
      //   this.cdr.detectChanges();
      // }

      if (
        this.hasLinkedIPBWithBookedStatus ||
        this.invoice.timeBilling ||
        this.hasLinkedTimeTrackingRecord ||
        (this.isOpenStatus && this.hasLinkedSoTsoSbsEco)
      ) {
        this.customerId.disable(opts);
        this.partnerType.disable(opts);
        this.partnerName.disable(opts);
        // this.partnerVat.disable(opts);
      } else {
        this.partnerType.enable(opts);
        this.customerId.enable(opts);
        this.partnerName.enable(opts);
        // this.partnerVat.enable(opts);
      }

      if (this.isPaidStatus) {
        this.customerId.disable(opts);
        this.partnerType.disable(opts);
        this.partnerName.disable(opts);
        this.partnerVat.disable(opts);
        this.phoneControl.disable(opts);
        this.emailRecipientControl.disable(opts);
        this.contactIdControl.disable(opts);
        this.formUpdatedByField.get('properties').get('phone').disable(opts);
        this.formUpdatedByField.get('sendEmailStatus').get('emailRecipient').disable(opts);
        this.formUpdatedByField.get('contactId').disable(opts);
      }
      //
      // if (get(this.invoice, 'customer.generalType') === 'inland') {
      //   this.billingAddressForm.get('country_iso3').disable(opts);
      // }

      if ((this.hasLinkedIPB || this.hasLinkedSo || this.hasLinkedTSO) && this.partnerType.value === PartnersTypeEnum.GENERAL) {
        this.customerId.disable(opts);
        this.partnerType.disable(opts);
        this.partnerName.disable(opts);
      }
    }
  }

  public updateField(fieldName: string, fieldValue: any): void {
    if (fieldName === 'companyContact.id') { fieldName = 'properties.contact'; }
    if (fieldName === 'phone') { fieldName = 'properties.phone'; }
    if (fieldName === 'email') { fieldName = 'sendEmailStatus.emailRecipient'; }

    this.invoiceApiService.updateOutgoingInvoiceField(this.invoice.invoiceId, fieldName, fieldValue)
      .pipe(switchMap(() => this.invoiceApiService.getSummaryInfo(this.invoice.invoiceId)))
      .subscribe(); // updated via store
  }

  public updatePartnerField(event: PartnersFieldsChangedModel): void {
    if (event.salesPriceList && event.salesPriceList !== this.invoice.salesPriceList) {
      const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG);

      dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.updateField(
            'customer',
            {
              type: event.partnerType,
              id: event.partnerId,
              updatePositions: true,
              updateDocument: true
            }
          );
        } else if (res === CommonModalsActionsEnum.REJECT) {
          this.updateField(
            'customer',
            {
              type: event.partnerType,
              id: event.partnerId,
              updatePositions: false,
              updateDocument: false
            }
          );
        } else {
          this.formUpdatedByField.patchValue(this.invoice, {emitEvent: false});
          this.formUpdatedByField.get('contactId').patchValue(get(this.invoice, 'properties.contact.id'), {emitEvent: false});
        }
      });
      return;
    }

    this.updateField(
      'customer',
      {
        type: event.partnerType,
        id: event.partnerId,
        updatePositions: false,
        updateDocument: false
      }
    );
  }

  get hasLinkedIPB(): boolean {
    if (!get(this, 'invoice.linkedDocuments')) { return false; }
    return get(this, 'invoice.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.IPB);
  }

  get hasLinkedIPBWithBookedStatus(): boolean {
    if (!get(this, 'invoice.linkedDocuments')) { return false; }
    return get(this, 'invoice.linkedDocuments')
      .some(doc => (
        doc.type === DocumentTypesUppercaseEnum.IPB &&
        doc.status.toLowerCase() === PaymentListTabsEnum.BOOKED
      ));
  }

  get hasLinkedTimeTrackingRecord(): boolean {
    const documents = get(this, 'invoice.linkedDocuments');
    return documents.some(doc => doc.type === DocumentTypesUppercaseEnum.TTR);
  }

  get isOpenStatus(): boolean {
    return (this.invoice.status === OutgoingInvoiceListTabsEnum.OPEN);
  }

  get hasLinkedSoTsoSbsEco(): boolean {
    return this.hasLinkedSo || this.hasLinkedTSO || this.hasLinkedECO || this.hasLinkedSBC;
  }

  get hasLinkedSo(): boolean {
    return !!get(this, 'invoice.linkedDocuments', [])
      .filter(doc => doc.printableType === DocumentTypesUppercaseEnum.SO).length;
  }

  get hasLinkedTSO(): boolean {
    return !!get(this, 'invoice.linkedDocuments', []).filter(doc => doc.printableType === DocumentTypesUppercaseEnum.TSO).length;
  }

  get hasLinkedECO(): boolean {
    return !!get(this, 'invoice.linkedDocuments', []).filter(doc => doc.printableType === DocumentTypesUppercaseEnum.ECO).length;
  }

  get hasLinkedSBC(): boolean {
    return !!get(this, 'invoice.linkedDocuments', []).filter(doc => doc.printableType === DocumentTypesUppercaseEnum.SBC).length;
  }

  get isPaidStatus(): boolean {
    return (this.invoice.status === OutgoingInvoiceListTabsEnum.PAID);
  }

  get isPartnerTypeCorporate(): boolean {
    return this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER;
  }

  public isView(): boolean {
    return (this.sidebarStatus === UIStatesEnum.VIEW);
  }

  get customerId(): FormControl { return this.formUpdatedByField.get('customerId') as FormControl; }
  get partnerType(): FormControl { return this.formUpdatedByField.get('partnerType') as FormControl; }
  get partnerName(): FormControl { return this.formUpdatedByField.get('partnerName') as FormControl; }
  get partnerVat(): FormControl { return this.formUpdatedByField.get('partnerVat') as FormControl; }
  get contactIdControl(): FormControl { return this.formUpdatedByField.get('contactId') as FormControl; }
  get phoneControl(): FormControl { return this.formUpdatedByField.get('properties').get('phone') as FormControl; }
  get emailRecipientControl(): FormControl { return this.formUpdatedByField.get('sendEmailStatus').get('emailRecipient') as FormControl; }
  // get countryControl(): FormControl { return this.billingAddressForm.get('country_iso3') as FormControl; }

}
