import { ExchangeListTabsEnum, ExchangeRequiredSolutionEnum } from './enums';
import { has } from 'lodash-es';

export function returnHintsList(
  hasReturns?: boolean,
  openInnerTable?: boolean,
  isReadOnly?: boolean,
  selectedPurpose?: ExchangeRequiredSolutionEnum,
  selectedReplacementProduct?: boolean,
  showLinkedDocuments?: boolean,
  selectedSo?: boolean,
  activeTab?: ExchangeListTabsEnum | string
) {
  const hintsList = {
    ['returns_tab_draft_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_2',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
    ['returns_tab_draft_3']: {
      text: 'MEGA_HINT.RETURNS.HINT_3',
      attachTo: {
        element: '#completedTab',
        on: 'bottom'
      },
    },

// MODAL ADD PRODUCT

    ['returns_add_positions_to_return_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_9',
      attachTo: {
        element: '#selectProduct',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_add_positions_to_return_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_10',
      attachTo: {
        element: '#returnPurposeField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_add_positions_to_return_3']: {
      text: 'MEGA_HINT.RETURNS.HINT_11',
      attachTo: {
        element: '#returnPurposeField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_add_positions_to_return_4']: {
      text: 'MEGA_HINT.RETURNS.HINT_12',
      attachTo: {
        element: '#exchangePurpose',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_add_positions_to_return_5']: {
      text: 'MEGA_HINT.RETURNS.HINT_13',
      attachTo: {
        element: '#damagedProductReasonField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_add_positions_to_return_6']: {
      text: 'MEGA_HINT.RETURNS.HINT_14',
      attachTo: {
        element: '#productDosntFitReasonField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_view_open_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_19',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
    },
    ['returns_view_open_3']: {
      text: 'MEGA_HINT.RETURNS.HINT_20',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
    },
    ['returns_view_open_4']: {
      text: 'MEGA_HINT.RETURNS.HINT_21',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
    },
    ['returns_view_open_5']: {
      text: 'MEGA_HINT.RETURNS.HINT_22',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
    },
    ['returns_view_open_6']: {
      text: 'MEGA_HINT.RETURNS.HINT_23',
      attachTo: {
        element: '#deliveryStep',
        on: 'bottom'
      },
    },
    ['returns_view_open_7']: {
      text: 'MEGA_HINT.RETURNS.HINT_24',
      attachTo: {
        element: '#emailSendControls',
        on: 'bottom'
      },
    },
    ['export_button_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_26',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
    ['create_entity_button_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_27',
      attachTo: {
        element: '#createBtn',
        on: 'bottom'
      },
    },
    ['cancel_entity_button_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_28',
      attachTo: {
        element: '#cancelBtn',
        on: 'bottom'
      },
    },
    ['edit_entity_button_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_29',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
    ['sidebar_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_31',
      attachTo: {
        element: '#propertiesStep',
        on: 'right'
      },
    },
    ['email_indicator_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_35',
      attachTo: {
        element: '#emailSendCheckedField',
        on: 'bottom'
      },
    },
    ['filter_button_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_37',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },

    // ['create_entity_button_returns']: {
    //   text: 'MEGA_HINT.RETURNS.HINT_33',
    //   attachTo: {
    //     element: '#createBtn',
    //     on: 'bottom'
    //   },
    // },

  }

  const positionsColumnHint = {
    ['returns_tab_draft_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_1',
      attachTo: {
        element: '#positionsColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const innerTableColumnsHints = {
    // ['returns_tab_canceled_1']: {
    //   text: 'MEGA_HINT.RETURNS.HINT_6',
    //   attachTo: {
    //     element: '#reasonColumn',
    //     on: 'bottom'
    //   },
    // },
    ['returns_tab_canceled_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_7',
      attachTo: {
        element: '#purposeInnerTableColumn',
        on: 'bottom'
      },
    },
  }

  const editStateHints = {
    ['create_positions_button_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_32',
      attachTo: {
        element: '#addPositionBtn',
        on: 'bottom'
      },
    },
    ['printable_remark_toggle_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_34',
      attachTo: {
        element: '#addInternalRemark',
        on: 'bottom'
      },
    },
  }

  const replacementProductHint = {
    ['returns_add_positions_to_exchange_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_15',
      attachTo: {
        element: '#replacementProduct',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const selectReplacementProductHints = {
    ['returns_add_positions_to_exchange_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_16',
      attachTo: {
        element: '#availableField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['returns_add_positions_to_exchange_3']: {
      text: 'MEGA_HINT.RETURNS.HINT_17',
      attachTo: {
        element: '#priceDifferenceField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const linkedDocumentsHints = {
    ['returns_view_open_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_18',
      attachTo: {
        element: '#Documents',
        on: 'bottom'
      },
    },
  }

  const contactPersonHint = {
    ['returns_view_draft_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_8',
      attachTo: {
        element: '#contactPerson',
        on: 'bottom'
      },
    },
  }

  const openAndCompleteTabColumn = {
    ['returns_tab_open_2']: {
      text: 'MEGA_HINT.RETURNS.HINT_5',
      attachTo: {
        element: '#deliveryColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const draftTabHint = {
    ['tab_draft_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_25',
      attachTo: {
        element: '#draftTab',
        on: 'bottom'
      },
    },
  }

  const deletedTabHint = {
    ['tab_deleted_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_36',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  const openTabHint = {
    ['returns_tab_open_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_2',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
  }

  const completedTabHint = {
    ['returns_tab_completed_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_3',
      attachTo: {
        element: '#completedTab',
        on: 'bottom'
      },
    },
  }

  const canceledTabHint = {
    ['returns_tab_canceled_1']: {
      text: 'MEGA_HINT.RETURNS.HINT_4',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
    },
  }

  const tableSummaryHint = {
    ['total_row_returns']: {
      text: 'MEGA_HINT.RETURNS.HINT_30',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }

  if (activeTab !== ExchangeListTabsEnum.OPEN && hasReturns) {
    Object.assign(hintsList, positionsColumnHint);
  }
  if (openInnerTable) {
    Object.assign(hintsList, innerTableColumnsHints);
  }
  if (!isReadOnly) {
    Object.assign(hintsList, editStateHints);
  }
  if (selectedReplacementProduct) {
    Object.assign(hintsList, selectReplacementProductHints);
  }
  if (selectedPurpose === ExchangeRequiredSolutionEnum.EXCHANGE) {
    Object.assign(hintsList, replacementProductHint);
  }
  if (showLinkedDocuments) {
    Object.assign(hintsList, linkedDocumentsHints);
  }
  if (!isReadOnly && selectedSo) {
    Object.assign(hintsList, contactPersonHint);
  }

  if (activeTab === ExchangeListTabsEnum.OPEN || activeTab === ExchangeListTabsEnum.COMPLETED && hasReturns) {
    Object.assign(hintsList, openAndCompleteTabColumn);
  }
  if (activeTab === ExchangeListTabsEnum.DRAFT) {
    Object.assign(hintsList, draftTabHint);
  }
  if (activeTab === ExchangeListTabsEnum.DELETED) {
    Object.assign(hintsList, deletedTabHint);
  }
  if (activeTab === ExchangeListTabsEnum.OPEN) {
    Object.assign(hintsList, openTabHint);
  }
  if (activeTab === ExchangeListTabsEnum.COMPLETED) {
    Object.assign(hintsList, completedTabHint);
  }
  if (activeTab === ExchangeListTabsEnum.CANCELED) {
    Object.assign(hintsList, canceledTabHint);
  }
  if (activeTab !== ExchangeListTabsEnum.DELETED && activeTab !== ExchangeListTabsEnum.CANCELED && hasReturns) {
    Object.assign(hintsList, tableSummaryHint);
  }

  return hintsList;
}
