import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SalesChannelEnum } from '../modules/ui-components/head-navigation-process-based/nav-items.config';

@Injectable({
  providedIn: 'root'
})
export class SalesChannelService {

  private selectedSalesChannel: BehaviorSubject<SalesChannelEnum> = new BehaviorSubject<SalesChannelEnum>(null);
  public activeSalesChannel = this.selectedSalesChannel.asObservable();

  setSalesChannel(salesChannel: SalesChannelEnum): void {
    this.selectedSalesChannel.next(salesChannel);
  }

}
