import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timePickerDisplayedValuePipe'})
export class TimePickerDisplayedValuePipe implements PipeTransform {
  transform(value: any): string {
    if (!value) { return value; }
    const timeArr = value.replace(/_/g, '').split(':');
    timeArr[0] = (timeArr[0] && +timeArr[0] > 23) ? 23 : timeArr[0] || '00';
    timeArr[1] = (timeArr[1] && +timeArr[1] > 59) ? 59 : timeArr[1] || '00';
    value = timeArr.join(':');

    return value;
  }
}
