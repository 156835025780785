import { CategoryFamilyModel } from './models';

/**
 * Finds family in families tree
 *
 * @param tree Families tree
 * @param predicate Predicate function
 */
export const findNodeInTree = (tree: CategoryFamilyModel[], predicate): CategoryFamilyModel => {

  const foundedNode = tree.find(predicate);
  if (foundedNode) {
    return foundedNode;
  }

  for (const node of tree.filter(nodeItm => nodeItm.children && nodeItm.children.length)) {
    const foundedChild = findNodeInTree(node.children, predicate);
    if (foundedChild) {
      return foundedChild;
    }
  }

  return null;
};
