import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { DynamicFormsModule, RnplCommonModule, UIComponentsModule } from 'common/src/modules';
import { ProductFormHardcodeComponent } from './product-form-hardcode.component';
import { ProductFormNameComponent } from './product-form-name.component';
import { ProductFormBarcodeComponent } from './product-form-barcode.component';
import { ProductFormPreferencesComponent } from './product-form-preferences.component';
import { ProductBasicColorFormComponent } from './product-basic-color-form/product-basic-color-form.component';

const COMPONENTS = [
  ProductFormHardcodeComponent,
  ProductFormNameComponent,
  ProductFormBarcodeComponent,
  ProductFormPreferencesComponent,
  ProductBasicColorFormComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    DynamicFormsModule,
    RnplCommonModule,
    TranslateModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: []
})
export class ProductFormHardcodeModule {

}
