import { Component, Input } from '@angular/core';

import { UserModel } from 'common/src/models';

@Component({
  selector: 'rnpl-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {

  @Input() user: UserModel;

  @Input() size: 'lg' | 'xl' | 'xxl' | 'sm' | 'profile';
}
