import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIComponentsModule } from 'common/src/modules';
import { PricesTableTotalInfoComponent } from './prices-table-total-info.component';
import { TradePricesApiService } from '../../services/trade-prices-api.service';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PricesTableTotalInfoComponent
  ],
    imports: [
        CommonModule,
        UIComponentsModule,
        RouterModule,
      TranslateModule,
    ],
  exports: [PricesTableTotalInfoComponent],
  providers: [TradePricesApiService]
})
export class PricesTableTotalInfoModule {}
