import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

import { BarcodeComponent } from './barcode/barcode.component';
import { RnplCommonModule } from '../rnpl-common';
import { BarcodeApiService } from './barcode.service';
import { LoaderWhiteModule } from '../ui-components/loader-white/loader-white.module';


@NgModule({
  declarations: [
    BarcodeComponent
  ],
  exports: [
    BarcodeComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatTabsModule,
    RnplCommonModule,
    LoaderWhiteModule,
    TranslateModule
  ],
  providers: [
    BarcodeApiService
  ]
})
export class BarcodeModule { }
