import { createReducer, on } from '@ngrx/store';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { CollectionTradeOfferActions } from '../actions/action-types';
import { TradeOfferModel, TradeOfferPositionsByProductType } from '../../models';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { ResponseModel } from '../../../shared/models/response';
import { TradeOfferListTabsEnum } from '../../enums';
import { FilterModel } from '../../../warehouse/models/filter.model';

export const TradeOfferFeatureKey = 'tradeOfferStore';


export interface TradeOffersListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: TradeOfferModel[];
  }
}
export interface TradeOffersState {
  [TradeOfferListTabsEnum.DRAFT]?: TradeOffersListState;
  [TradeOfferListTabsEnum.OPEN]?: TradeOffersListState;
  [TradeOfferListTabsEnum.ACCEPTED]?: TradeOffersListState;
  [TradeOfferListTabsEnum.DECLINED]?: TradeOffersListState;
  [TradeOfferListTabsEnum.DELETED]?: TradeOffersListState;
  [TradeOfferListTabsEnum.TEMPLATE]?: TradeOffersListState;
}

export interface TradeOfferStore {
  entity: TradeOfferState;
  list: TradeOffersListState;
  blocksValidations: TradeOfferBlocksValidationsState;
}

export interface TradeOfferState {
  tradeOffer: TradeOfferModel;
  currentState: UIStatesEnum;
  positions: ResponseModel<TradeOfferPositionsByProductType>;
  countries: CountryModel[];
  positionsCount: number;
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export const initialTradeOfferState: TradeOfferState = {
  tradeOffer: null,
  countries: [],
  currentState: UIStatesEnum.CREATE,
  positions: null,
  positionsCount: 0,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false,
};

export const initialTradeOffersState: TradeOffersState = {
  [TradeOfferListTabsEnum.DRAFT]: {},
  [TradeOfferListTabsEnum.OPEN]: {},
  [TradeOfferListTabsEnum.ACCEPTED]: {},
  [TradeOfferListTabsEnum.DECLINED]: {},
  [TradeOfferListTabsEnum.DELETED]: {},
  [TradeOfferListTabsEnum.TEMPLATE]: {},
}

export interface TradeOfferBlocksValidationsState {
  propertiesBlockValid: boolean;
  deliveryInfoBlockValid: boolean;
  billingInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialTradeOfferBlocksValidationsState: TradeOfferBlocksValidationsState = {
  propertiesBlockValid: false,
  deliveryInfoBlockValid: false,
  billingInfoBlockValid: false,
  positionsBlockValid: false,
};


export function TradeOfferReducer(state, action) {
  return _TradeOfferReducer(state, action);
}

export function TradeOfferListReducer(state, action) {
  return _TradeOfferListReducer(state, action);
}

export function TradeOfferBlocksValidationsReducer(state, action) {
  return _TradeOfferBlocksValidationsReducer(state, action);
}

const _TradeOfferBlocksValidationsReducer = createReducer(
  initialTradeOfferBlocksValidationsState,
  on(CollectionTradeOfferActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
    ...state,
    propertiesBlockValid
  })),
  on(CollectionTradeOfferActions.UpdateDeliveryInfoBlockValid, (state, { deliveryInfoBlockValid }) => ({
    ...state,
    deliveryInfoBlockValid
  })),
  on(CollectionTradeOfferActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionTradeOfferActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
  on(CollectionTradeOfferActions.ClearTradeOfferPositions, (state) => ({
    ...state,
  })),
  on(CollectionTradeOfferActions.ClearBlocksValidation, (state) => ({
    ...state,
    propertiesBlockValid: false,
    deliveryInfoBlockValid: false,
    billingInfoBlockValid: false,
    positionsBlockValid: false,
  }))
);

const _TradeOfferListReducer = createReducer(
  initialTradeOffersState,
  on(CollectionTradeOfferActions.LoadTradeOffersList, (state, { tradeOfferListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...tradeOfferListData
    },
  })),
);


const _TradeOfferReducer = createReducer(
  initialTradeOfferState,
  // TradeOffer
  on(CollectionTradeOfferActions.LoadTradeOffer, (state, { tradeOffer }) => ({
    ...state,
    tradeOffer
  })),
  on(CollectionTradeOfferActions.UpdateTradeOffer, (state, { tradeOffer }) => ({
    ...state,
    tradeOffer
  })),
  on(CollectionTradeOfferActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionTradeOfferActions.LoadTradeOfferPositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionTradeOfferActions.UpdateTradeOfferPositionsCount, (state, { positionsCount }) => ({
    ...state,
    positionsCount
  })),
  on(CollectionTradeOfferActions.UpdateTradeOfferStatus, (state, { status}) => ({
    ...state,
    tradeOffer: {
      ...state.tradeOffer,
      status: status
    }
  })),
  on(CollectionTradeOfferActions.UpdateTradeOfferSummary, (state, { summary }) => ({
    ...state,
    tradeOffer: {
      ...state.tradeOffer,
      summary
    }
  })),
  on(CollectionTradeOfferActions.LoadTradeOfferCountries, (state, {countries}) => ({
    ...state,
    countries
  })),
  on(CollectionTradeOfferActions.UpdateTradeOfferCurrentState, (state, { currentState }) => ({
    ...state,
    currentState: currentState
  })),
  on(CollectionTradeOfferActions.UpdateTradeOfferUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionTradeOfferActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionTradeOfferActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
  on(CollectionTradeOfferActions.ClearTradeOfferState, (state) => ({
    ...state,
    tradeOffer: null,
    currentState: UIStatesEnum.CREATE,
    isInvoiceExist: false,
    positions: null,
    positionsCount: 0,
    loadingRequestsCount: 0,
    updatedAt: null
  })),
  on(CollectionTradeOfferActions.ClearTradeOfferPositions, (state) => ({
    ...state,
    positions: null,
  })),
);
