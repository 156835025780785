import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-table-skeleton',
  templateUrl: './table-skeleton.component.html',
  styleUrls: ['./table-skeleton.component.scss']
})
export class TableSkeletonComponent {
  @Input() set rowsCounter(rowsCounter: number) {
    this.rows = Array(rowsCounter);
  }

  @Input() public productsType: boolean;
  @Input() public skeletonContainerClass: string = '';
  @Input() public darkType: boolean = false;

  public rows: Array<Object> = Array(40);

}
