import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { get } from 'lodash';

import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { selectOutgoingInvoice } from 'projects/workspace/src/app/outgoing-invoice/store/selectors';
import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-outgoing-invoice-add-remark-modal',
  templateUrl: './outgoing-invoice-add-remark-modal.component.html',
})
export class OutgoingInvoiceAddRemarkModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public invoice: OutgoingInvoiceModel;
  readonly destroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<OutgoingInvoiceAddRemarkModalComponent>,
    private invoiceApiService: InvoiceApiService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.trackInvoiceChanges()
  }

  private trackInvoiceChanges(): void {
    this.store.select(selectOutgoingInvoice)
      .pipe(takeUntil(this.destroy$))
      .subscribe((invoice: OutgoingInvoiceModel) => {
        this.invoice = invoice;
        this.initForm(this.invoice)
      });
  }

  public updateField(fieldValue: any, closeModal = false): void {
    this.invoiceApiService.updateOutgoingInvoiceField(this.invoice.invoiceId, 'printableRemarks', fieldValue)
      .subscribe(() => {
        if (closeModal) {
          this.dialogRef.close();
        }
      }); // updated via store
  }

  public initForm(invoice: OutgoingInvoiceModel = {} as OutgoingInvoiceModel): void {
    this.form = this.fb.group({
      printableRemarks: [get(invoice, 'printableRemarks')],
    });
    this.cdr.detectChanges();
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
