import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency-custom';

import { DynamicFormsModule, ProductsCommonModule, RnplCommonModule, UIComponentsModule, WizardModule } from '../..';
import { ProductFormHardcodeModule } from '..';
import { FamilyWizardComponent } from './family-wizard.component';
import {
  GeneralSettingsComponent,
  ProductLayoutComponent
} from './steps';
import { ExpansionPanelModule } from 'projects/workspace/src/app/shared/components/expansion-panel/expansion-panel.module';
import { GlAccountDropdownModule, GlProductTypeDropdownModule } from 'projects/workspace/src/app/gl-accounts/components';


@NgModule({
  declarations: [
    FamilyWizardComponent,
    GeneralSettingsComponent,
    ProductLayoutComponent
  ],
  exports: [FamilyWizardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DragDropModule,
    NgSelectModule,
    WizardModule,
    DynamicFormsModule,
    RnplCommonModule,
    UIComponentsModule,
    ProductFormHardcodeModule,
    TranslateModule,
    ProductsCommonModule,
    ExpansionPanelModule,
    GlAccountDropdownModule,
    GlProductTypeDropdownModule,
    PerfectScrollbarModule,
    NgxMaskModule,
    NgxCurrencyModule
  ],
  providers: []
})
export class FamilyWizardModule {

}
