import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectSalesOrder, selectSalesOrderCurrentState } from 'projects/workspace/src/app/sales-order/store/selectors';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { MetricConversionPipe } from '../../../rnpl-common';
import { UIStatesEnum } from '../../../../models';

@Component({
  selector: 'rnpl-sales-order-add-discount-modal',
  templateUrl: './sales-order-add-discount-modal.component.html',
  styleUrls: ['./sales-order-add-discount-modal.component.scss']
})
export class SalesOrderAddDiscountModalComponent implements OnInit {

  public salesOrder: SalesOrderModel;
  public discountControl: FormControl = new FormControl({value: null});
  public discountAmountNetControl: FormControl = new FormControl({value: null});
  public discountAmountGrossControl: FormControl = new FormControl({value: null});

  readonly destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private readonly salesOrderApiService: SalesOrderApiService,
    private readonly metricPipe: MetricConversionPipe,
    public dialogRef: MatDialogRef<SalesOrderAddDiscountModalComponent>
  ) { }

  ngOnInit(): void {
    this.trackSalesOfferChanges();

    this.store.select(selectSalesOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        if (response === UIStatesEnum.VIEW) {
          this.discountControl.disable({emitEvent: false});
          this.discountAmountNetControl.disable({emitEvent: false});
          this.discountAmountGrossControl.disable({emitEvent: false});
        } else {
          this.discountControl.enable({emitEvent: false});
          this.discountAmountNetControl.enable({emitEvent: false});
          this.discountAmountGrossControl.enable({emitEvent: false});
        }
      });
  }

  private trackSalesOfferChanges(): void {
    this.store.select(selectSalesOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((salesOrder: SalesOrderModel) => {
        this.salesOrder = salesOrder;
        this.discountControl.patchValue(this.salesOrder.discount);
        this.discountAmountNetControl.patchValue(this.getCurrencyConvertedValue(this.salesOrder.discountAmountNet));
        this.discountAmountGrossControl.patchValue(this.getCurrencyConvertedValue(this.salesOrder.discountAmountGross));
      });
  }

  public updateDiscount(field: FormInputChangedModel): void {
    this.salesOrderApiService.updateSalesOrder(this.salesOrder.id, field)
      .subscribe(); // updated via store
  }

  private getCurrencyConvertedValue(value: number|string): number|string {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
