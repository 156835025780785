import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { get } from 'lodash';

import { MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { ColumnTable } from '../../models/column-model';

@Component({
  selector: 'rnpl-column-input-with-dropdown',
  templateUrl: './column-input-with-dropdown.component.html',
  styleUrls: ['./column-input-with-dropdown.component.scss']
})
export class ColumnInputWithDropdownComponent implements OnChanges {

  public unitValue: string = 'mm';

  public metricConversion = 'mm-mm';

  public disabled = false;
  public invalid = false;
  public validatable = true;

  @Input() value: string;

  @Input() rowIndex: number;

  @Input() column: ColumnTable;

  @Input() row: any;

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  constructor(private metricPipe: MetricConversionPipe) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('row')) {
      this.disabled = false;
      this.invalid = !!this.row.invalid;
      this.updateDisabledStatus();
    }
    if (changes.hasOwnProperty('column')) {
      this.unitValue = get(this.column, 'metricList[0]', 'mm');
      this.metricConversion = `${this.unitValue}-${this.unitValue}`;
      if (this.column.validatable === false) {
        this.validatable = false;
      }
    }
  }

  public updateDisabledStatus(): void {
    if (this.row.disabled.fully) {
      this.disabled = true;
      return;
    }

    if (get(this.row, 'disabled.fields.length')) {

      if (this.row.disabled.fields.filter((el) => el === this.column.prop).length) {

        this.disabled = true;
      }
    }
  }

  public updateFormMetric(metricConversion: string): void {
    this.value = (this.metricPipe.transform(Number(this.value), metricConversion)).toString();
  }

  public getValueWithMetric(): string {
    const conversionDefault = get(this.column, 'metricList[0]', 'mm');
    return (this.metricPipe.transform(Number(this.value), `${this.unitValue}-${conversionDefault}`)).toString();
  }

  public updateValueEmit(): void {
    this.updateValue.emit({
      value: this.getValueWithMetric(),
      cell: this.column.prop,
      rowIndex: this.rowIndex
    });
  }

  public valueHandler(value: string): void {
    if (this.unitValue !== value) {
      this.metricConversion = `${this.unitValue}-${value}`;
      this.unitValue = value;
      this.updateFormMetric(this.metricConversion);
    }
  }

}
