import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { catchError, takeUntil } from 'rxjs/operators';

import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { ToasterService } from '../../../ui-components/toaster';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { DocumentsTemplatesApiService } from 'projects/workspace/src/app/shared/services';
import { CommonModalsActionsEnum } from '../../modals-common';
import { DocumentTemplateSameNameModalComponent } from '../document-template-same-name-modal/document-template-same-name-modal.component';


@Component({
  selector: 'rnpl-document-template-save-modal',
  templateUrl: './document-template-save-modal.component.html',
})
export class DocumentTemplateSaveModalComponent {

  public templateNameControl = new FormControl('', [Validators.required]);

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);


  constructor(
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private deliveryNoteApiService: DeliveryNoteApiService,
    private tradeOfferApiService: TradeOfferApiService,
    private salesOrderApiService: SalesOrderApiService,
    private purchaseOrderApiService: PurchaseOrderApiService,
    private subscriptionApiService: SubscriptionApiService,
    private documentsTemplatesApiService: DocumentsTemplatesApiService,
    public dialogRef: MatDialogRef<DocumentTemplateSaveModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentType: DocumentTypesUppercaseEnum,
      documentId: number
    }
  ) {

  }

  public submit(overwrite = false, resetCounter = false): void {
    this.templateNameControl.updateValueAndValidity();
    this.templateNameControl.markAsTouched();

    if (
      !this.templateNameControl.valid ||
      this.submitRequest$.getValue() ||
      !this.data.documentType ||
      !this.data.documentId
    ) {
      return;
    }

    this.submitRequest$.next(true);

    let request$: Observable<any>;
    const name = this.templateNameControl.value;

    switch (this.data.documentType) {
      case DocumentTypesUppercaseEnum.OFR:
        request$ = this.tradeOfferApiService.saveDocumentTemplate(this.data.documentId, {name, overwrite, resetCounter});
        break;
      case DocumentTypesUppercaseEnum.SO:
        request$ = this.salesOrderApiService.saveDocumentTemplate(this.data.documentId, {name, overwrite, resetCounter});
        break;
      case DocumentTypesUppercaseEnum.PO:
        request$ = this.purchaseOrderApiService.saveDocumentTemplate(this.data.documentId, {name, overwrite, resetCounter});
        break;
      case DocumentTypesUppercaseEnum.SBC:
        request$ = this.subscriptionApiService.saveDocumentTemplate(this.data.documentId, {name, overwrite, resetCounter});
        break;
      case DocumentTypesUppercaseEnum.DN:
        request$ = this.deliveryNoteApiService.saveDocumentTemplate(this.data.documentId, {name, overwrite, resetCounter});
        break;
      case DocumentTypesUppercaseEnum.OIN:
      case DocumentTypesUppercaseEnum.OPB:
        request$ = this.documentsTemplatesApiService
          .saveDocumentTemplate(this.data.documentType, this.data.documentId, {name, rewrite: overwrite, resetCounter});
        break;
    }

    if (!request$) { return; }

    request$
      .pipe(
        catchError(error => {
          this.submitRequest$.next(false);

          if (error.error.message) {
            this.checkError(error.error.message);
          }

          if (error.error.errors && error.error.errors.length) {
            error.error.errors.forEach((err: string) => {
              this.checkError(err);
            });
          }

          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.toasterService.notify({type: 'success', message: 'DOCUMENTS_TEMPLATES.TEMPLATE_CREATED'});
        this.dialogRef.close();
      });
  }

  private checkError(error: string): void {
    switch (error) {
      case 'nonUniqueTemplateName':
      case '1700':
        {
          const dialog = this.dialog.open(DocumentTemplateSameNameModalComponent);

          dialog.afterClosed().subscribe((res: {action: CommonModalsActionsEnum, resetCounter: boolean}) => {
            if (res && res.action === CommonModalsActionsEnum.CONFIRM) {
              this.submit(true, res.resetCounter);
            }
          });
        }
        break;
      default:
        this.toasterService.notify({type: 'error', message: error});
    }
  }


}

