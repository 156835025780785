import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ProductTypes } from '../../../products/product-types';
import { SalesPosition, SalesPositionsByProductType } from 'projects/workspace/src/app/sales-order/models/sales-order-position.model';
import { ResponseModel } from 'projects/workspace/src/app/shared/models/response';
import { CompositeProductModalComponent } from '../../modals-products';
import { ECommerceApiService } from 'projects/workspace/src/app/e-commerce/services';
import { EcoProductModel } from 'projects/workspace/src/app/e-commerce/modules/e-commerce-products/models';
import { CustomSearchFn } from '../../../rnpl-common/helpers';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { ProductModel, ProductsService } from '../../../products';
import { TradeDocumentPositionModel } from 'projects/workspace/src/app/trade/models';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { RecalculatePositionApiService } from '../../../../services/recalculate-position-api.service';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-sales-order-position-modal',
  templateUrl: './ecommerce-order-position-modal.component.html',
  styleUrls: ['./ecommerce-order-position-modal.component.scss']
})
export class EcommerceOrderPositionModalComponent extends BaseModalComponent implements OnInit {
  public companyProfile: CompanyProfile;

  readonly addPositionRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly productTypes = ProductTypes;
  // public readonly serviceProvidingTypes = ServiceProvidingTypes;

  public productsList: ProductModel[] | EcoProductModel[];
  // public productsListByType: ProductModel[] | EcoProductModel[] = [];

  public selectedProduct: ProductModel = null;
  public selectedPosition: TradeDocumentPositionModel | ProductModel = null;
  public isProductFound: boolean = false;
  public selectedProductInvalid: boolean = false;
  public selectedProductType: ProductTypes = ProductTypes.GOODS;
  // public selectedServiceProvidingType: ServiceProvidingTypes = ServiceProvidingTypes.ONE_TIME;

  public customSearchFn = CustomSearchFn;
  public form: FormGroup;
  public readonly vatList = VAT_LIST;

  constructor(
    public store: Store<AppState>,
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private eCommerceApiService: ECommerceApiService,
    private productsService: ProductsService,
    public dialogRef: MatDialogRef<EcommerceOrderPositionModalComponent>,
    private fb: FormBuilder,
    private actionButtonsService: ActionButtonsService,
    private recalculatePositionApiService: RecalculatePositionApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      ecoOrderId: number;
      vatBlocked: boolean;
    }
  ) {
    super(toasterService);
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {
    this.initForm();
    this.getProducts();
    this.dialogRef.afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.actionButtonsService.hideSidebarHandler();
      })
  }

  private getProducts(): void {
    this.showDropdownSpin$.next(true);
    this.eCommerceApiService.getActiveEcommerceProducts()
      .pipe(takeUntil(this._destroy))
      .subscribe((products: EcoProductModel[]) => {
        this.productsList = products.map(item => ({
          ...item,
          searchLabel: `${item.name} ${item.description}`
        }));
        // this.filterProducts();
      });
  }

  private initForm(selectProduct: ProductModel = {} as ProductModel): void {
    this.form = this.fb.group({
      grossTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      grossUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.grossPrice')), Validators.required],
      netTotalPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      netUnitPrice: [this.setCurrencyValue(get(selectProduct, 'prices.price.netPrice')), Validators.required],
      productDescription: [get(selectProduct, 'description')],
      providedServices: [get(selectProduct, 'description')],
      vat: [
        get(selectProduct, 'prices.price.vat') || get(selectProduct, 'prices.price.vat') === 0
          ? get(selectProduct, 'prices.price.vat')
          : get(selectProduct, 'accountingSettings.vatRateByDefault')
      ],
      discount: [null],
      discountAmountNet: [null],
      quantity: [1],
      addRemark: [false],
      remark: [''],
    });
  }

  public getProductById(productId, fromPo: boolean = false) {
    this.productsService.getProduct(productId)
      .pipe(takeUntil(this._destroy))
      .subscribe(product => {
        this.initForm(product);
        if (this.data.vatBlocked) {
          const defaultPrice = this.setCurrencyValue(product.prices.price.netPrice);
          this.vat.setValue(0);
          this.grossUnitPrice.setValue(defaultPrice);
          this.grossTotalPrice.setValue(defaultPrice);
        }

        if (fromPo) {
          this.quantity.setValue(this.selectedPosition.quantity);
          this.selectedProduct = {
            ...product,
            productImgUrl: this.selectedPosition.productImgUrl
          };
        }
      });
  }

  // public filterProducts(): void {
  //   this.productsListByType = this.productsList.filter(product => product.productType === this.selectedProductType);
  //   this.showDropdownSpin$.next(false);
  // }

  public changeProductType(): void {
    this.selectedProduct = null;
    this.selectedProductInvalid = false;
    this.isProductFound = false;
    // this.filterProducts();
  }

  // public createProduct(productType: ProductTypes): void {
  //   const dialog = this.dialog.open(CompositeProductModalComponent, {
  //     data: {
  //       family: null,
  //       productType: productType
  //     },
  //       disableClose: true,
  //   });
  //
  //   dialog.afterClosed().subscribe((product: ProductModel) => {
  //     if (product) {
  //       this.productsListByType.push(product);
  //       this.productsList.push(product);
  //       this.selectedProduct = product;
  //       this.isProductFound = false;
  //     }
  //   });
  // }

  public createProduct(productType: ProductTypes): void {
    const dialog = this.dialog.open(CompositeProductModalComponent, {
      data: {
        family: null,
        productType: productType
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe(product => {
      if (product) {
        this.productsList.push(product);
        this.selectedProduct = product;
        this.selectedProductType = product.productType;
        this.initForm(this.selectedProduct);
      }
    });
  }

  public addPosition(): void {
    if (this.addPositionRequest$.getValue()) { return; }
    this.addPositionRequest$.next(true);

    if (!this.selectedProduct) {
      this.selectedProductInvalid = true;
      return;
    }

    const position: SalesPosition = {} as SalesPosition;

    if (this.selectedProduct) {
      position.productId = +this.selectedProduct.id;
    }

    // if (this.selectedProductType === ProductTypes.SERVICES) {
    //   position.serviceProvidingType = this.selectedServiceProvidingType;
    // }

    this.eCommerceApiService
      .createEcoOrderPosition (
        this.data.ecoOrderId,
        {
          ...this.form.getRawValue(),
          ...position,
          grossTotalPrice: this.setCurrencyControl(this.grossTotalPrice.value),
          grossUnitPrice: this.setCurrencyControl(this.grossUnitPrice.value),
          netTotalPrice: this.setCurrencyControl(this.netTotalPrice.value),
          netUnitPrice: this.setCurrencyControl(this.netUnitPrice.value),
          productTypeForGrouping: this.selectedProductType,
          productDescription: !this.typeServicesOrArbitrary() ? this.description.value : null,
          providedServices: this.typeServicesOrArbitrary() ? this.providedServices.value : null,
        })
      .pipe(
        finalize(() => { this.addPositionRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(this.subscriberHandler);
  }

  public subscriberHandler = (response: ResponseModel<SalesPositionsByProductType>) => {
    this.dialogRef.close(response);
  }

  public setCurrencyControl(val): number {
    if (!val) { return val; }
    return +(val * 100).toFixed(0);
  }

  public updatePosition(value, fieldName): void {
    this.recalculatePositionApiService.recalculatePosition(DocumentTypesUppercaseEnum.SO, {
      protoPosition: {
        ...this.form.getRawValue(),
        productId: this.selectedProduct.id,
        productTypeForGrouping: this.selectedProductType,
        productDescription: !this.typeServicesOrArbitrary() ? this.description.value : null,
        providedServices: this.typeServicesOrArbitrary() ? this.providedServices.value : null,
      },
      fieldUpdateRequest: {
        fieldName: fieldName,
        fieldValue: value
      },
      type: this.selectedProductType
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(product => {
        this.form.patchValue(product);
      });
  }

  public setCurrencyValue(value): number {
    if (!value) { return value;}
    return +(value / 1e2).toFixed(2);
  }

  public updateDescription(description: string): void {
    this.selectedProduct = {
      ...this.selectedProduct,
      description: description
    };
  }

  public updateDescriptionByProductType(): void {
    if (this.selectedProductType === ProductTypes.SERVICES || this.selectedProductType === ProductTypes.ARBITRARY) {
      this.updatePosition(this.providedServices.value, 'providedServices');
    } else {
      this.updatePosition(this.description.value, 'productDescription');
    }
  }

  public typeServicesOrArbitrary(): boolean {
    return this.selectedProductType === ProductTypes.SERVICES || this.selectedProductType === ProductTypes.ARBITRARY;
  }

  get grossTotalPrice(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }
  get grossUnitPrice(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get addRemark(): FormControl { return this.form.get('addRemark') as FormControl; }
  get netTotalPrice(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get netUnitPrice(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get description(): FormControl { return this.form.get('productDescription') as FormControl; }
  get providedServices(): FormControl { return this.form.get('providedServices') as FormControl; }
  get quantity(): FormControl { return this.form.get('quantity') as FormControl; }
  get remark(): FormControl { return this.form.get('remark') as FormControl; }
  get vat(): FormControl { return this.form.get('vat') as FormControl; }
  get discount(): FormControl { return this.form.get('discount') as FormControl; }
  get discountAmountNet(): FormControl { return this.form.get('discountAmountNet') as FormControl; }


}
