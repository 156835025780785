import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';

@Directive({ selector: '[NumbersOnly]' })
export class NumbersOnlyDirective implements OnChanges {
  @Input() allowDecimals: boolean = true;
  @Input() allowSign: boolean = false;
  @Input() decimalSeparator: string = ',';
  @Input() displayedValueWasChanged: string = null;
  @Input() formControl: FormControl;
  @Input() replaceEmpty: boolean;

  previousValue: string = '';
  decimalSeparatorAlternative: string = '.';

  // --------------------------------------
  //  Regular expressions
  integerUnsigned: string = '^[0-9]*$';
  integerSigned: string = '^-?[0-9]+$';
  decimalUnsigned: string = '^[0-9]+(.|,[0-9]+)?$';
  decimalSigned: string = '^-?[0-9]+(.|,[0-9]+)?$';

  constructor(private hostElement: ElementRef) {}

  @TrackInputChanges<number>('displayedValueWasChanged', 'handleDisplayedValueWasChanged', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges) {}

  @HostListener('change', ['$event']) onChange(e) {
    this.validateValue(this.hostElement.nativeElement.value);
    this.hostElement.nativeElement['value'] = String(this.hostElement.nativeElement.value).replace('.', ',');
  }

  @HostListener('input', ['$event']) input(e) {
    this.hostElement.nativeElement['value'] = String(this.hostElement.nativeElement.value).replace('.', ',');
  }

  @HostListener('paste', ['$event']) onPaste(e) {
    // get and validate data from clipboard
    const value = e.clipboardData.getData('text/plain');
    this.validateValue(value);
    this.hostElement.nativeElement['value'] = String(value).replace('.', ',');
    if (this.formControl) {
      this.formControl.patchValue(String(value).replace('.', ','));
    }
    e.preventDefault();
  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    const cursorPosition: number = e.target['selectionStart'];
    const originalValue: string = e.target['value'];
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey === true || e.metaKey === true;
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(this.decimalSeparator) || originalValue.includes(this.decimalSeparatorAlternative);

    // allowed keys apart from numeric characters
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'];

    // when decimals are allowed, add
    // decimal separator or alternative separator to allowed codes when
    // its position is not close to the the sign (-. and .-)
    const separatorIsCloseToSign = signExists && cursorPosition <= 1;
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      allowedKeys.push(',');
      allowedKeys.push('.');
    }

    // when minus sign is allowed, add its
    // key to allowed key only when the
    // cursor is in the first position, and
    // first character is different from
    // decimal separator
    const firstCharacterIsSeparator =
      originalValue.charAt(0) !== this.decimalSeparator || originalValue.charAt(0) !== this.decimalSeparatorAlternative;
    if (this.allowSign && !signExists && firstCharacterIsSeparator && cursorPosition === 0) {
      allowedKeys.push('-');
    }

    // allow some non-numeric characters
    if (
      allowedKeys.indexOf(key) !== -1 ||
      // Allow: Ctrl+A and Command+A
      (key === 'a' && controlOrCommand) ||
      // Allow: Ctrl+C and Command+C
      (key === 'c' && controlOrCommand) ||
      // Allow: Ctrl+V and Command+V
      (key === 'v' && controlOrCommand) ||
      // Allow: Ctrl+X and Command+X
      (key === 'x' && controlOrCommand)
    ) {
      // let it happen, don't do anything
      return;
    }

    // save value before keydown event
    this.previousValue = originalValue;

    // allow number characters only
    const isNumber = new RegExp(this.integerUnsigned).test(key);
    if (isNumber) {
      return;
    } else {
      e.preventDefault();
    }
  }

  handleDisplayedValueWasChanged() {
    this.hostElement.nativeElement['value'] = String(this.hostElement.nativeElement.value).replace('.', ',');
  }

  validateValue(value: string): void {
    // choose the appropriate regular expression
    let regex: string;
    if (!this.allowDecimals && !this.allowSign) {
      regex = this.integerUnsigned;
    }
    if (!this.allowDecimals && this.allowSign) {
      regex = this.integerSigned;
    }
    if (this.allowDecimals && !this.allowSign) {
      regex = this.decimalUnsigned;
    }
    if (this.allowDecimals && this.allowSign) {
      regex = this.decimalSigned;
    }

    // when a numbers begins with a decimal separator,
    // fix it adding a zero in the beginning
    const firstCharacter = value.charAt(0);
    if (firstCharacter === this.decimalSeparator || firstCharacter === this.decimalSeparatorAlternative) {
      value = 0 + value;
    }

    // when a numbers ends with a decimal separator,
    // fix it adding a zero in the end
    const lastCharacter = value.charAt(value.length - 1);
    if (lastCharacter === this.decimalSeparator || lastCharacter === this.decimalSeparatorAlternative) {
      value = value + 0;
    }

    // test number with regular expression, when
    // number is invalid, replace it with a zero
    const valid: boolean = new RegExp(regex).test(value);

    if (this.replaceEmpty) {
      this.hostElement.nativeElement['value'] = valid ? value : 0;
    } else {
      this.hostElement.nativeElement['value'] = value;
    }
  }

  getName(e): string {
    if (e.key) {
      return e.key;
    } else {
      // for old browsers
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-'; // minus in numpad
          case 173:
            return '-'; // minus in alphabet keyboard in firefox
          case 189:
            return '-'; // minus in alphabet keyboard in chrome
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
  }
}
