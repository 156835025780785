import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { BACKGROUND_CONTENT, TimeOfDay } from '../sign-in.config';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { ImageModel } from '../../../../models';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { AuthService } from '../../../../auth/auth.service';
import { CURRENT_VERSION, CURRENT_VERSION_NAME } from 'projects/workspace/src/app/version.constant';

@Component({
  selector: 'rnpl-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  public backGroundContent = BACKGROUND_CONTENT;
  public timeOfDay = TimeOfDay();
  public fullSizeLogoImages: Array<ImageModel> = [];
  public form: FormGroup;
  public showPassword: boolean = false;
  public token: string;
  public currentVersion: string = CURRENT_VERSION;
  public currentVersionName: string = CURRENT_VERSION_NAME;

  readonly companyProfile$: BehaviorSubject<CompanyProfile> = new BehaviorSubject<CompanyProfile>(null);

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private readonly store: Store<AppState>,
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.selectCompanyProfileFromStore();

    this.route.queryParams.subscribe((params: Params) => {
      this.token = params.token;
    });

    this.form = this.fb.group({
      newPassword: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}')]],
    });
  }

  public selectCompanyProfileFromStore() {
    this.store
      .select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile: CompanyProfile) => {
        this.companyProfile$.next(profile);
        this.getFullSizeLogo();
      });
  }

  public getFullSizeLogo() {
    this.fullSizeLogoImages = [this.companyProfile.printLogo];
  }

  public changePassword(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.authService.restorePassword(
      decodeURIComponent(this.token),
      this.newPasswordControl.value
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (this.authService.isUserAuthorized()) {
          this.authService.signOut();
        }
        this.dialogRef.close();
      });
  }

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get companyProfile() { return this.companyProfile$.getValue(); }

  get logoName(): string {
    return window.location.host.includes('it-work.runple.app')
      ? 'it-work_logo_2020'
      : 'runple-logo-dark';
  }

  get newPasswordControl(): FormControl { return this.form.get('newPassword') as FormControl; }

}
