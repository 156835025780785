import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { WarehouseSettingsModel } from 'projects/workspace/src/app/warehouse/warehouse-settings-module/models';

@Component({
  selector: 'rnpl-gls-settings-modal',
  templateUrl: './gls-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlsSettingsModalComponent {

  public form: FormGroup;

  constructor(
    public fb: FormBuilder,
    public deliveryNoteApiService: DeliveryNoteApiService,
    public dialogRef: MatDialogRef<GlsSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      warehouseSettings: WarehouseSettingsModel
    }
  ) {
    this.initForm();
    this.form.patchValue(this.data.warehouseSettings);
  }

  initForm(): void {
    this.form = this.fb.group({
      glsContactId: [null, [Validators.required]],
      glsPassword: [null, [Validators.required]],
      glsUserId: [null, [Validators.required]],
    });
  }

  public onNoAccountClick(): void {
    // window.open('https://ec.europa.eu/commission/presscorner/detail/de/QANDA_19_5555', '_blank');
  }

  public submit(): void {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.dialogRef.close(this.form.getRawValue());
  }

  get glsContactIdControl(): FormControl { return this.form.get('glsContactId') as FormControl; }
  get glsPasswordControl(): FormControl { return this.form.get('glsPassword') as FormControl; }
  get glsUserIdControl(): FormControl { return this.form.get('glsUserId') as FormControl; }

}

