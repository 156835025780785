import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { PAYMENT_DEADLINE_LIST } from 'projects/workspace/src/app/trade/trade.constants';
import { EcoOrderModel } from 'projects/workspace/src/app/e-commerce/modules/eco-orders/models';
import { CountryModel } from '../../../rnpl-common';
import { DeliveryTypesEnum, PaymentCardTypeEnum, PaymentMethodsEnum, PaymentTermsEnum, UIStatesEnum } from '../../../../models';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { ECommerceApiService } from 'projects/workspace/src/app/e-commerce/services';
import { ToasterService } from '../../../ui-components/toaster';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  selectEcoOrder,
  selectEcoOrderCurrentState
} from 'projects/workspace/src/app/e-commerce/modules/eco-orders/store/selectors';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AddressTypeEnum } from 'projects/workspace/src/app/sales-order/enums/address-type.enum';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { EcoFormService } from '../../../../../../projects/workspace/src/app/e-commerce/modules/eco-orders/services/eco-form.service';

@Component({
  selector: 'rnpl-eco-billing-info-modal',
  templateUrl: './eco-billing-info-modal.component.html'
})
export class EcoBillingInfoModalComponent implements OnInit, OnDestroy {
  public readonly paymentDeadlineList: { value: number; label: string; }[] = PAYMENT_DEADLINE_LIST;

  public ecoOrder: EcoOrderModel;
  public countries: CountryModel[];

  public readonly paymentTerms = PaymentTermsEnum;
  public readonly paymentMethods = PaymentMethodsEnum;
  public readonly paymentCardTypeEnum = PaymentCardTypeEnum;
  public readonly deliveryTypes = DeliveryTypesEnum;
  public isReadonly: boolean = true;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;
  public addressTypeList = [];
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private convertAddressToString = convertAddressToStringHelper;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly fb: FormBuilder,
    private readonly eCommerceApiService: ECommerceApiService,
    private readonly toasterService: ToasterService,
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<EcoBillingInfoModalComponent>,
    private ecoFormService: EcoFormService,
    @Inject(MAT_DIALOG_DATA) public data: {
      form: FormGroup
    }
  ) { }

  ngOnInit() {
    this.selectCountries();

    this.store.select(selectEcoOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((eco: EcoOrderModel) => {
        this.ecoOrder = eco;
        this.addressTypeList = [
          {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: this.isCustomerSelected},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);

        // this.updateFormValid();
        this.setFormsState();

        this.ecoFormService.initBillingInfoForm(eco);
        this.data.form = this.ecoFormService.billingInfoForm;
      });

    this.store.select(selectEcoOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        this.isReadonly = response === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  // private updateFormValid(): void {
  //   if (get(this.ecoOrder, 'billingInformation.sconto.enabled')) {
  //     this.scontoRateControl.setValidators(Validators.required);
  //     this.scontoPeriodControl.setValidators(Validators.required)
  //   } else {
  //     this.scontoRateControl.clearValidators();
  //     this.scontoPeriodControl.clearValidators();
  //   }
  //   this.scontoRateControl.updateValueAndValidity();
  //   this.scontoPeriodControl.updateValueAndValidity();
  // }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.data.form.disable(opts);
    } else {
      this.data.form.enable(opts);
    }

    if (this.data.form.get('sameAsDeliveryAddress').value) {
      this.nameControl.disable(opts);
      this.billingAddressFormGroup.disable(opts);
      this.addressTemplateControl.disable(opts);
    }

    if (this.ecoOrder.deliveryInformation.deliveryType === this.deliveryTypes.PICK_UP) {
      this.data.form.get('sameAsDeliveryAddress').disable(opts);
    }

    if (get(this.ecoOrder, 'properties.partnerType') === PartnersTypeEnum.CORPORATE) {
      this.nameControl.disable(opts);
    }
  }

  public updateEcoOrder(fieldValue, fieldName: string): void {
    this.eCommerceApiService.updateEcoOrder(this.ecoOrder.id, {fieldValue, fieldName})
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  public updateAddressField(field: FormInputChangedModel): void {
    const fieldName = 'billingInformation.billingAddress.' + field.fieldName;
    this.updateEcoOrder(field.fieldValue, fieldName);
  }

  public scontoFieldUpdated(field: FormInputChangedModel): void {
    const fieldName = 'billingInformation.sconto.' + field.fieldName;
    this.updateEcoOrder(field.fieldValue, fieldName);
  }

  private selectCountries() {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
      });
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updateEcoOrder(AddressTypeEnum.USER_DEFINED, 'billingInformation.addressTemplate');
  }

  get billingAddressFormGroup(): FormGroup { return this.data.form.get('billingAddress') as FormGroup; }
  get nameControl(): FormControl { return this.data.form.get('name') as FormControl; }
  // get scontoFormGroup(): FormGroup { return this.data.form.get('sconto') as FormGroup; }
  // get scontoRateControl(): FormGroup { return this.data.form.get('sconto').get('rate') as FormGroup; }
  // get scontoPeriodControl(): FormGroup { return this.data.form.get('sconto').get('period') as FormGroup; }
  get bankAccountIdControl(): FormControl { return this.data.form.get('bankAccount').get('id') as FormControl; }
  get ibanControl(): FormControl { return this.data.form.get('iban') as FormControl; }
  get bicControl(): FormControl { return this.data.form.get('bic') as FormControl; }
  get dueWithinDays(): FormControl { return this.data.form.get('paymentDueWithin') as FormControl;}
  get vatDisabled(): FormControl { return this.data.form.get('vatDisabled') as FormControl;}
  get addressTemplateControl(): FormControl { return this.data.form.get('addressTemplate') as FormControl; }

  get paymentTermsValue(): string { return this.data.form.get('paymentTerms').value; }

  get billingAddressAsText(): string {
    if (!this.data.form) { return ''; }

    const addressVal = this.billingAddressFormGroup.value;
    return this.convertAddressToString(addressVal, this.countries);
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'ecoOrder.properties.company.runpleId');
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
