import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'rnpl-incoming-invoice-ocr-error-modal',
  templateUrl: './incoming-invoice-ocr-error-modal.component.html'
})
export class IncomingInvoiceOcrErrorModalComponent {
  public saveOnlyPositions = false;

  constructor(
    public dialogRef: MatDialogRef<IncomingInvoiceOcrErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      hasLinkedPo: boolean,
    }
  ) {
  }

  public submit(matching: boolean): void {
    if (this.data.hasLinkedPo) {
      const params: string = matching
        ? '?confirmed=true&matchDocument=false&savePositions=false'
        : '?confirmed=true&matchDocument=false&savePositions=true';
      this.dialogRef.close(params);
      return;
    }

    this.dialogRef.close(`?confirmed=true&matchDocument=${matching}&savePositions=${this.saveOnlyPositions}`);
  }

}
