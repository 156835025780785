import { createSelector } from '@ngrx/store';

export const purchaseOrderSelector = createSelector(
  state => state['purchaseOrderStore'],
  (po) => po.purchaseOrder
);

export const batchesListSelector = createSelector(
  purchaseOrderSelector,
  (po) => po.batches
);

export const currentBatchSelector = createSelector(
  state => state['purchaseOrderStore'],
  (po) => po.currentBatch.batch
);

export const currentBatchStateSelector = createSelector(
  state => state['purchaseOrderStore'],
  (po) => po.currentBatch.batchState
);
