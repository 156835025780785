import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';

import { finalize, takeUntil } from 'rxjs/operators';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { GlAccountsApiService } from 'projects/workspace/src/app/gl-accounts/services/gl-accounts-api.service';
import { GlAccountModel } from 'projects/workspace/src/app/gl-accounts/models';

@Component({
  selector: 'rnpl-create-new-gl-account-modal',
  templateUrl: './create-new-gl-account-modal.component.html'
})
export class CreateNewGlAccountModalComponent extends BaseModalComponent implements OnDestroy {

  public form: FormGroup;
  public accountsList: GlAccountModel[] = [];

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public router: Router,
    public toasterService: ToasterService,
    private fb: FormBuilder,
    private glAccountsApiService: GlAccountsApiService,
    public dialogRef: MatDialogRef<CreateNewGlAccountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      glAccount: GlAccountModel
    }
  ) {
    super(toasterService);

    this.initForm();
    this.getAccounts();

    if (this.data && this.data.glAccount) {
      this.form.patchValue(this.data.glAccount);
      this.parentIdControl.patchValue(this.data.glAccount.parent.id);

      if (!this.data.glAccount.custom) {
        this.codeControl.disable();
      }

      this.parentIdControl.disable();
    }
  }

  private getAccounts(): void {
    this.glAccountsApiService.getGlAccounts(true)
      .pipe(takeUntil(this._destroy))
      .subscribe((accounts: GlAccountModel[]) => this.accountsList = accounts);
  }

  private initForm(): void {
    this.form = this.fb.group({
      parentId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      code: [null, [Validators.required]],
    });
  }

  public deleteAccount(): void {
    this.glAccountsApiService.deleteGlAccount(this.data.glAccount.id)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.dialogRef.afterClosed()
          .subscribe(() => this.router.navigate(['accounting/gl-accounts']));
        this.dialogRef.close();
      });
  }

  public customSearchFn(term: string, item: GlAccountModel): boolean {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.code.indexOf(term) > -1;
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }
    if (this.submitRequest$.getValue()) { return; }

    this.submitRequest$.next(true);

    let submitRequestType$: Observable<any>;

    if (!this.data || !this.data.glAccount) {
      submitRequestType$ = this.glAccountsApiService.createGlAccount(this.form.getRawValue());
    } else {
      // if (!this.data.glAccount.custom) {
      //   this.dialogRef.close();
      //   return;
      // } else {
        submitRequestType$ = this.glAccountsApiService.updateGlAccount(this.form.getRawValue(), this.data.glAccount.id);
      // }
    }

    submitRequestType$
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((res) => this.dialogRef.close(res));
  }

  get parentIdControl(): FormControl { return  this.form.get('parentId') as FormControl; }
  get nameControl(): FormControl { return  this.form.get('name') as FormControl; }
  get codeControl(): FormControl { return  this.form.get('code') as FormControl; }

}
