import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { AppState } from '../../../../store/state/app.state';
import { PurchaseOrder } from '../../../models';
import { selectPurchaseOrder } from '../../../store/selectors';
import { PurchaseOrderApiService } from '../../../services/purchase-order-api.service';
import { FormInputChangedModel } from '../../../../shared/models/form-input-value.model';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common';

@Component({
  selector: 'rnpl-discount',
  templateUrl: './discount.component.html',
})
export class DiscountComponent implements OnInit, OnDestroy {
  public purchaseOrder: PurchaseOrder;
  public addDiscountControl: FormControl = new FormControl({value: null});
  public discountControl: FormControl = new FormControl({value: null});
  public discountAmountNetControl: FormControl = new FormControl({value: null});
  public discountAmountGrossControl: FormControl = new FormControl({value: null});

  readonly destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<AppState>,
    private readonly poApi: PurchaseOrderApiService,
    private readonly metricPipe: MetricConversionPipe,
  ) {
  }

  ngOnInit(): void {
    this.trackTradeOfferChanges();
  }

  private trackTradeOfferChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        this.addDiscountControl.patchValue(this.purchaseOrder.addDiscount);
        this.discountControl.patchValue(this.purchaseOrder.discount);
        this.discountAmountNetControl.patchValue(this.getCurrencyConvertedValue(this.purchaseOrder.discountAmountNet));
        this.discountAmountGrossControl.patchValue(this.getCurrencyConvertedValue(this.purchaseOrder.discountAmountGross));
      });
  }

  public updateDiscount(field: FormInputChangedModel): void {
    this.poApi.updatePO(this.purchaseOrder.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  private getCurrencyConvertedValue(value: number|string): number|string {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
