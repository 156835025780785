export * from './add-banner-modal/add-banner-modal.component';
export * from './add-category-modal/add-category-modal.component';
export * from './slider-modal/slider-modal.component';
export * from './update-delivery-term-modal/update-delivery-term-modal.component';
export * from './update-returns-term-modal/update-returns-term-modal.component';
export * from './card-payments-stripe-modal/card-payments-stripe-modal.component';
export * from './update-card-payments-modal/update-card-payments-modal.component';
export * from './update-payment-upon-pickup-modal/update-payment-upon-pickup-modal.component';
export * from './update-purchase-on-invoice-modal/update-purchase-on-invoice-modal.component';
export * from './update-block-color-modal/update-block-color-modal.component';
