import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { GlAccountModel } from '../../models';
import { GlAccountsApiService } from '../../services/gl-accounts-api.service';

@Component({
  selector: 'rnpl-gl-account-dropdown',
  templateUrl: './gl-account-dropdown.component.html',
})
export class GlAccountDropdownComponent implements OnInit, OnChanges, OnDestroy {

  @Input() isOptional = false;
  @Input() isReadonly: boolean = false;
  @Input() validate: boolean = false;
  @Input() title: string;
  @Input() value: number;
  @Input() control: FormControl = new FormControl();

  @Output() fieldUpdated: EventEmitter<number> = new EventEmitter<number>();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly glAccountsList$: BehaviorSubject<GlAccountModel[]> = new BehaviorSubject<GlAccountModel[]>([]);

  constructor(
    private readonly glAccountsApiService: GlAccountsApiService,
  ) {
  }

  ngOnInit(): void {
    this.getGLAccounts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('isReadonly')) {
      if (this.control) {
        if (this.isReadonly) {
          this.control.disable({ emitEvent: false });
        } else {
          this.control.enable({ emitEvent: false });
        }
      }
    }
    if (changes && changes.hasOwnProperty('value')) {
      this.control.patchValue(this.value, { emitEvent: false });
    }
  }

  private getGLAccounts(): void {
    this.glAccountsApiService.getGlAccounts()
      .pipe(takeUntil(this.destroy$))
      .subscribe((accounts: GlAccountModel[]) => {
        this.glAccountsList$.next(accounts);
      });
  }

  public updateField(accountId: number): void {
    this.fieldUpdated.emit(accountId);
  }

  public customSearchFn(term: string, item: GlAccountModel): boolean {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.code.indexOf(term) > -1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
