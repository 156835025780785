import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { delayWhen, finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { arrayMinLengthValidator } from '../../../rnpl-common/validators';
import { BaseModalComponent } from '../../../rnpl-common/components';
import { CountryModel } from '../../../rnpl-common/models';
import { CONTROLS } from '../../../dynamic-forms/controls';
import { ToasterService } from '../../../ui-components/toaster';
import { AttributeModel } from '../../../system-settings/models';
import { SystemSettingsService } from '../../../system-settings/system-settings.service';
import { ProductEntityTypes, ProductTypes } from '../../../products/product-types';
import { FamilyModel } from '../../../products';
import { optionsCountValidator, uniqueOptionsValidator } from '../../../system-settings/attribute-wizard/attribute-form.factory';
import { MAX_OPTIONS_COUNT_RADIO } from '../../../system-settings/attribute-wizard/constants';
import { CommonModalsActionsEnum, DangerModalComponent, InfoModalComponent, WarningModalComponent } from '../../modals-common';
import { CategoryHasAttributeErrorModalData, ChildCategoryHasAttributeErrorModalData, DeletePermanentlyModalData } from './family-assigned-attr-modal.config';
// import {HintsButtonsModel, HintsModel, HintsWhenModel} from '../../../rnpl-common/models/hints.model';
// import {HintsButtonCancelTemplate, HintsIndexTimerText} from '../../../rnpl-common/constants/hints-constans';

type injectedDataType = {
  family?: FamilyModel;
  productType?: ProductTypes;
  entityType?: ProductEntityTypes;
  entities?: string;
  formKey?: string;
  attribute?: AttributeModel;
};

@Component({
  selector: 'rnpl-family-assigned-attr-modal',
  templateUrl: './family-assigned-attr-modal.component.html',
  styleUrls: ['./family-assigned-attr-modal.component.scss']
})
export class FamilyAssignedAttrModalComponent extends BaseModalComponent  implements OnInit, OnDestroy {

  public isLoadingList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitAttributeRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public static KEY_CODE_ENTER = 13;
  public static KEY_CODE_TAB = 9;
  public static KEY_CODE_BACKSPACE = 8;

  public static MAX_OPTIONS_COUNT = {
    RADIO: 2,
    SELECT: 2,
  };

  public previewAttribute: AttributeModel;

  public countries: CountryModel[];

  public form: FormGroup;

  public controls: any;
  public controlList: Array<any> = [];

  public controlOptions: Array<any> = [];

  public control: any = null;
  public oldControl: any;

  public optionsIsAvail: boolean = false;
  public newOptionIsAvail: boolean = false;

  public minOptionsCount: number = 0;

  private defaultLang: string = 'eng';

  private lastOptKey: number = 0;


  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private hostElement: ElementRef,
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public systemSettingsService: SystemSettingsService,
    public dialogRef: MatDialogRef<FamilyAssignedAttrModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: injectedDataType
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.isLoadingList$.next(true);
    this.initForm(this.attribute);

    this.controlOptions = this.form.get('options').value;

    this.lastOptKey = this.controlOptions.reduce((lastKey, option) => {
      if (option.key && option.key > lastKey) {
        return option.key;
      } else {
        return lastKey;
      }
    }, 0);

    this.getControls();
    this.getAvailableCountries();
  }

  private getControls(): void {
    this.systemSettingsService.getControls()
      .subscribe((response) => {
        this.controls = response.data.controls;
        this.controlList = Object.keys(this.controls)
          .filter(key => !!CONTROLS[key])
          .map(key => {
            return {label: this.controls[key], value: key};
          });

        const control = get(this, 'attribute.control');
        if (control) {
          this.control = this.controlList.find(controlDef => controlDef.value === control);
          this.setControl(this.control);
          this.oldControl = this.control;
          this.updateMinOptionsCount(control.value);
          this.optionsIsAvail = this.optionsAvailable(control.value);
          this.newOptionIsAvail = this.newOptionAvailable();
        }
        this.isLoadingList$.next(false);
      });
  }

  private getAvailableCountries(): void {
    this.systemSettingsService.getAvailableCountries()
      .subscribe(response => {
        this.countries = response.data;

        const gbr = this.countries.find(country => country.code_iso3 === 'gbr');
        this.form.get('localizations').setValue([{country_id: gbr.id}]);
      });
  }

  private initForm(attr: AttributeModel): void {
    this.form = this.fb.group({
      name: [get(attr, 'name', null), [Validators.required]],
      type: ['custom', [Validators.required]], // default for workspace
      required: [get(attr, 'required', false)],
      printable: [get(attr, 'printable', false)],
      control: [null, [Validators.required]],
      localizations: [get(attr, 'localizations', []), [arrayMinLengthValidator(1)]],
      translations: [get(attr, 'translations', []), [arrayMinLengthValidator(1)]],
      options: [get(attr, 'options', [])],
      business_form_relations: [get(attr, 'business_form_relations', [{business_form: this.data.formKey}]), [Validators.required]],
    });

    this.form.get('options').setValidators([
      optionsCountValidator(this.form.get('control'), 2),
      uniqueOptionsValidator()
    ]);

    this.form.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((attr) => {
        this.previewAttribute = attr.name && attr.control
          ? { ...attr, control: attr.control.value }
          : null;
      });

    this.form.get('name').valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value: string) => {
        this.form.get('translations').setValue([{code_iso3: this.defaultLang, value}]);
      });
  }

  /**
   * Asks user for confirmation before change
   *
   * @param control The object with key and label of control
   */
  public changeControl(control): void {
    if (!this.oldControl) {
      this.setControl(control);
      this.oldControl = control;

      this.updateMinOptionsCount(control.value);
      return;
    }

    const optionsCount = this.controlOptions.filter(option => !!option[this.defaultLang]).length;
    const excessCount = this.getExcessOptionsCount(control.value, optionsCount);

    if (excessCount) {
      const dialog = this.dialog.open(WarningModalComponent, {
        data: {
          title: 'PRODUCTS.CONFIRM_CONTROL_CHANGE',
          message: this.translateService.instant('PRODUCTS.CONTROL_LOST', {label: control.label}),
        }
      });

      dialog.afterClosed().subscribe(res => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.setupNewControl(control, excessCount);
        } else {
          this.control = this.oldControl;
        }
      });
    } else {
      this.setupNewControl(control, excessCount);
    }
  }

  public setupNewControl(control, excessCount: number): void {
    this.controlOptions = this.controlOptions.filter(option => !!option[this.defaultLang]);
    this.controlOptions.splice(this.controlOptions.length - excessCount, excessCount);
    this.form.get('options').setValue(this.controlOptions);

    this.setControl(control);

    this.oldControl = this.control;
    this.updateMinOptionsCount(control.value);

  }

  /**
   * Adds new option if current isn't empty
   */
  public addOption(force: boolean = false): void {
    const current = document.activeElement as HTMLInputElement;
    const optionElement = current.closest('.drag-option')
      || this.hostElement.nativeElement.querySelector('.drag-option:last-child');
    if (!force && optionElement && !optionElement.querySelector('input').value) {
      optionElement.classList.add('has-error');
      optionElement.querySelector('input').focus();
      return;
    }

    const option = {key: ++this.lastOptKey};
    option[this.defaultLang] = '';
    this.controlOptions.push(option);
    this.form.get('options').setValue(this.controlOptions);
    this.newOptionIsAvail = this.newOptionAvailable();
    this.focusOnOption(this.controlOptions.length - 1);
  }

  /**
   * Removes options from option list by option index
   *
   * @param index The index of option
   */
  public removeOption(index: number): void {
    this.controlOptions.splice(index, 1);
    this.form.get('options').setValue(this.controlOptions);
    this.newOptionIsAvail = this.newOptionAvailable();
  }

  /**
   * Changes order of options
   *
   * @param event The drag'n'drop event object
   */
  public reorderOptions(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.controlOptions, event.previousIndex, event.currentIndex);
    this.form.get('options').setValue(this.controlOptions);
  }

  /**
   * Renames options
   *
   * @param event The keyup event object
   * @param index The index of options
   */
  public renameOption(event, index: number): void {
    event.target.closest('.drag-option').classList.remove('has-error');

    if (event.target.value) {
      const sameOption = this.controlOptions
        .find(option => option.eng.toLowerCase() === event.target.value);
      if (sameOption) {
        event.target.closest('.drag-option').classList.add('has-error');
      }
    }

    this.controlOptions[index][this.defaultLang] = event.target.value;
    this.form.get('options').setValue(this.controlOptions);
  }

  /**
   * Handle clicks on 'Enter' and 'Tab' buttons
   *
   * @param event The keydown event object
   * @param index The index of options
   */
  public handleKeyboardOptionsEvents(event, index: number): void {
    if (event.which === FamilyAssignedAttrModalComponent.KEY_CODE_ENTER
      && this.controlOptions.length === index + 1
      && this.newOptionIsAvail) {

      this.addOption();
      return;
    }

    if (event.which === FamilyAssignedAttrModalComponent.KEY_CODE_TAB
      || (event.which === FamilyAssignedAttrModalComponent.KEY_CODE_ENTER
        && (this.controlOptions.length > index + 1 || !this.newOptionIsAvail))) {

      const focusedIndex = index + 1 < this.controlOptions.length
        ? index + 1
        : 0;
      this.focusOnOption(focusedIndex);
      return;
    }

    if (event.which === FamilyAssignedAttrModalComponent.KEY_CODE_BACKSPACE
      && !this.controlOptions[index][this.defaultLang].length
      && this.controlOptions.length > this.minOptionsCount) {

      this.removeOption(index);
      if (index > 0) {
        this.focusOnOption(index - 1);
      }
    }
  }

  /**
   * Checks if new option is available
   */
  public newOptionAvailable(): boolean {
    const control = this.form.get('control').value.value;
    const optionsCount = this.form.get('options').value.length;

    switch (control) {
      case 'radio':
        return optionsCount < FamilyAssignedAttrModalComponent.MAX_OPTIONS_COUNT.RADIO;
      case 'select':
      case 'tokenfield':
      case 'combobox':
        return true;
      default:
        return false;
    }
  }

  private updateMinOptionsCount(control: string): void {
    switch (control) {
      case 'radio':
      case 'select':
      case 'combobox':
      case 'tokenfield':
        this.minOptionsCount = 2;
        break;
      default:
        this.minOptionsCount = 0;
    }

    while (this.minOptionsCount > this.controlOptions.length) {
      this.addOption(true);
    }
    const firstEmptyIndex = this.controlOptions.findIndex(option => !option[this.defaultLang]);
    if (firstEmptyIndex > -1) {
      this.focusOnOption(firstEmptyIndex);
    }
  }

  /**
   * Makes focus at field of option by index
   *
   * @param index The index of option
   */
  private focusOnOption(index): void {
    setTimeout(() => {
      const inputs = this.hostElement.nativeElement.querySelectorAll('.drag-option input');
      inputs.item(index).focus();
    }, 50);
  }

  /**
   * Checks if options are available for current control
   *
   * @param control Name of control
   */
  public optionsAvailable(control?: string): boolean {
    if (!this.form || !this.form.get('control')) {
      return false;
    }
    control = control || this.form.get('control').value.value;
    return ['tokenfield', 'radio', 'select', 'combobox'].includes(control);
  }

  public setControl(control): void {
    this.form.get('control').setValue(control);
    this.form.get('control').markAsDirty();
    this.optionsIsAvail = this.optionsAvailable();
    this.newOptionIsAvail = this.newOptionAvailable();

    if (this.newOptionIsAvail && !this.controlOptions.length) {
      this.addOption();
    }
    this.form.get('options').updateValueAndValidity();
  }

  private getExcessOptionsCount(control: string, optionsCount: number): number {

    let excess = 0;
    switch (control) {
      case 'radio':
        if (optionsCount > MAX_OPTIONS_COUNT_RADIO) {
          excess = optionsCount - MAX_OPTIONS_COUNT_RADIO;
        }
        break;
      case 'select':
      case 'tokenfield':
      case 'combobox':
        return 0;
      default:
        excess = optionsCount;
    }

    return excess;
  }

  public submitAttribute(): void {
    if (this.submitAttributeRequest$.getValue()) { return; }
    this.submitAttributeRequest$.next(true);

    const formVal = this.form.getRawValue();
    formVal.translations.map(item => {
      if (!item.code_iso3 && item.language.code_iso3) {
        item.code_iso3 = item.language.code_iso3;
      }
    });

    const attribute = {
      ...formVal,
      control: get(formVal, 'control.value', null),
      family_id: +this.data.family.id
    };

    if (this.attribute) {
      this.updateAttribute(attribute);
    } else {
      this.createAttribute(attribute);
    }
  }

  public createAttribute(attr: AttributeModel): void {
    this.systemSettingsService.saveAttribute(attr)
      .pipe(delayWhen(response => {
        return this.systemSettingsService.saveAttributeRelationsBatch(
          response.data.id,
          [this.data.formKey],
          [+this.data.family.id]);
      }))
      .pipe(
        finalize(() => this.submitAttributeRequest$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.displayMessage('success', this.translateService.instant('SUCCESS_CREATED.ATTR', { attributeName: this.attributeName}));
        this.dialogRef.close(CommonModalsActionsEnum.SUCCESS);
      }, error => {
        this.handlePopupErrors(error.error.message);
      });
  }

  public updateAttribute(attr: AttributeModel): void {
    this.systemSettingsService.updateAttribute(attr, this.attribute.id)
      .pipe(
        finalize(() => this.submitAttributeRequest$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.displayMessage('success', this.translateService.instant('PRODUCTS.ATTR_UPDATED', {attrName: this.attributeName}));
      }, error => {
        this.handlePopupErrors(error.error.message);
      });
  }

  private handlePopupErrors(errorText: string): void {
    switch (errorText) {
      case 'Attribute with same name already exists in current category':
      case 'Attribute with same name already exists in parent category':
        this.dialog.open(InfoModalComponent, {
          data: CategoryHasAttributeErrorModalData(this.attributeName, this.translateService)
        });
        break;
      case 'Attribute with same name already exists in child category':
        this.dialog.open(InfoModalComponent, {
          data: ChildCategoryHasAttributeErrorModalData(this.attributeName, this.translateService)
        });
        break;
      default:
        this.displayMessage('error', errorText);
        break;
    }
  }

  public deleteAttribute(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: DeletePermanentlyModalData
    });

    dialog.afterClosed().subscribe(res => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.systemSettingsService.deleteAttributes([this.attribute.id])
          .subscribe(() => {
            this.displayMessage('success', this.translateService.instant('PRODUCTS.ATTR_DELETED', {attrName: this.attributeName}));
            this.dialogRef.close(CommonModalsActionsEnum.DELETE);
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get attribute(): AttributeModel {
    return this.data.attribute || null;
  }

  get attributeName(): string {
    return this.form.get('name').value;
  }

}
