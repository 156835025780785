import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { AppDataModel, AppNameEnum } from '../../start-screen-page/launchpad.model';
import { AppDataByModuleName } from '../../start-screen-page/app-data.config';
import { LaunchpadApiService } from '../../start-screen-page/launchpad-api.service';

@Component({
  selector: 'rnpl-launch-pad-add-shortcuts-modal',
  templateUrl: './launch-pad-add-shortcuts-modal.component.html',
  styleUrls: ['./launch-pad-add-shortcuts-modal.component.scss']
})
export class LaunchPadAddShortcutsModalComponent implements OnInit, OnDestroy {
  public scrolledIsBottom: boolean;
  public isScrolled: boolean;
  public availableAppsList: AppNameEnum[] = [];
  public appDataByModuleName: {[key: string]: AppDataModel} = AppDataByModuleName();
  public addAppRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public dialogRef: MatDialogRef<LaunchPadAddShortcutsModalComponent>,
    private launchpadApiService: LaunchpadApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      screenCount: number;
    }
  ) { }

  ngOnInit() {
    this.getAvailableStartScreenApps();
  }

  public getAvailableStartScreenApps(): void {
    this.launchpadApiService.getAvailableStartScreenApps()
      .pipe(
        finalize(() => {
          this.addAppRequest$.next(false);
          this.isLoading$.next(false)
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        const prepareList = [];
        Object.keys(data).map(itm => prepareList.push(data[itm]));
        this.availableAppsList = prepareList;
      });
  }

  public addToStartScreen(item): void {
    if (!this.addAppRequest$.value) {
      this.addAppRequest$.next(true);
      this.launchpadApiService.addAppToStartScreen(item, this.data.screenCount)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.getAvailableStartScreenApps();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
