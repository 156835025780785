import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent, UrlSegment } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { get } from 'lodash';

import { NavBarBtnModel } from 'common/src/models';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { BatchStates } from '../../../../enums/batch-states';
import { BatchTabs } from '../../../../enums/batch-tabs';
import { BatchModel } from '../../../../models/purchase-order/batch.model';
import { PurchaseOrderApiService } from '../../../../services/purchase-order-api.service';
import { LoadBatchState, LoadCurrentBatch } from '../../../../store/actions/batch.actions';
import { UpdatePO } from '../../../../store/actions/po.actions';
import { currentBatchSelector, currentBatchStateSelector, purchaseOrderSelector } from '../../../../store/selectors/po.selectors';
import { BatchTabDefinition } from './batch-view-and-edit.config';
import { getBatchButtons } from './batch-view-and-edit.constants';
import { PurchaseOrder } from '../../../../models';
import { DangerModalComponent } from 'common/src/modules/modals/modals-common';


@Component({
  selector: 'rnpl-batch-view-and-edit',
  templateUrl: './batch-view-and-edit.component.html',
  styleUrls: ['./batch-view-and-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatchViewAndEditComponent implements OnDestroy, OnInit {

  readonly activePO$: BehaviorSubject<PurchaseOrder> = new BehaviorSubject({} as PurchaseOrder);
  readonly activeBatch$: BehaviorSubject<BatchModel> = new BehaviorSubject({} as BatchModel);
  readonly activeTab$: BehaviorSubject<BatchTabs> = new BehaviorSubject(BatchTabs.POSITIONS);
  readonly activeState$: BehaviorSubject<BatchStates> = new BehaviorSubject(BatchStates.VIEW);
  readonly destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  readonly tabButtons$: BehaviorSubject<NavBarBtnModel[]> = new BehaviorSubject([]);

  public tabs: TabDefinitionModel[] = BatchTabDefinition;
  public batchId: number;
  public poId: number;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly poApi: PurchaseOrderApiService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly route: ActivatedRoute,
    private readonly store: Store<AppState>
  ) {
    this.storeSubscribes();

    this.routerParamsSunscribe();
  }

  ngOnInit(): void {
    combineLatest([this.activeState$, this.activeBatch$])
      .pipe(
        map(([activeState, activeBatch]) => ({ activeState, activeBatch })),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.tabButtons$.next(getBatchButtons(response.activeState, response.activeBatch));
        this.cdr.detectChanges();
      });

    this.activeTab$.next(this.getCurrentSegment());

    this.routerSubscribe();
  }

  public storeSubscribes(): void {
    this.store
      .pipe(
        select(purchaseOrderSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(po => {
        this.activePO$.next(po);
      });

    this.store
      .pipe(
        select(currentBatchSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(activeBatch => {
        this.activeBatch$.next(activeBatch);
      });

    this.store
      .pipe(
        select(currentBatchStateSelector),
        takeUntil(this.destroy$)
      )
      .subscribe(activeState => {
        this.activeState$.next(activeState);
      });
  }

  public routerParamsSunscribe(): void {
    this.route.paramMap
      .pipe(
        tap((params) => {
          this.batchId = +params.get('batchId');
          this.poId = +params.get('id');
        }),
        switchMap(() => this.activePO$),
        takeUntil(this.destroy$)
      )
      .subscribe(purchaseOrder => {
        const selectedBatch = purchaseOrder.batches.find(batch => batch.id === this.batchId) || {};
        this.store.dispatch(LoadCurrentBatch({ batch: selectedBatch }));
      });
  }

  public getCurrentSegment(): BatchTabs {
    const currentSegment = get(
      this.router,
      'browserUrlTree.root.children.primary.segments',
      [new UrlSegment(BatchTabs.POSITIONS, {})]);
    return currentSegment[currentSegment.length - 1].path;
  }

  public routerSubscribe(): void {
    this.router.events
      .pipe(
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        filter((event: RouterEvent) => event.url.includes('batch-manage')),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.activeTab$.next(this.getCurrentSegment()));
  }

  public onCloseClick(): void {
    const url: any[] = ['/', 'trade', 'purchase-order', this.poId, 'batches'];
    this.router.navigate(url);
  }

  public onDeleteClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'Delete batch?',
        message: 'You are about to delete batch. This action cannot be undone.',
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(
        filter((r: boolean) => !!r),
        switchMap(() => this.poApi.deleteBatch(this.route.snapshot.params.id)),
      )
      .subscribe(() => {
        this.onCloseClick();
      });
  }

  public onEditClick(): void {
    this.store.dispatch(LoadBatchState({ batchState: BatchStates.EDIT }));
  }

  public onSaveClick(): void {
    this.store.dispatch(LoadBatchState({ batchState: BatchStates.VIEW }));

    this.poApi.createNewBatch(this.poId, { batches: [this.activeBatch$.getValue()] })
      .pipe(
        map(po => po.data),
        takeUntil(this.destroy$)
      )
      .subscribe(po => {
        this.store.dispatch(UpdatePO({ purchaseOrder: po }));
        this.onCloseClick();
      });
  }

  public actionsEvent(e: string): void {
    this[e]();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
