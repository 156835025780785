import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import {
  BankTransferMethodsEnum,
  ButtonDropdownActionsModel,
  GeneralPartnerTypeEnum,
  NavBarBtnModel,
  UIStatesEnum
} from 'common/src/models';
import { IncomingInvoiceListTabsEnum } from '../../enums';
import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';
import { IncomingInvoiceModel } from '../../models/incoming-invoice.model';
import {
  CreateDocumentStepsModel,
  DocumentToDoListModel,
  LinkedDocumentsModel,
  ViewDocumentListModel
} from 'common/src/models/view-document-list.model';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { DocumentPreviewTabsEnum } from 'common/src/models/document-preview-tabs.enum';
import { DocumentTypeEnum } from 'common/src/modules/ui-components/document-steps/document-step.model';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from '../../../accounting/accounting.constants';
import { PartnersTypeEnum } from '../../../partners/corporate/enums';
import { CurrencyFormat } from 'common/src/modules/rnpl-common/pipes/currency-format/currency-format.pipe';

export function getViewDocumentList(
  invoice: IncomingInvoiceModel,
  positionsCount: number,
  showLinkedDoc: boolean,
  isNarrowScreen: boolean,
  currentState: UIStatesEnum
): ViewDocumentListModel[] {
  const viewDocumentItems: ViewDocumentListModel[] = [
    {
      title: 'APP.BUSINESS_PARTNER',
      template: 'BusinessPartner',
      anchor: 'businessPartner',
    },
    {
      icon: 'file-text',
      title: 'DOC_BLOCK.PROPERTIES',
      template: 'Properties',
      anchor: 'properties',
    },
    {
      icon: 'list',
      title: 'DOC_BLOCK.POSITIONS',
      template: 'Position',
      count: positionsCount,
      anchor: 'positions',
    },
    {
      icon: 'euro',
      title: 'DOC_BLOCK.BILLING',
      template: 'BillingInfo',
      anchor: 'billing',
      hintId: 'invoiceCreateStep',
    },
  ];

  const fileUploader: ViewDocumentListModel = {
    icon: 'attach',
    title: 'DOC_BLOCK.INVOICE_FILE',
    template: 'InvoiceFile',
    count: invoice.files.length,
    anchor: 'invoice-file',
  };


  // const linkDocuments: ViewDocumentListModel = {
  //   icon: 'link',
  //   title: 'TABLE_SUMMARY_BAR.DOCUMENTS',
  //   template: 'Documents',
  //   count: invoice.properties.linkedDocuments.length,
  //   anchor: 'Documents',
  //   // showIndicator: !invoice.properties.linkedDocuments.length,
  // };

  // if (showLinkedDoc) {
  //   if (
  //     invoice.status === IncomingInvoiceListTabsEnum.CANCELED && !!invoice.properties.linkedDocuments.length ||
  //     invoice.status === IncomingInvoiceListTabsEnum.DELETED && !!invoice.properties.linkedDocuments.length ||
  //     invoice.status !== IncomingInvoiceListTabsEnum.DELETED && invoice.status !== IncomingInvoiceListTabsEnum.CANCELED
  //   ) {
  //
  //     viewDocumentItems.splice(-1, 0, linkDocuments);
  //   }
  // }

  if (isNarrowScreen && (currentState !== UIStatesEnum.VIEW || get(invoice, 'files.length'))) {
    viewDocumentItems.splice(3, 0, fileUploader);
  }

  return viewDocumentItems;
}

export function getIncomingInvoiceNavBarButtons(
  incomingInvoice: IncomingInvoiceModel = {} as IncomingInvoiceModel,
  state: UIStatesEnum = UIStatesEnum.VIEW,
  hasLinkedPO: boolean = true,
  hasLinkedSP: boolean = true,
  hasPayments: boolean = false,
  isForced: boolean = false,
  isOcrProcessing: boolean = false,
  isEqualized: boolean = false
): NavBarBtnModel[] {
  const NavBarButtons: NavBarBtnModel[] = [
    {
      classes: 'btn-white',
      text: 'BUTTON.MORE_ACTIONS',
      iconName: 'menu',
      disabled: isOcrProcessing && state === UIStatesEnum.CREATE,
      // allowLoadingSpinner: true,
      disabledMainAction: true,
      withActions: true,
      spinnerColor: '#5E6681',
      multiple: false,
      dropdownClass: 'inner-blue-400',
      width: 141,
      isMoreAction: true,
      actions: [
        {
          actionTitle: 'BUTTON.CREATE_CREDIT_NOTE',
          actionName: 'createCreditNote',
          actionIcon: 'credit-note',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.DRAFT,
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.CANCELED,
            IncomingInvoiceListTabsEnum.DELETED,
          ],
          hideAction: isEqualized || get(incomingInvoice, 'amountDue.foreignCurrency')
        },
        {
          actionTitle: 'BUTTON.DELETE',
          actionName: 'onDeleteClick',
          actionIcon: 'trash-2',
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.PAID,
            IncomingInvoiceListTabsEnum.OPEN,
            IncomingInvoiceListTabsEnum.DELETED,
          ],
        },
        {
          actionTitle: 'BUTTON.DELETE_PERMANENTLY',
          actionName: 'onDeletePermanentlyClick',
          actionIcon: 'fire',
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.PAID,
            IncomingInvoiceListTabsEnum.OPEN,
            IncomingInvoiceListTabsEnum.DRAFT,
            IncomingInvoiceListTabsEnum.CANCELED,
          ],
        },
        {
          actionTitle: 'BUTTON.CANCEL_ENTITY',
          actionName: 'onCancelClick',
          actionIcon: 'close-square',
          classes: 'btn-white red',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.PAID,
            IncomingInvoiceListTabsEnum.DRAFT,
            IncomingInvoiceListTabsEnum.CANCELED,
            IncomingInvoiceListTabsEnum.DELETED,
          ],
        },
        {
          actionTitle: 'BUTTON.WITHDRAW_APPROVAL',
          actionName: 'onWithdrawUApprovalClick',
          actionIcon: 'undo',
          classes: 'btn-white',
          greyIcon: true,
          hideAction: !get(incomingInvoice, 'properties.approved', false),
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.DRAFT,
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.PAID,
            IncomingInvoiceListTabsEnum.CANCELED,
            IncomingInvoiceListTabsEnum.DELETED,
          ],
        },
        {
          actionTitle: 'BUTTON.LINK_PO',
          actionName: 'linkDocument',
          actionIcon: 'link',
          showWithLinkedDocument: false,
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.DELETED,
            IncomingInvoiceListTabsEnum.CANCELED,
            IncomingInvoiceListTabsEnum.OPEN,
            IncomingInvoiceListTabsEnum.PAID,
          ],
        },
        {
          actionTitle: 'SCHEDULED_PAYMENT.LINK_SP',
          actionName: 'linkDocumentScheduledPayment',
          actionIcon: 'link',
          classes: 'btn-white',
          greyIcon: true,
          showWithLinkedDocument: false,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.DELETED,
            IncomingInvoiceListTabsEnum.CANCELED,
            IncomingInvoiceListTabsEnum.OPEN,
            IncomingInvoiceListTabsEnum.PAID,
          ],
        },
        {
          actionTitle: 'SCHEDULED_PAYMENT.CREATE_SCHEDULED_PAYMENT_BUTTON',
          actionIcon: 'recurring-costs',
          actionName: 'createBasedOnSP',
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.DRAFT,
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.CANCELED,
            IncomingInvoiceListTabsEnum.DELETED,
          ],
        },
        {
          actionTitle: 'BUTTON.CLONE',
          actionName: 'onCloneClick',
          actionIcon: 'copy',
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [IncomingInvoiceListTabsEnum.DELETED],
        },
        {
          actionTitle: 'BUTTON.REOPEN',
          actionName: 'onRestoreClick',
          actionIcon: 'undo',
          classes: 'btn-white',
          greyIcon: true,
          hideWithStatus: [
            IncomingInvoiceListTabsEnum.PENDING,
            IncomingInvoiceListTabsEnum.PAID,
            IncomingInvoiceListTabsEnum.OPEN,
            IncomingInvoiceListTabsEnum.DRAFT,
          ],
        },
      ],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white d-none',
      multipleClasses: 'btn-white',
      text: 'BUTTON.TRANSFER_FILE',
      multipleText: 'BUTTON.TRANSFER_FILE_BULK',
      iconName: 'euro',
      actionName: 'onExportXMLClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: true,
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.DRAFT,
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white red d-none',
      multipleClasses: 'btn-white red',
      text: 'BUTTON.DELETE',
      multipleText: 'BUTTON.DELETE',
      badgeClass: 'rnpl-badge--red',
      iconName: 'trash-2',
      actionName: 'onDeleteClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: true,
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.OPEN,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-white red d-none',
      multipleClasses: 'btn-white red',
      text: 'BUTTON.DELETE_PERMANENTLY',
      multipleText: 'BUTTON.DELETE_PERMANENTLY',
      badgeClass: 'rnpl-badge--red',
      iconName: 'fire',
      actionName: 'onDeletePermanentlyClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.OPEN,
        IncomingInvoiceListTabsEnum.DRAFT,
        IncomingInvoiceListTabsEnum.CANCELED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.EDIT',
      iconName: 'edit',
      actionName: 'onEditClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE',
      iconName: 'plus-square',
      disabled: isOcrProcessing && state === UIStatesEnum.CREATE,
      multiple: false,
      allowLoadingSpinner: true,
      actionName: 'onProceedClick',
      dropdownClass: 'inner-blue-400',
      hintsId: 'createInvoiceBtn',
      actions: [
        {
          actionTitle: 'BUTTON.CREATE',
          actionName: 'onProceedClick',
          actionIcon: 'plus-square'
        },
        {
          actionTitle: 'BUTTON.SAVE_AS_DRAFT',
          actionName: 'onCreateClick',
          actionIcon: 'save'
        },
      ],
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.OPEN,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.CREATE]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.CREATE',
      iconName: 'plus-square',
      actionName: 'onProceedClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.OPEN,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.VIEW]
    },
    {
      classes: 'btn-primary',
      text: 'BUTTON.DONE',
      iconName: 'checkmark',
      disabled: isOcrProcessing && state === UIStatesEnum.CREATE,
      actionName: 'onFinishEditingClick',
      hintsId: 'doneBtn',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
      other: [UIStatesEnum.EDIT]
    }
  ];

  if (hasPayments) {
    const forceCompleteBtn: ButtonDropdownActionsModel = {
      actionTitle: 'BUTTON.FORCE_COMPLETE',
      actionIcon: 'checkbox-on',
      disabled: isOcrProcessing && state === UIStatesEnum.CREATE,
      actionName: 'onForceCompleteClick',
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.DRAFT,
        IncomingInvoiceListTabsEnum.PAID,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
    };

    NavBarButtons[0].actions.splice(5, 0, forceCompleteBtn);
  }

  if (isForced) {
    const forceCompleteBtn: ButtonDropdownActionsModel = {
      actionTitle: 'BUTTON.WITHDRAW_COMPLETION',
      actionIcon: 'close-square',
      disabled: isOcrProcessing && state === UIStatesEnum.CREATE,
      actionName: 'onWithdrawCompletionInvoiceClick',
      hideWithStatus: [
        IncomingInvoiceListTabsEnum.PENDING,
        IncomingInvoiceListTabsEnum.DRAFT,
        IncomingInvoiceListTabsEnum.OPEN,
        IncomingInvoiceListTabsEnum.CANCELED,
        IncomingInvoiceListTabsEnum.DELETED,
      ],
    };

    NavBarButtons[0].actions.splice(5, 0, forceCompleteBtn);
  }

  const linkedBtn: NavBarBtnModel = {
    classes: 'btn-white',
    text: 'BUTTON.LINK',
    iconName: 'link',
    disabled: isOcrProcessing && state === UIStatesEnum.CREATE,
    showWithLinkedDocument: false,
    disabledMainAction: true,
    withActions: true,
    spinnerColor: '#5E6681',
    multiple: false,
    dropdownClass: 'inner-blue-400',
    actions: [
      {
        actionTitle: 'BUTTON.LINK_PO',
        actionName: 'linkDocument',
        actionIcon: 'link',
        showWithLinkedDocument: false,
      },
      {
        actionTitle: 'SCHEDULED_PAYMENT.LINK_SP',
        actionName: 'linkDocumentScheduledPayment',
        actionIcon: 'link',
        showWithLinkedDocument: false,
      },
    ],
    hideWithStatus: [
      IncomingInvoiceListTabsEnum.DELETED,
      IncomingInvoiceListTabsEnum.CANCELED,
      IncomingInvoiceListTabsEnum.OPEN,
      IncomingInvoiceListTabsEnum.PAID,
    ],
    other: [UIStatesEnum.CREATE, UIStatesEnum.EDIT]
  }

  if (!hasLinkedSP && !hasLinkedPO && !(get(incomingInvoice, 'entityState') === 'EXISTS' && state === UIStatesEnum.EDIT)) {
    NavBarButtons.splice(2, 0, linkedBtn);
  }


  NavBarButtons[0].actions = NavBarButtons[0].actions
    .filter((btn: ButtonDropdownActionsModel) => {
    if (btn.hasOwnProperty('showWithLinkedDocument')) {
      return (btn.showWithLinkedDocument && hasLinkedPO) ||
        (!btn.showWithLinkedDocument && !hasLinkedPO);
    }
    return true;
  });

   return NavBarButtons
     .filter((btn: NavBarBtnModel) => btn.other.includes(state))
     .filter((btn: NavBarBtnModel) => btn.hasOwnProperty('showCondition') ? btn.showCondition : true)
     .filter((btn: NavBarBtnModel) => {
       if (btn.hasOwnProperty('showWithLinkedDocument')) {
         return (btn.showWithLinkedDocument && hasLinkedPO) ||
           (!btn.showWithLinkedDocument && !hasLinkedPO);
       }
       return true;
     });
}

export function getCreateDocumentSteps(
  incomingInvoice: IncomingInvoiceModel,
  isOcrProcessing: boolean,
  isReadonly: boolean,
  propertiesBlockValid: boolean,
  positionsBlockValid: boolean,
  billingInfoBlockValid: boolean,
): CreateDocumentStepsModel[] {
  return [
    {
      title: 'DOC_SUMMARY.UPLOAD_FILE',
      // action: 'uploadFileEmit',
      valid: !!(incomingInvoice.files && incomingInvoice.files.length)
    },
    {
      title: 'FORM.SELECT_BUSINESS_PARTNER',
      anchor: 'businessPartner',
      valid: get(incomingInvoice, 'properties.partnerType') === PartnersTypeEnum.GENERAL
        ? !!get(incomingInvoice, 'partnerProfile.id') && !!get(incomingInvoice, 'properties.partnerName')
        : !!get(incomingInvoice, 'partnerProfile.id')
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_PROPERTIES',
      anchor: 'properties',
      valid: propertiesBlockValid,
    },
    {
      title: 'DOC_SUMMARY.ADD_POSITIONS',
      action: (isOcrProcessing || isReadonly) ? null : 'createNewPosition',
      anchorFragment: isReadonly ? 'positions' : null,
      valid: positionsBlockValid
    },
    {
      title: 'DOC_SUMMARY.SPECIFY_BILLING_DETAILS',
      anchor: 'billing',
      valid: billingInfoBlockValid
    },
  ];
}

export function getDocumentToDoList(
  checkVat: boolean,
  invoice: IncomingInvoiceModel,
  linkedPayment: LinkedDocumentsModel[],
  linkedPo: LinkedDocumentsModel[],
  linkedCreditNote: LinkedDocumentsModel[],
  displayDueDate: boolean,
  displayOpenAmount: boolean,
  isReadonly: boolean,
  isFreePlan: boolean,
  // hideCreateCreditNote: boolean,
): DocumentToDoListModel[] {
  const canTransferFile: boolean = invoice.billingInfo.method === 'bank_transfer'
    && invoice.billingInfo.bankTransferMethod === BankTransferMethodsEnum.MANUAL_TRANSFER;

  return [
    {
      title: 'DOC_SUMMARY.PAY_INVOICE',
      valid: invoice.status === IncomingInvoiceListTabsEnum.PAID,
      hideActionList: (invoice.status !== IncomingInvoiceListTabsEnum.OPEN) || !isReadonly,
      hideAdditionalInfoList: !displayDueDate && !displayOpenAmount,
      linkedDocuments: [...linkedPayment, ...linkedCreditNote],
      hide: invoice.status === IncomingInvoiceListTabsEnum.PAID,
      actions: [
        {
          text: isFreePlan
            ? 'BUTTON.BOOK_PAYMENT'
            : 'DOC_SUMMARY.ALLOCATE_PAYMENT',
          iconName: 'payment',
          actionName: isFreePlan
            ? 'bookPayment'
            : 'allocatePayment',
          classes: 'btn-primary',
          multiple: true,
          hide: invoice.status !== IncomingInvoiceListTabsEnum.OPEN || (!canTransferFile && isFreePlan),
          actions: [
            {
              actionTitle: 'DOC_SUMMARY.ALLOCATE_PAYMENT',
              actionIcon: 'payment',
              actionName: 'allocatePayment',
              hideAction: isFreePlan,
            },
            {
              actionTitle: 'BUTTON.BOOK_PAYMENT',
              actionName: 'bookPayment',
              actionIcon: 'payment',
            },
            {
              actionTitle: 'BUTTON.TRANSFER_FILE',
              actionName: 'onExportXMLClick',
              actionIcon: 'euro',
              hideAction: !canTransferFile
            }
          ]
        },
        {
          title: 'BUTTON.BOOK_PAYMENT',
          icon: 'payment',
          action: 'bookPayment',
          btnClass: 'primary',
          hide: invoice.status !== IncomingInvoiceListTabsEnum.OPEN || !(!canTransferFile && isFreePlan)
        },
        // {
        //   title: 'BUTTON.CREATE_CREDIT_NOTE',
        //   icon: 'credit-note',
        //   action: 'createCreditNote',
        //   btnClass: 'primary',
        //   hide: hideCreateCreditNote
        // },
      ],
      additionalInfoList: [
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'COMMON.DUE_DATE',
          value: (get(invoice, 'status') === IncomingInvoiceListTabsEnum.PENDING) ?
            new Date().toISOString() :
            get(invoice, 'properties.issueDate', null),
          statusLineType: 'date',
          expectedDate: get(invoice, 'properties.dueDate', null),
          hide: !displayDueDate
        },
        {
          type: DocumentTypeEnum.StatusLine,
          label: 'COLUMN.OPEN_AMOUNT',
          statusLineType: 'payments-euro-val-only',
          currency: CURRENCIES_SYMBOLS_BY_ISO_CODE[get(invoice, 'amountDue.currency')],
          value: get(invoice, 'summary.amountOpen', null),
          maxValue: get(invoice, 'amountDue.amountInvoice.gross', null),
          // valueOnly: true,
          colorState: 'main-600',
          hide: !displayOpenAmount || invoice.status === IncomingInvoiceListTabsEnum.PAID
        },
      ],
    },
    {
      title: 'BUTTON.APPROVE_INVOICE',
      valid: invoice.properties.approved,
      hideActionList: (invoice.status !== IncomingInvoiceListTabsEnum.PENDING) || !isReadonly,
      hide: invoice.status === IncomingInvoiceListTabsEnum.PAID,
      actions: [
        {
          title: 'BUTTON.APPROVE_INVOICE',
          icon: 'checkmark',
          action: 'onApproveIncomingInvoiceClick',
          btnClass: 'primary'
        },
        {
          title: 'BUTTON.DECLINE_INVOICE',
          icon: 'close',
          action: 'onDeclineIncomingInvoiceClick',
          btnClass: 'grey'
        },
      ],
    },
    {
      vatCheckType: true,
      vatStatus: get(invoice, 'properties.lastVatCheck'),
      hide: (get(invoice, 'partnerProfile.noVat') || !get(invoice, 'partnerProfile.euMember')
        && (get(invoice, 'properties.partnerType') !== PartnersTypeEnum.GENERAL || !get(invoice, 'properties.partnerVat'))
        || (
          get(invoice, 'properties.partnerType') === PartnersTypeEnum.GENERAL &&
          get(invoice, 'partnerProfile.generalType') === GeneralPartnerTypeEnum.NON_EU
        )) || invoice.status === IncomingInvoiceListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_PAYMENTS',
      linkedDocumentsType: true,
      linkedDocuments: linkedPayment,
      icon: 'payment',
      hide: !linkedPayment.length || invoice.status !== IncomingInvoiceListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_CRN',
      linkedDocumentsType: true,
      linkedDocuments: linkedCreditNote,
      icon: 'credit-note',
      hide: !linkedCreditNote.length || invoice.status !== IncomingInvoiceListTabsEnum.PAID
    },
    {
      title: 'DOC_SUMMARY.LINKED_PO',
      linkedDocumentsType: true,
      linkedDocuments: linkedPo,
      hide: !linkedPo.length
    }
  ];
}

export const InvoiceTabs: TabDefinitionModel[] = [
  {
    label: 'APP.INCOMING_INVOICE',
    tabName: DocumentPreviewTabsEnum.DOCUMENT,
    link: `${DocumentPreviewTabsEnum.DOCUMENT}`,
    icon: 'file-text',
  },
  {
    label: 'TAB.LOG',
    tabName: DocumentPreviewTabsEnum.LOG,
    link: `${DocumentPreviewTabsEnum.LOG}`,
    icon: 'clipboard',
  },
  {
    label: 'TABLE_SUMMARY_BAR.DOCUMENTS',
    tabName: DocumentPreviewTabsEnum.DOCUMENTS,
    link: `${DocumentPreviewTabsEnum.DOCUMENTS}`,
    count: 2,
    icon: 'link',
  },
  {
    label: 'MODAL.REMARK',
    tabName: DocumentPreviewTabsEnum.REMARK,
    link: `${DocumentPreviewTabsEnum.REMARK}`,
    icon: 'message-square',
  },
];

export const CreateOrConfirmModalData: CommonModalDataModel = {
  title: 'IIN.MODAL.CREATE_INVOICE_TITLE',
  message: 'IIN.MODAL.CREATE_INVOICE_MESSAGE',
  isRejectButton: true,
  rejectBtnText: 'BUTTON.DELETE',
  confirmBtnText: 'BUTTON.SAVE',
  confirmBtnIcon: 'plus-square'
};

export const DeletePermanentlyModalData: CommonModalDataModel = {
  title: 'MODAL.DELETE_PERMANENTLY',
  message: 'IIN.MODAL.DELETE_PERMANENTLY_MESSAGE',
  confirmBtnText: 'BUTTON.DELETE',
  confirmBtnIcon: 'trash-2'
};

export const DeleteModalData: CommonModalDataModel = {
  title: 'MODAL.DELETE',
  message: 'IIN.MODAL.DELETE_MESSAGE',
  confirmBtnText: 'BUTTON.DELETE',
  confirmBtnIcon: 'trash-2'
};

export function getMismatchModalData(message: string): CommonModalDataModel  {
  return {
    title: 'IIN.MODAL.MISMATCHED_AMOUNT_TITLE',
    message,
    confirmBtnText: 'COMMON.ROUNDING_DIFFERENCE',
    confirmBtnIcon: 'checkmark'
  };
}

export const MismatchInsuranceModalData: CommonModalDataModel = getInfoErrorModalData(
  'IIN.MODAL.MISMATCHED_AMOUNT_TITLE',
  'IIN.MODAL.MISMATCHED_INSURANCE_MESSAGE'
);

export const LogisticsPositionMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_COST',
  'MODAL.MISMATCHED_COST_INVOICE_MESSAGE'
);

// export const PositionQuantityMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
//   'MODAL.MISMATCHED_QUANTITY',
//   'IIN.MODAL.POSITION_MISMATCH)QUANTITY_MESSAGE'
// );

export const PositionIINQuantityMismatchModalData: CommonModalDataModel = getInfoErrorModalData(
  'MODAL.MISMATCHED_QUANTITY',
  'IIN.MODAL.IIN_MISMATCH_QUANTITY_MESSAGE'
);

export function getInfoErrorModalData(title: string, message: string): CommonModalDataModel {
  return {
    title,
    message,
    hideCancelBtn: true,
    titleIcon: 'alert-triangle',
    titleColor: 'yellow-400',
    confirmBtnText: 'BUTTON.CLOSE',
    confirmBtnIcon: 'close'
  }
}

export function getForceCompleteModalData(invoice: IncomingInvoiceModel, translateService: TranslateService, currencyFormat: CurrencyFormat) {
  const openAmount = +invoice.summary.amountOpen;
  const totalAmount = +invoice.summary.amountInvoice.gross;
  const onePercentOfTotalAmount = totalAmount / 100;
  // limit is 50€ or 1%
  const isOpenAmountMoreThanLimit: boolean = openAmount > 50 ? true : openAmount > onePercentOfTotalAmount;
  const openAmountFormatted = currencyFormat.transform(openAmount, '',2,'.', ',', 3, true);
  const currency: string = invoice.amountDue.foreignCurrency
    ? CURRENCIES_SYMBOLS_BY_ISO_CODE[invoice.summary.currencyInvoice]
    : CURRENCIES_SYMBOLS_BY_ISO_CODE[invoice.summary.currencyLocal];
  let amountMsg = isOpenAmountMoreThanLimit
    ? translateService.instant('FORCE_PAID.MESSAGE_1', { openAmount: openAmountFormatted, currency })
    : translateService.instant('FORCE_PAID.TITLE', { openAmount: openAmountFormatted, currency });

  return {
    title: 'BUTTON.FORCE_COMPLETE',
    message: `${amountMsg} ${translateService.instant('FORCE_PAID.MESSAGE_2')}`,
    confirmBtnText: 'BUTTON.FORCE_COMPLETE',
    confirmBtnIcon: 'checkbox-on'
  };
}
