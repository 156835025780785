// todo remove it as copy from rnpl-common module
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'countryImageSrc'})
export class CountryImagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return `/assets/img/countries/${value.split(' ').join('-').toLowerCase()}.svg`;
  }
}

@Pipe({name: 'langImageSrc'})
export class LangImagePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return `/assets/img/langs/${value}.svg`;
  }
}
