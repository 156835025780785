import { ProductTypes } from 'common/src/modules/products/product-types';

export function getPositionsStartCount(positions: {[key in ProductTypes]?: any[]}, productType: ProductTypes): number {
  const order: ProductTypes[] = [
    ProductTypes.GOODS,
    ProductTypes.SERVICES,
    ProductTypes.DIGITAL,
    ProductTypes.ARBITRARY,
    ProductTypes.CORRECTION,
    ProductTypes.OVERDUE_FEE,
    ProductTypes.PREPAYMENT,
    ProductTypes.INVOICE,
  ];

  let count = 0;

  for (let i = 0; i < order.length; i++) {
    if (order[i] === productType) { break; }
    if (positions[order[i]] && positions[order[i]].length) {
      count = count + positions[order[i]].length;
    }
  }

  return count;
}
