import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { OverdueNoticeFeeTypeEnum, OverdueNoticeTypeEnum } from '../../enums';
import { OverdueNoticeItemModel } from '../../models';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from '../../../accounting.constants';

@Component({
  selector: 'rnpl-overdue-notice-form',
  templateUrl: './overdue-notice-form.component.html',
})
export class OverdueNoticeFormComponent {

  public feeTypeList = [
    {value: OverdueNoticeFeeTypeEnum.PERCENT, label: 'OVERDUE_NOTICE.PERCENT_FROM_OPEN_AMOUNT'},
    {value: OverdueNoticeFeeTypeEnum.ABSOLUTE, label: 'OVERDUE_NOTICE.FIXED_AMOUNT'},
  ];

  public overdueNoticeFeeTypeEnum = OverdueNoticeFeeTypeEnum;
  public currencySymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;
  public workspaceCurrency = 'EUR';

  @Input() public formKey: OverdueNoticeTypeEnum;
  @Input() public isAutomaticallySend = false;
  @Input() public noticeCanBeRemoved = false;
  @Input() public showPreviewButton = true;
  @Input() public overdueNoticeForm: FormGroup;

  @Output() removeNoticeEmitted: EventEmitter<OverdueNoticeTypeEnum> = new EventEmitter<OverdueNoticeTypeEnum>();
  @Output() sendPreviewEmitted: EventEmitter<{ overdueNoticeType: OverdueNoticeTypeEnum, overdueNotice: OverdueNoticeItemModel }> =
    new EventEmitter<{ overdueNoticeType: OverdueNoticeTypeEnum, overdueNotice: OverdueNoticeItemModel }>();


  public sendPreviewEmail(): void {
    this.sendPreviewEmitted.emit({overdueNoticeType: this.formKey, overdueNotice: this.overdueNoticeForm.getRawValue()});
  }

  public removeNotice(): void {
    this.removeNoticeEmitted.emit(this.formKey);
  }

  get addFeeControl(): FormControl { return this.overdueNoticeForm.get('addFee') as FormControl; }
  get daysAfterDueDateControl(): FormControl { return this.overdueNoticeForm.get('daysAfterDueDate') as FormControl; }
  get feeValueControl(): FormControl { return this.overdueNoticeForm.get('feeValue') as FormControl; }
  get messageControl(): FormControl { return this.overdueNoticeForm.get('message') as FormControl; }
  get overdueFeeTypeControl(): FormControl { return this.overdueNoticeForm.get('overdueFeeType') as FormControl; }

}
