import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LimitedFunctionalityModalComponent } from './limited-functionality-modal.component';


@NgModule({
  declarations: [
    LimitedFunctionalityModalComponent
  ],
  exports: [
    LimitedFunctionalityModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class LimitedFunctionalityModalModule { }
