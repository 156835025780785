import { UserModel } from 'common/src/models';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export function employeeResponseHelper(response: UserModel[], translateService: TranslateService) {
  response = cloneDeep(response); // If data is loading from store - object is locked for edit
  return response.map((el: UserModel) => ({
    ...el,
    nameData: {
      user: el,
      label: `${el.firstName} ${el.lastName}`,
      routerLink: `/team/employee-profile/${el.id}`
    },
    documentLink: {
      label: el.runpleId,
      routerLink: `/team/employee-profile/${el.id}`
    },
    department: el.department
      .replace('Accounting', translateService.instant('CATEGORIES.ACCOUNTING'))
      .replace('Warehouse', translateService.instant('MODULE.WAREHOUSE'))
      .replace('Wholesale', translateService.instant('MODULE.TRADE'))
      .replace('Services', translateService.instant('MODULE.SERVICES'))
      .replace('Management', translateService.instant('APP.MANAGEMENT')),
    position: el.position
      .replace('CEO', translateService.instant('USER_POSITIONS.CEO'))
      .replace('C-level manager', translateService.instant('USER_POSITIONS.C_LEVEL'))
      .replace('Workspace administrator', translateService.instant('USER_POSITIONS.ADMIN'))
      .replace('Head of Department', translateService.instant('USER_POSITIONS.HEAD_OF_DEPARTMENT'))
      .replace('Trusted employee', translateService.instant('USER_POSITIONS.TRUSTED_EMPLOYEE'))
      .replace('Employee', translateService.instant('USER_POSITIONS.EMPLOYEE'))
      .replace('Intern', translateService.instant('USER_POSITIONS.INTERN'))
      .replace('Contractor', translateService.instant('USER_POSITIONS.CONTRACTOR'))

  }));
}
