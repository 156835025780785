export const CHANGE_CORRECTION_CALCULATION_MODAL_DATA = {
  title: 'MODALS.CORRECTION_AMOUNT_TITLE',
  message: 'MODALS.CORRECTION_AMOUNT_MSG',
  confirmBtnText: 'BUTTON.CONTINUE',
  confirmBtnIcon: 'edit'
}

export const REMOVE_POSITION_MODAL_DATA = {
  title: 'MODAL.REMOVE_POSITION',
  message: 'MODAL.REMOVE_POSITION_MSG',
  confirmBtnText: 'BUTTON.REMOVE',
  confirmBtnIcon: 'minus',
}
