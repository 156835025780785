import {NavBarBtnModel, NavigationItemModel, UIStatesEnum} from 'common/src/models';
import { EmployeeStatusEnum } from '../../enums/employee-status.enum';
import { UserListTabs } from '../user-list/enums/user-list-tabs.enum';

export const EMPLOYEE_NAV_ITEMS: NavigationItemModel[] = [
  {
    icon: 'badge',
    disabled: false,
    title: 'DOC_BLOCK.PROFILE',
    routerLink: './profile'
  },
  {
    icon: 'shield',
    disabled: false,
    hintId: 'roleAndPermissionTab',
    title: 'TEAM.R_AND_P',
    routerLink: './role-and-permissions'
  },
];

export const MY_PROFILE_NAV_ITEMS: NavigationItemModel[] = [
  {
    icon: 'person',
    disabled: false,
    title: 'DOC_BLOCK.PROFILE',
    routerLink: './profile'
  },
  {
    icon: 'shield',
    disabled: false,
    title: 'TEAM.R_AND_P',
    hintId: 'roleAndPermissionTab',
    routerLink: './role-and-permissions'
  },
  {
    icon: 'options-2',
    disabled: false,
    title: 'DOC_BLOCK.PREFERENCES',
    hintId: 'preferences',
    routerLink: './preferences',
    children: [
      {
        title: 'System',
        routerLink: './preferences/system',
      },
      {
        title: 'ADMINISTRATION.SECURITY',
        routerLink: './preferences/security',
      }
    ]
  },
];

export const commonButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-white',
    text: 'BUTTON.EDIT',
    hintsId: 'editProfileBtn',
    iconName: 'edit',
    disabled: false,
    actionName: 'onEditClick',
    multiple: false,
    other: [UIStatesEnum.VIEW],
    hideWithStatus: [UserListTabs.Inactive, UserListTabs.Deleted],
  },
  {
    classes: 'btn-primary',
    text: 'BUTTON.DONE',
    iconName: 'checkmark',
    disabled: false,
    actionName: 'onFinishEditingClick',
    multiple: false,
    hintsId: 'doneBtn',
    other: [UIStatesEnum.EDIT],
    hideWithStatus: [UserListTabs.Inactive, UserListTabs.Deleted],
  }
];

export function getUserNavBarButtons(state: UIStatesEnum): NavBarBtnModel[] {
  const buttons: NavBarBtnModel[] = [
    {
      classes: 'btn-white red',
      multipleClasses: 'btn-white red',
      text: 'BUTTON.DELETE',
      multipleText: 'BUTTON.DELETE',
      badgeClass: 'rnpl-badge--red',
      iconName: 'trash-2',
      actionName: 'onDeleteClick',
      hintsId: 'deletedBtn',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: true,
      hideWithStatus: [UserListTabs.Deleted],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white red',
      text: 'BUTTON.DELETE_PERMANENTLY',
      multipleText: 'BUTTON.DELETE_PERMANENTLY',
      badgeClass: 'rnpl-badge--red',
      iconName: 'fire',
      actionName: 'onDeletePermanentlyClick',
      multiple: false,
      hideWithStatus: [UserListTabs.Active, UserListTabs.Inactive],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white',
      text: 'BUTTON.ACTIVATE',
      multipleText: 'BUTTON.ACTIVATE',
      iconName: 'bulb',
      disabled: false,
      actionName: 'onActivateClick',
      allowLoadingSpinner: true,
      multiple: false,
      hideWithStatus: [UserListTabs.Active, UserListTabs.Deleted],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white',
      multipleClasses: 'btn-white',
      text: 'BUTTON.RESTORE',
      multipleText: 'BUTTON.RESTORE',
      iconName: 'undo',
      actionName: 'onRestoreClick',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [UserListTabs.Active, UserListTabs.Inactive],
      other: [UIStatesEnum.VIEW],
    },
    {
      classes: 'btn-white',
      multipleClasses: 'btn-white',
      text: 'BUTTON.DEACTIVATE',
      multipleText: 'BUTTON.DEACTIVATE',
      iconName: 'bulb-off',
      disabled: false,
      actionName: 'onDeactivateClick',
      hintsId: 'deactivatedBtn',
      allowLoadingSpinner: true,
      greyIcon: true,
      multiple: false,
      hideWithStatus: [UserListTabs.Inactive, UserListTabs.Deleted],
      other: [UIStatesEnum.VIEW],
    },
    ...commonButtons
  ];

  return buttons.filter((btn: NavBarBtnModel) => btn.other.includes(state));
}

export function getMyProfileNavBarButtons(state: UIStatesEnum): NavBarBtnModel[] {
  return commonButtons.filter((btn: NavBarBtnModel) => btn.other.includes(state));
}


export const STATUS_CLASSES: {[key: string]: string} = {
  [EmployeeStatusEnum.ACTIVE]: 'rnpl-badge--blue',
  [EmployeeStatusEnum.INACTIVE]: 'rnpl-badge--red',
  [EmployeeStatusEnum.DELETED]: 'rnpl-badge--empty',
  "editing": 'rnpl-badge--main-400',
};
