import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { AppState } from '../../../store/state/app.state';
import { StockAddressesService } from '../../../warehouse/services';
import { selectCurrentWarehouse, selectWarehouseState } from '../../../warehouse/store/selectors';
import { WarehouseResponse } from '../../../warehouse/models/warehouse-response.model';
import { BaseFormComponent } from '../../../crm/partner-forms/components/base-form.component';
import { BinLocationFormatEnum, InventoryControlMethodEnum, RackFormatEnum } from '../../enums';
import { UIStatesEnum } from 'common/src/models';

import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'rnpl-warehouse-preferences',
  templateUrl: './warehouse-preferences.component.html',
  styleUrls: ['./warehouse-preferences.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class WarehousePreferencesComponent extends BaseFormComponent implements OnInit, OnDestroy {
  public warehouseData: any;

  public rackFormatEnum = RackFormatEnum;
  public inventoryControlMethodEnum = InventoryControlMethodEnum;
  public binLocationFormatEnum = BinLocationFormatEnum;

  public inventoryControlMethodList = [
    {value: InventoryControlMethodEnum.FIRST_IN_FIRST_OUT, label: 'COMMON.FIFO',},
    {value: InventoryControlMethodEnum.LAST_IN_FIRST_OUT, label: 'COMMON.LIFO',},
    {value: InventoryControlMethodEnum.HIGHEST_IN_FIRST_OUT, label: 'COMMON.HIFO',},
    {value: InventoryControlMethodEnum.LOWEST_IN_FIRST_OUT, label: 'COMMON.LOFO',},
    {value: InventoryControlMethodEnum.FIRST_EXPIRED_FIRST_OUT, label: 'COMMON.FEFO',},
  ];

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private stockAddressesService: StockAddressesService,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    this.initForm();

    this.store.select(selectCurrentWarehouse)
      .pipe(
        filter((currentWarehouse: WarehouseResponse) => !!currentWarehouse),
        takeUntil(this.destroy$)
      )
      .subscribe((currentWarehouse) => {
        this.warehouseData = currentWarehouse;
        this.form.patchValue(currentWarehouse.preferences);
      });

    this.store.select(selectWarehouseState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.readonly = state === UIStatesEnum.VIEW;
        this.updateFormEditingStatus();
      });
  }

  initForm(warehouseData: any = {} as any): void {
    this.form = this.fb.group({
      differentProductsInSameBinLocation: [],
      multipleBatchesInSameBinLocation: [],
      binLocationFormat: [],
      inventoryControlMethod: [],
      rackFormat: [],
    });
  }

  public updateWarehouse(fieldValue: any, fieldName: string) {
    fieldName = 'preferences.' + fieldName;
    this.stockAddressesService.patchWarehouseProfile({fieldValue, fieldName}).subscribe();
  }

  get binLocationPreview(): string {
    let preview = '';
    preview = get(this, 'warehouseData.preferences.rackFormat') === RackFormatEnum.NUMBERS
     ? preview + '1'
     : preview + 'A';

    preview = get(this, 'warehouseData.preferences.binLocationFormat') === BinLocationFormatEnum.RACK_SHELF_BIN
      ? preview + '-10-020'
      : preview + '-020-10';

    return preview;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
