import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';


import { AddressModel } from 'common/src/models';
import { CountryModel } from 'common/src/modules/rnpl-common';

import { BaseFormComponent } from '../base-form.component';
import { PaymentProfileModel } from '../../../models';
import { AddressFormFactory } from '../address-form.factory';
import { get } from 'lodash';
import { convertAddressToStringHelper } from 'common/src/modules/rnpl-common/helpers';

@Component({
  selector: 'rnpl-accounting-form',
  templateUrl: './accounting-form.component.html'
})
export class AccountingFormComponent extends BaseFormComponent implements OnChanges {

  @Input()
  paymentInfo: PaymentProfileModel;

  @Input()
  legalAddress: AddressModel;

  @Input()
  countries: CountryModel[];

  public isWIP: boolean = true;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('paymentInfo')) {
      this.initForm(this.paymentInfo);
    }

    if (changes.hasOwnProperty('readonly')) {
      this.swapEditMode();
    }
  }

  swapEditMode() {
    if (this.readonly) {
      this.form.disable({onlySelf: false});
    } else {
      this.form.enable({onlySelf: false});
    }
  }

  setAddress(sameAsLegal: boolean): void {
    if (sameAsLegal && this.legalAddress) {
      this.form.get('billing_address').setValue(this.legalAddress);
      this.form.get('billing_address').updateValueAndValidity();
    }
  }

  initForm(paymentInfo: PaymentProfileModel = {} as any): void {
    this.form = this.fb.group({
      // first_name: [get(paymentInfo, 'first_name', ''), []],
      // last_name: [get(paymentInfo, 'last_name', ''), []],
      // bank: [get(paymentInfo, 'bank', ''), []],
      // swift: [get(paymentInfo, 'swift', ''), []],
      iban: [get(paymentInfo, 'iban', '')],
      bic: [get(paymentInfo, 'bic', '')],
      billing_address: AddressFormFactory.getForm(get(paymentInfo, 'billing_address', {} as AddressModel))
    });

    this.cdr.detectChanges();

    super.initForm();
  }

  public get billingAddressAsText(): string {
    if (!this.form) { return ''; }

    return convertAddressToStringHelper(this.billing_address.value, this.countries);
  }

  get billing_address() {
    return this.form.get('billing_address');
  }
}
