import { HintsModel } from 'common/src/modules/rnpl-common/models/hints.model';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { ProductStatusEnum } from './enums/product-status.enum';

export const ProductFormLayoutAddAttrToTheFormHint: HintsModel[] = [
  {
    id: 'addAttrToTheFormBtn',
    text: 'Click here to add the attribute to the form',
    attachTo: {
      element: '#addAttrToTheFormBtn',
      on: 'bottom'
    },
    scrollTo: false,
  },
];

export const ProductFormLayoutDragAndDropAttrHint: HintsModel[] = [
  {
    id: 'dragAndDropAttrContainer',
    text: 'Drag\'n\'drop to reorder the attributes',
    attachTo: {
      element: '#dragAndDropAttrContainer',
      on: 'top'
    },
    scrollTo: false,
  },
  {
    text: 'Select the attribute to change its size.',
    attachTo: {
      element: '#selectAttrContainer',
      on: 'bottom'
    },
    scrollTo: false,
  }
];

export function ProductFormLayoutHints(hasAvailableAttr: boolean = false, hasAssignedAttributes: boolean = false) {

  let hintsList = {
    ['form_composer_view_1']: {
      text: 'MEGA_HINT.FORM_COMPOSER.HINT_1',
      attachTo: {
        element: '#createAttrBtn',
        on: 'bottom'
      },
    }
  };

  const createAttrHint = {
    ['form_composer_view_3']: {
      text: 'MEGA_HINT.FORM_COMPOSER.HINT_3',
      attachTo: {
        element: '#addAttrToTheFormBtn',
        on: 'bottom'
      },
      scrollTo: false,
    }
  };

  const dragAndDropAttrHint = {
    ['form_composer_view_2']: {
      text: 'MEGA_HINT.FORM_COMPOSER.HINT_2',
      attachTo: {
        element: '#dragAndDropAttrContainer',
        on: 'top'
      },
    }
  };

  const resizeAttrHint = {
    ['form_composer_view_4']: {
      text: 'MEGA_HINT.FORM_COMPOSER.HINT_4',
      attachTo: {
        element: '#selectAttrContainer',
        on: 'bottom'
      },
    }
  };

  if (hasAvailableAttr) {
    hintsList = Object.assign(hintsList, createAttrHint)
  }

  if (hasAssignedAttributes) {
    hintsList = Object.assign(hintsList, dragAndDropAttrHint, resizeAttrHint);
  }

  return hintsList;
}

export function categoryHintsByName(productType?: ProductTypes | string) {
  const hintsList = {

    ['categories_tabs_1']: {
      text: 'MEGA_HINT.CATEGORY.HINT_2',
      attachTo: {
        element: '#createCategoryBtn',
        on: 'bottom'
      },
    },
    ['categories_tabs_2']: {
      text: 'MEGA_HINT.CATEGORY.HINT_3',
      attachTo: {
        element: '#setUpProductCreationBtn',
        on: 'bottom'
      },
    },
    ['categories_tabs_3']: {
      text: 'MEGA_HINT.CATEGORY.HINT_4',
      attachTo: {
        element: '#contextMenuCategoryBtn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
    },
    ['tabs_categories']: {
      text: 'MEGA_HINT.CATEGORY.HINT_1',
      attachTo: {
        element: '#servicesTabCategory',
        on: 'bottom'
      },
    },
  }

  // const servicesHint = {
  //   ['tabs_categories']: {
  //     text: 'MEGA_HINT.CATEGORY.HINT_1',
  //     attachTo: {
  //       element: '#servicesTabCategory',
  //       on: 'bottom-start'
  //     },
  //   },
  // }
  //
  // if (productType === ProductTypes.SERVICES) {
  //   Object.assign(hintsList, servicesHint)
  // }

  return hintsList;

}

export function productsHintsByName(
  isReadOnly?: boolean,
  hasProducts?: boolean,
  hasEntityTree?: boolean,
  status?: ProductStatusEnum,
  productHasEditFlag?: boolean,
  displayBlinkedHint?: boolean

) {
  const hintsList = {
    ['products_list_3']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_6',
      attachTo: {
        element: '#bulkListBtn',
        on: 'top-start'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      scrollTo: false,
    },
    ['products_view_1']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_14',
      attachTo: {
        element: '#actionsProduct',
        on: 'bottom-start'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      scrollTo: false,
    },
    ['create_entity_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_16',
      attachTo: {
        element: '#createProductsBtn',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
    ['filter_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_24',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const validFieldHint = {
    ['field_validation_products_view']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_3',
      attachTo: {
        element: '#productNameField',
        on: 'bottom'
      },
    },
  }

  const editStateHints = {
    ['done_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_22',
      attachTo: {
        element: '#doneBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
    ['save_timer_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_21',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const viewStateHint = {
    ['edit_entity_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_20',
      attachTo: {
        element: '#editBtn',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
    ['deactivate_entity_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_18',
      attachTo: {
        element: '#deactivateBtn',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
  }

  const deleteBtnHint = {
    ['delete_entity_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_26',
      attachTo: {
        element: '#deleteBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const cloneBtnHint = {
    ['clone_entyty_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_19',
      attachTo: {
        element: '#cloneBtn',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
  }

  const exportBtnHint = {
    ['export_button_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_15',
      attachTo: {
        element: '#exportProductsBtn',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
  }

  const entityTreeHint = {
    ['products_list_2']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_5',
      attachTo: {
        element: '#categoryTree',
        on: 'top'
      },
    },
  }

  const activeTabHint = {
    ['products_list_1']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_4',
      attachTo: {
        element: '#activeProductTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const corruptedTabHint = {
    ['products_list_4']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_7',
      attachTo: {
        element: '#corruptedProductTab',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
  }

  const inactiveTabHint = {
    ['products_list_5']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_8',
      attachTo: {
        element: '#inactiveProductTab',
        on: 'bottom-start'
      },
      scrollTo: false,
    },
  }

  const deletedTabHint = {
    ['tab_delete_products']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_17',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  if (hasProducts) {
    Object.assign(hintsList, exportBtnHint);
  }

  if (hasEntityTree) {
    Object.assign(hintsList, entityTreeHint);
  }
  if (status === ProductStatusEnum.ACTIVE) {
    Object.assign(hintsList, activeTabHint);
  }

  if (status === ProductStatusEnum.CORRUPTED) {
    Object.assign(hintsList, corruptedTabHint);
  }

  if (status === ProductStatusEnum.INACTIVE) {
    Object.assign(hintsList, inactiveTabHint);
  }

  if (status === ProductStatusEnum.DELETED) {
    Object.assign(hintsList, deletedTabHint);
  }
  if (status !== ProductStatusEnum.DELETED && isReadOnly) {
    Object.assign(hintsList, deleteBtnHint);
  }

  if (isReadOnly) {
    Object.assign(hintsList, viewStateHint);
  } else {
    Object.assign(hintsList, editStateHints);
  }

  if (isReadOnly && !(status !== ProductStatusEnum.ACTIVE && productHasEditFlag)) {
    Object.assign(hintsList, cloneBtnHint);
  }

  if (!isReadOnly && displayBlinkedHint) {
    Object.assign(hintsList, validFieldHint);
  }

  return hintsList;
}

export const CreateAttributeHints = {
  ['form_composer_create_attribute_1']: {
    text: 'MEGA_HINT.CATEGORY.HINT_5',
    classes: 'from-modal',
    attachTo: {
      element: '#attr_is_required',
      on: 'bottom'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
    },
  },
  ['form_composer_create_attribute_2']: {
    text: 'MEGA_HINT.CATEGORY.HINT_6',
    classes: 'from-modal',
    attachTo: {
      element: '#controlType',
      on: 'bottom'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
    },
  },
}
export function createProductsHints(productType: ProductTypes) {
  const hintsList = {
    ['products_create_1']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_1',
      classes: 'from-modal',
      attachTo: {
        element: '#categoriesField',
        on: 'bottom'
      },
    },
    ['field_validation_products_create']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_3',
      classes: 'from-modal',
      attachTo: {
        element: '#productNameField',
        on: 'bottom'
      },
    },
  }

  const unitsField = {
    ['products_create_2']: {
      text: 'MEGA_HINT.PRODUCTS.HINT_2',
      classes: 'from-modal',
      attachTo: {
        element: '#unitsField',
        on: 'bottom'
      },
    },
  }

  if (productType !== ProductTypes.DIGITAL) {
    Object.assign(hintsList, unitsField);
  }

  return hintsList;
}


export const repeatHintsHint = {
  title: `<img class="hints-img" src="assets/img/mega-hints/emoji/1.png" alt="">`,
  classes: 'from-modal',
  attachTo: {
    element: '#repeatHintsBtn',
    on: 'bottom'
  },
}


