import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositionsNavigateButtonsComponent } from './positions-navigate-buttons.component';

@NgModule({
  declarations: [
    PositionsNavigateButtonsComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    PositionsNavigateButtonsComponent
  ]
})
export class PositionsNavigateButtonsModule { }
