import { Routes } from '@angular/router';


import { FamilyWizardComponent } from '../products/family-wizard/family-wizard.component';
import { AttributeWizardComponent } from '../system-settings/attribute-wizard/attribute-wizard.component';

/**
 * todo: split wizard routes in modules
 */
export const WIZARD_ATTRIBUTE_ROUTES: Routes = [
  {
    path: 'attribute/create/:step',
    component: AttributeWizardComponent,
  }
];

export const WIZARD_ROUTES: Routes = [
  {
    path: 'family/edit/:step',
    component: FamilyWizardComponent,
    data: {
      hideNavigation: true
    }
  },
];
