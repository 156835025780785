import { TradeOfferListTabsEnum } from './enums';
import { UIStatesEnum } from 'common/src/models';

export function HintsListByName(
  hasOffers?: boolean,
  displayDelivery?: boolean,
  showPrintSerialNumber?: boolean,
  currentState?: UIStatesEnum,
  showVatChecked?: boolean,
  soStatus?: string,
  activeStatus?: TradeOfferListTabsEnum,
  displayFieldValidHint?: boolean,
  displayContactPersonHint?: boolean,
)  {

  const isReadOnly = currentState === UIStatesEnum.VIEW;
  const isEditState = currentState === UIStatesEnum.EDIT;
  const isCreateState = currentState === UIStatesEnum.CREATE;

  const isDraftStatus = activeStatus === TradeOfferListTabsEnum.DRAFT;
  const isAcceptedStatus = activeStatus === TradeOfferListTabsEnum.ACCEPTED;
  const isOpenedStatus = activeStatus === TradeOfferListTabsEnum.OPEN;
  const isDeclinedStatus = activeStatus === TradeOfferListTabsEnum.DECLINED;
  const isDeletedStatus = activeStatus === TradeOfferListTabsEnum.DELETED;

 const hintsList = {
   ['offers_view_draft_4']: {
     text: 'MEGA_HINT.OFFERS.HINT_11',
     attachTo: {
       element: '#sendOfferEmailControls',
       on: 'bottom'
     },
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 60]}}]
     },
   },
   ['offers_view_open_1']: {
     text: 'MEGA_HINT.OFFERS.HINT_15',
     attachTo: {
       element: '#endStep',
       on: 'bottom'
     },
     classes: 'break-spaces',
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 0]}}]
     },
   },
   // ['reverse_charge_toggle_offers']: {  // todo: move to billingInfo Modal
   //   text: 'MEGA_HINT.OFFERS.HINT_20',
   //   attachTo: {
   //     element: '#vatDisabled',
   //     on: 'bottom'
   //   },
   // },
   ['create_entity_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_25',
     attachTo: {
       element: '#createOfferBtn',
       on: 'bottom'
     },
   },
   ['filter_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_26',
     attachTo: {
       element: '#filterBtn',
       on: 'bottom'
     },
   },
 }

 const emailIndicatorHint = {
   ['email_indicator_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_33',
     attachTo: {
       element: '#emailSendCheckedField',
       on: 'bottom'
     },
   },
 }

 const sidebarHint = {
   ['sidebar_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_15',
     attachTo: {
       element: '#sidebarOffer',
       on: 'left'
     },
     classes: 'break-spaces',
   },
 }

 const exportBtnHint = {
   ['export_send_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_21',
     attachTo: {
       element: '#exportBtn',
       on: 'bottom'
     },
   },
 }

 const editBtnHint = {
   ['edit_entity_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_31',
     attachTo: {
       element: '#editBtn',
       on: 'bottom'
     },
   },
 }

 const reopenBtn = {
   ['restore_entity_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_23',
     attachTo: {
       element: '#reopenBtn',
       on: 'bottom'
     },
   },
   ['restore_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_23',
     attachTo: {
       element: '#reopenBtn',
       on: 'bottom'
     },
   },
 }

 const cloneBtnHInt = {
   ['clone_entyty_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_30',
     attachTo: {
       element: '#cloneBtn',
       on: 'bottom'
     },
   },
 }

 const columnHints = {
   ['offers_tab_draft_1']: {
     text: 'MEGA_HINT.OFFERS.HINT_1',
     attachTo: {
       element: '#positionsColumn',
       on: 'bottom'
     },
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 60]}}]
     },
   },
 }

 // const deliveryTabHints = { //todo: move to delivery info modal
 //   ['offers_view_draft_2']: {
 //     text: 'MEGA_HINT.OFFERS.HINT_9',
 //     attachTo: {
 //       element: '#is_partial_shipment',
 //       on: 'bottom'
 //     },
 //   },
 // }

 const canceledSoStepHint = {
   ['offers_view_accepted_1']: {
     text: 'MEGA_HINT.OFFERS.HINT_13',
     attachTo: {
       element: '#salesOrderStep',
       on: 'bottom'
     },
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 0]}}]
     },
   },
 }

 const createdSoStep = {
   ['offers_view_accepted_2']: {
     text: 'MEGA_HINT.OFFERS.HINT_14',
     attachTo: {
       element: '#salesOrderStep',
       on: 'bottom'
     },
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 0]}}]
     },
   },
 }

 const printSerialNumbersHint = {
   ['offers_view_draft_3']: {
     text: 'MEGA_HINT.OFFERS.HINT_10',
     attachTo: {
       element: '#print_serial_numbers',
       on: 'bottom'
     },
   },
 }

 const editStateHints = {
   ['create_positions_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_29',
     attachTo: {
       element: '#addPositionBtn',
       on: 'bottom'
     },
   },
   // ['printable_remark_toggle_offers']: { //todo: move to remark modal
   //   text: 'MEGA_HINT.OFFERS.HINT_24',
   //   attachTo: {
   //     element: '#printable_remark',
   //     on: 'top'
   //   },
   // },
   ['save_timer_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_27',
     attachTo: {
       element: '#savedInfo',
       on: 'bottom'
     },
   },
 }

 // const contactPersonHint = { // todo: move to select partner modal
 //   ['offers_view_draft_1']: {
 //     text: 'MEGA_HINT.OFFERS.HINT_8',
 //     attachTo: {
 //       element: '#contactPerson',
 //       on: 'bottom'
 //     },
 //   },
 // }

 const fieldValidationHint = {
   ['field_validation_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_28',
     attachTo: {
       element: '#selectCustomer',
       on: 'bottom'
     },
   },
 }

 const vatCheckedHint = {
   ['vat_indicator_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_22',
     attachTo: {
       element: '#vatCheckedField',
       on: 'bottom'
     },
   },
 }

 const openTabHints = {
   ['offers_tab_open_1']: {
     text: 'MEGA_HINT.OFFERS.HINT_3',
     attachTo: {
       element: '#openTab',
       on: 'bottom'
     },
   },
 }

 const acceptedTabHints = {
   ['offers_tab_accepted_1']: {
     text: 'MEGA_HINT.OFFERS.HINT_5',
     attachTo: {
       element: '#acceptedTab',
       on: 'bottom'
     },
   },
 }

 const createOfferBtnHint = {
   ['offers_view_draft_5']: {
     text: 'MEGA_HINT.OFFERS.HINT_12',
     attachTo: {
       element: '#createOfferBtn',
       on: 'bottom'
     },
   },
 }

 const draftTabHints = {
   ['tab_draft_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_16',
     attachTo: {
       element: '#draftTab',
       on: 'bottom'
     },
   },
 }

 const declinedTabHints = {
   ['offers_tab_rejected_1']: {
     text: 'MEGA_HINT.OFFERS.HINT_7',
     attachTo: {
       element: '#declinedTab',
       on: 'bottom'
     },
   },
 }

 const deletedTabHints = {
   ['tab_deleted_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_19',
     attachTo: {
       element: '#deletedTab',
       on: 'bottom'
     },
   },
 }

 const pendingTimeColumnHint = {
   ['offers_tab_open_2']: {
     text: 'MEGA_HINT.OFFERS.HINT_4',
     attachTo: {
       element: '#pendingTimeColumn',
       on: 'bottom'
     },
   },
 }

 const soColumnHint = {
   ['offers_tab_accepted_2']: {
     text: 'MEGA_HINT.OFFERS.HINT_6',
     attachTo: {
       element: '#salesOrderIdColumn',
       on: 'bottom'
     },
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 60]}}]
     },
   },
 }

 const estimatedProfitColumnHint = {
   ['offers_tab_draft_2']: {
     text: 'MEGA_HINT.OFFERS.HINT_2',
     attachTo: {
       element: '#estimatedProfitColumn',
       on: 'bottom'
     },
     popperOptions: {
       modifiers: [{name: 'offset', options: {offset: [0, 60]}}]
     },
   },
 }

 const tableSummaryHint = {
   ['total_row_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_17',
     attachTo: {
       element: '#tableSummaryBar',
       on: 'bottom'
     },
   },
 }

 const exportListBtnHint = {
   ['export_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_18',
     attachTo: {
       element: '#exportBtn',
       on: 'bottom'
     },
   },
 }

 const doneBtnHInt = {
   ['done_button_offers']: {
     text: 'MEGA_HINT.OFFERS.HINT_35',
     attachTo: {
       element: '#doneBtn',
       on: 'bottom'
     },
   },
 }

  if (hasOffers) { Object.assign(hintsList, columnHints); }
 // if (displayDelivery) { Object.assign(hintsList, deliveryTabHints); }
 if (showPrintSerialNumber) { Object.assign(hintsList, printSerialNumbersHint); }
 if (!isReadOnly) { Object.assign(hintsList, editStateHints); }
 if (showVatChecked && !isDraftStatus && !isCreateState) { Object.assign(hintsList, vatCheckedHint); }
 if (soStatus === 'canceled') { Object.assign(hintsList, canceledSoStepHint); }
 if (soStatus === 'open') { Object.assign(hintsList, createdSoStep); }

 if (isOpenedStatus) {
   Object.assign(hintsList, openTabHints);

   if (hasOffers) {
     Object.assign(hintsList, pendingTimeColumnHint);
   }
 }
 if (isAcceptedStatus) {
   Object.assign(hintsList, acceptedTabHints);

   if (hasOffers) {
     Object.assign(hintsList, soColumnHint);
   }
 }
 if (isDraftStatus) {
   Object.assign(hintsList, draftTabHints);

   if (!isEditState) {
     Object.assign(hintsList, createOfferBtnHint);
   }
 }
 if (isDeclinedStatus) {
   Object.assign(hintsList, declinedTabHints);
 }
 if (isDeletedStatus) {
   Object.assign(hintsList, deletedTabHints);
 }

 if (!isDeclinedStatus && !isDeletedStatus && hasOffers) {
   Object.assign(hintsList, estimatedProfitColumnHint);
   Object.assign(hintsList, tableSummaryHint);
 }
 if (hasOffers) {
   Object.assign(hintsList, exportListBtnHint);
 }

 if (!isDeclinedStatus && !isDeletedStatus && isReadOnly) {
   Object.assign(hintsList, cloneBtnHInt);
 }
 if ((isAcceptedStatus && isReadOnly) || ((isDeclinedStatus || isDeletedStatus) && !isEditState)) {
   Object.assign(hintsList, reopenBtn);
 }
 if ((isDraftStatus || isOpenedStatus) && isReadOnly) {
   Object.assign(hintsList, editBtnHint);
 }
 if (displayFieldValidHint && !isReadOnly) {
   Object.assign(hintsList, fieldValidationHint);
 }
 // if ((displayContactPersonHint && !isReadOnly) || displayFieldValidHint) {
 //   Object.assign(hintsList, contactPersonHint);
 // }
 if (!isDeclinedStatus && !isDeletedStatus && isReadOnly) {
   Object.assign(hintsList, exportBtnHint);
 }
 if (!isReadOnly && isDraftStatus) {
   Object.assign(hintsList, sidebarHint);
 }
 if (!isDraftStatus && !isCreateState) {
   Object.assign(hintsList, emailIndicatorHint);
 }
 if (!isDeclinedStatus && !isDeletedStatus && isEditState) {
   Object.assign(hintsList, doneBtnHInt);
 }

 return hintsList;
}

export const SelectPartnersHints = {
  ['offers_view_draft_1']: {
    text: 'MEGA_HINT.OFFERS.HINT_8',
    attachTo: {
      element: '#contactPersonFromModal',
      on: 'bottom'
    },
  },
}

export const RemarkHints = {
  ['printable_remark_toggle_offers']: {
    text: 'MEGA_HINT.OFFERS.HINT_24',
    attachTo: {
      element: '#printable_remark',
      on: 'top'
    },
  }
}

export const BillingInfoHints = {
  ['reverse_charge_toggle_offers']: {
    text: 'MEGA_HINT.OFFERS.HINT_20',
    attachTo: {
      element: '#vatDisabled',
      on: 'bottom'
    },
  },
}

export const DeliveryInfoHints = {
  ['offers_view_draft_2']: {
    text: 'MEGA_HINT.OFFERS.HINT_9',
    attachTo: {
      element: '#is_partial_shipment_modal',
      on: 'bottom'
    },
  },
}
