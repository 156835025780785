import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subscription } from 'rxjs';
import { isArray, isEqual } from 'lodash';

import { FiltersControlsNamesEnum } from '../../enums';
import { FiltersGroupModel } from '../../models';

@Component({
  selector: 'rnpl-filters-main',
  templateUrl: './filters-main.component.html',
  styleUrls: ['./filters-main.component.scss'],
  animations: [
    trigger('sidePanelAnimation', [
      state('visible', style({
        transform: 'translateX(0)',
      })),
      state('hidden', style({
        transform: 'translateX(120%)',
      })),
      transition('visible => hidden', animate('400ms ease-in-out')),
      transition('hidden => visible', animate('400ms ease-in-out'))
    ])
  ]
})
export class FiltersMainComponent implements OnChanges, OnInit, OnDestroy {

  @Input() filtersControls: FiltersGroupModel[];
  @Input() sidebarState: boolean = false;
  @Input() skipPageReset: boolean = false;

  @Output() filtersApplied: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public urlQueryParams = null;
  public filtersCount: number = 0;
  public appliedFiltersCount: number = 0;
  public hasQueryParams: boolean = false;
  public showApplyBtn: boolean = false;
  private subscriptions: Subscription[] = [];

  public filtersControlsNamesEnum = FiltersControlsNamesEnum;
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.urlQueryParams = params;
      this.hasQueryParams = !!Object.keys(params).length;
      this.appliedFiltersCount = Object.keys(params).filter(controlName => this.getIgnoreCountControls(controlName)).length;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('filtersControls')) {
      this.setControlsToForm();
    }

    if (changes.hasOwnProperty('opened')) {
      this.sidebarState = !!changes.opened.currentValue;
    }
  }

  ngOnInit() {
    this.formValueChanges();
  }

  public setControlsToForm(): void {
    this.form = this.fb.group({});

    if (this.filtersControls && this.filtersControls.length) {

      this.filtersControls.forEach(group => group.filters.forEach(item => {

        if (item.controlType !== FiltersControlsNamesEnum.DATES_RANGE) {
          const value = this.urlQueryParams[item.controlName]
            ? JSON.parse(this.urlQueryParams[item.controlName])
            : null;
          this.form.addControl(item.controlName, this.fb.control(value));
        }

        // add date pickers
        if (
          item.controlType === FiltersControlsNamesEnum.DATE_PERIODS ||
          item.controlType === FiltersControlsNamesEnum.DATES_RANGE
        ) {
          const valueFrom = this.urlQueryParams[(item.controlName + 'From')]
            ? JSON.parse(this.urlQueryParams[(item.controlName + 'From')])
            : null;

          const valueTo = this.urlQueryParams[(item.controlName + 'To')]
            ? JSON.parse(this.urlQueryParams[(item.controlName + 'To')])
            : null;
          this.form.addControl((item.controlName + 'From'),  this.fb.control(valueFrom));
          this.form.addControl((item.controlName + 'To'),  this.fb.control(valueTo));
        }
      }));

      this.formValueChanges();
    }
  }

  public formValueChanges():void {
    this.subscriptions.forEach(s => s.unsubscribe());
    const subscription = this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.updateFiltersCount();
      });
    this.subscriptions.push(subscription);
  }

  public applyFilters(): void {
    if (this.form) {
      this.updateFiltersCount();
      const formValue = this.form.getRawValue();
      const queryParams = {};

      for (const key in formValue) {
        if (formValue.hasOwnProperty(key) && (formValue[key] || formValue[key] === 0)) {
          if (Array.isArray(formValue[key]) && formValue[key].length) {
            queryParams[key] = JSON.stringify(formValue[key]);
          } else if (!Array.isArray(formValue[key])) {
            queryParams[key] = JSON.stringify(formValue[key]);
          }
        }
      }

      this.navigateToQueryParams(queryParams);
    }
  }

  public resetFilters(): void {
    if (this.form) {
      this.form.reset();
    }
    this.updateFiltersCount();
    this.navigateToQueryParams();
  }

  public navigateToQueryParams(queryParams = {}): void {
    const url: string = this.skipPageReset
      ? this.router.url.split('?')[0] // cut off query params
      : this.router.url.split('/').slice(0, -1).join('/');

    const navigateUrl: (string|number)[] = this.skipPageReset
      ? [url]
      : [url, 1];

    this.router.navigate(navigateUrl, { queryParams })
      .then(() => this.filtersApplied.emit());
  }

  public updateFiltersCount(): void {
    if (!this.form) { return null;}

    let filterCounter = 0;
    const formValue = this.form.value;
    let currentFormValues = {};

    for (let key in formValue) {
      if (
        ((!!formValue[key] || formValue[key] === 0) && !Array.isArray(formValue[key])) ||
        (Array.isArray(formValue[key]) && !!formValue[key].length)
      ) {
        currentFormValues[key] = JSON.stringify(isArray(formValue[key]) ? formValue[key].sort() : formValue[key]);
        if (this.getIgnoreCountControls(key)) {
          filterCounter ++;
        }
      }
    }

    this.showApplyBtn = !isEqual(currentFormValues, this.urlQueryParams);
    this.filtersCount = filterCounter;
  }

  closeSidebar(): void {
    this.sidebarState = false;
  }

  toggleSidebar(): void {
    this.updateFiltersCount();
    this.sidebarState = !this.sidebarState;
  }

 public getIgnoreCountControls(controlName: string): boolean {
    const ignoreControlNames = [
      'lastActivity',
      'created',
      'updated',
      'estimatedDeliveryDate',
      'booked',
      'createdWithin',
      'dueWithin',
      'dueDate',
      'opened',
      'bookingDate',
      'proceeded',
      'duration',
      'received',
      'warehoused',
      'expirationDate',
      'shipped',
      'packedUp',
      'canceled',
      'deleted',
      'lastTemplateUsed',
      'lastUsed',
      'purchased',
    ];

    return !ignoreControlNames.some(i => (i + 'From' === controlName) || (i + 'To' === controlName));
  }

  public checkAppliedFilterByName(controlName: string): boolean {
    return this.urlQueryParams.hasOwnProperty(controlName);
  }


  get formHasSomeValue(): boolean {
    if (!this.form) { return false; }
    return Object.values(this.form.controls).some(({ value }) => !!value || value === 0 || value === false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
