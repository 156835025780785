import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { TradeOfferModel, TradeOfferPositionsByProductType, TradeOfferSummaryModel } from '../../models';
import { TradeOfferListTabsEnum } from '../../enums';
import { ResponseModel } from '../../../shared/models/response';
import { CorporatePartnerModel } from '../../../crm/models';
import { TradeOffersListState } from '../reducers';

export const LoadTradeOffersList = createAction(
  '[Trade Offer] Load Trade Offers List',
  props<{ tradeOfferListData: TradeOffersListState, status: TradeOfferListTabsEnum, page: number }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Trade Offer] Update Trade Offer should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadTradeOffer = createAction(
  '[Trade Offer] Load Trade Offer',
  props<{ tradeOffer: TradeOfferModel }>()
);

export const UpdateTradeOffer = createAction(
  '[Trade Offer] Update Trade Offer',
  props<{ tradeOffer: TradeOfferModel }>()
);

export const LoadTradeOfferPositions = createAction(
  '[Trade Offer Positions] Load Trade Offer Positions',
  props<{ positions: ResponseModel<TradeOfferPositionsByProductType> }>()
);

export const UpdateTradeOfferPositionsCount = createAction(
  '[Trade Offer] Update Trade Offer Positions Count',
  props<{ positionsCount: number }>()
);

export const UpdateTradeOfferCurrentState = createAction(
  '[Trade Offer] Update Trade Offer Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateTradeOfferStatus = createAction(
  '[Trade Offer] Update Trade Offer Status',
  props<{ status: TradeOfferListTabsEnum }>()
);

export const LoadTradeOfferCountries = createAction(
  '[Trade Offer] Load Trade Offer Countries',
  props<{ countries: CountryModel[] }>()
);

export const UpdateTradeOfferUpdatedAt = createAction(
  '[Trade Offer] Update Trade Offer updatedAt',
  props<{ updatedAt: Date }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[Trade Offer] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Trade Offer] Decrement Loading Requests Count'
);

export const UpdateTradeOfferSummary = createAction(
  '[Trade Offer] Update Trade Offer Summary',
  props<{ summary: TradeOfferSummaryModel }>()
);

export const ClearTradeOfferState = createAction(
  '[Trade Offer] Clear Trade Offer state'
);

export const ClearTradeOfferPositions = createAction(
  '[Trade Offer] Clear Trade Offer positions'
);

export const ClearBlocksValidation = createAction(
  '[Trade Offer] Clear Trade Offer Blocks validation'
);

export const UpdatePropertiesBlockValid = createAction(
  '[Trade Offer] UpdateTrade Offer Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateDeliveryInfoBlockValid = createAction(
  '[Trade Offer] UpdateTrade Offer Delivery Info Block Valid',
  props<{ deliveryInfoBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Trade Offer] UpdateTrade Offer Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Trade Offer] UpdateTrade Offer Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);

