import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { FamilyModel, ProductModel } from 'common/src/modules/products/models';
import { ProductsService } from 'common/src/modules/products/products.service';
import { ProductTypes } from 'common/src/modules/products/product-types';

@Component({
  selector: 'rnpl-move-single-product-modal',
  templateUrl: './move-single-product-modal.component.html',
})
export class MoveSingleProductModalComponent extends BaseModalComponent implements OnInit {

  public family: FamilyModel;
  public familiesTree: Array<FamilyModel>;
  public flatFamilies: FamilyModel[] = [];
  public selectedFamily: FamilyModel = null;

  public controls: any = [];
  public form: FormGroup = new FormGroup({});
  public displayProductErrors: boolean = false;

  public counterAnimation: boolean = false;

  readonly submitProductRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly blueprintLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public toasterService: ToasterService,
    public productsService: ProductsService,
    public dialogRef: MatDialogRef<MoveSingleProductModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      product: ProductModel
    }
  ) {
    super(toasterService);

    setInterval(() => {
      this.displayHighlightingAnimation();
    }, 1200);
  }

  ngOnInit() {
    const requestParams = new HttpParams().set('products_type', this.productType);

    this.productsService.getFamiliesTree(requestParams)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        this.familiesTree = response.data;
        this.flatFamilies =  this.convertTreeToFlat(this.familiesTree)
          .filter(family => +family.id !== +this.data.product.family.id);
        this.showDropdownSpin$.next(false);
      });
  }

  public displayHighlightingAnimation(): void {
    this.counterAnimation = !this.counterAnimation;
  }

  private convertTreeToFlat(tree: FamilyModel[]): FamilyModel[] {
    if (!tree) { return; }

    const flatArray = [];
    const getTreeItems = (branch) => {
      branch.map(node => {
        if (!node.product_locked_at) {
          flatArray.push(node);
        }

        if (node.children.length) {
          getTreeItems(node.children);
        }
      });
    };

    getTreeItems(tree);

    return flatArray;
  }

  public getProductBlueprint(familyId: string = this.family.id): void {
    this.blueprintLoading$.next(true);
    this.form = new FormGroup({});
    this.controls = [];
    this.productsService.getProductBlueprint(this.productTypeFormKey, familyId)
      .pipe(takeUntil(this._destroy))
      .subscribe((response) => {
        this.controls = response.map(control => {
          control.properties = {label: control.label};
          const optionsRule = control.rules.find(rule => rule.type === 'options');
          if (optionsRule && optionsRule.list) {
            optionsRule.options = optionsRule.list.map(option => {
              return {label: option.eng, value: option.eng};
            });
          }
          return control;
        });
        this.blueprintLoading$.next(false);
      });
  }

  public submitProduct(): void {
    if (this.submitProductRequest$.getValue()) { return; }
    this.submitProductRequest$.next(true);

    if (!this.selectedFamily) {
      this.displayMessage('error', 'MESSAGES.CATEGORY_MUST_BE_FILLED');
      this.submitProductRequest$.next(false);
      return;
    }

    this.productsService.moveProductToAnotherCategory(+this.data.product.id, this.selectedFamily.id, false, this.form.getRawValue())
      .pipe(
        finalize(() => { this.submitProductRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(response => {
        this.dialogRef.close(response);
      });
  }

  get productTypeFormKey(): string {
    return `product_${this.productType}_create`;
  }

  get productType(): ProductTypes { return this.data.product.productType; }

}
