import { Component, Input } from '@angular/core';


@Component({
  selector: 'rnpl-linear-tree-branch-skeleton',
  templateUrl: './linear-tree-branch-skeleton.component.html',
  styleUrls: ['./linear-tree-branch-skeleton.component.scss']
})
export class LinearTreeBranchSkeletonComponent {
  @Input() public productsListType: boolean = false;

}
