import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { BaseControlComponent } from '../base-control.component';
import { BarcodeFormat } from '@zxing/library';

@Component({
  selector: 'rnpl-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarcodeScannerComponent extends BaseControlComponent implements OnInit, OnChanges {

  @Input()
  public placeholder: string = null;

  public scannerIsEnabled: boolean = false;
  public deviceIndex: number;
  public barcodeValue: string = '';
  public cameras: Array<any> = [];

  public formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  @ViewChild('input', {static: true, read: false})
  private input: ElementRef;


  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.control.value) {
      this.barcodeValue = this.control.value;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('disabled')) {
      this.updateControlEditingStatus();
    }

    if (this.control.value || this.control.value === null) {
      this.barcodeValue = this.control.value;
    }
  }

  public updateControlEditingStatus(): void {
    if (!this.control) { return; }

    if (this.disabled) {
      this.control.disable({ onlySelf: false });
    } else {
      this.control.enable({ onlySelf: false });
    }
  }

  public change(event?: any): void {
    if (this.control) {
      this.control.setValue(this.barcodeValue);
      this.control.markAsDirty();

      this.formUpdated.emit(true);
    }
  }

  /**
   * Checks available cameras
   *
   * @param cameras List of enabled cameras on device
   */
  public checkCameras(cameras): void {
    this.cameras = cameras;
    if (this.cameras.length) {
      const index = this.cameras.findIndex(device => (device.label as string).includes('back'));
      this.deviceIndex = index !== -1 ? index : 0;
    }
  }

  /**
   * Changes a camera on device
   */
  public switchCamera() {
    if (this.cameras.length < 2) {
      return;
    }
    this.deviceIndex = this.deviceIndex < this.cameras.length - 1
      ? this.deviceIndex + 1
      : 0;
  }

  /**
   * Enables/disables scanner
   *
   * @param isEnabled Show/hide key
   */
  public enableScanner(isEnabled?: boolean): void {
    if (!this.cameras.length && !this.scannerIsEnabled) {
      return;
    }
    this.scannerIsEnabled = isEnabled || !this.scannerIsEnabled;
    if (!this.scannerIsEnabled) {
      this.input.nativeElement.focus();
    } else {
      this.input.nativeElement.blur();
    }
  }

  /**
   * Enables scanner on focus of barcode field
   * if barcode value is empty
   *
   * @param isEnabled Show/hide key
   */
  public enableOnFocus(isEnabled?: boolean) {
    if (this.barcodeValue) {
      return;
    }
    this.enableScanner(isEnabled);
  }

  /**
   * Reads scanned value
   *
   * @param result Result of scanning
   */
  public handleScan(result: string): void {
    if (result) {
      this.barcodeValue = result;
      this.change();
    }
    this.enableScanner(false);
  }
}
