import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';

import { WizardStep } from '../../../../wizard/wizard-step';
import { FormTooltipComponent } from '../../../../ui-components/form-tooltip/form-tooltip.component';
import { AttributeWizardComponent } from '../../attribute-wizard.component';
import { AbstractAppComponent } from '../../../../../interfaces';

@Component({
  selector: 'rnpl-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent extends WizardStep implements OnInit {

  @ViewChild('nameHint', {static: false})
  private nameHint: FormTooltipComponent;

  @ViewChild('typeHint', {static: false})
  private typeHint: FormTooltipComponent;

  @ViewChild('isRequiredHint', {static: false})
  private isRequiredHint: FormTooltipComponent;

  private itemProgress: number;

  constructor(@Optional() public wizard: AttributeWizardComponent,
              @Optional() public app: AbstractAppComponent) {
    super();
  }

  ngOnInit(): void {

    if (this.app && this.app.projectName === 'workspace') {
      this.form.get('type').setValue('custom');
      this.form.get('type').markAsDirty();
    }

    this.initProgress();

    this.form.get('name').valueChanges
      .subscribe(value => {
        this.wizardForm.translations.get('translations').setValue([{code_iso3: 'eng', value}]);
      });

    this.form.get('name').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        const progress = this.wizard.progress + Math.floor(this.progress.finish / 2);
        if (progress <= this.progress.finish) {
          this.wizard.progress = progress;
        }
        if (this.nameHint) {
          this.nameHint.hide();
        }
      });

    if (this.app.projectName === 'supervisor') {
      this.form.get('type').valueChanges
        .pipe(take(1))
        .subscribe(() => {
          const progress = this.wizard.progress + Math.floor(this.progress.finish / 2);
          if (progress <= this.progress.finish) {
            this.wizard.progress = progress;
          }
          if (this.typeHint) {
            this.typeHint.hide();
          }
        });
    }

    this.form.get('required').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        if (this.isRequiredHint) {
          this.isRequiredHint.hide();
        }
      });
  }

  private initProgress(): void {

    this.itemProgress = this.app.projectName === 'supervisor'
      ? Math.floor(this.progress.finish / 2)
      : this.progress.finish;

    if (this.form.get('name').touched) {
      const progress = this.wizard.progress + this.itemProgress;
      if (progress <= this.progress.finish) {
        this.wizard.progress = progress;
      }
    }
    if (this.app.projectName === 'supervisor' && this.form.get('type').value) {
      const progress = this.wizard.progress + this.itemProgress;
      if (progress <= this.progress.finish) {
        this.wizard.progress = progress;
      }
    }
  }
}
