import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AbstractControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CustomSearchFn } from '../../../rnpl-common/helpers';

@Component({
  selector: 'rnpl-filters-control-base',
  template: ''
})
export class FiltersControlBaseComponent implements OnDestroy {

  public translatedLowerCasedTitle: string;
  public sourceTitle: string;
  public customSearchFn = CustomSearchFn;

  @Input() control: AbstractControl;
  @Input() set title(title: string) {
    this.translatedLowerCasedTitle = this.translateService.instant(title).toLowerCase();
    this.sourceTitle = title;
  }
  @Input() isMultiple: boolean = true;
  @Input() allowSelectAll: boolean = true;
  @Input() placeholder: string;
  @Input() hasAppliedFilter: boolean;

  @Output() applyFilterEmmit: EventEmitter<any> = new EventEmitter<any>();

  readonly destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(public translateService: TranslateService) { }

  public toggleSelectAllEntries(checked: boolean, select: NgSelectComponent): void {
    if (checked) {
      select.itemsList.filteredItems.forEach(itm => select.select(itm));
    } else {
      select.itemsList.filteredItems.forEach(itm => select.unselect(itm));
    }
  }

  public applyFilter(): void {
    this.applyFilterEmmit.next();
  }

  public resetFilterHandler(): void {
    this.control.reset();
    if (this.hasAppliedFilter) {
      this.applyFilter();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
