import { FormControl } from '@angular/forms';

/**
* Array min length validator
*
* @param length min length
*/
export const arrayMinLengthValidator = (length: number) => {
  return (control: FormControl) => {

    if (control.value.length < length) {
      return {message: `Should have ${length} of more items.`};
    }

    return null;
  };
};