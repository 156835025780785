import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rnpl-column-with-arrow-right',
  templateUrl: './column-with-arrow-right.component.html',
  styleUrls: ['./column-with-arrow-right.component.scss']
})
export class ColumnWithArrowRightComponent {
  @Output() textClick: EventEmitter<string> = new EventEmitter();
  @Input() value: {label: any, routerLink: string};

  onTextClick(): void {
    this.textClick.emit(this.value.label.label || this.value.label);
  }
}
