import { EventEmitter, Output } from '@angular/core';

export class Wizard {

  @Output()
  public completed: EventEmitter<any> = new EventEmitter();

  @Output()
  public closed: EventEmitter<any> = new EventEmitter();

  public step: string;

}
