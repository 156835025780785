import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../store/state/app.state';
import { FilterModel } from '../../warehouse/models/filter.model';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadTradeOffer,
  LoadTradeOfferPositions,
  LoadTradeOffersList,
  UpdateShouldRefreshEntity,
  UpdateTradeOffer,
  UpdateTradeOfferCurrentState,
  UpdateTradeOfferPositionsCount,
  UpdateTradeOfferSummary,
  UpdateTradeOfferUpdatedAt
} from '../store/actions/trade-offer.actions';
import {
  ResponseTradeOfferCountersModel,
  TradeOfferCountersModel,
  TradeOfferModel, TradeOfferPositionsByProductType, TradeOfferSummaryModel
} from '../models';
import { TradeOfferListTabsEnum } from '../enums';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { PredefinedDocumentDataModel, UIStatesEnum } from 'common/src/models';
import { TradePositionModel } from '../../trade/models';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { PurchaseOrder } from '../../purchase-order/models';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { DocumentTemplateModel } from '../../shared/models';


@Injectable()
export class TradeOfferApiService {
  private readonly apiEndpoint: string = '/trade-offers';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) { }

  createTradeOfferDraft(data: PredefinedDocumentDataModel = {}): Observable<TradeOfferModel> {
  return this.http.post<ResponseModel<TradeOfferModel>>(this.apiUrl('/draft'), {...data})
    .pipe(
      map((data: ResponseModel<TradeOfferModel>) => data.data),
      catchError(error => {
        this.handlePopupErrors(error);
        return throwError(error);
      })
    );
  }

  createAcceptTradeOffer(tradeOfferId: string | number, acceptedStatus = false): Observable<TradeOfferModel> {
    const body = { acceptedStatus };
    return this.http.post<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${tradeOfferId}/accept-create`), body)
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(LoadTradeOffer({tradeOffer: response.data as TradeOfferModel}));
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
        }),
        map((data: ResponseModel<TradeOfferModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  getTradeOfferList(
    status: TradeOfferListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION},
    filters: any = {}
  ): Observable<ResponseList<TradeOfferModel>> {
    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<TradeOfferModel>>(
      this.apiUrl(),
      { params }
    ).pipe(tap((data: ResponseList<TradeOfferModel>) => {
      this.store.dispatch(LoadTradeOffersList({
        tradeOfferListData: {
          [data.pagination.page]: {
            pagination: data.pagination,
            sort,
            data: data.data
          }
        },
        status, page: data.pagination.page
      }));
    }));
  }

  getTradeOfferListPositions(offerId: number): Observable<TradePositionModel[]> {
    return this.http.get<ResponseModel<TradePositionModel[]>>(this.apiUrl(`/${offerId}/list/positions`))
      .pipe(map((data: ResponseModel<TradePositionModel[]>) => data.data));
  }

  getTradeOffersRunpleIds(status: TradeOfferListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl(`/runpleIds`), { params })
      .pipe(map((data: any) => data.data));
  }

  getActiveOffers(): Observable<TradeOfferModel[]> {
    return this.http.get<ResponseModel<TradeOfferModel[]>>(this.apiUrl('/linkable'))
      .pipe(map((data: ResponseModel<TradeOfferModel[]>) => data.data));
  }

  getTradeOfferCounters(): Observable<TradeOfferCountersModel> {
    return this.http.get<ResponseModel<ResponseTradeOfferCountersModel>>(this.apiUrl('/counters'))
      .pipe(map((data: ResponseModel<ResponseTradeOfferCountersModel>) => data.data.offers));
  }

  getTradeOfferById(id: string | number): Observable<TradeOfferModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${id}`))
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(LoadTradeOffer({tradeOffer: response.data as TradeOfferModel}));
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
          this.store.dispatch(UpdateTradeOfferPositionsCount({positionsCount: response.data.positionsCount }));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updateTradeOffer(tradeOfferId: number, field: FormInputChangedModel): Observable<TradeOfferModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${tradeOfferId}`), field)
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadTradeOffer({tradeOffer: response.data}));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, tradeOfferId);
          return throwError(error);
        })
      );
  }

  updateDeliveryAddressByCustomer(tradeOfferId: number): Observable<TradeOfferModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<TradeOfferModel>>('put', this.apiUrl(`/${tradeOfferId}/delivery-address/update-by-customer`))
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadTradeOffer({ tradeOffer: response.data }));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, tradeOfferId);
          return throwError(error);
        })
      );
  }

  updatePickUpAddressByWarehouse(tradeOfferId: number): Observable<TradeOfferModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<TradeOfferModel>>('put', this.apiUrl(`/${tradeOfferId}/pickup-address/update-by-warehouse`))
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadTradeOffer({ tradeOffer: response.data }));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, tradeOfferId);
          return throwError(error);
        })
      );
  }

  updateBillingInfoAddressByCustomer(tradeOfferId: number): Observable<TradeOfferModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<TradeOfferModel>>('put', this.apiUrl(`/${tradeOfferId}/billing-address/update-by-customer`))
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadTradeOffer({ tradeOffer: response.data }));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, tradeOfferId);
          return throwError(error);
        })
      );
  }

  linkTradeOfferToSalesOrder(tradeOfferId: number | string): Observable<TradeOfferModel> {
    return this.http.post<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${tradeOfferId}/sales-order`), {})
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(LoadTradeOffer({tradeOffer: response.data as TradeOfferModel}))
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data)
      );
  }

  unlinkTradeOffer(tradeOfferId: number | string): Observable<TradeOfferModel> {
    return this.http.post<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${tradeOfferId}/unlink`), {})
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(LoadTradeOffer({tradeOffer: response.data as TradeOfferModel}))
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getTradeOfferPositions(tradeOfferId: number): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    return this.http.get<ResponseModel<TradeOfferPositionsByProductType>>(this.apiUrl(`/${tradeOfferId}/positions`))
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(LoadTradeOfferPositions({positions: response}));
        }),
      );
  }

  createTradeOfferPosition(tradeOfferId: number, position: TradePositionModel): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<TradeOfferPositionsByProductType>>(this.apiUrl(`/${tradeOfferId}/positions`), position)
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadTradeOfferPositions({positions: response}));
          this.store.dispatch(UpdateTradeOfferPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, tradeOfferId);
          return throwError(error);
        })
      );
  }

  updateTradeOfferPosition(id: number, field: FormInputChangedModel, tradeOfferId: number): Observable<ResponseModel<TradePositionModel>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<TradePositionModel>>(this.apiUrl(`/positions/${id}`), field).pipe(
      tap((response: ResponseModel<TradePositionModel>) => {
        this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, tradeOfferId);
        return throwError(error);
      })
    );
  }

  deleteTradeOfferPositions(tradeOfferId: number, ids: number[]): Observable<ResponseModel<TradeOfferPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ids: ids };
    return this.http.request<ResponseModel<TradeOfferPositionsByProductType>>(
      'delete',
      this.apiUrl(`/${tradeOfferId}/positions`),
      {body}
    )
      .pipe(
        tap((response: ResponseModel<TradeOfferPositionsByProductType>) => {
          this.store.dispatch(LoadTradeOfferPositions({positions: response}));
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateTradeOfferPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, tradeOfferId);
          return throwError(error);
        })
      );
  }

  changePositionOrder(posId: number, moveToOrder: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(this.apiUrl(`/positions/${posId}/order/${moveToOrder}`), body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  deleteTradeOfferPermanently(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(), {body});
  }

  changeTradeOfferStatus(status: TradeOfferListTabsEnum, tradeOfferId: number): Observable<TradeOfferModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status };
    return this.http
      .patch(this.apiUrl(`/${tradeOfferId}/status`), body)
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOfferUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadTradeOffer({ tradeOffer: response.data }));
        }),
        map((data: ResponseModel<TradeOfferModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  changeTradeOfferStatusBatch(status: TradeOfferListTabsEnum, offer_ids: number[]): Observable<any> {
    const body = {status, offer_ids};
    return this.http.patch(this.apiUrl('/multiple/statuses'), body);
  }

  getTradeOfferSummary(id: number | string): Observable<ResponseModel<TradeOfferSummaryModel>> {
    return this.http.get<ResponseModel<TradeOfferSummaryModel>>(this.apiUrl(`/${id}/summary`))
      .pipe(
        tap((data: ResponseModel<TradeOfferSummaryModel>) => {
          this.store.dispatch(UpdateTradeOfferSummary({summary: data.data}));
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  cloneTradeOffer(id: number): Observable<TradeOfferModel> {
    return this.http.request<ResponseModel<TradeOfferModel>>('post', this.apiUrl(`/${id}/clone`))
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(LoadTradeOffer({tradeOffer: response.data as TradeOfferModel}));
          this.store.dispatch(UpdateTradeOfferPositionsCount({positionsCount: response.data.positionsCount }));
        }),
        map((data: ResponseModel<TradeOfferModel>) => data.data)
      );
  }

  public getTradeOfferExportParams(id: number, title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `accounting/v1/pdf/trade-offer/${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  public tradeOfferSetEdit(id: string | number, force = false): Observable<TradeOfferModel> {
    const body = { force };

    return this.http.put<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOffer({ tradeOffer: response.data }));
          this.store.dispatch(UpdateTradeOfferCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public tradeOfferUnsetEdit(id: string | number): Observable<TradeOfferModel> {
    return this.http.request<ResponseModel<TradeOfferModel>>('put', this.apiUrl(`/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<TradeOfferModel>) => {
          this.store.dispatch(UpdateTradeOffer({ tradeOffer: response.data }));
          this.store.dispatch(UpdateTradeOfferCurrentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<TradeOfferModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  // @DisplayToaster({showErrorMessage: true})
  // public sendTradeOfferEmail(tradeOffer: number, url: string): Observable<TradeOfferModel> {
  //   return this.http.post<ResponseModel<TradeOfferModel>>(this.apiUrl(`/${tradeOffer}/email`), {url})
  //     .pipe(
  //       tap((response: ResponseModel<TradeOfferModel>) => {
  //         this.store.dispatch(UpdateTradeOffer({ tradeOffer: response.data }));
  //       }),
  //       map((response: ResponseModel<TradeOfferModel>) => response.data)
  //     );
  // }

  public getDocumentTemplates(): Observable<DocumentTemplateModel[]> {
    return this.http.get<ResponseModel<DocumentTemplateModel[]>>(this.apiUrl('/templates'))
      .pipe(map((response: ResponseModel<DocumentTemplateModel[]>) => response.data));
  }

  public getDocumentTemplatesNames(): Observable<{ id: number; name: string; }[]> {
    return this.http.get<ResponseModel<{ id: number; name: string; }[]>>(this.apiUrl('/templates/names'))
      .pipe(map((response: ResponseModel<{ id: number; name: string; }[]>) => response.data));
  }

  public saveDocumentTemplate(documentId: number, body: { name: string; overwrite: boolean; resetCounter: boolean; }): Observable<boolean> {
    return this.http.post<ResponseModel<boolean>>(this.apiUrl(`/${documentId}/template`), body)
      .pipe(map((response: ResponseModel<boolean>) => response.data));
  }

  public applyDocumentTemplate(documentId: number, templateId: number, force = false): Observable<any> {
    return this.http.patch<ResponseModel<any>>(this.apiUrl(`/${documentId}/template/${templateId}`), {force})
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDocumentFromTemplate(templateId: number): Observable<number> {
    return this.http.request<ResponseModel<number>>('post', this.apiUrl(`/template/${templateId}`))
      .pipe(map((response: ResponseModel<number>) => response.data));
  }

  public tradeOfferConfirmation(token: string): Observable<any> {
    return this.http.post(
      this.apiUrl(`/reply`),
      {
        secret: token
      }
    );
  }

  public getTOListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
    };
    return of(fileParams);
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, toId?: number | string): void {
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          ),
          backdropClass: 'backdrop-none',
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.tradeOfferSetEdit(toId, true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('APP.OFFER');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdateTradeOfferCurrentState({ currentState: UIStatesEnum.VIEW }));
        this.getTradeOfferById(toId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message);
        break
    }
  }

}
