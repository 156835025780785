import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BatchDataModel } from 'projects/workspace/src/app/purchase-order/pages/purchase-order-management/batches/models/batch-data.model';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { AutoUnsubscribe } from 'common/src/decorators/auto-unsubscribe';
import { Batch } from 'projects/workspace/src/app/purchase-order/models/purchase-order/batch';

@AutoUnsubscribe()
@Component({
  selector: 'rnpl-create-new-batch-modal',
  templateUrl: './create-new-batch-modal.component.html',
  styleUrls: ['./create-new-batch-modal.component.scss']
})
export class CreateNewBatchModalComponent implements OnDestroy {

  constructor(
    private toasterService: ToasterService,
    private readonly poApi: PurchaseOrderApiService,
    public dialogRef: MatDialogRef<CreateNewBatchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BatchDataModel) {
  }

  public displayMessage(msg: string, type: string) {
    this.toasterService.notify({
      type,
      message: msg
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public submitNewBatch(formValue) {
    this.createNewBatch(formValue, this.data.id);
  }

  public createNewBatch(batchData: {batches: Batch[]}, purchaseOrderId: number): void {
    this.poApi.createNewBatch(purchaseOrderId, batchData)
      .subscribe(
        (response: any) => {
          this.displayMessage(`New batch was successfully created`, 'success');
          this.dialogRef.close(response);
        },
        errorObject => {
          this.displayMessage(errorObject.error.message || errorObject.message, 'error');
        });
  }

  ngOnDestroy(): void {
  }

}
