import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { WarehouseCreateModel } from 'projects/workspace/src/app/warehouse/models/warehouse-create-model';
import { StockAddressesService } from 'projects/workspace/src/app/warehouse/services';


import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rnpl-create-new-warehouse-modal',
  templateUrl: './create-new-warehouse-modal.component.html',
  styleUrls: ['./create-new-warehouse-modal.component.scss']
})
export class CreateNewWarehouseModalComponent implements OnDestroy {

  public warehouseData: any = new WarehouseCreateModel();

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private stockAddressesService: StockAddressesService,
    private toasterService: ToasterService,
    public dialogRef: MatDialogRef<CreateNewWarehouseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  public displayMessage(msg: string, type: string) {
    this.toasterService.notify({
      type,
      message: msg
    });
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public submitWarehouse(warehouseDataRequest: WarehouseCreateModel) {
    this.stockAddressesService.createWarehouse(warehouseDataRequest)
    .pipe(
      takeUntil(this._destroy)
    )
    .subscribe((_) => {
      this.displayMessage(`Warehouse was successfully created`, 'success');
      this.onNoClick();
    }, errorObject => this.displayMessage(errorObject.error.message || errorObject.message, 'error'));
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }

}

