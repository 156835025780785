import {Component, Input, OnInit, Optional} from '@angular/core';
import { CustomTableComponent } from '../../custom-table.component';
import {ContextMenuItemModel} from '../../../../../models/context-menu.model';

@Component({
  selector: 'rnpl-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() x = 0;
  @Input() y = 0;
  @Input() isShow: boolean;
  @Input() itemsData: Array<ContextMenuItemModel>;

  constructor(public table: CustomTableComponent) { }

  ngOnInit() {
  }

  public itemAction(action: string): void {
    switch (action) {
      case 'deleteItem':
        this.table.deleteHandler();
        break;
      case 'view':
        this.table.viewHandler();
        break;
      case 'sendEmail':
        this.table.sendEmailHandler();
        break;
      case 'moveToArchive':
        this.table.moveToArchiveHandler();
        break;
      case 'addToBookmarks':
        this.table.addToBookmarksHandler();
        break;
    }
  }
}
