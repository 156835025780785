import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-base-dropdown',
  templateUrl: './filters-control-base-dropdown.component.html',
})
export class FiltersControlBaseDropdownComponent extends FiltersControlBaseComponent {

  public prepareItemsList: {label: string, value: any, searchLabel?: string}[] = [];

  @Input() itemsList: {label: string, value: any, searchLabel?: string}[] = [];
  @Input() placeholder: string;

  constructor(public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('itemsList')) {
      if (!!this.itemsList && !!this.itemsList.length) {
        this.prepareItemsList = this.itemsList.map(item => {
          return {
            ...item,
            searchLabel: `${item.value} ${this.translateService.instant(item.label)}`
          };
        });
      }
    }
  }

}
