import { Component, Input, OnInit, SimpleChanges, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

import { AddressModel } from 'common/src/models';
import { CountryModel, CountryTransformFromIso2ToIso3Pipe, CountryTransformFromIso3ToIso2Pipe } from 'common/src/modules/rnpl-common';
import { BaseFormComponent } from '../base-form.component';
import { AddressFormFactory } from '../address-form.factory';
import { FormLabelModel } from 'common/src/models/form-label.model';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models/form-input-value.model';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'rnpl-address-form',
  templateUrl: './address-form.component.html'
})
export class AddressFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  public streetAutocompleteOptions: Options = {
    fields: ['address_components'],
    types: ['route']
  } as Options;

  public postalCodeAutocompleteOptions: Options = {
    fields: ['address_components'],
    types: ['postal_code']
  } as Options;

  public cityAutocompleteOptions: Options = {
    fields: ['address_components'],
    types: ['locality'],
  } as Options;


  @Input()
  counterAnimation: boolean = false;

  @Input()
  form: FormGroup;

  @Input()
  disabledCountry: boolean;

  @Input()
  skipOptionalOnServerValidation: boolean = false;

  @Input()
  address: AddressModel;

  @Input()
  countries: CountryModel[];

  @Input()
  appendTo: string;

  @Input()
  formLabel: FormLabelModel;

  @Input()
  requiredControlNames: string[];

  @Input()
  streetHintsId: string = '';

  @Output()
  inputChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  constructor(
    private countryTransformFromIso3ToIso2Pipe: CountryTransformFromIso3ToIso2Pipe,
    private countryTransformFromIso2ToIso3Pipe: CountryTransformFromIso2ToIso3Pipe
  ) {
    super();
  }

  @ViewChild(NgSelectComponent, {static: false}) private select: NgSelectComponent;

  @ViewChild('placesRefStreet', {static: false}) private placesRefStreet: GooglePlaceDirective;
  @ViewChild('placesRefPostalCode', {static: false}) private placesRefPostalCode: GooglePlaceDirective;
  @ViewChild('placesRefCity', {static: false}) private placesRefCity: GooglePlaceDirective;

  ngOnInit() {
    if (!this.form) {
      this.initForm(this.address);
    }
    window.addEventListener('scroll', this.onScroll, true);

    this.country_iso3.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(data => {
      this.setCurrentCountry(data)
    });
  }

  ngAfterViewInit() {
    if (!!this.country_iso3.value) {
      this.setCurrentCountry(this.country_iso3.value);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
    AddressFormComponent.removeAutocompleteElement();
  }

  public displayHighlightingAnimation(): void {
    this.counterAnimation = !this.counterAnimation
  }

  @TrackInputChanges<boolean>('disabledCountry', 'handleDisabledCountryChanges', ChangesStrategy.Each)
  @TrackInputChanges<boolean>('requiredControlNames', 'handleRequiredControlNamesChanges', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void {
  }

  public updateInput(fieldName: string, fieldValue: any): void {
    if (fieldName === 'country_iso3' && !!fieldValue) {
      this.setCurrentCountry(fieldValue);
    }

    this.inputChanged.emit({fieldName, fieldValue});
  }

  public handleRequiredControlNamesChanges(): void {
    if (!this.form) { return; }

    if (this.requiredControlNames.length) {
      this.requiredControlNames.forEach(controlName => {
        this.setValidators(controlName, Validators.required);
      });
    }
  }

  public handleDisabledCountryChanges(): void {
    if (!this.form) { return; }
    (this.disabledCountry) ? this.country_iso3.disable({emitEvent: false}) : this.country_iso3.enable({emitEvent: false});
  }

  public setValidators(controlName: string, validator: ValidatorFn) {
    const control = this.form.get(controlName) as FormControl;
    if (control) {
      control.setValidators(validator);
    }
  }

  public hasValidator(controlName: string, validatorName: string): boolean {
    const controlValue = this.form.get(controlName) as FormControl;

    if (!controlValue || !controlValue.validator) { return; }

    return !!controlValue.validator({} as AbstractControl).hasOwnProperty(validatorName); // returns true if control has the validator
  }

  initForm(address: AddressModel = {} as any): void {
    this.form = AddressFormFactory.getForm(address, this.readonly);
    super.initForm();
  }
// Close dropdown with scroll form
  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.select.close();
    }
  }

  public addressChange(address: any, fieldName: string) {
    if (fieldName !== 'street') {
      const value = address.address_components[0].long_name;

      if (fieldName === 'city') {
        this.city.setValue(value)
      } else {
        this.zip_code.setValue(value)
      }

      this.inputChanged.emit({fieldName: fieldName, fieldValue: value});
      return;
    }

    let postalCode = '';
    let city = '';
    let street = '';
    let countryIso = '';
    // let state = '';
    let house = '';

    address.address_components.map(component => {
      switch (component.types[0]) {
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'country':
          countryIso = this.countryTransformFromIso2ToIso3Pipe.transform(component.short_name).toLowerCase();
          break;
        case 'route':
          street = component.long_name;
          break;
        // case 'administrative_area_level_1':
        //   state = component.long_name;
        //   break;
        case 'street_number':
          house = component.long_name;
          break;
      }
    });

    this.block.setValue('');
    this.apartments.setValue('');

    // if (!this.zip_code.value || fieldName === 'zip_code') {
      this.zip_code.setValue(postalCode);
      this.inputChanged.emit({fieldName: 'zip_code', fieldValue: postalCode});
    // }

    // if (!this.city.value || fieldName === 'city') {
      this.city.setValue(city);
      this.inputChanged.emit({fieldName: 'city', fieldValue: city});
    // }

    // if (!this.state.value) {
    //   this.state.setValue(state);
    //   this.inputChanged.emit({fieldName: 'state', fieldValue: state});
    // }

    // if (!this.house_number.value) {
      this.house_number.setValue(house);
      this.inputChanged.emit({fieldName: 'house_number', fieldValue: house || ''});
    // }

    // if (!this.street.value || fieldName === 'street') {
      this.street.setValue(street);
      this.inputChanged.emit({fieldName: 'street', fieldValue: street});
    // }


    this.country_iso3.setValue(countryIso);
    this.inputChanged.emit({fieldName: 'country_iso3', fieldValue: countryIso});
  }

  private setCurrentCountry(country): void {
    this.placesRefStreet.options.componentRestrictions = { country: this.countryTransformFromIso3ToIso2Pipe.transform(country) || '' };
    this.placesRefPostalCode.options.componentRestrictions = { country: this.countryTransformFromIso3ToIso2Pipe.transform(country) || '' };
    this.placesRefCity.options.componentRestrictions = { country: this.countryTransformFromIso3ToIso2Pipe.transform(country) || '' };

    this.placesRefStreet.reset();
    this.placesRefPostalCode.reset();
    this.placesRefCity.reset();
  }

  private static removeAutocompleteElement(): void {
    document.querySelectorAll('.pac-container').forEach(el => el.remove());
  }

  get country_iso3(): FormControl { return this.form.get('country_iso3') as FormControl; }
  get city(): FormControl { return this.form.get('city') as FormControl; }
  // get state(): FormControl { return this.form.get('state') as FormControl; }
  get street(): FormControl { return this.form.get('street') as FormControl; }
  // get floor(): FormControl { return this.form.get('floor') as FormControl; }
  get block(): FormControl { return this.form.get('block') as FormControl; }
  get house_number(): FormControl { return this.form.get('house_number') as FormControl; }
  get apartments(): FormControl { return this.form.get('apartments') as FormControl; }
  get zip_code(): FormControl { return this.form.get('zip_code') as FormControl; }
  get additional_information(): FormControl { return this.form.get('additional_information') as FormControl; }
}
