import { Directive, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { DynamicFormService } from '../dynamic-form.service';

@Directive({
  selector: '[rnplAttributePreview]'
})
export class AttributePreviewDirective implements OnChanges {

  @Input('rnplAttributePreview')
  attribute: any;

  @Input()
  formGroup: FormGroup;

  constructor(private viewContainer: ViewContainerRef, private formService: DynamicFormService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('attribute')) {
      this.viewContainer.clear();
      this.formService.initAttrPreview(this.viewContainer, this.attribute);
    }
  }

}
