import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';

import { TabDefinitionModel } from './tab-definition.model';


@Component({
  selector: 'rnpl-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnDestroy {

  @Input() public tabs: Array<TabDefinitionModel>;

  @Input() public activeStatus: string;

  @Output() action = new EventEmitter<string>();
  @Output() changeTabName = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) { }

  changeActiveStatus(status: string, tabName: string) {
    this.action.emit(status);
    this.changeTabName.emit(tabName);
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

}
