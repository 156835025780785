import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { CommonModalsActionsEnum } from '../../modals-common';
import {
  AccountingSettingsService
} from 'projects/workspace/src/app/accounting/accounting-settings-module/services/accounting-settings.service';

// todo: check usage
@Component({
  selector: 'rnpl-connect-bank-account-modal',
  templateUrl: './connect-bank-account-modal.component.html'
})
export class ConnectBankAccountModalComponent extends BaseModalComponent implements OnDestroy {
  public dontShowAgainToggle = false;
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    private accountingSettingsService: AccountingSettingsService,
    public dialogRef: MatDialogRef<ConnectBankAccountModalComponent>,
  ) {
    super(toasterService);
  }

  public onLearnAboutPSD2(): void {
    window.open('https://ec.europa.eu/commission/presscorner/detail/de/QANDA_19_5555', '_blank');
  }

  public submitConnect(): void {
    if (this.submitRequest$.getValue()) { return; }

    if (this.dontShowAgainToggle) {
      this.submitRequest$.next(true);

      this.accountingSettingsService.accountingSettingsSkipSyncPaymentsDialog()
        .pipe(
          finalize(() => this.submitRequest$.next(false)),
          takeUntil(this._destroy)
        )
        .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));

      return;
    }

    this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
  }

}
