import { Injectable } from '@angular/core';


import { StorageService } from 'common/src/modules/storage';
import { AppContextModel } from 'common/src/models/app-context.model';
import { environment } from '../../../../../../projects/workspace/src/environments/environment';


@Injectable()
export class AppContextService {

  private static STORAGE_KEY = 'rnpl_app_context';

  private static STORAGE_EXPIRES_TIME = 60 * 60 * 10000;

  private _context: AppContextModel;

  constructor(private storage: StorageService) {
    this.loadContext();
    this._context.apiBase = environment.apiUrl + environment.apiBase;
    this.saveContext();
  }

  public setToContext(key: string, data: any): void {
    this._context[key] = data;
    this.saveContext();
  }

  public getFromContext<T>(key: string): T {
    this.loadContext();
    return this._context[key];
  }

  private saveContext(): void {
    this.storage.store(AppContextService.STORAGE_KEY, this._context, StorageService.TYPE_LOCAL, AppContextService.STORAGE_EXPIRES_TIME);
  }

  private loadContext(): void {
    this._context = this.storage.load<AppContextModel>(AppContextService.STORAGE_KEY) || {};
  }
}
