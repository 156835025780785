import { Component } from '@angular/core';


@Component({
  selector: 'rnpl-step-action-bar',
  templateUrl: './step-action-bar.component.html',
  styleUrls: ['./step-action-bar.component.scss']
})
export class StepActionBarComponent {

}
