import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';

import { getHeadNavigationMenuItems, NavItem } from './nav-items.config';
import { UserPermissionsService } from '../../../services/user-permissions.service';
import { SystemPreferencesPermissionsService } from '../../../services/system-preferences-permissions.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-head-navigation',
  templateUrl: './head-navigation.component.html',
  styleUrls: ['./head-navigation.component.scss']
})
export class HeadNavigationComponent implements OnChanges, OnDestroy {

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() public menuData: NavItem[] = [];
  @Input() public appNav: boolean;

  @Output()
  openMenu = new EventEmitter();

  constructor(
    public router: Router,
    public store: Store<AppState>,
    private userPermissionsService: UserPermissionsService,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
  ) {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.appNav) {
          this.getMenuData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('appNav')) {
      if (!this.appNav) {
        this.getMenuData();
      }
    }
  }

  public getMenuData(): void {
    this.menuData = getHeadNavigationMenuItems(
      this.userPermissionsService.canViewAccounting(),
      this.userPermissionsService.canViewAdministration(),
      this.userPermissionsService.canViewAnalytics(),
      this.userPermissionsService.canViewEcommerce(),
      this.userPermissionsService.canViewPartners(),
      this.userPermissionsService.canViewProducts(),
      this.userPermissionsService.canViewRetail(),
      this.userPermissionsService.canViewServices(),
      this.userPermissionsService.canViewTeam(),
      this.userPermissionsService.canViewWarehouse(),
      this.userPermissionsService.canViewWholesale(),
      this.systemPreferencesPermissionsService.wholesaleEnabled(),
      this.systemPreferencesPermissionsService.ecommerceEnabled(),
      this.systemPreferencesPermissionsService.servicesEnabled(),
      this.systemPreferencesPermissionsService.goodsProductTypeEnabled(),
      this.systemPreferencesPermissionsService.servicesProductTypeEnabled(),
      this.systemPreferencesPermissionsService.digitalProductTypeEnabled(),
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
    );
  }

  public openNavBar() {
    this.openMenu.emit(true);

    const overlay = document.querySelector<HTMLElement>('.cdk-overlay-container');
    overlay.style.zIndex = '1200';
  }

  public showItem(item: NavItem): boolean {
    if (item.hasOwnProperty('show')) {
      return item.show;
    }

    return true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
