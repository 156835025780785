import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { ExpansionPanelComponent } from './expansion-panel.component';


@NgModule({
  declarations: [
    ExpansionPanelComponent
  ],
  exports: [
    ExpansionPanelComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
})
export class ExpansionPanelModule { }
