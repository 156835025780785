import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { PurposeTypeEnum } from '../../../purchase-order/enums';
import { ToasterService } from 'common/src/modules/ui-components/toaster';

@Component({
  selector: 'rnpl-purpose-form',
  templateUrl: './purpose-form.component.html',
})
export class PurposeFormComponent implements OnChanges {

  @Input() control: FormControl = new FormControl();
  @Input() controlValue: PurposeTypeEnum;
  @Input() idKey: string;
  @Input() disabled: boolean;
  @Input() cantBeChanged = false;

  @Output() fieldChanged: EventEmitter<PurposeTypeEnum> = new EventEmitter<PurposeTypeEnum>();

  public purposeTypeEnum = PurposeTypeEnum;

  constructor(private toasterService: ToasterService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.hasOwnProperty('controlValue')) {
      this.control.patchValue(this.controlValue);
    }

    if (changes && changes.hasOwnProperty('disabled')) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  public updateField(fieldValue: PurposeTypeEnum): void {
    if (this.cantBeChanged) {
      this.control.patchValue(PurposeTypeEnum.INTERNAL_USAGE);
      this.toasterService.notify({type: 'error', message: 'GL_ACCOUNTS.CHANGE_PURPOSE_ERROR'});
      return;
    }

    this.fieldChanged.emit(fieldValue);
  }

}
