import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable()
export class FiltersService {
  public filtersData = {};

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params: Params) => {
      this.filtersData = {};

      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key]) {
          this.filtersData[key] = JSON.parse(params[key]);
        }
      }
    });
  }

  public getFilters(): any {
    return this.filtersData;
  }

}
