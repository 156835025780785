import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class HotjarService {
  addScript(): void {
    if (environment.production) {
      // Hotjar Tracking Code for runple.app
      (function(h, o, t, j, a, r) {
        (h as any).hj = (h as any).hj || function() { ((h as any).hj.q = (h as any).hj.q || []).push(arguments) };
        (h as any)._hjSettings= { hjid: 2539821, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.setAttribute('id', 'hjScript')
        r.async = 1;
        r.src = t + (h as any)._hjSettings.hjid + j + (h as any)._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }

  removeScript(): void {
    if (environment.production) {
      // remove Hotjar Tracking Code for runple.app
      const hjScript = document.getElementById('hjScript');
      if (hjScript) {
        document.getElementsByTagName('head')[0].removeChild(hjScript);
        (window as any).hj = null;
        (window as any)._hjSettings = null;
      }
    }
  }
}
