import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';
import { TranslateService } from '@ngx-translate/core';
import { get, isEqual } from 'lodash';

import { NavBarBtnModel, PaginationModel, UserModel } from 'common/src/models';
import { HrmService } from '../../hrm.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { TabUserList } from './user-list-tab';
import { generateTableColumns } from './user-list-columns';
import { CreateNewEmployeeModalComponent } from 'common/src/modules/modals/modals-hrm';
import { UserListTabs } from './enums/user-list-tabs.enum';
import { STATUS_KEY, TABS_CAN_ADD_EMPLOYEE } from './user-list.constants';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { ResponseList, RowNumberSettings, UserSorting } from '../../../shared/models/response';
import { employeeResponseHelper } from './helpers/response.helper';
import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import {
  CommonModalsActionsEnum,
  DangerModalComponent,
  InfoModalComponent,
  WarningModalComponent
} from 'common/src/modules/modals/modals-common';
import { ActionBarButtons, EMPTY_STATE_DATA, getTableSummaryBarItems, getTeamsListFilters } from './user-list.config';
import { TeamsState } from '../../store/reducers';
import { AppState } from '../../../store/state/app.state';
import { selectTeamsList } from '../../store/selectors';
import { DEFAULT_PAGINATION, DEFAULT_SORT_DIRECTION } from '../../../shared/constants';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TableSummaryBarItemModel } from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { getContentDispositionFileName } from 'common/src/modules/rnpl-common/helpers';
import { FileService } from 'common/src/services/file.service';
import { selectCompanyProfile, selectOnboarding } from '../../../administration/store/selectors';
import { CompanyProfile } from '../../../administration/models/company-profile.model';
import { OnboardingModel } from '../../../administration/models/onboarding.model';
import { AdministrationsApiService } from '../../../administration/services/administrations-api.service';
import { StatusTranslatePipe } from 'common/src/modules/rnpl-common';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { UserTableSettingsService } from '../../../shared/services';
import { FiltersGroupModel, FiltersService } from 'common/src/modules/filters';
import { SubscriptionPlanEnum } from 'common/src/modules/subscription-activation/subscription-activation.emum';
import { ActionButtonsService } from 'common/src/services/action-buttons.service';


@Component({
  selector: 'rnpl-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, OnDestroy {
  public onboarding: OnboardingModel;
  readonly activeStatus$: BehaviorSubject<UserListTabs> = new BehaviorSubject<UserListTabs>(UserListTabs.Active);
  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoadingSyncRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly canCreateEmployee$: Observable<boolean>;
  readonly usersList$: BehaviorSubject<UserModel[]> = new BehaviorSubject([]);
  private listData$: BehaviorSubject<TeamsState> = new BehaviorSubject(null);
  public sort: FilterModel = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION };
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  public emptyStateData = EMPTY_STATE_DATA;

  public tabs: Array<TabDefinitionModel> = TabUserList;
  public pagination: PaginationModel = DEFAULT_PAGINATION;
  public actionBarButtons: NavBarBtnModel[] = ActionBarButtons;

  public selectedRows = [];
  public columns: TableColumnModelExtended[];
  public customizedColumns: TableColumnModelExtended[] = [];
  public tableSummaryBarItems: TableSummaryBarItemModel[] = [];
  public filtersConfig: FiltersGroupModel[] = [];

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly exportCsvRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly updatedAt$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly createNewEmployeeRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @ViewChild('filters', {static: false}) filtersRef;

  constructor(
    private hrmService: HrmService,
    private administrationsApiService: AdministrationsApiService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private router: Router,
    private titleService: Title,
    private readonly store: Store<AppState>,
    private location: Location,
    private readonly translateService: TranslateService,
    private readonly statusTranslatePipe: StatusTranslatePipe,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private userTableSettingsService: UserTableSettingsService,
    private filtersService: FiltersService,
    private actionButtonsService: ActionButtonsService,
  ) {
    this.canCreateEmployee$ = this.activatedRoute.paramMap
      .pipe(
        filter((params: ParamMap) => params.has(STATUS_KEY)),
        map((params: ParamMap) => TABS_CAN_ADD_EMPLOYEE.includes(params.get(STATUS_KEY) as UserListTabs))
      );

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {

        if (!companyProfile.onboardingCompleted) {
          this.administrationsApiService.getOnboardingProcess()
            .pipe(takeUntil(this.destroy$))
            .subscribe();
        }
      });

    this.store.select(selectOnboarding)
      .pipe(takeUntil(this.destroy$))
      .subscribe((onboarding: OnboardingModel) => {
        this.onboarding = onboarding;
      });
  }

  ngOnInit(): void {
    this.setTitle();

    this.bindTeamsList();

    this.activatedRoute.paramMap.pipe(takeUntil(this.destroy$)).subscribe(
      (params: ParamMap) => {
        const pageFromRouting = Number(params.get('page'));

        if (this.activeStatus !== params.get(STATUS_KEY)) {
          this.sort = { nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION };
        }
        this.activeStatus$.next(params.get(STATUS_KEY) as UserListTabs);
        this.selectedRows = [];
        this.tableSummaryBarItems = [];
        this.columns = generateTableColumns(this.activeStatus);
        this.pagination = { ...this.pagination, page: pageFromRouting };

        if (this.filtersRef) {
          this.filtersRef.closeSidebar();
        }

        // const usersByCurrentPage = this.listData$.getValue()[this.activeStatus][pageFromRouting];
        //
        // if (usersByCurrentPage && usersByCurrentPage.data && isEqual(this.sort, usersByCurrentPage.sort)) {
        //   this.isLoading$.next(false);
        //   this.isLoadingSyncRequest$.next(false);
        //   this.pagination = usersByCurrentPage.pagination;
        //   this.sort = usersByCurrentPage.sort;
        //   this.tableSummaryBarItems = getTableSummaryBarItems(usersByCurrentPage.totals);
        //   this.usersList$.next(employeeResponseHelper(usersByCurrentPage.data, this.translateService));
        //   this.getEmployeeList(true, this.pagination.page, );
        //   this.cdr.detectChanges();
        // } else {
        //   this.pagination = { ...this.pagination, page: pageFromRouting };
        //   this.getEmployeeList();
        // }

        this.filtersConfig = getTeamsListFilters(
          this.hrmService.getRunpleIdsList(),
          this.activeStatus
        );
        this.getEmployeeList();
        this.getUserCounters();
        this.cdr.detectChanges();
      }
    );
  }

  public setTitle(): void {
    const activeStatus = this.translateService.instant(this.statusTranslatePipe.transform(this.activeStatus));
    this.titleService.setTitle(
      `${this.translateService.instant('APP.EMPLOYEES')}: ${activeStatus}`
    );
  }

  public getEmployeeList(preventLoading = false, page = this.pagination.page): void {
    this.selectedRows = [];
    this.isLoadingSyncRequest$.next(true);

    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    this.userTableSettingsService.getRowNumberSettings(DocumentTypesUppercaseEnum.USER)
      .pipe(
        tap((rowNumberSettings: RowNumberSettings) => {
          this.pagination = {...this.pagination , per_page: rowNumberSettings.number};
        }),
        switchMap(() => this.userTableSettingsService.getUserSorting(this.activeStatus, DocumentTypesUppercaseEnum.USER)),
        tap((sorting: UserSorting) => {
          this.sort = {
            nameColumn: sorting.sortBy || this.sort.nameColumn,
            value: sorting.direction || this.sort.value
          };
          const usersByCurrentPage = this.listData$.getValue()[this.activeStatus][page];
          if (
            usersByCurrentPage &&
            isEqual(this.sort, usersByCurrentPage.sort) &&
            usersByCurrentPage.pagination.per_page === this.pagination.per_page
          ) {
            this.pagination = {...usersByCurrentPage.pagination};
            this.sort = usersByCurrentPage.sort;
            this.tableSummaryBarItems = getTableSummaryBarItems(usersByCurrentPage.totals);
            this.usersList$.next(employeeResponseHelper(usersByCurrentPage.data, this.translateService));
            this.isLoading$.next(false);
          }
        }),
        switchMap(() => this.hrmService.getUsersV2(
          this.activeStatus,
          page,
          this.pagination.per_page,
          this.sort,
          this.filtersService.getFilters()
        )),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data: ResponseList<UserModel>) => {
          if (data.status && data.status !== this.activeStatus) { return; }

          this.usersList$.next(employeeResponseHelper(data.data, this.translateService));
          this.pagination = get(data, 'pagination', DEFAULT_PAGINATION);
          this.isLoading$.next(false);
          this.isLoadingSyncRequest$.next(false);

          this.setTitle();

          this.tableSummaryBarItems = getTableSummaryBarItems(data.totals);
          this.updatedAt$.next(new Date());

          // const url = `/team/employee/list/${this.activeStatus}/${this.pagination.page}`;
          // this.location.go(url); // update url without reloading
        },
        (error) => {
          this.updatedAt$.next(null);
          this.isLoading$.next(false);
          this.isLoadingSyncRequest$.next(false);
          this.displayMessage(error.error.message, 'error');
        }
      );
  }

  public bindTeamsList() {
    this.store.select(selectTeamsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((teamsList) => {
        this.listData$.next(teamsList);
      });
  }

  public getUserCounters() {
    this.hrmService.getUsersCounters()
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.tabs = this.tabs.map((t: TabDefinitionModel) => ({ ...t, count: response[t.tabName] }));
        this.cdr.detectChanges();
      });
  }

  public createNewEmployee() {
    if (this.createNewEmployeeRequest$.getValue()) { return; }
    if (this.actionButtonsService.isActionDisabledByPlan()) { return; }

    this.createNewEmployeeRequest$.next(true);

    const dialogRef = this.dialog.open(CreateNewEmployeeModalComponent, {disableClose: true,});

    dialogRef.afterClosed()
      .pipe(finalize(() => this.createNewEmployeeRequest$.next(false)))
      .subscribe(res => {
        if (res) {
          this.getEmployeeList();
        }
      });
  }

  public displayMessage(message: string, type: string = 'success') {
    this.toasterService.notify({ type, message });
  }

  public selectedRowsReciever(rows) {
    this.selectedRows = rows;
  }

  public onActionReceived(btnAction: string): void {
    if (this[btnAction]) {
      this[btnAction]();
    }
  }

  public onDeletePermanentlyUserClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETE_PERMANENTLY',
        message: this.translateService.instant('TEAM.DELETE_PERMANENTLY_USERS', {quantity: this.selectedRows.length}),
        confirmBtnText: 'BUTTON.DELETE_PERMANENTLY',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeletePermanentlyUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          const ids = this.selectedRows.map((user: UserModel) => user.id);
          this.hrmService.deleteUsers(ids)
            .pipe(
              finalize(() => this.selectedRows = []),
              takeUntil(this.destroy$)
            )
            .subscribe(() => {
              this.getEmployeeList(true);
            });
        }
      });
  }

  public onDeleteUserClick(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'MODAL.DELETE',
        message: this.translateService.instant('TEAM.DELETE_USERS', {quantity: this.selectedRows.length}),
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeleteUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.changeUsersStatus(UserListTabs.Deleted);
        }
      });
  }

  public onRestoreUserClick(): void {
    if (this.actionButtonsService.isActionDisabledByPlan()) { return; }

    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.RESTORE',
        message: this.translateService.instant('TEAM.RESTORE_USERS', {quantity: this.selectedRows.length}),
        confirmBtnText: 'BUTTON.RESTORE',
        confirmBtnIcon: 'checkmark',
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onRestoreUserClick')))
      .subscribe(response => {
      if (response === CommonModalsActionsEnum.CONFIRM) {
        this.changeUsersStatus(UserListTabs.Inactive);
      }
    });
  }

  public onDeactivateUserClick(): void {
    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.DEACTIVATE',
        message: this.translateService.instant('TEAM.DEACTIVATE_USERS', {quantity: this.selectedRows.length}),
        confirmBtnIcon: 'checkmark',
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onDeactivateUserClick')))
      .subscribe(response => {
        if (response === CommonModalsActionsEnum.CONFIRM) {
          this.changeUsersStatus(UserListTabs.Inactive);
        }
    });
  }

  public onActivateUserClick(): void {
    if (this.actionButtonsService.isActionDisabledByPlan()) { return; }

    const dialog = this.dialog.open(WarningModalComponent, {
      data: {
        title: 'MODAL.ACTIVATE',
        message: this.translateService.instant('TEAM.ACTIVATE_USERS', {quantity: this.selectedRows.length}),
        confirmBtnIcon: 'checkmark',
      }
    });

    dialog.afterClosed()
      .pipe(finalize(() => this.btnToClearLoadingStatus$.next('onActivateUserClick')))
      .subscribe(response => {
      if (response === CommonModalsActionsEnum.CONFIRM) {
        this.changeUsersStatus(UserListTabs.Active);
      }
    });
  }

  public changeUsersStatus(status: UserListTabs, skipWarnings = false, user_ids?): void {
    const ids = this.selectedRows.map((user: UserModel) => user.id);
    this.hrmService.changeUsersStatus({ user_ids: ids.length ? ids : user_ids, status, skipWarnings })
      .pipe(
        finalize(() => this.selectedRows = []),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getEmployeeList(true);
      }, error => {
        this.handlePopupErrors(error, status, ids);
      });
  }

  private handlePopupErrors(error: HttpErrorResponse, status: UserListTabs, user_ids?): void {
    switch (error.error.message) {
      default:
        this.displayMessage(error.error.message, 'error');
        break;
    }
  }

  public goToPage(page: number): void {
    const url = this.getNewPaginationUrl(this.router.url);

    this.pagination = { ...this.pagination, page: page + 1 };
    this.router.navigate([url, this.pagination.page]);
  }

  public getNewPaginationUrl(oldUrl: string): string {
    return oldUrl.split('/').slice(0, -1).join('/');
  }

  public rowClickReceiver(event): void {
    if (event.type === TableActivateTypes.Link) {
      this.router.navigate([`/team/employee-profile/${event.row.id}`]);
    }
  }

  public onExportCsvClick(): void {
    if (this.exportCsvRequest$.getValue()) { return; }
    this.exportCsvRequest$.next(true);

    this.hrmService.getPOListExport(this.activeStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileParams: FileUploadParams) => {
        this.downloadFile(fileParams.url);
      });
  }

  public downloadFile(url) {
    this.fileService.downloadFile(url)
      .pipe(
        finalize(() => { this.exportCsvRequest$.next(false); }),
        takeUntil(this.destroy$),
      )
      .subscribe((res: any) => {
        this.fileSaverService.save(
          res.body,
          getContentDispositionFileName(res.headers.get('Content-Disposition'))
        );
      });
  }

  public sortTable(e): void {
    let sortedProp = e.column.prop;

    if (sortedProp === 'documentLink') {
      sortedProp = 'runpleId';
    }

    if (sortedProp === 'nameData') {
      sortedProp = 'name';
    }

    this.sort = {nameColumn: sortedProp, value: e.newValue.toUpperCase()};
    this.saveUserSorting(sortedProp, e.newValue.toUpperCase());
  }

  public saveUserSorting(sortBy: string, direction: 'ASC'|'DESC'): void {
    const sorting = {
      sortBy,
      direction,
      type: DocumentTypesUppercaseEnum.USER,
      status: this.activeStatus
    }
    this.userTableSettingsService.saveUserSorting(sorting)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getEmployeeList(true));
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get activeStatus(): UserListTabs {
    return this.activeStatus$.getValue();
  }

  public get showSummaryBar(): boolean {
    return this.tableSummaryBarItems.length && this.activeStatus === UserListTabs.Active;
  }
  public hideSummaryBar(): boolean {
    return this.activeStatus !== UserListTabs.Active;
  }
}
