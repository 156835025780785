import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { isEqual } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ScheduledPaymentBillingScheduleModel, ScheduledPaymentModel } from 'projects/workspace/src/app/scheduled-payment/models';
import { ScheduledPaymentApiService } from 'projects/workspace/src/app/scheduled-payment/services/scheduled-payment-api.service';
import {
  ScheduledPaymentBillingFrequencyEnum,
  ScheduledPaymentDurationEnum
} from 'projects/workspace/src/app/scheduled-payment/enums';

@Component({
  selector: 'rnpl-scheduled-payment-schedule-form-modal',
  templateUrl: './scheduled-payment-schedule-form-modal.component.html',
})
export class ScheduledPaymentScheduleFormModalComponent extends BaseModalComponent implements OnInit {

  public form: FormGroup;
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public fb: FormBuilder,
    private readonly datePipe: DatePipe,
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<ScheduledPaymentScheduleFormModalComponent>,
    private readonly scheduledPaymentApiService: ScheduledPaymentApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      scheduledPayment: ScheduledPaymentModel;
      schedule: ScheduledPaymentBillingScheduleModel;
      hasPeriods: boolean;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.initForm();
    this.patchForm(this.data.schedule);
  }

  private initForm(): void {
    this.form = this.fb.group({
      schedule: this.fb.group({
        frequency: [null, [Validators.required]],
        periodFirstDate: [null, [Validators.required]],
        periodLastDate: [null, [Validators.required]],
        billingDate: [null, [Validators.required]],
        duration: [null, [Validators.required]],
        durationEndDate: [null, [Validators.required]],
      })
    }, {updateOn: 'blur'});
  }

  public updateField(fieldName: string, fieldValue: any): void {
    this.scheduledPaymentApiService.updateScheduledPaymentScheduleField(
      this.data.scheduledPayment.id,
      this.getFormattedFormValue(),
      fieldName,
      fieldValue
    )
      .subscribe((schedule: ScheduledPaymentBillingScheduleModel) => this.patchForm(schedule));
  }

  private patchForm(schedule: ScheduledPaymentBillingScheduleModel): void {
    this.frequencyControl.patchValue(schedule.frequency);
    this.durationControl.patchValue(schedule.duration);
    this.durationEndDateControl.patchValue(this.datePipe.transform(schedule.durationEndDate, "yyyy-MM-dd"));
    this.periodFirstDateControl.patchValue(this.datePipe.transform(schedule.periodFirstDate, "yyyy-MM-dd"));
    this.periodLastDateControl.patchValue(this.datePipe.transform(schedule.periodLastDate, "yyyy-MM-dd"));
    this.billingDateControl.patchValue(this.datePipe.transform(schedule.billingDate, "yyyy-MM-dd"));

    if (schedule.frequency !== ScheduledPaymentBillingFrequencyEnum.SINGLE) {
      this.periodLastDateControl.disable();
    } else {
      this.periodLastDateControl.enable();
    }

    if (schedule.duration !== ScheduledPaymentDurationEnum.CUSTOM) {
      this.durationEndDateControl.disable();
    } else {
      this.durationEndDateControl.enable();
    }
  }

  public getFormattedDateControlValue(control: FormControl): string {
    return control.value
      ? new Date(control.value).toLocaleDateString('fr-CA')
      : null;
  }

  public getFormattedFormValue(): ScheduledPaymentBillingScheduleModel  {
    return {
      frequency: this.frequencyControl.value,
      duration: this.durationControl.value,
      periodFirstDate: this.getFormattedDateControlValue(this.periodFirstDateControl) as any,
      periodLastDate: this.getFormattedDateControlValue(this.periodLastDateControl) as any,
      billingDate: this.getFormattedDateControlValue(this.billingDateControl) as any,
      durationEndDate: this.getFormattedDateControlValue(this.durationEndDateControl) as any,
    }
  }

  public submit(isFormValid: boolean): void {
    if (!isFormValid || this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    this.scheduledPaymentApiService.updateScheduledPaymentField(
      this.data.scheduledPayment.id,
      'billing.schedule',
      this.getFormattedFormValue()
    )
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((scheduledPayment: ScheduledPaymentModel) => {
        if (!isEqual(this.data.schedule, scheduledPayment.billing.schedule)) {
          this.toasterService.notify({type: 'success', message: 'SCHEDULED_PAYMENT.UPDATE_SCHEDULE_SUCCESS'});
        }
        this.dialogRef.close();
      });
  }

  public get scheduleFormGroup(): FormGroup { return this.form.get('schedule') as FormGroup; }
  public get frequencyControl(): FormControl { return this.form.get('schedule').get('frequency') as FormControl; }
  public get periodFirstDateControl(): FormControl { return this.form.get('schedule').get('periodFirstDate') as FormControl; }
  public get periodLastDateControl(): FormControl { return this.form.get('schedule').get('periodLastDate') as FormControl; }
  public get billingDateControl(): FormControl { return this.form.get('schedule').get('billingDate') as FormControl; }
  public get durationControl(): FormControl { return this.form.get('schedule').get('duration') as FormControl; }
  public get durationEndDateControl(): FormControl { return this.form.get('schedule').get('durationEndDate') as FormControl; }

}
