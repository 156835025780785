import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ImageModel } from '../../../../models';
import {
  EcoSettingsBlockModel,
  EcoSettingsBlockUpdateModel
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { EcoSettingsBlockTypeEnum } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/enums';

@Component({
  selector: 'rnpl-add-banner-modal',
  templateUrl: './add-banner-modal.component.html',
})
export class AddBannerModalComponent extends BaseModalComponent implements OnInit {

  public images: ImageModel[] = [];
  public form: FormGroup;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private fb: FormBuilder,
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<AddBannerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      block?: EcoSettingsBlockModel;
      // sliderId?: number;
      addOnBlock?: boolean;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.initForm();
    if (this.data && this.data.block && this.data.block.container) {
      this.form.patchValue(this.data.block.container);

      if (this.data.block.container.image) {
        this.images.push(this.data.block.container.image);
      }
    }
  }

  public initForm(): void {
    this.form = this.fb.group({
      title: [],
      description: [],
      buttonLabel: [],
      url: [],
      backgroundColor: [null, [Validators.minLength(7), Validators.maxLength(7)]],
      displayOnWebSite: [false],
      blockType: [EcoSettingsBlockTypeEnum.BANNER],
      addOnBlock: [false],
    });
  }

  public setProductImages(images): void {
    this.images = images;
  }

  public addBanner(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity()

    if (this.form.invalid) {
      return;
    }

    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    let submitRequest$: Observable<any>;
    const data: EcoSettingsBlockUpdateModel = {
      ...this.form.getRawValue(),
      imageId: null
    };

    if (this.data && this.data.addOnBlock) {
      data.addOnBlock = true;
    }

    if (this.images && this.images.length) {
      data.imageId = +this.images[0].id || +this.images[0].apiModel.id;
    }

    if (this.data && this.data.block && this.data.block.id) {
      submitRequest$ = this.ecoSettingsApiService.ecoSettingsUpdateBlock(this.data.block.id, data);
    } else {
      submitRequest$ = this.ecoSettingsApiService.ecoSettingsCreateBlock(data);
    }

    submitRequest$
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((res) => this.dialogRef.close(res));
  }

  public getUploadImageLink(): string {
    return this.ecoSettingsApiService.getEcoUploadImageLink();
  }

  public get titleControl(): FormControl { return this.form.get('title') as FormControl; }
  public get descriptionControl(): FormControl { return this.form.get('description') as FormControl; }
  public get buttonLabelControl(): FormControl { return this.form.get('buttonLabel') as FormControl; }
  public get urlControl(): FormControl { return this.form.get('url') as FormControl; }
  public get backgroundColorControl(): FormControl { return this.form.get('backgroundColor') as FormControl; }
  public get displayOnWebSiteControl(): FormControl { return this.form.get('displayOnWebSite') as FormControl; }

}
