import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { PurchaseOrderApiService } from '../services/purchase-order-api.service';
import { PurchaseOrder } from '../models';
import { UpdatePOCurrentState, UpdateShouldRefreshEntity } from '../store/actions/po.actions';
import { AppState } from '../../store/state/app.state';
import { UIStatesEnum } from 'common/src/models';
import { selectPurchaseOrder } from '../store/selectors';
import { PoTabs } from '../enums';

@Injectable()
export class IsPurchaseOrder implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly poApi: PurchaseOrderApiService,
    private readonly store: Store<AppState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const id: number = Number(route.params.id);

    return this.store.select(selectPurchaseOrder).pipe(
      first(),
      switchMap((purchaseOrder: PurchaseOrder) => {
        const isEntityExistAndSameId = purchaseOrder && purchaseOrder.id === id;
        this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: isEntityExistAndSameId }));
        return (isEntityExistAndSameId) ? of(purchaseOrder) : this.poApi.getPO(id);
      }),
      catchError(() => {
        this.router.navigate(['/trade/purchase-order']);
        return of(false);
      }),
      switchMap((purchaseOrder: PurchaseOrder) => {
        this.store.dispatch(UpdatePOCurrentState({currentState: this.getPoState(purchaseOrder)}));
        return of(true);
      })
    );
  }

  private getPoState(po: PurchaseOrder): UIStatesEnum {
    return po.runpleId || po.status === PoTabs.Template
      ? UIStatesEnum.VIEW
      : UIStatesEnum.CREATE;
  }
}
