import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextMenuModule } from 'ngx-contextmenu';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { StartScreenPageComponent } from './components/start-screen-page/start-screen-page.component';
import { RnplCommonModule } from '../rnpl-common';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { FolderComponent } from './components/folder/folder.component';
import { PositionCardModule } from '../position-card/position-card.module';
import { PageContextMenuComponent } from './components/page-context-menu/page-context-menu.component';
import { QuickOnboardingModule } from '../quick-onboarding/quick-onboarding.module';



@NgModule({
  declarations: [StartScreenPageComponent, FolderComponent, PageContextMenuComponent],
  exports: [
    StartScreenPageComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    UIComponentsModule,
    DragDropModule,
    RouterModule,
    MatMenuModule,
    TranslateModule,
    PositionCardModule,
    ContextMenuModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
    QuickOnboardingModule
  ]
})
export class StartScreenPageModule { }
