import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';

import { PaymentTermsEnum } from 'common/src/models';



@Component({
  selector: 'rnpl-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit, OnDestroy {

  public invoices = [{
    processTitle: 'FORM.OPEN_INVOICES',
    count: 0,
    inAction: true,
    actionTitle: 'BUTTON.ADD_INVOICE',
  },
  {
    processTitle: 'FORM.PAID_INVOICES',
    count: 0,
  }];

  public readonly paymentTerms = PaymentTermsEnum;
  public form: FormGroup;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private readonly fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      payment_terms: [{value: this.paymentTerms.PRE_PAYMENT, disabled: true}, [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
