import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccordionItemDefinitionModel } from './accordion-item-definition.model';

@Component({
  selector: 'rnpl-accordion-tree',
  templateUrl: 'accordion-tree.component.html',
  styleUrls: ['accordion-tree.component.scss']
})
export class AccordionTreeComponent implements OnInit {

  @Input()
  public label: string = '';

  @Input()
  public items: Array<AccordionItemDefinitionModel>;

  @Input()
  public expanded: boolean = false;

  @Output()
  itemToggled: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Open/close toggle-accordion
   */
  public toggleAccordion (): void {
    this.expanded = !this.expanded;
  }

  public toggle(item, event) {
    this.itemToggled.emit({...item, checked: event.target.checked});
  }

}
