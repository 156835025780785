import { get } from 'lodash';

import { TransactionTypeEnum } from '../modules/transactions/enums';
import { QuantityCheckMethodEnum } from 'common/src/modules/modals/modals-warehouse/pack-up-modal/enums/quantity-check-method.enum';
import { DeliveryNote } from '../../delivery-note/models/delivery-note.model';
import { purchasesOrderHints } from '../../purchase-order/purchases-order-hints.config';
import { BinLocationsTabsEnum } from '../enums';
import { IncomingDeliveryListTabsEnum } from '../modules/incoming-delivery/enums';
import { DeliveryNoteListTabsEnum } from '../../delivery-note/enums';
import { DeliveryTypesEnum, UIStatesEnum } from 'common/src/models';

export function getBinLocationHintsList(isEdit: boolean, activeTab?: BinLocationsTabsEnum | string) {
  const hintsList = {
    ['warehouse_management_preferences_1']: {
      text: 'MEGA_HINT.PREFERENCES.HINT_1',
      attachTo: {
        element: '#inventoryControlMethodField',
        on: 'bottom'
      },
    },
  }

  const saveTimerHint = {
    ['save_timer_management']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_8',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const buttonsHints = {
    ['create_entity_button_warehouse_management']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_2',
      attachTo: {
        element: '#createBinLocationBtn',
        on: 'bottom'
      },
    },
    ['export_button_warehouse_management']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_3',
      attachTo: {
        element: '#exportBinLocationBtn',
        on: 'bottom'
      },
    },
  }

  const deletedTabHint = {
    ['tab_delete_warehouse_management']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_6',
      attachTo: {
        element: '#deleteTab',
        on: 'bottom'
      },
    },
  }

  const returnTabHint = {
    ['warehouse_management_bin_locations_2']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_5',
      attachTo: {
        element: '#returnsStockTab',
        on: 'bottom'
      },
    },
  }

  const generalTabHint = {
    ['warehouse_management_bin_locations_1']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_1',
      attachTo: {
        element: '#generalStockTab',
        on: 'bottom'
      },
    },
  }

  const doneEditBtnHint = {
    ['done_button_warehouse_management']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_4',
      attachTo: {
        element: '#doneBinLocationBtn',
        on: 'bottom'
      },
    },
  }

  const editBtnHint = {
    ['edit_entity_button_warehouse_management']: {
      text: 'MEGA_HINT.BIN_LOCATION.HINT_7',
      attachTo: {
        element: '#editBinLocationBtn',
        on: 'bottom'
      },
    },
  }

  if (isEdit) {
    Object.assign(hintsList, doneEditBtnHint);
    Object.assign(hintsList, saveTimerHint);
  } else {
    Object.assign(hintsList, editBtnHint);
  }
  if (activeTab === BinLocationsTabsEnum.DELETED) {
    Object.assign(hintsList, deletedTabHint);
  }
  if (activeTab === BinLocationsTabsEnum.RETURNS_STOCK) {
    Object.assign(hintsList, returnTabHint);
  }
  if (activeTab === BinLocationsTabsEnum.GENERAL_STOCK) {
    Object.assign(hintsList, generalTabHint);
  }
  if (activeTab !== BinLocationsTabsEnum.DELETED) {
    Object.assign(hintsList, buttonsHints);
  }

  return hintsList;

}

export const TransactionsHintsList = {
  ['transactions_list_1']: {
    text: 'MEGA_HINT.TRANSACTIONS.HINT_1',
    attachTo: {
      element: '#transactionsListTitle',
      on: 'bottom'
    },
  },
  ['export_button_transactions']: {
    text: 'MEGA_HINT.TRANSACTIONS.HINT_2',
    attachTo: {
      element: '#exportTransactionsBtn',
      on: 'bottom'
    },
  },
  ['transactions_list_2']: {
    text: 'MEGA_HINT.TRANSACTIONS.HINT_3',
    attachTo: {
      element: '#createTransactionBtn',
      on: 'bottom'
    },
  },
  ['filter_button_transactions']: {
    text: 'MEGA_HINT.TRANSACTIONS.HINT_4',
    attachTo: {
      element: '#filterTransactionsBtn',
      on: 'bottom'
    },
  },
}

export function stockStatusHintsList(
  activeTabIsReturnedStock: boolean,
  hasStockStatusList?: boolean,
) {
  const hintsList = {
    ['stock_status_view_3']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_3',
      attachTo: {
        element: '#columnSettingsBtn',
        on: 'bottom'
      },
    },
    ['stock_status_view_6']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_6',
      attachTo: {
        element: '#totalRowsProduct',
        on: 'bottom'
      },
    },
    ['export_button_stock_status']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_8',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
    },
  }

  const generalStockTab = {
    ['stock_status_view_7']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_7',
      attachTo: {
        element: '#totalRowStockValuation',
        on: 'bottom'
      },
    },
    ['filter_button_stock_status']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_9',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
  }

  const columnHints = {
    ['stock_status_view_1']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_1',
      attachTo: {
        element: '#onStockColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
    },
    ['stock_status_view_2']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_2',
      attachTo: {
        element: '#soldColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
    },
    ['stock_status_view_4']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_4',
      attachTo: {
        element: '#orderedColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
    },
    ['stock_status_view_5']: {
      text: 'MEGA_HINT.STOCK_STATUS.HINT_5',
      attachTo: {
        element: '#availableColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
    },
  }

  if (!activeTabIsReturnedStock) {
    Object.assign(hintsList, generalStockTab);
  }

  if (hasStockStatusList) {
    Object.assign(hintsList, columnHints);
  }

  return hintsList

}

export function newTransactionsHints(transactionsType: TransactionTypeEnum) {

  const stockTransfer = {
    ['transactions_create_1']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_5',
      attachTo: {
        element: '#transactionType',
        on: 'bottom'
      },
      classes: 'from-modal',
      scrollTo: false,
        popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 30] }}]
    },
    },
    ['transactions_create_2']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_6',
      attachTo: {
        element: '#createTransactionModalBtn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      classes: 'from-modal',
      scrollTo: false,
    }
  };

  const bookProduct = {
    ['transactions_create_3']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_7',
      attachTo: {
        element: '#transactionType',
        on: 'top'
      },
      classes: 'from-modal',
      scrollTo: false,
      popperOptions : {
       modifiers : [{ name: 'offset', options: { offset : [0, 30] }}]
      },
    },
    ['transactions_create_4']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_8',
      attachTo: {
        element: '#createTransactionModalBtn',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      classes: 'from-modal break-spaces',
      scrollTo: false,
    },
  };

  const writeOff = {
    ['transactions_create_5']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_9',
      attachTo: {
        element: '#transactionType',
        on: 'bottom'
      },
      classes: 'from-modal',
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 30] }}]
      },
    },
    ['transactions_create_6']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_8',
      attachTo: {
        element: '#createTransactionModalBtn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      classes: 'from-modal',
      scrollTo: false,
    },
  };

  if (transactionsType === TransactionTypeEnum.STOCK_TRANSFER) {
    return stockTransfer;
  }
  if (transactionsType === TransactionTypeEnum.BOOK_PRODUCT) {
    return bookProduct;
  }
  if (transactionsType === TransactionTypeEnum.WRITE_OFF_PRODUCT) {
    return writeOff;
  }
}

export function incomingDeliveryHints(activeTab?: IncomingDeliveryListTabsEnum | string, hasDocuments?: boolean) {
  const hintsList = {
    ['filter_button_incoming_deliveries']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_6',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const pendingTabHints = {
    ['incoming_deliveries_tab_pending_1']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_1',
      attachTo: {
        element: '#pendingTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const receivedTabHints = {
    ['incoming_deliveries_tab_received_1']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_7',
      attachTo: {
        element: '#receivedTab',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
    ['incoming_deliveries_tab_received_2']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_9',
      attachTo: {
        element: '#employeeColumnModal',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  }

  const exportBtnHint = {
    ['export_button_incoming_deliveries']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_5',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const columnsHints = {
    ['incoming_deliveries_tab_pending_3']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_4',
      attachTo: {
        element: '#receiptProductBtnColumn',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
    ['incoming_deliveries_tab_pending_2']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_3',
      attachTo: {
        element: '#incomingTotalRow',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      scrollTo: false,
    },
    ['total_row_incoming_deliveries']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_2',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'top'
      },
      scrollTo: false,
    },
  }

  const receivedColumnsHints = {
    ['incoming_deliveries_tab_received_3']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_10',
      attachTo: {
        element: '#warehousingColumnBtn',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
    ['incoming_deliveries_tab_received_4']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_11',
      attachTo: {
        element: '#bookBtn',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
  }

  const warehousedColumnHints = {
    ['incoming_deliveries_tab_warehoused_1']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_12',
      attachTo: {
        element: '#binLocationColumn',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
    ['incoming_deliveries_tab_warehoused_2']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_13',
      attachTo: {
        element: '#bookBtn',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
    ['incoming_deliveries_tab_warehoused_3']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_8',
      attachTo: {
        element: '#employeeColumnModal',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  }

  if (activeTab === IncomingDeliveryListTabsEnum.PENDING) {
    Object.assign(hintsList, pendingTabHints);
  }

  if (activeTab === IncomingDeliveryListTabsEnum.RECEIVED) {
    Object.assign(hintsList, receivedTabHints);

    if (hasDocuments) {
      Object.assign(hintsList, receivedColumnsHints);
    }
  }

  if (activeTab === IncomingDeliveryListTabsEnum.WAREHOUSED && hasDocuments) {
    Object.assign(hintsList, warehousedColumnHints);
  }

  if (activeTab === IncomingDeliveryListTabsEnum.PENDING && hasDocuments) {
    Object.assign(hintsList, columnsHints);
  }


  if (hasDocuments) {
    Object.assign(hintsList, exportBtnHint);
  }

  return hintsList;

}

export function receiptProductsHints(
  receiptAndWarehouse: boolean,
  fromPo?: boolean,
  hasColumns?: boolean,
  displayBinLocationColumnHint?: boolean
) {

  if (fromPo) {
    return purchasesOrderHints(
      null,
      null,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      receiptAndWarehouse
    );
  }

  const hintList = {
    ['incoming_deliveries_product_receipt_mega']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_17',
      attachTo: {
        element: '#productReceiptModal',
        on: 'bottom'
      },
      classes: 'from-modal ml-8',
    },
  }

  const binLocationModalsColumnHint = {
    ['incoming_deliveries_product_receipt_1']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_13',
      attachTo: {
        element: '#binLocationModalsColumn',
        on: 'bottom'
      },
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 30] }}]
      },
    },
  }

  const receiptAndWarehouseBtnHint = {
    ['incoming_deliveries_product_receipt_2']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_14',
      attachTo: {
        element: '#receiptAndWarehouseBtn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      classes: 'from-modal',
      scrollTo: false,
    }
  }

  const columnHints = {
    ['incoming_deliveries_product_receipt_3']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_11',
      attachTo: {
        element: '#batchColumnModal',
        on: 'bottom'
      },
      classes: 'from-modal',
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 30] }}]
      },
    },
    ['incoming_deliveries_product_receipt_4']: {
      text: 'MEGA_HINT.TRANSACTIONS.HINT_12',
      attachTo: {
        element: '#orderedColumnModal',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      classes: 'from-modal',
      scrollTo: false,
    }
  }

  const orderedColumnHint = {
    ['incoming_deliveries_product_receipt_5']: {
      text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_18',
      attachTo: {
        element: '#orderedColumnModal',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
      classes: 'from-modal',
      scrollTo: false,
    }
  }

  if (receiptAndWarehouse) {
    Object.assign(hintList, receiptAndWarehouseBtnHint);
    Object.assign(hintList, orderedColumnHint);
  } else {
    if (hasColumns) { Object.assign(hintList, columnHints); }
  }

  if (hasColumns && displayBinLocationColumnHint) {
   Object.assign(hintList, binLocationModalsColumnHint);
  }

  return hintList;
}

export const WarehousingHints = {
  ['incoming_deliveries_product_warehousing_1']: {
    text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_14',
    attachTo: {
      element: '#receivedColumnModal',
      on: 'bottom'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
    },
    scrollTo: false,
    classes: 'from-modal',
  },
  ['incoming_deliveries_product_warehousing_2']: {
    text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_15',
    attachTo: {
      element: '#binLocationColumnModal',
      on: 'bottom'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
    },
    classes: 'from-modal',
  },
  ['incoming_deliveries_product_warehousing_3']: {
    text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_16',
    attachTo: {
      element: '#warehouseColumnModal',
      on: 'bottom'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
    },
    scrollTo: false,
    classes: 'from-modal',
  },
  ['incoming_deliveries_product_warehousing_4']: {
    text: 'MEGA_HINT.INCOMING_DELIVERY.HINT_17',
    attachTo: {
      element: '#warehouseButton',
      on: 'bottom'
    },
    scrollTo: false,
    classes: 'from-modal',
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
    },
  },
}

export function getOutgoingDeliveryViewHints(
  isView: boolean,
  showPuckUpBtn?: boolean,
  deliveryNote?: DeliveryNote,
  currentState?: UIStatesEnum,
  hasDocuments?: boolean,
  activeTab?: DeliveryNoteListTabsEnum | string,
  isTableView?: boolean,
) {

  const isViewState = currentState === UIStatesEnum.VIEW;
  const isCreatedState = currentState === UIStatesEnum.CREATE;
  const isPackingState = currentState === UIStatesEnum.PACKING;

  const isDraftStatus = activeTab === DeliveryNoteListTabsEnum.DRAFT;
  const isOpenStatus = activeTab === DeliveryNoteListTabsEnum.OPEN;
  const isPackingStatus = activeTab === DeliveryNoteListTabsEnum.PACKING;
  const isPackedUpStatus = activeTab === DeliveryNoteListTabsEnum.PACKED_UP;
  const isCanceledStatus = activeTab === DeliveryNoteListTabsEnum.CANCELED;
  const isDeletedStatus = activeTab === DeliveryNoteListTabsEnum.DELETED;

  const hintsList = {
    ['outgoing_deliveries_tab_open_4']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_4',
      attachTo: {
        element: '#createDeliveryNoteBtn',
        on: 'bottom'
      },
    },

    ['outgoing_deliveries_tab_open_7']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_7',
      attachTo: {
        element: '#ahippedTab',
        on: 'bottom'
      },
      popperOptions: {
        modifiers: [{name: 'offset', options: {offset: [0, 20]}}]
      },
    },

    ['outgoing_deliveries_view_draft_2']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_10',
      attachTo: {
        element: '#preferencesEmailSendFields',
        on: 'top'
      },
    },
    ['outgoing_deliveries_view_draft_3']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_11',
      attachTo: {
        element: '#deliveryRemark',
        on: 'top'
      },
    },
    ['export_button_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_19',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },

    ['filter_button_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_47',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
    ['outgoing_deliveries_view_packing_4']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_10',
      attachTo: {
        element: '#preferencesEmailSendFields',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 10] }}]
      },
    },
  }

  const columnHints = {
    ['outgoing_deliveries_tab_open_2']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_49',
      attachTo: {
        element: '#deliveredColumn',
        on: 'bottom'
      },
      popperOptions: {
        modifiers: [{name: 'offset', options: {offset: [0, 60]}}]
      },
    },
  }

  const deliveryServicesHint = {
    ['outgoing_deliveries_view_packing_3']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_25',
      attachTo: {
        element: '#deliveryServiceFields',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const fieldValidHint = {
    ['blue_tab_validation_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_17',
      attachTo: {
        element: '#customerField',
        on: 'bottom'
      },
    },
  }

  const printableRemarkHint = {
    ['outgoing_deliveries_view_packing_5']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_11',
      attachTo: {
        element: '#printable_remark',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 10] }}]
      },
    },
  }

  const notViewStateHints = {
    ['create_positions_button_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_21',
      attachTo: {
        element: '#packUpProducts',
        on: 'bottom'
      },
    },
  }

  const createStateHints = {
    ['sidebar_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_15',
      attachTo: {
        element: '#sidebarSteps',
        on: 'left'
      },
    },
  }

  const linkedBtnHint = {
    ['outgoing_deliveries_view_draft_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_9',
      attachTo: {
        element: '#linkButton',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  }

  const openTabHint = {
    ['outgoing_deliveries_tab_open_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_1',
      attachTo: {
        element: '#openTabDeliveryNote',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const packingTabHint = {
    ['outgoing_deliveries_tab_packing_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_43',
      attachTo: {
        element: '#packingTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const puckUpBtnHint = {
    ['outgoing_deliveries_view_packing_2']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_27',
      attachTo: {
        element: '#createBtn',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  }

  const packingStateHint = {
    ['outgoing_deliveries_view_packing_13']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_53',
      attachTo: {
        element: '#unpackBtnColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, isTableView ? 80 : 17] }}]
      },
    },
    ['outgoing_deliveries_view_packing_14']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_54',
      attachTo: {
        element: '#packUpMainBtn',
        on: 'top'
      },
    },
  }

  const packingLineHint = {
    ['outgoing_deliveries_view_packing_6']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_52',
      attachTo: {
        element: '#packingStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  }

  const packedUpTabHint = {
    ['outgoing_deliveries_tab_open_6']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_6',
      attachTo: {
        element: '#packedTab',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions: {
        modifiers: [{name: 'offset', options: {offset: [0, 20]}}]
      },
    },
    ['outgoing_deliveries_tab_packed_up_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_6',
      attachTo: {
        element: '#packedTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const unpackBtnSuccessHint = {
    ['outgoing_deliveries_view_packed_up_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_14',
      attachTo: {
        element: '#unpackBtnSuccess',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }
  const packMultiBtnHint = {
    ['outgoing_deliveries_view_packing_20']: {
      text: 'MEGA_HINT.OFFERS.HINT_12',
      attachTo: {
        element: '#unpackBtnSuccess',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const shippedTabHint = {
    ['outgoing_deliveries_tab_shipped_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_7',
      attachTo: {
        element: '#shippedTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const canceledTabHint = {
    ['outgoing_deliveries_tab_open_8']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_8',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions: {
        modifiers: [{name: 'offset', options: {offset: [0, 20]}}]
      },
    },
    ['outgoing_deliveries_tab_canceled_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_8',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const deletedTabHint = {
    ['tab_delete_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_20',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }


  const linkBtnHint = {
    ['outgoing_deliveries_view_packing_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_9',
      attachTo: {
        element: '#linkButton',
        on: 'bottom'
      },
    },
  }

  const packedProductBtnHint = {
    ['outgoing_deliveries_view_open_3']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_55',
      attachTo: {
        element: '#packUpProducts',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  }

  const generatePickListBtn = {
    ['outgoing_deliveries_tab_open_3']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_3',
      attachTo: {
        element: '#generatePickListBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const tableSummaryHint = {
    ['total_row_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_48',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'top'
      },
      scrollTo: false,
    },
  }

  const rmaLinkedHint = {
    ['outgoing_deliveries_view_open_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_12',
      attachTo: {
        element: '#rmaSteps',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const generatePickListBtnView = {
    ['outgoing_deliveries_view_open_2']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_13',
      attachTo: {
        element: '#generatePickListBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const cancelBtnHint = {
    ['cancel_entity_button_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_22',
      attachTo: {
        element: '#cancelBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const shippedBtn = {
    ['outgoing_deliveries_view_packed_up_2']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_50',
      attachTo: {
        element: '#shipBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const exportViewBtnHint = {
    ['export_send_button_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_19',
      attachTo: {
        element: '#exportBtnView',
        on: 'bottom'
      },
    },
  }

  const startPackingBtnHint = {
    ['outgoing_deliveries_view_open_4']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_55',
      attachTo: {
        element: '#startPackingBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const linkedSoHint = {
    ['linked_document_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_24',
      attachTo: {
        element: '#salesOrderStep',
        on: 'bottom'
      },
      scrollTo: false,
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const savedInfoHint = {
    ['save_timer_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_56',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const checkEmailHint = {
    ['email_indicator_outgoing_deliveries']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_57',
      attachTo: {
        element: '#emailSendCheckedField',
        on: 'bottom'
      },
    },
  }

  if (isView) {
    if (!deliveryNote.isLinkedSo) {
      Object.assign(hintsList, linkedBtnHint);
    }

    if (deliveryNote.isLinkedSo) {
      Object.assign(hintsList, linkedSoHint);
    }

    if (!!deliveryNote.era) {
      Object.assign(hintsList, rmaLinkedHint);
    }

    if (showPuckUpBtn) {
      Object.assign(hintsList, packedProductBtnHint);
    }

    if (!deliveryNote.partner.id && !isViewState) {
      Object.assign(hintsList, fieldValidHint);
    }

    if (!!deliveryNote.salesOrder && isPackingStatus) {
      Object.assign(hintsList, packingLineHint);
    }
  }

  switch (activeTab) {
    case DeliveryNoteListTabsEnum.OPEN:
      Object.assign(hintsList, openTabHint);
      Object.assign(hintsList, generatePickListBtn);

      if (currentState === UIStatesEnum.VIEW) {
        Object.assign(hintsList, generatePickListBtnView);
        Object.assign(hintsList, startPackingBtnHint);
      }

      break;

    case DeliveryNoteListTabsEnum.PACKING:
      Object.assign(hintsList, packingTabHint);
      break;

    case DeliveryNoteListTabsEnum.PACKED_UP:
      Object.assign(hintsList, packedUpTabHint);

      if (currentState === UIStatesEnum.VIEW) { Object.assign(hintsList, shippedBtn); }
      break;

    case DeliveryNoteListTabsEnum.SHIPPED:
      Object.assign(hintsList, shippedTabHint);
      break;

    case DeliveryNoteListTabsEnum.CANCELED:
      Object.assign(hintsList, canceledTabHint);
      break;

    case DeliveryNoteListTabsEnum.DELETED:
      Object.assign(hintsList, deletedTabHint);
      break;
  }

  if (!isDeletedStatus && !isCanceledStatus && hasDocuments) {
    Object.assign(hintsList, tableSummaryHint);
  }
  if ((isOpenStatus|| isDraftStatus) && !get(deliveryNote, 'linkedDocumentId', null)) {
    Object.assign(hintsList, linkBtnHint);
  }

  if (
    !isCanceledStatus &&
    !isDeletedStatus &&
    !isDraftStatus &&
    isViewState
  ) {
    Object.assign(hintsList, exportViewBtnHint);
  }

  if (isPackingState) {
    Object.assign(hintsList, packingStateHint);
  }

  if (!get(deliveryNote, 'isLinkedSo') && !isPackingState && isCreatedState) {
    Object.assign(hintsList, puckUpBtnHint);
  }

  if ((isPackingStatus || isOpenStatus) && isViewState) {
    Object.assign(hintsList, cancelBtnHint);
  }
  if (isPackedUpStatus && isViewState) {
    Object.assign(hintsList, unpackBtnSuccessHint);
  }
  if (isPackingStatus && isViewState) {
    Object.assign(hintsList, packMultiBtnHint);
  }

  if (isCreatedState) {
    Object.assign(hintsList, createStateHints)
    Object.assign(hintsList, printableRemarkHint)
  }
  if (!isViewState && !get(deliveryNote, 'isLinkedSo') && !isPackingState) {
    Object.assign(hintsList, notViewStateHints)
  }
  if (hasDocuments) {
    Object.assign(hintsList, columnHints)
  }
  if (get(deliveryNote, 'deliveryInformation.deliveryType') !== DeliveryTypesEnum.PICK_UP) {
    Object.assign(hintsList, deliveryServicesHint);
  }
  if (!isViewState) {
    Object.assign(hintsList, savedInfoHint);
  }
  if (!isCreatedState) {
    Object.assign(hintsList, checkEmailHint);
  }

  return hintsList;
}

export function getPackUpHints(quantityMethod: QuantityCheckMethodEnum, selectProduct: boolean, isLinkedSo: boolean) {
  const manualHintsList = {
    ['outgoing_deliveries_goods_packing_1']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_29',
      attachTo: {
        element: '#quantityMethods',
        on: 'top'
      },
      scrollTo: false,
      classes: 'from-modal',
    },
    ['outgoing_deliveries_goods_packing_2']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_30',
      attachTo: {
        element: '#manual',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal ml-4',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_3']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_31',
      attachTo: {
        element: '#barcode',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal ml-4',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_4']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_32',
      attachTo: {
        element: '#serial_number',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal ml-4',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_5']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_33',
      attachTo: {
        element: '#productsList',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_7']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_34',
      attachTo: {
        element: '#isContinuousPacking',
        on: 'top'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_8']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_35',
      attachTo: {
        element: '#confirmPackedUpBtn',
        on: 'top'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  };

  const productsPackedUpHint = {
    ['outgoing_deliveries_goods_packing_6']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_36',
      attachTo: {
        element: '#productsPackedUpLine',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  };

  // const barcodeHints = {
  //   ['outgoing_deliveries_goods_packing_9']: {
  //     text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_37',
  //     attachTo: {
  //       element: '#is_product_without_barcode',
  //       on: 'bottom'
  //     },
  //     scrollTo: false,
  //     classes: 'from-modal',
  //     popperOptions : {
  //       modifiers : [{ name: 'offset', options: { offset : [0, 32] }}]
  //     },
  //   },
  // };

  const productsHints = {
    ['outgoing_deliveries_goods_packing_10']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_38',
      attachTo: {
        element: '#bookBtn',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_11']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_39',
      attachTo: {
        element: '#bookBtn',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_12']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_40',
      attachTo: {
        element: '#bookBtn',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 90] }}]
      },
    },
  };

  const serialNumberHints = {
    ['outgoing_deliveries_goods_packing_13']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_41',
      attachTo: {
        element: '#barcodeInput',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
    ['outgoing_deliveries_goods_packing_14']: {
      text: 'MEGA_HINT.OUTGOING_DELIVERY.HINT_42',
      attachTo: {
        element: '#serialNumber',
        on: 'bottom'
      },
      scrollTo: false,
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 20] }}]
      },
    },
  };

  switch (quantityMethod) {
    case QuantityCheckMethodEnum.MANUAL:
      if (isLinkedSo) {
        Object.assign(manualHintsList, productsPackedUpHint)
      }
      return manualHintsList;

    // case QuantityCheckMethodEnum.BARCODE:
    //   if (!selectProduct) {
    //     Object.assign(barcodeHints, productsHints)
    //   }
    //   return barcodeHints;

    case QuantityCheckMethodEnum.SERIAL_NUMBER:
      return serialNumberHints;
  }
}
