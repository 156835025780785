import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryTransformFromIso2ToIso3'
})
export class CountryTransformFromIso2ToIso3Pipe implements PipeTransform {

  transform(iso2: string, ...args: any[]): string {
    let countryIso = '';

    switch (iso2.toUpperCase()) {
      case 'AF':
        countryIso = 'AFG';
        break;
      case 'AL':
        countryIso = 'ALB';
        break;
      case 'DZ':
        countryIso = 'DZA';
        break;
      case 'AS':
        countryIso = 'ASM';
        break;
      case 'AD':
        countryIso = 'AND';
        break;
      case 'AO':
        countryIso = 'AGO';
        break;
      case 'AI':
        countryIso = 'AIA';
        break;
      case 'AG':
        countryIso = 'ATG';
        break;
      case 'AR':
        countryIso = 'ARG';
        break;
      case 'AW':
        countryIso = 'ABW';
        break;
      case 'AU':
        countryIso = 'AUS';
        break;
      case 'AT':
        countryIso = 'AUT';
        break;
      case 'AZ':
        countryIso = 'AZE';
        break;
      case 'BS':
        countryIso = 'BHS';
        break;
      case 'BH':
        countryIso = 'BHR';
        break;
      case 'BD':
        countryIso = 'BGD';
        break;
      case 'BB':
        countryIso = 'BRB';
        break;
      case 'BY':
        countryIso = 'BLR';
        break;
      case 'BE':
        countryIso = 'BEL';
        break;
      case 'BZ':
        countryIso = 'BLZ';
        break;
      case 'BJ':
        countryIso = 'BEN';
        break;
      case 'BM':
        countryIso = 'BMU';
        break;
      case 'BT':
        countryIso = 'BTN';
        break;
      case 'BO':
        countryIso = 'BOL';
        break;
      case 'BQ':
        countryIso = 'BES';
        break;
      case 'BA':
        countryIso = 'BIH';
        break;
      case 'BW':
        countryIso = 'BWA';
        break;
      case 'BR':
        countryIso = 'BRA';
        break;
      case 'IO':
        countryIso = 'IOT';
        break;
      case 'BN':
        countryIso = 'BRN';
        break;
      case 'BG':
        countryIso = 'BGR';
        break;
      case 'BF':
        countryIso = 'BFA';
        break;
      case 'BI':
        countryIso = 'BDI';
        break;
      case 'CV':
        countryIso = 'CPV';
        break;
      case 'KH':
        countryIso = 'KHM';
        break;
      case 'CM':
        countryIso = 'CMR';
        break;
      case 'CA':
        countryIso = 'CAN';
        break;
      case 'KY':
        countryIso = 'CYM';
        break;
      case 'CF':
        countryIso = 'CAF';
        break;
      case 'TD':
        countryIso = 'TCD';
        break;
      case 'CL':
        countryIso = 'CHL';
        break;
      case 'CN':
        countryIso = 'CHN';
        break;
      case 'CX':
        countryIso = 'CXR';
        break;
      case 'CC':
        countryIso = 'CCK';
        break;
      case 'CO':
        countryIso = 'COL';
        break;
      case 'KM':
        countryIso = 'COM';
        break;
      case 'CK':
        countryIso = 'COK';
        break;
      case 'CR':
        countryIso = 'CRI';
        break;
      case 'HR':
        countryIso = 'HRV';
        break;
      case 'CU':
        countryIso = 'CUB';
        break;
      case 'CW':
        countryIso = 'CUW';
        break;
      case 'CY':
        countryIso = 'CYP';
        break;
      case 'CZ':
        countryIso = 'CZE';
        break;
      case 'DK':
        countryIso = 'DNK';
        break;
      case 'DJ':
        countryIso = 'DJI';
        break;
      case 'DM':
        countryIso = 'DMA';
        break;
      case 'DO':
        countryIso = 'DOM';
        break;
      case 'EC':
        countryIso = 'ECU';
        break;
      case 'EG':
        countryIso = 'EGY';
        break;
      case 'ER':
        countryIso = 'ERI';
        break;
      case 'EE':
        countryIso = 'EST';
        break;
      case 'ET':
        countryIso = 'ETH';
        break;
      case 'FK':
        countryIso = 'FLK';
        break;
      case 'FO':
        countryIso = 'FRO';
        break;
      case 'FJ':
        countryIso = 'FJI';
        break;
      case 'FR':
        countryIso = 'FRA';
        break;
      case 'FI':
        countryIso = 'FIN';
        break;
      case 'PF':
        countryIso = 'PYF';
        break;
      case 'GA':
        countryIso = 'GAB';
        break;
      case 'GM':
        countryIso = 'GMB';
        break;
      case 'GE':
        countryIso = 'GEO';
        break;
      case 'DE':
        countryIso = 'DEU';
        break;
      case 'GH':
        countryIso = 'GHA';
        break;
      case 'GI':
        countryIso = 'GIB';
        break;
      case 'GR':
        countryIso = 'GRC';
        break;
      case 'GL':
        countryIso = 'GRL';
        break;
      case 'GD':
        countryIso = 'GRD';
        break;
      case 'GU':
        countryIso = 'GUM';
        break;
      case 'GT':
        countryIso = 'GTM';
        break;
      case 'GG':
        countryIso = 'GGY';
        break;
      case 'GN':
        countryIso = 'GIN';
        break;
      case 'GW':
        countryIso = 'GNB';
        break;
      case 'GY':
        countryIso = 'GUY';
        break;
      case 'HT':
        countryIso = 'HTI';
        break;
      case 'HN':
        countryIso = 'HND';
        break;
      case 'HK':
        countryIso = 'HKG';
        break;
      case 'HU':
        countryIso = 'HUN';
        break;
      case 'IS':
        countryIso = 'ISL';
        break;
      case 'IN':
        countryIso = 'IND';
        break;
      case 'ID':
        countryIso = 'IDN';
        break;
      case 'IR':
        countryIso = 'IRN';
        break;
      case 'IQ':
        countryIso = 'IRQ';
        break;
      case 'IE':
        countryIso = 'IRL';
        break;
      case 'IM':
        countryIso = 'IMN';
        break;
      case 'IL':
        countryIso = 'ISR';
        break;
      case 'IT':
        countryIso = 'ITA';
        break;
      case 'JM':
        countryIso = 'JAM';
        break;
      case 'JP':
        countryIso = 'JPN';
        break;
      case 'JE':
        countryIso = 'JEY';
        break;
      case 'JO':
        countryIso = 'JOR';
        break;
      case 'KZ':
        countryIso = 'KAZ';
        break;
      case 'KE':
        countryIso = 'KEN';
        break;
      case 'KI':
        countryIso = 'KIR';
        break;
      case 'KW':
        countryIso = 'KWT';
        break;
      case 'KG':
        countryIso = 'KGZ';
        break;
      case 'LV':
        countryIso = 'LVA';
        break;
      case 'LB':
        countryIso = 'LBN';
        break;
      case 'LS':
        countryIso = 'LSO';
        break;
      case 'LR':
        countryIso = 'LBR';
        break;
      case 'LY':
        countryIso = 'LBY';
        break;
      case 'LI':
        countryIso = 'LIE';
        break;
      case 'LT':
        countryIso = 'LTU';
        break;
      case 'LU':
        countryIso = 'LUX';
        break;
      case 'MO':
        countryIso = 'MAC';
        break;
      case 'MG':
        countryIso = 'MDG';
        break;
      case 'MW':
        countryIso = 'MWI';
        break;
      case 'MY':
        countryIso = 'MYS';
        break;
      case 'MV':
        countryIso = 'MDV';
        break;
      case 'ML':
        countryIso = 'MLI';
        break;
      case 'MT':
        countryIso = 'MLT';
        break;
      case 'MH':
        countryIso = 'MHL';
        break;
      case 'MQ':
        countryIso = 'MTQ';
        break;
      case 'MR':
        countryIso = 'MRT';
        break;
      case 'MU':
        countryIso = 'MUS';
        break;
      case 'MX':
        countryIso = 'MEX';
        break;
      case 'FM':
        countryIso = 'FSM';
        break;
      case 'MD':
        countryIso = 'MDA';
        break;
      case 'MC':
        countryIso = 'MCO';
        break;
      case 'MN':
        countryIso = 'MNG';
        break;
      case 'ME':
        countryIso = 'MNE';
        break;
      case 'MS':
        countryIso = 'MSR';
        break;
      case 'MA':
        countryIso = 'MAR';
        break;
      case 'MZ':
        countryIso = 'MOZ';
        break;
      case 'MM':
        countryIso = 'MMR';
        break;
      case 'NA':
        countryIso = 'NAM';
        break;
      case 'NR':
        countryIso = 'NRU';
        break;
      case 'NP':
        countryIso = 'NPL';
        break;
      case 'NL':
        countryIso = 'NLD';
        break;
      case 'NZ':
        countryIso = 'NZL';
        break;
      case 'NI':
        countryIso = 'NIC';
        break;
      case 'NE':
        countryIso = 'NER';
        break;
      case 'NG':
        countryIso = 'NGA';
        break;
      case 'NU':
        countryIso = 'NIU';
        break;
      case 'NF':
        countryIso = 'NFK';
        break;
      case 'MP':
        countryIso = 'MNP';
        break;
      case 'NO':
        countryIso = 'NOR';
        break;
      case 'OM':
        countryIso = 'OMN';
        break;
      case 'PK':
        countryIso = 'PAK';
        break;
      case 'PW':
        countryIso = 'PLW';
        break;
      case 'PS':
        countryIso = 'PSE';
        break;
      case 'PA':
        countryIso = 'PAN';
        break;
      case 'PG':
        countryIso = 'PNG';
        break;
      case 'PY':
        countryIso = 'PRY';
        break;
      case 'PE':
        countryIso = 'PER';
        break;
      case 'PH':
        countryIso = 'PHL';
        break;
      case 'PN':
        countryIso = 'PCN';
        break;
      case 'PL':
        countryIso = 'POL';
        break;
      case 'PT':
        countryIso = 'PRT';
        break;
      case 'PR':
        countryIso = 'PRI';
        break;
      case 'QA':
        countryIso = 'QAT';
        break;
      case 'MK':
        countryIso = 'MKD';
        break;
      case 'RO':
        countryIso = 'ROU';
        break;
      case 'RU':
        countryIso = 'RUS';
        break;
      case 'RW':
        countryIso = 'RWA';
        break;
      case 'KN':
        countryIso = 'KNA';
        break;
      case 'WS':
        countryIso = 'WSM';
        break;
      case 'SM':
        countryIso = 'SMR';
        break;
      case 'ST':
        countryIso = 'STP';
        break;
      case 'SA':
        countryIso = 'SAU';
        break;
      case 'SN':
        countryIso = 'SEN';
        break;
      case 'RS':
        countryIso = 'SRB';
        break;
      case 'SC':
        countryIso = 'SYC';
        break;
      case 'SL':
        countryIso = 'SLE';
        break;
      case 'SG':
        countryIso = 'SGP';
        break;
      case 'SX':
        countryIso = 'SXM';
        break;
      case 'SK':
        countryIso = 'SVK';
        break;
      case 'SI':
        countryIso = 'SVN';
        break;
      case 'SB':
        countryIso = 'SLB';
        break;
      case 'SO':
        countryIso = 'SOM';
        break;
      case 'ZA':
        countryIso = 'ZAF';
        break;
      case 'SS':
        countryIso = 'SSD';
        break;
      case 'ES':
        countryIso = 'ESP';
        break;
      case 'LK':
        countryIso = 'LKA';
        break;
      case 'SD':
        countryIso = 'SDN';
        break;
      case 'SR':
        countryIso = 'SUR';
        break;
      case 'SE':
        countryIso = 'SWE';
        break;
      case 'CH':
        countryIso = 'CHE';
        break;
      case 'SY':
        countryIso = 'SYR';
        break;
      case 'TW':
        countryIso = 'TWN';
        break;
      case 'TJ':
        countryIso = 'TJK';
        break;
      case 'TZ':
        countryIso = 'TZA';
        break;
      case 'TH':
        countryIso = 'THA';
        break;
      case 'TG':
        countryIso = 'TGO';
        break;
      case 'TK':
        countryIso = 'TKL';
        break;
      case 'TO':
        countryIso = 'TON';
        break;
      case 'TT':
        countryIso = 'TTO';
        break;
      case 'TN':
        countryIso = 'TUN';
        break;
      case 'TR':
        countryIso = 'TUR';
        break;
      case 'TM':
        countryIso = 'TKM';
        break;
      case 'TC':
        countryIso = 'TCA';
        break;
      case 'TV':
        countryIso = 'TUV';
        break;
      case 'UG':
        countryIso = 'UGA';
        break;
      case 'UA':
        countryIso = 'UKR';
        break;
      case 'AE':
        countryIso = 'ARE';
        break;
      case 'GB':
        countryIso = 'GBR';
        break;
      case 'US':
        countryIso = 'USA';
        break;
      case 'UY':
        countryIso = 'URY';
        break;
      case 'UZ':
        countryIso = 'UZB';
        break;
      case 'VU':
        countryIso = 'VUT';
        break;
      case 'VE':
        countryIso = 'VEN';
        break;
      case 'VN':
        countryIso = 'VNM';
        break;
      case 'VG':
        countryIso = 'VGB';
        break;
      case 'YE':
        countryIso = 'YEM';
        break;
      case 'ZM':
        countryIso = 'ZMB';
        break;
      case 'ZW':
        countryIso = 'ZWE';
        break;
    }

    return countryIso

  }

}
