import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { EcoSettingsDeliveryCountryModel } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';

@Component({
  selector: 'rnpl-update-payment-upon-pickup-modal',
  templateUrl: './update-payment-upon-pickup-modal.component.html',
})
export class UpdatePaymentUponPickupModalComponent extends BaseModalComponent implements OnInit {

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly updateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<UpdatePaymentUponPickupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
  }

  public useCorporateAddress(): void {

  }




}
