import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';

import { selectTradeOffer, selectTradeOfferState } from 'projects/workspace/src/app/trade-offer/store/selectors';
import { TradeOfferModel } from 'projects/workspace/src/app/trade-offer/models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { MetricConversionPipe } from '../../../rnpl-common';
import { UIStatesEnum } from '../../../../models';

@Component({
  selector: 'rnpl-offer-add-discount-modal',
  templateUrl: './offer-add-discount-modal.component.html',
  styleUrls: ['./offer-add-discount-modal.component.scss']
})
export class OfferAddDiscountModalComponent implements OnInit {

  public discountControl: FormControl = new FormControl({value: null});
  public discountAmountNetControl: FormControl = new FormControl({value: null});
  public discountAmountGrossControl: FormControl = new FormControl({value: null});
  public tradeOffer: TradeOfferModel;
  readonly destroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<OfferAddDiscountModalComponent>,
    private readonly store: Store<AppState>,
    private tradeOfferApiService: TradeOfferApiService,
    private readonly metricPipe: MetricConversionPipe,
  ) { }

  ngOnInit() {
    this.trackTradeOfferChanges();

    this.store.select(selectTradeOfferState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: UIStatesEnum) => {
        if (response === UIStatesEnum.VIEW) {
          this.discountControl.disable({emitEvent: false});
          this.discountAmountNetControl.disable({emitEvent: false});
          this.discountAmountGrossControl.disable({emitEvent: false});
        } else {
          this.discountControl.enable({emitEvent: false});
          this.discountAmountNetControl.enable({emitEvent: false});
          this.discountAmountGrossControl.enable({emitEvent: false});
        }
      });
  }

  private trackTradeOfferChanges(): void {
    this.store.select(selectTradeOffer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tradeOffer: TradeOfferModel) => {
        this.tradeOffer = tradeOffer;
        this.discountControl.patchValue(this.tradeOffer.discount);
        this.discountAmountNetControl.patchValue(this.getCurrencyConvertedValue(this.tradeOffer.discountAmountNet));
        this.discountAmountGrossControl.patchValue(this.getCurrencyConvertedValue(this.tradeOffer.discountAmountGross));
      })
  }

  public updateDiscount(field: FormInputChangedModel): void {
    this.tradeOfferApiService.updateTradeOffer(this.tradeOffer.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  private getCurrencyConvertedValue(value: number|string): number|string {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

}
