import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { get, isEqual } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { selectCreditNote } from 'projects/workspace/src/app/credit-note/store/selectors';
import { CreditNoteModel } from 'projects/workspace/src/app/credit-note/models';
import { CreditNoteApiService } from 'projects/workspace/src/app/credit-note/services/credit-note-api.service';

@Component({
  selector: 'rnpl-credit-note-add-remark-modal',
  templateUrl: './credit-note-add-remark-modal.component.html'
})
export class CreditNoteAddRemarkModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  readonly destroy$: Subject<void> = new Subject();
  public creditNote: CreditNoteModel;

  constructor(
    public dialogRef: MatDialogRef<CreditNoteAddRemarkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly store: Store<AppState>,
    private creditNoteApiService: CreditNoteApiService
  ) { }

  ngOnInit() {
    this.trackCreditNoteChanges();
  }

  private trackCreditNoteChanges(): void {
    this.store.select(selectCreditNote)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe((creditNote: CreditNoteModel) => {
        this.creditNote = creditNote;
        this.initForm(this.creditNote);
      });
  }

  public initForm(creditNote: CreditNoteModel = {} as CreditNoteModel): void {
    this.form = this.fb.group({
      printableRemarks: [get(creditNote, 'printableRemarks')],
    });
  }

  public creditNoteChanged(value, closeModal = false): void {
    this.creditNoteApiService.updateCreditNotePart(this.creditNote.id, {
      fieldValue: value,
      fieldName: 'printableRemarks'
    })
      .subscribe(() => {
        if (closeModal) {
          this.dialogRef.close();
        }
      }); // updated via store
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
