import { createAction, props } from '@ngrx/store';
import { WarehouseResponse } from '../../models/warehouse-response.model';
import { UIStatesEnum } from 'common/src/models';

// Current Warehouse

export const UpdateCurrentWarehouse = createAction(
  '[Warehouse] Update Current Warehouse',
  props<{ currentWarehouse: WarehouseResponse }>()
);

export const UpdateWarehouseCurrentState = createAction(
  '[Warehouse] Update Current Warehouse Current State',
  props<{ currentState: UIStatesEnum }>()
);

// Warehouses list

export const UpdateWarehousesList = createAction(
  '[Warehouse] Update Warehouses list',
  props<{ warehouses: WarehouseResponse[] }>()
);

export const ClearWarehousesList = createAction(
  '[Warehouse] Clear Warehouses list',
  props<{ warehouses: WarehouseResponse[] }>()
);

export const UpdateWarehouseUpdatedAt = createAction(
  '[Warehouse] Update Warehouse updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearWarehouseUpdatedAt = createAction(
  '[Warehouse] Clear Warehouse updatedAt',
);


export const IncrementLoadingRequestsCount = createAction(
  '[Warehouse] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Warehouse] Decrement Loading Requests Count'
);

export const UpdateWarehouseFormStock = createAction(
  '[Warehouse] Update Warehouse Form Stock',
  props<{ warehouseForm: any }>()
);

export const ClearWarehouseFormStock = createAction(
  '[Warehouse] Clear Warehouse Form Stock'
);
