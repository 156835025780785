import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ProcessCardItemModel } from '../../../models/process-card-item.model';
import { Store } from '@ngrx/store';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { Observable } from 'rxjs';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';

@Component({
  selector: 'rnpl-process-card',
  templateUrl: 'process-card.component.html',
  styleUrls: ['process-card.component.scss']
})
export class ProcessCardComponent {
  @Input() id: number | string;

  @Input()
  public isParent: boolean = false;

  @Input()
  public type: string = 'arrivals';

  @Input()
  public quantity: number;

  @Input()
  public itemId: number;

  @Input()
  public processCardData: Array<ProcessCardItemModel>;

  @Input()
  public backUrlId: number;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) { }

  navigateToManage(id: number | string) {
    const url: any[] = ['/', 'trade', 'purchase-order', this.backUrlId, 'batch-manage', id];

    this.router.navigate(url);
  }
}
