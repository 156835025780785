import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RnplCommonModule } from '../rnpl-common';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { PositionCardComponent } from './position-card/position-card.component';
import { StatusLineModule } from '../ui-components/status-line/status.line.module';
import { PositionMainInfoComponent } from './position-main-info/position-main-info.component';
import { PositionInfoListComponent } from './position-info-list/position-info-list.component';
import { PositionsControlsComponent } from './positions-controls/positions-controls.component';
import { AddPositionsRemarkComponent } from './add-positions-remark/add-positions-remark.component';
import { ChangePositionsViewButtonComponent } from './change-positions-view-button/change-positions-view-button.component';
import { ActionListComponent } from './action-list/action-list/action-list.component';
import { PositionAccountingSettingsModule } from 'projects/workspace/src/app/shared/components';
import { PurposeFormModule } from 'projects/workspace/src/app/shared/forms/purpose-form/purpose-form.module';
import { PositionsDiscountComponent } from './positions-discount/positions-discount.component';
import { NumberFormatPipeModule } from 'projects/workspace/src/app/shared/pipes/number-format-pipe/number-format-pipe.module';
import { GlProductTypeDropdownModule } from 'projects/workspace/src/app/gl-accounts/components';

@NgModule({
  declarations: [
    PositionCardComponent,
    PositionsControlsComponent,
    ChangePositionsViewButtonComponent,
    PositionMainInfoComponent,
    AddPositionsRemarkComponent,
    PositionInfoListComponent,
    ActionListComponent,
    PositionsDiscountComponent
  ],
  exports: [
    ChangePositionsViewButtonComponent,
    PositionCardComponent,
    PositionMainInfoComponent,
    ActionListComponent,
    PositionsControlsComponent,
    AddPositionsRemarkComponent,
    PositionInfoListComponent,
    PositionsDiscountComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    RnplCommonModule,
    RouterModule,
    StatusLineModule,
    MatMenuModule,
    UIComponentsModule,
    NgxCurrencyModule,
    PositionAccountingSettingsModule,
    PurposeFormModule,
    NumberFormatPipeModule,
    GlProductTypeDropdownModule,
  ]
})
export class PositionCardModule { }
