import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { ResponseList } from 'projects/workspace/src/app/shared/models/response';
import { UserModel } from '../../../../models';
import { UserListTabs } from 'projects/workspace/src/app/hrm/pages/user-list/enums/user-list-tabs.enum';
import { HrmService } from 'projects/workspace/src/app/hrm/hrm.service';

@Component({
  selector: 'rnpl-filters-control-employee',
  templateUrl: './filters-control-employee.component.html',
})
export class FiltersControlEmployeeComponent extends FiltersControlBaseComponent implements OnInit, OnDestroy {

  public availableEmployees: UserModel[] = [];

  @Input() employeeStatus: UserListTabs;

  constructor(
    private hrmService: HrmService,
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.getActiveEmployees();
  }

  private getActiveEmployees(): void {
    this.hrmService.getUsers(this.employeeStatus || UserListTabs.Active, '1', '10000')
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: ResponseList<UserModel>) => {
        this.availableEmployees = response.data
          .map(user => ({
            ...user,
            id: +user.id,
            searchLabel: `${user.first_name} ${user.last_name}`
          }))
          .sort((a, b) => a.searchLabel.localeCompare(b.searchLabel));
      });
  }

}
