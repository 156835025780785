import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { finalize, startWith, takeUntil } from 'rxjs/operators';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { GlAccountRuleModel } from 'projects/workspace/src/app/accounting/accounting-settings-module/models';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants';
import { CompositeProductModel, FamilyModel, ProductsService } from '../../../products';
import { GlAccountsApiService } from 'projects/workspace/src/app/gl-accounts/services/gl-accounts-api.service';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';


@Component({
  selector: 'rnpl-gl-allocation-rule-modal',
  templateUrl: './gl-allocation-rule-modal.component.html'
})
export class GlAllocationRuleModalComponent implements OnDestroy {

  public form: FormGroup;
  public productTypes = ProductTypes;
  public vatList = VAT_LIST;
  public flatFamilies: FamilyModel[] = [];
  // public generalCategories: FamilyModel[] = [];
  // public generalProducts: any[] = [];
  // public generalProductsSource: any[] = [];

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly loadingFamilies$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // readonly loadingGeneralCategories$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // readonly loadingGeneralProducts$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly deleteRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly productsService: ProductsService,
    private readonly glAccountsApiService: GlAccountsApiService,
    public readonly dialogRef: MatDialogRef<GlAllocationRuleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      rule: GlAccountRuleModel,
      predefinedOptions: Partial<GlAccountRuleModel>,
    }
  ) {
    this.initForm();

    if (this.data) {
      if (this.data.rule) { this.form.patchValue(this.data.rule); }
      if (this.data.predefinedOptions) { this.form.patchValue(this.data.predefinedOptions); }
      if (this.data.rule || this.data.predefinedOptions) {
        // get lists if needed
        this.getLists();
      }
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      productType: [null, [Validators.required]],
      location: [null, [Validators.required]],
      vatRate: [20, [Validators.required]],
      reverseCharge: [false],
      glAccountId: [null, [Validators.required]],
      detailedOptions: this.fb.group({
        enabled: [false],
        type: [],
        entityId: [],
        entityName: [],
      }),
    });

    this.productTypeControl.valueChanges
      .pipe(
        startWith(this.productTypeControl.value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        if (value) {
          this.detailedOptionsEnableControl.enable();
        } else {
          this.detailedOptionsEnableControl.disable();
        }
      });

    this.detailedOptionsEnableControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        this.detailedOptionsEntityIdControl.updateValueAndValidity();
        this.detailedOptionsTypeControl.updateValueAndValidity();

        if (value) {
          this.detailedOptionsEntityIdControl.setValidators(Validators.required);
          this.detailedOptionsTypeControl.setValidators(Validators.required);
        } else {
          this.detailedOptionsEntityIdControl.clearValidators();
          this.detailedOptionsTypeControl.clearValidators();
        }
      })
  }

  public setName(name: string): void {
    this.detailedOptionsEntityNameControl.patchValue(name);
  }

  public setGeneralProductName(product: CompositeProductModel): void {
    const name = `${this.translate.instant('GL_CATEGORY.' + product.category)}/${product.name}`;

    this.setName(name);
  }

  public reverseChargeChanged(): void {
    if (this.reverseChargeControl.value) {
      this.vatRateControl.patchValue(0);
      this.vatRateControl.disable();
    } else {
      this.vatRateControl.enable();
    }
  }

  public vatChanged(): void {
    const location = this.locationControl.value;
    if (this.vatRateControl.value === 0) {
      // this.vatRateControl.disable();
      if (location === 'EU' || location === 'NON_EU') {
        this.vatRateControl.disable();
        this.reverseChargeControl.patchValue(true);
      }
    }
  }

  public productTypeChanged(): void {
    this.detailedOptionsTypeControl.patchValue(null);
    this.detailedOptionsEntityIdControl.patchValue(null);
    this.detailedOptionsEntityNameControl.patchValue(null);
    this.detailedTypeChanged();

    this.form.markAsUntouched();
  }

  public detailedTypeChanged(): void {
    this.detailedOptionsEntityIdControl.patchValue(null);
    this.detailedOptionsEntityNameControl.patchValue(null);
    this.getLists();
  }

  private getLists(): void {
    if (this.detailedOptionsTypeControl.value === 'PRODUCT_CATEGORY') {
      this.getFamiliesTree();
    }
    // if (this.detailedOptionsTypeControl.value === 'GENERAL_CATEGORY') {
    //   if (!this.generalCategories.length) {
    //     this.getGeneralCategoriesTree();
    //   }
    // }
    // if (this.detailedOptionsTypeControl.value === 'GENERAL_PRODUCT') {
    //   this.getGeneralProducts();
    // }
  }

  // private getGeneralProducts(): void {
  //   if (this.generalProductsSource.length) {
  //     this.filterGeneralProducts();
  //     return;
  //   }
  //   this.loadingFamilies$.next(true);
  //
  //   this.productsService.getGeneralProductsListDropdown(false)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((response) => {
  //       this.generalProductsSource = response;
  //       this.filterGeneralProducts();
  //       this.loadingFamilies$.next(false);
  //     });
  // }
  //
  // private filterGeneralProducts(): void {
  //   if (!this.generalProductsSource.length || !this.productTypeControl.value) { return; }
  //   this.generalProducts = this.generalProductsSource.filter(glProduct => glProduct.entityForm === this.productTypeControl.value);
  // }

  private getFamiliesTree(): void {
    if (!this.productTypeControl.value) { return; }
    this.loadingFamilies$.next(true);
    this.flatFamilies = [];

    const requestParams = new HttpParams().set('products_type', this.productTypeControl.value.replace('-','_'));

    this.productsService.getFamiliesTree(requestParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.flatFamilies =  this.convertTreeToFlat(response.data);
        this.loadingFamilies$.next(false);
      });
  }

  // private getGeneralCategoriesTree(): void {
  //   this.loadingGeneralCategories$.next(true);
  //
  //   const requestParams = new HttpParams().set('products_type', 'general');
  //
  //   this.productsService.getFamiliesTree(requestParams)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(response => {
  //       this.generalCategories = response.data.map(itm => ({
  //         ...itm,
  //         translatedTitle: this.translate.instant('GL_CATEGORY.' + itm.title)
  //       }));
  //
  //       this.loadingGeneralCategories$.next(false);
  //     });
  // }

  private convertTreeToFlat(tree: FamilyModel[]): FamilyModel[] {
    if (!tree) { return; }

    const flatArray = [];
    const getTreeItems = (branch) => {
      branch.map(node => {
        if (!node.product_locked_at) {
          flatArray.push(node);
        }

        if (node.children.length) {
          getTreeItems(node.children);
        }
      });
    };

    getTreeItems(tree);

    return flatArray.map(itm => ({
      ...itm,
      id: +itm.id
    }));
  }

  public submitCreate(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.submitRequest$.next(true);

    this.glAccountsApiService.createGlAccountRule(this.form.getRawValue())
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this.destroy$)
      ).subscribe(() => this.dialogRef.close(true));
  }

  public submitUpdate(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.form.invalid) { return; }

    this.submitRequest$.next(true);

    this.glAccountsApiService.updateGlAccountRule(this.data.rule.ruleId, this.form.getRawValue())
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this.destroy$)
      ).subscribe(() => this.dialogRef.close(true));
  }

  public deleteRule(): void {
    if (this.deleteRequest$.getValue()) { return; }

    this.dialog.open(DangerModalComponent, {
      data: {
        title: 'GL_ACCOUNTS.DELETE_RULE_TITLE',
        message: 'GL_ACCOUNTS.DELETE_RULE_MSG',
        confirmBtnText: 'BUTTON.DELETE',
        confirmBtnIcon: 'trash-2'
      }
    }).afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deleteRequest$.next(true);

        this.glAccountsApiService.deleteGlAccountRule(this.data.rule.ruleId)
          .pipe(
            finalize(() => this.deleteRequest$.next(false)),
            takeUntil(this.destroy$)
          )
          .subscribe(() => this.dialogRef.close(true));
      }
    });
  }

  get productTypeControl(): FormControl { return this.form.get('productType') as FormControl; }
  get locationControl(): FormControl { return this.form.get('location') as FormControl; }
  get vatRateControl(): FormControl { return this.form.get('vatRate') as FormControl; }
  get reverseChargeControl(): FormControl { return this.form.get('reverseCharge') as FormControl; }
  get glAccountIdControl(): FormControl { return this.form.get('glAccountId') as FormControl; }
  get detailedOptionsEnableControl(): FormControl { return this.form.get('detailedOptions').get('enabled') as FormControl; }
  get detailedOptionsTypeControl(): FormControl { return this.form.get('detailedOptions').get('type') as FormControl; }
  get detailedOptionsEntityIdControl(): FormControl { return this.form.get('detailedOptions').get('entityId') as FormControl; }
  get detailedOptionsEntityNameControl(): FormControl { return this.form.get('detailedOptions').get('entityName') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}

