import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rnpl-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent {

  @Input() public title: string;
  @Input() public isOpen: boolean = true;

  @Output() togglePanelEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  public togglePanel(): void {
    this.isOpen = !this.isOpen;
    this.togglePanelEmit.emit(this.isOpen);
  }

}
