export const WarehouseListColumns = [{
  cellTemplate: 'numberRows',
  cellClass: 'center',
  name: 'Nr.',
  width: 40,
  maxWidth: 40,
  sortable: false,
  resizeable: false,
  headerClass: 'center'
}, {
  cellTemplate: 'withArrowRight',
  headerTemplate: 'headerTemplate',
  name: 'COLUMN.RUNPLE_ID',
  prop: 'runple_id_data',
  width: 150,
  maxWidth: 200,
  sortable: false
}, {
  cellTemplate: 'primary',
  headerTemplate: 'headerTemplate',
  name: 'COLUMN.WAREHOUSE',
  prop: 'name'
}, {
  cellTemplate: 'withStatusLine',
  headerTemplate: 'headerTemplate',
  name: 'Square storage area, m<sup>2</sup>',
  prop: 'storage_area',
  width: 200,
  maxWidth: 200,
  sortable: false
}, {
  cellTemplate: 'withStatusLine',
  headerTemplate: 'headerTemplate',
  name: 'Square storage volume, m<sup>2</sup>',
  prop: 'storage_volume',
  width: 200,
  maxWidth: 200,
  sortable: false
}, {
  cellTemplate: 'withDate',
  headerTemplate: 'headerTemplate',
  name: 'COLUMN.CREATED',
  prop: 'created_at',
  width: 150,
  maxWidth: 200,
}, {
  cellTemplate: 'withDate',
  headerTemplate: 'headerTemplate',
  name: 'COLUMN.UPDATED',
  prop: 'updated_at',
  width: 150,
  maxWidth: 200,
}
];
