import {
  Component,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'rnpl-wizard-summary',
  templateUrl: './wizard-summary.html',
  styleUrls: ['./wizard-summary.scss']
})
export class WizardSummaryComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
