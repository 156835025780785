import { createSelector } from '@ngrx/store';

import { SubscriptionFeatureKey, SubscriptionsBlocksValidationsState, SubscriptionState, SubscriptionStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[SubscriptionFeatureKey]);

export const selectSubscriptionsList = createSelector(
  selectFeature,
  (state: SubscriptionStore) => state.list
);

export const selectSubscriptionEntity = createSelector(
  selectFeature,
  (state: SubscriptionStore) => state.entity
);

export const selectSubscriptionsBlocksValidations = createSelector(
  selectFeature,
  (state: SubscriptionStore) => state.blocksValidations
);

export const selectSubscription = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.subscription
);

export const selectSubscriptionPositions = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.positions
);

export const selectSubscriptionPositionsCount = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.positionsCount
);

export const selectCountries = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.countries
);

export const selectSubscriptionCurrentState = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.currentState
);

export const loadingRequestsCount = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.loadingRequestsCount
);

export const subscriptionUpdatedAt = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectSubscriptionEntity,
  (state: SubscriptionState) => state.isShouldRefresh
);

// export const selectSubscriptionPropertiesBlockValid = createSelector(
//   selectSubscriptionsBlocksValidations,
//   (state: SubscriptionsBlocksValidationsState) => state.propertiesBlockValid
// );

export const selectSubscriptionDurationBlockValid = createSelector(
  selectSubscriptionsBlocksValidations,
  (state: SubscriptionsBlocksValidationsState) => state.durationBlockValid
);

export const selectSubscriptionBillingInfoBlockValid = createSelector(
  selectSubscriptionsBlocksValidations,
  (state: SubscriptionsBlocksValidationsState) => state.billingInfoBlockValid
);

export const selectSubscriptionPositionsBlockValid = createSelector(
  selectSubscriptionsBlocksValidations,
  (state: SubscriptionsBlocksValidationsState) => state.positionsBlockValid
);
