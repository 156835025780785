import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ViewportRuler } from '@angular/cdk/overlay';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { findIndex, isEqual, uniqWith } from 'lodash';

import { AppActionsListModel, AppListModel, AppListTypeEnum, AppNameEnum, StartScreenModel } from '../../launchpad.model';
import { AppDataByModuleName } from '../../app-data.config';
import { LaunchpadApiService } from '../../launchpad-api.service';
import { selectAppsCounters, selectUserStartScreenItems } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { RemoveActionConfig } from '../../launchpad.config';
import { ToasterService } from '../../../ui-components/toaster';
import { filerListByUniqElements } from '../../start-screen.helper';
import { UserPermissionsService } from '../../../../services/user-permissions.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { ProductsOptions } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SystemPreferencesPermissionsService } from '../../../../services/system-preferences-permissions.service';

@Component({
  selector: 'rnpl-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit, AfterViewInit, OnDestroy {

  public isOutside: boolean;
  public showControl: boolean;
  public isMovedElement: boolean = false;
  public appDataByModuleName: {[key: string]: any} = {} as {[key: string]: any};

  public timerDelayForEditMode = 100;
  public countdownTimer;
  public activeContainer;
  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public draggedIndex: number;
  public draggedApp: AppListModel;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public removeFromStartScreenRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public index: number;
  public appsCounters: {[key: string]: number} = {} as {[key: string]: number};
  public productOptions: ProductsOptions;

  @Input() public folderItems: AppListModel;
  @Input() public startScreenList: StartScreenModel[] = [];
  @Input() public screenIndex: number;
  @Input() public isEditMode: boolean;

  @Output() public closeFolderEmit: EventEmitter<any> = new EventEmitter();
  @Output() public removeElementFromFolderEmit: EventEmitter<any> = new EventEmitter();

  @ViewChild(CdkDropListGroup, {static: false}) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, {static: false}) placeholder: CdkDropList;
  @ViewChild('folderNameControl', {static: false}) folderNameControl: ElementRef;

  constructor(
    private viewportRuler: ViewportRuler,
    private launchpadApiService: LaunchpadApiService,
    public store: Store<AppState>,
    private actionButtonService: ActionButtonsService,
    private readonly toasterService: ToasterService,
    public userPermissionsService: UserPermissionsService,
    private systemPreferencesPermissionsService: SystemPreferencesPermissionsService,
  ) {
    this.target = null;
    this.source = null;

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile) => {
        this.productOptions = companyProfile.productOptions; // todo: change productOptions to userProductOptions after Runple Subscription 2.0 implementation
        this.getAppListConfig();
      });
  }

  ngOnInit() {
    this.showControl = !this.folderItems.name;
    this.trackStartScreenItemsListChanges();
    this.index = findIndex(this.startScreenList[this.screenIndex].items, this.folderItems);

    this.trackAppsCountersChanges();
  }

  public trackStartScreenItemsListChanges(): void {
    this.store.select(selectUserStartScreenItems)
      .pipe(takeUntil(this.destroy$))
      .subscribe((listItems) => {
        if (!this.folderItems.items || !this.folderItems.items.length) {
          this.closeFolder();
        }
      });
  }

  public trackAppsCountersChanges(): void {
    this.store.select(selectAppsCounters)
      .pipe(takeUntil(this.destroy$))
      .subscribe((counters) => {
        this.appsCounters = counters;
      });
  }

  ngAfterViewInit() {
    const phElement = this.placeholder.element.nativeElement;
    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
    this.folderNameControl.nativeElement.focus();
  }

  public renameFolder(name: string): void {
    if (!name) { return; }
    const prepareName = name.trim();
    if (prepareName && prepareName.length) {
      if (!this.startScreenList.some(screen => screen.items.some(item => item.name === prepareName && item.type === 'folder'))) {
        this.folderItems.name = prepareName;
      } else {
        this.showMsg('LAUNCH_PAD.FOLDER_DUPLICATE_NAME_ERROR');
      }
      this.showControl = false;
      this.startScreenList[this.screenIndex].items[this.index] = this.folderItems;
      this.updateStartScreenList(this.startScreenList);
    }
  }

  public removeFromStartScreen(app: AppListModel): void {
    if (!this.removeFromStartScreenRequest$.value) {
      this.removeFromStartScreenRequest$.next(true);
      this.launchpadApiService.removeAppFromStartScreen(app.name)
        .pipe(
          finalize(() => this.removeFromStartScreenRequest$.next(false)),
          // takeUntil(this.destroy$)
        )
        .subscribe(listItems => {
          this.folderItems.items = this.folderItems.items.filter(item => item !== app);
          if (!this.folderItems.items || !this.folderItems.items.length) {
            this.closeFolder();
          }
        });
    }
  }

  public actionListHandler(actionName: string, app?: AppListModel): void {
    if (actionName === 'removeFromStartScreen') {
      this.removeFromStartScreen(app);
    } else {
      if (this.actionButtonService[actionName]) {
        this.actionButtonService[actionName]();
      }
    }
  }

  public setEditMode(event: MouseEvent): void {
    if (event.button !== 0) { return; }
    let timer: number = this.timerDelayForEditMode;

    this.countdownTimer = setInterval(() => {
      if (!this.isMovedElement && !this.isEditMode && !this.isOutside) {
        timer -= 1;
        if (timer <= 0) {
          clearInterval(this.countdownTimer);
          this.isEditMode = !this.isMovedElement;
        }
      } else {
        clearInterval(this.countdownTimer);
      }
    }, 10);
  }

  public removeElementFromFolder(): void {
    if (this.isOutside) {
      const currentList = this.startScreenList[this.screenIndex].items;

      const draggedElement: AppListModel = {
        type: AppListTypeEnum.SINGLE,
        name: this.draggedApp.name,
        items: [this.draggedApp]
      }

      if (findIndex(currentList, draggedElement) === -1) {
        currentList.push(draggedElement);
      }

      this.folderItems.items = this.folderItems.items.filter(item => item !== this.draggedApp);

      currentList.splice(this.index, 1, this.folderItems);

      if (!this.folderItems.items.length) {
        currentList.splice(this.index, 1);
        this.startScreenList[this.screenIndex].items = uniqWith(currentList, isEqual);
        this.updateStartScreenList(this.startScreenList);
        this.closeFolder();
      } else {
        this.startScreenList[this.screenIndex].items = uniqWith(currentList, isEqual);
        this.updateStartScreenList(this.startScreenList);
      }
    }
  }


  public closeFolder(): void {
    this.closeFolderEmit.emit();
  }

  public updateStartScreenList(screenList): void {
    this.launchpadApiService.updateStartScreenApps(filerListByUniqElements(screenList))
      .subscribe(() => {
      }, error => {
        this.launchpadApiService.getStartScreenApps().subscribe();
      });
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList): boolean => {
    if (drop == this.placeholder) {
      return true;
    }

    if (drop != this.activeContainer) {
      return false;
    }

    const phElement = this.placeholder.element.nativeElement;
    const sourceElement = drag.dropContainer.element.nativeElement;
    const dropElement = drop.element.nativeElement;

    const dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    const dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = null;
    dropElement.parentElement.insertBefore(
      phElement,
      (dropIndex > dragIndex ? dropElement.nextSibling : dropElement)
    );

    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  dropListDropped(): void {
    if (!this.target) { return; }

    this.isMovedElement = false;

    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.targetIndex]);

    this.target = null;
    this.source = null;

    if ((this.sourceIndex !== this.targetIndex) && !this.isOutside) {
      moveItemInArray(this.folderItems.items, this.sourceIndex, this.targetIndex);
      this.startScreenList[this.screenIndex].items[this.index] = this.folderItems;

      this.updateStartScreenList(this.startScreenList);
    }
  }

  dragMoved(e: CdkDragMove): void {
    const point = this.getPointerPositionOnPage(e.event);
    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent): {[key: string]: number} {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;

    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

  public dragStartedHandler(app: AppListModel, draggedIndex?: number): void {
    this.draggedApp = app;
    this.draggedIndex = draggedIndex;
    this.isMovedElement = true;
  }

  public showControlHandler(): void {
    this.showControl = true;
    this.folderNameControl.nativeElement.value = this.folderItems.name ? this.folderItems.name.trim() : null;
    this.folderNameControl.nativeElement.focus();
  }

  public getAppActionList(app: AppListModel): AppActionsListModel {
    return {
      app: app,
      actions: [
        ...this.appDataByModuleName[app.name].actionList,
        RemoveActionConfig
      ]
    };
  }

  public getAppDescriptionFontSize(parentElementWidth: number, ratio: number): number {
    const minSize = 14;
    const calculatedSize = parentElementWidth * ratio;
    return calculatedSize > minSize ? calculatedSize : minSize;
  }

  private showMsg(message: string): void {
    this.toasterService.notify({type: 'error', message});
  }

  private getAppListConfig(): void {
    const wholesaleEnabled = this.systemPreferencesPermissionsService.wholesaleEnabled();
    const servicesEnabled = this.systemPreferencesPermissionsService.servicesEnabled();
    const ecommerceEnabled = this.systemPreferencesPermissionsService.ecommerceEnabled();
    const canViewWholesale = this.userPermissionsService.canViewWholesale();
    const canViewServices = this.userPermissionsService.canViewServices();
    const canViewEcommerce = this.userPermissionsService.canViewEcommerce();

    this.appDataByModuleName = AppDataByModuleName(
      this.userPermissionsService.canManageProducts(),
      this.userPermissionsService.canManagePartners(),
      this.userPermissionsService.canManageWarehouse(),
      this.userPermissionsService.canManageAccounting(),
      this.userPermissionsService.canManageWholesale(),
      this.userPermissionsService.canManageServices(),
      this.productOptions.goodsEnabled,
      this.productOptions.servicesEnabled,
      this.productOptions.digitalEnabled,
      this.systemPreferencesPermissionsService.corporatePartnersEnabled(),
      this.systemPreferencesPermissionsService.privatePartnersEnabled(),
      (canViewWholesale || canViewServices) && (wholesaleEnabled || servicesEnabled),
      (canViewWholesale || canViewEcommerce || canViewServices) && (wholesaleEnabled || ecommerceEnabled || servicesEnabled),
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}

function __indexOf(collection, node): number {
  return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number): boolean {
  const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
