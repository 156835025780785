export function getYearsList(startYear: number = 2020): string[] {
  const years: string[] = [];
  const currentYear = new Date().getFullYear();

  for (let i = startYear; i <= currentYear; i++) {
    years.push(i.toString());
  }

  return years;
}

export function getYearsNumbersList(startYear: number = 2020): number[] {
  const years: number[] = [];
  const currentYear = new Date().getFullYear();

  for (let i = startYear; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
}
