import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypesUppercaseEnum } from '../../modals/modals-common/link-document-modal/enums/ducument-types.enum';

@Pipe({name: 'documentIcon'})
export class DocumentIconPipe implements PipeTransform {
  transform(documentType: DocumentTypesUppercaseEnum, ...args: any[]): string {
    const placeholderIcon: string = 'icon-checkbox-off';

    const icons = {
      [DocumentTypesUppercaseEnum.SO]: 'icon-sales-order',
      [DocumentTypesUppercaseEnum.PO]: 'icon-purchase-order',
      [DocumentTypesUppercaseEnum.DN]: 'icon-outgoing-delivery',
      [DocumentTypesUppercaseEnum.ERA]: 'icon-exchange-return',
      [DocumentTypesUppercaseEnum.OFR]: 'icon-offer',
      [DocumentTypesUppercaseEnum.OIN]: 'icon-outgoing-invoice',
      [DocumentTypesUppercaseEnum.IIN]: 'icon-incoming-invoice',
      [DocumentTypesUppercaseEnum.CRN]: 'icon-credit-note',
      [DocumentTypesUppercaseEnum.INCOMING_DELIVERY]: 'icon-incoming-delivery',
      [DocumentTypesUppercaseEnum.IN]: 'icon-incoming-delivery',
      [DocumentTypesUppercaseEnum.SBC]: 'icon-subscriptions',
      [DocumentTypesUppercaseEnum.SP]: 'icon-recurring-costs',
    };

    return icons[documentType] || placeholderIcon;
  }
}
