import { UserListTabs } from './pages/user-list/enums/user-list-tabs.enum';

export function teamHints(status?: UserListTabs | string, hasTeams?: boolean) {
  const hintsList = {
    // ['filter_button_team']: {
    //   text: 'MEGA_HINT.TEAM.HINT_11',
    //   attachTo: {
    //     element: '#filterBtn',
    //     on: 'bottom-start'
    //   },
    // },
    ['export_button_team']: {
      text: 'MEGA_HINT.TEAM.HINT_12',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom-start'
      },
    },
  }

  const exportListBtn = {
    ['export_button_team_list']: {
      text: 'MEGA_HINT.TEAM.HINT_12',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom-start'
      },
    },
  }

  const totalRowHints = {
    ['total_row_team']: {
      text: 'MEGA_HINT.TEAM.HINT_27',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }

  const inactiveStatusHints = {
    ['team_list_2']: {
      text: 'MEGA_HINT.TEAM.HINT_2',
      attachTo: {
        element: '#inactiveTab',
        on: 'bottom-start'
      },
    },
  }

  const activeStatusHints = {
    ['team_list_3']: {
      text: 'MEGA_HINT.TEAM.HINT_3',
      attachTo: {
        element: '#lastActivityColumn',
        on: 'bottom-start'
      },
    },
    ['team_list_1']: {
      text: 'MEGA_HINT.TEAM.HINT_1',
      attachTo: {
        element: '#activeTab',
        on: 'bottom-start'
      },
    },
  }

  const createBtnHint = {
    ['create_entity_button_team']: {
      text: 'MEGA_HINT.TEAM.HINT_4',
      attachTo: {
        element: '#createEmployeeBtn',
        on: 'bottom-start'
      },
    },
  }

  const deletesTabHint = {
    ['tab_deleted_team']: {
      text: 'MEGA_HINT.TEAM.HINT_26',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  if (status === UserListTabs.Inactive) { Object.assign(hintsList, inactiveStatusHints); }
  if (status === UserListTabs.Active) {
    Object.assign(hintsList, activeStatusHints);

    if(hasTeams) {
      Object.assign(hintsList, totalRowHints);
    }
  }
  if (status !== UserListTabs.Deleted) { Object.assign(hintsList, createBtnHint); }
  if (status === UserListTabs.Deleted) { Object.assign(hintsList, deletesTabHint); }
  if (hasTeams) { Object.assign(hintsList, exportListBtn); }

  return hintsList;

}

export function GetTeamProfileHints(
  readOnly: boolean,
  screenName: string,
  displayChangeEmailHint: boolean,
  isMyProfile: boolean,
  status?: UserListTabs | string,
  activeTab?: string,
  hasTeams?: boolean
) {
  const hintsList = {
    ['team_employee_profile_1']: {
      text: 'MEGA_HINT.TEAM.HINT_5',
      attachTo: {
        element: '#roleAndPermissionTab',
        on: 'bottom-start'
      },
    },
  }

  const roleAndPermissionHints = {
    ['team_employee_roles_1']: {
      text: 'MEGA_HINT.TEAM.HINT_17',
      attachTo: {
        element: '#viewColumn',
        on: 'top'
      },
    },
    ['team_employee_roles_2']: {
      text: 'MEGA_HINT.TEAM.HINT_18',
      attachTo: {
        element: '#editColumn',
        on: 'bottom'
      },
    },
    ['team_employee_roles_3']: {
      text: 'MEGA_HINT.TEAM.HINT_19',
      attachTo: {
        element: '#appColumn',
        on: 'bottom'
      },
    },
    ['team_employee_roles_4']: {
      text: 'MEGA_HINT.TEAM.HINT_20',
      attachTo: {
        element: '#lockedColumn',
        on: 'bottom'
      },
    },
    ['team_employee_roles_5']: {
      text: 'MEGA_HINT.TEAM.HINT_25',
      attachTo: {
        element: '#manageColumn',
        on: 'bottom'
      },
    },
  }

  const profileHints = {
    ['team_employee_profile_2']: {
      text: 'MEGA_HINT.TEAM.HINT_6',
      attachTo: {
        element: '#emailAddress',
        on: 'bottom-start'
      },
    },
  }

  const editProfileHint = {
    ['edit_entity_button_team']: {
      text: 'MEGA_HINT.TEAM.HINT_8',
      attachTo: {
        element: '#editProfileBtn',
        on: 'bottom'
      },
    },
  }

  const deleteCurrentImgHint = {
    ['team_employee_profile_5']: {
      text: 'MEGA_HINT.TEAM.HINT_9',
      attachTo: {
        element: '#deleteCurrentImg',
        on: 'top'
      },
    },
  }

  const preferencesHint = {
    ['team_employee_profile_3']: {
      text: 'MEGA_HINT.TEAM.HINT_7',
      attachTo: {
        element: '#preferences',
        on: 'bottom-start'
      },
    },
  }

  const changeEmailHint = {
    ['team_employee_profile_4']: {
      text: 'MEGA_HINT.TEAM.HINT_8',
      attachTo: {
        element: '#changeEmailBtn',
        on: 'bottom-start'
      },
    },
  }

  const deletedBtnHint = {
    ['delete_entity_button_team']: {
      text: 'MEGA_HINT.TEAM.HINT_21',
      attachTo: {
        element: '#deletedBtn',
        on: 'bottom'
      },
    },
  }

  const deactivatedBtnHint = {
    ['deactivate_entity_button_team']: {
      text: 'MEGA_HINT.TEAM.HINT_22',
      attachTo: {
        element: '#deactivatedBtn',
        on: 'bottom'
      },
    },
  }

  const doneBtnHint = {
    ['done_button_team']: {
      text: 'MEGA_HINT.TEAM.HINT_24',
      attachTo: {
        element: '#doneBtn',
        on: 'bottom'
      },
    },
  }

  const savedInfoHint = {
    ['save_timer_team']: {
      text: 'MEGA_HINT.TEAM.HINT_23',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  if (!readOnly) {
    if (isMyProfile && activeTab === 'profile') {
      Object.assign(hintsList, deleteCurrentImgHint);
    }

    if (displayChangeEmailHint) {
      Object.assign(hintsList, changeEmailHint);
    }

    Object.assign(hintsList, doneBtnHint);
    Object.assign(hintsList, savedInfoHint);
  }

  if (isMyProfile) {
    Object.assign(hintsList, preferencesHint);
  }

  if (!isMyProfile && readOnly && status !== UserListTabs.Deleted) {
    Object.assign(hintsList, deletedBtnHint);
  }

  if (readOnly && status === UserListTabs.Active) {
    Object.assign(hintsList, editProfileHint);
  }

  if (readOnly && status === UserListTabs.Active && !isMyProfile) {
    Object.assign(hintsList, deactivatedBtnHint);
  }

  if (activeTab === 'roles') {
    Object.assign(hintsList, roleAndPermissionHints);
  }

  if (activeTab === 'profile' && !readOnly) {
    Object.assign(hintsList, profileHints)
  }

  if (screenName === 'team_employee_profile') {
    return hintsList
  }

  return teamHints(status, hasTeams);

}

export function createUserHints(setUpUserPermission: boolean) {
  const hintsList = {
    ['field_validation_team_create_profile']: {
      text: 'MEGA_HINT.TEAM.HINT_13',
      attachTo: {
        element: '#lastNameField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['team_create_employee_profile_1']: {
      text: 'MEGA_HINT.TEAM.HINT_14',
      attachTo: {
        element: '#departmentForm',
        on: 'top'
      },
      classes: 'from-modal',
    },
    ['team_create_employee_profile_2']: {
      text: 'MEGA_HINT.TEAM.HINT_15',
      attachTo: {
        element: '#setUpPermissionField',
        on: 'bottom'
      },
      classes: 'from-modal',
    },

  };

  const userPermissionHints = {
    ['team_create_employee_profile_3']: {
      text: 'MEGA_HINT.TEAM.HINT_16',
      attachTo: {
        element: '#manageColumn',
        on: 'bottom'
      },
      classes: 'from-modal break-spaces',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  if (setUpUserPermission) {
    Object.assign(hintsList, userPermissionHints);
  }

  return hintsList
}

