import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'projects/workspace/src/environments/environment';
import { DocumentTypesUppercaseEnum } from '../modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { ResponseModel } from 'projects/workspace/src/app/shared/models';
import { FieldUpdateRequestModel, ProtoPositionModel } from '../models/proto-position.model';
import { ProductTypes } from '../modules/products/product-types';
import { CreditNotePurposeEnum } from 'projects/workspace/src/app/credit-note/enums';
import { ToasterService } from '../modules/ui-components/toaster';

@Injectable({
  providedIn: 'root'
})
export class RecalculatePositionApiService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/positions`;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly http: HttpClient,
    private readonly toasterService: ToasterService,
  ) { }

  public recalculatePosition(
    documentType: DocumentTypesUppercaseEnum,
    positionData: {
      protoPosition: ProtoPositionModel,
      fieldUpdateRequest: FieldUpdateRequestModel,
      type: ProductTypes | string,
      crnPurpose?: CreditNotePurposeEnum,
      documentId?: number
    }): Observable<any> {
    return this.http.post<ResponseModel<ProtoPositionModel>>(
      this.apiUrl(`/${documentType}`),
      positionData
    )
      .pipe(
        map((data: ResponseModel<ProtoPositionModel>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message || error.error.errors);
          return throwError(error);
        })
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }
}
