import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TypeViewEnum } from '../../../models/positions-view.enum';

@Component({
  selector: 'rnpl-change-positions-view-button',
  templateUrl: './change-positions-view-button.component.html',
})
export class ChangePositionsViewButtonComponent {

  public TypeViewEnum = TypeViewEnum;

  public translatedTypeView: {[key: string]: string} = {
    ['table']: 'BUTTON.TABLE',
    ['card']: 'BUTTON.CARDS'
  };

  @Input() public typeView: TypeViewEnum;

  @Output() changeTypeView: EventEmitter<any> = new EventEmitter();

  public onChangeTypeView(type: TypeViewEnum) {
    this.changeTypeView.emit(type);
  }

}
