import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Renderer2, RendererFactory2,
  ViewContainerRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';

import { CONTROLS } from './controls';
import { ControlDefinitionModel, ControlRule } from './control-definition.model';

@Injectable()
export class DynamicFormService {

  private static FACTORIES: Map<string, ComponentFactory<any>> = new Map();

  private _formUpdated$ = new BehaviorSubject<boolean>(false);
  public formUpdated$ = this._formUpdated$.asObservable();

  private renderer: Renderer2;

  constructor(private factoryResolver: ComponentFactoryResolver, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public initAttrPreview(viewContainer: ViewContainerRef, attribute: any): Observable<any> {
    if (!attribute) { return null; }

    const factory = this.getFactory(attribute.control);
    const component: ComponentRef<any> = viewContainer.createComponent(factory);

    component.instance.key = `${attribute.control}_${attribute.id}`;

    if (component.instance.hasOwnProperty('control')) {
      component.instance.control = new FormControl(null);
    }

    if (component.instance.hasOwnProperty('label')) {
      component.instance.label = attribute.name;
    }

    if (component.instance.hasOwnProperty('options')) {
      component.instance.options = attribute.options.map(option => {
        return {label: option.eng, value: option.eng};
      });
    }

    if (component.instance.hasOwnProperty('attributes')) {
      component.instance.attributes = attribute.attributes || '';
    }

    component.instance.optional = !attribute.required;
    component.changeDetectorRef.markForCheck();
    return of(null);
  }

  public initControl(viewContainer: ViewContainerRef, definition: ControlDefinitionModel, formGroup: FormGroup): Observable<any> {
    const factory = this.getFactory(definition.control);

    const control = new FormControl({value: definition.value || null, disabled: definition.disabled});
    formGroup.addControl(definition.key, control);
    const component: ComponentRef<any> = viewContainer.createComponent(factory);
    if (component.instance.hasOwnProperty('control')) {
      component.instance.control = control;

      const sub: Subscription = component.instance.formUpdated.subscribe(value => {
        this._formUpdated$.next(value);
      });

      component.onDestroy(() => sub.unsubscribe());
    }
    this.bindProps(component, definition);
    this.bindRules(component, definition.rules);

    component.changeDetectorRef.markForCheck();
    return of(null);
  }

  private bindProps(component: ComponentRef<any>, definition: ControlDefinitionModel): void {
    component.instance.key = definition.key;
    this.renderer.addClass(component.location.nativeElement, `col-md-${definition.layout.width}`);

    if (!definition.properties) {
      return;
    }
    Object.keys(definition.properties).forEach((property: string) => {
      if (component.instance.hasOwnProperty(property)) {
        component.instance[property] = definition.properties[property];
      }
    });
  }

  private bindRules(component: ComponentRef<any>, rules: Array<ControlRule>): void {
    if (!rules || !rules.length) {
      return;
    }

    rules.forEach((rule: ControlRule) => {
      switch (rule.type) {
        case 'options':
          if (component.instance.hasOwnProperty('options')) {
            component.instance.options = rule.options;
          }
          break;

        case 'required':
          if (rule.value === true) {
            component.instance.control.setValidators([Validators.required]);
            component.instance.control.updateValueAndValidity();
            component.instance.optional = false;
          }
          break;

        default:
      }
    });
  }

  /**
   * Returns component factory
   *
   * @param control Name of control
   */
  private getFactory(control: string): ComponentFactory<any> {
    if (!CONTROLS.hasOwnProperty(control)) {
      return null;
    }
    if (!DynamicFormService.FACTORIES.has(control)) {
      DynamicFormService.FACTORIES.set(control, this.factoryResolver.resolveComponentFactory(CONTROLS[control]));
    }

    return DynamicFormService.FACTORIES.get(control);
  }
}
