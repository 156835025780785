import { createReducer, on } from '@ngrx/store';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { CollectionSalesOrderActions } from '../actions/action-types';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { SalesOrderModel } from '../../models/sales-order.model';
import { CorporatePartnerModel } from '../../../crm/models';
import { SalesPositionsByProductType } from '../../models/sales-order-position.model';
import { ResponseModel } from '../../../shared/models/response';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { SalesOrderListTabsEnum } from '../../enums';

export const SalesOrderFeatureKey = 'salesOrderStore';

export interface SalesOrdersListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: SalesOrderModel[];
  }
}
export interface SalesOrdersState {
  [SalesOrderListTabsEnum.DRAFT]?: SalesOrdersListState;
  [SalesOrderListTabsEnum.OPEN]?: SalesOrdersListState;
  [SalesOrderListTabsEnum.COMPLETED]?: SalesOrdersListState;
  [SalesOrderListTabsEnum.CANCELED]?: SalesOrdersListState;
  [SalesOrderListTabsEnum.DELETED]?: SalesOrdersListState;
  [SalesOrderListTabsEnum.TEMPLATE]?: SalesOrdersListState;
}

export interface SalesOrderStore {
  entity: SalesOrderState;
  list: SalesOrdersListState;
  blocksValidations: SalesOrderBlocksValidationsState;
}

export interface SalesOrderState {
  salesOrder: SalesOrderModel;
  currentState: UIStatesEnum;
  isInvoiceExist: boolean;
  countries: CountryModel[];
  positions: ResponseModel<SalesPositionsByProductType>;
  positionsCount: number;
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export const initialSalesOrderState: SalesOrderState = {
  salesOrder: null,
  countries: [],
  currentState: UIStatesEnum.CREATE,
  isInvoiceExist: false,
  positions: null,
  positionsCount: 0,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false
};

export const initialSalesOrdersState: SalesOrdersState = {
  [SalesOrderListTabsEnum.DRAFT]: {},
  [SalesOrderListTabsEnum.OPEN]: {},
  [SalesOrderListTabsEnum.COMPLETED]: {},
  [SalesOrderListTabsEnum.CANCELED]: {},
  [SalesOrderListTabsEnum.DELETED]: {},
  [SalesOrderListTabsEnum.TEMPLATE]: {},
}

export interface SalesOrderBlocksValidationsState {
  propertiesBlockValid: boolean;
  deliveryInfoBlockValid: boolean;
  billingInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialSalesOrderBlocksValidationsState: SalesOrderBlocksValidationsState = {
  propertiesBlockValid: false,
  deliveryInfoBlockValid: false,
  billingInfoBlockValid: false,
  positionsBlockValid: false,
};

export function SalesOrderReducer(state, action) {
  return _SalesOrderReducer(state, action);
}

export function SalesOrderListReducer(state, action) {
  return _SalesOrderListReducer(state, action);
}

export function SalesOrderBlocksValidationsReducer(state, action) {
  return _SalesOrderBlocksValidationsReducer(state, action);
}

const _SalesOrderBlocksValidationsReducer = createReducer(
  initialSalesOrderBlocksValidationsState,
  on(CollectionSalesOrderActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
    ...state,
    propertiesBlockValid
  })),
  on(CollectionSalesOrderActions.UpdateDeliveryInfoBlockValid, (state, { deliveryInfoBlockValid }) => ({
    ...state,
    deliveryInfoBlockValid
  })),
  on(CollectionSalesOrderActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionSalesOrderActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionSalesOrderActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
);

const _SalesOrderListReducer = createReducer(
  initialSalesOrdersState,
  on(CollectionSalesOrderActions.LoadSalesOrdersList, (state, { salesOrderListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...salesOrderListData
    },
  })),
);

const _SalesOrderReducer = createReducer(
  initialSalesOrderState,
  // SalesOrder
  on(CollectionSalesOrderActions.LoadSalesOrder, (state, { salesOrder }) => ({
    ...state,
    salesOrder
  })),
  on(CollectionSalesOrderActions.ClearSalesOrder, (state) => ({
    ...state,
    salesOrder: null
  })),
  on(CollectionSalesOrderActions.ClearSalesOrderState, (state) => ({
    ...state,
    salesOrder: null,
    currentState: UIStatesEnum.CREATE,
    isInvoiceExist: false,
    positions: null,
    positionsCount: 0,
    loadingRequestsCount: 0,
    updatedAt: null
  })),
  on(CollectionSalesOrderActions.ClearSalesOrderPositions, (state) => ({
    ...state,
    positions: null
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrder, (state, { salesOrder }) => ({
    ...state,
    salesOrder
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrderSummary, (state, { summary }) => ({
    ...state,
    salesOrder: {
      ...state.salesOrder,
      summary
    }
  })),
  on(CollectionSalesOrderActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionSalesOrderActions.LoadSalesOrderPositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrderPositionsCount, (state, { positionsCount }) => ({
    ...state,
    positionsCount
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrderStatus, (state, { status}) => ({
    ...state,
    salesOrder: {
      ...state.salesOrder,
      status: status
    }
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrderUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionSalesOrderActions.LoadSalesOrderCountries, (state, {countries}) => ({
    ...state,
    countries
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrderCurrentState, (state, { currentState }) => ({
    ...state,
    currentState: currentState
  })),
  on(CollectionSalesOrderActions.UpdateSalesOrderExistingInvoice, (state, { isInvoiceExist }) => ({
    ...state,
    isInvoiceExist
  })),
  on(CollectionSalesOrderActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionSalesOrderActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
);
