export enum OcrDocumentFieldTypeEnum {
  EXTERNAL_NUMBER = 'EXTERNAL_NUMBER',
  BIC = 'BIC',
  // CURRENCY = 'CURRENCY',
  DUE_DATE = 'DUE_DATE',
  IBAN = 'IBAN',
  ISSUE_DATE = 'ISSUE_DATE',
  SCONTO_AMOUNT = 'SCONTO_AMOUNT',
  SCONTO_RATE = 'SCONTO_RATE',
  SCONTO_DUE_DATE = 'SCONTO_DUE_DATE',
  PROVIDED_FROM_TO = 'PROVIDED_FROM_TO',
  GROSS_TOTAL = 'GROSS_TOTAL',
  VAT_TOTAL = 'VAT_TOTAL',
  VAT_PERCENT = 'VAT_PERCENT',
  NET_TOTAL = 'NET_TOTAL',
  // PARTNER_NAME = 'PARTNER_NAME',
}
