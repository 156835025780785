import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'rnpl-table-action-bar',
  templateUrl: './table-action-bar.component.html',
  styleUrls: ['./table-action-bar.component.scss']
})
export class TableActionBarComponent { // todo delete

  @Input() public selectedRows: any[] = [];

  @Input() public activeStatus$: Observable<string>;

  @Input() public deletePermanentlyBtnIsDisabled: boolean = true;

  @Output() action = new EventEmitter<string>();

  changeActiveStatus(status: string) {
    this.action.emit(status);
  }

  constructor() { }

}
