import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BankAccountsApiService } from 'projects/workspace/src/app/bank-accounts/services/bank-accounts-api.service';
import { BankAccountModel } from 'projects/workspace/src/app/bank-accounts/models';

@Component({
  selector: 'rnpl-create-new-bank-account-modal',
  templateUrl: './create-new-bank-account-modal.component.html'
})
export class CreateNewBankAccountModalComponent extends BaseModalComponent implements OnDestroy {
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    private bankAccountsApiService: BankAccountsApiService,
    public dialogRef: MatDialogRef<CreateNewBankAccountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      primaryBankAccountExists: boolean
    }
  ) {
    super(toasterService);
  }

  public submit(bankAccount: Partial<BankAccountModel>): void {
    if (!bankAccount) { return; }
    if (this.submitRequest$.getValue()) { return; }

    // bankAccount = {
    //   ...bankAccount,
    //   primaryAccount: bankAccount.primary
    // };

    this.submitRequest$.next(true);
    this.bankAccountsApiService.createBankAccount(bankAccount)
      .pipe(
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((response: BankAccountModel) => this.dialogRef.close(response));
  }

}
