import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'attrNameTranslatePipe'})
export class AttrNameTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(value: string, ...args: any[]): string {
    if (!value) { return value; }

    let translateKey: string;

    switch (value) {
      case 'Input':
        translateKey = 'CONTROL.INPUT';
        break;
      case 'Textarea':
        translateKey = 'CONTROL.TEXTAREA';
        break;
      case 'Dropdown':
        translateKey = 'CONTROL.DROPDOWN';
        break;
      case 'Toggle':
        translateKey = 'CONTROL.TOGGLE';
        break;
      case 'Radiobutton':
        translateKey = 'CONTROL.RADIO';
        break;
      case 'Combo-box':
        translateKey = 'CONTROL.COMBOBOX';
        break;
      case 'Barcode':
        translateKey = 'CONTROL.BARCODE';
        break;
    }

    return translateKey
      ? this.translateService.instant(translateKey)
      : value;
  }
}
