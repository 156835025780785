import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { BaseModalComponent } from '../../../rnpl-common/components';
import { ToasterService } from '../../../ui-components/toaster';
import { SalesOrderConflictPositionModel, SalesOrderIssues, SalesOrderModel, SalesOrderPositionModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { ProductModel, ProductsService } from '../../../products';
import { ProductTypes } from '../../../products/product-types';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { CustomSearchFn, getProductSearchValue } from '../../../rnpl-common/helpers';

@Component({
  selector: 'rnpl-resolve-conflict-modal',
  templateUrl: './resolve-conflict-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResolveConflictModalComponent extends BaseModalComponent implements OnInit {
  public issues: SalesOrderIssues;
  public productTypes = ProductTypes;

  public productsList: ProductModel[] = [];
  public issuedPositions: SalesOrderConflictPositionModel[] = [];

  public productType = PRODUCTS_TYPE;
  public customSearchFn = CustomSearchFn;

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public productsService: ProductsService,
    public salesOrderApi: SalesOrderApiService,
    public readonly dialogRef: MatDialogRef<ResolveConflictModalComponent>,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { salesOrder: SalesOrderModel }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.issues = cloneDeep(this.salesOrder.issues);
    this.issuedPositions = cloneDeep(
      this.salesOrder.issues.positions.filter((p: SalesOrderConflictPositionModel) => !p.productId)
    );

    this.getProducts();
  }

  private getProducts(): void {
    this.productsService.getActiveProducts()
      .pipe(takeUntil(this._destroy))
      .subscribe((products: ProductModel[]) => {
        this.productsList = this.prepareProductsList(products);
        if (this.issuedPositions.length) {
          this.issuedPositions = this.issuedPositions.map((p: SalesOrderConflictPositionModel) => ({
            ...p,
            productsList: [
              ...this.productsList.filter((product: ProductModel) => product.type === p.productType)
            ] // add own product list to each conflicted position
          }));
          this.cdr.detectChanges();
        }
      });
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValue(item, this.translateService)
    }));
  }

  public selectProduct(product: ProductModel, position: SalesOrderConflictPositionModel): void {
    // update position for view template
    position.productId = +product.id;
    position.productName = product.name;
    position.productType = product.type as ProductTypes;

    this.updIssuesPositionProductId(position.positionId, +product.id);
  }


  public updIssuesPositionProductId(positionId: number, value: number): void {
    // update position sent data
    const index = this.issues.positions.findIndex((p: SalesOrderPositionModel) => p.positionId === positionId);
    this.issues.positions[index].productId = value;
  }

  // public updIssuesPositionProductServiceType(positionId: number, serviceType: ServiceProvidingTypes): void {
  //   // update position sent data
  //   const index = this.issues.positions.findIndex((p: SalesOrderPositionModel) => p.positionId === positionId);
  //   this.issues.positions[index].serviceProvidingType = serviceType;
  // }

  // public getContactFullName(contact: PartnerContactModel): string {
  //   return `${contact.first_name} ${contact.last_name}`;
  // }

  public submitResolve(): void {
    const issues = {
      // customer: {
      //   company_id: get(this, 'issues.customer.company.id', null),
      //   company_contact_id: get(this, 'issues.customer.company_contact.id', null),
      // },
      positions: this.issues.positions
    };

    this.salesOrderApi.resolveSalesOrderIssue(this.salesOrder.id, issues)
      .pipe(takeUntil(this._destroy))
      .subscribe((salesOrder: SalesOrderModel) => {
        this.displayMessage('success', 'SO.ISSUES_RESOLVED');
        this.dialogRef.close(salesOrder);
      }, error => {
        this.displayMessage('error', error.error.message);
      });
  }

  get salesOrder(): SalesOrderModel { return this.data.salesOrder; }

  onClick(result: boolean): void {
    this.dialogRef.close(result);
  }
}
