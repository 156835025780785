import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { take } from 'rxjs/operators';

import { SoundService } from 'common/src/services/sound.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ProductsService } from 'common/src/modules/products/products.service';

@Component({
  selector: 'rnpl-delete-family-modal',
  templateUrl: './delete-family-modal.component.html'
})
export class DeleteFamilyModalComponent implements OnInit {

  constructor(
    private soundService: SoundService,
    private productsService: ProductsService,
    private toasterService: ToasterService,
    public dialogRef: MatDialogRef<DeleteFamilyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.soundService.playSound('modal_dangerous');
  }

  public confirmDeletion(): void {
    this.productsService.deleteFamily(this.data.family.id)
      .pipe(take(1))
      .subscribe(response => {
        this.dialogRef.close(response);
      }, err => {
        this.toasterService.notify({type: 'error', message: err.error.message});
        this.dialogRef.close();
      });
  }

}
