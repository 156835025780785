import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GeneralPartnerTypeEnum } from '../../../models';

@Pipe({name: 'generalPartnerTranslatePipe'})
export class GeneralPartnerTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(value: GeneralPartnerTypeEnum, ...args: any[]): string {
    if (!value) { return value; }

    let translateKey: string;

    switch (value) {
      case GeneralPartnerTypeEnum.INLAND:
        translateKey = 'GENERAL_PARTNER.INLAND';
        break;
      case GeneralPartnerTypeEnum.EU:
        translateKey = 'GENERAL_PARTNER.EU';
        break;
      case GeneralPartnerTypeEnum.NON_EU:
        translateKey = 'GENERAL_PARTNER.NON_EU';
        break;
    }

    return translateKey
      ? this.translateService.instant(translateKey)
      : value;
  }
}
