import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { tap, map, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { AppState } from '../../store/state/app.state';
import { environment } from 'projects/workspace/src/environments/environment';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FormInputChangedModel, ResponseList, ResponseModel } from '../../shared/models';
import {
  ScheduledPaymentBillingPeriodModel,
  ScheduledPaymentBillingScheduleModel,
  ScheduledPaymentListTotalsModel,
  ScheduledPaymentModel
} from '../models';
import { ScheduledPaymentListTabsEnum } from '../enums';
import { FilterModelNew } from '../../outgoing-invoice/models/filter-model-new';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadCurrencies,
  LoadScheduledPayment,
  LoadScheduledPaymentPeriods,
  LoadScheduledPaymentsList,
  UpdateScheduledPaymentAttachmentsCount,
  UpdateScheduledPaymentState,
  UpdateScheduledPaymentSyncPeriods,
  UpdateScheduledPaymentUpdatedAt,
  UpdateShouldRefreshEntity
} from '../store/actions/scheduled-payment.actions';
import { AccountingPositionModel, AccountingPositionsGroupModel } from '../../accounting/models';
import { CurrencyModel } from '../../payment/models/currency.model';
import { File, LinkedDocumentModel } from '../../incoming-invoice/models/incoming-invoice.model';
import { ChangeStatusOperationsEnum } from '../../outgoing-invoice/enums';
import { EditModel } from '../../outgoing-invoice/models/edit.model';
import { PeriodModel } from '../../subscription/models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class ScheduledPaymentApiService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/scheduled-payment`;

  constructor(
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly store: Store<AppState>
  ) { }

  public createScheduledPaymentBlank(): Observable<ScheduledPaymentModel> {
    return this.http.request<ResponseModel<ScheduledPaymentModel>>('post', `${this.apiEndpoint}`)
      .pipe(
        map((data: ResponseModel<ScheduledPaymentModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public createScheduledPaymentBasedOnIIN(iinId: number): Observable<ScheduledPaymentModel> {
    return this.http.request<ResponseModel<ScheduledPaymentModel>>('post', `${this.apiEndpoint}/based-on/iin/${iinId}`)
      .pipe(
        map((data: ResponseModel<ScheduledPaymentModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateScheduledPaymentField(spId: number, fieldName: string, fieldValue: any): Observable<ScheduledPaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { fieldName, fieldValue };

    return this.http.patch<ResponseModel<ScheduledPaymentModel>>(`${this.apiEndpoint}/${spId}`, body)
      .pipe(
        tap((response: ResponseModel<ScheduledPaymentModel>) => {
          this.store.dispatch(LoadScheduledPayment({ scheduledPayment: response.data }));
          this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<ScheduledPaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public getScheduledPaymentSchedule(spId: number): Observable<ScheduledPaymentBillingScheduleModel> {
    return this.http.get<ResponseModel<ScheduledPaymentBillingScheduleModel>>(`${this.apiEndpoint}/${spId}/schedule`)
      .pipe(
        map((response: ResponseModel<ScheduledPaymentBillingScheduleModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public updateScheduledPaymentScheduleField(
    spId: number,
    schedule: ScheduledPaymentBillingScheduleModel,
    fieldName: string,
    fieldValue: any
  ): Observable<ScheduledPaymentBillingScheduleModel> {
    const body = {
      schedule,
      request: {
        fieldName,
        fieldValue
      }
    };

    return this.http.patch<ResponseModel<ScheduledPaymentBillingScheduleModel>>(`${this.apiEndpoint}/${spId}/schedule`, body)
      .pipe(
        map((response: ResponseModel<ScheduledPaymentBillingScheduleModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  getScheduledPaymentFuturePeriods(wid: number, spId: number): Observable<PeriodModel[]> {
    const params = {
      wid: wid.toString(),
    };
    return this.http.get<ResponseModel<PeriodModel[]>>(`${this.apiEndpoint}/${spId}/future-periods`, { params })
      .pipe(map((response: ResponseModel<PeriodModel[]>) => response.data));
  }

  public getScheduledPaymentsList(
    status: ScheduledPaymentListTabsEnum,
    pagination: PaginationModel,
    sort: FilterModelNew,
    filters: any = {}
  ): Observable<ResponseList<ScheduledPaymentModel>> {

    const params = {
      status,
      page: pagination.page,
      sortBy: sort.sortBy,
      direction: sort.direction,
      length: pagination.per_page,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<ScheduledPaymentModel>>(`${this.apiEndpoint}/list`, { params })
      .pipe(tap((data: ResponseList<ScheduledPaymentModel>) => {
        this.store.dispatch(LoadScheduledPaymentsList({
          scheduledPaymentListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getScheduledPaymentFilterRunpleIds(wid: number, status: ScheduledPaymentListTabsEnum): Observable<any[]> {
    const params = {
      wid: wid.toString(),
      status
    };
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/rids`, { params })
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  getScheduledPaymentFilterGeneralProducts(status: ScheduledPaymentListTabsEnum): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/filter/general-products/${status}`)
      .pipe(map((response: ResponseModel<any[]>) => response.data));
  }

  public getScheduledPaymentListTotals(status: ScheduledPaymentListTabsEnum, filters: any = {}): Observable<ScheduledPaymentListTotalsModel> {
    const params = { status };
    for (const [key, value] of Object.entries(filters)) {
      params[key] = Array.isArray(value) ? value.join(',') : value.toString();
    }
    return this.http.get<ResponseModel<ScheduledPaymentListTotalsModel>>(`${this.apiEndpoint}/totals`, {params})
      .pipe(map((data: ResponseModel<ScheduledPaymentListTotalsModel>) => data.data));
  }

  public getScheduledPaymentListCounters(): Observable<{[key in ScheduledPaymentListTabsEnum]?: number}> {
    return this.http.get<ResponseModel<{[key in ScheduledPaymentListTabsEnum]?: number}>>(`${this.apiEndpoint}/counts`)
      .pipe(map((data: ResponseModel<{[key in ScheduledPaymentListTabsEnum]?: number}>) => data.data));
  }

  public getScheduledPaymentPositions(spId: number): Observable<AccountingPositionsGroupModel> {
    return this.http.get<ResponseModel<AccountingPositionsGroupModel>>(`${this.apiEndpoint}/${spId}/positions`)
      .pipe(map((data: ResponseModel<AccountingPositionsGroupModel>) => data.data));
  }

  public getScheduledPaymentPositionsTotals(spId: number): Observable<any> {
    return this.http
      .get<ResponseModel<any>>(`${this.apiEndpoint}/${spId}/positions/totals`)
      .pipe(map((data: ResponseModel<any>) => data.data));
  }

  public createScheduledPaymentPosition(spId: number, position: Partial<AccountingPositionModel>): Observable<AccountingPositionsGroupModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<AccountingPositionsGroupModel>>(`${this.apiEndpoint}/${spId}/positions`, position).pipe(
      tap(() => this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }))),
      map((data: ResponseModel<AccountingPositionsGroupModel>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, spId);
        return throwError(error);
      })
    );
  }

  public createPositionByPredefinedForm(spId: number, position: Partial<AccountingPositionModel>): Observable<AccountingPositionsGroupModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<AccountingPositionsGroupModel>>(`${this.apiEndpoint}/${spId}/positions/prefilled`, position).pipe(
      tap(() => this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }))),
      map((data: ResponseModel<AccountingPositionsGroupModel>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, spId);
        return throwError(error);
      })
    );
  }

  public createCorrectionPositions(
    spId: number,
    body: { targetAmountNet: number; targetVat: number; targetAmountGross: number; }
  ): Observable<AccountingPositionsGroupModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<AccountingPositionsGroupModel>>(`${this.apiEndpoint}/${spId}/positions/correction`, body)
      .pipe(
        tap(() => this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }))),
        map((data: ResponseModel<AccountingPositionsGroupModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public updateScheduledPaymentPosition(
    spId: number,
    positionId: number,
    field: FormInputChangedModel
  ): Observable<AccountingPositionsGroupModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<AccountingPositionsGroupModel>>(`${this.apiEndpoint}/${spId}/positions/${positionId}`, field)
      .pipe(
        tap(() => this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }))),
        map((data: ResponseModel<AccountingPositionsGroupModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public deleteScheduledPaymentPositions(spId: number, ids: number[]): Observable<AccountingPositionsGroupModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = { ids };

    return this.http.request<ResponseModel<AccountingPositionsGroupModel>>(
      'delete',
      `${this.apiEndpoint}/${spId}/positions/batch`,
      { body }
    )
      .pipe(
        tap(() => this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }))),
        map((data: ResponseModel<AccountingPositionsGroupModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  changePositionOrder(positionId: number, moveToOrder: number, spId: number): Observable<any> {
    return this.http.request('patch', `${this.apiEndpoint}/${spId}/positions/${positionId}/order/${moveToOrder}`)
      .pipe(
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public applyScheduledPaymentActionBatch(
    ids: number[],
    action: ChangeStatusOperationsEnum,
    ignore = false
  ): Observable<any> {
    const body = { ids };
    const params = new HttpParams()
      .set('ignore', ignore.toString());

    return this.http.post(`${this.apiEndpoint}/batch/action/${action}`, body, { params })
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getScheduledPaymentById(id: string | number): Observable<ScheduledPaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<ScheduledPaymentModel>>(`${this.apiEndpoint}/${id}`).pipe(
      tap((response: ResponseModel<ScheduledPaymentModel>) => {
        this.store.dispatch(LoadScheduledPayment({ scheduledPayment: response.data }));
        this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }));
        this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
      }),
      map((response: ResponseModel<ScheduledPaymentModel>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }

  public getCurrencies(): Observable<CurrencyModel[]> {
    return this.http.get<ResponseModel<CurrencyModel[]>>(`${environment.javaApiVersion}/utils/currencies`).pipe(
      tap((response: ResponseModel<CurrencyModel[]>) => {
        this.store.dispatch(LoadCurrencies({ currencies: response.data }));
      }),
      map((response: ResponseModel<CurrencyModel[]>) => response.data)
    );
  }

  public startScheduledPaymentEditing(spId: number, force = false, proceed = false): Observable<EditModel> {
    const params = new HttpParams()
      .set('force', force.toString())
      .set('proceed', proceed.toString());

    return this.http.get<EditModel>(
      `${environment.javaApiVersion}/utils/edit-flag/${DocumentTypesUppercaseEnum.SP}/${spId}/check-and-set`,
      {params}
      )
      .pipe(
        tap((response: EditModel) => this.store.dispatch(UpdateScheduledPaymentState({ currentState: UIStatesEnum.EDIT }))),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public finishScheduledPaymentEditing(spId: number): Observable<EditModel> {
    return this.http.get<EditModel>(`${environment.javaApiVersion}/utils/edit-flag/${DocumentTypesUppercaseEnum.SP}/${spId}/commit`)
      .pipe(
        tap((response: EditModel) => this.store.dispatch(UpdateScheduledPaymentState({ currentState: UIStatesEnum.VIEW }))),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public scheduledPaymentSaveAsDraft(spId: number): Observable<ScheduledPaymentModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<ScheduledPaymentModel>>('post', `${this.apiEndpoint}/${spId}`)
      .pipe(
        tap((response: ResponseModel<ScheduledPaymentModel>) => {
          this.store.dispatch(LoadScheduledPayment({ scheduledPayment: response.data }));
          this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<ScheduledPaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public scheduledPaymentApplyAction(spId: number, action: ChangeStatusOperationsEnum, ignore = false): Observable<ScheduledPaymentModel> {
    const params = new HttpParams()
      .set('ignore', ignore.toString());

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<ScheduledPaymentModel>>('post', `${this.apiEndpoint}/${spId}/action/${action}`, {params})
      .pipe(
        tap((response: ResponseModel<ScheduledPaymentModel>) => {
          this.store.dispatch(LoadScheduledPayment({ scheduledPayment: response.data }));
          this.store.dispatch(UpdateScheduledPaymentUpdatedAt({ updatedAt: new Date() }));
        }),
        map((response: ResponseModel<ScheduledPaymentModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public getScheduledPaymentListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/download?status=${status}`,
      type: 'zip',
    };
    return of(fileParams);
  }

  public getUploadPdfLink(spId: number): string {
    return `${environment.javaApiUrl}${environment.javaApiVersion}/scheduled-payment/${spId}/attachment`;
  }

  public getScheduledPaymentFiles(spId: number): Observable<File[]> {
    return this.http.get<ResponseModel<File[]>>(`${this.apiEndpoint}/${spId}/attachment`)
      .pipe(
        tap((response: ResponseModel<File[]>) => {
          this.store.dispatch(UpdateScheduledPaymentAttachmentsCount({attachmentsCount: response.data.length}));
        }),
        map((response: ResponseModel<File[]>) => response.data)
      );
  }

  public downloadScheduledPaymentFile(spId: number, fileId: number): Observable<Partial<FileUploadParams>> {
    const fileParams: Partial<FileUploadParams> = {
      url: `${this.apiEndpoint}/${spId}/attachment/${fileId}`,
    };
    return of(fileParams);
  }

  public deleteFile(spId: number, attachmentId: string): Observable<File[]> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<File[]>>(`${this.apiEndpoint}/${spId}/attachment/${attachmentId}`).pipe(
      tap((response: ResponseModel<File[]>) => {
        this.store.dispatch(UpdateScheduledPaymentAttachmentsCount({attachmentsCount: response.data.length}));
        this.store.dispatch(UpdateScheduledPaymentUpdatedAt({updatedAt: new Date()}));
      }),
      map((response: ResponseModel<File[]>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, spId);
        return throwError(error);
      })
    );
  }

  // BILLING PERIODS
  public getScheduledPaymentPeriods(spId: number): Observable<ScheduledPaymentBillingPeriodModel[]> {
    return this.http.get<ResponseModel<ScheduledPaymentBillingPeriodModel[]>>(`${this.apiEndpoint}/${spId}/period`)
      .pipe(
        tap((response: ResponseModel<ScheduledPaymentBillingPeriodModel[]>) => {
            this.store.dispatch(LoadScheduledPaymentPeriods({periods: response.data}));
          }),
        map((response: ResponseModel<ScheduledPaymentBillingPeriodModel[]>) => response.data),
      );
  }

  public getScheduledPaymentNotBilledPeriods(spId: number): Observable<ScheduledPaymentBillingPeriodModel[]> {
    return this.http.get<ResponseModel<ScheduledPaymentBillingPeriodModel[]>>(`${this.apiEndpoint}/${spId}/period/not-billed`)
      .pipe(
        map((response: ResponseModel<ScheduledPaymentBillingPeriodModel[]>) => response.data),
      );
  }

  public scheduledPaymentBillPeriod(
    spId: number,
    periodId: number,
    documentType: DocumentTypesUppercaseEnum.IIN | DocumentTypesUppercaseEnum.OPB
  ): Observable<LinkedDocumentModel> {
    return this.http.request<ResponseModel<LinkedDocumentModel>>('post', `${this.apiEndpoint}/${spId}/period/${periodId}/create/${documentType}`)
      .pipe(
        map((response: ResponseModel<LinkedDocumentModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public scheduledPaymentLinkPeriod(
    spId: number,
    periodId: number,
    linkableDocumentId: number,
    documentType: DocumentTypesUppercaseEnum.IIN | DocumentTypesUppercaseEnum.OPB,
  ): Observable<any> { // todo: type
    return this.http.request<any>('post', `${this.apiEndpoint}/${spId}/period/${periodId}/link/${documentType}/${linkableDocumentId}`)
      .pipe(
        map((response: any) => response.data),
        tap(() => this.store.dispatch(UpdateScheduledPaymentSyncPeriods({syncPeriods: {sync: true}}))),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public scheduledPaymentUnlinkPeriod(
    spId: number,
    linkedDocumentId: number,
    documentType: DocumentTypesUppercaseEnum.IIN | DocumentTypesUppercaseEnum.OPB,
  ): Observable<any> { // todo: type
    return this.http.request<any>('patch', `${this.apiEndpoint}/${spId}/period/unlink/${documentType}/${linkedDocumentId}`)
      .pipe(
        map((response: any) => response.data),
        tap(() => this.store.dispatch(UpdateScheduledPaymentSyncPeriods({syncPeriods: {sync: true}}))),
        catchError(error => {
          this.handlePopupErrors(error, spId);
          return throwError(error);
        })
      );
  }

  public scheduledPaymentIgnorePeriod(spId: number, periodId: number): Observable<any> { // todo: type
    return this.http.request<any>('patch', `${this.apiEndpoint}/${spId}/period/${periodId}/ignore`)
      .pipe(
        map((response: any) => response.data),
        tap(() => this.store.dispatch(UpdateScheduledPaymentSyncPeriods({syncPeriods: {sync: true}}))),
      );
  }

  public scheduledPaymentActivatePeriod(spId: number, periodId: number): Observable<any> { // todo: type
    return this.http.request<any>('patch', `${this.apiEndpoint}/${spId}/period/${periodId}/activate`)
      .pipe(
        map((response: any) => response.data),
        tap(() => this.store.dispatch(UpdateScheduledPaymentSyncPeriods({syncPeriods: {sync: true}}))),
      );
  }

  public getAvailableForLinkDocuments(spId: number): Observable<any> { // todo: type
    return this.http.get<any>(`${this.apiEndpoint}/${spId}/period/available`)
      .pipe(
        map((response: any) => response.data),
      );
  }
  // @END BILLING PERIODS

  public getAvailableForLinkScheduledPayments(documentId: number, documentType: DocumentTypesUppercaseEnum): Observable<any> { // todo: type
    const params = new HttpParams()
      .set('type', documentType)
      .set('id', documentId.toString());

    return this.http.get<any>(`${this.apiEndpoint}/available`, {params})
      .pipe(
        map((response: any) => response.data),
      );
  }

  public reCheckVatStatus(documentId: number): Observable<ScheduledPaymentModel> {
    return this.http.request<ResponseModel<ScheduledPaymentModel>>('post', `${this.apiEndpoint}/${documentId}/vat-status`)
      .pipe(
        tap((response: ResponseModel<ScheduledPaymentModel>) => {
          this.store.dispatch(LoadScheduledPayment({ scheduledPayment: response.data }));
        }),
        map((data: ResponseModel<ScheduledPaymentModel>) => data.data)
      );
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, spId?: number): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(errorText => {
        switch (errorText) {
          case 'anotherUserEditError':
            {
              const dialog = this.dialog.open(WarningModalComponent, {
                data: getAnotherUserEditErrorModalData(
                  {
                    document: error.error.data.entityName,
                    userName: error.error.data.userName,
                  },
                  this.translateService
                ),
                backdropClass: 'backdrop-none',
              });

              dialog.afterClosed().subscribe(res => {
                if (res === CommonModalsActionsEnum.CONFIRM) {
                  this.startScheduledPaymentEditing(spId, true).subscribe();
                  this.getScheduledPaymentById(spId).subscribe();
                }
              });
            }
            break;
          case 'notEditModeError':
            const documentName = this.translateService.instant('SCHEDULED_PAYMENT.SCHEDULED_PAYMENT');
            this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName}));
            this.store.dispatch(UpdateScheduledPaymentState({currentState: UIStatesEnum.VIEW}));
            this.getScheduledPaymentById(spId).subscribe();
            break;
          case 'DURATION_LAST_DATE_MUST_BE_AFTER_NEXT_BILLING_DATE':
            {
              this.dialog.open(InfoModalComponent, {
                data: {
                  title: 'SCHEDULED_PAYMENT.PROCEED_ERROR_TITLE',
                  message: 'SCHEDULED_PAYMENT.PROCEED_ERROR_MSG',
                  hideCancelBtn: true,
                  titleIcon: 'alert-triangle',
                  titleColor: 'yellow-400',
                  confirmBtnText: 'BUTTON.CLOSE',
                  confirmBtnIcon: 'close'
                }
              });
            }
            break;
          case 'NEXT_BILLING_DATE_MUST_BE_LATER_THAN_TODAY':
            this.showMsg('error', 'SCHEDULED_PAYMENT.NEXT_BILLING_DATE_MUST_BE_LATER_THAN_TODAY');
            break;
          case 'STATUS_UPDATE_IS_FORBIDDEN_IN_EDITING':
            this.showMsg('error', 'SCHEDULED_PAYMENT.STATUS_UPDATE_IS_FORBIDDEN_IN_EDITING');
            break;
          case 'SETUP_BILLING_DATE_FIRST':
            this.showMsg('error', 'SCHEDULED_PAYMENT.SETUP_BILLING_DATE_FIRST');
            break;
          case 'BILLING_DATE_MUST_BE_SPECIFIED':
            this.showMsg('error', 'SCHEDULED_PAYMENT.BILLING_DATE_MUST_BE_SPECIFIED');
            break;
          case 'BILLING_DATE_CANNOT_BE_LESS_THAN_TODAY_DATE':
            this.showMsg('error', 'SCHEDULED_PAYMENT.BILLING_DATE_CANNOT_BE_LESS_THAN_TODAY_DATE');
            break;
          case 'THE_END_DATE_MUST_NOT_BE_LESS_THAN_TODAY':
            this.showMsg('error', 'SCHEDULED_PAYMENT.THE_END_DATE_MUST_NOT_BE_LESS_THAN_TODAY');
            break;
          default:
            this.showMsg('error', errorText);
            break
        }
      });
    }
  }


}
