import { Inject, Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, throwError } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError } from 'rxjs/operators';

import { ToasterService } from '../modules/ui-components/toaster';

@Injectable({ providedIn: 'root' })
export class FileService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService,
    protected sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) { }

  downloadFile(url: string, params = {}): Observable<HttpResponse<Blob>> {
    return this.http.get(
      url,
      {
        params: {...params},
        observe: 'response',
        responseType: 'blob'
      }
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        return !!this.isBlobError(err)
          ? this.parseErrorBlob(err)
          : throwError(err);
      }),
      catchError((err: HttpErrorResponse) => {
        this.handleErrors(err);
        return throwError(err);
      }),
    );
  }

  downloadFilePostRequest(url: string, body: {}, params = {}): Observable<HttpResponse<Blob>> {
    return this.http.post(
      url,
      body,
      {
        params: {...params},
        observe: 'response',
        responseType: 'blob'
      }
    );
  }

  printPdfFile(url: string, params = {}): Observable<HttpResponse<Blob>> {
    return this.http.get(
      url,
      {
        params: {...params},
        observe: 'response',
        responseType: 'blob'
      }
    ).pipe(
      tap((res: HttpResponse<Blob>) => this.printPdf(res.body))
    );
  }

  printPdf(res: Blob) {
    const pdf = new Blob([res], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(pdf);
    const iframe = this.document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl));
    this.document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  downloadFileByUrl(url: string, name?: string): void {
    const a = this.document.createElement('a');
    a.href = url;
    if (name) {
      a.download = name;
    }
    this.document.body.appendChild(a);
    a.click();
    this.document.body.removeChild(a);
  }

  private isBlobError(err: any) {
    return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json';
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(new HttpErrorResponse({
          ...err,
          error: JSON.parse(reader.result as string),
        }));
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  private handleErrors(error: HttpErrorResponse): void {
    if (error.error.errors && error.error.errors.length) {
      error.error.errors.forEach(error => {
        switch (error) {
          case '1507':
            this.toasterService.notify({
              type: ToasterService.MESSAGE_TYPE_ERROR,
              message: 'XML_TEMPLATE.IIN_PAYMENT_METHOD_ERR'
            });
            break;
          case '1508':
            this.toasterService.notify({
              type: ToasterService.MESSAGE_TYPE_ERROR,
              message: 'XML_TEMPLATE.CRN_PAYMENT_METHOD_ERR'
            });
            break;
          default:
            this.toasterService.notify({
              type: ToasterService.MESSAGE_TYPE_ERROR,
              message: error
            });
        }
      });
    }
  }

}
