import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { DeliveryNote, Position } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { selectDeliveryNote, selectDeliveryNoteState } from 'projects/workspace/src/app/delivery-note/store/selectors';
import { UIStatesEnum } from '../../../../models';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PositionsActionsType } from '../../../ui-components/table/custom-table.enums';
import { ActionButtonsService } from '../../../../services/action-buttons.service';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import {
  generatePositionsCardsInfo,
} from 'projects/workspace/src/app/delivery-note/pages/delivery-note-products/delivery-note-products.config';
import { DeliveryNoteListTabsEnum } from 'projects/workspace/src/app/delivery-note/enums';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals-common';
import {
  ControlTypeEnum,
  DisplayConditionsModel,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';
import { REMOVE_POSITION_MODAL_DATA } from '../../modals.contsans';
import { ProductUnitModel } from 'projects/workspace/src/app/shared/models';
import { ProductUnitApiService } from 'projects/workspace/src/app/shared/services';
import { ProductTypes } from '../../../products/product-types';

@Component({
  selector: 'rnpl-edit-product-modal',
  templateUrl: './edit-product-modal.component.html',
  styleUrls: ['./edit-product-modal.component.scss']
})
export class EditProductModalComponent implements OnInit, OnDestroy {
  public position: any;
  public positions: any[];
  public rowIndex: number;
  public isReadOnly: boolean;
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public selectedRows: Position[] = [];
  public positionCardsInfo: PositionInfoListModel[] = [];
  public currentState: UIStatesEnum;
  public positionItemTypeEnum: typeof PositionItemTypeEnum = PositionItemTypeEnum;
  public controlTypeEnum: typeof ControlTypeEnum = ControlTypeEnum;
  public unitsList: string[] = [];
  public unitsListSource: ProductUnitModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditProductModalComponent>,
    private store: Store<AppState>,
    public actionButtonsService: ActionButtonsService,
    private deliveryNoteApiService: DeliveryNoteApiService,
    private translateService: TranslateService,
    private productUnitApiService: ProductUnitApiService,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveryNote: DeliveryNote;
      position: any,
      positions: any[],
      rowIndex: number
    }

  ) { }

  ngOnInit() {
    this.position = this.data.position;
    this.positions = this.data.positions;
    this.rowIndex = this.getRowIndex(this.position);

    this.trackDeliveryNoteStateChanges();
    this.trackDeliveryNoteChanges();
    this.getProductUnits();
  }

  public getProductUnits(): void {
    this.productUnitApiService.getUnits$(this.position.productType)
      .pipe(takeUntil(this.destroy$))
      .subscribe((unitsList: ProductUnitModel[]) => {
        this.unitsListSource = unitsList;
        if (!this.position.general) {
          this.unitsList = [this.position.unitType];
          return;
        } else {
          this.unitsList = unitsList.map(u => u.name);
        }
      });
  }

  private trackDeliveryNoteStateChanges(): void {
    this.store
      .select(selectDeliveryNoteState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
        this.isReadOnly = state === UIStatesEnum.VIEW || state === UIStatesEnum.PACKING;
        this.getPositionCardInfo();
      });
  }

  private trackDeliveryNoteChanges(): void {
    this.store
      .select(selectDeliveryNote)
      .pipe(takeUntil(this.destroy$))
      .subscribe((deliveryNote) => {
        this.getPositionCardInfo();
        this.positions = deliveryNote.positions;
      });
  }

  public getRowIndex(position): number {
    let rowIndex: number;
    this.positions.map((pos, index) => {
      if (pos.id === position.id) {
        rowIndex = index;
      }
    });
    return rowIndex;
  }

  public actionListHandler({actionType, row, moveTo}): void {
    if (actionType === PositionsActionsType.INFO) {
      this.actionButtonsService.displaySidebarHandler(row.productId);
      return;
    }
    this.movePosition(row.id, moveTo);
  }

  private movePosition(posId: number, moveTo: number): void {
    this.deliveryNoteApiService.changePositionOrder(posId, moveTo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((deliveryNote) => {
        this.clearSelected();
        const pos = this.getCurrentPosition(deliveryNote.positions);
        this.position = {
          ...pos,
          quantityCheck: (
            pos.quantityCheck
              ? pos.quantityCheck.split('_').join(' ')
              : '-'
          ) as any,
          availableTotal: {
            value: pos.quantity - pos.missedPreReservedQuantity - pos.packedUp,
            maxValue: (pos.quantity - pos.packedUp) - (pos.quantity - pos.missedPreReservedQuantity - pos.packedUp),
            inverse: true,
            postfix: this.translateService.instant('FORM.PCS')
          },
          packedUpTotal: {
            value: pos.packedUp,
            maxValue: pos.ordered,
            inverse: true,
            postfix: this.translateService.instant('FORM.PCS')
          },
        };
        this.getCurrentRowIndex();
        this.deliveryNoteApiService.getDeliveryNoteById(+this.data.deliveryNote.id).subscribe();
      });
  }

  public clearSelected(): void {
    this.selectedRows = [];
  }

  public displayProductInfo(productId): void {
    this.actionButtonsService.displaySidebarHandler(productId);
  }

  public deletePositionDialog(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: REMOVE_POSITION_MODAL_DATA,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.deletePositions([this.position.id]);
      }
    });
  }

  public trackByProp(index: number, item: PositionInfoListModel) {
    return item.prop;
  }

  public displayConditionsHandler(position, conditionsList: DisplayConditionsModel[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        if (!!condition.productType) {
          return position[condition.prop] === val && condition.productType === position.productTypeForGrouping;
        } else {
          return position[condition.prop] === val;
        }
      });
    });
  }

  public updatePosition(fieldName, fieldValue): void {
    this.deliveryNoteApiService
      .updateDeliveryNotePositionProperty(
        +this.data.deliveryNote.id,
        +this.position.id,
        {[fieldName]: fieldValue}
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((deliveryNote) => {
        const pos = this.getCurrentPosition(deliveryNote.positions);
        this.position = {
          ...pos,
          quantityCheck: (
            pos.quantityCheck
              ? pos.quantityCheck.split('_').join(' ')
              : '-'
          ) as any,
          availableTotal: {
            value: pos.quantity - pos.missedPreReservedQuantity - pos.packedUp,
            maxValue: (pos.quantity - pos.packedUp) - (pos.quantity - pos.missedPreReservedQuantity - pos.packedUp),
            inverse: true,
            postfix: this.translateService.instant('FORM.PCS')
          },
          packedUpTotal: {
            value: pos.packedUp,
            maxValue: pos.ordered,
            inverse: true,
            postfix: this.translateService.instant('FORM.PCS')
          },
        };
        this.deliveryNoteApiService.getDeliveryNoteById(+this.data.deliveryNote.id).subscribe();
        this.positions = deliveryNote.positions;
      });
  }

  public getCurrentPosition(positions: any[]): any {
    return positions.find(pos => pos.id === this.position.id);
  }

  public updateDatePicker(event, fieldName): void {
    this.updatePosition(fieldName, event.value ? new Date(event.value).toLocaleDateString('fr-CA') : null);
  }

  private deletePositions(ids: number[]): void {
    this.deliveryNoteApiService.deleteProducts(this.data.deliveryNote.id, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.clearSelected();
        this.dialog.closeAll();
      });
  }

  private getPositionCardInfo(): void {
    const deliveryNote = this.data.deliveryNote;
    this.positionCardsInfo = generatePositionsCardsInfo(
      this.currentState,
      this.data.deliveryNote.status as DeliveryNoteListTabsEnum,
      !!deliveryNote.era || !!deliveryNote.salesOrder || !!deliveryNote.eco,
      this.translateService
    );
  }

  public nextPosition({position, positions}): void {
    this.position = position;
    this.positions = positions;
    this.getPositionCardInfo();
    this.getCurrentRowIndex();
    this.getProductUnits();
  }

  public prevPosition({position, positions}): void {
    this.position = position;
    this.positions = positions;
    this.getPositionCardInfo();
    this.getCurrentRowIndex();
    this.getProductUnits();
  }

  public getCurrentRowIndex(): void {
    this.positions.map((pos, index) => {
      if (pos.order === this.position.order) {
        this.rowIndex = index;
      }
    });
  }

  get allowFractionalValues(): boolean {
    if (!this.position.unitType || !this.unitsListSource.length) { return true; }
    const selectedUnit: ProductUnitModel = this.unitsListSource
      .find((itm: ProductUnitModel) => itm.name === this.position.unitType);
    const isFractionalQuantity: boolean = !!(this.position.quantity % 1);
    return (selectedUnit && !isFractionalQuantity)
      ? selectedUnit.allowFractionalValues
      : isFractionalQuantity;
  }

  get isGoods(): boolean {
    return this.position.productType === ProductTypes.GOODS && !this.position.general;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
