import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InfoItemModel, InfoItemType } from '../models/info-item.model';

@Component({
  selector: 'rnpl-info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.scss']
})
export class InfoListComponent {

  public infoItemType: typeof InfoItemType = InfoItemType;

  @Input() public infoList: InfoItemModel[] = null;

  @Input() public reversColors: boolean = false;
  @Input() public dark: boolean = false;
  @Input() public isTransparentItems: boolean = false;

  @Output() action: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  public outputClick(itemLabel: string): void {
    this.action.emit(itemLabel);
  }

}
