import { Component, Input } from '@angular/core';

import { TableColumnModelExtended } from '../../../../../models/table-column.model';

@Component({
  selector: 'rnpl-column-with-profit',
  templateUrl: './column-with-profit.component.html',
  styleUrls: ['./column-with-profit.component.scss'],
})
export class ColumnWithProfitComponent {
  @Input()
  value: {profit: number, percent: number, currency: string};

  @Input()
  column: TableColumnModelExtended;

  @Input()
  row: any;

  constructor() {}
}
