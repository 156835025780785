import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { TradePositionModel } from 'projects/workspace/src/app/trade/models';
import { getReceivedGoodsColumns } from './incoming-delivery-cancel-warehousing-modal.config';
import {
  IncomingDeliveryApiService
} from 'projects/workspace/src/app/warehouse/modules/incoming-delivery/services/incoming-delivery-api.service';
import { StockAreaEnum } from 'projects/workspace/src/app/warehouse/modules/transactions/enums';
import { InfoModalComponent } from '../../modals-common';

interface DataType {
  documentId?: number;
  location?: StockAreaEnum;
  fromPo?: boolean;
}

@Component({
  selector: 'rnpl-incoming-delivery-warehousing-modal',
  templateUrl: './incoming-delivery-cancel-warehousing-modal.component.html',
  styleUrls: ['./incoming-delivery-cancel-warehousing-modal.component.scss'],
})
export class IncomingDeliveryCancelWarehousingModalComponent extends BaseModalComponent implements OnInit, AfterViewChecked {

  public cancelAllGoods: boolean = false;

  public columns: TableColumnModelExtended[] = getReceivedGoodsColumns();
  public products: any[] = [];

  readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly confirmRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public incomingDeliveryApiService: IncomingDeliveryApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<IncomingDeliveryCancelWarehousingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.incomingDeliveryApiService.getWarehousedProducts(this.data.location, this.data.documentId)
      .pipe(takeUntil(this._destroy))
      .subscribe((response) => {
        this.products = this.prepareProducts(response);
        window.dispatchEvent(new Event('resize'));
        this.isLoading$.next(false);
      });
  }

  ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'));
  }

  public onGoToProduct(service: TradePositionModel): void {
    window.open(window.location.origin + `/products/product-view/${service.productId}`, '_blank');
  }

  public prepareProducts(products): any {
    return [...products.map((item) => {
      return {
        ...item,
        productLink: {
          label: item.productRunpleId,
          routerLink: `/products/product-view/${item.productId}`
        },
        productNameLink: {
          label: item.productName,
          routerLink: `/products/product-view/${item.productId}`
        },
      };
    })];
  }

  public cancelAllGoodsChanged(): void {
    this.products = [...this.products.map((good) => {
      return {
        ...good,
        quantity: good.warehoused,
      };
    })];

    this.columns = getReceivedGoodsColumns(this.cancelAllGoods);
  }

  public cancelProductsWarehousing(): void {
    if (this.confirmRequest$.getValue()) { return; }
    this.confirmRequest$.next(true);

    const isProductsValid = this.products.every(product => product.quantity <= product.available);
    if (!isProductsValid) {
      this.dialog.open(InfoModalComponent, {
        data: {
          title: 'INCOMING_DELIVERY.NO_GOODS_IN_STOCK_TITLE',
          message: 'INCOMING_DELIVERY.NO_GOODS_IN_STOCK_MSG',
          hideCancelBtn: true,
          titleIcon: 'alert-triangle',
          titleColor: 'yellow-400',
          confirmBtnText: 'BUTTON.CLOSE',
          confirmBtnIcon: 'close'
        }
      });
      return;
    }

    const products = {
      products: this.products,
    };

    setTimeout(() => { // wait for steppers to update
      this.incomingDeliveryApiService.cancelWarehousingMultiple(this.data.location, this.data.documentId, products)
        .pipe(
          finalize(() => this.confirmRequest$.next(false)),
          takeUntil(this._destroy)
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    }, 1500);
  }

}
