import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';

export const PlanUpgradingRequireModalConfig: CommonModalDataModel = {
  title: 'SUBSCRIPTION_PLAN.UPGRADE_REQUIRED_TITLE',
  message: 'SUBSCRIPTION_PLAN.UPGRADE_REQUIRED_MSG',
  titleIcon: 'alert-triangle',
  titleColor: 'yellow-400',
  confirmBtnText: 'SUBSCRIPTION_PLAN.UPGRADE_PLAN',
  confirmBtnIcon: 'calendar'
};
