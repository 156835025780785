export enum ProductTypes {
  GOODS = 'goods',
  SERVICES = 'services',
  DIGITAL = 'digital_products',
  ARBITRARY = 'arbitrary',
  CORRECTION = 'correction',
  CREDIT = 'credit',
  OVERDUE_FEE = 'overdue_fee',
  GENERAL = 'general',
  UNASSIGNED = 'unassigned',
  PREPAYMENT = 'prepayment',
  INVOICE = 'invoice',
}

export enum ProductEntityTypes {
  GENERAL = 'general_product',
  PRODUCT = 'product',
  UNIT = 'product_unit',
}

export enum ServiceProvidingTypes {
  ONE_TIME = 'one_time',
  CONTINUOUS = 'continuous'
}
