import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';
import { TranslateService } from '@ngx-translate/core';

const commonProperties: CommonModalDataModel = {
  title: 'PRODUCTS.NOT_UNIQUE_ATTR',
  hideCancelBtn: true,
  titleIcon: 'alert-triangle',
  titleColor: 'yellow-400',
  confirmBtnText: 'BUTTON.CLOSE',
  confirmBtnIcon: 'close'
};

export function CategoryHasAttributeErrorModalData(attrName: string, translateService: TranslateService): CommonModalDataModel {
  return {
    ...commonProperties,
    message: translateService.instant('PRODUCTS.ALREADY_CREATED_ATTR', {attrName})
  };
}

export function ChildCategoryHasAttributeErrorModalData(attrName: string, translateService: TranslateService): CommonModalDataModel {
  return {
    ...commonProperties,
    message: translateService.instant('PRODUCTS.ALREADY_CREATED_ATTR_SUB', {attrName})
  };
}

export const DeletePermanentlyModalData: CommonModalDataModel = {
  title: 'PRODUCTS.DELETE_ATTR',
  message: 'PRODUCTS.DELETE_ATTR_PERMANENTLY'
};
