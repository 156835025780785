import { createReducer, on } from '@ngrx/store';
import { CountryModel } from 'common/src/modules/rnpl-common';
import { CategoryModel } from '../../shared/models/category-model';
import * as CollectionSharedActions from '../actions/shared.actions';
import { AccountingSettingsModel } from '../../accounting/accounting-settings-module/models';
import { GeneralSettingsModel, ProductUnitModel } from '../../shared/models';
import { UserShortcutsModel } from '../../hrm/models';
import { StartScreenModel } from 'common/src/modules/start-screen-page/launchpad.model';
import { PdfSettingsModel } from '../../pdf-settings/models';
import { ProductTypes } from '../../../../../../common/src/modules/products/product-types';

export const SharedFeatureKey = 'shared';

export interface SharedStoreState {
  countries: CountryModel[];
  categories: CategoryModel[];
  productsUnits: {[key in ProductTypes]?: ProductUnitModel[]};
  userShortcuts: UserShortcutsModel;
  accountingSettings: AccountingSettingsModel;
  generalSettings: GeneralSettingsModel;
  pdfSettings: PdfSettingsModel;
  userQuickActions: UserShortcutsModel;
  userStartScreenItems: StartScreenModel[];
  loadingRequestsCount: number;
  appsCounters: {[key: string]: number};
}

export const initialSharedStoreState: SharedStoreState = {
  countries: [],
  categories: [],
  productsUnits: {
    [ProductTypes.GOODS]: [],
    [ProductTypes.SERVICES]: [],
    [ProductTypes.DIGITAL]: [],
  },
  accountingSettings: {} as AccountingSettingsModel,
  generalSettings: {} as GeneralSettingsModel,
  pdfSettings: {} as PdfSettingsModel,
  userShortcuts: {
    activated: [],
    available: [],
  },
  userQuickActions: {
    activated: [],
    available: [],
  },
  userStartScreenItems: [],
  loadingRequestsCount: 0,
  appsCounters: {} as {[key: string]: number},
};

export function SharedStoreReducer(state, action) {
  return _SharedStoreReducer(state, action);
}


const _SharedStoreReducer = createReducer(
  initialSharedStoreState,
  on(CollectionSharedActions.LoadCountriesSuccess, (state, { countries }) => ({
    ...state,
    countries,
  })),
  on(CollectionSharedActions.LoadAccountingSettingsSuccess, (state, { accountingSettings }) => ({
    ...state,
    accountingSettings,
  })),
  on(CollectionSharedActions.LoadCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories,
  })),
  on(CollectionSharedActions.LoadProductsUnitsSuccess, (state, { productType, units }) => ({
    ...state,
    productsUnits: {
      ...state.productsUnits,
      [productType]: units
    },
  })),
  on(CollectionSharedActions.LoadUserShortcutsSuccess, (state, { userShortcuts }) => ({
    ...state,
    userShortcuts,
  })),
  on(CollectionSharedActions.LoadGeneralSettingsSuccess, (state, { generalSettings }) => ({
    ...state,
    generalSettings,
  })),
  on(CollectionSharedActions.LoadPdfSettingsSuccess, (state, { pdfSettings }) => ({
    ...state,
    pdfSettings,
  })),
  on(CollectionSharedActions.LoadUserQuickActionsSuccess, (state, { userQuickActions }) => ({
    ...state,
    userQuickActions,
  })),
  on(CollectionSharedActions.LoadUserStartScreenListSuccess, (state, { userStartScreenItems }) => ({
    ...state,
    userStartScreenItems,
  })),
  on(CollectionSharedActions.IncrementStartScreenListLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionSharedActions.DecrementStartScreenListLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
  on(CollectionSharedActions.LoadAppsCountersSuccess, (state, {appsCounters}) => ({
    ...state,
    appsCounters
  })),
);
