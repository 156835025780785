import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

type SubmitRequestFunction = (groupingFormData: any) => Observable<any>;

@Component({
  selector: 'rnpl-time-tracking-grouping-modal',
  templateUrl: './time-tracking-grouping-modal.component.html',
})
export class TimeTrackingGroupingModalComponent {

  @ViewChild('groupingForm', {static: true}) groupingFormRef;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<TimeTrackingGroupingModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      hideGroupingForm: boolean;
      submitRequestFn$: SubmitRequestFunction;
    }
  ) {
  }

  public submit(): void {
    this.submitRequest$.next(true);

    this.data.submitRequestFn$(this.groupingFormRef.getFormData())
      .pipe(finalize(() => this.submitRequest$.next(false)))
      .subscribe((invoice) => {
        this.router.navigate([`accounting/outgoing-invoice/${invoice.invoiceId}`])
          .then(() => this.dialog.closeAll());
      });
  }

}
