import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { BaseModalComponent } from '../../../rnpl-common';
import { ToasterService } from '../../../ui-components/toaster';
import { CommonModalsActionsEnum } from '../../modals-common';


@Component({
  selector: 'rnpl-document-template-same-name-modal',
  templateUrl: './document-template-same-name-modal.component.html'
})
export class DocumentTemplateSameNameModalComponent extends BaseModalComponent {

  public resetCounter = false;

  constructor(
    public toasterService: ToasterService,
    public dialogRef: MatDialogRef<DocumentTemplateSameNameModalComponent>,
  ) {
    super(toasterService);
  }

  public submit(): void {
    this.dialogRef.close({action: CommonModalsActionsEnum.CONFIRM, resetCounter: this.resetCounter});
  }
}

