import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rnpl-position-card-skeleton',
  templateUrl: './position-card-skeleton.component.html',
  styleUrls: ['./position-card-skeleton.component.scss']
})
export class PositionCardSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
