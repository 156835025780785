import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';


import { WizardStep } from 'common/src/modules/wizard/wizard-step';
import { FamilyModel } from 'common/src/modules/products/models';
import { FormTooltipComponent } from 'common/src/modules/ui-components/form-tooltip/form-tooltip.component';
import { AttributeWizardComponent } from '../../attribute-wizard.component';


@Component({
  selector: 'rnpl-family',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.scss']
})
export class FamilyComponent extends WizardStep implements OnInit {

  @Input()
  public families: Array<FamilyModel> = [];

  @Input()
  public selectedFamilies: Array<FamilyModel> = [];

  public familiesTree: Array<any> = [];

  @ViewChild('familiesHint', {static: false})
  private familiesHint: FormTooltipComponent;

  constructor(@Optional() public wizard: AttributeWizardComponent) {
    super();
  }

  ngOnInit(): void {
    this.familiesTree = this.initFamiliesItems(this.families);

    if (this.form.get('families').value.length) {
      this.wizard.progress = this.progress.finish;
    }

    this.form.get('families').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.wizard.progress = this.progress.finish;
        if (this.familiesHint) {
          this.familiesHint.hide();
        }
      });
  }

  public setFamily(event) {
    const families = this.form.get('families').value;
    if (event.checked) {
      families.push(event.family);
      this.form.get('families').setValue(families);
    } else {
      this.form.get('families').setValue(families.filter(family => family.id !== event.family.id));
    }
  }

  private initFamiliesItems(families: Array<any>): Array<any> {

    const familiesValue = this.form.get('families').value;
    return families.map((family) => {

      const children = family.children ? this.initFamiliesItems(family.children) : [];

      if (this.selectedFamilies.find(selectedFamily => selectedFamily.id === family.id)
        && !familiesValue.find(selectedFamily => selectedFamily.id === family.id)) {

        familiesValue.push(family);
        this.form.get('families').setValue(familiesValue);
      }

      const checked = !!children.find(child => child.checked)
        || this.form.get('families').value.find(selectedFamily => selectedFamily.id === family.id);

      return {
        key: family.id,
        family: family,
        label: family.translations[0].title,
        nodes: children,
        checked: checked
      };
    });
  }
}
