import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-documents-toggle-summary',
  templateUrl: './documents-toggle-summary.component.html',
})
export class DocumentsToggleSummaryComponent {

  @Input() public showSummary: boolean;

  public openSummary: boolean = false;

  public openToggleSummary(): void {
    this.openSummary = !this.openSummary;
  }
}
