import { Component, ContentChild, Directive, HostListener, Input, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

import * as iconsList from './icons.json';

@Component({
  selector: 'rnpl-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})

export class IconComponent implements OnInit {

  @Input() public iconName: string;

  @Input() public spinnerColor: string;

  @Input() public autoPlay: boolean = false;

  @Input() public noPlay: boolean = false;

  @Input() public isLoading: boolean = false;

  @Input() public greyIcon: boolean = false;

  @Input() public width: string = '24px';

  @Input() public height: string = '24px';

  public lottieConfig: AnimationOptions;

  private animation: AnimationItem;

  constructor(private ngZone: NgZone) {

  }

  ngOnInit(): void {
    this.lottieConfig = {
      path: `assets/icons/${this.iconName}.json`,
      // animationData: { icon: this.iconName },
      renderer: 'svg',
      autoplay: this.autoPlay,
      loop: false
    };
  }

  isAnimated(): boolean {
    // if device is on IOS system always display font icons
    if (this.isIos()) { return false; }
    // if animated icon exist and OS is Mac display animated icons only for Chrome Browser
    if (this.isAnimatedIconExist() && this.isMacOs()) { return this.isChromeBrowser(); }

    // in any other cases display animated icon if it exist
    return this.isAnimatedIconExist();
  }

  isAnimatedIconExist(): boolean {
    return (iconsList as any).default.includes(this.iconName);
  }

  isIos(): boolean {
    const platform = window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    return iosPlatforms.indexOf(platform) !== -1;
  }

  isMacOs(): boolean {
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'darvin'];
    return macosPlatforms.indexOf(platform) !== -1;
  }

  isChromeBrowser(): boolean {
    return window.navigator.userAgent.indexOf('Chrome') !== -1;
  }

  handleAnimation(animationItem: AnimationItem) {
    this.animation = animationItem;
  }

  stop(): void {
    if (this.animation && !this.autoPlay) {
      this.ngZone.runOutsideAngular(() => this.animation.stop());
    }
  }

  play(): void {
    if (this.animation && !this.noPlay) {
      this.ngZone.runOutsideAngular(() => this.animation.play());
    }
  }
}

@Directive({
  selector: '[rnpl-icon-container]'
})
export class IconContainerDirective {

  @ContentChild(IconComponent, {static: true})
  icon: IconComponent;

  @HostListener('mouseover')
  start() {
    if (this.icon) {
      this.icon.play();
    }
  }

  @HostListener('mouseleave')
  stop() {
    if (this.icon) {
      this.icon.stop();
    }
  }
}
