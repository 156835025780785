import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material';

import { QuickOnboardingComponent } from './components/quick-onboarding/quick-onboarding.component';
import {UIComponentsModule} from '../ui-components/ui-components.module';
import { RnplCommonModule } from '../rnpl-common';
import { ExitSandboxModalComponent } from './components/exit-sandbox-modal/exit-sandbox-modal.component';

@NgModule({
  declarations: [
    QuickOnboardingComponent,
    ExitSandboxModalComponent
  ],
  exports: [
    QuickOnboardingComponent,
    ExitSandboxModalComponent
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    TranslateModule,
    RnplCommonModule,
    FormsModule,
    DragDropModule,
    RouterModule,
    MatMenuModule
  ],
  entryComponents: [
    ExitSandboxModalComponent
  ],
})
export class QuickOnboardingModule { }
