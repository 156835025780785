export interface NavigationGroupModel {
  groupTitle?: string;
  groupType?: string;
  showCondition?:  boolean;
  pages?: Array<NavigationItemModel>;
}

export interface NavigationItemModel {
  title: string;
  icon?: string;
  class?: string;
  routerLink?: string;
  isExternalRouterLink?: boolean;
  hintId?: string;
  children?: Array<NavigationItemModel>;
  disabled?: boolean;
  show?: boolean;
  gradient?: GradientColor | string;
  hideWith?: string[];
  showWithStatus?: string[];
  showCondition?: boolean;
}

export enum GradientColor {
  BLUE = 'blue',
  PURPLE = 'purple',
  VIOLET = 'violet',
  GREEN = 'green',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  RED = 'red',
  BROWN = 'brown',
  SKY = 'sky',
  MINT = 'mint'
}
