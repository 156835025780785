import { Component, Inject, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import {
  AccountingSettingsService
} from 'projects/workspace/src/app/accounting/accounting-settings-module/services/accounting-settings.service';

@Component({
  selector: 'rnpl-card-payments-stripe-modal',
  templateUrl: './card-payments-stripe-modal.component.html'
})
export class CardPaymentsStripeModalComponent extends BaseModalComponent implements OnDestroy {
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private location: Location,
    public toasterService: ToasterService,
    public accountingSettingsService: AccountingSettingsService,
    public dialogRef: MatDialogRef<CardPaymentsStripeModalComponent>,
  ) {
    super(toasterService);
  }

  public onLearnAboutStripe(): void {
    // window.open('https://ec.europa.eu/commission/presscorner/detail/de/QANDA_19_5555', '_blank');
  }

  public submitConnect(): void {
    if (this.submitRequest$.getValue()) { return; }
    this.submitRequest$.next(true);

    this.accountingSettingsService.createStripeAccount()
      .pipe(
        switchMap(() => this.accountingSettingsService.updateStripeAccount(this.location.path())),
        finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((response) => {
        if (response.url) {
          window.open(response.url, '_blank');
          this.dialogRef.close();
        }
      });
  }

}
