import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../models/table-column.model';

export function getColumns(): TableColumnModelExtended[] {

  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 172,
      maxWidth: 172,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PRODUCT_NAME',
      preventTranslate: true,
      cellClass: 'link-primary inline',
      prop: 'productNameLink',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      sortable: true,
      sourceTextFormat: true,
      type: ColumnTypeEnum.TEXT,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.ORDERED',
      prop: 'ordered',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 98,
      maxWidth: 98,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.AVAILABLE',
      prop: 'quantity',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 98,
      maxWidth: 98,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.QUANTITY',
      prop: 'confirmQuantity',
      sortable: true,
      type: ColumnTypeEnum.STEPPER,
      decimal: 0,
      unitTypeShortcut: true,
      width: 160,
      maxWidth: 160,
    },
  ];
}
