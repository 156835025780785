import { Injectable } from '@angular/core';


import { AppContextService } from '../app-context/app-context.service';


@Injectable()
export class RnplModuleService {

  private _modulesHistory: Array<string> = [];

  constructor(private appContext: AppContextService) {
  }

  /**
   * Fixes the current module
   *
   * @param module A module name
   */
  public moduleOpened(module: string): void {
    if (this._modulesHistory[this._modulesHistory.length - 1] !== module) {
      this._modulesHistory.push(module);
    }
    this.appContext.setToContext('currentModule', module);
  }
}
