import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { switchMap, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { InfoItemModel, InfoItemType } from '../../../ui-components/info-components/models/info-item.model';
import { getTrackShipmentLink } from 'projects/workspace/src/app/shared/helpers/track-shipment.helper';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { BaseModalComponent } from '../../../rnpl-common';
import { ToasterService } from '../../../ui-components/toaster';
import { FileService } from '../../../../services/file.service';
import { FileUploadParams } from '../../../../models/file-upload-params.model';

@Component({
  selector: 'rnpl-pack-up-shipment-success-modal',
  templateUrl: './pack-up-shipment-success-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackUpShipmentSuccessModalComponent extends BaseModalComponent {

  public form: FormGroup;

  constructor(
    public toasterService: ToasterService,
    public deliveryNoteApiService: DeliveryNoteApiService,
    private readonly fileService: FileService,
    public dialogRef: MatDialogRef<PackUpShipmentSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveryNote: DeliveryNote
    }
  ) {
    super(toasterService);
  }

  public printLabel(): void {
    this.deliveryNoteApiService.getDeliveryNoteDeliveryServiceLabel(this.data.deliveryNote.id)
      .pipe(
        switchMap((fileParams: FileUploadParams) => this.fileService.printPdfFile(fileParams.url)),
        takeUntil(this._destroy)
      )
      .subscribe(() => {

      }, error => {
        this.toasterService.notify({type: 'error', message: error.error.message || error.error.errors});
      });
  }

  public get deliveryServiceInfoItems(): InfoItemModel[] {
    return [
      {
        label: 'FORM.DELIVERY_SERVICE',
        value: get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.deliveryService.name'),
        type: InfoItemType.WITH_DELIVERY_SERVICE,
        tableItem: true
      },
      {
        label: 'FORM.TRACKING_CODE',
        value: get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.trackingCode'),
        routerLink: getTrackShipmentLink(
          get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.deliveryService.name'),
          get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.trackingCode')
        ),
        type: InfoItemType.WITH_EXTERNAL_LINK,
        tableItem: true
      },
      {
        label: 'DELIVERY_SERVICES.DELIVERY_METHOD',
        value: (get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.deliveryMethod') || '')
          .replace('express' , 'ECOMMERCE.EXPRESS')
          .replace('standard' , 'ECOMMERCE.STANDARD')
          .replace('standart' , 'ECOMMERCE.STANDARD'),
        type: InfoItemType.PRIMARY,
        tableItem: true
      },
      {
        label: 'DELIVERY_SERVICES.BOXES_QTY',
        value: get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.boxesQuantity'),
        type: InfoItemType.PRIMARY,
        tableItem: true
      },
      {
        label: 'COLUMN.PACKED_UP',
        value: get(this, 'data.deliveryNote.deliveryInformation.deliveryServiceDetails.packedUp'),
        type: InfoItemType.WITH_DATE_PRIMARY,
        tableItem: true
      },
    ];
  }

}

