import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Title} from '@angular/platform-browser';

import {TableSummaryBarItemModel, TableSummaryBarItemTypeEnum} from '../../modules/ui-components/table-summary-bar/table-summary-bar.model';
import {NavigationItemModel} from '../../models';
import {ChangePasswordComponent} from '../../modules/modals/modals-sign-in/change-password/change-password.component';

@Component({
  selector: 'rnpl-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss']
})
export class ChangePasswordPageComponent implements OnInit, OnDestroy {

  public showHeader: boolean = false;
  public navItems: NavigationItemModel = {
    title: 'Runple Runple',
    icon: '',
    children: [
      {
        title: 'Runple',
        icon: 'heart',
        disabled: true,
      },
      {
        title: 'Runple',
        icon: 'settings',
        disabled: true,
      },
      {
        title: 'Runple',
        icon: 'settings',
        disabled: true,
      },
      {
        title: 'Runple',
        icon: 'settings',
        disabled: true,
      }
    ]
  };
  public tabs = [
    {
      label: 'Runple',
      link: '',
      icon: 'box',
      count: 0
    },
  ];
  public tableSummaryBarItems: TableSummaryBarItemModel[] = [
    {
      label: 'Runple',
      type: TableSummaryBarItemTypeEnum.PRIMARY,
      value: 'runple',
      titleIcon: 'box',
      basisWidth: 167,
    },
    {
      label: 'Runple',
      type: TableSummaryBarItemTypeEnum.PRIMARY,
      value: 'runple',
      titleIcon: 'box',
      basisWidth: 167,
    },
    {
      label: 'Runple',
      type: TableSummaryBarItemTypeEnum.PRIMARY,
      value: 'runple',
      titleIcon: 'box',
      basisWidth: 167,
    },
    {
      label: 'Runple',
      type: TableSummaryBarItemTypeEnum.PRIMARY,
      value: 'runple',
      titleIcon: 'box',
      basisWidth: 167,
    },
  ];
  public rows: Array<Object> = Array(24);

  constructor(private dialog: MatDialog, private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('Runple. Change password');
    this.dialog.open(ChangePasswordComponent, {
      disableClose: true,
      backdropClass: 'drop-sign-in',
      closeOnNavigation: false,
      panelClass: 'sign-in-modal'
    });
  }

  ngOnDestroy() {
    this.dialog.closeAll();
  }

}
