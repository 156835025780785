import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { HintTypeEnum } from '../models/hints-list.model';
import { GetTeamProfileHints } from '../team-hints.config';
import { UserListTabs } from '../pages/user-list/enums/user-list-tabs.enum';
import {
  BaseMegaHintComponentDependences, BaseMegaHintsComponent,
} from 'common/src/modules/rnpl-common/components/base-mega-hints/base-mega-hints.component';

@Component({
  selector: 'rnpl-team-mega-hint',
  templateUrl: './team-mega-hint.component.html',
  styleUrls: ['./team-mega-hint.component.scss']
})
export class TeamMegaHintComponent extends BaseMegaHintsComponent implements OnInit, OnChanges {
  @Input() public screenName: string;
  @Input() public status: UserListTabs | string;
  @Input() public readOnly: boolean = false;
  @Input() public displayChangeEmailBtn: boolean = false;
  @Input() public isMyProfile: boolean = false;
  @Input() public hasTeams: boolean;
  @Input() public activeTab: string = '';

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'MODULE.TEAM'
    this.megaHintTimer = 600;
    this.displayHints();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readOnly') || changes.hasOwnProperty('status')) {
      clearInterval(this.countdownTimer);
      if (this.shepherdService.isActive) {
        this.shepherdService.cancel();
      }

      if (!!this.screenName) {
        this.initHints();
      }
    }
  }

  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = GetTeamProfileHints(
            this.readOnly,
            this.screenName,
            this.displayChangeEmailBtn,
            this.isMyProfile,
            this.status,
            this.activeTab,
            this.hasTeams
          );

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!hints.data.hints && !!hints.data.hints.length && !hints.data.hints[0].viewed && hints.data.hints[0].type === HintTypeEnum.MEGA) {
            this.isCloseHint$.next(false);
            this.startTimerMegaHint();
          } else {
            if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
              this.shepherdService.start();
            }
          }
        }
      })
  }
}
