import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SafePipeModule } from 'safe-pipe';

import { DeliveryServiceControlComponent } from './delivery-service-control.component';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    DeliveryServiceControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    RnplCommonModule,
    UIComponentsModule,
    TranslateModule,
    SafePipeModule
  ],
  exports: [
    DeliveryServiceControlComponent,
  ],
  entryComponents: [DeliveryServiceControlComponent],
})
export class DeliveryServiceControlModule { }
