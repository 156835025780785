import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-document-rid',
  templateUrl: './document-rid.component.html',
  styleUrls: ['./document-rid.component.scss']
})
export class DocumentRidComponent {

  @Input() public runpleId: string = null;

}
