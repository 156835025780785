import { Component, EventEmitter, HostBinding, Inject, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rnpl-color-picker-control',
  templateUrl: './color-picker-control.component.html',
  styleUrls: ['./color-picker-control.component.scss'],
})
export class ColorPickerControlComponent implements OnChanges, OnDestroy {

  public color: string = null;
  public colorPickerOpened: boolean = false;
  @HostBinding('class.transformToTop') transformToTop: boolean = false; // cpPosition doesn't work correctly, and need to be fixed with css

  @Input() control: FormControl;
  @Input() label: string = 'DATA_IMPORT.COLOR';
  @Input() validate = false;

  @Output() fieldChanged: EventEmitter<string> = new EventEmitter<string>();

  private colorsChangeBuffer$: Subject<string> = new Subject<string>();
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.colorsChangeBuffer$
      .pipe(
        distinctUntilChanged(),
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe((color: string) => {
        this.fieldChanged.emit(color);
        this.control.patchValue(color);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('control')) {
      this.color = this.control.value;
    }
  }

  public colorChanged(color: string): void {
    if (color) {
      this.colorsChangeBuffer$.next(color);
    }
  }

  public inputChanged(value: string): void {
    this.control.patchValue(value);
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }

  public toggleColorPicker(): void {
    this.colorPickerOpened = !this.colorPickerOpened;
  }

  public setPosition(e: Event): void {
    if (this.document.documentElement.offsetHeight < (e.target as HTMLElement).getBoundingClientRect().bottom + 260) {
      this.transformToTop = true;
      return;
    }

    this.transformToTop = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
