import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import {
  EcoSettingsBlockModel,
  EcoSettingsBlockUpdateModel
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { EcoSettingsBlockTypeEnum } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/enums';

@Component({
  selector: 'rnpl-update-block-color-modal',
  templateUrl: './update-block-color-modal.component.html',
})
export class UpdateBlockColorModalComponent extends BaseModalComponent implements OnInit {

  public modalTitle = {
    [EcoSettingsBlockTypeEnum.NEW_ITEM]: 'ECOMMERCE.EDIT_NEW_ITEMS',
    [EcoSettingsBlockTypeEnum.BESTSELLER]: 'ECOMMERCE.EDIT_NEW_BESTSELLERS',
  };

  public form: FormGroup;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private fb: FormBuilder,
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<UpdateBlockColorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      block?: EcoSettingsBlockModel;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.initForm();
    if (this.data && this.data.block && this.data.block.container) {
      this.form.patchValue(this.data.block.container);
    }
  }

  public initForm(): void {
    this.form = this.fb.group({
      backgroundColor: [null, [Validators.minLength(7), Validators.maxLength(7)]],
    });
  }

  public removeBlock(): void {
    this.ecoSettingsApiService.ecoSettingsDeleteBlock(this.data.block.id)
      .pipe(takeUntil(this._destroy))
      .subscribe(() => this.dialogRef.close(true));
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity()

    if (this.form.invalid) {
      return;
    }

    const data: EcoSettingsBlockUpdateModel = {
      ...this.data.block.container,
      ...this.form.getRawValue(),
      blockType: this.data && this.data.block
        ? this.data.block.type
        : EcoSettingsBlockTypeEnum.PRODUCTS
    };

    this.ecoSettingsApiService.ecoSettingsUpdateBlock(this.data.block.id, data)
      .pipe(takeUntil(this._destroy))
      .subscribe((res) => this.dialogRef.close(res));
  }

  public getUploadImageLink(): string {
    return this.ecoSettingsApiService.getEcoUploadImageLink();
  }

  public get backgroundColorControl(): FormControl { return this.form.get('backgroundColor') as FormControl; }

}
