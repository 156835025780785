import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ToasterDefinitionModel, ToasterRequestModel } from './toaster-definition.model';


@Injectable()
export class ToasterService {

  public static readonly MESSAGE_TYPE_WARNING = 'warning';
  public static readonly MESSAGE_TYPE_ERROR = 'error';
  public static readonly MESSAGE_TYPE_SUCCESS = 'success';
  public static readonly MESSAGE_TYPE_INFO = 'info';
  public static readonly MESSAGE_TYPE_ACTIVATE_SUBSCRIPTION = 'activate-subscription';

  private readonly messages$: Subject<ToasterDefinitionModel> = new Subject();
  private readonly requestMessages$: BehaviorSubject<ToasterRequestModel[]> = new BehaviorSubject([]);

  /**
   * Returns Observable of ToasterDefinitionModel
   */
  public getMessages(): Observable<ToasterDefinitionModel> {
    return this.messages$;
  }

  public getRequestMessages(): Observable<ToasterRequestModel[]> {
    return this.requestMessages$;
  }

  /**
   * Add message in messages queue
   *
   * @param message ToasterDefinitionModel
   */
  public notify(message: ToasterDefinitionModel): void {
    if (typeof(message.message) === 'object') {
      message.message.forEach(el => this.messages$.next({...message, message: el}));
    } else {
      this.messages$.next(message);
    }
  }

  public notifyRequestMessage(message: ToasterRequestModel): void {
    if (!this.isRequestMessageInProgress(message)) {
      const messages = this.requestMessages$.getValue();
      this.requestMessages$.next([...messages, message]);
    }
  }

  public hideRequestMessage(key: string): void {
    // timeout for permanently completed requests
    setTimeout(() => {
      const messages = this.requestMessages$.getValue();
      const messageIndex = messages.findIndex(m => m.key === key);
      if (messageIndex !== -1) {
        messages.splice(messageIndex, 1);
        this.requestMessages$.next([...(messages || [])]);
      }
    }, 500);
  }

  public isRequestMessageInProgress(message): boolean {
    const messages = this.requestMessages$.getValue();
    const messageIndex = messages.findIndex(m => m.key === message.key);
    return messageIndex !== -1;
  }

  public serverError(error): void {
    this.notify({
      type: ToasterService.MESSAGE_TYPE_WARNING,
      message: error.error.message,
    });
  }
}
