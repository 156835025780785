import { Component, Input, OnDestroy } from '@angular/core';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';

import { CustomerTypeEnum, GeneralPartnerTypeEnum } from '../../../models';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-document-partner-info',
  templateUrl: './document-partner-info.component.html',
  styleUrls: ['./document-partner-info.component.scss']
})
export class DocumentPartnerInfoComponent implements OnDestroy {

  public companyProfile: CompanyProfile;

  @Input() public isPartnerExist: boolean = true;
  @Input() public partnerId?: number|string;
  @Input() public partnerType?: PartnersTypeEnum|CustomerTypeEnum;
  @Input() public generalType?: GeneralPartnerTypeEnum;
  @Input() public partnerName?: string;
  @Input() public partnerRunpleId?: string;
  @Input() public partnerImgUrl?: string;
  @Input() public notExistPartnerName?: string;
  @Input() public isMultiplePartner?: boolean;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public get partnerUrl(): string {
    if (this.partnerType !== PartnersTypeEnum.CORPORATE && this.partnerType !== PartnersTypeEnum.PRIVATE) {
      return null;
    }
    return `/partners-new/${this.partnerType}/${this.partnerId}`;
  }

  constructor(private store: Store<AppState>) {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
