import { Directive, Input, HostListener, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { debounceTime } from 'rxjs/internal/operators';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[dropdown-max-height]'
})
export class DropdownHeightDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public bottomIndent: number = 8;
  @Input() public parentElement: string;
  @Input() public headTmplOnly: boolean;
  @Input() public headTmplOffset: number = 0;

  public dropdownId: string;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private select: NgSelectComponent) {
      this.dropdownId = select.dropdownId;
  }

  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (this.headTmplOnly) {
        const isScrollingHead = (event.target.className as string).indexOf('dd-head-list') > -1;
        if (isScrollingHead) { return; }
      }
      if (isScrollingInScrollHost) { return; }
      this.select.close();
    }
  };

  ngOnInit(): void {
    window.addEventListener('scroll', this.onScroll, true);

    this.select.searchEvent
      .pipe(
        debounceTime(5),
        takeUntil(this.destroy$)
      )
      .subscribe( e => this.getMaxHeight());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('headTmplOnly') || changes.hasOwnProperty('bottomIndent')) {
      // timeout for header template update
      setTimeout(() =>  this.getMaxHeight(), 0);
    }
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.getMaxHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.select && this.select.isOpen) {
      this.select.close();
    }
  }

  public getMaxHeight() {
    const panel = document.querySelector(`#${this.dropdownId}`);
    const dropdownHeader = panel && panel.querySelector('.ng-dropdown-header');
    const dropdownContainer = panel && panel.querySelector('.ng-dropdown-panel-items') as HTMLElement;
    const bodyHeight = document.body.getBoundingClientRect().height;
    let {top, height} = this.select.element.getBoundingClientRect();
    let parentElementHeight;

    if (this.parentElement) {
      const parentElementContainer = document.querySelector(this.parentElement).getBoundingClientRect();
      parentElementHeight = parentElementContainer.height;
      top = top - parentElementContainer.top;
    } else {
      parentElementHeight = bodyHeight;
    }


    const dropdownPanelTop = top + height;
    const dropdownPanelMaxHeightToBottom = (parentElementHeight - dropdownPanelTop) - this.bottomIndent;
    const dropdownPanelMaxHeightToTop = top - this.bottomIndent;
    let dropdownPanelMaxHeight;

    if (this.select.currentPanelPosition === 'bottom') {
      dropdownPanelMaxHeight = dropdownPanelMaxHeightToBottom;
    } else {
      dropdownPanelMaxHeight = dropdownPanelMaxHeightToTop;
    }

    if  (dropdownContainer) {
      dropdownContainer.classList.remove('m-0', 'p-0');
    }

    if (dropdownHeader) {
      if (this.headTmplOnly) {
        const headerContainer = document.querySelector('.ng-dropdown-header') as HTMLElement;
        const headerList = document.querySelector('.dd-head-list') as HTMLElement;
        if (headerList) {
          headerList.style.maxHeight = `${dropdownPanelMaxHeight - this.headTmplOffset }px`;
        }
        headerContainer.style.maxHeight = `${dropdownPanelMaxHeight}px`;
        dropdownContainer.classList.add('m-0', 'p-0');
        return;
      }

      dropdownPanelMaxHeight = dropdownPanelMaxHeight - dropdownHeader.getBoundingClientRect().height;
    }

    if (panel) {
      dropdownContainer.style.maxHeight = `${dropdownPanelMaxHeight}px`;
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
