import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';

@Component({
  selector: 'rnpl-filters-control-partners',
  templateUrl: './filters-control-partners.component.html',
})
export class FiltersControlPartnersComponent extends FiltersControlBaseComponent implements OnInit {

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly combinedPartnersList$: BehaviorSubject<PartnerModel[]> = new BehaviorSubject<PartnerModel[]>([]);

  @Input() readonly companyIdBindValue: string = 'id';

  constructor(
    private partnersApiService: PartnersApiService,
    public translateService: TranslateService
  ) {
    super(translateService);
  }

  ngOnInit() {
    this.getCombinedPartners();
  }

  private getCombinedPartners(): void {
    this.partnersApiService.getCombinedPartnersList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(partners => {
        this.showDropdownSpin$.next(false);
        this.combinedPartnersList$.next(partners);
      });
  }

}
