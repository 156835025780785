import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'rnpl-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends BaseControlComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.disabled = this.control.disabled || false;
  }

  public change(event) {
    this.control.setValue(event.target.checked);
    this.control.markAsDirty();

    this.formUpdated.emit(true);
  }
}
