import { MatDialogConfig } from '@angular/material/dialog/typings/dialog-config';

export const UPDATE_DOCUMENT_SALES_PRICE_LIST_MODAL_CONFIG: MatDialogConfig = {
  data: {
    title: 'MULTIPLE_PRICE_LISTS.UPDATE_DOCUMENT_SALES_PRICE_LIST_TTL',
    message: 'MULTIPLE_PRICE_LISTS.UPDATE_DOCUMENT_SALES_PRICE_LIST_MSG',
    isRejectButton: true,
    cancelBtnClass: 'btn-white mr-auto',
    rejectBtnText: 'MULTIPLE_PRICE_LISTS.DONT_ACCEPT_CHANGES',
    rejectBtnIcon: 'close',
    rejectBtnClass: 'btn-grey',
    confirmBtnText: 'MULTIPLE_PRICE_LISTS.ACCEPT_CHANGES',
    confirmBtnIcon: 'checkmark',
  },
  disableClose: true
};

export const UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG: MatDialogConfig = {
  data: {
    title: 'MULTIPLE_PRICE_LISTS.CHANGE_PARTNER_SALES_PRICE_LIST_TTL',
    message: 'MULTIPLE_PRICE_LISTS.CHANGE_PARTNER_SALES_PRICE_LIST_MSG',
    isRejectButton: true,
    cancelBtnClass: 'btn-white mr-auto',
    rejectBtnText: 'MULTIPLE_PRICE_LISTS.DONT_ACCEPT_CHANGES',
    rejectBtnIcon: 'close',
    rejectBtnClass: 'btn-grey',
    confirmBtnText: 'MULTIPLE_PRICE_LISTS.ACCEPT_CHANGES',
    confirmBtnIcon: 'checkmark',
  },
  disableClose: true
};
