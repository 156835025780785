import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, interval, ReplaySubject, Subscription } from 'rxjs';
import { finalize, startWith, takeUntil, takeWhile } from 'rxjs/operators';
import { animate, group, query, sequence, style, transition, trigger } from '@angular/animations';

import { BarcodeApiService } from '../barcode.service';
import { BarcodeStatusEnum } from '../barcode-status.enum';
import { BarcodeModel } from '../barcode.model';
import { CommonModalsActionsEnum, DangerModalComponent } from '../../modals/modals-common';
import { ActionButtonsService } from '../../../services/action-buttons.service';

@Component({
  selector: 'rnpl-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
  animations: [
    trigger('copyBarcodeAnimation', [
      transition('* => visible', [

        group([
          query('visible, .checkmark', [
            style({
              opacity: 0,
              transform: 'scale(.3)',
            }),
            sequence( [
              animate(
                '250ms',
                style({ opacity: 1, transform: 'scale(1)'})
              ),
              animate(
                '300ms',
                style({ opacity: 1}),
              ),
              animate(
                '250ms',
                style({ opacity: 0, transform: 'scale(.3)'}),
              ),
            ]),
          ]),

          query('visible, .icon-copy', [
            style({
              transform: 'scale(1)',
            }),
            sequence([
              animate(
                '250ms',
                style({
                  transform: 'scale(.3)'
                })
              ),
              animate(
                '300ms',
                style({
                  transform: 'scale(.3)'
                })
              ),
              animate(
                '250ms',
                style({
                  transform: 'scale(1)'
                })
              ),
            ]),
          ]),
        ])
      ]),
    ]),
  ]
})
export class BarcodeComponent implements OnInit, OnDestroy {

  public tabs: any[] = [
    {
      icon: 'barcode',
      label: 'BARCODE.SCANNED',
      tabName: BarcodeStatusEnum.SCANNED
    },
    {
      icon: 'trash-2',
      label: 'TAB.DELETED',
      tabName: BarcodeStatusEnum.REMOVED
    }
  ];

  private subscriptions: Subscription[] = [];

  public barcodeList: {[key: string]: BarcodeModel[] } = {
    [BarcodeStatusEnum.SCANNED]: null,
    [BarcodeStatusEnum.REMOVED]: null,
  };

  public firstLoading: {[key: string]: boolean } = {
    [BarcodeStatusEnum.SCANNED]: true,
    [BarcodeStatusEnum.REMOVED]: true,
  };

  public activeTab: BarcodeStatusEnum = BarcodeStatusEnum.SCANNED;

  public barcodeStatusEnum = BarcodeStatusEnum;

  public dragPosition = {x: 0, y: 0};

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public copiedBarcodeId: number;

  public animationState: string;

  public barcodeSkeletonItems = Array(3);

  constructor(
    private barcodeApiService: BarcodeApiService,
    private dialog: MatDialog,
    private readonly actionButtonService: ActionButtonsService
  ) {
  }

  ngOnInit() {
    this.animationState = '';
    this.actionButtonService.barcodeDialogEmitter
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.toggle());
  }

  public changeTab(tabName: BarcodeStatusEnum): void {
    if (this.activeTab !== tabName) {
      this.activeTab = tabName;
      this.getBarcodes();
      this.animationState = '';
    }
  }

  public getBarcodes(preventLoading = false): void {
    this.animationState = '';
    if (!preventLoading) {
      this.isLoading$.next(true);
    }

    this.barcodeApiService.getBarcodesList(this.activeTab)
      .pipe(
        finalize(() => {
          this.isLoading$.next(false);
          this.firstLoading[this.activeTab] = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.barcodeList[this.activeTab] = res.data;
      });
  }

  public defaultPosition() {
    this.dragPosition = {x: 0, y: 0};
  }

  public copyBarcode(barcodeId: number, copied: boolean): void {
    this.animationState = '';
    this.copiedBarcodeId = barcodeId;
    this.barcodeApiService.copyBarcodeItem(barcodeId, copied)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.getBarcodes(true);

        if (copied) {
          this.animationState = 'visible';
        }
      });
  }

  public deleteBarcodePermanently(barcodeId: number): void {
    this.barcodeApiService.deleteBarcodeItemPermanently(barcodeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getBarcodes(true));
  }

  public deleteAllBarcodesPermanently(): void {
    const dialog = this.dialog.open(DangerModalComponent, {
      data: {
        title: 'BARCODE.CLEAR?',
        message: 'BARCODE.YOU_SURE?',
        confirmBtnText: 'BARCODE.CLEAR',
        confirmBtnIcon: 'fire'
      }
    });

    dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
      if (res === CommonModalsActionsEnum.CONFIRM) {
        this.barcodeApiService.deleteAllBarcodesPermanently()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.getBarcodes(true));
      }
    });
  }

  public changeBarcodeStatus(barcodeId: number, status: BarcodeStatusEnum): void {
    this.animationState = '';
    this.barcodeApiService.changeBarcodeItemStatus(barcodeId, status)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getBarcodes(true));
  }

  public changeMultipleBarcodesStatus(status: BarcodeStatusEnum): void {
    const ids = this.barcodeList[this.activeTab].map(itm => itm.id);
    this.barcodeApiService.changeBarcodesBatchStatus(status, ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.getBarcodes(true));
  }

  public close(): void {
    this.isOpen$.next(false);

    setTimeout(() => {
      this.defaultPosition();
    }, 300);
  }

  public toggle(): void {
    this.isOpen$.next(!this.isOpen$.getValue());
    this.subscriptions.forEach(s => s.unsubscribe());

    if (this.isOpen$.getValue()) {
      const subscription = interval(300 * 10e2)// 300 * 10e2 = 5 minutes
        .pipe(
          startWith(0),
          takeWhile(() => this.isOpen$.getValue()),
          takeUntil(this.destroy$),
        )
        .subscribe(() => this.getBarcodes());

      this.subscriptions.push(subscription);
    }

    setTimeout(() => {
      this.defaultPosition();
    }, 300);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
