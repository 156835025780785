import { Component } from '@angular/core';

@Component({
  selector: 'rnpl-vertical-tree-skeleton',
  templateUrl: './vertical-tree-skeleton.component.html',
  styleUrls: ['./vertical-tree-skeleton.component.scss']
})
export class VerticalTreeSkeletonComponent {

  skeletonElements = Array(10);
}
