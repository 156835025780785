import { get } from 'lodash';
import { SubscriptionListTabsEnum } from './enums';
import { SubscriptionModel } from './models/subscription.model';
import { UIStatesEnum } from 'common/src/models';

export function subscriptionHints(
  currentState?: UIStatesEnum,
  displayCreateInvoiceBtn?: boolean,
  activeTab?: SubscriptionListTabsEnum | string,
  hasSubscriptions?: boolean,
  subscription?: SubscriptionModel
) {
  const isReadOnly = currentState === UIStatesEnum.VIEW;
  const isCreated = currentState === UIStatesEnum.CREATE;
  const isEditState = currentState === UIStatesEnum.EDIT;
  const isCompletedStatus = activeTab === SubscriptionListTabsEnum.COMPLETED;
  const isDraftStatus = activeTab === SubscriptionListTabsEnum.DRAFT;
  const isOpenStatus = activeTab === SubscriptionListTabsEnum.OPEN;
  const isPausedStatus = activeTab === SubscriptionListTabsEnum.PAUSED;
  const isDeletedStatus = activeTab === SubscriptionListTabsEnum.DELETED;
  const isCanceledStatus = activeTab === SubscriptionListTabsEnum.CANCELED;

  const hintsList = {
    ['subscriptions_view_draft_2']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_12',
      attachTo: {
        element: '#blockBilling',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -30] }}]
      },
    },
    ['subscriptions_view_draft_3']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_13',
      attachTo: {
        element: '#blockBilling',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -122] }}]
      },
    },
    // ['subscriptions_view_draft_5']: { // todo: move to billing info modal
    //   text: 'MEGA_HINT.SUBSCRIPTION.HINT_15',
    //   attachTo: {
    //     element: '#paymentTerms',
    //     on: 'top'
    //   },
    //   classes: 'ml--25',
    // },
    ['subscriptions_view_draft_6']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_16',
      attachTo: {
        element: '#blockBilling',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -300] }}]
      },
    },
    ['subscriptions_view_draft_7']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_17',
      attachTo: {
        element: '#priceManagement',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['subscriptions_view_draft_8']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_18',
      attachTo: {
        element: '#takeEffectNextBillingPeriod',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 24] }}]
      },
    },
    // ['reverse_charge_toggle_subscriptions']: { // todo: move to billingInfo Modal
    //   text: 'MEGA_HINT.SUBSCRIPTION.HINT_27',
    //   attachTo: {
    //     element: '#vatDisabled',
    //     on: 'bottom'
    //   },
    // },

    ['sidebar_linked_document_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_30',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
    ['create_entity_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_33',
      attachTo: {
        element: '#createSubscriptionBtn',
        on: 'bottom'
      },
    },
    ['filter_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_34',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
  }

  const billingScheduleHints = {
    ['subscriptions_view_draft_4']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_14',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const linkedInvoiceHint = {
    ['linked_document_invoice_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_30',
      attachTo: {
        element: '#billingStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, displayCreateInvoiceBtn ? -40 : 0] }}]
      },
    },
  }

  const doneBtnHint = {
    ['done_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_44',
      attachTo: {
        element: '#doneBtn',
        on: 'bottom'
      },
    },
  }

  const editBtnHint = {
    ['edit_entity_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_29',
      attachTo: {
        element: '#editBtn',
        on: 'bottom'
      },
    },
  }

  const cancelBtnHint = {
    ['cancel_entity_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_28',
      attachTo: {
        element: '#cancelBtn',
        on: 'bottom'
      },
    },
  }

  const cloneBtnHint = {
    ['subscriptions_view_open_2_1']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_41',
      attachTo: {
        element: '#cloneBtn',
        on: 'bottom'
      },
    },
  }

  const editStateHints= {
    // ['subscriptions_view_draft_1']: { //todo: move to selectPartner modal
    //   text: 'MEGA_HINT.SUBSCRIPTION.HINT_11',
    //   attachTo: {
    //     element: '#selectPartner',
    //     on: 'top'
    //   },
    // },
    ['create_positions_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_31',
      attachTo: {
        element: '#addPositionBtn',
        on: 'bottom'
      },
    },
    ['save_timer_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_43',
      attachTo: {
        element: '#savedInfo',
        on: 'bottom'
      },
    },
  }

  const validationHint = {
    ['field_validation_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_35',
      attachTo: {
        element: '#selectCustomer',
        on: 'bottom'
      },
    },
  }

  const pausedViewStateHints = {
    ['subscriptions_view_pause_1']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_23',
      attachTo: {
        element: '#activeSubscriptionBtn',
        on: 'bottom'
      },
      scrollTo: false,
    },
  }

  const openViewStateHints = {
    ['subscriptions_view_open_1']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_21',
      attachTo: {
        element: '#pauseBtn',
        on: 'bottom'
      },
    },
  }

  const createInvoiceBtnHint = {
    ['subscriptions_view_open_4']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_19',
      attachTo: {
        element: '#createInvoice',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 16] }}]
      },
    },
  }

  const draftTabHint = {
    ['tab_draft_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_25',
      attachTo: {
        element: '#draftTab',
        on: 'bottom'
      },
    },
  }

  const sidebarSteps = {
    ['sidebar_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_42',
      attachTo: {
        element: '#sidebarSteps',
        on: 'left'
      },
    },
  }

  const pausedTabHint = {
    ['subscriptions_tab_draft_2']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_5',
      attachTo: {
        element: '#pauseTab',
        on: 'bottom'
      },
    },
  }

  const completedTabHint = {
    ['subscriptions_tab_draft_3']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_6',
      attachTo: {
        element: '#completedTab',
        on: 'bottom'
      },
    },
  }

  const canceledTabHint = {
    ['subscriptions_tab_draft_4']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_7',
      attachTo: {
        element: '#canceledTab',
        on: 'bottom'
      },
    },
  }

  const openTabHint = {
    ['subscriptions_tab_draft_1']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_4',
      attachTo: {
        element: '#openTab',
        on: 'bottom'
      },
    },
    ['subscriptions_view_open_3']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_22',
      attachTo: {
        element: '#billingStep',
        on: 'top'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, -17] }}]
      },
    },
  }

  const deletedTabHint = {
    ['tab_deleted_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_26',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  const columnHints = {
    ['subscriptions_tab_draft_5']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_1',
      attachTo: {
        element: '#billingAmountColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const durationColumnHint = {
    ['subscriptions_tab_draft_6']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_2',
      attachTo: {
        element: '#durationColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  const draftOpenPausedColumnHints = {
    ['subscriptions_tab_draft_7']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_3',
      attachTo: {
        element: '#billingColumn',
        on: 'bottom'
      },
    },
  }

  const openTabColumnHints = {
    ['subscriptions_tab_open_1']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_8',
      attachTo: {
        element: '#nextBillingDateColumn',
        on: 'bottom'
      },
    },
  }

  const openCompletePausedColumnHints = {
    ['subscriptions_tab_open_2']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_9',
      attachTo: {
        element: '#generatedRevenueColumn',
        on: 'bottom'
      },
    },
  }

  const pausedColumnHints = {
    ['subscriptions_tab_paused_1']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_10',
      attachTo: {
        element: '#pausedPeriodColumn',
        on: 'bottom'
      },
    },
  }

  const exportBtnHint = {
    ['export_button_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_32',
      attachTo: {
        element: '#exportCsvBtn',
        on: 'bottom'
      },
    },
  }

  const pauseDurationHint = {
    ['subscriptions_view_pause_2']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_24',
      attachTo: {
        element: '#obligationsStep',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  // const obligationStepHint = {
  //   ['subscriptions_view_open_2']: {
  //     text: 'MEGA_HINT.SUBSCRIPTION.HINT_20',
  //     attachTo: {
  //       element: '#obligationsStep',
  //       on: 'top'
  //     },
  //     popperOptions : {
  //       modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
  //     },
  //   },
  // }

  const totalRowHint = {
    ['total_row_subscriptions']: {
      text: 'MEGA_HINT.SUBSCRIPTION.HINT_45',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }

  if (displayCreateInvoiceBtn) {
    Object.assign(hintsList, createInvoiceBtnHint);
  }
  if (isDraftStatus) {
    Object.assign(hintsList, draftTabHint);

    if (!isReadOnly) {
      Object.assign(hintsList, sidebarSteps);
    }
  }
  if (isPausedStatus) {
    Object.assign(hintsList, pausedTabHint);
    Object.assign(hintsList, pauseDurationHint);

    if (isReadOnly) { Object.assign(hintsList, pausedViewStateHints); }
  }
  if (isCompletedStatus) {
    Object.assign(hintsList, completedTabHint);
  }
  if (isCanceledStatus) {
    Object.assign(hintsList, canceledTabHint);
  }

  if (isOpenStatus) {
    Object.assign(hintsList, openTabHint);

    if (hasSubscriptions) { Object.assign(hintsList, openTabColumnHints); }
    if (isReadOnly) { Object.assign(hintsList, openViewStateHints); }
  }

  if (isDeletedStatus) {
    Object.assign(hintsList, deletedTabHint);
  }

  if (hasSubscriptions) {
    Object.assign(hintsList, columnHints);
    Object.assign(hintsList, exportBtnHint);
  }
  if (!isCompletedStatus && hasSubscriptions) {
    Object.assign(hintsList, durationColumnHint);
  }
  if (isPausedStatus && hasSubscriptions) {
    Object.assign(hintsList, pausedColumnHints);
  }
  if ((isDraftStatus || isOpenStatus || isPausedStatus) && hasSubscriptions) {
    Object.assign(hintsList, draftOpenPausedColumnHints);
  }
  if ((isOpenStatus || isCompletedStatus || isPausedStatus) && hasSubscriptions) {
    Object.assign(hintsList, openCompletePausedColumnHints);
  }
  if (!!subscription && !subscription.properties.company.id && !isDeletedStatus && !isReadOnly) {
    Object.assign(hintsList, validationHint);
  }
  if (
    !!subscription &&
    !!subscription.linkedDocuments &&
    subscription.linkedDocuments.some(doc => !!doc.documentRunpleId) &&
    !isCanceledStatus &&
    !isDeletedStatus
  ) {
    Object.assign(hintsList, linkedInvoiceHint);
  }

  if (
    isReadOnly &&
    !isCompletedStatus &&
    !isCanceledStatus &&
    !isDeletedStatus
  ) {
    Object.assign(hintsList, editBtnHint);
  }

  if (!isReadOnly) {
     Object.assign(hintsList, editStateHints);
  }

  if (!isCanceledStatus && !isDeletedStatus && isReadOnly) {
    Object.assign(hintsList, cloneBtnHint);
  }
  if ((isOpenStatus || isPausedStatus) && isReadOnly) {
    Object.assign(hintsList, cancelBtnHint);
  }
  // if (isReadOnly && !isCanceledStatus && !isDeletedStatus&& !isDraftStatus) {
  //   Object.assign(hintsList, obligationStepHint);
  // }
  if (!isCanceledStatus && !isDeletedStatus && hasSubscriptions) {
    Object.assign(hintsList, totalRowHint);
  }

  if (!isDraftStatus && !isReadOnly) {
    Object.assign(hintsList, doneBtnHint);
  }

  if ((isDraftStatus && !isReadOnly) && get(subscription, 'properties.billingSchedule.billingDates.length')) {
    Object.assign(hintsList, billingScheduleHints);
  }

  return hintsList;

}

export const PauseModalHints = {
  ['subscriptions_set_pause_1']: {
    text: 'MEGA_HINT.SUBSCRIPTION.HINT_24',
    attachTo: {
      element: '#PAUSE_EXTENDS_DURATION',
      on: 'bottom'
    },
    classes: 'from-modal',
    scrollTo: false,
  },
  ['subscriptions_set_pause_2']: {
    text: 'MEGA_HINT.SUBSCRIPTION.HINT_39',
    attachTo: {
      element: '#PAUSE_WITHIN_DURATION',
      on: 'top'
    },
    classes: 'from-modal',
    scrollTo: false,
  },
}

export const BillingInfoHints = {
  ['reverse_charge_toggle_subscriptions']: {
    text: 'MEGA_HINT.SUBSCRIPTION.HINT_27',
    attachTo: {
      element: '#vatDisabled',
      on: 'bottom'
    },
  },
  ['subscriptions_view_draft_5']: {
    text: 'MEGA_HINT.SUBSCRIPTION.HINT_15',
    attachTo: {
      element: '#paymentTerms',
      on: 'top'
    },
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
    },
    classes: 'ml--25',
  },
}

export const SelectPartnersHints = {
  ['subscriptions_view_draft_1']: {
    text: 'MEGA_HINT.SUBSCRIPTION.HINT_11',
    attachTo: {
      element: '#selectPartner',
      on: 'top'
    },
  },
}
