import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { FamilyModel } from 'common/src/modules/products/models';
import { ProductsService } from 'common/src/modules/products/products.service';
import { ProductTypes } from 'common/src/modules/products/product-types';
import { CommonModalsActionsEnum } from '../../modals-common';

@Component({
  selector: 'rnpl-move-products-modal',
  templateUrl: './move-products-modal.component.html',
})
export class MoveProductsModalComponent extends BaseModalComponent implements OnInit {

  public family: FamilyModel;
  public familiesTree: Array<FamilyModel>;
  public flatFamilies: FamilyModel[] = [];
  public selectedFamily: FamilyModel = null;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly blueprintLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly submitProductRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    public productsService: ProductsService,
    public dialogRef: MatDialogRef<MoveProductsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      productType: ProductTypes,
      productIds: number[]
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.showDropdownSpin$.next(true);

    const requestParams = new HttpParams().set('products_type', this.data.productType);

    this.productsService.getFamiliesTree(requestParams)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        this.familiesTree = response.data;
        this.flatFamilies =  this.convertTreeToFlat(this.familiesTree);
        this.showDropdownSpin$.next(false);
      });
  }

  private convertTreeToFlat(tree: FamilyModel[]): FamilyModel[] {
    if (!tree) { return; }

    const flatArray = [];
    const getTreeItems = (branch) => {
      branch.map(node => {
        if (!node.product_locked_at) {
          flatArray.push(node);
        }

        if (node.children.length) {
          getTreeItems(node.children);
        }
      });
    };

    getTreeItems(tree);

    return flatArray;
  }

  public submitMove(): void {
    if (this.submitProductRequest$.getValue()) { return; }
    this.submitProductRequest$.next(true);

    if (!this.selectedFamily) {
      this.displayMessage('error', 'MESSAGES.CATEGORY_MUST_BE_FILLED');
      this.submitProductRequest$.next(false);
      return;
    }

    this.productsService.moveProductsToAnotherCategory(this.data.productIds, this.selectedFamily.id)
      .pipe(
        finalize(() => { this.submitProductRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(() => {
        this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
      });
  }

}
