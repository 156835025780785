import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-process-card-group',
  templateUrl: 'process-card-group.component.html',
  styleUrls: ['process-card-group.component.scss']
})
export class ProcessCardGroupComponent {

  @Input()
  cardGroupTitle: string;

  @Input()
  cardGroupCount: number = 0;

  public isOpen = false;

  public getTypeGroup(): string {
    let color;
    switch (this.cardGroupTitle) {
      case 'today':
        color = 'green';
        break;
      case 'tomorrow':
        color = 'yellow';
        break;
      case 'overdue':
        color = 'red';
        break;
      default:
        color = '';
        break;
    }
    return color;
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }
}
