import { ProductTypes } from 'common/src/modules/products/product-types';

export function hasDifferentProductTypePositions(groupedPositions: {[key in ProductTypes]?: any[]}): boolean {
  let acc = 0;
  for (const key in groupedPositions) {
    if (groupedPositions.hasOwnProperty(key) && groupedPositions[key].length) { acc = acc + 1;}
  }

  return acc > 1;
}
