import {AfterViewInit, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { catchError, finalize, startWith, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';

import { NavBarBtnModel } from '../../../../models';
import {
  getCommonAndAdditionalColumns,
  getCommonColumns,
  NavBarButtons,
  STOCK_AREA_LIST,
  TRANSACTION_TYPE_LIST
} from './create-new-transaction-modal.config';
import { StockAreaEnum, TransactionTypeEnum } from 'projects/workspace/src/app/warehouse/modules/transactions/enums';
import { TransactionsApiService } from 'projects/workspace/src/app/warehouse/modules/transactions/services/transactions-api.service';
import { ProductModel } from '../../../products';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { BinLocationProductModel } from 'projects/workspace/src/app/warehouse/modules/transactions/models';
import { PRODUCTS_TYPE } from '../../../rnpl-common/constants/products-type';
import { CustomSearchFn, getProductSearchValueCamel } from '../../../rnpl-common/helpers';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from '../../modals-common';
import { ToasterService } from '../../../ui-components/toaster';
import { ProductTypes } from '../../../products/product-types';

@Component({
  selector: 'rnpl-create-transaction-modal',
  templateUrl: './create-new-transaction-modal.component.html',
  styleUrls: ['./create-new-transaction-modal.component.scss'],
})
export class CreateNewTransactionModalComponent implements OnInit, OnDestroy {
  public columns: TableColumnModelExtended[] = getCommonColumns();
  public commonAndAdditionalColumns: TableColumnModelExtended[] = getCommonAndAdditionalColumns(this.translateService);
  public transactionTypeEnum = TransactionTypeEnum;
  public transactionTypeList = TRANSACTION_TYPE_LIST;
  public stockAreaList = STOCK_AREA_LIST;
  // public activeBinLocationsList = [];
  public targetBinLocationsList = [];
  public sourceBinLocationsList = [];
  public quantity: number;
  public notAvailableUnitsExists = false;
  public form: FormGroup;
  public actionButtons: NavBarBtnModel[] = NavBarButtons;
  public productType = PRODUCTS_TYPE;
  public productTypes: typeof ProductTypes = ProductTypes;

  public customSearchFn = CustomSearchFn;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly productsList$: BehaviorSubject<Partial<ProductModel>[]> = new BehaviorSubject<Partial<ProductModel>[]>([]);
  readonly sourceProductBatchesList$: BehaviorSubject<BinLocationProductModel[]> = new BehaviorSubject<BinLocationProductModel[]>([]);
  readonly targetProductBatchesList$: BehaviorSubject<BinLocationProductModel[]> = new BehaviorSubject<BinLocationProductModel[]>([]);
  readonly productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private transactionsApiService: TransactionsApiService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateNewTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    // this.getBinLocations();
  }

  public actionsEvent(e: string): void {
    this[e]();
  }

  private initForm(): void {
    this.form = this.fb.group({
      productId: [],
      transactionType: [TransactionTypeEnum.STOCK_TRANSFER],
      batch: [{value: null, disabled: true}],
      expirationDate: [{value: null, disabled: true}],
      targetBinLocationId: [{value: null, disabled: true}],
      sourceBinLocationId: [{value: null, disabled: true}],
      targetStockArea: [StockAreaEnum.GENERAL_STOCK],
      sourceStockArea: [StockAreaEnum.GENERAL_STOCK],
      transferAllAvailableUnits: [],
      writeOffAllAvailableUnits: [],
    });

    this.trackTransactionTypeControlChanges();
    this.trackProductIdControlChanges();
    this.trackSourceStockAreaControlChanges();
    this.trackTargetStockAreaControlChanges();
    this.trackSourceBinLocationIdControlChanges();
    this.trackTargetBinLocationIdControlChanges();
    this.trackTransferAllAvailableUnitsControlChanges();
    this.trackWriteOffAllAvailableUnitsControlChanges();
  }

  // public getBinLocations(): void {
  //   this.transactionsApiService.getTransactionBinLocations()
  //     .pipe(
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe(products => {
  //       this.activeBinLocationsList = products;
  //     });
  // }

  public getSourceBinLocations(): void {
    this.transactionsApiService.getTransactionBinLocations(
      this.productIdControl.value,
      this.sourceStockAreaControl.value
    )
      .pipe(
        tap(res => {
          if (res.hasOwnProperty('notAvailableUnitsExists') && this.transactionTypeControl.value !== TransactionTypeEnum.BOOK_PRODUCT) {
            this.notAvailableUnitsExists = res.notAvailableUnitsExists;
            return;
          }
          this.notAvailableUnitsExists = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(products => this.sourceBinLocationsList = products.data);
  }

  public getTargetBinLocations(): void {
    this.transactionsApiService.getTransactionBinLocations(
      null,
      this.targetStockAreaControl.value,
      this.transactionTypeControl.value === TransactionTypeEnum.STOCK_TRANSFER ? this.sourceBinLocationIdControl.value : null
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(products => this.targetBinLocationsList = products.data);
  }

  public trackTransactionTypeControlChanges(): void {
    this.transactionTypeControl.valueChanges
      .pipe(
        startWith(TransactionTypeEnum.STOCK_TRANSFER),
        takeUntil(this.destroy$)
      )
      .subscribe((value: TransactionTypeEnum) => {
        this.productsLoading$.next(true);
        this.productsList$.next([]);

        this.transactionsApiService.getAvailableForTransactionProducts(value)
          .pipe(
            finalize(() => this.productsLoading$.next(false)),
            takeUntil(this.destroy$)
          )
          .subscribe(products => {
            this.productsList$.next(this.prepareProductsList(products));
            this.formReset();
          });

      });
  }

  public prepareProductsList(products): ProductModel[] {
    return products.map(item => ({
      ...item,
      searchLabel: getProductSearchValueCamel(item, this.translateService)
    }));
  }

  public trackProductIdControlChanges(): void {
    this.productIdControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value) {
          this.getSourceBinLocations();
          this.getTargetBinLocations();

          this.batchControl.enable({emitEvent: false});
          this.expirationDateControl.enable({emitEvent: false});
          this.targetBinLocationIdControl.patchValue(this.targetBinLocationsList ? this.targetBinLocationsList[0].id : null);
          this.sourceBinLocationIdControl.patchValue(this.sourceBinLocationsList ? this.sourceBinLocationsList[0].id : null);
          this.targetBinLocationIdControl.enable({emitEvent: false});
          this.sourceBinLocationIdControl.enable({emitEvent: false});
        }
      });
  }

  public trackTargetStockAreaControlChanges(): void {
    this.targetStockAreaControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value) {
          this.getTargetBinLocations();
          this.targetBinLocationIdControl.patchValue(null);
          // this.targetBinLocationIdControl.enable({emitEvent: false});
        }
      });
  }

  public trackSourceStockAreaControlChanges(): void {
    this.sourceStockAreaControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value) {
          this.getSourceBinLocations();
          this.sourceBinLocationIdControl.patchValue(null);
          // this.sourceBinLocationIdControl.enable({emitEvent: false});
        }
      });
  }

  public trackSourceBinLocationIdControlChanges(): void {
    this.sourceBinLocationIdControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value && this.productIdControl.value) {
          this.getTargetBinLocations();

          this.transactionsApiService.getBinLocationProductData(this.productIdControl.value, value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(batches => this.sourceProductBatchesList$.next(batches));
        }
      });
  }

  public trackTargetBinLocationIdControlChanges(): void {
    this.targetBinLocationIdControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => {
        if (value && this.productIdControl.value) {
          this.transactionsApiService.getBinLocationProductData(this.productIdControl.value, value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(batches => this.targetProductBatchesList$.next(batches));
        }
      });
  }

  public trackTransferAllAvailableUnitsControlChanges(): void {
    this.transferAllAvailableUnitsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value && this.sourceProductBatchesList$.getValue().length) {
          const batches = this.sourceProductBatchesList$.getValue().map(batch => ({
            ...batch,
            quantity: batch.available
          }));

          this.sourceProductBatchesList$.next(batches);
        }
      });
  }

  public trackWriteOffAllAvailableUnitsControlChanges(): void {
    this.writeOffAllAvailableUnitsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value) {
          const batches = this.sourceProductBatchesList$.getValue().map(batch => ({
            ...batch,
            quantity: batch.available
          }));

          this.sourceProductBatchesList$.next(batches);
        }
      });
  }

  public increaseQuantity(): void {
    this.quantity = (this.quantity || 0) + 1;
  }

  public decreaseQuantity(): void {
    if (!this.quantity) { return; }
    this.quantity = this.quantity - 1;
  }

  public onEditTableCell(): void {
    this.transferAllAvailableUnitsControl.patchValue(false);
    this.writeOffAllAvailableUnitsControl.patchValue(false);
  }

  public formReset(): void {
    if (!this.form) { return; }

    this.productIdControl.reset();
    this.expirationDateControl.reset();
    this.batchControl.reset();
    this.quantity = null;
    this.notAvailableUnitsExists = false;

    this.transferAllAvailableUnitsControl.reset();
    this.writeOffAllAvailableUnitsControl.reset();

    this.sourceStockAreaControl.patchValue(StockAreaEnum.GENERAL_STOCK);
    this.targetStockAreaControl.patchValue(StockAreaEnum.GENERAL_STOCK);

    this.sourceBinLocationIdControl.reset();
    this.targetBinLocationIdControl.reset();
    this.sourceBinLocationIdControl.disable({emitEvent: false});
    this.targetBinLocationIdControl.disable({emitEvent: false});
    this.batchControl.disable({emitEvent: false});
    this.expirationDateControl.disable({emitEvent: false});
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submit();
  }

  onSubmitAndContinue(): void {
    this.submit(true);
  }

  public submit(isContinue = false, skipDeliveryReservedWarning = false): void {
    const form = {
      productId: this.productIdControl.value
    };

    if (this.transactionTypeControl.value === TransactionTypeEnum.STOCK_TRANSFER) {
      form['sourceBinLocationId'] = this.sourceBinLocationIdControl.value;
      form['targetBinLocationId'] = this.targetBinLocationIdControl.value;
      form['products'] = this.sourceProductBatchesList$.getValue();
    }

    if (this.transactionTypeControl.value === TransactionTypeEnum.WRITE_OFF_PRODUCT) {
      form['sourceBinLocationId'] = this.sourceBinLocationIdControl.value;
      form['products'] = this.sourceProductBatchesList$.getValue();
      form['skipDeliveryReservedWarning'] = skipDeliveryReservedWarning;
    }

    if (this.transactionTypeControl.value === TransactionTypeEnum.BOOK_PRODUCT) {
      form['quantity'] = this.quantity;
      form['batch'] = this.batchControl.value;
      form['expirationDate'] = this.expirationDateControl.value;
      form['targetBinLocationId'] = this.targetBinLocationIdControl.value;
    }

    this.transactionsApiService.createTransaction(form, this.transactionTypeControl.value)
      .pipe(
        finalize(() => {
          this.btnToClearLoadingStatus$.next('onSubmit');
        }),
        catchError(error => {
          this.handlePopupErrors(error, isContinue);
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        if (!isContinue) {
          this.dialogRef.close(res);
          return;
        }

        this.formReset();
      });
  }

  private handlePopupErrors(error: HttpErrorResponse, isContinue: boolean): void {
    switch (error.error.message) {
      case 'unitsReceiptedFromOpenOrder':
        this.dialog.open(InfoModalComponent, {
          data: {
            title: 'WAREHOUSE.TRANSACTIONS.WRITE_OFF_MODAL_TITLE',
            message: 'WAREHOUSE.TRANSACTIONS.WRITE_OFF_MODAL_MSG',
            hideCancelBtn: true,
            titleIcon: 'alert-triangle',
            titleColor: 'yellow-400',
            confirmBtnText: 'BUTTON.CLOSE',
            confirmBtnIcon: 'close'
          }
        });
        break;
      case 'writeOffProductsReservedForDelivery':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: {
              title: 'COMMON.WRITE_OFF_PRODUCT',
              message: 'WAREHOUSE.TRANSACTIONS.WRITE_OFF_RESERVED_MODAL_MSG',
              confirmBtnText: 'BUTTON.CONTINUE',
              confirmBtnIcon: 'plus-square'
            }
          });

          dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.submit(isContinue, true);
            }
          });
        }
        break;
      default:
        this.showMsg('error', error.error.message);
        break;
    }
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  get isStockTransferType(): boolean {
    return this.transactionTypeControl.value === TransactionTypeEnum.STOCK_TRANSFER;
  }

  get isWriteOfProductType(): boolean {
    return this.transactionTypeControl.value === TransactionTypeEnum.WRITE_OFF_PRODUCT;
  }

  get isBookProductType(): boolean {
    return this.transactionTypeControl.value === TransactionTypeEnum.BOOK_PRODUCT;
  }

  get productIdControl(): FormControl { return this.form.get('productId') as FormControl; }
  get transactionTypeControl(): FormControl { return this.form.get('transactionType') as FormControl; }
  get batchControl(): FormControl { return this.form.get('batch') as FormControl; }
  get expirationDateControl(): FormControl { return this.form.get('expirationDate') as FormControl; }
  get targetStockAreaControl(): FormControl { return this.form.get('targetStockArea') as FormControl; }
  get sourceStockAreaControl(): FormControl { return this.form.get('sourceStockArea') as FormControl; }
  get targetBinLocationIdControl(): FormControl { return this.form.get('targetBinLocationId') as FormControl; }
  get sourceBinLocationIdControl(): FormControl { return this.form.get('sourceBinLocationId') as FormControl; }
  get transferAllAvailableUnitsControl(): FormControl { return this.form.get('transferAllAvailableUnits') as FormControl; }
  get writeOffAllAvailableUnitsControl(): FormControl { return this.form.get('writeOffAllAvailableUnits') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
