import { NavBarBtnModel } from '../../../../models';
import { StockAreaEnum, TransactionTypeEnum } from 'projects/workspace/src/app/warehouse/modules/transactions/enums';
import { ColumnTypeEnum, TableColumnModelExtended } from '../../../../models/table-column.model';
import { TranslateService } from '@ngx-translate/core';

export const TRANSACTION_TYPE_LIST = [
  {
    label: 'FORM.STOCK_TRANSFER',
    value: TransactionTypeEnum.STOCK_TRANSFER
  },
  {
    label: 'FORM.BOOK_PRODUCT',
    value: TransactionTypeEnum.BOOK_PRODUCT
  },
  {
    label: 'FORM.WRITE_OFF_PRODUCT',
    value: TransactionTypeEnum.WRITE_OFF_PRODUCT
  }
];

export const STOCK_AREA_LIST = [
  {
    label: 'TAB.GENERAL_STOCK',
    value: StockAreaEnum.GENERAL_STOCK
  },
  {
    label: 'TAB.RETURNS_STOCK',
    value: StockAreaEnum.RETURNS_STOCK
  },
];


export const NavBarButtons: NavBarBtnModel[] = [
  {
    classes: 'btn-grey',
    text: 'BUTTON.CANCEL',
    iconName: 'close',
    disabled: false,
    actionName: 'onCloseClick',
    multiple: false,
  },
  {
    classes: 'btn-primary btn-create-category',
    text: 'BUTTON.CREATE',
    iconName: 'plus-square',
    allowLoadingSpinner: true,
    actionName: 'onSubmit',
    disabled: false,
    multiple: false,
    dropdownClass: 'inner-blue-400 btn-drop-menu--inner-fluid icon-close-right',
    hintsId: 'createTransactionModalBtn',
    actions: [
      {
        actionTitle: 'BUTTON.CREATE',
        actionName: 'onSubmit',
        actionIcon: 'plus-square'
      },
      {
        actionTitle: 'BUTTON.CREATE_AND_CONTINUE',
        actionName: 'onSubmitAndContinue',
        actionIcon: 'plus-square'
      }
    ]
  },
];

export function getCommonColumns(): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BATCH',
      prop: 'batchRunpleId',
      sortable: true,
      // resizeable: false,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.STORED',
      prop: 'stored',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 100,
      maxWidth: 100,
    },
  ];
}

export function getCommonAndAdditionalColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    ...getCommonColumns(),
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.AVAILABLE',
      prop: 'available',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 100,
      maxWidth: 100,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      type: ColumnTypeEnum.STEPPER,
      decimal: 0,
      additionalText: translateService.instant('FORM.PCS'),
      width: 166,
      maxWidth: 166,
    },
  ];
}
