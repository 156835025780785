import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { debounceTime } from 'rxjs/internal/operators';
import { isEqual } from 'lodash';

import { DASHBOARDS_PERIODS_LIST } from '../../constants';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { CompanyProfile } from '../../../administration/models/company-profile.model';
import { AppState } from '../../../store/state/app.state';
import { DashboardPeriodFormModel } from './dashboard-period-form.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { daysBetweenDates } from '../../helpers';

@Component({
  selector: 'rnpl-dashboard-period-form',
  templateUrl: './dashboard-period-form.component.html',
  styleUrls: ['./dashboard-period-form.component.scss']
})
export class DashboardPeriodFormComponent implements OnInit, OnChanges, OnDestroy {

  public form: FormGroup;
  // public companyProfile: CompanyProfile;
  // public wid: number;

  @Input() public initialSelectedRange: string = 'CURRENT_MONTH';
  @Input() public dashboardsPeriodsList = DASHBOARDS_PERIODS_LIST;
  @Output() formChanged: EventEmitter<DashboardPeriodFormModel> = new EventEmitter<DashboardPeriodFormModel>();

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly fb: FormBuilder,
    // private readonly store: Store<AppState>,
    // private readonly toasterService: ToasterService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.outputValues();

    // this.store
    //   .select(selectCompanyProfile)
    //   .pipe(
    //     distinctUntilChanged(isEqual),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((profile: CompanyProfile) => {
    //     this.companyProfile = profile;
    //     this.wid = profile.workspaceId;
    //     this.outputValues();
    //   });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  public initForm(): void {
    this.form = this.fb.group({
      selectedRange: [this.initialSelectedRange],
      // groupBy: ['DAY'],
      // dateTo: [new Date()],
      // dateFrom: [],
    });

    // this.periodControl.valueChanges
    //   .pipe(
    //     startWith('month'),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((period: string) => {
    //     this.setDateFrom(period);
    //   });
    //
    // this.dateFromControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((value: Date) => {
    //     this.updateGroupBy(value, this.dateToControl.value);
    //   });
    //
    // this.dateToControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((value: Date) => {
    //     this.updateGroupBy(this.dateFromControl.value, value);
    //   });

    this.form.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe((value: any) => {
        this.outputValues();
      });
  }

  // public datePickerValueManuallyChanged(): void {
  //   // set 'custom-period' value to this.periodControl if one of dates changed manually
  //   this.periodControl.patchValue('custom-period', {emitEvent: false});
  // }

  public outputValues(): void {
    const formValue = this.form.getRawValue();
    // if (!formValue.dateTo || !formValue.dateFrom) {
    //   return;
    // }
    //
    // if (formValue.dateTo < formValue.dateFrom) {
    //   this.toasterService.notify({type: 'error', message: 'DASHBOARDS.DATE_ERROR'});
    //   return;
    // }
    //
    // if ((this.wid || this.wid === 0) && formValue) {
    //   // if (formValue.dateTo) {
    //     formValue.dateTo = this.dateToString(formValue.dateTo);
    //   // }
    //   // if (formValue.dateFrom) {
    //     formValue.dateFrom = this.dateToString(formValue.dateFrom);
    //   // }
      this.formChanged.emit(formValue);
    // }
  }

  // public updateGroupBy(dateFrom: Date, dateTo: Date): void {
  //   if ((this.periodControl.value === 'custom-period' || this.periodControl.value === 'total') && dateFrom && dateTo) {
  //     const daysBetween = daysBetweenDates(dateFrom, dateTo);
  //     switch(true) {
  //       case daysBetween < 30:
  //         this.groupByControl.patchValue('DAY');
  //         break;
  //       case daysBetween < 180:
  //         this.groupByControl.patchValue('WEEK');
  //         break;
  //       default:
  //         this.groupByControl.patchValue('MONTH');
  //     }
  //   }
  // }

  // public setDateFrom(period: string): void {
  //   const today = new Date();
  //   const todayDate = today.getDate();
  //   this.dateToControl.patchValue(today);
  //
  //   switch (period) {
  //     case 'week':
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 7)));
  //       this.groupByControl.patchValue('DAY');
  //       break;
  //     case 'month':
  //       // this.dateFromControl.patchValue(new Date(new Date().setMonth(today.getMonth() - 1)));
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 30)));
  //       this.groupByControl.patchValue('DAY');
  //       break;
  //     case 'quarter':
  //       // this.dateFromControl.patchValue(new Date(new Date().setMonth(today.getMonth() - 4)));
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 90)));
  //       this.groupByControl.patchValue('WEEK');
  //       break;
  //     case 'half-year':
  //       // this.dateFromControl.patchValue(new Date(new Date().setMonth(today.getMonth() - 6)));
  //       this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 180)));
  //       this.groupByControl.patchValue('MONTH');
  //       break;
  //     case 'year':
  //       this.dateFromControl.patchValue(new Date(new Date(new Date().setMonth(today.getMonth() - 12)).setDate(todayDate - 1)));
  //       // this.dateFromControl.patchValue(new Date(new Date().setDate(todayDate - 360)));
  //       this.groupByControl.patchValue('MONTH');
  //       break;
  //     case 'custom-period':
  //       this.dateFromControl.setValue(null);
  //       this.dateToControl.setValue(null);
  //       break;
  //     case 'total':
  //       this.dateFromControl.setValue(new Date(this.companyProfile.tracking.createdAt));
  //       this.updateGroupBy(this.dateFromControl.value, this.dateToControl.value);
  //       break;
  //   }
  // }

  // public dateToString(date: Date): string {
  //   return date.toISOString().substring(0,10);
  // }

  get selectedRangeControl(): FormControl { return this.form.get('selectedRange') as FormControl; }
  // get groupByControl(): FormControl { return this.form.get('groupBy') as FormControl; }
  // get dateToControl(): FormControl { return this.form.get('dateTo') as FormControl; }
  // get dateFromControl(): FormControl { return this.form.get('dateFrom') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
