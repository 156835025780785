import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'rnpl-non-auth-footer',
  templateUrl: './non-auth-footer.component.html',
  styleUrls: ['./non-auth-footer.component.scss']
})
export class NonAuthFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
