import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { get } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rnpl-column-with-enable-disable-row',
  templateUrl: './column-with-enable-disable-row.component.html',
  styleUrls: ['./column-with-enable-disable-row.component.scss']
})
export class ColumnWithEnableDisableRowComponent implements OnInit, OnChanges, OnDestroy {

  @Input() value: boolean = false;

  @Input() row: any;

  @Input() column: any;

  @Output() enableOrDisableClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  public enableRow = new FormControl('');

  public disabled = false;

  ngOnInit(): void {
    this.initForm();
    this.onChangeEnable();
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      this.initForm();
    }
    if (changes.hasOwnProperty('row')) {
      this.disabled = false;
      this.updateDisabledStatus();
    }
  }

  public updateDisabledStatus(): void {
    if (this.row.disabled.fully) {
      this.disabled = true;
      return;
    }

    const isFieldDisabled = this.row.disabled.fields.filter((el) => el === this.column.prop).length;

    if (get(this.row, 'disabled.fields.length') && isFieldDisabled) {
      this.disabled = true;
    }
  }

  public initForm(): void {
    this.enableRow = new FormControl({value: Boolean(this.value) || '', disabled: this.disabled});
  }

  public onChangeEnable(): void {
    this.enableRow.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe((val: boolean) => {
        this.enableOrDisableClick.emit(val);
      });
  }

}
