import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rnpl-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss']
})
export class FormCardComponent implements OnInit {

  @Input()
  cardTitle: string;

  constructor() {
  }

  ngOnInit() {
  }

}
