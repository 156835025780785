import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-info-hints-card',
  templateUrl: './info-hints-card.component.html',
  styleUrls: ['./info-hints-card.component.scss']
})
export class InfoHintsCardComponent {

  @Input() public hintTitle: string = '';
  @Input() public hintDescription: string = '';
  @Input() public hintImg: string = 'email.png';

  public isDisplayHint: boolean = true;


  public closeHint(): void {
    this.isDisplayHint = false;
  }

}
