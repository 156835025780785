import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FormInputChangedModel } from '../../models/form-input-value.model';
import { PayerEnum } from 'common/src/models';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';
import { VAT_LIST } from '../../constants/vat-list';

@Component({
  selector: 'rnpl-insurance-cost-form',
  templateUrl: './insurance-cost-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsuranceCostFormComponent {

  @Input() form: FormGroup;
  @Input() currencyConversion: boolean = false;
  @Input() isInsuranceCostEnabled: boolean = false;
  @Input() showToggle: boolean = true;
  @Input() vatBlocked: boolean = false;
  @Input() localCurrency: string = 'EUR';
  @Input() recipientLabel: string = 'FORM.RECIPIENT';
  @Input() senderLabel: string = 'FORM.SENDER';

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public readonly payer = PayerEnum;
  public readonly vatList = VAT_LIST;

  constructor(private metricPipe: MetricConversionPipe) {

  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  public updateFieldWithConversion(fieldValue: any, fieldName: string): void {
    if ( this.currencyConversion && fieldValue) {
      fieldValue = this.toInteger(fieldValue);
    }

    if (!fieldValue && fieldValue !== 0) {
      fieldValue = null;
    }

    this.updateField(fieldValue, fieldName);
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  get isInsuredDelivery(): FormControl { return this.form.get('isInsuredDelivery') as FormControl; }
  get deliveryInsurancePayer(): FormControl { return this.form.get('deliveryInsurancePayer') as FormControl; }
  get deliveryInsuranceCosts(): FormControl { return this.form.get('deliveryInsuranceCosts') as FormControl; }
  get deliveryInsuranceVat(): FormControl { return this.form.get('deliveryInsuranceVat') as FormControl; }
  get deliveryInsuranceCostsGross(): FormControl { return this.form.get('deliveryInsuranceCostsGross') as FormControl; }

}
