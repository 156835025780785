export enum InitialSetupStepIdEnum {
  HELLO = 'hello',
  LEGAL_INFO = 'legalInfo',
  SALES_CHANNELS = 'salesChannels',
  BUSINESS_SPECIFICS = 'businessSpecifics',
  CORPORATE_LOGO = 'corporateLogo',
  BANK_ACCOUNT = 'bank_account',
  SANDBOX = 'sandbox',
  FINISHING = 'finishing',
  ERROR = 'error',
}

export enum ColorsEnum {
  BLUE = 'blue',
  ORANGE = 'orange',
  RED = 'red',
  GREEN = 'green'
}

export enum ReportingPeriodEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY'
}
