import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'metricConversionPipe' })
export class MetricConversionPipe implements PipeTransform {
  transform(value: number, conversion: string): number {
    if (typeof value === 'number') {
      if (!value) {
        return 0;
      }

      let decimalLength = 0;
      let multiplier = Math.pow(10, decimalLength); // get multiplier to calculate only integer numbers

      if (value) {
        const splittedValue = value.toString().split('.');
        if (splittedValue.length > 1) {
          decimalLength = splittedValue[1].length;
          multiplier = Math.pow(10, decimalLength);
          value = +(value * multiplier).toFixed(0);
        }
      }

      switch (conversion.toUpperCase()) {
        case 'MM-CM': // convert from Millimeters-Сentimeters
          return value / (1e1 * multiplier);
        case 'MM-M': // convert from Millimeters-Meter
          return value / (1e3 * multiplier);

        case 'CM-MM': // convert from Сentimeters-Millimeters
          return (value * 1e1) / multiplier;
        case 'CM-M': // convert from Сentimeters-Meter
          return value / (1e2 * multiplier);

        case 'M-MM': // convert from Meters-Millimeter
          return (value * 1e3) / multiplier;
        case 'M-CM': // convert from Meters-Сentimeters
          return (value * 1e2) / multiplier;
        case 'M-KM': // convert from Meters-Kilometers
          return value / (1e3 * multiplier);

        case 'MM2-M2': // Millimeters^2-Meter^2
          return value / (1e6 * multiplier);

        case 'GR-KG': // convert from Gram to Kilogram
          return value / (1e3 * multiplier);
        case 'GR-T': // convert from Gram to Ton
          return value / (1e6 * multiplier);
        case 'KG-GR': // convert from Kilogram to Gram
          return (value * 1e3) / multiplier;
        case 'KG-T': // convert from Kilogram to Ton
          return value / (1e3 * multiplier);
        case 'T-KG': // convert from Ton to Kilogram
          return (value * 1e3) / multiplier;
        case 'T-GR': // convert from Ton to Gram
          return (value * 1e6) / multiplier;
        case 'TO-INTEGER': // convert from fractional to integer
          return (value * 1e2) / multiplier;
        case 'TO-FRACTIONAL': // convert from integer to fractional
          return value / (1e2 * multiplier);
        default:
          return value / multiplier;
      }
    }
  }
}

// 'kg', 'gr', 't'
