import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { IncomingDeliveryListTabsEnum } from '../enums';
import { LoadIncomingDeliveriesList } from '../store/actions/incoming-delivery.actions';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import {
  IncomingBufferProductModel,
  IncomingDeliveryModel,
  IncomingDeliveryTabsCountersModel,
  ResponseIncomingDeliveryTabsCountersModel
} from '../models';
import { AppState } from '../../../../store/state/app.state';
import { ResponseList, ResponseModel } from '../../../../shared/models/response';
import { FilterModel } from '../../../models/filter.model';
import { StockAreaEnum } from '../../transactions/enums';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DisplayToaster } from '../../../../shared/decorators/toaster';
import { DEFAULT_SORT_DIRECTION } from '../../../../shared/constants';


@Injectable({
  providedIn: 'root'
})
export class IncomingDeliveryApiService {
  private readonly apiEndpoint: string = '/incoming-delivery';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {}

  getIncomingDeliveryList(
    status: IncomingDeliveryListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = { nameColumn: 'createdAt', value: DEFAULT_SORT_DIRECTION },
    filters: any = {}
  ): Observable<ResponseList<IncomingDeliveryModel>> {
    const params = {
      page,
      per_page,
      [`sort[${sort.nameColumn}]`]: sort.value,
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<IncomingDeliveryModel>>(this.apiUrl(`/${status}`), { params })
      .pipe(tap((data: ResponseList<IncomingDeliveryModel>) => {
        this.store.dispatch(LoadIncomingDeliveriesList({
          incomingDeliveryListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status,
          page: data.pagination.page
        }));
      }));
  }

  getIncomingDeliveriesDocumentsRunpleIds(status: IncomingDeliveryListTabsEnum): Observable<any> {
    // const params = { status };
    return this.http.get<any>(this.apiUrl(`/${status}/runpleIds`))
      .pipe(map((data: any) => data.data));
  }

  public getListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
    };
    return of(fileParams);
  }

  getIncomingDeliveriesCounters(): Observable<IncomingDeliveryTabsCountersModel> {
    return this.http
      .get<ResponseModel<ResponseIncomingDeliveryTabsCountersModel>>(
        this.apiUrl('/counters')
      )
      .pipe(
        map(
          (data: ResponseModel<ResponseIncomingDeliveryTabsCountersModel>) =>
            data.data['incoming-delivery']
        )
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getIncomingBufferProducts(location: StockAreaEnum, documentId: number): Observable<IncomingBufferProductModel[]> {
    return this.http.get<ResponseModel<IncomingBufferProductModel[]>>(`/warehouse/incoming-buffer/${location}/${documentId}/products`)
      .pipe(
        map((response: ResponseModel<IncomingBufferProductModel[]>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getWarehousedProducts(location: StockAreaEnum, documentId: number): Observable<IncomingBufferProductModel[]> {
    return this.http.get<ResponseModel<IncomingBufferProductModel[]>>(`/warehouse/${location}/${documentId}/warehoused-products`)
      .pipe(
        map((response: ResponseModel<IncomingBufferProductModel[]>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  getAvailableStorageObjects(location: StockAreaEnum, productId: number, batch: string): Observable<{ id: number, binLocation: string }[]> {
    return this.http.get<ResponseModel<{ id: number, binLocation: string }[]>>(
      `/warehouse/${location}/storage-objects/batch-rid/${batch}/product/${productId}`
    )
      .pipe(
        map((response: ResponseModel<{ id: number, binLocation: string }[]>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  cancelWarehousing(
    location: StockAreaEnum,
    documentId: number,
    body: {
      batchId: number,
      binLocationId: number,
      employeeId: number,
      warehousedAt: Date,
    }
      ): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/warehouse/${location}/${documentId}/warehousing/cancel`, { body })
      .pipe(
        map((response: ResponseModel<any>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  cancelWarehousingMultiple(
    location: StockAreaEnum,
    documentId: number,
    body
  ): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/warehouse/${location}/${documentId}/cancel-warehousing`, { body })
      .pipe(
        map((response: ResponseModel<any>) => response.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  transferIncomingBufferProducts(location: StockAreaEnum, documentId: number, body): Observable<any> {
    return this.http.request('post', `/warehouse/incoming-buffer/${location}/${documentId}/products`, { body });
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
