import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-column-with-avatar',
  templateUrl: './column-with-avata.component.html',
  styleUrls: ['./column-with-avata.component.scss']
})
export class ColumnWithAvataComponent {

  @Input()
  value: {
    first_name?: string,
    last_name?: string,
    avatar?: {},
    image?: {},
    name?: string
  };


  constructor() {
  }
}
