import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DocumentTypesUppercaseEnum } from '../link-document-modal/enums/ducument-types.enum';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { RnplCommonService } from '../../../rnpl-common/services/rnpl-common.service';
import { EMAIL_SENT_STATUS_CLASSES } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-send-email-modal',
  templateUrl: './send-email-modal.component.html'
})
export class SendEmailModalComponent implements OnInit {

  public emails: string = null;
  public setStatusToSent: boolean = false;

  public emailSentStatusClasses: {[key: string]: string } = EMAIL_SENT_STATUS_CLASSES;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public commonService: RnplCommonService,
    public dialogRef: MatDialogRef<SendEmailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentId: number,
      documentType: DocumentTypesUppercaseEnum,
      contactEmail: string,
      emailStatus: string,
    }
  ) {

  }

  ngOnInit(): void {
    if (this.data && this.data.contactEmail) {
      this.emails = this.data.contactEmail;
    }
  }

  public sendEmail(): void {
    const emails = this.emails
      ? this.emails.split(',')
      : null;

    this.commonService.sendEmail(emails, this.data.documentType, this.data.documentId, this.setStatusToSent)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.dialogRef.close(res));
  }

}

