import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rnpl-process-board',
  templateUrl: 'process-board.component.html',
  styleUrls: ['process-board.component.scss']
})
export class ProcessBoardComponent {

  @Input()
  processTitle: string;

  @Input()
  disabled: boolean = false;

  @Input()
  actionTitle: string;

  @Input()
  isAction: boolean = false;

  @Input()
  count: number = 1;

  @Output() action: EventEmitter<string> = new EventEmitter<string>();

  actionClick(action: string) {
    if (this.disabled) {return; }
    this.action.emit();
  }

}
