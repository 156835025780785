import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MultipleControlComponent, OptionModel } from '../multiple-control.component';

@Component({
  selector: 'rnpl-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxGroupComponent extends MultipleControlComponent implements OnInit {

  public optionsGrid: Array<Array<OptionModel>>;

  private checkedValues: Array<any> = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initOptionsGrid();
    this.disabled = this.control.disabled || false;
  }

  public setValue(event, value) {
    if (event.target.checked) {
      this.checkedValues.push(value);
    } else {
      this.checkedValues = this.checkedValues.filter(checkedValue => checkedValue !== value);
    }
    this.control.setValue(this.checkedValues);
    this.control.markAsDirty();

    this.formUpdated.emit(true);
  }

  private initOptionsGrid(): void {
    if (!this.options.length) {
      return;
    }

    this.optionsGrid = [[this.options[0]]];
    for (let index = 1; index < this.options.length; index++) {
      if (index % 2 === 0) {
        this.optionsGrid.push([this.options[index]]);
      } else {
        this.optionsGrid[this.optionsGrid.length - 1].push(this.options[index]);
      }
    }
  }

}
