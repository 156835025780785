import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { UIStatesEnum } from 'common/src/models';
import { HrmService } from '../../hrm.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { selectTeamsUser, selectTeamsUserCurrentState } from '../../store/selectors';
import { AppState } from '../../../store/state/app.state';
import { UserProfileModel } from '../../models';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';
import { ChangePasswordModalComponent } from 'common/src/modules/modals/modals-hrm';

@Component({
  selector: 'rnpl-preferences-security',
  templateUrl: './preferences-security.component.html',
  styleUrls: ['./preferences-security.component.scss']
})
export class PreferencesSecurityComponent implements OnInit {

  public userData: UserProfileModel;
  public editingMode: boolean = false;
  public isMyProfile: boolean = false;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private hrmService: HrmService,
    private toasterService: ToasterService,
    private store:  Store<AppState>,
  ) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isMyProfile = this.route.parent.snapshot.data.myProfile;
        }
      });
  }

  ngOnInit() {
    this.store.select(selectTeamsUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.userData = user;
      });

    this.store.select(selectTeamsUserCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
      });
  }

  public changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordModalComponent);
  }

  public updateUser(field: FormInputChangedModel): void {
    this.hrmService.updateUserProfile(this.userData.profile.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

}
