import { Directive, Injectable, Input, EventEmitter, Output, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[scrollSpy]'
})
export class ScrollSpyDirective implements OnInit{
  @Input() public spiedTags = [];
  @Output() public sectionChange = new EventEmitter<string>();
  @Output() public isScrolled = new EventEmitter<boolean>();
  @Output() public isScrolledToBottom = new EventEmitter<boolean>();

  private currentSection: string;
  private scrollIsBottom: boolean;

  constructor(private _el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    this.scrollIsBottom = (event.target.scrollHeight - event.target.clientHeight) === event.target.scrollTop;

    this.isScrolledToBottom.emit(this.scrollIsBottom);

    this.isScrolled.emit(event.target.scrollTop > 4);

    if (!!document.getElementById('parentDiv')) {

      let currentSection: string;
      const containerHeight = document.getElementById('parentDiv').clientHeight;
      const visibleHeight = containerHeight * 0.4;
      const children = this._el.nativeElement.children[0].children[0].children;
      const scrollTop = event.target.scrollTop;
      const parentOffset = event.target.offsetTop + visibleHeight;

      for (let i = 0; i < children.length; i++) {
        const element = children[i];
        if (this.spiedTags.some(spiedTag => spiedTag === element.tagName)) {
          if ((element.offsetTop - parentOffset) <= scrollTop) {
            currentSection = element.id;
          }
        }

        if(!scrollTop) {
          currentSection = children[0].id;
        }

        if ((event.target.scrollHeight - scrollTop) <= containerHeight) {
          currentSection = children[children.length - 1].id;
        }
      }

      if (currentSection !== this.currentSection) {
        this.currentSection = currentSection;
        this.sectionChange.emit(this.currentSection);
      }
    }
  }

  ngOnInit() {
    this.isScrolled.emit(this._el.nativeElement.scrollTop > 4);
  }
}
