import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { BatchTabs } from '../../../../enums/batch-tabs';

export const BatchTabDefinition: TabDefinitionModel[] = [
  {
    label: BatchTabs.POSITIONS,
    link: './positions',
    icon: 'bulb',
    count: 0,
    hideCount: true
  },
  {
    label: BatchTabs.LOGISTICS,
    link: './logistics',
    icon: 'bulb-off',
    count: 0,
    hideCount: true
  },
  {
    label: BatchTabs.COSTS,
    link: './costs',
    icon: 'blocked',
    count: 0,
    hideCount: true
  }
];
