export enum PartnersMenuTabsEnum {
  LEGAL_INFORMATION = 'legal-information',
  BILLING_INFORMATION = 'billing-information',
  DELIVERY_INFORMATION = 'delivery-information',
  CONTACTS = 'contacts',
  ECO_CONTACTS = 'eco-contacts',
  PREFERENCES = 'preferences',
  ECOMMERCE = 'e-commerce'
}

export enum PartnersLegalInfoTabsEnum {
  COMPANY = 'company',
  CEO = 'ceo',
}

export enum PartnersBillingInfoTabsEnum {
  BILLING_ADDRESS = 'billing-address',
  PAYMENT_REFERENCES = 'payment-references',
}

export enum PartnersDeliveryInfoTabsEnum {
  DELIVERY_ADDRESS = 'delivery-address',
  // DELIVERY_PREFERENCES = 'delivery-preferences',
}

