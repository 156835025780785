import { Observable } from 'rxjs';

import { PositionsActionsModel } from '../table/custom-table.interface';
import { NavBarBtnModel, SendEmailStatusEnum, UserModel, VatStatusEnum } from '../../../models';
import { DeliveryServiceDetails } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';

export interface DocumentStepModel {
  stepContentClass?: string;
  actionList?: ActionModel[];
  hideDocuments?: boolean;
  hideStep?: boolean;
  hintId?: string;
  documentState?: boolean;
  icon?: string;
  title: TitleModel;
  descr?: string;
  descrClass?: string;
  descrInfo?: string;
  success?: boolean;
  action?: string | boolean;
  documents?: Array<DocumentsModule>;
  currentDocument?: boolean;
  buttonIcon?: string;
  buttonTitle?: string;
  disabled?: boolean;
  hideAction?: boolean;
  showCondition?: boolean;
  status?: StepStatusEnum;
  class?: string;
  endStep?: boolean;
  actionsMultiple?: NavBarBtnModel;
  stepHelperBlock?: {
    icon?: string;
    title: string;
  };
}

export interface ActionModel {
  buttonClass?: string;
  action?: any;
  hideAction?: boolean;
  disabled?: boolean;
  buttonTitle?: string;
  buttonIcon?: string;
}


export interface TitleModel {
  type: TitleTypeEnum;
  label: any;
}

export interface DocumentsModule {
  user?: UserModel;
  disabled?: boolean;
  statusLineWithLabel?: boolean;
  statusLineInverse?: boolean;
  reverseDirection?: boolean;
  hideProgress?: boolean;
  onEditing?: boolean;
  dateFormat?: string;
  runpleId?: string;
  rightLabel?: string;
  dueWithinDays?: any;
  postfix?: any;
  isEndless?: any;
  maxValue?: any;
  value?: any;
  label?: string;
  status?: string;
  documentRunpleId?: string;
  icon?: string;
  iconColor?: string;
  routerLink?: string;
  title?: string;
  type?: DocumentTypeEnum;
  vatStatus?: VatStatusEnum;
  emailSentStatus?: SendEmailStatusEnum;
  statusLineType?: string;
  statusLineOverdueHighlight?: boolean;
  hintId?: string;
  expectedDate?: any;
  colorState?: string;
  currency?: string;
  action?: string;
  actionIcon?: string;
  actionClass?: string;
  printableRunleId?: string;
  actions?: PositionsActionsModel[];
  notices?: NoticeModel[];
  deliveryServiceDetails?: DeliveryServiceDetails;
  deliveryServiceLoading$?: Observable<boolean>;
  helperAction?: HelperActionModel;
  valueOnly?: boolean
  steps?: any[];
  hide?: boolean;
  loading$?: Observable<any>;
  id?: number;
}

export interface NoticeModel {
  icon: string;
  iconColor?: string;
  title?: string;
  date: Date;
}

export enum TitleTypeEnum {
  primary,
  date,
  datePrimary
}

export enum DocumentTypeEnum {
  linkDocument,
  VatNumberCheck,
  EmailSendCheck,
  Notice,
  StatusLine,
  Primary,
  InfoItem,
  InfoItemIconActions,
  User,
  DeliveryService,
  Steps
}

export enum StepStatusEnum {
  Pending = 'pending',
  In_progress = 'in-progress',
  Success = 'success',
  Canceled = 'canceled',
  Deleted = 'deleted',
}

export interface HelperActionModel {
  icon?: string;
  actionIcon?: string;
  title: string;
  action: string;
  disabled?: boolean;
  hide?: boolean;
  showCondition?: boolean;
}


