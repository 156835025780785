import { Component } from '@angular/core';

// import { MENU_ITEMS_TEAM } from '../../module-menu-items';
// import { NavItem } from 'common/src/modules/ui-components/head-navigation/nav-items.config';


@Component({
  selector: 'rnpl-hrm-main',
  templateUrl: './hrm-main.component.html',
  styleUrls: ['./hrm-main.component.scss']
})
export class HrmMainComponent {

  // navItems: NavItem;

  constructor() {
    // this.navItems = MENU_ITEMS_TEAM;
  }
}
