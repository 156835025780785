import { createAction, props } from '@ngrx/store';

import { TeamsListState } from '../reducers';
import { UserListTabs } from '../../pages/user-list/enums/user-list-tabs.enum';
import { UIStatesEnum } from 'common/src/models';
import { UserProfileModel } from '../../models';

export const LoadTeamsList = createAction(
  '[Teams] Load Teams List',
  props<{ teamsListData: TeamsListState, status: UserListTabs, page: number }>()
);

export const LoadUser = createAction(
  '[Teams] Load User',
  props<{ user: UserProfileModel }>()
);

export const LoadLoggedUser = createAction(
  '[Teams] Load Logged User',
  props<{ loggedUser: UserProfileModel }>()
);

export const UpdateUserCurrentState = createAction(
  '[Teams] Update User Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateTeamsUserUpdatedAt = createAction(
  '[Teams] Update Teams User updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Teams] Update Teams should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);


export const IncrementLoadingRequestsCount = createAction(
  '[Teams] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Teams] Decrement Loading Requests Count'
);
