import { Component, OnInit } from '@angular/core';
import { isEqual } from 'lodash';

import { ToasterService } from './toaster.service';
import { ToasterDefinitionModel, ToasterRequestModel } from './toaster-definition.model';
import { SoundService } from '../../../services/sound.service';


@Component({
  selector: 'rnpl-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  public messages: ToasterDefinitionModel[] = [];
  public requestMessages: ToasterRequestModel[] = [];

  constructor(private toasterService: ToasterService, public soundService: SoundService) { }

  ngOnInit() {
    this.toasterService.getMessages()
      .subscribe((message: ToasterDefinitionModel) => {
        const newMessage = {...message, isClose: false};

        if (this.hasMessage(newMessage)) { return; } // skip message If it is already in array

        this.messages.push(newMessage);
        this.soundService.playSound(this.getSoundNameByType(message.type, message.soundOff));
        setTimeout(() => {
          this.messages.pop();
        }, 4900);
      });

    this.toasterService.getRequestMessages()
      .subscribe((messages: ToasterRequestModel[]) => this.requestMessages = messages);
  }

  public getIcon(msgType: string) {
    let iconName = '';

    switch (msgType) {
      case 'success':
        iconName = 'checkmark-circle';
        break;
      case 'warning':
        iconName = 'alert-triangle';
        break;
      case 'info':
        iconName = 'info';
        break;
      default:
        iconName = 'alert-circle';
        break;
    }

    return iconName;
  }

  /**
   * audio to action method
   */
  public getSoundNameByType(msgType: string, soundOff?) {
    let audioName = '';

    if (soundOff) { return  null; }

    switch (msgType) {
      case 'success':
        // audioName = 'toast_success';
        audioName = null;
        break;
      case 'warning':
        audioName = 'toast_warning';
        break;
      default:
        audioName = 'toast_error';
        break;
    }

    return audioName;
  }

  public getTranslatedType(msgType: string) {
    let translatedType = '';

    switch (msgType) {
      case 'success':
        translatedType = 'COMMON.SUCCESS';
        break;
      case 'warning':
        translatedType = 'COMMON.WARNING';
        break;
      case 'info':
        translatedType = 'Info';
        break;
      case 'activate-subscription':
        translatedType = 'SUBSCRIPTION_PLAN.YOUR_SUBSCRIPTION_IS_NOW_ACTIVE';
        break;
      default:
        translatedType = 'COMMON.ERROR';
        break;
    }

    return translatedType;
  }

  public hasMessage(message): boolean {
    return this.messages.some(m => isEqual(m, message))
  }

  public onClose(index): void {
   this.messages[index].isClose = true;
  }
}
