import { get } from 'lodash';

import { InfoItemModel, InfoItemType } from 'common/src/modules/ui-components/info-components/models/info-item.model';
import { PricesInfoModel } from '../../models/prices-info.model';

export function getPurchaseDataInfoHelper(productInfo: PricesInfoModel): InfoItemModel[] {
  return [
    {
      label: 'COLUMN.PURCHASE_PRICE_NET',
      value: get(productInfo, 'purchase_data.purchase_price', null),
      type: InfoItemType.WITH_CURRENCY,
      tableItem: true,
      currencyConversion: true
    },
    {
      label: 'TABLE_SIDEBAR.LAST_PURCHASE_PRICE_NET',
      value: get(productInfo, 'purchase_data.last_purchase_price', null),
      type: InfoItemType.WITH_CURRENCY,
      tableItem: true,
      icon: getLastPurchasePriceIcon(get(productInfo, 'purchase_data.price_increase', null)),
      iconColor: getLastPurchasePriceIconColor(get(productInfo, 'purchase_data.price_increase', null)),
      currencyConversion: true
    },
  ];
}

export function getSalesDataInfoHelper(productInfo: PricesInfoModel): InfoItemModel[] {
  return [
    {
      label: 'SO_PO.SALES_PRICE_NET',
      value: get(productInfo, 'sales_price.net', null),
      type: InfoItemType.WITH_CURRENCY,
      currencyConversion: true,
      tableItem: true,
    },
    {
      label: 'FORM.DISCOUNT_NET',
      value: get(productInfo, 'sales_price.discount_net', null),
      percents: get(productInfo, 'sales_price.discount', null),
      type: InfoItemType.WITH_CURRENCY_AND_PERCENTS,
      currencyConversion: true,
      tableItem: true,
      valueClasses: 'color-red-300'
    },
    {
      label: 'COLUMN.AVG_MARGIN',
      value: get(productInfo, 'sales_data.wa_margin_rate', null),
      type: InfoItemType.PRIMARY,
      postfix: ' %',
      tableItem: true,
      valueClasses: getValueColor(get(productInfo, 'sales_data.wa_margin_rate', null))
    },
    {
      label: 'TABLE_SIDEBAR.LAST_SALES_PRICE_NET',
      value: get(productInfo, 'sales_data.last_sales_price', null),
      type: InfoItemType.WITH_CURRENCY,
      currencyConversion: true,
      tableItem: true,
      icon: getLastPurchasePriceIcon(get(productInfo, 'sales_data.price_increase', null)),
      iconColor: getLastSalesPriceIconColor(get(productInfo, 'sales_data.price_increase', null)),
    },
    {
      label: 'TABLE_SIDEBAR.LAST_SALES_DATE',
      value: get(productInfo, 'sales_data.last_sales_date', null),
      type: InfoItemType.WITH_DATE_PRIMARY,
      dateFormat: 'dd.MM.yyyy, HH:mm',
      tableItem: true,
    },
  ];
}

export function getStockStatusDataInfoHelper(productInfo: PricesInfoModel): InfoItemModel[] {
  return [
    {
      label: 'TABLE_SIDEBAR.AVAILABLE',
      value: get(productInfo, 'stock_status.available', null),
      type: InfoItemType.WITH_NUMBER,
      tableItem: true,
      unitType: get(productInfo, 'unit_type', 'pcs'),
      valueClasses: getValueColor(get(productInfo, 'stock_status.available', null))
    },
    {
      label: 'COLUMN.ON_STOCK',
      value: get(productInfo, 'stock_status.on_stock', null),
      type: InfoItemType.WITH_NUMBER,
      tableItem: true,
      unitType: get(productInfo, 'unit_type', 'pcs'),
    },
    {
      label: 'TABLE_SIDEBAR.SOLD',
      value: get(productInfo, 'stock_status.sold', null),
      type: InfoItemType.WITH_NUMBER,
      tableItem: true,
      unitType: get(productInfo, 'unit_type', 'pcs'),
    },
    {
      label: 'TABLE_SIDEBAR.ORDERED',
      value: get(productInfo, 'stock_status.ordered', null),
      type: InfoItemType.WITH_NUMBER,
      tableItem: true,
      unitType: get(productInfo, 'unit_type', 'pcs'),
    },
  ];
}

export function getPrimeCostDataInfoHelper(productInfo: PricesInfoModel): InfoItemModel[] {
  return [
    {
      label: 'COLUMN.PRIME_COST_NET',
      value: get(productInfo, 'purchase_data.prime_costs', null),
      type: InfoItemType.WITH_CURRENCY,
      tableItem: true,
      currencyConversion: true
    }
  ];
}


export function getLastPurchasePriceIcon(direction: 'down'|'up'): string {
  if (!direction) { return null; }
  if (direction === 'down') {
    return 'arrow-right-small rotate-90'
  }
  return `arrow-right-small rotate--90`;
}

export function getLastPurchasePriceIconColor(direction: 'down'|'up'): string {
  const colors = {
    ['up']: 'color-red-300',
    ['down']: 'color-green-600',
  };
  if (!direction) { return 'color-main-100'; }
  return colors[direction];
}

export function getLastSalesPriceIconColor(direction: 'down'|'up'): string {
  const colors = {
    ['up']: 'color-green-600',
    ['down']: 'color-red-300',
  };
  if (!direction) { return 'color-main-100'; }
  return colors[direction];
}

export function getValueColor(val: number): string {
  if (!val) {return;}
  if (val === 0) {
    return 'color-yellow-600';
  }
  if (val > 0) {
    return 'color-green-600';
  }
  if (val < 0) {
    return 'color-red-300';
  }
}
