import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { HintTypeEnum } from 'projects/workspace/src/app/hrm/models/hints-list.model';
import { receiptProductsHints } from 'projects/workspace/src/app/warehouse/configs/hints.config';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from '../../../rnpl-common/components/base-mega-hints/base-mega-hints.component';

@Component({
  selector: 'rnpl-product-receipt-hints',
  templateUrl: './product-receipt-hints.component.html',
  styleUrls: ['./product-receipt-hints.component.scss']
})
export class ProductReceiptHintsComponent extends BaseMegaHintsComponent implements OnInit, OnChanges {
  @Input() public receiptAndWarehouse: boolean;
  @Input() public fromPo: boolean;
  @Input() public hasColumns: boolean;
  @Input() public displayBinLocationColumnHint: boolean;

  constructor(
    public deps: BaseMegaHintComponentDependences
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'BUTTON.PRODUCT_RECEIPT';
    this.megaHintTimer = 1200;
    if (this.shepherdService.isActive) {
      this.shepherdService.cancel();
      this.shepherdService.complete();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('receiptAndWarehouse') || changes.hasOwnProperty('displayBinLocationColumnHint')) {
      clearInterval(this.countdownTimer);
      if (this.shepherdService.isActive) {
        this.shepherdService.cancel();
        this.animationStateIsPaused$.next(false);
      }

      this.initHints();
    }
  }

  private initHints(): void {
    this.screenName = this.fromPo ? 'purchase_orders_warehousing' : 'incoming_deliveries_product_receipt';

    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = receiptProductsHints(
            this.receiptAndWarehouse,
            this.fromPo,
            this.hasColumns,
            this.displayBinLocationColumnHint
          );

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.shepherdService.start();
          }
        }
      });
  }
}
