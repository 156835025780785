import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { DashboardPeriodFormComponent } from './dashboard-period-form.component';
import { RnplCommonModule, UIComponentsModule } from 'common/src/modules';

@NgModule({
  declarations: [
    DashboardPeriodFormComponent
  ],
  imports: [
    CommonModule,
    UIComponentsModule,
    RnplCommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
  ],
  exports: [DashboardPeriodFormComponent],
  entryComponents: [DashboardPeriodFormComponent],
  providers: []
})
export class DashboardPeriodFormModule { }
