import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

import { ActionButtonsModel } from '../../../models/action-buttons.model';
import { TrackInputChanges } from '../../../../../projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from '../../../../../projects/workspace/src/app/shared/enums/change-strategy.enum';

@Component({
  selector: 'rnpl-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionButtonsComponent implements AfterViewInit, OnChanges {

  public initReady = false;

  @Input() buttons: ActionButtonsModel[] = [];

  @Output() action = new EventEmitter<string>();

  @Input() btnClearLoadingStatus: string;
  @Output() loadingStatusCleared = new EventEmitter<null>();

  constructor(private readonly cdr: ChangeDetectorRef) { }

  @TrackInputChanges<string>('btnClearLoadingStatus', 'handleBtnClearLoadingStatus', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit(): void {
    this.initReady = true;
    this.cdr.detectChanges();
  }

  public handleBtnClearLoadingStatus(): void {
    if (!this.buttons || !this.btnClearLoadingStatus) { return; }
    const selectedButtons = this.buttons.filter((btn) => btn.action === this.btnClearLoadingStatus);
    if (selectedButtons.length) {
      selectedButtons.forEach(btn => {
        this.changeBtnLoadingStatus(btn, false);
      });
      this.loadingStatusCleared.emit();
    }
  }

  public clickAction(btn: ActionButtonsModel): void {
    if (!btn.disabled && !btn.isLoading) {
      this.action.emit(btn.action);
      if (btn.allowLoadingSpinner) {
        this.changeBtnLoadingStatus(btn, true);
      }
    }
  }

  public changeBtnLoadingStatus(btn: ActionButtonsModel, status: boolean): void {
    btn.isLoading = status;
  }
}
