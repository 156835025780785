import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { get, isEqual } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { PaymentApiService } from 'projects/workspace/src/app/payment/services/payment-api.service';
import { generateCardsInfo } from './link-nultiple-payments-modal.helper';
import { getDocumentUrl } from 'projects/workspace/src/app/shared/helpers';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';
import { AvailableAssignsModel } from '../link-document-modal/model/available-assigns.model';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { AddDocumentModalComponent } from '../add-document-modal/add-document-modal.component';
import { PositionInfoListModel } from '../../../position-card/position-card-models.model';
import { PaymentDirectionEnum, PaymentModel } from 'projects/workspace/src/app/payment/models/payment.model';
import { TableActivateTypes } from '../../../ui-components/table/custom-table.enums';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CustomerTypeEnum } from '../../../../models';
import { ScheduledPaymentBillingPeriodModel } from 'projects/workspace/src/app/scheduled-payment/models';
import { ScheduledPaymentApiService } from 'projects/workspace/src/app/scheduled-payment/services/scheduled-payment-api.service';

@Component({
  selector: 'rnpl-link-multiple-payments-modal',
  templateUrl: './link-multiple-payments-modal.component.html',
  styleUrls: ['./link-multiple-payments-modal.component.scss'],
})
export class LinkMultiplePaymentsModalComponent extends BaseModalComponent implements OnInit {
  public companyProfile: CompanyProfile;
  public multipleDocumentsLinking = false;
  public bulkPartnersLinking = false;
  readonly currenciesSymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;

  public selectedSingleDocument: AvailableAssignsModel = null;
  public selectedMultipleDocuments: AvailableAssignsModel[] = [];
  public selectedBillingPeriod:  ScheduledPaymentBillingPeriodModel = null;
  public availableDocumentsList: AvailableAssignsModel[] = [];
  public scheduledPaymentBillingPeriodsList:  ScheduledPaymentBillingPeriodModel[] = [];
  public cards: PositionInfoListModel[] = generateCardsInfo(this.data.currency);

  public customSearchFn = CustomSearchFn;
  public getDocumentUrl = getDocumentUrl;

  @ViewChild('positionCardComponent', {static: false}) positionCardComponent;

  readonly linkRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly store: Store<AppState>,
    public toasterService: ToasterService,
    public router: Router,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LinkMultiplePaymentsModalComponent>,
    private readonly paymentApiService: PaymentApiService,
    private readonly scheduledPaymentApiService: ScheduledPaymentApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      paymentDirection: PaymentDirectionEnum;
      paymentId: number;
      openAmount: string;
      totalAmount: string;
      hasLinkedPayments: boolean; // todo: remove (duplicates 'isAlreadyLinked')
      isAlreadyLinked: boolean;
      hasUserDefinedPartner: boolean;
      isForeignCurrency: boolean;
      currency: string;
    }
  ) {
    super(toasterService);

    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this._destroy)
      ).subscribe((profile: CompanyProfile) => {
        this.companyProfile = profile;
      });
  }

  ngOnInit() {
    if (this.data.isAlreadyLinked) {
      this.multipleDocumentsLinking = true;
    }
    this.getAvailableDocuments();
  }

  private getAvailableDocuments(): void {
    this.showDropdownSpin$.next(true);

    this.paymentApiService.getAvailableAssigns(this.data.paymentId)
      .pipe(takeUntil(this._destroy))
      .subscribe((documents: AvailableAssignsModel[]) => {
        this.showDropdownSpin$.next(false);
        this.availableDocumentsList = this.prepareDocumentsList(documents);
      }, error => {
        this.showDropdownSpin$.next(false);
        this.displayMessage('error', error.error.message);
      });
  }

  public prepareDocumentsList(documents): AvailableAssignsModel[] {
    return documents
      .map((document: AvailableAssignsModel) => ({
        ...document,
        searchLabel: `${document.runpleId} ${get(document, 'partner.name')} ${document.amount} ${document.externalNumber || ''}`,
        rowActions: {
          [TableActivateTypes.DeleteRow]: true
        },
        documentLink: {
          label: document.runpleId,
          routerLink: getDocumentUrl(document.type, document.id),
        }
      }))
      .filter((document: AvailableAssignsModel) => {
        // remove scheduled payments from available if payment already has linked document
        return !this.data.isAlreadyLinked || document.type !== DocumentTypesUppercaseEnum.SP;
      });
  }

  public getBillingPeriods(): void {
    if (!this.isSelectedSP) { return; }

    this.selectedBillingPeriod = null;
    this.scheduledPaymentApiService.getScheduledPaymentNotBilledPeriods(this.selectedSingleDocument.id)
      .pipe(takeUntil(this._destroy))
      .subscribe((periods: ScheduledPaymentBillingPeriodModel[]) => {
        this.scheduledPaymentBillingPeriodsList = this.prepareBillingPeriodsList(periods);
        if (this.scheduledPaymentBillingPeriodsList.length === 1) {
          this.selectedBillingPeriod = this.scheduledPaymentBillingPeriodsList[0];
        }
      });
  }

  public prepareBillingPeriodsList(billingPeriods): ScheduledPaymentBillingPeriodModel[] {
    return billingPeriods.map((item: ScheduledPaymentBillingPeriodModel) => {
      return {
        ...item,
        label: `${item.periodFrom} - ${item.periodTo}`
      };
    });
  }

  public addDocument(): void {
    if (this.showDropdownSpin$.getValue()) { return; }

    const addedDocumentsIds: number[] = this.selectedMultipleDocuments.map(doc => doc.id);
    // filter already added documents from available list
    const availableDocumentsList: AvailableAssignsModel[] = this.availableDocumentsList
      .filter(doc => !addedDocumentsIds.includes(doc.id))
      .filter(doc => doc.type !== DocumentTypesUppercaseEnum.SP);

    this.dialog.open(AddDocumentModalComponent, {
      data: {
        availableDocumentsList,
        bulkPartnersLinking: this.bulkPartnersLinking,
        alreadySelectedPartner: !!this.selectedMultipleDocuments.length && this.selectedMultipleDocuments[0].partner,
        paymentCurrency: this.data.currency,
        // currencySymbol: this.currenciesSymbols[this.data.currency],
      },
      disableClose: true
    })
      .afterClosed()
      .subscribe(document => {
        if (document) {
          this.selectedMultipleDocuments.unshift(document);
        }
      });
  }

  public rowAction(event: {position: any, actionType: TableActivateTypes}): void {
    if (event.actionType === TableActivateTypes.DeleteRow) {
      this.selectedMultipleDocuments = this.selectedMultipleDocuments
        .filter(doc => !(doc.id === event.position.id && doc.type === event.position.type));
    }
  }

  public onEditPositionTableCell(val: { value: number; row: AvailableAssignsModel }): void {
    val.row.paidAmount = val.value;
  }

  public submitLinking(): void {

    if (this.positionCardComponent) {
      const isControlsValid = this.positionCardComponent.isControlsValid();
      if (!isControlsValid) {
        return;
      }
    }

    if (this.linkRequest$.getValue()) { return; }
    if (this.isSelectedSP && !this.selectedBillingPeriod) { return; }
    this.linkRequest$.next(true);

    if (this.isSelectedSP) {
      this.scheduledPaymentApiService
        .scheduledPaymentLinkPeriod(
          this.selectedSingleDocument.id,
          this.selectedBillingPeriod.periodId,
          this.data.paymentId,
          DocumentTypesUppercaseEnum.OPB
        )
        .pipe(
          finalize(() => this.linkRequest$.next(false)),
          takeUntil(this._destroy))
        .subscribe(this.subscriberHandler, this.handleError);

      return;
    }

    let documentsToAssign;

    if (this.multipleDocumentsLinking) {
      documentsToAssign = this.selectedMultipleDocuments.map((document: AvailableAssignsModel) => ({
        documentType: document.type,
        documentId: document.id,
        amount: document.paidAmount || null,
      }));
    } else {
      documentsToAssign = [
        {
          documentId: this.selectedSingleDocument.id,
          documentType: this.selectedSingleDocument.type,
        }
      ];
    }

    this.paymentApiService.assignMultipleDocumentsToPayment(
      this.data.paymentId,
      documentsToAssign,
      this.multipleDocumentsLinking,
      this.bulkPartnersLinking,
    )
      .pipe(
        finalize(() => this.linkRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(this.subscriberHandler, this.handleError);
  }

  public subscriberHandler = (response: PaymentModel) => {
    this.dialogRef.close(response);
  }

  public handleError = error => {
    this.displayMessage('error', error.error.message || error.error.errors);
  }

  public get selectedDocumentsPaidAmount(): number {
    return this.selectedMultipleDocuments
      .reduce((accumulator: number, document: AvailableAssignsModel) => accumulator + (document.paidAmount || 0), 0);
  }

  public get isOutgoingPaymentDirection(): boolean {
    return this.data.paymentDirection === PaymentDirectionEnum.OUTGOING;
  }

  public get isSelectedSP(): boolean {
    return this.selectedSingleDocument && this.selectedSingleDocument.type === DocumentTypesUppercaseEnum.SP;
  }

  public get partnerUrl(): string {
    if (!this.selectedSingleDocument || !this.selectedSingleDocument.partner) { return null; }
    if (
      this.selectedSingleDocument.partner.type !== CustomerTypeEnum.CORPORATE_PARTNER &&
      this.selectedSingleDocument.partner.type !== CustomerTypeEnum.PRIVATE_PARTNER
    ) {
      return null;
    }

    return `/partners-new/${this.selectedSingleDocument.partner.type}/${this.selectedSingleDocument.partner.id}`;
  }

}
