import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { BaseModalComponent } from '../../../rnpl-common/components';
import { ToasterService } from '../../../ui-components/toaster';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { TimeTrackingRecordModel } from 'projects/workspace/src/app/time-tracking/models';

@Component({
  selector: 'rnpl-resolve-conflict-modal',
  templateUrl: './time-tracking-resolve-issue-modal.component.html'
})
export class TimeTrackingResolveIssueModalComponent extends BaseModalComponent implements OnInit {

  public form: FormGroup;

  constructor(
    public readonly toasterService: ToasterService,
    public readonly timeTrackingApiService: TimeTrackingApiService,
    private readonly fb: FormBuilder,
    public readonly dialogRef: MatDialogRef<TimeTrackingResolveIssueModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      recordIds: number[],
      userDefinedCustomerName: string
    }
  ) {
    super(toasterService);
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      partnerType: [PartnersTypeEnum.CORPORATE],
      partner: [],
    });
  }

  public submitResolve(): void {
    this.timeTrackingApiService.resolveRecordsIssues(this.data.recordIds, this.partnerTypeControl.value, this.partnerControl.value.id)
      .pipe(takeUntil(this._destroy))
      .subscribe( (records: TimeTrackingRecordModel[]) => {
        this.dialogRef.close(records);
      });
  }

  get partnerTypeControl(): FormControl { return this.form.get('partnerType') as FormControl; }
  get partnerControl(): FormControl { return this.form.get('partner') as FormControl; }

}
