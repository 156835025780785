import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { EcoSettingsApiService } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import {
  EcoSettingsDeliveryServiceModel,
  EcoSettingsReturnCountryModel
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryModel } from '../../../rnpl-common';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';

@Component({
  selector: 'rnpl-update-returns-term-modal',
  templateUrl: './update-returns-term-modal.component.html',
  styleUrls: ['./update-returns-term-modal.component.scss'],
})
export class UpdateReturnsTermModalComponent extends BaseModalComponent {

  public form: FormGroup;
  public deliveryServices: EcoSettingsDeliveryServiceModel[];

  public countries$: Observable<CountryModel[]> = this.store.select(selectCountries)
    .pipe(
        map(countries => {
        return countries.filter(c => !this.data.alreadyAddedCountriesISO3.includes(c.code_iso3.toUpperCase()));
      })
    );
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public fb: FormBuilder,
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<UpdateReturnsTermModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      returnCountry: EcoSettingsReturnCountryModel,
      canBeDeleted: boolean,
      alreadyAddedCountriesISO3: string[]
    }
  ) {
    super(toasterService);
    this.initForm();
    if (this.data.returnCountry) {
      this.form.patchValue(this.data.returnCountry);
    }
    this.getEcommerceSettingsDeliveryServices();
  }

  initForm(): void {
    this.form = this.fb.group({
      country: [null, [Validators.required]],
      deliveryService: [null, [Validators.required]],
      deliveryType: ['STANDARD', [Validators.required]],
    });
  }

  private getEcommerceSettingsDeliveryServices(): void {
    this.ecoSettingsApiService.getEcoSettingsDeliveryServices()
      .pipe(takeUntil(this._destroy))
      .subscribe((deliveryServices: EcoSettingsDeliveryServiceModel[]) => {
        this.deliveryServices = deliveryServices;
      });
  }

  public deleteCountry(): void {
    this.ecoSettingsApiService.ecoSettingsReturnsRemoveCountry(this.data.returnCountry.id)
      .pipe(
        // finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(() => this.dialogRef.close(true));
  }

  private getFormValue(): EcoSettingsReturnCountryModel {
    const formValue = this.form.getRawValue();
    const country = this.data.returnCountry
      ? formValue.country
      : {iso2: formValue.country && formValue.country.country_iso2 as any};

    return {
      ...formValue,
      activated: get(this, 'data.returnCountry.activated', false),
      country,
    };
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    if (this.submitRequest$.getValue() || this.form.invalid) { return; }
    this.submitRequest$.next(true);

    let submitRequest$;

    if (!this.data.returnCountry) {
      submitRequest$ = this.ecoSettingsApiService.createEcoSettingsReturnsCountry(this.getFormValue());
    } else {
      submitRequest$ = this.ecoSettingsApiService.updateEcoSettingsReturnsCountry(this.getFormValue(), this.data.returnCountry.id);
    }

      submitRequest$
        .pipe(
          finalize(() => this.submitRequest$.next(false)),
          takeUntil(this._destroy)
        )
        .subscribe(res => this.dialogRef.close(res));
  }

  public get countryControl(): FormControl { return this.form.get('country') as FormControl; }
  public get deliveryServiceControl(): FormControl { return this.form.get('deliveryService') as FormControl; }
  public get deliveryTypeControl(): FormControl { return this.form.get('deliveryType') as FormControl; }


}
