import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CompanyNameControlComponent } from './company-name-control.component';

@NgModule({
  declarations: [
    CompanyNameControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    CompanyNameControlComponent,
  ],
  // entryComponents: [
  //   CompanyNameControlComponent
  // ],
})
export class CompanyNameControlModule { }
