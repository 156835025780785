import { SalesOrderListTabsEnum } from 'projects/workspace/src/app/sales-order/enums';
import { PaymentListTabsEnum } from 'projects/workspace/src/app/payment/enums';
import { DeliveryNoteListTabsEnum } from 'projects/workspace/src/app/delivery-note/enums';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { TradeOfferListTabsEnum } from 'projects/workspace/src/app/trade-offer/enums';

export const DOCUMENT_NAME: {[key: string]: string} = {
  ['delivery_note']: 'COLUMN.DELIVERY_NOTE',
  ['outgoing_invoice']: 'APP.OIN',
  ['OIN']: 'APP.OIN',
  ['era']: 'APP.RETURNS',
  ['ERA']: 'APP.RETURNS',
  ['offer']: 'APP.OFFER',
  ['OFR']: 'APP.OFFER',
  ['incoming_invoice']: 'APP.INCOMING_INVOICE',
  ['IIN']: 'APP.INCOMING_INVOICE',
  ['SP']: 'SCHEDULED_PAYMENT.SCHEDULED_PAYMENT',
  ['credit_note']: 'APP.CREDIT_NOTE',
  ['CRN']: 'APP.CREDIT_NOTE',
  ['ICN']: 'APP.CREDIT_NOTE',
  ['OCN']: 'APP.CREDIT_NOTE',
  ['sales_order']: 'APP.SALES_ORDER',
  ['eco_order']: 'MODULE.E_COMMERCE',
  ['SO']: 'APP.SALES_ORDER',
  ['ECO']: 'DOC_SUMMARY.E_COMMERCE_ORDER',
  ['SBC']: 'APP.SUBSCRIPTION',
  ['OPB']: 'COMMON.OUTGOING_PAYMENT',
  ['IPB']: 'COMMON.INCOMING_PAYMENT',
  ['PO']: 'APP.PURCHASE_ORDER',
  ['purchase_order']: 'APP.PURCHASE_ORDER',
};


export const DOCUMENT_ICON: {[key: string]: string} = { // todo remove (use documentIcon pipe instead)
  ['delivery_note']: 'outgoing-delivery',
  ['outgoing_invoice']: 'outgoing-invoice',
  ['OIN']: 'outgoing-invoice',
  ['era']: 'returns',
  ['ERA']: 'returns',
  ['offer']: 'offer',
  ['OFR']: 'offer',
  ['incoming_invoice']: 'incoming-invoice',
  ['IIN']: 'incoming-invoice',
  ['SP']: 'recurring-costs',
  ['credit_note']: 'credit-note',
  ['CRN']: 'credit-note',
  ['ICN']: 'credit-note',
  ['OCN']: 'credit-note',
  ['sales_order']: 'sales-order',
  ['eco_order']: 'sales-order',
  ['SO']: 'sales-order',
  ['ECO']: 'sales-order',
  ['SBC']: 'subscriptions',
  ['OPB']: 'payment',
  ['IPB']: 'payment',
  ['PO']: 'purchase-order',
  ['purchase_order']: 'purchase-order',
  ['SP_CALENDAR']: 'calendar',
};

export const DOCUMENT_COLOR_ICON: {[key: string]: string} = {
  ['delivery_note']: 'orange',
  ['outgoing_invoice']: 'violet',
  ['OIN']: 'violet',
  ['era']: 'yellow',
  ['ERA']: 'yellow',
  ['offer']: 'yellow',
  ['incoming_invoice']: 'violet',
  ['IIN']: 'violet',
  ['SP']: 'violet',
  ['credit_note']: 'violet',
  ['CRN']: 'violet',
  ['ICN']: 'violet',
  ['OCN']: 'violet',
  ['sales_order']: 'yellow',
  ['SO']: 'yellow',
  ['OPB']: 'violet',
  ['IPB']: 'violet',
  ['PO']: 'yellow',
  ['purchase_order']: 'yellow',
  ['eco_order']: 'red',
};


export const STATUS_CLASSES: {[key: string]: string} = {
  [SalesOrderListTabsEnum.DRAFT]: 'rnpl-badge--grey',
  [DeliveryNoteListTabsEnum.PACKED_UP]: 'rnpl-badge--blue',
  [SalesOrderListTabsEnum.OPEN]: 'rnpl-badge--blue',
  [PaymentListTabsEnum.BOOKED]: 'rnpl-badge--green',
  [SalesOrderListTabsEnum.COMPLETED]: 'rnpl-badge--green',
  [OutgoingInvoiceListTabsEnum.PAID]: 'rnpl-badge--green',
  [DeliveryNoteListTabsEnum.SHIPPED]: 'rnpl-badge--green',
  [TradeOfferListTabsEnum.ACCEPTED]: 'rnpl-badge--green',
  [SalesOrderListTabsEnum.CANCELED]: 'rnpl-badge--red',
  [SalesOrderListTabsEnum.DELETED]: 'rnpl-badge--empty',
  ['editing']: 'rnpl-badge--main-400',
};
