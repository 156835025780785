import { OnDestroy, OnInit } from '@angular/core';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, ReplaySubject } from 'rxjs';

import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { ResponseModel } from 'projects/workspace/src/app/shared/models/response';


export abstract class InitialSetupBaseComponent implements OnInit, OnDestroy {

  public companyProfile: CompanyProfile;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    public store: Store<AppState>,
    public router: Router,
    public administrationsApiService: AdministrationsApiService
  ) {
    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        if (companyProfile.tracking.completedAt && companyProfile.entityState === 'EXISTS') {
          this.router.navigate(['/start']);
        }
        this.companyProfile = companyProfile;
      });
  }

  ngOnInit() {

  }

  public updateCompanyInfo$(companyProfile: CompanyProfile): Observable<CompanyProfile> {
    return this.administrationsApiService.updateCompanyProfile(companyProfile)
      .pipe(map((data: ResponseModel<CompanyProfile>) => data.data));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
