import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  TableComponent
} from './table';
import {
  HeaderRowComponent,
  HeaderRowDefDirective,
  RowComponent,
  RowDefDirective
} from './row';
import {
  CellDefDirective,
  CellDirective,
  HeaderCellDefDirective,
  HeaderCellDirective
} from './cell';

@NgModule({
  declarations: [
    TableComponent,
    CellDirective,
    HeaderCellDirective,
    CellDefDirective,
    HeaderCellDefDirective,
    HeaderRowComponent,
    RowComponent,
    HeaderRowDefDirective,
    RowDefDirective
  ],
  exports: [
    TableComponent,
    CellDirective,
    HeaderCellDirective,
    CellDefDirective,
    HeaderCellDefDirective,
    HeaderRowComponent,
    RowComponent,
    HeaderRowDefDirective,
    RowDefDirective
  ],
  imports: [
    CommonModule
  ]
})
export class TableModule {
}
