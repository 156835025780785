import { SendEmailStatusEnum } from 'common/src/models';

export const EMAIL_SENT_STATUS_CLASSES: {[key in SendEmailStatusEnum]?: string } = {
  [SendEmailStatusEnum.SENT]: 'icon-email color-blue-400',
  [SendEmailStatusEnum.READ]: 'icon-checkmark-circle color-green-500',
  [SendEmailStatusEnum.NOT_SENT]: 'icon-clock color-grey-500',
  [SendEmailStatusEnum.PENDING]: 'icon-clock color-grey-500',
  [SendEmailStatusEnum.FAILED]: 'icon-alert-triangle color-yellow-400',
};

export const EMAIL_SENT_STATUS_CLASSES_OVERDUE_NOTICE: {[key in SendEmailStatusEnum]?: string } = {
  [SendEmailStatusEnum.SENT]: 'blue-400',
  [SendEmailStatusEnum.READ]: 'green-500',
  [SendEmailStatusEnum.NOT_SENT]: 'grey-500',
  [SendEmailStatusEnum.PENDING]: 'grey-500',
  [SendEmailStatusEnum.FAILED]: 'yellow-400',
};

export const EMAIL_SENT_STATUS_CLASSES_CONTROL_CENTER: {[key in SendEmailStatusEnum]?: string } = {
  [SendEmailStatusEnum.SENT]: 'color-blue-400',
  [SendEmailStatusEnum.READ]: 'color-green-500',
  [SendEmailStatusEnum.NOT_SENT]: 'color-main-200',
  [SendEmailStatusEnum.PENDING]: 'color-main-200',
  [SendEmailStatusEnum.FAILED]: 'color-yellow-400',
};
