import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { PaginationModel } from 'common/src/models';
import { GlAccountsApiService } from '../../../gl-accounts/services/gl-accounts-api.service';
import { DEFAULT_PAGINATION, DEFAULT_SORT_DIRECTION } from '../../constants';
import { FilterModelNew } from '../../../outgoing-invoice/models/filter-model-new';
import { GlTransactionModel } from '../../../gl-accounts/models';
import { ResponseList } from '../../models';

@Component({
  selector: 'rnpl-gl-transactions',
  templateUrl: './gl-transactions.component.html',
  styleUrls: ['./gl-transactions.component.scss'],
})
export class GlTransactionsComponent implements OnChanges, OnDestroy {

  @Input() currency: string = 'EUR';
  @Input() public gltransactionLinkedDocumentKey: string;

  public transactions: GlTransactionModel[] = [];
  public pagination: PaginationModel = { ...DEFAULT_PAGINATION, per_page: 1000 };
  public sort: FilterModelNew = { sortBy: 'createdAt', direction: DEFAULT_SORT_DIRECTION };

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  public showList: boolean;

  constructor(private glAccountsApiService: GlAccountsApiService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('gltransactionLinkedDocumentKey') && this.gltransactionLinkedDocumentKey) {
      this.getTransactions(this.gltransactionLinkedDocumentKey);
    }
  }

  private getTransactions(gltransactionLinkedDocumentKey: string): void {
    this.glAccountsApiService.getGlAccountsTransactions(
      this.pagination,
      this.sort,
      { linkedDocuments: gltransactionLinkedDocumentKey }
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe((transactions: ResponseList<GlTransactionModel>) => this.transactions = transactions.data);
  }

  public transactionsListToggle(): void {
    this.showList = !this.showList;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
