import { MatExpansionModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { GlTransactionsComponent } from './gl-transactions.component';
import { RnplCommonModule } from 'common/src/modules';


@NgModule({
  declarations: [
    GlTransactionsComponent
  ],
  exports: [
    GlTransactionsComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    TranslateModule,
    MatExpansionModule
  ],
})
export class GlTransactionsModule { }
