import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { CustomerTypeEnum, DeliveryTypesEnum, PayerEnum, UIStatesEnum } from 'common/src/models';
import { convertAddressToStringHelper } from 'common/src/modules/rnpl-common/helpers';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { selectCountries, selectPoCurrentState, selectPurchaseOrder } from '../../../store/selectors';
import { AddressFormFactory } from '../../../../shared/forms';
import { FormInputChangedModel } from '../../../../shared/models';
import { PurchaseOrderApiService } from '../../../services/purchase-order-api.service';
import { AppState } from '../../../../store/state/app.state';
import { PurchaseOrder, PurchaseOrderDeliveryInfoModel } from '../../../models';
import { UpdateDeliveryInfoBlockValid } from '../../../store/actions/po.actions';
import { PoTabs } from '../../../enums';
import { AddressTypeEnum } from '../../../../sales-order/enums/address-type.enum';

@Component({
  selector: 'rnpl-logistics',
  templateUrl: './logistics.component.html',
  styleUrls: ['./logistics.component.scss'],
})
export class LogisticsComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public pickUpAddressForm: FormGroup;
  public deliveryToAddressForm: FormGroup;

  public countries: CountryModel[];
  public purchaseOrder: PurchaseOrder;
  public addressTypeList = [
    {value: AddressTypeEnum.WAREHOUSE, label: 'FORM.WAREHOUSE_ADDRESS'},
    // {value: AddressTypeEnum.BILLING, label: 'FORM.BILLING_ADDRESS', enabled: this.isCustomerSelected},
    // {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: this.isCustomerSelected},
    {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS'},
    {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS'},
  ];
  public addressTypeListPickUp = [];
  public isReadonly: boolean = true;
  public readonly deliveryTypes = DeliveryTypesEnum;
  private convertAddressToString = convertAddressToStringHelper;
  public addressTypeEnum: typeof AddressTypeEnum = AddressTypeEnum;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private purchaseOrderApiService: PurchaseOrderApiService,
    private cdr: ChangeDetectorRef,
    private metricPipe: MetricConversionPipe,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.selectCountries();
    this.selectPurchaseOrder();
    this.selectPurchaseOrderState();
  }

  private selectCountries(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countriesFromStore: CountryModel[]) => {
        this.countries = countriesFromStore;
        this.cdr.detectChanges();
      });
  }

  private selectPurchaseOrder(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        this.initForm(purchaseOrder.deliveryInformation);
        this.setFormsState();

        this.addressTypeListPickUp = [
          {value: AddressTypeEnum.DELIVERY, label: 'FORM.DELIVERY_ADDRESS', enabled: !!purchaseOrder.properties.company.id && !this.isGeneralPartner},
          {value: AddressTypeEnum.USER_DEFINED, label: 'COMMON.NEW_ADDRESS', enabled: true},
          {value: AddressTypeEnum.SIMPLIFIED, label: 'COMMON.USER_DEFINED_ADDRESS', enabled: true},
        ].filter(i => i.enabled);
      });
  }

  private selectPurchaseOrderState(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = {onlySelf: true, emitEvent: false};

    if (this.isReadonly) {
      this.form.disable(opts);
      this.deliveryToAddressForm.disable(opts);
      this.pickUpAddressForm.disable(opts);
      this.cdr.detectChanges();
      return;
    } else {
      this.form.enable(opts);
      this.deliveryToAddressForm.enable(opts);
      this.pickUpAddressForm.enable(opts);
      this.cdr.detectChanges();
    }

    if (this.deliveryCostsPayer.value === PayerEnum.SENDER) {
      this.deliveryCostsVat.disable(opts);
      this.deliveryCosts.disable(opts);
      this.deliveryCostsGross.disable(opts);
    }

    // if (this.deliveryInsurancePayer.value === PayerEnum.SENDER) {
    //   this.deliveryInsuranceVat.disable(opts);
    //   this.deliveryInsuranceCosts.disable(opts);
    //   this.deliveryInsuranceCostsGross.disable(opts);
    // }

    if (this.purchaseOrder.properties.vatDisabled) {
      this.deliveryCostsVat.disable(opts);
      // this.deliveryInsuranceVat.disable(opts);
    }

    if (this.purchaseOrder.status === PoTabs.Template) {
      this.estimatedDeliveryDate.disable(opts);
    }

    if (!get(this.purchaseOrder, 'properties.company.id')) {
      this.deliveryToAddressForm.disable(opts);
      this.pickUpAddressForm.disable(opts);
    }
  }

  private initForm(deliveryInfo: PurchaseOrderDeliveryInfoModel = {} as PurchaseOrderDeliveryInfoModel): void {
    const deliveryDate = get(deliveryInfo, 'estimatedDeliveryDate');

    this.form = this.fb.group({
      deliveryType: [get(deliveryInfo, 'deliveryType'), [Validators.required]],
      isExpressDelivery: [get(deliveryInfo, 'isExpressDelivery')],
      deliveryService: [get(deliveryInfo, 'deliveryService.id')],
      trackingCode: [get(deliveryInfo, 'trackingCode')],
      isInsuredDelivery: [get(deliveryInfo, 'isInsuredDelivery')],
      isDeliveryCosts: [get(deliveryInfo, 'isDeliveryCosts')],
      estimatedDeliveryDate: [deliveryDate ? this.datePipe.transform(deliveryDate, 'yyyy-MM-dd') : null],
      //deliveryCosts
      deliveryCosts: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryCosts'))],
      deliveryCostsGross: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryCostsGross'))],
      deliveryCostsVat: [get(deliveryInfo, 'deliveryCostsVat')],
      deliveryCostsPayer: [get(deliveryInfo, 'deliveryCostsPayer')],
      //deliveryInsurance
      // deliveryInsuranceCosts: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryInsuranceCosts'))],
      // deliveryInsuranceCostsGross: [this.fillControlValueWithConversion(get(deliveryInfo, 'deliveryInsuranceCostsGross'))],
      // deliveryInsuranceVat: [get(deliveryInfo, 'deliveryInsuranceVat')],
      // deliveryInsurancePayer: [get(deliveryInfo, 'deliveryInsurancePayer')],
      addressTemplate: [get(deliveryInfo, 'addressTemplate')],
    });

    this.pickUpAddressForm = AddressFormFactory.getForm(get(deliveryInfo, 'pickUpAddress'), false, true);
    this.deliveryToAddressForm = AddressFormFactory.getForm(get(deliveryInfo, 'deliveryToAddress'), false, true);

    this.cdr.detectChanges();
    this.updateDeliveryInfoBlockValid();
  }

  private updateDeliveryInfoBlockValid(): void {
    const deliveryCostControls: FormControl[] = [this.deliveryCostsVat, this.deliveryCosts, this.deliveryCostsGross, this.deliveryCostsPayer];
    // const insuranceCostControls: FormControl[] = [this.deliveryInsuranceVat, this.deliveryInsuranceCosts, this.deliveryInsuranceCostsGross, this.deliveryInsurancePayer];

    if (this.form.get('isDeliveryCosts').value && this.deliveryCostsPayer.value !== PayerEnum.SENDER) {
      deliveryCostControls.forEach(control => control.setValidators(Validators.required));
    } else {
      deliveryCostControls.forEach(control => control.clearValidators());
    }

    // if (this.form.get('isInsuredDelivery').value && this.deliveryInsurancePayer.value !== PayerEnum.SENDER) {
    //   insuranceCostControls.forEach(control => control.setValidators(Validators.required));
    // } else {
    //   insuranceCostControls.forEach(control => control.clearValidators());
    // }

    // [...deliveryCostControls, ...insuranceCostControls].forEach(control => control.updateValueAndValidity());

    [...deliveryCostControls].forEach(control => control.updateValueAndValidity());

    this.store.dispatch(UpdateDeliveryInfoBlockValid({ deliveryInfoBlockValid: this.form.valid }));
  }

  public updatePODeliveryInfo(fieldValue, fieldName: string): void {
    fieldName = 'deliveryInformation.' + fieldName;
    this.purchaseOrderApiService.updatePO(this.purchaseOrder.id, {fieldValue, fieldName})
      .pipe(takeUntil(this.destroy$))
      .subscribe(); // updated via store
  }

  public updateAddressField(field: FormInputChangedModel, addressGroupName: string): void {
    const fieldName = addressGroupName + '.' + field.fieldName;
    this.updatePODeliveryInfo(field.fieldValue, fieldName);
  }

  // public fillCustomerDeliveryAddress(): void {
  //   this.purchaseOrderApiService.updatePickUpAddressByVendor(this.purchaseOrder.id)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(); // updated via store
  // }
  //
  // public fillOwnWarehouseDeliveryAddress(): void {
  //   this.purchaseOrderApiService.updateDeliveryAddressByWarehouse(this.purchaseOrder.id)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(); // updated via store
  // }

  private fillControlValueWithConversion(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return null; }
    return this.metricPipe.transform(+value, 'TO-FRACTIONAL') || null;
  }

  public get isDeliveryTypeSelected(): boolean {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryType') === this.deliveryTypes.DELIVERY;
  }

  public get isCustomerSelected(): boolean {
    return !!get(this, 'purchaseOrder.properties.company.runpleId');
  }

  public get isGeneralPartner(): boolean {
    return get(this, 'purchaseOrder.properties.partnerType') === CustomerTypeEnum.GENERAL;
  }

  public get pickUpAddressAsText(): string {
    if (!this.form) { return ''; }

    const pickUpAddressVal = this.pickUpAddressForm.value;
    return this.convertAddressToString(pickUpAddressVal, this.countries);
  }

  public get deliveryAddressAsText(): string {
    if (!this.form) { return ''; }

    const deliveryAddressVal = this.deliveryToAddressForm.value;
    return this.convertAddressToString(deliveryAddressVal, this.countries);
  }

  public editAddress(): void {
    this.addressTemplateControl.setValue(AddressTypeEnum.USER_DEFINED);
    this.updatePODeliveryInfo(AddressTypeEnum.USER_DEFINED, 'addressTemplate');
  }

  get deliveryTypeControl(): FormControl { return this.form.get('deliveryType') as FormControl; }
  get deliveryServiceControl(): FormControl { return this.form.get('deliveryService') as FormControl; }
  get estimatedDeliveryDate(): FormControl { return this.form.get('estimatedDeliveryDate') as FormControl; }
  get deliveryCostsPayer(): FormControl { return this.form.get('deliveryCostsPayer') as FormControl; }
  get deliveryCostsVat(): FormControl { return this.form.get('deliveryCostsVat') as FormControl; }
  get deliveryCosts(): FormControl { return this.form.get('deliveryCosts') as FormControl; }
  get deliveryCostsGross(): FormControl { return this.form.get('deliveryCostsGross') as FormControl; }
  // get deliveryInsurancePayer(): FormControl { return this.form.get('deliveryInsurancePayer') as FormControl; }
  // get deliveryInsuranceVat(): FormControl { return this.form.get('deliveryInsuranceVat') as FormControl; }
  // get deliveryInsuranceCosts(): FormControl { return this.form.get('deliveryInsuranceCosts') as FormControl; }
  // get deliveryInsuranceCostsGross(): FormControl { return this.form.get('deliveryInsuranceCostsGross') as FormControl; }
  get addressTemplateControl(): FormControl { return this.form.get('addressTemplate') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
