import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActionButtonsService } from '../../../services/action-buttons.service';
import { NavItem } from '../head-navigation/nav-items.config';

@Component({
  selector: 'rnpl-nav-menu-items',
  templateUrl: './nav-menu-items.component.html',
  styleUrls: ['./nav-menu-items.component.scss']
})
export class NavMenuItemsComponent {

  @Input() items: Array<NavItem>;
  @Input() appNav: boolean;
  @Input() minWidthClass: string;

  @Output() closeNav: EventEmitter<any> = new EventEmitter();

  @ViewChild('childMenu', {static: true}) public childMenu;


  constructor(public router: Router, private actionButtonService: ActionButtonsService) { }

  public activeRoute(children): boolean {
    return children.children.some((item) => this.router.isActive(item.route, false)
    );
  }

  public actionHandler(action: string, event: Event) {
    this.closeNav.emit();
    event.stopPropagation();
    this.actionButtonService.callAction(action);
  }

  public routNavigate(link, secondRouter?: string): void {
    if (link) {
      this.router.navigate([secondRouter || link]);
    }
  }

  public closedMenu(): void {
    const overlay = document.querySelector<HTMLElement>('.cdk-overlay-container');
    overlay.style.zIndex = '1000';
  }

  public showItem(item: NavItem): boolean {
    if (item.hasOwnProperty('show')) {
      return item.show;
    }

    return true;
  }

}
