import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import {
  BinLocationCreationMethodEnum,
  BinLocationFormatEnum,
  BinLocationsTabsEnum,
  RackFormatEnum
} from 'projects/workspace/src/app/warehouse/enums';
import { BinLocationService } from 'projects/workspace/src/app/warehouse/services';
import { NavBarBtnModel } from '../../../../models';
import { NavBarButtons } from './create-new-bin-location-modal.config';

@Component({
  selector: 'rnpl-create-new-bin-location-modal',
  templateUrl: './create-new-bin-location-modal.component.html',
  styleUrls: ['./create-new-bin-location-modal.component.scss'],
})
export class CreateNewBinLocationModalComponent implements OnDestroy {
  public rackList = [];
  public shelfList = [];
  public binList = [];
  public form: FormGroup;
  public actionButtons: NavBarBtnModel[] = NavBarButtons;
  public binLocationCreationMethodEnum = BinLocationCreationMethodEnum;
  public binLocationFormatEnum = BinLocationFormatEnum;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private binLocationService: BinLocationService,
    public dialogRef: MatDialogRef<CreateNewBinLocationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {
    this.initForm();
    this.rackList = this.getRackList();

    this.shelfList = Array.from({length: 10}, (val, index) => index === 0 ? '00' : (index + '0'));
    this.binList = Array.from({length: 999}, (val, index) => {
      if (index < 9) { return '00' + (index + 1); }
      if (index < 99) { return '0' + (index + 1); }
      return (index + 1).toString();
    });
  }

  public actionsEvent(e: string): void {
    this[e]();
  }

  private initForm(): void {
    this.form = this.fb.group({
      creationMethod: [BinLocationCreationMethodEnum.SINGLE_BIN],
      rack: [null, [Validators.required]],
      shelf: [null, [Validators.required]],
      bin: [null, [Validators.required]],
      binFrom: [null, [Validators.required]],
      binTo: [null, [Validators.required]]
    });
  }

  public getRackList(): string[] {
    if (!this.data || !this.data.rackFormat || !this.data.location) { return []; }
    if (this.data.rackFormat === RackFormatEnum.NUMBERS) {
      if (this.data.location === BinLocationsTabsEnum.GENERAL_STOCK) {
        return Array.from({length: 26}, (val, index) => (index + 1).toString());
      }
      if (this.data.location === BinLocationsTabsEnum.RETURNS_STOCK) {
        return Array.from({length: 26}, (val, index) => 'R' + (index + 1));
      }
    }

    if (this.data.rackFormat === RackFormatEnum.LETTERS) {
      if (this.data.location === BinLocationsTabsEnum.GENERAL_STOCK) {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      }
      if (this.data.location === BinLocationsTabsEnum.RETURNS_STOCK) {
        return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(i => 'R' + i);
      }
    }

    return [];
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submit();
  }

  onSubmitAndContinue(): void {
    this.submit(true);
  }

  public submit(isContinue = false): void {
    this.binLocationService.createNewStorageObject(this.data.location, this.form.getRawValue())
      .pipe(
        finalize(() => this.btnToClearLoadingStatus$.next('onSubmit')),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        if (!isContinue) {
          this.dialogRef.close(res);
          return;
        }

        this.form.reset();
        this.form.get('creationMethod').patchValue(BinLocationCreationMethodEnum.SINGLE_BIN);
      });
  }

  get binLocationPreview(): string {
    const previewArray: string[] = [];

    if (this.rackValue) {
      previewArray.push(this.rackValue);
    }

    if (this.binLocationFormat === this.binLocationFormatEnum.RACK_SHELF_BIN) {
      if (this.shelfValue) {
        previewArray.push(this.shelfValue);
      }
      if (this.binValue) {
        previewArray.push(this.binValue);
      }
    }

    if (this.binLocationFormat === this.binLocationFormatEnum.RACK_BIN_SHELF) {
      if (this.binValue) {
        previewArray.push(this.binValue);
      }
      if (this.shelfValue) {
        previewArray.push(this.shelfValue);
      }
    }

    return previewArray.join('-');
  }

  get binLocationsPreview(): string {
    let preview: string = '';
    const previewArrayFrom: string[] = [];
    const previewArrayTo: string[] = [];

    if (this.rackValue) {
      previewArrayFrom.push(this.rackValue);
      previewArrayTo.push(this.rackValue);
    }

    if (this.binLocationFormat === this.binLocationFormatEnum.RACK_SHELF_BIN) {
      if (this.shelfValue) {
        previewArrayFrom.push(this.shelfValue);
        previewArrayTo.push(this.shelfValue);
      }
      if (this.binFromValue) {
        previewArrayFrom.push(this.binFromValue);
      }
      if (this.binToValue) {
        previewArrayTo.push(this.binToValue);
      }
    }

    if (this.binLocationFormat === this.binLocationFormatEnum.RACK_BIN_SHELF) {
      if (this.binFromValue) {
        previewArrayFrom.push(this.binFromValue);
      }
      if (this.binToValue) {
        previewArrayTo.push(this.binToValue);
      }
      if (this.shelfValue) {
        previewArrayFrom.push(this.shelfValue);
        previewArrayTo.push(this.shelfValue);
      }
    }

    if (previewArrayFrom.length) {
      preview = `${this.translateService.instant('FORM.FROM')} ${previewArrayFrom.join('-')}`;
    }

    if (previewArrayTo.length) {
      preview += ` ${this.translateService.instant('FORM.TO_DATE').toLowerCase()} ${previewArrayTo.join('-')}`;
    }

    return preview;
  }

  get binsQuantity(): number {
    if (this.binFromValue && this.binToValue) {
      return +(this.binToValue) - +(this.binFromValue) + 1;
    }
    return 0;
  }

  get binLocationFormat(): BinLocationFormatEnum { return get(this, 'data.binLocationFormat'); }
  get creationMethodValue(): BinLocationCreationMethodEnum { return this.form.get('creationMethod').value; }
  get rackValue(): string { return this.form.get('rack').value; }
  get shelfValue(): string { return this.form.get('shelf').value; }
  get binValue(): string { return this.form.get('bin').value; }
  get binFromValue(): string { return this.form.get('binFrom').value; }
  get binToValue(): string { return this.form.get('binTo').value; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

interface DataType {
  rackFormat: RackFormatEnum;
  binLocationFormat: BinLocationFormatEnum;
  location: BinLocationsTabsEnum;
}
