import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, NG_ASYNC_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { PartnerVatCheckModel } from 'projects/workspace/src/app/partners/corporate/models/vat-check.model';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { VatStatusEnum } from '../../../models';

@Directive({
  selector: `
    [rnplVatValidator][formControlName][vatValidatorCountry],
    [rnplVatValidator][formControl][vatValidatorCountry],
    [rnplVatValidator][ngModel][vatValidatorCountry]
  `,
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: RnplVatValidatorDirective, multi: true }]
})
export class RnplVatValidatorDirective implements Validator {

  @Input('vatValidatorCountry') codeIso3: string;
  @Input() validatable: boolean = true;

  @Output() vatValidatorResponse: EventEmitter<PartnerVatCheckModel> = new EventEmitter<PartnerVatCheckModel>();

  constructor(
    private readonly partnerApiService: PartnersApiService,
  ) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return (control.value && this.codeIso3 && this.validatable)
      ? this.validateVatNumber(control.value, this.codeIso3)
      : of(null);
  }

  validateVatNumber(vatNumber: string, codeIso3: string) {
    return this.partnerApiService.validateVatNumber(vatNumber, null, codeIso3).pipe(
      tap(vatResponse => this.vatValidatorResponse.emit(vatResponse)),
      map(vatResponse => ( vatResponse.valid || vatResponse.vatStatus === VatStatusEnum.NA ? null : { vatValidation: true})),
      catchError(() => of({vatValidation: true})),
    );
  }
}
