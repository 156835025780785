import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { UserListTabs } from './enums/user-list-tabs.enum';
import { CreditNotesListTabsEnum } from '../../../credit-note/enums';

export const TabUserList: TabDefinitionModel[]  = [
  {
    label: 'TAB.ACTIVE',
    hintsId: 'activeTab',
    tabName: UserListTabs.Active,
    link: '../../active/1',
    icon: 'bulb',
    count: 0
  },
  {
    label: 'TAB.INACTIVE',
    hintsId: 'inactiveTab',
    tabName: UserListTabs.Inactive,
    link: '../../inactive/1',
    icon: 'bulb-off',
    count: 0,
    hideCount: true
  },
  {
    label: 'TAB.DELETED',
    tabName: UserListTabs.Deleted,
    link: '../../deleted/1',
    icon: 'trash-2',
    hintsId: 'deletedTab',
    count: 0,
    hideCount: true
  }
];
