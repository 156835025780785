import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ReportingPeriodsApiService } from 'projects/workspace/src/app/reporting-periods/services/reporting-periods-api.service';

@Component({
  selector: 'rnpl-reporting-periods-schedule-reporting-modal',
  templateUrl: './reporting-periods-schedule-reporting-modal.component.html'
})
export class ReportingPeriodsScheduleReportingModalComponent extends BaseModalComponent implements OnInit {
  public form: FormGroup;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public toasterService: ToasterService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    private reportingPeriodsApiService: ReportingPeriodsApiService,
    public dialogRef: MatDialogRef<ReportingPeriodsScheduleReportingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {

    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
    });
  }

}
