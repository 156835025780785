import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';

export const InvoiceGeneratedOnSchedule: CommonModalDataModel = getInfoModalData(
  'MODAL.INVOICE_GENERATE_ON_SCHEDULE_TITLE',
  'MODAL.INVOICE_GENERATE_ON_SCHEDULE_MESSAGE'
);

export const InvoiceGeneratedAutomatically: CommonModalDataModel = getInfoModalData(
  'The invoice will be created automatically',
  'MODAL.INVOICE_GENERATE_ON_SCHEDULE_MESSAGE'
);

export const InvoiceCreatedForAllProducts: CommonModalDataModel = getInfoModalData(
  'MODAL.INVOICE_CREATE_FOR_ALL_PRODUCTS_TITLE',
  'MODAL.INVOICE_CREATE_FOR_ALL_PRODUCTS_MESSAGE'
);

export const DeliveryNoteCreatedForAllProducts: CommonModalDataModel = getInfoModalData(
  'MODAL.DN_CREATE_FOR_ALL_PRODUCTS_TITLE',
  'MODAL.DN_CREATE_FOR_ALL_PRODUCTS_MESSAGE'
);

export const UnableToAddProduct: CommonModalDataModel = getInfoModalData(
  'MODAL.UNABLE_TO_ADD_PRODUCT_TITLE',
  'MODAL.UNABLE_TO_ADD_PRODUCT_MESSAGE'
);


export function getInfoModalData(title: string, message: string): CommonModalDataModel {
  return {
    title,
    message,
    hideCancelBtn: true,
    titleIcon: 'alert-triangle',
    titleColor: 'yellow-400',
    confirmBtnText: 'BUTTON.CLOSE',
    confirmBtnIcon: 'close'
  }
}
