import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PositionInfoListModel, PositionItemTypeEnum } from '../position-card-models.model';
import { DateColumnType } from '../../../models/table-column.model';
import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from 'projects/workspace/src/app/accounting/accounting.constants';

@Component({
  selector: 'rnpl-position-info-list',
  templateUrl: './position-info-list.component.html',
  styleUrls: ['./position-info-list.component.scss']
})
export class PositionInfoListComponent {

  public readonly currencyIconByName = CURRENCIES_SYMBOLS_BY_ISO_CODE;

  public readonly positionItemTypeEnum = PositionItemTypeEnum;
  public readonly dateTypes: typeof DateColumnType = DateColumnType;
  public readonly dateColumnType = DateColumnType;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);

  @Input() public positionsCardInfo: PositionInfoListModel[];
  @Input() public position;

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  public displayConditionsHandler(position, conditionsList: any[]): boolean {
    if (!conditionsList && !conditionsList.length) { return false; }

    return conditionsList.every(condition => {
      return condition.values.some((val) => {
        return position[condition.prop] === val;
      });
    });
  }

}
