import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { ButtonDropdownActionsModel, NavBarBtnModel } from '../../../models';
import { MatMenuTrigger } from '@angular/material/menu';


@Component({
  selector: 'rnpl-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss']
})
export class ButtonDropdownComponent {

  public hideHover: boolean = false;

  @Input() btn: NavBarBtnModel;
  @Input() status: string = null;

  @Output() clickAction = new EventEmitter<string>();
  @Output() isOpenedDropdownEmitter = new EventEmitter<boolean>(false);
  @Output() clickActionWithValue = new EventEmitter<{ actionName: string, returnValue: any }>();

  @HostBinding('class.d-none')
  get blankMoreActions(): boolean {
    return this.btn.isMoreAction && !(this.actions && this.actions.length);
  }

  @ViewChild(MatMenuTrigger, {static: false}) menuTrigger: MatMenuTrigger;

  constructor() {

  }

  public actionClick(btn: NavBarBtnModel, actionName: string, disabled: boolean, returnValue?: any): void {
    if (!btn.disabled && !disabled) {
      returnValue
        ? this.clickActionWithValue.emit({actionName, returnValue})
        : this.clickAction.emit(actionName);
      if (this.menuTrigger) {
        this.menuTrigger.closeMenu();
      }
    }
  }

  public isOpenedDropdown(isOpened: boolean): void {
    this.isOpenedDropdownEmitter.emit(isOpened);
  }

  get actions(): ButtonDropdownActionsModel[] {
    if (!this.status) { return this.btn.actions; }
    return this.btn.actions.filter(action => {
      if (action.hasOwnProperty('hideWithStatus')) {
        return !action.hideWithStatus.includes(this.status);
      }
      return true;
    }).filter(action => !action.hideAction);
  }

}
