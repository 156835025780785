import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { ProductModel, ProductsService } from '../../../products';
import { InfoItemModel } from '../../../ui-components/info-components/models/info-item.model';
import {
  getAuthorizationReasonList,
  getRequiredSolutionList,
  getSerialNumberInfoHelper,
  getWADataInfoHelper
} from './exchange-add-product-modal.helper';
import { PricesInfoModel } from 'projects/workspace/src/app/trade-prices/models/prices-info.model';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { TradePricesApiService } from 'projects/workspace/src/app/trade-prices/services/trade-prices-api.service';
import { ExchangeAuthorizationReasonEnum, ExchangeRequiredSolutionEnum } from 'projects/workspace/src/app/exchange/enums';
import {
  ExchangeNewPositionBodyModel,
  ExchangePositionModel,
  ExchangeSalesOrderProductModel
} from 'projects/workspace/src/app/exchange/models';
import { ProductTypes } from '../../../products/product-types';
import { MetricConversionPipe } from '../../../rnpl-common';
import { CommonModalsActionsEnum, InfoModalComponent } from '../../modals-common';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';


interface DataType {
  exchangeId: number;
  position?: ExchangePositionModel;
}

@Component({
  selector: 'rnpl-exchange-add-product-modal',
  templateUrl: './exchange-add-product-modal.component.html',
  styleUrls: ['./exchange-add-product-modal.component.scss'],
})
export class ExchangeAddProductModalComponent implements OnInit, OnDestroy {

  public static unexpectedProductKey = 'unexpectedProduct';
  public static missingProductKey = 'missingProduct';

  public enteredSerialNumber: string;
  public serialNumbers: string[] = [];

  public unexpectedOrMissingProduct: boolean = false;
  public unexpectedOrMissingProductType: 'unexpectedProduct'|'missingProduct' = null;
  public exchangeAuthorizationReasonEnum = ExchangeAuthorizationReasonEnum;

  public selectedDeliveredProduct: ExchangeSalesOrderProductModel = null;
  public selectedOrderedProduct: ExchangeSalesOrderProductModel = null;
  public selectedUnexpectedProduct: ProductModel = null;

  public selectedAuthorizationReason: ExchangeAuthorizationReasonEnum = null;
  public selectedRequiredSolution: ExchangeRequiredSolutionEnum = null;
  public specifySerialNumbers: boolean = false;
  public invalidSerialNumber: boolean = false;
  public quantity: number;
  public remark: string;

  public deliveredProductPrices = {
    netUnitPrice: null,
    vat: null,
    grossUnitPrice: null,
    netTotalPrice: null,
    grossTotalPrice: null,
  };

  public compensationInfo = {
    compensationAmount: null,
    compensationAmountRate: null,
    isEstimatedMarginNegative: false
  };

  public serialNumberInfo:  InfoItemModel[] = [];
  public WADataInfo: InfoItemModel[] = [];
  public deliveredProductPriceInfo: PricesInfoModel = null;
  public availableProductsList: ExchangeSalesOrderProductModel[] = [];
  public activeGoodsProducts: ProductModel[] = [];

  public authorizationReasonList = [];
  public requiredSolutionList = [];

  public getSerialNumberInfo = getSerialNumberInfoHelper;

  public customSearchFn = CustomSearchFn;

  readonly showDropdownSpinAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly showDropdownSpinActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public dialogRef: MatDialogRef<ExchangeAddProductModalComponent>,
    public exchangeApiService: ExchangeApiService,
    public productsService: ProductsService,
    public pricesApiService: TradePricesApiService,
    public metricPipe: MetricConversionPipe,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {

  }

  ngOnInit(): void {
    if (this.data.position) {
      this.remark = this.data.position.remark;
      this.selectedAuthorizationReason = this.data.position.authorizationReason;
      this.selectedRequiredSolution = this.data.position.requiredSolution;
      this.unexpectedOrMissingProduct = this.data.position.unexpectedOrMissingProduct;

      if (this.unexpectedOrMissingProduct) {
        if (this.data.position.unexpectedProduct) {
          this.unexpectedOrMissingProductType = ExchangeAddProductModalComponent.unexpectedProductKey as 'unexpectedProduct';
        }

        if (this.data.position.missingProduct) {
          this.unexpectedOrMissingProductType = ExchangeAddProductModalComponent.missingProductKey as 'missingProduct';
        }
      }

      this.serialNumbers = this.data.position.units.map(unit => unit.serialNumber) || [];

      this.deliveredProductPrices.netUnitPrice = this.data.position.netUnitPrice;
      this.deliveredProductPrices.vat = this.data.position.vat;
      this.deliveredProductPrices.grossUnitPrice = this.data.position.grossUnitPrice;
      this.deliveredProductPrices.netTotalPrice = this.data.position.netTotalPrice;
      this.deliveredProductPrices.grossTotalPrice = this.data.position.grossTotalPrice;

      this.compensationInfo.compensationAmount = this.data.position.compensationAmount;
      this.compensationInfo.compensationAmountRate = this.data.position.compensationAmountRate;

      this.quantity = this.data.position.quantity;
      this.specifySerialNumbers = this.data.position.specifySerialNumbers;

      this.getActualAuthorizationDetailsLists();
    }
    this.getSalesOrderProducts();
    this.getActiveProducts();
    this.getActualAuthorizationDetailsLists();
  }

  public getSalesOrderProducts(): void {
    this.exchangeApiService.getExchangeSalesOrderProducts(this.data.exchangeId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showDropdownSpinAvailable$.next(false))
      )
      .subscribe((products: ExchangeSalesOrderProductModel[]) => {
        if (this.data.position && this.data.position.productId) {
          const selectedProduct = products.find(product => +product.id === +this.data.position.productId);

          if (!this.unexpectedOrMissingProduct || this.unexpectedOrMissingProductType === 'missingProduct') {
            this.selectedDeliveredProduct = selectedProduct;
            this.getProductPriceData(+this.selectedDeliveredProduct.id);
          }

          if ((this.showReplaceProductBlock || this.showWrongProductMoneyReturnBlock) && this.data.position.orderedProductId) {
            const orderedProduct = products.find(product => +product.id === +this.data.position.orderedProductId);
            this.selectedOrderedProduct = orderedProduct;
          }
        }

        this.availableProductsList = this.prepareProductsList(products);
      });
  }

  public prepareProductsList(products): ExchangeSalesOrderProductModel[] {
    return products.map(item => {
      return {
        ...item,
        searchLabel: `${item.name} ${item.orderedQuantity} ${item.returnedQuantity}`
      };
    })
  }

  public getActiveProducts(): void {
    this.productsService.getActiveProducts()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.showDropdownSpinActive$.next(false))
      )
      .subscribe((products: ProductModel[]) => {
        const availableProducts =  products.filter((product: ProductModel) => product.type === ProductTypes.GOODS);

        if (this.unexpectedOrMissingProduct && this.data.position && this.data.position.productId) {
          const selectedProduct = availableProducts.find(product => +product.id === +this.data.position.productId);
          if (this.unexpectedOrMissingProductType === 'unexpectedProduct') {
            this.selectedUnexpectedProduct = selectedProduct;
            this.getProductPriceData(+this.selectedUnexpectedProduct.id);
          }
        }
        this.activeGoodsProducts = this.prepareProductsGoodsList(availableProducts);
      });
  }

  public prepareProductsGoodsList(products): ProductModel[] {
    return products.map(item => {
      return {
        ...item,
        searchLabel: `${item.name} ${item.description}`
      };
    })
  }

  public getActualAuthorizationDetailsLists(): void {
    this.authorizationReasonList = getAuthorizationReasonList(this.unexpectedOrMissingProduct, this.unexpectedOrMissingProductType);
    this.requiredSolutionList = getRequiredSolutionList(this.selectedAuthorizationReason);
  }

  public changeAuthorizationReason(): void {
    this.selectedRequiredSolution = null;
    this.getActualAuthorizationDetailsLists();
  }

  public clearAuthorizations(): void {
    this.selectedAuthorizationReason = null;
    this.selectedRequiredSolution = null;
    this.unexpectedOrMissingProductType = null;
  }

  public getProductPriceData(productId: number): void {
    this.pricesApiService.getProductSalesInfo(productId)
      .subscribe((response: PricesInfoModel) => {
        if (
          this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT &&
          this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.SELL_PRODUCT &&
          +get(this, 'selectedUnexpectedProduct.id') === +get(response, 'id')
        ) {
          this.deliveredProductPriceInfo = response;
        }
        this.WADataInfo = this.selectedUnexpectedProduct ? getWADataInfoHelper(response) : null;
      });
  }

  public changeRequiredSolution(): void {
    if (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.SELL_PRODUCT &&
      get(this, 'selectedUnexpectedProduct.id')
    ) {
      this.getProductPriceData(+this.selectedUnexpectedProduct.id);
    }
  }

  public addSerialNumber(): void {
    if (this.selectedDeliveredProduct && this.selectedDeliveredProduct.serialNumbersAvailable) {
      if (this.selectedDeliveredProduct.serialNumbers.includes(this.enteredSerialNumber)) {
        if (!this.serialNumbers.includes(this.enteredSerialNumber)) {
          this.serialNumbers.push(this.enteredSerialNumber);
          this.quantity = this.serialNumbers.length;
        }
      } else {
        this.invalidSerialNumber = true;
      }
    }
  }

  public removeSerialNumber(index: number): void {
    this.serialNumbers.splice(index, 1);
    this.quantity = this.serialNumbers.length;
  }

  public setPrices(value): void {
    this.deliveredProductPrices = value;
  }

  public setCompensation(value): void {
    this.compensationInfo = value;
  }

  public increaseQuantity(): void {
    if (this.maxQuantity && this.maxQuantity <= this.quantity) { return; }
    this.quantity = (this.quantity || 0) + 1;
  }

  public decreaseQuantity(): void {
    if (!this.quantity) { return; }
    this.quantity = this.quantity - 1;
  }

  private validatePositionData(): boolean {
    let isValid = true;
    const action: string = this.data.position ? 'Save' : 'Add';

    if (this.showDamagedProductGiveCompensationBlock && this.compensationInfo.isEstimatedMarginNegative) {
      const dialog = this.dialog.open(InfoModalComponent, {
        data: {
          title: 'ERA.MODAL.THE_ESTIMATED_MARGIN_IS_NEGATIVE',
          confirmBtnText: `${action} product`,
          confirmBtnIcon: 'plus',
          message: 'ERA.MODAL.THE_ESTIMATED_MARGIN_IS_NEGATIVE_MESSAGE'
        }
      });

      dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.submitProduct(false);
        }
      });
      isValid = false;
    }

    if (this.showSellDeliveredProductBlock && this.estimatedProfit < 0) {
      const dialog = this.dialog.open(InfoModalComponent, {
        data: {
          title: 'ERA.MODAL.THE_ESTIMATED_PROFIT_IS_NEGATIVE',
          confirmBtnText: `${action} product`,
          confirmBtnIcon: 'plus',
          message: 'ERA.MODAL.THE_ESTIMATED_PROFIT_IS_NEGATIVE_MESSAGE'
        }
      });

      dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.submitProduct(false);
        }
      });
      isValid = false;
    }

    return isValid;
  }

  private getPositionRequestBody(): ExchangeNewPositionBodyModel {
    const productId = this.unexpectedOrMissingProductType === ExchangeAddProductModalComponent.unexpectedProductKey
      ? get(this, 'selectedUnexpectedProduct.id')
      : get(this, 'selectedDeliveredProduct.id');

    const soPositionId = this.showReplaceProductBlock || this.showWrongProductMoneyReturnBlock
      ? get(this, 'selectedOrderedProduct.soPositionId')
      : get(this, 'selectedDeliveredProduct.soPositionId');

    const specifySerialNumbers = this.unexpectedOrMissingProduct
      ? false
      : this.specifySerialNumbers;

    return {
      productId,
      soPositionId,
      unexpectedOrMissingProduct: this.unexpectedOrMissingProduct,
      unexpectedProduct: this.unexpectedOrMissingProductType === ExchangeAddProductModalComponent.unexpectedProductKey,
      missingProduct: this.unexpectedOrMissingProductType === ExchangeAddProductModalComponent.missingProductKey,
      orderedProductId: get(this, 'selectedOrderedProduct.id'),
      quantity: this.quantity,
      specifySerialNumbers,
      serialNumbers: this.serialNumbers,
      authorizationReason: this.selectedAuthorizationReason,
      requiredSolution: this.selectedRequiredSolution,
      compensationAmount: this.showDamagedProductGiveCompensationBlock
        ? this.compensationInfo.compensationAmount
        : null,
      compensationAmountRate: this.showDamagedProductGiveCompensationBlock
        ? this.compensationInfo.compensationAmountRate
        : null,
      remark: this.remark,
      netUnitPrice: this.deliveredProductPrices.netTotalPrice,
      vat: this.deliveredProductPrices.vat,
      grossUnitPrice: this.deliveredProductPrices.grossUnitPrice,
      netTotalPrice: this.deliveredProductPrices.netTotalPrice,
      grossTotalPrice: this.deliveredProductPrices.grossTotalPrice,
    };
  }

  public submitProduct(validate: boolean = false): void {
    if (validate) {
      if (!this.validatePositionData()) { return; }
    }

    const request$ = this.data.position
      ? this.exchangeApiService.editExchangePosition(this.data.exchangeId, this.getPositionRequestBody(), this.data.position.id)
      : this.exchangeApiService.addExchangePosition(this.data.exchangeId, this.getPositionRequestBody());

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => this.dialogRef.close(res));
  }

  get showReplaceProductBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.WRONG_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.REPLACE_PRODUCT
    );
  }

  get showWrongProductMoneyReturnBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.WRONG_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.MONEY_RETURN
    );
  }

  get showMissingProductMoneyReturnBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.MISSING_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.MONEY_RETURN
    );
  }

  get showDamagedProductMoneyReturnBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.MONEY_RETURN
    );
  }

  get showProductDoesntFitMoneyReturnBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.PRODUCT_DOESNT_FIT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.MONEY_RETURN
    );
  }

  get showDamagedProductReplaceProductBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.REPLACE_PRODUCT
    );
  }

  get showDamagedProductGiveCompensationBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.DAMAGED_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.GIVE_COMPENSATION
    );
  }

  get showSellDeliveredProductBlock(): boolean {
    return (
      // (
      //   this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.WRONG_PRODUCT &&
      //   this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.SELL_DELIVERED_PRODUCT
      // ) || (
        this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT &&
        this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.SELL_PRODUCT
      // )
    );
  }

  get showProductReturnBlock(): boolean {
    return (
      this.selectedAuthorizationReason === ExchangeAuthorizationReasonEnum.UNEXPECTED_PRODUCT &&
      this.selectedRequiredSolution === ExchangeRequiredSolutionEnum.PRODUCT_RETURN
    );
  }

  get refundAmount(): number {
    if (!this.selectedOrderedProduct || !this.quantity) { return null; }
    return this.selectedOrderedProduct.grossUnitPrice * this.quantity;
  }

  get refundAmountDeliveredProduct(): number {
    if (!this.selectedDeliveredProduct || !this.quantity) { return null; }
    return this.selectedDeliveredProduct.grossUnitPrice * this.quantity;
  }

  get estimatedProfit(): number {
    if (
      !this.quantity ||
      !get(this, 'deliveredProductPriceInfo.purchase_data.wa_purchase_price') ||
      !get(this, 'deliveredProductPrices.netTotalPrice')
    ) {
      return null;
    }

    return this.deliveredProductPrices.netTotalPrice - (this.deliveredProductPriceInfo.purchase_data.wa_purchase_price *  this.quantity);
  }

  get maxQuantity(): number {
    if (this.unexpectedOrMissingProduct) { return null; }

    if (this.selectedDeliveredProduct) {
      return this.selectedDeliveredProduct.shippedQuantity - this.selectedDeliveredProduct.returnedQuantity;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

