import { Component, Inject } from '@angular/core';
import { FormArray, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { FileUploadParams } from '../../../../models/file-upload-params.model';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { BaseModalComponent, greaterThanZero } from '../../../rnpl-common';
import { ToasterService } from '../../../ui-components/toaster';
import { FileService } from '../../../../services/file.service';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';

@Component({
  selector: 'rnpl-pack-up-shipment-modal',
  templateUrl: './pack-up-shipment-modal.component.html'
})
export class PackUpShipmentModalComponent extends BaseModalComponent {

  public formBoxes: FormArray = new FormArray([
    new FormControl(null, [greaterThanZero()])
  ]);

  readonly printDnRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  readonly printOINRequest$: {[key: number]: BehaviorSubject<boolean>} = {};

  constructor(
    public dialogRef: MatDialogRef<PackUpShipmentModalComponent>,
    public toasterService: ToasterService,
    public deliveryNoteApiService: DeliveryNoteApiService,
    public invoiceApiService: InvoiceApiService,
    private readonly fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveryNoteId: number,
      isAuthorizedDeliveryService: boolean,
      outgoingInvoices: {
        id: number;
        runpleId: string;
      }[]
    }
  ) {
    super(toasterService);

    if (this.data.outgoingInvoices && this.data.outgoingInvoices.length) {
      this.data.outgoingInvoices.forEach(doc => {
        this.printOINRequest$[doc.id] = new BehaviorSubject<boolean>(null);
      });
    }

    if (this.data.isAuthorizedDeliveryService) {
      this.formBoxes.controls.forEach(c => c.setValidators([Validators.required, greaterThanZero()]));
    }
  }

  public removeBoxControl(controlIndex: number): void {
    this.formBoxes.removeAt(controlIndex);
  }

  public addBoxControl(): void {
    const validators: ValidatorFn[] = this.data.isAuthorizedDeliveryService
      ? [Validators.required, greaterThanZero()]
      : [greaterThanZero()];

    this.formBoxes.push(new FormControl(null, validators));
  }

  public printDN(): void {
    if (this.printDnRequest$.getValue()) { return; }
    this.printDnRequest$.next(true);

    this.deliveryNoteApiService.getDeliveryNoteExportParams(this.data.deliveryNoteId)
      .pipe(
        switchMap((fileParams: FileUploadParams) => this.fileService.printPdfFile(fileParams.url)),
        finalize(() => this.printDnRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  public printOIN(invoiceId: number): void {
    if (this.printOINRequest$[invoiceId].getValue()) { return; }
    this.printOINRequest$[invoiceId].next(true);
    this.invoiceApiService.getInvoicesExportParams(invoiceId)
      .pipe(
        switchMap((fileParams: FileUploadParams) => this.fileService.printPdfFile(fileParams.url)),
        finalize(() => this.printOINRequest$[invoiceId].next(false)),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  public packUp(): void {

    this.formBoxes.markAllAsTouched();
    this.formBoxes.updateValueAndValidity();

    if (this.formBoxes.invalid) { return; }

    const boxesWeights = this.formBoxes.getRawValue()
      .filter(weight => !!weight || weight === 0)
      .map(weight => ({
        weight
      }));

    if (boxesWeights.length) {
      this.dialogRef.close(boxesWeights);
    } else {
      this.dialogRef.close([{weight: null}]);
    }
  }

}

