import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { FilterModel } from '../models/filter.model';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { BinLocationsTabsEnum } from '../enums';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { FileUploadParams } from '../../../../../../common/src/models/file-upload-params.model';
import { LoadBinLocationsList } from '../store/actions/bin-locations.actions';
import { AppState } from '../../store/state/app.state';
import { selectCompanyProfile } from '../../administration/store/selectors';
import { CompanyProfile } from '../../administration/models/company-profile.model';
import { AdministrationsApiService } from '../../administration/services/administrations-api.service';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class BinLocationService {

  private readonly apiEndpoint: string = '/warehouse';

  public companyProfile: CompanyProfile;

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private http: HttpClient,
    private administrationsApiService: AdministrationsApiService,
    private readonly toasterService: ToasterService,
    private readonly store: Store<AppState>,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((companyProfile: CompanyProfile) => {
        this.companyProfile = companyProfile;
      });
  }

  public getBinLocations(
    location: string,
    // filter: FilterModel = {nameColumn: 'status', value: 'active'},
    page = '1',
    per_page = '100',
    sort: FilterModel = {nameColumn: 'binLocation', value: DEFAULT_SORT_DIRECTION}
  ): Observable<any> {
    return this.http.get(this.apiUrl(`/${location}/storage-objects`), {
      params: {
        page,
        per_page,
        [`sort[${sort.nameColumn}]`]: sort.value,
        // [`filter[${filter.nameColumn}]`]: filter.value
      }
    })
    .pipe(tap((data: ResponseList<any>) => {
      this.store.dispatch(LoadBinLocationsList({
        binLocationsListData: {
          [data.pagination.page]: {
            pagination: data.pagination,
            sort,
            data: data.data
          }
        },
        location: location as BinLocationsTabsEnum
      }));
    }));
  }

  public getBinLocationsCounters(): Observable<any> { // todo type
    return this.http.get<ResponseModel<any>>(this.apiUrl('/storage-objects/counters'))
      .pipe(map((data: ResponseModel<any>) => data.data));
  }

  public createNewStorageObject(location: BinLocationsTabsEnum, storageObject): Observable<any> { // todo type
    const body = { ...storageObject };
    return this.http.post<ResponseModel<any>>(this.apiUrl(`/${location}/storage-objects`), body)
      .pipe(
        map((data: ResponseModel<any>) => data.data),
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public changeBinLocationStatus(status: string, ids: number[]): Observable<any> {
    const body = {
      status,
      ids
    };

    return this.http.patch(this.apiUrl('/storage-objects/status'), body)
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteBinLocations(ids: number[]): Observable<any> {
    const body = { ids };

    return this.http.request('delete', this.apiUrl('/storage-objects'), { body })
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/storage-objects/csv`),
      type: 'zip'
    };
    return of(fileParams);
  }

  public getRackFiltersList(location: string): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/${location}/storage-objects/filters/rack`)
      .pipe(
        map((data: ResponseModel<any[]>) => data.data)
      )
  }

  public getShelfList(location: string): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/${location}/storage-objects/filters/shelf`)
      .pipe(
        map((data: ResponseModel<any[]>) => data.data)
      )
  }

  public getBinList(location: string): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(`${this.apiEndpoint}/${location}/storage-objects/filters/bin`)
      .pipe(
        map((data: ResponseModel<any[]>) => data.data)
      )
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse): void {
    switch (error.error.message) {
  //     case 'anotherUserEditError':
  //     {
  //       const dialog = this.dialog.open(WarningModalComponent, {
  //         data: getAnotherUserEditErrorModalData({
  //           document: error.error.data.entityName,
  //           userName: error.error.data.userName,
  //         })
  //       });
  //
  //       dialog.afterClosed().subscribe(res => {
  //         if (res === CommonModalsActionsEnum.CONFIRM) {
  //           this.warehouseSetEdit(true).subscribe();
  //         }
  //       });
  //     }
  //       break;
      default:
        this.showMsg('error', error.error.message);
        break
    }
  }
}
