import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ColumnTable } from '../../models/column-model';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-column-header-text-with-remove-button',
  templateUrl: './column-header-text-with-remove-button.component.html',
  styleUrls: ['./column-header-text-with-remove-button.component.scss']
})
export class ColumnHeaderTextWithRemoveButtonComponent implements OnChanges {

  @Input() column: ColumnTable;

  @Output() removeColumnClick: EventEmitter<ColumnTable> = new EventEmitter<ColumnTable>();

  public disabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('column')) {
      this.disabled = get(this.column, 'disabled', false);
    }
  }

  public onClickRemoveButton(column: ColumnTable): void {
    if (this.disabled) { return; }
    this.removeColumnClick.emit(column);
  }

}
