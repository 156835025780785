import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ReplaySubject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, map, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { Store, select } from '@ngrx/store';

import { CreateNewBatchModalComponent } from 'common/src/modules/modals/modals-purchase-order';
import { BatchDataModel } from './models/batch-data.model';
import { PurchaseOrder } from '../../../models';
import { getBatches, TemplateBatch } from './batches.config';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { BatchModel } from '../../../models/purchase-order/batch.model';
import { UpdatePO } from '../../../store/actions/po.actions';
import { purchaseOrderSelector, batchesListSelector } from '../../../store/selectors/po.selectors';

@Component({
  selector: 'rnpl-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})
export class BatchesComponent implements OnInit { // todo: refactor

  public batches$: Observable<TemplateBatch[]>;
  public purchaseOrderData: BatchDataModel;
  public positions: number;
  public batchId: string;
  public isCreateButtonActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private readonly dialog: MatDialog,
    private store: Store<AppState>
  ) {

  }

  ngOnInit() {
    this.batches$ = this.store
      .pipe(
        select(batchesListSelector),
        tap(batches => this.refreshCreateButton(batches)),
        map(batches => getBatches(this, batches)),
        takeUntil(this._destroy)
      );

    this.store
      .pipe(
        select(purchaseOrderSelector),
        takeUntil(this._destroy)
      )
      .subscribe(purchase => {
        this.positions = this.getCounters(purchase.positions);
        this.batchId = this.getBatchId(purchase.runpleId);
        this.refreshPoData(purchase);
      });
  }

  public getCounters(positions: any[]): number { // todo: model
    let counter = 0;
    positions.forEach(position => counter += position.quantity);
    return counter;
  }

  // '01' - its number batch, future count
  public getBatchId(poId): string {
    return poId ? (poId + '-01') : '01';
  }

  public refreshPoData(purchase: PurchaseOrder): void {
    this.purchaseOrderData = {
      id: get(purchase, 'id'),
      runpleId: get(purchase, 'runpleId'),
      vendor_name: get(purchase, 'vendor.corporate_partner.name'),
      delivery_address: get(purchase, 'logistic.delivery_to_address', []),
    };
  }

  public refreshCreateButton(batches: BatchModel[]): void {
    (Array.isArray(batches) && batches.length)
      ? this.isCreateButtonActive$.next(false)
      : this.isCreateButtonActive$.next(true);
  }

  public createNewBatch(): void {
    const dialogRef = this.dialog.open(CreateNewBatchModalComponent, {
      data: {...this.purchaseOrderData}, disableClose: true,
    });

    dialogRef.afterClosed()
      .subscribe((response: any) => {
        if (response.hasOwnProperty('data')) {
          this.store.dispatch(UpdatePO({purchaseOrder: response.data as PurchaseOrder}));
        }
      });
  }

  public isBatches(): Observable<boolean> {
    return this.batches$.pipe(
      map(batches => {
        let isBatchExist = false;

        batches.map(batch => {
          if (batch.count) {
            isBatchExist = true;
          }
        });

        return isBatchExist;
      }),
    );
  }

}
