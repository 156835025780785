import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { FilterModel } from '../warehouse/models/filter.model';
import { ResponseList, ResponseModel } from '../shared/models/response';
import { UIStatesEnum, UserModel } from 'common/src/models';
import { UserListTabs } from './pages/user-list/enums/user-list-tabs.enum';
import { AppState } from '../store/state/app.state';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount, LoadLoggedUser,
  LoadTeamsList,
  LoadUser,
  UpdateShouldRefreshEntity,
  UpdateUserCurrentState
} from './store/actions/teams.actions';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { UserProfileModel, UserShortcutsModel } from './models';
import { DisplayToaster } from '../shared/decorators/toaster';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { FormInputChangedModel } from '../shared/models/form-input-value.model';
import { DEFAULT_SORT_DIRECTION } from '../shared/constants';
import { HintsModel } from './models/hints-list.model';
import { UserShortcutEnum } from './enums';
import { LoadUserQuickActionsSuccess, LoadUserShortcutsSuccess } from '../store/actions/shared.actions';
import { ControlCenterModuleTypeEnum, ControlCenterTabNameEnum } from '../control-center/control-center.model';
import { OptionModel } from '../../../../../common/src/modules/dynamic-forms/controls/multiple-control.component';
import { UsersFiltersListModel } from './models/users-filters-list.model';
import { AuthService } from '../../../../../common/src/auth/auth.service';

@Injectable()
export class HrmService {

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private authService: AuthService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private readonly store: Store<AppState>,
  ) {
  }

  public getUsers(
    status: UserListTabs,
    page = '1',
    per_page = '100',
    sort: FilterModel = {nameColumn: 'status', value: DEFAULT_SORT_DIRECTION},
    ): Observable<ResponseList<UserModel>> {
    return this.http.get<ResponseList<UserModel>>(
      '/users',
      { params: {
        page,
        per_page,
        [`filters[status]`]: status},
        // [`sort[${sort.nameColumn}]`]: sort.value
      }
    );
  }

  public getUsersV2(
    status: UserListTabs,
    page = '1',
    per_page = '100',
    sort: FilterModel = {nameColumn: 'status', value: DEFAULT_SORT_DIRECTION},
    filters: any = {},
  ): Observable<ResponseList<UserModel>> {
    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<UserModel>>('/v2/users', { params })
      .pipe(tap((data: ResponseList<UserModel>) => {
        this.store.dispatch(LoadTeamsList({
          teamsListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data,
              totals: data.totals
            }
          },
          status,
          page: data.pagination.page
        }));
      }));
  }

  public getSubscriptionActivationUsers(): Observable<UserModel[]> {
    return this.http.get<ResponseModel<UserModel[]>>('/v2/users/subscription-activation/manage')
      .pipe(map((response: ResponseModel<UserModel[]>) => response.data));
  }

  public getUsersCounters(): Observable<any> {
    // return this.http.get('/v2/users/counters')
    return this.http.get('/users/counters')
      .pipe(
        map((response: any) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getUserProfileAndBlueprints(userId: number): Observable<any> {
    return this.http.get(`/users/${userId}/profile`).pipe(map((response: any) => response.data));
  }

  public getUserProfileAndBlueprintsV2(
    userId: number,
    setToStoreAsUser = true,
    setToStoreAsLoggedUser = false
  ): Observable<UserProfileModel> {
    return this.http.get(`/v2/users/${userId}/profile`)
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          if (setToStoreAsUser) {
            this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
            this.store.dispatch(LoadUser({ user: response.data }));
          }
          if (setToStoreAsLoggedUser) {
            this.store.dispatch(LoadLoggedUser({ loggedUser: response.data }));
            this.authService.updatePermissions(response.data.roleAndPermissions.permissions);
          }
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data)
      );
  }

  public getUserPredefinedPermissions(department: string, position: string): Observable<any> {
    const params = {
      department,
      position,
    };
    return this.http.get('/v2/users/permissions/department/position', { params })
      .pipe(
        map((response: ResponseModel<any>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        }),
      );
  }

  public getCreateUserBlueprint(): Observable<any> {
    return this.http.get('/entities-library/forms/user_employee_signup/blueprint').pipe(map((response: any) => response.data));
  }

  public createUser(userData, skipWarnings = false): Observable<UserProfileModel> {
    return this.http.request('post', '/v2/users/employee', {body: {...userData, skipWarnings}})
      .pipe(
        map((response: ResponseModel<UserProfileModel>) => response.data),
      );
  }

  public deleteUser(userId: number): Observable<any> {
    return this.http.request('delete', `/v2/users/${userId}`)
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public deleteUsers(ids: number[]): Observable<any> {
    return this.http.request('delete', '/v2/users', { body: { ids } })
      .pipe(
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public changMyProfilePassword(params: {
    password: string,
    newPassword: string,
    newPasswordConfirm: string,
  }): Observable<UserProfileModel> {
    return this.http.request('patch', '/v2/users/self/password', { body: params })
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({ user: response.data }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
      );
  }

  public changMyProfileEmail(params: {email: string, password: string}): Observable<UserProfileModel> {
    return this.http.request('patch', '/v2/users/self/email', { body: params })
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({ user: response.data }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public cancelMyProfileEmailChange(): Observable<UserProfileModel> {
    return this.http.request('post', '/v2/users/self/email/cancel-update')
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({ user: response.data }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public changeUsersStatus(params: {user_ids: number[], status: string, skipWarnings}): Observable<any> {
    return this.http.request('patch', '/v2/users/status', { body: params });
  }

  public changeUserStatus(userId: number, status: string, skipWarnings = false): Observable<UserProfileModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch(`/v2/users/${userId}/status`, { status, skipWarnings })
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({user: response.data}));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      );
  }

  public updateUserProfile(userId: number, field: FormInputChangedModel): Observable<UserProfileModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<UserProfileModel>>(`/v2/users/${userId}`, field)
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({user: response.data}));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, userId);
          return throwError(error);
        })
      );
  }

  public updateUserControlCenterNavigation(
    userId: number,
    controlCenterModule: ControlCenterModuleTypeEnum,
    controlCenterTab: ControlCenterTabNameEnum
  ): Observable<UserProfileModel> {
    return this.http.patch<ResponseModel<UserProfileModel>>(`/v2/users/${userId}/control-center/navigation`, {controlCenterModule, controlCenterTab})
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadLoggedUser({ loggedUser: response.data }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, userId);
          return throwError(error);
        })
      );
  }

  public userProfileSetEdit(userId: number, force = false): Observable<UserProfileModel> {
    const body = { force };
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<UserProfileModel>>(`/v2/users/${userId}/locking`, body)
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({ user: response.data }));
          this.store.dispatch(UpdateUserCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, userId);
          return throwError(error);
        })
      );
  }

  public userProfileUnsetEdit(userId: number): Observable<UserProfileModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<UserProfileModel>>('put', `/v2/users/${userId}/unlocking`)
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({ user: response.data }));
          this.store.dispatch(UpdateUserCurrentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public updateUserPermissionsV2(
    userId: number,
    module: string,
    permission: string,
    value: boolean,
  ): Observable<UserProfileModel> {
    const body = {
      module,
      permission,
      value
    }
    return this.http.patch<ResponseModel<UserProfileModel>>(`/v2/users/${userId}/permissions`, body)
      .pipe(
        tap((response: ResponseModel<UserProfileModel>) => {
          this.store.dispatch(LoadUser({ user: response.data }));
        }),
        map((response: ResponseModel<UserProfileModel>) => response.data)
      );
  }

  public getHintsByPage(
    screenName: string,
  ): Observable<ResponseModel<HintsModel>> {
    const params = new HttpParams().set('screenName', screenName.toString());

    return this.http.get<ResponseModel<HintsModel>>(
      '/users/hints',
      { params }
    );
  }

  public setViewedHint(
    id: number,
    screenName
  ): Observable<ResponseModel<HintsModel>> {
    const params = new HttpParams().set('screenName', screenName.toString());

    return this.http.post<ResponseModel<HintsModel>>(`/users/hints/${id}/view`, null, {params});
  }

  public skipHints(
    screenName,
    ids: number[]
  ): Observable<ResponseModel<HintsModel>> {
    const params = new HttpParams().set('screenName', screenName.toString());

    return this.http.post<ResponseModel<HintsModel>>(`/users/hints/skip`, {ids}, {params});
  }


  public skipAllHints(): Observable<ResponseModel<HintsModel>> {
    return this.http.post<ResponseModel<HintsModel>>(`/users/hints/all/skip`, null);
  }

  public skipRepeatHints(screenName): Observable<ResponseModel<HintsModel>> {
    const params = new HttpParams().set('screenName', screenName.toString());

    return this.http.post<ResponseModel<HintsModel>>(`/users/hints/repeat/skip`, null, {params});
  }

  @DisplayToaster({showErrorMessage: true})
  public getPOListExport(status: UserListTabs): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `/v2/users/employee/${status}/csv`,
      type: 'zip',
    };
    return of(fileParams);
  }

  public getUserModulesShortcuts(userId: number): Observable<UserShortcutsModel> {
    return this.http.get<ResponseModel<UserShortcutsModel>>(`/users/${userId}/shortcuts`)
      .pipe(
        tap((res: ResponseModel<UserShortcutsModel>) => {
          this.store.dispatch(LoadUserShortcutsSuccess({userShortcuts: res.data}));
        }),
        map((res: ResponseModel<UserShortcutsModel>) => res.data)
      );
  }

  public updateUserModulesShortcuts(userId: number, shortcuts: UserShortcutEnum[]): Observable<UserShortcutsModel> {
    return this.http.patch<ResponseModel<UserShortcutsModel>>(`/users/${userId}/shortcuts`, {shortcuts})
      .pipe(
        tap((res: ResponseModel<UserShortcutsModel>) => {
          this.store.dispatch(LoadUserShortcutsSuccess({userShortcuts: res.data}));
        }),
        map((res: ResponseModel<UserShortcutsModel>) => res.data)
      );
  }

  public getUserModulesQuickActions(userId: number): Observable<UserShortcutsModel> {
    return this.http.get<ResponseModel<UserShortcutsModel>>(`/users/${userId}/quick-actions`)
      .pipe(
        tap((res: ResponseModel<UserShortcutsModel>) => {
          this.store.dispatch(LoadUserQuickActionsSuccess({userQuickActions: res.data}));
        }),
        map((res: ResponseModel<UserShortcutsModel>) => res.data)
      );
  }

  public updateUserModulesQuickActions(userId: number, quickActions: UserShortcutEnum[]): Observable<UserShortcutsModel> {
    return this.http.patch<ResponseModel<UserShortcutsModel>>(`/users/${userId}/quick-actions`, {quickActions})
      .pipe(
        tap((res: ResponseModel<UserShortcutsModel>) => {
          this.store.dispatch(LoadUserQuickActionsSuccess({userQuickActions: res.data}));
        }),
        map((res: ResponseModel<UserShortcutsModel>) => res.data)
      );
  }

  public getDepartmentsList(): Observable<OptionModel[]> {
   return this.http.get<ResponseModel<OptionModel[]>>('/users/employee/departments')
     .pipe(map((data) => {
       return data.data.filter(department => department.value !== 'unspecified');
     }));
  }

  public getPositionsList(): Observable<OptionModel[]> {
   return this.http.get<ResponseModel<OptionModel[]>>('/users/employee/positions')
     .pipe(map((data) => {
       return data.data.filter(position => position.value !== 'unspecified');
     }));
  }

  public getRunpleIdsList(): Observable<UsersFiltersListModel[]> {
   return this.http.get<ResponseModel<UsersFiltersListModel[]>>('/v2/users/filters/list')
     .pipe(map((data) => data.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, userId?: number): void {
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          )
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.userProfileSetEdit(userId, true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('COLUMN.EMPLOYEE');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdateUserCurrentState({ currentState: UIStatesEnum.VIEW }));
        this.getUserProfileAndBlueprintsV2(userId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message);
        break
    }
  }
}
