export const FamilyAttrsPageColumnsList = [{
  cellTemplate: 'numberRows',
  cellClass: 'center',
  name: 'Nr.',
  width: 40,
  maxWidth: 40,
  sortable: false,
  resizeable: false,
  headerClass: 'center'

}, {
  cellTemplate: 'primary',
  name: 'Attribute name',
  prop: 'name',
  sortable: false,
  resizeable: false,
}, {
  cellTemplate: 'primary',
  name: 'UI control',
  prop: 'control',
  sortable: false,
  resizeable: false,
}, {
  cellTemplate: 'withDate',
  name: 'Created',
  prop: 'created_at',
  sortable: false,
  resizeable: false,
}, {
  cellTemplate: 'withStatusLabel',
  name: 'Type',
  prop: 'type',
  width: 96,
  maxWidth: 96,
  sortable: false,
  resizeable: false,
}, {
  cellTemplate: 'primary',
  name: 'Attribute id',
  prop: 'id',
  sortable: false,
  resizeable: false,
}
];