import { SendEmailStatusEnum, UIStatesEnum, UserModel } from '../../../../models';

export interface InfoItemModel {
  label: string;
  value: string | Date | number;
  maxValue?: string | Date | number; // STATUS_LINE, STATUS_LINE_DATE
  statusLineType?: string; // STATUS_LINE
  isEndless?: boolean; // STATUS_LINE
  type?: InfoItemType;
  dateType?: string;
  icon?: string;
  iconColor?: string;
  dateFormat?: string;
  routerLink?: string | string[]; // WITH_LINK, WITH_PARTNER
  runpleId?: string; // WITH_LINK
  statusIcon?: string; // WITH_STATUS
  currencyType?: string; // WITH_CURRENCY
  currencyConversion?: boolean; // WITH_CURRENCY
  valueClasses?: string;
  postfix?: string;
  prefix?: string;
  percents?: number | string;
  tableItem?: boolean;
  dueWithinDays?: number | string;
  expectedDate?: Date;
  showWithStatuses?: any[];
  showWithUIState?: UIStatesEnum[];
  user?: UserModel;
  partnerExist?: boolean;
  currency?: string;
  colorState?: string;
  emailSentStatus?: SendEmailStatusEnum; // EMAIL
  isVisible?: boolean;
  hideProgress?: boolean;
  avatarUrl?: string;
  statusLineLabelRight?: any;
  inverse?: boolean;
  unitType?: string;
  unitTypeShortcut?: boolean;
}

export enum InfoItemType {
  PRIMARY,
  WITH_STATUS,
  WITH_LINK,
  WITH_EXTERNAL_LINK,
  WITH_CURRENCY,
  WITH_CURRENCY_REAL_VALUE,
  WITH_CURRENCY_AND_PERCENTS,
  WITH_DATE_PRIMARY,
  WITH_DATE_DAYS,
  STATUS_LINE,
  STATUS_LINE_VAL_ONLY,
  STATUS_LINE_MAX_VAL_ONLY,
  STATUS_LINE_DATE,
  CLICK_OUTPUT,
  EMAIL,
  WITH_USER,
  WITH_VAT_STATUS,
  WITH_USER_NAME,
  WITH_PARTNER,
  WITH_DELIVERY_SERVICE,
  WITH_NUMBER
}
