import { createAction, props } from '@ngrx/store';
import { RootLinkState } from '../reducers/link.reducer';

export const SaveLink = createAction(
  '[Links Store] Save link',
  props<{ linkState:  RootLinkState}>()
);

export const RemoveLastLink = createAction(
  '[Links Store] Remove Last Link'
);

export const ClearLinkQueue = createAction(
  '[Links Store] Clear Links Queue'
);