import { DocumentTypesUppercaseEnum } from 'common/src/modules/modals/modals-common/link-document-modal/enums/ducument-types.enum';

export interface DocumentNumberModel {
  currentSettings: DocumentNumberSettingsModel;
  documentType: DocumentTypesUppercaseEnum
  forwardSettings: DocumentNumberSettingsModel;
  id: number;
  updated: boolean;
}

export interface DocumentNumberSettingsModel {
  firstPrefix: DocumentNumberPrefixModel;
  secondPrefix: DocumentNumberPrefixModel;
  length: number;
  documentNameConfiguration: {
    type: 'CUSTOM'|'DEFAULT';
    name: string;
  };
  mode: DocumentNumberModeEnum;
  reset: DocumentNumberResetEnum;
  continueExistingNumberSequence: boolean;
  number: number;
}

export interface DocumentNumberPrefixModel {
  separator: DocumentNumberPrefixSeparatorEnum;
  type: DocumentNumberPrefixTypeEnum;
  value: string;
}

export enum DocumentNumberModeEnum {
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  NONE = 'NONE',
}

export enum DocumentNumberResetEnum {
  ANNUALLY = 'ANNUALLY',
  // MONTHLY = 'MONTHLY',
  DAILY = 'DAILY',
  NEVER = 'NEVER'
}

export enum DocumentNumberPrefixSeparatorEnum {
  NONE ='NONE',
  // SPACE ='SPACE',
  DASH ='DASH',
  SLASH ='SLASH',
  COLON ='COLON',
  DOT ='DOT',
}

export enum DocumentNumberPrefixTypeEnum {
  USER_DEFINED = 'USER_DEFINED',
  // DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  DATE = 'DATE',
  YEAR = 'YEAR',
  // CUSTOM_DATE = 'CUSTOM_DATE',
}

export enum DocumentNumberDateFormatEnum {
  DDMMYYYY = 'ddMMyyyy',
  DDMMYY = 'ddMMyy',
  YYYMMDD = 'yyyyMMdd',
  YYMMDD = 'yyMMdd',
  YY = 'yy',
  YYYY = 'yyyy',
}
