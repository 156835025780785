import { OnInit } from '@angular/core';


import { RnplModuleService } from '../../services/rnpl-module/rnpl-module.service';


export abstract class ModuleOutlet implements OnInit {

  appName: string;
  moduleService: RnplModuleService;

  ngOnInit(): void {
    this.moduleService.moduleOpened(this.appName);
  }
}
