import { createReducer, on } from '@ngrx/store';

import { PaginationModel, UIStatesEnum } from 'common/src/models';
import { CollectionSubscriptionActions } from '../actions/action-types';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { SubscriptionListModel, SubscriptionModel } from '../../models/subscription.model';
import { SalesPositionsByProductType } from '../../models/subscription-position.model';
import { ResponseModel } from '../../../shared/models/response';
import { FilterModel } from '../../../warehouse/models/filter.model';
import { SubscriptionListTabsEnum } from '../../enums';

export const SubscriptionFeatureKey = 'subscriptionStore';

export interface SubscriptionListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModel;
    data: SubscriptionListModel[];
  }
}
export interface SubscriptionsState {
  [SubscriptionListTabsEnum.DRAFT]?: SubscriptionListState;
  [SubscriptionListTabsEnum.OPEN]?: SubscriptionListState;
  [SubscriptionListTabsEnum.PAUSED]?: SubscriptionListState;
  [SubscriptionListTabsEnum.COMPLETED]?: SubscriptionListState;
  [SubscriptionListTabsEnum.CANCELED]?: SubscriptionListState;
  [SubscriptionListTabsEnum.DELETED]?: SubscriptionListState;
  [SubscriptionListTabsEnum.TEMPLATE]?: SubscriptionListState;
}

export interface SubscriptionStore {
  entity: SubscriptionState;
  list: SubscriptionsState;
  blocksValidations: SubscriptionsBlocksValidationsState;
}

export interface SubscriptionState {
  subscription: SubscriptionModel;
  currentState: UIStatesEnum;
  countries: CountryModel[];
  positions: ResponseModel<SalesPositionsByProductType>;
  positionsCount: number;
  loadingRequestsCount: number;
  updatedAt: Date;
  isShouldRefresh: boolean;
}

export const initialSubscriptionState: SubscriptionState = {
  subscription: null,
  countries: [],
  currentState: UIStatesEnum.CREATE,
  positions: null,
  positionsCount: 0,
  loadingRequestsCount: 0,
  updatedAt: null,
  isShouldRefresh: false
};

export const initialSubscriptionsState: SubscriptionsState = {
  [SubscriptionListTabsEnum.DRAFT]: {},
  [SubscriptionListTabsEnum.OPEN]: {},
  [SubscriptionListTabsEnum.PAUSED]: {},
  [SubscriptionListTabsEnum.COMPLETED]: {},
  [SubscriptionListTabsEnum.CANCELED]: {},
  [SubscriptionListTabsEnum.DELETED]: {},
  [SubscriptionListTabsEnum.TEMPLATE]: {},
};

export interface SubscriptionsBlocksValidationsState {
  // propertiesBlockValid: boolean;
  durationBlockValid: boolean;
  billingInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialSubscriptionBlocksValidationsState: SubscriptionsBlocksValidationsState = {
  // propertiesBlockValid: false,
  durationBlockValid: false,
  billingInfoBlockValid: false,
  positionsBlockValid: false,
};

export function SubscriptionBlocksValidationsReducer(state, action) {
  return _SubscriptionBlocksValidationsReducer(state, action);
}

const _SubscriptionBlocksValidationsReducer = createReducer(
  initialSubscriptionBlocksValidationsState,
  // on(CollectionSubscriptionActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
  //   ...state,
  //   propertiesBlockValid
  // })),
  on(CollectionSubscriptionActions.UpdateDurationBlockValid, (state, { durationBlockValid }) => ({
    ...state,
    durationBlockValid
  })),
  on(CollectionSubscriptionActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionSubscriptionActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
);

export function SubscriptionsListReducer(state, action) {
  return _SubscriptionsListReducer(state, action);
}

const _SubscriptionsListReducer = createReducer(
  initialSubscriptionsState,
  on(CollectionSubscriptionActions.LoadSubscriptionsList, (state, { subscriptionsListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...subscriptionsListData
    },
  })),
);

export function SubscriptionReducer(state, action) {
  return _SubscriptionReducer(state, action);
}

const _SubscriptionReducer = createReducer(
  initialSubscriptionState,
  // Subscription
  on(CollectionSubscriptionActions.LoadSubscription, (state, { subscription }) => ({
    ...state,
    subscription
  })),
  on(CollectionSubscriptionActions.ClearSubscription, (state) => ({
    ...state,
    subscription: null
  })),
  on(CollectionSubscriptionActions.ClearSubscriptionState, (state) => ({
    ...state,
    subscription: null,
    currentState: UIStatesEnum.CREATE,
    positions: null,
    positionsCount: 0,
    loadingRequestsCount: 0,
    updatedAt: null
  })),
  on(CollectionSubscriptionActions.ClearSubscriptionPositions, (state) => ({
    ...state,
    positions: null,
  })),
  on(CollectionSubscriptionActions.UpdateSubscription, (state, { subscription }) => ({
    ...state,
    subscription
  })),
  on(CollectionSubscriptionActions.UpdateSubscriptionSummary, (state, { summary }) => ({
    ...state,
    subscription: {
      ...state.subscription,
      summary
    }
  })),
  on(CollectionSubscriptionActions.LoadSubscriptionPositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionSubscriptionActions.UpdateSubscriptionPositionsCount, (state, { positionsCount }) => ({
    ...state,
    positionsCount
  })),
  on(CollectionSubscriptionActions.UpdateSubscriptionStatus, (state, { status}) => ({
    ...state,
    subscription: {
      ...state.subscription,
      status: status
    }
  })),
  on(CollectionSubscriptionActions.UpdateSubscriptionUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt
  })),
  on(CollectionSubscriptionActions.LoadSubscriptionCountries, (state, {countries}) => ({
    ...state,
    countries
  })),
  on(CollectionSubscriptionActions.UpdateSubscriptionCurrentState, (state, { currentState }) => ({
    ...state,
    currentState: currentState
  })),
  on(CollectionSubscriptionActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1
  })),
  on(CollectionSubscriptionActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1
  })),
  on(CollectionSubscriptionActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),

);
