import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import {
  ActionsModel,
  CreateDocumentStepsModel,
  DocumentToDoListModel,
  ViewDocumentListModel
} from '../../../models/view-document-list.model';
import { DocumentPreviewTabsEnum } from '../../../models/document-preview-tabs.enum';
import { DocumentTypeEnum } from '../document-steps/document-step.model';
import { VatStatusEnum } from '../../../models';

@Component({
  selector: 'rnpl-view-document',
  templateUrl: './view-document-container.component.html',
  styleUrls: ['./view-document-container.component.scss']
})
export class ViewDocumentContainerComponent implements OnInit, AfterViewInit {

  @Input() public anchors: ViewDocumentListModel[];
  @Input() public anchorsInside: boolean = false;
  @Input() public isNarrowScreen: boolean = false;
  @Input() public set tabs(tabs: any[]) {
    // this.filteredTabs = tabs;
    this.filteredTabs = tabs.filter(t => !t.hasOwnProperty('showCondition') || t.showCondition);
  };
  @Input() public activeTab: DocumentPreviewTabsEnum;
  @Input() public showProgressIndicator: boolean = false;
  @Input() public displayCreateDocumentSteps: boolean = false;
  @Input() public displayDocumentStepsTitle: boolean = true;
  @Input() public documentTitle: ViewDocumentListModel;
  @Input() public customClass: string;
  @Input() public showSummary: boolean = false;
  @Input() public showDocumentBar: boolean = false;
  @Input() public titleCenter: boolean = false;
  @Input() public closeSummary: boolean; // todo: refactor
  @Input() public isReadOnly: boolean = true;
  @Input() public documentId: string;
  @Input() public reverse: boolean;
  @Input() public isOutgoingDocument: boolean = false;
  @Input() public customScrollClass: string;
  @Input() public createDocumentSteps: CreateDocumentStepsModel[];
  @Input() public todoDocumentList: DocumentToDoListModel[];
  @Input() public checkVatRequest$: BehaviorSubject<boolean>;
  @Input() public openSummary: boolean;
  @Input() public chekValidSteps: boolean = false;
  @Input() public showLinkedDocumentsOnly: boolean;

  @Output() actionAnchorEmit: EventEmitter<any> = new EventEmitter();
  @Output() actionEmit: EventEmitter<string> = new EventEmitter();
  @Output() unlinkDocumentEmit: EventEmitter<any> = new EventEmitter();
  @Output() actionWithValueEmit: EventEmitter<any> = new EventEmitter();
  @Output() checkVatEmit: EventEmitter<any> = new EventEmitter();

  public filteredTabs: any[] = [];
  public isInit: boolean = false;
  public documentTypeEnum: typeof DocumentTypeEnum = DocumentTypeEnum;
  public isOpenedDropdown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public fragment;

  public expanded: boolean;
  public isScrolled: boolean;

  public vatStatusEnum: typeof VatStatusEnum = VatStatusEnum;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.anchors && this.anchors.length) {
      this.route.fragment.subscribe(fragment => {
        this.fragment = fragment || this.anchors[0].anchor;
      });
    }

    this.expanded = !this.displayWidthIsLow;

    // setTimeout(() => {
    //   this.isLoading = true
    // }, 10)
  }

  ngAfterViewInit(): void {
    try {
      document.getElementById(this.fragment).scrollIntoView();
    } catch (e) { }

    setTimeout(() => {
      this.isInit = true
    }, 100)

  }

  public goToAnchor(link) {
    this.fragment = link;
    try {
      document.getElementById(link).scrollIntoView();
    } catch (e) { }
  }

  public actionAnchorHandler(action, disabled: boolean = false): void {
    if (!action || disabled) { return; }

    if (this.displayAnchorWithButtonStyle(action)) {
      this.goToAnchor(action);
      return;
    }

    this.actionAnchorEmit.emit(action);
  }

  public checkVatEmitHandler(): void {
    this.checkVatEmit.emit();
  }

  public actionEmitHandler(actionName: string): void {
    this.actionEmit.next(actionName);
  }

  public unlinkDocumentHandler(unlinkData: any): void {
    this.unlinkDocumentEmit.next(unlinkData);
  }

  public actionWithValueEmitHandler(event: any): void {
    this.actionWithValueEmit.next(event);
  }

  public onSectionChange(section: string): void {
    this.fragment = section;
  }

  public showScrollGradient(e) {
    this.isScrolled = e;
  }

  public getMultipleButton(multiButton: ActionsModel): ActionsModel {
    return {
      ...multiButton,
      actions: multiButton.actions.filter(btn => !btn.hideAction)
    };
  }

  get displayWidthIsLow(): boolean {
    return document.documentElement.clientWidth <= 1600;
  }

  public displayAnchorWithButtonStyle(actionName: string): boolean {
    switch (actionName) {
      case 'preferences':
      case 'Documents':
      case 'canceled-positions':
        return true;
      default:
        return false;
    }
  }

  public availableActions(actions: ActionsModel[]): ActionsModel[] {
    if (!actions || !actions.length) { return []; }
    return actions.filter(a => !a.hide);
  }

  public openedDropdownHandler(isOpen: boolean): void {
    this.isOpenedDropdown$.next(isOpen);
  }

}
