import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { FiltersControlsNamesEnum, FiltersGroupModel } from 'common/src/modules/filters';
import { ColumnTypeEnum, DateColumnType, TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TabDefinitionModel } from 'common/src/modules/ui-components/nav-tabs/tab-definition.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { IncomingDeliveryListTabsEnum } from '../../enums';
import {
  HighlightTypeEnum,
  TableSummaryBarItemModel,
  TableSummaryBarItemTypeEnum
} from 'common/src/modules/ui-components/table-summary-bar/table-summary-bar.model';
import { IncomingDeliveryListTotalsModel } from '../../models';
import { FlagsEnum } from 'common/src/models/flags.enum';

export const TABS_ICONS = {
  [IncomingDeliveryListTabsEnum.PENDING]: 'incoming-delivery',
  [IncomingDeliveryListTabsEnum.RECEIVED]: 'goods-receipt',
  [IncomingDeliveryListTabsEnum.WAREHOUSED]: 'warehousing',
};

export function getIncomingDeliveryListFiltersConfig(
  status: IncomingDeliveryListTabsEnum,
  documentsList$: Observable<any>,
  binLocationsList$: Observable<any>,
  batchesList$: Observable<any>,
): FiltersGroupModel[] {
  const filtersConfig: FiltersGroupModel[] = [
    {
      disabled: true,
      filters: [
        {
          label: 'APP.BUSINESS_PARTNER',
          controlName: 'vendorId',
          controlType: FiltersControlsNamesEnum.PARTNER,
          placeholder: 'FORM.SELECT_BUSINESS_PARTNER'
        },
        {
          label: 'POSITIONS.PRODUCT',
          controlName: 'productId',
          controlType: FiltersControlsNamesEnum.PRODUCT,
          // availableProductTypes: [ProductTypes.GOODS, ProductTypes.SERVICES, ProductTypes.DIGITAL]
        },
        {
          label: 'FILTERS.STORAGE_PLACE',
          controlName: 'binLocation',
          bindValue: 'binLocation',
          bindLabel: 'binLocation',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          allowSelectAll: true,
          observableList: binLocationsList$,
          controlClass: 'col-6 mw-272',
          placeholder: 'FILTERS.STORAGE_PLACE',
          showWithStatus: [
            IncomingDeliveryListTabsEnum.WAREHOUSED,
          ]
        },
        {
          label: 'COLUMN.BATCH',
          controlName: 'batchId',
          bindValue: 'id',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          allowSelectAll: true,
          observableList: batchesList$,
          containerClass: 'col-6 mb-0',
          placeholder: 'FORM.SELECT_BATCH',
          showWithStatus: [
            IncomingDeliveryListTabsEnum.RECEIVED,
            IncomingDeliveryListTabsEnum.WAREHOUSED,
          ]
        },
        {
          label: 'COLUMN.EXPIRATION_DATE',
          controlName: 'estimateExpirationDate',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          containerClass: 'col-6',
          placeholder: 'FORM.SELECT_DUE_WITHIN',
          baseDropdownItems: [
            {label: 'FORM.1_DAY', value: 1},
            {label: 'FORM.7_DAYS', value: 7},
            {label: 'FORM.30_DAYS', value: 30},
          ],
          showWithStatus: [
            IncomingDeliveryListTabsEnum.RECEIVED,
            IncomingDeliveryListTabsEnum.WAREHOUSED,
          ]
        },

//         {
//           label: 'FORM.BIN_LOCATIONS',
//           controlName: 'binLocation',
//           bindValue: 'binLocation',
//           bindLabel: 'binLocation',
//           controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
//           allowSelectAll: true,
//           observableList: binLocationsList$,
//           showWithStatus: [
//             IncomingDeliveryListTabsEnum.WAREHOUSED,
//           ]
//         },

      ]
    },
    {
      title: 'DOC_BLOCK.DELIVERY',
      filters: [
        {
          label: 'DOC_SUMMARY.DELIVERY_DATE',
          controlName: 'estimatedDeliveryDate',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          controlClass: 'col-6 mw-272',
          placeholder: 'FILTERS.SELECT_PERIOD',
          baseDropdownItems: [
            {label: 'FORM.1_DAY', value: 1},
            {label: 'FORM.7_DAYS', value: 7},
            {label: 'FORM.30_DAYS', value: 30},
          ],
          showWithStatus: [
            IncomingDeliveryListTabsEnum.PENDING,
          ]
        },
      ]
    },
    {
      title: 'DOC_BLOCK.PROPERTIES',
      filters: [
        {
          label: 'COLUMN.STATUS',
          controlName: 'flag',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          controlClass: 'col-6 mw-272',
          placeholder: 'FORM.SELECT_STATUS',
          baseDropdownItems: [
            // {label: 'STATUS.PENDING', value: IncomingDeliveryListTabsEnum.PENDING.toLowerCase()},
            {label: 'STATUS.DELAYED', value: FlagsEnum.DELAYED.toLowerCase()},
          ],
          showWithStatus: [
            IncomingDeliveryListTabsEnum.PENDING,
          ]
        },
        {
          label: 'COLUMN.DOCUMENT_TYPE_1',
          controlName: 'documentType',
          controlType: FiltersControlsNamesEnum.BASE_DROPDOWN,
          placeholder: 'FORM.SELECT_DOCUMENT_TYPE',
          containerClass: 'col-6',
          baseDropdownItems: [
            {label: 'APP.PURCHASE_ORDERS', value: 'po'},
            {label: 'APP.RETURNS', value: 'era'},
          ],
        },
        {
          label: 'COLUMN.LINKED_DOCUMENTS',
          controlName: 'runpleId',
          bindValue: 'runpleId',
          containerClass: 'col-6 mb-0',
          placeholder: 'FORM.SELECT_LINKED_DOCUMENT',
          controlType: FiltersControlsNamesEnum.LINKED_DOCUMENT,
          allowSelectAll: true,
          observableList: documentsList$,
        },
        {
          label: 'FILTERS.RECEIVING_DATE',
          controlName: 'received',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            IncomingDeliveryListTabsEnum.RECEIVED,
          ]
        },
        {
          label: 'FILTERS.WAREHOUSING_DATE',
          controlName: 'warehoused',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
          showWithStatus: [
            IncomingDeliveryListTabsEnum.WAREHOUSED,
          ]
        },
        {
          label: 'FORM.CREATION_DATE',
          controlName: 'created',
          lastDates: true,
          controlType: FiltersControlsNamesEnum.DATE_PERIODS,
        },
        {
          label: 'FORM.EMPLOYEE',
          controlName: 'employeeId',
          controlClass: 'col-6 mw-272',
          controlType: FiltersControlsNamesEnum.EMPLOYEE,
          showWithStatus: [
            IncomingDeliveryListTabsEnum.RECEIVED,
            IncomingDeliveryListTabsEnum.WAREHOUSED,
          ]
        },
      ]
    }
  ];

  return filtersConfig.map(itm => {
    return {
      ...itm,
      filters: itm.filters.filter(itm => itm.hasOwnProperty('showWithStatus') ? itm.showWithStatus.includes(status) : true)
    }
  });
}

export const IncomingDeliveryListTabs: TabDefinitionModel[] = [
  {
    label: 'TAB.PENDING',
    tabName: IncomingDeliveryListTabsEnum.PENDING,
    link: `../../${IncomingDeliveryListTabsEnum.PENDING}/1`,
    icon: TABS_ICONS[IncomingDeliveryListTabsEnum.PENDING],
    hintsId: 'pendingTab',
    count: 0
  },
  {
    label: 'COLUMN.RECEIVED',
    tabName: IncomingDeliveryListTabsEnum.RECEIVED,
    link: `../../${IncomingDeliveryListTabsEnum.RECEIVED}/1`,
    icon: TABS_ICONS[IncomingDeliveryListTabsEnum.RECEIVED],
    hintsId: 'receivedTab',
    count: 0
  },
  {
    label: 'COLUMN.WAREHOUSED',
    tabName: IncomingDeliveryListTabsEnum.WAREHOUSED,
    link: `../../${IncomingDeliveryListTabsEnum.WAREHOUSED}/1`,
    icon: TABS_ICONS[IncomingDeliveryListTabsEnum.WAREHOUSED],
    hideCount: true
  },
];

export function getIncomingDeliveryListColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center cursor-pointer',
      name: 'Nr.',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      customizable: true,
      headerClass: 'center',
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'withLinkPrimary',
      cellClass: 'link-primary cursor-pointer',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.DOCUMENT_ID',
      prop: 'documentLink',
      width: 160,
      maxWidth: 160,
      sortable: true,
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'withStatusLabel',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.STATUS',
      prop: 'status',
      sortable: true,
      customizable: true,
      cellClass: 'center cursor-pointer',
      width: 124,
      maxWidth: 124,
      clicktype: TableActivateTypes.RowDetail,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary cursor-pointer',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      customizable: true,
      width: 160,
      maxWidth: 160,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED
      ]
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary inline cursor-pointer',
      name: 'COLUMN.PRODUCT_NAME',
      prop: 'productNameLink',
      preventTranslate: true,
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED
      ]
    },
    {
      cellTemplate: 'withCorporatePartner',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary cursor-pointer',
      name: 'COLUMN.VENDOR',
      prop: 'vendor',
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.PENDING,
        IncomingDeliveryListTabsEnum.RECEIVED,
      ]
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.POSITIONS',
      prop: 'positionsCount',
      sortable: true,
      customizable: true,
      cellClass: 'right font-weight-500 cursor-pointer',
      headerClass: 'right',
      width: 128,
      maxWidth: 128,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.PENDING,
        IncomingDeliveryListTabsEnum.RECEIVED,
      ]
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      customizable: true,
      postFixText: 'FORM.PCS',
      cellClass: 'right font-weight-500 cursor-pointer',
      width: 112,
      maxWidth: 112,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED
      ]
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer',
      name: 'COLUMN.BATCH',
      prop: 'batchesValue',
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED,
      ]
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer',
      name: 'COLUMN.BATCH',
      prop: 'batch.runpleId',
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED,
      ]
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerWithHints',
      hintsId: 'binLocationColumn',
      cellClass: 'cursor-pointer',
      name: 'COLUMN.BIN_LOCATION',
      prop: 'binLocation.binLocation',
      sortable: true,
      customizable: true,
      width: 152,
      maxWidth: 152,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED,
      ]
    },
    {
      cellTemplate: 'withAvatarArrowRight',
      headerTemplate: 'headerWithHints',
      hintsId: 'employeeColumnModal',
      cellClass: 'cursor-pointer p-0',
      name: 'COLUMN.EMPLOYEE',
      prop: 'employee_data',
      width: 210,
      maxWidth: 210,
      clicktype: TableActivateTypes.RowDetail,
      customizable: true,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED,
        IncomingDeliveryListTabsEnum.WAREHOUSED,
      ]
    },
    {
      cellTemplate: 'withStatusLine',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.DELIVERY_DATE',
      prop: 'deliveryDateValue',
      sortable: true,
      customizable: true,
      type: ColumnTypeEnum.DATE,
      width: 180,
      maxWidth: 180,
      clicktype: TableActivateTypes.RowDetail,
      cellClass: 'cursor-pointer',
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.PENDING
      ],
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer',
      name: 'COLUMN.CREATED',
      prop: 'createdAt',
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.PENDING
      ]
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer',
      name: 'COLUMN.RECEIVED',
      prop: 'receivedAt',
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED
      ]
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer',
      name: 'COLUMN.WAREHOUSED',
      prop: 'warehousedAt',
      sortable: true,
      customizable: true,
      clicktype: TableActivateTypes.RowDetail,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED
      ]
    },
    {
      cellTemplate: 'withClickableIcon',
      headerTemplate: 'headerWithHints',
      hintsId: 'receiptProductBtnColumn',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.Receipt,
      icon: 'goods-receipt',
      cellClass: 'px-4 cursor-pointer',
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.PENDING
      ],
      frozenRight: true,
    },
    {
      cellTemplate: 'withClickableIcon',
      headerTemplate: 'headerWithHints',
      hintsId: 'bookBtn',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.Return,
      icon: 'undo',
      cellClass: 'px-4 cursor-pointer',
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED,
      ],
      frozenRight: true,
    },
    {
      cellTemplate: 'withClickableIcon',
      headerTemplate: 'headerWithHints',
      hintsId: 'bookBtn',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.Cancel,
      icon: 'undo',
      cellClass: 'px-4 cursor-pointer',
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.WAREHOUSED
      ],
      frozenRight: true,
    },
    {
      cellTemplate: 'withClickableIcon',
      headerTemplate: 'headerWithHints',
      hintsId: 'warehousingColumnBtn',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.Action,
      icon: 'warehousing',
      cellClass: 'px-4 cursor-pointer',
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED
      ],
      frozenRight: true,
    },
    {
      cellTemplate: 'withClickableIcon',
      name: '',
      width: 42,
      maxWidth: 42,
      sortable: false,
      resizeable: false,
      clicktype: TableActivateTypes.RowDetail,
      frozenRight: true,
      icon: 'arrow-micro-down',
      cellClass: 'px-4 cursor-pointer',
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.PENDING,
        IncomingDeliveryListTabsEnum.RECEIVED
      ]
    }
  ];
}

export function getIncomingDeliveryListInnerTableColumns(translateService: TranslateService): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 160,
      maxWidth: 160,

    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary inline',
      name: 'COLUMN.PRODUCT_NAME',
      prop: 'productNameLink',
      preventTranslate: true,
      sortable: true
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 112,
      maxWidth: 112,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BATCH',
      prop: 'batch.runpleId',
      sortable: true,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED
      ]
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.EXPIRATION_DATE',
      prop: 'expirationDate',
      sortable: true,
      width: 288,
      maxWidth: 288,
      type: ColumnTypeEnum.DATE,
      dateType: DateColumnType.WITHOUT_TIME,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED
      ]
    },
    {
      cellTemplate: 'withAvatarArrowRight',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer p-0',
      name: 'COLUMN.EMPLOYEE',
      prop: 'employee_data',
      width: 210,
      maxWidth: 210,
      showWithStatuses: [
        IncomingDeliveryListTabsEnum.RECEIVED,
      ]
    },
  ];
}

// todo
export const EMPTY_STATE_DATA = {
  [IncomingDeliveryListTabsEnum.PENDING]: {
    icon: TABS_ICONS[IncomingDeliveryListTabsEnum.PENDING],
    text: `WAREHOUSE.INCOMING_DELIVERY.EMPTY_LIST_PENDING`
  },
  [IncomingDeliveryListTabsEnum.RECEIVED]: {
    icon: TABS_ICONS[IncomingDeliveryListTabsEnum.RECEIVED],
    text: `WAREHOUSE.INCOMING_DELIVERY.EMPTY_LIST_RECEIVED`
  },
  [IncomingDeliveryListTabsEnum.WAREHOUSED]: {
    icon: TABS_ICONS[IncomingDeliveryListTabsEnum.WAREHOUSED],
    text: `WAREHOUSE.INCOMING_DELIVERY.EMPTY_LIST_WAREHOUSED`
  },
};

export function getTableSummaryBarItems(
  totals: IncomingDeliveryListTotalsModel,
  status: IncomingDeliveryListTabsEnum
): TableSummaryBarItemModel[] {
  const tableSummaryBarItems: TableSummaryBarItemModel[] = [
    {
      label: 'TAB.PENDING',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      value: totals.incoming,
      titleIcon: 'incoming-delivery',
      basisWidth: 167,
      id: 'incomingTotalRow',
    },
    {
      label: 'TABLE_SUMMARY_BAR.DELAYED',
      type: TableSummaryBarItemTypeEnum.WITH_NUMBER,
      highlightTypes: [HighlightTypeEnum.POSITIVE_TO_RED],
      value: totals.delayed,
      basisWidth: 135,
    },
  ];

  return tableSummaryBarItems.filter(itm => !itm.showWithDocumentStatus || itm.showWithDocumentStatus.includes(status));
}
