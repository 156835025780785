import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-toggle',
  templateUrl: './filters-control-toggle.component.html',
  styleUrls: ['./filters-control-toggle.component.scss']
})
export class FiltersControlToggleComponent extends FiltersControlBaseComponent implements OnInit {

  @Input() controlName: string;

  constructor(public translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit() {
  }

}
