import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { map, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { AvailableSalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { tableColumns } from './time-tracking-assign-sales-order-modal.config';
import { STATUS_CLASSES } from 'projects/workspace/src/app/sales-order/sales-order.constants';
import { InvoiceApiService } from 'projects/workspace/src/app/outgoing-invoice/services/invoice-api.service';
import { OutgoingInvoiceModel } from 'projects/workspace/src/app/outgoing-invoice/models';
import { TimeTrackingRecordModel } from 'projects/workspace/src/app/time-tracking/models';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { CustomerTypeEnum } from '../../../../models';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';


@Component({
  selector: 'rnpl-time-tracking-assign-sales-order-modal',
  templateUrl: './time-tracking-assign-sales-order-modal.component.html',
  styleUrls: ['./time-tracking-assign-sales-order-modal.component.scss'],
})
export class TimeTrackingAssignSalesOrderModalComponent extends BaseModalComponent implements OnInit {

  public availableDocuments: (AvailableSalesOrderModel|OutgoingInvoiceModel)[] = [];
  // public availableSalesOrders: AvailableSalesOrderModel[] = [];
  public selectedDocument: AvailableSalesOrderModel|OutgoingInvoiceModel = null;
  public updateQuantity: boolean = false;
  public columns = tableColumns;
  readonly statusClasses: { [key: string]: string } = STATUS_CLASSES;

  public records: TimeTrackingRecordModel[] = [];
  public selectedRows: TimeTrackingRecordModel[] = [];

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('groupingForm', {static: true}) groupingFormRef;

  constructor(
    public toasterService: ToasterService,
    public dialog: MatDialog,
    private timeTrackingApiService: TimeTrackingApiService,
    private salesOrderApiService: SalesOrderApiService,
    private invoiceApiService: InvoiceApiService,
    public dialogRef: MatDialogRef<TimeTrackingAssignSalesOrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      recordIds: number[],
      partnerId: number,
      partnerType: CustomerTypeEnum,
    }
  ) {
    super(toasterService);
  }

  ngOnInit(): void {
    forkJoin({
      invoices: this.invoiceApiService.getOutgoingInvoicesAvailableForTimeTracking(this.data.partnerId, this.data.partnerType),
      orders: this.salesOrderApiService.getAvailableToTimeTrackingSalesOrders(this.data.recordIds)
    }).pipe(
      map(documents => [
        ...documents.invoices.map(invoice => ({
          ...invoice,
          runpleId: invoice.runpleId || 'Draft invoice'
        })),
        ...documents.orders.map(order => ({
          ...order,
          runpleId: order.runpleId || 'Draft sales order'
        }))
      ]),
      takeUntil(this._destroy)
    ).subscribe(documents => this.availableDocuments = documents);
  }

  public changeSalesOrder(): void {
    if (!this.selectedDocument) { return; }
    this.selectedRows = [];

    // this.salesOrderApiService.getSalesOrderRecordPositions(this.selectedDocument.id, this.data.recordIds)
    //   .pipe(takeUntil(this._destroy))
    //   .subscribe((records: TimeTrackingRecordModel[]) => {
    //     this.records = records.map(record => ({
    //       ...record,
    //       productLink: {
    //         label: record.serviceRunpleId,
    //         routerLink: `/products/product-view/${record.serviceId}`
    //       },
    //       unitType: record.units,
    //     }));
    //
    //     this.selectedRows = this.records.filter(record => record.assign);
    //   });

    const filters: any = {
      billable: true,
      approved : true,
      hasDocuments: false,
      partnerIds: this.selectedDocument.customer.id
    };

    this.timeTrackingApiService.getOpenRecordsByPartner(filters, true)
      .pipe(takeUntil(this._destroy))
      .subscribe(records => {
        this.records = records.map(record => ({
          ...record,
          productLink: {
            label: record.product.runpleId,
            routerLink: `/products/product-view/${record.product.id}`
          },
          unitType: record.loggedUnits,
        //   loggedDuration: record.loggedDuration ? record.loggedDuration.toString().replace('.', ',') : null,
        //   // period: this.getProvidedWithinValue(position),
        }));

        this.data.recordIds.forEach(id => {
          const selectedRecord = this.records.find(r => r.id === id);
          this.selectedRows.push(selectedRecord);
        });
      });
  }

  public addToDocument(): void {
    if (this.isOINSelected) {
      this.addToOIN();
    } else {
      this.addToSO();
    }
  }

  private addToSO(): void {
    const records = this.selectedRows.map(record => ({
      recordId: record.id,
      assign: true
    }));

    this.salesOrderApiService.assignTimeTrackingRecordsToSO(
      (this.selectedDocument as AvailableSalesOrderModel).id,
      this.updateQuantity,
      records,
      {
        ...this.groupingFormRef.getFormData(),
        // providedServicesEnabled: !this.groupingFormRef.getFormData().providedServicesEnabled
      }
    )
      .pipe(takeUntil(this._destroy))
      .subscribe( res => this.dialogRef.close(res));
  }

  private addToOIN(forceAdding = false): void {
    const recordsIds = this.selectedRows.map(record => record.id);

    if (get(this, 'selectedDocument.hasLinkedSO') && !forceAdding) {
      this.dialog
        .open(WarningModalComponent, {
          data: {
            title: 'BUTTON.ADD_TO_DOCUMENT',
            message: 'TIME_TRACKING.MODAL.ADD_TO_DOCUMENT_MESSAGE',
            confirmBtnIcon: 'link',
            confirmBtnText: 'BUTTON.CONTINUE',
          }
        })
        .afterClosed()
        .subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.addToOIN(true);
          }
        });
      return;
    }

    this.timeTrackingApiService.addRecordsToOIN(
      recordsIds,
      (this.selectedDocument as OutgoingInvoiceModel).invoiceId,
      forceAdding,
      this.groupingFormRef.getFormData()
    )
      .pipe(takeUntil(this._destroy))
      .subscribe(res => this.dialogRef.close(res));
  }

  get documentUrl(): string {
    if (!this.selectedDocument) { return  ''; }

    if (this.isOINSelected) {
      return `/accounting/outgoing-invoice/${(this.selectedDocument as OutgoingInvoiceModel).invoiceId}`;
    } else {
      return `/trade/sales-order/${(this.selectedDocument as AvailableSalesOrderModel).id}`;
    }
  }

  get isOINSelected(): boolean {
    return this.selectedDocument && (this.selectedDocument as OutgoingInvoiceModel).documentType === DocumentTypesUppercaseEnum.OIN;
  }

  get partnerUrl(): string {
    if (!this.partner) { return  ''; }
    return `/partners-new/${this.partner.type || PartnersTypeEnum.CORPORATE}/${this.partner.id}`;
  }

  get partner() {
    if (!this.selectedDocument) { return ; }
    return this.selectedDocument.customer;
  }


}
