import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { BaseFormComponent } from 'projects/workspace/src/app/crm/partner-forms/components/base-form.component';
import { BatchDataModel } from '../models/batch-data.model';
import { AddressFormFactory } from 'projects/workspace/src/app/crm/partner-forms/components/address-form.factory';
import { CountryModel, RnplCommonService, MetricConversionPipe } from 'common/src/modules/rnpl-common';
import { takeUntil } from 'rxjs/operators';
import { Batch } from '../../../../models/purchase-order/batch';
import { CostsType } from '../batch-view-and-edit/enums/costs.enum';

@Component({
  selector: 'rnpl-batch-form',
  templateUrl: './batch-form.component.html',
  styleUrls: ['./batch-form.component.scss']
})
export class BatchFormComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() batchData: BatchDataModel;

  public countries: CountryModel[] = [];

  public unitValue: string = 'EUR';

  public metricConversion = 'EUR-EUR';

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private rnplCommonService: RnplCommonService,
    private metricPipe: MetricConversionPipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.rnplCommonService.getCountries()
      .pipe(takeUntil(this._destroy))
      .subscribe((response: { data: CountryModel[] }) => this.countries = response.data);

    this.updateFormEditingStatus();
    this.initForm();
    this.subscribeCosts();
    this.speditionTypeChanges();
    this.freightCostChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('readonly')) {
      this.updateFormEditingStatus();
    }
  }

  public initForm(): void {
    this.form = this.fb.group({
      purchase_order_information: this.fb.group({
        vendor: [{ value: this.batchData.runpleId || '', disabled: true }, [Validators.required]],
        purchase_order: [{ value: this.batchData.vendor_name || '', disabled: true }, [Validators.required]],
      }),
      spedition_type: this.fb.group({
        type: ['internal', [Validators.required]],
        shipment_service: ['', [Validators.required]],
        tracking_number: [{value: '', disabled: true }, []],
        estimated_time_of_arrival: ['', [Validators.required]],
      }),
      delivery_address: AddressFormFactory.getForm(this.batchData.delivery_address),
      freight_cost: this.fb.group({
        costs: ['payed_by_us', [Validators.required]],
        netto_price: ['', { validators: [Validators.required], updateOn: 'blur' }],
        vat: [{ value: '', disabled: true }, [Validators.required]],
        brutto_price: ['', { validators: [Validators.required], updateOn: 'blur' }],
      }),
      comments: this.fb.group({
        comments: ['', []],
      }),
    });

    super.initForm();
  }

  subscribeCosts() {
    this.netto_price.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((netto) => {
        const vat = (Number(netto) * 0.2).toFixed(2);
        const brutto = (Number(netto) + Number(vat)).toFixed(2);

        this.vat.setValue(vat);
        this.brutto_price.setValue(brutto, { emitEvent: false });
      });
    this.brutto_price.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((brutto) => {
        const vat = (Number(brutto) / 6).toFixed(2);
        const netto = (Number(brutto) / 1.2).toFixed(2);

        this.vat.setValue(vat);
        this.netto_price.setValue(netto, { emitEvent: false });
      });
  }

  public getFormData(): {batches: Batch[]} {
    const formValue = this.form.getRawValue();
    const delivery_cost = (formValue.freight_cost.costs === 'payed_by_us')
      ? Number((formValue.freight_cost.netto_price * 100).toFixed(2))
      : null;

    return { batches:
      [
        {
          spedition_type: formValue.spedition_type.type,
          spedition: formValue.spedition_type.shipment_service,
          tracking_number: formValue.spedition_type.tracking_number,
          expected_arrival_date: formValue.spedition_type.estimated_time_of_arrival,
          comment: formValue.comments.comments,
          delivery_cost_transportation: delivery_cost
        }
      ]
    };
  }

  public updateFormEditingStatus(): void {
    if (!this.form) { return; }

    if (this.readonly) {
      this.form.disable({ onlySelf: false });
    } else {
      this.form.enable({ onlySelf: false });
    }
  }

  public valueHandler(value: string): void {
    if (this.unitValue !== value) {
      this.metricConversion = `${this.unitValue}-${value}`;
      this.unitValue = value;
      this.updateFormMetric(this.metricConversion);
    }
  }

  public updateFormMetric(metricConversion): void {
    const toUpdateList = [
      'netto_price',
      'vat',
      'brutto_price'
    ];
    toUpdateList.forEach((item) => {
      this[item].setValue(
        this.metricPipe.transform(Number(this[item].value), metricConversion)
      );
    });
  }

  private fieldsAction(type: string, ...fields: FormControl[]): void {
    switch (type) {
      case 'enable':
        fields.forEach(field => field.enable({ emitEvent: false }));
        break;
      case 'disable':
        fields.forEach(field => field.disable({ emitEvent: false }));
        break;
      case 'reset':
        fields.forEach(field => field.setValue(0, { emitEvent: false }));
        break;
    }
  }

  private speditionTypeChanges() {
    this.form.get('spedition_type')
      .valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        const trackingNumberControl: AbstractControl = this.form
          .get('spedition_type')
          .get('tracking_number');

        if (value.type === 'internal') {
          trackingNumberControl.reset('', { emitEvent: false});
          trackingNumberControl.disable({ emitEvent: false });
        } else {
          trackingNumberControl.enable({ emitEvent: false });
        }
      });
  }

  private freightCostChanges() {
    this.form.get('freight_cost')
      .valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        if (value.costs === CostsType.PAYED_BY_VENDOR) {
          this.fieldsAction('reset', this.netto_price);
          this.fieldsAction('disable', this.netto_price, this.brutto_price);
        } else {
          this.fieldsAction('enable', this.netto_price, this.brutto_price);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }

  get vendor() { return this.form.get('purchase_order_information').get('vendor'); }

  get purchase_order() { return this.form.get('purchase_order_information').get('purchase_order'); }

  get shipment_service() { return this.form.get('spedition_type').get('shipment_service'); }

  get estimated_time_of_arrival() { return this.form.get('spedition_type').get('estimated_time_of_arrival'); }

  get netto_price() { return this.form.get('freight_cost').get('netto_price') as FormControl; }

  get vat() { return this.form.get('freight_cost').get('vat'); }

  get brutto_price() { return this.form.get('freight_cost').get('brutto_price') as FormControl; }

}
