import { OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { ToasterService } from '../../../ui-components/toaster';

export class BaseModalComponent implements OnDestroy {

  protected _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(public toasterService: ToasterService) {}


  public displayMessage(type: string, message: string) {
    this.toasterService.notify({type, message});
  }

  ngOnDestroy(): void {
   this._destroy.next(null);
   this._destroy.complete();
  }
}
