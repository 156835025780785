import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

import { AddressModel, ImageModel, VatStatusEnum } from 'common/src/models';
import { CountryModel, CountryTransformFromIso2ToIso3Pipe, CountryTransformFromIso3ToIso2Pipe } from 'common/src/modules/rnpl-common';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { PartnerVatCheckModel } from 'projects/workspace/src/app/partners/corporate/models/vat-check.model';
import { PartnersApiService } from 'projects/workspace/src/app/partners/corporate/services/partner-api.service';
import { AddressFormFactory } from 'projects/workspace/src/app/shared/forms/address-form.factory';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
import { PartnerModel } from 'projects/workspace/src/app/partners/corporate/models/partner.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { convertAddressToStringHelper } from '../../../rnpl-common/helpers';
import { CommonModalsActionsEnum, WarningModalComponent } from '../../modals-common';

@Component({
  selector: 'rnpl-create-new-partner-modal',
  templateUrl: './create-new-partner-modal.component.html',
  styleUrls: ['./create-new-partner-modal.component.scss']
})
export class CreateNewPartnerModalComponent implements OnInit, OnDestroy {

  public legalNameAutocompleteOptions: Options = {
    fields: ['address_components', 'international_phone_number', 'name'],
    types: ['establishment']
  } as Options;

  public form: FormGroup;
  public displayEuSuffix: boolean = false;
  public canValidateVatNumber: boolean = true;

  readonly countries$: BehaviorSubject<CountryModel[]> = new BehaviorSubject<CountryModel[]>([]);

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  readonly submitPartnerRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    private readonly partnerApiService: PartnersApiService,
    private readonly store: Store<AppState>,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateNewPartnerModalComponent>,
    private countryTransformFromIso3ToIso2Pipe: CountryTransformFromIso3ToIso2Pipe,
    private countryTransformFromIso2ToIso3Pipe: CountryTransformFromIso2ToIso3Pipe,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {
    this.initForm();
    this.bindRegistrationCountryChanges();
    this.bindNoVatControlChanges();
    this.selectCountries();
  }

  initForm(): void {
    this.form = this.fb.group({
      registrationCountry: [null, [Validators.required]],
      vatNumber: [{value: null, disabled: true}, {validators: [Validators.required], updateOn: 'blur'}],
      noVat: [{value: null, disabled: true}],
      name: [null, [Validators.required]],
      legalAddress: AddressFormFactory.getForm({} as AddressModel),
      email: [null, [Validators.email]],
      phone: [null, []],
      image: [null, []],
      alias: [null, []],
      remark: [null, []],
      description: [null, []],
    });
  }

  private selectCountries(): void {
    this.store.select(selectCountries)
      .pipe(takeUntil(this.destroy$))
      .subscribe((countries: CountryModel[]) => {
        this.countries$.next(countries);
      });
  }

  submitPartner(partnerData: {companyProfile: Partial<PartnerModel>}) {
    if (this.submitPartnerRequest$.getValue()) { return; }
    this.submitPartnerRequest$.next(true);
    this.vatNumber.markAsTouched();
    this.vatNumber.updateValueAndValidity();

    this.partnerApiService.createPartner(PartnersTypeEnum.CORPORATE, partnerData)
      .pipe(
        finalize(() => this.submitPartnerRequest$.next(false)),
        takeUntil(this.destroy$),
        catchError(error => {
          if (
            error.error.message &&
            error.error.message.includes('The entered VAT number belongs to') &&
            error.error.message.includes('Do you want to create another partner with the same VAT number')
          ) {
            const companiesNames = error.error.message
                .replace('VAT number is already taken! The entered VAT number belongs to ', '')
                .replace('. Do you want to create another partner with the same VAT number?', '');

            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'MESSAGES.VAT_TAKEN_TITLE',
                message: this.translateService.instant('MESSAGES.VAT_TAKEN_MSG', {partnerName:companiesNames }),
                confirmBtnText: 'BUTTON.CONTINUE',
                confirmBtnIcon: 'checkbox-on'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                partnerData = {
                  companyProfile: {
                    ...partnerData.companyProfile,
                    force: true
                  },
                };
                this.submitPartner(partnerData);
              }
            });
            return throwError(error);
          }
          // if (error.error.message === 'cantCheckVatStatus') {
          //   const dialog = this.dialog.open(WarningModalComponent, {
          //     data: {
          //       title: 'BUTTON.CREATE_CORPORATE_PARTNER',
          //       message: 'We cannot check the status of the entered VAT number. Do you want to continue?',
          //       confirmBtnText: 'BUTTON.CONTINUE',
          //       confirmBtnIcon: 'checkbox-on'
          //     }
          //   });
          //
          //   dialog.afterClosed().subscribe(res => {
          //     if (res === CommonModalsActionsEnum.CONFIRM) {
          //       partnerData = {
          //         companyProfile: {
          //           ...partnerData.companyProfile,
          //           skipVatValidation: true
          //         },
          //       };
          //       this.submitPartner(partnerData);
          //     }
          //   });
          //   return throwError(error);
          // }
          this.displayMessage(error.error.message || error.error.errors, 'error');
          return throwError(error);
        })
      )
      .subscribe((partner: PartnerModel ) => {
        this.displayMessage('SUCCESS_CREATED.PARTNER', 'success');
        this.dialogRef.close(partner);
      });
  }

  public getFormData() {
    const formData: any = {
      companyProfile: this.form.getRawValue(),
    };
    formData.companyProfile.image = { id: get(formData, 'companyProfile.image.apiModel.id', null) };
    return formData;
  }

  public displayMessage(message: string, type: string) {
    this.toasterService.notify({ type, message });
  }

  public bindNoVatControlChanges() {
    this.noVat.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value) {
          this.vatNumber.reset(null);
          this.vatNumber.disable();
        } else {
          this.vatNumber.enable();
        }
      });
  }

  public bindRegistrationCountryChanges(): void {
    this.registrationCountry.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        const currentCountry = this.countries.find(country => country.code_iso3 === value);
        this.legalAddressCountry.setValue(value);

        if (value && currentCountry.isVatEnabled) {
          this.vatNumber.enable({emitEvent: false});
          this.noVat.enable({emitEvent: false});
        } else {
          this.vatNumber.disable({emitEvent: false});
          this.noVat.disable({emitEvent: false});
        }

        if (!!currentCountry) {
          this.displayEuSuffix = !currentCountry.isEu;
        }
      });
  }

  public setLogo(images: ImageModel[]): void {
    this.image.setValue(images.length ? images[0] : null);
  }

  processVatValidation(vatData: PartnerVatCheckModel): void {
    if (vatData.vatStatus === VatStatusEnum.NA) {
      this.canValidateVatNumber = false;
      return;
    } else {
      this.canValidateVatNumber = true;
    }

    this.name.setValue(vatData.name);

    const arrAddress = vatData.address.split(' ');
    let isAddressAndZipFound = false;
    const parsedAddress = {
      street: [],
      houseAndZip: [],
      houseBlockApartments: [],
      city: [],
      zip: '',
    };

    for (const i in arrAddress) {
      if (!isAddressAndZipFound && !arrAddress[i].includes('/')) { // while not found zip all items is parts of street name
        parsedAddress.street.push(arrAddress[i]);
      } else if (!isAddressAndZipFound && arrAddress[i].includes('/')) {
        // (house, block, apartments) separates with (zip code) by '↵' sign
        parsedAddress.houseAndZip = arrAddress[i].split(/(\r\n|\n|\r)/gm);
        // house/block?(optional)/apartments - separates by '/' sign
        parsedAddress.houseBlockApartments = parsedAddress.houseAndZip[0].split('/');
        parsedAddress.zip = parsedAddress.houseAndZip[2];
        isAddressAndZipFound = true;
      } else { // when zip found all items is parts of city name
        parsedAddress.city.push(arrAddress[i]);
      }
    }

    this.setControlValue(this.legalAddress, 'street', parsedAddress.street.join(' '));
    this.setControlValue(this.legalAddress, 'city', parsedAddress.city.join(' '));
    this.setControlValue(this.legalAddress, 'zip_code', parsedAddress.zip);
    this.setControlValue(this.legalAddress, 'house_number', parsedAddress.houseBlockApartments[0]);

    if (parsedAddress.houseBlockApartments.length === 2) { // without block number
      this.setControlValue(this.legalAddress, 'apartments', parsedAddress.houseBlockApartments[1]);
    } else { // with block number
      this.setControlValue(this.legalAddress, 'block', parsedAddress.houseBlockApartments[1]);
      this.setControlValue(this.legalAddress, 'apartments', parsedAddress.houseBlockApartments[2]);
    }
  }

  setControlValue(form: AbstractControl, control: string, val: any): void {
    if (!form.get(control).value) {
      form.get(control).setValue(val);
      form.get(control).markAsTouched();
    }
  }

  public get vatPrefix() {
    if (!this.registrationCountry.value) { return; }
    if (!this.countries.length) { return; }

    if (this.registrationCountry.value === 'che') { // exception for switzerland
      return 'CHE';
    }

    const selectedCountry = this.countries.find(country => country.code_iso3 === this.registrationCountry.value);
    return !selectedCountry.isEu ? 'EU' : selectedCountry.code_iso2.toUpperCase();
  }

  public get legalAddressAsText(): string {
    if (!this.form) { return ''; }
    return convertAddressToStringHelper(this.legalAddress.value, this.countries);
  }

  public get countries(): CountryModel[] {
    return this.countries$.getValue();
  }

  public get selectedCountry(): CountryModel {
    if (!this.registrationCountry.value) { return null; }
    return this.countries.find(country => country.code_iso3 === this.registrationCountry.value);
  }

  public addressChange(address: any) {
    let postalCode = null;
    let city = '';
    let street = '';
    let countryIso = '';
    let state = '';
    let house = null;

    address.address_components.map(component => {
      switch (component.types[0]) {
        case 'postal_code':
          postalCode = component.long_name;
          break;
        case 'locality':
          city = component.long_name;
          break;
        case 'country':
          countryIso = this.countryTransformFromIso2ToIso3Pipe.transform(component.short_name).toLowerCase();
          break;
        case 'route':
          street = component.long_name;
          break;
        case 'administrative_area_level_1':
          state = component.long_name;
          break;
        case 'street_number':
          house = component.long_name;
          break;
      }
    });

    if (!this.zip_code.value) { this.zip_code.setValue(postalCode); }
    if (!this.city.value) { this.city.setValue(city); }
    if (!this.state.value) { this.state.setValue(state); }
    if (!this.house_number.value) { this.house_number.setValue(house); }
    if (!this.name.value) { this.name.setValue(address.name); }
    if (!this.phone.value) { this.phone.setValue(address.international_phone_number); }
    if (!this.street.value) { this.street.setValue(street); }

    this.legalAddressCountry.setValue(countryIso);
    this.registrationCountry.setValue(countryIso);
  }

  private static removeAutocompleteElement(): void {
    document.querySelectorAll('.pac-container').forEach(el => el.remove());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    CreateNewPartnerModalComponent.removeAutocompleteElement();
  }

  public get registrationCountry(): FormControl { return this.form.get('registrationCountry') as FormControl; }
  public get vatNumber(): FormControl { return this.form.get('vatNumber') as FormControl; }
  public get noVat(): FormControl { return this.form.get('noVat') as FormControl; }
  public get name(): FormControl { return this.form.get('name') as FormControl; }
  public get legalAddress(): FormControl { return this.form.get('legalAddress') as FormControl; }
  public get legalAddressCountry() { return this.form.get('legalAddress').get('country_iso3') as FormControl; }
  public get email(): FormControl { return this.form.get('email') as FormControl; }
  public get phone(): FormControl { return this.form.get('phone') as FormControl; }
  public get image(): FormControl { return this.form.get('image') as FormControl; }
  public get alias(): FormControl { return this.form.get('alias') as FormControl; }
  public get remark(): FormControl { return this.form.get('remark') as FormControl; }
  public get description(): FormControl { return this.form.get('description') as FormControl; }

  get city(): FormControl { return this.form.get('legalAddress').get('city') as FormControl; }
  get state(): FormControl { return this.form.get('legalAddress').get('state') as FormControl; }
  get street(): FormControl { return this.form.get('legalAddress').get('street') as FormControl; }
  get house_number(): FormControl { return this.form.get('legalAddress').get('house_number') as FormControl; }
  get zip_code(): FormControl { return this.form.get('legalAddress').get('zip_code') as FormControl; }

}
