import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'rnpl-non-auth-header',
  templateUrl: './non-auth-header.component.html',
  styleUrls: ['./non-auth-header.component.scss']
})
export class NonAuthHeaderComponent implements OnInit {

  @Input('isVisible')
  public isVisible: boolean;

  @Input('headerTitle')
  public headerTitle: string;

  @Input('optionalTitle')
  public optionalTitle: string;

  constructor() { }

  ngOnInit() {
  }

}
