import { createAction, props } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { OutgoingInvoiceModel, PositionsModel, PositionsTotals } from '../../models';
import { SummaryInvoiceModel } from '../../models/summary-invoice.model';
import { UIStatesEnum } from 'common/src/models';
import { AddressModel } from 'common/src/models';
import { OutgoingInvoicesListState } from '../reducers';
import { OutgoingInvoiceListTabsEnum } from '../../enums';

export const LoadOutgoingInvoicesList = createAction(
  '[Outgoing Invoice] Load Outgoing Invoices List',
  props<{ outgoingInvoiceListData: OutgoingInvoicesListState, status: OutgoingInvoiceListTabsEnum, page: number }>()
);

export const LoadOutgoingInvoice = createAction(
  '[Outgoing Invoice] Load Outgoing Invoice',
  props<{ outgoingInvoice: OutgoingInvoiceModel }>()
);

export const UpdateOutgoingInvoiceBillingAddress = createAction(
  '[Outgoing Invoice] Update Outgoing Invoice Billing Address',
  props<{ billingAddress: AddressModel }>()
);

export const ResetOutgoingInvoice = createAction(
  '[Outgoing Invoice] Reset Outgoing Invoice'
);

export const LoadOutgoingInvoiceCountries = createAction(
  '[Outgoing Invoice] Load Countries',
  props<{ countries: CountryModel[] }>()
);

export const UpdateOutgoingInvoiceState = createAction(
  '[Outgoing Invoice] Update Outgoing Invoice Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const ResetOutgoingInvoiceState = createAction(
  '[Outgoing Invoice] Reset Outgoing Invoice Current State'
);

export const LoadOutgoingInvoicePositions = createAction(
  '[Outgoing Invoice] Load Outgoing Invoice Positions',
  props<{ positions: PositionsModel }>()
);

export const UpdateOutgoingInvoicePositionsCount = createAction(
  '[Outgoing Invoice] Update Outgoing Invoice Positions Count',
  props<{ positionsCount: number }>()
);

export const ResetOutgoingInvoicePositionsCount = createAction(
  '[Outgoing Invoice] Reset Outgoing Invoice Positions Count'
);

export const UpdateOutgoingInvoiceSummary = createAction(
  '[Outgoing Invoice] Update Outgoing Invoice Summary',
  props<{ outgoingInvoiceSummary: SummaryInvoiceModel }>()
);

export const UpdateOutgoingPositionsTotals = createAction(
  '[Outgoing Invoice] Update Outgoing Positions Totals',
  props<{ outgoingPositionsTotals: PositionsTotals }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Outgoing Invoice] Update Outgoing Invoice should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const UpdateOutgoingInvoiceUpdatedAt = createAction(
  '[Outgoing invoice] Update Outgoing invoice updatedAt',
  props<{ updatedAt: Date }>()
);

export const OutgoingInvoiceSendEmail = createAction('[Outgoing Invoice] Send Email');

export const ClearOutgoingInvoiceUpdatedAt = createAction('[Outgoing invoice] Clear Outgoing invoice updatedAt');

export const IncrementLoadingRequestsCount = createAction('[Outgoing invoice] Increment Loading Requests Count');

export const DecrementLoadingRequestsCount = createAction('[Outgoing invoice] Decrement Loading Requests Count');

export const UpdatePropertiesBlockValid = createAction(
  '[Outgoing invoice] Update Outgoing invoice Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdateBillingInfoBlockValid = createAction(
  '[Outgoing invoice] Update Outgoing invoice Billing Info Block Valid',
  props<{ billingInfoBlockValid: boolean }>()
);

export const UpdatePositionsBlockValid = createAction(
  '[Outgoing invoice] Update Outgoing invoice Positions Block Valid',
  props<{ positionsBlockValid: boolean }>()
);
