import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-column-with-input',
  templateUrl: './column-with-input.component.html',
  styleUrls: ['./column-with-input.component.scss']
})
export class ColumnWithInputComponent {
  @Input()
  value: string;
}
