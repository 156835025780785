import { Component, Input } from '@angular/core';


@Component({
  selector: 'rnpl-form-tooltip',
  templateUrl: './form-tooltip.component.html',
  styleUrls: ['./form-tooltip.component.scss']
})
export class FormTooltipComponent {

  @Input('displayed')
  public isDisplayed: boolean = true;

  public show(): void {
    this.isDisplayed = true;
  }

  public hide(): void {
    this.isDisplayed = false;
  }
}
