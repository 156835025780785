import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-linked-document',
  templateUrl: './filters-control-linked-document.component.html',
})
export class FiltersControlLinkedDocumentComponent extends FiltersControlBaseComponent {

  @Input() observableList: Observable<any>;
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() placeholder: string;

  public defaultBindValue = 'id';

}
