import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';

import { AddressModel } from 'common/src/models';

export class AddressFormFactory {

  static getForm(
    address: AddressModel = {} as any,
    readonly: boolean = false,
    allIsOptional: boolean = false,
    requiredControls?: string[]
  ): FormGroup {
    if (address === null) { address = {} as any}

    const formGroup = new FormGroup({
        country_iso3: new FormControl({
          value: address.country ? (address.country.code_iso3 || address.country.codeISO3) : address.country_iso3,
          disabled: readonly
        }, [
          Validators.required
        ]),

        address_line: new FormControl({
          value: address.address_line,
          disabled: readonly
        }, [
          Validators.required
        ]),

        city: new FormControl(
          {
            value: address.city || null,
            disabled: readonly,
          }, [
            Validators.required,
            // Validators.pattern('^[a-zA-Z-\\s]{0,30}$')
          ]
        ),

        state: new FormControl(
          {
            value: address.state || null,
            disabled: readonly
          }, [
            // Validators.pattern('^[a-zA-Z-\\s]{0,255}$')
          ]
        ),

        street: new FormControl(
          {
            value: address.street || null,
            disabled: readonly
          }, [
            Validators.required,
            // Validators.pattern('^[a-zA-Z-\\s]{0,255}$')
          ]
        ),

        floor: new FormControl(
          {
            value: address.floor || null,
            disabled: readonly
          }, [
            // Validators.pattern('^[a-zA-Z0-9]{0,10}$')
          ]
        ),

        block: new FormControl(
          {
            value: address.block || null,
            disabled: readonly
          },
          [
            // Validators.pattern('^[a-zA-Z0-9]{0,10}$')
          ]
        ),

        house_number: new FormControl(
          {
            value: address.house_number || null,
            disabled: readonly
          }, [
            // Validators.required,
            // Validators.pattern('^[0-9-\\s\\/]{0,20}$')
          ]
        ),

        apartments: new FormControl(
          {
            value: address.apartments || null,
            disabled: readonly
          }, [
            // Validators.pattern('^[a-zA-Z0-9-]{0,10}$')
          ]
        ),

        zip_code: new FormControl(
          {
            value: address.zip_code || null,
            disabled: readonly
          }, [
            Validators.required,
            // Validators.pattern('^[a-zA-Z0-9-]{3,8}$')
          ],
        ),

        additional_information: new FormControl(
          {
            value: address.additional_information || null,
            disabled: readonly
          }, [
          ],
        ),
      },
      { updateOn: 'blur' });

    if (allIsOptional) {
       Object.keys(formGroup.controls).map( item => {
         if (!requiredControls || !requiredControls.includes(item)) {
          formGroup.controls[item].clearValidators();
         }
       });
    }

    return formGroup;

  }

  static setStateForSimplifiedFAddressTemplate(form: FormGroup): void {
    const controls: AbstractControl[] = [
      form.get('city'),
      form.get('state'),
      form.get('street'),
      form.get('floor'),
      form.get('block'),
      form.get('house_number'),
      form.get('apartments'),
      form.get('zip_code'),
      form.get('additional_information'),
    ];

    controls.forEach((c: AbstractControl) => {
      c.disable({emitEvent: false});
      c.updateValueAndValidity({emitEvent: false});
    });
  }
}
