import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MetricConversionPipe } from '../../rnpl-common';
import { InputDropdownTypeEnum } from '../position-card-models.model';

@Component({
  selector: 'rnpl-positions-controls',
  templateUrl: './positions-controls.component.html',
  styleUrls: ['./positions-controls.component.css']
})
export class PositionsControlsComponent implements OnInit, OnChanges {

  public inputControl = new FormControl(null, {validators: [Validators.required], updateOn: 'blur'});

  public readonly inputDropdownTypeEnum = InputDropdownTypeEnum;

  @Input() value: string | number;
  @Input() label: string;
  @Input() type: InputDropdownTypeEnum;
  @Input() dropdownListItems: string[];
  @Input() unitType: string;
  @Input() allowFractionalValues: boolean = true;
  @Input() id: string;
  @Input() currencyIsOriginal: boolean;
  @Input() currencyNegativeRow: boolean;
  @Input() controlClass: string;
  @Input() currency: string = 'EUR';
  @Input() isOptional: boolean;
  @Input() disabled: boolean = false;
  @Input() showUnitOnly: boolean = false;
  @Input() showControls: boolean = true;

  @Output() fieldChanged: EventEmitter<any> = new EventEmitter();
  @Output() unitTypeChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private metricPipe: MetricConversionPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('value')) {
      this.setValue();
    }
    if (changes.hasOwnProperty('disabled') && this.disabled) {
      this.inputControl.disable();
    }
    if (changes.hasOwnProperty('disabled') && !this.disabled) {
      this.inputControl.enable();
    }
  }

  ngOnInit() {
    this.setValue();
  }

  private setValue(): void {
    if (!!this.value || this.value === 0) {
      const valueModified = this.currencyIsOriginal ? +this.value : (+this.value / 1e2).toFixed(2);

      if (this.type) {
        this.inputControl.setValue(valueModified);
      } else {
        this.inputControl.setValue(this.value);
      }
    } else {
      this.inputControl.setValue(null);
    }

    if (this.isOptional) {
      this.inputControl.clearValidators();
    }
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return this.currencyIsOriginal ? +value : +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public updateInput(fieldValue): void {
    if (this.type === InputDropdownTypeEnum.CURRENCY && fieldValue) {
      this.fieldChanged.emit(this.toInteger(fieldValue));
    } else {
      this.fieldChanged.emit(fieldValue);
    }
  }

  public updateUnitType(unitType): void {
    this.unitTypeChanged.emit(unitType);
  }

  public isValid(): boolean {
    this.inputControl.markAsDirty();
    this.inputControl.markAsTouched();
    this.inputControl.updateValueAndValidity();
    return this.inputControl.valid;
  }

}
