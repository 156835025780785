import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-view-summary-wrap',
  templateUrl: './view-summary-wrap.component.html'
})
export class ViewSummaryWrapComponent {

  @Input() title: string = 'Summary';

  public isOpen = false;

}
