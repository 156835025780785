import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { ProductsService } from 'common/src/modules/products';
import { ProductTypes } from 'common/src/modules/products/product-types';

@Component({
  selector: 'rnpl-gl-product-type-dropdown',
  templateUrl: './gl-product-type-dropdown.component.html',
  styleUrls: ['./gl-product-type-dropdown.component.scss'],
})
export class GlProductTypeDropdownComponent implements OnInit, OnDestroy, OnChanges {

  private subscriptions: Subscription[] = [];

  @Input() singleProductType?: ProductTypes|string;
  @Input() required = false;
  @Input() showErrors = false;
  @Input() patchSettingsOnChange: boolean;
  @Input() control: FormControl = new FormControl();
  @Input() controlValue: number = null;

  @Input() usedUpControl: FormControl = new FormControl();
  @Input() periodOfUseControl: FormControl = new FormControl();
  @Input() purchaseCreditControl: FormControl = new FormControl();
  @Input() purchaseDebitControl: FormControl = new FormControl();
  @Input() writeOffDebitControl: FormControl = new FormControl();
  @Input() longTermPeriodOfUseControl: FormControl = new FormControl();
  @Input() fundsWriteOffMethodControl: FormControl = new FormControl();
  @Input() fundsWriteOffRateControl: FormControl = new FormControl();

  @Output() fieldUpdated: EventEmitter<number> = new EventEmitter<number>();
  @Output() productUpdated: EventEmitter<any> = new EventEmitter<any>();

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  readonly loadingList$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly generalCategoriesList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly generalCategoriesListFiltered$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  readonly generalProductsList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private productsService: ProductsService,
  ) {
  }

  ngOnInit(): void {
    this.getGeneralProductsListDropdown();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('control')) {
      this.subscriptions.forEach(s => s.unsubscribe());

      const subscription = this.control.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((productId: number) => {
          if (!productId || !this.selectedGLProductType || !this.patchSettingsOnChange) {
            this.clearProductsList();
            return;
          }

          if (this.usedUpControl) { this.usedUpControl.patchValue(this.selectedGLProductType.usedUpByDefault > 1); }
          if (this.purchaseCreditControl) { this.purchaseCreditControl.patchValue(this.selectedGLProductType.glAccountIdPurchaseCredit); }
          if (this.purchaseDebitControl) { this.purchaseDebitControl.patchValue(this.selectedGLProductType.glAccountIdPurchaseDebit); }
          if (this.writeOffDebitControl) { this.writeOffDebitControl.patchValue(this.selectedGLProductType.glAccountWriteOffDebit); }
          if (this.longTermPeriodOfUseControl) { this.longTermPeriodOfUseControl.patchValue(this.selectedGLProductType.longTermPeriodOfUse); }
          if (this.periodOfUseControl) { this.periodOfUseControl.patchValue(this.selectedGLProductType.periodOfUse); }
          if (this.fundsWriteOffMethodControl) { this.fundsWriteOffMethodControl.patchValue(this.selectedGLProductType.fundsWriteOffMethod); }
          if (this.fundsWriteOffRateControl) { this.fundsWriteOffRateControl.patchValue(this.selectedGLProductType.fundsWriteOffRate); }
        });

      this.subscriptions.push(subscription);
    }

    if (changes && changes.hasOwnProperty('controlValue') && this.controlValue) {
      this.control.patchValue(this.controlValue);
    }

    if (changes && changes.hasOwnProperty('singleProductType') && this.singleProductType) {
      this.getAvailableGeneralCategories();
    }
  }

  private getGeneralProductsListDropdown(): void {
    this.productsService.getGeneralProductsListDropdown()
      .pipe(
        finalize(() => this.loadingList$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        const displayedCategories = response
          .filter(category => category.isVisibleForList || category.children.some(child => child.id === this.control.value));
        this.generalCategoriesList$.next(displayedCategories);
        // if (this.singleProductType) {
          this.getAvailableGeneralCategories();
        // }
      });
  }

  public updateField(productId: number): void {
    this.fieldUpdated.emit(productId);
  }

  public updateProduct(product: any): void {
    this.productUpdated.emit(product);
  }

  public selectCategory(category): void {
    this.generalProductsList$.next(category.children);
  }

  public clearProductsList(): void {
    this.generalProductsList$.next([]);
  }

  public getAvailableGeneralCategories(): void {
    const allCategories = this.generalCategoriesList$.getValue();

    if (this.singleProductType) {
      const categories = allCategories
        .map(category => ({
          ...category,
          children: category.children.filter(product => product.entityForm === this.singleProductType)
        }))
        // filter out empty categories
        .filter(category => !!category.children.length);

      this.generalCategoriesListFiltered$.next(categories);
    } else {
      this.generalCategoriesListFiltered$.next(allCategories);
    }
  }

  get selectedGLProductType(): any {
    if (!this.generalCategoriesList$.getValue().length || !this.control.value) { return null; }

    const selectedCategory = this.generalCategoriesList$.getValue()
      .find(category => category.children.find(productType => productType.id === this.control.value));

    if (!selectedCategory) { return null; }

    return selectedCategory.children.find(productType => productType.id === this.control.value);
  }

  public get selectedGLProductTypeUsedUpAllowed(): boolean {
    if (!this.selectedGLProductType) { return false; }
    return this.selectedGLProductType.usedUpAllowed;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
