import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { AppState } from '../../store/state/app.state';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { PredefinedDocumentDataModel, UIStatesEnum } from 'common/src/models';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import {
  ExchangeAvailableSalesDocumentModel,
  ExchangeCountersModel, ExchangeReplacementProductPriceInfoModel,
  ExchangeListModel,
  ExchangeModel,
  ExchangeNewPositionBodyModel,
  ExchangePositionModel,
  ExchangeProductsToReceiptModel,
  ExchangeProductToReceiptModel,
  ExchangeSalesOrderProductModel,
  ResponseExchangeCountersModel
} from '../models';
import { FilterModel } from '../../warehouse/models/filter.model';
import { ExchangeListTabsEnum } from '../enums';
import {
  DecrementLoadingRequestsCount,
  IncrementLoadingRequestsCount,
  LoadExchange,
  LoadExchangesList,
  UpdateExchange,
  UpdateExchangeCurrentState,
  UpdateExchangeUpdatedAt,
  UpdateShouldRefreshEntity
} from '../store/actions/exchange.actions';
import { CommonModalsActionsEnum, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { PurchaseOrder } from '../../purchase-order/models';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';

@Injectable()
export class ExchangeApiService {
  private readonly apiEndpoint: string = '/era';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  createExchangeDraft(data: PredefinedDocumentDataModel = {}): Observable<ExchangeModel> {
    return this.http.post<ResponseModel<ExchangeModel>>(this.apiUrl('/draft'), {...data})
      .pipe(
        map((data: ResponseModel<ExchangeModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  createAcceptExchange(eraId: string | number): Observable<ExchangeModel> {
    return this.http.post<ResponseModel<ExchangeModel>>(this.apiUrl(`/${eraId}/accept-create`), {})
      .pipe(
        map((data: ResponseModel<ExchangeModel>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  getExchangesList(
    status: ExchangeListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updatedAt', value: DEFAULT_SORT_DIRECTION},
    filters: any = {}
  ): Observable<ResponseList<ExchangeListModel>> {
    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<ExchangeListModel>>(
      this.apiUrl(), { params })
      .pipe(tap((data: ResponseList<ExchangeListModel>) => {
        this.store.dispatch(LoadExchangesList({
          exchangesListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getExchangesListPositions(exchangeId: number): Observable<ExchangePositionModel[]> {
    return this.http.get<ResponseModel<ExchangePositionModel[]>>(this.apiUrl(`/${exchangeId}/list/positions`))
      .pipe(map((data: ResponseModel<ExchangePositionModel[]>) => data.data));
  }

  getExchangesRunpleIds(status: ExchangeListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl(`/runpleIds`), { params })
      .pipe(map((data: any) => data.data));
  }

  getExchangeCounters(): Observable<ExchangeCountersModel> {
    return this.http.get<ResponseModel<ResponseExchangeCountersModel>>(this.apiUrl('/counters'))
      .pipe(map((data: ResponseModel<ResponseExchangeCountersModel>) => data.data.era));
  }

  getExchangeAvailableSalesDocuments(): Observable<ExchangeAvailableSalesDocumentModel[]> {
    return this.http.get<ResponseModel<ExchangeAvailableSalesDocumentModel[]>>(this.apiUrl('/sales-orders/available-for-link'))
      .pipe(map((data: ResponseModel<ExchangeAvailableSalesDocumentModel[]>) => data.data));
  }

  getExchangeSalesOrderProducts(exchangeId: number, sort: FilterModel = {nameColumn: 'name', value: DEFAULT_SORT_DIRECTION}): Observable<ExchangeSalesOrderProductModel[]> {
    return this.http.get<ResponseModel<ExchangeSalesOrderProductModel[]>>(this.apiUrl(`/${exchangeId}/sales-orders/products`),
      {
        params: {
          [`sort[${sort.nameColumn}]`]: sort.value
        }
      })
      .pipe(map((data: ResponseModel<ExchangeSalesOrderProductModel[]>) => data.data));
  }

  getExchangeById(id: string | number): Observable<ExchangeModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<ExchangeModel>>(this.apiUrl(`/${id}`))
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(LoadExchange({exchange: response.data as ExchangeModel}));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
          // this.store.dispatch(UpdateTradeOfferPositionsCount({positionsCount: response.data.positionsCount }));
        }),
        map((response: ResponseModel<ExchangeModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updateExchange(exchangeId: number, field: FormInputChangedModel): Observable<ExchangeModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<ExchangeModel>>(this.apiUrl(`/${exchangeId}`), field)
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateExchange({ exchange: response.data }));
        }),
        map((response: ResponseModel<ExchangeModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  addExchangePosition(exchangeId: number, position: ExchangeNewPositionBodyModel): Observable<ResponseList<ExchangePositionModel>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseList<ExchangePositionModel>>(this.apiUrl(`/${exchangeId}/positions`), position)
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  editExchangePosition(exchangeId: number, position: ExchangeNewPositionBodyModel, positionId: number): Observable<ResponseList<ExchangePositionModel>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseList<ExchangePositionModel>>(this.apiUrl(`/positions/${positionId}`), position)
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  deleteExchangePositions(exchangeId: number, ids: number[]): Observable<ResponseModel<null>> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = { ids: ids };

    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${exchangeId}/positions`), {body})
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  changePositionOrder(posId: number, moveToOrder: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(this.apiUrl(`/positions/${posId}/order/${moveToOrder}`), body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  deleteExchangePositionUnit(exchangeId: number, unitId: number): Observable<ResponseModel<null>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${exchangeId}/unit/${unitId}`))
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  getExchangePositions(exchangeId: number): Observable<ResponseList<ExchangePositionModel>> {
    return this.http.get<ResponseList<ExchangePositionModel>>(this.apiUrl(`/${exchangeId}/positions`))
      .pipe(
        // todo
        // tap((response: ResponseList<ExchangePositionModel>) => {
        //   this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
        //   // this.store.dispatch(UpdateExchange({ exchange: response.data }));
        // }),
        // finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  getExchangeProductsToReceipt(exchangeId: number): Observable<ExchangeProductsToReceiptModel> {
    return this.http.get<ResponseModel<ExchangeProductsToReceiptModel>>(this.apiUrl(`/${exchangeId}/confirmation/positions`))
      .pipe(map((data: ResponseModel<ExchangeProductsToReceiptModel>) => data.data));
  }

  getReplacementProductsPriceInfo(soPositionId: number, replacementProductId: number, quantity): Observable<ExchangeReplacementProductPriceInfoModel> {
    return this.http.get<ResponseModel<ExchangeReplacementProductPriceInfoModel>>(
      `/so-position/${soPositionId}/replacement-product/${replacementProductId}/info`,
      {
        params: {
          quantity
        }
      }
    )
      .pipe(map((data: ResponseModel<ExchangeReplacementProductPriceInfoModel>) => data.data));
  }

  confirmExchangeProducts(exchangeId: number, body: ExchangeProductsToReceiptModel): Observable<any> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<any>(
      'post',
      this.apiUrl(`/${exchangeId}/positions/confirm`),
      {body}
    ).pipe(
      tap(() => {
        this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, exchangeId);
        return throwError(error);
      })
    );
  }

  returnExchangeProducts(exchangeId: number, body: ExchangeProductsToReceiptModel): Observable<ResponseList<ExchangePositionModel>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseList<ExchangePositionModel>>(
      'post',
      this.apiUrl(`/${exchangeId}/positions/return`),
      {body}
    ).pipe(
      tap(() => {
        this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
    );
  }

  returnExchangeReceiptProduct(positionId: number): Observable<ExchangeProductToReceiptModel> {
    return this.http.request<ResponseModel<ExchangeProductToReceiptModel>>(
      'post',
      this.apiUrl(`/positions/${positionId}/return`)
    ).pipe(map((data: ResponseModel<ExchangeProductToReceiptModel>) => data.data));
  }

  changeExchangeStatus(status: ExchangeListTabsEnum, exchangeId: number): Observable<ExchangeModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status };
    return this.http
      .patch(this.apiUrl(`/${exchangeId}/status`), body)
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateExchange({ exchange: response.data }));
        }),
        map((data: ResponseModel<ExchangeModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  @DisplayToaster({showErrorMessage: true})
  deleteExchangePermanently(exchangeId: number): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${exchangeId}`));
  }

  public exchangeSetEdit(id: string | number, force = false): Observable<ExchangeModel> {
    const body = { force };

    return this.http.put<ResponseModel<ExchangeModel>>(this.apiUrl(`/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(UpdateExchange({ exchange: response.data }));
          this.store.dispatch(UpdateExchangeCurrentState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<ExchangeModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public exchangeUnsetEdit(id: string | number): Observable<ExchangeModel> {
    return this.http.request<ResponseModel<ExchangeModel>>('put', this.apiUrl(`/${id}/unlocking`))
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(UpdateExchange({ exchange: response.data }));
          this.store.dispatch(UpdateExchangeCurrentState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<ExchangeModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  updateReplacementDeliveryAddressByCustomer(exchangeId: number): Observable<ExchangeModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<ExchangeModel>>('put', this.apiUrl(`/${exchangeId}/delivery-address/update-by-customer`))
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateExchange({ exchange: response.data }));
        }),
        map((response: ResponseModel<ExchangeModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  updateReplacementPickUpAddressByWarehouse(exchangeId: number): Observable<ExchangeModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<ExchangeModel>>('put', this.apiUrl(`/${exchangeId}/pickup-address/update-by-warehouse`))
      .pipe(
        tap((response: ResponseModel<ExchangeModel>) => {
          this.store.dispatch(UpdateExchangeUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateExchange({ exchange: response.data }));
        }),
        map((response: ResponseModel<ExchangeModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, exchangeId);
          return throwError(error);
        })
      );
  }

  changeExchangeStatusBatch(status: ExchangeListTabsEnum, ids: number[]): Observable<any> {
    const body = {status, ids};
    return this.http.patch(this.apiUrl('/status'), body);
  }

  deleteExchangesPermanentlyBatch(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(), {body});
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, exchangeId?: number | string): void {
    switch (error.error.message) {
      case 'anotherUserEditError':
      {
        const dialog = this.dialog.open(WarningModalComponent, {
          data: getAnotherUserEditErrorModalData(
            {
              document: error.error.data.entityName,
              userName: error.error.data.userName,
            },
            this.translateService
          )
        });

        dialog.afterClosed().subscribe(res => {
          if (res === CommonModalsActionsEnum.CONFIRM) {
            this.exchangeSetEdit(exchangeId, true).subscribe();
          }
        });
      }
        break;
      case 'notEditModeError':
        const documentName = this.translateService.instant('APP.ERA');
        this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
        this.store.dispatch(UpdateExchangeCurrentState({ currentState: UIStatesEnum.VIEW }));
        this.getExchangeById(exchangeId).subscribe();
        break;
      default:
        this.showMsg('error', error.error.message || error.error.errors);
        break
    }
  }

  public getExchangeListExport(status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv`),
      type: 'zip',
    };
    return of(fileParams);
  }

}
