import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { isEmpty } from 'lodash';

import { CommonModalsActionsEnum, InfoModalComponent } from 'common/src/modules/modals/modals-common';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SubscriptionPlanEnum } from '../modules/subscription-activation/subscription-activation.emum';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PlanUpgradingRequireModalConfig } from '../../../projects/workspace/src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FreePlanLimitationGuard implements CanActivate {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly store: Store<AppState>
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select(selectCompanyProfile).pipe(
      first(),
      switchMap((companyProfile: CompanyProfile) => {
        return isEmpty(companyProfile) ? this.administrationsApiService.getCompanyProfile() : of(companyProfile);
      }),
      catchError(() => {
        return of(false);
      }),
      switchMap((companyProfile: CompanyProfile) => {
        if (companyProfile.subscriptionManagement.subscriptionPlanActive === SubscriptionPlanEnum.FREE) {
          this.dialog.open(InfoModalComponent, { // todo: add common service to manage modal
            data: PlanUpgradingRequireModalConfig
          }).afterClosed()
            .subscribe(response => {
              if (response === CommonModalsActionsEnum.CONFIRM) {
                this.router.navigate(['/administration/subscription-management'])
              }
            });
          return of(false);
        } else {
          return of(true);
        }
      }),
    );
  }

}
