import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { InitialSetupBaseComponent } from '../initial-setup-base/initial-setup-base.component';
// import { TableActivateTypes } from '../../../ui-components/table/custom-table.enums';
// import { TableColumnModel } from '../../../../models/table-column.model';
// import { getApplicationsColumnInnerList, getApplicationsColumnList, getApplicationsList } from './sales-chanels.config';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { selectCompanyProfile } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import {
  SubscriptionPricesConfigModel
} from 'projects/workspace/src/app/administration/models/subscription-prices-config.model';

@Component({
  selector: 'rnpl-sales-channels',
  templateUrl: './sales-channels.component.html',
})
export class SalesChannelsComponent extends InitialSetupBaseComponent {

  public form: FormGroup;
  public billingDetailsForm: FormGroup;
  // public columns: TableColumnModel[] = getApplicationsColumnList();
  // public innerTableColumns: TableColumnModel[] = getApplicationsColumnInnerList();
  // readonly selectedInnerTableData$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  // public applicationsList = [];
  public subscriptionPricesConfig: SubscriptionPricesConfigModel;

  @ViewChild('tableComponent', {static: false}) tableComponentRef;
  @ViewChild('subscriptionPlanForm', {static: false}) subscriptionPlanFormRef;

  constructor(
    public fb: FormBuilder,
    public readonly store: Store<AppState>,
    public router: Router,
    // public translate: TranslateService,
    public readonly administrationsApiService: AdministrationsApiService,
  ) {
    super(store, router, administrationsApiService);

    this.administrationsApiService.getCompanySubscriptionPricesConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((pricesConfig: SubscriptionPricesConfigModel) => this.subscriptionPricesConfig = pricesConfig);
  }

  ngOnInit() {
    this.initForm();
    this.initBillingDetailsForm();

    this.store.select(selectCompanyProfile)
      .pipe(takeUntil(this.destroy$))
      .subscribe((companyProfile: CompanyProfile) => {
        if (companyProfile) {
          this.form.patchValue(companyProfile.subscriptionManagement, {emitEvent: false});
          this.billingDetailsForm.patchValue(companyProfile.billingDetails);
          // this.updateApplicationList();
        }
      });
  }

  public initBillingDetailsForm(): void {
    this.billingDetailsForm = this.fb.group({
      billingFrequency: [],
    });
  }

  public initForm(): void {
    this.form = this.fb.group({
      userChannelOptions: this.fb.group({
        wholesalesEnabled: [],
        ecommerceEnabled: [],
        servicesEnabled: [],
      }),
      subscriptionPlan: []
    });
  //
  //   // this.form.valueChanges
  //   //   .pipe(takeUntil(this.destroy$))
  //   //   .subscribe(() => this.updateApplicationList());
  }

  // rowClickReceiver(event): void {
  //   if (event.type === TableActivateTypes.RowDetail) {
  //     this.selectedInnerTableData$.next(this.prepareInnerTableColumns(event.row));
  //   }
  // }

  // private prepareInnerTableColumns(data: any): any[] {
  //   return data.innerData.map((row) => {
  //     const rowData = {
  //       ...row,
  //       appName: this.translate.instant(row.appName),
  //       status: {
  //         icon: get(row, 'status.icon', data.status.icon),
  //         iconClass: get(row, 'status.iconClass', data.status.iconClass),
  //       },
  //     };
  //
  //     return rowData;
  //   });
  // }

  // private updateApplicationList(): void {
  //   this.applicationsList = getApplicationsList(
  //     this.wholesaleControl.value,
  //     this.eCommerceControl.value,
  //     this.servicesControl.value
  //   );
  //
  //   if (this.tableComponentRef) {
  //     this.tableComponentRef.collapseAll();
  //   }
  // }

  public updateStep$(): Observable<boolean | CompanyProfile> {
    if (!this.getStepValidation()) {
      return of(false);
    }
    const formValue = this.form.getRawValue();
    const company = {
      ...this.companyProfile,
      subscriptionManagement: {
        ...this.companyProfile.subscriptionManagement,
        subscriptionPlan: formValue.subscriptionPlan,
        userChannelOptions: {
          ...this.companyProfile.subscriptionManagement.userChannelOptions,
          ...formValue.userChannelOptions
        }
      }
    };
    return this.updateCompanyInfo$(company);
  }

  public getStepValidation(): boolean {
    this.billingDetailsForm.markAllAsTouched();
    this.billingDetailsForm.markAsDirty();
    this.billingDetailsForm.updateValueAndValidity();
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
    return !this.subscriptionPlanFormRef.salesChannelsValidation();
  }

}
