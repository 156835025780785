import { Component, Input } from '@angular/core';
import { ScontoModel, ScontoRateTypeEnum } from 'common/src/models';

@Component({
  selector: 'rnpl-sconto-summary-block',
  templateUrl: './sconto-summary-block.component.html'
})
export class ScontoSummaryBlockComponent {
  @Input() sconto: ScontoModel;
  @Input() currency: string = ' €';

  public scontoRateTypeEnum = ScontoRateTypeEnum;
}
