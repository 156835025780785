import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

import { TableColumnModelExtended } from '../../../../../models/table-column.model';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-column-with-form-textarea',
  templateUrl: './column-with-form-textarea.component.html',
  styleUrls: ['./column-with-form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnWithFormTextareaComponent implements OnInit, OnChanges, OnDestroy {

  public controlTextarea: FormControl = new FormControl(null, {updateOn: 'blur'});
  @Input() value: string;
  @Input() rowIndex: number;
  @Input() row: any;
  @Input() column: TableColumnModelExtended;
  @Input() disabled?: boolean;
  @Input() required?: boolean;

  @Output() updateValue: EventEmitter<any> = new EventEmitter<any>();

  protected destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @TrackInputChanges<string>('value', 'setValue', ChangesStrategy.Each)
  @TrackInputChanges<string>('disabled', 'setFormState', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.controlTextarea.valueChanges
      .pipe(
        distinctUntilChanged(),
        // debounceTime(500), Unused, updated by blur
        takeUntil(this.destroy$)
      )
        .subscribe(value => {
        this.updateValueEmit(value);
      });

    if (get(this.column, 'required', false) && !this.value) {
      this.controlTextarea.setValidators([Validators.required]);
    }
  }

  updateValueEmit(value: string): void {
    this.updateValue.emit({
      value,
      cell: this.column.prop,
      rowIndex: this.rowIndex
    });
  }

  public setValue(): void {
    this.controlTextarea.setValue(this.value, {emitEvent: false});
  }

  public setFormState(): void {
    if (this.disabled) {
      this.controlTextarea.disable();
    } else {
      this.controlTextarea.enable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
