import { createAction, props } from '@ngrx/store';

import { UIStatesEnum } from 'common/src/models';
import { ExchangeModel } from '../../models/exchange.model';
import { ExchangeListTabsEnum } from '../../enums';
import { ResponseModel } from '../../../shared/models/response';
import { ExchangeListState } from '../reducers';
import { CountryModel } from 'common/src/modules/rnpl-common/models';

export const LoadExchangesList = createAction(
  '[Exchange] Load Exchanges List',
  props<{ exchangesListData: ExchangeListState, status: ExchangeListTabsEnum, page: number }>()
);

export const LoadExchangeCountries = createAction(
  '[Exchange] Load Exchange Countries',
  props<{ countries: CountryModel[] }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Exchange] Update Exchange should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const LoadExchange = createAction(
  '[Exchange] Load Exchange',
  props<{ exchange: ExchangeModel }>()
);

export const LoadExchangePositions = createAction(
  '[Exchange Positions] Load Exchange Positions',
  props<{ positions: ResponseModel<any> }>()
);

export const UpdateExchange = createAction(
  '[Exchange] Update Exchange',
  props<{ exchange: ExchangeModel }>()
);

export const ClearExchange = createAction(
  '[Exchange] Clear Exchange'
);

export const ClearExchangeState = createAction(
  '[Exchange] Clear Exchange state'
);

export const ClearExchangePositions = createAction(
  '[Exchange] Clear Exchange positions'
);

export const UpdateExchangeCurrentState = createAction(
  '[Exchange] Update Exchange Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const UpdateExchangeStatus = createAction(
  '[Exchange] Update Exchange Status',
  props<{ status: ExchangeListTabsEnum }>()
);

export const UpdateExchangeUpdatedAt = createAction(
  '[Exchange] Update Exchange updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearExchangeUpdatedAt = createAction(
  '[Exchange] Clear Exchange updatedAt',
);

export const IncrementLoadingRequestsCount = createAction(
  '[Exchange] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Exchange] Decrement Loading Requests Count'
);


export const UpdateBillingBlockValid = createAction(
  '[Exchange] Update Exchange Billing Block Valid',
  props<{ billingBlockValid: boolean }>()
);

export const UpdateDeliveryBlockValid = createAction(
  '[Exchange] Update Exchange Delivery Block Valid',
  props<{ deliveryBlockValid: boolean }>()
);
