import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, finalize, startWith, takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { get, isEqual } from 'lodash';

import { CollectiveFields, FamilyModel } from '../models';
import { ProductsService } from '../products.service';
import { ToasterService } from '../../ui-components/toaster';
import { AssignedAttributeModel } from '../../system-settings/models';
import { Wizard } from '../../wizard/wizard';
import { WizardService } from '../../wizard/wizard.service';

export interface FamilyWizardContext {
  family: FamilyModel;
  assignedAttributes?: Array<AssignedAttributeModel>;
  assignedToProductAttrs?: Array<AssignedAttributeModel>;
  changed?: boolean;
  isRoot?: boolean;
}

@Component({
  selector: 'rnpl-family-wizard',
  templateUrl: './family-wizard.component.html',
  styleUrls: ['./family-wizard.component.scss']
})
export class FamilyWizardComponent extends Wizard implements OnInit, OnDestroy {

  private productFormKey: string;

  public step: string;

  public parentId;

  public parentProductControls: Array<any> = [];

  public family: FamilyModel;

  public form: FormGroup;

  public assignedToProductControl: FormControl = new FormControl([]);

  public assignedToProductAttrs: Array<AssignedAttributeModel> = [];

  public productCollectiveFields: CollectiveFields = {};

  public displayHints: boolean = false;

  public dialogRef: MatDialogRef<TemplateRef<any>>;
  public accountingSettingsChangedDialogRef: MatDialogRef<TemplateRef<any>>;

  private changed: boolean = false;

  private formsSubscriptions: Subscription[] = [];

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  readonly completedRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  @ViewChild('closingPopup', {static: true}) private closingPopupTpl: TemplateRef<any>;
  @ViewChild('accountingSettingsChangedPopup', {static: true}) private accountingSettingsChangedPopupTpl: TemplateRef<any>;
  @ViewChild('productLayoutComponent', {static: true}) private productLayoutComponentTpl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private wizardService: WizardService,
    private productsService: ProductsService,
    private toasterService: ToasterService,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.step = params.get('step');
      });

    const context = this.wizardService.getContext<FamilyWizardContext>('family');
    if (!context) {
      this.initForm();
      console.warn(`Family context wasn't find.`);
      return;
    }
    this.goToStep(context.step);
    this.changed = get(context, 'data.changed', false);
    this.family = get(context, 'data.family', null);
    this.parentId = get(this, 'family.parent_id');
    if (this.family.id) {
      this.loadFamily(this.family.id);
    }
    this.initForm(this.family);

    const attributesProduct = context.data.assignedToProductAttrs;
    if (attributesProduct) {
      this.assignedToProductAttrs = attributesProduct;
      this.assignedToProductControl.setValue(attributesProduct, {emitEvent: false});
      this.assignedToProductControl.markAsDirty({onlySelf: true});
      this.assignedToProductControl.markAsTouched({onlySelf: true});
    }

    this.assignedToProductControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(changedAttrs => {
        this.assignedToProductAttrs = changedAttrs;
        this.wizardService.patchContextData('family', {assignedToProductAttrs: changedAttrs, changed: true});
        this.changed = true;
      });

    if (!this.family.parent_id) {
      return;
    }

    this.initFormsKeys(this.family.products_type);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public initFormsKeys(productType: string): void {
    this.productFormKey = `product_${productType}_create`;
  }

  /**
   * Navigation function
   *
   * @param step Step that should be displayed
   */
  public goToStep = (step: string) => {
    this.wizardService.setWizardStep('family', step);

    this.router.navigate(['../', step], { relativeTo: this.route });
  }

  public close(): void {
    if (!this.changed) {
      this.wizardService.close('family');
      this.closed.emit();
      return;
    }

    this.dialogRef = this.dialog.open(this.closingPopupTpl, {
      width: 'auto',
      position: {top: '64px'},
      disableClose: true,
    });
  }

  public confirmClosing(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.wizardService.close('family');
    this.closed.emit();
  }

  public loadFamily(familyId): void {
    this.productsService.getFamily(familyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.family = {...response.data, ...this.family};
        this.titleService.setTitle(this.family.title);
        this.initForm(this.family);
        this.initFormsKeys(this.family.products_type);
        this.getAssignedAttrsByFormKey();
      });
  }

  public getAssignedAttrsByFormKey(): void {
    forkJoin({
      product: this.productsService.getAssignedFamilyAttributes(this.productFormKey, this.family.id)
    })
    .pipe(takeUntil(this.destroy$))
    .subscribe((response) => {
      if (!this.assignedToProductAttrs.length) {
        this.assignedToProductAttrs = response.product;
      }
      this.assignedToProductControl.patchValue(this.assignedToProductAttrs, {emitEvent: false});
    });
  }

  public save(ignoreAccountingSettingsChanges = false): void {
    if (!ignoreAccountingSettingsChanges && this.family) {
      if (
        this.family.gl_product_type_id !== this.form.getRawValue().gl_product_type_id ||
        this.family.gl_account_id_purchase_debit !== this.form.getRawValue().gl_account_id_purchase_debit ||
        this.family.gl_account_id_purchase_credit !== this.form.getRawValue().gl_account_id_purchase_credit ||
        this.family.gl_account_write_off_debit !== this.form.getRawValue().gl_account_write_off_debit ||
        this.family.long_term_period_of_use !== this.form.getRawValue().long_term_period_of_use ||
        this.family.period_of_use !== this.form.getRawValue().period_of_use ||
        this.family.funds_write_off_method !== this.form.getRawValue().funds_write_off_method ||
        this.family.funds_write_off_rate !== this.form.getRawValue().funds_write_off_rate
      ) {
        this.accountingSettingsChangedDialogRef = this.dialog.open(this.accountingSettingsChangedPopupTpl, {
          width: 'auto',
          position: {top: '64px'},
          disableClose: true,
        });
        return;
      }
    }

    if (this.accountingSettingsChangedDialogRef) {
      this.accountingSettingsChangedDialogRef.close();
    }

    if (this.completedRequest$.getValue()) { return; }
    this.assignedToProductAttrs = this.productLayoutComponentTpl.assignedAttributes;
    this.productCollectiveFields =  this.productLayoutComponentTpl.collectiveFields;
    this.completedRequest$.next(true);

    this.family = this.formToFamily(this.form);

    forkJoin([
      this.productsService.updateFamily(this.family),
      this.productsService.assignFamilyAttributes(
        this.productFormKey,
        this.family.id,
        this.assignedToProductAttrs
      )
    ])
      .pipe(
        finalize(() => this.completedRequest$.next(false)),
        takeUntil(this.destroy$))
      .subscribe(() => {
        this.checkForCollectiveFields();
      }, error => {
        this.toasterService.notify({type: 'error', message: error.error.message});
      });
  }

  public checkForCollectiveFields(): void {
    let requests$ = [];

    if (this.productCollectiveFields !== {}) {
      requests$ = requests$
        .concat(this.getCollectiveFieldsRequest(this.productCollectiveFields, this.productFormKey));
    }

    if (!requests$.length) {
      this.successClosing();
      return;
    }

    forkJoin(requests$)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.successClosing();
      }, error => {
        this.toasterService.notify({type: 'error', message: error.error.message});
      });
  }

  public getCollectiveFieldsRequest(fields: CollectiveFields, formKey: string): Observable<any>[] {
    const requests$ = [];
    for (const collectiveField in fields) {
      if (fields.hasOwnProperty(collectiveField)) {
        const ids = fields[collectiveField].map(field => Number(field.id));
        requests$.push(this.productsService.addPropertiesToCollectiveField(formKey, this.family.id, collectiveField, ids));
      }
    }
    return requests$;
  }

  public successClosing(): void {
    this.toasterService.notify({
      type: 'success',
      message: this.translateService.instant('PRODUCTS.CATEGORY_SAVED', { name: this.family.translations[0].title })
    });
    this.confirmClosing();
  }

  private initForm(family?: FamilyModel): void {
    this.formsSubscriptions.forEach(s => s.unsubscribe());

    family = family || ({} as FamilyModel);
    const isDisabled = family && family.type === 'hardcoded';

    this.form = this.fb.group({
      'name': [{value: null, disabled: isDisabled}, [Validators.required]],
      'gl_product_type_id': [get(family, 'gl_product_type_id')],
      'gl_account_id_purchase_credit': [get(family, 'gl_account_id_purchase_credit')],
      'gl_account_id_purchase_debit': [get(family, 'gl_account_id_purchase_debit')],
      'gl_account_write_off_debit': [get(family, 'gl_account_write_off_debit')],
      'long_term_period_of_use': [get(family, 'long_term_period_of_use')],
      'period_of_use': [get(family, 'period_of_use')],
      'funds_write_off_method': [get(family, 'funds_write_off_method')],
      'funds_write_off_rate': [get(family, 'funds_write_off_rate')],
      'product_creation_forbidden': [get(family, 'product_creation_forbidden', false)],
      'description': [{value: '', disabled: isDisabled}, []],
      // 'type': [family.type, [Validators.required]],
    });

    this.setGlFormState();

    if (family.translations && family.translations.length) {
      const familyName = isDisabled
        ? family.title
        : family.translations[0].title;
      this.form.get('name').setValue(familyName, {emitEvent: false});
      this.form.get('description').setValue(family.translations[0].description, {emitEvent: false});

      this.form.get('name').markAsDirty();
      // this.form.get('type').markAsDirty();
    }

    const formSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe(form => {
        this.changed = true;
        family.translations = family.translations || [{} as any];
        family.translations[0].title = form.name;
        family.translations[0].description = form.description;
        // family.type = form.type;
        this.wizardService.patchContextData('family', {family: family, changed: true});
        this.setGlFormState();
      });

    // const productCreationSubscription = this.productCreationForbiddenControl.valueChanges
    //   .pipe(
    //     startWith(this.productCreationForbiddenControl.value),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((value: boolean) => {
    //     if (value) {
    //       this.glAccountIdPurchaseDebitControl.disable({emitEvent: false});
    //       this.glAccountIdPurchaseCreditControl.disable({emitEvent: false});
    //     } {
    //       this.glAccountIdPurchaseDebitControl.enable({emitEvent: false});
    //       this.glAccountIdPurchaseCreditControl.enable({emitEvent: false});
    //     }
    //   });

    this.formsSubscriptions.push(formSubscription);
    // this.formsSubscriptions.push(productCreationSubscription);
  }

  private setGlFormState(): void {
    if (this.form.get('gl_product_type_id').value) {
      this.form.get('long_term_period_of_use').enable({emitEvent: false});
      this.form.get('period_of_use').enable({emitEvent: false});
      this.form.get('funds_write_off_method').enable({emitEvent: false});
      this.form.get('funds_write_off_rate').enable({emitEvent: false});
    } else {
      this.form.get('long_term_period_of_use').disable({emitEvent: false});
      this.form.get('period_of_use').disable({emitEvent: false});
      this.form.get('funds_write_off_method').disable({emitEvent: false});
      this.form.get('funds_write_off_rate').disable({emitEvent: false});
    }
  }

  private formToFamily(form: FormGroup): FamilyModel {
    let family: FamilyModel = {
      gl_account_id_purchase_credit: form.get('gl_account_id_purchase_credit').value,
      gl_account_id_purchase_debit: form.get('gl_account_id_purchase_debit').value,
      gl_product_type_id: form.get('gl_product_type_id').value,
      gl_account_write_off_debit: form.get('gl_account_write_off_debit').value,
      product_creation_forbidden: form.get('product_creation_forbidden').value,
      long_term_period_of_use: form.get('long_term_period_of_use').value,
      period_of_use: form.get('period_of_use').value,
      funds_write_off_method: form.get('funds_write_off_method').value,
      funds_write_off_rate: form.get('funds_write_off_rate').value,
      // type: form.get('type').value,
      translations: [
        {
          language: {code_iso3: 'eng'},
          title: form.get('name').value,
          description: form.get('description').value
        }
      ]
    };
    family = {...this.family, ...family};

    if (!family.parent_id && this.parentId) {
      family.parent_id = this.parentId;
    }

    return family;
  }

  // public get glAccountIdPurchaseDebitControl(): FormControl { return this.form.get('gl_account_id_purchase_debit') as FormControl; }
  // public get glAccountIdPurchaseCreditControl(): FormControl { return this.form.get('gl_product_type_id') as FormControl; }
  // public get productCreationForbiddenControl(): FormControl { return this.form.get('product_creation_forbidden') as FormControl; }

}
