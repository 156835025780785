import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { SalesPriceListControlComponent } from './sales-price-list-control.component';

@NgModule({
  declarations: [
    SalesPriceListControlComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    TranslateModule,
  ],
  exports: [
    SalesPriceListControlComponent,
  ],
  entryComponents: [SalesPriceListControlComponent],
})
export class SalesPriceListControlModule { }
