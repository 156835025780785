import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';


import { BaseFormComponent } from '../base-form.component';
import { PartnerContactModel } from '../../../models';

@Component({
  selector: 'rnpl-partner-contact-form',
  templateUrl: './partner-contact-form.component.html'
})
export class PartnerContactFormComponent extends BaseFormComponent implements OnInit, OnDestroy {

  public titleItems: Array<string> = [
    'Dr.',
    'Prof.',
    'Prof. Dr.',
    'Ing.',
    'Dipl. -ing',
    'Mag.'
  ];

  public positionItems: Array<string> = [
    'CEO',
    'CFO',
    'CTO',
    'Head of Department',
    'Team Lead',
    'Project Manager',
    'Recruiter',
    'Accountant',
    'Developer'
  ];

  public departmentItems: Array<string> = [
    'Accounting',
    'Warehouse',
    'Trade',
    'Support',
    'IT'
  ];

  @Input()
  contact: PartnerContactModel;

  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm(this.contact);
  }

  private initControlVal(contact, attr: string, defaultVal): any {
    return (contact && contact[attr]) ? contact[attr] : defaultVal;
  }

  initForm(contact = {} as PartnerContactModel): void {
    this.form = this.fb.group({
      id: [this.initControlVal(contact, 'id', null), []],
      first_name: [this.initControlVal(contact, 'first_name', ''), [Validators.required]],
      last_name: [this.initControlVal(contact, 'last_name', ''), [Validators.required]],
      phone: [this.initControlVal(contact, 'phone', ''), []],
      email: [this.initControlVal(contact, 'email', ''), [Validators.email]],
      title: [this.initControlVal(contact, 'title', null), []],
      birthday: [this.initControlVal(contact, 'birthday', ''), []],
      gender: [this.initControlVal(contact, 'gender', 'Other'), [Validators.required]],
      department: [this.initControlVal(contact, 'department', null)],
      position: [this.initControlVal(contact, 'position', null)]
    });

    super.initForm();

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(value => this.changed.emit(value));
  }

  get birthday() { return this.form.get('birthday'); }

  get department() { return this.form.get('department'); }

  get position() { return this.form.get('position'); }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }
}
