import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'countryImageSrc'})
export class CountryImagePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const processedValue = value.split(' ').join('-').toLowerCase();
    return `/assets/img/countries/${processedValue}.svg`;
  }
}

@Pipe({name: 'langImageSrc'})
export class LangImagePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return `/assets/img/langs/${value}.svg`;
  }
}

@Pipe({name: 'currencyImageSrc'})
export class CurrencyImagePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return `/assets/img/currencies/${value}.svg`;
  }
}
