import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash';

import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { MetricConversionPipe } from '../../../../rnpl-common';
import { ExchangePositionModel, ExchangeSalesOrderProductModel } from 'projects/workspace/src/app/exchange/models';

@Component({
  selector: 'rnpl-exchange-add-product-compensation',
  templateUrl: './exchange-add-product-compensation.component.html',
})
export class ExchangeAddProductCompensationComponent implements OnChanges, OnDestroy {
  @Input() quantity: number = 0;
  @Input() waPurchasePrice: number = null;
  @Input() deliveredProduct: ExchangeSalesOrderProductModel = null;

  @Input() set position(position: ExchangePositionModel) {
    if (position) {
      if (position.compensationAmount) {
        this.compensationAmountControl.setValue(
          (position.compensationAmount || position.compensationAmount === 0) ? +(position.compensationAmount / 1e2).toFixed(2) : null
        );
      }
      if (position.compensationAmountRate) {
        this.compensationAmountRateControl.setValue(position.compensationAmountRate);
      }
    }
  }

  @Output() compensationFormUpdated: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup = this.fb.group({
    compensationAmount: [],
    compensationAmountRate: [],
  });

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @TrackInputChanges<string | number>('quantity', 'quantityFilled', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void { }

  constructor(
    public metricPipe: MetricConversionPipe,
    public fb: FormBuilder
  ) {
    this.compensationAmountControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.compensationAmountFilled(value));

    this.compensationAmountRateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.compensationRateFilled(value));

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.outputFormValue());
  }

  public compensationAmountFilled(amount: number): void {
    if ((!amount && amount !== 0) || !this.authorizationAmountGross) { return; }

    this.compensationAmountRateControl.setValue(
      ((this.compensationAmountControl.value / this.authorizationAmountGross * 100) * 100).toFixed(2),
      {emitEvent: false}
      );
  }

  public compensationRateFilled(rate: number): void {
    if ((!rate && rate !== 0) || !this.authorizationAmountGross) { return; }

    this.compensationAmountControl.setValue(((this.authorizationAmountGross / 100 * rate) / 100).toFixed(2), {emitEvent: false});
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public outputFormValue(): void {
    const formValue = this.form.getRawValue();
    const convertedFormValue = {
      compensationAmount: formValue.compensationAmount ? this.toInteger(formValue.compensationAmount) : null,
      compensationAmountRate: formValue.compensationAmountRate,
      isEstimatedMarginNegative: this.estimatedMarginAbsolute < 0
    };

    this.compensationFormUpdated.emit(convertedFormValue);
  }

  get compensationAmountControl(): FormControl { return this.form.get('compensationAmount') as FormControl; }
  get compensationAmountRateControl(): FormControl { return this.form.get('compensationAmountRate') as FormControl; }

  get authorizationAmountGross(): number {
    if (!this.quantity || !get(this, 'deliveredProduct.grossUnitPrice')) { return null; }

    return this.deliveredProduct.grossUnitPrice * this.quantity;
  }

  get estimatedMarginAbsolute(): number {
    if (
      !this.quantity ||
      !get(this, 'deliveredProduct.marginNet') ||
      (!this.compensationAmountControl.value && (this.compensationAmountControl.value !== 0))
    ) {
      return null;
    }

    return this.deliveredProduct.marginNet * this.quantity - this.toInteger(this.compensationAmountControl.value);
  }

  get estimatedMarginPercents(): number {
    if (!this.quantity || !this.estimatedMarginAbsolute || !this.waPurchasePrice) {
      return null;
    }

    return +(this.estimatedMarginAbsolute / this.quantity / this.waPurchasePrice * 100).toFixed(2);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

