import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';

import { ColumnsSettingsComponent } from './columns-settings.component';
import { UserColumnsSettingsService } from '../../services';


@NgModule({
  declarations: [
    ColumnsSettingsComponent
  ],
  exports: [
    ColumnsSettingsComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    TranslateModule,
    NgSelectModule
  ],
  providers: [
    UserColumnsSettingsService
  ]
})
export class ColumnsSettingsModule { }
