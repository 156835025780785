import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
// import { Store } from '@ngrx/store';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
// import { selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
// import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { CustomSearchFn } from 'common/src/modules/rnpl-common/helpers';
import { ScheduledPaymentApiService } from 'projects/workspace/src/app/scheduled-payment/services/scheduled-payment-api.service';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { ScheduledPaymentBillingPeriodModel, ScheduledPaymentModel } from 'projects/workspace/src/app/scheduled-payment/models';
import { PartnersTypeEnum } from '../../../../../../projects/workspace/src/app/partners/corporate/enums';

@Component({
  selector: 'rnpl-link-sp-modal-modal',
  templateUrl: './link-scheduled-payment-modal.component.html',
  styleUrls: ['./link-scheduled-payment-modal.component.scss']
})
export class LinkScheduledPaymentModalComponent extends BaseModalComponent implements OnInit {

  public scheduledPaymentsList:  Partial<ScheduledPaymentModel>[] = [];
  public scheduledPaymentBillingPeriodsList:  ScheduledPaymentBillingPeriodModel[] = [];
  public selectedScheduledPayment:  Partial<ScheduledPaymentModel> = null;
  public selectedBillingPeriod:  ScheduledPaymentBillingPeriodModel = null;

  public customSearchFn = CustomSearchFn;

  // readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);
  readonly scheduledPaymentsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly scheduledPaymentPeriodsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly linkDocumentRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(
    public toasterService: ToasterService,
    // private readonly store: Store<AppState>,
    public dialogRef: MatDialogRef<LinkScheduledPaymentModalComponent>,
    private scheduledPaymentApiService: ScheduledPaymentApiService,
    @Inject(MAT_DIALOG_DATA) public data: {
      incomingInvoiceId: number;
      // partnerId: number;
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.getAvailableScheduledPayments();

    // this.router.events
    //   .pipe(
    //     filter((event: RouterEvent) => event instanceof NavigationStart),
    //     filter(() => !!this.dialogRef),
    //     takeUntil(this._destroy)
    //   )
    //   .subscribe(() => {
    //     this.dialogRef.close();
    //   });
  }

  private getAvailableScheduledPayments(): void {
    this.scheduledPaymentsLoading$.next(true);
    this.scheduledPaymentApiService.getAvailableForLinkScheduledPayments(this.data.incomingInvoiceId, DocumentTypesUppercaseEnum.IIN)
      .pipe(
        finalize(() => this.scheduledPaymentsLoading$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((scheduledPaymentsList:  Partial<ScheduledPaymentModel>[]) => {
        this.scheduledPaymentsList = this.prepareScheduledPaymentsList(scheduledPaymentsList);
      });
  }

  public getBillingPeriods(): void {
    this.selectedBillingPeriod = null;
    this.scheduledPaymentsLoading$.next(true);
    this.scheduledPaymentApiService.getScheduledPaymentNotBilledPeriods(this.selectedScheduledPayment.id)
      .pipe(
        finalize(() => this.scheduledPaymentsLoading$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((periods:  ScheduledPaymentBillingPeriodModel[]) => {
        this.scheduledPaymentBillingPeriodsList = this.prepareBillingPeriodsList(periods);
        if (this.scheduledPaymentBillingPeriodsList.length === 1) {
          this.selectedBillingPeriod = this.scheduledPaymentBillingPeriodsList[0];
        }
      });
  }

  public prepareScheduledPaymentsList(scheduledPayments): Partial<ScheduledPaymentModel>[] {
    return scheduledPayments.map(item => {
      return {
        ...item,
        searchLabel: `${item.runpleId}`
      };
    });
  }

  public prepareBillingPeriodsList(billingPeriods): ScheduledPaymentBillingPeriodModel[] {
    return billingPeriods.map((item: ScheduledPaymentBillingPeriodModel) => {
      return {
        ...item,
        label: `${item.periodFrom} - ${item.periodTo}`
      };
    });
  }

  public link(): void {
    if (this.linkDocumentRequest$.getValue()) { return; }
    this.linkDocumentRequest$.next(true);

    this.scheduledPaymentApiService
      .scheduledPaymentLinkPeriod(
        this.selectedScheduledPayment.id,
        this.selectedBillingPeriod.periodId,
        this.data.incomingInvoiceId,
        DocumentTypesUppercaseEnum.IIN
      )
      .pipe(
        finalize(() => { this.linkDocumentRequest$.next(false); }),
        takeUntil(this._destroy))
      .subscribe(this.subscriberHandler);
  }

  public subscriberHandler = (response: ScheduledPaymentBillingPeriodModel) => {
    this.dialogRef.close(response);
  }

  public get partnerUrl(): string {
    return `/partners-new/${this.partner.type || PartnersTypeEnum.CORPORATE}/${this.partner.id}`;
  }

  public get partner() {
    return this.selectedScheduledPayment.partner;
  }

}
