import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SoundService } from 'common/src/services/sound.service';
import { get } from 'lodash';

// todo: DELETE

@Component({
  selector: 'rnpl-change-status-confirmation-modal',
  templateUrl: './change-status-confirmation-modal.component.html',
  styleUrls: ['./change-status-confirmation-modal.component.scss']
})
export class ChangeStatusConfirmationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChangeStatusConfirmationModalComponent>,
    public soundService: SoundService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.soundService.playSound('modal_warning');
  }

  public confirmStatusChanging() {
    let entitiesToChange;

    if (this.data.mode === 'single') {
      entitiesToChange = [this.data.selectedEntities];
    } else {
      if (this.data.selectedEntities.selected) {
        entitiesToChange = this.getSelectedIds(this.data.selectedEntities.selected);
      } else {
        entitiesToChange = this.data.selectedEntities.map(entity => (entity.id));
      }
    }

    this.dialogRef.close({ids: entitiesToChange, status: this.data.statusToChange});
  }

  public getSelectedIds(selectedEntities): Array<number> {
    const ids = [];
    selectedEntities.map(entity => ids.push(Number(entity.id)));
    return [...new Set(ids)];
  }

  public getElementsCount(): number {
    return get(this.data, 'selectedEntities.selected.length') || get(this.data, 'selectedEntities.length');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
