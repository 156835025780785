import { TimeTrackingListTabsEnum } from './enums';

export function timeTrackingListHints(
  selectedItems: boolean,
  activeStatus?: TimeTrackingListTabsEnum,
  hasRecords?: boolean
) {
  const hintsList = {
    ['export_button_time_tracking']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_6',
      attachTo: {
        element: '#exportBtn',
        on: 'bottom-start'
      },
    },
    ['create_entity_button_time_tracking']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_7',
      attachTo: {
        element: '#logTimeBtn',
        on: 'bottom'
      },
    },
    ['time_tracking_tab_open_time_5']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_27',
      attachTo: {
        element: '#billTimeBtn',
        on: 'bottom-start'
      },
    },
    ['time_tracking_tab_open_time_7_1']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_28',
      attachTo: {
        element: '#logTimeHeaderBtn',
        on: 'bottom'
      },
    },
  }

  const filterBtnHint = {
    ['filter_button_time_tracking']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_8',
      attachTo: {
        element: '#filterBtn',
        on: 'bottom'
      },
    },
  }

  const selectedItemsHints = {
    ['time_tracking_tab_open_time_7']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_9',
      attachTo: {
        element: '#addToDocumentBtn',
        on: 'bottom-start'
      },
    },
    ['time_tracking_tab_open_time_6']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_26',
      attachTo: {
        element: '#createInvoiceActionBarBtn',
        on: 'bottom'
      },
    },
  }

  const openTabHint = {
    ['time_tracking_tab_open_time_1']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_9',
      attachTo: {
        element: '#openTabHint',
        on: 'bottom-start'
      },
    },
  }

  const logTabColumnsHint = {
    ['time_tracking_tab_log_1']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_4',
      attachTo: {
        element: '#editOrDeleteRecordBtnColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 80] }}]
      },
    },
    ['time_tracking_tab_log_2']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_5',
      attachTo: {
        element: '#createInvoiceBtnColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 80] }}]
      },
    },
  }

  const openTabColumnHints = {
    ['time_tracking_tab_open_time_2']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_1',
      attachTo: {
        element: '#billableTimeColumn',
        on: 'bottom-start'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['time_tracking_tab_open_time_3']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_2',
      attachTo: {
        element: '#openTimeColumn',
        on: 'bottom-start'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
    ['time_tracking_tab_open_time_4']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_3',
      attachTo: {
        element: '#createInvoiceBtnColumn',
        on: 'bottom'
      },
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 80] }}]
      },
    },
  }

  const tableSummaryHint = {
    ['total_row_time_tracking']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_29',
      attachTo: {
        element: '#tableSummaryBar',
        on: 'bottom'
      },
    },
  }
  const deletedTabHint = {
    ['tab_deleted_time_tracking']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_30',
      attachTo: {
        element: '#deletedTab',
        on: 'bottom'
      },
    },
  }

  if (selectedItems && activeStatus !== TimeTrackingListTabsEnum.DELETED) {
    Object.assign(hintsList, selectedItemsHints);
  }
  if (activeStatus === TimeTrackingListTabsEnum.OPEN) {
    Object.assign(hintsList, openTabHint);
  }
  if (activeStatus === TimeTrackingListTabsEnum.OPEN && hasRecords) {
    Object.assign(hintsList, openTabColumnHints);
  }
  if (activeStatus === TimeTrackingListTabsEnum.LOG && hasRecords) {
    Object.assign(hintsList, logTabColumnsHint);
  }
  if (activeStatus !== TimeTrackingListTabsEnum.DELETED && hasRecords) {
    Object.assign(hintsList, tableSummaryHint);
  }
  if (activeStatus === TimeTrackingListTabsEnum.DELETED) {
    Object.assign(hintsList, deletedTabHint);
  }
  if (activeStatus !== TimeTrackingListTabsEnum.DELETED) {
    Object.assign(hintsList, filterBtnHint);
  }

  return hintsList;

}


export function getLodTimeRecordHints(
  billNeed: boolean,
  displayDurationSummaryItem: boolean,
  displayDurationHint: boolean,
  displayDurationEnableHint: boolean,
  selectedServices: boolean,
) {
  const hintsList = {
    ['time_tracking_create_log_time_record_1']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_11',
      attachTo: {
        element: '#customerSelect',
        on: 'bottom'
      },
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
    ['time_tracking_create_log_time_record_2']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_12',
      attachTo: {
        element: '#servicesSelect',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['time_tracking_create_log_time_record_3']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_13',
      attachTo: {
        element: '#logTimeByDate',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['time_tracking_create_log_time_record_4']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_14',
      attachTo: {
        element: '#logTimeByPeriod',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
    ['time_tracking_create_log_time_record_7']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_18',
      attachTo: {
        element: '#billNeeded',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const validationFieldHint = {
    ['field_validation_time_tracking']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_16',
      attachTo: {
        element: '#servicesSelect',
        on: 'top'
      },
      classes: 'from-modal',
    },
  }

  const durationEnableHint = {
    ['time_tracking_create_log_time_record_6']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_15',
      attachTo: {
        element: '#durationEnabled',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const durationManuallyHints = {
    ['time_tracking_create_log_time_record_9']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_17',
      attachTo: {
        element: '#durationSummaryItem',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const durationHint = {
    ['time_tracking_create_log_time_record_5']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_20',
      attachTo: {
        element: '#duration',
        on: 'bottom'
      },
      classes: 'from-modal',
    },
  }

  const roundBillableTimeHint = {
    ['time_tracking_create_log_time_record_8']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_19',
      attachTo: {
        element: '#roundBillableTime',
        on: 'top'
      },
      classes: 'from-modal',
    },
  }

  if (billNeed) {
    Object.assign(hintsList, roundBillableTimeHint);
  }
  if (displayDurationSummaryItem) {
    Object.assign(hintsList, durationManuallyHints);
  }
  if (displayDurationHint) {
    Object.assign(hintsList, durationHint);
  }
  if (displayDurationEnableHint) {
    Object.assign(hintsList, durationEnableHint);
  }
  if (!selectedServices) {
    Object.assign(hintsList, validationFieldHint);
  }

  return hintsList;

}

export function getBillTimeHints(hasServices: boolean) {
  const hintsList = {
    ['time_tracking_bill_time_1']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_21',
      attachTo: {
        element: '#customerBillTimeModal',
        on: 'bottom'
      },
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 0] }}]
      },
    },
  }

  const servicesHint = {
    ['time_tracking_bill_time_2']: {
      text: 'MEGA_HINT.TIME_TRACKING.HINT_22',
      attachTo: {
        element: '#toggle_selection_head',
        on: 'bottom'
      },
      classes: 'from-modal',
      popperOptions : {
        modifiers : [{ name: 'offset', options: { offset : [0, 60] }}]
      },
    },
  }

  if (hasServices) {
    Object.assign(hintsList, servicesHint)
  }

  return hintsList;

}

export const CreateInvoiceHints = {
  ['time_tracking_tab_create_invoice_1']: {
    text: 'MEGA_HINT.TIME_TRACKING.HINT_23',
    attachTo: {
      element: '#COMBINED',
      on: 'bottom-start'
    },
    classes: 'from-modal',
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [-100, 30] }}]
    },
  },
  ['time_tracking_tab_create_invoice_2']: {
    text: 'MEGA_HINT.TIME_TRACKING.HINT_24',
    attachTo: {
      element: '#SEPARATE',
      on: 'bottom-start'
    },
    classes: 'from-modal',
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [-100, 30] }}]
    },
  },
  ['time_tracking_tab_create_invoice_3']: {
    text: 'MEGA_HINT.TIME_TRACKING.HINT_25',
    attachTo: {
      element: '#providedServicesEnabledControl',
      on: 'bottom-start'
    },
    classes: 'from-modal',
    popperOptions : {
      modifiers : [{ name: 'offset', options: { offset : [-100, 30] }}]
    },
  },
}


