import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AssignedAttributeModel, AttributeModel } from './models';
import { ResponseList, ResponseModel } from '../../../../projects/workspace/src/app/shared/models/response';
import { CategoryModel } from 'projects/workspace/src/app/shared/models/category-model';
import { PurposeModel } from 'projects/workspace/src/app/shared/models/purpose-model';
import { environment } from 'projects/workspace/src/environments/environment';


@Injectable()
export class SystemSettingsService {

  private readonly javaApiEndpoint: string = `${environment.javaApiVersion}`;

  constructor(private http: HttpClient) {
  }

  public getControls(): Observable<any> {
    return this.http.get('/controls-library/primitives');
  }

  public getAvailableCountries(onlyEu = 0): Observable<any> {
    const requestParams = new HttpParams().set('onlyEu', onlyEu.toString());
    return this.http.get('/countries/available', {params: requestParams});
  }

  public getAvailableCategories(): Observable<CategoryModel[]> {
    return this.http.get<ResponseList<CategoryModel>>(`${this.javaApiEndpoint}/dictionary/payment/categories`)
      .pipe(map((data: ResponseList<CategoryModel>) => data.data));
  }

  public getPurposesListByCategory(categoryId: number): Observable<PurposeModel[]> {
    return this.http.get<ResponseList<PurposeModel>>(`${this.javaApiEndpoint}/dictionary/payment/categories/${categoryId}/purpose`)
      .pipe(map((data: ResponseList<PurposeModel>) => data.data));
  }

  public getAvailableLanguages(): Observable<any> {
    return this.http.get('/languages/available');
  }

  public getEntities(): Observable<any> {
    return this.http.get('/entities-library/business-items');
  }

  public getAttribute(id: number): Observable<any> {
    return this.http.get(`/attributes-library/attributes/${id}`);
  }

  public saveAttribute(attribute): Observable<any> {
    return this.http.post('/attributes-library/attributes', attribute);
  }

  public updateAttribute(attribute: AttributeModel, id: number): Observable<AttributeModel> {
    return this.http.patch<ResponseModel<AttributeModel>>(`/attributes-library/attributes/${id}`, attribute)
      .pipe(map((data: ResponseModel<AttributeModel>) => data.data));
  }

  public saveAttributeDraft(attribute): Observable<any> {
    return this.http.post('/attributes-library/attributes/draft', attribute);
  }

  public saveAttributeRelation(attributeId, form, familyId): Observable<any> {
    return this.http.post(
      `/products/forms/${form}/families/${familyId}/attributes/relation`,
      {attribute_id: attributeId}
    );
  }

  public saveAttributeRelationsBatch(
    attributeId: number,
    business_forms: string[],
    family_ids: number[]
  ): Observable<any> {
    return this.http.post(
      `/products/forms/families/attributes/${attributeId}/relation`,
      {
        family_ids,
        business_forms
      }
    );
  }


  public getAttributes(params: any): Observable<any> {
    const requestParams = new HttpParams({fromObject: params});

    return this.http.get('/attributes-library/attributes', {params: requestParams});
  }

  public deleteAttributes(ids: any): Observable<any> {
    const body = { ids };
    return this.http.request('delete', `/attributes-library/attributes`, {body});
  }

  public availableAttributes(form: string): Observable<any> {
    return this.http.get(`/entities-library/forms/${form}/attributes/available`);
  }

  public assignedAttributes(form: string): Observable<any> {
    return this.http.get(`/entities-library/forms/${form}/attributes/assigned`);
  }

  public assignAttributes(form: string, attributes: Array<AssignedAttributeModel>): Observable<any> {
    const requestAttributes = attributes.map((attribute, index) => {
      return {
        attribute_id: attribute.attribute.id,
        layout: attribute.layout,
        position: index
      };
    });

    return this.http.put(
      `/entities-library/forms/${form}/attributes/assigned`,
      {attributes: requestAttributes}
    );
  }
}
