import {
  AfterContentInit,
  Component,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { StepState } from '../../step-state.enum';


@Component({
  selector: 'rnpl-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input()
  public step: string;

  @Input()
  public isNew: boolean = true;

  @Output()
  wizardClosed: EventEmitter<any> = new EventEmitter();

  @Output()
  wizardConfirmed: EventEmitter<any> = new EventEmitter();

  @Output()
  itemDeleted: EventEmitter<any> = new EventEmitter();

  @Output()
  public systemHintsActivated: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('stepOutlet', {read: ViewContainerRef, static: true})
  private stepOutlet: ViewContainerRef;


  public progress: number = 0;

  public currentStep: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
  }

  ngOnDestroy(): void {
    this.stepOutlet.clear();
  }

  public close(): void {
    this.wizardClosed.emit((this.currentStep.step as any).key);
  }

  public confirm(): void {
    this.wizardConfirmed.emit();
  }

  public delete(): void {
    this.itemDeleted.emit();
  }

  public setProgress(progress: number): void {
    this.progress = progress > this.progress ? progress : this.progress;
  }

  public readyToNext(isReady: boolean = true): void {
    if (this.currentStep && this.currentStep.next()) {
      const nextStep = this.currentStep.next();
      if (isReady && nextStep.isLocked()) {
        nextStep.markAsReady();
      }
      if (!isReady && nextStep.hasState(StepState.READY)) {
        nextStep.markAsLocked();
      }
    }
  }

  public activateSystemHints(event) {
    this.systemHintsActivated.emit(event);
  }
}
