import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TranslateModule } from '@ngx-translate/core';

import { FormComponent } from './form.component';
import { ComposerModule } from './composer/composer.module';
import { DynamicFormService } from './dynamic-form.service';
import {
  BarcodeScannerComponent,
  CheckboxComponent,
  CheckboxGroupComponent,
  DropdownComponent,
  ComboboxComponent,
  TokenfieldComponent,
  InputComponent,
  RadioGroupComponent,
  TextareaComponent,
  ToggleComponent,
  CollectivefieldComponent
} from './controls';
import {
  AttributePreviewDirective,
  ControlDefinitionDirective
} from './directives';
import { FormComposerComponent } from './components';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { RnplCommonModule } from '../rnpl-common';


@NgModule({
  declarations: [
    FormComponent,
    FormComposerComponent,
    AttributePreviewDirective,
    ControlDefinitionDirective,
    BarcodeScannerComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DropdownComponent,
    ComboboxComponent,
    TokenfieldComponent,
    InputComponent,
    RadioGroupComponent,
    TextareaComponent,
    ToggleComponent,
    CollectivefieldComponent
  ],
  exports: [
    FormComponent,
    FormComposerComponent,
    AttributePreviewDirective,
    ControlDefinitionDirective,
    BarcodeScannerComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DropdownComponent,
    ComboboxComponent,
    TokenfieldComponent,
    InputComponent,
    RadioGroupComponent,
    TextareaComponent,
    ToggleComponent,
    CollectivefieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ZXingScannerModule,
    ComposerModule,
    RnplCommonModule,
    UIComponentsModule,
    TranslateModule
  ],
  providers: [
    DynamicFormService
  ],
  entryComponents: [
    BarcodeScannerComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    DropdownComponent,
    ComboboxComponent,
    TokenfieldComponent,
    InputComponent,
    RadioGroupComponent,
    TextareaComponent,
    ToggleComponent,
    CollectivefieldComponent
  ]
})
export class DynamicFormsModule {

}
