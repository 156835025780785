import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss']
})
export class InfoBlockComponent {

  @Input() title: string;
  @Input() titleIcon = 'info';
  @Input() description: string;
  @Input() descriptionTitle: string;
  @Input() img: string;
  @Input() width: number;
  @Input() customClass: string;

}
