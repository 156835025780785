import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  public searchEntity(url: string, search_query: string, page = '1', per_page = '20'): Observable<any> {
    return this.http.get(url, {params: {
      search_query,
      page,
      per_page
    }}).pipe(map((response: any) => response.data));
  }
}
