import { createAction, props } from '@ngrx/store';

import { PaymentModel } from '../../models/payment.model';
import { UIStatesEnum } from 'common/src/models';
import { CurrencyModel } from '../../models/currency.model';
import { PaymentsListState } from '../reducers';
import { PaymentListTabsEnum } from '../../enums/payment-list-tabs.enum';

export const LoadPayment = createAction(
  '[Payment] Load Payment',
  props<{ payment: PaymentModel }>()
);

export const ResetPayment = createAction('[Payment] Reset Payment');

export const LoadPaymentsList = createAction(
  '[Payment] Load Payment List',
  props<{ paymentListData: PaymentsListState, status: PaymentListTabsEnum, page: number }>()
);

export const UpdatePaymentState = createAction(
  '[Payment] Update Payment Current State',
  props<{ currentState: UIStatesEnum }>()
);

export const ResetPaymentState = createAction(
  '[Payment] Reset Payment Current State'
);

export const LoadCurrencies = createAction(
  '[Payment] Load Currencies',
  props<{ currencies: CurrencyModel[] }>()
);

export const UpdateShouldRefreshEntity = createAction(
  '[Payment] Update Payment should refresh flag',
  props<{ isShouldRefresh: boolean }>()
);

export const UpdatePaymentUpdatedAt = createAction(
  '[Payment] Update Payment updatedAt',
  props<{ updatedAt: Date }>()
);

export const ClearPaymentUpdatedAt = createAction(
  '[Payment] Clear Payment updatedAt'
);

export const IncrementLoadingRequestsCount = createAction(
  '[Payment] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Payment] Decrement Loading Requests Count'
);

export const UpdatePropertiesBlockValid = createAction(
  '[Payment] Update Payment Properties Block Valid',
  props<{ propertiesBlockValid: boolean }>()
);

export const UpdatePaymentInfoBlockValid = createAction(
  '[Payment] Update Payment Info Block Valid',
  props<{ paymentInfoBlockValid: boolean }>()
);

export const UpdatePaymentAmountBlockValid = createAction(
  '[Payment] Update Payment Amount Block Valid',
  props<{ paymentAmountBlockValid: boolean }>()
);
