import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '../../../../auth/auth.service';

@Component({
  selector: 'rnpl-hello-initial-setup',
  templateUrl: './hello-initial-setup.component.html',
  styleUrls: ['./hello-initial-setup.component.scss']
})
export class HelloInitialSetupComponent {

  @Output() startInitialSetupEmit: EventEmitter<any> = new EventEmitter<any>();

  public firstName: string = this.authService.getUser().firstName;

  constructor(private authService: AuthService) {
  }

  public startInitialSetup(): void {
    this.startInitialSetupEmit.emit();
  }

}
