import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { FiltersControlBaseComponent } from '../filters-control-base/filters-control-base.component';

@Component({
  selector: 'rnpl-filters-control-dates-range',
  templateUrl: './filters-control-dates-range.component.html',
})
export class FiltersControlDatesRangeComponent extends FiltersControlBaseComponent implements OnDestroy {

  @Input() controlDateFrom: AbstractControl;
  @Input() controlDateTo: AbstractControl;
  @Input() hasAppliedDateFrom: boolean;
  @Input() hasAppliedDateTo: boolean;

  constructor(public translateService: TranslateService) {
    super(translateService);
  }

  public resetFilterHandler(): void {
    this.controlDateFrom.reset();
    this.controlDateTo.reset();
    if (this.hasAppliedDateFrom || this.hasAppliedDateTo) {
      this.applyFilter();
    }
  }

}
