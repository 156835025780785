import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import { SubscriptionActivationComponent } from './subscription-activation/subscription-activation.component';
import { RnplCommonModule } from '../rnpl-common';
import { SubscriptionOptionsComponent } from './subscription-options/subscription-options.component';
// import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import { HelloSubscriptionActivationComponent } from './hello-subscription-activation/hello-subscription-activation.component';
import { SubscriptionPlanFormModule } from 'projects/workspace/src/app/shared/forms/subscription-plan-form/subscription-plan-form.module';
// import { PayInvoiceComponent } from './pay-invoice/pay-invoice.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { StripePaymentService } from 'projects/workspace/src/app/shared/services';

@NgModule({
  declarations: [
    SubscriptionActivationComponent,
    SubscriptionOptionsComponent,
    // BillingDetailsComponent,
    CardDetailsComponent,
    ManageUsersComponent,
    HelloSubscriptionActivationComponent,
    // PayInvoiceComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    TranslateModule,
    UIComponentsModule,
    SubscriptionPlanFormModule,
    PerfectScrollbarModule
  ],
  entryComponents: [
    SubscriptionActivationComponent,
    ManageUsersComponent
  ],
  providers: [
    StripePaymentService
  ]
})
export class SubscriptionActivationModule { }

