import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-wizard-summary-item',
  templateUrl: './wizard-summary-item.component.html',
  styleUrls: ['./wizard-summary-item.component.scss']
})
export class WizardSummaryItemComponent {

  @Input()
  public label: string;

  @Input()
  public expanded: boolean = false;

}
