import {Component, EventEmitter, Input, Output, SimpleChanges, OnChanges} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'rnpl-dropdown-input',
  templateUrl: 'dropdown-input.component.html',
  styleUrls: ['dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnChanges {

  @Input() valuesData: Array<any>;

  @Input() control: FormControl;

  @Input() updateCurrent: string;

  @Input() disabled: boolean = false;
  @Input() alignTop: boolean = false;

  @Output() selectedValue = new EventEmitter<string>();

  public displayValue = 'mm';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('updateCurrent') && this.updateCurrent) {
      this.displayValue = this.updateCurrent;
    }
  }

  selectValue(item: string, matMenu): void {
    this.displayValue = item;
    if (this.control) {
      this.control.setValue(item);
    }
    this.selectedValue.emit(item);
    matMenu.closeMenu();
  }

}
