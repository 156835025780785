import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateTimezonePipe'})
export class DateTimezonePipe implements PipeTransform {
  transform(value: any, timezoneOffset: string, ...args: any[]): string {
    if (value && timezoneOffset) {
      const shortDateRexExp = /^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/;
      if (value.match(shortDateRexExp)) { value = new Date(value).toISOString(); }
      return value.includes('+') || value.includes('Z')
        ? value
        : value + timezoneOffset;
    }
    return value as any;
  }
}
