import { createSelector } from '@ngrx/store';
import { SharedStoreState, SharedFeatureKey } from '../reducers/shared.reducer';
import { AppState } from '../state/app.state';

export const selectFeature = ((state: AppState) => state[SharedFeatureKey]);

export const selectCountries = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.countries
);

export const selectCategories = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.categories
);

export const selectProductsUnits = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.productsUnits
);

export const selectAccountingSettings = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.accountingSettings
);

export const selectGeneralSettings = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.generalSettings
);

export const selectPdfSettings = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.pdfSettings
);

export const selectUserShortcuts = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.userShortcuts
);

export const selectUserQuickActions = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.userQuickActions
);

export const selectUserStartScreenItems = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.userStartScreenItems
);

export const loadingRequestsCount = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.loadingRequestsCount
);


export const selectAppsCounters = createSelector(
  selectFeature,
  (state: SharedStoreState) => state.appsCounters
);
