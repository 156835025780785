import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, TemplateRef } from '@angular/core';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ComposerContainerComponent } from '../composer-container/composer-container.component';
import { ComposerRowComponent } from '../composer-row/composer-row.component';
import { CellDataModel, CellSize, ComposerCell } from '../../interfaces';
import { digitalSize } from '../../helpers';

@Component({
  selector: 'rnpl-composer-cell',
  templateUrl: './composer-cell.component.html',
  styleUrls: ['./composer-cell.component.scss'],
  providers: [{provide: ComposerCell, useExisting: ComposerCellComponent}]
})
export class ComposerCellComponent implements OnInit, OnDestroy {

  @Input()
  public size: CellSize = 'medium';

  @Input()
  public data: CellDataModel<any>;

  @Input()
  public contentTemplate: TemplateRef<any>;

  @Input()
  index: number;

  public get rowIndex() {
    return this.row.index;
  }

  public get digitalSize() {
    return digitalSize(this.size);
  }

  public get rect() {
    return this.hostElement.nativeElement.getBoundingClientRect();
  }

  @HostBinding('class.empty')
  public isEmpty: boolean = false;

  @HostBinding('class.active')
  public isActive: boolean = false;

  @HostBinding('class.collapsed-vertically')
  private _isCollapsedY: boolean = false;

  @HostBinding('class.collapsed-horizontally')
  private _isCollapsedX: boolean = false;

  private clickSubscription: Subscription;

  constructor(@Optional() private container: ComposerContainerComponent,
              @Optional() private row: ComposerRowComponent,
              private hostElement: ElementRef) {
  }

  ngOnInit(): void {

    this.clickSubscription = fromEvent(this.hostElement.nativeElement, 'click')
      .subscribe(() => this.activateCell());

    this.container.dragEnded
      .subscribe(() => {
        this.clickSubscription = fromEvent(this.hostElement.nativeElement, 'click')
          .pipe(debounceTime(300))
          .subscribe(() => this.activateCell());
      });

    this.container.dragStarted
      .subscribe(() => {
        this.isActive = false;
        this.clickSubscription.unsubscribe();
      });

    this.container.cellSelected
      .subscribe(cell => this.isActive = cell === this.data);
  }

  ngOnDestroy(): void {
    if (this.clickSubscription) {
      this.clickSubscription.unsubscribe();
    }
  }

  public activateCell(): void {
    this.container.selectCell(this.data);
  }

  public dragStarted(dragItem: CdkDrag): void {
    this.isEmpty = true;
    if (this.container) {
      this.container.activate({
        dragItem,
        dragItemSize: this.size,
        sourceCell: this
      });
    }
  }

  // public removeSelf(): void {
  //   this.container.removeCell(this.rowIndex, this.index);
  //   this.container.reload();
  // }

}
