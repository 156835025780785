import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';

import { AssignedAttributeModel } from '../../../system-settings';
import { AttributeModel } from 'projects/supervisor/src/app/system-settings/models/attribute.model';
import { CollectiveFieldControl } from '../../../products';
import { ControlDefinitionModel } from '../../../dynamic-forms/control-definition.model';

@Component({
  selector: 'rnpl-attribute-settings-contents',
  templateUrl: './attribute-settings-contents.component.html',
  styleUrls: ['./attribute-settings-contents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttributeSettingsContentsComponent implements OnChanges {
  @Input() selectedAttribute: AssignedAttributeModel;
  @Input() availableAttributes: Array<AssignedAttributeModel> = [];
  @Input() blueprints: ControlDefinitionModel[] = [];

  @Output() selectedControlsToCombine: EventEmitter<CollectiveFieldControl[]> = new EventEmitter<CollectiveFieldControl[]>();

  private readonly TO_SHOW: string[] = ['collective_field'];

  private readonly attributeControls: FormArray = new FormArray([]);

  get showSelected(): boolean {
    return this.TO_SHOW.includes(this.selectedAttribute.attribute.control);
  }


  toggle(i: number, $event: Event, control: AbstractControl): void {
    this.attributeControls.controls[i]
      .setValue({
        ...control.value,
        checked: ($event.target as HTMLInputElement).checked
      });

    const selectedControls: CollectiveFieldControl[] = this.attributeControls
      .getRawValue()
      .filter(ctrl => ctrl.checked)
      .map(ctrl => ({
        id: ctrl.id,
        name: ctrl.name
      }));

    this.selectedControlsToCombine.emit(selectedControls);
  }

  private initControls(): void {
    this.selectedAttribute.properties = this.selectedAttribute.properties || [];
    this.attributeControls.controls = (this.blueprints as any)
      .concat(this.availableAttributes.map(a => a.attribute))
      .filter((v: AttributeModel) => v.control !== 'collective_field')
      .map((v) => ({
        id: String(v.key || v.id),
        name: v.name || v.label,
        checked: this.selectedAttribute.properties.includes(Number( v.key || v.id))
      }))
      .map((v => new FormControl(v)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initControls();
  }
}
