import { ColumnTypeEnum, DateColumnType, TableColumnModelExtended } from '../../../../models/table-column.model';

export const COLUMNS: TableColumnModelExtended[] = [
  {
    cellTemplate: 'numberRows',
    cellClass: 'center',
    name: 'Nr.',
    width: 40,
    maxWidth: 40,
    sortable: false,
    resizeable: false,
    headerClass: 'center'
  },
  {
    cellTemplate: 'withLinkPrimary',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.DOCUMENT_ID',
    comparator: (a, b) => (a.label).localeCompare(b.label),
    cellClass: 'link-primary',
    prop: 'documentLink',
    sortable: true,
    width: 172,
    maxWidth: 172,
    // resizeable: false,
  },
  {
    cellTemplate: 'withDate',
    headerTemplate: 'headerPrimary',
    name: 'FORM.ESTIMATED_DELIVERY_DATE',
    prop: 'estimatedDeliveryDate',
    sortable: true,
    width: 164,
    maxWidth: 164,
    type: ColumnTypeEnum.DATE,
    dateType: DateColumnType.WITHOUT_TIME,
    // resizeable: false,
  },
  {
    cellTemplate: 'withCorporatePartner',
    headerTemplate: 'headerPrimary',
    name: 'FORM.RECIPIENT',
    comparator: (a, b) => (a.name).localeCompare(b.name),
    prop: 'recipient',
    sortable: true,
    cellClass: 'link-primary',
    // width: 190,
    // maxWidth: 190,
    // resizeable: false,
  },
  {
    cellTemplate: 'withLinkPrimary',
    headerTemplate: 'headerPrimary',
    name: 'POSITIONS.PRODUCT',
    preventTranslate: true,
    prop: 'productLink',
    sortable: true,
    cellClass: 'link-primary',
    // width: 144,
    // maxWidth: 144,
  },
  {
    cellTemplate: 'primaryWithNumber',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.AVAILABLE_QUANTITY',
    prop: 'ordered',
    sortable: true,
    additionalText: 'pcs',
    cellClass: 'right font-weight-500',
    width: 140,
    maxWidth: 140,
  },
  {
    cellTemplate: 'primary',
    headerTemplate: 'headerPrimary',
    name: 'FORM.BIN',
    prop: 'bin',
    sortable: true,
    width: 104,
    maxWidth: 104,
    // resizeable: false,
  },
  {
    cellTemplate: 'withInput',
    headerTemplate: 'headerPrimary',
    name: 'COLUMN.ASSIGNED_QUANTITY',
    prop: 'quantity',
    sortable: true,
    type: ColumnTypeEnum.STEPPER,
    decimal: 0,
    additionalText: 'pcs',
    width: 150,
    maxWidth: 150,
  },
];
