import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryTransformFromIso3ToIso2'
})
export class CountryTransformFromIso3ToIso2Pipe implements PipeTransform {

  transform(iso3: string, ...args: any[]): string {
    let countryIso = '';

    switch (iso3.toUpperCase()) {
      case 'AFG':
        countryIso = 'AF';
        break;
      case 'ALB':
        countryIso = 'AL';
        break;
      case 'DZA':
        countryIso = 'DZ';
        break;
      case 'ASM':
        countryIso = 'AS';
        break;
      case 'AND':
        countryIso = 'AD';
        break;
      case 'AGO':
        countryIso = 'AO';
        break;
      case 'AIA':
        countryIso = 'AI';
        break;
      case 'ATG':
        countryIso = 'AG';
        break;
      case 'ARG':
        countryIso = 'AR';
        break;
      case 'ABW':
        countryIso = 'AW';
        break;
      case 'AUS':
        countryIso = 'AU';
        break;
      case 'AUT':
        countryIso = 'AT';
        break;
      case 'AZE':
        countryIso = 'AZ';
        break;
      case 'BHS':
        countryIso = 'BS';
        break;
      case 'BHR':
        countryIso = 'BH';
        break;
      case 'BGD':
        countryIso = 'BD';
        break;
      case 'BRB':
        countryIso = 'BB';
        break;
      case 'BLR':
        countryIso = 'BY';
        break;
      case 'BEL':
        countryIso = 'BE';
        break;
      case 'BLZ':
        countryIso = 'BZ';
        break;
      case 'BEN':
        countryIso = 'BJ';
        break;
      case 'BMU':
        countryIso = 'BM';
        break;
      case 'BTN':
        countryIso = 'BT';
        break;
      case 'BOL':
        countryIso = 'BO';
        break;
      case 'BES':
        countryIso = 'BQ';
        break;
      case 'BIH':
        countryIso = 'BA';
        break;
      case 'BWA':
        countryIso = 'BW';
        break;
      case 'BRA':
        countryIso = 'BR';
        break;
      case 'IOT':
        countryIso = 'IO';
        break;
      case 'BRN':
        countryIso = 'BN';
        break;
      case 'BGR':
        countryIso = 'BG';
        break;
      case 'BFA':
        countryIso = 'BF';
        break;
      case 'BDI':
        countryIso = 'BI';
        break;
      case 'CPV':
        countryIso = 'CV';
        break;
      case 'KHM':
        countryIso = 'KH';
        break;
      case 'CMR':
        countryIso = 'CM';
        break;
      case 'CAN':
        countryIso = 'CA';
        break;
      case 'CYM':
        countryIso = 'KY';
        break;
      case 'CAF':
        countryIso = 'CF';
        break;
      case 'TCD':
        countryIso = 'TD';
        break;
      case 'CHL':
        countryIso = 'CL';
        break;
      case 'CHN':
        countryIso = 'CN';
        break;
      case 'CXR':
        countryIso = 'CX';
        break;
      case 'CCK':
        countryIso = 'CC';
        break;
      case 'COL':
        countryIso = 'CO';
        break;
      case 'COM':
        countryIso = 'KM';
        break;
      case 'COK':
        countryIso = 'CK';
        break;
      case 'CRI':
        countryIso = 'CR';
        break;
      case 'HRV':
        countryIso = 'HR';
        break;
      case 'CUB':
        countryIso = 'CU';
        break;
      case 'CUW':
        countryIso = 'CW';
        break;
      case 'CYP':
        countryIso = 'CY';
        break;
      case 'CZE':
        countryIso = 'CZ';
        break;
      case 'DNK':
        countryIso = 'DK';
        break;
      case 'DJI':
        countryIso = 'DJ';
        break;
      case 'DMA':
        countryIso = 'DM';
        break;
      case 'DOM':
        countryIso = 'DO';
        break;
      case 'ECU':
        countryIso = 'EC';
        break;
      case 'EGY':
        countryIso = 'EG';
        break;
      case 'ERI':
        countryIso = 'ER';
        break;
      case 'EST':
        countryIso = 'EE';
        break;
      case 'ETH':
        countryIso = 'ET';
        break;
      case 'FLK':
        countryIso = 'FK';
        break;
      case 'FRO':
        countryIso = 'FO';
        break;
      case 'FJI':
        countryIso = 'FJ';
        break;
      case 'FRA':
        countryIso = 'FR';
        break;
      case 'FIN':
        countryIso = 'FI';
        break;
      case 'PYF':
        countryIso = 'PF';
        break;
      case 'GAB':
        countryIso = 'GA';
        break;
      case 'GMB':
        countryIso = 'GM';
        break;
      case 'GEO':
        countryIso = 'GE';
        break;
      case 'DEU':
        countryIso = 'DE';
        break;
      case 'GHA':
        countryIso = 'GH';
        break;
      case 'GIB':
        countryIso = 'GI';
        break;
      case 'GRC':
        countryIso = 'GR';
        break;
      case 'GRL':
        countryIso = 'GL';
        break;
      case 'GRD':
        countryIso = 'GD';
        break;
      case 'GUM':
        countryIso = 'GU';
        break;
      case 'GTM':
        countryIso = 'GT';
        break;
      case 'GGY':
        countryIso = 'GG';
        break;
      case 'GIN':
        countryIso = 'GN';
        break;
      case 'GNB':
        countryIso = 'GW';
        break;
      case 'GUY':
        countryIso = 'GY';
        break;
      case 'HTI':
        countryIso = 'HT';
        break;
      case 'HND':
        countryIso = 'HN';
        break;
      case 'HKG':
        countryIso = 'HK';
        break;
      case 'HUN':
        countryIso = 'HU';
        break;
      case 'ISL':
        countryIso = 'IS';
        break;
      case 'IND':
        countryIso = 'IN';
        break;
      case 'IDN':
        countryIso = 'ID';
        break;
      case 'IRN':
        countryIso = 'IR';
        break;
      case 'IRQ':
        countryIso = 'IQ';
        break;
      case 'IRL':
        countryIso = 'IE';
        break;
      case 'IMN':
        countryIso = 'IM';
        break;
      case 'ISR':
        countryIso = 'IL';
        break;
      case 'ITA':
        countryIso = 'IT';
        break;
      case 'JAM':
        countryIso = 'JM';
        break;
      case 'JPN':
        countryIso = 'JP';
        break;
      case 'JEY':
        countryIso = 'JE';
        break;
      case 'JOR':
        countryIso = 'JO';
        break;
      case 'KAZ':
        countryIso = 'KZ';
        break;
      case 'KEN':
        countryIso = 'KE';
        break;
      case 'KIR':
        countryIso = 'KI';
        break;
      case 'KWT':
        countryIso = 'KW';
        break;
      case 'KGZ':
        countryIso = 'KG';
        break;
      case 'LVA':
        countryIso = 'LV';
        break;
      case 'LBN':
        countryIso = 'LB';
        break;
      case 'LSO':
        countryIso = 'LS';
        break;
      case 'LBR':
        countryIso = 'LR';
        break;
      case 'LBY':
        countryIso = 'LY';
        break;
      case 'LIE':
        countryIso = 'LI';
        break;
      case 'LTU':
        countryIso = 'LT';
        break;
      case 'LUX':
        countryIso = 'LU';
        break;
      case 'MAC':
        countryIso = 'MO';
        break;
      case 'MDG':
        countryIso = 'MG';
        break;
      case 'MWI':
        countryIso = 'MW';
        break;
      case 'MYS':
        countryIso = 'MY';
        break;
      case 'MDV':
        countryIso = 'MV';
        break;
      case 'MLI':
        countryIso = 'ML';
        break;
      case 'MLT':
        countryIso = 'MT';
        break;
      case 'MHL':
        countryIso = 'MH';
        break;
      case 'MTQ':
        countryIso = 'MQ';
        break;
      case 'MRT':
        countryIso = 'MR';
        break;
      case 'MUS':
        countryIso = 'MU';
        break;
      case 'MEX':
        countryIso = 'MX';
        break;
      case 'FSM':
        countryIso = 'FM';
        break;
      case 'MDA':
        countryIso = 'MD';
        break;
      case 'MCO':
        countryIso = 'MC';
        break;
      case 'MNG':
        countryIso = 'MN';
        break;
      case 'MNE':
        countryIso = 'ME';
        break;
      case 'MSR':
        countryIso = 'MS';
        break;
      case 'MAR':
        countryIso = 'MA';
        break;
      case 'MOZ':
        countryIso = 'MZ';
        break;
      case 'MMR':
        countryIso = 'MM';
        break;
      case 'NAM':
        countryIso = 'NA';
        break;
      case 'NRU':
        countryIso = 'NR';
        break;
      case 'NPL':
        countryIso = 'NP';
        break;
      case 'NLD':
        countryIso = 'NL';
        break;
      case 'NZL':
        countryIso = 'NZ';
        break;
      case 'NIC':
        countryIso = 'NI';
        break;
      case 'NER':
        countryIso = 'NE';
        break;
      case 'NGA':
        countryIso = 'NG';
        break;
      case 'NIU':
        countryIso = 'NU';
        break;
      case 'NFK':
        countryIso = 'NF';
        break;
      case 'MNP':
        countryIso = 'MP';
        break;
      case 'NOR':
        countryIso = 'NO';
        break;
      case 'OMN':
        countryIso = 'OM';
        break;
      case 'PAK':
        countryIso = 'PK';
        break;
      case 'PLW':
        countryIso = 'PW';
        break;
      case 'PSE':
        countryIso = 'PS';
        break;
      case 'PAN':
        countryIso = 'PA';
        break;
      case 'PNG':
        countryIso = 'PG';
        break;
      case 'PRY':
        countryIso = 'PY';
        break;
      case 'PER':
        countryIso = 'PE';
        break;
      case 'PHL':
        countryIso = 'PH';
        break;
      case 'PCN':
        countryIso = 'PN';
        break;
      case 'POL':
        countryIso = 'PL';
        break;
      case 'PRT':
        countryIso = 'PT';
        break;
      case 'PRI':
        countryIso = 'PR';
        break;
      case 'QAT':
        countryIso = 'QA';
        break;
      case 'MKD':
        countryIso = 'MK';
        break;
      case 'ROU':
        countryIso = 'RO';
        break;
      case 'RUS':
        countryIso = 'RU';
        break;
      case 'RWA':
        countryIso = 'RW';
        break;
      case 'KNA':
        countryIso = 'KN';
        break;
      case 'WSM':
        countryIso = 'WS';
        break;
      case 'SMR':
        countryIso = 'SM';
        break;
      case 'STP':
        countryIso = 'ST';
        break;
      case 'SAU':
        countryIso = 'SA';
        break;
      case 'SEN':
        countryIso = 'SN';
        break;
      case 'SRB':
        countryIso = 'RS';
        break;
      case 'SYC':
        countryIso = 'SC';
        break;
      case 'SLE':
        countryIso = 'SL';
        break;
      case 'SGP':
        countryIso = 'SG';
        break;
      case 'SXM':
        countryIso = 'SX';
        break;
      case 'SVK':
        countryIso = 'SK';
        break;
      case 'SVN':
        countryIso = 'SI';
        break;
      case 'SLB':
        countryIso = 'SB';
        break;
      case 'SOM':
        countryIso = 'SO';
        break;
      case 'ZAF':
        countryIso = 'ZA';
        break;
      case 'SSD':
        countryIso = 'SS';
        break;
      case 'ESP':
        countryIso = 'ES';
        break;
      case 'LKA':
        countryIso = 'LK';
        break;
      case 'SDN':
        countryIso = 'SD';
        break;
      case 'SUR':
        countryIso = 'SR';
        break;
      case 'SWE':
        countryIso = 'SE';
        break;
      case 'CHE':
        countryIso = 'CH';
        break;
      case 'SYR':
        countryIso = 'SY';
        break;
      case 'TWN':
        countryIso = 'TW';
        break;
      case 'TJK':
        countryIso = 'TJ';
        break;
      case 'TZA':
        countryIso = 'TZ';
        break;
      case 'THA':
        countryIso = 'TH';
        break;
      case 'TGO':
        countryIso = 'TG';
        break;
      case 'TKL':
        countryIso = 'TK';
        break;
      case 'TON':
        countryIso = 'TO';
        break;
      case 'TTO':
        countryIso = 'TT';
        break;
      case 'TUN':
        countryIso = 'TN';
        break;
      case 'TUR':
        countryIso = 'TR';
        break;
      case 'TKM':
        countryIso = 'TM';
        break;
      case 'TCA':
        countryIso = 'TC';
        break;
      case 'TUV':
        countryIso = 'TV';
        break;
      case 'UGA':
        countryIso = 'UG';
        break;
      case 'UKR':
        countryIso = 'UA';
        break;
      case 'ARE':
        countryIso = 'AE';
        break;
      case 'GBR':
        countryIso = 'GB';
        break;
      case 'USA':
        countryIso = 'US';
        break;
      case 'URY':
        countryIso = 'UY';
        break;
      case 'UZB':
        countryIso = 'UZ';
        break;
      case 'VUT':
        countryIso = 'VU';
        break;
      case 'VEN':
        countryIso = 'VE';
        break;
      case 'VNM':
        countryIso = 'VN';
        break;
      case 'VGB':
        countryIso = 'VG';
        break;
      case 'YEM':
        countryIso = 'YE';
        break;
      case 'ZMB':
        countryIso = 'ZM';
        break;
      case 'ZWE':
        countryIso = 'ZW';
        break;
    }

    return countryIso

  }
}
