import { Component, Input } from '@angular/core';
import { get } from 'lodash';

import { PurchaseOrder } from '../../../models';
import { Info } from '../../../../outgoing-invoice/models/summary-invoice.model';
import { PayerEnum } from 'common/src/models';
import { PartnersTypeEnum } from '../../../../partners/corporate/enums';

@Component({
  selector: 'rnpl-summary-purchase-order',
  templateUrl: './summary.component.html',
})
export class SummaryComponent {
  @Input() purchaseOrder: PurchaseOrder;
  @Input() isPositions: boolean;

  get positionsSummary(): Info {
    return get(this, 'purchaseOrder.summary.positions');
  }

  get deliveryCostsSummary(): Info {
    return get(this, 'purchaseOrder.summary.deliveryCosts');
  }

  get totalAmountSummary(): Info {
    return get(this, 'purchaseOrder.summary.totalAmount');
  }

  get hasGoods(): boolean {
    return get(this, 'purchaseOrder.hasGoods');
  }

  get isDeliveryCosts(): boolean {
    return get(this, 'purchaseOrder.deliveryInformation.isDeliveryCosts');
  }

  get isInsuredDelivery(): boolean {
    return get(this, 'purchaseOrder.deliveryInformation.isInsuredDelivery');
  }

  get isSenderPays(): boolean {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryCostsPayer') === PayerEnum.SENDER
  }

  get isSenderPaysInsurance(): boolean {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryInsurancePayer') === PayerEnum.SENDER
  }

  get deliveryCosts(): number {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryCosts');
  }

  get deliveryCostsGross(): number {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryCostsGross');
  }

  get deliveryCostsVat(): number {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryCostsVat');
  }

  get deliveryInsuranceCosts(): number {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryInsuranceCosts');
  }

  get deliveryInsuranceCostsGross(): number {
    return get(this, 'purchaseOrder.deliveryInformation.deliveryInsuranceCosts');
  }

  get isPrivatePartner(): boolean {
    return get(this, 'purchaseOrder.properties.partnerType') === PartnersTypeEnum.PRIVATE;
  }
}

