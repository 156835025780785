import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { TradeOfferModel, TradeOfferPropertiesModel } from 'projects/workspace/src/app/trade-offer/models';
import { selectTradeOffer } from 'projects/workspace/src/app/trade-offer/store/selectors';
import { ModalNameEnum } from '../../../../models/modal-name.enum';

@Component({
  selector: 'rnpl-offer-add-remark-modal',
  templateUrl: './offer-add-remark-modal.component.html',
})
export class OfferAddRemarkModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public tradeOffer: TradeOfferModel;
  readonly destroy$: Subject<void> = new Subject();
  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<OfferAddRemarkModalComponent>,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<AppState>,
    private tradeOfferApiService: TradeOfferApiService
  ) { }

  ngOnInit() {
    this.isLoading$.next(true);
    this.trackTradeOfferChanges();
  }

  private trackTradeOfferChanges(): void {
    this.store.select(selectTradeOffer)
      .pipe(takeUntil(this.destroy$))
      .subscribe((tradeOffer: TradeOfferModel) => {
        this.tradeOffer = tradeOffer;
        this.initForm(this.tradeOffer.properties)
      })
  }

  public initForm(properties: TradeOfferPropertiesModel = {} as TradeOfferPropertiesModel): void {
    this.form = this.fb.group({
      printableRemarks: [get(properties, 'printableRemarks')],
    });
    this.isLoading$.next(false);
    this.cdr.detectChanges();
  }

  public updateProperties(value, closeModal = false): void {
    this.tradeOfferApiService.updateTradeOffer(
      this.tradeOffer.id,
      {
        fieldValue: value,
        fieldName: 'properties.printableRemarks'
      })
      .subscribe(() => {
        if (closeModal) {
          this.dialogRef.close();
        }
      }); // updated via store
  }

  get printableRemarks(): FormControl { return this.form.get('printableRemarks') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
