import { createReducer, on } from '@ngrx/store';

import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { CollectionOutgoingInvoiceActions } from '../actions/action-types';
import { OutgoingInvoiceModel, PositionsModel, PositionsTotals } from '../../models';
import { SummaryInvoiceModel } from '../../models/summary-invoice.model';
import { UIStatesEnum, PaginationModel, SendEmailStatusEnum } from 'common/src/models';
import { FilterModelNew } from '../../models/filter-model-new';
import { OutgoingInvoiceListTabsEnum } from '../../enums';

export const OutgoingInvoiceFeatureKey = 'outgoingInvoiceStore';

export interface OutgoingInvoicesListState {
  [page: string]: {
    pagination: PaginationModel;
    sort: FilterModelNew;
    data: OutgoingInvoiceModel[];
  }
}
export interface OutgoingInvoicesState {
  [OutgoingInvoiceListTabsEnum.DRAFT]?: OutgoingInvoicesListState;
  [OutgoingInvoiceListTabsEnum.OPEN]?: OutgoingInvoicesListState;
  [OutgoingInvoiceListTabsEnum.PAID]?: OutgoingInvoicesListState;
  [OutgoingInvoiceListTabsEnum.CANCELED]?: OutgoingInvoicesListState;
  [OutgoingInvoiceListTabsEnum.DELETED]?: OutgoingInvoicesListState;
  [OutgoingInvoiceListTabsEnum.TEMPLATE]?: OutgoingInvoicesListState;
}
export interface OutgoingInvoiceStore {
  entity: OutgoingInvoiceState;
  list: OutgoingInvoicesListState;
  blocksValidations: OutgoingInvoiceBlocksValidationsState;
}

export const initialIOutgoingInvoicesState: OutgoingInvoicesState = {
  [OutgoingInvoiceListTabsEnum.DRAFT]: {},
  [OutgoingInvoiceListTabsEnum.OPEN]: {},
  [OutgoingInvoiceListTabsEnum.PAID]: {},
  [OutgoingInvoiceListTabsEnum.CANCELED]: {},
  [OutgoingInvoiceListTabsEnum.DELETED]: {},
  [OutgoingInvoiceListTabsEnum.TEMPLATE]: {},
}

export interface OutgoingInvoiceState {
  outgoingInvoice: OutgoingInvoiceModel;
  outgoingInvoiceSummary: SummaryInvoiceModel;
  outgoingPositionsTotals: PositionsTotals;
  currentState: UIStatesEnum;
  countries: CountryModel[];
  positionsCount: number;
  isShouldRefresh: boolean;
  loadingRequestsCount: number;
  updatedAt: Date;
  positions: PositionsModel;
}

export const initialOutgoingInvoiceState: OutgoingInvoiceState = {
  outgoingInvoice: {} as OutgoingInvoiceModel,
  outgoingInvoiceSummary: {} as SummaryInvoiceModel,
  outgoingPositionsTotals: {} as PositionsTotals,
  countries: [],
  currentState: UIStatesEnum.VIEW,
  positionsCount: 0,
  isShouldRefresh: false,
  loadingRequestsCount: 0,
  updatedAt: null,
  positions: null,
};

export interface OutgoingInvoiceBlocksValidationsState {
  propertiesBlockValid: boolean;
  billingInfoBlockValid: boolean;
  positionsBlockValid: boolean;
}

export const initialOutgoingInvoiceBlocksValidationsState: OutgoingInvoiceBlocksValidationsState = {
  propertiesBlockValid: false,
  billingInfoBlockValid: false,
  positionsBlockValid: false,
};

export function OutgoingInvoiceReducer(state, action) {
  return _OutgoingInvoiceReducer(state, action);
}

export function OutgoingInvoicesListReducer(state, action) {
  return _OutgoingInvoicesListReducer(state, action);
}

export function OutgoingInvoiceBlocksValidationsReducer(state, action) {
  return _OutgoingInvoiceBlocksValidationsReducer(state, action);
}

const _OutgoingInvoiceBlocksValidationsReducer = createReducer(
  initialOutgoingInvoiceBlocksValidationsState,
  on(CollectionOutgoingInvoiceActions.UpdatePropertiesBlockValid, (state, { propertiesBlockValid }) => ({
    ...state,
    propertiesBlockValid
  })),
  on(CollectionOutgoingInvoiceActions.UpdateBillingInfoBlockValid, (state, { billingInfoBlockValid }) => ({
    ...state,
    billingInfoBlockValid
  })),
  on(CollectionOutgoingInvoiceActions.UpdatePositionsBlockValid, (state, { positionsBlockValid }) => ({
    ...state,
    positionsBlockValid
  })),
);

const _OutgoingInvoicesListReducer = createReducer(
  initialIOutgoingInvoicesState,
  on(CollectionOutgoingInvoiceActions.LoadOutgoingInvoicesList, (state, { outgoingInvoiceListData, status, page }) => ({
    ...state,
    [status]: {
      ...state[status],
      ...outgoingInvoiceListData
    },
  })),
);

const _OutgoingInvoiceReducer = createReducer(
  initialOutgoingInvoiceState,
  on(CollectionOutgoingInvoiceActions.LoadOutgoingInvoice, (state, { outgoingInvoice }) => ({
    ...state,
    outgoingInvoice
  })),
  on(CollectionOutgoingInvoiceActions.UpdateOutgoingInvoiceBillingAddress, (state, { billingAddress }) => ({
    ...state,
    outgoingInvoice: {
      ...state.outgoingInvoice,
      billingAddress
    }
  })),
  on(CollectionOutgoingInvoiceActions.LoadOutgoingInvoiceCountries, (state, { countries }) => ({
    ...state,
    countries
  })),
  on(CollectionOutgoingInvoiceActions.UpdateOutgoingInvoiceState, (state, { currentState }) => ({
    ...state,
    currentState
  })),
  on(CollectionOutgoingInvoiceActions.LoadOutgoingInvoicePositions, (state, { positions }) => ({
    ...state,
    positions
  })),
  on(CollectionOutgoingInvoiceActions.UpdateOutgoingInvoicePositionsCount, (state, { positionsCount }) => ({
    ...state,
    positionsCount
  })),
  on(CollectionOutgoingInvoiceActions.ResetOutgoingInvoice, (state) => ({
    ...state,
    outgoingInvoice: null
  })),
  on(CollectionOutgoingInvoiceActions.ResetOutgoingInvoiceState, (state) => ({
    ...state,
    currentState: UIStatesEnum.VIEW
  })),
  on(CollectionOutgoingInvoiceActions.ResetOutgoingInvoicePositionsCount, (state) => ({
    ...state,
    positionsCount: 0
  })),
  on(CollectionOutgoingInvoiceActions.OutgoingInvoiceSendEmail, (state) => ({
    ...state,
    outgoingInvoice: {
      ...state.outgoingInvoice,
      sendEmailStatus: {
        ...state.outgoingInvoice.sendEmailStatus,
        emailStatus: SendEmailStatusEnum.PENDING
      }
    }
  })),
  on(CollectionOutgoingInvoiceActions.UpdateOutgoingInvoiceSummary, (state, { outgoingInvoiceSummary }) => ({
    ...state,
    outgoingInvoiceSummary
  })),
  on(CollectionOutgoingInvoiceActions.UpdateOutgoingPositionsTotals, (state, { outgoingPositionsTotals }) => ({
    ...state,
    outgoingPositionsTotals
  })),
  on(CollectionOutgoingInvoiceActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionOutgoingInvoiceActions.UpdateOutgoingInvoiceUpdatedAt, (state, { updatedAt }) => ({
    ...state,
    updatedAt,
  })),
  on(CollectionOutgoingInvoiceActions.UpdateShouldRefreshEntity, (state, { isShouldRefresh }) => ({
    ...state,
    isShouldRefresh,
  })),
  on(CollectionOutgoingInvoiceActions.IncrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount + 1,
  })),
  on(CollectionOutgoingInvoiceActions.DecrementLoadingRequestsCount, (state) => ({
    ...state,
    loadingRequestsCount: state.loadingRequestsCount - 1,
  })),
);
