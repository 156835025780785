import { Component } from '@angular/core';

@Component({
  selector: 'rnpl-limited-functionality-modal',
  templateUrl: './limited-functionality-modal.component.html',
  styleUrls: ['./limited-functionality-modal.component.scss']
})
export class LimitedFunctionalityModalComponent {

  public closeModal(): void {
    localStorage.setItem('isClosedLimitedModal', 'true');
  }

}
