import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'moduleNameTranslatePipe'})
export class ModuleNameTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(value: string, ...args: any[]): string {
    if (!value) { return value; }

    let translateKey: string;

    switch (value) {
      case 'wholesale':
        translateKey = 'MODULE.TRADE';
        break;
      case 'retail':
        translateKey = 'DEPARTMENTS.RETAIL';
        break;
      case 'ecommerce':
        translateKey = 'ADMINISTRATION.ECOMMERCE';
        break;
      case 'services':
        translateKey = 'CATEGORIES.SERVICES';
        break;
      case 'accounting':
        translateKey = 'CATEGORIES.ACCOUNTING';
        break;
      case 'warehouse':
        translateKey = 'MODULE.WAREHOUSE';
        break;
      case 'products':
        translateKey = 'MODULE.PRODUCTS';
        break;
      case 'partners':
        translateKey = 'MODULE.PARTNERS';
        break;
      case 'team':
        translateKey = 'MODULE.TEAM';
        break;
      case 'analytics':
        translateKey = 'MODULE.ANALYTICS';
        break;
      case 'administration':
        translateKey = 'MODULE.ADMINISTRATION';
        break;
    }

    return translateKey
      ? this.translateService.instant(translateKey)
      : value;
  }
}
