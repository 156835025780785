import { AddressModel } from 'common/src/models';
import { CountryModel } from 'common/src/modules/rnpl-common/models';
import { get } from 'lodash';

export const convertAddressToStringHelper = (address: AddressModel, countries: CountryModel[]): string => {
  const addressBlock1: string[] = []; // expected all/some of/none items [street, house, block, floor, room, additionalInfo]
  const addressBlock2: string[] = []; // expected all/some of/none items [zipCode, city]
  const addressBlock3: string[] = []; // expected all/some of/none items [state, country]

  // addressBlock1
  if (address.street) { addressBlock1.push(address.street); }
  if (address.house_number) { addressBlock1.push(address.house_number); }
  if (address.block) { addressBlock1.push(address.block); }
  if (address.floor) { addressBlock1.push(address.floor); }
  if (address.apartments) { addressBlock1.push(address.apartments); }
  if (address.additional_information) { addressBlock1.push(address.additional_information); }

  // addressBlock2
  if (address.zip_code) { addressBlock2.push(address.zip_code); }
  if (address.city) { addressBlock2.push(address.city); }

  // addressBlock3
  if (address.state) { addressBlock3.push(address.state); }
  if (address.country_iso3 && get(countries, 'length')) {
    const countryFilter = countries.find(countryItem => countryItem.code_iso3 === address.country_iso3);
    const country = get(countryFilter, 'title');
    if (country) {
      addressBlock3.push(country);
    }
  }


  // @START add semicolons to blocks
  if (addressBlock1.length && (addressBlock2.length || addressBlock3.length)) {
    addressBlock1[addressBlock1.length - 1] += ',';
  }

  if (addressBlock2.length && addressBlock3.length) {
    addressBlock2[addressBlock2.length - 1] += ',';
  }
  // @END add semicolons to blocks

  return [...addressBlock1, ...addressBlock2, ...addressBlock3].join(' ');
};
