import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { CommonModalsActionsEnum } from '../../modals-common';

@Component({
  selector: 'rnpl-co-products-activation-modal',
  templateUrl: './eco-products-activation-modal.component.html',
})
export class EcoProductsActivationModalComponent {

  constructor(
    public dialogRef: MatDialogRef<EcoProductsActivationModalComponent>,
  ) {
  }

  submit(): void {
    this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
  }

  reject(): void {
    this.dialogRef.close(CommonModalsActionsEnum.REJECT);
  }

}
