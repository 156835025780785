import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';

import { ImageCropperModalComponent } from './image-cropper-modal.component';



@NgModule({
  declarations: [
    ImageCropperModalComponent
  ],
  exports: [
    ImageCropperModalComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    TranslateModule
  ],
  entryComponents: [
    ImageCropperModalComponent
  ]
})
export class ImageCropperModalModule { }
