import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

import { GlAccountDropdownComponent } from './gl-account-dropdown.component';
import { RnplCommonModule } from 'common/src/modules';


@NgModule({
  declarations: [
    GlAccountDropdownComponent
  ],
  exports: [
    GlAccountDropdownComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    TranslateModule,
    NgSelectModule,
    ReactiveFormsModule
  ],
})
export class GlAccountDropdownModule { }
