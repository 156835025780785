import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { InfoItemModel, InfoItemType } from 'common/src/modules/ui-components/info-components/models/info-item.model';
import { PurchaseOrder } from '../models';
import { PartnersTypeEnum } from '../../partners/corporate/enums/partner-types.enum';

export function getToPropertiesHelper(purchaseOrder: PurchaseOrder, translateService: TranslateService): InfoItemModel[] {
  const infoItems: InfoItemModel[] = [
    {
      label: 'FORM.CONTACT_PERSON',
      isVisible: get(purchaseOrder, 'properties.company.type') === PartnersTypeEnum.CORPORATE,
      value: !get(purchaseOrder, 'properties.companyContact.id')
        ? translateService.instant('COMMON.USER_DEFINED_CONTACT')
        : get(purchaseOrder, 'properties.companyContact.name')
          .replace('Corporate Contact', translateService.instant('COMMON.CORPORATE_CONTACT'))
          .replace('Accounting Contact', translateService.instant('COMMON.ACCOUNTING_CONTACT')),
      type: get(purchaseOrder, 'properties.companyContact.id') ? InfoItemType.WITH_LINK : InfoItemType.PRIMARY,
      routerLink: [`/partners-new/${PartnersTypeEnum.CORPORATE}/${get(purchaseOrder, 'properties.company.id')}/contacts`],
      tableItem: true,
    },
    {
      label: 'FORM.EMAIL',
      value: get(purchaseOrder, 'properties.email') || '-',
      type: get(purchaseOrder, 'properties.email') ? InfoItemType.EMAIL : InfoItemType.PRIMARY,
      tableItem: true,
    },
    {
      label: 'FORM.PHONE_NUMBER',
      value: get(purchaseOrder, 'properties.phone') || '-',
      type: InfoItemType.PRIMARY,
      tableItem: true,
    },
    {
      label: 'FORM.EXTERNAL_NUMBER',
      value: get(purchaseOrder, 'properties.externalNumber'),
      type: InfoItemType.PRIMARY,
      tableItem: true,
    },
    {
      label: 'FORM.DATE_OF_ISSUE',
      value: get(purchaseOrder, 'properties.issueDate'),
      type: InfoItemType.WITH_DATE_PRIMARY,
      dateFormat: 'dd.MM.yyyy',
      tableItem: true,
    },
  ];

  return infoItems.filter((item: InfoItemModel) => get(item, 'isVisible', true));
}
