import { Component, Input, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BaseFormComponent } from '../base-form.component';
import { CEOInfoModel } from '../../../models';
import { get } from 'lodash';

@Component({
  selector: 'rnpl-ceo-info-form',
  templateUrl: './ceo-info-form.component.html',
  styleUrls: ['./ceo-info-form.component.scss'],
})
export class CeoInfoFormComponent extends BaseFormComponent implements OnInit, OnChanges {
  @Input()
  ceoInfo: CEOInfoModel;

  public titleItems: Array<string> = [
    'Dr.',
    'Prof.',
    'Prof. Dr.',
    'Ing.',
    'Dipl. -ing',
    'Mag.',
  ];

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.initForm(this.ceoInfo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('ceoInfo')) {
      this.updateForm(this.ceoInfo);
    }

    if (changes.hasOwnProperty('readonly')) {
      this.swapEditMode();
    }
  }

  swapEditMode() {
    if (!this.form) {
      return;
    }

    if (this.readonly) {
      this.form.disable({onlySelf: false});
    } else {
      this.form.enable({onlySelf: false});
    }
  }

  public updateForm(ceoInfo: CEOInfoModel) {
    if (this.form && ceoInfo) {
      for (const key in ceoInfo) {
        if (ceoInfo.hasOwnProperty(key)) {
          const formControl = this.form.get(key);
          if (formControl) {
            formControl.setValue(ceoInfo[key]);
          }
          continue;
        }
      }
    }
  }

  initForm(ceoInfo: CEOInfoModel = {} as any): void {
    this.form = this.fb.group({
      title: [
        {
          value: get(ceoInfo, 'title', ''),
          disabled: this.readonly,
        },
        [],
      ],
      birthday: [
        {
          value: get(ceoInfo, 'birthday', ''),
          disabled: this.readonly,
        },
        [],
      ],
      first_name: [
        {
          value: get(ceoInfo, 'first_name', ''),
          disabled: this.readonly,
        },
      ],
      last_name: [
        {
          value: get(ceoInfo, 'last_name', ''),
          disabled: this.readonly,
        },
      ],
      email: [
        {
          value: get(ceoInfo, 'email', ''),
          disabled: this.readonly,
        },
      ],
      phone: [
        {
          value: get(ceoInfo, 'phone', ''),
          disabled: this.readonly,
        }
      ],
      gender: [
        {
          value: get(ceoInfo, 'gender', ''),
          disabled: this.readonly,
        },
      ]
    });

    super.initForm();
  }

  get title() {
    return this.form.get('title');
  }

  get birthday() {
    return this.form.get('birthday');
  }

  get first_name() {
    return this.form.get('first_name');
  }

  get last_name() {
    return this.form.get('last_name');
  }

  get email() {
    return this.form.get('email');
  }

  get phone() {
    return this.form.get('phone');
  }

  get gender() {
    return this.form.get('gender');
  }
}
