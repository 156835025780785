import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subscription } from 'rxjs';
import { get, isEqual } from 'lodash';
import { Store } from '@ngrx/store';

// import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/delivery-note/store/actions/delivery-note.actions';
import { selectDeliveryNote, selectDeliveryNoteState } from 'projects/workspace/src/app/delivery-note/store/selectors';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { DeliveryNote } from 'projects/workspace/src/app/delivery-note/models/delivery-note.model';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { UIStatesEnum } from '../../../../models';

@Component({
  selector: 'rnpl-select-partner-modal',
  templateUrl: './select-partner-modal.component.html',
})
export class SelectPartnerModalComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public formSubscription: Subscription = null;
  public deliveryNote: DeliveryNote;
  public sidebarStatus: UIStatesEnum = UIStatesEnum.VIEW;
  public isReadonly: boolean;
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    public dialogRef: MatDialogRef<SelectPartnerModalComponent>,
    private store: Store<AppState>,
    private readonly cdr: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private readonly deliveryNoteApiService: DeliveryNoteApiService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.trackDeliveryNoteChanges();
    this.trackDeliveryNoteStateChanges();
  }

  private trackDeliveryNoteChanges(): void {
    this.store.select(selectDeliveryNote)
      .subscribe((deliveryNote: DeliveryNote) => {
        this.deliveryNote = deliveryNote;
        this.formSubscription.unsubscribe();
        this.initForm(deliveryNote);
        // this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));

        this.setControlsState(deliveryNote);
      });
  }

  private trackDeliveryNoteStateChanges(): void {
    this.store.select(selectDeliveryNoteState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.sidebarStatus = state;
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setControlsState(this.deliveryNote);
      });
  }

  private initForm(deliveryNote: DeliveryNote = {} as DeliveryNote): void {
    this.form = this.fb.group({
      customer: this.fb.group({
        email: [get(deliveryNote, 'customer.email'), {updateOn: 'blur'}],
        phone: [get(deliveryNote, 'customer.phone'), {updateOn: 'blur'}],
        partnerType: [get(deliveryNote, 'customer.partnerType'), [Validators.required]],
        generalPartnerName: [get(deliveryNote, 'customer.generalPartnerName'), {validators: [Validators.required], updateOn: 'blur'}],
        company: this.fb.group({
          id: [get(deliveryNote, 'customer.company.id'), [Validators.required]],
        }),
        contactPerson: this.fb.group({
          id: [get(deliveryNote, 'customer.contactPerson.id')],
        }),
      }),
    });

    this.cdr.detectChanges();

    this.formSubscription = this.form.valueChanges
      .pipe(
        debounceTime(50),
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const deliveryNote = {
          ...this.deliveryNote,
          ...this.form.getRawValue()
        };
        this.setControlsState(deliveryNote);
        this.deliveryNoteApiService.updateDeliveryNote(this.deliveryNote.id, deliveryNote)
          .pipe(takeUntil(this.destroy$))
          .subscribe();
      });
  }

  private setControlsState(deliveryNote: Partial<DeliveryNote>): void {
    const opts = {emitEvent: false};

    if (this.isView() || this.isPacking()) {
      this.form.disable(opts);
      return;
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }

    if (deliveryNote.isLinkedSo || deliveryNote.era || deliveryNote.eco) {
      this.companyId.disable(opts);
      this.partnerType.disable(opts);
      this.generalPartnerName.disable(opts);
      return;
    } else {
      this.companyId.enable(opts);
      this.partnerType.enable(opts);
      this.generalPartnerName.enable(opts);
    }

    if (!get(this.deliveryNote, 'customer.company.id')) {
      this.form.get('customer').get('contactPerson').get('id').disable(opts);
      this.form.get('customer').get('email').disable(opts);
      this.form.get('customer').get('phone').disable(opts);
    }
  }

  public isView(): boolean {
    return (this.sidebarStatus === UIStatesEnum.VIEW);
  }

  public isPacking(): boolean {
    return (this.sidebarStatus === UIStatesEnum.PACKING);
  }

  get partnerType(): FormControl { return this.form.get('customer').get('partnerType') as FormControl; }
  get generalPartnerName(): FormControl { return this.form.get('customer').get('generalPartnerName') as FormControl; }
  get email(): FormControl { return this.form.get('customer').get('email') as FormControl; }
  get phone(): FormControl { return this.form.get('customer').get('phone') as FormControl; }
  get companyId(): FormControl { return this.form.get('customer').get('company').get('id') as FormControl; }
  get contactPersonId(): FormControl { return this.form.get('customer').get('contactPerson').get('id') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
