import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TimePickerDisplayedValuePipe, TimePickerFocusedValuePipe } from '../../rnpl-common';

@Component({
  selector: 'rnpl-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent {

  @Input() optional: boolean = false;
  @Input() label: string = 'Time';
  @Input() controlTimeFormat: string;
  @Input() control: FormControl;
  @Input() showTime: Date = null;
  @Input() required: boolean = false;
  @Input() showErrors: boolean = false;

  public isOpenDropdown: boolean = false;

  @Output() changed: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private readonly datePipe: DatePipe,
    public readonly timePickerFocusedValuePipe: TimePickerFocusedValuePipe,
    public readonly timePickerDisplayedValuePipe: TimePickerDisplayedValuePipe,
    ) {
  }

  public clearShowTime(): void {
    this.showTime = null;
  }

  openPicker(openDialog, dropDownTarget): void {
    openDialog(openDialog(dropDownTarget));
    this.isOpenDropdown = !this.isOpenDropdown;

    this.setDropdownWidth(dropDownTarget);
  }

  closePicker (openDialog, pickerClose, dropDownTarget): void {
    openDialog(pickerClose());
    this.isOpenDropdown = !this.isOpenDropdown;

    this.setDropdownWidth(dropDownTarget)
  }

  setDropdownWidth(dropDownTarget) {
    const pickerWidth = dropDownTarget.getBoundingClientRect().width;
    const overlayContent = document.querySelectorAll<HTMLElement>('.igx-overlay__content');
    overlayContent.forEach(el => el.style.width = pickerWidth + 'px');
  }

  timeChanged(event): void {
    if (!this.control) { return; }

    const value = this.controlTimeFormat
      ? this.datePipe.transform(event.newValue, this.controlTimeFormat)
      : event.newValue;

    this.control.setValue(value);
  }

  timeChangedByInput(event): void {
    if (event) {
      const timeArr = event.replace(/_/g, '').split(':');
      timeArr[0] = (timeArr[0] && +timeArr[0] > 23) ? 23 : (timeArr[0] || '00');
      timeArr[1] = (timeArr[1] && +timeArr[1] > 59) ? 59 : (timeArr[1] || '00');
      this.showTime = new Date( new Date().setHours(timeArr[0] || '00', timeArr[1] || '00', 0));
      event = this.timePickerDisplayedValuePipe.transform(event);
    }
    this.control.setValue(event);
    this.changed.emit(event);
  }

}
