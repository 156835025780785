import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SystemSettingsService } from 'common/src/modules/system-settings';
import { EMPTY } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { PartnersApiService } from '../../partners/corporate/services/partner-api.service';
import { PrivatePartnersApiService } from '../../partners/private/services/partner-api.service';
import { LoadCategories, LoadCategoriesSuccess, LoadCountries, LoadCountriesSuccess } from '../actions/shared.actions';

@Injectable()
export class SharedEffects {

  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCountries),
    mergeMap(() => this.systemSettingsService.getAvailableCountries()
      .pipe(
        map(response => (LoadCountriesSuccess({countries: response.data}))),
        catchError(() => EMPTY)
      ))
    )
  );

  loadCategories$ = createEffect(() => this.actions$.pipe(
    ofType(LoadCategories),
    mergeMap(() => this.systemSettingsService.getAvailableCategories()
      .pipe(
        map(categories => (LoadCategoriesSuccess({categories}))),
        catchError(() => EMPTY)
      ))
    )
  );

  constructor(
    private actions$: Actions,
    private readonly partnerApiService: PartnersApiService,
    private readonly privatePartnerApiService: PrivatePartnersApiService,
    private readonly systemSettingsService: SystemSettingsService,
  ) {}
}
