import { Component, Input } from '@angular/core';

@Component({
  selector: 'rnpl-selected-items-badge',
  templateUrl: './selected-items-badge.component.html',
  styleUrls: ['./selected-items-badge.component.scss']
})
export class SelectedItemsBadgeComponent {

  @Input() itemsLength: number;

}
