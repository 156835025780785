import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { UIStatesEnum } from 'common/src/models';
import { FileService } from 'common/src/services/file.service';
import { AppState } from '../../../store/state/app.state';
import {
  selectPoCurrentState,
  selectPurchaseOrder
} from '../../store/selectors';
import { environment } from 'projects/workspace/src/environments/environment';
import { AuthService } from 'common/src/auth/auth.service';
import { PoFileModel, PurchaseOrder } from '../../models';
import { PurchaseOrderApiService } from '../../services/purchase-order-api.service';

@Component({
  selector: 'rnpl-purchase-order-file',
  templateUrl: './purchase-order-file.component.html',
  styleUrls: ['./purchase-order-file.component.scss']
})
export class PurchaseOrderFileComponent implements OnInit, OnDestroy {

  public purchaseOrder: PurchaseOrder;
  public currentState: UIStatesEnum = UIStatesEnum.VIEW;
  public statesEnum: typeof UIStatesEnum = UIStatesEnum;

  @ViewChild('uploader', { static: true }) uploader: TemplateRef<any>;

  readonly files$: BehaviorSubject<PoFileModel[]> = new BehaviorSubject<PoFileModel[]>([]);
  readonly uploadPdfLink$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() public isTypeView: boolean = false;

  constructor(
    private readonly store: Store<AppState>,
    private toasterService: ToasterService,
    private purchaseOrderApiService: PurchaseOrderApiService,
    private readonly fileService: FileService,
    private readonly fileSaverService: FileSaverService,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.trackIncomingInvoiceChanges();
    this.trackIncomingInvoiceStateChanges();
  }

  private trackIncomingInvoiceChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((purchaseOrder: PurchaseOrder) => {
        this.purchaseOrder = purchaseOrder;
        const filesWithType: PoFileModel[] = purchaseOrder.files.map((file: any) => {
          return {
            ...file,
            type: file.title.split('.').pop()
          }
        });
        this.files$.next(filesWithType);
        this.uploadPdfLink$.next(this.purchaseOrderApiService.getUploadPdfLink(this.purchaseOrder.id));
      });
  }

  private trackIncomingInvoiceStateChanges(): void {
    this.store.select(selectPoCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.currentState = state;
      });
  }

  public uploadFile(): void {
    if (this.currentState === UIStatesEnum.VIEW) { return; }
    this.uploader.elementRef.nativeElement.firstElementChild.click();
  }

  public trackByFn(item) {
    return item.id;
  }

  public onImageSet() {
    this.purchaseOrderApiService.getPO(this.purchaseOrder.id).subscribe();
  }

  public deleteFile(fileId) {
    this.purchaseOrderApiService.deleteFile(this.purchaseOrder.id, fileId).subscribe();
  }

  public downloadFile(file): void {
    this.purchaseOrderApiService.getDownloadFileLink(file.id, file.title)
      .pipe(takeUntil(this.destroy$))
      .subscribe((fileParams: FileUploadParams) => {
        const {url, title} = fileParams;
        this.fileService.downloadFile(url)
          .subscribe((res: any) => {
            this.fileSaverService.save(res.body, `${res.filename || title}`);
          });
      }, error => {
        this.showMsg('error', error.error.message || error.error.errors);
      });
  }

  public getFileUrl(file: PoFileModel) {
    const basicPath = environment.javaApiUrl + environment.javaApiVersion;
    const apiPath = '/utils/download';
    const params = {
      name: encodeURIComponent(file.title),
      resource: file.key,
      uid: this.authService.getToken()
    };
    return `${basicPath}${apiPath}?${new URLSearchParams(params).toString()}`;
  }

  private showMsg(type: string, message: string): void {
    this.toasterService.notify({type, message});
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
