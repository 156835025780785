import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { ExportActionModel } from '../../models';


@Component({
  selector: 'rnpl-export-btn',
  templateUrl: './export-button.component.html',
  styleUrls: ['./export-button.component.scss']
})
export class ExportButtonComponent {

  @Input()
  actions: ExportActionModel[] = [];

  constructor(private http: HttpClient) {

  }

  exportFrom(path: string): void {
    this.http.get(path)
      .subscribe(response => {
        const data = (response as any).data;
        if (data && data.url) {
          window.open(`https://${data.url}`, '_blank');
        }
      });
  }

}
