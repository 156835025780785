import { Component, Input, } from '@angular/core';

@Component({
  selector: 'rnpl-icon-tip',
  templateUrl: './icon-tip.component.html',
  styleUrls: ['./icon-tip.component.scss']
})

export class IconTipComponent {

  @Input()
  public iconClasses: string;

  @Input()
  public title = 'Info';

  @Input()
  public width = 272; //default width

  public visible = false;

  constructor() {

  }

  public toggle(): void {
    this.visible = !this.visible;
  }

}
