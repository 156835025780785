import { Component, Input, OnChanges, OnInit, Optional } from '@angular/core';

import { CategoryFamilyModel } from '../../models';
import { LinearTreeComponent } from '../linear-tree/linear-tree.component';
import { TreeEntityDefinitionModel } from '../linear-tree/tree-entity-definition.model';

@Component({
  selector: 'rnpl-linear-tree-branch',
  templateUrl: './linear-tree-branch.component.html',
  styleUrls: ['./linear-tree-branch.component.scss']
})
export class LinearTreeBranchComponent implements OnInit, OnChanges {

  @Input()
  public isLoading = false;

  @Input()
  public tree: Array<CategoryFamilyModel> = [];

  @Input()
  public title: string;

  @Input()
  public entity: TreeEntityDefinitionModel;

  @Input()
  public selectedParentNode: CategoryFamilyModel;

  public selectedNode: CategoryFamilyModel;

  constructor(@Optional() public wrap: LinearTreeComponent) {
  }

  ngOnChanges(): void {
    this.selectedNode = null;
  }

  ngOnInit(): void {
    this.selectedNode = this.tree
      .find(node => this.wrap.selectedNodes.isSelected(node));

    this.wrap.selectedNodes.changed
      .subscribe(() => {
        this.selectedNode = this.tree
          .find(node => this.wrap.selectedNodes.isSelected(node));
      });
  }
}
