import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'rnpl-apply-changes',
  templateUrl: './apply-changes.component.html',
  styleUrls: ['./apply-changes.component.scss'],
})
export class ApplyChangesComponent implements OnChanges {

  public changesApplied = false;

  @Input() public appliedDate: Date;
  @Input() public haveUnsaved: boolean;
  @Input() public applying: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('applying') && changes.applying.previousValue) {
      this.changesApplied = true;
      setTimeout(() => {
        this.cdr.detectChanges();
        this.changesApplied = false;
        this.cdr.detectChanges();
      }, 1000);
    }
  }

  get iconName(): string {
    if (this.changesApplied) {
      return 'checkmark';
    }
    if (this.haveUnsaved) {
      return 'info';
    }
    if (this.appliedDate) {
      return  'save';
    }
  }

}
