import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import * as fromTeams from './store/reducers';

import {
  DynamicFormsModule,
  UIComponentsModule,
  SystemSettingsCommonModule,
  UsersCommonModule,
  RnplCommonModule
} from 'common/src/modules';
import {
  EmployeeProfileComponent,
  HrmMainComponent,
  UserListComponent,
  PersonalInfoComponent,
  RoleAndPermissionsComponent,
  PreferencesSecurityComponent,
  PreferencesSystemComponent,
} from './pages';
import { HrmService } from './hrm.service';
import { HrmRoutingModule } from './hrm-routing.module';
import { EntitiesManagementModule } from 'common/src/modules/entities-management/entities-management.module';
import { IsUserGuard } from './guards/is-user.guard';
import { EmployeeFormComponent } from './pages/employee-form/employee-form.component';
import { DepartmentFormComponent } from './pages/department-form/department-form.component';
import { MyProfileGuard } from './guards/my-profile.guard';
import { UserTableSettingsService } from '../shared/services';
import { TeamMegaHintComponent } from './team-mega-hint/team-mega-hint.component';
import { ColumnsSettingsModule } from '../shared/components/columns-settings/columns-settings.module';
import { FiltersModule } from 'common/src/modules/filters';
import { DocumentListContainerModule } from '../shared/components/document-list-container/document-list-container.module';


@NgModule({
  declarations: [
    HrmMainComponent,
    UserListComponent,
    EmployeeProfileComponent,
    EmployeeFormComponent,
    DepartmentFormComponent,
    PersonalInfoComponent,
    RoleAndPermissionsComponent,
    PreferencesSecurityComponent,
    PreferencesSystemComponent,
    TeamMegaHintComponent,
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    MatMenuModule,
    HrmRoutingModule,
    UsersCommonModule,
    SystemSettingsCommonModule,
    DynamicFormsModule,
    UIComponentsModule,
    EntitiesManagementModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    StoreModule.forFeature(
      fromTeams.TeamsFeatureKey,
      {
        list: fromTeams.TeamsListReducer,
        entity: fromTeams.TeamsEntityReducer,
      }
    ),
    TranslateModule,
    ColumnsSettingsModule,
    FiltersModule,
    DocumentListContainerModule,
    PerfectScrollbarModule
  ],
  exports: [
    DepartmentFormComponent,
    EmployeeFormComponent
  ],
  providers: [
    HrmService,
    IsUserGuard,
    MyProfileGuard,
    UserTableSettingsService
  ]
})
export class HrmModule {
}
