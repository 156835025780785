import { CreditNotePurposeEnum, CreditNotesListTabsEnum } from './enums';
import { FlagsEnum } from 'common/src/models/flags.enum';
import { PaymentDirectionEnum } from '../payment/models/payment.model';

export const STATUS_CLASSES: {[key: string]: string} = {
  [CreditNotesListTabsEnum.DRAFT]: 'rnpl-badge--grey',
  [CreditNotesListTabsEnum.OPEN]: 'rnpl-badge--blue',
  [CreditNotesListTabsEnum.PAID]: 'rnpl-badge--green',
  [CreditNotesListTabsEnum.CANCELED]: 'rnpl-badge--red',
  [CreditNotesListTabsEnum.DELETED]: 'rnpl-badge--empty',
  [FlagsEnum.EDITING]: 'rnpl-badge--main-400',
};

export const CRN_PURPOSES: {value: CreditNotePurposeEnum; label: string}[] = [
  {
    value: CreditNotePurposeEnum.OIN_CORRECTION,
    label: 'FORM.OUTGOING_INVOICE_CORRECTION'
  },
  {
    value: CreditNotePurposeEnum.IIN_CORRECTION,
    label: 'FORM.INCOMING_INVOICE_CORRECTION'
  },
  {
    value: CreditNotePurposeEnum.COMMON_CORRECTION,
    label: 'FORM.COMMON_CORRECTION'
  },
];

export const CRN_COMMON_CORRECTION_DIRECTIONS: {value: PaymentDirectionEnum; label: string}[] = [
  {
    value: PaymentDirectionEnum.OUTGOING,
    label: 'TABLE_SUMMARY_BAR.OUTGOING'
  },
  {
    value: PaymentDirectionEnum.INCOMING,
    label: 'TABLE_SUMMARY_BAR.INCOMING'
  },
];

export function getCRNInvoiceTitle(crnPurpose: CreditNotePurposeEnum): string {
  let title: string;

  switch (crnPurpose) {
    case CreditNotePurposeEnum.IIN_CORRECTION:
      title = 'APP.INCOMING_INVOICE';
      break;
    case CreditNotePurposeEnum.OIN_CORRECTION:
      title = 'APP.OUTGOING_INVOICE';
      break;
    default:
      title = 'APP.INVOICE';
  }

  return title;
}
