import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Observable, ReplaySubject, BehaviorSubject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { SubscriptionApiService } from 'projects/workspace/src/app/subscription/services/subscription-api.service';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { DocumentTemplateModel } from 'projects/workspace/src/app/shared/models';
import { TradeOfferApiService } from 'projects/workspace/src/app/trade-offer/services/trade-offer-api.service';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { DocumentsTemplatesApiService } from 'projects/workspace/src/app/shared/services';
import { ToasterService } from '../../../ui-components/toaster';
import { FlagsEnum } from '../../../../models/flags.enum';


@Component({
  selector: 'rnpl-document-templates-modal',
  templateUrl: './document-templates-modal.component.html',
  styleUrls: ['./document-templates-modal.component.scss'],
})
export class DocumentTemplatesModalComponent {

  public flagsEnum = FlagsEnum;
  public realCurrencyValue = false;
  public searchValue: string = '';
  public selectedTemplate: DocumentTemplateModel;
  private templates: DocumentTemplateModel[] = [];

  public statusClasses = {
    ['editing']: 'rnpl-badge--main-400',
    ['corrupted']: 'rnpl-badge--orange'
  };

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @ViewChild('searchFieldRef', {static: false}) searchFieldRef: ElementRef;

  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private toasterService: ToasterService,
    private deliveryNoteApiService: DeliveryNoteApiService,
    private tradeOfferApiService: TradeOfferApiService,
    private salesOrderApiService: SalesOrderApiService,
    private purchaseOrderApiService: PurchaseOrderApiService,
    private subscriptionApiService: SubscriptionApiService,
    private documentsTemplatesApiService: DocumentsTemplatesApiService,
    public dialogRef: MatDialogRef<DocumentTemplatesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      documentType: DocumentTypesUppercaseEnum,
      documentId: number
    }
  ) {
    this.getTemplates();
  }

  public getTemplates(): void {
    this.isLoading$.next(true);
    if (!this.data.documentType) { return; }

    let request$: Observable<any>;

    switch (this.data.documentType) {
      case DocumentTypesUppercaseEnum.OFR:
        request$ = this.tradeOfferApiService.getDocumentTemplates();
        break;
      case DocumentTypesUppercaseEnum.SO:
        request$ = this.salesOrderApiService.getDocumentTemplates();
        break;
      case DocumentTypesUppercaseEnum.PO:
        request$ = this.purchaseOrderApiService.getDocumentTemplates();
        break;
      case DocumentTypesUppercaseEnum.SBC:
        request$ = this.subscriptionApiService.getDocumentTemplates();
        break;
      case DocumentTypesUppercaseEnum.DN:
        request$ = this.deliveryNoteApiService.getDocumentTemplates();
        break;
      case DocumentTypesUppercaseEnum.OIN:
      case DocumentTypesUppercaseEnum.OPB:
        this.realCurrencyValue = true;
        request$ = this.documentsTemplatesApiService.getDocumentTemplates(this.data.documentType);
        break;
    }

    if (!request$) { return; }

    request$
      .pipe(takeUntil(this.destroy$))
      .subscribe((templates: DocumentTemplateModel[]) => {
        this.templates = templates;

        if (!!this.searchFieldRef) {
          this.searchFieldRef.nativeElement.focus();
        }

        this.isLoading$.next(false);
      });
  }

  public selectTemplate(template: DocumentTemplateModel): void {
    this.selectedTemplate = template;
    this.documentsTemplatesApiService.applyPreviewTemplateDocument(template.id);
  }

  public clearSearch(): void {
    this.searchValue = '';
  }

  public submit(force = false): void {
    if (!this.selectedTemplate) { return; }

    let request$: Observable<any>;

    switch (this.data.documentType) {
      case DocumentTypesUppercaseEnum.OFR:
        request$ = this.tradeOfferApiService.applyDocumentTemplate(this.data.documentId, this.selectedTemplate.id, force);
        break;
      case DocumentTypesUppercaseEnum.SO:
        request$ = this.salesOrderApiService.applyDocumentTemplate(this.data.documentId, this.selectedTemplate.id, force);
        break;
      case DocumentTypesUppercaseEnum.PO:
        request$ = this.purchaseOrderApiService.applyDocumentTemplate(this.data.documentId, this.selectedTemplate.id, force);
        break;
      case DocumentTypesUppercaseEnum.SBC:
        request$ = this.subscriptionApiService.applyDocumentTemplate(this.data.documentId, this.selectedTemplate.id, force);
        break;
      case DocumentTypesUppercaseEnum.DN:
        request$ = this.deliveryNoteApiService.applyDocumentTemplate(this.data.documentId, this.selectedTemplate.id, force);
        break;
      case DocumentTypesUppercaseEnum.OIN:
      case DocumentTypesUppercaseEnum.OPB:
        request$ = this.documentsTemplatesApiService.applyDocumentTemplate(
          this.data.documentType,
          this.data.documentId,
          this.selectedTemplate.id,
          false
        );
        break;
    }

    if (!request$) { return; }

    request$
      .pipe(
        catchError(error => {
          this.submitRequest$.next(false);

          if (error.error.message) {
            this.checkError(error.error.message);
          }

          if (error.error.errors && error.error.errors.length) {
            error.error.errors.forEach((err: string) => {
              this.checkError(err);
            });
          }

          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.closeDialog());
  }

  private checkError(error: string): void {
    this.toasterService.notify({type: 'error', message: error});
    // switch (error) {
    //   case 'dataWillBeOverwrittenByTemplate':
    //   {
    //     const dialog = this.dialog.open(WarningModalComponent, {
    //       data: {
    //         title: 'DOCUMENTS_TEMPLATES.LINKED_DOCUMENT_ERROR_TITLE',
    //         message: 'DOCUMENTS_TEMPLATES.LINKED_DOCUMENT_ERROR_MSG',
    //         confirmBtnText: 'BUTTON.APPLY',
    //         confirmBtnIcon: 'checkmark',
    //       }
    //     });
    //
    //     dialog.afterClosed().subscribe(res => {
    //       if (res === CommonModalsActionsEnum.CONFIRM) {
    //         this.submit(true);
    //       }
    //     });
    //   }
    //     break;
    //   default:
    //     this.toasterService.notify({type: 'error', message: error});
    // }
  }

  public closeDialog(): void {
    this.documentsTemplatesApiService.applyPreviewTemplateDocument(null);
    this.dialogRef.close(this.data.documentId);
  }

  get filteredTemplated(): DocumentTemplateModel[] {
    if (!this.searchValue) { return this.templates; }
    const searchValue = this.searchValue.toLowerCase();

    return this.templates.filter((tpl: DocumentTemplateModel) => {
      return (tpl.customer && tpl.customer.name && tpl.customer.name.toLowerCase().includes(searchValue))
        || (tpl.name && tpl.name.toLowerCase().includes(searchValue))
        || (tpl.products && tpl.products.length && tpl.products.some((name: string) => name.toLowerCase().includes(searchValue)));
    });
  }

}

