import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

export class ApiInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('https:') && request.url.includes('s3')) {
      return next.handle(request);
    }

    // todo: refactor
    let url: string;

    if (
      request.url.includes(environment.javaApiVersion) ||
      request.url.includes(environment.javaServicesApiVersion) ||
      request.url.includes(environment.javaReportsApiVersion) ||
      request.url.includes(environment.javaCommonsApiVersion) ||
      request.url.includes(environment.javaEcommerceApiVersion)
    ) { // weak checking (need to find better way)
      url = environment.javaApiUrl + request.url;
    } else {
      const apiBase = request.url.includes('v2/')
        ? environment.apiBaseV2
        : environment.apiBase;
      const urlFiltered = request.url.includes('/v2')
        ? request.url.split('/v2').pop()
        : request.url;
      url = environment.apiUrl + apiBase + urlFiltered;
    }

    if (!request.url.includes('i18n')) {
      request = request.clone({
        url,
        setHeaders: {Accept: 'application/json'}
      });
    }
    return next.handle(request);
  }

}

