import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { BreadcrumbModel } from './breadcrumb.model';


@Injectable()
export class BreadcrumbsService {

  public additionalBreadcrumbs: Subject<Array<BreadcrumbModel>> = new Subject();

  public addBreadcrumbs(breadcrumbs: Array<BreadcrumbModel>): void {
    this.additionalBreadcrumbs.next(breadcrumbs);
  }

}
