import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../auth/auth.service';
import { ToasterService } from '../../modules/ui-components/toaster';
import { SoundService } from '../../services/sound.service';


@Component({
  selector: 'rnpl-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {

  public showPassword: boolean = false;
  public emailErrMessage: string;
  public passwordErrMessage: string;

  public signInForm: FormGroup;

  private btnIsClicked: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private toasterService: ToasterService,
    public soundService: SoundService) {
  }

  ngOnInit() {
    this.initForm();
  }

  /**
   * Displays/hides password symbols
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Sets error message for invalid field
   *
   * @param controlName string
   * @param event MouseEvent
   */
  public setError(controlName: string, event?: MouseEvent): void {
    if (event && !(event.target as HTMLInputElement).value) {
      return;
    }

    const control = this.signInForm.get(controlName);

    if (control.invalid) {
      switch (controlName) {
        case 'email':
          if (this.btnIsClicked && !control.value) {
            this.emailErrMessage = 'Email cannot be blank.';
          } else {
            this.emailErrMessage = 'Invalid Email address.';
          }
          break;
        case 'password':
          if (this.btnIsClicked && !control.value) {
            this.passwordErrMessage = 'Password cannot be blank.';
          } else {
            this.passwordErrMessage = 'Invalid password.';
          }
          break;
        default:
      }
      this.soundService.playSound('toast_error');
    }
  }

  /**
   * Invokes authorization method of AuthService
   */
  public signIn(): void {
    this.btnIsClicked = true;

    if (this.signInForm.invalid) {
      this.setError('email');
      this.setError('password');
      return;
    }

    this.authService.signIn(
      this.signInForm.get('email').value,
      this.signInForm.get('password').value
    )
      .subscribe((response: any) => {
        if (response.data.two_factor_code && response.data.user) {

          const state = {
            ...this.signInForm.getRawValue(),
            data: response.data
          };

          this.router.navigate(['/auth-key'], { state: state });

        } else {
          this.router.navigate(['/']);
        }
      }, error => {
        this.toasterService.notify({
          type: 'error',
          message: 'Wrong email or password.'
        });
      });
  }

  /**
   * Initializes sign in form
   */
  private initForm(): void {
    this.signInForm = this.fb.group({
      'email': [null, [Validators.required, Validators.email]],
      'password': [null, [/* todo: add password validators */]]
    });

    this.signInForm.get('email').valueChanges
      .subscribe(() => {
        this.emailErrMessage = '';
      });

    this.signInForm.get('password').valueChanges
      .subscribe(() => {
        this.passwordErrMessage = '';
      });
  }
}
