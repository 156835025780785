import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import { TimeTrackingRecordModel } from 'projects/workspace/src/app/time-tracking/models';
import { TimeTrackingApiService } from 'projects/workspace/src/app/time-tracking/services/time-tracking-api.service';
import { getTableColumns } from './time-tracking-bill-time-modal.config';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { TimeTrackingGroupingModalComponent } from '../time-tracking-grouping-modal/time-tracking-grouping-modal.component';


@Component({
  selector: 'rnpl-time-tracking-bill-time-modal',
  templateUrl: './time-tracking-bill-time-modal.component.html'
})
export class TimeTrackingBillTimeModalComponent extends BaseModalComponent implements OnInit {
  public columns = getTableColumns(this.translateService);

  public customersList: TimeTrackingRecordModel[] = [];
  public records: TimeTrackingRecordModel[] = [];
  public selectedRows: TimeTrackingRecordModel[] = [];
  public selectedCustomer: TimeTrackingRecordModel = null;
  public emptyStateTypeEnum = EmptyStateTypeEnum;

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    public toasterService: ToasterService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private timeTrackingApiService: TimeTrackingApiService,
    public dialogRef: MatDialogRef<TimeTrackingBillTimeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      record: TimeTrackingRecordModel,
      customersList: TimeTrackingRecordModel[],
      multipleRecords: TimeTrackingRecordModel[],
    }
  ) {
    super(toasterService);
  }

  ngOnInit(): void {
    if (this.data && this.data.customersList) {
      this.customersList = this.data.customersList;
      this.showDropdownSpin$.next(false);
    } else {
      this.timeTrackingApiService.getPartnersBillingList()
        .pipe(
          finalize(() => this.showDropdownSpin$.next(false)),
          takeUntil(this._destroy)
        )
        .subscribe(records => {
          this.customersList = records;
        });
    }
  }

  // ngAfterViewChecked(): void {
  //   window.dispatchEvent(new Event('resize'));
  // }

  public customerChanged(): void {
    this.selectedRows = [];

    const filters: any = {
      billable: true,
      approved : true,
      hasDocuments: false
    };

    if (this.selectedCustomer.partnerInfo.id) {
      filters.partnerIds = [this.selectedCustomer.partnerInfo.id];
    }

    if (!this.selectedCustomer.partnerInfo.id && this.selectedCustomer.partnerInfo.name) {
      filters.arbitrary = true;
      filters.partnerNames = [this.selectedCustomer.partnerInfo.name];
    }

    this.timeTrackingApiService.getOpenRecordsByPartner(filters)
      .pipe(takeUntil(this._destroy))
      .subscribe(records => {
        this.records = records.map(record => ({
          ...record,
          productLink: {
            label: record.product.runpleId,
            routerLink: `/products/product-view/${record.product.id}`
          },
          unitType: record.loggedUnits,
          loggedDuration: get(record, 'loggedUnits') === 'l.s.'
            ? record.loggedQuantity
            : record.loggedDuration
        }));
      });
  }

  public createInvoice(): void {
    const recordIds = this.selectedRows.map(record => record.id);
    this.dialog.open(TimeTrackingGroupingModalComponent, {
      data: {
        submitRequestFn$: (groupingFormData) => {
          return this.timeTrackingApiService.createOINBasedOnTimeTrackingRecords(recordIds, groupingFormData);
        }
      },
      disableClose: true
    });
  }

}
