import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { ModalContainerComponent } from './modal-container.component';


@NgModule({
  declarations: [
    ModalContainerComponent
  ],
  exports: [
    ModalContainerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    DragDropModule,
    PerfectScrollbarModule,
  ]
})
export class ModalContainerModule { }
