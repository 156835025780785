import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { selectCompanyProfile, selectCompanyTimezoneOffset } from 'projects/workspace/src/app/administration/store/selectors';
import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums/partner-types.enum';
import { DocumentTypesUppercaseEnum } from '../../modals/modals-common/link-document-modal/enums/ducument-types.enum';
import { getDocumentUrl } from 'projects/workspace/src/app/shared/helpers';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';

@Component({
  selector: 'rnpl-view-summary-document',
  templateUrl: './view-summary-document.component.html',
  styleUrls: ['./view-summary-document.component.scss']
})
export class ViewSummaryDocumentComponent implements OnInit, OnDestroy {

  public title = {
    [DocumentTypesUppercaseEnum.PMNT]: 'DOC_SUMMARY.PAYMENT'
  };

  public companyProfile: CompanyProfile;
  public documentTypesUppercaseEnum = DocumentTypesUppercaseEnum;
  public getDocumentUrlLink = getDocumentUrl;

  @Input() document: any = null;
  @Input() currency: string = ' €';
  @Input() currentDocumentType: DocumentTypesUppercaseEnum;
  @Input() linkToDocumentType?: DocumentTypesUppercaseEnum;

  readonly companyProfileTimezoneOffset$: Observable<string> = this.store.select(selectCompanyTimezoneOffset);
  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private readonly store: Store<AppState>,
  ) { }

  ngOnInit() {
    this.store.select(selectCompanyProfile)
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      ).subscribe((profile: CompanyProfile) => {
        this.companyProfile = profile;
      });
  }

  public getDocumentTitle(document): string {
    if (document.type === DocumentTypesUppercaseEnum.IIN || document.type === DocumentTypesUppercaseEnum.ICN) {
      return document.externalNumber;
    }
    return document.runpleId;
  }

  public get partnerUrl(): string {
    if (this.partner.type === PartnersTypeEnum.CORPORATE || this.partner.type === PartnersTypeEnum.PRIVATE) {
      return `/partners-new/${this.partner.type}/${this.partner.id}`;
    }

    return null;
  }

  public get partner() {
    return this.document.partner;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
