import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';


import { WIZARD_ROUTES } from 'common/src/modules/wizard';


import {
  AttributesComponent,
  EntitiesManagementComponent,
  FormSetupComponent,
  FormSetupViewComponent,
} from './pages';


const moduleRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'user/employee/attributes/1',
  },
  {
    path: ':type/:entity/attributes/:page',
    component: AttributesComponent
  },
  {
    path: ':type/:entity/form-composer/:step',
    component: FormSetupComponent
  },
  {
    path: ':type/:entity/form-composer-view',
    component: FormSetupViewComponent
  },
  ...WIZARD_ROUTES
];

const routes: Routes = [
  {
    path: '',
    component: EntitiesManagementComponent,
    children: moduleRoutes
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EntitiesManagementRoutingModule {
}
