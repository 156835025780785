import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { EcoSettingsPaymentsModel } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';
import {
  AccountingSettingsService
} from 'projects/workspace/src/app/accounting/accounting-settings-module/services/accounting-settings.service';

@Component({
  selector: 'rnpl-update-card-payments-modal',
  templateUrl: './update-card-payments-modal.component.html',
  styleUrls: ['./update-card-payments-modal.component.scss']
})
export class UpdateCardPaymentsModalComponent extends BaseModalComponent implements OnInit {

  public cardPaymentsControl: FormControl = new FormControl();

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly updateRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly manageRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private location: Location,
    public toasterService: ToasterService,
    public accountingSettingsService: AccountingSettingsService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<UpdateCardPaymentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      paymentsSettings: EcoSettingsPaymentsModel
    }
  ) {
    super(toasterService);
  }

  ngOnInit() {
    this.cardPaymentsControl.patchValue(get(this, 'data.paymentsSettings.cardPayment.enabled'));
    if (
      !this.data.paymentsSettings.cardPayment.stripeAccount ||
      !get(this, 'data.paymentsSettings.cardPayment.stripeAccount.chargesEnabled') ||
      !get(this, 'data.paymentsSettings.cardPayment.stripeAccount.detailsSubmitted')
    ) {
      this.cardPaymentsControl.disable();
    } else {
      this.cardPaymentsControl.enable();
    }
  }

  public manageStripeAccount(): void {
    if (this.manageRequest$.getValue()) {return; }
    this.manageRequest$.next(true);

    this.accountingSettingsService.updateStripeAccount(this.location.path())
      .pipe(
        finalize(() => this.manageRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe((response) => {
        if (response.url) {
          window.open(response.url, '_blank');
        }
      });
  }

  public submit(): void {
    if (this.updateRequest$.getValue()) {return; }

    this.updateRequest$.next(true);

    this.ecoSettingsApiService.updateEcoSettingsPayments({
      fieldValue: this.cardPaymentsControl.value,
      fieldName: 'cardPayment.enabled'
    })
      .pipe(
        takeUntil(this._destroy),
        finalize(() => this.updateRequest$.next(false))
      )
      .subscribe((settings: EcoSettingsPaymentsModel) => this.dialogRef.close(settings));
  }

}
