import { CommonModalDataModel } from 'common/src/modules/modals/modals-common';

export const DeleteTemplateModalData: CommonModalDataModel = {
  title: 'DOCUMENTS_TEMPLATES.DELETE_TEMPLATE_TITLE',
  message: 'DOCUMENTS_TEMPLATES.DELETE_TEMPLATE_MSG',
  confirmBtnText: 'BUTTON.DELETE',
  confirmBtnIcon: 'trash-2'
};

export const DeleteMultipleTemplatesModalData: CommonModalDataModel = {
  title: 'DOCUMENTS_TEMPLATES.DELETE_TEMPLATE_MULTIPLE_TITLE',
  message: 'DOCUMENTS_TEMPLATES.DELETE_TEMPLATE_MULTIPLE_MSG',
  confirmBtnText: 'BUTTON.DELETE',
  confirmBtnIcon: 'trash-2'
};
