import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { getColumns } from './create-so-modal.config';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { TradeDocumentPositionModel } from 'projects/workspace/src/app/trade/models';
import { PurchaseOrderApiService } from 'projects/workspace/src/app/purchase-order/services/purchase-order-api.service';
import { SalesOrderModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';

@Component({
  selector: 'rnpl-create-so-modal',
  templateUrl: './create-so-modal.component.html',
})
export class CreateSoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public columns: TableColumnModelExtended[] = getColumns();
  public emptyStateTypeEnum = EmptyStateTypeEnum;
  public addAllToggle = true;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly productsList$: BehaviorSubject<TradeDocumentPositionModel[]> = new BehaviorSubject<TradeDocumentPositionModel[]>([]);
  readonly productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    public salesOrderApiService: SalesOrderApiService,
    public purchaseOrderApiService: PurchaseOrderApiService,
    public dialogRef: MatDialogRef<CreateSoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      poId: number,
    }
  ) {}

  ngOnInit(): void {
    this.purchaseOrderApiService.getPositionsAvailableForSO(this.data.poId)
      .pipe(
        finalize(() => this.productsLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((products) => this.productsList$.next(this.prepareProductsList(products)));
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  public prepareProductsList(products): any {
    return products.map(item => {
      return {
        ...item,
        productLink: {
          label: item.productRunpleId,
          routerLink: `/products/product-view/${item.productId}`
        },
        productNameLink: {
          label: item.description,
          routerLink: `/products/product-view/${item.productId}`
        },
        confirmQuantity: item.quantity
      };
    });
  }

  public addAllProducts(e: boolean): void {
    if (!e) { return; }
    const products = this.productsList$.getValue().map(p => ({
      ...p,
      confirmQuantity: p.quantity
    }));
    this.productsList$.next(products);
  }

  public submit(): void {
    const positions = this.productsList$.getValue().map(p => ({
      ...p,
      quantity: p.confirmQuantity
    }));

    this.salesOrderApiService.createSalesOrderByPurchaseOrderId(this.data.poId, positions)
      .pipe(
        // finalize(() => this.btnToClearLoadingStatus$.next('onCreatePurchaseOrderClick')),
        takeUntil(this.destroy$)
      )
      .subscribe((salesOrder: SalesOrderModel) => this.dialogRef.close(salesOrder));
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
