import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { get } from 'lodash';

import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { SalesOrderApiService } from 'projects/workspace/src/app/sales-order/services/sales-order-api.service';
import { selectSalesOrder, selectSalesOrderCurrentState } from 'projects/workspace/src/app/sales-order/store/selectors';
import { SalesOrderModel, SalesOrderPropertiesModel } from 'projects/workspace/src/app/sales-order/models/sales-order.model';
import { FormInputChangedModel } from 'projects/workspace/src/app/shared/models';
import { UpdatePropertiesBlockValid } from 'projects/workspace/src/app/sales-order/store/actions/sales-order.actions';
import { CustomerTypeEnum, UIStatesEnum } from '../../../../models';
import { DocumentTypesUppercaseEnum } from '../../modals-common/link-document-modal/enums/ducument-types.enum';
import { OutgoingInvoiceListTabsEnum } from 'projects/workspace/src/app/outgoing-invoice/enums';
import { DeliveryNoteListTabsEnum } from 'projects/workspace/src/app/delivery-note/enums';
import { ModalNameEnum } from '../../../../models/modal-name.enum';
import { PartnersTypeEnum } from 'projects/workspace/src/app/partners/corporate/enums';
import { CommonModalsActionsEnum, ConfirmModalComponent } from '../../modals-common';
import { PartnersFieldsChangedModel } from '../../../ui-components/select-partner/update-partner-fields.model';
import { UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG } from 'projects/workspace/src/app/shared/constants';

@Component({
  selector: 'rnpl-select-partner-modal',
  templateUrl: './so-select-partner-modal.component.html',
})
export class SoSelectPartnerModalComponent implements OnInit, OnDestroy {

  public salesOrder: SalesOrderModel;
  public form: FormGroup;
  public isReadonly: boolean = false;
  readonly destroy$: Subject<void> = new Subject();

  public modalNameEnum: typeof ModalNameEnum = ModalNameEnum;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<SoSelectPartnerModalComponent>,
    private store: Store<AppState>,
    private salesOrderApiService: SalesOrderApiService,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.trackSalesOrderChanges();
    this.selectAndHandleOrderState();
  }

  private trackSalesOrderChanges(): void {
    this.store.select(selectSalesOrder)
      .pipe(takeUntil(this.destroy$))
      .subscribe((salesOrder: SalesOrderModel) => {
        this.salesOrder = salesOrder;
        this.initForm(this.salesOrder.properties);
      });
  }

  public initForm(
    properties: SalesOrderPropertiesModel = {} as SalesOrderPropertiesModel
  ): void {
    this.form = this.fb.group({
      partnerType: [get(properties, 'partnerType'), [Validators.required]],
      generalPartnerName: [get(properties, 'generalPartnerName'), [Validators.required]],
      generalPartnerVat: [get(properties, 'generalPartnerVat')],
      companyId: [get(properties, 'company.id'), [Validators.required]],
      companyContactId: [get(properties, 'companyContact.id')],
      email: [get(properties, 'email')],
      phone: [get(properties, 'phone')],
    });

    if (this.partnerType.value === CustomerTypeEnum.CORPORATE_PARTNER) {
      this.companyContactId.setValidators(Validators.required);
    }

    this.cdr.detectChanges();
    this.store.dispatch(UpdatePropertiesBlockValid({ propertiesBlockValid: this.form.valid }));
  }

  public updatePartnerField(partnerData): void {
    if (partnerData.salesPriceList && partnerData.salesPriceList !== this.salesOrder.salesPriceList) {
      const dialog = this.dialog.open(ConfirmModalComponent, UPDATE_PARTNER_SALES_PRICE_LIST_MODAL_CONFIG);

      dialog.afterClosed().subscribe((res: CommonModalsActionsEnum) => {
        if (res === CommonModalsActionsEnum.CONFIRM) {
          this.updatePartnerFieldRequest(partnerData, true, true);
        } else if (res === CommonModalsActionsEnum.REJECT) {
          this.updatePartnerFieldRequest(partnerData, false, false);
        } else {
          this.initForm(this.salesOrder.properties);
        }
      });
      return;
    }

    this.updatePartnerFieldRequest(partnerData);
  }

  public updatePartnerFieldRequest(partnerData: PartnersFieldsChangedModel, updatePositions = false, updateDocument = false): void {
    this.salesOrderApiService.updateSalesOrder(
      this.salesOrder.id,
      {
        fieldValue: {
          type: partnerData.partnerType,
          id: partnerData.partnerId,
          updatePositions,
          updateDocument
        },
        fieldName: 'properties.' + partnerData.partnerIdFieldName
      })
      .subscribe(); // updated via store
  }

  public updateSalesProperties(field: FormInputChangedModel): void {
    field.fieldName = 'properties.' + field.fieldName;
    this.salesOrderApiService.updateSalesOrder(this.salesOrder.id, field)
      .subscribe(); // updated via store
  }

  private selectAndHandleOrderState() {
    this.store.select(selectSalesOrderCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: UIStatesEnum) => {
        this.isReadonly = state === UIStatesEnum.VIEW;
        this.setFormsState();
      });
  }

  private setFormsState(): void {
    const opts = { onlySelf: true, emitEvent: false };
    if (this.isReadonly) {
      this.form.disable(opts);
    } else {
      this.form.enable(opts);
      this.cdr.detectChanges();
    }

    if (
      this.hasLinkedOINOpenPaidStatus ||
      this.hasLinkedDNWithStatuses ||
      // this.createdWithOffer ||
      this.hasLinkedTimeTrackingRecord
    ) {
      this.partnerType.disable(opts);
      this.companyId.disable(opts);
    }

    if (this.hasLinkedDN || this.hasLinkedEra) {
      this.generalPartnerName.disable(opts);
    }

    if ((this.hasLinkedOIN || this.hasLinkedOfr) && this.partnerType.value === PartnersTypeEnum.GENERAL) {
      this.partnerType.disable(opts);
      this.companyId.disable(opts);
      this.generalPartnerName.disable(opts);
    }
  }

  get hasLinkedTimeTrackingRecord(): boolean {
    const documents = get(this, 'salesOrder.linkedDocuments');
    return documents.some(doc => doc.type === 'logged_time');
  }

  get hasLinkedOINOpenPaidStatus(): boolean {
    if (!get(this, 'salesOrder.linkedDocuments')) { return false; }
    return get(this, 'salesOrder.linkedDocuments')
      .filter(doc =>
        (doc.type === DocumentTypesUppercaseEnum.OUTGOING_INVOICE &&
          (doc.status === OutgoingInvoiceListTabsEnum.OPEN ||
            doc.status === OutgoingInvoiceListTabsEnum.PAID))).length;
  }

  get hasLinkedDNWithStatuses(): boolean {
    if (!get(this, 'salesOrder.linkedDocuments')) { return false; }

    return get(this, 'salesOrder.linkedDocuments')
      .some(doc =>
        (doc.type === DocumentTypesUppercaseEnum.DELIVERY_NOTE &&
          (doc.status !== DeliveryNoteListTabsEnum.CANCELED &&
            doc.status !== DeliveryNoteListTabsEnum.DELETED)));
  }

  get hasLinkedDN(): boolean {
    if (!get(this, 'salesOrder.linkedDocuments')) { return false; }

    return get(this, 'salesOrder.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.DELIVERY_NOTE);
  }

  get hasLinkedEra(): boolean {
    if (!get(this, 'salesOrder.linkedDocuments')) { return false; }

    return get(this, 'salesOrder.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.ERA.toLowerCase());
  }

  get hasLinkedOfr(): boolean {
    if (!get(this, 'salesOrder.linkedDocuments')) { return false; }

    return get(this, 'salesOrder.linkedDocuments')
      .some(doc => (doc.type === DocumentTypesUppercaseEnum.OFR.toLowerCase() || doc.type === DocumentTypesUppercaseEnum.OFFER.toLowerCase()));
  }

  get hasLinkedOIN(): boolean {
    if (!get(this, 'salesOrder.linkedDocuments')) { return false; }
    return get(this, 'salesOrder.linkedDocuments')
      .some(doc => doc.type === DocumentTypesUppercaseEnum.OUTGOING_INVOICE);
  }

  get email(): FormControl { return this.form.get('email') as FormControl; }
  get phone(): FormControl { return this.form.get('phone') as FormControl; }
  get companyId(): FormControl { return this.form.get('companyId') as FormControl; }
  get partnerType(): FormControl { return this.form.get('partnerType') as FormControl; }
  get generalPartnerName(): FormControl { return this.form.get('generalPartnerName') as FormControl; }
  get generalPartnerVat(): FormControl { return this.form.get('generalPartnerVat') as FormControl; }
  get companyContactId(): FormControl { return this.form.get('companyContactId') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
