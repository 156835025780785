import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ReturnStockStatusModel } from '../models';
import { FilterModel } from '../models/filter.model';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { AppState } from '../../store/state/app.state';
import { LoadReturnStockStatusList } from '../store/actions/return-stock-status.actions';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { StockStatusBinsModel, StockStatusDocumentModel } from '../models/stock-address.model';


@Injectable({
  providedIn: 'root'
})
export class ReturnStockStatusService {

  private readonly apiEndpoint: string = '/warehouse/returned-stock-status';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private http: HttpClient,
    private readonly store: Store<AppState>,
  ) {}

  public getReturnStockStatusList(
    page = '1',
    per_page = '100',
    sort: FilterModel = {nameColumn: 'status', value: DEFAULT_SORT_DIRECTION}
  ): Observable<ResponseList<ReturnStockStatusModel>> {
    return this.http.get<ResponseList<ReturnStockStatusModel>>(
      this.apiUrl(),
      {
        params: {
          page,
          per_page,
          [`sort[${sort.nameColumn}]`]: sort.value,
        }
      })
      .pipe(tap((data: ResponseList<ReturnStockStatusModel>) => {
        this.store.dispatch(LoadReturnStockStatusList({
          returnStockStatusListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data,
              totals: data.totals
            }
          }
        }));
      }));
  }

  public getReturnStockStatusListExportFile(title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl('/csv'),
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  getReturnsStockListBins(stockStatusPositionId: number): Observable<StockStatusBinsModel[]> {
    return this.http.get<ResponseModel<StockStatusBinsModel[]>>(`/warehouse/returned-stock-status/${stockStatusPositionId}/bins`)
      .pipe(map((data: ResponseModel<StockStatusBinsModel[]>) => data.data));
  }

  getReturnsStockListDocuments(stockStatusPositionId: number): Observable<StockStatusDocumentModel[]> {
    return this.http.get<ResponseModel<StockStatusDocumentModel[]>>(`/warehouse/returned-stock-status/${stockStatusPositionId}/documents`)
      .pipe(map((data: ResponseModel<StockStatusDocumentModel[]>) => data.data));
  }

}
