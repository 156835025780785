import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { AppState } from '../../store/state/app.state';
import { Observable, throwError } from 'rxjs';
import { CompanyProfile, LegalType, PartnerOptions, ProductsOptions } from '../models/company-profile.model';
import { ResponseModel } from '../../shared/models';
import { tap, map, finalize, catchError } from 'rxjs/operators';
import {
  LoadCompanyProfile,
  UpdateCompanyProfile,
  IncrementLoadingRequestsCount,
  DecrementLoadingRequestsCount,
  LoadLegalTypes,
  UpdateValidations,
  UpdateProfileUpdatedAt, LoadOnboarding, LoadLegalCountryTranslate
} from '../store/actions/company-profile.actions';
import { ImageModel } from 'common/src/models';
import { environment } from 'projects/workspace/src/environments/environment';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { OnboardingModel } from '../models/onboarding.model';
import { SandboxModel } from 'common/src/modules/quick-onboarding/sandbox.model';
import { SubscriptionPricesConfigModel } from '../models/subscription-prices-config.model';

@Injectable({
  providedIn: 'root',
})
export class AdministrationsApiService {
  private readonly apiEndpoint: string = `${environment.javaApiVersion}/company`;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
    private readonly toasterService: ToasterService,
  ) {}


  @DisplayToaster({showErrorMessage: true})
  public getCompanyProfile(): Observable<CompanyProfile> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<CompanyProfile>>(`${this.apiEndpoint}/profile`)
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
          this.store.dispatch(UpdateProfileUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public finishCompanyProfileSetup(wid: number, exitSandbox = false): Observable<CompanyProfile> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const params = new HttpParams()
      .set('', wid.toString())
      .set('exitSandbox', exitSandbox.toString());

    return this.http.request<ResponseModel<CompanyProfile>>('patch', `${this.apiEndpoint}/profile/finish-setup`, {params})
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
          this.store.dispatch(UpdateProfileUpdatedAt({ updatedAt: new Date() }));
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public updateCompanyProfile(
    companyProfile: CompanyProfile,
    ignore?: string,
    activate= false,
  ): Observable<ResponseModel<CompanyProfile>> {
    const params = {};
    params['wid'] = companyProfile.workspaceId;

    if (ignore) {
      params['ignore'] = ignore;
    }
    if (activate) {
      params['activate'] = activate;
    }

    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<CompanyProfile>>(`${this.apiEndpoint}/profile`, companyProfile, { params })
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(UpdateCompanyProfile({ companyProfile: response.data as CompanyProfile }));
          this.store.dispatch(UpdateValidations({ validations: response.validations }));
          this.store.dispatch(UpdateProfileUpdatedAt({updatedAt: new Date()}));
        }),
        catchError(error => {
          if (error.error.errors.includes('CHANGE_LEGAL_TYPE_OPEN_DOCUMENT_SMALL_BUSINESS_VALIDATION_ERROR')) {
            return throwError(error);
          } else {
            this.toasterService.notify({
              type: ToasterService.MESSAGE_TYPE_ERROR,
              message: error.error.message || error.error.errors || 'COMMON.SOME_ERROR'
            });
          }
          return throwError(error);
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public exitSandbox(sandbox: SandboxModel): Observable<ResponseModel<CompanyProfile>> {
    const params = new HttpParams().set('wid', sandbox.workspaceId.toString());
    return this.http.post<ResponseModel<CompanyProfile>>(`${this.apiEndpoint}/profile/sandbox/exit`, {...sandbox}, {params})
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
        }),
    );
  }

  public getCompanySubscriptionPricesConfig(): Observable<SubscriptionPricesConfigModel> {
    return this.http.get<ResponseModel<SubscriptionPricesConfigModel>>(`${environment.javaApiVersion}/utils/subscription/prices`)
      .pipe(
        map((data: ResponseModel<SubscriptionPricesConfigModel>) => data.data),
      );
  }

  public applyPromoCode(wid: number, promoCode: string): Observable<ResponseModel<CompanyProfile>> {
    return this.http.request<ResponseModel<CompanyProfile>>(
      'post',
      `${environment.javaApiVersion}/workspaces/${wid}/subscription/promo-code/${promoCode}`
    )
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data as CompanyProfile }));
        }),
      );
  }

  public getLegalCountryTranslate(countryCodeIso3: string): Observable<string> {
    const params = { countryCodeIso3 };
    return this.http.get<ResponseModel<string>>(`${environment.javaApiVersion}/utils/countries/translate`, { params })
      .pipe(
        tap((response: ResponseModel<string>) => {
          this.store.dispatch(LoadLegalCountryTranslate({ legalCountryTranslate: response.data }));
        }),
        map((data: ResponseModel<string>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public setFullsizeLogo(image: ImageModel): Observable<CompanyProfile> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<CompanyProfile>>(`${this.apiEndpoint}/profile/full-size-logo`, image).pipe(
      tap(() => {
        this.store.dispatch(UpdateProfileUpdatedAt({updatedAt: new Date()}));
      }),
      map((data: ResponseModel<CompanyProfile>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }

  @DisplayToaster({showErrorMessage: true, showSuccessMessage: false, successMsg: 'Image deleted successfully'})
  public deleteFullsizeLogo(image: ImageModel): Observable<CompanyProfile> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http
      .request<ResponseModel<CompanyProfile>>('delete', `${this.apiEndpoint}/profile/full-size-logo`, { body: image })
      .pipe(
        tap(() => {
          this.store.dispatch(UpdateProfileUpdatedAt({updatedAt: new Date()}));
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public getLegalTypes(countryCodeIso3: string): Observable<LegalType[]> {
    return this.http
      .get<LegalType[]>(`${environment.javaApiVersion}/utils/profile/legal-types`, {
        params: {
          countryCodeIso3,
        },
      })
      .pipe(
        tap((response: LegalType[]) => {
          this.store.dispatch(LoadLegalTypes({ legalTypes: response }));
        }),
        map((response: LegalType[]) => response)
      );
  }

  public getUploadImageLink(): string {
    return `${environment.javaApiUrl}${environment.javaApiVersion}/image/CP`;
  }

  @DisplayToaster({showErrorMessage: true})
  public getOnboardingProcess(): Observable<OnboardingModel> {
    return this.http.get<ResponseModel<OnboardingModel>>(`${environment.javaApiVersion}/onboarding`)
      .pipe(
        tap((response: ResponseModel<OnboardingModel>) => {
          this.store.dispatch(LoadOnboarding({ onboarding: response.data }));
          if (response.data.progress.total === response.data.progress.completed) {
            this.getCompanyProfile().subscribe();
          }
        }),
        map((response: ResponseModel<OnboardingModel>) => response.data),
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public completeOnboardingProcess(): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `${environment.javaApiVersion}/onboarding/complete`);
  }

  @DisplayToaster({showErrorMessage: true})
  public skipPdfDesignerProcess(): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `${environment.javaApiVersion}/onboarding/pdf-designer-skip`);
  }

  @DisplayToaster({showErrorMessage: true})
  public skipBankAccountProcess(): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `${environment.javaApiVersion}/onboarding/bank-account-skip`);
  }

  @DisplayToaster({showErrorMessage: true})
  public skipWarehouseProcess(): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/warehouse/onboarding/skip`);
  }

  @DisplayToaster({showErrorMessage: true})
  public skipImportProcess(): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/import/onboarding/skip`);
  }

  @DisplayToaster({showErrorMessage: true})
  public skipDeliveryServiceProcess(): Observable<any> {
    return this.http.request<ResponseModel<any>>('post', `/delivery-service/onboarding/skip`);
  }

  public checkOrigin(): Observable<any> { // returns "200 OK" if workspace activated
    return this.http.get(`${this.apiEndpoint}/profile/check-origin`);
  }

  public resendConfirmationEmail(): Observable<any> {
    return this.http.request('post', `${this.apiEndpoint}/profile/resend`);
  }

  public updateProductsSettings(wid: number, body: ProductsOptions): Observable<CompanyProfile> {
    const params = new HttpParams().set('wid', wid.toString())
    return this.http.request<ResponseModel<CompanyProfile>>('post', `${this.apiEndpoint}/profile/settings/product`, {params, body})
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data }));
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data)
      );
  }

  public updatePartnersSettingsSetup(wid: number, body: PartnerOptions): Observable<CompanyProfile> {
    const params = new HttpParams().set('wid', wid.toString())
    return this.http.request<ResponseModel<CompanyProfile>>('post', `${this.apiEndpoint}/profile/settings/partner`, {params, body})
      .pipe(
        tap((response: ResponseModel<CompanyProfile>) => {
          this.store.dispatch(LoadCompanyProfile({ companyProfile: response.data }));
        }),
        map((data: ResponseModel<CompanyProfile>) => data.data)
      );
  }

  public showMsg(type: string, message: string | string[]): void {
    this.toasterService.notify({type, message});
  }
}
