import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { UserListTabs } from './enums/user-list-tabs.enum';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';

export function generateTableColumns(
  status?: UserListTabs
): TableColumnModelExtended[] {

  const tableColumns: TableColumnModelExtended[] = [
    {
      cellTemplate: 'withSelection',
      headerTemplate: 'headerSelections',
      cellClass: 'center',
      name: 'selection',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      frozenLeft: true,
    },
    {
      cellTemplate: 'numberRows',
      cellClass: 'center cursor-pointer',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      customizable: true,
      headerClass: 'center',
      clicktype: TableActivateTypes.Link,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'TEAM.EMPLOYEE_ID',
      prop: 'documentLink',
      width: 128,
      maxWidth: 128,
      clicktype: TableActivateTypes.Link,
      customizable: true,
    },
    {
      cellTemplate: 'withStatusLabel',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.STATUS',
      prop: 'statusValue',
      width: 124,
      maxWidth: 124,
      cellClass: 'center cursor-pointer',
      clicktype: TableActivateTypes.Link,
      customizable: true,
    },
    {
      cellTemplate: 'withAvatarArrowRight',
      headerTemplate: 'headerPrimary',
      cellClass: 'cursor-pointer p-0',
      name: 'COLUMN.EMPLOYEE',
      prop: 'nameData',
      clicktype: TableActivateTypes.Link,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.DEPARTMENT',
      prop: 'department',
      cellClass: 'cursor-pointer',
      width: 147,
      maxWidth: 147,
      clicktype: TableActivateTypes.Link,
      customizable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'TEAM.POSITION',
      prop: 'position',
      cellClass: 'cursor-pointer',
      width: 147,
      maxWidth: 147,
      clicktype: TableActivateTypes.Link,
      customizable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.EMAIL',
      prop: 'email',
      cellClass: 'cursor-pointer',
      width: 176,
      maxWidth: 176,
      clicktype: TableActivateTypes.Link,
      customizable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.PHONE_NUMBER',
      prop: 'phone',
      cellClass: 'cursor-pointer',
      width: 155,
      maxWidth: 155,
      clicktype: TableActivateTypes.Link,
      customizable: true,
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      columnId: 'lastActivityColumn',
      name: 'COLUMN.LAST_ACTIVITY',
      prop: 'lastActivity',
      cellClass: 'cursor-pointer',
      width: 147,
      clicktype: TableActivateTypes.Link,
      customizable: true,
      hideWithStatus: [UserListTabs.Inactive, UserListTabs.Deleted],
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.DEACTIVATED',
      prop: 'deactivatedAt',
      cellClass: 'cursor-pointer',
      width: 147,
      clicktype: TableActivateTypes.Link,
      customizable: true,
      hideWithStatus: [UserListTabs.Active, UserListTabs.Deleted],
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.DELETED',
      prop: 'deletedAt',
      cellClass: 'cursor-pointer',
      width: 147,
      clicktype: TableActivateTypes.Link,
      customizable: true,
      hideWithStatus: [UserListTabs.Active, UserListTabs.Inactive],
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.UPDATED',
      prop: 'updatedAt',
      cellClass: 'cursor-pointer',
      width: 147,
      clicktype: TableActivateTypes.Link,
      customizable: true,
      hideWithStatus: [UserListTabs.Inactive, UserListTabs.Deleted],
    }
  ].filter((col: TableColumnModelExtended) => {
    if (col.hasOwnProperty('hideWithStatus')) {
      return !col.hideWithStatus.includes(status);
    }
    return true;
  });
  return tableColumns;
}
