import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormInputChangedModel } from '../../models';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';
import { CURRENCIES_SYMBOLS_BY_ISO_CODE } from '../../../accounting/accounting.constants';

@Component({
  selector: 'rnpl-discount-form',
  templateUrl: './discount-form.component.html',
})
export class DiscountFormComponent {

  public currencySymbols = CURRENCIES_SYMBOLS_BY_ISO_CODE;

  @Input() discountControl: FormControl;
  @Input() discountAmountNetControl: FormControl;
  @Input() discountAmountGrossControl: FormControl;
  @Input() localCurrency: string = 'EUR';
  @Input() currencyConversion: boolean = false;
  @Input() incomingDocument: boolean = false;

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  constructor(private metricPipe: MetricConversionPipe) {
  }

  public updateField(fieldValue: any, fieldName: string): void {
    fieldValue = fieldValue === '' ? null : fieldValue;
    if ((fieldName === 'discountAmountNet' || fieldName === 'discountAmountGross') && this.currencyConversion && fieldValue) {
      fieldValue = this.toInteger(fieldValue);
    }
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

}
