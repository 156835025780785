import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency-custom';
import { TranslateModule } from '@ngx-translate/core';

import { MetricConversionPipe, RnplCommonModule } from 'common/src/modules/rnpl-common';
import { ScontoFormComponent } from './sconto-form.component';
import { UIComponentsModule } from 'common/src/modules';
import { ScontoSummaryBlockComponent } from './sconto-summary-block/sconto-summary-block.component';

@NgModule({
  declarations: [
    ScontoFormComponent,
    ScontoSummaryBlockComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    UIComponentsModule,
    NgxMaskModule,
    NgxCurrencyModule,
    TranslateModule
  ],
  exports: [
    ScontoFormComponent,
    ScontoSummaryBlockComponent
  ],
  entryComponents: [ScontoFormComponent],
  providers: [MetricConversionPipe]
})
export class ScontoFormModule { }
