import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FamilyModel } from '../../../models';
import { AbstractAppComponent } from 'common/src/interfaces';
import { FundsWriteOffEnum } from 'projects/workspace/src/app/fixed-assets/enums';


@Component({
  selector: 'rnpl-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnChanges, OnInit {

  public fundsWriteOffEnum = FundsWriteOffEnum;

  @Input()
  public form: FormGroup;

  @Input()
  public displayHints: boolean = true;

  @Input()
  public family: FamilyModel;

  @Output()
  public completed: EventEmitter<null> = new EventEmitter();

  @Output()
  public closed: EventEmitter<any> = new EventEmitter();

  constructor(@Optional() public app: AbstractAppComponent) {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    // if (this.app && this.app.projectName === 'workspace') {
    //   this.form.get('type').setValue('custom', {emitEvent: false});
    //   this.form.get('type').markAsDirty();
    // }
  }

  public complete(): void {
    this.completed.emit();
  }

  public close(): void {
    this.closed.emit(
      this.form.dirty
      && (this.form.get('type').value || this.form.get('name').value || this.form.get('description').value)
    );
  }

  public get glProductTypeIdControl(): FormControl { return this.form.get('gl_product_type_id') as FormControl; }
  public get glAccountIdPurchaseDebitControl(): FormControl { return this.form.get('gl_account_id_purchase_debit') as FormControl; }
  public get glAccountIdPurchaseCreditControl(): FormControl { return this.form.get('gl_account_id_purchase_credit') as FormControl; }
  public get glAccountWriteOffDebitControl(): FormControl { return this.form.get('gl_account_write_off_debit') as FormControl; }
  public get productCreationForbiddenControl(): FormControl { return this.form.get('product_creation_forbidden') as FormControl; }
  public get longTermPeriodOfUseControl(): FormControl { return this.form.get('long_term_period_of_use') as FormControl; }
  public get periodOfUseControl(): FormControl { return this.form.get('period_of_use') as FormControl; }
  public get fundsWriteOffMethodControl(): FormControl { return this.form.get('funds_write_off_method') as FormControl; }
  public get fundsWriteOffRateControl(): FormControl { return this.form.get('funds_write_off_rate') as FormControl; }
}
