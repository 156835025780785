import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'rnpl-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent {
  public croppedImage: string;
  public fileToReturn: any;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      image: File,
      filename: string,
      // width: number,
      // height: number,
    }
  ) { }

  base64ToFile(data): any {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }

    const filename: string = this.data.filename
      .replace('.png', '.jpeg')
      .replace('.jpg', '.jpeg')
      .replace('.heic', '.jpeg');

    return new File([u8arr], filename, { type: mime });
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(event.base64);
  }

  public confirmImageCrop(): void {
    this.dialogRef.close(this.fileToReturn);
  }

}
