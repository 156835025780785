import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { MultipleControlComponent } from '../multiple-control.component';

@Component({
  selector: 'rnpl-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent extends MultipleControlComponent implements OnInit {

  @Input()
  public placeholder: string = 'Choose the option';

  @Input()
  public isMultiple: boolean = false;

  @Input()
  public isSearchable: boolean = false;

  public selectedValues: Array<any> | any;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.selectedValues = this.control.value;

    // if (this.isSearchable) {
    //   this.placeholder = 'Enter or select options';
    // }
    //
    // if (this.isMultiple) {
    //   this.placeholder = 'Choose the options';
    // }

    this.disabled = this.control.disabled || false;
  }

  public change(event): void {
    if (this.isMultiple && Array.isArray(event)) {
      this.control.setValue(event.map(ev => ev.value));
      this.control.markAsDirty();
      return;
    }

    this.control.setValue(event.value);
    this.control.markAsDirty();

    this.formUpdated.emit(true);
  }
}

@Component({
  selector: 'rnpl-combobox',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboboxComponent extends DropdownComponent {
  constructor () {
    super();
    this.isSearchable = true;
  }
}

@Component({
  selector: 'rnpl-tokenfield',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenfieldComponent extends DropdownComponent {
  constructor () {
    super();
    this.isMultiple = true;
    this.isSearchable = true;
  }
}
