export enum CustomerTypeEnum {
  CORPORATE_PARTNER = 'corporate',
  PRIVATE_PARTNER = 'private',
  NOT_SAVED = 'not_saved_customer',
  NOT_SAVED_PARTNER = 'not_saved_partner',
  GENERAL = 'general',
}

export enum GeneralPartnerTypeEnum {
  INLAND = 'inland',
  EU = 'eu',
  NON_EU = 'non_eu',
}
