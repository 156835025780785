import { DateColumnType } from 'common/src/models/table-column.model';
import { PositionInfoListModel, PositionItemTypeEnum } from 'common/src/modules/position-card/position-card-models.model';


export function generateCardsInfo(): PositionInfoListModel[] {
  let cards: PositionInfoListModel[] = [
    {
      label: 'COLUMN.UNITS_QUANTITY',
      prop: 'unitQuantity',
      unitType: 'unitType',
      type: PositionItemTypeEnum.PRIMARY,
      displayConditions: [
        {
          prop: 'type',
          values: [
            'offer',
            'delivery_note',
          ]
        },
      ],
    },
    {
      label: 'COLUMN.QUANTITY',
      prop: 'unitQuantity',
      unitType: 'pcs',
      type: PositionItemTypeEnum.PRIMARY,
      displayConditions: [
        {
          prop: 'type',
          values: [
            'sales_order',
            'SO',
          ]
        }
      ]
    },
    {
      label: 'COLUMN.AMOUNT',
      prop: 'amount',
      suffix: '€',
      type: PositionItemTypeEnum.CURRENCY,
      displayConditions: [
        {
          prop: 'type',
          values: [
            'outgoing_invoice',
            'incoming_invoice',
            'IIN',
            'sales_order',
            'purchase_order',
            'SO',
            'offer',
            'credit_note',
            'CRN',
            'SP',
            'SBC',
            'OPB',
            'OIN',
            'IPB',
            'PO',
          ]
        }
      ]
    },
    {
      label: 'COLUMN.UPDATED',
      prop: 'updatedAt',
      type: PositionItemTypeEnum.WITH_DATE,
      dateType: DateColumnType.WITH_TIME,
      displayConditions: [
        {
          prop: 'type',
          values: [
            'outgoing_invoice',
            'offer',
            'incoming_invoice',
            'sales_order',
            'credit_note',
            'delivery_note',
            'era',
            'purchase_order',
          ]
        }
      ]

    },
    {
      label: 'COLUMN.UPDATED',
      prop: 'updated',
      type: PositionItemTypeEnum.WITH_DATE,
      dateType: DateColumnType.WITH_TIME,
      displayConditions: [
        {
          prop: 'type',
          values: [
            'SO',
            'SP',
            'OPB',
            'OIN',
            'IIN',
            'IPB',
            'ERA',
            'PO',
            'CRN'
          ]
        }
      ]
    },
  ];

  return cards;
}
