import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { ImageModel, UIStatesEnum } from 'common/src/models';
import { HrmService } from '../../hrm.service';
import { selectTeamsUser, selectTeamsUserCurrentState } from '../../store/selectors';
import { AppState } from '../../../store/state/app.state';
import { UserProfileModel } from '../../models';
import { FormInputChangedModel } from '../../../shared/models/form-input-value.model';
import { AuthService } from 'common/src/auth/auth.service';
import { ChangeEmailModalComponent } from 'common/src/modules/modals/modals-hrm';

@Component({
  selector: 'rnpl-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  public userData: UserProfileModel;
  public editingMode: boolean = false;
  public isMyProfile: boolean = false;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private dialog: MatDialog,
    private hrmService: HrmService,
    private authService: AuthService,
    private store:  Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.isMyProfile = this.route.parent.snapshot.data.myProfile;
        }
      });
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this.store.select(selectTeamsUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.userData = user;
        this.isLoading$.next(false);
      });

    this.store.select(selectTeamsUserCurrentState)
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.editingMode = state === UIStatesEnum.EDIT;
      });
  }

  public updateUser(field: FormInputChangedModel): void {
    this.hrmService.updateUserProfile(this.userData.profile.id, field)
      .pipe(takeUntil(this.destroy$))
      .subscribe(userData => {
        if (field.fieldName === 'profile.avatar' && this.isMyProfile) {
          if (this.isMyProfile) {
            const storedUser = {
              ...this.authService.getUser(),
              logo: get(userData, 'profile.avatar.urlOriginal')
            };

            this.authService.updateUser(storedUser);
          }
        }
      });
  }

  setLogo(images: ImageModel[]): void {
    const avatar = images.filter((image: ImageModel) => !!image)
      .map((image: ImageModel) => image.apiModel || image);

    this.updateUser({
      fieldValue: avatar.length
        ? avatar[0].id
        : null,
      fieldName: 'profile.avatar'
    });
  }

  public changeEmail(): void {
    this.dialog.open(ChangeEmailModalComponent, {
      data: {
        currentEmail: this.userData.profile.email
      },
      disableClose: true,
    });
  }

  public cancelChangeEmail(): void {
    this.hrmService.cancelMyProfileEmailChange()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

}
