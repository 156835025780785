import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';


@Directive({
  selector: '[rnplComposerCell]'
})
export class ComposerCellDirective {

  constructor(public templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
  }

}
