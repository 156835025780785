export enum SignInFormNameEnum {
  SignIn,
  TwoFactor,
  ResetPassword,
  CheckInbox,
  CheckInboxActivation,
  WorkspaceNotActivated,
  ChangePassword
}

