import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, takeUntil } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { get } from 'lodash';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { BaseModalComponent } from 'common/src/modules/rnpl-common/components';
import {
  EcoSettingsApiService
} from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/services/eco-settings-api.service';
import { EcoSettingsDeliveryCountryModel } from 'projects/workspace/src/app/e-commerce/modules/ecommerce-settings/models';

@Component({
  selector: 'rnpl-update-delivery-term-modal',
  templateUrl: './update-delivery-term-modal.component.html',
  styleUrls: ['./update-delivery-term-modal.component.scss']
})
export class UpdateDeliveryTermModalComponent extends BaseModalComponent {

  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly submitRequest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public toasterService: ToasterService,
    public ecoSettingsApiService: EcoSettingsApiService,
    public dialogRef: MatDialogRef<UpdateDeliveryTermModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      deliveryCountry: EcoSettingsDeliveryCountryModel,
      canBeDeleted: boolean,
      alreadyAddedCountriesISO3: string[]
    }
  ) {
    super(toasterService);
  }

  public deleteCountry(): void {
    this.ecoSettingsApiService.ecoSettingsDeliveryRemoveCountry(this.data.deliveryCountry.id)
      .pipe(
        // finalize(() => this.submitRequest$.next(false)),
        takeUntil(this._destroy)
      )
      .subscribe(() => this.dialogRef.close(true));
  }

  public submit(isFormValid: boolean, deliveryFormValue: EcoSettingsDeliveryCountryModel): void {
    if (this.submitRequest$.getValue() || !isFormValid) { return; }
    this.submitRequest$.next(true);

    deliveryFormValue = {
      activated: get(this, 'data.deliveryCountry.activated', false),
      ...deliveryFormValue
    };

    let submitRequest$;

    if (!this.data.deliveryCountry) {
      submitRequest$ = this.ecoSettingsApiService.createEcoSettingsDeliveryCountry(deliveryFormValue);
    } else {
      submitRequest$ = this.ecoSettingsApiService.updateEcoSettingsDelivery(deliveryFormValue, this.data.deliveryCountry.id);
    }

      submitRequest$
        .pipe(
          finalize(() => this.submitRequest$.next(false)),
          takeUntil(this._destroy)
        )
        .subscribe(res => this.dialogRef.close(res));
  }


}
