import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserPermissionsService } from 'common/src/services/user-permissions.service';

@Injectable({
  providedIn: 'root'
})
export class ShowControlCenterGuard implements CanActivate, CanLoad {
  constructor(
    public userPermissionsService: UserPermissionsService,
    private router: Router
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.userPermissionsService.canViewAccounting() ||
        this.userPermissionsService.canViewWarehouse() ||
        this.userPermissionsService.canViewWholesale()
    ) {
      return true;
    } else {
      this.router.navigate(['start']);
      return false;
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (this.userPermissionsService.canViewAccounting() ||
      this.userPermissionsService.canViewWarehouse() ||
      this.userPermissionsService.canViewWholesale()
    ) {
      return true;
    } else {
      this.router.navigate(['start']);
      return false;
    }
  }
}
