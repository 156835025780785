import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FormInputChangedModel } from '../../models/form-input-value.model';
import { InfoItemModel, InfoItemType } from 'common/src/modules/ui-components/info-components/models/info-item.model';
import { ScontoRateTypeEnum } from 'common/src/models';
import { MetricConversionPipe } from 'common/src/modules/rnpl-common/pipes';

@Component({
  selector: 'rnpl-sconto-form',
  templateUrl: './sconto-form.component.html',
  styleUrls: ['./sconto-form.component.scss']
})
export class ScontoFormComponent implements OnChanges {

  @Input() form: FormGroup;
  @Input() scontoAmount: number;
  @Input() invoiceAmount: number;
  @Input() dueWithinDays?: number;
  @Input() currencyConversion: boolean = false;
  @Input() disableTypeControl: boolean = false;
  @Input() localCurrency: string = 'EUR';

  @Output() fieldChanged: EventEmitter<FormInputChangedModel> = new EventEmitter<FormInputChangedModel>();

  public scontoInfoList: InfoItemModel[] = [];
  public filteredScontoPeriods: {value: number, label: string}[] = [];
  public scontoPeriods: {value: number, label: string}[] = [
    {value: 1, label: 'FORM.1_DAY'},
    {value: 2, label: 'FORM.2_DAYS'},
    {value: 3, label: 'FORM.3_DAYS'},
    {value: 4, label: 'FORM.4_DAYS'},
    {value: 5, label: 'FORM.5_DAYS'},
    {value: 6, label: 'FORM.6_DAYS'},
    {value: 7, label: 'FORM.7_DAYS'},
    {value: 14, label: 'FORM.14_DAYS'},
    {value: 30, label: 'FORM.30_DAYS'},
    {value: 90, label: 'FORM.90_DAYS'}
  ];

  constructor(private metricPipe: MetricConversionPipe) {
    this.filteredScontoPeriods = [...this.scontoPeriods];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('dueWithinDays') && this.dueWithinDays) {
      this.filteredScontoPeriods = [...this.scontoPeriods.filter(period => period.value < this.dueWithinDays)]
    }

    if (changes.hasOwnProperty('scontoAmount') || changes.hasOwnProperty('invoiceAmount') || changes.hasOwnProperty('localCurrency')) {
      this.scontoInfoList = this.getScontoInfoList();
    }
  }

  public updateField(fieldValue: any, fieldName: string): void {
    this.fieldChanged.emit({fieldName, fieldValue});
  }

  public scontoTypeChanged(type: string): void {
    this.updateField(
      type === '%' ? ScontoRateTypeEnum.PERCENT : ScontoRateTypeEnum.ABSOLUTE,
      'type'
    );
  }

  public updateRate(fieldValue): void {
    if ((this.form.get('type').value === ScontoRateTypeEnum.ABSOLUTE) && this.currencyConversion && fieldValue) {
      fieldValue = this.toInteger(fieldValue);
      this.updateField(fieldValue, 'rate');
      return;
    }

    if (!fieldValue) {
      fieldValue = fieldValue === 0 ? 0 : null;
    }

    this.updateField(fieldValue, 'rate');
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public getScontoInfoList(): InfoItemModel[] {
    return [
      {
        label: 'SCONTO.SCONTO_AMOUNT',
        value: this.scontoAmount,
        currencyType: this.localCurrency,
        type: InfoItemType.WITH_CURRENCY,
        tableItem: true,
        currencyConversion: this.currencyConversion
      },
      {
        label: 'SCONTO.SCONTO_INVOICE_AMOUNT',
        value: this.invoiceAmount,
        currencyType: this.localCurrency,
        type: InfoItemType.WITH_CURRENCY,
        tableItem: true,
        currencyConversion: this.currencyConversion
      }
    ]
  }

  get updateCurrent(): string {
    return this.form.get('type').value === ScontoRateTypeEnum.PERCENT ? '%' : this.localCurrency;
  }

  get enabled(): FormControl { return this.form.get('enabled') as FormControl; }
  get rate(): FormControl { return this.form.get('rate') as FormControl; }
  get period(): FormControl { return this.form.get('period') as FormControl; }

}
