import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { get } from 'lodash';


import {
  MAX_OPTIONS_COUNT_RADIO,
  MAX_OPTIONS_COUNT_SELECT,
  MIN_OPTIONS_COUNT_COMBOBOX,
  MIN_OPTIONS_COUNT_RADIO,
  MIN_OPTIONS_COUNT_SELECT, MIN_OPTIONS_COUNT_TOKENFIELD
} from './constants';
import { AttributeModel } from '../models';
import { AttributeRelations, RelationHelpers } from './helpers';
import { arrayMinLengthValidator } from 'common/src/modules/rnpl-common/validators';


export interface AttributeForm {
  generalSettings?: FormGroup;
  controlSetup?: FormGroup;
  localization?: FormGroup;
  translations?: FormGroup;
  entityType?: FormGroup;
  entity?: FormGroup;
  form?: FormGroup;
  family?: FormGroup;
  relations?: FormGroup;
}

export const familyValidator = (entityType: AbstractControl) => {
  return (families: FormControl) => {

    if (entityType.value.includes('product') && families.value.length < 1) {
      return {message: 'At least one family should be selected for product entity type relation.'};
    }

    return null;
  };
};

/**
 * Translations validator
 *
 * @param translations list of translations
 */
export const translationsValidator = (options: AbstractControl) => {
  return (translations: FormControl) => {

    const emptyTranslate = translations.value.find(translation => !translation.value);

    if (emptyTranslate) {
      return {
        code_iso3: emptyTranslate.code_iso3,
        message: 'All translations should be filled.'
      };
    }

    const emptyOption = options.value.find(option => {
      const optionKeys = Object.keys(option).filter(key => key !== 'key');
      return optionKeys.length < translations.value.length || optionKeys.find(key => !option[key]);
    });

    if (emptyOption) {
      return {...emptyOption, message: 'All translations of options should be filled.'};
    }

    return null;
  };
};

export const uniqueOptionsValidator = () => {
  return (options: FormControl) => {
    const optionValues = options.value
      .map(option => option.eng);

    if (optionValues.length > (new Set(optionValues)).size) {
      return {message: 'All options should be unique.'}
    }
  }
};

/**
 * Validator for count of options by control
 *
 * @param control Chosen control
 * @param minCount manually set minimal count
 */
export const optionsCountValidator = (control: AbstractControl, minCount?: number) => {
  return (options: FormControl) => {
    const controlKey = control.value;
    const optionsCount = options.value
      .filter(option => option.eng.length)
      .length;

    switch (controlKey) {

      case 'radio':
        if (optionsCount < (minCount || MIN_OPTIONS_COUNT_RADIO)) {
          return {controlKey, message: `Count of options should be more than ${minCount || MIN_OPTIONS_COUNT_RADIO} options.`};
        }
        if (optionsCount > MAX_OPTIONS_COUNT_RADIO) {
          return {controlKey, message: `Count of options should be less than ${MAX_OPTIONS_COUNT_RADIO} options.`};
        }
        break;

      case 'select':
        if (optionsCount < (minCount || MIN_OPTIONS_COUNT_SELECT)) {
          return {control, message: `Count of options should be more than ${minCount || MIN_OPTIONS_COUNT_SELECT} options.`};
        }
        if (optionsCount > MAX_OPTIONS_COUNT_SELECT) {
          return {controlKey, message: `Count of options should be less than ${MAX_OPTIONS_COUNT_SELECT} options.`};
        }
        break;

      case 'tokenfield':
        if (optionsCount < (minCount || MIN_OPTIONS_COUNT_TOKENFIELD)) {
          return {controlKey, message: `Count of options should be more than ${minCount || MIN_OPTIONS_COUNT_TOKENFIELD} options.`};
        }
        break;

      case 'combobox':
        if (optionsCount < (minCount || MIN_OPTIONS_COUNT_COMBOBOX)) {
          return {controlKey, message: `Count of options should be more than ${minCount || MIN_OPTIONS_COUNT_COMBOBOX} options.`};
        }
        break;

      default:
        return null;
    }

    return null;
  };
};

export class AttributeFormFactory {

  /**
   * For general settings step
   */
  public static generalSettings(attribute: AttributeModel = {} as AttributeModel): FormGroup {
    const fg = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      required: new FormControl(attribute.required || false)
    });

    if (attribute.name) {
      fg.controls.name.setValue(attribute.name);
      fg.controls.name.markAsDirty();
    }

    if (attribute.type) {
      fg.controls.type.setValue(attribute.type);
      fg.controls.type.markAsDirty();
    }

    return fg;
  }

  /**
   * For control setup step
   */
  public static controlSetup(attribute: AttributeModel = {} as AttributeModel): FormGroup {

    const fg = new FormGroup({
      control: new FormControl(null, [Validators.required]),
      options: new FormControl([])
    });
    fg.get('options').setValidators([
      optionsCountValidator(fg.get('control')),
      uniqueOptionsValidator()
    ]);

    if (attribute.control) {
      fg.controls.control.setValue(attribute.control);
      fg.controls.control.markAsDirty();
    }

    if (attribute.options) {
      fg.controls.options.setValue(attribute.options);
      fg.controls.options.markAsDirty();
    }

    return fg;
  }

  /**
   * For localization step
   */
  public static localization(attribute: AttributeModel = {} as AttributeModel): FormGroup {

    const fg = new FormGroup({
      localizations: new FormControl([], arrayMinLengthValidator(1))
    });

    if (attribute.localizations && attribute.localizations.length) {
      fg.controls.localizations.setValue(
        attribute.localizations.map(locale => locale.country)
      );
      fg.controls.localizations.markAsDirty();
    }

    return fg;
  }

  /**
   * For translations step
   */
  public static translations(attribute: AttributeModel = {} as AttributeModel, optionsControl: AbstractControl): FormGroup {

    const fg = new FormGroup({
      translations: new FormControl([], [translationsValidator(optionsControl)])
    });

    if (attribute.translations && attribute.translations.length) {
      fg.controls.translations.setValue(
        attribute.translations.map(translate => {
          return {
            code_iso3: get(translate, 'language.code_iso3', ''),
            value: translate.value
          };
        })
      );
      fg.controls.translations.markAsDirty();
    }

    return fg;
  }

  public static relations(entities: any, attribute: AttributeModel = {} as AttributeModel): FormGroup {
    let relations: AttributeRelations;
    if (attribute.business_form_relations) {
      relations = RelationHelpers.splitEntitiesByAttribute(attribute, entities);
    } else {
      relations = new AttributeRelations();
    }

    if (attribute.family_relations) {
      relations.families = attribute.family_relations.map(relation => relation.family);
    }

    const fg = new FormGroup({
      entityType: new FormControl(relations.entityTypes, arrayMinLengthValidator(1)),
      entities: new FormControl(relations.entities, arrayMinLengthValidator(1)),
      forms: new FormControl(relations.forms, arrayMinLengthValidator(1)),
      families: new FormControl(relations.families)
    });

    fg.get('families').setValidators(familyValidator(fg.get('entityType')));

    return fg;
  }

}
