import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({name: 'countryTranslateByIso3'})
export class CountryTranslateByIso3Pipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }
  transform(iso3: string, ...args: any[]): string {
    let translateKey = '';

    switch (iso3.toUpperCase()) {
      case 'AFG':
        translateKey = 'Afghanistan';
        break;
      case 'ALB':
        translateKey = 'Albania';
        break;
      case 'DZA':
        translateKey = 'Algeria';
        break;
      case 'ASM':
        translateKey = 'American Samoa';
        break;
      case 'AND':
        translateKey = 'Andorra';
        break;
      case 'AGO':
        translateKey = 'Angola';
        break;
      case 'AIA':
        translateKey = 'Anguilla';
        break;
      case 'ATG':
        translateKey = 'Antigua and Barbuda';
        break;
      case 'ARG':
        translateKey = 'Argentina';
        break;
      case 'ABW':
        translateKey = 'Aruba';
        break;
      case 'AUS':
        translateKey = 'Australia';
        break;
      case 'AUT':
        translateKey = 'COUNTRY_BY_ISO3.AUT';
        break;
      case 'AZE':
        translateKey = 'Azerbaijan';
        break;
      case 'BHS':
        translateKey = 'Bahamas';
        break;
      case 'BHR':
        translateKey = 'Bahrain';
        break;
      case 'BGD':
        translateKey = 'Bangladesh';
        break;
      case 'BRB':
        translateKey = 'Barbados';
        break;
      case 'BLR':
        translateKey = 'Belarus';
        break;
      case 'BEL':
        translateKey = 'COUNTRY_BY_ISO3.BEL';
        break;
      case 'BLZ':
        translateKey = 'Belize';
        break;
      case 'BEN':
        translateKey = 'Benin';
        break;
      case 'BMU':
        translateKey = 'Bermuda';
        break;
      case 'BTN':
        translateKey = 'Bhutan';
        break;
      case 'BOL':
        translateKey = 'Bolivia';
        break;
      case 'BES':
        translateKey = 'Bonaire';
        break;
      case 'BIH':
        translateKey = 'Bosnia-and-herzegovina';
        break;
      case 'BWA':
        translateKey = 'Botswana';
        break;
      case 'BRA':
        translateKey = 'Brazil';
        break;
      case 'IOT':
        translateKey = 'British-indian-ocean-territory';
        break;
      case 'BRN':
        translateKey = 'Brunei-darussalam';
        break;
      case 'BGR':
        translateKey = 'COUNTRY_BY_ISO3.BGR';
        break;
      case 'BFA':
        translateKey = 'Burkina-faso';
        break;
      case 'BDI':
        translateKey = 'Burundi';
        break;
      case 'CPV':
        translateKey = 'Cape-verde';
        break;
      case 'KHM':
        translateKey = 'Cambodia';
        break;
      case 'CMR':
        translateKey = 'Cameroon';
        break;
      case 'CAN':
        translateKey = 'Canada';
        break;
      case 'CYM':
        translateKey = 'Cayman-islands';
        break;
      case 'CAF':
        translateKey = 'Central-african-republic';
        break;
      case 'TCD':
        translateKey = 'Chad';
        break;
      case 'CHL':
        translateKey = 'Chile';
        break;
      case 'CHN':
        translateKey = 'China';
        break;
      case 'CXR':
        translateKey = 'Christmas-island';
        break;
      case 'CCK':
        translateKey = 'Cocos-island';
        break;
      case 'COL':
        translateKey = 'Colombia';
        break;
      case 'COM':
        translateKey = 'Comoros';
        break;
      case 'COK':
        translateKey = 'Cook-islands';
        break;
      case 'CRI':
        translateKey = 'Costa-rica';
        break;
      case 'HRV':
        translateKey = 'COUNTRY_BY_ISO3.HRV';
        break;
      case 'CUB':
        translateKey = 'Cuba';
        break;
      case 'CUW':
        translateKey = 'Curacao';
        break;
      case 'CYP':
        translateKey = 'COUNTRY_BY_ISO3.CYP';
        break;
      case 'CZE':
        translateKey = 'COUNTRY_BY_ISO3.CZE';
        break;
      case 'DNK':
        translateKey = 'COUNTRY_BY_ISO3.DNK';
        break;
      case 'DJI':
        translateKey = 'Djibouti';
        break;
      case 'DMA':
        translateKey = 'Dominica';
        break;
      case 'DOM':
        translateKey = 'Dominican-republic';
        break;
      case 'ECU':
        translateKey = 'Ecuador';
        break;
      case 'EGY':
        translateKey = 'Egypt';
        break;
      case 'ERI':
        translateKey = 'Eritrea';
        break;
      case 'EST':
        translateKey = 'COUNTRY_BY_ISO3.EST';
        break;
      case 'ETH':
        translateKey = 'Ethiopia';
        break;
      case 'FLK':
        translateKey = 'Falkland-islands';
        break;
      case 'FRO':
        translateKey = 'Faroe-islands';
        break;
      case 'FJI':
        translateKey = 'Fiji';
        break;
      case 'FRA':
        translateKey = 'COUNTRY_BY_ISO3.FRA';
        break;
      case 'FIN':
        translateKey = 'COUNTRY_BY_ISO3.FIN';
        break;
      case 'PYF':
        translateKey = 'French-polynesia';
        break;
      case 'GAB':
        translateKey = 'Gabon';
        break;
      case 'GMB':
        translateKey = 'Gambia';
        break;
      case 'GEO':
        translateKey = 'Georgia';
        break;
      case 'DEU':
        translateKey = 'COUNTRY_BY_ISO3.DEU';
        break;
      case 'GHA':
        translateKey = 'Ghana';
        break;
      case 'GIB':
        translateKey = 'Gibraltar';
        break;
      case 'GRC':
        translateKey = 'COUNTRY_BY_ISO3.GRC';
        break;
      case 'GRL':
        translateKey = 'Greenland';
        break;
      case 'GRD':
        translateKey = 'Grenada';
        break;
      case 'GUM':
        translateKey = 'Guam';
        break;
      case 'GTM':
        translateKey = 'Guatemala';
        break;
      case 'GGY':
        translateKey = 'Guernsey';
        break;
      case 'GIN':
        translateKey = 'Guinea';
        break;
      case 'GNB':
        translateKey = 'Guinea-bissau';
        break;
      case 'GUY':
        translateKey = 'Guyana';
        break;
      case 'HTI':
        translateKey = 'Haiti';
        break;
      case 'HND':
        translateKey = 'Honduras';
        break;
      case 'HKG':
        translateKey = 'Hong-kong';
        break;
      case 'HUN':
        translateKey = 'COUNTRY_BY_ISO3.HUN';
        break;
      case 'ISL':
        translateKey = 'COUNTRY_BY_ISO3.ISL';
        break;
      case 'IND':
        translateKey = 'India';
        break;
      case 'IDN':
        translateKey = 'Indonesia';
        break;
      case 'IRN':
        translateKey = 'Iran';
        break;
      case 'IRQ':
        translateKey = 'Iraq';
        break;
      case 'IRL':
        translateKey = 'COUNTRY_BY_ISO3.IRL';
        break;
      case 'IMN':
        translateKey = 'Isle-of-man';
        break;
      case 'ISR':
        translateKey = 'Israel';
        break;
      case 'ITA':
        translateKey = 'COUNTRY_BY_ISO3.ITA';
        break;
      case 'JAM':
        translateKey = 'Jamaica';
        break;
      case 'JPN':
        translateKey = 'Japan';
        break;
      case 'JEY':
        translateKey = 'Jersey';
        break;
      case 'JOR':
        translateKey = 'Jordan';
        break;
      case 'KAZ':
        translateKey = 'Kazakhstan';
        break;
      case 'KEN':
        translateKey = 'Kenya';
        break;
      case 'KIR':
        translateKey = 'Kiribati';
        break;
      case 'KWT':
        translateKey = 'Kuwait';
        break;
      case 'KGZ':
        translateKey = 'Kyrgyzstan';
        break;
      case 'LVA':
        translateKey = 'COUNTRY_BY_ISO3.LVA';
        break;
      case 'LBN':
        translateKey = 'Lebanon';
        break;
      case 'LSO':
        translateKey = 'Lesotho';
        break;
      case 'LBR':
        translateKey = 'Liberia';
        break;
      case 'LBY':
        translateKey = 'Libya';
        break;
      case 'LIE':
        translateKey = 'COUNTRY_BY_ISO3.LIE';
        break;
      case 'LTU':
        translateKey = 'COUNTRY_BY_ISO3.LTU';
        break;
      case 'LUX':
        translateKey = 'COUNTRY_BY_ISO3.LUX';
        break;
      case 'MAC':
        translateKey = 'Macao';
        break;
      case 'MDG':
        translateKey = 'Madagascar';
        break;
      case 'MWI':
        translateKey = 'Malawi';
        break;
      case 'MYS':
        translateKey = 'Malaysia';
        break;
      case 'MDV':
        translateKey = 'Maldives';
        break;
      case 'MLI':
        translateKey = 'Mali';
        break;
      case 'MLT':
        translateKey = 'COUNTRY_BY_ISO3.MLT';
        break;
      case 'MHL':
        translateKey = 'Marshall-island';
        break;
      case 'MTQ':
        translateKey = 'Martinique';
        break;
      case 'MRT':
        translateKey = 'Mauritania';
        break;
      case 'MUS':
        translateKey = 'Mauritius';
        break;
      case 'MEX':
        translateKey = 'Mexico';
        break;
      case 'FSM':
        translateKey = 'Micronesia';
        break;
      case 'MDA':
        translateKey = 'Moldova';
        break;
      case 'MCO':
        translateKey = 'COUNTRY_BY_ISO3.MCO';
        break;
      case 'MNG':
        translateKey = 'Mongolia';
        break;
      case 'MNE':
        translateKey = 'Montenegro';
        break;
      case 'MSR':
        translateKey = 'Montserrat';
        break;
      case 'MAR':
        translateKey = 'Morocco';
        break;
      case 'MOZ':
        translateKey = 'Mozambique';
        break;
      case 'MMR':
        translateKey = 'Myanmar';
        break;
      case 'NAM':
        translateKey = 'Namibia';
        break;
      case 'NRU':
        translateKey = 'Nauru';
        break;
      case 'NPL':
        translateKey = 'Nepal';
        break;
      case 'NLD':
        translateKey = 'COUNTRY_BY_ISO3.NLD';
        break;
      case 'NZL':
        translateKey = 'New-zealand';
        break;
      case 'NIC':
        translateKey = 'Nicaragua';
        break;
      case 'NER':
        translateKey = 'Niger';
        break;
      case 'NGA':
        translateKey = 'Nigeria';
        break;
      case 'NIU':
        translateKey = 'Niue';
        break;
      case 'NFK':
        translateKey = 'Norfolk-island';
        break;
      case 'MNP':
        translateKey = 'Northern-marianas-islands';
        break;
      case 'NOR':
        translateKey = 'COUNTRY_BY_ISO3.NOR';
        break;
      case 'OMN':
        translateKey = 'Oman';
        break;
      case 'PAK':
        translateKey = 'Pakistan';
        break;
      case 'PLW':
        translateKey = 'Palau';
        break;
      case 'PSE':
        translateKey = 'Palestine';
        break;
      case 'PAN':
        translateKey = 'Panama';
        break;
      case 'PNG':
        translateKey = 'Papua-new-guinea';
        break;
      case 'PRY':
        translateKey = 'Paraguay';
        break;
      case 'PER':
        translateKey = 'Peru';
        break;
      case 'PHL':
        translateKey = 'Philippines';
        break;
      case 'PCN':
        translateKey = 'Pitcairn-islands';
        break;
      case 'POL':
        translateKey = 'COUNTRY_BY_ISO3.POL';
        break;
      case 'PRT':
        translateKey = 'COUNTRY_BY_ISO3.PRT';
        break;
      case 'PRI':
        translateKey = 'Puerto-rico';
        break;
      case 'QAT':
        translateKey = 'Qatar';
        break;
      case 'MKD':
        translateKey = 'Republic-of-macedonia';
        break;
      case 'ROU':
        translateKey = 'COUNTRY_BY_ISO3.ROU';
        break;
      case 'RUS':
        translateKey = 'Russia';
        break;
      case 'RWA':
        translateKey = 'Rwanda';
        break;
      case 'KNA':
        translateKey = 'Saint-kitts-and-nevis';
        break;
      case 'WSM':
        translateKey = 'Samoa';
        break;
      case 'SMR':
        translateKey = 'San-marino';
        break;
      case 'STP':
        translateKey = 'Sao-tome-and-principe';
        break;
      case 'SAU':
        translateKey = 'Saudi-arabia';
        break;
      case 'SEN':
        translateKey = 'Senegal';
        break;
      case 'SRB':
        translateKey = 'Serbia';
        break;
      case 'SYC':
        translateKey = 'Seychelles';
        break;
      case 'SLE':
        translateKey = 'Sierra-leone';
        break;
      case 'SGP':
        translateKey = 'Singapore';
        break;
      case 'SXM':
        translateKey = 'Sint-maarten';
        break;
      case 'SVK':
        translateKey = 'COUNTRY_BY_ISO3.SVK';
        break;
      case 'SVN':
        translateKey = 'COUNTRY_BY_ISO3.SVN';
        break;
      case 'SLB':
        translateKey = 'Solomon-islands';
        break;
      case 'SOM':
        translateKey = 'Somalia';
        break;
      case 'ZAF':
        translateKey = 'South-africa';
        break;
      case 'SSD':
        translateKey = 'South-sudan';
        break;
      case 'ESP':
        translateKey = 'COUNTRY_BY_ISO3.ESP';
        break;
      case 'LKA':
        translateKey = 'Sri-lanka';
        break;
      case 'SDN':
        translateKey = 'Sudan';
        break;
      case 'SUR':
        translateKey = 'Suriname';
        break;
      case 'SWE':
        translateKey = 'COUNTRY_BY_ISO3.SWE';
        break;
      case 'CHE':
        translateKey = 'COUNTRY_BY_ISO3.CHE';
        break;
      case 'SYR':
        translateKey = 'Syria';
        break;
      case 'TWN':
        translateKey = 'Taiwan';
        break;
      case 'TJK':
        translateKey = 'Tajikistan';
        break;
      case 'TZA':
        translateKey = 'Tanzania';
        break;
      case 'THA':
        translateKey = 'Thailand';
        break;
      case 'TGO':
        translateKey = 'Togo';
        break;
      case 'TKL':
        translateKey = 'Tokelau';
        break;
      case 'TON':
        translateKey = 'Tonga';
        break;
      case 'TTO':
        translateKey = 'Trinidad-and-tobago';
        break;
      case 'TUN':
        translateKey = 'Tunisia';
        break;
      case 'TUR':
        translateKey = 'Turkey';
        break;
      case 'TKM':
        translateKey = 'Turkmenistan';
        break;
      case 'TCA':
        translateKey = 'Turks-and-caicos';
        break;
      case 'TUV':
        translateKey = 'Tuvalu';
        break;
      case 'UGA':
        translateKey = 'Uganda';
        break;
      case 'UKR':
        translateKey = 'Ukraine';
        break;
      case 'ARE':
        translateKey = 'United-arab-emirates';
        break;
      case 'GBR':
        translateKey = 'COUNTRY_BY_ISO3.GBR';
        break;
      case 'USA':
        translateKey = 'COUNTRY_BY_ISO3.USA';
        break;
      case 'URY':
        translateKey = 'Uruguay';
        break;
      case 'UZB':
        translateKey = 'Uzbekistn';
        break;
      case 'VUT':
        translateKey = 'Vanuatu';
        break;
      case 'VEN':
        translateKey = 'Venezuela';
        break;
      case 'VNM':
        translateKey = 'Vietnam';
        break;
      case 'VGB':
        translateKey = 'Virgin-islands';
        break;
      case 'YEM':
        translateKey = 'Yemen';
        break;
      case 'ZMB':
        translateKey = 'Zambia';
        break;
      case 'ZWE':
        translateKey = 'Zimbabwe';
        break;
    }

    return this.translateService.instant(translateKey);
  }
}


