import { NgModule } from '@angular/core';


import { UserAvatarComponent } from './components';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [UserAvatarComponent],
  exports: [UserAvatarComponent],
  imports: [CommonModule]
})
export class UsersCommonModule {

}
