import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { FileSaverService } from 'ngx-filesaver';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, finalize, catchError, mergeMap } from 'rxjs/operators';
import { get } from 'lodash';

import { AppState } from '../../store/state/app.state';
import { ResponseList, ResponseModel, DocumentTemplateModel } from '../../shared/models';
import { FilterModel } from '../../warehouse/models/filter.model';
import { DeliveryNote, DeliveryCounters } from '../models/delivery-note.model';
import { LoadDeliveryNote, UpdateDeliveryNoteState, IncrementLoadingRequestsCount, DecrementLoadingRequestsCount, UpdateDeliveryNoteUpdatedAt, LoadDeliveryNoteCounters, LoadDeliveryNotesList, UpdateShouldRefreshEntity } from '../store/actions/delivery-note.actions';
import { DeliveryNoteListTabsEnum } from '../enums';
import { ProductSearchResponse } from 'common/src/modules/modals/modals-warehouse/pack-up-modal/models/product-search-response.model';
import { AvailableProduct } from 'common/src/modules/modals/modals-warehouse/pack-up-modal/models/available-product.model';
import { UIStatesEnum } from 'common/src/models';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { environment } from 'projects/workspace/src/environments/environment';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { GeneratePickListErrorModalComponent } from 'common/src/modules/modals/modals-delivery-note/generate-pick-list-error-modal/generate-pick-list-error-modal.component';
import { getContentDispositionFileName } from 'common/src/modules/rnpl-common/helpers';
import { PurchaseOrder } from '../../purchase-order/models';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { PrereservationPositionsModel } from '../models/prereservation-positions.model';


@Injectable({
  providedIn: 'root',
})
export class DeliveryNoteApiService {
  private readonly apiEndpoint: string = '/delivery-notes';
  private readonly apiJavaEndpoint: string = `${environment.javaApiVersion}`;

  constructor(
    private readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly fileSaverService: FileSaverService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
  ) {}

  public createDeliveryNoteDraft(): Observable<DeliveryNote> {
    return this.http.request<ResponseModel<DeliveryNote>>(
        'post',
        `${this.apiEndpoint}/draft`
      )
      .pipe(
        map((data: ResponseModel<DeliveryNote>) => data.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getDeliveryNoteById(id: string | number): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${id}`)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(LoadDeliveryNoteCounters({ counters: response.counters }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public getProduct(
    deliveryNoteId: number,
    barcode: string,
    productId: string,
  ): Observable<ProductSearchResponse> {
    return this.http.get<ResponseModel<ProductSearchResponse>>(
      `${this.apiEndpoint}/${deliveryNoteId}/products`,
      {
        params: (barcode) ? {barcode} : {productId}
      })
      .pipe(
        map((response: ResponseModel<ProductSearchResponse>) => response.data)
      );
  }

  public getAvailableProductsList(
    deliveryNoteId: number,
    sort: FilterModel = {nameColumn: 'name', value: DEFAULT_SORT_DIRECTION}
  ): Observable<AvailableProduct[]> {
    return this.http.get<ResponseModel<AvailableProduct[]>>(
      `${this.apiEndpoint}/${deliveryNoteId}/products/available`,
      { params: { [`sort[${sort.nameColumn}]`]: sort.value }})
      .pipe(
        map((response: ResponseModel<AvailableProduct[]>) => response.data)
      );
  }

  public getPreReservationProduct(deliveryNoteId: number): Observable<PrereservationPositionsModel[]> {
    return this.http.get<ResponseModel<PrereservationPositionsModel[]>>(`${this.apiEndpoint}/${deliveryNoteId}/prereservation/products`)
      .pipe(
        map((response: ResponseModel<PrereservationPositionsModel[]>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public preReservateProducts(deliveryNoteId: number, products): Observable<any[]> {
    return this.http.post<ResponseModel<any[]>>(`${this.apiEndpoint}/${deliveryNoteId}/prereservation/products`, {products})
      .pipe(
        map((response: ResponseModel<any[]>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getAvailableProductsForUnlinkedDeliveryNote(dnStatus: string): Observable<AvailableProduct[]> {
    return this.http.get<ResponseModel<AvailableProduct[]>>(`${this.apiEndpoint}/unlinked/products/available`, {params: {dnStatus}})
      .pipe(
        map((response: ResponseModel<AvailableProduct[]>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getAvailableProductsForLinkedDeliveryNote(dnId: number): Observable<AvailableProduct[]> {
    return this.http.get<ResponseModel<AvailableProduct[]>>(`${this.apiEndpoint}/linked/${dnId}/products/add/available`)
      .pipe(
        map((response: ResponseModel<AvailableProduct[]>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public getAvailableProductUnitsForUnlinkedDeliveryNote(stockArea: string, productId: number): Observable<any[]> {
    return this.http.get<ResponseModel<any[]>>(
      `${this.apiEndpoint}/unlinked/products/${productId}/available`,
      { params: { stockArea }}
    )
      .pipe(
        map((response: ResponseModel<any[]>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public addProductUnitsToUnlinkedDeliveryNote(
    dnId: number,
    stockArea: string,
    productId: number,
    specifyUnits: boolean,
    quantity: number,
    units: any[]
  ): Observable<DeliveryNote> {
    return this.http.post<ResponseModel<DeliveryNote>>(
      `${this.apiEndpoint}/${dnId}/unlinked/position`,
      {
        stockArea,
        productId,
        specifyUnits,
        quantity,
        units
      }
    )
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(LoadDeliveryNoteCounters({ counters: response.counters }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }

  public addProductUnitsToLinkedDeliveryNote(dnId: number, productId: number, quantity: number): Observable<DeliveryNote> {
    return this.http.post<ResponseModel<DeliveryNote>>(
      `${this.apiEndpoint}/${dnId}/linked/position`,
      {
        productId,
        quantity
      }
    )
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(LoadDeliveryNoteCounters({ counters: response.counters }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error);
          return throwError(error);
        })
      );
  }


  public packUpProduct(
    deliveryNoteId: number,
    data: any
  ): Observable<ProductSearchResponse> {
    return this.http.post<ResponseModel<ProductSearchResponse>>(`${this.apiEndpoint}/${deliveryNoteId}/unit`, data)
      .pipe(
        map((response: ResponseModel<ProductSearchResponse>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, deliveryNoteId);
          return throwError(error);
        })
      );
  }


  // public getProductsList(id: string | number): Observable<ProductSearchResponse[]> {
  //   return this.http.get<ResponseModel<ProductSearchResponse[]>>(`${this.apiEndpoint}/${id}/products`)
  //     .pipe(
  //       map((response: ResponseModel<ProductSearchResponse[]>) => response.data)
  //     );
  // }

  public updateDeliveryNote(deliveryNoteId: string | number, deliveryNote: DeliveryNote): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${deliveryNoteId}`, deliveryNote)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, deliveryNoteId);
          return throwError(error);
        })
      );
  }

  public getDeliveryNoteList(
    status: DeliveryNoteListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = {nameColumn: 'updated_at', value: DEFAULT_SORT_DIRECTION},
    filters: any = {}
  ): Observable<ResponseList<DeliveryNote>> {
    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<DeliveryNote>>(this.apiEndpoint, { params })
      .pipe(tap((data: ResponseList<DeliveryNote>) => {
        this.store.dispatch(LoadDeliveryNotesList({
          deliveryNotesListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getDeliveryNotesRunpleIds(status: DeliveryNoteListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(`${this.apiEndpoint}/runpleIds`, { params })
      .pipe(map((data: any) => data.data));
  }

  public getDeliveryNoteCounters(): Observable<DeliveryCounters> {
    return this.http
      .get<ResponseModel<DeliveryCounters>>(
        `${this.apiEndpoint}/counters`
      )
      .pipe(map((data: ResponseModel<DeliveryCounters>) => data.data));
  }

  public changeDeliveryNotesStatus(
    ids: number[],
    status: DeliveryNoteListTabsEnum,
  ): Observable<any> {
    const body = { ids, status };
    return this.http.patch<ResponseModel<any>>(
      `${this.apiEndpoint}/status`,
      body
    );
  }

  deleteDeliveryNotesPermanently(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiEndpoint, {body});
  }

  public validateAndCreatePickList(
    ids: number[],
    type: string
  ): Observable<HttpResponse<Blob>> {
    const body = {ids, type};
    return this.http.post(`${this.apiEndpoint}/pick-list/validate`, body)
      .pipe(
        mergeMap(() => this.createPickList(ids, type)),
        catchError(error => {
          if (error.error.message === 'No products for picking') {
            const dialog = this.dialog.open(GeneratePickListErrorModalComponent, {
              data: {
                isMultiple: ids.length > 1,
                canGenerate: error.error.data.canGenerate,
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.createPickList(ids, type).subscribe((res) => {
                  this.fileSaverService.save(res.body, getContentDispositionFileName(res.headers.get('Content-Disposition')));
                });
              }
            });
          } else {
            this.showMsg('error', error.error.message);
          }
          return throwError(error);
        })
      );
  }

  public createPickList(
    ids: number[],
    type: string
  ): Observable<HttpResponse<Blob>> {
    const body = {ids, type};
    return this.http.post(
      `${this.apiJavaEndpoint}/pdf/delivery-note/pick-list`,
      body,
      {
        observe: 'response',
        responseType: 'blob'
      }
    );
  }

  public changeSingleDeliveryNoteStatus(
    id: number | string,
    status: DeliveryNoteListTabsEnum,
    units?: Partial<DeliveryNote>,
    skipRePreReservation = false,
    skipWarnings?: boolean,
    boxes?: {height?: number; width?: number; length?: number; weight?: number;}
  ): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = {
      status: status.split('-').join('_'),
      skipWarnings,
      skipRePreReservation,
      ...units,
      boxes
    };
    return this.http.patch<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${id}/status`, body)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  @DisplayToaster({showErrorMessage: true})
  public restoreDeliveryNote(id: number | string): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<DeliveryNote>>('post', `${this.apiEndpoint}/${id}/restore`)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }


  public submitDeliveryNoteCreation(id: number | string): Observable<DeliveryNote> {
    return this.http.request<ResponseModel<DeliveryNote>>('post', `${this.apiEndpoint}/${id}/accept-create`)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data)
      );
  }

  public deleteDeliveryNote(id: number | string): Observable<ResponseModel<null | DeliveryNote>> {
    return this.http.request<ResponseModel<null | DeliveryNote>>('delete', `${this.apiEndpoint}/${id}`);
  }

  public deleteUnit(
    deliveryNoteId: number | string,
    unitId: string
  ): Observable<ProductSearchResponse> {
    return this.http.request<ResponseModel<ProductSearchResponse>>(
      'delete',
      `${this.apiEndpoint}/${deliveryNoteId}/unit`,
      {body: {unitId}}
    ).pipe(
      map((response: ResponseModel<ProductSearchResponse>) => response.data),
      catchError(error => {
        this.handlePopupErrors(error, deliveryNoteId);
        return throwError(error);
      })
    );
  }

  public unpackProduct(
    deliveryNoteId: number | string,
    positionId: number | string
  ): Observable<ProductSearchResponse> {
    return this.http.request<ResponseModel<ProductSearchResponse>>(
      'delete',
      `${this.apiEndpoint}/${deliveryNoteId}/positions/${positionId}/unpack`,
    ).pipe(
      map((response: ResponseModel<ProductSearchResponse>) => response.data),
      catchError(error => {
        this.handlePopupErrors(error, deliveryNoteId);
        return throwError(error);
      })
    );
  }

  public deleteProducts(deliveryNoteId: number | string, ids: number[]): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<DeliveryNote>>(
      'delete',
      `${this.apiEndpoint}/${deliveryNoteId}/positions`,
      {body: {ids}}
    ).pipe(
      tap((response: ResponseModel<DeliveryNote>) => {
        this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
        this.store.dispatch(LoadDeliveryNoteCounters({ counters: response.counters }));
        this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
      }),
      map((response: ResponseModel<DeliveryNote>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, deliveryNoteId);
        return throwError(error);
      })
    );
  }

  changePositionOrder(posId: number, moveToOrder: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(`${this.apiEndpoint}/positions/${posId}/order/${moveToOrder}`, body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public unpackProducts(deliveryNoteId: number | string, ids: number[]): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<DeliveryNote>>(
      'delete',
      `${this.apiEndpoint}/${deliveryNoteId}/positions/unpack`,
      {body: {ids}}
    ).pipe(
      tap((response: ResponseModel<DeliveryNote>) => {
        this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
        this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
      }),
      map((response: ResponseModel<DeliveryNote>) => response.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, deliveryNoteId);
        return throwError(error);
      })
    );
  }

  // public deleteDeliveryNoteUnit(deliveryNoteId: number | string, unitId: number): Observable<Position> {
  //   return this.http.request<ResponseModel<Position>>(
  //     'delete',
  //     `${this.apiEndpoint}/${deliveryNoteId}/unit`,
  //     {body: {unitId}}
  //   ).pipe(
  //     map((response: ResponseModel<Position>) => response.data),
  //     catchError(error => {
  //       this.handlePopupErrors(error, deliveryNoteId);
  //       return throwError(error);
  //     })
  //   );
  // }

  public updateDeliveryNotePositionProperty(deliveryNoteId: string | number, positionId: number, property: {[key: string]: string }): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.put<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${deliveryNoteId}/positions/${positionId}`, property)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, deliveryNoteId);
          return throwError(error);
        })
      );
  }

  // pass salesOrderId to link and dont pass for unlink
  public linkOrUnlinkSalesOrder(id: string | number, salesOrderId?: number): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = salesOrderId ? { salesOrderId } : {};
    return this.http.put<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${id}/sales-order`, body)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  public deliveryNoteSetEdit(id: string | number, force = false): Observable<DeliveryNote> {
    const body = { force };

    return this.http.put<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${id}/locking`, body)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteState({ currentState: UIStatesEnum.EDIT }));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public deliveryNoteUnsetEdit(id: string | number): Observable<DeliveryNote> {
    return this.http.request<ResponseModel<DeliveryNote>>('put', `${this.apiEndpoint}/${id}/unlocking`)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  public deliveryNoteTrackingRefresh(deliveryNoteId: string | number): Observable<DeliveryNote> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<DeliveryNote>>('post', `${this.apiEndpoint}/${deliveryNoteId}/tracking/refresh`)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, deliveryNoteId);
          return throwError(error);
        })
      );
  }

  public createDeliveryNoteBySalesOrder(salesOrderId: number): Observable<DeliveryNote> {
    return this.http.request<ResponseModel<DeliveryNote>>('post', `${this.apiEndpoint}/by-sales-order/${salesOrderId}`)
      .pipe(map((response: ResponseModel<DeliveryNote>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDeliveryNoteByERA(eraId: number): Observable<DeliveryNote> {
    return this.http.request<ResponseModel<DeliveryNote>>('post', `${this.apiEndpoint}/by-era/${eraId}`)
      .pipe(map((response: ResponseModel<DeliveryNote>) => response.data));
  }

  public getDeliveryNoteExportParams(id: number, title: string = 'DN'): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `accounting/v1/pdf/delivery-note/${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  // @DisplayToaster({showErrorMessage: true})
  // public sendDeliveryNoteEmail(id: number): Observable<DeliveryNote> {
  //   return this.http.request<ResponseModel<DeliveryNote>>('post', `${this.apiEndpoint}/${id}/email`)
  //     .pipe(
  //       tap((response: ResponseModel<DeliveryNote>) => {
  //         this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
  //       }),
  //       map((response: ResponseModel<DeliveryNote>) => response.data),
  //     );
  // }

  public getDeliveryNoteListExport(title: string, status: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/${status}/csv/export`,
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  public getDeliveryNoteDeliveryServiceLabel(deliveryNoteId: number): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `${this.apiEndpoint}/${deliveryNoteId}/label`,
      type: 'pdf',
    };
    return of(fileParams);
  }

  public deliveryNoteSetPacking(id: string | number, skipRePreReservation = false): Observable<DeliveryNote> {
    const body = { skipRePreReservation };

    return this.http.put<ResponseModel<DeliveryNote>>(`${this.apiEndpoint}/${id}/packing/locking`, body)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteState({ currentState: UIStatesEnum.PACKING }));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        // catchError(error => {
        //   this.handlePopupErrors(error, id);
        //   return throwError(error);
        // })
      );
  }

  public deliveryNotePausePacking(id: string | number): Observable<DeliveryNote> {
    return this.http.request<ResponseModel<DeliveryNote>>('put', `${this.apiEndpoint}/${id}/packing/pause`)
      .pipe(
        tap((response: ResponseModel<DeliveryNote>) => {
          this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
          this.store.dispatch(UpdateDeliveryNoteState({ currentState: UIStatesEnum.VIEW }));
        }),
        map((response: ResponseModel<DeliveryNote>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, id);
          return throwError(error);
        })
      );
  }

  // public deliveryNoteUnsetPacking(id: string | number): Observable<DeliveryNote> {
  //   return this.http.request<ResponseModel<DeliveryNote>>('put', `${this.apiEndpoint}/${id}/packing/unlocking`)
  //     .pipe(
  //       tap((response: ResponseModel<DeliveryNote>) => {
  //         this.store.dispatch(LoadDeliveryNote({ deliveryNote: response.data }));
  //         this.store.dispatch(UpdateDeliveryNoteState({ currentState: UIStatesEnum.VIEW }));
  //       }),
  //       map((response: ResponseModel<DeliveryNote>) => response.data),
  //       catchError(error => {
  //         this.handlePopupErrors(error, id);
  //         return throwError(error);
  //       })
  //     );
  // }

  public getBCities(): Observable<any> {
    return this.http.get<ResponseModel<any>>(`${this.apiEndpoint}/cities`)
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  public getDocumentTemplates(): Observable<DocumentTemplateModel[]> {
    return this.http.get<ResponseModel<DocumentTemplateModel[]>>(`${this.apiEndpoint}/templates`)
      .pipe(map((response: ResponseModel<DocumentTemplateModel[]>) => response.data));
  }

  public getDocumentTemplatesNames(): Observable<{ id: number; name: string; }[]> {
    return this.http.get<ResponseModel<{ id: number; name: string; }[]>>(`${this.apiEndpoint}/templates/names`)
      .pipe(map((response: ResponseModel<{ id: number; name: string; }[]>) => response.data));
  }

  public saveDocumentTemplate(documentId: number, body: { name: string; overwrite: boolean; resetCounter: boolean; }): Observable<boolean> {
    return this.http.post<ResponseModel<boolean>>(`${this.apiEndpoint}/${documentId}/template`, body)
      .pipe(map((response: ResponseModel<boolean>) => response.data));
  }

  public applyDocumentTemplate(documentId: number, templateId: number, force = false): Observable<any> {
    return this.http.patch<ResponseModel<any>>(`${this.apiEndpoint}/${documentId}/template/${templateId}`, {force})
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDocumentFromTemplate(templateId: number): Observable<number> {
    return this.http.request<ResponseModel<number>>('post', `${this.apiEndpoint}/template/${templateId}`)
      .pipe(map((response: ResponseModel<number>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, dnId?: number | string): void {
    if (get(error, 'error.message')) {
      switch (error.error.message) {
        case 'anotherUserEditError':
        {
          const dialog = this.dialog.open(WarningModalComponent, {
            data: getAnotherUserEditErrorModalData(
              {
                document: error.error.data.entityName,
                userName: error.error.data.userName,
              },
              this.translateService
            )
          });

          dialog.afterClosed().subscribe(res => {
            if (res === CommonModalsActionsEnum.CONFIRM) {
              this.deliveryNoteSetEdit(dnId, true).subscribe();
            }
          });
        }
          break;
        case 'serialNumberAlreadyUsed':
          // handled in modal
          break;
        case 'notEditModeError':
          const documentName = this.translateService.instant('COLUMN.DELIVERY_NOTE');
          this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
          this.store.dispatch(UpdateDeliveryNoteState({ currentState: UIStatesEnum.VIEW }));
          this.getDeliveryNoteById(dnId).subscribe();
          break;
        case 'There aren\'t any available product on stock!':
          this.dialog.open(InfoModalComponent, {
            data: {
              title: 'Unable to create outgoing delivery',
              message: 'There aren\'t any available product on stock!',
              hideCancelBtn: true,
              titleColor: 'yellow-400',
              titleIcon: 'alert-triangle',
              confirmBtnText: 'Close',
              confirmBtnIcon: 'close'
            }
          });
          break;
        case 'cannotAddMoreThanAvailableForDelivery':
          this.dialog.open(InfoModalComponent, {
            data: {
              title: 'WAREHOUSE.DELIVERY_NOTE.NOT_ENOUGH_UNITS',
              message: 'WAREHOUSE.DELIVERY_NOTE.MORE_THAN_AVAILABLE',
              hideCancelBtn: true,
              titleColor: 'blue-400',
              titleIcon: 'alert-triangle',
              confirmBtnText: 'Close',
              confirmBtnIcon: 'close'
            }
          });
          break;
        default:
          if (error.error.message) {
            this.showMsg('error', error.error.message);
          }
          break;
      }
    }

    if (get(error, 'error.data.length')) {
      error.error.data.forEach(validationError => {
        if (validationError.message) {
          this.showMsg('error', validationError.message);
        }
      });
    }
  }

}
