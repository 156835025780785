import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { BreadcrumbModel } from './breadcrumb.model';
import { BreadcrumbsService } from './breadcrumbs.service';


@Component({
  selector: 'rnpl-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public get breadcrumbs(): Array<BreadcrumbModel> {
    return [...this._breadcrumbs, ...this.additionalBreadcrumbs];
  }

  private _breadcrumbs: Array<BreadcrumbModel> = [];
  private additionalBreadcrumbs: Array<BreadcrumbModel> = [];

  private lastPath: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private breadcrumbsService: BreadcrumbsService) {
  }

  ngOnInit() {
    this.router.events
      .subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          const currPath = event.url.replace(/(\/\d+)$/, '');
          if (!this.lastPath || this.lastPath !== currPath) {
            this.additionalBreadcrumbs = [];
            this.lastPath = currPath;
          }
          this._breadcrumbs = this.getBreadcrumbs();
        }
      });

    this.breadcrumbsService.additionalBreadcrumbs
      .subscribe(breadcrumbs => this.additionalBreadcrumbs = breadcrumbs);

    this._breadcrumbs = this.getBreadcrumbs();
  }

  /**
   * Make breadcrumbs from active route
   */
  private getBreadcrumbs(): Array<BreadcrumbModel> {
    return this.getRouteList(this.activatedRoute)
      .reduce((breadcrumbs, route) => {
        const label = route.snapshot.data.title;
        let path = route.routeConfig ? route.routeConfig.path : '';

        if (!label || !path) {
          return breadcrumbs;
        }

        if (breadcrumbs.length) {
          path = `${breadcrumbs[0].path}/${path}`;
        }

        const breadcrumb: BreadcrumbModel = {
          label: route.snapshot.data.title,
          path: path
        };

        return [breadcrumb, ...breadcrumbs];
      }, []).reverse();
  }

  /**
   * Gets list of active routes
   * @param route ActivatedRoute
   */
  private getRouteList(route: ActivatedRoute): Array<ActivatedRoute> {
    return route.firstChild ? [route, ...this.getRouteList(route.firstChild)] : [route];
  }

}
