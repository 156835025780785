import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { AppState } from '../../store/state/app.state';
import { selectTeamsUser } from '../store/selectors';
import { HrmService } from '../hrm.service';
import { UpdateShouldRefreshEntity, UpdateUserCurrentState } from '../store/actions/teams.actions';
import { UIStatesEnum } from 'common/src/models';
import { UserProfileModel } from '../models';
import { AuthService } from 'common/src/auth/auth.service';

@Injectable()
export class IsUserGuard implements CanActivate {

  private user = this.authService.getUser();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly hrmService: HrmService,
    private readonly store: Store<AppState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const userId: number = Number(route.params.userId);

    return this.store.select(selectTeamsUser).pipe(
      first(),
      switchMap((user: UserProfileModel) => {
        if (this.user.id === userId) {
          this.router.navigate(['/team/my-profile']);
          return of(false);
        }
        const isEntityExistAndSameId = user && get(user, 'profile.id') === userId;
        this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: isEntityExistAndSameId }));
        return (isEntityExistAndSameId) ? of(user) : this.hrmService.getUserProfileAndBlueprintsV2(userId);
      }),
      catchError(() => {
        this.router.navigate(['/team']);
        return of(false);
      }),
      switchMap(() => {
        this.store.dispatch(UpdateUserCurrentState({currentState: UIStatesEnum.VIEW}));
        return of(true);
      })
    );
  }
}
