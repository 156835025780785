import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';



import { ImageModel } from 'common/src/models';
import { CountryModel } from 'common/src/modules/rnpl-common';
import { CompanyInfoModel, PartnerVATDataModel } from '../../../models';

import { BaseFormComponent } from '../base-form.component';
import { AddressFormFactory } from '../address-form.factory';
import { CrmService } from '../../../crm.service';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { convertAddressToStringHelper } from 'common/src/modules/rnpl-common/helpers';

@Component({
  selector: 'rnpl-corporate-partner-form',
  templateUrl: './corporate-partner-form.component.html'
})
export class CorporatePartnerFormComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  companyInfo: CompanyInfoModel;

  @Input()
  attributes: any[];

  @Input()
  countries: CountryModel[];

  @Input()
  appendTo: string;


  public vatNumber = '';
  public vatPrefix = null;
  public vatValid: any = null;

  private _destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private fb: FormBuilder,
    private crmService: CrmService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('companyInfo')) {
      this.initBaseForms();
    }

    if (changes.hasOwnProperty('readonly')) {
      this.swapEditMode();
      this.initBaseForms();
    }
  }

  ngOnInit(): void {
    this.initBaseForms();
  }

  toggleVat(vatNumber): void {
    if (this.vatNumber !== null) {
      // todo: fill VAT form field
      this.vatNumber = null;
    } else {
      // todo: reset VAT form field
      this.vatNumber = vatNumber;
    }
  }

  swapEditMode() {
    if (this.readonly) {
      this.form.disable({onlySelf: false});
    } else {
      this.form.enable({onlySelf: false});
      this.form.get('registration_date').disable();
    }
  }

  initBaseForms(): void {
    this.initForm(this.companyInfo);

    if (get(this.companyInfo, 'image')) {
      this.setLogo([this.companyInfo.image]);
    }
  }

  setLogo(images: ImageModel[]): void {
    this.form.get('image').setValue(images.length ? images[0] : null);
  }

  initForm(companyInfo: CompanyInfoModel = {} as any): void {
    const regCountryVal = get(companyInfo, 'legal_address.country')
      ? companyInfo.legal_address.country
      : null;

    this.form = this.fb.group({
      name: [{
        value: companyInfo.name || '',
        disabled: this.readonly
      }, [Validators.required]],
      description: [{
        value: companyInfo.description || '',
        disabled: this.readonly
      }],
      alias: [{
        value: companyInfo.alias || '',
        disabled: this.readonly
      }],
      registration_date: [{
        value: companyInfo.registration_date || (new Date()).toISOString(),
        disabled: true
      }, [Validators.required]],
      legal_address: AddressFormFactory.getForm(companyInfo.legal_address, this.readonly),
      image: [null, []],
      vat_number: [{
        value: companyInfo.vat_number || '',
        disabled: this.readonly || !!!regCountryVal
      }],
      registration_country: [{
        value: regCountryVal,
        disabled: this.readonly
      }, []],
      email: [{
        value: companyInfo.email || '',
        disabled: this.readonly
      }, [Validators.email]],
      phone: [{
        value: companyInfo.phone || '',
        disabled: this.readonly
      }, []],
    });

    this.cdr.detectChanges();

    if ( get(companyInfo, 'legal_address.country_iso3')) {
      const iso_3 = companyInfo.legal_address.country_iso3;
      for (let country in this.countries) {
        if (Object.values(this.countries[country]).indexOf(iso_3) > -1) {
          this.form.get('registration_country').setValue(this.countries[country]);
          this.vatPrefix = this.countries[country].code_iso2.toUpperCase();
        }
      }
    }

    this.form.get('registration_country').valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(Boolean),
        takeUntil(this._destroy)
      )
      .subscribe((val: any) => {
        this.form.get('legal_address.country_iso3').setValue(val.code_iso3);
        this.vat_number.enable();
        this.vatValid = null;

        if (val && val.code_iso2) {
          this.vatPrefix = val.code_iso2.toUpperCase();
        }
      });

    this.vat_number.valueChanges
      .pipe(
        debounceTime(400),
        // distinctUntilChanged(),
        filter(val => (val.length >= 8 && !this.readonly)),
        switchMap((vat: string) =>  this.crmService.validateVatNumber(vat.toUpperCase().substring(2), this.vatPrefix)),
        takeUntil(this._destroy)
      )
      .subscribe(response => {
        this.processVatValidation(response.data);
      }, error => {
        this.toasterService.notify({
          type: ToasterService.MESSAGE_TYPE_WARNING,
          message: error.error.message
        });
      });

    super.initForm();
  }

  processVatValidation(vatData: PartnerVATDataModel): void {
    this.vatValid = vatData.valid;

    if (!vatData.valid) {
      this.vat_number.setErrors({'invalid': true});
      return;
    } else {
      if (!get(this, 'companyInfo.id')) {
        this.form.get('name').setValue(vatData.name);

        const legalAddressForm = this.form.get('legal_address');
        const arrAddress = vatData.address.split(' ');
        let isAddressAndZipFound = false;
        const parsedAddress = {
          street: [],
          houseAndZip: [],
          houseBlockApartments: [],
          city: [],
          zip: '',
        };

        for (let i in arrAddress) {
          if (!isAddressAndZipFound && !arrAddress[i].includes('/')) { // while not found zip all items is parts of street name
            parsedAddress.street.push(arrAddress[i]);
          } else if (!isAddressAndZipFound && arrAddress[i].includes('/')) {
            // (house, block, apartments) separates with (zip code) by '↵' sign
            parsedAddress.houseAndZip = arrAddress[i].split(/(\r\n|\n|\r)/gm);
            // house/block?(optional)/apartments - separates by '/' sign
            parsedAddress.houseBlockApartments = parsedAddress.houseAndZip[0].split('/');
            parsedAddress.zip = parsedAddress.houseAndZip[2];
            isAddressAndZipFound = true;
          } else { // when zip found all items is parts of city name
            parsedAddress.city.push(arrAddress[i]);
          }
        }

        this.setControlValue(legalAddressForm, 'street', parsedAddress.street.join(' '));
        this.setControlValue(legalAddressForm, 'city', parsedAddress.city.join(' '));
        this.setControlValue(legalAddressForm, 'zip_code', parsedAddress.zip);
        this.setControlValue(legalAddressForm, 'house_number', parsedAddress.houseBlockApartments[0]);

        if (parsedAddress.houseBlockApartments.length === 2) { // without block number
          this.setControlValue(legalAddressForm, 'apartments', parsedAddress.houseBlockApartments[1]);
        } else { // with block number
          this.setControlValue(legalAddressForm, 'block', parsedAddress.houseBlockApartments[1]);
          this.setControlValue(legalAddressForm, 'apartments', parsedAddress.houseBlockApartments[2]);
        }
      }
    }
  }

  setControlValue(form: AbstractControl, control: string, val: any): void {
    if (!form.get(control).value) {
      form.get(control).setValue(val);
      form.get(control).markAsTouched();
    }
  }

  public get legalAddressAsText(): string {
    if (!this.form) { return ''; }

    return convertAddressToStringHelper(this.legal_address.value, this.countries);
  }

  get name() {
    return this.form.get('name');
  }

  get vat_number() {
    return this.form.get('vat_number');
  }

  get legal_address() {
    return this.form.get('legal_address');
  }

  get email() {
    return this.form.get('email');
  }

  get phone() {
    return this.form.get('phone');
  }

  get imageControl() {
    return this.form.get('image');
  }

  ngOnDestroy(): void {
    this._destroy.next(null);
    this._destroy.complete();
  }
}
