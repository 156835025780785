import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private numberPIpe: DecimalPipe) {
  }

  transform(value: number|string): string {

    if (!value && value !== 0) { return null; }
    const result = '\\d(?=(\\d{3})\\D)';
    if (!isFloat(value)) {
      return this.numberPIpe.transform(+value).replace(',', '.');
    }
    return String(value).replace('.', ',').replace(new RegExp(result, 'g'), '$&' + '.');
  }
}

function isFloat(n: number | string): boolean {
  return String(n).includes('.');
}
