import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { get } from 'lodash';

import { HintTypeEnum } from '../../../hrm/models/hints-list.model';
import { purchasesOrderHints } from '../../purchases-order-hints.config';
import {
  BaseMegaHintComponentDependences,
  BaseMegaHintsComponent
} from 'common/src/modules/rnpl-common/components/base-mega-hints/base-mega-hints.component';
import { PoTabs } from '../../enums';
import { UIStatesEnum } from 'common/src/models';
import { selectPurchaseOrder } from '../../store/selectors';
import { AppState } from '../../../store/state/app.state';

@Component({
  selector: 'rnpl-purchase-order-mega-hint',
  templateUrl: './purchase-order-mega-hint.component.html',
  styleUrls: ['./purchase-order-mega-hint.component.scss']
})
export class PurchaseOrderMegaHintComponent extends BaseMegaHintsComponent implements OnInit {
  @Input() public screenName: string;
  @Input() public currentState: UIStatesEnum;
  @Input() public activeStatus: PoTabs;
  @Input() public hasPo: boolean;
  @Input() public displayProductReceiptBtnHint: boolean;
  @Input() public hideDeliveryAction: boolean;
  @Input() public hideBillingAction: boolean;

  public displayFieldValidHint: boolean;
  public displayBatchesHint: boolean;
  public displayInvoiceHint: boolean;
  public displayVatIndictaor: boolean;

  constructor(
    public deps: BaseMegaHintComponentDependences,
    private readonly store: Store<AppState>,
  ) {
    super(deps);
  }

  ngOnInit() {
    this.endFinishText = 'APP.PURCHASE_ORDERS';
    this.megaHintTimer = 800;
    this.displayHints();
    this.keepPoChanges()
  }

  private initHints(): void {
    this.hintsList = [];
    this.notViewedHintsList = [];

    this.hrmService.getHintsByPage(this.screenName)
      .pipe(takeUntil(this._destroy))
      .subscribe(hints => {

        if (!!hints.data.hints && !!hints.data.hints.length) {
          this.hintListByName = purchasesOrderHints(
            this.currentState,
            this.activeStatus,
            this.hasPo,
            this.displayFieldValidHint,
            this.displayBatchesHint,
            this.displayInvoiceHint,
            this.displayProductReceiptBtnHint,
            this.hideDeliveryAction,
            this.hideBillingAction,
            this.displayVatIndictaor,
          );

          this.hintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.notViewedHintsList = this.prepareHintsList(hints.data.hints)
            .filter(hint => hint.type !== HintTypeEnum.MEGA)
            .filter(hint => !hint.viewed)
            .filter(hint => !!this.hintListByName[hint.name]);

          this.megaHintId = +hints.data.hints[0].id;

          if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
            this.addHintsSteps(this.notViewedHintsList);
          }

          if (!!hints.data.hints && !!hints.data.hints.length && !hints.data.hints[0].viewed && hints.data.hints[0].type === HintTypeEnum.MEGA) {
            this.isCloseHint$.next(false);
            this.startTimerMegaHint();
          } else {
            if (!!this.notViewedHintsList && !!this.notViewedHintsList.length) {
              this.shepherdService.start();
            }
          }
        }
      })
  }

  private keepPoChanges(): void {
    this.store.select(selectPurchaseOrder)
      .pipe(takeUntil(this._destroy))
      .subscribe(response => {
        this.displayFieldValidHint = !get(response, 'properties.company.id');
        this.displayVatIndictaor = !get(response, 'properties.company.noVat');
        this.displayBatchesHint = !!get(response, 'properties.batches', []).length;
        this.displayInvoiceHint = get(response, 'linkedDocuments', []).some(doc => doc.type === 'incoming_invoice' && doc.status !== 'canceled' && doc.status !== 'deleted');

        clearInterval(this.countdownTimer);
        if (this.shepherdService.isActive) {
          this.shepherdService.cancel();
        }

        this.initHints();

      });
  }

}
