import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AccordionItemDefinitionModel } from './accordion-item-definition.model';

@Component({
  selector: 'rnpl-toggle-accordion',
  templateUrl: './toggle-accordion.component.html',
  styleUrls: ['./toggle-accordion.component.scss']
})
export class ToggleAccordionComponent implements OnInit {

  @Input()
  public items: Array<AccordionItemDefinitionModel>;

  @Input()
  label: string;

  @Input()
  icon: string;

  @Input()
  public expanded: boolean = false;

  @Output()
  itemToggled: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Open/close toggle-accordion
   */
  public toggleAccordion(): void {
    this.expanded = !this.expanded;
  }

  public toggle(key, event) {
    this.itemToggled.emit({key, checked: event.target.checked});
  }
}
