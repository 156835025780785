import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'rnpl-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {

  @Input() setupErrors: HttpErrorResponse;

  @Output() backToSetupEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() retryFinishEmit: EventEmitter<any> = new EventEmitter<any>();

  backToSetupClick(): void { this.backToSetupEmit.emit(); }
  retryFinishClick(): void { this.retryFinishEmit.emit(); }

}
