import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { ResponseModel } from '../../../shared/models/response';
import { DisplayToaster } from '../../../shared/decorators/toaster';
import { FormInputChangedModel } from '../../../shared/models';
import { DeliveryServiceModel, WarehouseSettingsModel } from '../models';
import { DeliveryServiceEnum } from '../../../shared/enums';
import { AdministrationsApiService } from '../../../administration/services/administrations-api.service';
import { selectCompanyProfile } from '../../../administration/store/selectors';
import { CompanyProfile } from '../../../administration/models/company-profile.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/state/app.state';


@Injectable({
  providedIn: 'root'
})
export class WarehouseSettingsService {
  public companyProfile: CompanyProfile;
  private readonly apiEndpoint: string = `/warehouse/settings`;


  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly toasterService: ToasterService,
    private readonly administrationsApiService: AdministrationsApiService,
    private readonly http: HttpClient,
  ) {
    this.store.select(selectCompanyProfile)
      .subscribe((profile: CompanyProfile) => {
        this.companyProfile = profile;
      });
  }

  public getDeliveryServices(): Observable<DeliveryServiceModel[]> {
    return this.http.get<ResponseModel<DeliveryServiceModel[]>>(this.apiUrl('/delivery-services'))
      .pipe(
        map((settings: ResponseModel<DeliveryServiceModel[]>) => {
          return [
            // move USER_DEFINED item to last array index
            ...settings.data.filter(({name}) => name !== DeliveryServiceEnum.USER_DEFINED),
            ...settings.data.filter(({name}) => name === DeliveryServiceEnum.USER_DEFINED)
          ];
        })
      );
  }

  public getWarehouseSettings(): Observable<WarehouseSettingsModel> {
    return this.http.get<ResponseModel<WarehouseSettingsModel>>(this.apiUrl())
      .pipe(map((settings: ResponseModel<WarehouseSettingsModel>) => settings.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public updateWarehouseSettings(field: FormInputChangedModel): Observable<WarehouseSettingsModel> {
    return this.http.patch<ResponseModel<WarehouseSettingsModel>>(this.apiUrl(), field)
      .pipe(map((settings: ResponseModel<WarehouseSettingsModel>) => settings.data));
  }

  public updatePostalService(service: 'post'|'dhl'|'gls'|'ups', settings: WarehouseSettingsModel): Observable<WarehouseSettingsModel> {
    return this.http.patch<ResponseModel<WarehouseSettingsModel>>(`/warehouse/${service}/settings`, settings)
      .pipe(
        tap(() => {
          if (!this.companyProfile.onboardingCompleted) {
            this.administrationsApiService.getOnboardingProcess().subscribe();
          }
        }),
        map((settings: ResponseModel<WarehouseSettingsModel>) => settings.data)
      );
  }

  public disconnectPostalService(service: 'post'|'dhl'|'gls'|'ups'): Observable<WarehouseSettingsModel> {
    return this.http.request<ResponseModel<WarehouseSettingsModel>>('post', `/warehouse/${service}/settings/disconnect`)
      .pipe(map((settings: ResponseModel<WarehouseSettingsModel>) => settings.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

}
