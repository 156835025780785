export enum EcoStaticPageTypeEnum {
  CONTACT_US = 'CONTACT_US',
  HELP = 'HELP',
  LEGAL_NOTICE = 'LEGAL_NOTICE',
  ABOUT = 'ABOUT',
  DELIVERY = 'DELIVERY',
  PAYMENT = 'PAYMENT',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  RETURNS = 'RETURNS',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
}
