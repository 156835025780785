import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsToggleSummaryComponent } from './documents-toggle-summary.component';

@NgModule({
  declarations: [DocumentsToggleSummaryComponent],
  exports: [
    DocumentsToggleSummaryComponent
  ],
  imports: [
    CommonModule
  ]
})
export class DocumentsToggleSummaryModule { }
