import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { SoundService } from '../../../../services/sound.service';
import { CommonModalsActionsEnum } from '../../modals-common';

export interface DataType {
  isMultiple: boolean;
  canGenerate: boolean;
}

@Component({
  selector: 'rnpl-generate-pick-list-error',
  templateUrl: './generate-pick-list-error-modal.component.html'
})
export class GeneratePickListErrorModalComponent implements OnInit {

  constructor(
    private soundService: SoundService,
    public dialogRef: MatDialogRef<GeneratePickListErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataType
  ) {

  }

  ngOnInit(): void {
    this.soundService.playSound('modal_warning');
  }

  public closeAction(): void {
    if (this.data.canGenerate) {
      this.dialogRef.close(CommonModalsActionsEnum.CONFIRM);
    } else {
      this.dialogRef.close();
    }
  }

}

