import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ArbitraryPositionTypeControlComponent } from './arbitrary-position-type-control.component';

@NgModule({
  declarations: [
    ArbitraryPositionTypeControlComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [ArbitraryPositionTypeControlComponent],
  entryComponents: [ArbitraryPositionTypeControlComponent],
})
export class ArbitraryPositionTypeControlModule { }
