import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {  ProductsService } from '../../../../products';
import { VAT_LIST } from 'projects/workspace/src/app/shared/constants/vat-list';
import { ChangesStrategy } from 'projects/workspace/src/app/shared/enums/change-strategy.enum';
import { TrackInputChanges } from 'projects/workspace/src/app/shared/decorators/track-input-changes';
import { ExchangeApiService } from 'projects/workspace/src/app/exchange/services/exchange-api.service';
import { ExchangePositionModel } from 'projects/workspace/src/app/exchange/models';
import { TradePricesApiService } from 'projects/workspace/src/app/trade-prices/services/trade-prices-api.service';
import { MetricConversionPipe } from '../../../../rnpl-common';

@Component({
  selector: 'rnpl-exchange-add-product-prices',
  templateUrl: './exchange-add-product-prices.component.html',
})
export class ExchangeAddProductPricesComponent implements OnChanges, OnDestroy {
  @Input() quantity: number = 0;

  @Input() set vat(value: number) {
    this.vatControl.setValue(value);
  }

  @Input() set netUnitPrice(value: number) {
    this.netUnitPriceControl.setValue((value || value === 0) ? +(value / 1e2).toFixed(0) : null);
  }

  @Input() set position(position: ExchangePositionModel) {
    if (position) {
      if (position.vat) {
        this.vatControl.setValue(position.vat);
      }
      if (position.netUnitPrice) {
        this.netUnitPriceControl.setValue(
          (position.netUnitPrice || position.netUnitPrice === 0) ? +(position.netUnitPrice / 1e2).toFixed(0) : null
        );
      }
      if (position.grossUnitPrice) {
        this.grossUnitPriceControl.setValue(
          (position.grossUnitPrice || position.grossUnitPrice === 0) ? +(position.grossUnitPrice / 1e2).toFixed(0) : null
        );
      }
      if (position.netTotalPrice) {
        this.netTotalPriceControl.setValue(
          (position.netTotalPrice || position.netTotalPrice === 0) ? +(position.netTotalPrice / 1e2).toFixed(0) : null
        );
      }
      if (position.grossTotalPrice) {
        this.grossTotalPriceControl.setValue(
          (position.grossTotalPrice || position.grossTotalPrice === 0) ? +(position.grossTotalPrice / 1e2).toFixed(0) : null
        );
      }
    }
  }

  @Output() priceFormUpdated: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup = this.fb.group({
    netUnitPrice: [],
    vat: [],
    grossUnitPrice: [],
    netTotalPrice: [],
    grossTotalPrice: [],
  });

  public localCurrency: string = 'EUR';
  public readonly vatList = VAT_LIST;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @TrackInputChanges<string | number>('quantity', 'quantityFilled', ChangesStrategy.Each)
  ngOnChanges(changes: SimpleChanges): void { }

  constructor(
    public exchangeApiService: ExchangeApiService,
    public productsService: ProductsService,
    public pricesApiService: TradePricesApiService,
    public metricPipe: MetricConversionPipe,
    public fb: FormBuilder
  ) {
    this.netUnitPriceControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.netUnitPriceFilled(value));

    this.vatControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.vatFilled(value));

    this.grossUnitPriceControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.grossUnitPriceFilled(value));

    this.netTotalPriceControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.netTotalPriceFilled(value));

    this.grossTotalPriceControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.grossTotalPriceFilled(value));

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.outputFormValue());
  }

  public netUnitPriceFilled(netUnitPrice: number): void {
    if (!netUnitPrice && netUnitPrice !== 0) {
      this.grossUnitPriceControl.setValue(null, {emitEvent: false});
      this.netTotalPriceControl.setValue(null, {emitEvent: false});
      this.grossTotalPriceControl.setValue(null, {emitEvent: false});
      return;
    }

    if (this.quantity) {
      this.netTotalPriceControl.setValue(netUnitPrice * this.quantity, {emitEvent: false});
    }

    if (this.vatMultiplier || this.vatMultiplier === 0) {
      this.grossUnitPriceControl.setValue(+(netUnitPrice * this.vatMultiplier).toFixed(2), {emitEvent: false});
    }

    if (this.quantity && (this.vatMultiplier || this.vatMultiplier === 0)) {
      this.grossTotalPriceControl.setValue(+(netUnitPrice * this.quantity * this.vatMultiplier).toFixed(2), {emitEvent: false});
    }
  }

  public netTotalPriceFilled(netTotalPrice: number): void {
    if (!netTotalPrice && netTotalPrice !== 0) { return; }

    if (this.quantity) {
      this.netUnitPriceControl.setValue(+(netTotalPrice / this.quantity).toFixed(2), {emitEvent: false});
    }

    if (this.vatMultiplier || this.vatMultiplier === 0) {
      this.grossTotalPriceControl.setValue(+(netTotalPrice * this.vatMultiplier).toFixed(2), {emitEvent: false});
    }

    if (this.quantity && this.vatMultiplier) {
      this.grossUnitPriceControl.setValue(
        +(this.netUnitPriceControl.value * this.vatMultiplier).toFixed(2), {emitEvent: false}
      );
    }
  }

  public grossUnitPriceFilled(gross: number): void {
    if (!gross && gross !== 0) { return; }

    if (this.quantity) {
      this.grossTotalPriceControl.setValue(+(this.grossUnitPriceControl.value * this.quantity).toFixed(2), {emitEvent: false});
    }

    if (this.vatMultiplier || this.vatMultiplier === 0) {
      this.netUnitPriceControl.setValue(+(this.grossUnitPriceControl.value / this.vatMultiplier).toFixed(2), {emitEvent: false});

      if (this.quantity) {
        this.netTotalPriceControl.setValue(
          +(this.grossUnitPriceControl.value * (this.quantity / this.vatMultiplier)).toFixed(2), {emitEvent: false}
        );
      }
    }
  }

  public grossTotalPriceFilled(grossTotalPrice: number): void {
    if (!grossTotalPrice && grossTotalPrice !== 0) { return; }

    if (this.quantity) {
      this.grossUnitPriceControl.setValue(+(this.grossTotalPriceControl.value / this.quantity).toFixed(2), {emitEvent: false});
    }

    if (this.vatMultiplier || this.vatMultiplier === 0) {
      this.netTotalPriceControl.setValue(+(this.grossTotalPriceControl.value / this.vatMultiplier).toFixed(2), {emitEvent: false});
    }

    if (this.quantity && (this.vatMultiplier || this.vatMultiplier === 0)) {
      this.netUnitPriceControl.setValue(
        +(this.grossTotalPriceControl.value / this.quantity / this.vatMultiplier).toFixed(2),
        {emitEvent: false}
      );
    }
  }

  public vatFilled(vat: number): void {
    if (!vat && vat !== 0) { return; }

    if (this.vatMultiplier && this.netUnitPriceControl.value) {
      this.grossUnitPriceControl.setValue(+(this.netUnitPriceControl.value * this.vatMultiplier).toFixed(2), {emitEvent: false});
    }

    if (this.quantity && this.grossUnitPriceControl.value) {
      this.grossTotalPriceControl.setValue(+(this.grossUnitPriceControl.value * this.quantity).toFixed(2), {emitEvent: false});
    }
  }

  public quantityFilled(): void {
    if (!this.vatMultiplier && this.vatMultiplier !== 0) { return; }

    if (this.quantity) {
      if (this.netUnitPriceControl.value) {
        this.netTotalPriceControl.setValue(+(this.netUnitPriceControl.value * this.quantity).toFixed(2), {emitEvent: false});
      }

      if (this.grossUnitPriceControl.value) {
        this.grossTotalPriceControl.setValue(+(this.grossUnitPriceControl.value * this.quantity).toFixed(2), {emitEvent: false});
      }
    }

    if (this.quantity === 0) {
      this.netTotalPriceControl.setValue(null, {emitEvent: false});
      this.grossTotalPriceControl.setValue(null, {emitEvent: false});
    }

    this.outputFormValue();
  }

  private toInteger(value: number | string): number {
    if (value === 0) { return value; }
    if (!value) { return; }
    return +this.metricPipe.transform(+value, 'TO-INTEGER').toFixed(0);
  }

  public outputFormValue(): void {
    const formValue = this.form.getRawValue();
    const convertedFormValue = {
      netUnitPrice: formValue.netUnitPrice ? this.toInteger(formValue.netUnitPrice) : null,
      netTotalPrice: formValue.netTotalPrice ? this.toInteger(formValue.netTotalPrice) : null,
      grossUnitPrice: formValue.grossUnitPrice ? this.toInteger(formValue.grossUnitPrice) : null,
      grossTotalPrice: formValue.grossTotalPrice ? this.toInteger(formValue.grossTotalPrice) : null,
      vat: formValue.vat,
    };

    this.priceFormUpdated.emit(convertedFormValue);
  }

  get vatMultiplier(): number {
    if (this.vatControl.value === 0) { return 1; }
    return this.vatControl.value ? this.vatControl.value / 100 + 1 : null;
  }

  get netUnitPriceControl(): FormControl { return this.form.get('netUnitPrice') as FormControl; }
  get netTotalPriceControl(): FormControl { return this.form.get('netTotalPrice') as FormControl; }
  get vatControl(): FormControl { return this.form.get('vat') as FormControl; }
  get grossUnitPriceControl(): FormControl { return this.form.get('grossUnitPrice') as FormControl; }
  get grossTotalPriceControl(): FormControl { return this.form.get('grossTotalPrice') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}

