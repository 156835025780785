import { NavigationItemModel } from 'common/src/models/navigation-item.model';

export const MODULE_MENU_ITEMS: NavigationItemModel = {
  title: 'Entities management',
  icon: 'settings',
  children: [
    {
      title: 'Users',
      icon: 'people',
      children: [
        {
          title: 'Employees',
          children: [
            {
              title: 'Attributes',
              routerLink: 'user/employee/attributes/1',
            }, {
              title: 'Form setup',
              // routerLink: 'user/employee/form-composer/form-layout',
              routerLink: 'user/employee/form-composer-view',
            }
          ]
        }, {
          title: 'Partners',
          children: [
            {
              title: 'Attributes',
              routerLink: 'user/client/attributes/1',
            }, {
              title: 'Form setup',
              // routerLink: 'user/client/form-composer/form-layout',
              routerLink: 'user/client/form-composer-view',
            }
          ]
        }
      ]
    }, {
      title: 'Products',
      icon: 'box',
      children: [
        {
          title: 'General goods',
          children: [
            {
              title: 'Attributes',
              routerLink: 'product/general-goods/attributes/1',
            }
          ]
        }
      ]
    }, {
      title: 'Controls',
      icon: 'options-2',
      routerLink: 'controls-library'
    }
  ]
};
