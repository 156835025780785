import { Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';


import { WizardStep } from 'common/src/modules/wizard/wizard-step';
import { RelationHelpers } from '../../helpers';
import { FormTooltipComponent } from 'common/src/modules/ui-components/form-tooltip/form-tooltip.component';
import { AttributeWizardComponent } from '../../attribute-wizard.component';


@Component({
  selector: 'rnpl-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent extends WizardStep implements OnInit {

  @Input()
  public entities: any;

  public formLists: Array<any> = [];

  @ViewChild('formsHint', {static: false})
  private formsHint: FormTooltipComponent;

  constructor(@Optional() public wizard: AttributeWizardComponent) {
    super();
  }

  ngOnInit(): void {

    Object.keys(this.entities)
      .filter(entityType => this.form.get('entityType').value.includes(entityType))
      .forEach(entityType => {

        const accordionDef = Object.keys(this.entities[entityType])
          .filter(entity => this.form.get('entities').value.includes(entity))
          .map(entity => {

            // todo: move this to helper
            const keyItems = entity.split('_');
            let typeLabel = keyItems.shift();
            typeLabel = typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1) + 's';
            let entityLabel = keyItems.join(' ');
            entityLabel = entityLabel.charAt(0).toUpperCase() + entityLabel.slice(1);

            const listLabel = `${typeLabel} / ${entityLabel}`;

            const forms = this.entities[entityType][entity]
              /**
               * Two level product filter
               * Needed for filter out general product and product unit
               */
              .filter(entity => !(entity === 'product_products_create' || entity === 'product_digital_products_create'))
              /**
               * Two level product filter
               * @END
               */
              .map((form) => {
                return {
                  key: form,
                  label: RelationHelpers.formKeyToLabel(form),
                  checked: !!this.form.get('forms').value.includes(form)
                };
              });

            let icon;
            switch (entityType) {
              case 'product':
                icon = 'box';
                break;
              case 'user':
                icon = 'people';
                break;

              default:
                icon = 'list';
            }

            return {
              key: entity,
              label: listLabel,
              icon: icon,
              forms: forms
            };
          });

        this.formLists = [...this.formLists, ...accordionDef];
      });

    if (this.form.get('forms').value.length) {
      this.setProgress();
    }

    this.form.get('forms').valueChanges
      .pipe(take(1))
      .subscribe(() => {
        this.setProgress();
        if (this.formsHint) {
          this.formsHint.hide();
        }
      });
  }

  public setForm(event) {
    let forms = this.form.get('forms').value;

    if (event.checked) {
      if (!forms.includes(event.key)) {
        forms.push(event.key);
      }
    } else {
      forms = forms.filter(form => form !== event.key);
    }
    this.form.get('forms').setValue(forms);
  }

  private setProgress(): void {
    if (!this.wizard.form.relations.get('entityType').value.includes('product')) {
      this.wizard.progress = this.wizard.stepProgresses.family.finish;
    } else {
      this.wizard.progress = this.progress.finish;
    }
  }

}
