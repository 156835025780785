import {
  ControlTypeEnum,
  InputDropdownTypeEnum,
  PositionInfoListModel,
  PositionItemTypeEnum
} from '../../../position-card/position-card-models.model';

export function generateCardsInfo(currency: string): PositionInfoListModel[] {
  return [
    {
      label: 'COLUMN.AMOUNT',
      prop: 'amount',
      suffix: currency,
      // suffix: '€'
      type: PositionItemTypeEnum.CURRENCY,
      currencySourceFormat: true,
    },
    {
      label: 'TABLE_SUMMARY_BAR.OPEN_AMOUNT',
      prop: 'amountOpen',
      // suffix: '€'
      suffix: currency,
      type: PositionItemTypeEnum.CURRENCY,
      currencySourceFormat: true,
    },
    {
      label: 'COLUMN.ALLOCATED_AMOUNT',
      prop: 'paidAmount',
      // suffix: '€'
      suffix: currency,
      type: PositionItemTypeEnum.WITH_CONTROL,
      controlType: ControlTypeEnum.INPUT_DROPDOWN,
      inputDropdownType: InputDropdownTypeEnum.CURRENCY,
    },
  ];
}
