import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, finalize, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
// import { Store } from '@ngrx/store';
import { get } from 'lodash';

// import { CountryModel } from '../../../rnpl-common';
import { BankAccountModel, BankModel, InstitutionModel } from 'projects/workspace/src/app/bank-accounts/models';
// import { selectCountries } from 'projects/workspace/src/app/store/selectors/shared.selectors';
// import { AppState } from 'projects/workspace/src/app/store/state/app.state';
import { BankAccountsApiService } from 'projects/workspace/src/app/bank-accounts/services/bank-accounts-api.service';
import { CurrencyModel } from 'projects/workspace/src/app/payment/models/currency.model';
import { PaymentCardTypeEnum } from '../../../../models';
import { OpenBankingApiService } from 'projects/workspace/src/app/payment/services/open-banking-api.service';

@Component({
  selector: 'rnpl-bank-account',
  templateUrl: './bank-account.component.html',
})
export class BankAccountComponent implements OnInit, OnDestroy {

  // public countries: CountryModel[] = [];
  public banks: InstitutionModel[] = [];
  public form: FormGroup;
  public bankAccount: BankAccountModel;
  private subscriptions: Subscription[] = [];
  public currencies: CurrencyModel[] = [];

  public paymentCardTypeEnum = PaymentCardTypeEnum;
  // private availableCountriesIso3Codes: string[] = [
  //   'aut', 'deu', 'bel', 'bgr', 'hrv', 'est', 'cyp', 'cze', 'dnk', 'fin', 'fra', 'grc', 'hun', 'isl', 'irl', 'ita', 'lva',
  //   'lie', 'ltu', 'lux', 'mlt', 'mco', 'nld', 'nor', 'pol', 'prt', 'rou', 'svk', 'svn', 'esp',  'swe', 'che', 'gbr', 'usa',
  // ];

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly showDropdownSpin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    // private store: Store<AppState>,
    private fb: FormBuilder,
    private bankAccountsApiService: BankAccountsApiService,
    private openBankingApiService: OpenBankingApiService,
  ) { }

  ngOnInit() {
    // this.store.select(selectCountries)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((countries: CountryModel[]) => {
    //     this.countries = countries
    //       .filter(country => this.availableCountriesIso3Codes.includes(country.code_iso3));
    //   });

    this.getCurrencies();
    this.initForm();
    this.getPrimaryBankAccount();
    this.getInstitutions();
  }

  private getPrimaryBankAccount(): void {
    this.bankAccountsApiService.getBankAccountPrimary()
      .pipe(takeUntil(this.destroy$))
      .subscribe((bankAccount: BankAccountModel) => {
        this.bankAccount = bankAccount;
        this.form.patchValue(bankAccount, {emitEvent: false});
        // this.countryControl.patchValue(bankAccount.country.toLowerCase(), {emitEvent: false});
        // if (get(this, 'bankAccount.userDefinedBank')) {
        //   this.bankControl.patchValue({}, {emitEvent: false});
        // }
      });
  }

  public initForm(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    // const country = (get(this, 'bankAccount.country') || 'aut').toLowerCase();

    this.form = this.fb.group({
      accountName: [get(this, 'bankAccount.accountName')],
      institution: [get(this, 'bankAccount.institution'), [Validators.required]],
      // country: [country],
      currencyCode: [{value: get(this, 'bankAccount.currencyCode', 'EUR'), disabled: true}],
      iban: [get(this, 'bankAccount.iban'), [Validators.required]],
      bic: [get(this, 'bankAccount.bic'), [Validators.required]],
      primaryAccount: [get(this, 'bankAccount.primaryAccount', false)],
      // userDefinedBank: [get(this, 'bankAccount.userDefinedBank', false)],
      // bankDescription: [get(this, 'bankAccount.bankDescription')],
      card: this.fb.group({
        type: [get(this, 'bankAccount.card.type', PaymentCardTypeEnum.DEBIT_CARD)],
      })
    });

    // if (get(this, 'bankAccount.userDefinedBank')) {
    //   this.bankControl.patchValue({}, {emitEvent: false});
    // }

    // this.getBanksByCountry(country);

    // const countrySubscription$ = this.countryControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((iso3: string) => {
    //     if (!this.bankAccount) {
    //       this.bankControl.reset();
    //       // this.userDefinedBankControl.patchValue(false);
    //       // this.bankDescriptionControl.patchValue(null);
    //       this.getBanksByCountry(iso3);
    //     }
    //   });

    // const bankSubscription$ = this.bankControl.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((bank: BankModel) => {
    //     if ((bank && !this.bankAccount) || (bank && bank.id)) {
    //       this.userDefinedBankControl.patchValue(false);
    //       this.bankDescriptionControl.patchValue(null);
    //       this.bankDescriptionControl.clearValidators();
    //       this.bankDescriptionControl.updateValueAndValidity();
    //     }
    //   });

    const ibanControlSubscription = this.ibanControl.valueChanges
      .pipe(
        filter(() => !this.bankAccount),
        startWith(this.ibanControl.value),
        pairwise(),
        takeUntil(this.destroy$)
      )
      .subscribe(([prevIban, nextIban]: [any, any]) => {
        if (!this.accountNameControl.value || this.accountNameControl.value === prevIban) {
          this.accountNameControl.patchValue(nextIban);
        }
      });

    // this.subscriptions.push(countrySubscription$);
    // this.subscriptions.push(bankSubscription$);
    this.subscriptions.push(ibanControlSubscription);
  }

  // public getBanksByCountry(codeIso3: string): void {
    // this.bankAccountsApiService.getBanksByCountry(codeIso3)
    //   .pipe(
    //     finalize(() => this.showDropdownSpin$.next(false)),
    //     takeUntil(this.destroy$)
    //   )
    //   .subscribe((banks: BankModel[]) => {
    //     this.banks = banks;
    //     if (this.banks.length === 1 && !this.bankAccount) {
    //       this.bankControl.patchValue(this.banks[0]);
    //     }
    //   });
  // }

  public getInstitutions(): void {
    this.openBankingApiService.getInstitutions()
      .pipe(
        finalize(() => this.showDropdownSpin$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((institutions: InstitutionModel[]) => {
        this.banks = institutions;
        if (this.banks.length === 1 && !this.bankAccount) {
          this.institutionControl.patchValue(this.banks[0]);
        }
      });
  }

  public updateStep$(): Observable<boolean | BankAccountModel> {
    if (!this.getStepValidation) {
      return of(false);
    }

    const bankAccount: BankAccountModel = {
      ...this.bankAccount,
      ...this.form.getRawValue(),
      // bank: this.userDefinedBankControl.value && (!this.bankAccount || !this.bankAccount.id)
      //   ? null
      //   : this.bankControl.value,
      primaryAccount: true,
      accountName: this.ibanControl.value
    };

    return this.bankAccount && this.bankAccount.id
      ? this.bankAccountsApiService.updateInitialSetupBankAccount(bankAccount)
      : this.bankAccountsApiService.createBankAccount(bankAccount);
  }

  public getStepValidation(): boolean {
    this.form.markAsDirty();
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();

    return this.form.valid;
  }

  // public setUserDefinedBank(): void {
  //   this.bankControl.patchValue({});
  //   this.userDefinedBankControl.patchValue(true);
  //   this.bankDescriptionControl.setValidators(Validators.required);
  //   this.bankDescriptionControl.updateValueAndValidity();
  // }

  public getCurrencies(): void {
    this.bankAccountsApiService.getCurrencies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((currencies: CurrencyModel[]) => this.currencies = currencies);
  }

  public updateBank(bic: string): void {
    this.bicControl.patchValue(bic);
  }

  get accountNameControl(): FormControl { return this.form.get('accountName') as FormControl; }
  get institutionControl(): FormControl { return this.form.get('institution') as FormControl; }
  // get countryControl(): FormControl { return this.form.get('country') as FormControl; }
  get currencyCodeControl(): FormControl { return this.form.get('currencyCode') as FormControl; }
  get ibanControl(): FormControl { return this.form.get('iban') as FormControl; }
  get bicControl(): FormControl { return this.form.get('bic') as FormControl; }
  // get userDefinedBankControl(): FormControl { return this.form.get('userDefinedBank') as FormControl; }
  // get bankDescriptionControl(): FormControl { return this.form.get('bankDescription') as FormControl; }
  get cardTypeControl(): FormControl { return this.form.get('card').get('type') as FormControl; }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
