import { StartScreenModel } from './launchpad.model';
import { isEqual, uniqWith } from 'lodash';

export function filerListByUniqElements(startScreenList: StartScreenModel[]): StartScreenModel[] {
  const filteredList = startScreenList.map(screen => {
    return {
      ...screen,
      items: uniqWith(screen.items, isEqual)
    };
  });

  return filteredList;
}
