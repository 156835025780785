import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

import { RnplCommonModule } from 'common/src/modules/rnpl-common';

import {
  AccountingFormComponent,
  PartnerContactFormComponent,
  WarehouseFormComponent,
  CorporatePartnerFormSkeletonComponent,
} from './components';
import { CorporatePartnerFormModule } from './components/corporate-partner-form/corporate-partner-form.module';
import { CeoInfoFormModule } from './components/ceo-info-form/ceo-info-form.module';
import { AddressFormModule } from './components/address-form/address-form.module';
import { UIComponentsModule } from 'common/src/modules';


const DECLARATIONS = [
  AccountingFormComponent,
  WarehouseFormComponent,
  PartnerContactFormComponent,
  CorporatePartnerFormSkeletonComponent
];

@NgModule({
  declarations: DECLARATIONS,
  exports: [
    ...DECLARATIONS,
    CorporatePartnerFormModule,
    CeoInfoFormModule,
    AddressFormModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RnplCommonModule,
    NgxMaskModule.forRoot(),
    CorporatePartnerFormModule,
    CeoInfoFormModule,
    AddressFormModule,
    UIComponentsModule,
    TranslateModule
  ]
})
export class PartnerFormsModule {
}
