import { Subscription } from 'rxjs';

const isFunction = (fn: any) => typeof fn === 'function';

const doUnsubscribe = (subscription: Subscription) => {
  subscription && isFunction(subscription.unsubscribe) && subscription.unsubscribe();
};

const doUnsubscribeIfArray = (subscriptionsArray: Subscription[]) => {
  Array.isArray(subscriptionsArray) && subscriptionsArray.forEach(doUnsubscribe);
};

export function AutoUnsubscribe({
  blackList = [],
  arrayName = '',
  event = 'ngOnDestroy'
} = {}) {
  return function (constructor: Function) {
    const original = constructor.prototype[event];

    if (!isFunction(original)) {
      throw new Error(
        `${
        constructor.name
        } is using @AutoUnsubscribe but does not implement ${event}`
      );
    }

    constructor.prototype[event] = function () {
      isFunction(original) && original.apply(this, arguments);

      if (arrayName) {
        doUnsubscribeIfArray(this[arrayName]);
        return;
      }

      for (const propName in this) {
        if (blackList.includes(propName)) { continue; }

        const property = this[propName];
        doUnsubscribe(property);
      }
    };
  };
}

// example usage

// @AutoUnsubscribe()
// @Component()
// export class InboxComponent {
//   one: Subscription;


//   ngOnInit() {
//     this.one = Observable.interval.subscribe(data => // do something);
//   }

//   // This method must be present, even if empty.
//   ngOnDestroy() {
//     // Error will be throw if it doesn't
//   }
// }
