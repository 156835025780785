import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, finalize, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { AppState } from '../../store/state/app.state';
import { FilterModel } from '../../warehouse/models/filter.model';
import { ResponseList, ResponseModel } from '../../shared/models/response';
import { SalesOrderModel, SODeliveryNotes, SalesOrderSearchByIdModel, AvailableSalesOrderModel } from '../models/sales-order.model';
import {
  SalesOrderCountersModel,
  ResponseSalesOrderCountersModel
} from '../models/sales-order-counters.model';
import { SalesOrderForceCompleteActionEnum, SalesOrderListTabsEnum } from '../enums';
import {
  LoadSalesOrder,
  LoadSalesOrderPositions,
  UpdateSalesOrder,
  UpdateSalesOrderCurrentState,
  UpdateSalesOrderPositionsCount,
  IncrementLoadingRequestsCount,
  DecrementLoadingRequestsCount,
  UpdateSalesOrderSummary,
  UpdateSalesOrderUpdatedAt,
  UpdateShouldRefreshEntity,
  LoadSalesOrdersList, ClearSalesOrderState,
} from '../store/actions/sales-order.actions';
import { SalesOrderServicesConfirmModel, SalesOrderServicesToConfirmType, SalesPosition, SalesPositionsByProductType } from '../models/sales-order-position.model';
import { FileUploadParams } from 'common/src/models/file-upload-params.model';
import { FormInputChangedModel } from '../../shared/models/form-input-value.model';
import { PredefinedDocumentDataModel, UIStatesEnum } from 'common/src/models';
import { SummaryInfoModel } from '../models/summary-info-model';
import { DisplayToaster } from '../../shared/decorators/toaster';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { CommonModalsActionsEnum, InfoModalComponent, WarningModalComponent } from 'common/src/modules/modals/modals-common';
import { getAnotherUserEditErrorModalData } from 'common/src/modules/modals/modals-common/common-modal.config';
import { UnableToAddProduct } from '../helpers/handle-popup-errors.helper';
import { SalesOrderRecordPositionModel } from '../models/sales-order-record-position.model';
import { PurchaseOrder } from '../../purchase-order/models';
import { ExchangeListTabsEnum } from '../../exchange/enums';
import { TradeDocumentPositionModel } from '../../trade/models';
import { SalesOrderApiHelperService } from './sales-order-api-helper.service';
import { CreatePoModalComponent } from 'common/src/modules/modals/modals-sales-order/create-po-modal/create-po-modal.component';
import { DEFAULT_SORT_DIRECTION } from '../../shared/constants';
import { DeliveryNoteListTabsEnum } from '../../delivery-note/enums';
import { DocumentTemplateModel } from '../../shared/models';

@Injectable()
export class SalesOrderApiService {
  private readonly apiEndpoint: string = '/sales-orders';

  private apiUrl(url: string = ''): string {
    return this.apiEndpoint + url;
  }

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly salesOrderHelperService: SalesOrderApiHelperService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly store: Store<AppState>,
  ) {}

  createSalesOrderDraft(data: PredefinedDocumentDataModel = {}): Observable<SalesOrderModel> {
    return this.http
      .post<ResponseModel<SalesOrderModel>>(this.apiUrl('/draft'), {...data})
      .pipe(
        map((data: ResponseModel<SalesOrderModel>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public createSalesOrderByPurchaseOrderId(poId: number, positions: {positionId: number, quantity: number}[],): Observable<SalesOrderModel> {
    const body = { positions };
    return this.http.request<ResponseModel<SalesOrderModel>>('post', this.apiUrl(`/by-purchase-order-positions/${poId}`), {body})
      .pipe(
        map((data: ResponseModel<SalesOrderModel>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public unlinkSalesOrderWithPurchaseOrder(soId: number, poId: number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.delete<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${soId}/purchase-order/${poId}`))
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((data: ResponseModel<SalesOrderModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public getPositionsFromSalesOrders(): Observable<TradeDocumentPositionModel[]> {
    return this.http.get<ResponseModel<TradeDocumentPositionModel[]>>(this.apiUrl('/positions/available-for-po'))
      .pipe(
        map((data: ResponseModel<TradeDocumentPositionModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  public getPositionsAvailableForPO(soId: number): Observable<TradeDocumentPositionModel[]> {
    return this.http.get<ResponseModel<TradeDocumentPositionModel[]>>(this.apiUrl(`/${soId}/positions/available-for-po`))
      .pipe(
        map((data: ResponseModel<TradeDocumentPositionModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  getSalesOrderList(
    status: SalesOrderListTabsEnum,
    page: string = '1',
    per_page: string = '100',
    sort: FilterModel = { nameColumn: 'updated_at', value: DEFAULT_SORT_DIRECTION },
    // shop: boolean = false,
    filters: any = {}
  ): Observable<ResponseList<SalesOrderModel>> {
    const params = {
      page,
      per_page,
      [`filters[status]`]: status,
      [`sort[${sort.nameColumn}]`]: sort.value,
      // shop: String(+shop),
    };

    for (const [key, value] of Object.entries(filters)) {
      params[`filters[${key}]`] = Array.isArray(value) ? value.join(',') : value.toString();
    }

    return this.http.get<ResponseList<SalesOrderModel>>(this.apiUrl(), { params })
      .pipe(tap((data: ResponseList<SalesOrderModel>) => {
        this.store.dispatch(LoadSalesOrdersList({
          salesOrderListData: {
            [data.pagination.page]: {
              pagination: data.pagination,
              sort,
              data: data.data
            }
          },
          status, page: data.pagination.page
        }));
      }));
  }

  getSalesOrderListPositions(soId: number): Observable<SalesPosition[]> {
    return this.http.get<ResponseModel<SalesPosition[]>>(this.apiUrl(`/${soId}/list/positions`))
      .pipe(map((data: ResponseModel<SalesPosition[]>) => data.data));
  }

  getSalesOrdersRunpleIds(status: SalesOrderListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl(`/runpleIds`), { params })
      .pipe(map((data: any) => data.data));
  }

  getAvailableToLinkSalesOrders(partnerId: number): Observable<AvailableSalesOrderModel[]> {
    let params = {};

    if (partnerId) {
      params['partnerId'] = partnerId.toString();
    }
    return this.http.get<ResponseModel<AvailableSalesOrderModel[]>>(this.apiUrl('/invoice/available'), { params })
      .pipe(
        map((data: ResponseModel<AvailableSalesOrderModel[]>) => data.data),
      );
  }

  getLinkedToEraSalesOrders(status: ExchangeListTabsEnum): Observable<any> {
    const params = { status };
    return this.http.get<any>(this.apiUrl(`/linked-to-era`), { params })
      .pipe(map((data: any) => data.data));
  }

  getTimeTrackingRecordQuantityValidation(positionId: number, quantity: number): Observable<any> {
    const body = { quantity };
    return this.http.post<ResponseModel<any>>(this.apiUrl(`/positions/${positionId}/time-tracking/quantity/validation`), body)
      .pipe(
        map((data: ResponseModel<any>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  getAvailableToTimeTrackingSalesOrders(recordIds: number[]): Observable<AvailableSalesOrderModel[]> {
    return this.http.get<ResponseModel<AvailableSalesOrderModel[]>>(this.apiUrl('/time-tracking'), {
      params: {
        [`filters[recordIds]`]: recordIds.join(','),
      }
    })
      .pipe(
        map((data: ResponseModel<AvailableSalesOrderModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  unlinkTimeTrackingRecord(salesOrderId: number, recordsIds: number[], skipWarnings = false): Observable<any> {
    const body = {
      ids: recordsIds,
      skipWarnings
    };

    return this.http.post(this.apiUrl(`/${salesOrderId}/time-tracking/unlink`), { ...body })
      .pipe(
        catchError(error => {
          if (error.error.message === 'Records is linked with OIN') {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'Unlink record',
                message: 'A record will be unlinked from an invoice if you unlink a record from a sales order.',
                confirmBtnText: 'Unlink',
                confirmBtnIcon: 'unlink'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.unlinkTimeTrackingRecord(salesOrderId, recordsIds, true)
                  .subscribe(() => {
                    this.getSalesOrderById(salesOrderId).subscribe();
                  });
              }
            });
          } else {
            this.showMsg('error', error.error.message);
          }
          return throwError(error);
        })
      );
  }

  getSalesOrderRecordPositions(salesOrderId: number, recordIds: number[]): Observable<SalesOrderRecordPositionModel[]> {
    return this.http.get<ResponseModel<SalesOrderRecordPositionModel[]>>(this.apiUrl(`/${salesOrderId}/time-tracking/positions`), {
      params: {
        recordIds: recordIds.join(','),
      }
    })
      .pipe(
        map((data: ResponseModel<SalesOrderRecordPositionModel[]>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  assignTimeTrackingRecordsToSO(salesOrderId, updateQuantity: boolean, records: Partial<SalesOrderRecordPositionModel>[], groupingForm: any): Observable<any> {
    const body = {
      updateQuantity,
      records,
      ...groupingForm
    };
    return this.http.post(this.apiUrl(`/${salesOrderId}/time-tracking/assign`), { ...body })
      .pipe(
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  getSalesOrderSummary(
    id: number | string
  ): Observable<ResponseModel<SummaryInfoModel>> {
    return this.http.get<ResponseModel<SummaryInfoModel>>(this.apiUrl(`/${id}/summary`))
      .pipe(
        tap((data: ResponseModel<SummaryInfoModel>) => {this.store.dispatch(UpdateSalesOrderSummary({summary: data.data}))})
      );
  }

  public getSOListExport(title: string, status: string, isShop: boolean): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: this.apiUrl(`/${status}/csv?shop=${String(+isShop)}`),
      type: 'zip',
      title,
    };
    return of(fileParams);
  }

  getSalesOrderCounters(): Observable<SalesOrderCountersModel> {
    return this.http
      .get<ResponseModel<ResponseSalesOrderCountersModel>>(
        this.apiUrl('/counters')
      )
      .pipe(
        map(
          (data: ResponseModel<ResponseSalesOrderCountersModel>) =>
            data.data.orders
        )
      );
  }

  changeSalesOrderStatusBatch(status: SalesOrderListTabsEnum, order_ids: number[]): Observable<any> {
    const body = { status, order_ids };
    return this.http.patch(this.apiUrl('/multiple/statuses'), body);
  }

  changeSalesOrderStatus(
    status: SalesOrderListTabsEnum,
    salesOrderId: number,
    skipPoCreationWarning = false,
    skipProfitWarning = false
  ): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { status, skipPoCreationWarning, skipProfitWarning };
    return this.http
      .patch(this.apiUrl(`/${salesOrderId}/status`), body)
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((data: ResponseModel<SalesOrderModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  salesOrderForceComplete(
    salesOrderId: number,
    action: SalesOrderForceCompleteActionEnum,
    fixDeliveryCosts = false,
    ignoreDeliveryCosts = false,
  ): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { action, fixDeliveryCosts, ignoreDeliveryCosts };
    return this.http.patch(this.apiUrl(`/${salesOrderId}/force-complete`), body)
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((data: ResponseModel<SalesOrderModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, salesOrderId);
          return throwError(error);
        }),
      );
  }

  salesOrderForceCompleteValidation(salesOrderId: number,): Observable<any> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.get(this.apiUrl(`/${salesOrderId}/validation/force-complete`))
      .pipe(
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, salesOrderId);
          return throwError(error);
        })
      );
  }

  getSalesOrderById(id: string | number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http
      .get<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${id}`))
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
          this.store.dispatch(UpdateShouldRefreshEntity({ isShouldRefresh: false }));
          this.store.dispatch(UpdateSalesOrderPositionsCount({ positionsCount: response.data.positionsCount }));
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  updateSalesOrder(salesOrderId: number, field: FormInputChangedModel): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${salesOrderId}`), field)
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, salesOrderId);
          return throwError(error);
        })
      );
  }

  updateDeliveryAddressByCustomer(salesOrderId: number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<SalesOrderModel>>('put', this.apiUrl(`/${salesOrderId}/delivery-address/update-by-customer`))
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, salesOrderId);
          return throwError(error);
        })
      );
  }

  updatePickUpAddressByWarehouse(salesOrderId: number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<SalesOrderModel>>('put', this.apiUrl(`/${salesOrderId}/pickup-address/update-by-warehouse`))
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, salesOrderId);
          return throwError(error);
        })
      );
  }

  updateBillingInfoAddressByCustomer(salesOrderId: number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<SalesOrderModel>>('put', this.apiUrl(`/${salesOrderId}/billing-address/update-by-customer`))
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, salesOrderId);
          return throwError(error);
        })
      );
  }

  createSalesOrder(id: number): Observable<SalesOrderModel> {
    return this.http.request<ResponseModel<SalesOrderModel>>('post', this.apiUrl(`/${id}/accept-create`))
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          // //   this.store.dispatch(UpdateSalesOrderPositions({positions: response.data.sales_positions as SalesPosition[]}));
        }),
        map((data: ResponseModel<SalesOrderModel>) => data.data)
      );
  }

  getSalesOrderPositions(salesOrderId: number): Observable<ResponseModel<SalesPositionsByProductType>> {
    return this.http.get<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/${salesOrderId}/positions`))
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadSalesOrderPositions({positions: response}));
        }),
      );
  }

  createSalesOrderPosition(toId: number, position: SalesPosition): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/${toId}/positions`), position)
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(LoadSalesOrderPositions({positions: response}));
          this.store.dispatch(UpdateSalesOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, toId);
          return throwError(error);
        })
      );
  }

  updateSalesOrderPosition(positionId: number, field: FormInputChangedModel, toId: number): Observable<ResponseModel<SalesPosition>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.patch<ResponseModel<SalesPosition>>(this.apiUrl(`/positions/${positionId}`), field).pipe(
      tap((response: ResponseModel<SalesPosition>) => {
        this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, toId);
        return throwError(error);
      })
    );
  }

  deleteSalesOrderPositions(salesOrderId: number, ids: number[]): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    const body = { ids: ids };
    return this.http.request<ResponseModel<SalesPositionsByProductType>>(
      'delete',
      this.apiUrl(`/${salesOrderId}/positions`),
      {body}
    )
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadSalesOrderPositions({positions: response}));
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateSalesOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +salesOrderId);
          return throwError(error);
        })
      );
  }

  restoreSalesOrderPositions(salesOrderId: number, ids: number[]): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());
    const body = { ids: ids };

    return this.http.patch<ResponseModel<SalesPositionsByProductType>>(this.apiUrl(`/${salesOrderId}/positions/restore`), body)
      .pipe(
        tap((response: ResponseModel<SalesPositionsByProductType>) => {
          this.store.dispatch(LoadSalesOrderPositions({positions: response}));
          this.store.dispatch(UpdateSalesOrderUpdatedAt({updatedAt: new Date()}));
          this.store.dispatch(UpdateSalesOrderPositionsCount({positionsCount: response.totals.totalCount}));
        }),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
        catchError(error => {
          this.handlePopupErrors(error, +salesOrderId);
          return throwError(error);
        })
      );
  }

  changePositionOrder(posId: number, moveToOrder: number): any {
    const body = {
      orderPosition: moveToOrder,
      id: posId,
    }
    return this.http.patch(this.apiUrl(`/positions/${posId}/order/${moveToOrder}`), body)
      .pipe(
        map((data: ResponseModel<PurchaseOrder>) => data.data),
        catchError(error => {
          this.showMsg('error', error.error.message);
          return throwError(error);
        })
      );
  }

  getSalesOrderServicesToConfirm(salesOrderId: number): Observable<SalesOrderServicesToConfirmType> {
    return this.http.get<ResponseModel<SalesOrderServicesToConfirmType>>(this.apiUrl(`/${salesOrderId}/services`))
      .pipe(map((data: ResponseModel<SalesOrderServicesToConfirmType>) => data.data));
  }

  confirmSalesOrderServices(salesOrderId: number, body: SalesOrderServicesConfirmModel): Observable<ResponseModel<SalesPositionsByProductType>> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.request<ResponseModel<SalesPositionsByProductType>>(
      'post',
      this.apiUrl(`/${salesOrderId}/services/confirm`),
      {body}
    ).pipe(
      tap((response: ResponseModel<SalesPositionsByProductType>) => {
        this.store.dispatch(LoadSalesOrderPositions({positions: response}));
      }),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount())),
      catchError(error => {
        this.handlePopupErrors(error, salesOrderId);
        return throwError(error);
      })
    );
  }

  @DisplayToaster({showErrorMessage: true})
  returnSalesOrderService(positionId: number): Observable<SalesPosition> {
    return this.http.request<ResponseModel<SalesPosition>>(
      'post',
      this.apiUrl(`/services/${positionId}/return`)
    ).pipe(map((data: ResponseModel<SalesPosition>) => data.data));
  }

  linkOffer(salesOrderId: string | number, offerId: string | number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${salesOrderId}/trade-offer/${offerId}`), {})
    .pipe(
      map((data: ResponseModel<SalesOrderModel>) => data.data),
      finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
    );
  }

  unlinkOffer(salesOrderId: string | number): Observable<SalesOrderModel> {
    this.store.dispatch(IncrementLoadingRequestsCount());

    return this.http.post<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${salesOrderId}/unlink`), {})
      .pipe(
        map((data: ResponseModel<SalesOrderModel>) => data.data),
        finalize(() => this.store.dispatch(DecrementLoadingRequestsCount()))
      );
  }

  resolveSalesOrderIssue(id: number, issues): Observable<SalesOrderModel> {
    return this.http.patch<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${id}/resolve-issues`), issues)
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
        }),
        map((data: ResponseModel<SalesOrderModel>) => data.data)
      );
  }

  @DisplayToaster({showErrorMessage: true})
  deleteSalesOrdersPermanently(ids: number[]): Observable<ResponseModel<null>> {
    const body = { ids };
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(), {body});
  }

  deleteSalesOrderPermanently(salesOrderId: number): Observable<ResponseModel<null>> {
    return this.http.request<ResponseModel<null>>('delete', this.apiUrl(`/${salesOrderId}`));
  }

  public getSalesOrderExportParams(id: number, title: string): Observable<FileUploadParams> {
    const fileParams: FileUploadParams = {
      url: `accounting/v1/pdf/sales-order/${id}`,
      type: 'pdf',
      title,
    };
    return of(fileParams);
  }

  public cloneSalesOrder(salesOrderId: number): Observable<SalesOrderModel> {
    return this.http.post<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${salesOrderId}/clone`), {})
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
          this.store.dispatch(UpdateSalesOrderPositionsCount({ positionsCount: response.data.positionsCount }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data)
      );
  }

  // @DisplayToaster({showErrorMessage: true})
  // public sendSalesOrderEmail(salesOrderId: number): Observable<SalesOrderModel> {
  //   return this.http.post<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${salesOrderId}/email`), {})
  //     .pipe(
  //       tap((response: ResponseModel<SalesOrderModel>) => {
  //         this.store.dispatch(LoadSalesOrder({ salesOrder: response.data }));
  //       }),
  //       map((response: ResponseModel<SalesOrderModel>) => response.data)
  //     );
  // }

  public salesOrderSetEdit(id: string | number, force = false): Observable<SalesOrderModel> {
    const body = { force };

    return this.http.put<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${id}/locking`), body)
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderCurrentState({ currentState: UIStatesEnum.EDIT }));
          this.store.dispatch(UpdateSalesOrder({ salesOrder: response.data }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, +id);
          return throwError(error);
        })
      );
  }

  public salesOrderUnsetEdit(id: string | number, skipProfitWarning = false, skipPoCreationWarning = false): Observable<SalesOrderModel> {
    const body = { skipProfitWarning, skipPoCreationWarning };
    return this.http.put<ResponseModel<SalesOrderModel>>(this.apiUrl(`/${id}/unlocking`), body)
      .pipe(
        tap((response: ResponseModel<SalesOrderModel>) => {
          this.store.dispatch(UpdateSalesOrderCurrentState({ currentState: UIStatesEnum.VIEW }));
          this.store.dispatch(UpdateSalesOrder({ salesOrder: response.data }));
        }),
        map((response: ResponseModel<SalesOrderModel>) => response.data),
        catchError(error => {
          this.handlePopupErrors(error, +id);
          return throwError(error);
        })
      );
  }

  public getActiveSalesOrders(partnerId: number): Observable<SODeliveryNotes[]> {
    let params = {};

    if (partnerId) {
      params['partnerId'] = partnerId.toString();
    }
    return this.http.get<ResponseModel<SODeliveryNotes[]>>(`${this.apiEndpoint}/delivery/available`, { params })
      .pipe(map((data: ResponseModel<SODeliveryNotes[]>) => data.data));
  }

  public getActiveSalesOrderById(id: string | number): Observable<SalesOrderSearchByIdModel> {
    return this.http.get<ResponseModel<SalesOrderSearchByIdModel>>(`${this.apiEndpoint}/${id}/delivery/available`)
      .pipe(map((data: ResponseModel<SalesOrderSearchByIdModel>) => data.data));
  }

  getSalesOrderLinkedToDeliveryNotes(dnStatus: DeliveryNoteListTabsEnum): Observable<SODeliveryNotes[]> {
    const params = { dnStatus };

    return this.http.get<ResponseModel<SODeliveryNotes[]>>(`${this.apiEndpoint}/delivery/linked`, { params })
      .pipe(
        map((response: ResponseModel<SODeliveryNotes[]>) => response.data)
      );
  }

  getSalesOrderLinkedToOutgoingInvoice(): Observable<SODeliveryNotes[]> {
    return this.http.get<ResponseModel<SODeliveryNotes[]>>(`${this.apiEndpoint}/invoice/filters`)
      .pipe(
        map((response: ResponseModel<SODeliveryNotes[]>) => response.data)
      );
  }

  public getDocumentTemplates(): Observable<DocumentTemplateModel[]> {
    return this.http.get<ResponseModel<DocumentTemplateModel[]>>(this.apiUrl('/templates'))
      .pipe(map((response: ResponseModel<DocumentTemplateModel[]>) => response.data));
  }

  public getDocumentTemplatesNames(): Observable<{ id: number; name: string; }[]> {
    return this.http.get<ResponseModel<{ id: number; name: string; }[]>>(this.apiUrl('/templates/names'))
      .pipe(map((response: ResponseModel<{ id: number; name: string; }[]>) => response.data));
  }

  public saveDocumentTemplate(documentId: number, body: { name: string; overwrite: boolean; resetCounter: boolean; }): Observable<boolean> {
    return this.http.post<ResponseModel<boolean>>(this.apiUrl(`/${documentId}/template`), body)
      .pipe(map((response: ResponseModel<boolean>) => response.data));
  }

  public applyDocumentTemplate(documentId: number, templateId: number, force = false): Observable<any> {
    return this.http.patch<ResponseModel<any>>(this.apiUrl(`/${documentId}/template/${templateId}`), {force})
      .pipe(map((response: ResponseModel<any>) => response.data));
  }

  @DisplayToaster({showErrorMessage: true})
  public createDocumentFromTemplate(templateId: number): Observable<number> {
    return this.http.request<ResponseModel<number>>('post', this.apiUrl(`/template/${templateId}`))
      .pipe(map((response: ResponseModel<number>) => response.data));
  }

  public showMsg(type: string, message: string): void {
    this.toasterService.notify({ type, message });
  }

  private handlePopupErrors(error: HttpErrorResponse, soId?: number): void {
    if (error.error.errors && error.error.errors.length) {
      this.showMsg('error', error.error.errors);
    }
    if (error.error.message) {
      switch (error.error.message) {
        case 'anotherUserEditError':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: getAnotherUserEditErrorModalData(
                {
                  document: error.error.data.entityName,
                  userName: error.error.data.userName,
                },
                this.translateService
              ),
              backdropClass: 'backdrop-none',
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.salesOrderSetEdit(soId, true).subscribe();
              }
            });
          }
          break;
        case 'salesOrderNegativeProfit':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'SO_PO.SELL_WITH_LOSS_TITLE',
                message: 'SO_PO.SELL_WITH_LOSS_MSG',
                confirmBtnText: 'BUTTON.CONTINUE',
                confirmBtnIcon: 'plus-square'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.salesOrderUnsetEdit(soId, true).subscribe();
              }
            });
          }
          break;
        case 'notPaidSoDelivery':
          {
            const dialog = this.dialog.open(WarningModalComponent, {
              data: {
                title: 'DASHBOARDS.LOGISTICS_COSTS',
                message: 'FORCE_COMPLETE_SO.FORCE_COMPLETE_LOGISTIC_MODAL_MSG',
                confirmBtnText: 'FORCE_COMPLETE_SO.FORCE_COMPLETE',
                confirmBtnIcon: 'checkbox-on'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.salesOrderForceComplete(soId, null, false, true).subscribe();
              }
            });
          }
          break;
        case 'editModeLockedForInstantSale':
          {
            this.dialog.open(InfoModalComponent, {
              data: {
                title: 'MODALS.INSTANT_SALE_TITLE',
                message: 'MODALS.INSTANT_SALE_MSG',
                confirmBtnText: 'BUTTON.CLOSE',
                confirmBtnIcon: 'close',
                hideCancelBtn: true,
              }
            });
          }
          break;
        case 'createPoForMissingProductsInStock':
          {
            const dialog = this.dialog.open(InfoModalComponent, {
              data: {
                title: 'SO_PO.PO_PRODUCTS_OUT_OF_STOCK_TITLE',
                message: 'SO_PO.PO_PRODUCTS_OUT_OF_STOCK_MSG',
                confirmBtnText: 'BUTTON.CONTINUE_WITHOUT_ORDER',
                confirmBtnClass: 'btn-primary',
                confirmBtnIcon: 'arrow-right',
                cancelBtnText: 'BUTTON.CREATE_PO',
                cancelBtnClass: 'btn-primary',
                cancelBtnIcon: 'purchase-order'
              }
            });

            dialog.afterClosed().subscribe(res => {
              if (res === CommonModalsActionsEnum.CONFIRM) {
                this.salesOrderUnsetEdit(soId, true, true).subscribe();
              } else if (res === CommonModalsActionsEnum.REJECT) {
                const dialog = this.dialog.open(CreatePoModalComponent, {
                  data: {
                    soId,
                    finishSoEditing: true
                  },
                  disableClose: true,
                  panelClass: 'mat-dialog-container--w-full',
                  maxWidth: '1210px'
                });

                dialog.afterClosed().subscribe((purchaseOrder: PurchaseOrder) => {
                  if (purchaseOrder) {
                    this.router.navigate(['/trade/purchase-order', purchaseOrder.id])
                      .then(() => this.store.dispatch(ClearSalesOrderState()));
                  }
                });
              }
            });
          }
          break;
        case 'notEditModeError':
          const documentName = this.translateService.instant('APP.SALES_ORDER');
          this.showMsg('warning', this.translateService.instant('COMMON.DOC_UPDATED_BY_USER', { document: documentName }));
          this.store.dispatch(UpdateSalesOrderCurrentState({ currentState: UIStatesEnum.VIEW }));
          this.getSalesOrderById(soId).subscribe();
          break;
        case 'Unable to add product':
          this.dialog.open(InfoModalComponent, {
            data: UnableToAddProduct
          });
          break;
        default:
          this.showMsg('error', error.error.message);
          break
      }
    }
  }

}
