import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { get } from 'lodash';

import { SubscriptionActivationPricesModel } from '../subscription-activation-prices.model';
import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { ManageUsersComponent } from '../manage-users/manage-users.component';
import { SubscriptionPricesConfigModel } from 'projects/workspace/src/app/administration/models/subscription-prices-config.model';
import { AdministrationsApiService } from 'projects/workspace/src/app/administration/services/administrations-api.service';
import { SubscriptionPlanEnum } from '../subscription-activation.emum';

@Component({
  selector: 'rnpl-subscription-options',
  templateUrl: './subscription-options.component.html',
  styleUrls: ['./subscription-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionOptionsComponent implements OnDestroy {

  public subscriptionPricesConfig: SubscriptionPricesConfigModel;

  @Input() form: FormGroup;
  @Input() subscriptionPrices: SubscriptionActivationPricesModel;
  @Input() companyProfile: CompanyProfile;
  @Input() displaySubscriptionPlanPrice: boolean;

  @ViewChild('subscriptionPlanForm', {static: false}) public subscriptionPlanForm;

  readonly destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private dialog: MatDialog,
    private crd: ChangeDetectorRef,
    private administrationsApiService: AdministrationsApiService
  ) {
    this.getSubscriptionConfig();
  }


  public manageSubscriptionUsers(): void {
    this.dialog.open(ManageUsersComponent);
  }

  public getSubscriptionConfig(): void {
    this.administrationsApiService.getCompanySubscriptionPricesConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((pricesConfig: SubscriptionPricesConfigModel) => {
        this.subscriptionPricesConfig = pricesConfig;
        this.crd.detectChanges();
      });
  }

  public switchPlan(): void {
    this.subscriptionManagementForm.get('subscriptionPlan').patchValue(SubscriptionPlanEnum.ACCOUNTING_MONTHLY);
    this.subscriptionManagementForm.get('userChannelOptions').get('wholesalesEnabled').patchValue(false);
    this.subscriptionManagementForm.get('userChannelOptions').get('servicesEnabled').patchValue(false);
    this.subscriptionManagementForm.get('userChannelOptions').get('ecommerceEnabled').patchValue(false);
  }

  public planUpgrade(): void {
    this.subscriptionManagementForm.get('subscriptionPlan').patchValue(SubscriptionPlanEnum.TEAM_MONTHLY);
    this.subscriptionManagementForm.get('userChannelOptions').patchValue(this.companyProfile.subscriptionManagement.userChannelOptions);
  }

  get activeUsersTeamPlan(): number {
    if (!this.companyProfile || !this.companyProfile.activeUsers) {
      return 0;
    }
    const users = this.companyProfile.activeUsers;
    return users <= 3 ? 0 : (users - 3);
  }

  get activeUsersAccountingPlan(): number {
    if (!this.companyProfile || !this.companyProfile.activeUsers) {
      return 0;
    }
    const users = this.companyProfile.activeUsers;
    return users <= 1 ? 0 : (users - 1);
  }

  get isAccountingPlan(): boolean {
    return get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.ACCOUNTING_ANNUALLY
      || get(this.companyProfile, 'subscriptionManagement.subscriptionPlan') === SubscriptionPlanEnum.ACCOUNTING_MONTHLY;
  }

  public salesChannelsValidation(): boolean {
    return this.subscriptionPlanForm.salesChannelsValidation();
  }

  get paymentMethodControl() { return this.form.get('billingDetails.paymentMethod') as FormControl; }

  get subscriptionManagementForm(): FormGroup { return this.form.get('subscriptionManagement') as FormGroup; }
  get billingDetailsForm(): FormGroup { return this.form.get('billingDetails') as FormGroup; }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
