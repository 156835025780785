import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function greaterThanZero(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (!value && value !== 0) {
      return null;
    }

    const pattern = /^([0-9]*[1-9][0-9]*(\.[0-9]+)?|[0]+\.[0-9]*[1-9][0-9]*)$/;

    return pattern.test(value)
      ? null
      : {greaterThanZero: true};
  };
}
