import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

import {
  ActionsReorderingPositionModel,
  PositionsIndexModel
} from 'common/src/modules/position-card/position-card-models.model';
import { PositionsActionsType } from 'common/src/modules/ui-components/table/custom-table.enums';

@Component({
  selector: 'rnpl-positions-context-menu',
  templateUrl: './positions-context-menu.component.html',
})
export class PositionsContextMenuComponent {

  public positionsActionsType: typeof PositionsActionsType = PositionsActionsType;
  public positionsIndexes: PositionsIndexModel[] = [];

  @Input('positions') public positions: any[];
  @Input() public skipInfoBtn: boolean;
  @Input() public showShipInfo: boolean = false;
  @Input() public showBillInfo: boolean = false;
  @Input() public isReadOnly: boolean;
  @Input() public deletable: boolean = true;

  @Output() public positionsActionList: EventEmitter<ActionsReorderingPositionModel> = new EventEmitter();

  @ViewChild('contextMenu', {static: false}) public contextMenu: ContextMenuComponent;

  constructor(
    private contextMenuService: ContextMenuService,
  ) {
  }

  public onContextMenu(event: MouseEvent, item: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.getPositionsIndex();

    this.contextMenuService.show.next({
      contextMenu: this.contextMenu,
      event: event,
      item: item,
    });
  }

  public getRowOrderNumber(index: number): string | number {
    return index + 1 < 10 ? '0' + (index + 1) : index + 1;
  }

  public contextMenuActionListHandler(actionType, {position, index}, moveTo?: number, returnData?: any): void {
    this.positionsActionList.emit({
      rowIndex: index,
      actionType: actionType,
      row: position,
      moveTo: moveTo,
      top: 0,
      left: 0,
      rows: this.positions,
      returnData: returnData
    });
  }

  public getPositionsIndex(): any {
    if (!!this.positions.length) {
      this.positionsIndexes = this.positions.map((item, index) => {
        return {
          index,
          title: this.getRowOrderNumber(index)
        };
      });
    }
  }
}
