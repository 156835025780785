import { Component } from '@angular/core';


@Component({
  selector: 'rnpl-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})

export class PreloaderComponent {
}
