import { createSelector } from '@ngrx/store';

import { POBlocksValidations, POFeatureKey, POState, POStore } from '../reducers';
import { AppState } from '../../../store/state/app.state';


export const selectFeature = ((state: AppState) => state[POFeatureKey]);

export const selectPOList = createSelector(
  selectFeature,
  (state: POStore) => state.list
);

export const selectPOEntity = createSelector(
  selectFeature,
  (state: POStore) => state.entity
);

export const selectPOBlocksValidations = createSelector(
  selectFeature,
  (state: POStore) => state.blocksValidations
);


export const selectPurchaseOrder = createSelector(
  selectPOEntity,
  (state: POState) => state.purchaseOrder
);

export const selectCountries = createSelector(
  selectPOEntity,
  (state: POState) => state.countries
);

export const selectPartners = createSelector(
  selectPOEntity,
  (state: POState) => state.partners
);

export const selectPoCurrentState = createSelector(
  selectPOEntity,
  (state: POState) => state.currentState
);

export const selectPoPositions = createSelector(
  selectPOEntity,
  (state: POState) => state.positions
);

export const selectPoPositionsCount = createSelector(
  selectPOEntity,
  (state: POState) => state.positionsCount
);

export const loadingRequestsCount = createSelector(
  selectPOEntity,
  (state: POState) => state.loadingRequestsCount
);

export const purchaseOrderUpdatedAt = createSelector(
  selectPOEntity,
  (state: POState) => state.updatedAt
);

export const isShouldRefresh = createSelector(
  selectPOEntity,
  (state: POState) => state.isShouldRefresh
);

export const selectPurchaseOrderPropertiesBlockValid = createSelector(
  selectPOBlocksValidations,
  (state: POBlocksValidations) => state.propertiesBlockValid
);

export const selectPurchaseOrderDeliveryInfoBlockValid = createSelector(
  selectPOBlocksValidations,
  (state: POBlocksValidations) => state.deliveryInfoBlockValid
);

export const selectPurchaseOrderPositionsBlockValid = createSelector(
  selectPOBlocksValidations,
  (state: POBlocksValidations) => state.positionsBlockValid
);

