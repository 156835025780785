import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'rnpl-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent {

  @Input() public dialogRef: MatDialogRef<any>;
  @Input() public modalTitle: string;
  @Input() public dialogContentClass: string;

  @Output() closeClicked: EventEmitter<any> = new EventEmitter<any>();

  public closeModal(): void {
    this.closeClicked.emit();
  }

}
