import { Routes } from '@angular/router';

import { IsPurchaseOrder } from './guards/is-po.guard';
import {
  PurchaseOrderListComponent,
  PurchaseOrderManagementComponent,
  DocumentsComponent,
  PurchaseOrderAttachmentsComponent
} from './pages';
import { PoTabs } from './enums';
import { DocumentPreviewTabsEnum } from 'common/src/models/document-preview-tabs.enum';
import { PurchaseOrderRemarkComponent } from './pages/purchase-order-management/purchase-order-remark/purchase-order-remark.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `list/${PoTabs.Open}/1`,
    pathMatch: 'full'
  },
  {
    path: 'list/:status/:page',
    component: PurchaseOrderListComponent,
  },
  {
    path: ':id',
    component: PurchaseOrderManagementComponent,
    canActivate: [IsPurchaseOrder],
    children: [
      {
        path: '',
        redirectTo: `${DocumentPreviewTabsEnum.DOCUMENT}`,
        pathMatch: 'full'
      },
      {
        path: `${DocumentPreviewTabsEnum.DOCUMENT}`,
        component: PurchaseOrderManagementComponent,
      },
      {
        path: `${DocumentPreviewTabsEnum.DOCUMENTS}`,
        component: DocumentsComponent,
      },
      {
        path: `${DocumentPreviewTabsEnum.REMARK}`,
        component: PurchaseOrderRemarkComponent,
      },
      {
        path: `${DocumentPreviewTabsEnum.ATTACHMENTS}`,
        component: PurchaseOrderAttachmentsComponent,
      },
    ]
  },
];
