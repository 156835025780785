import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SidebarComponent } from './sidebar.component';
import { RnplCommonModule } from 'common/src/modules/rnpl-common';

@NgModule({
  declarations: [
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RnplCommonModule,
  ],
  exports: [SidebarComponent],
  entryComponents: [SidebarComponent]
})
export class SidebarModule { }
