import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';


import { CellDataModel } from '../../composer/interfaces';
import { AssignedAttributeModel, AttributeModel } from '../../../system-settings/models';
import { ControlDefinitionModel } from '../../control-definition.model';


const TMP_GRID = [
  // the first row
  [
    {size: 'medium', data: '1.1'}
  ],
  // the second row
  [
    {size: 'small', data: '2.1'},
    {size: 'small', data: '2.2'},
    // {size: 'medium', data: '2.3'}
  ],
  // the third row
  [
    {size: 'medium', data: '3.1'},
    {size: 'medium', data: '3.2'}
  ],
  // the fourth row
  [
    {size: 'small', data: '4.1'},
    {size: 'medium', data: '4.2'}
  ],
  // the fifth row
  [
    {size: 'wide', data: '5.1'}
  ]
];

@Component({
  selector: 'rnpl-form-composer',
  templateUrl: './form-composer.component.html',
  styleUrls: ['./form-composer.component.scss']
})
export class FormComposerComponent implements OnChanges {

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('parentFamilyControls') && this.assignedAttrs) {
      this.grid = this.assignedToGrid(this.assignedAttrs);
    }
  }

  @Input()
  set assignedAttributes(attributes: Array<AssignedAttributeModel>) {
    this.grid = this.assignedToGrid(attributes);
    this.assignedAttrs = attributes;
  }

  @Input() parentFamilyControls: ControlDefinitionModel[] = [];

  @Output()
  changed: EventEmitter<Array<AssignedAttributeModel>> = new EventEmitter<Array<AssignedAttributeModel>>();

  @Output()
  itemSelected: EventEmitter<AttributeModel> = new EventEmitter<AttributeModel>();

  assignedAttrs: AssignedAttributeModel[];

  grid: Array<Array<CellDataModel<AttributeModel>>> = [];

  public updateGrid(newGrid: Array<Array<CellDataModel<AttributeModel>>>): void {
    this.grid = newGrid;
    this.changed.emit(this.gridToAssigned(this.grid));
  }

  public selectCell(cell: CellDataModel<AttributeModel>): void {
    this.itemSelected.emit(cell.data);
  }

  private assignedToGrid(attributes: Array<AssignedAttributeModel>): Array<Array<CellDataModel<AttributeModel>>> {
    return attributes.reduce((grid, assignedAttr, index) => {

      grid[grid.length - 1].push({
        size: assignedAttr.layout.size || 'medium',
        data: assignedAttr.attribute
      });
      if (assignedAttr.layout.isLast && index < attributes.length - 1) {
        grid.push([]);
      }

      // feature only for collective_field controls
      if (assignedAttr.attribute.control === 'collective_field' && assignedAttr.properties && assignedAttr.properties.length) {
        const placeholder: string[] = [];

        // Check collective field property entries for parent family assigned attributes
        if (this.parentFamilyControls && this.parentFamilyControls.length) {
          this.parentFamilyControls.map(control => {
            if (assignedAttr.properties.includes(Number(control.key))) {
              placeholder.push(control.properties.label);
            }
          });
        }

        // Check collective field property entries for current family assigned attributes
        if (attributes && attributes.length) {
          attributes.map(attr => {
            if (assignedAttr.properties.includes(attr.attribute.id)) {
              placeholder.push(attr.attribute.name);
            }
          });
        }

        // assignedAttr.attribute.attributes will be added as placeholder
        assignedAttr.attribute.attributes = placeholder;
      }

      return grid;
    }, [[]]);
  }

  private gridToAssigned(grid: Array<Array<CellDataModel<AttributeModel>>>): Array<AssignedAttributeModel> {
    return grid.reduce<Array<AssignedAttributeModel>>((assignedAttrs, row) => {

      const cells: Array<AssignedAttributeModel> = row.map(cell => {
        return {
          attribute: cell.data,
          layout: {size: cell.size}
        };
      });
      cells[cells.length - 1].layout.isLast = true;

      return [...assignedAttrs, ...cells];
    }, []);
  }

}
