import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseControlComponent } from '../base-control.component';

@Component({
  selector: 'rnpl-toggle',
  templateUrl: './toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleComponent extends BaseControlComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.disabled = this.control.disabled || false;
    if (this.control.value === null) {
      // prefill false value
      this.control.patchValue(false, {emitEvent: false});
    }
  }

  public change(event) {
    this.control.setValue(event.target.checked);
    this.control.markAsDirty();

    this.formUpdated.emit(true);
  }
}
