import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlSegment, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';

import { UserPermissionsService } from '../services/user-permissions.service';
import { ToasterService } from '../modules/ui-components/toaster';

@Injectable({
  providedIn: 'root'
})
export class PermissionEcommerceGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private userPermissionsService: UserPermissionsService,
    private toasterService: ToasterService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.userPermissionsService.canViewEcommerce();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

    if (!this.userPermissionsService.canViewEcommerce()) {
      // this.toasterService.notify({
      //   type: 'error',
      //   message: 'Looks like you have no access to e-commerce documents. Get in touch with your workspace administrator if you want to get access.'
      // });
      this.router.navigate(['/no-access', {appName: 'e-commerce'}]);
      return false;
    }

    return this.userPermissionsService.canViewEcommerce();
  }
}
