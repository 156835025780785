import { Input } from '@angular/core';

import { BaseControlComponent } from './base-control.component';

export interface OptionModel {
  label: string;
  value: string;
}

export class MultipleControlComponent extends BaseControlComponent {

  @Input()
  public options: Array<OptionModel> = [];

  constructor() {
    super();
  }

}
