import { Pipe, PipeTransform } from '@angular/core';
import { DetailedVatModel } from 'common/src/modules/document-viewer/document-viewer.model';

@Pipe({
  name: 'sortingVatDetails'
})
export class SortingVatDetailsPipe implements PipeTransform {

  transform(vatDetailsList: DetailedVatModel[]): DetailedVatModel[] {
    if (!vatDetailsList) { return []; }

    const correctionVat = vatDetailsList.filter(itm => itm.vat < 0);
    const anyVat = vatDetailsList.filter(itm => itm.vat >= 0).sort((a, b) => b.vat - a.vat);

    return [...correctionVat, ...anyVat];
  }
}
