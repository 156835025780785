import { ColumnTypeEnum, DateColumnType, TableColumnModelExtended } from '../../../../models/table-column.model';

export function getReceivedGoodsColumns(cancelAll = false): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      cellClass: 'link-primary',
      name: 'COLUMN.PRODUCT_ID',
      prop: 'productLink',
      sortable: true,
      width: 132,
      maxWidth: 132,
    },
    {
      cellTemplate: 'withLinkPrimary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PRODUCT_NAME',
      preventTranslate: true,
      cellClass: 'link-primary inline',
      prop: 'productNameLink',
      sortable: true,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'FORM.DESCRIPTION',
      prop: 'productDescription',
      width: 256,
      maxWidth: 256,
      sortable: true,
      type: ColumnTypeEnum.TEXT,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.WAREHOUSED',
      prop: 'warehoused',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 98,
      maxWidth: 98,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.AVAILABLE',
      prop: 'available',
      sortable: true,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-500',
      width: 98,
      maxWidth: 98,
    },
    {
      cellTemplate: 'withInput',
      headerTemplate: 'headerPrimary',
      columnId: 'warehouseColumnModal',
      name: 'COLUMN.QUANTITY',
      prop: 'quantity',
      sortable: true,
      type: ColumnTypeEnum.STEPPER,
      decimal: 0,
      disabled: cancelAll,
      width: 172,
      maxWidth: 172,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BATCH',
      prop: 'batch',
      sortable: true,
      resizeable: false,
    },
    {
      cellTemplate: 'withDate',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.EXPIRATION_DATE',
      prop: 'expirationDate',
      sortable: true,
      resizeable: false,
      width: 140,
      maxWidth: 140,
      type: ColumnTypeEnum.DATE,
      dateType: DateColumnType.WITHOUT_TIME,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BIN_LOCATION',
      prop: 'binLocation',
      sortable: true,
      resizeable: false,
      width: 140,
      maxWidth: 140,
    },
  ];
}

