import { createAction, props } from '@ngrx/store';
import { FamilyModel, ProductModel } from 'common/src/modules/products';
import { UIStatesEnum } from 'common/src/models';
import { ProductsListPageState } from '../reducers';
import { ProductStatusEnum } from '../../enums/product-status.enum';
import { ProductTypes } from 'common/src/modules/products/product-types';


export const LoadEcommerceProductsList = createAction(
  '[Products e-commerce] Load Products List',
  props<{
    productType: ProductTypes,
    entityKey: 'bulk'|number,
    // status: ProductStatusEnum,
    page: number,
    productsListData: ProductsListPageState,
  }>()
);

export const LoadEcommerceCategoriesList = createAction(
  '[Products e-commerce] Load Categories',
  props<{
    productType: ProductTypes,
    categories: FamilyModel[],
  }>()
);
