import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { DeliveryNoteUnitsFormComponent } from './delivery-note-units-form.component';
import { UIComponentsModule } from 'common/src/modules';
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    DeliveryNoteUnitsFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UIComponentsModule,
    TranslateModule
  ],
  exports: [
    DeliveryNoteUnitsFormComponent
  ],
  providers: [],
})
export class DeliveryNoteUnitsFormModule {}
