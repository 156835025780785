import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BottomNavigationModel } from './bottom-navigation.model';

@Component({
  selector: 'rnpl-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomNavigationComponent {

  @Input() public navListItems: BottomNavigationModel[];

}
