import { Pipe, PipeTransform } from '@angular/core';
import { CountryImagePipe } from './country-image/country-image.pipe';

@Pipe({name: 'countryImageSrcByIso3'})
export class CountryImageByIso3Pipe implements PipeTransform {
  constructor(private countryImagePipe: CountryImagePipe) {
  }
  transform(iso3: string, ...args: any[]): string {
    let country = '';

    switch (iso3.toUpperCase()) {
      case 'AFG':
        country = 'afghanistan';
        break;
      case 'ALB':
        country = 'albania';
        break;
      case 'DZA':
        country = 'algeria';
        break;
      case 'ASM':
        country = 'american-samoa';
        break;
      case 'AND':
        country = 'andorra';
        break;
      case 'AGO':
        country = 'angola';
        break;
      case 'AIA':
        country = 'anguilla';
        break;
      case 'ATG':
        country = 'antigua-and-barbuda';
        break;
      case 'ARG':
        country = 'argentina';
        break;
      case 'ABW':
        country = 'aruba';
        break;
      case 'AUS':
        country = 'australia';
        break;
      case 'AUT':
        country = 'austria';
        break;
      case 'AZE':
        country = 'azerbaijan';
        break;
      case 'BHS':
        country = 'bahamas';
        break;
      case 'BHR':
        country = 'bahrain';
        break;
      case 'BGD':
        country = 'bangladesh';
        break;
      case 'BRB':
        country = 'barbados';
        break;
      case 'BLR':
        country = 'belarus';
        break;
      case 'BEL':
        country = 'belgium';
        break;
      case 'BLZ':
        country = 'belize';
        break;
      case 'BEN':
        country = 'benin';
        break;
      case 'BMU':
        country = 'bermuda';
        break;
      case 'BTN':
        country = 'bhutan';
        break;
      case 'BOL':
        country = 'bolivia';
        break;
      case 'BES':
        country = 'bonaire';
        break;
      case 'BIH':
        country = 'bosnia-and-herzegovina';
        break;
      case 'BWA':
        country = 'botswana';
        break;
      case 'BRA':
        country = 'brazil';
        break;
      case 'IOT':
        country = 'british-indian-ocean-territory';
        break;
      case 'BRN':
        country = 'brunei-darussalam';
        break;
      case 'BGR':
        country = 'bulgaria';
        break;
      case 'BFA':
        country = 'burkina-faso';
        break;
      case 'BDI':
        country = 'burundi';
        break;
      case 'CPV':
        country = 'cape-verde';
        break;
      case 'KHM':
        country = 'cambodia';
        break;
      case 'CMR':
        country = 'cameroon';
        break;
      case 'CAN':
        country = 'canada';
        break;
      case 'CYM':
        country = 'cayman-islands';
        break;
      case 'CAF':
        country = 'central-african-republic';
        break;
      case 'TCD':
        country = 'chad';
        break;
      case 'CHL':
        country = 'chile';
        break;
      case 'CHN':
        country = 'china';
        break;
      case 'CXR':
        country = 'christmas-island';
        break;
      case 'CCK':
        country = 'cocos-island';
        break;
      case 'COL':
        country = 'colombia';
        break;
      case 'COM':
        country = 'comoros';
        break;
      case 'COK':
        country = 'cook-islands';
        break;
      case 'CRI':
        country = 'costa-rica';
        break;
      case 'HRV':
        country = 'croatia';
        break;
      case 'CUB':
        country = 'cuba';
        break;
      case 'CUW':
        country = 'curacao';
        break;
      case 'CYP':
        country = 'cyprus';
        break;
      case 'CZE':
        country = 'czech-republic';
        break;
      case 'DNK':
        country = 'denmark';
        break;
      case 'DJI':
        country = 'djibouti';
        break;
      case 'DMA':
        country = 'dominica';
        break;
      case 'DOM':
        country = 'dominican-republic';
        break;
      case 'ECU':
        country = 'ecuador';
        break;
      case 'EGY':
        country = 'egypt';
        break;
      case 'ERI':
        country = 'eritrea';
        break;
      case 'EST':
        country = 'estonia';
        break;
      case 'ETH':
        country = 'ethiopia';
        break;
      case 'FLK':
        country = 'falkland-islands';
        break;
      case 'FRO':
        country = 'faroe-islands';
        break;
      case 'FJI':
        country = 'fiji';
        break;
      case 'FRA':
        country = 'france';
        break;
      case 'FIN':
        country = 'finland';
        break;
      case 'PYF':
        country = 'french-polynesia';
        break;
      case 'GAB':
        country = 'gabon';
        break;
      case 'GMB':
        country = 'gambia';
        break;
      case 'GEO':
        country = 'georgia';
        break;
      case 'DEU':
        country = 'germany';
        break;
      case 'GHA':
        country = 'ghana';
        break;
      case 'GIB':
        country = 'gibraltar';
        break;
      case 'GRC':
        country = 'greece';
        break;
      case 'GRL':
        country = 'greenland';
        break;
      case 'GRD':
        country = 'grenada';
        break;
      case 'GUM':
        country = 'guam';
        break;
      case 'GTM':
        country = 'guatemala';
        break;
      case 'GGY':
        country = 'guernsey';
        break;
      case 'GIN':
        country = 'guinea';
        break;
      case 'GNB':
        country = 'guinea-bissau';
        break;
      case 'GUY':
        country = 'guyana';
        break;
      case 'HTI':
        country = 'haiti';
        break;
      case 'HND':
        country = 'honduras';
        break;
      case 'HKG':
        country = 'hong-kong';
        break;
      case 'HUN':
        country = 'hungary';
        break;
      case 'ISL':
        country = 'iceland';
        break;
      case 'IND':
        country = 'india';
        break;
      case 'IDN':
        country = 'indonesia';
        break;
      case 'IRN':
        country = 'iran';
        break;
      case 'IRQ':
        country = 'iraq';
        break;
      case 'IRL':
        country = 'ireland';
        break;
      case 'IMN':
        country = 'isle-of-man';
        break;
      case 'ISR':
        country = 'israel';
        break;
      case 'ITA':
        country = 'italy';
        break;
      case 'JAM':
        country = 'jamaica';
        break;
      case 'JPN':
        country = 'japan';
        break;
      case 'JEY':
        country = 'jersey';
        break;
      case 'JOR':
        country = 'jordan';
        break;
      case 'KAZ':
        country = 'kazakhstan';
        break;
      case 'KEN':
        country = 'kenya';
        break;
      case 'KIR':
        country = 'kiribati';
        break;
      case 'KWT':
        country = 'kuwait';
        break;
      case 'KGZ':
        country = 'kyrgyzstan';
        break;
      case 'LVA':
        country = 'latvia';
        break;
      case 'LBN':
        country = 'lebanon';
        break;
      case 'LSO':
        country = 'lesotho';
        break;
      case 'LBR':
        country = 'liberia';
        break;
      case 'LBY':
        country = 'libya';
        break;
      case 'LIE':
        country = 'liechtenstein';
        break;
      case 'LTU':
        country = 'lithuania';
        break;
      case 'LUX':
        country = 'luxembourg';
        break;
      case 'MAC':
        country = 'macao';
        break;
      case 'MDG':
        country = 'madagascar';
        break;
      case 'MWI':
        country = 'malawi';
        break;
      case 'MYS':
        country = 'malaysia';
        break;
      case 'MDV':
        country = 'maldives';
        break;
      case 'MLI':
        country = 'mali';
        break;
      case 'MLT':
        country = 'malta';
        break;
      case 'MHL':
        country = 'marshall-island';
        break;
      case 'MTQ':
        country = 'martinique';
        break;
      case 'MRT':
        country = 'mauritania';
        break;
      case 'MUS':
        country = 'mauritius';
        break;
      case 'MEX':
        country = 'mexico';
        break;
      case 'FSM':
        country = 'micronesia';
        break;
      case 'MDA':
        country = 'moldova';
        break;
      case 'MCO':
        country = 'monaco';
        break;
      case 'MNG':
        country = 'mongolia';
        break;
      case 'MNE':
        country = 'montenegro';
        break;
      case 'MSR':
        country = 'montserrat';
        break;
      case 'MAR':
        country = 'morocco';
        break;
      case 'MOZ':
        country = 'mozambique';
        break;
      case 'MMR':
        country = 'myanmar';
        break;
      case 'NAM':
        country = 'namibia';
        break;
      case 'NRU':
        country = 'nauru';
        break;
      case 'NPL':
        country = 'nepal';
        break;
      case 'NLD':
        country = 'netherlands';
        break;
      case 'NZL':
        country = 'new-zealand';
        break;
      case 'NIC':
        country = 'nicaragua';
        break;
      case 'NER':
        country = 'niger';
        break;
      case 'NGA':
        country = 'nigeria';
        break;
      case 'NIU':
        country = 'niue';
        break;
      case 'NFK':
        country = 'norfolk-island';
        break;
      case 'MNP':
        country = 'northern-marianas-islands';
        break;
      case 'NOR':
        country = 'norway';
        break;
      case 'OMN':
        country = 'oman';
        break;
      case 'PAK':
        country = 'pakistan';
        break;
      case 'PLW':
        country = 'palau';
        break;
      case 'PSE':
        country = 'palestine';
        break;
      case 'PAN':
        country = 'panama';
        break;
      case 'PNG':
        country = 'papua-new-guinea';
        break;
      case 'PRY':
        country = 'paraguay';
        break;
      case 'PER':
        country = 'peru';
        break;
      case 'PHL':
        country = 'philippines';
        break;
      case 'PCN':
        country = 'pitcairn-islands';
        break;
      case 'POL':
        country = 'poland';
        break;
      case 'PRT':
        country = 'portugal';
        break;
      case 'PRI':
        country = 'puerto-rico';
        break;
      case 'QAT':
        country = 'qatar';
        break;
      case 'MKD':
        country = 'republic-of-macedonia';
        break;
      case 'ROU':
        country = 'romania';
        break;
      case 'RUS':
        country = 'russia';
        break;
      case 'RWA':
        country = 'rwanda';
        break;
      case 'KNA':
        country = 'saint-kitts-and-nevis';
        break;
      case 'WSM':
        country = 'samoa';
        break;
      case 'SMR':
        country = 'san-marino';
        break;
      case 'STP':
        country = 'sao-tome-and-principe';
        break;
      case 'SAU':
        country = 'saudi-arabia';
        break;
      case 'SEN':
        country = 'senegal';
        break;
      case 'SRB':
        country = 'serbia';
        break;
      case 'SYC':
        country = 'seychelles';
        break;
      case 'SLE':
        country = 'sierra-leone';
        break;
      case 'SGP':
        country = 'singapore';
        break;
      case 'SXM':
        country = 'sint-maarten';
        break;
      case 'SVK':
        country = 'slovakia';
        break;
      case 'SVN':
        country = 'slovenia';
        break;
      case 'SLB':
        country = 'solomon-islands';
        break;
      case 'SOM':
        country = 'somalia';
        break;
      case 'ZAF':
        country = 'south-africa';
        break;
      case 'SSD':
        country = 'south-sudan';
        break;
      case 'ESP':
        country = 'spain';
        break;
      case 'LKA':
        country = 'sri-lanka';
        break;
      case 'SDN':
        country = 'sudan';
        break;
      case 'SUR':
        country = 'suriname';
        break;
      case 'SWE':
        country = 'sweden';
        break;
      case 'CHE':
        country = 'switzerland';
        break;
      case 'SYR':
        country = 'syria';
        break;
      case 'TWN':
        country = 'taiwan';
        break;
      case 'TJK':
        country = 'tajikistan';
        break;
      case 'TZA':
        country = 'tanzania';
        break;
      case 'THA':
        country = 'thailand';
        break;
      case 'TGO':
        country = 'togo';
        break;
      case 'TKL':
        country = 'tokelau';
        break;
      case 'TON':
        country = 'tonga';
        break;
      case 'TTO':
        country = 'trinidad-and-tobago';
        break;
      case 'TUN':
        country = 'tunisia';
        break;
      case 'TUR':
        country = 'turkey';
        break;
      case 'TKM':
        country = 'turkmenistan';
        break;
      case 'TCA':
        country = 'turks-and-caicos';
        break;
      case 'TUV':
        country = 'tuvalu';
        break;
      case 'UGA':
        country = 'uganda';
        break;
      case 'UKR':
        country = 'ukraine';
        break;
      case 'ARE':
        country = 'united-arab-emirates';
        break;
      case 'GBR':
        country = 'united-kingdom';
        break;
      case 'USA':
        country = 'united-states';
        break;
      case 'URY':
        country = 'uruguay';
        break;
      case 'UZB':
        country = 'uzbekistn';
        break;
      case 'VUT':
        country = 'vanuatu';
        break;
      case 'VEN':
        country = 'venezuela';
        break;
      case 'VNM':
        country = 'vietnam';
        break;
      case 'VGB':
        country = 'virgin-islands';
        break;
      case 'YEM':
        country = 'yemen';
        break;
      case 'ZMB':
        country = 'zambia';
        break;
      case 'ZWE':
        country = 'zimbabwe';
        break;
    }

    return this.countryImagePipe.transform(country);
  }
}

// Unused:

// abkhazia.svg
// aland-islands.svg
// azores-islands.svg
// balearic-islands.svg
// basque-country.svg
// bhutan-1.svg
// british-columbia.svg
// british-virgin-islands.svg
// canary-islands.svg
// ceuta.svg
// corsica.svg
// democratic-republic-of-congo.svg
// east-timor.svg
// england.svg
// equatorial-guinea.svg
// european-union.svg
// galapagos-islands.svg
// great-britain.svg
// guernsey.svg
// hawaii.svg
// ivory-coast.svg
// kosovo.svg
// laos.svg
// madeira.svg
// melilla.svg
// nato.svg
// north-korea.svg
// northen-cyprus.svg
// orkney-islands.svg
// ossetia.svg
// rapa-nui.svg
// republic-of-the-congo.svg
// saba-island.svg
// salvador.svg
// sardinia.svg
// scotland.svg
// sint-eustatius.svg
// somaliland.svg
// south-africa.svg
// south-korea.svg
// st-barts.svg
// st-lucia.svg
// st-vincent-and-the-grenadines.svg
// swaziland.svg
// tibet.svg
// transnistria.svg
// united-nations.svg
// vatican-city.svg
// wales.svg
// western-sahara.svg


