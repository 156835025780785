import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

import { SoundService } from '../../../../services/sound.service';
import { WarningModalComponent } from '../warning-modal/warning-modal.component';
import { ResultDataModel } from './skip-hints-modal.model';

@Component({
  selector: 'rnpl-skip-hint-modal',
  templateUrl: './skip-hint-modal.component.html',
})

export class SkipHintModalComponent implements OnInit {

  public skipAllHints: boolean = false;

  constructor(
    private soundService: SoundService,
    public dialogRef: MatDialogRef<WarningModalComponent>,
  ) {

  }

  ngOnInit(): void {
    this.soundService.playSound('modal_warning');

    const overlay = document.querySelector<HTMLElement>('.cdk-overlay-container');
    overlay.style.zIndex = '1200';

    this.dialogRef.afterClosed().subscribe(() => {
      overlay.style.zIndex = '1000';
    });
  }

  public confirmAction(): void {
    const resultData: ResultDataModel = {
      skipAllHints: this.skipAllHints
    };

    this.dialogRef.close(resultData);
  }

}
