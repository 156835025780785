import { NgModule } from '@angular/core';


import { StorageService } from './storage.service';


@NgModule({
  providers: [StorageService]
})
export class StorageModule {

}
