import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DocumentInfoTypeEnum, DocumentViewerModel } from '../../document-viewer.model';
import { PdfSettingsModel, PdfSettingsPositionsModel } from 'projects/workspace/src/app/pdf-settings/models';

@Component({
  selector: 'rnpl-document-viewer-positions-summary',
  templateUrl: './document-viewer-positions-summary.component.html',
  styleUrls: ['./document-viewer-positions-summary.component.scss']
})
export class DocumentViewerPositionsSummaryComponent {

  public DocumentInfoTypeEnum = DocumentInfoTypeEnum;

  @Input() public documentPreviewData: DocumentViewerModel;
  @Input() public pdfSettings: PdfSettingsModel;
  @Input() public isLoading: boolean;
  @Input() public isReadOnly: boolean;
  @Input() public hidePositionTotal: boolean;

  @Output() public displayDiscountModalEmit: EventEmitter<any> = new EventEmitter();
  @Output() public displayBillingInfoEmit: EventEmitter<any> = new EventEmitter();

  public displayDiscountModal(): void {
    this.displayDiscountModalEmit.emit();
  }

  public displayBillingInfo(action: string, el: HTMLElement): void {
    this.displayBillingInfoEmit.emit({action: action, element: el});
  }

  public get positionsSettings(): PdfSettingsPositionsModel {
    if (!this.pdfSettings) { return null; }
    return this.pdfSettings.settings.positions;
  }

}
