import { Component, Input } from '@angular/core';

import { CompanyProfile } from 'projects/workspace/src/app/administration/models/company-profile.model';
import { SubscriptionPlanEnum } from '../subscription-activation.emum';
import { StripePaymentService } from 'projects/workspace/src/app/shared/services';

@Component({
  selector: 'rnpl-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent {

  public stripePaymentElementId: string;

  @Input() activationError = false;
  @Input() companyProfile: CompanyProfile;
  @Input() currentSubscriptionPlan: SubscriptionPlanEnum;

  constructor(
  ) {
    this.stripePaymentElementId = StripePaymentService.PAYMENT_ELEMENT_ID;
  }

}
