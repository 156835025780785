export const LEGAL_TYPES_FOR_EAR = [
  'Einzelunternehmen',
  'GesbR',
  'KG',
  'OG',
  'Verein',
];

// legal types which used small business in Austria
export const LEGAL_TYPES_AUSTRIA_SMALL_BUSINESS = [
  'Einzelunternehmen',
  'GmbH',
  'GesbR',
  'KG',
  'OG',
  'Verein',
];
