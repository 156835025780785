export const VAT_LIST: {value: number; label: string}[] = [
  {value: 0, label: '0%'},
  {value: 5, label: '5%'},
  {value: 7, label: '7%'},
  {value: 10, label: '10%'},
  {value: 11, label: '11%'},
  {value: 12, label: '12%'},
  {value: 13, label: '13%'},
  {value: 19, label: '19%'},
  {value: 20, label: '20%'},
];
