import { TableColumnModelExtended } from 'common/src/models/table-column.model';
import { TableActivateTypes } from 'common/src/modules/ui-components/table/custom-table.enums';
import { TranslateService } from '@ngx-translate/core';

export function getSalesOrderOrderedProductsTableColumns(): TableColumnModelExtended[] {
  return [
    {
      cellTemplate: 'numberRows',
      cellClass: 'center',
      name: 'Nr.',
      width: 40,
      maxWidth: 40,
      sortable: false,
      resizeable: false,
      headerClass: 'center'
    },
    {
      cellTemplate: 'withArrowLink',
      cellClass: 'cursor-pointer',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.PRODUCT_NAME',
      prop: 'productName',
      sortable: true,
      clicktype: TableActivateTypes.Link,
    },
    {
      cellTemplate: 'primary',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.BARCODE',
      prop: 'barcode',
      sortable: true,
      width: 170,
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.OPEN',
      prop: 'open',
      sortable: true,
      width: 60,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-bold',
    },
    {
      cellTemplate: 'primaryWithNumber',
      headerTemplate: 'headerPrimary',
      name: 'COLUMN.ON_STOCK',
      prop: 'onStock',
      sortable: true,
      width: 60,
      unitTypeShortcut: true,
      cellClass: 'right font-weight-bold',
    }
  ];
}
