import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTreeModule, MatMenuModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { TabsModule } from 'common/src/modules/tabs/tabs.module';
import { RnplCommonModule } from '../rnpl-common';
import { UIComponentsModule } from '../ui-components/ui-components.module';
import {
  VerticalTreeComponent,
  LinearTreeBranchComponent,
  LinearTreeComponent,
  FamiliesPageComponent,
  FamilyAttrsPageComponent,
  LinearTreeBranchSkeletonComponent,
  FormComposerMegaHintComponent,
} from './components';

import { ProductsService } from './products.service';

import { DeleteFamilyModalComponent } from './modals';
import { VerticalTreeSkeletonComponent } from './components/vertical-tree-skeleton/vertical-tree-skeleton.component';

const PRODUCTS_COMPONENTS: Array<any> = [
  VerticalTreeComponent,
  LinearTreeComponent,
  FamiliesPageComponent,
  DeleteFamilyModalComponent,
  FamilyAttrsPageComponent,
  VerticalTreeSkeletonComponent,
  LinearTreeBranchSkeletonComponent,
  FormComposerMegaHintComponent
];

@NgModule({
  declarations: [
    ...PRODUCTS_COMPONENTS,
    LinearTreeBranchComponent
  ],
  exports: PRODUCTS_COMPONENTS,
  imports: [
    CommonModule,
    MatTreeModule,
    MatMenuModule,
    ReactiveFormsModule,
    TabsModule,
    RnplCommonModule,
    UIComponentsModule,
    TranslateModule,
    PerfectScrollbarModule
  ],
  entryComponents: [
    DeleteFamilyModalComponent
  ],
  providers: [
    ProductsService
  ]
})
export class ProductsCommonModule {

}
