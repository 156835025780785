import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

import { AttrListComponent } from './components';
import { UIComponentsModule } from 'common/src/modules/ui-components/ui-components.module';
import { SystemSettingsService } from './system-settings.service';
import { RnplCommonModule } from '../rnpl-common';


@NgModule({
  declarations: [AttrListComponent],
  exports: [AttrListComponent],
  imports: [
    RouterModule,
    CommonModule,
    MatMenuModule,
    NgSelectModule,
    UIComponentsModule,
    RnplCommonModule,
    TranslateModule
  ],
  providers: [SystemSettingsService]
})
export class SystemSettingsCommonModule {

}
