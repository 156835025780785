import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { COLUMNS } from './prereservation-modal.config';
import { DeliveryNoteApiService } from 'projects/workspace/src/app/delivery-note/services/delivery-note-api.service';
import { TableColumnModelExtended } from '../../../../models/table-column.model';
import { EmptyStateTypeEnum } from '../../../ui-components/empty-state/empty-state.model';
import { getDocumentUrl } from 'projects/workspace/src/app/shared/helpers';
import { CommonModalsActionsEnum } from '../../modals-common';
import { DeliveryNoteListTabsEnum } from 'projects/workspace/src/app/delivery-note/enums';
import {
  PrereservationPositionModel,
  PrereservationPositionsModel
} from 'projects/workspace/src/app/delivery-note/models/prereservation-positions.model';

@Component({
  selector: 'rnpl-prereservation-modal',
  templateUrl: './prereservation-modal.component.html',
  styleUrls: ['./prereservation-modal.component.scss'],
})
export class PrereservationModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public columns: TableColumnModelExtended[] = COLUMNS;
  public emptyStateTypeEnum = EmptyStateTypeEnum;

  private destroy$: ReplaySubject<any> = new ReplaySubject<any>(1);
  readonly productsList$: BehaviorSubject<PrereservationPositionsModel[]> = new BehaviorSubject<PrereservationPositionsModel[]>([]);
  readonly productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly btnToClearLoadingStatus$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private deliveryNoteApiService: DeliveryNoteApiService,
    public dialogRef: MatDialogRef<PrereservationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      dnId: number,
    }
  ) {}

  ngOnInit(): void {
    this.deliveryNoteApiService.getPreReservationProduct(this.data.dnId)
      .pipe(
        finalize(() => this.productsLoading$.next(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((products: PrereservationPositionsModel[]) => this.productsList$.next(this.prepareProductsList(products)));
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  public prepareProductsList(products: PrereservationPositionsModel[]): PrereservationPositionsModel[] {
    return products.map(product => {
      return {
        ...product,
        positions: product.positions.map(position => {
          return {
            ...position,
            documentLink: {
              label: position.documentRunpleId,
              routerLink: getDocumentUrl(position.documentType, position.documentId),
            },
            productLink: {
              label: position.productName,
              routerLink: `products/product-view/${position.productId}`,
            },
            recipient: {
              ...position.recipient,
              type:  position.recipient.runpleId && position.recipient.runpleId.includes('CP')
                ? 'corporate' as any
                : 'private' as any
            },
          };
        })
      };
    });
  }

  public submit(): void {
    let positions: PrereservationPositionModel[] = [];
    this.productsList$.getValue().forEach(p => {
      positions = [...positions, ...p.positions];
    });

    this.deliveryNoteApiService.preReservateProducts(
      this.data.dnId,
      positions
    )
      .pipe(
        switchMap(() => {
          return this.deliveryNoteApiService.changeSingleDeliveryNoteStatus(this.data.dnId, DeliveryNoteListTabsEnum.PACKING, null, true);
        }),
        switchMap(() => this.deliveryNoteApiService.deliveryNoteSetPacking(this.data.dnId, true)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.dialogRef.close(CommonModalsActionsEnum.CONFIRM));
  }

  public getAllProductsQuantity(positions: PrereservationPositionModel[]): number {
    if (!positions.length) { return 0; }

    return positions.reduce((acc, p: any) => (p.quantity || 0) + acc, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
