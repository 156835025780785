import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delayWhen } from 'rxjs/operators';


import { ProductsService } from 'common/src/modules/products/products.service';
import { FamilyModel } from 'common/src/modules/products/models';
import { ToasterService } from 'common/src/modules/ui-components/toaster';
import { Wizard } from 'common/src/modules/wizard/wizard';
import { WizardService } from 'common/src/modules/wizard/wizard.service';
import { PreloaderService } from 'common/src/modules/rnpl-common';
import { AttributeStep } from './steps';
import { SystemSettingsService } from '../system-settings.service';
import { AttributeModel } from '../models';
import { AttributeForm, AttributeFormFactory } from './attribute-form.factory';
import { ClosingPopupComponent } from './modals';
import { mapFormToAttribute } from './helpers';
import { HttpParams } from '@angular/common/http';
import { ProductTypes } from '../../products/product-types';

export interface AttributeWizardContext {
  productsType?: string;
  entityTypes?: Array<string>;
  entities?: Array<string>;
  forms?: Array<string>;
  families?: Array<FamilyModel>;
  attribute?: AttributeModel;
}

const stepStates: Map<string, string> = new Map([
  ['general-settings', 'ready'],
  ['control-setup', 'locked'],
  ['localization', 'locked'],
  ['translations', 'locked'],
  ['entity-type', 'locked'],
  ['entity', 'locked'],
  ['form', 'locked'],
  ['family', 'locked'],
  ['confirmation', 'locked']
]);

const stepProgresses = {
  generalSettings: {start: 0, finish: 23},
  controlSetup: {start: 23, finish: 38},
  entityType: {start: 38, finish: 52},
  entity: {start: 52, finish: 73},
  form: {start: 73, finish: 82},
  family: {start: 82, finish: 100},
  confirmation: {start: 100, finish: 100}
};

@Component({
  selector: 'rnpl-attribute-wizard',
  templateUrl: './attribute-wizard.component.html',
  styleUrls: ['./attribute-wizard.component.scss']
})
export class AttributeWizardComponent extends Wizard implements OnInit, OnDestroy {

  public entityTypes: Array<string> = [];
  public selectedEntities: Array<string> = [];
  public forms: Array<string> = [];
  public selectedFamilies: Array<FamilyModel> = [];

  @Output()
  public completed: EventEmitter<any> = new EventEmitter();

  @Output()
  public closed: EventEmitter<any> = new EventEmitter();

  public step: AttributeStep;

  public stepStates = new Map<string, string>(stepStates);

  public stepProgresses = stepProgresses;

  public form: AttributeForm = {};

  public countries: Array<any>;
  public availableLangs: Array<any>;
  public entities: any;
  public families: Array<FamilyModel>;
  public productsType: ProductTypes = ProductTypes.GOODS; // product type by default

  public displayHints: boolean = true;

  public get progress(): number {
    return this._progress;
  }

  public set progress(progress: number) {
    if (progress > this._progress) {
      this._progress = progress;
    }
  }

  public attributeId: number;
  private attribute: AttributeModel;

  private _progress: number = 0;

  private completedSteps: Set<string> = new Set<string>();

  private subscriptions: Array<Subscription> = [];

  @ViewChild('closingPopup', {static: true})
  private closingPopup: ClosingPopupComponent;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private systemSettingsService: SystemSettingsService,
              private wizardService: WizardService,
              private productsService: ProductsService,
              private toasterService: ToasterService,
              private preloaderService: PreloaderService) {

    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        this.step = params.get('step') as AttributeStep;
        this.setStepState(this.step, 'active');
      })
    );

    this.initForm();

    this.systemSettingsService.getAvailableCountries()
      .subscribe(response => {
        this.countries = response.data;

        const gbr = this.countries.find(country => country.code_iso3 === 'gbr');
        this.form.localization.get('localizations').setValue([gbr]);
      });

    this.systemSettingsService.getAvailableLanguages()
      .subscribe(response => this.availableLangs = response.data);

    const context = this.wizardService.getContext<AttributeWizardContext>('attribute');
    if (!context) {
      return;
    }
    this.setCompletedUntil(context.step);
    this.goToStep(context.step);
    if (context.data.entityTypes) {
      this.entityTypes = context.data.entityTypes;
    }
    if (context.data.entities) {
      this.selectedEntities = context.data.entities;
    }
    if (context.data.forms) {
      this.forms = context.data.forms;
    }
    if (context.data.families) {
      this.selectedFamilies = context.data.families;
    }
    if (context.data.productsType) {
      this.productsType = context.data.productsType as ProductTypes;
    }
    if (context.data.attribute) {
      this.initForm(context.data.attribute);
    }

    const familyParams = new HttpParams().set('products_type', this.productsType);
    this.productsService.getFamiliesTree(familyParams)
      .subscribe(response => this.families = response.data);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Invokes when a step is completed
   *
   * @param form Form of a step
   */
  public stepCompleted(form): void {
    this.wizardService.patchContextData('attribute', {
      attribute: mapFormToAttribute(this.form),
      entityTypes: this.form.relations.get('entityType').value,
      entities: this.form.relations.get('entities').value,
      forms: this.form.relations.get('forms').value,
      families: this.form.relations.get('families').value
    });
    this.setStepState(this.step, 'completed');
    this.completedSteps.add(this.step);

    if (this.step === 'confirmation') {
      this.preloaderService.initPreloader('attributeSaving');
      this.saveAttribute()
        .subscribe(
          () => {
            this.preloaderService.stopPreloader('attributeSaving');
            this.toasterService.notify({
              type: 'success',
              message: `${this.form.generalSettings.get('name').value} has been successfully created`
            });
            this.wizardService.close('attribute');
            this.completed.emit();
          },
          () => {
            this.preloaderService.stopPreloader('attributeSaving');
            this.toasterService.notify({
              type: 'error',
              message: `${this.form.generalSettings.get('name').value || 'The attribute'} has not been created`
            });
          }
        );
    }
  }

  /**
   * Invokes when a step is closed
   */
  public close(): void {
    if (!this.form.generalSettings.get('name').value) {
      this.wizardService.close('attribute');
      this.closed.emit();
      return;
    }

    this.closingPopup.close()
      .subscribe(action => {

        switch (action) {

          case 'save':
            this.saveAttribute(true)
              .subscribe(() => {
                this.wizardService.close('attribute');
                this.closed.emit();
              });
            break;

          case 'delete':
            this.wizardService.close('attribute');
            this.completed.emit();
            break;

          default:
        }
      });
  }

  /**
   * Navigation function
   *
   * @param step Step that should be displayed
   */
  public goToStep = (step: string) => {
    this.wizardService.setWizardStep('attribute', step);

    if (this.completedSteps.has(this.step)) {
      this.setStepState(this.step, 'completed');
    } else {
      this.setStepState(this.step, 'ready');
    }

    this.setStepState(step, 'active');
    if (this.attributeId) {
      // Todo replace path to relative
      this.router.navigate(['/system-settings/attributes-library/edit', this.attributeId, step]);
    } else {
      this.router.navigate(['../', step], { relativeTo: this.route });
    }
  };

  public openStep(step: AttributeStep, event?: Event, prevForms?: Array<string>): void {
    // if only one of the previous form invalid - returns true else false
    const isInvalid: boolean = prevForms ? prevForms.some(form => this.form[form].invalid) : false;

    if (event) {
      event.stopPropagation();
    }
    if (['ready', 'completed'].includes(this.stepStates.get(step)) && !isInvalid) {
      this.goToStep(step);
    }
  }

  public localizationStepsState(): string {
    let state = 'locked';

    if (this.stepStates.get('localization') === 'ready') {
      state = 'ready';
    }
    if (['localization', 'translations'].find(step => this.stepStates.get(step) === 'active')) {
      state = 'active';
    }

    const completedCount = ['localization', 'translations']
      .filter(step => this.stepStates.get(step) === 'completed')
      .length;
    if (completedCount === 2) {
      state = 'completed';
    }

    return state;
  }

  public relationsStepsState(): string {
    let state = 'locked';

    if (this.stepStates.get('entity-type') === 'ready') {
      state = 'ready';
    }
    if (['entity-type', 'entity', 'form', 'family'].find(step => this.stepStates.get(step) === 'active')) {
      state = 'active';
    }

    const completedCount = ['entity-type', 'entity', 'form', 'family']
      .filter(step => this.stepStates.get(step) === 'completed')
      .length;
    if (completedCount === 4) {
      state = 'completed';
    }

    return state;
  }

  private setStepState(step: AttributeStep | string, state: string): void {
    this.stepStates.set(step, state);
  }

  /**
   * Loads an existing attribute for editing mode
   */
  private loadAttribute(): void {
    this.systemSettingsService.getAttribute(this.attributeId)
      .subscribe(response => {
        this.attribute = response.data;
        this.initForm(this.attribute);
      });
  }

  /**
   * Saves attribute
   *
   * @param isDraft The draft toggle
   */
  private saveAttribute(isDraft: boolean = false): Observable<any> {
    const attribute = mapFormToAttribute(this.form);

    let result$ = !isDraft
      ? this.systemSettingsService.saveAttribute(attribute)
      : this.systemSettingsService.saveAttributeDraft(attribute);

    const families = this.form.relations.get('families').value;
    const forms = this.form.relations.get('forms').value;

    if (families.length && this.form.relations.get('entityType').value.includes('product')) {
      result$ = result$.pipe(delayWhen(response => {
        const productForms = forms.filter(form => form.startsWith('product'));
        const familyIds = families.map((family: FamilyModel) => family.id);

        return this.systemSettingsService.saveAttributeRelationsBatch(response.data.id, productForms, familyIds)
      }));
    }

    return result$;
  }

  /**
   * Inits form by form factory
   *
   * @param attribute An existing attribute
   */
  private initForm(attribute?: AttributeModel): void {
    attribute = attribute || {} as AttributeModel;

    this.form.generalSettings = AttributeFormFactory.generalSettings(attribute);
    this.form.controlSetup = AttributeFormFactory.controlSetup(attribute);
    this.form.localization = AttributeFormFactory.localization(attribute);

    this.form.translations = AttributeFormFactory.translations(attribute, this.form.controlSetup.get('options'));

    this.systemSettingsService.getEntities()
      .subscribe(response => {
        this.entities = response.data;

        this.form.relations = AttributeFormFactory.relations(this.entities, attribute);

        if (this.selectedEntities && this.selectedEntities.length) {
          this.form.relations.get('entities').patchValue(this.selectedEntities);
          if (!this.entityTypes || !this.entityTypes.length) {
            this.entityTypes = this.selectedEntities.map(entity => entity.match(/^[a-z]+/)[0]);
          }
        }
        if (this.entityTypes && this.entityTypes.length) {
          this.form.relations.get('entityType').patchValue(this.entityTypes);
        }
        if (this.forms && this.forms.length) {
          this.form.relations.get('forms').patchValue(this.forms);
        }
      });
  }

  private setCompletedUntil(step): void {
    for (const currStep of this.stepStates.keys()) {
      if (currStep === step) {
        break;
      }
      this.completedSteps.add(currStep);
      this.stepStates.set(currStep, 'completed');
    }
  }
}
