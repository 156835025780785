import { createSelector } from '@ngrx/store';

import { AppState } from '../../../store/state/app.state';
import { CompanyProfileState, CompanyProfileFeatureKey } from '../reducers';
import { get } from 'lodash';

export const selectFeature = ((state: AppState) => state[CompanyProfileFeatureKey]);


export const selectCompanyProfile = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.companyProfile
);

export const selectOnboarding = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.onboarding
);

export const selectCompanyTimezoneOffset = createSelector(
  selectFeature,
  (state: CompanyProfileState) => get(state, 'companyProfile.timeZoneOffset.zoneOffset')
);

export const selectCountries = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.countries
);

export const selectLegalTypes = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.legalTypes
);

export const selectValidations = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.validations
);

export const selectTitles = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.titles
);

export const loadingRequestsCount = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.loadingRequestsCount
);

export const profileUpdatedAt = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.updatedAt
);

// export const selectProfileState = createSelector(
//   selectFeature,
//   (state: CompanyProfileState) => state.currentState
// );

export const selectSaveAction = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.saveAction
);

export const selectActionName = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.actionName
);

// export const selectSaveActionDisabledAllowed = createSelector(
//   selectFeature,
//   (state: CompanyProfileState) => state.saveActionDisabled
// );
//
// export const selectSaveActionDisabled = createSelector(
//   selectFeature,
//   (state: CompanyProfileState) => state.saveActionDisabledAllowed
// );

export const selectLegalCountryTranslate = createSelector(
  selectFeature,
  (state: CompanyProfileState) => state.legalCountryTranslate
);
