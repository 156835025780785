import { createAction, props } from '@ngrx/store';

export const EmitEcoSettingsSync = createAction(
  '[Eco settings] Emit sync'
);

export const UpdateEcoSettingsUpdatedAt = createAction(
  '[Eco settings] Update Eco settings updatedAt',
  props<{ updatedAt: Date }>()
);

export const UpdateEcoSettingsTracking = createAction(
  '[Eco settings] Update Eco settings tracking',
  props<{ tracking: { changed: boolean, publishedAt: Date } }>()
);

export const IncrementLoadingRequestsCount = createAction(
  '[Eco settings] Increment Loading Requests Count'
);

export const DecrementLoadingRequestsCount = createAction(
  '[Eco settings] Decrement Loading Requests Count'
);

